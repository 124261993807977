import { post, destroy } from 'app/shared/utils/fetchr';
import routes from 'app/shared/utils/routes';
import { RetrieveFilesPayload } from 'app/modules/uploads/models';

export const getAllDatafiles = (payload: RetrieveFilesPayload) =>
  post(routes.patronus.uploadsDatafilesList, payload);

export const deleteDatafile = (id: number) => {
  return destroy(`${routes.patronus.uploadsDatafilesDelete}/${id}`);
};

export const processOneDatafile = (id: number, executeRules: boolean) => {
  const path = routes.patronus.uploadsDatafilesProcess.replace(
    ':id',
    String(id),
  );
  return post(path, {
    run_rules: executeRules,
  });
};
