/*
This function returns true if the app is running on a Windows machine based on the `userAgent` value
The reason for using this over the `appVersion` value is the latter is expected to be deprecated

For more information about the window navigator object: https://developer.mozilla.org/en-US/docs/Web/API/Window/navigator
*/
export const isWindows = (): boolean => {
  const { navigator } = window;
  return navigator.userAgent.includes('Win'); // Possible identifiers are Windows, Win32, Win64, WinCE
};
