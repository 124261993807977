// Models
import {
  CustomChecklistActionTypes,
  CustomChecklistUpdateItemPayload,
  CustomChecklistSearchPayload,
  CustomChecklistContent,
} from 'app/shared/customChecklist/models';

// Helpers
import { createAction, ActionsUnion } from 'app/shared/utils/action-helpers';

// Search custom checklist by tags and type
export const retrieveCustomChecklist = (
  payload: CustomChecklistSearchPayload,
) =>
  createAction(CustomChecklistActionTypes.RETRIEVE_CUSTOM_CHECKLIST, payload);

export const retrieveCustomChecklistSuccess = (result) =>
  createAction(
    CustomChecklistActionTypes.RETRIEVE_CUSTOM_CHECKLIST_SUCCESS,
    result,
  );

export const retrieveCustomChecklistSubmission = (id: number) =>
  createAction(
    CustomChecklistActionTypes.RETRIEVE_CUSTOM_CHECKLIST_SUBMISSION,
    id,
  );

export const retrieveCustomChecklistSubmissionSuccess = (result) =>
  createAction(
    CustomChecklistActionTypes.RETRIEVE_CUSTOM_CHECKLIST_SUBMISSION_SUCCESS,
    result,
  );

export const submitCustomChecklistSuccess = (response: any) =>
  createAction(
    CustomChecklistActionTypes.SUBMIT_CUSTOM_CHECKLIST_SUCCESS,
    response,
  );

export const updateCustomChecklistItem = (
  payload: CustomChecklistUpdateItemPayload,
) =>
  createAction(
    CustomChecklistActionTypes.UPDATE_CUSTOM_CHECKLIST_ITEM,
    payload,
  );

export const setCustomChecklistContent = (payload: CustomChecklistContent) =>
  createAction(
    CustomChecklistActionTypes.SET_CUSTOM_CHECKLIST_CONTENT,
    payload,
  );

export const setCustomChecklistSubmissionId = (payload: number) =>
  createAction(
    CustomChecklistActionTypes.SET_CUSTOM_CHECKLIST_SUBMISSION_ID,
    payload,
  );

const CustomChecklistActionsList = {
  submitCustomChecklistSuccess,
  retrieveCustomChecklist,
  retrieveCustomChecklistSuccess,
  retrieveCustomChecklistSubmission,
  retrieveCustomChecklistSubmissionSuccess,
  updateCustomChecklistItem,
  setCustomChecklistContent,
  setCustomChecklistSubmissionId,
};

export type CustomChecklistActions = ActionsUnion<
  typeof CustomChecklistActionsList
>;
