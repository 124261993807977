import { U21Chip, U21ChipColor } from 'app/shared/u21-ui/components';
import { InvestigationStatus } from 'app/modules/investigations/models';
import { AlertStatus } from 'app/modules/alerts/models';

type Status = AlertStatus | `${InvestigationStatus}`;

interface Props {
  status: Status;
}

export const STATUS_LABELS: Record<Status, string> = {
  OPEN: 'Open',
  CLOSED: 'Closed',
  IN_REVIEW: 'In review',
  OPEN_SHADOW: 'Shadow',
  RULE_VALIDATION: 'Validation',
};

export const STATUS_TO_COLOR: Partial<Record<Status, U21ChipColor>> = {
  OPEN: 'success',
  CLOSED: 'error',
  IN_REVIEW: 'warning',
};

export const StatusChip = (props: Props) => {
  const { status } = props;
  return (
    <U21Chip color={STATUS_TO_COLOR[status]}>{STATUS_LABELS[status]}</U21Chip>
  );
};
