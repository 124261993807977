import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { TableConfigActionTypes } from 'app/shared/CustomConfig/models';

// Api
import { saveCustomConfig as api } from 'app/shared/api/tableConfigs';

// Actions
import {
  saveCustomConfig as action,
  saveCustomConfigSuccess,
} from 'app/shared/CustomConfig/actions';

// Constants
import {
  TABLE_CONFIG_SAVE_ERROR_TOAST_MESSAGE,
  TABLE_CONFIG_SAVE_SUCCESS_TOAST_MESSAGE,
} from 'app/shared/CustomConfig/constants';

const rootAction = TableConfigActionTypes.SAVE_TABLE_CONFIG;
export function* saveCustomConfig({ payload }: ReturnType<typeof action>) {
  const config = {
    rootAction,
    request: call(api, payload),
    successToastMessage: TABLE_CONFIG_SAVE_SUCCESS_TOAST_MESSAGE,
    errorToastMessage: TABLE_CONFIG_SAVE_ERROR_TOAST_MESSAGE,
    success: function* onSuccess(
      result: ReturnType<typeof saveCustomConfigSuccess>['payload'],
    ) {
      yield put(saveCustomConfigSuccess(result));
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watch() {
  yield takeLatest(rootAction, saveCustomConfig);
}
