import {
  AndOrOperator,
  ConditionalOperators,
  EMPTY_FIELD_VALUE,
} from 'app/modules/detectionModels/constants';
import {
  AndOrCondition,
  Condition,
  DropdownBuilderFormValues,
} from 'app/modules/detectionModels/components/DropdownBuilderForm/models';

export enum DropdownBuilderFormFields {
  LEFT_OPERAND = 'leftOperand',
  OPERATOR = 'operator',
  CONDITION = 'condition',
  CONDITIONS = 'conditions',
}

export const EMPTY_DROPDOWN_BUILDER_CONDITION: Condition = {
  type: 'rule_condition_composite',
  operator: ConditionalOperators.EQ,
  leftOperand: EMPTY_FIELD_VALUE,
  rightOperand: null,
  betweenOperand: null,
};

export const EMPTY_DROPDOWN_BUILDER_AND_OR_CONDITION: AndOrCondition = {
  type: 'rule_condition_composite',
  operator: AndOrOperator.AND,
  conditions: [EMPTY_DROPDOWN_BUILDER_CONDITION],
};

export const EMPTY_DROPDOWN_BUILDER_AND_OR_CONDITION_WITH_NO_CONDITIONS: AndOrCondition =
  {
    type: 'rule_condition_composite',
    operator: AndOrOperator.AND,
    conditions: [],
  };

export const DEFAULT_DROPDOWN_BUILDER_FORM_VALUES: DropdownBuilderFormValues =
  Object.freeze({
    facts: [],
    condition: {
      type: 'rule_condition_composite',
      operator: AndOrOperator.AND,
      conditions: [EMPTY_DROPDOWN_BUILDER_CONDITION],
    },
  });
