import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { NotesActionTypes } from 'app/modules/notes/models';

// Actions
import {
  deleteNote as deleteNoteAction,
  deleteNoteSuccess,
} from 'app/modules/notes/actions';

// API
import { deleteNote } from 'app/shared/api/notes';

const rootAction = NotesActionTypes.DELETE_NOTE;
function* deleteNoteFlow({ payload }: ReturnType<typeof deleteNoteAction>) {
  const config = {
    rootAction,
    request: call(deleteNote, payload),
    success: function* onSuccess() {
      yield put(deleteNoteSuccess(payload));
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watchDeleteNote() {
  yield takeLatest(rootAction, deleteNoteFlow);
}
