// Models
import { UploadsActionTypes } from 'app/modules/uploads/models';

export const selectUploads = (state: RootState) => {
  return [
    ...(state.uploads.filesToUploadAsync || []),
    ...(state.uploads.uploads || []),
  ];
};

export const selectUploadsCount = (state: RootState) => {
  return state.uploads.count || 0;
};

export const selectUnprocessedUploadsCount = (state: RootState) => {
  return state.uploads.unprocessedCount || 0;
};

export const selectFilesToUpload = (state: RootState) => {
  return state.uploads.filesToUpload || [];
};

// Loading
export const selectRetrieveDatafilesLoading = (state: RootState) =>
  Boolean(state.loading[UploadsActionTypes.RETRIEVE_DATAFILES]);
