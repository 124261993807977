// Models
import {
  AlertsActionTypes,
  GetAlertsPayload,
  ChangeAlertStatusPayload,
  ReassignAlertsPayload,
  RequeueAlertsPayload,
  RetrieveAlertTxnsPayload,
  AlertEditPayload,
  RetrieveAlertEntitiesPayload,
  GetAlertsListResponse,
  AssociateAlertsWithCasesPayload,
  CreateAlertPayload,
  RetrieveUnassignedAlertsPayload,
  GetMyAlertsStatsResponse,
  PaginatedAlertsPayloadModel,
  AlertDetails,
  RetrieveAlertHistogramPayload,
  RetrieveAlertQueuesListResponse,
} from 'app/modules/alerts/models';
import { RetrieveAlertActionEventsPayload } from 'app/modules/alerts/requests';
import { TriggerActionPayload } from 'app/modules/webhooks/models';
import { ShortActionEventResponse } from 'app/modules/actionEvents/responses';
import { FullAttachmentResponse } from 'app/modules/attachments/types';

// Types
import {
  AssociatedInstrumentsResponse,
  AssociatedEntitiesResponse,
  ShortAlertResponse,
  FullAlertResponse,
} from 'app/modules/alerts/types';

// Utils
import { createAction, ActionsUnion } from 'app/shared/utils/action-helpers';
import { AlertsAlertCountResponse } from 'app/modules/alerts/queries/useFetchAlertsAlertCount';

export const editAlertDocumentSuccess = (result: {
  attachments: FullAttachmentResponse[];
}) => createAction(AlertsActionTypes.EDIT_DOCUMENT_SUCCESS, result);

export const associateAlertsWithCases = (
  payload: AssociateAlertsWithCasesPayload,
) => {
  return createAction(AlertsActionTypes.ASSOCIATE_ALERTS_WITH_CASES, payload);
};

export const associateAlertsWithCasesSuccess = (
  payload: AssociateAlertsWithCasesPayload,
) =>
  createAction(AlertsActionTypes.ASSOCIATE_ALERTS_WITH_CASES_SUCCESS, payload);

export const createAlert = (payload: CreateAlertPayload) =>
  createAction(AlertsActionTypes.CREATE_ALERT, payload);

export const createAlertSuccess = (result: AlertDetails) =>
  createAction(AlertsActionTypes.CREATE_ALERT_SUCCESS, result);

export const editAlert = (payload: AlertEditPayload) =>
  createAction(AlertsActionTypes.EDIT_ALERT, payload);

export const editAlertSuccess = (result: ShortAlertResponse) =>
  createAction(AlertsActionTypes.EDIT_ALERT_SUCCESS, result);

export const retrieveAlerts = (payload: GetAlertsPayload) =>
  createAction(AlertsActionTypes.RETRIEVE_ALERTS, payload);

export const retrieveAlertsSuccess = (result: GetAlertsListResponse) =>
  createAction(AlertsActionTypes.RETRIEVE_ALERTS_SUCCESS, result);

export const retrievealertsAlertCountSuccess = (
  payload: AlertsAlertCountResponse,
) =>
  createAction(AlertsActionTypes.RETRIEVE_ALERTS_ALERT_COUNT_SUCCESS, payload);

export const appendToTopOfAlerts = (result: GetAlertsListResponse) =>
  createAction(AlertsActionTypes.APPEND_TO_TOP_OF_ALERTS, result);

export const retrieveMyAlertsStats = () =>
  createAction(AlertsActionTypes.RETRIEVE_MY_ALERTS_STATS);

export const retrieveMyAlertsStatsSuccess = (
  result: GetMyAlertsStatsResponse,
) => createAction(AlertsActionTypes.RETRIEVE_MY_ALERTS_STATS_SUCCESS, result);

export const clearAlertsList = () =>
  createAction(AlertsActionTypes.CLEAR_ALERTS_LIST);

export const retrieveAlert = (payload: {
  id: number;
  useAlertTxnCacheEndpoint: boolean;
}) => createAction(AlertsActionTypes.RETRIEVE_ALERT, payload);

export const retrieveAlertSuccess = (result) =>
  createAction(AlertsActionTypes.RETRIEVE_ALERT_SUCCESS, result);

export const retrieveAlertDetails = (payload: {
  id: number;
  useAlertTxnCacheEndpoint: boolean;
}) => createAction(AlertsActionTypes.RETRIEVE_ALERT_DETAILS, payload);

export const retrieveAlertDetailsSuccess = (result) =>
  createAction(AlertsActionTypes.RETRIEVE_ALERT_DETAILS_SUCCESS, result);

export const retrieveAlertTransactions = (payload: RetrieveAlertTxnsPayload) =>
  createAction(AlertsActionTypes.RETRIEVE_ALERT_TRANSACTIONS, payload);

export const retrieveAlertTransactionsSuccess = (result) =>
  createAction(AlertsActionTypes.RETRIEVE_ALERT_TRANSACTIONS_SUCCESS, result);

export const retrieveAlertAuditTrail = (id: number) =>
  createAction(AlertsActionTypes.RETRIEVE_ALERT_AUDIT_TRAIL, id);

export const retrieveAlertAuditTrailSuccess = (result) =>
  createAction(AlertsActionTypes.RETRIEVE_ALERT_AUDIT_TRAIL_SUCCESS, result);

export const retrieveAlertEntities = (payload: RetrieveAlertEntitiesPayload) =>
  createAction(AlertsActionTypes.RETRIEVE_ALERT_ENTITIES, payload);

export const retrieveAlertEntitiesSuccess = (
  result: AssociatedEntitiesResponse,
) => {
  return createAction(
    AlertsActionTypes.RETRIEVE_ALERT_ENTITIES_SUCCESS,
    result,
  );
};

export const retrieveAlertInstruments = (
  payload: any, // TODO
) => createAction(AlertsActionTypes.RETRIEVE_ALERT_INSTRUMENTS, payload);

export const retrieveAlertInstrumentsSuccess = (
  result: AssociatedInstrumentsResponse,
) => createAction(AlertsActionTypes.RETRIEVE_ALERT_INSTRUMENTS_SUCCESS, result);

export const addDocumentsSuccess = (result) =>
  createAction(AlertsActionTypes.ADD_DOCUMENTS_SUCCESS, result);

export const triggerAlertAction = (payload: TriggerActionPayload) =>
  createAction(AlertsActionTypes.TRIGGER_ALERT_ACTION, payload);

export const triggerAlertActionSuccess = (payload: AlertDetails) =>
  createAction(AlertsActionTypes.TRIGGER_ALERT_ACTION_SUCCESS, payload);

export const changeAlertComponentStatus = (
  payload: ChangeAlertStatusPayload,
  onSuccess?: {
    txnUpdate?: RetrieveAlertTxnsPayload;
    entityUpdate?: RetrieveAlertEntitiesPayload;
  },
) => {
  return createAction(
    AlertsActionTypes.CHANGE_ALERT_COMPONENT_STATUS,
    payload,
    onSuccess,
  );
};

export const changeAlertComponentStatusSuccess = (result) =>
  createAction(AlertsActionTypes.CHANGE_ALERT_COMPONENT_STATUS_SUCCESS, result);

export const reassignAlerts = (
  payload: ReassignAlertsPayload,
  alertFilters?: GetAlertsPayload,
  handleSuccess?: () => void,
) =>
  createAction(AlertsActionTypes.REASSIGN_ALERTS, payload, {
    alertFilters,
    handleSuccess,
  });

export const reassignAlertsSuccess = (result) =>
  createAction(AlertsActionTypes.REASSIGN_ALERTS_SUCCESS, result);

export const requeueAlerts = (
  payload: RequeueAlertsPayload,
  alertFilters?: GetAlertsPayload,
  handleSuccess?: () => void,
) =>
  createAction(AlertsActionTypes.REQUEUE_ALERTS, payload, {
    alertFilters,
    handleSuccess,
  });

export const requeueAlertsSuccess = (result: PaginatedAlertsPayloadModel) =>
  createAction(AlertsActionTypes.REQUEUE_ALERTS_SUCCESS, result);

export const deleteDocumentSuccess = (result) =>
  createAction(AlertsActionTypes.DELETE_DOCUMENT_SUCCESS, result);

export const clearAlertEntities = () =>
  createAction(AlertsActionTypes.CLEAR_ALERT_ENTITIES);

export const retrieveAlertQueues = (payload) => {
  return createAction(AlertsActionTypes.RETRIEVE_ALERT_QUEUES, payload);
};

export const retrieveAlertQueuesSuccess = (
  result: RetrieveAlertQueuesListResponse,
) => {
  return createAction(AlertsActionTypes.RETRIEVE_ALERT_QUEUES_SUCCESS, result);
};

export const retrieveAllAlertQueues = () => {
  return createAction(AlertsActionTypes.RETRIEVE_ALL_ALERT_QUEUES);
};

export const retrieveAllAlertQueuesSuccess = (
  result: RetrieveAlertQueuesListResponse,
) => {
  return createAction(
    AlertsActionTypes.RETRIEVE_ALL_ALERT_QUEUES_SUCCESS,
    result,
  );
};

export const retrieveUnassignedAlerts = (
  payload: RetrieveUnassignedAlertsPayload,
) => createAction(AlertsActionTypes.RETRIEVE_UNASSIGNED_ALERTS, payload);

export const retrieveUnassignedAlertsSuccess = (result) =>
  createAction(AlertsActionTypes.RETRIEVE_UNASSIGNED_ALERTS_SUCCESS, result);

export const retrieveAlertHistogram = (
  payload: RetrieveAlertHistogramPayload,
) => createAction(AlertsActionTypes.RETRIEVE_ALERT_HISTOGRAM, payload);

export const retrieveAlertHistogramSuccess = (result) =>
  createAction(AlertsActionTypes.RETRIEVE_ALERT_HISTOGRAM_SUCCESS, result);

export const retrieveAlertActionEvents = (
  payload: RetrieveAlertActionEventsPayload,
) => createAction(AlertsActionTypes.RETRIEVE_ALERT_ACTION_EVENTS, payload);
export const retrieveAlertActionEventsSuccess = (result: {
  count: number;
  events: ShortActionEventResponse[];
}) =>
  createAction(AlertsActionTypes.RETRIEVE_ALERT_ACTION_EVENTS_SUCCESS, result);

export const updateAlertCache = (payload: FullAlertResponse) => {
  return createAction(AlertsActionTypes.UPDATE_ALERT_CACHE, payload);
};

const AlertsActionsList = {
  retrieveAlertsSuccess,
  appendToTopOfAlerts,
  retrieveMyAlertsStatsSuccess,
  clearAlertsList,
  retrieveAlertSuccess,
  retrieveAlertDetailsSuccess,
  retrieveAlertTransactionsSuccess,
  retrieveAlertAuditTrailSuccess,
  retrieveAlertEntitiesSuccess,
  retrieveAlertInstrumentsSuccess,
  changeAlertComponentStatusSuccess,
  reassignAlertsSuccess,
  requeueAlertsSuccess,
  createAlertSuccess,
  editAlertSuccess,
  addDocumentsSuccess,
  editAlertDocumentSuccess,
  deleteDocumentSuccess,
  clearAlertEntities,
  triggerAlertActionSuccess,
  associateAlertsWithCasesSuccess,
  retrieveAlertQueuesSuccess,
  retrieveAllAlertQueuesSuccess,
  retrieveUnassignedAlertsSuccess,
  retrieveAlertHistogramSuccess,
  retrieveAlertActionEventsSuccess,
  retrievealertsAlertCountSuccess,
  updateAlertCache,
};

export type AlertsActions = ActionsUnion<typeof AlertsActionsList>;
