// Saga
import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Api
import { addTagAPI } from 'app/shared/api/team';

// Models
import { TeamActionTypes, Tag } from 'app/modules/teamsOld/models';

// Actions
import {
  addTag as addTagAction,
  addTagSuccess,
  tagLimitReached,
} from 'app/modules/teamsOld/actions';

const rootAction = TeamActionTypes.ADD_TAG;
export function* addTag({
  payload,
  meta: handleSuccess,
}: ReturnType<typeof addTagAction>) {
  const config = {
    rootAction,
    request: call(addTagAPI, payload),
    successToastMessage: 'Tag created successfully',
    error: function* onError(response) {
      const { message } = response;

      if (message === 'Reached org tag limit') {
        yield put(tagLimitReached());
      }
    },
    success: function* onSuccess(result: Tag) {
      yield put(addTagSuccess(result));
      handleSuccess?.(result);
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watchAddTag() {
  yield takeLatest(rootAction, addTag);
}
