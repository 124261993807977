import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { WebhookActionTypes } from 'app/modules/webhooks/models';

// Actions
import {
  testWebhook as testWebhookAction,
  testWebhookSuccess,
} from 'app/modules/webhooks/actions';

// API
import { testWebhook as testWebhookAPI } from 'app/shared/api/webhooks';

import { sendSuccessToast } from 'app/shared/toasts/actions';

const rootAction = WebhookActionTypes.TEST_WEBHOOK;
export function* testWebhook({
  payload,
}: ReturnType<typeof testWebhookAction>) {
  const config = {
    rootAction,
    request: call(testWebhookAPI, payload),
    success: function* onSuccess(
      result: ReturnType<typeof testWebhookSuccess>['payload'],
    ) {
      yield put(testWebhookSuccess(result));
      yield put(sendSuccessToast(`Test Payload Triggered`));
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watchTestWebhook() {
  yield takeLatest(rootAction, testWebhook);
}
