// Saga
import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Api
import { archiveSar as archiveSarApi } from 'app/shared/api/sars';

// Models
import { SarActionTypes } from 'app/modules/fincenSar/models';

// Actions
import {
  archiveSarSuccess,
  archiveSar as archiveSarAction,
} from 'app/modules/fincenSar/actions';
import { archiveSarSuccess as goAMLArchiveSarSuccess } from 'app/modules/goAML/actions';

const rootAction = SarActionTypes.ARCHIVE_SAR;
function* archiveSar({ payload }: ReturnType<typeof archiveSarAction>) {
  const config = {
    rootAction,
    request: call(archiveSarApi, payload),
    successToastMessage: `Successfully updated SAR`,
    success: function* onSuccess(
      result: ReturnType<typeof archiveSarSuccess>['payload'],
    ) {
      yield put(archiveSarSuccess(result));
      yield put(goAMLArchiveSarSuccess(result));
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watch() {
  yield takeLatest(rootAction, archiveSar);
}
