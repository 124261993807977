import { SarStatus } from 'app/modules/fincenSar/models';

import { createSelector } from 'reselect';
import { selectHasEditSARsPermission } from 'app/modules/session/selectors';

import { FINCEN_SAR_SLICE_NAME } from 'app/modules/fincenSarNew/sliceFincenSar';

export const selectSarFormData = (state: RootState) =>
  state[FINCEN_SAR_SLICE_NAME].sarFormData;

export const selectSarAuditTrail = (state: RootState) =>
  state[FINCEN_SAR_SLICE_NAME].sarAuditTrail;

export const selectSar = (state: RootState) => state[FINCEN_SAR_SLICE_NAME].sar;

export const selectRetrieveSarLoading = (state: RootState) =>
  state[FINCEN_SAR_SLICE_NAME].loadingRetrieveSar;

export const selectUpdateSarLoading = (state: RootState) =>
  state[FINCEN_SAR_SLICE_NAME].loadingUpdateSar;

export const selectReturnToEditLoading = (state: RootState) =>
  state[FINCEN_SAR_SLICE_NAME].loadingReturnToEdit;

export const selectEntityIpsLoading = (state: RootState) =>
  state[FINCEN_SAR_SLICE_NAME].loadingEntityIps;

export const selectEventIpsLoading = (state: RootState) =>
  state[FINCEN_SAR_SLICE_NAME].loadingEventIps;

export const selectUploadAttachmentLoading = (state: RootState) =>
  state[FINCEN_SAR_SLICE_NAME].loadingUploadAttachment;

export const selectDownloadAttachmentLoading = (state: RootState) =>
  state[FINCEN_SAR_SLICE_NAME].loadingDownloadAttachment;

export const selectDeleteAttachmentLoading = (state: RootState) =>
  state[FINCEN_SAR_SLICE_NAME].loadingDeleteAttachment;

export const selectTouchedFields = (state: RootState) =>
  state[FINCEN_SAR_SLICE_NAME].touchedFields;

export const selectNarrativeTemplates = (state: RootState) =>
  state[FINCEN_SAR_SLICE_NAME].narrativeTemplates;

export const selectPreviewing = (state: RootState) =>
  state[FINCEN_SAR_SLICE_NAME].previewing;

export const selectNarrativeTemplatesLoading = (state: RootState) =>
  state[FINCEN_SAR_SLICE_NAME].loadingNarrativeTemplates;

export const selectSarXlOrCsvExportLoading = (state: RootState) =>
  state[FINCEN_SAR_SLICE_NAME].loadingSarXlOrCsvExport;

export const selectArchiveLoading = (state: RootState) =>
  state[FINCEN_SAR_SLICE_NAME].loadingArchive;

export const selectAuditTrailLoading = (state: RootState) =>
  state[FINCEN_SAR_SLICE_NAME].loadingAuditTrail;

export const selectSubmittingSarLoading = (state: RootState) =>
  state[FINCEN_SAR_SLICE_NAME].loadingSubmittingSar;

export const selectSarIsArchived = (state: RootState) =>
  state[FINCEN_SAR_SLICE_NAME].sar?.status === SarStatus.ARCHIVED;

export const selectFormIsDisabled = createSelector(
  selectSar,
  selectPreviewing,
  selectHasEditSARsPermission,
  selectSarIsArchived,
  (sar, previewing, sarEditPermission, archived) => {
    let isDisabled = previewing;
    isDisabled = isDisabled || !sarEditPermission;
    isDisabled = isDisabled || sar.lock;
    isDisabled = isDisabled || sar.submitted;
    isDisabled = isDisabled || archived;
    return isDisabled;
  },
);

export const selectFormLocked = createSelector(selectSar, (sar) => {
  return sar.lock;
});

export const selectFormSubmitted = createSelector(selectSar, (sar) => {
  return sar.submitted;
});

export const selectCarEnabled = (state: RootState) =>
  state[FINCEN_SAR_SLICE_NAME].carEnabled;

export const selectChangeSarQueueLoading = (state: RootState) =>
  state[FINCEN_SAR_SLICE_NAME].loadingChangeSarQueue;

export const selectSarEntities = createSelector(selectSar, (sar) => {
  return sar.entities;
});

export const selectSarAlerts = createSelector(selectSar, (sar) => {
  return sar.alerts;
});

export const selectSarEvents = createSelector(selectSar, (sar) => {
  return sar.events;
});

export const selectSarDeadline = createSelector(selectSar, (sar) => {
  return { deadline: sar.deadline, deadlineConfig: sar.deadline_config };
});

export const selectAll3241Enabled = (state: RootState) =>
  state[FINCEN_SAR_SLICE_NAME].all3241enabled;

export const selectNarrativeTab = (state: RootState) =>
  state[FINCEN_SAR_SLICE_NAME].narrativeTab;
