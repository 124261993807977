import { QueueType } from 'app/modules/queues/models';
import { QueueSubtypes } from 'app/modules/alerts/models';
import { ValidateAgentInQueuePayload } from 'app/modules/queues/requests';

const BASE = ['queues'];

export const QUEUES_QUERY_KEYS = {
  getSingleQueueDeadlineSummary: (id: number) =>
    [...BASE, 'deadline-summary', id] as const,
  getAllQueues: (payload: QueueType) => [...BASE, 'all', payload] as const,
  getQueue: (id: number) => [...BASE, 'queue', id] as const,
  getSubtypeAlertQueue: (type: QueueSubtypes) =>
    [...BASE, 'subtype', type] as const,
  validateAgentInQueue: (payload: ValidateAgentInQueuePayload) =>
    [...BASE, 'validate-agent-in-queue', payload] as const,
};
