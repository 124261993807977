// ----------------------------------------------------------------------
// CUSTOM CHANGES
// - set base font size to 14
// CUSTOM CHANGES
// - updated remToPx to handle strings

const BODY_FONT_SIZE = 14;

export function remToPx(value: number | string): number {
  if (typeof value === 'string') {
    return Math.round(parseFloat(value.replace('rem', '')) * BODY_FONT_SIZE);
  }
  return Math.round(value * BODY_FONT_SIZE);
}

export function pxToRem(value: number) {
  return `${value / BODY_FONT_SIZE}rem`;
}
