// Saga
import { call, put, takeEvery } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { DropdownActionTypes } from 'app/shared/dropdown/models';

// Api
import { retrieveDropdownOrgs as retrieveDropdownOrgsAPI } from 'app/shared/api/orgs';

// Actions
import {
  retrieveDropdownOrgs as retrieveDropdownOrgsAction,
  retrieveDropdownOrgsSuccess,
} from 'app/shared/dropdown/actions';

const rootAction = DropdownActionTypes.RETRIEVE_DROPDOWN_ORGS;
function* retrieveDropdownOrgs({
  payload,
}: ReturnType<typeof retrieveDropdownOrgsAction>) {
  const config = {
    rootAction,
    request: call(retrieveDropdownOrgsAPI, payload),
    success: function* onSuccess(
      result: ReturnType<typeof retrieveDropdownOrgsSuccess>['payload'],
    ) {
      yield put(retrieveDropdownOrgsSuccess(result));
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watchRetrieveDropdownOrgs() {
  yield takeEvery(rootAction, retrieveDropdownOrgs);
}
