import uploadStatus, {
  uploadStatusLabel,
} from 'app/modules/uploads/enums/uploadStatus';
import { Upload } from 'app/modules/uploads/models';

import styled from 'styled-components';

import { IconX } from '@u21/tabler-icons';
import {
  U21Button,
  U21Progress,
  U21Spacer,
} from 'app/shared/u21-ui/components';

interface OwnProps {
  row: Upload;
}

const UploadStatus = (props: OwnProps) => {
  const { row } = props;
  const percent = Math.round(row.percent || 0);

  let message = '';
  try {
    message = JSON.parse(row.status_message!).message;
  } catch (_error) {
    message = row.status_message!;
  }

  return (
    <Status
      $status={row.status}
      horizontal={row.status === uploadStatus.uploading}
    >
      <span>{uploadStatusLabel[row.status]}</span>
      {row.status === uploadStatus.validationFailed && (
        <div>({row.status_message})</div>
      )}
      {row.status === uploadStatus.processFailed && <div>({message})</div>}
      {row.status === uploadStatus.otherFailed && <div>({message})</div>}
      {row.status === uploadStatus.uploading && (
        <>
          <U21Progress value={percent} variant="circular">
            {percent}%
          </U21Progress>
          <CancelButton
            aria-label="cancel"
            color="error"
            icon={<IconX />}
            // cannot pass abort function directly into onClick
            onClick={() => row?.xhr?.abort?.()}
            size="small"
          />
        </>
      )}
    </Status>
  );
};

export default UploadStatus;

interface StyleProps {
  $status: string;
}

const CancelButton = styled(U21Button)`
  visibility: hidden;
`;

const Status = styled(U21Spacer)<StyleProps>`
  color: ${(props) => {
    switch (props.$status) {
      case uploadStatus.uploading:
        return props.theme.palette.primary.main;
      case uploadStatus.validationPending:
        return props.theme.palette.grey[800];
      case uploadStatus.validationFailed:
      case uploadStatus.processAborted:
      case uploadStatus.processFailed:
      case uploadStatus.otherFailed:
        return props.theme.palette.error.main;
      case uploadStatus.awaitingTrigger:
        return 'green';
      default:
        return props.theme.palette.grey[600];
    }
  }};

  :hover ${CancelButton} {
    visibility: visible;
  }
`;
