import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { WorkflowActionTypes } from 'app/modules/workflows/models';

// Actions
import {
  retrieveWorkflow as retrieveWorkflowAction,
  retrieveWorkflowSuccess,
} from 'app/modules/workflows/actions';

// API
import { retrieveWorkflow as api } from 'app/shared/api/workflows';

const rootAction = WorkflowActionTypes.RETRIEVE_WORKFLOW;
function* retrieveWorkflow({
  payload,
}: ReturnType<typeof retrieveWorkflowAction>) {
  const config = {
    rootAction,
    request: call(api, payload),
    success: function* onSuccess(
      result: ReturnType<typeof retrieveWorkflowSuccess>['payload'],
    ) {
      yield put(retrieveWorkflowSuccess(result));
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watchRetrieveWorkflow() {
  yield takeLatest(rootAction, retrieveWorkflow);
}
