import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { WebhookActionTypes } from 'app/modules/webhooks/models';

// Actions
import {
  rotateWebhookKey as rotateWebhookKeyAction,
  rotateWebhookKeySuccess,
} from 'app/modules/webhooks/actions';
import { sendSuccessToast } from 'app/shared/toasts/actions';

// API
import { rotateWebhook as api } from 'app/shared/api/webhooks';

const rootAction = WebhookActionTypes.ROTATE_WEBHOOK_KEY;
export function* rotateWebhookKey({
  payload,
}: ReturnType<typeof rotateWebhookKeyAction>) {
  const config = {
    rootAction,
    request: call(api, payload),
    success: function* onSuccess(
      result: ReturnType<typeof rotateWebhookKeySuccess>['payload'],
    ) {
      yield put(rotateWebhookKeySuccess(result));
      yield put(sendSuccessToast('Successfully rotated webhook key.'));
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watchRotateWebhookKey() {
  yield takeLatest(rootAction, rotateWebhookKey);
}
