import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Actions
import {
  revalidateDetectionModel as revalidateDetectionModelAction,
  revalidateDetectionModelSuccess,
  resetEditingModels,
} from 'app/modules/detectionModels/actions';

// API
import { revalidateDetectionModel as revalidateDetectionModelAPI } from 'app/shared/api/detectionModels';

// Models
import { DetectionModelsActionTypes } from 'app/modules/detectionModels/models';

// Utils
import routes from 'app/shared/utils/routes';
import { history } from 'app/store/browserHistory';

const rootAction = DetectionModelsActionTypes.REVALIDATE_DETECTION_MODEL;
function* revalidateDetectionModel({
  payload,
}: ReturnType<typeof revalidateDetectionModelAction>) {
  const config = {
    rootAction,
    errorToastMessage: 'Unable to revalidate model.',
    successToastMessage: 'Revalidating model',
    request: call(revalidateDetectionModelAPI, payload),
    success: function* onSuccess(
      result: ReturnType<typeof revalidateDetectionModelSuccess>['payload'],
    ) {
      yield put(revalidateDetectionModelSuccess(result));
      yield put(resetEditingModels());
      history.push(
        routes.lumos.detectionModelsValidationId.replace(
          ':id',
          `${result.rule_id}`,
        ),
      );
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watch() {
  yield takeLatest(rootAction, revalidateDetectionModel);
}
