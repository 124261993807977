import { forwardRef } from 'react';
import {
  U21Typography,
  U21TypographyProps,
} from 'app/shared/u21-ui/components/display/typography/U21Typography';

export const U21_NO_VALUE = '--';

export interface U21NoValueProps extends Omit<U21TypographyProps, 'children'> {}

export const U21NoValue = forwardRef<HTMLDivElement, U21NoValueProps>(
  (props: U21NoValueProps, ref) => {
    return (
      <U21Typography
        color="text.secondary"
        ref={ref}
        variant="body2"
        {...props}
      >
        {U21_NO_VALUE}
      </U21Typography>
    );
  },
);
