import { QueueSummaryResponse } from 'app/modules/queues/responses';

export enum NarrativeTemplateType {
  ALERT_NARRATIVE_TEMPLATE = 'ALERT_NARRATIVE_TEMPLATE',
  CASE_NARRATIVE_TEMPLATE = 'CASE_NARRATIVE_TEMPLATE',
}

export interface NarrativeTemplate {
  id: number;
  title: string;
  content: string;
  created_at: string | null;
  created_by_display_name: string;
  queues?: QueueSummaryResponse[];
  type: NarrativeTemplateType;
}
