import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { AgentsActionTypes } from 'app/modules/agentsOld/models';
import { ImpersonationSuccessResponse } from 'app/modules/agentsOld/types/responses';
// Actions
import {
  startImpersonation as startImpersonationAction,
  startImpersonationSuccess,
} from 'app/modules/agentsOld/actions';
import { startSession } from 'app/modules/session/actions';

// API
import { startImpersonation as startImpersonationAPI } from 'app/shared/api/agents';

// Utils
import {
  setImpersonateId,
  setImpersonateLogId,
} from 'app/shared/utils/sessionStorage';
import routes from 'app/shared/utils/routes';
import { history } from 'app/store/browserHistory';
import { queryClient } from 'app/cache/queryClient';

const rootAction = AgentsActionTypes.START_IMPERSONATION;
function* startImpersonation({
  payload: { authClient, ...payload },
}: ReturnType<typeof startImpersonationAction>) {
  const config = {
    rootAction,
    request: call(startImpersonationAPI, payload),
    errorToastMessage: 'Something went wrong - unable to impersonate agent.',
    success: function* onSuccess(result: ImpersonationSuccessResponse) {
      setImpersonateId(payload.agent_to_impersonate_id);
      setImpersonateLogId(result.impersonation_log_id);
      queryClient.clear();
      yield put({ type: '@@rootReducer/RESET_APP' });
      yield put(startSession(authClient));
      yield put(startImpersonationSuccess(result));
      history.push(routes.lumos.home);
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watch() {
  yield takeLatest(rootAction, startImpersonation);
}
