import { createSelector } from 'reselect';

// Models
import { BlacklistActionTypes } from 'app/modules/lists/models';
import { DropdownItemProps } from 'semantic-ui-react';

// Constants
import { INITIAL_BLACKLIST } from 'app/modules/lists/constants';

export const selectBlacklists = (state: RootState) =>
  state.blacklists.blacklists || [];

export const selectActiveBlacklists = createSelector(
  selectBlacklists,
  (blacklists) => blacklists.filter(({ status }) => status === 'ACTIVE'),
);

export const selectBlacklistsDropdownOptions = createSelector(
  selectActiveBlacklists,
  (blacklists): DropdownItemProps[] => {
    const formattedOptions = [] as DropdownItemProps[];
    blacklists.forEach((blacklist) =>
      formattedOptions.push({
        key: blacklist.id,
        text: blacklist.name,
        value: blacklist.id,
      }),
    );
    return formattedOptions;
  },
);

export const selectBlacklistsLoading = (state: RootState) =>
  Boolean(state.loading[BlacklistActionTypes.RETRIEVE_BLACKLISTS]);

export const selectBlacklistsCount = (state: RootState) =>
  state.blacklists.blacklistsCount || 0;

export const selectBlacklist = (state: RootState) =>
  state.blacklists.blacklist || INITIAL_BLACKLIST;

export const selectBlacklistLoading = (state: RootState) =>
  Boolean(state.loading[BlacklistActionTypes.RETRIEVE_BLACKLIST]);

export const selectBlacklistItems = (state: RootState) =>
  state.blacklists.blacklistItems;

export const selectBlacklistItemsLoading = (state: RootState) =>
  Boolean(state.loading[BlacklistActionTypes.RETRIEVE_BLACKLIST_ITEMS]);

export const selectBlacklistItemsCount = (state: RootState) =>
  state.blacklists.blacklistItemsCount;

export const selectCreateBlacklistLoading = (state: RootState) =>
  Boolean(state.loading[BlacklistActionTypes.CREATE_BLACKLIST]);

export const selectDeactivateBlacklistItemLoading = (state: RootState) =>
  Boolean(state.loading[BlacklistActionTypes.DEACTIVATE_BLACKLIST_ITEM]);

export const selectAddBlacklistItemLoading = (state: RootState) =>
  Boolean(state.loading[BlacklistActionTypes.ADD_BLACKLIST_ITEM]) ||
  Boolean(state.loading[BlacklistActionTypes.ADD_USER_BLACKLIST_ITEM]) ||
  Boolean(state.loading[BlacklistActionTypes.ADD_BUSINESS_BLACKLIST_ITEM]);

export const selectAppendFileToBlacklistLoading = (state: RootState) =>
  Boolean(state.loading[BlacklistActionTypes.APPEND_FILE_TO_BLACKLIST]);

export const selectBlacklistFileStatus = (state: RootState) =>
  state.blacklists.fileStatus;
