import { useState } from 'react';
import { ListValues, WidgetProps } from 'react-awesome-query-builder';
import AntdWidgets from 'react-awesome-query-builder/lib/components/widgets/antd';

type RelativeDuration =
  | 'MINUTES'
  | 'HOURS'
  | 'DAYS'
  | 'WEEKS'
  | 'MONTHS'
  | 'YEARS';

interface TimeValue {
  value?: number;
  duration?: RelativeDuration;
}

const DEFAULT_TIME_VALUE: TimeValue = {
  value: undefined,
  duration: 'DAYS',
};

const parseExistingValue = (
  existingValue: string | undefined,
): TimeValue | null => {
  if (!existingValue) {
    return null;
  }
  const [timeValue, duration] = existingValue.split(' ');
  const parsedNum: number = Number.parseInt(timeValue, 10);
  if (Number.isNaN(parsedNum)) {
    return {
      value: undefined,
      duration: duration as RelativeDuration,
    };
  }
  return {
    value: parsedNum,
    duration: duration as RelativeDuration,
  };
};

export const RelativeTimeWidget = (props: WidgetProps) => {
  const {
    setValue,
    value: existingValue,
    validateValue,
    placeholder,
    ...restProps
  } = props;
  const timeValue = parseExistingValue(existingValue) ?? DEFAULT_TIME_VALUE;
  const [internalTimeValue, setInternalTimeValue] =
    useState<TimeValue>(timeValue);
  const listValues: ListValues = [
    { title: 'MINUTES', value: 'MINUTES' },
    { title: 'HOURS', value: 'HOURS' },
    { title: 'DAYS', value: 'DAYS' },
    { title: 'WEEKS', value: 'WEEKS' },
    { title: 'MONTHS', value: 'MONTHS' },
    { title: 'YEARS', value: 'YEARS' },
  ];
  const updateValue = (newVal: TimeValue) => {
    setInternalTimeValue(newVal);
    // Invalid value to actualy set in the UI. Return null so the value can't be saved.
    if (!newVal.duration || !newVal.value) {
      setValue(null);
      return;
    }
    setValue(`${newVal.value} ${newVal.duration}`);
  };
  return (
    <div style={{ display: 'flex' }}>
      <AntdWidgets.NumberWidget
        value={internalTimeValue.value}
        setValue={(value) => {
          // Don't allow for negative values
          const numValue =
            value === undefined || value <= 0 ? undefined : value;
          updateValue({ ...internalTimeValue, ...{ value: numValue } });
        }}
        placeholder="value"
        {...restProps}
      />
      <AntdWidgets.SelectWidget
        value={internalTimeValue.duration}
        setValue={(value) => {
          updateValue({ ...internalTimeValue, ...{ duration: value } });
        }}
        listValues={listValues}
        placeholder="duration"
        {...restProps}
      />
    </div>
  );
};
