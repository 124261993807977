// Utils
import { get, post } from 'app/shared/utils/fetchr';

// Routes
import routes from 'app/shared/utils/routes';

// Models
import {
  CustomChecklistSearchPayload,
  CustomChecklistSubmissionPayload,
} from 'app/shared/customChecklist/models';

export const getCustomChecklist = (payload: CustomChecklistSearchPayload) =>
  post(routes.patronus.customChecklistSearch, payload);

export const getCustomChecklistSubmissionByArticleId = (id: number) =>
  get(
    routes.patronus.customChecklistArticleIdSubmission.replace(
      ':id',
      id.toString(),
    ),
  );

export const submitCustomChecklist = (
  payload: CustomChecklistSubmissionPayload,
) => {
  return post(
    routes.patronus.customChecklistArticleIdSubmission.replace(
      ':id',
      payload.article_id.toString(),
    ),
    payload,
  );
};
