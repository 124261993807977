import { u21CreateSlice } from 'app/shared/thunk/u21CreateSlice';
import { PayloadAction } from '@reduxjs/toolkit';

// Models
import {
  CompositeQueryTreeNode,
  DmFact,
  DmObjectToFlag,
  DmOperator,
  IRSchema,
  QueryTreeNode,
} from 'app/modules/rulesAdvanced/types';
import {
  ConditionalOperators,
  EMPTY_RULE_CONDITION_COMPOSITE,
  EMPTY_RULE_CONDITION_GROUP,
  IN_OPERATORS,
} from 'app/modules/detectionModels/constants';
import { clone, cloneDeep, get } from 'lodash';
import { FieldToFlagValue } from 'app/modules/rulesAdvanced/types/RulesRepresentation';
import {
  extractFactNames,
  extractFactNamesFromGroup,
  generateEmptyIrRule,
  generateNestedOperandKey,
} from 'app/modules/detectionModels/dropdownBuilderHelpers';

const RULE_CONTENT = 'rule-content';

export type RuleConditionValidity = Record<string, string>;
interface RuleContentState {
  irSchema: IRSchema;
  noEventSubTypesFound: boolean;
  ruleConditionValidity: RuleConditionValidity;
}

const generateInitialState = (object: DmObjectToFlag): RuleContentState => ({
  irSchema: generateEmptyIrRule(object),
  noEventSubTypesFound: false,
  ruleConditionValidity: {},
});
const initialState = generateInitialState('TXN_EVENT');

const findAndRemoveVariables = (
  condition: CompositeQueryTreeNode,
  variableName: string,
): CompositeQueryTreeNode => {
  if (!condition.operands.length) return condition;
  const conditionCopy = clone(condition);
  conditionCopy.operands = condition.operands
    .filter((operand) => {
      return !(
        !operand ||
        (operand.type === 'VARIABLE' && operand.name === variableName)
      );
    })
    .map((filteredOperand) => {
      if (filteredOperand?.type === 'rule_condition_composite') {
        return findAndRemoveVariables(filteredOperand, variableName);
      }
      return filteredOperand;
    });

  return conditionCopy;
};

const updateValidityKeys = (
  pattern: RegExp,
  validityObj: RuleConditionValidity,
) => {
  const validityObjCopy = cloneDeep(validityObj);
  const removableValidityEntries = Object.keys(validityObjCopy).filter((key) =>
    pattern.test(key),
  );
  removableValidityEntries.forEach((key) => delete validityObjCopy[key]);

  const validityKeys = Object.keys(validityObjCopy);
  let newGroupLevelIndex = 0;
  validityKeys.forEach((key, index) => {
    const keyPattern = /\d+,/;
    if (keyPattern.test(key)) return;

    Object.assign(validityObjCopy, {
      [newGroupLevelIndex]: validityObjCopy[key],
    });
    delete validityObjCopy[key];

    let nextIndex = index + 1;
    let nextKey = validityKeys[nextIndex];
    while (keyPattern.test(nextKey)) {
      const newKey = nextKey.split(',');
      newKey.splice(0, 1, newGroupLevelIndex.toString());

      Object.assign(validityObjCopy, {
        [newKey.join()]: validityObjCopy[validityKeys[nextKey]],
      });
      delete validityObjCopy[nextKey];

      nextIndex += 1;
      nextKey = validityKeys[nextIndex];
    }

    newGroupLevelIndex += 1;
  });

  return validityObjCopy;
};

const ruleContentSlice = u21CreateSlice({
  name: RULE_CONTENT,
  initialState,
  reducers: {
    resetRuleContentSlice: (draft, { payload }) => {
      const objectToFlag = payload?.objectToFlag || 'TXN_EVENT';
      Object.assign(draft, generateInitialState(objectToFlag));
    },
    setObjectToFlag: (draft, { payload }: PayloadAction<DmObjectToFlag>) => {
      draft.irSchema.object = payload;
    },
    setFieldToFlag: (draft, { payload }: PayloadAction<FieldToFlagValue>) => {
      draft.irSchema.field_to_flag = payload;
    },
    setEventSubtypeFilters: (draft, { payload }: PayloadAction<string[]>) => {
      if (draft.irSchema.field_to_flag)
        draft.irSchema.field_to_flag = {
          ...draft.irSchema.field_to_flag,
          values: payload,
        };
    },
    setNoEventSubtypesFound: (draft, { payload }: PayloadAction<boolean>) => {
      draft.noEventSubTypesFound = payload;
    },
    setRuleConditionValidity: (
      draft,
      {
        payload: { key, validity },
      }: PayloadAction<{ key: string; validity: string }>,
    ) => {
      draft.ruleConditionValidity[key] = validity;
    },
    setRuleCondition: (
      draft,
      { payload }: PayloadAction<CompositeQueryTreeNode>,
    ) => {
      draft.irSchema.rule_condition = payload;
    },
    setFacts: (draft, { payload }: PayloadAction<DmFact[]>) => {
      draft.irSchema.facts = payload;
    },
    updateOneFact: (draft, { payload }: PayloadAction<DmFact>) => {
      draft.irSchema.facts = draft.irSchema.facts.map((fact) => {
        return fact.id === payload.id ? payload : fact;
      });
    },
    removeOneFact: (
      draft,
      {
        payload: { groupIndex, conditionOperandIndex, variableName },
      }: PayloadAction<{
        groupIndex: number[];
        conditionOperandIndex: number;
        variableName: string;
      }>,
    ) => {
      draft.irSchema.facts = draft.irSchema.facts.filter(
        (fact) => fact.id !== variableName,
      );
      if (
        draft.irSchema.rule_condition &&
        draft.irSchema.rule_condition.type === 'rule_condition_composite'
      ) {
        let parentCondition;
        let conditionToUpdate;
        if (groupIndex.length === 0) {
          parentCondition = draft.irSchema.rule_condition;
          conditionToUpdate = clone(
            draft.irSchema.rule_condition.operands[conditionOperandIndex],
          );
        } else {
          parentCondition = get(
            draft.irSchema.rule_condition,
            generateNestedOperandKey(groupIndex),
          );

          conditionToUpdate = clone(
            get(
              draft.irSchema.rule_condition,
              generateNestedOperandKey(groupIndex),
            ).operands[conditionOperandIndex],
          );
        }
        if (
          conditionToUpdate &&
          conditionToUpdate.type === 'rule_condition_composite'
        ) {
          parentCondition.operands[conditionOperandIndex] =
            findAndRemoveVariables(conditionToUpdate, variableName);
        }
      }
    },
    setIrSchema: (draft, { payload }: PayloadAction<IRSchema>) => {
      draft.irSchema = payload;
    },
    setRuleConditionAndOrOperator: (
      draft,
      {
        payload: { groupIndex, operator },
      }: PayloadAction<{
        groupIndex: number[];
        operator: DmOperator;
      }>,
    ) => {
      if (
        draft.irSchema.rule_condition &&
        draft.irSchema.rule_condition.type === 'rule_condition_composite'
      ) {
        if (groupIndex.length === 0)
          draft.irSchema.rule_condition.operator = operator;
        else
          get(
            draft.irSchema.rule_condition,
            generateNestedOperandKey(groupIndex),
          ).operator = operator;
      }
    },
    setRuleConditionOperand: (
      draft,
      {
        payload: { groupIndex, operandIndex, updatedOperand },
      }: PayloadAction<{
        groupIndex: number[];
        operandIndex: number;
        updatedOperand: QueryTreeNode;
      }>,
    ) => {
      if (
        draft.irSchema.rule_condition &&
        draft.irSchema.rule_condition.type === 'rule_condition_composite'
      ) {
        const operand = cloneDeep(updatedOperand);
        if (
          (operand?.type === 'COMPOSITE' ||
            operand?.type === 'rule_condition_composite') &&
          operand.operands[1]?.type === 'MATCHLIST_FIELD' &&
          !IN_OPERATORS.has(operand.operator)
        ) {
          operand.meta_ui = {
            ...operand.meta_ui,
            ui_operator: ConditionalOperators.IN,
          };
          operand.operator = ConditionalOperators.ANY_IN;
        }

        if (groupIndex.length === 0)
          draft.irSchema.rule_condition.operands[operandIndex] = operand;
        else
          get(
            draft.irSchema.rule_condition,
            generateNestedOperandKey(groupIndex),
          ).operands[operandIndex] = operand;
      }
    },
    setRuleConditionOperator: (
      draft,
      {
        payload: { groupIndex, operandIndex, uiOperator, operator },
      }: PayloadAction<{
        groupIndex: number[];
        operandIndex: number;
        uiOperator?: DmOperator;
        operator: DmOperator;
      }>,
    ) => {
      if (
        draft.irSchema.rule_condition &&
        draft.irSchema.rule_condition.type === 'rule_condition_composite'
      ) {
        let condition;
        if (groupIndex.length === 0)
          condition = draft.irSchema.rule_condition.operands[operandIndex];
        else
          condition = get(
            draft.irSchema.rule_condition,
            generateNestedOperandKey(groupIndex),
          ).operands[operandIndex];

        if (
          condition?.type === 'COMPOSITE' ||
          condition?.type === 'rule_condition_composite'
        ) {
          condition.meta_ui = {
            ...condition.meta_ui,
            ui_operator: uiOperator,
          };
          condition.operator = operator;
        }
      }
    },
    addNewRuleConditionGroup: (
      draft,
      { payload: { groupIndex } }: PayloadAction<{ groupIndex: number[] }>,
    ) => {
      if (
        draft.irSchema.rule_condition &&
        draft.irSchema.rule_condition.type === 'rule_condition_composite'
      ) {
        if (groupIndex.length === 0)
          draft.irSchema.rule_condition.operands.push(
            EMPTY_RULE_CONDITION_GROUP,
          );
        else
          get(
            draft.irSchema.rule_condition,
            generateNestedOperandKey(groupIndex),
          ).operands.push(EMPTY_RULE_CONDITION_GROUP);
      }
    },
    addNewRuleConditionOperand: (
      draft,
      {
        payload: { groupIndex },
      }: PayloadAction<{
        groupIndex: number[];
      }>,
    ) => {
      if (
        draft.irSchema.rule_condition &&
        draft.irSchema.rule_condition.type === 'rule_condition_composite'
      ) {
        if (groupIndex.length === 0)
          draft.irSchema.rule_condition.operands.push(
            EMPTY_RULE_CONDITION_COMPOSITE,
          );
        else {
          const clonedNestedCondition = clone(
            get(
              draft.irSchema.rule_condition,
              generateNestedOperandKey(groupIndex),
            ).operands,
          );
          clonedNestedCondition.push(EMPTY_RULE_CONDITION_COMPOSITE);
          get(
            draft.irSchema.rule_condition,
            generateNestedOperandKey(groupIndex),
          ).operands = clonedNestedCondition;
        }
      }
    },
    removeOneRuleConditionOperand: (
      draft,
      {
        payload: { groupIndex, operandIndex },
      }: PayloadAction<{ groupIndex: number[]; operandIndex: number }>,
    ) => {
      if (
        draft.irSchema.rule_condition &&
        draft.irSchema.rule_condition.type === 'rule_condition_composite'
      ) {
        const factNames: Set<string> = new Set();
        if (groupIndex.length === 0) {
          const condition = clone(
            draft.irSchema.rule_condition.operands[operandIndex],
          );
          if (condition?.type === 'rule_condition_composite')
            extractFactNames(condition).forEach((factName) =>
              factNames.add(factName),
            );
          draft.irSchema.rule_condition.operands.splice(operandIndex, 1);
        } else {
          const conditionGroup = get(
            draft.irSchema.rule_condition,
            generateNestedOperandKey(groupIndex),
          );
          extractFactNames(
            clone(conditionGroup.operands[operandIndex]),
          ).forEach((factName) => factNames.add(factName));
          conditionGroup.operands.splice(operandIndex, 1);
        }

        const pattern = new RegExp(
          groupIndex.length > 0 ? `[${groupIndex.join(',')}]` : '^0',
        );
        draft.ruleConditionValidity = updateValidityKeys(
          pattern,
          draft.ruleConditionValidity,
        );

        if (factNames.size) {
          // NOTE: remove any facts that need to be removed with the condition
          draft.irSchema.facts = draft.irSchema.facts.filter(
            (fact) => !factNames.has(fact.id),
          );
        }
      }
    },
    removeOneRuleConditionGroup: (
      draft,
      { payload: { groupIndex } }: PayloadAction<{ groupIndex: number[] }>,
    ) => {
      if (
        draft.irSchema.rule_condition &&
        draft.irSchema.rule_condition.type === 'rule_condition_composite'
      ) {
        const pattern = new RegExp(`[${groupIndex.join(',')}]`);
        draft.ruleConditionValidity = updateValidityKeys(
          pattern,
          draft.ruleConditionValidity,
        );

        const condition = clone(
          draft.irSchema.rule_condition.operands[groupIndex[0]],
        );
        const factNames: Set<string> = new Set();
        if (condition?.type === 'rule_condition_composite')
          extractFactNamesFromGroup(condition).forEach((factName) =>
            factNames.add(factName),
          );

        if (factNames.size) {
          // NOTE: remove any facts that need to be removed with the condition
          draft.irSchema.facts = draft.irSchema.facts.filter(
            (fact) => !factNames.has(fact.id),
          );
        }

        draft.irSchema.rule_condition.operands.splice(groupIndex[0], 1);
      }
    },
  },
});

export const RULE_CONTENT_SLICE_NAME = ruleContentSlice.name;
export const {
  resetRuleContentSlice,
  setObjectToFlag,
  setFieldToFlag,
  setEventSubtypeFilters,
  setNoEventSubtypesFound,
  setRuleConditionValidity,
  setRuleCondition,
  setFacts,
  updateOneFact,
  removeOneFact,
  setIrSchema,
  setRuleConditionAndOrOperator,
  setRuleConditionOperand,
  setRuleConditionOperator,
  addNewRuleConditionGroup,
  addNewRuleConditionOperand,
  removeOneRuleConditionOperand,
  removeOneRuleConditionGroup,
} = ruleContentSlice.actions;
export default ruleContentSlice.reducer;
