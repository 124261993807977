import { u21CreateSlice } from 'app/shared/thunk/u21CreateSlice';
import { PayloadAction } from '@reduxjs/toolkit';

export const FRAUD_DASHBOARD_SLICE_NAME = 'fraudDashboard';

interface FraudDashboardState {
  optIn: boolean;
}

const INITIAL_STATE: FraudDashboardState = {
  optIn: true,
};

export const slice = u21CreateSlice({
  initialState: INITIAL_STATE,
  name: FRAUD_DASHBOARD_SLICE_NAME,
  reducers: {
    setOptIn: (draft, action: PayloadAction<boolean>) => {
      draft.optIn = action.payload;
    },
  },
});

export const { setOptIn } = slice.actions;
export default slice.reducer;
