// Needs to be "defaultstride" instead of "defaultStride"
// because custom attributes need to be all lowercase

import { TimeWindow } from 'app/modules/detectionModels/models';

// to indicate the time stride window is monthly
export const MONTHLY_TIME_WINDOW_VALUES: string[] = [
  '1m',
  '2m',
  '3m',
  '4m',
  '6m',
  '1y',
];

export const TIME_WINDOW_OPTIONS: TimeWindow[] = [
  {
    key: '10 minute',
    value: '10min',
    text: '10 minute',
    minutes: 10,
    defaultstride: '2min',
  },
  {
    key: '20 minute',
    value: '20min',
    text: '20 minute',
    minutes: 20,
    defaultstride: '4min',
  },
  {
    key: '1 hour',
    value: '1h',
    text: '1 hour',
    minutes: 60,
    defaultstride: '10min',
  },
  {
    key: '3 hour',
    value: '3h',
    text: '3 hour',
    minutes: 180,
    defaultstride: '20min',
  },
  {
    key: '6 hour',
    value: '6h',
    text: '6 hour',
    minutes: 360,
    defaultstride: '45min',
  },
  {
    key: '12 hour',
    value: '12h',
    text: '12 hour',
    minutes: 720,
    defaultstride: '1h',
  },
  {
    key: '1 day',
    value: '1d',
    text: '1 day',
    minutes: 1440,
    defaultstride: '2h',
  },
  {
    key: '34 hour',
    value: '34h',
    text: '34 hour',
    minutes: 2040,
    defaultstride: '3h',
  },
  {
    key: '2 day',
    value: '2d',
    text: '2 day',
    minutes: 2880,
    defaultstride: '3h',
  },
  {
    key: '3 day',
    value: '3d',
    text: '3 day',
    minutes: 4320,
    defaultstride: '4h',
  },
  {
    key: '5 day',
    value: '5d',
    text: '5 day',
    minutes: 7200,
    defaultstride: '6h',
  },
  {
    key: '10 day',
    value: '10d',
    text: '10 day',
    minutes: 14400,
    defaultstride: '1d',
  },
  {
    key: '1 week',
    value: '1w',
    text: '1 week',
    minutes: 10080,
    defaultstride: '1d',
  },
  {
    key: '2 week',
    value: '2w',
    text: '2 week',
    minutes: 20160,
    defaultstride: '3d',
  },
  {
    key: '3 week',
    value: '3w',
    text: '3 week',
    minutes: 30240,
    defaultstride: '4d',
  },
  {
    key: '1 month',
    value: '1m',
    text: '1 month',
    minutes: 44640,
    defaultstride: '5d',
  },
  {
    key: '31 days',
    value: '31d',
    text: '31 days',
    minutes: 44640,
    defaultstride: '5d',
  },
  {
    key: '6 week',
    value: '6w',
    text: '6 week',
    minutes: 60480,
    defaultstride: '6d',
  },
  {
    key: '2 month',
    value: '2m',
    text: '2 month',
    minutes: 87840,
    defaultstride: '1w',
  },
  {
    key: '61 days',
    value: '61d',
    text: '61 days',
    minutes: 87840,
    defaultstride: '1w',
  },
  {
    key: '3 month',
    value: '3m',
    text: '3 month',
    minutes: 132480,
    defaultstride: '2w',
  },
  {
    key: '92 days',
    value: '92d',
    text: '92 days',
    minutes: 132480,
    defaultstride: '2w',
  },
  {
    key: '4 month',
    value: '4m',
    text: '4 month',
    minutes: 175680,
    defaultstride: '2w',
  },
  {
    key: '122 days',
    value: '122d',
    text: '122 days',
    minutes: 175680,
    defaultstride: '2w',
  },
  {
    key: '6 month',
    value: '6m',
    text: '6 month',
    minutes: 263520,
    defaultstride: '3w',
  },
  {
    key: '183 days',
    value: '183d',
    text: '183 days',
    minutes: 263520,
    defaultstride: '3w',
  },
  {
    key: '7 month',
    value: '7m',
    text: '7 month',
    minutes: 306600,
    defaultstride: '1m',
  },
  {
    key: '1 year',
    value: '1y',
    text: '1 year',
    minutes: 525600,
    defaultstride: '1m',
  },
  {
    key: '365 days',
    value: '365d',
    text: '365 days',
    minutes: 525600,
    defaultstride: '1m',
  },
];

export interface WindowStrideOption {
  key: string;
  value: string;
  text: string;
  minutes: number;
  monthly_window_required?: boolean;
}
export const WINDOW_STRIDE_92DAYS_IN_MIN = 132_480;

export enum WindowStrideValue {
  DAYS_1 = '1d',
  DAYS_3 = '3d',
  WEEKS_1 = '1w',
  DAYS_30 = '30d',
}
export const WINDOW_STRIDE_OPTIONS: WindowStrideOption[] = [
  {
    key: '1 minutes',
    value: '1min',
    text: '1 minutes',
    minutes: 1,
  },
  {
    key: '2 minutes',
    value: '2min',
    text: '2 minutes',
    minutes: 2,
  },
  {
    key: '4 minutes',
    value: '4min',
    text: '4 minutes',
    minutes: 4,
  },
  {
    key: '5 minutes',
    value: '5min',
    text: '5 minutes',
    minutes: 5,
  },
  {
    key: '10 minutes',
    value: '10min',
    text: '10 minutes',
    minutes: 10,
  },
  {
    key: '15 minutes',
    value: '15min',
    text: '15 minutes',
    minutes: 15,
  },
  {
    key: '20 minutes',
    value: '20min',
    text: '20 minutes',
    minutes: 20,
  },
  {
    key: '30 minutes',
    value: '30min',
    text: '30 minutes',
    minutes: 30,
  },
  {
    key: '45 minutes',
    value: '45min',
    text: '45 minutes',
    minutes: 45,
  },
  {
    key: '1 hour',
    value: '1h',
    text: '1 hour',
    minutes: 60,
  },
  {
    key: '2 hours',
    value: '2h',
    text: '2 hours',
    minutes: 120,
  },
  {
    key: '3 hours',
    value: '3h',
    text: '3 hours',
    minutes: 180,
  },
  {
    key: '4 hours',
    value: '4h',
    text: '4 hours',
    minutes: 240,
  },
  {
    key: '6 hours',
    value: '6h',
    text: '6 hours',
    minutes: 360,
  },
  {
    key: '12 hours',
    value: '12h',
    text: '12 hours',
    minutes: 720,
  },
  {
    key: '1 day',
    value: WindowStrideValue.DAYS_1,
    text: '1 day',
    minutes: 1440,
  },
  {
    key: '2 days',
    value: '2d',
    text: '2 days',
    minutes: 2880,
  },
  {
    key: '3 days',
    value: WindowStrideValue.DAYS_3,
    text: '3 days',
    minutes: 4320,
  },
  {
    key: '4 days',
    value: '4d',
    text: '4 days',
    minutes: 5760,
  },
  {
    key: '5 days',
    value: '5d',
    text: '5 days',
    minutes: 7200,
  },
  {
    key: '6 days',
    value: '6d',
    text: '6 days',
    minutes: 8640,
  },
  {
    key: '1 week',
    value: WindowStrideValue.WEEKS_1,
    text: '1 week',
    minutes: 10080,
  },
  {
    key: '2 weeks',
    value: '2w',
    text: '2 weeks',
    minutes: 20160,
  },
  {
    key: '3 weeks',
    value: '3w',
    text: '3 weeks',
    minutes: 30240,
  },
  {
    key: 'first day of month',
    value: 'fdom',
    text: 'first day of month',
    minutes: 44640,
    monthly_window_required: true,
  },
  {
    key: 'last day of month',
    value: 'ldom',
    text: 'last day of month',
    minutes: 44640,
    monthly_window_required: true,
  },
  {
    key: 'first Monday of month',
    value: 'fmom',
    text: 'first Monday of month',
    minutes: 44640,
    monthly_window_required: true,
  },
  {
    key: 'last Friday of month',
    value: 'lfom',
    text: 'last Friday of month',
    minutes: 44640,
    monthly_window_required: true,
  },
  {
    key: '30 days',
    value: WindowStrideValue.DAYS_30,
    text: '30 days',
    minutes: 43200,
  },
  {
    key: '1 month',
    value: '1m',
    text: '1 month',
    minutes: 44640,
  },
  {
    key: '31 days',
    value: '31d',
    text: '31 days',
    minutes: 44640,
  },
  {
    key: '2 months',
    value: '2m',
    text: '2 months',
    minutes: 87840,
  },
  {
    key: '61 days',
    value: '61d',
    text: '61 days',
    minutes: 87840,
  },
  {
    key: '3 months',
    value: '3m',
    text: '3 months',
    minutes: 132480,
  },
  {
    key: '92 days',
    value: '92d',
    text: '92 days',
    minutes: WINDOW_STRIDE_92DAYS_IN_MIN,
  },
];
