// Models
import {
  PermissionsActionTypes,
  RetrievePermissionsPayload,
  PermissionSummary,
  EditPermissionsPayload,
  EditPermissionsResponse,
} from 'app/modules/permissions/models';

// Utils
import { createAction, ActionsUnion } from 'app/shared/utils/action-helpers';

export const retrievePermissions = (payload: RetrievePermissionsPayload) => {
  return createAction(PermissionsActionTypes.RETRIEVE_PERMISSIONS, payload);
};

export const retrievePermissionsSuccess = (payload: PermissionSummary[]) => {
  return createAction(
    PermissionsActionTypes.RETRIEVE_PERMISSIONS_SUCCESS,
    payload,
  );
};

export const retrieveUnassignedPermissions = (
  payload: RetrievePermissionsPayload,
) => {
  return createAction(
    PermissionsActionTypes.RETRIEVE_UNASSIGNED_PERMISSIONS,
    payload,
  );
};

export const retrieveUnassignedPermissionsSuccess = (
  payload: PermissionSummary[],
) => {
  return createAction(
    PermissionsActionTypes.RETRIEVE_UNASSIGNED_PERMISSIONS_SUCCESS,
    payload,
  );
};

export const editPermissions = (payload: EditPermissionsPayload) => {
  return createAction(PermissionsActionTypes.EDIT_PERMISSIONS, payload);
};

export const editPermissionsSuccess = (payload: EditPermissionsResponse) => {
  return createAction(PermissionsActionTypes.EDIT_PERMISSIONS_SUCCESS, payload);
};

// only needed for typing in the reducer
const ReduxActions = {
  retrievePermissionsSuccess,
  retrieveUnassignedPermissionsSuccess,
  editPermissionsSuccess,
};

export type PermissionsActions = ActionsUnion<typeof ReduxActions>;
