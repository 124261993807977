// Models
import { CustomChecklistActionTypes } from 'app/shared/customChecklist/models';

// Reducers
import { initialState } from 'app/shared/customChecklist/reducer';

export const selectCustomChecklistArchived = (state: RootState) => {
  return (
    state.customChecklist || {
      ...initialState,
    }
  ).archived;
};

export const selectCustomChecklistContent = (state: RootState) => {
  return (
    state.customChecklist || {
      ...initialState,
    }
  ).content;
};

export const selectCustomChecklistDefinition = (state: RootState) => {
  return (
    state.customChecklist || {
      ...initialState,
    }
  ).definition;
};

export const selectCustomChecklistId = (state: RootState) => {
  return (
    state.customChecklist || {
      ...initialState,
    }
  ).checklistId;
};

export const selectCustomChecklistModifiedAt = (state: RootState) => {
  return (
    state.customChecklist || {
      ...initialState,
    }
  ).modified_at;
};

export const selectCustomChecklistName = (state: RootState) => {
  return (
    state.customChecklist || {
      ...initialState,
    }
  ).name;
};

export const selectCustomChecklistHistory = (state: RootState) => {
  return (
    state.customChecklist || {
      ...initialState,
    }
  ).history;
};

export const selectCustomSubmissionId = (state: RootState) => {
  return (
    state.customChecklist || {
      ...initialState,
    }
  ).submissionId;
};

export const selectCustomChecklistValidations = (state: RootState) => {
  return (
    state.customChecklist || {
      ...initialState,
    }
  ).validations;
};

export const selectCustomChecklistValidated = (state: RootState) => {
  return (state.customChecklist || { ...initialState }).isValidated;
};

export const selectRetrieveCustomChecklistPending = (state: RootState) =>
  Boolean(state.loading[CustomChecklistActionTypes.RETRIEVE_CUSTOM_CHECKLIST]);

export const selectRetrieveCustomChecklistSubmissionPending = (
  state: RootState,
) =>
  Boolean(
    state.loading[
      CustomChecklistActionTypes.RETRIEVE_CUSTOM_CHECKLIST_SUBMISSION
    ],
  );
