import {
  Utils as QbUtils,
  JsonGroup,
  JsonRule,
} from 'react-awesome-query-builder';

export function getInitialTree(): JsonGroup {
  const treeId = QbUtils.uuid();
  const childId = QbUtils.uuid();

  return {
    id: treeId,
    type: 'group',
    properties: {
      conjunction: 'AND',
    },
    children1: {
      [childId]: {
        type: 'rule',
        properties: {
          field: null,
          operator: null,
          value: [],
          valueSrc: [],
          valueError: [],
          valueType: [],
        },
        path: [treeId, childId],
      } as JsonRule,
    },
  };
}
