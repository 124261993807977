// Models
import { PaginationPayload } from 'app/shared/pagination/models';

export const DEFAULT_PAGINATION_LIMIT = 25;

export const DEFAULT_SEARCH_PAGINATION_LIMIT = 15;

export const DEFAULT_MIN_PAGINATION_LIMIT = 10;

export const DEFAULT_OFFSET = 1;

export const DEFAULT_DEBOUNCE_INTERVAL = 500;

export const DEFAULT_PAGINATION_PAYLOAD: PaginationPayload = {
  offset: DEFAULT_OFFSET,
  limit: DEFAULT_PAGINATION_LIMIT,
};
