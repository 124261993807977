// Utils
import { post } from 'app/shared/utils/fetchr';

const API_BASE = '/agents';

export const sendAuthEmail = (payload: {
  email: string;
}): Promise<Record<string, never>> => {
  return post(`${API_BASE}/send-auth-email`, payload);
};
