// Utils
import { get, post, put, destroy } from 'app/shared/utils/fetchr';
import routes from 'app/shared/utils/routes';

// Actions
import {
  retrieveDashboard as retrieveDashboardAction,
  retrieveChart as retrieveChartAction,
  createDashboard as createDashboardAction,
  editDashboard as editDashboardAction,
  editDashboardChartsLayout as editDashboardChartsLayoutAction,
  editChart as editChartAction,
} from 'app/modules/insights/actions';
import { PaginationPayload } from 'app/shared/pagination/models';

export const retrieveChartDefinitions = (payload: PaginationPayload) => {
  return post(routes.patronus.insightsChartDefinitionsList, payload);
};

export const retrieveDashboards = () => {
  return get(routes.patronus.insightsDashboardsList);
};

export const retrieveDashboard = (
  id: ReturnType<typeof retrieveDashboardAction>['payload'],
) => {
  const path = routes.patronus.insightsDashboardsId.replace(':id', String(id));
  return get(path);
};

export const retrieveChart = (
  payload: ReturnType<typeof retrieveChartAction>['payload'],
) => {
  const { id, ...filters } = payload;
  const path = routes.patronus.insightsChartsId.replace(':id', String(id));
  return post(path, filters);
};

export const deleteDashboard = (
  id: ReturnType<typeof retrieveDashboardAction>['payload'],
) => {
  const path = routes.patronus.insightsDashboardsId.replace(':id', String(id));
  return destroy(path);
};

export const duplicateDashboard = (
  id: ReturnType<typeof retrieveDashboardAction>['payload'],
) => {
  const path = routes.patronus.insightsDashboardsDuplicateId.replace(
    ':id',
    String(id),
  );
  return put(path);
};

export const createDashboard = (
  payload: ReturnType<typeof createDashboardAction>['payload'],
) => {
  return post(routes.patronus.insightsDashboardsCreate, payload);
};

export const editDashboard = (
  payload: ReturnType<typeof editDashboardAction>['payload'],
) => {
  const { id, ...details } = payload;
  const path = routes.patronus.insightsDashboardsEditId.replace(
    ':id',
    String(id),
  );
  return put(path, details);
};

export const editChart = (
  payload: ReturnType<typeof editChartAction>['payload'],
) => {
  const { id, ...details } = payload;
  const path = routes.patronus.insightsChartsEditId.replace(':id', String(id));
  return put(path, details);
};

export const editDashboardChartsLayout = (
  payload: ReturnType<typeof editDashboardChartsLayoutAction>['payload'],
) => {
  const { id, ...details } = payload;
  const path = routes.patronus.insightsDashboardsEditId.replace(
    ':id',
    String(id),
  );
  return put(path, details);
};
