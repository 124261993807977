import {
  CustomConfigsMap,
  CustomTableConfig,
  TableConfigLabels,
  TableConfigType,
} from 'app/shared/CustomConfig/models';
import { ALERT_SCORE_COLUMN } from 'app/modules/alerts/columns';
import { FeatureFlag, FeatureFlags } from 'app/shared/featureFlags/models';
import {
  ASCENDANT_TXN_EVENT_TABLE_KEY_LABEL_MAP,
  UNIT21_KEY_TO_UNIT21_COLUMN_TYPE,
} from 'app/modules/tableSettings/constants';
import { UNSORTABLE_DATA_EXPLORER_COLUMNS } from 'app/modules/dataExplorer/constants';
import {
  DataSettingsKeyType,
  OrgDataSettingsConfig,
} from 'app/modules/dataSettings/responses';
import {
  getFormattedValue,
  keyPathToLabel,
} from 'app/modules/dataSettings/utils';
import {
  DATA_SETTING_FIELD_TYPE_TO_COLUMN_TYPE,
  NATIVE_DATA_SETTINGS_NATIVE_KEY_MISMATCH_MAP,
} from 'app/modules/dataSettings/constants';
import { U21TableBodyCellProps } from 'app/shared/u21-ui/components';
import { ShortTxnResponse } from 'app/modules/transactions/types';
import { ShortEntityResponse } from 'app/modules/entities/types';
import { ShortTxnInstrumentResponse } from 'app/modules/txnInstruments/types';
import { ShortActionEventResponse } from 'app/modules/actionEvents/responses';
import { DataSettingDataDisplay } from 'app/modules/dataSettings/shared/DataSettingDataDisplay';
import { TableConfig } from 'app/shared/pagination/models';

export const getTableConfigs = (
  configs: CustomConfigsMap,
  featureFlags: FeatureFlags,
) =>
  Object.keys(configs).reduce((acc, key) => {
    // Make sure the key exists in a map with all the table configs
    if (Object.prototype.hasOwnProperty.call(TableConfigLabels, key)) {
      if (
        key === TableConfigType.ALERT_TABLE &&
        !featureFlags[FeatureFlag.ALERT_SCORE]
      ) {
        acc[key] = {
          ...configs[key],
          definition: configs[key]?.definition?.filter(
            (config) => config?.key !== ALERT_SCORE_COLUMN.id,
          ),
        };
      } else {
        acc[key] = configs[key];
      }
    }
    return acc;
  }, {}) as Record<TableConfigType, CustomTableConfig>;

export const buildNonCustomDataTableColumn = (key: string, label: string) => {
  const type = UNIT21_KEY_TO_UNIT21_COLUMN_TYPE[key] || 'text';
  return {
    key,
    label,
    type,
    key_type: undefined,
    sortable: !UNSORTABLE_DATA_EXPLORER_COLUMNS.has(type),
    // data comes back nested for ascendant keys on transaction tables
    // which utilize the latest txn table formatter, so we need to provide
    // a custom accessor function, since some of these tables (at the time of writing this)
    // utilize the deprecated `U21PaginationTable` which doesn't support dot notation for nested keys
    ...(ASCENDANT_TXN_EVENT_TABLE_KEY_LABEL_MAP[key]
      ? {
          accessor: (row) => {
            return key.split('.').reduce((acc, path) => acc?.[path], row);
          },
        }
      : {}),
  };
};

export const buildDataSettingColumn = (
  dataSetting: OrgDataSettingsConfig,
): TableConfig => {
  const { id, data_type: dataType, key_type: keyType } = dataSetting;

  const key =
    keyType === DataSettingsKeyType.NATIVE
      ? (NATIVE_DATA_SETTINGS_NATIVE_KEY_MISMATCH_MAP[
          dataSetting.unit21_data_classifier
        ][dataSetting.native_key] ?? dataSetting.native_key)
      : `custom_data.${dataSetting.key_path.join('.')}`;

  const type =
    UNIT21_KEY_TO_UNIT21_COLUMN_TYPE[key] ||
    DATA_SETTING_FIELD_TYPE_TO_COLUMN_TYPE[dataType] ||
    'text';

  return {
    dataSettingId: id,
    key,
    // TODO: replace with DataSettingDataLabel component
    label: keyPathToLabel(dataSetting),
    type: type ?? 'text',
    sortable: !UNSORTABLE_DATA_EXPLORER_COLUMNS.has(key),
    accessor: ({ formatted_data: formattedData }) => {
      const formattedDataItem = formattedData[id];
      if (!formattedDataItem) {
        return null;
      }
      return getFormattedValue(formattedDataItem);
    },
    Cell: ({
      row: { formatted_data: formattedData },
    }: U21TableBodyCellProps<
      | ShortTxnResponse
      | ShortEntityResponse
      | ShortTxnInstrumentResponse
      | ShortActionEventResponse
    >) => {
      return (
        <DataSettingDataDisplay
          dataSetting={dataSetting}
          formattedData={formattedData}
          dataDisplayProps={{
            ENUM: { color: 'text.secondary' },
            NUMBER: { color: 'text.secondary' },
            DATE: { color: 'text.secondary' },
            DATE_TIME: { color: 'text.secondary' },
            TEXT: { color: 'text.secondary' },
            LINK: { color: 'text.secondary' },
            ANY: { color: 'text.secondary' },
          }}
        />
      );
    },
  };
};
