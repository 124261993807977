import {
  DataSettingsKeyType,
  OrgDataSettingsConfig,
  Unit21DataClassifier,
} from 'app/modules/dataSettings/responses';
import {
  RESERVED_GROUP_NAMES,
  SUMMARY_CONFIG_GROUP_ERRORS,
} from 'app/modules/summarySettings/constants';
import {
  SummarySettingsState,
  SummaryViewConfig,
  SummaryViewConfigFieldToGroupMap,
  SummaryViewConfigTypeClassifier,
} from 'app/modules/summarySettings/types';
import { U21SelectOptionProps } from 'app/shared/u21-ui/components';
import { WritableDraft } from 'immer/dist/internal';

export const configDataHelper = (
  draft: WritableDraft<SummarySettingsState>,
) => {
  const classifier = draft.selectedClassifier;
  const id = draft.selectedGroup;
  const currentConfig =
    draft.configs.status === 'COMPLETE' ? draft.configs[classifier] : [];
  return { classifier, id, currentConfig };
};

const sortFieldOption = (
  { text: one }: U21SelectOptionProps,
  { text: two }: U21SelectOptionProps,
) => {
  const [a, b] = [one.toString().toLowerCase(), two.toString().toLowerCase()];
  if (a === b) {
    return 0;
  }
  return a < b ? -1 : 1;
};

export const fieldsToSelectOptions = (
  groupFields: Set<number>,
  assignedFields: Set<number>,
  dataSettings: OrgDataSettingsConfig[],
  fieldToGroupMap: SummaryViewConfigFieldToGroupMap,
) =>
  dataSettings
    .reduce<U21SelectOptionProps[][]>(
      ([unassignedOptions, assignedOptions], ds) => {
        const { id } = ds;
        let color: U21SelectOptionProps['color'] = groupFields.has(id)
          ? undefined
          : 'info';
        let description = groupFields.has(id) ? undefined : 'Unassigned';
        const assignedElsewhere =
          !groupFields.has(ds.id) && assignedFields.has(ds.id);
        if (assignedElsewhere) {
          color = 'primary';
          description = fieldToGroupMap[id].join(', ');
        }
        const option = {
          value: id,
          text:
            ds.user_facing_label ||
            (ds.key_type === DataSettingsKeyType.CUSTOM
              ? ds.key_path.join('.')
              : ds.native_field),
          description,
          color,
        };
        if (assignedElsewhere) {
          assignedOptions.push(option);
        } else {
          unassignedOptions.push(option);
        }
        return [
          unassignedOptions.sort(sortFieldOption),
          assignedOptions.sort(sortFieldOption),
        ];
      },
      [[], []],
    )
    .flat();

export const getGroupErrors = (
  name: string,
  fields: number[],
  groups: Array<string>,
): Array<keyof typeof SUMMARY_CONFIG_GROUP_ERRORS> => {
  const errors: Array<keyof typeof SUMMARY_CONFIG_GROUP_ERRORS> = [];
  if (!name) {
    errors.push('noName');
  } else if (RESERVED_GROUP_NAMES.has(name.toLowerCase())) {
    errors.push('reservedName');
  } else if (groups.filter((g) => g === name).length > 1) {
    errors.push('duplicateName');
  }
  if (!fields.length) {
    errors.push('noFields');
  }
  return errors;
};

export const concatenateGroupErrors = (
  errors: Array<keyof typeof SUMMARY_CONFIG_GROUP_ERRORS>,
) => errors.map((e) => SUMMARY_CONFIG_GROUP_ERRORS[e]).join(` `);

export const getAllGroupErrors = (
  config?: SummaryViewConfig,
): Array<keyof typeof SUMMARY_CONFIG_GROUP_ERRORS> => {
  if (!config) return [];
  const groupNames = config.map(({ group }) => group);
  return [
    ...new Set(
      config.reduce<Array<keyof typeof SUMMARY_CONFIG_GROUP_ERRORS>>(
        (acc, { group, fields }) => [
          ...acc,
          ...getGroupErrors(group, fields, groupNames),
        ],
        [],
      ),
    ),
  ];
};

export const dataSettingsToIdMap = (
  dataSettingsByClassifier: Record<
    Unit21DataClassifier,
    OrgDataSettingsConfig[]
  >,
  classifier: SummaryViewConfigTypeClassifier,
) => {
  const dataSettings = dataSettingsByClassifier[classifier];
  return dataSettings.reduce<Record<number, OrgDataSettingsConfig>>(
    (acc, ds) => {
      acc[ds.id] = ds;
      return acc;
    },
    {},
  );
};

export const getUnassignedFieldsAsSet = (config?: SummaryViewConfig) =>
  new Set(config?.flatMap((g) => g.fields) ?? []);
