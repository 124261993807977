import { useQuery } from '@tanstack/react-query';
import { get } from 'app/shared/utils/fetchr';

import { RetrieveQueuesListResponse } from 'app/modules/queues/responses';
import { QueueType } from 'app/modules/queues/models';
import { QUEUES_QUERY_KEYS } from 'app/modules/queues/queries/keys';
import { useSelector } from 'react-redux';
import { selectHasReadQueuePermission } from 'app/modules/queues/selectors';

export const getAllQueuesApi = (
  queueType: QueueType,
): Promise<RetrieveQueuesListResponse> => {
  return get('/queues', [{ key: 'type', value: queueType }]);
};

export const useGetAllQueues = <T = RetrieveQueuesListResponse>(
  queueType: QueueType,
  options?: { select?: (data: RetrieveQueuesListResponse) => T },
) => {
  const hasReadQueuePermission = useSelector((state: RootState) =>
    selectHasReadQueuePermission(state, queueType),
  );

  return useQuery({
    queryKey: QUEUES_QUERY_KEYS.getAllQueues(queueType),
    queryFn: () => getAllQueuesApi(queueType),
    enabled: hasReadQueuePermission,
    initialData: { queues: [], count: 0 },
    meta: { errorMessage: 'Unable to retrieve queues. Please try again' },
    ...options,
  });
};
