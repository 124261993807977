import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { GoAMLActionTypes } from 'app/modules/goAML/models';

// Actions
import {
  retrieveGoAMLFiling as retrieveGoAMLFilingAction,
  retrieveGoAMLFilingSuccess,
} from 'app/modules/goAML/actions';

// API
import { retrieveGoAMLFiling as retrieveGoAMLFilingAPI } from 'app/shared/api/goAML';

const rootAction = GoAMLActionTypes.RETRIEVE_GOAML_FILING;
export function* retrieveGoAMLFiling({
  payload,
}: ReturnType<typeof retrieveGoAMLFilingAction>) {
  const config = {
    rootAction,
    errorToastMessage: 'Something went wrong, unable to retrieve goAML report.',
    request: call(retrieveGoAMLFilingAPI, payload),
    success: function* onSuccess(
      result: ReturnType<typeof retrieveGoAMLFilingSuccess>['payload'],
    ) {
      yield put(retrieveGoAMLFilingSuccess(result));
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watch() {
  yield takeLatest(rootAction, retrieveGoAMLFiling);
}
