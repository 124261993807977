import React, { useState, useEffect, useCallback } from 'react';
import { Select } from 'semantic-ui-react';
import styles from 'app/shared/styles/TimeDeltaSelect.module.scss';

enum TimeElements {
  YEAR,
  MONTH,
  DAY,
}

const TIME_UNITS_CONVERSIONS: {
  [time in TimeElements]: {
    conversionToMS: number;
    label: string;
    options: { text: string; value: number }[];
  };
} = {
  [TimeElements.YEAR]: {
    conversionToMS: 365 * 24 * 60 * 60 * 1000,
    label: 'Years',
    options: Array(20)
      .fill(null)
      .map((_, i) => ({ text: `${i}`, value: i })),
  },
  [TimeElements.MONTH]: {
    conversionToMS: 30 * 24 * 60 * 60 * 1000,
    label: 'Months',
    options: Array(11)
      .fill(null)
      .map((_, i) => ({ text: `${i}`, value: i })),
  },
  [TimeElements.DAY]: {
    conversionToMS: 24 * 60 * 60 * 1000,
    label: 'Days',
    options: Array(29)
      .fill(null)
      .map((_, i) => ({ text: `${i}`, value: i })),
  },
};

const TimeDeltaSelect: React.FC<{
  value: number;
  onChange: (value: number) => void;
  label?: string;
}> = ({ value, onChange, label }) => {
  const [localValues, setLocalValues] = useState({ year: 0, month: 0, day: 0 });

  useEffect(() => {
    let total = value;
    const newValues = { year: 0, month: 0, day: 0 };
    Object.keys(TIME_UNITS_CONVERSIONS).forEach((key) => {
      const count = Math.floor(
        total / TIME_UNITS_CONVERSIONS[key].conversionToMS,
      );
      total -= TIME_UNITS_CONVERSIONS[key].conversionToMS * count;
      newValues[key] = count;
    });
    setLocalValues(newValues);
  }, [value]);

  const updateTotal = useCallback(
    (newState) => {
      let total = 0;
      Object.keys(TIME_UNITS_CONVERSIONS).forEach((key) => {
        total += newState[key] * TIME_UNITS_CONVERSIONS[key].conversionToMS;
      });
      onChange(total);
    },
    [onChange],
  );

  return (
    <div>
      <div className={styles.timeSelectLabel}>{label}</div>
      {Object.keys(TIME_UNITS_CONVERSIONS).map((key) => {
        const config = TIME_UNITS_CONVERSIONS[key];
        return (
          <div key={config.label} className={styles.timeSelectWrapper}>
            <div>{config.label}</div>
            <Select
              value={localValues[key]}
              type="number"
              onChange={(_, { value: newValue }) => {
                if (newValue !== undefined) {
                  updateTotal({ ...localValues, [key]: +newValue });
                }
              }}
              options={config.options}
              className={styles.timeSelect}
            />
          </div>
        );
      })}
    </div>
  );
};

export default TimeDeltaSelect;
