// Temporary component to evaluate performance
import React, { useRef } from 'react';
import styled from 'styled-components';

export const RenderCount = () => {
  const renders = useRef(0);

  renders.current += 1;

  return <Circle>{renders.current}</Circle>;
};

const size = 30;
const Circle = styled.i`
  font-style: normal;
  text-align: center;
  height: ${size}px;
  width: ${size}px;
  line-height: ${size}px;
  border-radius: ${size / 2}px;
  border: 1px solid #ddd;
  background: #eee;
`;
