import { TableInstance } from 'react-table';
import {
  U21TableColumn,
  U21TableColumnTypes,
  U21TableColumnTypesType,
  U21TableProps,
} from 'app/shared/u21-ui/components/display/table/models';

export const getColumnTypeDefaults = <T extends object, V = any>(
  type?: U21TableColumnTypesType,
): Partial<U21TableColumn<T, V>> => {
  switch (type) {
    case U21TableColumnTypes.ACTIONS:
    case U21TableColumnTypes.BUTTON: {
      return {
        disableResizing: true,
        disableSortBy: false,
        maxWidth: 54,
        minWidth: 54,
        noDivider: true,
        width: 54,
      };
    }
    default:
      return {};
  }
};

// Sort ascending by default
export const getDefaultSortBy = (
  colId: string,
): {
  desc: boolean;
  id: string;
} => ({
  desc: false,
  id: colId,
});

export const getSelectableRows = <T extends object>(
  rows: TableInstance<T>['rows'],
  selected: U21TableProps<T>['selected'],
  disabled: U21TableProps<T>['disabled'],
): TableInstance<T>['rows'] => {
  // nothing is selectable if everything is disabled
  if (disabled === true) {
    return [];
  }

  // everything is selectable if not disabled
  if (!disabled) {
    return rows;
  }
  const disabledSet = new Set(disabled);
  const selectedSet = new Set(selected);

  // selectable if not disabled or disabled and selected
  return rows.filter(
    (i) =>
      !disabledSet.has(i.id) ||
      (selectedSet.has(i.id) && disabledSet.has(i.id)),
  );
};

export const getDisabledSelectedRows = <T extends object>(
  rows: TableInstance<T>['rows'],
  selected: U21TableProps<T>['selected'],
  disabled: U21TableProps<T>['disabled'],
) => {
  const selectedSet = new Set(selected);

  // everything is disabled so return all the selected
  if (disabled === true) {
    return rows.filter((i) => selectedSet.has(i.id));
  }

  // everything is enabled so return nothing
  if (!disabled) {
    return [];
  }

  // find the intersection between selected and disabled
  const disabledSet = new Set(disabled);
  return rows.filter((i) => disabledSet.has(i.id) && selectedSet.has(i.id));
};

export const lengthSorter = (a?: number, b?: number): -1 | 0 | 1 => {
  if (a === b) {
    return 0;
  }
  return (a ?? -Infinity) > (b ?? -Infinity) ? 1 : -1;
};

export const boolSorter = (a?: number, b?: number): -1 | 0 | 1 => {
  if (a && !b) {
    return -1;
  }
  if (!a && b) {
    return 1;
  }
  return 0;
};

export const alphanumericSorter = (
  a?: string | number | null,
  b?: string | number | null,
): -1 | 0 | 1 => {
  const aValue = String(a ?? '');
  const bValue = String(b ?? '');
  if (aValue === bValue) {
    return 0;
  }
  if (aValue.toLowerCase() < bValue.toLowerCase()) {
    return -1;
  }
  if (aValue.toLowerCase() > bValue.toLowerCase()) {
    return 1;
  }
  return aValue > bValue ? 1 : -1;
};

export const datetimeSorter = (a: string = '', b: string = ''): -1 | 0 | 1 => {
  const aDate = new Date(a).getTime();
  const bDate = new Date(b).getTime();
  if (isNaN(aDate) && isNaN(bDate)) {
    return 0;
  }
  if (isNaN(aDate)) {
    return 1;
  }
  if (isNaN(bDate)) {
    return -1;
  }
  if (aDate > bDate) {
    return 1;
  }
  if (aDate < bDate) {
    return -1;
  }
  return 0;
};

export const numericSorter = (
  a?: number | null,
  b?: number | null,
): -1 | 0 | 1 => {
  const aValue = a ?? Number.NEGATIVE_INFINITY;
  const bValue = b ?? Number.NEGATIVE_INFINITY;

  if (aValue === bValue) {
    return 0;
  }

  return aValue > bValue ? 1 : -1;
};
