import { FINCEN_CTR_SLICE_NAME } from 'app/modules/fincenCtr/sliceFincenCtr';
import { createSelector } from 'reselect';

// Models
import {
  Activity,
  ValidationErrors,
  FincenCtrTablePages,
} from 'app/modules/fincenCtr/models';
import { CtrFilingStatus } from 'app/modules/fincenCtr/enums';
import { GetCtrsPayload } from 'app/modules/fincenCtr/requests';

// Selectors
import {
  selectAgent,
  selectHasEditSARsPermission,
} from 'app/modules/session/selectors';

// Helpers
import { formatBEErrors } from 'app/modules/fincenCtr/helpers';
import { getDefaultDate } from 'app/modules/fincenCtr/schemas/parties/shared';

export const selectFilingFormData = (state: RootState): Partial<Activity> => {
  return state[FINCEN_CTR_SLICE_NAME].filing.content.form || {};
};

export const selectFormRawErrors = (state: RootState) => {
  return state[FINCEN_CTR_SLICE_NAME].filing.content.errors || [];
};

export const selectTransactionLocations = (state: RootState) =>
  state[FINCEN_CTR_SLICE_NAME].transactionLocations;

export const selectFiling = (state: RootState) => {
  return state[FINCEN_CTR_SLICE_NAME].filing;
};

export const selectFilingIsLocked = createSelector(
  selectFiling,
  (filing): boolean => filing.lock,
);

export const selectFilingIsSubmitted = createSelector(
  selectFiling,
  (filing): boolean =>
    filing.status === CtrFilingStatus.ACCEPTED_BY_FINCEN ||
    filing.status === CtrFilingStatus.REJECTED_BY_FINCEN ||
    filing.status === CtrFilingStatus.ACCEPTED_WITH_WARNINGS,
);

export const selectFormIsDisabled = createSelector(
  selectHasEditSARsPermission,
  selectFilingIsLocked,
  (hasEditPermission, filingIsLocked) => !hasEditPermission || filingIsLocked,
);

export const selectFilingTransactionsDate = createSelector(
  selectFilingFormData,
  (formData): Date | null =>
    getDefaultDate(
      formData.currencyTransactionActivity?.transactionDateText ?? '',
    ),
);

export const selectFilingPageListFilters = (
  pageType: FincenCtrTablePages,
): ((RootState) => Partial<GetCtrsPayload['filters']>) => {
  // TODO update the selectors once we allow agents to toggle filters per page
  switch (pageType) {
    case FincenCtrTablePages.MY_FILINGS:
      return selectMyFilingListFilters;
    case FincenCtrTablePages.QUEUED_FILINGS:
      return selectQueuedFilingListFilters;
    default:
      return selectAdminFilingListFilters;
  }
};

const selectMyFilingListFilters = createSelector(
  selectAgent,
  ({ id }): Partial<GetCtrsPayload['filters']> => ({ reviewer_ids: [id] }),
);

const selectQueuedFilingListFilters = createSelector(
  selectAgent,
  (agent): Partial<GetCtrsPayload['filters']> => ({
    queue_ids: agent.accessible_queues,
  }),
);

const selectAdminFilingListFilters = () => ({
  // TODO: add filters
});

// BE validation errors
export const selectValidationErrors = createSelector(
  selectFormRawErrors,
  (rawErrors): ValidationErrors => formatBEErrors(rawErrors),
);

export const hasBEValidationErrors = createSelector(
  selectFormRawErrors,
  (rawErrors): boolean => rawErrors.length > 0,
);

// Loading
export const selectLoadingUpdateFiling = (state: RootState) =>
  state[FINCEN_CTR_SLICE_NAME].loadingUpdateFiling;

export const selectLoadingCreateFiling = (state: RootState) =>
  state[FINCEN_CTR_SLICE_NAME].loadingCreateFiling;

export const selectLoadingRetrieveFiling = (state: RootState) =>
  state[FINCEN_CTR_SLICE_NAME].loadingFiling;

export const selectLoadingLockFiling = (state: RootState) =>
  state[FINCEN_CTR_SLICE_NAME].loadingLockFiling;

export const selectLoadingUnlockFiling = (state: RootState) =>
  state[FINCEN_CTR_SLICE_NAME].loadingUnlockFiling;

export const selectLoadingUploadAttachments = (state: RootState) =>
  state[FINCEN_CTR_SLICE_NAME].loadingUploadAttachments;
