// Redux
import { Reducer } from 'redux';
import produce, { Draft } from 'immer';

// Models
import { ToastActionTypes, ToastState } from 'app/shared/toasts/models';

// Actions;
import { ToastsActions } from 'app/shared/toasts/actions';

// Utils
import deepFreeze from 'app/shared/utils/deepFreeze';

const initialState: ToastState = {
  messages: [],
};
// to ensure initialState is readonly
deepFreeze(initialState);

const reducer: Reducer<ToastState> = (
  state = initialState,
  action: ToastsActions,
) => {
  return produce(state, (draft: Draft<ToastState>) => {
    switch (action.type) {
      case ToastActionTypes.SEND_TOAST: {
        const messageExists = draft.messages.some(
          (toastItem) => toastItem.message === action.payload.message,
        );

        if (!messageExists) {
          draft.messages = [...draft.messages, action.payload];
        }
        return;
      }

      case ToastActionTypes.CLEAR_TOAST:
        draft.messages = draft.messages.filter((toastItem) => {
          return toastItem.message !== action.payload;
        });
        return;

      default:
        return;
    }
  });
};

export { reducer as toastsReducer, initialState };
