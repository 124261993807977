import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { WebhookActionTypes } from 'app/modules/webhooks/models';

// Actions
import {
  editWebhook as editWebhookAction,
  editWebhookSuccess,
  listWebhooks,
} from 'app/modules/webhooks/actions';
import { sendSuccessToast } from 'app/shared/toasts/actions';

// API
import { changeWebhook } from 'app/shared/api/webhooks';

const rootAction = WebhookActionTypes.EDIT_WEBHOOK;
export function* editWebhook({
  payload,
}: ReturnType<typeof editWebhookAction>) {
  const config = {
    rootAction,
    request: call(changeWebhook, payload),
    success: function* onSuccess(
      result: ReturnType<typeof editWebhookSuccess>['payload'],
    ) {
      yield put(editWebhookSuccess(result));
      yield put(sendSuccessToast('Successfully edited webhook.'));
      yield put(listWebhooks());
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watchEditWebhook() {
  yield takeLatest(rootAction, editWebhook);
}
