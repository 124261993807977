import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Actions
import {
  editGoAMLTemplates as editGoAMLTemplatesAction,
  editGoAMLTemplatesSuccess,
} from 'app/modules/settings/actions';

// API
import { editGoAMLTemplates as editGoAMLTemplatesAPI } from 'app/shared/api/settings';

// Models
import {
  RetrieveGoAMLTemplatesResult,
  SettingsActionTypes,
} from 'app/modules/settings/models';

const rootAction = SettingsActionTypes.EDIT_GO_AML_TEMPLATES;
export function* editGoAMLTemplates({
  payload,
}: ReturnType<typeof editGoAMLTemplatesAction>) {
  const config = {
    rootAction,
    request: call(editGoAMLTemplatesAPI, payload),
    successToastMessage: 'Successfully updated template',
    success: function* onSuccess(result: RetrieveGoAMLTemplatesResult) {
      yield put(editGoAMLTemplatesSuccess(result));
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watchEditGoAMLTemplates() {
  yield takeLatest(rootAction, editGoAMLTemplates);
}
