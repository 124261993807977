import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------
// CUSTOM CHANGES
// - updated font weight to regular

export default function Badge(theme: Theme) {
  return {
    MuiBadge: {
      styleOverrides: {
        badge: {
          fontWeight: theme.typography.fontWeightRegular,
        },
        dot: {
          width: 10,
          height: 10,
          borderRadius: '50%',
        },
      },
    },
  };
}
