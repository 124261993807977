import { u21CreateSlice } from 'app/shared/thunk/u21CreateSlice';

// Models
import { Filter } from 'app/modules/filters/models';

import { FILING_FILTER_OPTIONS_BY_PAGE } from 'app/modules/fincenSarNew/filters';
import { FincenSarTablePages } from 'app/modules/fincenSar/models';
import { LocalStorageKeys } from 'app/shared/constants/localStorage';

// Utils
import { getLocalStorageJSON } from 'app/shared/utils/localStorage';
import { getValidFilters } from 'app/modules/filters/utils';
import { u21CreateAsyncThunk } from 'app/shared/thunk/u21CreateAsyncThunk';

const FILINGS_NAME = 'filings';

interface FilingsState {
  adminFilingFilters: Filter[];
  myFilingFilters: Filter[];
  queuedFilingFilters: Filter[];
}

export const filingsInitialState: Readonly<FilingsState> = {
  adminFilingFilters: getValidFilters(
    getLocalStorageJSON(LocalStorageKeys.ADMIN_FILING_FILTERS),
    FILING_FILTER_OPTIONS_BY_PAGE[FincenSarTablePages.REPORT_FILING],
  ),
  myFilingFilters: getValidFilters(
    getLocalStorageJSON(LocalStorageKeys.MY_FILING_FILTERS),
    FILING_FILTER_OPTIONS_BY_PAGE[FincenSarTablePages.MY_FILINGS],
  ),
  queuedFilingFilters: getValidFilters(
    getLocalStorageJSON(LocalStorageKeys.QUEUED_FILING_FILTERS),
    FILING_FILTER_OPTIONS_BY_PAGE[FincenSarTablePages.QUEUED_FILINGS],
  ),
};

export const setAdminFilingFilters = u21CreateAsyncThunk<Filter[], Filter[]>(
  `${FILINGS_NAME}/SET_ADMIN_FILING_FILTERS`,
  (payload) => {
    localStorage.setItem(
      LocalStorageKeys.ADMIN_FILING_FILTERS,
      JSON.stringify(payload),
    );
    return payload;
  },
);

export const setMyFilingFilters = u21CreateAsyncThunk<Filter[], Filter[]>(
  `${FILINGS_NAME}/SET_MY_FILING_FILTERS`,
  (payload) => {
    localStorage.setItem(
      LocalStorageKeys.MY_FILING_FILTERS,
      JSON.stringify(payload),
    );
    return payload;
  },
);

export const setQueuedFilingFilters = u21CreateAsyncThunk<Filter[], Filter[]>(
  `${FILINGS_NAME}/SET_QUEUED_FILING_FILTERS`,
  (payload) => {
    localStorage.setItem(
      LocalStorageKeys.QUEUED_FILING_FILTERS,
      JSON.stringify(payload),
    );
    return payload;
  },
);

const filingsSlice = u21CreateSlice({
  initialState: filingsInitialState,
  name: FILINGS_NAME,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setAdminFilingFilters.fulfilled, (draft, action) => {
        draft.adminFilingFilters = action.payload;
      })
      .addCase(setMyFilingFilters.fulfilled, (draft, action) => {
        draft.myFilingFilters = action.payload;
      })
      .addCase(setQueuedFilingFilters.fulfilled, (draft, action) => {
        draft.queuedFilingFilters = action.payload;
      });
  },
});

export const FILINGS_SLICE_NAME = filingsSlice.name;
export default filingsSlice.reducer;
