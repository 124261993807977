// Models
import { AnyObjectType, PaginationPayload } from 'app/shared/models';
import {
  FullTeamResponse,
  ShortTeamResponse,
} from 'app/modules/teamsOld/types';
import { FullTagResponse } from 'app/modules/tags/responses';
import { Auth0Role } from 'app/shared/types/auth0';

export enum TeamActionTypes {
  RETRIEVE_API_CONFIGS = '@@team/RETRIEVE_API_CONFIGS',
  RETRIEVE_API_CONFIGS_SUCCESS = '@@team/RETRIEVE_API_CONFIGS_SUCCESS',

  RETRIEVE_ALL_TAGS = '@@team/RETRIEVE_ALL_TAGS',
  RETRIEVE_ALL_TAGS_SUCCESS = '@@team/RETRIEVE_ALL_TAGS_SUCCESS',

  ADD_TAG = '@@team/ADD_TAG',
  ADD_TAG_SUCCESS = '@@team/ADD_TAG_SUCCESS',
  CLEAR_ADDED_TAG = '@@team/CLEAR_ADDED_TAG',

  TAG_LIMIT_REACHED = '@@teams/TAG_LIMIT_REACHED',
  CLEAR_TAG_LIMIT_REACHED = '@@teams/CLEAR_TAG_LIMIT_REACHED',
}

export interface AddTagPayload {
  tag: string;
  agentId?: number;
}

export interface TagsById {
  [id: string]: Tag;
}

export type Tag = FullTagResponse;

export interface Org {
  id: number;
  created_at: string;
  name: string;
  default_unit: string;
}

export interface TeamInfo {
  id: number;
  name: string;
  description: string;
}

export interface TeamState {
  tags: TagsById;
  addedTags: TagsById;
  api_config: ApiConfig;
  tagLimitReached?: boolean;
}

export interface ApiConfig {
  entity_page?: AnyObjectType;
  link_analysis?: Array<AnyObjectType>;
  allowed_endpoints?: string[];
  auto_assign_alerts?: boolean;
  agent_self_signup_allowed: boolean;
  self_signup_approval_required: boolean;
  allow_adding_agents: boolean;
}

export interface TeamSummary extends ShortTeamResponse {}

export interface TeamDetail extends FullTeamResponse {
  roles: Auth0Role[];
  alert_assignment_tags: Tag[];
  allowed_tags: Tag[];
}

export interface TeamPermissions {
  allow_listing_unassigned_alerts: boolean;
  allow_viewing_unassigned_alerts: boolean;
  allow_viewing_entity_page_of_unassigned_alerts: boolean;
  allow_self_assigning_alerts: boolean;
  allow_assign_others_alerts: boolean;
  allow_listing_unassigned_entities: boolean;
  alert_assignment_limit: number;
  allow_tag_create: boolean;
  allow_tag_edit: boolean;
  allow_rule_deployment: boolean;
}

export type TeamFeatureCheckboxType =
  | 'allow_listing_unassigned_alerts'
  | 'allow_viewing_unassigned_alerts'
  | 'allow_viewing_entity_page_of_unassigned_alerts'
  | 'allow_self_assigning_alerts'
  | 'allow_assign_others_alerts'
  | 'allow_listing_unassigned_entities'
  | 'allow_tag_create'
  | 'allow_tag_edit'
  | 'allow_rule_deployment'
  | 'self_signup_enabled';

export type TeamFeatureNumberType = 'alert_assignment_limit';

export interface PaginatedTeamsPayload extends PaginationPayload {
  query?: string;
  ids?: number[];
}

export interface PaginatedTeamsResponse {
  count: number;
  teams: TeamSummary[];
}
