import { call, put, takeLatest } from 'redux-saga/effects';

// Actions
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';
import {
  retrieveEntitiesFromGlobalWhitelist as retrieveEntitiesFromGlobalWhitelistAction,
  retrieveEntitiesFromGlobalWhitelistSuccess,
} from 'app/modules/rules/actions';

// API
import { retrieveEntitiesFromGlobalWhitelist as retrieveEntitiesFromGlobalWhitelistAPI } from 'app/shared/api/entities';

// Models
import { RulesActionTypes } from 'app/modules/rules/models';

const rootAction = RulesActionTypes.RETRIEVE_GLOBALWHITELIST_ENTITIES;
function* retrieveEntitiesFromGlobalWhitelist({
  payload,
}: ReturnType<typeof retrieveEntitiesFromGlobalWhitelistAction>) {
  const config = {
    rootAction,
    request: call(retrieveEntitiesFromGlobalWhitelistAPI, payload),
    success: function* onSuccess(result) {
      yield put(retrieveEntitiesFromGlobalWhitelistSuccess(result));
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watchRetrieveWhitelistedEntities() {
  yield takeLatest(rootAction, retrieveEntitiesFromGlobalWhitelist);
}
