import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { WebhookActionTypes } from 'app/modules/webhooks/models';

// Actions
import {
  getWebhookCallbacks as getWebhookCallbacksAction,
  getWebhookCallbacksSuccess,
} from 'app/modules/webhooks/actions';

// API
import { listWebhookCallbacks } from 'app/shared/api/webhooks';

const rootAction = WebhookActionTypes.GET_WEBHOOK_CALLBACKS;
function* getWebhookCallbacks({
  payload,
}: ReturnType<typeof getWebhookCallbacksAction>) {
  const { id, ...reqBody } = payload;
  const config = {
    rootAction,
    request: call(listWebhookCallbacks, id, reqBody),
    success: function* onSuccess(
      result: ReturnType<typeof getWebhookCallbacksSuccess>['payload'],
    ) {
      yield put(getWebhookCallbacksSuccess(result));
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watchGetWebhookCallbacks() {
  yield takeLatest(rootAction, getWebhookCallbacks);
}
