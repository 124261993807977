import { DISPOSITIONS_SLICE_NAME } from 'app/modules/dispositions/sliceDisposition';

export const selectCreateDispositionLoading = (state: RootState) =>
  state[DISPOSITIONS_SLICE_NAME].loadingCreateDisposition;

export const selectRetrieveDispositionsLoading = (state: RootState) =>
  state[DISPOSITIONS_SLICE_NAME].loadingRetrieveDispositions;

export const selectUpdateDispositionLoading = (state: RootState) =>
  state[DISPOSITIONS_SLICE_NAME].loadingUpdateDisposition;

export const selectDispositions = (state: RootState) =>
  state[DISPOSITIONS_SLICE_NAME].dispositions;

export const selectDispositionsCount = (state: RootState) =>
  state[DISPOSITIONS_SLICE_NAME].dispositionsCount;
