import { createRoot } from 'react-dom/client';

import { IconCircleX } from '@u21/tabler-icons';
import { U21Modal } from 'app/shared/u21-ui/components/layout/modal/U21Modal';
import { U21Typography } from 'app/shared/u21-ui/components/display/typography/U21Typography';
import { U21Theme } from 'vendor/material-minimal';

export const U21SavingPrompt = (
  message: string,
  callback: (transition: boolean) => void,
) => {
  const container = document.createElement('div');
  document.body.appendChild(container);
  const root = createRoot(container);

  const closeModal = (callBackState) => {
    root.unmount();
    callback(callBackState);
  };

  root.render(
    <U21Theme>
      <U21Modal
        actionButtonProps={{
          children: 'Leave',
          color: 'warning',
        }}
        closeButtonProps={{
          children: 'Stay on this page',
        }}
        open
        onAction={() => closeModal(true)}
        onClose={() => closeModal(false)}
        title={message}
        titleIcon={<IconCircleX />}
      >
        <U21Typography variant="body2">
          Leaving the page will result in losing progress.
        </U21Typography>
      </U21Modal>
    </U21Theme>,
  );
};
