import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import {
  RetrieveGoAMLTemplatesResult,
  SettingsActionTypes,
} from 'app/modules/settings/models';

// Actions
import { retrieveGoAMLTemplatesSuccess } from 'app/modules/settings/actions';

// API
import { getGoAMLTemplates as retrieveGoAMLTemplatesAPI } from 'app/shared/api/settings';

const rootAction = SettingsActionTypes.RETRIEVE_GO_AML_TEMPLATES;
function* retrieveGoAMLTemplates() {
  const config = {
    rootAction,
    request: call(retrieveGoAMLTemplatesAPI),
    success: function* onSuccess(result: RetrieveGoAMLTemplatesResult[]) {
      yield put(retrieveGoAMLTemplatesSuccess(result));
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watchRetrieveGoAMLTemplates() {
  yield takeLatest(
    SettingsActionTypes.RETRIEVE_GO_AML_TEMPLATES,
    retrieveGoAMLTemplates,
  );
}
