import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { RulesActionTypes } from 'app/modules/rules/models';

// Actions
import {
  retrieveValidationRules as retrieveValidationRulesAction,
  retrieveValidationRulesSuccess,
} from 'app/modules/rules/actions';

// API
import { retrieveValidationRules as retrieveValidationRulesApi } from 'app/shared/api/rules';

const rootAction = RulesActionTypes.RETRIEVE_VALIDATION_RULES;
function* retrieveValidationRules({
  payload,
}: ReturnType<typeof retrieveValidationRulesAction>) {
  const config = {
    rootAction,
    request: call(retrieveValidationRulesApi, payload),
    success: function* onSuccess(
      result: ReturnType<typeof retrieveValidationRulesSuccess>['payload'],
    ) {
      yield put(retrieveValidationRulesSuccess(result));
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watchRetrieveValidationRules() {
  yield takeLatest(rootAction, retrieveValidationRules);
}
