import { TTo, TToMyClient } from 'app/modules/goAML/models';

export const EMPTY_TO: TTo = {
  to_funds_code: '',
  // to_funds_comment?: string;
  // to_foreign_currency?: ForeignCurrency;
  to_country: '',
};

export const EMPTY_TO_MY_CLIENT: TToMyClient = {
  to_funds_code: '',
  // to_funds_comment?: string;
  // to_foreign_currency?: ForeignCurrency;
  to_country: '',
};
