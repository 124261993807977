import { createAction, ActionsUnion } from 'app/shared/utils/action-helpers';
import {
  WebhookActionTypes,
  EditWebhookRequest,
  WebhookCallbackRequest,
  Webhook,
  WebhookCallbackResponse,
} from 'app/modules/webhooks/models';

export const listWebhooks = () =>
  createAction(WebhookActionTypes.LIST_WEBHOOKS);

export const listWebhooksSuccess = (result: Webhook[]) =>
  createAction(WebhookActionTypes.LIST_WEBHOOKS_SUCCESS, result);

export const getWebhook = (payload: string) =>
  createAction(WebhookActionTypes.GET_WEBHOOK, payload);

export const getWebhookSuccess = (result: Webhook) =>
  createAction(WebhookActionTypes.GET_WEBHOOK_SUCCESS, result);

export const createWebhook = (details) =>
  createAction(WebhookActionTypes.CREATE_WEBHOOK, details);

export const createWebhookSuccess = () =>
  createAction(WebhookActionTypes.CREATE_WEBHOOK_SUCCESS);

export const deleteWebhook = (payload: string) =>
  createAction(WebhookActionTypes.DELETE_WEBHOOK, payload);

export const deleteWebhookSuccess = () =>
  createAction(WebhookActionTypes.DELETE_WEBHOOK_SUCCESS);

export const editWebhook = (payload: EditWebhookRequest) =>
  createAction(WebhookActionTypes.EDIT_WEBHOOK, payload);

export const editWebhookSuccess = (result: Webhook) =>
  createAction(WebhookActionTypes.EDIT_WEBHOOK_SUCCESS, result);

export const retrieveActionTypes = () =>
  createAction(WebhookActionTypes.RETRIEVE_ACTION_TYPE);

export const retrieveActionTypesSuccess = (result: string[]) =>
  createAction(WebhookActionTypes.RETRIEVE_ACTION_TYPE_SUCCESS, result);

export const rotateWebhookKey = (payload: string) =>
  createAction(WebhookActionTypes.ROTATE_WEBHOOK_KEY, payload);

export const rotateWebhookKeySuccess = (result: Webhook) =>
  createAction(WebhookActionTypes.ROTATE_WEBHOOK_KEY_SUCCESS, result);

export const getWebhookCallbacks = (payload: WebhookCallbackRequest) =>
  createAction(WebhookActionTypes.GET_WEBHOOK_CALLBACKS, payload);

export const getWebhookCallbacksSuccess = (result: WebhookCallbackResponse) =>
  createAction(WebhookActionTypes.GET_WEBHOOK_CALLBACKS_SUCCESS, result);

export const testWebhook = (id: number) =>
  createAction(WebhookActionTypes.TEST_WEBHOOK, id);

export const testWebhookSuccess = (result: WebhookCallbackResponse) =>
  createAction(WebhookActionTypes.TEST_WEBHOOK_SUCCESS, result);

const WebhookReduxActionsList = {
  listWebhooksSuccess,
  createWebhookSuccess,
  getWebhookSuccess,
  deleteWebhookSuccess,
  editWebhookSuccess,
  retrieveActionTypesSuccess,
  rotateWebhookKey,
  rotateWebhookKeySuccess,
  getWebhookCallbacks,
  getWebhookCallbacksSuccess,
  testWebhook,
  testWebhookSuccess,
};

type WebhookReduxActions = ActionsUnion<typeof WebhookReduxActionsList>;

export default WebhookReduxActions;
