import { HTMLProps, MouseEventHandler, ReactNode } from 'react';

import { getDOMProps } from 'app/shared/utils/react';
import styled from 'styled-components';

import { Drawer } from '@mui/material';
import { IconX } from '@u21/tabler-icons';
import {
  U21Button,
  U21ButtonProps,
} from 'app/shared/u21-ui/components/input/U21Button';
import { U21Divider } from 'app/shared/u21-ui/components/display/U21Divider';
import { U21Spacer } from 'app/shared/u21-ui/components/layout/U21Spacer';
import { U21Typography } from 'app/shared/u21-ui/components/display/typography/U21Typography';

// I'm not sure why mui doesn't do this natively...
declare module '@mui/base' {
  interface ModalUnstyledComponentsPropsOverrides {
    invisible: boolean;
  }
}

export interface U21DrawerProps
  extends Omit<HTMLProps<HTMLDivElement>, 'title'> {
  actionButtonProps?: U21ButtonProps;
  children: ReactNode;
  closeButtonProps?: U21ButtonProps;
  onAction?: MouseEventHandler<HTMLButtonElement>;
  onClose: MouseEventHandler<HTMLButtonElement>;
  onEnter?: () => void;
  onExited?: () => void;
  open: boolean;
  title: ReactNode;
}

export const U21Drawer = (props: U21DrawerProps) => {
  const {
    actionButtonProps,
    children,
    closeButtonProps,
    onAction,
    onClose,
    onEnter,
    onExited,
    open,
    title,
    ...rest
  } = props;
  return (
    <Drawer
      anchor="right"
      componentsProps={{
        backdrop: { invisible: true },
      }}
      onClose={onClose}
      SlideProps={{ onEnter, onExited }}
      open={open}
    >
      <Container {...getDOMProps(rest)}>
        <HeaderContainer>
          <Header>
            <U21Typography variant="h6">{title}</U21Typography>
            <StyledU21Button
              aria-label="close"
              icon={<IconX />}
              onClick={onClose}
            />
          </Header>
          <StyledU21Divider horizontal />
        </HeaderContainer>
        <Content>{children}</Content>
        <FooterContainer>
          <StyledU21Divider horizontal />
          <Footer horizontal>
            <U21Button
              // eslint-disable-next-line react/no-children-prop
              children="Cancel"
              {...closeButtonProps}
              onClick={onClose}
            />
            {(Boolean(onAction) || Boolean(actionButtonProps)) && (
              <U21Button
                // eslint-disable-next-line react/no-children-prop
                children="Confirm"
                color="primary"
                variant="contained"
                {...actionButtonProps}
                onClick={onAction}
              />
            )}
          </Footer>
        </FooterContainer>
      </Container>
    </Drawer>
  );
};

const Container = styled.div`
  width: 370px;
`;

const HeaderContainer = styled.div`
  position: sticky;
  top: 0;
  background-color: ${(props) => props.theme.palette.background.paper};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
`;

const StyledU21Divider = styled(U21Divider)`
  margin: 0 16px;
`;

const Content = styled.div`
  padding: 16px;
`;

const FooterContainer = styled.div`
  position: sticky;
  bottom: 0;
  background-color: ${(props) => props.theme.palette.background.paper};
`;

const Footer = styled(U21Spacer)`
  padding: 16px;
  justify-content: flex-end;
`;

const StyledU21Button = styled(U21Button)`
  margin-left: auto;
`;
