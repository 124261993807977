import { UpdateSummaryViewConfigRequest } from 'app/modules/summarySettings/requests';
import { ListSummaryViewConfigsResponse } from 'app/modules/summarySettings/responses';
import { get, post } from 'app/shared/utils/fetchr';

const API_BASE = '/summary-view-configs';

export const getSummaryViewConfigs =
  (): Promise<ListSummaryViewConfigsResponse> => get(`${API_BASE}/list`);

export const updateSummaryViewConfig = (
  payload: UpdateSummaryViewConfigRequest,
): Promise<Record<string, never>> => post(`${API_BASE}/edit`, payload);
