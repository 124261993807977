// Saga
import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { InsightsActionTypes } from 'app/modules/insights/models';

// Api
import { retrieveDashboard as retrieveDashboardApi } from 'app/shared/api/insights';

// Actions
import {
  retrieveDashboard as retrieveDashboardAction,
  retrieveDashboardSuccess,
} from 'app/modules/insights/actions';

const rootAction = InsightsActionTypes.RETRIEVE_DASHBOARD;
function* retrieveDashboard({
  payload,
}: ReturnType<typeof retrieveDashboardAction>) {
  const config = {
    rootAction,
    request: call(retrieveDashboardApi, payload),
    errorToastMessage: `Something went wrong, unable to retrieve dashboard ${payload}.`,
    success: function* onSuccess(
      result: ReturnType<typeof retrieveDashboardSuccess>['payload'],
    ) {
      yield put(retrieveDashboardSuccess(result));
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watchRetrieveDashboard() {
  yield takeLatest(rootAction, retrieveDashboard);
}
