import { CytoscapeOptions, LayoutOptions } from 'cytoscape';
import {
  BASE_CYTOSCAPE_OPTIONS,
  BASE_EDGE_STYLES,
  BASE_LABEL_STYLES,
} from 'app/shared/components/Graphs/constants';
import {
  BaseGBRNodeDataDefinition,
  EntityGraphLink,
} from 'app/modules/detectionModels/components/scenarioWidgets/models';
import {
  Bank,
  Building,
  Calendar,
  CreditCard,
  FilterMini,
  FilterOffMini,
  Fingerprint,
  FoldersMini,
  FoldersOffMini,
  Home,
  License,
  Mail,
  MapPin,
  MinusMini,
  Number,
  Phone,
  PlusMini,
  Tag,
  User,
  Wifi,
} from 'app/shared/components/Graphs/svgs';
import { U21NetworkGraphElementKeysToUpdate } from 'app/shared/components/Graphs/U21NetworkGraph';

export const CONNECTION_TYPE_TO_ICON: Record<EntityGraphLink, string> = {
  address: Home,
  ssn: Number,
  ip_address: Wifi,
  instrument: CreditCard,
  phone: Phone,
  client_fingerprint: Fingerprint,
  email: Mail,
  full_name: Tag,
  first_name: Tag,
  last_name: Tag,
  date_of_birth: Calendar,
  geolocation: MapPin,
  physical_id: License,
  bank_account: Bank,
  business_name: Building,
  entity_custom: User,
  entity: User,
};

export const GLYPH_TYPE_TO_ICON = {
  filter: FilterMini,
  filterOff: FilterOffMini,
  plus: PlusMini,
  minus: MinusMini,
  folders: FoldersMini,
  foldersOff: FoldersOffMini,
};

export const GBR_LAYOUT_OPTIONS: LayoutOptions = {
  name: 'concentric',
  animate: true,
  animationDuration: 175,
  levelWidth: () => 1,
  // @ts-ignore
  concentric: (node) => (node.data('connectionType') ? 2 : 1),
  minNodeSpacing: 15,
  nodeDimensionsIncludeLabels: true,
  startAngle: Math.PI,
  fit: false,
};

export const GRAPH_BASED_RULES_CYTOSCAPE_OPTIONS: CytoscapeOptions = {
  ...BASE_CYTOSCAPE_OPTIONS,
  userZoomingEnabled: false,
  layout: GBR_LAYOUT_OPTIONS,
  autoungrabify: true,
  style: [
    {
      selector: 'node',
      style: {
        content: (node) => node.data('label') || '',
        'background-color': (node) => node.data('color') || 'purple',
        'background-image': (node) => {
          const nodeType: BaseGBRNodeDataDefinition['nodeType'] =
            node.data('nodeType');
          if (nodeType === 'connection') {
            return CONNECTION_TYPE_TO_ICON[node.data('connectionType')] ?? '';
          } else if (nodeType === 'glyph') {
            return GLYPH_TYPE_TO_ICON[node.data('icon')] ?? undefined;
          }
          return User;
        },
        'border-width': 0,
        width: (node) => (node.data('nodeType') === 'glyph' ? '12px' : '32px'),
        height: (node) => (node.data('nodeType') === 'glyph' ? '12px' : '32px'),
        events: (node) => (node.data('nodeType') === 'glyph' ? 'no' : 'yes'),
      },
    },
    {
      selector: 'edge',
      style: {
        ...BASE_EDGE_STYLES,
      },
    },
    {
      selector: 'node[label]',
      style: {
        ...BASE_LABEL_STYLES,
        'text-max-width': '100px',
        'text-wrap': 'wrap',
        'text-valign': 'bottom',
      },
    },
  ],
};

export const GBR_ELEMENT_KEYS_TO_UPDATE: U21NetworkGraphElementKeysToUpdate = {
  nodes: ['label', 'glyphs'],
  edges: [],
};
