import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { PermissionsActionTypes } from 'app/modules/permissions/models';

// Api
import { retrievePermissions as api } from 'app/shared/api/permissions';

// Actions
import {
  retrievePermissions as action,
  retrievePermissionsSuccess,
} from 'app/modules/permissions/actions';

const rootAction = PermissionsActionTypes.RETRIEVE_PERMISSIONS;
export function* retrievePermissions({ payload }: ReturnType<typeof action>) {
  const config = {
    rootAction,
    request: call(api, payload),
    success: function* onSuccess(
      result: ReturnType<typeof retrievePermissionsSuccess>['payload'],
    ) {
      yield put(retrievePermissionsSuccess(result));
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watch() {
  yield takeLatest(rootAction, retrievePermissions);
}
