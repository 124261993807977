import { AlertsActionTypes } from 'app/modules/alerts/models';
import { BlacklistActionTypes } from 'app/modules/lists/models';
import { CasesActionTypes } from 'app/modules/casesOld/models';
import { DetectionModelsActionTypes } from 'app/modules/detectionModels/models';
import { EntitiesActionTypes } from 'app/modules/entities/models';
import { GoAMLActionTypes } from 'app/modules/goAML/models';
import { TransactionsActionTypes } from 'app/modules/transactions/models';
import { WebhookActionTypes } from 'app/modules/webhooks/models';
import { WorkflowActionTypes } from 'app/modules/workflows/models';

export const isFourOhFourRoute = (action: string) =>
  new Set([
    AlertsActionTypes.RETRIEVE_ALERT_DETAILS,
    CasesActionTypes.RETRIEVE_CASE,
    GoAMLActionTypes.RETRIEVE_GOAML_FILING,
    BlacklistActionTypes.RETRIEVE_BLACKLIST,
    WebhookActionTypes.GET_WEBHOOK,
    WorkflowActionTypes.RETRIEVE_WORKFLOW,
    DetectionModelsActionTypes.RETRIEVE_DETECTION_MODEL_VALIDATION,
    EntitiesActionTypes.RETRIEVE_ENTITY,
    TransactionsActionTypes.RETRIEVE_ACTION_EVENT,
    TransactionsActionTypes.RETRIEVE_ORG_TRANSACTION,
    `investigationChecklist/RETRIEVE_INVESTIGATION_CHECKLIST`,
    `agentsRefresh/GET_AGENT_DETAILS`,
    'fincenSarNew/RETRIEVE_SAR',
  ]).has(action);
