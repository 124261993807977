import { post, get } from 'app/shared/utils/fetchr';

// Models
import { PaginatedOrgsPayload } from 'app/shared/dropdown/models';

// Utils
import routes from 'app/shared/utils/routes';

export const retrieveDropdownOrgs = (payload: PaginatedOrgsPayload) => {
  return post(routes.patronus.orgsAutocomplete, payload);
};

export const retrieveRelevantOrgs = () => {
  return get(routes.patronus.orgsRelevantOrgsDetails);
};
