import { ColorSchema } from 'vendor/material-minimal/palette';

import { alpha } from '@mui/system/colorManipulator';
import {
  forwardRef,
  HTMLProps,
  ReactElement,
  ReactNode,
  SyntheticEvent,
  useMemo,
} from 'react';
import styled, { css } from 'styled-components';
import { getDOMProps, isRenderable } from 'app/shared/utils/react';

// Components
import { ButtonProps, Chip, ChipProps } from '@mui/material';
import { IconX } from '@u21/tabler-icons';
import { Link, LinkProps } from 'react-router-dom';
import { U21Button } from 'app/shared/u21-ui/components/input/U21Button';
import useForkRef from '@mui/utils/useForkRef';
import { useResizeDetector } from 'react-resize-detector';
import {
  U21Tooltip,
  U21TooltipProps,
} from 'app/shared/u21-ui/components/display/U21Tooltip';
import { U21Spacer } from 'app/shared/u21-ui/components/layout/U21Spacer';

export type U21ChipColor = 'default' | ColorSchema;

export type U21ChipVariant = 'filled' | 'outlined' | 'ghost';

export interface U21ChipProps extends Omit<HTMLProps<HTMLDivElement>, 'size'> {
  children: ReactNode;
  disabled?: boolean;
  color?: U21ChipColor;
  endIcon?: ReactElement;
  icon?: ReactElement;
  onClick?: (e: SyntheticEvent) => void;
  onDelete?: (e: SyntheticEvent) => void;
  rounded?: boolean;
  to?: string;
  tooltip?: ReactNode;
  tooltipProps?: Omit<U21TooltipProps, 'children' | 'tooltip'>;
  variant?: U21ChipVariant;
}

const U21_TO_MUI_VARIANT: Record<U21ChipVariant, ChipProps['variant']> = {
  filled: 'filled',
  outlined: 'outlined',
  ghost: 'filled',
};

// wrap Link component to not pass disableRipple prop which tests don't like
const LinkWrapper = forwardRef<
  HTMLAnchorElement,
  Omit<ButtonProps, 'ref'> & LinkProps
>((props, ref) => {
  const { disableRipple, ...rest } = props;
  return <Link ref={ref} {...rest} />;
});

export const U21Chip = forwardRef<HTMLDivElement, U21ChipProps>(
  (
    {
      children,
      color = 'default',
      disabled,
      endIcon,
      icon,
      onClick,
      onDelete,
      rounded,
      variant = 'filled',
      to,
      tooltip,
      tooltipProps,
      ...rest
    },
    ref,
  ) => {
    const { ref: internalRef, width } = useResizeDetector<HTMLDivElement>({
      handleHeight: false,
      refreshMode: 'debounce',
      refreshRate: 300,
    });

    const hasFullTextTooltip = useMemo(() => {
      const [chipLabel] =
        internalRef.current?.getElementsByClassName('MuiChip-label') ?? [];
      return (chipLabel?.scrollWidth || 0) > (chipLabel?.clientWidth || 0);
      // use width as a proxy for when this should be updated
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [internalRef, width]);

    const combinedRef = useForkRef(ref, internalRef);

    const displayTooltip = useMemo(() => {
      if (tooltip && hasFullTextTooltip) {
        return (
          <U21Spacer dividers spacing={0.5} useFlexGap>
            {children}
            {tooltip}
          </U21Spacer>
        );
      }
      if (tooltip) {
        return tooltip;
      }
      if (hasFullTextTooltip) {
        return children;
      }
      return '';
    }, [children, hasFullTextTooltip, tooltip]);

    if (!isRenderable(children)) {
      return null;
    }

    return (
      <U21Tooltip tooltip={displayTooltip} {...tooltipProps}>
        <StyledChip
          ref={combinedRef}
          $endIcon={endIcon}
          $rounded={rounded}
          $u21Variant={variant}
          component={to ? LinkWrapper : undefined}
          to={to}
          color={color}
          deleteIcon={
            <U21Button
              aria-label="Delete"
              color={color === 'default' ? 'inherit' : color}
              icon={<IconX />}
              onMouseDown={onClick ? (e) => e.stopPropagation() : undefined}
              size="small"
            />
          }
          disabled={disabled}
          icon={icon}
          label={
            endIcon ? (
              <>
                {children}
                <EndIconContainer>{endIcon}</EndIconContainer>
              </>
            ) : (
              children
            )
          }
          onClick={
            onClick
              ? (e) => {
                  const selection = document.getSelection()?.toString();
                  if (!selection) {
                    onClick(e);
                  }
                }
              : undefined
          }
          onDelete={onDelete}
          size="small"
          variant={U21_TO_MUI_VARIANT[variant]}
          // override default disableRipple behavior when using `to`
          // cannot use explicit undefined due to mui prop spreading
          {...(to || onClick ? { disableRipple: false } : {})}
          {...getDOMProps(rest)}
        />
      </U21Tooltip>
    );
  },
);

const StyledChip = styled(Chip)<
  {
    $endIcon: U21ChipProps['endIcon'];
    $rounded: U21ChipProps['rounded'];
    $u21Variant: U21ChipVariant;
    color: U21ChipColor;
  } & Partial<LinkProps>
>`
  border-radius: ${(props) => (props.$rounded ? 16 : 6)}px;

  // prevent svg from shrinking
  & > svg {
    min-width: 20px;
  }

  .MuiChip-label {
    ${(props) => props.theme.typography.body2}
    ${(props) =>
      props.$endIcon
        ? css`
            display: flex;
            align-items: center;
          `
        : css``}
  }

  ${(props) => {
    const { $u21Variant, color, onClick, to, theme } = props;
    const clickable = Boolean(to) || Boolean(onClick);

    if ($u21Variant === 'filled') {
      if (color === 'default') {
        return css`
          ${clickable
            ? css`
                :hover {
                  background-color: ${theme.palette.grey[500_32]};
                }
              `
            : css``}
        `;
      }
    }
    if ($u21Variant === 'ghost') {
      if (color === 'default') {
        return css`
          background-color: ${theme.palette.grey[500_16]};
          border: 1px solid ${theme.palette.grey[500_24]};
          color: ${theme.palette.text.secondary};

          ${clickable
            ? css`
                :hover {
                  background-color: ${theme.palette.grey[500_24]};
                }
              `
            : css``}
        `;
      }
      return css`
        background-color: ${alpha(theme.palette[color].main, 0.12)};
        border: 1px solid ${alpha(theme.palette[color].main, 0.24)};
        color: ${theme.palette[color].dark};

        .MuiChip-deleteIcon {
          color: ${alpha(theme.palette[props.color].dark, 0.7)};

          :hover {
            color: ${theme.palette[color].dark};
          }
        }

        ${clickable
          ? css`
              :hover {
                background-color: ${alpha(theme.palette[color].main, 0.24)};
              }
            `
          : css``}
      `;
    }
    return css``;
  }}

  ${(props) => {
    const { $u21Variant, to, color, theme } = props;

    if (to) {
      let colorValue;
      if ($u21Variant === 'filled') {
        if (color === 'default') {
          colorValue = theme.palette.text.primary;
        } else {
          colorValue = theme.palette[color].contrastText;
        }
      } else if ($u21Variant === 'outlined') {
        if (color === 'default') {
          colorValue = theme.palette.text.primary;
        } else {
          colorValue = theme.palette[color].main;
        }
      } else if (color === 'default') {
        colorValue = theme.palette.text.secondary;
      } else {
        colorValue = theme.palette[color].dark;
      }
      return css`
        cursor: pointer;
        :hover {
          color: ${colorValue};
        }
      `;
    }
    return css``;
  }}

  .MuiChip-deleteIcon {
    margin-right: 0;

    ${(props) =>
      props.size === 'small' &&
      css`
        padding: 2px;
      `}
  }
`;

const EndIconContainer = styled.span`
  display: flex;
  padding-left: 4px;
  color: ${(props) =>
    props.theme.palette.mode === 'light'
      ? props.theme.palette.grey[700]
      : props.theme.palette.grey[300]};
`;
