import { IconFocus2 } from '@u21/tabler-icons';
import { CONCENTRIC_LAYOUT_OPTIONS } from 'app/shared/components/Graphs/constants';
import {
  U21Button,
  U21ButtonProps,
  U21Spacer,
} from 'app/shared/u21-ui/components';
import cytoscape, {
  CytoscapeOptions,
  Core,
  EventObject,
  LayoutOptions,
  NodeDefinition,
  EdgeDefinition,
  NullLayoutOptions,
  LayoutHandler,
} from 'cytoscape';
import { MouseEvent, useEffect, useRef, useCallback, useMemo } from 'react';
import styled from 'styled-components';

// --- types ---

interface ActionButtonProps extends Omit<U21ButtonProps, 'onClick'> {
  onClick?: (e: MouseEvent<HTMLButtonElement>, cy: Core | null) => void;
  key: string;
}

export interface U21NetworkGraphElements {
  nodes: Record<string | number, NodeDefinition>;
  edges: Record<string | number, EdgeDefinition>;
}

export interface U21NetworkGraphElementKeysToUpdate {
  nodes: string[];
  edges: string[];
}

// --- component ---

export type U21NetworkGraphProps = {
  actions?: ActionButtonProps[];
  cytoscapeOptions: CytoscapeOptions;
  elements?: U21NetworkGraphElements;
  handleGraphClick?: (e: EventObject, cy: Core | null) => void;
  height?: number;
  elementKeysToUpdate?: U21NetworkGraphElementKeysToUpdate;
  layoutOptions?: LayoutOptions;
  onElementsChange?: (
    cy: Core,
    elements: U21NetworkGraphElements,
    elementKeysToUpdate?: U21NetworkGraphElementKeysToUpdate,
  ) => void;
  onLayoutEnd?: LayoutHandler;
};

export const U21NetworkGraph = ({
  actions,
  cytoscapeOptions,
  elementKeysToUpdate,
  elements,
  handleGraphClick = () => {},
  height,
  layoutOptions,
  onElementsChange = defaultOnElementsChange,
  onLayoutEnd,
}: U21NetworkGraphProps) => {
  const cytoscapeContainer = useRef<HTMLDivElement>(null);
  const cy = useRef<Core | null>(null);

  const finalLayoutOptions = useMemo(() => {
    let options = cytoscapeOptions.layout ?? CONCENTRIC_LAYOUT_OPTIONS;
    // use layoutOptions if provided
    if (layoutOptions) {
      options = layoutOptions;
    }
    if (onLayoutEnd && !isNullLayout(options)) {
      options.stop = onLayoutEnd;
    }
    // use the layoutOptions in the base cytoscape options, else concentric options as fallback
    return options;
  }, [cytoscapeOptions, layoutOptions, onLayoutEnd]);

  const runLayout = useCallback(() => {
    if (cy.current) {
      const layout = cy.current.makeLayout(finalLayoutOptions);
      layout.run();
    }
  }, [finalLayoutOptions]);
  useEffect(() => {
    runLayout();
  }, [runLayout]);

  // initialize cytoscape graph; run layout (i.e. organize nodes)
  useEffect(() => {
    cy.current = cytoscape({
      ...cytoscapeOptions,
      container: cytoscapeContainer.current,
    });
  }, [cytoscapeOptions]);

  // data change handler
  useEffect(() => {
    if (cy.current && elements && onElementsChange) {
      onElementsChange(cy.current, elements, elementKeysToUpdate);
      runLayout();
    }
    // We don't want to run this effect whenever layoutOptions change.
    // If `runLayout` func's dependencies change,
    // the func will be called in the effect that initializes the graph (above)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elements, onElementsChange]);

  // click event handler
  useEffect(() => {
    if (cy.current) {
      cy.current.on('tap', (e) => handleGraphClick(e, cy.current));
    }
  }, [handleGraphClick, elements]);

  // wheel event handler (zoom functionality)
  const wheelEventListener = useCallback((e: WheelEvent) => {
    const { ctrlKey, metaKey, deltaY, offsetX, offsetY } = e;
    if ((ctrlKey || metaKey) && cy.current) {
      e.preventDefault();
      const currentZoom = cy.current.zoom();
      if (deltaY) {
        cy.current.zoom({
          level: currentZoom * (deltaY < 0 ? 1.1 : 0.9),
          renderedPosition: { x: offsetX, y: offsetY },
        });
      }
    }
  }, []);
  useEffect(() => {
    const container = cytoscapeContainer?.current;
    if (container) {
      container.addEventListener('wheel', wheelEventListener);
      // cleanup
      return () => container.removeEventListener('wheel', wheelEventListener);
    }
    // void cleanup for linter
    return () => {};
  }, [wheelEventListener, elements]);

  // cursor styles
  useEffect(() => {
    if (cy.current) {
      // cursor: pointer when hovering over node
      cy.current.on('mouseover', 'node', () => {
        const graph = document.querySelector(`.${NETWORK_GRAPH_CLASS_NAME}`);
        if (graph) {
          (graph as HTMLElement).style.cursor = 'pointer';
        }
      });
      // else cursor: grab
      cy.current.on('mouseout', 'node', () => {
        const graph = document.querySelector(`.${NETWORK_GRAPH_CLASS_NAME}`);
        if (graph) {
          (graph as HTMLElement).style.cursor = 'grab';
        }
      });
    }
  }, [elements]);

  return (
    <Wrapper>
      <Container
        $height={height}
        ref={cytoscapeContainer}
        className={NETWORK_GRAPH_CLASS_NAME}
      />
      <Actions>
        <U21Button
          onClick={(e) => (cy.current ? recenterGraph(e, cy.current) : {})}
          color="primary"
          variant="outlined"
          tooltip="Recenter graph"
          tooltipProps={{ placement: 'left' }}
          aria-label="Recenter graph"
        >
          <IconFocus2 />
        </U21Button>
        {!!actions?.length &&
          actions.map((a) => {
            const { key, onClick, ...rest } = a;
            return (
              <U21Button
                key={key}
                {...rest}
                {...(onClick ? { onClick: (e) => onClick(e, cy.current) } : {})}
                tooltipProps={{ placement: 'left' }}
              />
            );
          })}
      </Actions>
    </Wrapper>
  );
};

// --- component functions ---

const defaultOnElementsChange: U21NetworkGraphProps['onElementsChange'] = (
  cy,
  { nodes, edges },
  { nodes: nodeKeys, edges: edgeKeys } = { nodes: [], edges: [] },
): void => {
  // initialize set of desired nodes
  const desiredNodes = new Set(Object.keys(nodes));
  // iterate through current nodes on graph
  for (const node of cy.nodes()) {
    const id = node.data('id');
    // if node is in our set of desired nodes...
    if (desiredNodes.has(id)) {
      // update its data if it needs to be updated...
      for (const key of nodeKeys) {
        node.data(key, nodes[id].data[key]);
      }
      // and mark it as seen by removing from the set
      desiredNodes.delete(id);

      // if node is not in desired nodes, remove from graph
    } else {
      node.remove();
    }
  }
  // get container's dimensions (for initial positioning of new nodes below)
  const { offsetHeight, offsetWidth } = cy.container() ?? {};
  // add remaining desired nodes not currently on graph (i.e. those that were not removed above)
  let c = 0;
  for (const id of desiredNodes) {
    // note: setting an initial position is purely for UX purposes,
    // the node's positions will change once the layout is run,
    // but this makes new nodes' animation begin from the center of the graph,
    // instead of default top left (which is ugly)
    const position =
      offsetHeight && offsetWidth
        ? // cy dislikes manually positioning nodes exactly on top of one another
          { x: offsetWidth / 2 + c, y: offsetHeight / 2 + c }
        : undefined;
    cy.add({ data: nodes[id].data, position });
    c += 1;
  }

  // repeat the above for edges
  const desiredEdges = new Set(Object.keys(edges));
  for (const edge of cy.edges()) {
    const id = edge.data('id');
    if (desiredEdges.has(id)) {
      for (const key of edgeKeys) {
        edge.data(key, edges[id][key]);
      }
      desiredEdges.delete(id);
    } else {
      edge.remove();
    }
  }
  for (const id of desiredEdges) {
    cy.add({ data: edges[id].data });
  }
};

const recenterGraph = (e: MouseEvent<HTMLButtonElement>, cy: Core) => {
  e.stopPropagation();
  cy?.center().fit();
};

const isNullLayout = (options: LayoutOptions): options is NullLayoutOptions => {
  return options.name === 'null';
};

// --- styled components ---

const Wrapper = styled.div`
  position: relative;
`;

const Actions = styled(U21Spacer)`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
`;

const NETWORK_GRAPH_CLASS_NAME = 'unit21-network-graph';

const Container = styled.div<{ $height?: number }>`
  height: ${(props) => props.$height ?? 650}px;
  cursor: grab;

  canvas {
    top: 0px;
    left: 0px;
  }
`;
