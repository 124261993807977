import { FormFieldError } from 'app/shared/models/form';
import { GoogleDriveDoc } from 'app/modules/uploads/models';
import { UpdateGDriveFolderPayload } from 'app/modules/orgSettings/requests';
import { UploadedFile } from 'app/shared/models';

import routes from 'app/shared/utils/routes';
import URL from 'url-parse';

// valid URLs
// - https://drive.google.com/drive/folders/<id>
// - https://drive.google.com/drive/u/0/folders/<id>
export const validateGDriveURL = (url?: string): FormFieldError => {
  if (!url) {
    return undefined;
  }

  const parsedUrl = URL(url, {});
  const { host, pathname } = parsedUrl;
  if (!url.startsWith('https://')) {
    return 'URL must start with https://';
  }
  if (host !== 'drive.google.com') {
    return 'Host must be drive.google.com';
  }
  if (!pathname.match(/\/drive(\/.*)?\/folders\/[\w-]+$/)) {
    return 'Invalid google drive folder path';
  }
  return undefined;
};

export const createGDriveFolderPayload = (
  url?: string,
): UpdateGDriveFolderPayload => {
  const match = url?.match(/\/folders\/([\w-]+)$/);
  if (!url || !match) {
    return { storage_config: {} };
  }
  return {
    storage_config: {
      folder_id: match[1],
      raw_url: url,
    },
  };
};

export const createGDriveDocPayload = (
  file: File,
  uploadedDriveFile: UploadedFile,
): GoogleDriveDoc => {
  return {
    name: file.name,
    path: file.path,
    id: uploadedDriveFile.id,
    pendingUpload: false,
    isExternalDoc: false,
    mimeType: uploadedDriveFile.mimeType,
    iconUrl: uploadedDriveFile.iconLink,
    embedUrl: `${routes.external.googleDrive.filePrefix}${uploadedDriveFile.id}/preview?usp=drive_web`,
    url: `${routes.external.googleDrive.filePrefix}${uploadedDriveFile.id}/view?usp=drive_web`,
    parentUrl: '',
    lastEditedUtc: new Date(uploadedDriveFile.createdTime).getTime(),
    sizeBytes: Number(uploadedDriveFile.size),
  };
};
