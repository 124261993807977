import { useSelector } from 'react-redux';

// Components
import {
  U21NoValue,
  U21Skeleton,
  U21Typography,
} from 'app/shared/u21-ui/components';

import { selectFormatAmount } from 'app/modules/orgSettings/selectors';
import { selectHasReadAlertsPermission } from 'app/modules/session/selectors';

// API
import { useGetAlertAmountByAlertId } from 'app/modules/alerts/queries/useGetAlertAmount';

interface OwnProps {
  alertId: number;
  currency: string | undefined;
}

const CustomTypography = ({ amount }: { amount: string }) => (
  <U21Typography color="text.secondary" variant="body2">
    {amount}
  </U21Typography>
);

export const NewAlertAmount = ({ alertId, currency }: OwnProps) => {
  const {
    data: foo,
    isLoading,
    isError,
    isSuccess,
  } = useGetAlertAmountByAlertId(alertId);
  const formatAmount = useSelector(selectFormatAmount);
  const hasReadAlertsPermission = useSelector(selectHasReadAlertsPermission);

  if (!hasReadAlertsPermission) {
    return <U21NoValue />;
  }

  if (isLoading) {
    return <U21Skeleton width={80} />;
  }
  if (isError) {
    return <CustomTypography amount="Amount unavailable" />;
  }
  if (isSuccess) {
    if (foo.amount === null) {
      return <CustomTypography amount="Amount unavailable" />;
    }
    return (
      <CustomTypography
        amount={formatAmount({
          amount: foo.amount,
          currencyCodeProps: currency,
        })}
      />
    );
  }
  return <U21NoValue />;
};
