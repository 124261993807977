import { FC } from 'react';

// Components
import { U21Button } from 'app/shared/u21-ui/components';
import { IconArrowRight } from '@u21/tabler-icons';

// Models
import { WorkflowTemplate } from 'app/modules/workflows/models';
import { TemplateIds } from 'app/modules/workflows/constants';

interface OwnProps {
  item: WorkflowTemplate;
}
export const WorkflowTemplateButton: FC<OwnProps> = ({ item }) => {
  const { id } = item;
  return (
    <U21Button color="primary" variant="contained" endIcon={<IconArrowRight />}>
      {id !== TemplateIds.NEW ? 'Select template' : 'Start from scratch'}
    </U21Button>
  );
};
