// Redux
import { Reducer } from 'redux';
import { Draft, produce } from 'immer';

// Models
import {
  ActionTriggerState,
  SessionActionTypes,
  SessionState,
} from 'app/modules/session/models';
import { AgentsActionTypes } from 'app/modules/agentsOld/models';

// Actions
import SessionActions from 'app/modules/session/actions';
import { AgentActions } from 'app/modules/agentsOld/actions';
import { TeamActions } from 'app/modules/teamsOld/actions';

// Utils
import {
  getImpersonateId,
  getOriginalAgentId,
  getOrgSwitcherAgentId,
  setImpersonateId,
  setOrgSwitcherAgentId,
  setOriginalAgentId,
} from 'app/shared/utils/sessionStorage';
import deepFreeze from 'app/shared/utils/deepFreeze';

// Constants
import {
  INITIAL_SESSION_AGENT,
  INITIAL_SESSION_RELEVANT_ORGS,
  MAX_TOKEN_PING_RETRIES,
} from 'app/modules/session/constants';
import { WorkflowActionTypes } from 'app/modules/workflows/models';
import WorkflowReduxActions from 'app/modules/workflows/actions';
import { OrgsActionTypes } from 'app/modules/orgs/models';

const actionTriggersInitialState: ActionTriggerState = {
  alertTriggers: [],
  alertComponentTriggers: [],
  entityTriggers: [],
  caseTriggers: [],
  ruleTriggers: [],
  eventTriggers: [],
};

const initialState: Readonly<SessionState> = {
  agent: INITIAL_SESSION_AGENT,
  relevantOrgs: INITIAL_SESSION_RELEVANT_ORGS,
  idp: {} as SessionState['idp'],
  accessToken: '',
  permissions: [],
  impersonating:
    getImpersonateId() !== -1 && getImpersonateId() !== getOriginalAgentId(),
  failedTokenPingCount: 0,
  tokenHasExpired: false,
  actionTriggers: actionTriggersInitialState,
};
// to ensure initialState is readonly
deepFreeze(initialState);

const reducer: Reducer<SessionState> = (
  state = initialState,
  action: SessionActions | AgentActions | TeamActions | WorkflowReduxActions,
) => {
  return produce(state, (draft: Draft<SessionState>) => {
    const impersontationAgentId = getImpersonateId();

    const isImpersonationAgentNotSet =
      !impersontationAgentId || impersontationAgentId === -1;
    const orgSwitcherAgentId = getOrgSwitcherAgentId();
    const isOrgSwitcherAgentNotSet =
      !orgSwitcherAgentId || orgSwitcherAgentId === -1;
    switch (action.type) {
      case SessionActionTypes.START_SESSION_SUCCESS:
        if (isImpersonationAgentNotSet && isOrgSwitcherAgentNotSet)
          setOriginalAgentId(action.payload.id);

        if (isImpersonationAgentNotSet) {
          setImpersonateId(-1);
        }
        if (isOrgSwitcherAgentNotSet) {
          setOrgSwitcherAgentId(-1);
        }
        draft.agent = action.payload;
        return;

      case SessionActionTypes.RETRIEVE_IDP_SUCCESS:
        draft.idp = action.payload;
        return;

      case SessionActionTypes.TOKEN_PING_SUCCESS:
        if (action.payload) {
          if (state.failedTokenPingCount < MAX_TOKEN_PING_RETRIES) {
            draft.failedTokenPingCount += 1;
          } else {
            draft.tokenHasExpired = true;
          }
        } else {
          draft.failedTokenPingCount = 0;
          draft.tokenHasExpired = false;
        }

        return;

      case SessionActionTypes.RETRIEVE_ACTION_TRIGGERS_SUCCESS:
        draft.actionTriggers.alertTriggers =
          action.payload.alert_action_triggers;
        draft.actionTriggers.entityTriggers =
          action.payload.entity_action_triggers;
        draft.actionTriggers.caseTriggers = action.payload.case_action_triggers;
        draft.actionTriggers.ruleTriggers = action.payload.rule_action_triggers;
        draft.actionTriggers.eventTriggers =
          action.payload.event_action_triggers;
        draft.actionTriggers.alertComponentTriggers =
          action.payload.alert_component_action_triggers;
        return;

      case SessionActionTypes.SET_SESSION_AGENT_PERMISSIONS: {
        draft.permissions = action.payload.map((i) => i.name);
        return;
      }

      case AgentsActionTypes.START_IMPERSONATION_SUCCESS:
        draft.impersonating = true;
        return;

      case AgentsActionTypes.CANCEL_IMPERSONATION:
        draft.impersonating = false;
        return;

      case OrgsActionTypes.RETRIEVE_RELEVANT_ORGS_SUCCESS:
        draft.relevantOrgs = action.payload;
        return;

      case SessionActionTypes.SET_LAST_REQUEST_DATE:
        draft.lastRequestDate = action.payload;
        return;

      case SessionActionTypes.UPDATE_SESSION_AGENT_NAME_SUCCESS:
        draft.agent.full_name = action.payload;
        return;

      case SessionActionTypes.UPDATE_SESSION_AGENT_PICTURE_SUCCESS:
        draft.agent.picture = action.payload;
        return;

      case WorkflowActionTypes.DELETE_WORKFLOW_SUCCESS:
        draft.actionTriggers.alertTriggers =
          draft.actionTriggers.alertTriggers.filter(
            (trigger) => trigger.id.toString() !== action.payload,
          );
    }
  });
};

export { reducer as sessionReducer, initialState };
