import { downloadFile } from 'app/shared/utils/fetchr';

// Models
import { FileExportDownloadPayload } from 'app/modules/fileExportsOld/models';

// Utils
import routes from 'app/shared/utils/routes';

export const downloadFileExport = (payload: FileExportDownloadPayload) => {
  const { id, file_name: fileName } = payload;
  return downloadFile(`${routes.patronus.fileExportsDownload}/${id}`, fileName);
};
