import { useState } from 'react';
import styled, { css } from 'styled-components';

// Components
import {
  U21Alert,
  U21Button,
  U21Section,
  U21Divider,
  U21Form,
  U21MenuButton,
  U21MenuItemProps,
  U21Spacer,
  U21Typography,
  U21Chip,
} from 'app/shared/u21-ui/components';
import {
  U21FormField,
  U21FormFieldTypes,
} from 'app/shared/u21-ui/components/form/U21FormField';
import { DeleteChecklistItemModal } from 'app/shared/u21-ui/components/dashboard/DeleteChecklistItemModal';
import { IconDots, IconPencil, IconPlus, IconTrash } from '@u21/tabler-icons';
import { U21CreateChecklistItemModal } from 'app/shared/u21-ui/components/dashboard/U21CreateChecklistItemModal';

// Models
import { CustomChecklistDefinition } from 'app/shared/customChecklist/models';
import { InvestigationChecklistTypes } from 'app/modules/investigationChecklist/models';

// Utils
import emptyFn from 'app/shared/utils/empty-fn';
import { generateFormFieldProps } from 'app/shared/utils/form';
import {
  ALERT_INVESTIGATION_CHECKLIST_DOCS,
  CASE_INVESTIGATION_CHECKLIST_DOCS,
} from 'app/shared/constants/docs';
import { U21_DOCS_PASSWORD_TOOLTIP } from 'app/shared/u21-ui/components/dashboard/U21DocsLink';

export interface AllProps {
  disabled?: boolean;
  groupDefinition: CustomChecklistDefinition;
  subheader?: string;
  checklistType: `${InvestigationChecklistTypes}`;
  onEdit: (id: string | number, title?: string) => void;
  onDelete: (id: string | number, title?: string) => void;
  onAddItemToGroup: (definition: CustomChecklistDefinition) => void;
  onDeleteItem: (
    groupKey: string,
    checklistItem: CustomChecklistDefinition,
    itemIndex: number,
  ) => void;
}

export const U21CreateChecklist = ({
  disabled,
  groupDefinition,
  subheader,
  checklistType,
  onEdit,
  onDelete,
  onAddItemToGroup,
  onDeleteItem,
}: AllProps) => {
  const { key: id, label: title, items } = groupDefinition;

  const [showAddChecklistModal, setShowAddChecklistModal] =
    useState<boolean>(false);
  const [itemToEdit, setItemToEdit] = useState<
    CustomChecklistDefinition | undefined
  >(undefined);
  const [conditionalItemToEdit, setConditionalItemToEdit] = useState<
    CustomChecklistDefinition | undefined
  >(undefined);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemIndexToDelete, setItemIndexToDelete] = useState<
    number | undefined
  >(undefined);

  const handleAddChecklistItem = (
    checklistItem: CustomChecklistDefinition,
    conditionalChecklistItem: CustomChecklistDefinition,
  ) => {
    const checklistItems = items && items.length ? [...items] : [];

    // Check if there are currently items if not just push the items
    if (checklistItems.length) {
      // See if this item already exists
      const checklistItemToUpdateIndex = checklistItems.findIndex(
        (item) => item.key === checklistItem.key,
      );

      // See if there is a conditional item that already exists
      let conditionalChecklistItemToUpdateIndex = conditionalChecklistItem
        ? checklistItems.findIndex(
            (item) => item.key === conditionalChecklistItem.key,
          )
        : checklistItems.findIndex(
            (item) => item.enabled_by === checklistItem.key,
          );

      // If the item exists replace it
      if (checklistItemToUpdateIndex >= 0) {
        checklistItems.splice(checklistItemToUpdateIndex, 1, checklistItem);

        // If the conditional item previously existed update it as well
        if (conditionalChecklistItemToUpdateIndex >= 0) {
          // If there is no item provided but one currently found it must be destroyed
          if (!conditionalChecklistItem) {
            checklistItems.splice(conditionalChecklistItemToUpdateIndex, 1);
          } else {
            checklistItems.splice(
              conditionalChecklistItemToUpdateIndex,
              1,
              conditionalChecklistItem,
            );
          }
        } else if (conditionalChecklistItem) {
          // If the conditional didn't exist we can assume that this was another type of field that got edited to be coniditional
          // Add it right after the previously existing item
          conditionalChecklistItemToUpdateIndex =
            checklistItemToUpdateIndex + 1;
          checklistItems.splice(
            conditionalChecklistItemToUpdateIndex,
            0,
            conditionalChecklistItem,
          );
        }
      } else {
        // If this item did not exist before then lets add it to the end
        checklistItems.push(checklistItem);

        // If a conditional item was passed then add it right after
        if (conditionalChecklistItem) {
          checklistItems.push(conditionalChecklistItem);
        }
      }
    } else {
      // Same as addition case
      checklistItems.push(checklistItem);

      if (conditionalChecklistItem) {
        checklistItems.push(conditionalChecklistItem);
      }
    }

    onAddItemToGroup({ ...groupDefinition, items: checklistItems });
  };

  const cardItems: U21MenuItemProps[] = [
    {
      icon: <IconPencil />,
      text: 'Edit',
      onClick: () => onEdit(id, title),
    },
    {
      icon: <IconTrash />,
      text: 'Delete',
      onClick: () => onDelete(id, title),
      color: 'error',
    },
  ];

  const handleFormFieldType = (item, key) => {
    const { key: itemKey, label, options, placeholder, required, type } = item;

    const fieldProps = {
      label,
      options,
      placeholder,
    };

    const reactKey = itemKey || key;
    const formFieldProps = generateFormFieldProps({
      name: itemKey,
      required,
      fieldProps,
    });

    switch (type) {
      case 'dropdown':
        return (
          <U21FormField
            key={reactKey}
            {...formFieldProps}
            type={U21FormFieldTypes.SELECT}
          />
        );

      case 'multi_select':
        return (
          <U21FormField
            key={reactKey}
            {...formFieldProps}
            type={U21FormFieldTypes.MULTISELECT}
          />
        );

      case 'checkbox':
        return (
          <U21FormField
            key={reactKey}
            {...formFieldProps}
            type={U21FormFieldTypes.CHECKBOX}
          />
        );

      case 'input':
        return (
          <U21FormField
            key={reactKey}
            {...formFieldProps}
            type={U21FormFieldTypes.TEXT}
          />
        );

      case 'label':
        return (
          <U21Alert key={reactKey} severity="warning">
            {label}
          </U21Alert>
        );

      case 'textarea':
        return (
          <U21FormField
            key={reactKey}
            {...formFieldProps}
            type={U21FormFieldTypes.TEXTAREA}
          />
        );

      case 'date_range':
        return (
          <U21FormField
            key={reactKey}
            {...formFieldProps}
            type={U21FormFieldTypes.DATE_RANGE_PICKER}
          />
        );

      default:
        return null;
    }
  };

  const handleItemEdit = (item: CustomChecklistDefinition) => {
    setShowAddChecklistModal(true);
    setItemToEdit(item);

    if (items && items.length) {
      const conditionalTriggerIndex = items.findIndex(
        (possibleTriggerItem) => possibleTriggerItem.enabled_by === item.key,
      );

      if (conditionalTriggerIndex >= 0) {
        setConditionalItemToEdit(items[conditionalTriggerIndex]);
      }
    }
  };

  const handleDeleteItemConfirmation = (
    checklistItem: CustomChecklistDefinition,
    itemIndex: number,
  ) => {
    setItemIndexToDelete(itemIndex);
    setItemToEdit(checklistItem);
    setDeleteModalOpen(true);
  };

  const handleDeleteItemModalClose = () => {
    setItemIndexToDelete(undefined);
    setItemToEdit(undefined);
    setDeleteModalOpen(false);
  };

  return (
    <U21Section
      title={
        groupDefinition.ai_investigations_enabled ? (
          <U21Spacer horizontal>
            <span>{title}</span>
            <U21Chip>AI agent enabled</U21Chip>
          </U21Spacer>
        ) : (
          title
        )
      }
      subheader={subheader}
      helpModalProps={{
        children: `Groups are used to separate different concerns in an Investigation Checklist. 
        Each group can have as many items as desired but must contain at least one. 
        The title and validations on each group can be edited while the checklist is still in Draft state.`,
        docsLink:
          checklistType === InvestigationChecklistTypes.ALERT_CHECKLIST
            ? ALERT_INVESTIGATION_CHECKLIST_DOCS
            : CASE_INVESTIGATION_CHECKLIST_DOCS,
        docsLinkProps: { tooltip: U21_DOCS_PASSWORD_TOOLTIP },
      }}
      action={
        !disabled && (
          <U21MenuButton items={cardItems} alt="options">
            <IconDots />
          </U21MenuButton>
        )
      }
    >
      <U21Spacer spacing={4}>
        {items && items.length > 0 ? (
          <U21Form disabled prompt={false} onSubmit={emptyFn}>
            <U21Spacer spacing={2}>
              {items.map((item: CustomChecklistDefinition, key) => {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <U21Spacer horizontal key={key}>
                    <StyledDiv>{handleFormFieldType(item, key)}</StyledDiv>
                    <ButtonWrap horizontal $indent={Boolean(item.enabled_by)}>
                      {!item.enabled_by && !disabled && (
                        <>
                          <U21Button
                            aria-label="edit"
                            icon={<IconPencil />}
                            onClick={() => handleItemEdit(item)}
                          />
                          <U21Button
                            aria-label="delete"
                            color="error"
                            icon={<IconTrash />}
                            onClick={() =>
                              handleDeleteItemConfirmation(item, key)
                            }
                          />
                        </>
                      )}
                    </ButtonWrap>
                  </U21Spacer>
                );
              })}
            </U21Spacer>
          </U21Form>
        ) : (
          <U21Typography variant="subtitle2" color="grey.500">
            There are no items in this group! Click the button below to get
            started.
          </U21Typography>
        )}
        {!disabled && (
          <>
            <U21Divider horizontal />
            <ButtonContainer>
              <U21Button
                color="primary"
                disabled={disabled}
                startIcon={<IconPlus />}
                variant="contained"
                onClick={() => setShowAddChecklistModal(true)}
              >
                Add an item to this group
              </U21Button>
            </ButtonContainer>
          </>
        )}
      </U21Spacer>
      <DeleteChecklistItemModal
        open={deleteModalOpen}
        handleClose={handleDeleteItemModalClose}
        checklistItem={itemToEdit}
        checklistItemIndex={itemIndexToDelete}
        handleDeleteChecklist={onDeleteItem}
        groupKey={groupDefinition.key}
      />
      <U21CreateChecklistItemModal
        handleSubmit={handleAddChecklistItem}
        itemToEdit={itemToEdit}
        checklistGroupDefinition={groupDefinition}
        conditionalItemToEdit={conditionalItemToEdit}
        open={showAddChecklistModal}
        handleClose={() => {
          setItemToEdit(undefined);
          setConditionalItemToEdit(undefined);
          setShowAddChecklistModal(false);
        }}
      />
    </U21Section>
  );
};

const StyledDiv = styled.div`
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: start;
`;

const ButtonWrap = styled(U21Spacer)<{ $indent: boolean }>`
  ${(props) =>
    props.$indent &&
    css`
      margin-right: 112px !important;
    `}
`;
