import { LockOpen } from '@mui/icons-material';
import { IconEye, IconEyeOff, IconKey } from '@u21/tabler-icons';
import { useDecryptPan } from 'app/modules/dataSettings/queries/useDecryptPan';
import { FormattedDataCensorshipStatus } from 'app/modules/dataSettings/responses';
import { selectHasDecryptPansPermission } from 'app/modules/session/selectors';
import { sendErrorToast } from 'app/shared/toasts/actions';
import {
  U21Button,
  U21Chip,
  U21Loading,
  U21Spacer,
} from 'app/shared/u21-ui/components';
import { forwardRef, ReactElement, ReactNode, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export interface CensorshipWrapperProps {
  /**
   * @param censorship - the censorship status of the field.
   * @param useChip - we render an uncensored value inside a chip depending on its data type and other factors like rendering options.
   * @param centerAlignRecensor - depening on the data type and rendering options of the uncensored value, we may want to center align the recensor button.
   * @param children - the uncensored value.
   * @param token - the raw token, used for decryption if the field is encrypted. Only applies to text fields curently.
   */
  censorship: FormattedDataCensorshipStatus | undefined;
  useChip: boolean;
  centerAlignRecensor?: boolean;
  children: ReactNode;
  token?: string | null;
}

export const CensorshipWrapper = forwardRef<
  HTMLDivElement,
  CensorshipWrapperProps
>(
  (
    {
      censorship: censorshipStatus,
      useChip,
      centerAlignRecensor = false,
      children,
      token,
    },
    ref,
  ) => {
    const [viewable, setViewable] = useState(false);
    const hasDecryptPansPermission = useSelector(
      selectHasDecryptPansPermission,
    );
    const isCensored = useMemo(() => {
      return (
        censorshipStatus &&
        censorshipStatus !== FormattedDataCensorshipStatus.UNCENSORED
      );
    }, [censorshipStatus]);

    const {
      data: decryptedValue,
      mutateAsync: decryptPan,
      isPending: isDecrypting,
    } = useDecryptPan();

    const dispatch = useDispatch();

    if (censorshipStatus === FormattedDataCensorshipStatus.TOKEN_ENCRYPTED) {
      // if value has been decrypted, show it
      if (viewable) {
        return (
          <U21Chip icon={<LockOpen />} ref={ref}>
            {decryptedValue}
          </U21Chip>
        );
      }
      // if there is no token, render children which should just be u21_no_value
      if (!token) {
        return children;
      }
      return (
        <U21Chip
          tooltip={hasDecryptPansPermission ? 'Decrypt' : null}
          icon={
            isDecrypting ? (
              <U21Loading loading variant="spinner" />
            ) : (
              <IconKey />
            )
          }
          onClick={
            hasDecryptPansPermission
              ? async (e) => {
                  e.stopPropagation();
                  try {
                    await decryptPan(token);
                    setViewable(true);
                  } catch {
                    dispatch(sendErrorToast('Failed to decrypt data.'));
                  }
                }
              : undefined
          }
          disabled={isDecrypting}
          ref={ref}
        >
          {token}
        </U21Chip>
      );
    }

    if (!isCensored) {
      return children;
    }

    let icon: ReactElement | undefined;
    if (censorshipStatus === FormattedDataCensorshipStatus.UNCENSORABLE) {
      icon = viewable ? <IconEyeOff /> : <IconEye />;
    }
    const censorshipChip = (
      <U21Chip
        icon={icon}
        onClick={
          censorshipStatus === FormattedDataCensorshipStatus.UNCENSORABLE
            ? (e) => {
                e.stopPropagation();
                setViewable((prev) => !prev);
              }
            : undefined
        }
        ref={ref}
      >
        {viewable ? children : '••••••'}
      </U21Chip>
    );

    if (useChip) {
      return censorshipChip;
    }
    return (
      <U21Spacer
        horizontal
        align={centerAlignRecensor ? 'center' : 'start'}
        ref={ref}
      >
        {viewable ? (
          <U21Button
            icon={icon}
            size="small"
            onClick={
              censorshipStatus === FormattedDataCensorshipStatus.UNCENSORABLE
                ? (e) => {
                    e.stopPropagation();
                    setViewable(false);
                  }
                : undefined
            }
            tooltip="Censor"
            aria-label="Censor"
          />
        ) : (
          censorshipChip
        )}
        {viewable ? children : null}
      </U21Spacer>
    );
  },
);
