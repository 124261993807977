import { Disposition } from 'app/modules/dispositions/response';
import { PaginationPayload } from 'app/shared/models';
import { DispositionType } from 'app/modules/dispositions/models';

export const INITIAL_DISPOSITION: Disposition = {
  id: -1,
  title: '',
  type: DispositionType.TRUE_POSITIVE,
  buttons: [],
  is_default: false,
};

export const DISPOSITION_PAGINATION_PAYLOAD: PaginationPayload = {
  limit: 99999,
  offset: 1,
  sort_column: 'disposition_label',
  sort_direction: 'ascending',
};
