import { useMemo } from 'react';
import { debounce } from 'lodash';
import { FormSpy, useForm } from 'react-final-form';
import { Config } from 'final-form';

interface OwnProps<FormValues> {
  delay?: number; // in milli-seconds
  onSubmit?: Config<FormValues>['onSubmit'];
  skipAutoSaveValidation?: boolean;
}
export const U21FormAutoSave = <FormValues,>({
  delay = 2000,
  onSubmit,
  skipAutoSaveValidation,
}: OwnProps<FormValues>) => {
  const form = useForm<FormValues>();

  const handleChange = useMemo(() => {
    return debounce(({ valid, dirty, values }) => {
      if (skipAutoSaveValidation && onSubmit && dirty) {
        onSubmit(values, form);
      } else if (valid && dirty) {
        form.submit();
      }
    }, delay);
  }, [delay, form, skipAutoSaveValidation, onSubmit]);

  return (
    <FormSpy
      onChange={handleChange}
      subscription={{
        values: true,
        valid: true,
        dirty: true,
      }}
    />
  );
};
