import { useEffect, useMemo, useRef } from 'react';
import { PaginatedAgentsPayload } from 'app/modules/agentsOld/models';

import { INFINITE_SCROLL_LIMIT, OFFSET_DEFAULT } from 'app/shared/constants';
import permissions from 'app/shared/utils/permissions';

import { identityFunction } from 'app/shared/utils/function';
import { retrieveDropdownAgents } from 'app/shared/dropdown/actions';
import { selectAgentOptions } from 'app/shared/dropdown/selectors';
import { selectHasPermissionsFactory } from 'app/modules/session/selectors';
import { useDispatch, useSelector } from 'react-redux';

import { IconUser } from '@u21/tabler-icons';
import {
  U21Avatar,
  U21SelectOptionProps,
  U21SelectSearch,
  U21SelectSearchProps,
} from 'app/shared/u21-ui/components';

interface Props {
  getPayload?: (payload: PaginatedAgentsPayload) => PaginatedAgentsPayload;
  selector?: (state: RootState) => U21SelectOptionProps<number>[];
  unassigned?: boolean;
}

export type U21AgentSelectProps<TClearable extends boolean> = DistributiveOmit<
  U21SelectSearchProps<number, TClearable>,
  'options' | 'onSearch'
> &
  Props;

const selectHasReadAgentsPermission = selectHasPermissionsFactory(
  permissions.readAgents,
);

const UNASSIGNED_OPTION = {
  icon: <U21Avatar name="?" />,
  text: 'Unassigned',
  value: -1,
};

export const U21AgentSelect = <TClearable extends boolean>(
  props: U21AgentSelectProps<TClearable>,
) => {
  const dispatch = useDispatch();
  const {
    getPayload = identityFunction,
    selector = selectAgentOptions,
    unassigned = false,
    ...rest
  } = props;
  const baseOptions = useSelector(selector);
  const hasReadAgentsPermission = useSelector(selectHasReadAgentsPermission);

  const options = useMemo(() => {
    if (unassigned) {
      return [UNASSIGNED_OPTION, ...baseOptions];
    }
    return baseOptions;
  }, [baseOptions, unassigned]);

  // assume array if undefined for missing values calculation
  const { value = [] } = props;
  const values = useMemo(
    () => (Array.isArray(value) ? value : [value]),
    [value],
  ) as number[];

  // calledRef is used to prevent an infinite loop in case the API never returns with the missing value
  const calledRef = useRef(false);
  useEffect(() => {
    calledRef.current = false;
  }, [values]);

  const getPayloadRef = useRef(getPayload);
  getPayloadRef.current = getPayload;
  useEffect(() => {
    if (hasReadAgentsPermission) {
      const missingValues = values.filter(
        (i) => !options.find((j) => j.value === i),
      );
      if (missingValues.length && !calledRef.current) {
        calledRef.current = true;
        dispatch(
          retrieveDropdownAgents(
            getPayloadRef.current({
              limit: missingValues.length,
              offset: OFFSET_DEFAULT,
              ids: missingValues,
            }),
          ),
        );
      }
    }
  }, [dispatch, hasReadAgentsPermission, options, values]);

  if (!hasReadAgentsPermission) {
    return null;
  }

  return (
    <U21SelectSearch
      label="Agents"
      onSearch={(query) =>
        dispatch(
          retrieveDropdownAgents(
            getPayload({
              limit: INFINITE_SCROLL_LIMIT,
              offset: OFFSET_DEFAULT,
              query,
            }),
          ),
        )
      }
      options={options}
      startIcon={<IconUser />}
      {...rest}
    />
  );
};
