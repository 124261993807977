import { ScenarioParameters } from 'app/modules/detectionModels/models';

export const validateCheckFraudRuleContentStep = (
  params: ScenarioParameters,
): boolean => {
  if (!params.check_fraud_content_valid) {
    return false;
  }
  return true;
};
