import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { SearchActionTypes } from 'app/modules/search/models';

// Api
import { rulesSearch as rulesSearchApi } from 'app/shared/api/search';

// Actions
import {
  rulesSearch as rulesSearchAction,
  rulesSearchSuccess,
} from 'app/modules/search/actions';

const rootAction = SearchActionTypes.RULES_SEARCH;
function* rulesSearch(
  payload: ReturnType<typeof rulesSearchAction>['payload'],
) {
  const config = {
    rootAction,
    request: call(rulesSearchApi, payload),
    errorToastMessage: `There was an error searching rules. Please try again`,
    success: function* onSuccess(
      result: ReturnType<typeof rulesSearchSuccess>['payload'],
    ) {
      yield put(rulesSearchSuccess(result));
    },
  };
  yield call(makeSagaRequest, config);
}

function* rulesSearchFlow({ payload }: ReturnType<typeof rulesSearchAction>) {
  yield call(rulesSearch, payload);
}

export default function* watchrulesSearch() {
  yield takeLatest(rootAction, rulesSearchFlow);
}
