import { EMPTY_PERSON_MY_CLIENT } from 'app/modules/goAML/schemas/templates/emptyPerson';
import { TEntity, TEntityMyClient } from 'app/modules/goAML/models';

export const EMPTY_ENTITY: TEntity = {
  name: '',
  incorporation_legal_form: '',
};

export const EMPTY_ENTITY_MY_CLIENT: TEntityMyClient = {
  ...EMPTY_ENTITY,
  incorporation_number: '',
  business: '',
  phones: [
    {
      phone: {
        tph_contact_type: '-',
        tph_communication_type: 'L',
        tph_country_prefix: '',
        tph_number: '',
      },
    },
  ],
  addresses: [
    {
      address: {
        address_type: '-',
        address: '',
        city: '',
        country_code: '',
      },
    },
  ],
  incorporation_country_code: '',
  director_id: {
    ...EMPTY_PERSON_MY_CLIENT,
    role: 'D',
  },
  incorporation_date: '',
};
