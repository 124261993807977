// Utils
import { get, post, destroy } from 'app/shared/utils/fetchr';
import routes from 'app/shared/utils/routes';

// Models
import { RetrieveGoAMLTemplatesContent } from 'app/modules/settings/models';
import { APIKey } from 'app/modules/apiKeys/responses';

export const getAPIKeys = () => get(routes.patronus.apiKeysList);

export const createAPIKey = () => post<APIKey>(routes.patronus.apiKeysCreate);

export const updateAPIKeyPermissions = (payload) =>
  post(routes.patronus.apiKeysUpdatePermissions, payload);

export const deactivateAPIKey = (keyPrefix: string) =>
  destroy(
    routes.patronus.apiKeysDeactivateKeyPrefix.replace(':keyPrefix', keyPrefix),
  );

export const getGoAMLTemplates = () => get(routes.patronus.goAMLTemplates);

export const editGoAMLTemplates = (payload: {
  type: string;
  content: RetrieveGoAMLTemplatesContent;
}) => post(routes.patronus.goAMLTemplatesUpdate, payload);
