import { FormFieldError } from 'app/shared/models/form';

export function required(
  message: string = 'Field is required.',
  isList: boolean = false,
) {
  return (value: any) => {
    if (isList && !value?.length) {
      return message;
    }

    // special condition for 0 and false since they are falsey
    if (typeof value === 'number' || typeof value === 'boolean') {
      return undefined;
    }

    if (!value || (typeof value === 'string' && !value.trim())) {
      return message;
    }
    return undefined;
  };
}

export const validateLength = (
  length: number,
  value?: string,
  isRequired?: boolean,
  unknown?: boolean,
): FormFieldError | Promise<FormFieldError> => {
  if (unknown) {
    return undefined;
  }

  const mandatory = required('Required')(value);
  if (isRequired && mandatory) {
    return mandatory;
  }
  if (value && value.length > length) {
    return `Max ${length} characters allowed`;
  }
  return undefined;
};
