import { useQuery } from '@tanstack/react-query';
import { get } from 'app/shared/utils/fetchr';
import { ALERT_QUERY_KEYS } from 'app/modules/alerts/queries/keys';
import { useSelector } from 'react-redux';
import { selectHasReadAlertsPermission } from 'app/modules/session/selectors';

export const useGetAlertAmountByAlertId = (id: number) => {
  const hasReadAlertsPermission = useSelector(selectHasReadAlertsPermission);
  return useQuery({
    enabled: hasReadAlertsPermission,
    queryKey: ALERT_QUERY_KEYS.getAlertAmount(id),
    queryFn: () => {
      return get<{ amount: number | null }>(`/alert/${id}/amount`);
    },
    staleTime: 60 * 60 * 1000,
  });
};
