// Models
import {
  SessionActionTypes,
  SessionAgent,
  SessionRelevantOrgs,
} from 'app/modules/session/models';

// Constants
import { INITIAL_TEAMS_PERMISSIONS } from 'app/modules/teamsOld/constants';
import { DEFAULT_ID } from 'app/shared/constants';

export const INITIAL_SESSION_AGENT: SessionAgent = {
  created_at: '',
  email: '',
  full_name: '',
  id: -1,
  org_name: '',
  org_display_name: '',
  org_features: [],
  picture: '',
  status: '',
  unit_name: '',
  org_id: -1,
  is_default_login: true,
  default_org_id: -1,
  default_org_display_name: '',
  unit_id: -1,
  is_reviewer: null,
  teams: [],
  teams_permissions: { ...INITIAL_TEAMS_PERMISSIONS },
  accessible_queues: [],
};

export const INITIAL_SESSION_RELEVANT_ORGS: SessionRelevantOrgs = {
  org_id: DEFAULT_ID,
  org_type: '',
  org_name: '',
  org_display_name: '',
  parent_org_id: DEFAULT_ID,
  parent_org_name: '',
  parent_org_display_name: '',
};

// used to indicate which sagas we should ignore when tracking user activity
// currently, just token ping is done every x seconds w/ out user activity
export const LAST_REQUEST_SAGAS_TO_IGNORE: { [key: string]: true } = {
  [SessionActionTypes.TOKEN_PING]: true,
};

// If token ping fails this many times in a row then the user will be logged
// out automatically. Token ping happens every minute.
export const MAX_TOKEN_PING_RETRIES = 2;

export const QA_ADMIN_USER_EMAIL = 'qa_admin_user@unit21.ai';
