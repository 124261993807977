import { PinType } from 'app/shared/u21-ui/components/display/table/hooks/models';

import { ColumnPins } from 'app/shared/u21-ui/components/display/table/hooks/usePinColumns';

import { css } from 'styled-components';

export const PINNED_SHADOW_STYLE = css<{
  $pinType: PinType;
  $showShadow: boolean;
}>`
  ${(props) => {
    const { $pinType, $showShadow, theme } = props;
    if ($pinType === ColumnPins.CENTER || !$showShadow) {
      return css``;
    }
    return css`
      box-shadow: ${theme.palette.grey[500_10]}
        ${$pinType === ColumnPins.LEFT ? '' : '-'}10px 2px 10px 0;
    `;
  }}
`;
