import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { RulesActionTypes } from 'app/modules/rules/models';
import { GlobalWhitelistPaginatedPayload } from 'app/modules/entities/models';

// Actions
import {
  addEntitiesToGlobalWhitelist as addEntitiesToGlobalWhitelistAction,
  retrieveEntitiesFromGlobalWhitelist,
} from 'app/modules/rules/actions';
import { sendSuccessToast } from 'app/shared/toasts/actions';

// API
import { addEntitiesToGlobalWhitelist } from 'app/shared/api/entities';

// Constants
import { INITIAL_ENTITY_WHITELIST_PAGINATION_SETTINGS } from 'app/modules/rules/constants';

const rootAction = RulesActionTypes.GLOBALWHITELIST_ENTITIES;
function* globalWhitelistEntitiesForRule({
  payload,
}: ReturnType<typeof addEntitiesToGlobalWhitelistAction>) {
  const entityMessage =
    payload.entity_external_ids.length > 1 ? 'entities' : 'entity';
  const config = {
    rootAction,
    request: call(addEntitiesToGlobalWhitelist, payload),
    success: function* onSuccess() {
      yield put(sendSuccessToast(`Added ${entityMessage} to rule whitelist`));
      // Get new whitelisted entities with default pagination
      const request: GlobalWhitelistPaginatedPayload = {
        offset: INITIAL_ENTITY_WHITELIST_PAGINATION_SETTINGS.pageOffset,
        limit: INITIAL_ENTITY_WHITELIST_PAGINATION_SETTINGS.pageLimit,
      };
      yield put(retrieveEntitiesFromGlobalWhitelist(request));
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watchWhitelistEntitiesForRuleStatus() {
  yield takeLatest(rootAction, globalWhitelistEntitiesForRule);
}
