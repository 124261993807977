// Models
import { ScenarioParameters } from 'app/modules/detectionModels/models';
import { ScenarioConstants } from 'app/modules/rules/models';
import {
  EntityGraphLink,
  ScenarioBuilderWidgets,
} from 'app/modules/detectionModels/components/scenarioWidgets/models';
import { Unit21DataClassifier } from 'app/modules/dataSettings/responses';
import { CompositeQueryTreeNode } from 'app/modules/rulesAdvanced/types';
import {
  DmEventByObjectFact,
  DurationValue,
  IRSchema,
  QueryTreeNode,
} from 'app/modules/rulesAdvanced/types/RulesRepresentation';
import { cloneDeep } from 'lodash';
import { WINDOW_STRIDE_OPTIONS } from 'app/modules/rules/config/timeWindow';

export const EXECUTION_FREQUENCY_INVALID_OPTION = '-1h';

export const validateScenarioBlacklistParams = (
  params: ScenarioParameters,
): boolean => {
  return Object.prototype.hasOwnProperty.call(
    params,
    ScenarioConstants.BLACKLIST_ID,
  );
};

export const validatePerFieldBlacklistEditor = (
  params: ScenarioParameters,
): boolean => {
  return Boolean(params[ScenarioConstants.MATCH_CONDITIONS_VALIDATION_RESULT]);
};

export const validateSequenceCodeEditor = (
  params: ScenarioParameters,
): boolean => {
  return (params[ScenarioConstants.SEQUENCE_CONTENT] || []).length > 0;
};

export const validateComponentRulesSelector = (
  params: ScenarioParameters,
): boolean => {
  return Boolean(params.component_rule_ids);
};

export const WIDGET_VALIDATIONS: {
  [key in ScenarioBuilderWidgets]: (param: ScenarioParameters) => void;
} = {
  [ScenarioConstants.BLACKLIST_SELECTOR]: validateScenarioBlacklistParams,
  [ScenarioConstants.SEQUENCE_CODE_EDITOR]: validateSequenceCodeEditor,
  [ScenarioConstants.PER_FIELD_BLACKLIST_EDITOR]:
    validatePerFieldBlacklistEditor,
  [ScenarioConstants.COMPONENT_RULES_SELECTOR]: validateComponentRulesSelector,
  [ScenarioConstants.PER_FIELD_BLACKLIST_EDITOR_WONOLO]:
    validatePerFieldBlacklistEditor,
  [ScenarioConstants.LINK_ANALYSIS_MOCK]: () => true,
};

export const entityLinkToCustomDataKey = (
  link: EntityGraphLink,
): Unit21DataClassifier | undefined => {
  switch (link) {
    case 'address':
      return Unit21DataClassifier.ADDRESS;
    case 'entity_custom':
      return Unit21DataClassifier.ENTITY;
    case 'instrument':
      return Unit21DataClassifier.INSTRUMENT;
    default:
      return undefined;
  }
};

export const generateWatchlistSpec = (
  dynamicRuleCondition: CompositeQueryTreeNode,
): IRSchema => {
  return {
    facts: [],
    object: 'ENTITY',
    rule_condition: dynamicRuleCondition,
  };
};

export const findFirstFactForImplicitDmbRule = (
  spec: IRSchema | null,
): DmEventByObjectFact | null => {
  if (spec === null) {
    return null;
  }
  if (spec?.facts?.length !== 1) {
    return null;
  }
  if (spec.facts[0].type !== 'EVENT_BY_OBJECT_FACT') {
    return null;
  }
  return spec.facts[0];
};

export const findWindowStartOffsetForImplicitDmbRule = (
  spec: IRSchema | null,
): DurationValue | null => {
  const fact = findFirstFactForImplicitDmbRule(spec);
  if (!fact) return null;
  return fact.window_start_offset;
};

export const findFirstRuleConditionForImplicitDmbRule = (
  spec: IRSchema | null,
): QueryTreeNode | null => {
  if (spec === null) {
    return null;
  }
  if (spec?.rule_condition?.operands.length !== 1) {
    return null;
  }
  return spec.rule_condition?.operands[0];
};

export const convertRuleConditionToFactCondition = (
  ruleCondition: CompositeQueryTreeNode,
): CompositeQueryTreeNode => {
  return convertConditionType(
    ruleCondition,
    'rule_condition_composite',
    'COMPOSITE',
  );
};

export const convertConditionType = (
  ruleCondition: CompositeQueryTreeNode,
  fromType: 'COMPOSITE' | 'rule_condition_composite',
  toType: 'COMPOSITE' | 'rule_condition_composite',
): CompositeQueryTreeNode => {
  return {
    ...ruleCondition,
    type: toType,
    operands: ruleCondition.operands.map((i) => {
      if (i?.type === fromType) {
        return convertConditionType(i, fromType, toType);
      }
      return i;
    }),
  };
};

export const convertFactConditionToRuleCondition = (
  ruleCondition: CompositeQueryTreeNode,
): CompositeQueryTreeNode => {
  return convertConditionType(
    ruleCondition,
    'COMPOSITE',
    'rule_condition_composite',
  );
};

export const retrieveDynamicFactFilterAsRuleCondition = (
  spec: IRSchema | null,
  conditionsToIgnoreOnSpecCount: number | undefined = 1,
): CompositeQueryTreeNode | null => {
  const fact = findFirstFactForImplicitDmbRule(spec);
  if (!fact) {
    return null;
  }
  if (fact.event_filters?.type !== 'COMPOSITE') {
    return null;
  }
  if (fact.event_filters.operands.length < conditionsToIgnoreOnSpecCount + 1) {
    return null;
  }
  const queryTreeOperand =
    fact.event_filters.operands[conditionsToIgnoreOnSpecCount];
  if (queryTreeOperand?.type === 'COMPOSITE') {
    return convertFactConditionToRuleCondition(queryTreeOperand);
  }
  return null;
};

export const isDynamicConditionEmpty = (
  dynamicCondition: CompositeQueryTreeNode | null,
): boolean => {
  if (!dynamicCondition) {
    return true;
  }
  if (dynamicCondition.operands?.length === 0) {
    return true;
  }
  return false;
};

export const mapFrequencyToDuration = (
  executionFrequency: string,
): DurationValue => {
  /* Maps duration expressed as a string per convention into Duration */
  if (executionFrequency === EXECUTION_FREQUENCY_INVALID_OPTION) {
    return {
      type: 'DURATION',
      unit: 'HOUR',
      value: -1,
    };
  }
  const option = WINDOW_STRIDE_OPTIONS.find(
    (item) => item.value === executionFrequency,
  );
  if (!option) {
    return {
      type: 'DURATION',
      unit: 'HOUR',
      value: -1,
    };
  }
  if (option.minutes % (60 * 24 * 7) === 0) {
    // if weeks
    return {
      type: 'DURATION',
      unit: 'WEEK',
      value: option.minutes / (60 * 24 * 7),
    };
  }
  if (option.minutes % (60 * 24) === 0) {
    // if days
    return {
      type: 'DURATION',
      unit: 'DAY',
      value: option.minutes / (60 * 24),
    };
  }
  if (option.minutes % 60 === 0) {
    // if hours
    return {
      type: 'DURATION',
      unit: 'HOUR',
      value: option.minutes / 60,
    };
  }
  return {
    type: 'DURATION',
    unit: 'MINUTE',
    value: option.minutes,
  };
};

export const generateSpecificationWithExecutionFrequency = (
  executionFrequency: string,
  oldSpec: IRSchema | null,
): IRSchema | null => {
  if (!oldSpec) {
    return null;
  }
  const spec = cloneDeep(oldSpec);
  const fact = findFirstFactForImplicitDmbRule(spec);
  if (!fact) {
    return null;
  }
  fact.window_start_offset = mapFrequencyToDuration(executionFrequency);

  return spec;
};
