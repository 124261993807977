import { HTMLProps, useContext } from 'react';
import { FieldSubscription } from 'final-form';
import { ValidateFn } from 'app/shared/models/form';
import { U21HelpTooltipProps } from 'app/shared/u21-ui/components/display/U21HelpTooltip';

import { getDOMProps } from 'app/shared/utils/react';
import { isEqual as lodashIsEqual } from 'lodash';
import styled from 'styled-components';
import useId from '@mui/utils/useId';

import {
  Field as ReactFormField,
  FieldRenderProps,
  UseFieldConfig,
} from 'react-final-form';
import { FormControl } from '@mui/material';
import { U21ButtonGroup } from 'app/shared/u21-ui/components/input/button-group/U21ButtonGroup';
import { U21Checkbox } from 'app/shared/u21-ui/components/input/U21Checkbox';
import { U21CheckboxGroup } from 'app/shared/u21-ui/components/input/U21CheckboxGroup';
import { U21DatePicker } from 'app/shared/u21-ui/components/input/U21DatePicker';
import { U21DateRangePicker } from 'app/shared/u21-ui/components/input/U21DateRangePicker';
import { U21FormContext } from 'app/shared/u21-ui/components/form/U21FormContext';
import { U21FormError } from 'app/shared/u21-ui/components/form/U21FormError';
import { U21FormLabel } from 'app/shared/u21-ui/components/form/U21FormLabel';
import { U21MultiSelect } from 'app/shared/u21-ui/components/input/select/U21MultiSelect';
import { U21NumberUnitField } from 'app/shared/u21-ui/components/input/U21NumberUnitField';
import { U21RadioGroup } from 'app/shared/u21-ui/components/input/radio/U21RadioGroup';
import { U21Select } from 'app/shared/u21-ui/components/input/select/U21Select';
import { U21SelectSearch } from 'app/shared/u21-ui/components/input/U21SelectSearch';
import { U21Slider } from 'app/shared/u21-ui/components/input/U21Slider';
import { U21DateTimePicker } from 'app/shared/u21-ui/components/input/U21DateTimePicker';
import { U21TextField } from 'app/shared/u21-ui/components/input/text-field/U21TextField';
import { U21TextFieldProps } from 'app/shared/u21-ui/components/input/text-field/U21TextFieldInputProps';
import { U21NumberRange } from 'app/shared/u21-ui/components/input/U21NumberRange';
import { U21FileUploader } from 'app/shared/u21-ui/components/input/U21FileUploader';
import { RenderCount } from 'app/shared/u21-ui/components/form/RenderCount';
import { U21Typography } from 'app/shared/u21-ui/components/display/typography/U21Typography';
import { IconPercentage } from '@u21/tabler-icons';

// todo:
// fix FieldRenderProps<any>
// refactor ButtonGroup use onChange for consistency

export enum U21FormFieldTypes {
  BUTTON_GROUP = 'BUTTON_GROUP',
  CHECKBOX = 'CHECKBOX',
  CHECKBOX_GROUP = 'CHECKBOX_GROUP',
  DATE_PICKER = 'DATE_PICKER',
  DATE_RANGE_PICKER = 'DATE_RANGE_PICKER',
  MULTISELECT = 'MULTISELECT',
  NUMBER = 'NUMBER',
  INTEGER = 'INTEGER',
  NUMBER_UNIT = 'NUMBER_UNIT',
  PASSWORD = 'PASSWORD',
  RADIO_GROUP = 'RADIO_GROUP',
  SELECT = 'SELECT',
  SELECT_SEARCH = 'SELECT_SEARCH',
  SLIDER = 'SLIDER',
  TEXT = 'TEXT',
  TEXTAREA = 'TEXTAREA',
  NUMBER_RANGE = 'NUMBER_RANGE',
  FILE_UPLOADER = 'FILE_UPLOADER',
  DATE_TIME_PICKER = 'DATE_TIME_PICKER',
  PERCENTAGE = 'PERCENTAGE',
}

export interface U21FormFieldProps<
  FieldValue,
  FormValues extends Record<string, any>,
> extends Omit<HTMLProps<HTMLDivElement>, 'type'> {
  allowNull?: boolean;
  description?: string;
  disabled?: boolean;
  fieldProps?: Record<string, any>;
  help?: U21HelpTooltipProps['help'];
  hidden?: boolean;
  isEqual?: UseFieldConfig<FieldValue>['isEqual'];
  label?: string;
  name: string;
  required?: boolean;
  subscription?: FieldSubscription;
  suppressError?: boolean;
  type: `${U21FormFieldTypes}`;
  validate?: ValidateFn<FieldValue, FormValues>;
  displayRenderCount?: boolean;
}

// function to treat undefined and empty string as the same for U21Form so that
// we don't show the leave warning since effectively the input hasn't changed
const isEqualText = (a, b) => {
  if ((a === '' && b === undefined) || (a === undefined && b === '')) {
    return true;
  }
  return lodashIsEqual(a, b);
};

// function to treat undefined and false as the same for U21Form so that
// we don't show the leave warning since effectively the input hasn't changed
const isEqualBoolean = (a, b) => {
  if ((a === false && b === undefined) || (a === undefined && b === false)) {
    return true;
  }
  return lodashIsEqual(a, b);
};

const IS_EQUAL_BY_TYPE: Partial<
  Record<U21FormFieldTypes, UseFieldConfig<void>['isEqual']>
> = {
  [U21FormFieldTypes.CHECKBOX]: isEqualBoolean,
  [U21FormFieldTypes.PASSWORD]: isEqualText,
  [U21FormFieldTypes.TEXT]: isEqualText,
  [U21FormFieldTypes.TEXTAREA]: isEqualText,
};

const FORM_TEXT_FIELD_TYPE_MAPPING: Partial<
  Record<U21FormFieldTypes, U21TextFieldProps['type']>
> = {
  [U21FormFieldTypes.NUMBER]: 'number',
  [U21FormFieldTypes.INTEGER]: 'integer',
  [U21FormFieldTypes.PASSWORD]: 'password',
  [U21FormFieldTypes.TEXT]: 'text',
  [U21FormFieldTypes.TEXTAREA]: 'textarea',
};

export const U21FormField = <
  FieldValue,
  FormValues extends object = Record<string, any>,
>({
  description,
  disabled: disabledProp,
  fieldProps,
  help,
  hidden,
  label,
  name,
  required,
  subscription,
  suppressError = false,
  type,
  isEqual = IS_EQUAL_BY_TYPE[type] || lodashIsEqual,
  validate,
  displayRenderCount,
  allowNull,
  ...rest
}: U21FormFieldProps<FieldValue, FormValues>) => {
  const {
    disabled: disabledContext,
    loading,
    preventEnterSubmit,
  } = useContext(U21FormContext);
  const id = useId(fieldProps?.id);

  return (
    <ReactFormField
      isEqual={isEqual}
      name={name}
      subscription={{
        error: true,
        invalid: true,
        touched: true,
        value: true,
        ...subscription,
      }}
      validate={validate}
      allowNull={allowNull}
    >
      {({ input, meta }: FieldRenderProps<any>) => {
        if (hidden) {
          return null;
        }
        const error = meta.touched && meta.invalid;
        const disabled = disabledContext || disabledProp || loading;
        return (
          <div {...getDOMProps(rest)}>
            {process.env.NODE_ENV === 'development' && displayRenderCount && (
              <RenderCount />
            )}
            <FormControl error={error} fullWidth>
              {Boolean(label) && (
                <U21FormLabel
                  disabled={disabled}
                  id={`${id}-label`}
                  htmlFor={id}
                  error={error}
                  help={help}
                  required={required}
                >
                  {label}
                </U21FormLabel>
              )}
              {(() => {
                const formFieldProps = {
                  disabled,
                  error,
                  required,
                  ...fieldProps,
                  ...input,
                  onChange: (value, ...args) => {
                    input?.onChange(value);
                    fieldProps?.onChange?.(value, ...args);
                  },
                  value: input.value === '' ? undefined : input.value,
                };
                switch (type) {
                  case U21FormFieldTypes.BUTTON_GROUP:
                    return (
                      <U21ButtonGroup
                        buttons={[]}
                        {...formFieldProps}
                        onClick={formFieldProps.onChange}
                      />
                    );

                  case U21FormFieldTypes.CHECKBOX:
                    return (
                      <div>
                        <U21Checkbox
                          id={id}
                          {...formFieldProps}
                          checked={formFieldProps.value}
                        />
                      </div>
                    );

                  case U21FormFieldTypes.CHECKBOX_GROUP:
                    return (
                      <U21CheckboxGroup options={[]} {...formFieldProps} />
                    );

                  case U21FormFieldTypes.DATE_PICKER:
                    return <U21DatePicker id={id} {...formFieldProps} />;

                  case U21FormFieldTypes.DATE_RANGE_PICKER:
                    return (
                      <U21DateRangePicker
                        id={id}
                        {...formFieldProps}
                        value={input.value || []}
                      />
                    );

                  case U21FormFieldTypes.MULTISELECT:
                    return (
                      <U21MultiSelect
                        id={id}
                        options={[]}
                        {...formFieldProps}
                      />
                    );

                  case U21FormFieldTypes.NUMBER:
                  case U21FormFieldTypes.INTEGER:
                  case U21FormFieldTypes.PASSWORD:
                  case U21FormFieldTypes.TEXT:
                    return (
                      <U21TextField
                        id={id}
                        {...formFieldProps}
                        type={FORM_TEXT_FIELD_TYPE_MAPPING[type]}
                        onKeyDown={(e) => {
                          if (preventEnterSubmit && e.key === 'Enter') {
                            e.preventDefault();
                          }
                          // @ts-ignore formFieldProps could be any prop and we check for existence
                          formFieldProps.onKeyDown?.(e);
                        }}
                      />
                    );
                  case U21FormFieldTypes.PERCENTAGE: {
                    const value = input.value === '' ? undefined : input.value;
                    return (
                      <U21TextField
                        id={id}
                        {...formFieldProps}
                        type="integer"
                        value={value === undefined ? value : value * 100}
                        onChange={(newValue?: number, ...args) => {
                          const valueInDecimal =
                            newValue === undefined ? newValue : newValue / 100;
                          input?.onChange(valueInDecimal);
                          fieldProps?.onChange?.(valueInDecimal, ...args);
                        }}
                        endIcon={<IconPercentage />}
                      />
                    );
                  }

                  case U21FormFieldTypes.TEXTAREA:
                    return (
                      <U21TextField
                        id={id}
                        {...formFieldProps}
                        type="textarea"
                      />
                    );

                  case U21FormFieldTypes.NUMBER_UNIT:
                    return (
                      <U21NumberUnitField
                        id={id}
                        options={[]}
                        {...formFieldProps}
                      />
                    );

                  case U21FormFieldTypes.RADIO_GROUP:
                    return (
                      <U21RadioGroup id={id} options={[]} {...formFieldProps} />
                    );

                  case U21FormFieldTypes.SELECT:
                    return (
                      <U21Select id={id} options={[]} {...formFieldProps} />
                    );

                  case U21FormFieldTypes.SELECT_SEARCH:
                    return (
                      <U21SelectSearch
                        id={id}
                        onSearch={() => {}}
                        options={[]}
                        {...formFieldProps}
                      />
                    );

                  case U21FormFieldTypes.SLIDER:
                    return <U21Slider {...formFieldProps} />;

                  case U21FormFieldTypes.NUMBER_RANGE:
                    return (
                      <U21NumberRange
                        {...formFieldProps}
                        value={input.value || []}
                      />
                    );

                  case U21FormFieldTypes.FILE_UPLOADER: {
                    const { onChange, ...restFormFieldProps } = formFieldProps;
                    return (
                      <U21FileUploader
                        onDrop={formFieldProps.onChange}
                        {...restFormFieldProps}
                      />
                    );
                  }

                  case U21FormFieldTypes.DATE_TIME_PICKER:
                    return <U21DateTimePicker id={id} {...formFieldProps} />;

                  default:
                    return null;
                }
              })()}
              {!suppressError && <U21FormError error={error && meta.error} />}
              <StyledU21Typography color="text.secondary" variant="body2">
                {description}
              </StyledU21Typography>
            </FormControl>
          </div>
        );
      }}
    </ReactFormField>
  );
};

const StyledU21Typography = styled(U21Typography)`
  margin-top: 4px;
`;
