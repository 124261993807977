import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------
// CUSTOM CHANGES
// - removed box shadow
// - added border
// - updated padding
// - updated default title + subheader typography props
// - updated avatar margin right
// - removed action margin
// - added action center align

export default function Card(theme: Theme) {
  return {
    MuiCard: {
      styleOverrides: {
        root: {
          border: `1px solid ${theme.palette.grey[500_24]}`,
          borderRadius: theme.shape.borderRadiusMd,
          position: 'relative',
          zIndex: 0, // Fix Safari overflow: hidden with border radius
          padding: theme.spacing(3),
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: { variant: 'h4' },
        subheaderTypographyProps: { variant: 'body1' },
      },
      styleOverrides: {
        root: {
          padding: 0,
        },
        action: {
          margin: 0,
          alignSelf: 'center',
        },
        avatar: {
          marginRight: 8,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 0,
          ':last-child': {
            paddingBottom: 0,
          },
        },
      },
    },
  };
}
