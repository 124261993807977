import { IconExternalLink } from '@u21/tabler-icons';
import {
  U21Typography,
  U21TypographyProps,
} from 'app/shared/u21-ui/components/display/typography/U21Typography';
import { U21ExternalLinkModal } from 'app/shared/u21-ui/components/display/U21ExternalLinkModal';
import { U21Button } from 'app/shared/u21-ui/components/input/U21Button';
import { useState } from 'react';
import styled from 'styled-components';

export interface U21ExternalLinkProps {
  url: string;
  variant?: U21TypographyProps['variant'];
}

export const U21ExternalLink = ({ url, variant }: U21ExternalLinkProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      <StyledButton
        onClick={() => setIsOpen(true)}
        endIcon={<IconExternalLink />}
        variant="text"
        color="primary"
      >
        <U21Typography color="inherit" ellipsis variant={variant}>
          {url}
        </U21Typography>
      </StyledButton>
      <U21ExternalLinkModal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        url={url}
      />
    </>
  );
};

const StyledButton = styled(U21Button)`
  max-width: 100%;
`;
