import { JsonViewer, JsonViewerProps } from '@textea/json-viewer';
import styled, { css } from 'styled-components';

export interface U21JsonViewerProps
  extends Omit<JsonViewerProps, 'rootName' | 'value' | 'onCopy'> {
  data: object;
  isDefaultCollapsed?: boolean;
}

// API reference: https://viewer.textea.io/apis
export const U21JsonViewer = ({
  data,
  isDefaultCollapsed,
  defaultInspectDepth,
  ...rest
}: U21JsonViewerProps) => (
  <StyledJsonViewer
    {...rest}
    rootName={false}
    value={data}
    defaultInspectDepth={isDefaultCollapsed ? 0 : defaultInspectDepth}
  />
);

const StyledJsonViewer = styled(JsonViewer)`
  padding: 8px;
  ${(props) => css`
    background-color: ${props.theme.palette.background.neutral};
    border-radius: ${props.theme.shape.borderRadius}px;
  `}

  ${(props) => props.theme.typography.body2};
`;
