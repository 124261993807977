import { Component, ReactNode } from 'react';

// Components
import { U21Loading } from 'app/shared/u21-ui/components';
import styled from 'styled-components';

interface OwnProps {
  children: ReactNode;
  loading: boolean;
  styleProps?: StyleMap;
  firstLoadOnly?: boolean;
  // used to indicate no data needs to be retrieved
  fetchedData?: boolean;
}

type AllProps = OwnProps;

interface AllState {
  fetchedData: boolean;
}

/**
 * @deprecated Use U21Loading instead
 */
class LoadingWrapper extends Component<AllProps, AllState> {
  constructor(props: AllProps) {
    super(props);
    this.state = {
      // used to show loading state before data is fetched
      // can override this with props if already fetched
      fetchedData: Boolean(props.fetchedData),
    };
  }

  componentDidUpdate(prevProps: AllProps) {
    // once we start loading for the first time, set fetching data to true
    // avoid doing this if fetchedData is already set to true
    const { loading } = this.props;
    const { fetchedData } = this.state;
    if (prevProps.loading !== loading && !fetchedData) {
      this.setState({ fetchedData: true });
    }
  }

  render() {
    const { loading, styleProps, firstLoadOnly, children } = this.props;
    const { fetchedData } = this.state;

    // return UI if firstLoadOnly and already fetched data
    if ((fetchedData && firstLoadOnly) || process.env.NODE_ENV === 'test') {
      return <>{children}</>;
    }

    // return loading if we have not fetchedData or loading
    if (!fetchedData || loading) {
      return (
        <Container style={styleProps || {}}>
          <StyledU21Loading loading />
        </Container>
      );
    }
    // not loading, return children
    return <>{children}</>;
  }
}

const Container = styled.div`
  height: 100%;
`;

const StyledU21Loading = styled(U21Loading)`
  height: 100%;
`;

export default LoadingWrapper;
