import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Actions
import {
  retrieveEntityAlerts as retrieveEntityAlertsAction,
  retrieveEntityAlertsSuccess,
} from 'app/modules/entities/actions';

// API
import { getEntityAlerts } from 'app/shared/api/entities';

// Models
import { EntitiesActionTypes } from 'app/modules/entities/models';

const rootAction = EntitiesActionTypes.RETRIEVE_ENTITY_ALERTS;
function* retrieveEntityAlerts({
  payload,
}: ReturnType<typeof retrieveEntityAlertsAction>) {
  const config = {
    rootAction,
    request: call(getEntityAlerts, payload),
    success: function* onSuccess(result) {
      yield put(retrieveEntityAlertsSuccess(result));
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watchRetrieveEntityAlerts() {
  yield takeLatest(rootAction, retrieveEntityAlerts);
}
