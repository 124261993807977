import { ColorColorSchema } from 'vendor/material-minimal/palette';

// Constants
import { BUTTON_ICON_MAPPING } from 'app/modules/workflows/constants';

// Helpers
import {
  getButtonColor,
  getWorkflowButtonDetailsPath,
} from 'app/modules/workflows/helpers';

import styled, { css } from 'styled-components';

// Components
import {
  U21Button,
  U21ButtonSize,
  U21TooltipProps,
} from 'app/shared/u21-ui/components';
import { useHistory } from 'react-router';
import { ActionTriggerContexts } from 'app/modules/webhooks/models';
import { WorkflowContexts } from 'app/modules/workflows/models';

interface OwnProps {
  // TODO: Add Workflow type after BE changes, currently using old ActionTrigger models along with new Workflow model
  item: Record<string, any>;
  disabled?: boolean;
  tooltip?: U21TooltipProps['tooltip'];
  tooltipProps?: Omit<U21TooltipProps, 'children' | 'tooltip'>;
  onClick?: () => void;
  size?: U21ButtonSize;
  toWorkflow?: boolean;
}
export const WorkflowButton = ({
  item,
  onClick,
  disabled = false,
  tooltip,
  tooltipProps,
  size = 'medium',
  toWorkflow = false,
}: OwnProps) => {
  const history = useHistory();
  const handleOnClick = toWorkflow
    ? () =>
        history.push(
          getWorkflowButtonDetailsPath(
            item.context === ActionTriggerContexts.ALERT
              ? WorkflowContexts.ALERT
              : WorkflowContexts.CASE,
            item.id,
          ),
        )
    : onClick;
  // need to wrap in a div to prevent buttons from stretching to container width
  return (
    <StyledU21ButtonWrapper>
      <StyledU21Button
        $color={item.color}
        size={size}
        startIcon={BUTTON_ICON_MAPPING[item.icon]}
        onClick={handleOnClick}
        disabled={disabled}
        tooltip={tooltip}
        tooltipProps={tooltipProps}
        variant="contained"
        $static={!onClick && !handleOnClick}
      >
        <OverflowSpan>{item.label ?? 'Label'}</OverflowSpan>
      </StyledU21Button>
    </StyledU21ButtonWrapper>
  );
};

const StyledU21Button = styled(U21Button)<{
  $color: ColorColorSchema;
  $static: boolean;
}>`
  max-width: 100%;
  ${(props) => getButtonColor(props.$color)}
  ${(props) => {
    const { color, background, hoverBackground } = getButtonColor(props.$color);
    if (props.$static) {
      return css`
        pointer-events: none;
      `;
    }
    return css`
      color: ${color};
      background: ${background};
      cursor: pointer;
      &.MuiButton-root {
        :hover {
          background-color: ${hoverBackground};
          border: none;
        }
      }
    `;
  }}
  border-width: 0;

  // these are user-specified labels and should not be transformed
  text-transform: none;
`;

const OverflowSpan = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

// Needed for disabled button overflow
const StyledU21ButtonWrapper = styled.div`
  & > span {
    max-width: 100%;
  }
`;
