export enum DispositionIsFraud {
  NOT_DEFINED = 'NOT_DEFINED',
  NOT_FRAUD = 'NOT_FRAUD',
  IS_FRAUD = 'IS_FRAUD',
}

export enum DispositionType {
  TRUE_POSITIVE = 'TRUE_POSITIVE',
  TRUE_POSITIVE_BLOCKED = 'TRUE_POSITIVE_BLOCKED',
  FALSE_POSITIVE_HELPFUL = 'FALSE_POSITIVE_HELPFUL',
  FALSE_POSITIVE = 'FALSE_POSITIVE',
  UNDER_INVESTIGATION = 'UNDER_INVESTIGATION',
  UNDETERMINED = 'UNDETERMINED',
  UNKNOWN = 'UNKNOWN',
}

export const DISPOSITION_TYPE_OPTIONS = [
  {
    text: 'Closing review - took action',
    value: DispositionType.TRUE_POSITIVE,
    description:
      'This is the type of behavior we are trying to stop by using Unit21',
  },
  {
    text: 'Blocking this user',
    value: DispositionType.TRUE_POSITIVE_BLOCKED,
    description:
      'This indicates that the user will be blocked from the platform',
  },
  {
    text: 'Dismissing this, but it was worth spending the time to look at this',
    value: DispositionType.FALSE_POSITIVE_HELPFUL,
    description:
      'The user’s behavior was correctly flagged, but it was explainable after reviewing more carefully or getting additional information',
  },
  {
    text: 'Dismissing this - this should not have reached this stage of the process',
    value: DispositionType.FALSE_POSITIVE,
    description:
      'This should have never generated an alert, or should not have been escalated to a case',
  },
  {
    text: 'We are moving this forward in our review process',
    value: DispositionType.UNDER_INVESTIGATION,
    description:
      'This represents an interim workflow state, e.g. moving from L1 to L2',
  },
  {
    text: 'Not applicable',
    value: 'UNDETERMINED',
  },
];

export interface EditDispositionFormValues {
  title: string;
  type: DispositionType;
}
