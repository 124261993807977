import { destroy, get, post, put } from 'app/shared/utils/fetchr';

// Models
import {
  TeamAgentsPayload,
  CreateTeamPayload,
  CheckAssignmentChangesPayload,
  EditTeamPayload,
  RemoveTeamAgentsPayload,
} from 'app/modules/teams/requests';
import {
  AddTeamAgentsResponse,
  CheckAssignmentChangesResponse,
  FullTeamResponse,
  PaginatedTeamsResponse,
} from 'app/modules/teams/responses';
import { PaginationPayload } from 'app/shared/pagination/models';

export const addAgentToTeam = (id: number, payload: TeamAgentsPayload) => {
  return post<AddTeamAgentsResponse>(`/teams/${id}/agents/add`, payload);
};

export const removeAgentFromTeam = (id: number, payload: TeamAgentsPayload) => {
  return post(`/teams/${id}/agents/remove`, payload);
};

export const retrievePaginatedTeams = (
  payload: PaginationPayload,
): Promise<PaginatedTeamsResponse> => {
  return post('/teams/list', payload);
};

export const createTeam = (
  payload: CreateTeamPayload,
): Promise<FullTeamResponse> => {
  return post('/teams/create', payload);
};

export const retrieveTeamDetails = (id: number): Promise<FullTeamResponse> => {
  return get(`/teams/${id}`);
};

export const checkAssignmentChangesApi = (
  payload: CheckAssignmentChangesPayload,
): Promise<CheckAssignmentChangesResponse> => {
  return get('/teams/check-assignment-changes', [
    ...payload.team_ids.map((id) => ({ key: 'team_ids', value: `${id}` })),
    ...payload.agent_ids.map((id) => ({ key: 'agent_ids', value: `${id}` })),
  ]);
};

export const deleteTeam = (id: number) => {
  return destroy(`/teams/${id}`);
};

export const editTeam = (
  id: number,
  payload: EditTeamPayload,
): Promise<FullTeamResponse> => {
  return put(`/teams/${id}`, payload);
};

export const removeTeamAgents = (
  teamId: number,
  payload: RemoveTeamAgentsPayload,
) => post(`/teams/${teamId}/agents/remove`, payload);
