import startSession from 'app/modules/session/sagas/initSession';
import retrieveIDP from 'app/modules/session/sagas/retrieveIDP';
import tokenPing from 'app/modules/session/sagas/tokenPing';
import watchRetrieveActionTriggers from 'app/modules/session/sagas/retrieveActionTriggers';
import initIntercom from 'app/modules/session/sagas/initIntercom';
import emailTokenExchange from 'app/modules/session/sagas/emailTokenExchange';
import retrieveRelevantOrgs from 'app/modules/session/sagas/retrieveRelevantOrgs';

const sagaRegistry = [
  startSession,
  retrieveIDP,
  tokenPing,
  retrieveRelevantOrgs,
  watchRetrieveActionTriggers,
  initIntercom,
  emailTokenExchange,
];

export default sagaRegistry;
