import { format } from 'date-fns-tz';
import { formatDistanceToNow } from 'date-fns';

interface TimeOptions {
  showTimezone?: boolean;
  timezone?: string | null;
}

export const DATE_FORMAT_OPTIONS: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
};

export const TIME_FORMAT_OPTIONS: Intl.DateTimeFormatOptions = {
  hour: 'numeric',
  minute: '2-digit',
  hour12: true,
};

export const formatDate = (
  date?: number | string | Date | null,
  formatStr?: string,
  options: TimeOptions = {},
): string => {
  if (!date) {
    return '';
  }
  const dateObj = new Date(date);
  if (isNaN(dateObj.getTime())) {
    return 'Invalid date';
  }
  if (formatStr) {
    return format(dateObj, formatStr);
  }
  const { showTimezone = false, timezone = null } = options;
  return new Intl.DateTimeFormat('en-US', {
    ...DATE_FORMAT_OPTIONS,
    timeZone: timezone || undefined,
    timeZoneName: showTimezone ? 'short' : undefined,
  }).format(dateObj);
};

export const formatDatetime = (
  date?: number | string | Date | null,
  formatStr?: string,
  options: TimeOptions = {},
): string => {
  if (!date) {
    return '';
  }

  const dateObj = new Date(date);
  if (isNaN(dateObj.getTime())) {
    return 'Invalid date';
  }
  if (formatStr) {
    return format(dateObj, formatStr);
  }
  const { showTimezone = false, timezone = null } = options;
  return new Intl.DateTimeFormat('en-US', {
    ...DATE_FORMAT_OPTIONS,
    ...TIME_FORMAT_OPTIONS,
    timeZone: timezone || undefined,
    timeZoneName: showTimezone ? 'short' : undefined,
  }).format(dateObj);
};

export const formatTime = (
  date?: number | string | Date | null,
  formatStr?: string,
): string => {
  if (!date) {
    return '';
  }
  const dateObj = new Date(date);
  if (isNaN(dateObj.getTime())) {
    return 'Invalid date';
  }
  if (formatStr) {
    return format(dateObj, formatStr);
  }
  return new Intl.DateTimeFormat('en-US', TIME_FORMAT_OPTIONS).format(dateObj);
};

export const formatDateToNow = (
  date?: number | string | Date | null,
  options?: {
    includeSeconds?: boolean;
    addSuffix?: boolean;
  },
) => {
  if (!date) {
    return '';
  }
  return formatDistanceToNow(new Date(date), options);
};

// Turn frequencies like "30min" to "30 minutes", "1d" to "1 day", etc.
export const toReadableFrequency = (frequency: string) => {
  const [num, unit] = frequency.split(/(\D+)/);
  // Corresponds to TIME_UNIT_MAPPING in Umbridge/rule_engine/rule_constants.py
  const unitMap = {
    min: 'minute',
    h: 'hour',
    d: 'day',
    w: 'week',
    m: 'month',
    y: 'year',
  };
  const unitStr = unitMap[unit];
  return `${num} ${unitStr}${num === '1' ? '' : 's'}`;
};
