import { isValidElement, ReactElement, useMemo } from 'react';

import {
  anyPermissionChecker,
  hasPermissionChecker,
} from 'app/modules/permissions/utils';
import { selectFeatureFlags } from 'app/shared/featureFlags/selectors';
import { useSelector } from 'react-redux';

import {
  U21TabRouter,
  U21TabRouterItem,
  U21TabRouterProps,
} from 'app/shared/u21-ui/components';
import { selectSessionAgentPermissions } from 'app/modules/session/selectors';
import { FeatureFlag } from 'app/shared/featureFlags/models';

export interface U21PermissionedTabRouterItem extends U21TabRouterItem {
  anyPermissions?: string[];
  permissions?: string[]; // todo: rename to hasPermissions
  featureFlag?: FeatureFlag;
  hideOnFeatureFlag?: FeatureFlag;
}

export interface U21PermissionedTabRouterProps
  extends Omit<U21TabRouterProps, 'tabs'> {
  tabs: (U21PermissionedTabRouterItem | ReactElement)[];
}

export const U21PermissionedTabRouter = (
  props: U21PermissionedTabRouterProps,
) => {
  const { tabs, ...rest } = props;
  const permissions = useSelector(selectSessionAgentPermissions);
  const featureFlags = useSelector(selectFeatureFlags);

  const accessibleTabs = useMemo(
    () =>
      tabs
        .filter((i) => {
          if (isValidElement<any>(i)) {
            return true;
          }
          // make sure the feature flag is turned on if specified
          if (i.featureFlag && !featureFlags[i.featureFlag]) {
            return false;
          }
          // If the tab is specified to *not* be shown when specified feature flag is turned on
          if (i.hideOnFeatureFlag && featureFlags[i.hideOnFeatureFlag]) {
            return false;
          }
          if (
            i.permissions &&
            !hasPermissionChecker(permissions, i.permissions)
          ) {
            return false;
          }
          if (
            i.anyPermissions &&
            !anyPermissionChecker(permissions, i.anyPermissions)
          ) {
            return false;
          }
          return true;
        })
        .map((i) => {
          if (isValidElement<any>(i)) {
            return i;
          }
          const {
            anyPermissions: ignore,
            featureFlag,
            permissions: drop,
            ...tabItem
          } = i;
          return tabItem;
        }),
    [featureFlags, permissions, tabs],
  );
  return <U21TabRouter tabs={accessibleTabs} {...rest} />;
};
U21PermissionedTabRouter.Divider = U21TabRouter.Divider;
