import retrieveWorkflow from 'app/modules/workflows/sagas/retrieveWorkflow';
import retrieveWorkflows from 'app/modules/workflows/sagas/retrieveWorkflows';
import retrieveSubdispositions from 'app/modules/workflows/sagas/retrieveSubdispositions';
import createWorkflow from 'app/modules/workflows/sagas/createWorkflow';
import editWorkflow from 'app/modules/workflows/sagas/editWorkflow';
import deleteWorkflow from 'app/modules/workflows/sagas/deleteWorkflow';

const sagaRegistry = [
  retrieveWorkflow,
  retrieveWorkflows,
  retrieveSubdispositions,
  createWorkflow,
  editWorkflow,
  deleteWorkflow,
];

export default sagaRegistry;
