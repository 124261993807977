import { ReactNode, useMemo } from 'react';
import { U21Themes } from 'vendor/material-minimal/models';

import { SCALING_FACTOR } from 'vendor/material-minimal/spacing';

import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeOptions, ThemeProvider } from '@mui/material/styles';
import { ThemeProvider as SCThemeProvider } from 'styled-components';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';

import shape from 'vendor/material-minimal/shape';
import palette from 'vendor/material-minimal/palette';
import typography from 'vendor/material-minimal/typography';
import breakpoints from 'vendor/material-minimal/breakpoints';
import GlobalStyles from 'vendor/material-minimal/globalStyles';
import componentsOverride from 'vendor/material-minimal/overrides';
import shadows, { customShadows } from 'vendor/material-minimal/shadows';

import '@fontsource/lato';

// ----------------------------------------------------------------------
// CUSTOM CHANGES
// - add styled components theme provider
// - removed settings hook
// - defined themes
// - createMuiTheme -> createTheme
// - SCALING_FACTOR constant
// - renamed component
// - changed to named export
// - added lato font import

interface Props {
  children: ReactNode;
  direction?: 'ltr' | 'rtl';
  mode?: 'light' | 'dark';
}

export const U21Theme = (props: Props) => {
  const { children, direction = 'ltr', mode = U21Themes.LIGHT } = props;

  const theme = useMemo(() => {
    const isLight = mode === U21Themes.LIGHT;
    const config: ThemeOptions = {
      palette: isLight
        ? { ...palette.light, mode: 'light' }
        : { ...palette.dark, mode: 'dark' },
      typography,
      shape,
      breakpoints,
      spacing: SCALING_FACTOR,
      direction,
      shadows: isLight ? shadows.light : shadows.dark,
      customShadows: isLight ? customShadows.light : customShadows.dark,
    };
    const muiTheme = createTheme(config);
    muiTheme.components = componentsOverride(muiTheme);
    return muiTheme;
  }, [direction, mode]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        <SCThemeProvider theme={theme}>{children}</SCThemeProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
