import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import {
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchContentRef,
} from 'react-zoom-pan-pinch';
import ReactCrop, { type Crop } from 'react-image-crop';
import {
  IconCrop,
  IconFlipHorizontal,
  IconFlipVertical,
  IconRotateClockwise,
  IconWand,
  IconZoomIn,
  IconZoomOut,
} from '@u21/tabler-icons';
import {
  U21Spacer,
  U21Divider,
  U21Slider,
  U21Button,
  U21Typography,
  U21MenuLayout,
} from 'app/shared/u21-ui/components';
import {
  flipImageOnHorizontalAxis,
  flipImageOnVerticalAxis,
  rotateImageClockwise,
  initialiseCanvases,
  applyImageFilter,
  copyCanvas,
  cropImage,
} from 'app/shared/u21-ui/components/dashboard/imageEditor/helper';
import { alpha } from '@mui/system/colorManipulator';
import { isWindows } from 'app/shared/utils/system';

export interface U21ImageEditorProps {
  imgSrc: string;
  isCopy?: boolean;
  setParentOffsetCanvas?: (offsetCanvas: OffscreenCanvas) => void;
}

export const U21ImageEditor = ({
  imgSrc,
  isCopy,
  setParentOffsetCanvas,
}: U21ImageEditorProps) => {
  const mainCanvasRef = useRef<HTMLCanvasElement | null>(null);
  const cropCanvasRef = useRef<HTMLCanvasElement | null>(null);
  const rawCanvasRef = useRef<OffscreenCanvas | null>(null);
  const transformRef = useRef<ReactZoomPanPinchContentRef | null>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const [crop, setCrop] = useState<Crop>({
    unit: 'px',
    x: 0,
    y: 0,
    width: 100,
    height: 100,
  });

  const [image, setImage] = useState<HTMLImageElement | null>(null);
  const [aspectRatio, setAspectRatio] = useState<number>(1);

  const [brightness, setBrightness] = useState<number>(100);
  const [contrast, setContrast] = useState<number>(100);
  const [saturation, setSaturation] = useState<number>(100);
  const [grayscale, setGrayscale] = useState<number>(0);
  const [isCropping, setIsCropping] = useState<boolean>(false);

  // Popover operations
  const [beautifyOpen, setBeautifyOpen] = useState<boolean>(false);

  useEffect(() => {
    if (isCopy) {
      const mainCanvas = mainCanvasRef.current;
      const mainContext = mainCanvas?.getContext('2d');

      if (setParentOffsetCanvas && mainCanvas && mainContext) {
        const parentCanvas = new OffscreenCanvas(
          mainCanvas.width,
          mainCanvas.height,
        );
        const parentContext = parentCanvas.getContext('2d');

        if (parentContext) {
          parentContext.clearRect(0, 0, mainCanvas.width, mainCanvas.height);
          parentContext.drawImage(mainCanvas, 0, 0);

          setParentOffsetCanvas(parentCanvas);
        }
      }
    }
  }, [isCopy, setParentOffsetCanvas]);

  useEffect(() => {
    const img = new Image();
    img.src = imgSrc;
    img.onload = () => {
      setImage(img);
      setAspectRatio(img.width / img.height);
      rawCanvasRef.current = initialiseCanvases(
        mainCanvasRef,
        cropCanvasRef,
        img,
      );

      if (transformRef.current) {
        transformRef.current.resetTransform(0);
        transformRef.current.centerView(undefined, 0);
      }
    };
  }, [imgSrc]);

  useEffect(() => {
    applyImageFilter(
      mainCanvasRef,
      rawCanvasRef,
      brightness,
      contrast,
      grayscale,
      saturation,
    );
  }, [brightness, contrast, grayscale, saturation]);

  const confirmCrop = useCallback(() => {
    setAspectRatio(cropImage(crop, mainCanvasRef, cropCanvasRef, rawCanvasRef));
    setIsCropping(false);

    if (transformRef.current) {
      transformRef.current.resetTransform(0);
      transformRef.current.centerView(undefined, 0);
    }
  }, [crop]);

  const mainButtons = useMemo<
    {
      onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
      icon: ReactElement;
      label: string;
      ariaLabel: string;
    }[]
  >(
    () => [
      {
        label: 'rotate',
        icon: <IconRotateClockwise color="white" />,
        ariaLabel: 'Rotate image',
        onClick: () => {
          setAspectRatio(rotateImageClockwise(mainCanvasRef, rawCanvasRef));

          if (transformRef.current) {
            transformRef.current.centerView(undefined, 0);
          }
        },
      },
      {
        label: 'isFlipHorizontal',
        ariaLabel: 'Flip image along horizontal axis',
        icon: <IconFlipHorizontal color="white" />,
        onClick: () => flipImageOnHorizontalAxis(mainCanvasRef, rawCanvasRef),
      },
      {
        label: 'isFlipVertical',
        ariaLabel: 'Flip image along vertical axis',
        icon: <IconFlipVertical color="white" />,
        onClick: () => flipImageOnVerticalAxis(mainCanvasRef, rawCanvasRef),
      },
      {
        label: 'crop',
        ariaLabel: 'Crop image',
        icon: <IconCrop color="white" />,
        onClick: () => {
          copyCanvas(mainCanvasRef, cropCanvasRef);

          if (transformRef.current) {
            transformRef.current.resetTransform(0);
            transformRef.current.centerView(undefined, 0);
          }

          setIsCropping((prevIsCropping) => !prevIsCropping);
        },
      },
    ],
    [],
  );

  const beautifyOptions = useMemo<
    { onChange: (val: number) => void; value: number; label: string }[]
  >(
    () => [
      {
        label: 'Brightness',
        value: brightness,
        onChange: (val: number) => setBrightness(val),
      },
      {
        label: 'Contrast',
        value: contrast,
        onChange: (val: number) => setContrast(val),
      },
      {
        label: 'Saturate',
        value: saturation,
        onChange: (val: number) => setSaturation(val),
      },
      {
        label: 'Grayscale',
        value: grayscale,
        onChange: (val: number) => setGrayscale(val),
      },
    ],
    [brightness, contrast, saturation, grayscale],
  );

  const resetBeautify = () => {
    setBrightness(100);
    setContrast(100);
    setSaturation(100);
    setGrayscale(0);
  };

  return (
    <WrapperDiv ref={wrapperRef}>
      <ZoomWrapper>
        <TransformWrapper
          centerOnInit
          ref={transformRef}
          disabled={isCropping}
          wheel={{ activationKeys: isWindows() ? ['Control'] : ['Meta'] }}
        >
          <TransformComponent
            wrapperStyle={{
              height: '100%',
              width: '100%',
            }}
            contentStyle={{
              height: aspectRatio > 1 ? 'fit-content' : '100%',
              width: aspectRatio > 1 ? '100%' : `fit-content`,
              aspectRatio,
            }}
          >
            <StyledCropper
              $isCropping={isCropping}
              crop={crop}
              onChange={(c) => setCrop(c)}
            >
              <CropCanvas ref={cropCanvasRef} />
            </StyledCropper>

            <MainCanvas ref={mainCanvasRef} $isCropping={isCropping} />
          </TransformComponent>
        </TransformWrapper>
      </ZoomWrapper>

      <ButtonSpacer align="center">
        <U21Spacer horizontal>
          {!isCropping ? (
            <>
              <OperationButtonWrapper>
                <U21MenuLayout
                  alignRight
                  onClose={() => setBeautifyOpen(false)}
                  open={beautifyOpen}
                  trigger={
                    <StyledButton
                      key="beautify"
                      aria-label="Beautify image"
                      icon={<IconWand color="white" />}
                      onClick={() => setBeautifyOpen(true)}
                    />
                  }
                >
                  {beautifyOptions.map(({ value, label, onChange }) => (
                    <InputGrid key={label}>
                      <StyledU21Typography variant="body2">
                        {label}:
                      </StyledU21Typography>
                      <StyledU21Slider
                        autoFocus
                        valueLabelFormat={(val) => `${val}%`}
                        value={value}
                        max={200}
                        onChange={onChange}
                      />
                      <U21Typography variant="body2">{value}%</U21Typography>
                    </InputGrid>
                  ))}
                  <U21Button onClick={resetBeautify}>Reset</U21Button>
                </U21MenuLayout>

                {mainButtons.map(({ icon, label, onClick, ariaLabel }) => (
                  <StyledButton
                    key={label}
                    aria-label={ariaLabel}
                    icon={icon}
                    onClick={onClick}
                  />
                ))}

                <U21Divider flexItem />

                <StyledButton
                  aria-label="Zoom in image"
                  icon={<IconZoomIn color="white" />}
                  onClick={() => {
                    if (transformRef.current) {
                      transformRef.current.zoomIn();
                    }
                  }}
                />
                <StyledButton
                  aria-label="Zoom out image"
                  icon={<IconZoomOut color="white" />}
                  onClick={() => {
                    if (transformRef.current) {
                      transformRef.current.zoomOut();
                    }
                  }}
                />
              </OperationButtonWrapper>
              <U21Button
                onClick={() => {
                  if (image) {
                    rawCanvasRef.current = initialiseCanvases(
                      mainCanvasRef,
                      cropCanvasRef,
                      image,
                    );

                    if (transformRef.current) {
                      transformRef.current.resetTransform(0);
                      transformRef.current.centerView(undefined, 0);
                    }

                    resetBeautify();
                    setAspectRatio(image.width / image.height);
                  }
                }}
                size="medium"
                variant="contained"
              >
                Start Over
              </U21Button>
            </>
          ) : (
            <>
              <U21Button
                onClick={() => setIsCropping(false)}
                size="medium"
                color="error"
                variant="contained"
              >
                Cancel
              </U21Button>
              <U21Button
                onClick={confirmCrop}
                size="medium"
                color="success"
                variant="contained"
              >
                Crop
              </U21Button>
            </>
          )}
        </U21Spacer>
      </ButtonSpacer>
    </WrapperDiv>
  );
};

const WrapperDiv = styled.div`
  width: 100%;
  height: 100%;
`;

const ZoomWrapper = styled.div`
  width: 100%;
  height: calc(100% - 40px);
`;

const StyledCropper = styled(ReactCrop)<{
  $isCropping: boolean;
}>`
  height: 100%;
  width: 100%;
  display: ${({ $isCropping }) => ($isCropping ? 'inherit' : 'none')};

  .ReactCrop__child-wrapper {
    height: 100%;
    width: 100%;
  }
`;

const MainCanvas = styled.canvas<{ $isCropping: boolean }>`
  height: 100%;
  width: 100%;
  display: ${({ $isCropping }) => ($isCropping ? 'none' : 'inherit')};
`;

const CropCanvas = styled.canvas`
  width: 100%;
  height: 100%;
`;

const ButtonSpacer = styled(U21Spacer)`
  margin-top: 8px;
`;

const OperationButtonWrapper = styled.div`
  display: flex;
  background-color: ${(props) => alpha(props.theme.palette.grey[800], 0.8)};
  border-radius: 8px;
  padding: 4px;
`;

const StyledButton = styled(U21Button)`
  border-radius: 0;
  background-color: transparent;
  border: 0px;
  padding: 4px;

  &:hover {
    background-color: ${(props) => alpha(props.theme.palette.grey[800], 0.8)};
  }
`;

const StyledU21Typography = styled(U21Typography)`
  margin-top: 20px;
`;

const StyledU21Slider = styled(U21Slider)`
  .MuiSlider-root {
    margin-top: 0px;
  }
`;

const InputGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 0.6fr;
  grid-auto-rows: auto;
  column-gap: 0px;
  padding: 5px;
  margin-top: 8px;
  margin-bottom: 16px;
`;
