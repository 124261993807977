import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { NotesActionTypes } from 'app/modules/notes/models';

// Actions
import {
  updateNote as updateNoteAction,
  updateNoteSuccess,
} from 'app/modules/notes/actions';

// API
import { updateNote } from 'app/shared/api/notes';

const rootAction = NotesActionTypes.UPDATE_NOTE;
export function* updateNoteFlow({
  payload,
}: ReturnType<typeof updateNoteAction>) {
  const config = {
    rootAction,
    request: call(updateNote, payload),
    success: function* onSuccess(result) {
      yield put(updateNoteSuccess(result));
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watchAddNote() {
  yield takeLatest(rootAction, updateNoteFlow);
}
