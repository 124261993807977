import { formatDisposition } from 'app/shared/utils/disposition';

interface Props {
  value: string;
}

export const DispositionFilterDisplay = (props: Props) => {
  const { value } = props;
  return (
    <>
      <b>Disposition</b> is <b>{formatDisposition(value)}</b>
    </>
  );
};
