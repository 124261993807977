import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Actions
import {
  validateDetectionModel as validateDetectionModelAction,
  validateDetectionModelSuccess,
  resetEditingModels,
} from 'app/modules/detectionModels/actions';

// API
import { validateDetectionModel as validateDetectionModelAPI } from 'app/shared/api/detectionModels';

// Models
import { DetectionModelsActionTypes } from 'app/modules/detectionModels/models';

// Utils
import routes from 'app/shared/utils/routes';
import { history } from 'app/store/browserHistory';

const rootAction = DetectionModelsActionTypes.VALIDATE_DETECTION_MODEL;
function* validateDetectionModel({
  payload,
}: ReturnType<typeof validateDetectionModelAction>) {
  const config = {
    rootAction,
    errorToastMessage: 'Unable to validate model.',
    successToastMessage: 'Validating model',
    request: call(validateDetectionModelAPI, payload),
    success: function* onSuccess(
      result: ReturnType<typeof validateDetectionModelSuccess>['payload'],
    ) {
      yield put(validateDetectionModelSuccess(result));
      yield put(resetEditingModels());
      history.push(
        routes.lumos.detectionModelsValidationId.replace(
          ':id',
          `${result.rule_id}`,
        ),
      );
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watch() {
  yield takeLatest(rootAction, validateDetectionModel);
}
