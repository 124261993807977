// Models
import { FeatureFlagsActionTypes } from 'app/shared/featureFlags/models';

// Utils
import { createAction, ActionsUnion } from 'app/shared/utils/action-helpers';

export const setFeatureFlags = (payload) => {
  return createAction(FeatureFlagsActionTypes.SET_FEATURE_FLAGS, payload);
};

// only needed for typing in the reducer
const ReduxActions = {
  setFeatureFlags,
};

export type FeatureFlagActions = ActionsUnion<typeof ReduxActions>;
