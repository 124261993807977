// Types
import { InvestigationActionPayload } from 'app/modules/investigations/types/requests';
import {
  InvestigationsAlertResponse,
  InvestigationsPayload,
} from 'app/modules/investigations/types';

// Utils
import { post } from 'app/shared/utils/fetchr';

export const retrieveInvestigationsAlert = <TResponse>(
  payload: InvestigationsPayload,
) => {
  return post<TResponse>('/investigations/retrieve/alert', payload);
};

export const retrieveInvestigationsCase = <TResponse>(
  payload: InvestigationsPayload,
) => {
  return post<TResponse>('/investigations/retrieve/case', payload);
};

export const retrieveInvestigationsCases = <TResponse>(
  payload: InvestigationsPayload,
) => {
  return post<TResponse>('/investigations/retrieve/case-list', payload);
};

export const retrieveInvestigationAgentStats = (
  payload: InvestigationsPayload,
) => post('/investigations/agent-stats', payload);

export const performInvestigationAction = (
  payload: InvestigationActionPayload,
) => post<InvestigationsAlertResponse>('/investigations/action', payload);
