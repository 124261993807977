// https://twitter.com/DawsonBotsford/status/1064549108504059906
import invariant from 'invariant';

type EnvKey = keyof Required<Window>['_env_'];

// do not fail if these are missing
const nonStrictVars: EnvKey[] = [
  'DOCS_PASSWORD',
  'REACT_APP_BUILD_TIMESTAMP',
  'REACT_APP_INTERCOM_ID',
  'STATUSPAGE_URL',
  'TOKENIZATION_SERVICE_URL',
];

export default function getFromProcessEnv(envKey: EnvKey): string {
  const env = window._env_ ?? {};
  const value = env[envKey] || process.env[envKey];
  if (process.env.NODE_ENV !== 'test' && !nonStrictVars.includes(envKey)) {
    invariant(value, `variable "${envKey}" not found on process.env`);
  }
  return value ?? '';
}
