import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { GoAMLActionTypes } from 'app/modules/goAML/models';

// Actions
import {
  retrieveGoAMLFilings as retrieveGoAMLFilingsAction,
  retrieveGoAMLFilingsSuccess,
} from 'app/modules/goAML/actions';

// API
import { retrieveGoAMLFilings as retrieveGoAMLFilingsAPI } from 'app/shared/api/goAML';

const rootAction = GoAMLActionTypes.RETRIEVE_GOAML_FILINGS;
export function* retrieveGoAMLFilings({
  payload,
}: ReturnType<typeof retrieveGoAMLFilingsAction>) {
  const config = {
    rootAction,
    errorToastMessage:
      'Something went wrong, unable to retrieve goAML filings.',
    request: call(retrieveGoAMLFilingsAPI, payload),
    success: function* onSuccess(result) {
      yield put(retrieveGoAMLFilingsSuccess(result));
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watch() {
  yield takeLatest(rootAction, retrieveGoAMLFilings);
}
