import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { DetectionModelsActionTypes } from 'app/modules/detectionModels/models';

// Actions
import { retrieveScenarioConfigsSuccess } from 'app/modules/detectionModels/actions';

// API
import { retrieveScenarioConfigs as retrieveScenarioConfigsAPI } from 'app/shared/api/detectionModels';

const rootAction = DetectionModelsActionTypes.RETRIEVE_SCENARIO_CONFIGS;
export function* retrieveScenarioConfigs() {
  const config = {
    rootAction,
    errorToastMessage: 'Something went wrong, unable to retrieve histogram.',
    request: call(retrieveScenarioConfigsAPI),
    success: function* onSuccess(result) {
      yield put(retrieveScenarioConfigsSuccess(result));
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watch() {
  yield takeLatest(rootAction, retrieveScenarioConfigs);
}
