import { u21CreateSlice } from 'app/shared/thunk/u21CreateSlice';
import { u21CreateAsyncThunk } from 'app/shared/thunk/u21CreateAsyncThunk';

// Models
import {
  CreateNarrativeTemplatePayload,
  EditNarrativeTemplatePayload,
  ListNarrativeTemplatesPayload,
  SearchNarrativeTemplatesPayload,
} from 'app/modules/narrativeTemplates/requests';
import {
  ListNarrativeTemplatesResponse,
  SearchNarrativeTemplatesResponse,
} from 'app/modules/narrativeTemplates/responses';
import {
  NarrativeTemplate,
  NarrativeTemplateType,
} from 'app/modules/narrativeTemplates/models';

// APIs
import {
  createNarrativeTemplateApi,
  deleteNarrativeTemplateApi,
  editNarrativeTemplateApi,
  retrieveNarrativeTemplatesListApi,
  retrieveNarrativeTemplatesSearchApi,
} from 'app/modules/narrativeTemplates/api';

// Utils
import { fromUpperToSentenceCase } from 'app/shared/utils/string';
import { sendErrorToast, sendSuccessToast } from 'app/shared/toasts/actions';

const NARRATIVE_TEMPLATES_NAME = 'narrative_templates';

interface NarrativeTemplatesState {
  loadingNarrativeTemplates: boolean;
  alertNarrativeTemplates: NarrativeTemplate[];
  caseNarrativeTemplates: NarrativeTemplate[];
  alertNarrativeTemplatesCount: number;
  caseNarrativeTemplatesCount: number;
  loadingCreateEditDeleteNarrativeTemplate: boolean;
  searchAlertNarrativeTemplates: NarrativeTemplate[];
  searchCaseNarrativeTemplates: NarrativeTemplate[];
}

export const narrativeTemplatesInitialState: Readonly<NarrativeTemplatesState> =
  {
    loadingNarrativeTemplates: false,
    alertNarrativeTemplates: [],
    caseNarrativeTemplates: [],
    alertNarrativeTemplatesCount: 0,
    caseNarrativeTemplatesCount: 0,
    loadingCreateEditDeleteNarrativeTemplate: false,
    searchAlertNarrativeTemplates: [],
    searchCaseNarrativeTemplates: [],
  };

export const retrieveNarrativeTemplatesListThunkName = `${NARRATIVE_TEMPLATES_NAME}/RETRIEVE_NARRATIVE_TEMPLATES_LIST`;
export const retrieveNarrativeTemplatesListThunk = u21CreateAsyncThunk<
  ListNarrativeTemplatesPayload,
  ListNarrativeTemplatesResponse & { type: NarrativeTemplateType }
>(retrieveNarrativeTemplatesListThunkName, async (payload, { dispatch }) => {
  try {
    const response = await retrieveNarrativeTemplatesListApi(payload);
    return { ...response, type: payload.type };
  } catch (e) {
    dispatch(
      sendErrorToast(
        'Unable to retrieve narrative templates. Please try again',
      ),
    );
    throw e;
  }
});

export const retrieveNarrativeTemplatesSearchThunkName = `${NARRATIVE_TEMPLATES_NAME}/RETRIEVE_NARRATIVE_TEMPLATES_SEARCH`;
export const retrieveNarrativeTemplatesSearchThunk = u21CreateAsyncThunk<
  SearchNarrativeTemplatesPayload,
  SearchNarrativeTemplatesResponse & { type: NarrativeTemplateType }
>(retrieveNarrativeTemplatesSearchThunkName, async (payload, { dispatch }) => {
  try {
    const response = await retrieveNarrativeTemplatesSearchApi(payload);
    return { ...response, type: payload.type };
  } catch (e) {
    dispatch(
      sendErrorToast(
        'Unable to retrieve narrative templates. Please try again',
      ),
    );
    throw e;
  }
});

export const createNarrativeTemplateThunkName = `${NARRATIVE_TEMPLATES_NAME}/CREATE_NARRATIVE_TEMPLATE`;
export const createNarrativeTemplateThunk = u21CreateAsyncThunk<
  CreateNarrativeTemplatePayload,
  NarrativeTemplate
>(createNarrativeTemplateThunkName, async (payload, { dispatch }) => {
  try {
    const response = await createNarrativeTemplateApi(payload);
    dispatch(
      sendSuccessToast(
        `${fromUpperToSentenceCase(payload.type)} successfully created`,
      ),
    );
    return response;
  } catch (e) {
    dispatch(
      sendErrorToast(
        `Unable to create ${fromUpperToSentenceCase(
          payload.type,
        )}. Please try again`,
      ),
    );
    throw e;
  }
});

export const editNarrativeTemplateThunkName = `${NARRATIVE_TEMPLATES_NAME}/EDIT_NARRATIVE_TEMPLATE`;
export const editNarrativeTemplateThunk = u21CreateAsyncThunk<
  EditNarrativeTemplatePayload,
  NarrativeTemplate
>(editNarrativeTemplateThunkName, async (payload, { dispatch }) => {
  try {
    const response = await editNarrativeTemplateApi(payload);
    dispatch(sendSuccessToast(`Narrative template successfully edited`));
    return response;
  } catch (e) {
    dispatch(
      sendErrorToast('Unable to edit narrative template. Please try again'),
    );
    throw e;
  }
});

export const deleteNarrativeTemplateThunkName = `${NARRATIVE_TEMPLATES_NAME}/DELETE_NARRATIVE_TEMPLATE`;
export const deleteNarrativeTemplateThunk = u21CreateAsyncThunk<
  number,
  NarrativeTemplate
>(deleteNarrativeTemplateThunkName, async (id, { dispatch }) => {
  try {
    const response = await deleteNarrativeTemplateApi(id.toString());
    dispatch(sendSuccessToast(`Narrative template successfully deleted`));
    return response;
  } catch (e) {
    dispatch(
      sendErrorToast('Unable to delete narrative template. Please try again'),
    );
    throw e;
  }
});

const narrativeTemplatesSlice = u21CreateSlice({
  initialState: narrativeTemplatesInitialState,
  name: NARRATIVE_TEMPLATES_NAME,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addLoadingCase(
        retrieveNarrativeTemplatesListThunk,
        'loadingNarrativeTemplates',
        (draft, action) => {
          if (
            action.payload.type ===
            NarrativeTemplateType.ALERT_NARRATIVE_TEMPLATE
          ) {
            draft.alertNarrativeTemplates = action.payload.narrative_templates;
            draft.alertNarrativeTemplatesCount = action.payload.count;
          } else if (
            action.payload.type ===
            NarrativeTemplateType.CASE_NARRATIVE_TEMPLATE
          ) {
            draft.caseNarrativeTemplates = action.payload.narrative_templates;
            draft.caseNarrativeTemplatesCount = action.payload.count;
          }
        },
      )
      .addLoadingCase(
        retrieveNarrativeTemplatesSearchThunk,
        'loadingNarrativeTemplates',
        (draft, action) => {
          if (
            action.payload.type ===
            NarrativeTemplateType.ALERT_NARRATIVE_TEMPLATE
          ) {
            draft.searchAlertNarrativeTemplates =
              action.payload.narrative_templates;
          } else if (
            action.payload.type ===
            NarrativeTemplateType.CASE_NARRATIVE_TEMPLATE
          ) {
            draft.searchCaseNarrativeTemplates =
              action.payload.narrative_templates;
          }
        },
      )
      .addLoadingCase(
        createNarrativeTemplateThunk,
        'loadingCreateEditDeleteNarrativeTemplate',
        (draft, action) => {
          if (
            action.payload.type ===
            NarrativeTemplateType.ALERT_NARRATIVE_TEMPLATE
          ) {
            draft.alertNarrativeTemplates = [
              action.payload,
              ...draft.alertNarrativeTemplates,
            ];
            draft.alertNarrativeTemplatesCount += 1;
          } else if (
            action.payload.type ===
            NarrativeTemplateType.CASE_NARRATIVE_TEMPLATE
          ) {
            draft.caseNarrativeTemplates = [
              action.payload,
              ...draft.caseNarrativeTemplates,
            ];
            draft.caseNarrativeTemplatesCount += 1;
          }
        },
      )
      .addLoadingCase(
        editNarrativeTemplateThunk,
        'loadingCreateEditDeleteNarrativeTemplate',
        (draft, action) => {
          if (
            action.payload.type ===
            NarrativeTemplateType.ALERT_NARRATIVE_TEMPLATE
          ) {
            draft.alertNarrativeTemplates = draft.alertNarrativeTemplates.map(
              (narrativeTemplate) => {
                if (narrativeTemplate.id === action.payload.id) {
                  return action.payload;
                }
                return narrativeTemplate;
              },
            );
          } else if (
            action.payload.type ===
            NarrativeTemplateType.CASE_NARRATIVE_TEMPLATE
          ) {
            draft.caseNarrativeTemplates = draft.caseNarrativeTemplates.map(
              (narrativeTemplate) => {
                if (narrativeTemplate.id === action.payload.id) {
                  return action.payload;
                }
                return narrativeTemplate;
              },
            );
          }
        },
      )
      .addLoadingCase(
        deleteNarrativeTemplateThunk,
        'loadingCreateEditDeleteNarrativeTemplate',
        (draft, action) => {
          if (
            action.payload.type ===
            NarrativeTemplateType.ALERT_NARRATIVE_TEMPLATE
          ) {
            draft.alertNarrativeTemplates =
              draft.alertNarrativeTemplates.filter((narrativeTemplate) => {
                return narrativeTemplate.id !== action.payload.id;
              });
            draft.alertNarrativeTemplatesCount -= 1;
          } else if (
            action.payload.type ===
            NarrativeTemplateType.CASE_NARRATIVE_TEMPLATE
          ) {
            draft.caseNarrativeTemplates = draft.caseNarrativeTemplates.filter(
              (narrativeTemplate) => {
                return narrativeTemplate.id !== action.payload.id;
              },
            );
            draft.caseNarrativeTemplatesCount -= 1;
          }
        },
      );
  },
});

export const NARRATIVE_TEMPLATES_SLICE_NAME = narrativeTemplatesSlice.name;
export default narrativeTemplatesSlice.reducer;
