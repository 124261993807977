import {
  HTMLProps,
  isValidElement,
  ReactElement,
  ReactNode,
  useRef,
} from 'react';

import {
  U21HelpModal,
  U21HelpModalProps,
} from 'app/shared/u21-ui/components/display/U21HelpModal';
import { U21Typography } from 'app/shared/u21-ui/components/display/typography/U21Typography';
import { U21Loading } from 'app/shared/u21-ui/components/display/U21Loading';
import { U21Spacer } from 'app/shared/u21-ui/components/layout/U21Spacer';

import { getDOMProps } from 'app/shared/utils/react';
import styled, { css } from 'styled-components';
import { useTheme } from '@mui/material/styles';
import { debounce } from 'lodash';

type PageSizeType = 'medium' | 'max';

export interface U21PageProps
  extends Omit<HTMLProps<HTMLDivElement>, 'action' | 'size' | 'title'> {
  action?: ReactNode;
  afterTitleContent?: ReactNode;
  beforeTitleContent?: ReactNode;
  children: ReactNode;
  helpModalProps?: U21HelpModalProps;
  loading?: boolean;
  size?: PageSizeType;
  title: ReactNode;
  titleIcon?: ReactElement;
}

export const U21PageNew = (props: U21PageProps) => {
  const {
    action,
    afterTitleContent,
    beforeTitleContent,
    children,
    helpModalProps,
    loading,
    size = 'max',
    title,
    titleIcon,
    ...rest
  } = props;

  const ref = useRef<HTMLDivElement>(null);

  const theme = useTheme();

  return (
    <Container
      {...getDOMProps(rest)}
      onScroll={debounce((e) => {
        if (ref.current) {
          ref.current.style.boxShadow = e.target.scrollTop
            ? theme.customShadows.z12
            : 'none';
        }
      }, 100)}
    >
      {!loading && (
        <>
          <Header $size={size} ref={ref} horizontal useFlexGap>
            <HeaderSpacer horizontal>
              {beforeTitleContent}
              {isValidElement(title) ? (
                title
              ) : (
                <U21Typography
                  align="start"
                  ellipsis
                  icon={titleIcon}
                  variant="h4"
                >
                  {title}
                </U21Typography>
              )}
              {afterTitleContent}
              {helpModalProps && (
                <U21HelpModal
                  title="How to Use This Page"
                  {...helpModalProps}
                />
              )}
            </HeaderSpacer>
            {action}
          </Header>
          <Content $size={size}>{children}</Content>
        </>
      )}
      <MaxLoading loading={loading} />
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;
  background: ${(props) => props.theme.palette.background.neutral};
`;

const SIZE_PADDING_OVERRIDE_STYLE = css<{ $size: PageSizeType }>`
  ${(props) => {
    switch (props.$size) {
      case 'medium':
        // set a max width of 1280px by adding padding
        return css`
          padding-left: max(calc((100% - 1280px) / 2), 24px);
          padding-right: max(calc((100% - 1280px) / 2), 24px);
        `;
      default:
        return css``;
    }
  }}
`;

const Header = styled(U21Spacer)<{ $size: PageSizeType }>`
  position: sticky;
  top: 0;
  justify-content: space-between;
  padding: 24px 24px 16px;
  background: ${(props) => props.theme.palette.background.neutral};
  z-index: 1;
  transition: ${(props) => props.theme.transitions.create(['all'])};
  ${SIZE_PADDING_OVERRIDE_STYLE}
`;

const Content = styled.div<{ $size: PageSizeType }>`
  padding: 0 24px 24px;
  background: ${(props) => props.theme.palette.background.neutral};
  ${SIZE_PADDING_OVERRIDE_STYLE}
`;

const HeaderSpacer = styled(U21Spacer)`
  overflow: hidden;
`;

const MaxLoading = styled(U21Loading)`
  // factor in margin for height + width
  height: calc(100% - 48px);
  width: calc(100% - 48px);
  margin: 24px;
`;
