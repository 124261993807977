import { BasicSymbolTable } from 'app/modules/rulesAdvanced/lib/SymbolTable';
import {
  CompositeQueryTreeNode,
  IRSchema,
} from 'app/modules/rulesAdvanced/types';
import { TIME_CONVERSION } from 'app/modules/rulesAdvanced/lib/IRHelpers';
import { RuleExecutionConfig } from 'app/modules/orgSettings/models';
import { MAX_WINDOW_SIZE_IN_YEARS_DEFAULT } from 'app/modules/rulesAdvanced/constants';
import { RuleSpecification } from 'app/modules/rules/types/responses';

export const constructOptionsFromSymbolTable = (table: BasicSymbolTable) => {
  return Object.keys(table).map((key) => {
    const symbol = table[key];
    const symbolKey =
      symbol.value.value.type === 'FIELD' ? symbol.value.value.label : key;
    return {
      value: {
        field:
          symbol.value.value.type === 'FIELD' ? symbol.value.value.field : key,
        symbolKey,
      },
      text: symbol.text,
      type: symbol.type,
    };
  });
};

/**
 * this checks the rule_condition to see if we have at least 1 proper expression
 * @param ruleCondition a binary tree of operands and operators like 'variable == 1', where (variable, 1) are operands and (==) is an operator
 * @returns boolean
 */
export const checkRuleConditionExists = (
  ruleCondition: CompositeQueryTreeNode,
): boolean => {
  // Note that only NOT_BETWEEN has a special data structure, where the BETWEEN clause is nested within a NOT clause, hence the special strict check.
  // Also note that NOT_BETWEEN has only 1 child operand, which is the between clause. See 'should interpret NOT_BETWEEN keyword' test for example
  return ruleCondition?.operator === 'NOT'
    ? ruleCondition?.operands?.length === 1 &&
        ruleCondition?.operands[0]?.type === 'rule_condition_composite' &&
        ruleCondition?.operands[0]?.operator === 'BETWEEN'
    : ruleCondition?.operands?.length > 1; // this will be the first trigger condition someone writes in
};

export const getMaxWindowSizeInMinutes = (
  orgRuleExecutionConfig: RuleExecutionConfig,
): number => {
  if (!orgRuleExecutionConfig.max_window_size_in_days) {
    return TIME_CONVERSION.YEAR * MAX_WINDOW_SIZE_IN_YEARS_DEFAULT;
  }
  return orgRuleExecutionConfig.max_window_size_in_days * TIME_CONVERSION.DAY;
};

// Workaround for "asserts x is type doesn't work when using arrow functions" issue
// https://github.com/microsoft/TypeScript/issues/34523
const assertIsIrSchema: (
  specification: RuleSpecification,
) => asserts specification is IRSchema = (specification) => {
  if (specification.type) {
    throw new Error('Specification has to be a IRSchema');
  }
};

export const isIrSchema = (
  specification: RuleSpecification,
): specification is IRSchema => {
  return !specification.type;
};

export const toIrSchema = (specification: RuleSpecification): IRSchema => {
  if (specification) {
    assertIsIrSchema(specification);
  }
  return specification;
};

export const toIrSchemaOrNull = (
  specification: RuleSpecification | undefined | null,
): IRSchema | null => {
  if (specification) {
    assertIsIrSchema(specification);
  }
  return specification ?? null;
};
