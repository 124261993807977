export const getDefaultDate = (
  dateValue: string | null | Date,
): Date | null => {
  // IF it is a date return it as is
  if (dateValue instanceof Date) {
    return dateValue;
  }
  // IF it is a string try to format it using the YYYYMMDD FinCEN format
  if (dateValue && typeof dateValue === 'string' && dateValue.length === 8) {
    const year = dateValue.substring(0, 4);
    const month = dateValue.substring(4, 6);
    const day = dateValue.substring(6, 8);
    return new Date(`${year}-${month}-${day}`);
  }
  // Some value but not format we expected - use default date parser
  if (dateValue) {
    return new Date(dateValue);
  }

  // No Value
  return null;
};
