import { ReactNode, HTMLProps } from 'react';

// Components
import { Badge } from '@mui/material';

// Models
import { ColorSchema } from 'vendor/material-minimal/palette';

// Utils
import { getDOMProps } from 'app/shared/utils/react';
import styled, { css } from 'styled-components';

export interface U21BadgeProps
  extends Omit<HTMLProps<HTMLSpanElement>, 'content'> {
  children?: ReactNode;
  color?: 'default' | 'unset' | 'white' | ColorSchema;
  content?: ReactNode;
  hidden?: boolean;
  variant?: 'dot' | 'standard';
}

export const U21Badge = ({
  color = 'primary',
  content,
  hidden,
  children = null,
  variant = 'standard',
  ...rest
}: U21BadgeProps) => {
  if (hidden) {
    return <>{children}</>;
  }

  if (!content && content !== 0 && variant !== 'dot') {
    return <>{children}</>;
  }

  return (
    <StyledBadge
      $color={color}
      badgeContent={content}
      color={color === 'unset' || color === 'white' ? 'default' : color}
      variant={variant}
      {...getDOMProps(rest)}
    >
      {children}
    </StyledBadge>
  );
};

const StyledBadge = styled(Badge)<{
  $color: Exclude<U21BadgeProps['color'], undefined>;
}>`
  .MuiBadge-badge {
    ${(props) => {
      if (props.$color === 'default') {
        return css`
          background-color: ${props.theme.palette.background.neutral};
        `;
      }
      if (props.$color === 'white') {
        return css`
          background-color: ${props.theme.palette.background.paper};
        `;
      }
      return css``;
    }}
  }
`;
