import { ScenarioParameters } from 'app/modules/detectionModels/models';
import {
  isAchConsortiumRule,
  isAchDuplicatedRule,
  isAchMismatchedRule,
  isAchMultipleFailedValidationAttemptsRule,
  isAchRiskScoreRule,
} from 'app/modules/detectionModels/helpers';

const validateAchFraudRuleContentStep = (
  params: ScenarioParameters,
): boolean => {
  if (!params.ach_fraud_content_valid) {
    return false;
  }
  return true;
};

const validateAchRiskRuleContentStep = (
  params: ScenarioParameters,
): boolean => {
  return !!params.ach_risk_score_content_valid;
};
const validateAchConsortiumRuleContentStep = (
  params: ScenarioParameters,
): boolean => {
  return !!params.ach_consortium_content_valid;
};
const validateAchMismatchedRuleContentStep = (
  params: ScenarioParameters,
): boolean => {
  return !!params.ach_mismatched_content_valid;
};
const validateAchDuplicateRuleContentStep = (
  params: ScenarioParameters,
): boolean => {
  return !!params.ach_duplicate_content_valid;
};

export const isAchFraudScenarioValid = (
  scenarioName: string,
  scenarioParameters: ScenarioParameters,
): boolean => {
  if (isAchMultipleFailedValidationAttemptsRule(scenarioName)) {
    const isAchMultipleFailedValidationAttemptsRuleValid =
      validateAchFraudRuleContentStep(scenarioParameters);
    if (!isAchMultipleFailedValidationAttemptsRuleValid) return false;
  }
  if (isAchRiskScoreRule(scenarioName)) {
    const achRiskScoreValid =
      validateAchRiskRuleContentStep(scenarioParameters);
    if (!achRiskScoreValid) return false;
  }
  if (isAchConsortiumRule(scenarioName)) {
    const achConsortiumValid =
      validateAchConsortiumRuleContentStep(scenarioParameters);
    if (!achConsortiumValid) return false;
  }
  if (isAchMismatchedRule(scenarioName)) {
    const achMismatchedValid =
      validateAchMismatchedRuleContentStep(scenarioParameters);
    if (!achMismatchedValid) return false;
  }
  if (isAchDuplicatedRule(scenarioName)) {
    const achDuplicateValid =
      validateAchDuplicateRuleContentStep(scenarioParameters);
    if (!achDuplicateValid) return false;
  }
  return true;
};
