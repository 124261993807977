import { Action } from 'redux';
import {
  configureStore as toolkitConfigureStore,
  isPlain,
} from '@reduxjs/toolkit';
import { history } from 'app/store/browserHistory';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import rootReducer from 'app/store/rootReducer';
import SagaManager from 'app/store/SagaManager';
import * as Sentry from '@sentry/react';

const OMITTED = 'OMITTED_DATA';

const configureStore = () => {
  const sagaMiddleware = createSagaMiddleware();
  const connectedRouterMiddleware = routerMiddleware(history);

  // @ts-ignore Getting a "Type instantiation is excessively deep" error
  const store = toolkitConfigureStore({
    devTools: process.env.NODE_ENV === 'development',
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        // @ts-ignore redux toolkit for some reason expects true only for serializableCheck
        // when it accepts these https://redux-toolkit.js.org/api/serializabilityMiddleware
        serializableCheck: {
          ignoredActions: [
            '@@session/START_SESSION',
            '@@session/RETRIEVE_ORG_SETTINGS',
          ],
          ignoredPaths: ['notes.editorState'],
          isSerializable: (value) =>
            isPlain(value) || value instanceof Date || value instanceof Set,
        },
      })
        .concat(sagaMiddleware)
        .concat(connectedRouterMiddleware),
    reducer: rootReducer,
    enhancers: [
      Sentry.createReduxEnhancer({
        actionTransformer: (action: Action) => {
          const a: any = { ...action };
          // omits sensitive parts of the redux store
          if (
            (Object.prototype.hasOwnProperty.call(a, 'payload') &&
              a.type.includes('@@session')) ||
            a.type.includes('CREATE_APIKEY_SUCCESS')
          ) {
            a.payload = OMITTED;
          }
          return a;
        },
        stateTransformer: (state: RootState) => {
          const s: any = { ...state };
          // omits sensitive or large sub-sections of the redux store
          s.session = {
            permissions: s.session.permissions,
            impersonatedPermissions: s.session.impersonatedPermissions,
            agent: s.session.agent,
          };
          s.entities = { ...s.entities, details: OMITTED };
          s.settings = { ...s.settings, latestAPIKey: OMITTED };
          return s;
        },
      }),
    ],
  });

  SagaManager.startSagas(sagaMiddleware);

  return store;
};

export const store = configureStore();
export type AppDispatch = typeof store.dispatch;
