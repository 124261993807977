import {
  IRSchema,
  DmDurationUnit,
  DurationValue,
} from 'app/modules/rulesAdvanced/types/RulesRepresentation';
import { toSentenceCase } from 'app/shared/utils/string';
import { invert } from 'lodash';

export const MONTH_UNIT_MAP = {
  fdom: 'the first day of the month',
  ldom: 'the last day of the month',
  fmom: 'the first Monday of the month',
  lfom: 'the last Friday of the month',
};

const DM_DURATION_UNITS: readonly DmDurationUnit[] = [
  'MINUTE',
  'HOUR',
  'DAY',
  'WEEK',
  'MONTH',
  'YEAR',
];

export const isDmDurationUnit = (
  value: string | undefined,
): value is DmDurationUnit => {
  if (!value) return false;
  return (DM_DURATION_UNITS as readonly string[]).includes(value);
};

export const DURATION_UNIT_MAP: { [key: string]: DmDurationUnit } = {
  min: 'MINUTE',
  h: 'HOUR',
  d: 'DAY',
  w: 'WEEK',
  m: 'MONTH',
  y: 'YEAR',
};

export const durationOptions: {
  text: string;
  value: DmDurationUnit;
}[] = Object.values(DURATION_UNIT_MAP).map((unit) => {
  return {
    text: toSentenceCase(unit),
    value: unit,
  };
});

export const pluralizedDurationOptions: {
  text: string;
  value: DmDurationUnit;
}[] = Object.values(DURATION_UNIT_MAP).map((unit) => {
  return {
    text: `${toSentenceCase(unit)}(s)`,
    value: unit,
  };
});

export const DURATION_UNIT_MAP_INVERTED = invert(DURATION_UNIT_MAP);

export const TIME_CONVERSION = {
  MINUTE: 1,
  HOUR: 60,
  DAY: 60 * 24,
  WEEK: 60 * 24 * 7,
  MONTH: 60 * 24 * 31,
  YEAR: 60 * 24 * 365,
};

export function getWindowSizeFromIR(rule: IRSchema): string {
  let windowLookbackInMin = 10;
  let node: DurationValue = {
    value: 10,
    unit: 'MINUTE',
    type: 'DURATION',
  };
  let containsValueFactsOnly = true;
  rule.facts.forEach((fact) => {
    if (fact.type === 'EVENT_BY_OBJECT_FACT') {
      containsValueFactsOnly = false;
      const windowSize =
        fact.window_start_offset.value *
        TIME_CONVERSION[fact.window_start_offset.unit];

      if (windowLookbackInMin < windowSize) {
        windowLookbackInMin = windowSize;
        node = fact.window_start_offset;
      }
    }
  });

  if (containsValueFactsOnly) {
    node = {
      value: 1,
      unit: 'MONTH',
      type: 'DURATION',
    };
  }

  return (
    node.value +
    (node.unit !== undefined ? DURATION_UNIT_MAP_INVERTED[node.unit] : 'MINUTE')
  );
}
