import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { AlertsActionTypes } from 'app/modules/alerts/models';

// Actions
import {
  editAlert as editAlertAction,
  editAlertSuccess,
} from 'app/modules/alerts/actions';
import { retrieveCustomChecklistSubmission } from 'app/shared/customChecklist/actions';

// API
import { updateAlert } from 'app/shared/api/alerts';

const rootAction = AlertsActionTypes.EDIT_ALERT;
export function* editAlert({ payload }: ReturnType<typeof editAlertAction>) {
  const config = {
    rootAction,
    errorToastMessage: 'Something went wrong, unable to edit this alert.',
    successToastMessage: `Alert ${payload.id} updated successfully.`,
    request: call(updateAlert, payload),
    success: function* onSuccess(
      result: ReturnType<typeof editAlertSuccess>['payload'],
    ) {
      yield put(retrieveCustomChecklistSubmission(payload.id));
      yield put(editAlertSuccess(result));
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watchEditAlert() {
  yield takeLatest(rootAction, editAlert);
}
