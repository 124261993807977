import retrieveAlerts from 'app/modules/alerts/sagas/retrieveAlerts';
import retrieveAlert from 'app/modules/alerts/sagas/retrieveAlert';
import retrieveAlertDetails from 'app/modules/alerts/sagas/retrieveAlertDetails';
import retrieveAlertTransactions from 'app/modules/alerts/sagas/retrieveAlertTransactions';
import retrieveAlertAuditTrail from 'app/modules/alerts/sagas/retrieveAlertAuditTrail';
import changeAlertComponentStatus from 'app/modules/alerts/sagas/changeAlertComponentStatus';
import reassignAlerts from 'app/modules/alerts/sagas/reassignAlerts';
import requeueAlerts from 'app/modules/alerts/sagas/requeueAlerts';
import createAlert from 'app/modules/alerts/sagas/createAlert';
import editAlert from 'app/modules/alerts/sagas/editAlert';
import retrieveAlertEntities from 'app/modules/alerts/sagas/retrieveAlertEntities';
import retrieveAlertInstruments from 'app/modules/alerts/sagas/retrieveAlertInstruments';
import triggerAlertAction from 'app/modules/alerts/sagas/triggerAlertAction';
import associateAlertsWithCases from 'app/modules/alerts/sagas/associateAlertsWithCases';
import retrieveAlertQueues from 'app/modules/alerts/sagas/retrieveAlertQueues';
import retrieveAllAlertQueues from 'app/modules/alerts/sagas/retrieveAllAlertQueues';
import retrieveMyAlertsStats from 'app/modules/alerts/sagas/retrieveMyAlertsStats';
import retrieveUnassignedAlerts from 'app/modules/alerts/sagas/retrieveUnassignedAlerts';
import retrieveAlertHistogram from 'app/modules/alerts/sagas/retrieveAlertHistogram';
import retrieveAlertActionEvents from 'app/modules/alerts/sagas/retrieveAlertActionEvents';

const sagaRegistry = [
  retrieveAlerts,
  retrieveAlert,
  retrieveAlertDetails,
  retrieveMyAlertsStats,
  retrieveAlertTransactions,
  retrieveAlertAuditTrail,
  retrieveAlertEntities,
  changeAlertComponentStatus,
  reassignAlerts,
  requeueAlerts,
  createAlert,
  editAlert,
  retrieveAllAlertQueues,
  retrieveAlertInstruments,
  triggerAlertAction,
  associateAlertsWithCases,
  retrieveAlertQueues,
  retrieveUnassignedAlerts,
  retrieveAlertHistogram,
  retrieveAlertActionEvents,
];

export default sagaRegistry;
