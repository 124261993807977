import {
  cloneElement,
  HTMLProps,
  isValidElement,
  ReactElement,
  ReactNode,
  useContext,
} from 'react';
import { FieldSubscription } from 'final-form';
import { FormFieldError } from 'app/shared/models/form';
import { U21HelpTooltipProps } from 'app/shared/u21-ui/components/display/U21HelpTooltip';

import { getDOMProps } from 'app/shared/utils/react';
import { isEqual } from 'lodash';
import styled from 'styled-components';
import useId from '@mui/utils/useId';

import {
  Field as ReactFormField,
  FieldInputProps,
  FieldRenderProps,
} from 'react-final-form';
import { FormControl } from '@mui/material';
import { U21FormContext } from 'app/shared/u21-ui/components/form/U21FormContext';
import { U21FormError } from 'app/shared/u21-ui/components/form/U21FormError';
import { U21FormLabel } from 'app/shared/u21-ui/components/form/U21FormLabel';
import { U21Typography } from 'app/shared/u21-ui/components/display/typography/U21Typography';

export interface U21FormFieldCustomInputProps<FieldValue>
  extends Omit<FieldInputProps<FieldValue>, 'onChange' | 'value'> {
  onChange: (value: FieldValue) => void;
  value?: FieldValue;
}

/** @deprecated */
type DeprecatedReactElement = ReactElement;
export interface U21FormFieldCustomProps<FieldValue, FormValues>
  extends Omit<HTMLProps<HTMLDivElement>, 'children' | 'type'> {
  children:
    | DeprecatedReactElement
    | ((props: U21FormFieldCustomInputProps<FieldValue>) => ReactNode);
  description?: string;
  disabled?: boolean;
  fieldProps?: Record<string, any>;
  help?: U21HelpTooltipProps['help'];
  hidden?: boolean;
  label?: string;
  name: string;
  required?: boolean;
  subscription?: FieldSubscription;
  suppressError?: boolean;
  validate?: (value: FieldValue, allValues: FormValues) => FormFieldError;
}

export const U21FormFieldCustom = <
  FieldValue,
  FormValues extends object = Record<string, any>,
>({
  children,
  description,
  disabled: disabledProp,
  fieldProps,
  help,
  hidden,
  label,
  name,
  required,
  subscription,
  suppressError,
  validate,
  ...rest
}: U21FormFieldCustomProps<FieldValue, FormValues>) => {
  const { disabled: disabledContext, loading } = useContext(U21FormContext);
  const id = useId(fieldProps?.id);

  return (
    <ReactFormField
      isEqual={isEqual}
      name={name}
      subscription={{
        error: true,
        invalid: true,
        touched: true,
        value: true,
        ...subscription,
      }}
      validate={validate}
    >
      {({ input, meta }: FieldRenderProps<FieldValue>) => {
        if (hidden) {
          return null;
        }
        const error = meta.touched && meta.invalid;
        const disabled = disabledContext || disabledProp || loading;
        const inputProps = {
          disabled,
          error,
          id,
          required,
          ...fieldProps,
          ...input,
          onChange: (value, ...args) => {
            input?.onChange(value);
            fieldProps?.onChange?.(value, ...args);
          },
          value: input.value === '' ? undefined : input.value,
        };
        return (
          <div {...getDOMProps(rest)}>
            <FormControl error={error} fullWidth>
              {Boolean(label) && (
                <U21FormLabel
                  disabled={disabled}
                  id={`${id}-label`}
                  error={error}
                  help={help}
                  htmlFor={id}
                  required={required}
                >
                  {label}
                </U21FormLabel>
              )}
              {isValidElement<any>(children)
                ? cloneElement(children, inputProps)
                : children(inputProps)}
              {!suppressError && <U21FormError error={error && meta.error} />}
              <StyledU21Typography color="text.secondary" variant="body2">
                {description}
              </StyledU21Typography>
            </FormControl>
          </div>
        );
      }}
    </ReactFormField>
  );
};

const StyledU21Typography = styled(U21Typography)`
  margin-top: 4px;
`;
