// Utils
import { createAction, ActionsUnion } from 'app/shared/utils/action-helpers';

// Models
import {
  EntitiesActionTypes,
  EditEntityPayload,
  EditEntityResult,
  EntityAlertsPayload,
  RetrieveLinkAnalysisPayload,
  EntityLinkAnalysisReport,
  EntitiesLinkAnalysisMetadataResponse,
} from 'app/modules/entities/models';

export const editEntity = (payload: EditEntityPayload) => {
  return createAction(EntitiesActionTypes.EDIT_ENTITY, payload);
};

export const editEntitySuccess = (payload: EditEntityResult) => {
  return createAction(EntitiesActionTypes.EDIT_ENTITY_SUCCESS, payload);
};

export const retrieveEntity = (
  entityId: string | number,
  ignoreComments: boolean = false,
) => {
  return createAction(EntitiesActionTypes.RETRIEVE_ENTITY, {
    entityId,
    ignoreComments,
  });
};

export const retrieveEntityPreview = (entityId: number | string) => {
  return createAction(EntitiesActionTypes.RETRIEVE_ENTITY_PREVIEW, entityId);
};

export const retrieveEntitySuccess = (payload) => {
  return createAction(EntitiesActionTypes.RETRIEVE_ENTITY_SUCCESS, payload);
};

export const retrieveEntityAlerts = (payload: EntityAlertsPayload) => {
  return createAction(EntitiesActionTypes.RETRIEVE_ENTITY_ALERTS, payload);
};

export const retrieveEntityAlertsSuccess = (payload: any) => {
  return createAction(
    EntitiesActionTypes.RETRIEVE_ENTITY_ALERTS_SUCCESS,
    payload,
  );
};

export const queueEntityLinkAnalysis = (entityId: number | string) => {
  return createAction(EntitiesActionTypes.QUEUE_ENTITY_LINK_ANALYSIS, entityId);
};

export const queueEntityLinkAnalysisSuccess = (
  result: EntityLinkAnalysisReport,
) => {
  return createAction(
    EntitiesActionTypes.QUEUE_ENTITY_LINK_ANALYSIS_SUCCESS,
    result,
  );
};

export const retrieveEntityLinkAnalysis = (
  payload: RetrieveLinkAnalysisPayload,
) => {
  return createAction(
    EntitiesActionTypes.RETRIEVE_ENTITY_LINK_ANALYSIS,
    payload,
  );
};

export const retrieveEntityLinkAnalysisSuccess = (
  result: EntityLinkAnalysisReport | null,
) =>
  createAction(
    EntitiesActionTypes.RETRIEVE_ENTITY_LINK_ANALYSIS_SUCCESS,
    result,
  );

export const retrieveEntitiesLinkAnalysisMetadata = (payload: {
  entity_ids: (number | string)[];
}) =>
  createAction(
    EntitiesActionTypes.RETRIEVE_ENTITIES_LINK_ANALYSIS_METADATA,
    payload,
  );

export const retrieveEntitiesLinkAnalysisMetadataSuccess = (
  result: EntitiesLinkAnalysisMetadataResponse,
) =>
  createAction(
    EntitiesActionTypes.RETRIEVE_ENTITIES_LINK_ANALYSIS_METADATA_SUCCESS,
    result,
  );

export const resetEntitiesLinkAnalysisMetadata = () =>
  createAction(EntitiesActionTypes.RESET_ENTITIES_LINK_ANALYSIS_METADATA);

export const clearAllEntityState = () => {
  return createAction(EntitiesActionTypes.CLEAR_ALL_ENTITY_STATE);
};

const EntitiesActionsList = {
  editEntitySuccess,
  retrieveEntitySuccess,
  retrieveEntityAlertsSuccess,
  retrieveEntityLinkAnalysis,
  retrieveEntityLinkAnalysisSuccess,
  clearAllEntityState,
  queueEntityLinkAnalysis,
  queueEntityLinkAnalysisSuccess,
  retrieveEntitiesLinkAnalysisMetadata,
  retrieveEntitiesLinkAnalysisMetadataSuccess,
  resetEntitiesLinkAnalysisMetadata,
};

export type EntitiesActions = ActionsUnion<typeof EntitiesActionsList>;
