import { useMutation } from '@tanstack/react-query';
import { sendErrorToast } from 'app/shared/toasts/actions';
import { decryptPan } from 'app/shared/utils/fetchr';
import { useDispatch } from 'react-redux';

export const useDecryptPan = () => {
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: decryptPan,
    onError: () => dispatch(sendErrorToast('Failed to decrypt PAN')),
  });
};
