import styled from 'styled-components';

import { AdornmentButton } from 'app/shared/u21-ui/components/input/text-field/AdornmentButton';
import { U21Loading } from 'app/shared/u21-ui/components/display/U21Loading';
import { IconX } from '@u21/tabler-icons';

export const Loading = styled(U21Loading).attrs({
  delay: 0,
  variant: 'spinner',
})`
  padding: 4px;
`;

// typescript doesn't like this for some reason
export const ClearButton: any = styled(AdornmentButton).attrs({
  'aria-label': 'Clear',
  icon: <IconX />,
})`
  visibility: hidden;
`;

export const StartIconContainer = styled.div<{ $disabled?: boolean }>`
  display: flex;
  align-items: center;
  padding: 4px;

  color: ${(props) => props.theme.palette.grey[props.$disabled ? 500 : 600]};
`;
