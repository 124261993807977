import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { TableConfigActionTypes } from 'app/shared/CustomConfig/models';

// Api
import { retrieveOrgTableConfig as api } from 'app/shared/api/tableConfigs';

// Actions
import { retrieveOrgTableConfigSuccess } from 'app/shared/CustomConfig/actions';

const rootAction = TableConfigActionTypes.RETRIEVE_ORG_TABLE_CONFIG;
export function* retrieveOrgTableConfig() {
  const config = {
    rootAction,
    request: call(api),
    errorToastMessage:
      'Something went wrong, unable to retrieve the table config.',
    success: function* onSuccess(
      result: ReturnType<typeof retrieveOrgTableConfigSuccess>['payload'],
    ) {
      yield put(retrieveOrgTableConfigSuccess(result));
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watch() {
  yield takeLatest(rootAction, retrieveOrgTableConfig);
}
