import { Filter } from 'app/modules/filters/models';

import { ALL_TRANSACTION_FILTER_OPTIONS } from 'app/modules/transactions/filters';
import { LocalStorageKeys } from 'app/shared/constants/localStorage';

import { getLocalStorageJSON } from 'app/shared/utils/localStorage';
import { getValidFilters } from 'app/modules/filters/utils';
import { selectFilters } from 'app/modules/transactions/sliceSelectors';
import { u21CreateAsyncThunk } from 'app/shared/thunk/u21CreateAsyncThunk';
import { u21CreateSlice } from 'app/shared/thunk/u21CreateSlice';

export const TRANSACTIONS_SLICE_NAME = 'transactionsSlice';

interface TransactionState {
  filters: Filter[];
}

const initialState: TransactionState = {
  filters: getValidFilters(
    getLocalStorageJSON(LocalStorageKeys.TRANSACTION_FILTERS),
    ALL_TRANSACTION_FILTER_OPTIONS,
  ),
};

export const setTransactionFilters = u21CreateAsyncThunk<Filter[], Filter[]>(
  `${TRANSACTIONS_SLICE_NAME}/SET_TRANSACTION_FILTERS`,
  (payload) => {
    localStorage.setItem(
      LocalStorageKeys.TRANSACTION_FILTERS,
      JSON.stringify(payload),
    );
    return payload;
  },
);

export const addTransactionFilter = u21CreateAsyncThunk<Filter>(
  `${TRANSACTIONS_SLICE_NAME}/ADD_TRANSACTION_FILTERS`,
  async (payload, { dispatch, getState }) => {
    const currentFilters = selectFilters(getState());
    let exists = false;
    const newFilters = currentFilters.map((i) => {
      if (i.key === payload.key) {
        exists = true;
        return payload;
      }
      return i;
    });
    if (!exists) {
      newFilters.push(payload);
    }
    await dispatch(setTransactionFilters(newFilters));
  },
);

export const transactionsSlice = u21CreateSlice({
  initialState,
  name: TRANSACTIONS_SLICE_NAME,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setTransactionFilters.fulfilled, (draft, action) => {
      draft.filters = action.payload;
    });
  },
});

export default transactionsSlice.reducer;
