import { HTMLProps, ReactElement, ReactNode } from 'react';

import { getDOMProps } from 'app/shared/utils/react';
import styled, { css } from 'styled-components';

// Components
import { Card, CardHeader, CardContent } from '@mui/material';
import { U21Spacer } from 'app/shared/u21-ui/components/layout/U21Spacer';
import { U21Typography } from 'app/shared/u21-ui/components/display/typography/U21Typography';
import { U21TypographyIcon } from 'app/shared/u21-ui/components/display/typography/U21TypographyIcon';

export interface U21CardProps
  extends Omit<HTMLProps<HTMLButtonElement>, 'action' | 'title'> {
  action?: ReactNode;
  children?: ReactNode;
  icon?: ReactElement;
  shaded?: boolean;
  subheader?: ReactNode;
  title?: ReactNode;
}

export const U21Card = ({
  action,
  children,
  icon,
  shaded,
  title,
  subheader,
  ...rest
}: U21CardProps) => {
  return (
    <StyledCard $shaded={shaded} {...getDOMProps(rest)}>
      <Content>
        {Boolean(title) && (
          <CardHeader
            action={action}
            title={
              <U21Spacer>
                <U21TypographyIcon variant="h4">{icon}</U21TypographyIcon>
                <span>{title}</span>
              </U21Spacer>
            }
            subheader={subheader}
            titleTypographyProps={{ variant: 'subtitle1' }}
            subheaderTypographyProps={{ variant: 'body2' }}
          />
        )}
        <CardContent>
          {typeof children === 'string' || typeof children === 'number' ? (
            <StyledU21Typography variant="h4">{children}</StyledU21Typography>
          ) : (
            children
          )}
        </CardContent>
      </Content>
    </StyledCard>
  );
};

const StyledCard = styled(Card)<{ $shaded?: boolean }>`
  display: flex;

  ${(props) => {
    return props.$shaded
      ? css`
          background-color: ${props.theme.palette.background.neutral};
          // keep border but same color as background so content positioning is the same
          border-color: ${props.theme.palette.background.neutral};
        `
      : '';
  }}
`;

const Content = styled(U21Spacer)`
  flex: 1 1 auto;
  max-width: 100%;
`;

const StyledU21Typography = styled(U21Typography)`
  .MuiTypography-root {
    width: 100%;
  }
`;
