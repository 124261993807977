import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { WorkflowActionTypes } from 'app/modules/workflows/models';
import { DEFAULT_PAGINATION_PAYLOAD } from 'app/shared/pagination/constants';

// Actions
import {
  deleteWorkflow as deleteWorkflowAction,
  deleteWorkflowSuccess,
  retrieveWorkflows,
} from 'app/modules/workflows/actions';

// API
import { deleteWorkflow as deleteWorkflowAPI } from 'app/shared/api/workflows';

// Utils
import { getWorkflowButtonsListPath } from 'app/modules/workflows/helpers';
import { history } from 'app/store/browserHistory';

const rootAction = WorkflowActionTypes.DELETE_WORKFLOW;
function* deleteWorkflow({
  payload,
  meta,
}: ReturnType<typeof deleteWorkflowAction>) {
  const config = {
    rootAction,
    errorToastMessage: 'Something went wrong, unable to delete this workflow.',
    successToastMessage: `Workflow deleted successfully.`,
    request: call(deleteWorkflowAPI, payload),
    success: function* onSuccess() {
      yield put(
        retrieveWorkflows({
          context: meta,
          ...DEFAULT_PAGINATION_PAYLOAD,
        }),
      );
      history.push(getWorkflowButtonsListPath(meta));
      yield put(deleteWorkflowSuccess(payload));
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watchdeleteWorkflow() {
  yield takeLatest(rootAction, deleteWorkflow);
}
