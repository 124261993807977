import { ActionsUnion, createAction } from 'app/shared/utils/action-helpers';

// Models
import {
  RuleSummary,
  RulesActionTypes,
  RetrieveTriggeredAlertsResponse,
  RuleEntitiesWhitelistPayload,
  RetrieveWhitelistedEntitiesRequest,
  RetrieveValidationRuleAlertsPayload,
  PaginatedAlerts,
  RetrievedRulesModel,
  RuleValidationAlertTransactionsPayload,
  RuleValidationAlertTransactionsResponse,
  RuleValidationAlertEntitiesPayload,
  RuleValidationAlertEntitiesResponse,
  DeployShadowRulePayload,
  RetrieveRulesPayload,
  RuleParametersAdditionalFields,
  SetEditingRulePayload,
  RuleValidationFileExportsResponse,
  RuleValidationAlertActionEventsResponse,
  RuleValidationAlertInstrumentsActionPayload,
} from 'app/modules/rules/models';
import {
  GlobalWhitelistEntitiesPayload,
  GlobalWhitelistPaginatedPayload,
} from 'app/modules/entities/models';
import { AlertDetails } from 'app/modules/alerts/models';
import { PaginationPayload } from 'app/shared/pagination/models';

export const setDynamicColumnDefinitionId = (id: number) => {
  return createAction(RulesActionTypes.SET_DYNAMIC_COLUMN_DEFINITION_ID, id);
};

export const retrieveValidationRules = (payload: PaginationPayload) => {
  return createAction(RulesActionTypes.RETRIEVE_VALIDATION_RULES, payload);
};

export const retrieveValidationRulesSuccess = (
  payload: RetrievedRulesModel,
) => {
  return createAction(
    RulesActionTypes.RETRIEVE_VALIDATION_RULES_SUCCESS,
    payload,
  );
};

export const deleteValidationRuleSuccess = (payload: number) => {
  return createAction(RulesActionTypes.DELETE_VALIDATION_RULE_SUCCESS, payload);
};

export const deployShadowRule = (payload: DeployShadowRulePayload) => {
  return createAction(RulesActionTypes.DEPLOY_SHADOW_RULE, payload);
};

export const deployShadowRuleSuccess = (payload: RuleSummary) => {
  return createAction(RulesActionTypes.DEPLOY_SHADOW_RULE_SUCCESS, payload);
};

export const retrieveRules = (payload: RetrieveRulesPayload) =>
  createAction(RulesActionTypes.RETRIEVE_RULES, payload);

export const retrieveRulesSuccess = (result) =>
  createAction(RulesActionTypes.RETRIEVE_RULES_SUCCESS, result);

export const retrieveComponentRules = (payload: RetrieveRulesPayload) =>
  createAction(RulesActionTypes.RETRIEVE_COMPONENT_RULES, payload);

export const retrieveComponentRulesSuccess = (result) =>
  createAction(RulesActionTypes.RETRIEVE_COMPONENT_RULES_SUCCESS, result);

export const clearRules = () => {
  return createAction(RulesActionTypes.CLEAR_RULES);
};

export const setAdditionalFieldsInRuleParameters = (
  payload: RuleParametersAdditionalFields,
) => {
  return createAction(
    RulesActionTypes.SET_ADDITIONAL_FIELDS_IN_RULE_PARAMETERS,
    payload,
  );
};

export const retrieveTriggeredAlerts = (
  id: number,
  payload: PaginationPayload,
) => createAction(RulesActionTypes.RETRIEVE_TRIGGERED_ALERTS, { id, payload });

export const retrieveTriggeredAlertsPending = () =>
  createAction(RulesActionTypes.RETRIEVE_TRIGGERED_ALERTS_PENDING);

export const retrieveTriggeredAlertsSuccess = (
  payload: RetrieveTriggeredAlertsResponse,
) => {
  return createAction(
    RulesActionTypes.RETRIEVE_TRIGGERED_ALERTS_SUCCESS,
    payload,
  );
};

export const retrieveTriggeredAlertsError = () =>
  createAction(RulesActionTypes.RETRIEVE_TRIGGERED_ALERTS_ERROR);

export const retrieveWhitelistedEntities = (
  payload: RetrieveWhitelistedEntitiesRequest,
) => createAction(RulesActionTypes.RETRIEVE_WHITELISTED_ENTITIES, payload);

export const retrieveWhitelistedEntitiesSuccess = (payload) => {
  return createAction(
    RulesActionTypes.RETRIEVE_WHITELISTED_ENTITIES_SUCCESS,
    payload,
  );
};

export const setEditingRule = (payload: SetEditingRulePayload) =>
  createAction(RulesActionTypes.SET_EDITING_RULE, payload);

export const whitelistEntitiesForRule = (
  payload: RuleEntitiesWhitelistPayload,
) => {
  return createAction(RulesActionTypes.WHITELIST_ENTITIES_FOR_RULE, payload);
};

export const whitelistEntitiesForRuleSuccess = () =>
  createAction(RulesActionTypes.WHITELIST_ENTITIES_FOR_RULE_SUCCESS);

export const removeWhitelistEntitiesForRule = (
  payload: RuleEntitiesWhitelistPayload,
) => {
  return createAction(
    RulesActionTypes.REMOVE_WHITELIST_ENTITIES_FOR_RULE,
    payload,
  );
};

export const removeWhitelistEntitiesForRuleSuccess = () =>
  createAction(RulesActionTypes.REMOVE_WHITELIST_ENTITIES_FOR_RULE_SUCCESS);

export const addEntitiesToGlobalWhitelist = (
  payload: GlobalWhitelistEntitiesPayload,
) => createAction(RulesActionTypes.GLOBALWHITELIST_ENTITIES, payload);

export const removeEntitiesFromGlobalWhitelist = (
  payload: GlobalWhitelistEntitiesPayload,
) => createAction(RulesActionTypes.REMOVE_GLOBALWHITELIST_ENTITIES, payload);

export const retrieveEntitiesFromGlobalWhitelist = (
  payload: GlobalWhitelistPaginatedPayload,
) => createAction(RulesActionTypes.RETRIEVE_GLOBALWHITELIST_ENTITIES, payload);

export const retrieveEntitiesFromGlobalWhitelistSuccess = (payload) =>
  createAction(
    RulesActionTypes.RETRIEVE_GLOBALWHITELIST_ENTITIES_SUCCESS,
    payload,
  );

export const retrieveRuleValidationAlert = (payload: string) => {
  return createAction(RulesActionTypes.RETRIEVE_RULE_VALIDATION_ALERT, payload);
};

export const retrieveRuleValidationAlertSuccess = (payload: AlertDetails) => {
  return createAction(
    RulesActionTypes.RETRIEVE_RULE_VALIDATION_ALERT_SUCCESS,
    payload,
  );
};

export const retrieveRuleValidationFiles = (id: number) => {
  return createAction(
    RulesActionTypes.RETRIEVE_RULE_VALIDATION_FILE_EXPORTS,
    id,
  );
};

export const retrieveRuleValidationFilesSuccess = (
  payload: RuleValidationFileExportsResponse,
) => {
  return createAction(
    RulesActionTypes.RETRIEVE_RULE_VALIDATION_FILE_EXPORTS_SUCCESS,
    payload,
  );
};

export const retrieveRuleValidationAlertTransactions = (
  payload: RuleValidationAlertTransactionsPayload,
) => {
  return createAction(
    RulesActionTypes.RETRIEVE_RULE_VALIDATION_ALERT_TRANSACTIONS,
    payload,
  );
};

export const retrieveRuleValidationAlertTransactionsSuccess = (
  payload: RuleValidationAlertTransactionsResponse,
) => {
  return createAction(
    RulesActionTypes.RETRIEVE_RULE_VALIDATION_ALERT_TRANSACTIONS_SUCCESS,
    payload,
  );
};

export const retrieveRuleValidationAlertEntities = (
  payload: RuleValidationAlertEntitiesPayload,
) => {
  return createAction(
    RulesActionTypes.RETRIEVE_RULE_VALIDATION_ALERT_ENTITIES,
    payload,
  );
};

export const retrieveRuleValidationAlertEntitiesSuccess = (
  payload: RuleValidationAlertEntitiesResponse,
) => {
  return createAction(
    RulesActionTypes.RETRIEVE_RULE_VALIDATION_ALERT_ENTITIES_SUCCESS,
    payload,
  );
};

export const retrieveRuleValidationAlertActionEventsSuccess = (
  payload: RuleValidationAlertActionEventsResponse & { id: number | string },
) => {
  return createAction(
    RulesActionTypes.RETRIEVE_RULE_VALIDATION_ALERT_ACTION_EVENTS_SUCCESS,
    payload,
  );
};

export const retrieveRulesValidationAlertInstrumentsSuccess = (
  payload: RuleValidationAlertInstrumentsActionPayload,
) => {
  return createAction(
    RulesActionTypes.RETRIEVE_RULE_VALIDATION_ALERT_INSTRUMENTS_SUCCESS,
    payload,
  );
};

export const retrievePaginatedAlerts = (
  payload: RetrieveValidationRuleAlertsPayload,
) => {
  return createAction(RulesActionTypes.RETRIEVE_PAGINATED_ALERTS, payload);
};

export const retrievePaginatedAlertsSuccess = (payload: PaginatedAlerts) => {
  return createAction(
    RulesActionTypes.RETRIEVE_PAGINATED_ALERTS_SUCCESS,
    payload,
  );
};

const RulesActionsList = {
  retrieveRules,
  retrieveRulesSuccess,

  retrieveComponentRules,
  retrieveComponentRulesSuccess,

  setDynamicColumnDefinitionId,

  clearRules,

  setEditingRule,

  retrieveTriggeredAlerts,
  retrieveTriggeredAlertsPending,
  retrieveTriggeredAlertsSuccess,
  retrieveTriggeredAlertsError,

  retrieveWhitelistedEntitiesSuccess,

  addEntitiesToGlobalWhitelist,
  removeEntitiesFromGlobalWhitelist,
  retrieveEntitiesFromGlobalWhitelist,
  retrieveEntitiesFromGlobalWhitelistSuccess,

  retrieveValidationRulesSuccess,
  retrieveRuleValidationAlertSuccess,
  retrieveRuleValidationAlertTransactionsSuccess,
  retrievePaginatedAlertsSuccess,

  deleteValidationRuleSuccess,

  retrieveRuleValidationAlertEntitiesSuccess,
  retrieveRuleValidationAlertActionEventsSuccess,

  retrieveRulesValidationAlertInstrumentsSuccess,

  setAdditionalFieldsInRuleParameters,

  retrieveRuleValidationFiles,
  retrieveRuleValidationFilesSuccess,
};
export type RulesActions = ActionsUnion<typeof RulesActionsList>;
export default RulesActions;
