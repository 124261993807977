import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { WebhookActionTypes } from 'app/modules/webhooks/models';

// Actions
import { listWebhooksSuccess } from 'app/modules/webhooks/actions';

// API
import { getWebhooks } from 'app/shared/api/webhooks';

const rootAction = WebhookActionTypes.LIST_WEBHOOKS;
export function* listWebhooks() {
  const config = {
    rootAction,
    request: call(getWebhooks),
    success: function* onSuccess(
      result: ReturnType<typeof listWebhooksSuccess>['payload'],
    ) {
      yield put(listWebhooksSuccess(result));
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watchListWebhooks() {
  yield takeLatest(rootAction, listWebhooks);
}
