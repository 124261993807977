import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { EntitiesActionTypes } from 'app/modules/entities/models';

// Actions
import {
  retrieveEntitiesLinkAnalysisMetadata as retrieveEntitiesLinkAnalysisMetadataAction,
  retrieveEntitiesLinkAnalysisMetadataSuccess,
} from 'app/modules/entities/actions';

// API
import { retrieveEntitiesLinkAnalysisMetadata as retrieveEntitiesLinkAnalysisMetadataAPI } from 'app/shared/api/entities';

const rootAction = EntitiesActionTypes.RETRIEVE_ENTITIES_LINK_ANALYSIS_METADATA;
export function* retrieveEntitiesLinkAnalysisMetadata({
  payload,
}: ReturnType<typeof retrieveEntitiesLinkAnalysisMetadataAction>) {
  const config = {
    rootAction,
    errorToastMessage:
      'Something went wrong, unable to retrieve link analysis metadata.',
    request: call(retrieveEntitiesLinkAnalysisMetadataAPI, payload),
    success: function* onSuccess(
      result: ReturnType<
        typeof retrieveEntitiesLinkAnalysisMetadataSuccess
      >['payload'],
    ) {
      yield put(retrieveEntitiesLinkAnalysisMetadataSuccess(result));
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watch() {
  yield takeLatest(rootAction, retrieveEntitiesLinkAnalysisMetadata);
}
