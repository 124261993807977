import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { TeamActionTypes, Tag } from 'app/modules/teamsOld/models';

// Actions
import { retrieveAllTagsSuccess } from 'app/modules/teamsOld/actions';

// API
import { getAllTags } from 'app/shared/api/team';
import { sendErrorToast } from 'app/shared/toasts/actions';
import { getTagLimitReachedError, TAG_CAP } from 'app/shared/helpers';

const rootAction = TeamActionTypes.RETRIEVE_ALL_TAGS;
export function* retrieveAllTags() {
  const config = {
    rootAction,
    request: call(getAllTags),
    success: function* onSuccess(result: Tag[]) {
      if (result.length > TAG_CAP) {
        const error = getTagLimitReachedError(result.length);
        yield put(sendErrorToast(error));
      }
      yield put(retrieveAllTagsSuccess(result));
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watchRetrieveAllTags() {
  yield takeLatest(rootAction, retrieveAllTags);
}
