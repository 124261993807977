import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { DeadlinesActionTypes } from 'app/modules/deadlines/models';

// Actions
import {
  retrieveDeadlinesConfigs as retrieveDeadlinesConfigsAction,
  retrieveDeadlinesConfigsSuccess,
} from 'app/modules/deadlines/actions';

// API
import { retrieveDeadlinesConfigs as retrieveDeadlinesConfigsAPI } from 'app/shared/api/deadlines';

const rootAction = DeadlinesActionTypes.RETRIEVE_DEADLINES_CONFIGS;
function* retrieveDeadlinesConfigs({
  payload,
}: ReturnType<typeof retrieveDeadlinesConfigsAction>) {
  const config = {
    rootAction,
    request: call(retrieveDeadlinesConfigsAPI, payload),
    success: function* onSuccess(
      result: ReturnType<typeof retrieveDeadlinesConfigsSuccess>['payload'],
    ) {
      yield put(retrieveDeadlinesConfigsSuccess(result));
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watchRetrieveDeadlinesConfigs() {
  yield takeLatest(rootAction, retrieveDeadlinesConfigs);
}
