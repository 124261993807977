import { getTagDisplayName } from 'app/modules/tags/utils';
import { selectAllTags } from 'app/modules/teamsOld/selectors';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { FilterMoreDisplay } from 'app/modules/filters/components/FilterMoreDisplay';
import {
  FILTER_OPERATOR,
  FILTER_TYPE_OPERATOR_LABELS,
} from 'app/modules/filters/constants';

interface Props {
  ids: number[];
  operator:
    | typeof FILTER_OPERATOR.IS_ONE_OF
    | typeof FILTER_OPERATOR.IS_NOT_ONE_OF;
}

export const TagFilterDisplay = (props: Props) => {
  const { ids, operator } = props;
  const tags = useSelector(selectAllTags);

  const values = useMemo(
    () => ids.map((i) => (tags[i] ? getTagDisplayName(tags[i]) : 'Unknown')),
    [ids, tags],
  );

  return (
    <>
      <b>Tag</b> {FILTER_TYPE_OPERATOR_LABELS[operator]}{' '}
      <FilterMoreDisplay values={values} />
    </>
  );
};
