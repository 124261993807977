import produce, { Draft } from 'immer';

// Actions
import WorkflowReduxActions from 'app/modules/workflows/actions';

// Models
import {
  WorkflowActionTypes,
  WorkflowState,
} from 'app/modules/workflows/models';

export const initialState: WorkflowState = {
  workflows: [],
  subdispositions: [],
  count: 0,
};

const reducer = (state = initialState, action: WorkflowReduxActions) => {
  return produce(state, (draft: Draft<WorkflowState>) => {
    switch (action.type) {
      case WorkflowActionTypes.RETRIEVE_WORKFLOWS_SUCCESS:
        draft.workflows = action.payload.workflows;
        draft.count = action.payload.count;
        return;

      case WorkflowActionTypes.RETRIEVE_WORKFLOW_SUCCESS:
        draft.workflow = action.payload;
        return;

      case WorkflowActionTypes.EDIT_WORKFLOW_SUCCESS:
        draft.workflow = action.payload;
        return;

      case WorkflowActionTypes.RETRIEVE_SUBDISPOSITIONS_SUCCESS:
        draft.subdispositions = action.payload;
        return;

      default:
        return;
    }
  });
};

export { reducer as workflowsReducer };
