import { ColorSchema } from 'vendor/material-minimal/palette';
import { HTMLProps, useEffect, useState } from 'react';

import { getDOMProps } from 'app/shared/utils/react';
import styled, { css } from 'styled-components';

import { Slider, SliderProps } from '@mui/material';

type SliderColor = 'inherit' | ColorSchema;

export interface U21SliderProps<T extends number | [number, number] = number>
  extends Omit<HTMLProps<HTMLDivElement>, 'onChange' | 'value'> {
  color?: SliderColor;
  disabled?: boolean;
  marks?: boolean | { label?: string; value: number }[];
  max?: number;
  min?: number;
  onChange: (value: T, e: Event) => void;
  step?: number;
  value?: T;
  valueLabelDisplay?: SliderProps['valueLabelDisplay'];
  valueLabelFormat?: (value: number, index: number) => React.ReactNode;
}

export const U21Slider = <T extends number | [number, number]>(
  props: U21SliderProps<T>,
) => {
  const {
    color = 'primary',
    disabled,
    marks,
    max = 100,
    min = 0,
    onChange,
    step = 1,
    value = 0,
    valueLabelDisplay = 'auto',
    valueLabelFormat,
    ...rest
  } = props;

  const [sliderValue, setSliderValue] = useState(value);
  useEffect(() => {
    setSliderValue(value);
  }, [value]);

  return (
    <Container {...getDOMProps(rest)}>
      <StyledSlider
        $color={color}
        disabled={disabled}
        marks={marks}
        max={max}
        min={min}
        onChange={(e: Event, val: T) => setSliderValue(val)}
        onChangeCommitted={(e: Event, val: T) => onChange(val, e)}
        orientation="horizontal"
        step={step}
        value={sliderValue}
        valueLabelDisplay={valueLabelDisplay}
        valueLabelFormat={valueLabelFormat}
      />
    </Container>
  );
};

const Container = styled.div`
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const StyledSlider = styled(Slider)<{ $color: SliderColor }>`
  /* hardcode margin to include the slide label */
  margin-top: 35px;
  color: ${(props) => props.theme.palette[props.$color].main};

  .MuiSlider-rail {
    background-color: ${(props) => props.theme.palette.grey[400]};
  }

  .MuiSlider-thumb.Mui-disabled {
    color: ${(props) => props.theme.palette.grey[500]};
  }

  .MuiSlider-valueLabel {
    background-color: ${(props) => props.theme.palette[props.$color].main};

    ${(props) =>
      (props.$color === 'success' || props.$color === 'warning') &&
      css`
        color: ${props.theme.palette.text.primary};
      `}
  }
`;
