// Utils
import { post } from 'app/shared/utils/fetchr';
import routes from 'app/shared/utils/routes';

// Actions
import { saveCustomConfig as saveCustomConfigAction } from 'app/shared/CustomConfig/actions';

// TODO: [anami] rename to saveAgentCustomConfig
export const saveCustomConfig = (
  payload: ReturnType<typeof saveCustomConfigAction>['payload'],
) => {
  return post(routes.patronus.customConfigs, payload);
};

export const retrieveOrgTableConfig = () => {
  const path = routes.patronus.customConfigsOrg;
  return post(path);
};
