import { call, takeLatest, select } from 'redux-saga/effects';

// Models
import { SessionActionTypes, SessionAgent } from 'app/modules/session/models';

// Selectors
import { selectAgent } from 'app/modules/session/selectors';

// Utils
import { retrieveSessionAgentPermissions } from 'app/shared/api/permissions';
import { setupIntercom } from 'app/shared/utils/intercom';
import getFromProcessEnv from 'app/shared/utils/getFromProcessEnv';
import { isQAUser } from 'app/modules/session/helpers';
import { PermissionSummary } from 'app/modules/permissions/models';

const rootAction = SessionActionTypes.INIT_INTERCOM;
function* init() {
  const intercomId = getFromProcessEnv('REACT_APP_INTERCOM_ID');
  if (intercomId) {
    const {
      id,
      email,
      full_name: name,
      org_id: orgID,
      org_name: orgName,
      org_display_name: orgDisplayName,
    }: SessionAgent = yield select(selectAgent);

    if (isQAUser(email)) {
      return;
    }

    let permissions: Record<string, true> = {};
    try {
      const assigned: PermissionSummary[] = yield call(
        retrieveSessionAgentPermissions,
        {
          associationType: 'agent',
          associationId: id,
        },
      );
      permissions = assigned.reduce(
        (acc, i) => ({ ...acc, [`permission:${i.name}`]: true }),
        {},
      );
    } catch {}

    setupIntercom({
      app_id: intercomId,
      email,
      name,
      org_id: orgID,
      org_name: orgName,
      company: {
        // use orgName as company ID as that is how we uniquely different orgs across environments
        company_id: orgName,
        name: orgDisplayName,
      },
      user_id: email,
      ...permissions,
    });
  }
}

export default function* initializeIntercom() {
  yield takeLatest(rootAction, init);
}
