import { createSelector } from 'reselect';

// Models
import { EntitiesActionTypes } from 'app/modules/entities/models';

// Constants
import { initialPaginatedEntityAlerts } from 'app/modules/entities/reducer';

// Constants
import { EMPTY_ENTITY } from 'app/modules/entities/constants';

export const selectEntity = (state: RootState) => {
  return state.entities.details || EMPTY_ENTITY;
};

export const selectPaginatedEntityAlerts = (state: RootState) => {
  return (
    state.entities.details?.paginated_alerts || {
      ...initialPaginatedEntityAlerts,
    }
  );
};

// Loading
export const selectLinkAnalysisLoading = (state: RootState): boolean => {
  return Boolean(
    state.loading[EntitiesActionTypes.RETRIEVE_ENTITY_LINK_ANALYSIS],
  );
};

export const selectEntityLinkAnalysisReport = (state: RootState) =>
  state.entities.linkAnalysisReport;

export const selectEntitiesLinkAnalysisMetadata = (state: RootState) =>
  state.entities.entitiesLinkAnalysisMetadata;

export const selectEntitiesLinkAnalysisMetadataLoading = (state: RootState) =>
  Boolean(
    state.loading[EntitiesActionTypes.RETRIEVE_ENTITIES_LINK_ANALYSIS_METADATA],
  );

export const selectEntityIdsWithPIIOverlap = createSelector(
  selectEntitiesLinkAnalysisMetadata,
  ({ metadata }) =>
    new Set(metadata.filter((m) => m.pii_overlap).map((m) => m.entity_id)),
);

export const selectRetrieveEntityLoading = (state: RootState): boolean => {
  return Boolean(state.loading[EntitiesActionTypes.RETRIEVE_ENTITY]);
};

export const selectEditEntityLoading = (state: RootState): boolean => {
  return Boolean(state.loading[EntitiesActionTypes.EDIT_ENTITY]);
};

export const selectEntityAlertsLoading = (state: RootState): boolean => {
  return Boolean(state.loading[EntitiesActionTypes.RETRIEVE_ENTITY_ALERTS]);
};
