import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { GoAMLActionTypes } from 'app/modules/goAML/models';

// Actions
import {
  exportGoAMLFiling as exportGoAMLFilingAction,
  exportGoAMLFilingSuccess,
} from 'app/modules/goAML/actions';

// API
import { exportGoAMLFiling as exportGoAMLFilingAPI } from 'app/shared/api/goAML';

const rootAction = GoAMLActionTypes.EXPORT_GOAML_FILING;
export function* exportGoAMLFiling({
  payload,
}: ReturnType<typeof exportGoAMLFilingAction>) {
  const config = {
    rootAction,
    errorToastMessage: 'Something went wrong, unable to export goAML filing.',
    request: call(exportGoAMLFilingAPI, payload),
    success: function* onSuccess() {
      yield put(exportGoAMLFilingSuccess());
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watch() {
  yield takeLatest(rootAction, exportGoAMLFiling);
}
