import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { NotesActionTypes } from 'app/modules/notes/models';

// Actions
import {
  retrieveNotes as retrieveNotesAction,
  retrieveNotesSuccess,
} from 'app/modules/notes/actions';

// API
import { getNotes } from 'app/shared/api/notes';

export const errorToastMessage = 'Unable to retrieve notes at this moment.';

const rootAction = NotesActionTypes.RETRIEVE_NOTES;
export function* retrieveNotes({
  payload,
}: ReturnType<typeof retrieveNotesAction>) {
  const config = {
    rootAction,
    request: call(getNotes, payload),
    errorToastMessage,
    success: function* onSuccess(result) {
      yield put(retrieveNotesSuccess(result));
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watchRetrieveNotes() {
  yield takeLatest(NotesActionTypes.RETRIEVE_NOTES, retrieveNotes);
}
