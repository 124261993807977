import React from 'react';
import { useHistory } from 'react-router-dom';

// Components
import { Icon, Label } from 'semantic-ui-react';

// Utils
import routes from 'app/shared/utils/routes';
import assets from 'app/shared/utils/assets';

// Styles
import styles from 'app/shared/events/styles/EventTable.module.scss';

export interface ListItemProps {
  id: string;
  title?: string;
  type:
    | 'Alert'
    | 'Entity'
    | 'Event'
    | 'Case'
    | 'Rule'
    | 'Rule ID'
    | 'Instrument'
    | 'SAR Filing';
  shouldLink?: boolean;
  onClick?: () => void;
}

/**
 * @deprecated Use U21Chip instead
 */
const LinkedLabel = ({
  type: typeProp,
  id,
  shouldLink,
  title,
  onClick,
}: ListItemProps) => {
  const { push } = useHistory();
  const [lumosId, icon] =
    'Entity:entitiesId/user, Alert:alertsId/alerts, Case:casesId/cases, Event:transactionsId/transactions, Rule:detectionModelsId/rules, Instrument:instrumentsId/creditCard, SAR Filing:filingsSarId/sarFilings'
      .match(new RegExp(`${typeProp}:(\\w+)/(\\w+)`))
      ?.slice(1) || [];

  // If we weren't able to derive what the label should link to
  if (!lumosId) {
    return null;
  }

  // If the identifier provided wouldn't link to a page
  if (!id) {
    return null;
  }

  return (
    <div>
      <Label
        className={`${styles.valueLabel} ${styles.linkLabel}`}
        onClick={(e) => {
          e.stopPropagation();
          if (onClick) onClick();
          if (shouldLink) {
            push(routes.lumos[lumosId]?.replace(':id', id));
          }
        }}
      >
        <Icon name={assets.icons[icon]} />
        {title || id}
      </Label>
    </div>
  );
};

export default LinkedLabel;
