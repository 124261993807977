import { call, put, select, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import {
  EditEntityResult,
  EntitiesActionTypes,
} from 'app/modules/entities/models';

// Actions
import {
  editEntity as editEntityAction,
  retrieveEntity,
} from 'app/modules/entities/actions';
import { sendSuccessToast } from 'app/shared/toasts/actions';

// Selectors
import { selectEntity } from 'app/modules/entities/selectors';

// API
import { editEntity as editEntityApi } from 'app/shared/api/entities';

const rootAction = EntitiesActionTypes.EDIT_ENTITY;
function* editEntity({ payload }: ReturnType<typeof editEntityAction>) {
  const config = {
    rootAction,
    errorToastMessage: 'Something went wrong, unable to edit this entity.',
    request: call(editEntityApi, payload),
    success: function* onSuccess(result: EditEntityResult) {
      const currentEntity = yield select(selectEntity);
      const pageChanged = currentEntity.id !== result.id;
      if (!pageChanged) {
        yield put(
          sendSuccessToast(`Entity ${result.id} updated successfully.`),
        );
      }
      yield put(retrieveEntity(result.id));
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watchEditEntity() {
  yield takeLatest(rootAction, editEntity);
}
