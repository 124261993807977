import { TPersonMyClient, TPerson } from 'app/modules/goAML/models';

export const EMPTY_PERSON: TPerson = {
  gender: '',
  title: '',
  first_name: '',
  last_name: '',
  birthdate: '',
  id_number: '',
  nationality1: '',
  residence: '',
  email: '',
  identification: [],
};

export const EMPTY_PERSON_MY_CLIENT: TPersonMyClient = {
  gender: '',
  title: '',
  first_name: '',
  last_name: '',
  birthdate: '',
  id_number: '',
  nationality1: '',
  residence: '',
  email: '',
  identification: [
    {
      type: '',
      number: '',
      issue_country: '',
    },
  ],
};
