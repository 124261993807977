import { TableConfig } from 'app/shared/pagination/models';
import { CustomDataFieldType } from 'app/modules/orgSettings/responses';

export enum TableConfigType {
  ENTITY_TABLE = 'ENTITY_TABLE',
  INSTRUMENT_TABLE = 'INSTRUMENT_TABLE',
  TXN_TABLE = 'TXN_TABLE',
  ALERT_TABLE = 'ALERT_TABLE',
  ACTION_EVENT_TABLE = 'ACTION_EVENT_TABLE',
  CASE_TABLE = 'CASE_TABLE',
  VALIDATING_MODEL_TABLE = 'VALIDATING_MODEL_TABLE',
  LIVE_MODEL_TABLE = 'LIVE_MODEL_TABLE',
  SHADOW_MODEL_TABLE = 'DETECTION_MODEL_TABLE',
  SAR_TABLE = 'SAR_TABLE',
  LINK_ANALYSIS_TABLE = 'LINK_ANALYSIS_TABLE',
  ALERT_QUEUES_TABLE = 'ALERT_QUEUES_TABLE',
  TEAMS_TABLE = 'TEAMS_TABLE',
  CASE_QUEUES_TABLE = 'CASE_QUEUES_TABLE',
  SAR_QUEUES_TABLE = 'SAR_QUEUES_TABLE',
  RULE_ADMIN_SCENARIO_CONFIG_TABLE = 'RULE_ADMIN_SCENARIO_CONFIG_TABLE',
  RULE_ADMIN_CONTENT_TABLE = 'RULE_ADMIN_CONTENT_TABLE',
  RULE_ADMIN_QUERY_TABLE = 'RULE_ADMIN_QUERY_TABLE',
  RULE_ADMIN_EXECUTION_TABLE = 'RULE_ADMIN_EXECUTION_TABLE',
  RULE_ADMIN_VALIDATION_TABLE = 'RULE_ADMIN_VALIDATION_TABLE',
  RULE_ADMIN_SCHEDULE_TABLE = 'RULE_ADMIN_SCHEDULE_TABLE',
  RULE_ADMIN_ALERT_CONTENT_TABLE = 'RULE_ADMIN_ALERT_CONTENT_TABLE',
  RULE_ADMIN_ALERT_HIT_TABLE = 'RULE_ADMIN_ALERT_HIT_TABLE',
  DATA_SETTINGS = 'DATA_SETTINGS',
  WATCHLIST_TABLE = 'WATCHLIST_TABLE',
}

export enum CustomDataConfigType {
  ENTITY_CUSTOM_DATA_SETTINGS = 'ENTITY_CUSTOM_DATA_SETTINGS',
  TXN_CUSTOM_DATA_SETTINGS = 'TXN_CUSTOM_DATA_SETTINGS',
  INSTRUMENT_CUSTOM_DATA_SETTINGS = 'INSTRUMENT_CUSTOM_DATA_SETTINGS',
  DEVICE_CUSTOM_DATA_SETTINGS = 'DEVICE_CUSTOM_DATA_SETTINGS',
}

export enum TabConfigType {
  TM_ALERT_TABS = 'TM_ALERT_TABS',
  CHAINALYSIS_ALERT_TABS = 'CHAINALYSIS_ALERT_TABS',
  CASE_TABS = 'CASE_TABS',
  ENTITY_DETAILS_TAB = 'ENTITY_DETAILS_TAB',
  INSTRUMENT_DETAILS_TAB = 'INSTRUMENT_DETAILS_TAB',
  WATCHLIST_ALERT_TABS = 'WATCHLIST_ALERT_TABS',
  DARK_WEB_ALERT_TABS = 'DARK_WEB_ALERT_TABS',
  FINCEN_314A_ALERT_TABS = 'FINCEN_314A_ALERT_TABS',
}

export enum MiscellaneousConfigType {
  INTERFACE_SETTINGS = 'INTERFACE_SETTINGS',
}

export const CustomConfigType = {
  ...TableConfigType,
  ...MiscellaneousConfigType,
  ...CustomDataConfigType,
  ...TabConfigType,
};

export const TableConfigLabels: { [key in TableConfigType]: string } = {
  [TableConfigType.ENTITY_TABLE]: 'Entity Table',
  [TableConfigType.CASE_TABLE]: 'Case Table',
  [TableConfigType.SAR_TABLE]: 'SAR Table',
  [TableConfigType.INSTRUMENT_TABLE]: 'Instruments Table',
  [TableConfigType.TXN_TABLE]: 'Transactions Table',
  [TableConfigType.ALERT_TABLE]: 'Alert Table',
  [TableConfigType.VALIDATING_MODEL_TABLE]: 'Validating Detection Models Table',
  [TableConfigType.LIVE_MODEL_TABLE]: 'Live Detection Models Table',
  [TableConfigType.SHADOW_MODEL_TABLE]: 'Detection Models Table',
  [TableConfigType.ACTION_EVENT_TABLE]: 'Action Event Table',
  [TableConfigType.LINK_ANALYSIS_TABLE]: 'Link Analysis Table',
  [TableConfigType.TEAMS_TABLE]: 'Teams Table',
  [TableConfigType.ALERT_QUEUES_TABLE]: 'Alert Queues Table',
  [TableConfigType.CASE_QUEUES_TABLE]: 'Case Queues Table',
  [TableConfigType.SAR_QUEUES_TABLE]: 'Filing Queues Table',
  [TableConfigType.RULE_ADMIN_SCENARIO_CONFIG_TABLE]:
    'Rule Admin Scenario Config Table',
  [TableConfigType.RULE_ADMIN_CONTENT_TABLE]: 'Rule Admin Content Table',
  [TableConfigType.RULE_ADMIN_QUERY_TABLE]: 'Rule Admin Query Table',
  [TableConfigType.RULE_ADMIN_EXECUTION_TABLE]: 'Rule Admin Execution Table',
  [TableConfigType.RULE_ADMIN_VALIDATION_TABLE]: 'Rule Admin Validation Table',
  [TableConfigType.RULE_ADMIN_SCHEDULE_TABLE]: 'Rule Admin Schedule Table',
  [TableConfigType.RULE_ADMIN_ALERT_CONTENT_TABLE]:
    'Rule Admin Alert Content Table',
  [TableConfigType.RULE_ADMIN_ALERT_HIT_TABLE]: 'Rule Admin Alert Hit Table',
  [TableConfigType.DATA_SETTINGS]: 'Data Settings Table',
  [TableConfigType.WATCHLIST_TABLE]: 'Watchlist Results Table',
};

export const CustomDataConfigLabels: { [key in CustomDataConfigType]: string } =
  {
    [CustomDataConfigType.DEVICE_CUSTOM_DATA_SETTINGS]:
      'Device custom data settings',
    [CustomDataConfigType.INSTRUMENT_CUSTOM_DATA_SETTINGS]:
      'Instrument custom data settings',
    [CustomDataConfigType.TXN_CUSTOM_DATA_SETTINGS]:
      'Transaction custom data settings',
    [CustomDataConfigType.ENTITY_CUSTOM_DATA_SETTINGS]:
      'Entity custom data settings',
  };

export enum TableConfigActionTypes {
  SAVE_TABLE_CONFIG = '@@tableConfig/SAVE_TABLE_CONFIG',
  SAVE_TABLE_CONFIG_SUCCESS = '@@tableConfig/SAVE_TABLE_CONFIG_SUCCESS',
  RETRIEVE_ORG_TABLE_CONFIG = '@@tableConfig/RETRIEVE_ORG_TABLE_CONFIG',
  RETRIEVE_ORG_TABLE_CONFIG_SUCCESS = '@@tableConfig/RETRIEVE_ORG_TABLE_CONFIG_SUCCESS',
  RETRIEVE_TABLE_CONFIG_SUCCESS = '@@tableConfig/RETRIEVE_TABLE_CONFIG_SUCCESS',
  SAVE_LOCAL_TABLE_CONFIG = '@@tableConfig/SAVE_LOCAL_TABLE_CONFIG',
}

export interface TableConfigActionPayload {
  tableConfigType: TableConfigType;
}

export interface BaseCustomConfig {
  definition: object;
  type:
    | TableConfigType
    | MiscellaneousConfigType
    | CustomDataConfigType
    | TabConfigType;
  name?: string;
  description?: string;
  flag?: string;
  modified_at: string;
}

export interface BaseCustomConfigPayload {
  definition: object;
  type:
    | TableConfigType
    | MiscellaneousConfigType
    | CustomDataConfigType
    | TabConfigType;
  name?: string;
  description?: string;
  flag?: string;
}

export interface CustomDataConfig extends BaseCustomConfig {
  definition: CustomDataSettings[];
  type: CustomDataConfigType;
}

export interface CustomDataSettings {
  key: string;
  label: string;
  type?: CustomDataFieldType;
  nested?: boolean;
  exportable: boolean;
}

/* the "modified_at" requirement is omitted here because table configs can come
from either the custom_config table or lumos configs (org_settings.lumos_config)
and the latter don't include a modified_at field. Here we remove the required modified_at
 field and instead add an optional modified_at field */

export interface CustomTableConfig
  extends Omit<BaseCustomConfig, 'modified_at'> {
  definition: TableConfig[];
  type: TableConfigType;
  modified_at?: string;
}

export interface CustomTableConfigPayload extends BaseCustomConfigPayload {
  definition: TableConfig[];
  type: TableConfigType;
}

export interface CustomTabConfig extends BaseCustomConfig {
  definition: TabConfig[];
  type: TabConfigType;
  modified_at: string;
}

export interface CustomTabConfigPayload extends BaseCustomConfigPayload {
  definition: TabConfig[];
  type: TabConfigType;
}

export interface TabConfig {
  key: string;
  hidden: boolean;
}

export interface CustomInterfaceConfig {
  definition: CustomInterfaceSettings;
  type: MiscellaneousConfigType.INTERFACE_SETTINGS;
}

export interface CustomInterfaceSettings {
  homePage?: string;
}

export interface CustomConfigsMap {
  // Table Configs
  [TableConfigType.ACTION_EVENT_TABLE]?: CustomTableConfig;
  [TableConfigType.ENTITY_TABLE]?: CustomTableConfig;
  [TableConfigType.INSTRUMENT_TABLE]?: CustomTableConfig;
  [TableConfigType.TXN_TABLE]?: CustomTableConfig;
  [TableConfigType.ALERT_TABLE]?: CustomTableConfig;
  [TableConfigType.CASE_TABLE]?: CustomTableConfig;
  [TableConfigType.SAR_TABLE]?: CustomTableConfig;
  [TableConfigType.VALIDATING_MODEL_TABLE]?: CustomTableConfig;
  [TableConfigType.LIVE_MODEL_TABLE]?: CustomTableConfig;
  [TableConfigType.SHADOW_MODEL_TABLE]?: CustomTableConfig;
  [TableConfigType.ACTION_EVENT_TABLE]?: CustomTableConfig;
  [TableConfigType.LINK_ANALYSIS_TABLE]?: CustomTableConfig;
  [TableConfigType.ALERT_QUEUES_TABLE]?: CustomTableConfig;
  [TableConfigType.TEAMS_TABLE]?: CustomTableConfig;
  [TableConfigType.RULE_ADMIN_SCENARIO_CONFIG_TABLE]?: CustomTableConfig;
  [TableConfigType.RULE_ADMIN_CONTENT_TABLE]?: CustomTableConfig;
  [TableConfigType.RULE_ADMIN_QUERY_TABLE]?: CustomTableConfig;
  [TableConfigType.RULE_ADMIN_EXECUTION_TABLE]?: CustomTableConfig;
  [TableConfigType.RULE_ADMIN_VALIDATION_TABLE]?: CustomTableConfig;
  [TableConfigType.RULE_ADMIN_SCHEDULE_TABLE]?: CustomTableConfig;
  [TableConfigType.RULE_ADMIN_ALERT_CONTENT_TABLE]?: CustomTableConfig;
  [TableConfigType.RULE_ADMIN_ALERT_HIT_TABLE]?: CustomTableConfig;
  [TableConfigType.DATA_SETTINGS]?: CustomTableConfig;
  [TableConfigType.WATCHLIST_TABLE]?: CustomTableConfig;

  // Custom Data Configs
  [CustomDataConfigType.DEVICE_CUSTOM_DATA_SETTINGS]?: CustomDataConfig;
  [CustomDataConfigType.INSTRUMENT_CUSTOM_DATA_SETTINGS]?: CustomDataConfig;
  [CustomDataConfigType.TXN_CUSTOM_DATA_SETTINGS]?: CustomDataConfig;
  [CustomDataConfigType.ENTITY_CUSTOM_DATA_SETTINGS]?: CustomDataConfig;

  // Custom Tab Configs
  [TabConfigType.TM_ALERT_TABS]?: CustomTabConfig;
  [TabConfigType.CHAINALYSIS_ALERT_TABS]?: CustomTabConfig;
  [TabConfigType.CASE_TABS]?: CustomTabConfig;
  [TabConfigType.WATCHLIST_ALERT_TABS]?: CustomTabConfig;
  [TabConfigType.DARK_WEB_ALERT_TABS]?: CustomTabConfig;
  [TabConfigType.FINCEN_314A_ALERT_TABS]?: CustomTabConfig;

  // Other custom configs
  [MiscellaneousConfigType.INTERFACE_SETTINGS]?: CustomInterfaceConfig;
}
export interface TableConfigState {
  configs: CustomConfigsMap;
}
