// Redux
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { u21CreateAsyncThunk } from 'app/shared/thunk/u21CreateAsyncThunk';

// Types from other parts of the app
import { FullTeamResponse } from 'app/modules/teams/responses';

// API
import {
  editPermissions,
  retrieveUnassignedPermissions,
} from 'app/shared/api/permissions';

// Selectors
import { selectAllUsedPermissions } from 'app/modules/devtools/selectors';

const DEVTOOLS_NAME = 'devtools';

interface DevtoolsState {
  allPermissions: Record<string, boolean>;
  perRoutePermissions: Record<string, string[]>;
}

const initialState: DevtoolsState = {
  allPermissions: {},
  perRoutePermissions: {},
};

export const addAuditorPermissionsThunk = u21CreateAsyncThunk<FullTeamResponse>(
  `${DEVTOOLS_NAME}/ADD_AUDITOR_PERMISSIONS`,

  async (payload, { getState }) => {
    const usedPermissions = selectAllUsedPermissions(getState());

    // Since the newly created team has no permissions, retrieveUnassignedPermissions
    // will return all permissions
    const allPermissions = await retrieveUnassignedPermissions({
      associationId: payload.id,
      associationType: 'team',
    });

    const desiredPermissionIDs = allPermissions
      .filter((permission) => {
        // We don't want to grant root:all permissions anywhere, but we do want to
        // leave it in the state so we can surface usages of it and remove them on the backend
        // Similarly, create:teams will always be present since we create a team right before
        // this call, but we likely don't actually want the new team to have this permission
        return (
          permission.name in usedPermissions &&
          permission.name !== 'root:all' &&
          permission.name !== 'create:teams'
        );
      })
      .map((permission) => permission.id);

    await editPermissions({
      associationId: payload.id,
      associationType: 'team',
      permissions: desiredPermissionIDs,
    });
  },
);

const devtoolsSlice = createSlice({
  name: DEVTOOLS_NAME,
  initialState,
  reducers: {
    addPermission: (draft, action: PayloadAction<string[]>) => {
      const path = window.location.pathname;
      const currentRoutePermissions = draft.perRoutePermissions[path];

      if (!currentRoutePermissions) {
        draft.perRoutePermissions[path] = action.payload;
      } else {
        action.payload.forEach((permission) => {
          if (!currentRoutePermissions.includes(permission)) {
            currentRoutePermissions.push(permission);
          }
        });
      }

      action.payload.forEach((permission) => {
        draft.allPermissions[permission] = true;
      });
    },
    resetPermissions: (draft) => {
      draft.allPermissions = initialState.allPermissions;
    },
  },
});

export const { addPermission, resetPermissions } = devtoolsSlice.actions;

export const DEVTOOLS_SLICE_NAME = devtoolsSlice.name;
export default devtoolsSlice.reducer;
