import {
  AgentNotificationSettingResponse,
  NotificationEventTypes,
} from 'app/modules/agents/responses';

import { AGENTS_SLICE_NAME } from 'app/modules/agents/sliceAgents';
import { NOTIFICATION_EVENT_GROUP_ORDER } from 'app/modules/agents/constants';

import { createSelector } from 'reselect';

export const selectAgents = (state: RootState) =>
  state[AGENTS_SLICE_NAME].agents;

export const selectAgentDetails = (state: RootState) =>
  state[AGENTS_SLICE_NAME].agent;

export const selectAgentsCount = (state: RootState) =>
  state[AGENTS_SLICE_NAME].agentsCount;

export const selectFilters = (state: RootState) =>
  state[AGENTS_SLICE_NAME].filters;

const selectAgentNotificationSettings = (state: RootState) =>
  state[AGENTS_SLICE_NAME].notificationSettings;

const selectAgentSlackNotificationSettings = (state: RootState) =>
  state[AGENTS_SLICE_NAME].slackNotificationSettings;

export const selectAgentNotificationGroupSettings = createSelector(
  selectAgentNotificationSettings,
  (settings) =>
    settings.reduce<Record<string, AgentNotificationSettingResponse[]>>(
      (acc, i) => {
        const { event_group: group } = i;
        if (acc[group]) {
          acc[group].push(i);
        } else {
          acc[group] = [i];
        }
        return acc;
      },
      {},
    ),
);

export const selectDailyDigestEnabled = createSelector(
  selectAgentNotificationSettings,
  (settings) =>
    settings.find((i) => i.event_type === NotificationEventTypes.DAILY_DIGEST)
      ?.notification_methods?.email || false,
);

export const selectIsSlackInstalled = createSelector(
  selectAgentSlackNotificationSettings,
  (slackNotificationSettings) =>
    Object.keys(slackNotificationSettings.individual_slack_meta).length > 0,
);

export const selectAllValidNotifications = createSelector(
  selectAgentNotificationGroupSettings,
  (settingsMap) =>
    NOTIFICATION_EVENT_GROUP_ORDER.map((i) => settingsMap[i] || []).flat(),
);

export const selectAllNotificationsEnabled = createSelector(
  selectAllValidNotifications,
  selectIsSlackInstalled,
  (settings, isSlackInstalled) =>
    settings.every(
      (i) =>
        i.notification_methods.email &&
        i.notification_methods.system &&
        (isSlackInstalled ? i.notification_methods.slack : true),
    ),
);

export const selectSomeNotificationsEnabled = createSelector(
  selectAllValidNotifications,
  (settings) =>
    settings.some(
      (i) => i.notification_methods.email && i.notification_methods.system,
    ),
);

// Loading
export const selectLoadingAgents = (state: RootState) =>
  state[AGENTS_SLICE_NAME].loadingAgents;

export const selectLoadingDeactivateAgent = (state: RootState) =>
  state[AGENTS_SLICE_NAME].loadingDeactivateAgent;

export const selectLoadingActivateAgent = (state: RootState) =>
  state[AGENTS_SLICE_NAME].loadingActivateAgent;

export const selectLoadingAddAgent = (state: RootState) =>
  state[AGENTS_SLICE_NAME].loadingAddAgent;

export const selectLoadingAgentDetails = (state: RootState) =>
  state[AGENTS_SLICE_NAME].loadingAgentDetails;

export const selectLoadingUpdateAgent = (state: RootState) =>
  state[AGENTS_SLICE_NAME].loadingUpdateAgent;

export const selectLoadingUpdateAgentPicture = (state: RootState) =>
  state[AGENTS_SLICE_NAME].loadingUpdateAgentPicture;

export const selectLoadingNotificationSettings = (state: RootState) =>
  state[AGENTS_SLICE_NAME].loadingNotificationSettings;

export const selectLoadingEnableSlackNotifications = (state: RootState) =>
  state[AGENTS_SLICE_NAME].loadingEnableSlackNotifications;

export const selectLoadingDisableSlackNotifications = (state: RootState) =>
  state[AGENTS_SLICE_NAME].loadingDisableSlackNotifications;
