import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Actions
import {
  retrieveRules as action,
  retrieveRulesSuccess,
} from 'app/modules/rules/actions';

// Api
import { retrieveRules as api } from 'app/shared/api/rules';

// Models
import { RulesActionTypes } from 'app/modules/rules/models';

const rootAction = RulesActionTypes.RETRIEVE_RULES;
function* retrieveRules({ payload }: ReturnType<typeof action>) {
  const config = {
    rootAction,
    request: call(api, payload),
    success: function* onSuccess(result) {
      yield put(retrieveRulesSuccess(result));
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watch() {
  yield takeLatest(rootAction, retrieveRules);
}
