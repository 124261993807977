// Redux
import { Reducer } from 'redux';
import { Draft, produce } from 'immer';

// Models
import { NavigatorState } from 'app/modules/navigator/models';

import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router';

// Utils
import {
  getRoutingHistory,
  setRoutingHistory,
} from 'app/shared/utils/sessionStorage';
import {
  popRoute,
  pushRoute,
  replaceRoute,
} from 'app/modules/navigator/helpers';
import deepFreeze from 'app/shared/utils/deepFreeze';

const initialState: NavigatorState = {
  // pad it with current location since connected-react-router fires a pop event initially
  history: [
    `${window.location.pathname}${window.location.search}`,
    ...getRoutingHistory(),
  ],
};

deepFreeze(initialState);

const reducer: Reducer<NavigatorState> = (
  state = initialState,
  action: LocationChangeAction,
) => {
  return produce(state, (draft: Draft<NavigatorState>) => {
    switch (action.type) {
      case LOCATION_CHANGE: {
        const {
          action: routeAction,
          location: { pathname, search },
        } = action.payload;
        const url = `${pathname}${search}`;
        switch (routeAction) {
          case 'POP':
            draft.history = popRoute(state.history, url);
            break;
          case 'PUSH':
            draft.history = pushRoute(state.history, url);
            break;
          case 'REPLACE':
            draft.history = replaceRoute(state.history, url);
            break;
          default:
            break;
        }
        setRoutingHistory(draft.history);
        return;
      }

      default:
        return;
    }
  });
};

export { reducer as navigatorReducer, initialState };
