import { u21CreateSlice } from 'app/shared/thunk/u21CreateSlice';
import { u21CreateAsyncThunk } from 'app/shared/thunk/u21CreateAsyncThunk';

// Models
import {
  CreateNarrativePayload,
  EditNarrativePayload,
  ListNarrativesPayload,
} from 'app/modules/narratives/requests';
import { ListNarrativesResponse } from 'app/modules/narratives/responses';
import { Narrative } from 'app/modules/narratives/models';

// APIs
import {
  createNarrativeApi,
  deleteNarrativeApi,
  editNarrativeApi,
  retrieveNarrativesListApi,
} from 'app/modules/narratives/api';

// Utils
import { sendErrorToast, sendSuccessToast } from 'app/shared/toasts/actions';

const NARRATIVES_NAME = 'narratives';

interface NarrativeState {
  loadingNarratives: boolean;
  narratives: Narrative[];
  narrativesCount: number;
  loadingCreateEditDeleteNarrative: boolean;
}

export const narrativesInitialState: Readonly<NarrativeState> = {
  loadingNarratives: false,
  narratives: [],
  narrativesCount: 0,
  loadingCreateEditDeleteNarrative: false,
};

export const retrieveNarrativesListThunk = u21CreateAsyncThunk<
  ListNarrativesPayload,
  ListNarrativesResponse
>(
  `${NARRATIVES_NAME}/RETRIEVE_NARRATIVES_LIST`,
  async (payload, { dispatch }) => {
    try {
      return await retrieveNarrativesListApi(payload);
    } catch (e) {
      dispatch(
        sendErrorToast('Unable to retrieve narratives. Please try again'),
      );
      throw e;
    }
  },
);

export const createNarrativeThunk = u21CreateAsyncThunk<
  CreateNarrativePayload,
  Narrative
>(`${NARRATIVES_NAME}/CREATE_NARRATIVE`, async (payload, { dispatch }) => {
  try {
    const response = await createNarrativeApi(payload);
    dispatch(sendSuccessToast('Narrative successfully created'));
    return response;
  } catch (e) {
    dispatch(sendErrorToast(`Unable to create narrative. Please try again`));
    throw e;
  }
});

export const editNarrativeThunk = u21CreateAsyncThunk<
  EditNarrativePayload,
  Narrative
>(`${NARRATIVES_NAME}/EDIT_NARRATIVE`, async (payload, { dispatch }) => {
  try {
    const response = await editNarrativeApi(payload);
    dispatch(sendSuccessToast(`Narrative successfully edited`));
    return response;
  } catch (e) {
    dispatch(sendErrorToast('Unable to edit narrative. Please try again'));
    throw e;
  }
});

export const deleteNarrativeThunk = u21CreateAsyncThunk<number, Narrative>(
  `${NARRATIVES_NAME}/DELETE_NARRATIVE`,
  async (id, { dispatch }) => {
    try {
      const response = await deleteNarrativeApi(id.toString());
      dispatch(sendSuccessToast(`Narrative successfully deleted`));
      return response;
    } catch (e) {
      dispatch(sendErrorToast('Unable to delete narrative. Please try again'));
      throw e;
    }
  },
);

const narrativesSlice = u21CreateSlice({
  initialState: narrativesInitialState,
  name: NARRATIVES_NAME,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addLoadingCase(
        retrieveNarrativesListThunk,
        'loadingNarratives',
        (draft, action) => {
          draft.narratives = action.payload.narratives;
          draft.narrativesCount = action.payload.count;
        },
      )
      .addLoadingCase(
        createNarrativeThunk,
        'loadingCreateEditDeleteNarrative',
        (draft, action) => {
          draft.narratives = [action.payload, ...draft.narratives];
          draft.narrativesCount += 1;
        },
      )
      .addLoadingCase(
        editNarrativeThunk,
        'loadingCreateEditDeleteNarrative',
        (draft, action) => {
          draft.narratives = draft.narratives.map((narrative) => {
            if (narrative.id === action.payload.id) {
              return action.payload;
            }
            return narrative;
          });
        },
      )
      .addLoadingCase(
        deleteNarrativeThunk,
        'loadingCreateEditDeleteNarrative',
        (draft, action) => {
          draft.narratives = draft.narratives.filter((narrative) => {
            return narrative.id !== action.payload.id;
          });
          draft.narrativesCount -= 1;
        },
      );
  },
});

export const NARRATIVES_SLICE_NAME = narrativesSlice.name;
export default narrativesSlice.reducer;
