// Saga
import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { InsightsActionTypes } from 'app/modules/insights/models';

// Api
import { editDashboardChartsLayout as editDashboardChartsLayoutApi } from 'app/shared/api/insights';

// Actions
import {
  editDashboardChartsLayout as editDashboardChartsLayoutAction,
  editDashboardChartsLayoutSuccess,
} from 'app/modules/insights/actions';

const rootAction = InsightsActionTypes.EDIT_DASHBOARD_CHARTS_LAYOUT;
function* editDashboardChartsLayout({
  payload,
}: ReturnType<typeof editDashboardChartsLayoutAction>) {
  const config = {
    rootAction,
    request: call(editDashboardChartsLayoutApi, payload),
    errorToastMessage: 'Something went wrong, unable to save dashboard layout.',
    success: function* onSuccess(
      result: ReturnType<typeof editDashboardChartsLayoutSuccess>['payload'],
    ) {
      yield put(editDashboardChartsLayoutSuccess(result));
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watchEditDashboardChartsLayout() {
  yield takeLatest(rootAction, editDashboardChartsLayout);
}
