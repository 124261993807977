import {
  IconCoin,
  IconCreditCard,
  IconFingerprint,
  IconHome,
  IconLicense,
  IconMail,
  IconNumber,
  IconPhone,
  IconWifi,
} from '@u21/tabler-icons';
import { formatTxnAmount } from 'app/modules/networkAnalysis/helpers';
import { Filters, LinkType } from 'app/modules/networkAnalysis/types';
import {
  BASE_CYTOSCAPE_OPTIONS,
  BASE_EDGE_STYLES,
  BASE_LABEL_STYLES,
  BASE_NODE_STYLES,
} from 'app/shared/components/Graphs/constants';
import {
  Coin,
  CreditCard,
  Fingerprint,
  Home,
  License,
  Mail,
  Number,
  Phone,
  User,
  Wifi,
} from 'app/shared/components/Graphs/svgs';
import { CytoscapeOptions, EdgeSingular } from 'cytoscape';
import palette from 'vendor/material-minimal/palette';
import { ReactElement } from 'react';

// node svgs

export const LINK_ANALYSIS_LINK_TYPES = {
  ADDRESS: 'Address',
  CLIENT_FINGERPRINT: 'Client Fingerprint',
  EMAIL_ADDRESS: 'Email Address',
  INSTRUMENT: 'Instrument',
  IP_ADDRESS: 'IP Address',
  PHONE: 'Phone',
  PHYSICAL_ID: 'Physical ID',
  SSN: 'SSN',
  TRANSACTION: 'Transactions',
} as const;

export const LINK_TYPE_TO_BG_IMG: Record<LinkType, string> = {
  Address: Home,
  'Client Fingerprint': Fingerprint,
  'Email Address': Mail,
  'IP Address': Wifi,
  Phone,
  'Physical ID': License,
  SSN: Number,
  Instrument: CreditCard,
  Transactions: Coin,
};

export const ACCEPTED_LINK_TYPES: Set<LinkType> = new Set(
  Object.values(LINK_ANALYSIS_LINK_TYPES),
);

export const EMPTY_FILTERS: Filters = {
  linkType: [],
  entityType: [],
  entitySubtype: [],
  entityStatus: [],
  transactionData: { min: 0, direction: 'BOTH' },
};

export const LINK_TYPE_TO_ICON: Record<LinkType, ReactElement> = {
  Address: <IconHome />,
  'Client Fingerprint': <IconFingerprint />,
  'Email Address': <IconMail />,
  'IP Address': <IconWifi />,
  Phone: <IconPhone />,
  'Physical ID': <IconLicense />,
  SSN: <IconNumber />,
  Transactions: <IconCoin />,
  Instrument: <IconCreditCard />,
};

export const NETWORK_ANALYSIS_CYTOSCAPE_OPTIONS: CytoscapeOptions = {
  ...BASE_CYTOSCAPE_OPTIONS,
  style: [
    {
      selector: 'node',
      style: {
        ...BASE_NODE_STYLES,
        'font-size': '10px',
        'background-color': (node) => {
          if (node.data('nodeType') === 'link') {
            return palette.light.primary.main;
          }
          return palette.light[node.data('isBaseEntity') ? 'error' : 'info']
            .main;
        },
        'border-color': (node) => {
          if (node.data('selected') || node.data('nodeType') === 'link') {
            return palette.light.primary.light;
          }
          return palette.light[node.data('isBaseEntity') ? 'error' : 'info']
            .light;
        },
        'background-image': (node) =>
          node.data('nodeType') === 'entity'
            ? User
            : (LINK_TYPE_TO_BG_IMG[node.data('type')] ?? 'none'),
        opacity: (node) =>
          node.data('selected') ||
          node.data('opaque') ||
          node.data('isBaseEntity')
            ? 1
            : 0.35,
        content: (node) => {
          const label = node.data('label') || '';
          const type = node.data('type');
          if (
            type === LINK_ANALYSIS_LINK_TYPES.TRANSACTION &&
            typeof label === 'number'
          ) {
            return formatTxnAmount(label);
          }
          return label;
        },
      },
    },
    {
      selector: 'edge',
      style: {
        ...BASE_EDGE_STYLES,
        opacity: (edge: EdgeSingular) => (edge.data('selected') ? 1 : 0.35),
        'line-color': (edge: EdgeSingular) => {
          if (edge.data('label')) {
            return edge.data('sent')
              ? palette.light.error.light
              : palette.light.success.light;
          }
          return edge.data('selected')
            ? palette.light.primary.light
            : palette.light.grey[300];
        },
        'target-arrow-color': (edge: EdgeSingular) =>
          edge.data('sent')
            ? palette.light.error.light
            : palette.light.success.light,
        'target-arrow-shape': (edge) =>
          edge.data('label') ? 'triangle' : 'none',
        'arrow-scale': 0.75,
        label: (edge: EdgeSingular) => edge.data('label') ?? '',
        'curve-style': (edge: EdgeSingular) =>
          edge.data('label') ? 'bezier' : 'haystack',
      },
    },
    {
      selector: 'node[label]',
      style: {
        ...BASE_LABEL_STYLES,
      },
    },
    {
      selector: 'edge[label]',
      style: {
        ...BASE_LABEL_STYLES,
        'text-background-color': (edge) =>
          edge.data('sent')
            ? palette.light.error.main
            : palette.light.success.main,
        color: (edge) =>
          edge.data('sent')
            ? palette.light.error.contrastText
            : palette.light.success.contrastText,
      },
    },
  ],
};

export const LINK_SECTION_MOUNTED = 'linkSectionMounted';
export const LINK_SECTION_UNMOUNTED = 'linkSectionUnMounted';
export const TRANSACTIONS_SECTION_HASH = 'transactions' as const;

export const ELEMENT_KEYS_TO_UPDATE = {
  nodes: ['degree', 'selected'],
  edges: [],
};
