import { useMemo } from 'react';

import { selectTransactionDataSettingsByNativeKey } from 'app/modules/dataSettings/selectors';
import { useSelector } from 'react-redux';

import {
  U21MultiSelect,
  U21MultiSelectProps,
} from 'app/shared/u21-ui/components';

export const TxnTypeSelect = <TClearable extends boolean>(
  props: Omit<U21MultiSelectProps<string, TClearable>, 'options'>,
) => {
  const transactionCodes = useSelector(selectTransactionDataSettingsByNativeKey)
    ?.type?.enum_set;

  const options = useMemo(
    () => (transactionCodes ?? []).map((i) => ({ text: i, value: i })),
    [transactionCodes],
  );

  return <U21MultiSelect {...props} options={options} />;
};
