import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { GoAMLActionTypes } from 'app/modules/goAML/models';

// Actions
import {
  createGoAMLFiling as createGoAMLFilingAction,
  createGoAMLFilingSuccess,
} from 'app/modules/goAML/actions';

// API
import { createGoAMLFiling as createGoAMLFilingAPI } from 'app/shared/api/goAML';

// Utils
import routes from 'app/shared/utils/routes';
import { history } from 'app/store/browserHistory';

const rootAction = GoAMLActionTypes.CREATE_GOAML_FILING;
export function* createGoAMLFiling({
  payload,
}: ReturnType<typeof createGoAMLFilingAction>) {
  const config = {
    rootAction,
    errorToastMessage: 'Something went wrong, unable to create goAML report.',
    request: call(createGoAMLFilingAPI, payload),
    success: function* onSuccess(
      result: ReturnType<typeof createGoAMLFilingSuccess>['payload'],
    ) {
      yield put(createGoAMLFilingSuccess(result));

      // Redirect to edit view
      history.push(
        routes.lumos.filingsGoAMLIdEdit.replace(':id', `${result.id}`),
      );
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watch() {
  yield takeLatest(rootAction, createGoAMLFiling);
}
