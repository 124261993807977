import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';
import makeActions from 'app/shared/sagas/make-actions';

// Models
import { RulesActionTypes } from 'app/modules/rules/models';

// Actions
import {
  retrieveRuleValidationAlertEntities as retrieveRuleValidationAlertEntitiesAction,
  retrieveRuleValidationAlertEntitiesSuccess,
} from 'app/modules/rules/actions';

// API
import { retrieveRuleValidationAlertEntities as retrieveRuleValidationAlertEntitiesApi } from 'app/shared/api/rules';

// Helpers
import { combineRootActionWithId } from 'app/shared/helpers';

const rootAction = RulesActionTypes.RETRIEVE_RULE_VALIDATION_ALERT_ENTITIES;
function* retrieveRuleValidationAlertEntities({
  payload,
}: ReturnType<typeof retrieveRuleValidationAlertEntitiesAction>) {
  // to keep track of individual alerts loading
  const formattedRootAction = combineRootActionWithId(
    rootAction,
    String(payload.id),
  );
  const formattedActions = makeActions(formattedRootAction);

  const config = {
    rootAction: formattedRootAction,
    request: call(retrieveRuleValidationAlertEntitiesApi, payload),
    success: function* onSuccess(result) {
      // to update alert state by id
      const formattedResult: ReturnType<
        typeof retrieveRuleValidationAlertEntitiesSuccess
      >['payload'] = { id: payload.id, entities: result?.entities || [] };
      yield put(retrieveRuleValidationAlertEntitiesSuccess(formattedResult));
      // to cancel individual loading state
      yield put(formattedActions.success({}));
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watchRetrieveValidationRuleAlert() {
  yield takeLatest(rootAction, retrieveRuleValidationAlertEntities);
}
