// Models
import {
  InvestigationChecklistCreatePayload,
  InvestigationChecklistUpdatePayload,
  InvestigationChecklistListPayload,
  InvestigationChecklistRetrievePayload,
  InvestigationChecklist,
  RetrieveInvestigationChecklistListResponse,
} from 'app/modules/investigationChecklist/models';

// Routes
import routes from 'app/shared/utils/routes';

// Utils
import { destroy, get, post, put } from 'app/shared/utils/fetchr';

export const listInvestigationChecklists = (
  payload: InvestigationChecklistListPayload,
) => {
  return post<RetrieveInvestigationChecklistListResponse>(
    routes.patronus.investigationChecklistList,
    payload,
  );
};

export const retrieveInvestigationChecklist = (
  payload: InvestigationChecklistRetrievePayload,
) => {
  return get<InvestigationChecklist>(
    routes.patronus.customChecklistId.replace(':id', String(payload.id)),
  );
};

export const createInvestigationChecklist = (
  payload: InvestigationChecklistCreatePayload,
) => {
  return post<InvestigationChecklist>(routes.patronus.customChecklist, payload);
};

export const updateInvestigationChecklist = (
  payload: InvestigationChecklistUpdatePayload,
) => {
  return put<InvestigationChecklist>(routes.patronus.customChecklist, payload);
};

export const deleteInvestigationChecklist = (
  payload: InvestigationChecklistRetrievePayload,
) => {
  return destroy<InvestigationChecklist>(
    routes.patronus.customChecklistId.replace(':id', String(payload.id)),
  );
};
