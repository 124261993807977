import { post, put, destroy } from 'app/shared/utils/fetchr';

// Models
import {
  CreateNarrativePayload,
  EditNarrativePayload,
  ListNarrativesPayload,
} from 'app/modules/narratives/requests';
import { ListNarrativesResponse } from 'app/modules/narratives/responses';
import { Narrative } from 'app/modules/narratives/models';

export const retrieveNarrativesListApi = (
  payload: ListNarrativesPayload,
): Promise<ListNarrativesResponse> => {
  return post('/narratives/list', payload);
};

export const createNarrativeApi = (
  payload: CreateNarrativePayload,
): Promise<Narrative> => {
  return post('/narratives', payload);
};

export const editNarrativeApi = (
  payload: EditNarrativePayload,
): Promise<Narrative> => {
  return put('/narratives', payload);
};

export const deleteNarrativeApi = (id: string): Promise<Narrative> => {
  return destroy('/narratives/:id'.replace(':id', id));
};
