import { SyntheticEvent, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components
import {
  createU21FilterOptions,
  U21SelectOptionProps,
  U21MultiSelect,
  U21MultiSelectProps,
} from 'app/shared/u21-ui/components';
import { IconTag } from '@u21/tabler-icons';

// Actions
import { addTag } from 'app/modules/teamsOld/actions';

// Selectors
import { selectHasEditTagsPermission } from 'app/modules/session/selectors';
import { selectFormattedTags } from 'app/modules/teamsOld/selectors';

// Models
import { AddTagPayload, Tag } from 'app/modules/teamsOld/models';
import { TAG_CAP } from 'app/shared/helpers';

export interface U21TagSelectProps<TClearable extends boolean>
  extends Omit<U21MultiSelectProps<number, TClearable>, 'options'> {
  creatable?: boolean;
  excludeInternalTags?: boolean;
}

export const U21TagSelect = <TClearable extends boolean>({
  onChange,
  value,
  onInputChange,
  onKeyDown,
  onClose,
  creatable = false,
  excludeInternalTags = false,
  ...rest
}: U21TagSelectProps<TClearable>) => {
  const dispatch = useDispatch();

  const allOptions = useSelector(selectFormattedTags) as U21SelectOptionProps[];
  const hasEditTagsPermission = useSelector(selectHasEditTagsPermission);

  const formattedOptions = useMemo(() => {
    const options = excludeInternalTags
      ? allOptions.filter(
          (tag) => typeof tag.text === 'string' && !tag.text.startsWith('$'),
        )
      : allOptions;

    return options.map((i) => ({
      ...i,
      icon: <IconTag />,
    }));
  }, [allOptions, excludeInternalTags]);

  const [valueBeforeReturn, setValueBeforeReturn] = useState<string>('');

  const tagCreationValidation = /^[a-zA-Z\d_]+:[a-zA-Z\d_]+/.test(
    valueBeforeReturn,
  );

  const createNewTag = (e: SyntheticEvent) => {
    if (!tagCreationValidation) {
      return;
    }

    const payload: AddTagPayload = {
      tag: valueBeforeReturn,
    };
    dispatch(
      addTag(payload, (result: Tag) =>
        onChange([...(value || []), result.id], e),
      ),
    );
    setValueBeforeReturn('');
  };

  if (!creatable || !hasEditTagsPermission) {
    return (
      <U21MultiSelect
        // react will use the same instance if creatable changes so use key to reinitialize
        key="notcreate"
        label="Tags"
        onChange={onChange}
        onClose={onClose}
        onInputChange={onInputChange}
        onKeyDown={onKeyDown}
        options={formattedOptions}
        startIcon={<IconTag />}
        value={value}
        {...rest}
      />
    );
  }
  return (
    <U21MultiSelect
      key="create"
      inputValue={valueBeforeReturn}
      options={formattedOptions}
      onChange={onChange}
      optionLimit={TAG_CAP}
      label="Tags"
      value={value}
      onKeyDown={(e) => {
        onKeyDown?.(e);

        // Called before onInputChange (enterPressed functionality)
        if (e.key === 'Enter') {
          e.preventDefault();
          createNewTag(e);
        }
      }}
      onClose={(e, reason) => {
        onClose?.(e, reason);
        if (reason === 'blur') {
          setValueBeforeReturn('');
        }
      }}
      onInputChange={(e, newValue, reason) => {
        // onInputChange below is the native method
        if (reason === 'input') {
          setValueBeforeReturn(newValue);
        } else if (reason === 'clear') {
          setValueBeforeReturn('');
        }
        onInputChange?.(e, newValue, reason);
      }}
      filterOptions={(selectOptions, state) => {
        const filteredOptions = createU21FilterOptions<number>(
          selectOptions,
          state,
        );
        if (filteredOptions.length > 0) {
          return filteredOptions;
        }
        if (tagCreationValidation) {
          return [
            {
              item: {
                onClick: createNewTag,
                text: `Create new tag "${valueBeforeReturn}"`,
              },
              optionType: 'menuItem',
            },
          ];
        }
        return [];
      }}
      noOptionsText="To create a new tag, use the format type:text. Example: sector:europe"
      {...rest}
    />
  );
};
