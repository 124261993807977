import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { AlertsActionTypes } from 'app/modules/alerts/models';

// Actions
import {
  changeAlertComponentStatus as changeAlertComponentStatusAction,
  changeAlertComponentStatusSuccess,
  retrieveAlertAuditTrail,
  retrieveAlertTransactions,
  retrieveAlertEntities,
} from 'app/modules/alerts/actions';
import { sendSuccessToast } from 'app/shared/toasts/actions';

// API
import { alertComponentStatusChange } from 'app/shared/api/alerts';

const rootAction = AlertsActionTypes.CHANGE_ALERT_COMPONENT_STATUS;
export function* changeAlertComponentStatus({
  payload,
  meta,
}: ReturnType<typeof changeAlertComponentStatusAction>) {
  const config = {
    rootAction,
    request: call(alertComponentStatusChange, payload),
    errorToastMessage: `Error changing the status of alert ${payload.alertId}`,
    success: function* onSuccess(result: any) {
      yield put(changeAlertComponentStatusSuccess(result));
      yield put(sendSuccessToast(`Alert Component Status Updated`));
      yield put(retrieveAlertAuditTrail(result.id));
      if (meta?.txnUpdate !== undefined) {
        yield put(retrieveAlertTransactions(meta.txnUpdate));
      }
      if (meta?.entityUpdate !== undefined) {
        yield put(retrieveAlertEntities(meta.entityUpdate));
      }
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watchChangeAlertComponentStatus() {
  yield takeLatest(rootAction, changeAlertComponentStatus);
}
