import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { BlacklistActionTypes } from 'app/modules/lists/models';

// Actions
import {
  createBlacklist as createBlacklistAction,
  createBlacklistSuccess,
} from 'app/modules/lists/actions';
import { sendSuccessToast } from 'app/shared/toasts/actions';

// API
import { createBlacklist as createBlacklistApi } from 'app/shared/api/blacklists';

// Utils
import { ROUTES_MAP, pathJoin } from 'app/shared/utils/routes';
import { history } from 'app/store/browserHistory';

const rootAction = BlacklistActionTypes.CREATE_BLACKLIST;
function* createBlacklist({
  payload,
}: ReturnType<typeof createBlacklistAction>) {
  const config = {
    rootAction,
    request: call(createBlacklistApi, payload),
    success: function* onSuccess(result) {
      const { id } = result;

      yield put(createBlacklistSuccess(result));
      history.push(pathJoin(ROUTES_MAP.matchlists.path, id));
      yield put(sendSuccessToast(`Blacklist ${id} created successfully.`));
    },
    errorToastMessage: 'Failed to create blacklist',
  };

  yield call(makeSagaRequest, config);
}

export default function* watchCreateBlacklist() {
  yield takeLatest(rootAction, createBlacklist);
}
