import {
  ActionWithPayload,
  Action,
  createAction,
} from 'app/shared/utils/action-helpers';

// auto-generates each possible action string
const makeActionTypes = (rootAction: string) => ({
  pending: `${rootAction}_PENDING`,
  success: `${rootAction}_SUCCESS`,
  error: `${rootAction}_ERROR`,
});

const makeActions = <T extends string>(rootAction: T) => {
  const actionType = makeActionTypes(rootAction);
  return {
    root: <P>(payload?: P): Action<T> | ActionWithPayload<T, P> =>
      payload === undefined
        ? createAction(rootAction)
        : createAction(rootAction, payload),
    pending: () => createAction(actionType.pending),
    success: ({ ...payloads }) =>
      createAction(actionType.success, { ...payloads }),
    error: (payload?: any) => createAction(actionType.error, payload),
  };
};

export default makeActions;
