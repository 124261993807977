import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import {
  RulesActionTypes,
  RetrieveWhitelistedEntitiesRequest,
} from 'app/modules/rules/models';

// Actions
import {
  removeWhitelistEntitiesForRule as removeWhitelistEntitiesForRuleAction,
  removeWhitelistEntitiesForRuleSuccess,
  retrieveWhitelistedEntities as retrieveWhitelistedEntitiesAction,
} from 'app/modules/rules/actions';
import { sendSuccessToast } from 'app/shared/toasts/actions';

// API
import { removeWhitelistedEntitiesForRule as removeWhitelistedEntitiesForRuleApi } from 'app/shared/api/rules';

// Constants
import { INITIAL_ENTITY_WHITELIST_PAGINATION_SETTINGS } from 'app/modules/rules/constants';

const rootAction = RulesActionTypes.REMOVE_WHITELIST_ENTITIES_FOR_RULE;
function* removeWhitelistEntitiesForRule({
  payload,
}: ReturnType<typeof removeWhitelistEntitiesForRuleAction>) {
  const entityMessage =
    payload.entity_external_ids.length > 1 ? 'entities' : 'entity';
  const config = {
    rootAction,
    errorToastMessage: 'Error removing entities from whitelist',
    request: call(removeWhitelistedEntitiesForRuleApi, payload),
    success: function* onSuccess() {
      yield put(removeWhitelistEntitiesForRuleSuccess());
      yield put(
        sendSuccessToast(`Removed ${entityMessage} from rule whitelist`),
      );
      // Get new whitelisted entities with default pagination
      const request: RetrieveWhitelistedEntitiesRequest = {
        id: payload.rule_id,
        offset: INITIAL_ENTITY_WHITELIST_PAGINATION_SETTINGS.pageOffset,
        limit: INITIAL_ENTITY_WHITELIST_PAGINATION_SETTINGS.pageLimit,
      };
      yield put(retrieveWhitelistedEntitiesAction(request));
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watchRemoveWhitelistEntitiesForRuleStatus() {
  yield takeLatest(rootAction, removeWhitelistEntitiesForRule);
}
