import {
  DateRange,
  U21HistogramDateRange,
  U21HistogramSeries,
} from 'app/shared/u21-ui/components/charts/models';

import {
  aggregateTimeseries,
  calculateDateRange,
  tooltipFormatter,
  xaxisFormatter,
} from 'app/shared/u21-ui/components/charts/utils';
import { identityFunction } from 'app/shared/utils/function';
import { useControlled } from 'app/shared/hooks/useControlled';
import { useMemo, useRef } from 'react';
import useId from '@mui/utils/useId';

import {
  U21Chart,
  U21ChartProps,
} from 'app/shared/u21-ui/components/charts/U21Chart';

type Options = Required<Pick<U21ChartProps, 'options'>>['options'];

export interface U21HistogramProps
  extends Omit<U21ChartProps, 'options' | 'type'> {
  dateRange?: U21HistogramDateRange;
  getOverrideOptions?: (options: Options) => Options;
  onChangeDateRange?: (value: DateRange) => void;
  series: U21HistogramSeries;
  timezone?: string | null;
}

export const U21Histogram = (props: U21HistogramProps) => {
  const {
    dateRange: dateRangeProp,
    getOverrideOptions = identityFunction,
    chartID: chartIDProp,
    onChangeDateRange: onChangeDateRangeProp,
    series,
    timezone,
    ...rest
  } = props;

  const chartID = useId(chartIDProp);

  const seriesDateRange = useMemo(() => calculateDateRange(series), [series]);
  const [dateRange, onChangeDateRange, isExternal] = useControlled(
    dateRangeProp || seriesDateRange,
    onChangeDateRangeProp,
  );

  const fixedDateRange = useMemo<U21HistogramDateRange>(
    () => [
      dateRange?.[0] || seriesDateRange[0],
      dateRange?.[1] || seriesDateRange[1],
    ],
    [dateRange, seriesDateRange],
  );

  const { series: aggregatedSeries, bucketSize } = useMemo(
    () => aggregateTimeseries(series, fixedDateRange),
    [fixedDateRange, series],
  );

  // must use refs because the following changes don't trigger react-apexchart updates
  const onChangeDateRangeRef = useRef(onChangeDateRange);
  onChangeDateRangeRef.current = onChangeDateRange;
  const bucketSizeRef = useRef(bucketSize);
  bucketSizeRef.current = bucketSize;

  const options = useMemo(() => {
    return getOverrideOptions({
      chart: {
        animations: { enabled: false },
        events: {
          // disable internal zoom if zoom is externally controlled
          beforeZoom: isExternal
            ? (_, chartOptions) => {
                const {
                  xaxis: { max, min },
                } = chartOptions;
                onChangeDateRangeRef.current([new Date(min), new Date(max)]);
                // return undefined so zoomed doesn't do anything
                return { xaxis: {} };
              }
            : undefined,
          zoomed: (_, chartOptions) => {
            const {
              xaxis: { max, min },
            } = chartOptions;
            // ignored undefined caused when resetting zoom
            if (typeof min !== 'undefined' && typeof max !== 'undefined') {
              onChangeDateRangeRef.current([new Date(min), new Date(max)]);
            }
          },
          beforeResetZoom: () => {
            onChangeDateRangeRef.current(calculateDateRange(series));
          },
        },
        stacked: true, // Stacking bars if they fall on the same timestamp
        toolbar: {
          // can't disable zoom but render div to hide it
          tools: { pan: false, reset: !isExternal, zoom: '<div />' },
        },
      },
      dataLabels: { enabled: false },
      plotOptions: { bar: { horizontal: false } },
      tooltip: {
        x: {
          formatter: (timestamp) =>
            tooltipFormatter({
              bucketSize: bucketSizeRef.current,
              timezone,
            })(timestamp),
        },
      },
      xaxis: {
        labels: {
          // timestamp is typed as string but is actually number
          formatter: (timestamp) =>
            xaxisFormatter({
              bucketSize: bucketSizeRef.current,
              timezone,
            })(Number(timestamp)),
        },
        type: 'datetime',
      },
    });
  }, [getOverrideOptions, isExternal, series, timezone]);

  return (
    <U21Chart
      chartID={chartID}
      options={options}
      series={aggregatedSeries}
      type="bar"
      {...rest}
    />
  );
};
