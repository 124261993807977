import { COLOR_STYLE } from 'app/shared/u21-ui/components/display/typography/styles';

import { forwardRef, HTMLProps, ReactElement } from 'react';
import { getDOMProps } from 'app/shared/utils/react';
import styled, { css } from 'styled-components';
import { TypographyProps } from '@mui/material';

export interface U21TypographyIconProps extends HTMLProps<HTMLDivElement> {
  children?: ReactElement;
  color?: string;
  variant?: TypographyProps['variant'];
}

export const U21TypographyIcon = forwardRef<
  HTMLDivElement,
  U21TypographyIconProps
>((props, ref) => {
  const {
    children,
    color = 'text.primary',
    variant = 'body1',
    ...rest
  } = props;
  if (!children) {
    return null;
  }
  return (
    <IconContainer
      $color={color}
      $variant={variant}
      ref={ref}
      {...getDOMProps(rest)}
    >
      {children}
    </IconContainer>
  );
});

const IconContainer = styled.span<{
  $color: string;
  $variant: NonNullable<TypographyProps['variant']>;
}>`
  display: flex;
  ${(props) => {
    const {
      theme: { typography },
    } = props;
    const { remToPx } = typography;
    const properties = typography[props.$variant];
    const { fontSize } = properties;
    const base = remToPx(fontSize);
    const small = remToPx(
      properties['@media (min-width:600px)']?.fontSize || fontSize,
    );
    const medium = remToPx(
      properties['@media (min-width:960px)']?.fontSize || fontSize,
    );
    const large = remToPx(
      properties['@media (min-width:1280px)']?.fontSize || fontSize,
    );
    return css`
      & > * {
        height: ${base}px;
        width: ${base}px;
        @media (min-width: 600px) {
          height: ${small}px;
          width: ${small}px;
        }
        @media (min-width: 960px) {
          height: ${medium}px;
          width: ${medium}px;
        }
        @media (min-width: 1280px) {
          height: ${large}px;
          width: ${large}px;
        }
      }
    `;
  }}
  ${COLOR_STYLE}
`;
