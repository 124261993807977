import { FC, useState, useMemo, HTMLProps } from 'react';

import { getDOMProps } from 'app/shared/utils/react';
import styled from 'styled-components';

import { IconSearch } from '@u21/tabler-icons';
import { U21Spacer } from 'app/shared/u21-ui/components/layout/U21Spacer';
import { U21TextField } from 'app/shared/u21-ui/components/input/text-field/U21TextField';
import {
  U21MenuItem,
  U21MenuItemProps,
} from 'app/shared/u21-ui/components/display/U21MenuItem';

export interface U21MenuListOptionProps extends Omit<U21MenuItemProps, 'text'> {
  text: string;
}

export interface U21MenuListProps extends HTMLProps<HTMLDivElement> {
  label?: string;
  loading?: boolean;
  onSearch?: (search: string) => void;
  options: U21MenuListOptionProps[];
  placeholder?: string;
}

export const U21MenuList: FC<U21MenuListProps> = ({
  label,
  loading,
  onSearch,
  options,
  placeholder = 'Search options',
  ...rest
}) => {
  const [value, setValue] = useState('');
  const menuListOptions = useMemo(() => {
    return options.filter((option) =>
      option.text.toLowerCase().includes(value.toLowerCase()),
    );
  }, [options, value]);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      {...getDOMProps(rest)}
    >
      <U21TextField
        autoFocus
        clearable
        label={label}
        loading={loading}
        onChange={(val: string) => {
          onSearch?.(val);
          setValue(val || '');
        }}
        // prevent refocusing on the first menu object
        onKeyDown={(e) => e.stopPropagation()}
        placeholder={placeholder}
        startIcon={<IconSearch />}
        value={value}
      />
      <MenuItemSpacer spacing={0}>
        {menuListOptions.length > 0 ? (
          menuListOptions.map((option, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <U21MenuItem item={option} key={`${option.text}_${idx}`} />
          ))
        ) : (
          <U21MenuItem
            item={{
              text: loading ? 'Loading...' : 'No options',
              disabled: true,
            }}
          />
        )}
      </MenuItemSpacer>
    </div>
  );
};

const MenuItemSpacer = styled(U21Spacer)`
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  max-height: 40vh;
`;
