import { get } from 'lodash';

// Models
import { TableValueType } from 'app/shared/pagination/models';

// extract value from object based on the table config
export const getValueFromConfig = (
  item: any,
  keyOptions: string | string[] | ((item: any) => any),
  type?: TableValueType,
) => {
  // If keyOptions is an array, it means that there are different item types
  // with different keys used to extract the value from test to see which key
  // exists on item - e.g. "user_name" for Users and "name" for Businesses
  let key;

  if (Array.isArray(keyOptions)) {
    for (let i = 0; i < keyOptions.length; i++) {
      if (Object.prototype.hasOwnProperty.call(item, keyOptions[i])) {
        key = keyOptions[i];
        break;
      }
    }
  } else if (typeof keyOptions === 'function') {
    return keyOptions(item);
  } else {
    key = keyOptions;
  }

  if (!key) {
    return '';
  }

  if (key === 'sender_readable' && type === 'entity') {
    return {
      id: item?.sender_entity_id || '',
      title: item?.sender_entity_external_id,
    };
  }

  if (key === 'receiver_readable' && type === 'entity') {
    return {
      id: item?.receiver_entity_id || '',
      title: item?.receiver_entity_external_id,
    };
  }

  // get value object for columns like sender_instrument.name or receiver_instrument.name
  if (key.includes('_instrument.name')) {
    const title: string | null = get(item, key);
    return {
      id: title ? get(item, key.replace('.name', '_id')) : null,
      title,
    };
  }

  if (key === 'sender_instrument.external_id' && type === 'entity') {
    return {
      id: item?.sender_instrument_id || '',
      // use external id if it exists, default to id
      title:
        get(item, 'sender_instrument.external_id') ||
        get(item, 'sender_instrument_id', ''),
    };
  }

  if (key === 'receiver_instrument.external_id' && type === 'entity') {
    return {
      id: item?.receiver_instrument_id || '',
      // use external id if it exists, default to id
      title:
        get(item, 'receiver_instrument.external_id') ||
        get(item, 'receiver_instrument_id', ''),
    };
  }

  if (key.startsWith('custom_data.internal.')) {
    return item && item.custom_data && item.custom_data.internal
      ? item.custom_data.internal[key.substr('custom_data.internal.'.length)]
      : '';
  } else if (key.startsWith('custom_data.customer_raw.')) {
    // custom_data is either returned with custom_data.customer_raw.values_we_care_about OR custom_data.values_we_care_about
    // so we want to account for both cases
    const customDataExists = Boolean(item && item.custom_data);
    if (!customDataExists) {
      return '';
    }
    if (item.custom_data.customer_raw) {
      // get the nested key in custom_data.customer_raw
      return get(item, key) || '';
    }
    // custom_data has been flattened (i.e. no top level customer_raw key)
    return (
      item.custom_data[key.substr('custom_data.customer_raw.'.length)] || ''
    );
  } else if (key.includes('[') && key.includes(']')) {
    const ixStart = key.lastIndexOf('[');
    const ixEnd = key.lastIndexOf(']');
    const position = parseInt(key.substr(ixStart + 1).slice(0, ixEnd), 10);
    const fieldName = key.substr(0, ixStart);
    const arrayVal = item[fieldName];
    if (Array.isArray(arrayVal) && arrayVal.length > position) {
      if (!key.endsWith(']') && arrayVal[position]) {
        // Accessing field within array object
        const subfieldName = key.substr(ixEnd + 2);
        return arrayVal[position][subfieldName];
      }
      return arrayVal[position];
    }
    return '';
  }
  return item[key];
};
