import { FC } from 'react';

import styled from 'styled-components';

import {
  U21Button,
  U21ButtonProps,
} from 'app/shared/u21-ui/components/input/U21Button';

interface AdornmentProps extends Omit<U21ButtonProps, 'as' | 'icon'> {
  icon: U21ButtonProps['icon'];
}

export const AdornmentButton: FC<AdornmentProps> = (props) => {
  const { 'aria-label': ariaLabel } = props;
  return (
    <StyledButton size="small" tabIndex={-1} title={ariaLabel} {...props} />
  );
};

const StyledButton = styled(U21Button)`
  padding: 4px;
`;
