import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// API
import { deployShadowRule as api } from 'app/shared/api/rules';

// Models
import { RulesActionTypes, RuleSummary } from 'app/modules/rules/models';

// Actions
import {
  deployShadowRule as deployShadowRuleAction,
  deployShadowRuleSuccess,
} from 'app/modules/rules/actions';

// Utils
import routes from 'app/shared/utils/routes';
import { history } from 'app/store/browserHistory';

const rootAction = RulesActionTypes.DEPLOY_SHADOW_RULE;
function* deployShadowRule({
  payload,
}: ReturnType<typeof deployShadowRuleAction>) {
  const config = {
    rootAction,
    errorToastMessage: 'Something went wrong, unable to launch model.',
    request: call(api, payload),
    successToastMessage: `Model successfully launched.`,
    success: function* onSuccess(response: RuleSummary) {
      yield put(deployShadowRuleSuccess(response));
      history.push(
        routes.lumos.detectionModelsId.replace(':id', `${response.id}`),
      );
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watch() {
  yield takeLatest(rootAction, deployShadowRule);
}
