export enum QueueType {
  ALERT_QUEUE = 'ALERT_QUEUE',
  CASE_QUEUE = 'CASE_QUEUE',
  SAR_QUEUE = 'SAR_QUEUE',
}

export enum QueueAccessType {
  OPEN = 'OPEN',
  RESTRICTED = 'RESTRICTED',
  CLASSIFIED = 'CLASSIFIED',
}
export enum DeadlineState {
  LATE = 'Overdue',
  WARNING = 'Approaching deadline',
  OKAY = 'In progress',
  NO_DEADLINE = 'No deadline set',
}

export interface ChartStack {
  name: DeadlineState;
  data: number[];
}

export interface SeriesPoint {
  x: DeadlineState;
  y: number;
}
