import { alpha } from '@mui/system/colorManipulator';
import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------
// CUSTOM CHANGES
// - changed background

export default function Backdrop(theme: Theme) {
  return {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          background: alpha(theme.palette.grey[900], 0.72),
          '&.MuiBackdrop-invisible': {
            background: 'transparent',
          },
        },
      },
    },
  };
}
