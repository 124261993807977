import { IconProps } from 'app/shared/components/Icons/models';

export const IconPCI = ({
  size = 20,
  color = 'currentColor',
  stroke = 2,
  className = '',
  ...props
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`tabler tabler-icon-pci ${className}`}
      {...props}
    >
      <path
        d="M12 3C14.3358 5.06658 17.3845 6.14257 20.5 6C20.9536 7.54302 21.0924 9.16147 20.9081 10.7592C20.7239 12.3569 20.2203 13.9013 19.4274 15.3005C18.6345 16.6998 17.5684 17.9254 16.2925 18.9045C15.0165 19.8836 13.5568 20.5962 12 21C10.4432 20.5962 8.9835 19.8836 7.70756 18.9045C6.43163 17.9254 5.36553 16.6998 4.57261 15.3005C3.7797 13.9013 3.27615 12.3569 3.09189 10.7592C2.90763 9.16147 3.04642 7.54302 3.50002 6C6.61555 6.14257 9.66419 5.06658 12 3Z"
        stroke={color}
        strokeWidth={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 14V10H9.5C9.76522 10 10.0196 10.1054 10.2071 10.2929C10.3946 10.4804 10.5 10.7348 10.5 11V11.5C10.5 11.7652 10.3946 12.0196 10.2071 12.2071C10.0196 12.3946 9.76522 12.5 9.5 12.5H8"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.3333 11C14.3333 10.7348 14.228 10.4804 14.0404 10.2929C13.8529 10.1054 13.5985 10 13.3333 10H13C12.7348 10 12.4804 10.1054 12.2929 10.2929C12.1054 10.4804 12 10.7348 12 11V13C12 13.2652 12.1054 13.5196 12.2929 13.7071C12.4804 13.8946 12.7348 14 13 14H13.3333C13.5985 14 13.8529 13.8946 14.0404 13.7071C14.228 13.5196 14.3333 13.2652 14.3333 13"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 10V14"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
