// Models
import { Dictionary } from 'lodash';
import { AgentDetails } from 'app/modules/agentsOld/models';
import { TeamSummary } from 'app/modules/teamsOld/models';
import { PaginationPayload } from 'app/shared/models';

export enum DropdownActionTypes {
  RETRIEVE_DROPDOWN_AGENTS = '@@dropdown/RETRIEVE_DROPDOWN_AGENTS',
  RETRIEVE_DROPDOWN_AGENTS_SUCCESS = '@@dropdown/RETRIEVE_DROPDOWN_AGENTS_SUCCESS',

  RETRIEVE_DROPDOWN_TEAMS = '@@dropdown/RETRIEVE_DROPDOWN_TEAMS',
  RETRIEVE_DROPDOWN_TEAMS_SUCCESS = '@@dropdown/RETRIEVE_DROPDOWN_TEAMS_SUCCESS',

  RETRIEVE_DROPDOWN_ORGS = '@@dropdown/RETRIEVE_DROPDOWN_ORGS',
  RETRIEVE_DROPDOWN_ORGS_SUCCESS = '@@dropdown/RETRIEVE_DROPDOWN_ORGS_SUCCESS',
}

export interface OrgSummary {
  id: number;
  name: string;
  display_name: string;
  default_unit: number;
  created_at: string;
  is_parent?: boolean;
  parent_id?: number;
}

export interface PaginatedOrgsResponse {
  count: number;
  results: OrgSummary[];
}

export interface PaginatedOrgsPayload extends PaginationPayload {}

export interface UnitSummary {
  id: number;
  name: string;
  org_id: number;
  org_name: string;
  created_at: string;
}

export interface DropdownState {
  agents: Dictionary<AgentDetails>;
  teams: Dictionary<TeamSummary>;
  orgs: Dictionary<OrgSummary>;
  units: Dictionary<UnitSummary>;
}
