import { AnalyticsEvents, trackEvent } from 'app/shared/u21-ui/analytics';

import {
  forwardRef,
  HTMLProps,
  MutableRefObject,
  ReactNode,
  RefObject,
  SyntheticEvent,
} from 'react';
import { getDOMProps } from 'app/shared/utils/react';
import styled from 'styled-components';

import { Checkbox, FormControlLabel } from '@mui/material';
import { U21Typography } from 'app/shared/u21-ui/components/display/typography/U21Typography';

export interface U21CheckboxProps
  extends Omit<HTMLProps<HTMLButtonElement>, 'label' | 'onChange'> {
  checked?: boolean;
  disabled?: boolean;
  label?: ReactNode;
  description?: string;
  indeterminate?: boolean;
  onChange: (checked: boolean, e: SyntheticEvent) => void;
  ref?: RefObject<HTMLButtonElement>;
  required?: boolean;
}

export const U21Checkbox = forwardRef<HTMLButtonElement, U21CheckboxProps>(
  (props, ref) => {
    const {
      checked = false,
      disabled,
      label,
      description,
      indeterminate,
      onChange,
      required,
      ...rest
    } = props;

    const checkboxProps = {
      checked,
      disabled,
      indeterminate: checked ? undefined : indeterminate,
      onChange: (e) => {
        trackEvent(AnalyticsEvents.U21CHECKBOX_ON_CLICK, props, {}, e);
        onChange(e.target.checked, e);
      },
    };
    if (!label) {
      return (
        <Checkbox
          ref={ref as MutableRefObject<HTMLButtonElement>}
          {...checkboxProps}
          {...getDOMProps(rest)}
        />
      );
    }

    return (
      <StyledFormControlLabel
        control={<Checkbox {...checkboxProps} />}
        label={
          <>
            {typeof label === 'string' ? (
              <>
                <U21Typography
                  color={disabled ? 'text.secondary' : 'text.primary'}
                  inline
                  variant="body2"
                >
                  {label}
                </U21Typography>
                {required && <Asterisk>&thinsp;*</Asterisk>}
              </>
            ) : (
              label
            )}
            <U21Typography color="text.secondary" variant="body2">
              {description}
            </U21Typography>
          </>
        }
        ref={ref}
        {...getDOMProps(rest)}
      />
    );
  },
);

const StyledFormControlLabel = styled(FormControlLabel)`
  margin-right: 32px;
  width: fit-content;

  .MuiFormControlLabel-label {
    margin: 4px 0;
  }
`;

const Asterisk = styled.span`
  color: ${(props) => props.theme.palette.error.main};
`;
