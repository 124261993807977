// Payload
import {
  CreateDispositionPayload,
  UpdateDispositionPayload,
} from 'app/modules/dispositions/request';
import { PaginationPayload } from 'app/shared/pagination/models';

// Utils
import { put, post } from 'app/shared/utils/fetchr';
import {
  Disposition,
  PaginatedDispositionsResponse,
} from 'app/modules/dispositions/response';

const API_BASE = '/dispositions';

export const createDisposition = (
  payload: CreateDispositionPayload,
): Promise<Disposition> => {
  return post(`${API_BASE}/create`, payload);
};

export const getDispositions = (
  payload: PaginationPayload,
): Promise<PaginatedDispositionsResponse> => {
  return post(`${API_BASE}/list`, payload);
};

export const updateDisposition = (
  payload: UpdateDispositionPayload,
): Promise<Disposition> => {
  return put(`${API_BASE}/edit/${payload.id}`, {
    title: payload.title,
    type: payload.type,
  });
};
