import { getTagDisplayName } from 'app/modules/tags/utils';
import { useMemo } from 'react';

import { FilterMoreDisplay } from 'app/modules/filters/components/FilterMoreDisplay';
import { useFetchAllDataLabelsById } from 'app/modules/dataLabels/queries/useFetchAllDataLabels';

interface Props {
  ids: (string | number)[];
}

export const DataLabelFilterDisplay = (props: Props) => {
  const { ids } = props;
  const dataLabelsById = useFetchAllDataLabelsById();
  const values = useMemo(() => {
    return ids.map((i) =>
      dataLabelsById[i] ? getTagDisplayName(dataLabelsById[i]) : 'Unknown',
    );
  }, [ids, dataLabelsById]);

  return (
    <>
      <b>Tag</b> is one of <FilterMoreDisplay values={values} />
    </>
  );
};
