import { AnyAction, PayloadAction, ThunkDispatch } from '@reduxjs/toolkit';
import { u21CreateAsyncThunk } from 'app/shared/thunk/u21CreateAsyncThunk';

import {
  UploadPullBasedDataFilePayload,
  ProcessPullBasedDataFileAPIPayload,
  UseDataFilesPayload,
} from 'app/modules/pullBasedDataFiles/requests';
import {
  PullBasedDataFile,
  Stream,
  Streams,
  PullBasedDataFilesPage,
} from 'app/modules/pullBasedDataFiles/responses';
import {
  PullBasedDataFilesState,
  DeletePullBasedDataFileAPIInfo,
  UploadingFlatFileProgress,
  ModalState,
  PullBasedDataFilesTableState,
} from 'app/modules/pullBasedDataFiles/types';
import {
  deleteDataFile,
  getDataFilesForStream,
  processPullBasedDatafile,
  uploadFlatFileForIngestion,
} from 'app/modules/pullBasedDataFiles/api';
import { sendErrorToast } from 'app/shared/toasts/actions';
import { u21CreateSlice } from 'app/shared/thunk/u21CreateSlice';
import { ERROR_STATE, LOADING_STATE } from 'app/shared/types/utils/asyncStatus';
import { UPLOAD_ABORTED_MESSAGE } from 'app/shared/utils/fetchr';
import { DEFAULT_MODAL_STATE } from 'app/modules/pullBasedDataFiles/constants';

export const FFIP_NAME = 'flatFileIngestion';

export const initialState: PullBasedDataFilesState = {
  streams: [],
  uploadingFlatFilesByStreamId: {},
  dataFilePagesByStreamId: {},
  modalState: DEFAULT_MODAL_STATE,
};

export const getDataFilesForStreamThunk = u21CreateAsyncThunk<
  { streamId: number; offset: number; limit: number },
  PullBasedDataFilesPage
>(
  `${FFIP_NAME}/RETRIEVE_PULL_BASED_DATA_FILE_PAGE`,
  async ({ streamId, ...args }, { dispatch }) => {
    try {
      return await getDataFilesForStream({
        stream_ids: [streamId],
        ...args,
      });
    } catch (e) {
      dispatch(sendErrorToast('Something went wrong'));
      throw e;
    }
  },
);

export const resetDataFilePage = (
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  streamId: number,
) => dispatch(getDataFilesForStreamThunk({ streamId, offset: 1, limit: 25 }));

// fileId: number
// streamId: number
export const deletePullBasedDataFileThunk = u21CreateAsyncThunk<
  DeletePullBasedDataFileAPIInfo,
  DeletePullBasedDataFileAPIInfo
>(`${FFIP_NAME}/DELETE_PULL_BASED_DATA_FILE`, async (payload, { dispatch }) => {
  try {
    await deleteDataFile(payload.fileId);
    resetDataFilePage(dispatch, payload.streamId);
    return payload;
  } catch (e) {
    dispatch(sendErrorToast('Something went wrong'));
    throw e;
  }
});

export const uploadFlatFileForIngestionThunk = u21CreateAsyncThunk<
  UploadPullBasedDataFilePayload,
  PullBasedDataFile
>(
  `${FFIP_NAME}/UPLOAD_PULL_BASED_DATA_FILE_WITH_PROGRESS`,
  async (payload, { dispatch, signal }) => {
    // We're going to listen to the upload progress events to update the UI
    // In order to prevent memory leaks, we will have to remove the event listeners
    // at the end of this action.
    // We keep a reference to the XMLHttpRequest so that we can remove the event listeners
    let uploadReq: XMLHttpRequest | null = null;

    // Used in progress update actions
    const fileInfo = {
      path: payload.file.path,
      size: payload.file.size,
    };

    const progressUpdater = (ev: ProgressEvent<XMLHttpRequestEventTarget>) => {
      if (!ev.lengthComputable) {
        return;
      }

      const percentComplete = Math.floor((ev.loaded / ev.total) * 100);
      dispatch(
        updateFlatFileUploadProgress({
          filePath: payload.file.path,
          progress: {
            fileInfo,
            progress: percentComplete,
            status: percentComplete === 100 ? 'processing' : 'uploading',
          },
          streamId: payload.stream_id,
        }),
      );
    };

    const abortUpload = () => {
      if (!uploadReq) {
        return;
      }

      uploadReq.abort();
    };

    const cleanupProgressTracker = (
      status: 'done' | 'failed',
      errorMessage?: string,
    ) => {
      if (uploadReq) {
        uploadReq.removeEventListener('progress', progressUpdater);
      }
      dispatch(
        updateFlatFileUploadProgress({
          filePath: payload.file.path,
          progress: {
            fileInfo,
            progress: status === 'done' ? 100 : 0,
            status,
            errorMessage,
          },
          streamId: payload.stream_id,
        }),
      );
    };

    try {
      const { req, uploadPromise } = uploadFlatFileForIngestion(payload);
      uploadReq = req;
      req.upload.addEventListener('progress', progressUpdater);
      signal.addEventListener('abort', abortUpload);

      const retVal = await uploadPromise;
      signal.removeEventListener('abort', abortUpload);

      cleanupProgressTracker('done');
      return retVal;
    } catch (e) {
      const { message } = e;

      if (message !== UPLOAD_ABORTED_MESSAGE) {
        cleanupProgressTracker('failed', message);
      }

      signal.removeEventListener('abort', abortUpload);
      throw e;
    }
  },
);

export const processPullBasedDataFileThunk = u21CreateAsyncThunk<
  ProcessPullBasedDataFileAPIPayload,
  PullBasedDataFile
>(
  `${FFIP_NAME}/PROCESS_PULL_BASED_DATA_FILE`,
  async (payload, { dispatch }) => {
    try {
      return await processPullBasedDatafile(payload);
    } catch (e) {
      const msg = 'Something went wrong';
      try {
        const json = await e.json();
        dispatch(sendErrorToast(json?.message ?? msg));
      } catch {
        dispatch(sendErrorToast(msg));
      }

      throw e;
    }
  },
);

const flatFilesIngestionSlice = u21CreateSlice({
  name: FFIP_NAME,
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getDataFilesForStreamThunk.pending, (draft, { meta }) => {
        const { streamId, ...pageInfo } = meta.arg;
        draft.dataFilePagesByStreamId[streamId] = {
          ...pageInfo,
          ...LOADING_STATE,
        };
      })
      .addCase(
        getDataFilesForStreamThunk.fulfilled,
        (draft, { payload, meta }) => {
          const { arg } = meta;
          const streamToUpdate = draft.dataFilePagesByStreamId[arg.streamId];
          draft.dataFilePagesByStreamId[arg.streamId] = {
            ...streamToUpdate,
            status: 'COMPLETE',
            count: payload.count,
            pullBasedDataFiles: payload.pull_based_datafiles,
          };
        },
      )
      .addCase(getDataFilesForStreamThunk.rejected, (draft, { meta }) => {
        const { arg } = meta;
        const streamToUpdate = draft.dataFilePagesByStreamId[arg.streamId];
        draft.dataFilePagesByStreamId[arg.streamId] = {
          ...streamToUpdate,
          ...ERROR_STATE,
        };
      })
      .addCase(
        processPullBasedDataFileThunk.fulfilled,
        (draft, { payload }) => {
          const streamToUpdate =
            draft.dataFilePagesByStreamId[payload.stream_id];
          if (streamToUpdate && streamToUpdate.status === 'COMPLETE') {
            streamToUpdate.pullBasedDataFiles =
              streamToUpdate.pullBasedDataFiles.map((datafile) => {
                if (datafile.id === payload.id) {
                  return payload;
                }

                return datafile;
              });
          }
        },
      );
  },
  reducers: {
    updateStreams: (draft, { payload }: PayloadAction<Streams>) => {
      draft.streams = payload.streams;
    },
    addStream: (draft, { payload }: PayloadAction<Stream>) => {
      draft.streams = [payload, ...draft.streams];
    },
    updateStream: (draft, { payload }: PayloadAction<Stream>) => {
      draft.streams = draft.streams.map((stream) =>
        stream.id === payload.id ? payload : stream,
      );
    },
    updateDataFilesByStreamId: (
      draft,
      {
        payload,
      }: PayloadAction<{
        req: UseDataFilesPayload;
        res: PullBasedDataFilesPage;
      }>,
    ) => {
      const { req, res } = payload;
      const updated: PullBasedDataFilesTableState = {
        status: 'COMPLETE',
        offset: req.offset,
        limit: req.limit,
        count: res.count,
        pullBasedDataFiles: res.pull_based_datafiles,
      };
      draft.dataFilePagesByStreamId[req.stream_id] = updated;
    },
    setModalState: (
      draft,
      { payload }: PayloadAction<{ modalState: ModalState; stream?: Stream }>,
    ) => {
      draft.modalState = payload.modalState;
      if (payload.stream) {
        draft.selectedStream = payload.stream;
      }
    },
    setSelectedStream: (
      draft,
      {
        payload,
      }: PayloadAction<{
        stream: Stream;
      }>,
    ) => {
      draft.selectedStream = payload.stream;
    },
    setSelectedDataFile: (
      draft,
      {
        payload,
      }: PayloadAction<{
        file: PullBasedDataFile;
      }>,
    ) => {
      draft.selectedDatafile = payload.file;
    },
    closeStreamsModal: (draft) => {
      draft.modalState = DEFAULT_MODAL_STATE;
    },
    updateFlatFileUploadProgress: (
      draft,
      {
        payload,
      }: PayloadAction<{
        streamId: number;
        filePath: string;
        progress: UploadingFlatFileProgress;
      }>,
    ) => {
      const { filePath, progress, streamId } = payload;
      if (!draft.uploadingFlatFilesByStreamId[streamId]) {
        draft.uploadingFlatFilesByStreamId[streamId] = {};
      }
      draft.uploadingFlatFilesByStreamId[streamId][filePath] = progress;
    },
    removeFlatFileUploadsFromStream: (
      draft,
      { payload }: PayloadAction<{ streamId: number; filePath: string }>,
    ) => {
      delete draft.uploadingFlatFilesByStreamId[payload.streamId][
        payload.filePath
      ];
    },
    clearFinishedFlatFileUploadsForStream: (
      draft,
      { payload }: PayloadAction<{ streamId: number }>,
    ) => {
      const newUploadingFiles: Record<string, UploadingFlatFileProgress> = {};

      // Keep files that are still uploading or processing
      Object.entries(
        draft.uploadingFlatFilesByStreamId[payload.streamId] || {},
      ).forEach(([filePath, progressInfo]) => {
        if (!['done', 'failed'].includes(progressInfo.status)) {
          newUploadingFiles[filePath] = progressInfo;
        }
      });

      draft.uploadingFlatFilesByStreamId[payload.streamId] = newUploadingFiles;
    },
  },
});

export const {
  updateStreams,
  addStream,
  updateStream,
  updateDataFilesByStreamId,
  setModalState,
  setSelectedStream,
  setSelectedDataFile,
  closeStreamsModal,
  updateFlatFileUploadProgress,
  clearFinishedFlatFileUploadsForStream,
  removeFlatFileUploadsFromStream,
} = flatFilesIngestionSlice.actions;
export default flatFilesIngestionSlice.reducer;
