import {
  DateRange,
  U21HistogramDateRange,
  U21HistogramApexChartSeries,
  U21HistogramSeries,
} from 'app/shared/u21-ui/components/charts/models';

import {
  addDays,
  differenceInDays,
  startOfDay,
  startOfHour,
  startOfMonth,
  startOfYear,
  subDays,
} from 'date-fns';
import { formatDate, formatDatetime } from 'app/shared/utils/date';

export const calculateDateRange = (
  series: U21HistogramSeries,
): U21HistogramDateRange => {
  const data = series.flatMap((i) => i.data);
  if (!data.length) {
    return [undefined, undefined];
  }
  return data.reduce<DateRange>(
    (acc, i) => {
      const [min, max] = acc;
      let newMin = min;
      let newMax = max;
      const epoch = new Date(i.x);
      if (epoch > max) {
        newMax = epoch;
      }
      if (epoch < min) {
        newMin = epoch;
      }
      return [newMin, newMax];
    },
    [new Date(data[0].x), new Date(data[0].x)],
  );
};

interface Config {
  bucketSize: number;
  timezone?: string | null;
}

export const tooltipFormatter = (config: Config) => {
  const { timezone, bucketSize } = config;
  return (timestamp: number) => {
    // bucketSize > 1 day means don't need to use show time
    if (bucketSize > 24 * 60 * 60 * 1000) {
      return `${formatDate(timestamp, undefined, { timezone })} - ${formatDate(subDays(new Date(timestamp + bucketSize), 1), undefined, { timezone })}`;
    } else if (bucketSize === 24 * 60 * 60 * 1000) {
      // only show 1 date if bucket is 1 day
      return formatDate(timestamp, undefined, { timezone });
    }
    if (timezone) {
      return `${formatDatetime(timestamp, undefined, {
        timezone,
        showTimezone: true,
      })} - ${formatDatetime(timestamp + bucketSize, undefined, {
        timezone,
        showTimezone: true,
      })}`;
    }

    return `${formatDatetime(timestamp)} - ${formatDatetime(timestamp + bucketSize)}`;
  };
};

export const xaxisFormatter = (config: Config) => {
  const { timezone, bucketSize } = config;
  return (timestamp: number) => {
    // bucketSize >= 1 day means don't need to use show time
    if (bucketSize >= 24 * 60 * 60 * 1000) {
      return formatDate(timestamp, undefined, { timezone });
    }
    if (timezone) {
      return formatDatetime(timestamp, undefined, {
        timezone,
        showTimezone: true,
      });
    }

    return formatDatetime(timestamp);
  };
};

export const aggregateTimeseries = (
  series: U21HistogramSeries,
  dateRange: U21HistogramDateRange,
): { bucketSize: number; series: U21HistogramApexChartSeries } => {
  const [min, max] = dateRange;
  if (!min || !max) {
    return { series: [], bucketSize: 0 };
  }

  const end = startOfDay(addDays(max, 1));
  const days = differenceInDays(end, startOfDay(min));

  let bucketSize: number = 60 * 60 * 1000; // 1 hr
  let offsetFunc: (date: Date) => Date = startOfHour;
  if (days === 1) {
    // 1 - 2 days = 1 hr aggregation
    bucketSize = 60 * 60 * 1000; // 1 hr
    offsetFunc = startOfHour;
  } else if (days === 2) {
    // 2 - 3 days = 2 hr aggregation
    bucketSize = 2 * 60 * 60 * 1000;
    offsetFunc = startOfHour;
  } else if (days === 3) {
    // 3 - 4 days = 3 hr aggregation
    bucketSize = 3 * 60 * 60 * 1000;
    offsetFunc = startOfHour;
  } else if (days === 4) {
    // 4 - 5 days = 4 hr aggregation
    bucketSize = 4 * 60 * 60 * 1000;
    offsetFunc = startOfHour;
  } else if (days < 12) {
    // 5 - 12 days = 6 hr aggregation
    bucketSize = 6 * 60 * 60 * 1000;
    offsetFunc = startOfHour;
  } else if (days < 24) {
    // 12 - 24 days = 12 hr aggregation
    bucketSize = 12 * 60 * 60 * 1000;
    offsetFunc = startOfHour;
  } else if (days < 60) {
    // 24 - 60 days = 1 day aggregation
    bucketSize = 24 * 60 * 60 * 1000;
    offsetFunc = startOfDay;
  } else if (days < 90) {
    // 2 - 3 months = 2 day aggregation
    bucketSize = 2 * 24 * 60 * 60 * 1000;
    offsetFunc = startOfDay;
  } else if (days < 120) {
    // 3 - 4 months = 3 day aggregation
    bucketSize = 3 * 24 * 60 * 60 * 1000;
    offsetFunc = startOfDay;
  } else if (days < 240) {
    // 4 - 8 months = 5 day aggregation
    bucketSize = 5 * 24 * 60 * 60 * 1000;
    offsetFunc = startOfDay;
  } else if (days < 365) {
    // 8 - 12 months = 1 week aggregation
    bucketSize = 7 * 24 * 60 * 60 * 1000;
    offsetFunc = startOfDay;
  } else if (days < 2 * 365) {
    // 1 - 2 years = 2 week aggregation
    bucketSize = 2 * 7 * 24 * 60 * 60 * 1000;
    offsetFunc = startOfDay;
  } else if (days < 4 * 365) {
    // 2 - 4 years = 4 week aggregation
    bucketSize = 4 * 7 * 24 * 60 * 60 * 1000;
    offsetFunc = startOfMonth;
  } else if (days < 8 * 365) {
    // 4 - 8 years = 8 week aggregation
    bucketSize = 8 * 7 * 24 * 60 * 60 * 1000;
    offsetFunc = startOfMonth;
  } else if (days < 16 * 365) {
    // 8 - 16 years = 16 week aggregation
    bucketSize = 16 * 7 * 24 * 60 * 60 * 1000;
    offsetFunc = startOfMonth;
  } else if (days < 32 * 365) {
    // 16 - 32 years = 32 week aggregation
    bucketSize = 32 * 7 * 24 * 60 * 60 * 1000;
    offsetFunc = startOfMonth;
  } else if (days < 64 * 365) {
    // 32 - 64 years = 1 year aggregation
    bucketSize = 52 * 7 * 24 * 60 * 60 * 1000;
    offsetFunc = startOfYear;
  } else if (days < 128 * 365) {
    // 64 - 128 years = 2 year aggregation
    bucketSize = 2 * 52 * 7 * 24 * 60 * 60 * 1000;
    offsetFunc = startOfYear;
  } else if (days < 200 * 365) {
    // 128 - 200 years = 5 year aggregation
    bucketSize = 5 * 52 * 7 * 24 * 60 * 60 * 1000;
    offsetFunc = startOfYear;
  } else if (days < 300 * 365) {
    // 200 - 300 years = 10 year aggregation
    bucketSize = 10 * 52 * 7 * 24 * 60 * 60 * 1000;
    offsetFunc = startOfYear;
  } else {
    // 300 years + = 20 year aggregation
    bucketSize = 20 * 52 * 7 * 24 * 60 * 60 * 1000;
    offsetFunc = startOfYear;
  }

  return {
    bucketSize,
    series: series.map((i) => {
      if (!i.data.length) {
        return {
          ...i,
          data: [],
        };
      }

      let sum = 0;
      let bucketStartTimestamp = offsetFunc(min).getTime();
      let bucketEndTimestamp = bucketStartTimestamp + bucketSize;
      const data: U21HistogramApexChartSeries[number]['data'] = [];

      i.data.forEach((j) => {
        const { x, y } = j;
        const epochTimestamp = new Date(x).getTime();

        // add sum if timestamp is within the current bucket
        if (
          bucketStartTimestamp <= epochTimestamp &&
          epochTimestamp < bucketEndTimestamp
        ) {
          sum += y;
          return;
        }

        // save previous sum if current timestamp is in next bucket
        if (bucketEndTimestamp <= epochTimestamp) {
          data.push({
            x: bucketStartTimestamp,
            y: sum,
          });
          sum = y;
          bucketStartTimestamp += bucketSize;
          bucketEndTimestamp += bucketSize;
        }

        // pad zeroes while epochTimestamp is outside of the current bucket
        while (bucketEndTimestamp <= epochTimestamp) {
          data.push({
            x: bucketStartTimestamp,
            y: 0,
          });
          bucketStartTimestamp += bucketSize;
          bucketEndTimestamp += bucketSize;
        }
      });

      // save results from final iteration
      data.push({
        x: bucketStartTimestamp,
        y: sum,
      });

      // pad zeros up to end
      bucketStartTimestamp += bucketSize;
      while (bucketStartTimestamp <= end.getTime() + bucketSize) {
        data.push({
          x: bucketStartTimestamp,
          y: 0,
        });
        bucketStartTimestamp += bucketSize;
      }

      return {
        ...i,
        data,
      };
    }),
  };
};
