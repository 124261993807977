// Redux
import { Reducer } from 'redux';
import { produce, Draft } from 'immer';
import { keyBy } from 'lodash';
import getFullTagName from 'app/shared/utils/getFullTagName';

// Models
import { TeamActionTypes, TeamState } from 'app/modules/teamsOld/models';

// Actions
import { TeamActions } from 'app/modules/teamsOld/actions';

// Utils
import deepFreeze from 'app/shared/utils/deepFreeze';

const initialState: TeamState = {
  tags: {},
  addedTags: {},
  api_config: {} as TeamState['api_config'],
};

// to ensure initialState is readonly
deepFreeze(initialState);

const reducer: Reducer<TeamState> = (
  state = initialState,
  action: TeamActions,
) => {
  return produce(state, (draft: Draft<TeamState>) => {
    switch (action.type) {
      case TeamActionTypes.RETRIEVE_ALL_TAGS_SUCCESS:
        draft.tags = keyBy(action.payload, (tag) => tag.id);
        return;

      case TeamActionTypes.ADD_TAG_SUCCESS:
        draft.tags[action.payload.id] = action.payload;
        draft.addedTags[
          getFullTagName(action.payload.type, action.payload.name)
        ] = action.payload;
        return;

      case TeamActionTypes.CLEAR_ADDED_TAG:
        delete draft.addedTags[action.payload];
        return;

      case TeamActionTypes.RETRIEVE_API_CONFIGS_SUCCESS:
        draft.api_config = action.payload;
        return;

      case TeamActionTypes.TAG_LIMIT_REACHED:
        draft.tagLimitReached = true;
        return;

      case TeamActionTypes.CLEAR_TAG_LIMIT_REACHED:
        draft.tagLimitReached = false;
        return;
    }
  });
};

export { reducer as teamReducer, initialState };
