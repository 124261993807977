export enum OrgTableColumnKeyType {
  UNIT21 = 'UNIT21',
  DATA_SETTING = 'DATA_SETTING',
}

export interface Unit21ColumnMetadata {
  key: string;
  key_type: OrgTableColumnKeyType.UNIT21;
}

export interface DataSettingColumnMetadata {
  data_setting_id: number;
  key_type: OrgTableColumnKeyType.DATA_SETTING;
}

export type ColumnMetadata = Unit21ColumnMetadata | DataSettingColumnMetadata;

export interface OrgTableConfig {
  id: string | null;
  type_classifier: OrgTableTypeClassifier;
  columns: ColumnMetadata[];
}
export interface OrgTableConfigResponse {
  data: OrgTableConfig[];
}
export interface UpdateOrgTableConfigResponse {
  data: OrgTableConfig;
}

export enum OrgTableTypeClassifier {
  ENTITY = 'ENTITY',
  INSTRUMENT = 'INSTRUMENT',
  EVENT = 'EVENT',
  ACTION_EVENT = 'ACTION_EVENT',
  ALERT = 'ALERT',
  CASE = 'CASE',
  RULE = 'RULE',
  WATCHLIST = 'WATCHLIST',
}

export interface DeleteAgentTableConfigResponse {}
