import {
  getInitialTimeOption,
  secondsToTime,
} from 'app/shared/components/TimeDeltaInput';
import { DeadlineChipStatus } from 'app/modules/deadlines/models';

function getFullTimeOption(seconds: number) {
  // Calculate
  if (seconds % (60 * 60 * 24 * 31) === 0) {
    return 'month(s)';
  }
  if (seconds % (60 * 60 * 24 * 7) === 0) {
    return 'week(s)';
  }
  if (seconds % (60 * 60 * 24) === 0) {
    return 'day(s)';
  }
  return 'hour(s)';
}

export function getFullTimeLabel(seconds: number): string {
  const timeBucket = getInitialTimeOption(seconds);
  const timeConstant = getFullTimeOption(seconds);
  const timeAmount = secondsToTime(seconds, timeBucket);
  return `${timeAmount} ${timeConstant}`;
}

export const getDeadlineChipStatus = (
  deadline?: number,
  pastDue?: boolean,
  showWarning?: boolean,
): DeadlineChipStatus => {
  if (!deadline) return DeadlineChipStatus.NO_DEADLINE;
  if (pastDue) return DeadlineChipStatus.PAST_DEADLINE;
  if (showWarning) return DeadlineChipStatus.WARN_BEFORE_DEADLINE;
  return DeadlineChipStatus.NORMAL_DEADLINE;
};
