import { createSelector } from 'reselect';

// Models
import {
  InsightsActionTypes,
  InsightsFiltersStatus,
} from 'app/modules/insights/models';

// Helpers
import { combineRootActionWithId } from 'app/shared/helpers';

export const selectChartDefinitions = (state: RootState) => {
  return state.insights.chart_definitions || [];
};

export const selectChartDefinitionsCount = (state: RootState) => {
  return state.insights.chart_definitions_count;
};

export const selectDashboards = (state: RootState) => {
  return state.insights.dashboards || [];
};

export const selectFirstDashboardsId = createSelector(
  selectDashboards,
  (dashboards): null | number => {
    if (dashboards.length <= 0) {
      return null;
    }
    return dashboards[0].id;
  },
);

export const selectDashboard = (state: RootState) => {
  return state.insights.dashboard;
};

export const selectDashboardTagNameFilters = createSelector(
  selectDashboard,
  (dashboard) => {
    const selectedTagNames: string[] = [];
    (dashboard.filters || []).forEach((filter) => {
      if (filter.name === 'tag.name') {
        selectedTagNames.push(filter.value);
      }
    });
    return selectedTagNames;
  },
);

export const selectDashboardTagTypeFilters = createSelector(
  selectDashboard,
  (dashboard) => {
    const selectedTagTypes: string[] = [];
    (dashboard.filters || []).forEach((filter) => {
      if (filter.name === 'tag.type') {
        selectedTagTypes.push(filter.value);
      }
    });
    return selectedTagTypes;
  },
);

export const selectDashboardStatusFilter = createSelector(
  selectDashboard,
  (dashboard) => {
    let status: InsightsFiltersStatus = '';
    (dashboard.filters || []).forEach((filter) => {
      if (filter.name === 'status') {
        status = filter.value;
      }
    });
    return status;
  },
);

export const selectDashboardAgentsFilters = createSelector(
  selectDashboard,
  (dashboard) => {
    const selectedAgentIds: number[] = [];
    (dashboard.filters || []).forEach((filter) => {
      // avoid dups as BE can send multiple filters for the same agent
      if (
        filter.name === 'agent_id' &&
        !selectedAgentIds.includes(Number(filter.value))
      ) {
        // convert string id to number
        selectedAgentIds.push(Number(filter.value));
      }
    });
    return selectedAgentIds;
  },
);

export const selectDashboardOrgFilters = createSelector(
  selectDashboard,
  (dashboard) =>
    dashboard?.filtered_org_id ? [dashboard?.filtered_org_id] : [],
);

export const selectDashboardUnitFilters = createSelector(
  selectDashboard,
  (dashboard) =>
    dashboard?.filtered_unit_id ? [dashboard?.filtered_unit_id] : [],
);

export const selectDashboardTeamsFilters = createSelector(
  selectDashboard,
  (dashboard) => {
    const selectedTeamsIds: number[] = [];
    (dashboard.filters || []).forEach((filter) => {
      // avoid dups as BE can send multiple filters for the same team
      if (
        filter.name === 'team_id' &&
        !selectedTeamsIds.includes(Number(filter.value))
      ) {
        // convert string id to number
        selectedTeamsIds.push(Number(filter.value));
      }
    });
    return selectedTeamsIds;
  },
);

export const selectDashboardTimeBucket = createSelector(
  selectDashboard,
  (dashboard) => {
    return dashboard?.time_bucket || '';
  },
);

export const selectDashboardId = (state: RootState) => {
  return state.insights.dashboard?.id;
};

export const selectCharts = (state: RootState) => {
  return state.insights.charts;
};

export const selectChart = (state: RootState, id: number) => {
  return state.insights.charts[id] || {};
};

export const selectUsedChartsDefinitions = createSelector(
  selectDashboard,
  selectCharts,
  (dashboard, charts) => {
    const usedChartDefinitions: number[] = [];
    const dashboardCharts = dashboard?.charts ?? [];
    dashboardCharts.forEach((chartId) => {
      const chart = charts[chartId];
      if (chart && chart.definition) {
        usedChartDefinitions.push(chart.definition);
      }
    });
    return usedChartDefinitions;
  },
);

// Loading
export const selectRetrieveChartLoading = (
  state: RootState,
  chartId: number,
) => {
  const formattedActionType = combineRootActionWithId(
    InsightsActionTypes.RETRIEVE_CHART,
    chartId,
  );
  return Boolean(state.loading[formattedActionType]);
};

export const selectEditChartLoading = (state: RootState, chartId: number) => {
  const formattedActionType = combineRootActionWithId(
    InsightsActionTypes.EDIT_CHART,
    chartId,
  );
  return Boolean(state.loading[formattedActionType]);
};

export const selectRetrieveChartDefinitionsLoading = (state: RootState) =>
  Boolean(state.loading[InsightsActionTypes.RETRIEVE_CHART_DEFINITIONS]);

export const selectRetrieveDashboardLoading = (state: RootState): boolean =>
  Boolean(state.loading[InsightsActionTypes.RETRIEVE_DASHBOARD]);

export const selectRetrieveDashboardsLoading = (state: RootState): boolean =>
  Boolean(state.loading[InsightsActionTypes.RETRIEVE_DASHBOARDS]);

export const selectCreateDashboardLoading = (state: RootState): boolean =>
  Boolean(state.loading[InsightsActionTypes.CREATE_DASHBOARD]);

export const selectDuplicateDashboardLoading = (state: RootState): boolean =>
  Boolean(state.loading[InsightsActionTypes.DUPLICATE_DASHBOARD]);

export const selectDeleteDashboardLoading = (state: RootState): boolean =>
  Boolean(state.loading[InsightsActionTypes.DELETE_DASHBOARD]);

export const selectEditDashboardLoading = (state: RootState) =>
  Boolean(state.loading[InsightsActionTypes.EDIT_DASHBOARD]);

// combine all loading states that have to do with a dashboard
export const selectDashboardLoading = createSelector(
  selectRetrieveDashboardLoading,
  selectCreateDashboardLoading,
  selectDuplicateDashboardLoading,
  selectDeleteDashboardLoading,
  (
    retrieveLoading,
    createLoading,
    duplicateLoading,
    deleteLoading,
  ): boolean => {
    return Boolean(
      retrieveLoading || createLoading || duplicateLoading || deleteLoading,
    );
  },
);
