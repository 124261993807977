import retrieveBlacklists from 'app/modules/lists/sagas/retrieveBlacklists';
import createBlacklist from 'app/modules/lists/sagas/createBlacklist';
import retrieveBlacklist from 'app/modules/lists/sagas/retrieveBlacklist';
import retireveBlacklistItems from 'app/modules/lists/sagas/retrieveBlacklistItems';
import editBlacklist from 'app/modules/lists/sagas/editBlacklist';
import deactivateBlacklistItem from 'app/modules/lists/sagas/deactivateBlacklistItem';
import addBlacklistItem from 'app/modules/lists/sagas/addBlacklistItem';
import addBusinessBlacklistItem from 'app/modules/lists/sagas/addBusinessBlacklistItem';
import addUserBlacklistItem from 'app/modules/lists/sagas/addUserBlacklistItem';
import appendFileToBlacklist from 'app/modules/lists/sagas/appendFileToBlacklist';

const sagaRegistry = [
  retrieveBlacklists,
  createBlacklist,
  retrieveBlacklist,
  retireveBlacklistItems,
  editBlacklist,
  deactivateBlacklistItem,
  addBlacklistItem,
  addUserBlacklistItem,
  addBusinessBlacklistItem,
  appendFileToBlacklist,
];

export default sagaRegistry;
