import { call, put, takeLatest, delay } from 'redux-saga/effects';
import { getIDP, refreshIDP } from 'app/shared/api/session';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';
import {
  retrieveIDPPending,
  retrieveIDPSuccess,
  retrieveIDPError,
} from 'app/modules/session/actions';
import {
  SessionActionTypes,
  SessionAgentIDP,
} from 'app/modules/session/models';
import { createSentryError } from 'app/shared/utils/sentry';

const rootAction = SessionActionTypes.RETRIEVE_IDP;
function* retrieveIDPFlow() {
  const config = {
    rootAction,
    request: call(getIDP),
    pending: function* onPending() {
      yield put(retrieveIDPPending());
    },
    success: function* onSuccess(result: SessionAgentIDP) {
      if (result) {
        yield put(retrieveIDPSuccess(result));
        // refresh google auth token until application is exited
        while (true) {
          // ten seconds before google auth token expires, refresh it
          const secondsTillExpiration = result.expires_in - 10;
          // delay takes in milliseconds
          yield delay(1000 * secondsTillExpiration);
          const newIDP: SessionAgentIDP = yield call(refreshIDP);

          yield put(retrieveIDPSuccess(newIDP));
        }
      } else {
        const error =
          'Unexpected response from server for retrieveIDPFlow saga';
        createSentryError({ error });
      }
    },
    error: function* onError() {
      yield put(retrieveIDPError());
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watchRetrieveIDP() {
  yield takeLatest(rootAction, retrieveIDPFlow);
}
