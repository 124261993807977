import {
  NewNotificationsState,
  NotificationStatus,
} from 'app/modules/notifications/models';
import {
  NotificationResponse,
  RetrieveNotificationsResponse,
} from 'app/modules/notifications/types/responses';
import { PayloadAction } from '@reduxjs/toolkit';

import {
  getNotifications,
  readAllNotification,
  readNotification,
} from 'app/shared/api/notifications';
import { u21CreateAsyncThunk } from 'app/shared/thunk/u21CreateAsyncThunk';
import { u21CreateSlice } from 'app/shared/thunk/u21CreateSlice';

const NOTIFICATIONS_NAME = 'notifications';

const initialState: Readonly<NewNotificationsState> = {
  notifications: [],
  notificationsCount: 0,
  unreadNotificationsCount: 0,
  loadingNotifications: false,
  topbarNotifications: [],
  loadingTopbarNotifications: false,
  loadingMarkAllNotificationsRead: false,
};

export const retrieveNotificationsThunk = u21CreateAsyncThunk(
  `${NOTIFICATIONS_NAME}/GET_NOTIFICATIONS`,
  getNotifications,
);

export const markAllNotificationsReadThunk = u21CreateAsyncThunk(
  `${NOTIFICATIONS_NAME}/MARK_ALL_NOTIFICATIONS_READ`,
  readAllNotification,
);

export const readNotificationThunk = u21CreateAsyncThunk(
  `${NOTIFICATIONS_NAME}/READ_NOTIFICATION`,
  readNotification,
);

export const retrieveTopbarNotificationsThunk = u21CreateAsyncThunk(
  `${NOTIFICATIONS_NAME}/GET_TOPBAR_NOTIFICATIONS`,
  getNotifications,
);

export const notificationsSlice = u21CreateSlice({
  name: NOTIFICATIONS_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addLoadingCase(
        retrieveNotificationsThunk,
        'loadingNotifications',
        (draft, action: PayloadAction<RetrieveNotificationsResponse>) => {
          draft.notifications = action.payload.notifications;
          draft.notificationsCount = action.payload.count;
          draft.unreadNotificationsCount = action.payload.unread_count;
        },
      )
      .addLoadingCase(
        retrieveTopbarNotificationsThunk,
        'loadingTopbarNotifications',
        (draft, action: PayloadAction<RetrieveNotificationsResponse>) => {
          draft.topbarNotifications = action.payload.notifications;
          draft.notificationsCount = action.payload.count;
          draft.unreadNotificationsCount = action.payload.unread_count;
        },
      )
      .addLoadingCase(
        markAllNotificationsReadThunk,
        'loadingMarkAllNotificationsRead',
        (draft) => {
          draft.notifications = draft.notifications.map((notification) => ({
            ...notification,
            status: NotificationStatus.READ,
          }));

          draft.topbarNotifications = draft.topbarNotifications.map(
            (notification) => ({
              ...notification,
              status: NotificationStatus.READ,
            }),
          );

          draft.unreadNotificationsCount = 0;
        },
      )
      .addCase(
        readNotificationThunk.fulfilled,
        (draft, action: PayloadAction<NotificationResponse>) => {
          draft.notifications = draft.notifications.map((notification) => {
            if (notification.id === action.payload.id) {
              return { ...notification, status: NotificationStatus.READ };
            }
            return notification;
          });

          draft.topbarNotifications = draft.topbarNotifications.map(
            (notification) => {
              if (notification.id === action.payload.id) {
                return { ...notification, status: NotificationStatus.READ };
              }
              return notification;
            },
          );
          draft.unreadNotificationsCount -= 1;
        },
      );
  },
});

export const NOTIFICATIONS_SLICE_NAME = notificationsSlice.name;
export default notificationsSlice.reducer;
