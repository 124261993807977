// Components
import { EventChip } from 'app/modules/alerts/components/AlertDetails/EventChip';
import { ActionEventChip } from 'app/modules/alerts/components/AlertDetails/ActionEventChip';

// Models
import {
  U21TableColumnTypes,
  U21TableColumnTypesType,
} from 'app/shared/u21-ui/components';
import { U21EntityChip } from 'app/shared/u21-ui/components/dashboard';

const LIST: U21TableColumnTypesType = 'list';

export const ENTITY_IDS_COLUMN = {
  id: 'entity_ids',
  accessor: 'entity_ids',
  Header: 'Entities',
  disableSortBy: true,
  type: LIST,
  cellProps: {
    getShowLessText: () => 'Show fewer entities',
    getShowMoreText: (length: number) => `Show all ${length} entities`,
    displayFunc: (entityID: number) => (
      <U21EntityChip
        key={entityID}
        id={entityID}
        name={`Entity #${entityID}`}
      />
    ),
  },
};

export const EVENT_IDS_COLUMN = {
  id: 'event_ids',
  accessor: 'event_ids',
  Header: 'Transactions',
  disableSortBy: true,
  type: LIST,
  cellProps: {
    displayFunc: (eventID: number) => (
      <EventChip key={eventID} eventID={eventID} />
    ),
  },
};

export const ACTION_EVENT_IDS_COLUMN = {
  id: 'action_event_ids',
  accessor: 'action_event_ids',
  Header: 'Action Events',
  disableSortBy: true,
  type: LIST,
  cellProps: {
    displayFunc: (eventID: number) => (
      <ActionEventChip key={eventID} eventID={eventID} />
    ),
  },
};

export const CREATED_AT_COLUMN = {
  id: 'created_at',
  accessor: 'created_at',
  Header: 'Created at',
  type: U21TableColumnTypes.DATETIME,
};
