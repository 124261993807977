import {
  forwardRef,
  ForwardedRef,
  HTMLProps,
  ReactNode,
  SyntheticEvent,
} from 'react';

import { getDOMProps } from 'app/shared/utils/react';
import styled from 'styled-components';

import { FormControlLabel, Radio } from '@mui/material';
import { U21Typography } from 'app/shared/u21-ui/components/display/typography/U21Typography';

export type U21RadioValue = string | number;

export interface U21RadioProps
  extends Omit<HTMLProps<HTMLButtonElement>, 'label' | 'onChange'> {
  checked?: boolean;
  description?: string;
  disabled?: boolean;
  label?: ReactNode;
  onChange: (value: string, e: SyntheticEvent) => void;
  tooltip?: string;
  value: U21RadioValue;
}

export const U21Radio = forwardRef<HTMLElement, U21RadioProps>(
  (props: U21RadioProps, ref) => {
    const {
      value,
      checked,
      description,
      label,
      onChange,
      disabled,
      tooltip,
      ...rest
    } = props;

    const radioButton = (
      <Radio
        value={value}
        checked={checked}
        disabled={disabled}
        onChange={(e) => onChange(e.target.value, e)}
        {...(label
          ? {}
          : {
              // HTMLButtonElement extends from HTMLElement so should be safe
              ref: ref as ForwardedRef<HTMLButtonElement>,
              ...getDOMProps(rest),
            })}
      />
    );

    if (!label) {
      return radioButton;
    }

    return (
      <StyledFormControlLabel
        ref={ref}
        control={radioButton}
        label={
          <>
            {typeof label === 'string' ? (
              <U21Typography
                color={disabled ? 'text.secondary' : 'text.primary'}
                variant="body2"
              >
                {label}
              </U21Typography>
            ) : (
              label
            )}
            <U21Typography color="text.secondary" variant="body2">
              {description}
            </U21Typography>
          </>
        }
        {...getDOMProps(rest)}
      />
    );
  },
);

const StyledFormControlLabel = styled(FormControlLabel)`
  margin-right: 32px;
  width: fit-content;

  .MuiFormControlLabel-label {
    margin: 4px 0;
  }
`;
