import {
  getImpersonateId,
  getOrgSwitcherAgentId,
} from 'app/shared/utils/sessionStorage';
import { QA_ADMIN_USER_EMAIL } from 'app/modules/session/constants';

export const isQAUser = (email) => QA_ADMIN_USER_EMAIL.includes(email);

export const getIsOrgSwitcherAgentSet = () => {
  const orgSwitcherAgentId = getOrgSwitcherAgentId();
  return orgSwitcherAgentId !== -1 && orgSwitcherAgentId !== 0;
};

export const getIsImpersonating = () => {
  const impersonationID = getImpersonateId();
  return impersonationID !== -1 && impersonationID !== 0;
};
