import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { AlertsActionTypes } from 'app/modules/alerts/models';

// Actions
import {
  retrieveAlertAuditTrail as retrieveAlertAuditTrailAction,
  retrieveAlertAuditTrailSuccess,
} from 'app/modules/alerts/actions';

// API
import { retrieveAlertAuditTrail as api } from 'app/shared/api/alerts';

const rootAction = AlertsActionTypes.RETRIEVE_ALERT_AUDIT_TRAIL;
function* retrieveAlertAuditTrail({
  payload,
}: ReturnType<typeof retrieveAlertAuditTrailAction>) {
  const config = {
    rootAction,
    request: call(api, payload),
    success: function* onSuccess(result) {
      yield put(retrieveAlertAuditTrailSuccess(result));
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watchRetrieveAlertAuditTrail() {
  yield takeLatest(rootAction, retrieveAlertAuditTrail);
}
