import {
  downloadFile,
  get,
  post,
  destroy,
  uploadWithProgress,
} from 'app/shared/utils/fetchr';
import {
  GetDataFilesForStreamPayload,
  ProcessPullBasedDataFileAPIPayload,
  UploadPullBasedDataFilePayload,
} from 'app/modules/pullBasedDataFiles/requests';
import {
  PullBasedDataFile,
  PullBasedDataFilesPage,
  PullBasedDataFilesPreview,
} from 'app/modules/pullBasedDataFiles/responses';

export const API_BASE = '/database-connections';

export const uploadFlatFileForIngestion = ({
  file,
  ...data
}: UploadPullBasedDataFilePayload): {
  req: XMLHttpRequest;
  uploadPromise: Promise<PullBasedDataFile>;
} => {
  const formData = new FormData();
  formData.append('data', JSON.stringify(data));
  formData.append('file', file);

  return uploadWithProgress(`${API_BASE}/pull-based-datafiles`, formData);
};

export const processPullBasedDatafile = ({
  id,
  isDryRun = false,
}: ProcessPullBasedDataFileAPIPayload): Promise<PullBasedDataFile> =>
  post(`${API_BASE}/pull-based-datafiles/${id}/process`, {
    is_dry_run: isDryRun,
  });

export const getDataFilesForStream = (
  payload: GetDataFilesForStreamPayload,
): Promise<PullBasedDataFilesPage> =>
  post(`${API_BASE}/pull-based-datafiles/list`, payload);

export const previewDatafile = async (
  fileId: number,
): Promise<PullBasedDataFilesPreview> => {
  return get<PullBasedDataFilesPreview>(
    `${API_BASE}/pull-based-datafiles/preview/${fileId}`,
  );
};

export const deleteDataFile = (fileId: number): Promise<boolean> =>
  destroy(`${API_BASE}/pull-based-datafiles/${fileId}/delete`);

export const downloadDatafile = (fileId: number, fileName: string) =>
  downloadFile(`${API_BASE}/pull-based-datafiles/download/${fileId}`, fileName);
