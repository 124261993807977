import { ScenarioParameters } from 'app/modules/detectionModels/models';

export const validateConsortiumContentStep = (
  params: ScenarioParameters,
): boolean => {
  const executionFrequencyDefined = params.$window_stride;
  if (!executionFrequencyDefined) {
    // Since execution_frequency == window_stride, it is easier to check window_stride as it updates based off
    return false;
  }
  if (!params.consortium_dynamic_condition_valid) {
    return false;
  }
  return true;
};
