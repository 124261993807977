import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { DetectionModelsActionTypes } from 'app/modules/detectionModels/models';

// Actions
import {
  retrieveDetectionModelValidationMetrics as retrieveDetectionModelValidationMetricsAction,
  retrieveDetectionModelValidationMetricsSuccess,
} from 'app/modules/detectionModels/actions';

// API
import { retrieveDetectionModelValidationMetrics as retrieveDetectionModelValidationMetricsAPI } from 'app/shared/api/detectionModels';

const rootAction =
  DetectionModelsActionTypes.RETRIEVE_DETECTION_MODEL_VALIDATION_METRICS;
export function* retrieveDetectionModelValidationMetrics({
  payload,
}: ReturnType<typeof retrieveDetectionModelValidationMetricsAction>) {
  const config = {
    rootAction,
    errorToastMessage: 'Something went wrong, unable to retrieve metrics.',
    request: call(retrieveDetectionModelValidationMetricsAPI, payload),
    success: function* onSuccess(result) {
      yield put(retrieveDetectionModelValidationMetricsSuccess(result));
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watch() {
  yield takeLatest(rootAction, retrieveDetectionModelValidationMetrics);
}
