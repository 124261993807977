import { call, takeLatest, put } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { WorkflowActionTypes } from 'app/modules/workflows/models';

// Actions
import { retrieveSubdispositionsSuccess } from 'app/modules/workflows/actions';

// API
import { retrieveSubdispositions as api } from 'app/shared/api/workflows';

const rootAction = WorkflowActionTypes.RETRIEVE_SUBDISPOSITIONS;
function* retrieveSubdispositions() {
  const config = {
    rootAction,
    request: call(api),
    success: function* onSuccess(result) {
      yield put(retrieveSubdispositionsSuccess(result));
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watchRetrieveSubdispositions() {
  yield takeLatest(rootAction, retrieveSubdispositions);
}
