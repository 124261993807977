import { HTMLProps, MouseEvent, ReactElement, ReactNode, useMemo } from 'react';

import { getA11yClickProps } from 'app/shared/utils/a11y';
import { getDOMProps, isRenderable } from 'app/shared/utils/react';
import { useControlled } from 'app/shared/hooks/useControlled';
import styled, { css } from 'styled-components';

import { Collapse } from '@mui/material';
import { IconChevronDown, IconChevronUp } from '@u21/tabler-icons';
import { U21Spacer } from 'app/shared/u21-ui/components/layout/U21Spacer';
import { U21Typography } from 'app/shared/u21-ui/components/display/typography/U21Typography';

export interface U21SubsectionProps
  extends Omit<HTMLProps<HTMLDivElement>, 'action' | 'title'> {
  action?: ReactNode;
  children: ReactNode;
  collapsed?: boolean;
  collapsible?: boolean;
  mounted?: boolean;
  onToggleCollapse?: (collapsed: boolean, e: MouseEvent<HTMLElement>) => void;
  shaded?: boolean;
  title: ReactNode;
  titleIcon?: ReactElement;
}

export const U21Subsection = (props: U21SubsectionProps) => {
  const {
    action,
    children,
    collapsed: collapsedProp = false,
    collapsible = false,
    mounted,
    onToggleCollapse: onToggleCollapseProp,
    shaded,
    title,
    titleIcon,
    ...rest
  } = props;

  const [collapsed, onToggleCollapse] = useControlled(
    collapsedProp,
    onToggleCollapseProp,
  );

  const ariaLabel = useMemo(() => {
    if (rest['aria-label']) {
      return rest['aria-label'];
    }
    return typeof title === 'string' ? title : undefined;
  }, [rest, title]);

  return (
    <div {...getDOMProps(rest)} aria-label={ariaLabel}>
      <StyledSpacer
        $collapsible={collapsible}
        $shaded={shaded}
        horizontal
        {...getA11yClickProps(
          onToggleCollapse
            ? (event: MouseEvent<HTMLElement>) =>
                onToggleCollapse(!collapsed, event)
            : undefined,
          !collapsible,
        )}
      >
        <StyledU21Typography
          component={typeof title === 'string' ? undefined : 'div'}
          icon={titleIcon}
          variant="subtitle1"
        >
          {title}
        </StyledU21Typography>
        {(action || collapsible) && (
          <U21Spacer horizontal>
            {action && <div>{action}</div>}
            {collapsible &&
              (collapsed ? <IconChevronDown /> : <IconChevronUp />)}
          </U21Spacer>
        )}
      </StyledSpacer>
      {(() => {
        if (!isRenderable(children)) {
          return null;
        }
        if (collapsible) {
          return (
            <Collapse
              in={!collapsed}
              mountOnEnter={!mounted}
              unmountOnExit={!mounted}
            >
              <Content>{children}</Content>
            </Collapse>
          );
        }
        return <Content>{children}</Content>;
      })()}
    </div>
  );
};

const StyledSpacer = styled(U21Spacer)<{
  $collapsible: boolean;
  $shaded?: boolean;
}>`
  justify-content: space-between;
  padding: 8px 16px;

  ${(props) =>
    props.$collapsible
      ? css`
          cursor: pointer;
        `
      : ''}

  ${(props) => {
    return props.$shaded
      ? css`
          background-color: ${props.theme.palette.background.neutral};
        `
      : '';
  }}
`;

const StyledU21Typography = styled(U21Typography)`
  text-transform: capitalize;
`;

const Content = styled.div`
  padding: 8px 16px;
`;
