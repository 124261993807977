import { get } from 'app/shared/utils/fetchr';
import { useQuery } from '@tanstack/react-query';
import { ALL_DATA_LABELS_QUERY_KEYS } from 'app/modules/dataLabels/queries/keys';
import { useMemo } from 'react';

interface FetchAtllDataLabelsResponse {
  data_labels: Array<DataLabelResponse>;
}

export interface DataLabelResponse {
  id: string | number;
  created_at: string | null;
  name: string;
  type: string;
}

const fetchAllDataLabels = (): Promise<FetchAtllDataLabelsResponse> =>
  get('/data-labels/list');

export const useFetchAllDataLabels = () => {
  return useQuery({
    queryKey: ALL_DATA_LABELS_QUERY_KEYS.getAllDataLabels(),
    queryFn: () => fetchAllDataLabels(),
    meta: { errorMessage: 'Failed to fetch all tags.' },
  });
};

export const useFetchAllDataLabelsById = () => {
  const { data } = useFetchAllDataLabels();
  return useMemo(() => {
    const dataLabels = data?.data_labels ?? [];
    const dataLabelsById = {};
    dataLabels.forEach((dataLabel) => {
      dataLabelsById[dataLabel.id] = dataLabel;
    });
    return dataLabelsById;
  }, [data]);
};
