import { FC, HTMLProps } from 'react';

import { getDOMProps } from 'app/shared/utils/react';

import Divider from '@mui/material/Divider';

export interface U21DividerProps
  extends HTMLProps<HTMLDivElement | HTMLHRElement> {
  horizontal?: boolean;
  flexItem?: boolean;
}

export const U21Divider: FC<U21DividerProps> = ({
  horizontal = false,
  flexItem = false,
  ...rest
}) => (
  <Divider
    orientation={horizontal ? 'horizontal' : 'vertical'}
    flexItem={flexItem}
    {...getDOMProps(rest)}
  />
);
