import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

// Components
import { U21Chip, U21ChipProps } from 'app/shared/u21-ui/components';

// Selectors
import { selectLumosConfig } from 'app/modules/orgSettings/selectors';

// Models
import { LumosConfig } from 'app/modules/orgSettings/models';

// Utils
import { generateColor } from 'app/shared/utils/stringToColor';

// Helpers
import { isSuitableBackgroundColor } from 'app/shared/helpers';
import { forwardRef } from 'react';

interface U21DynamicColorChipProps
  extends Omit<U21ChipProps, 'as' | 'color' | 'ref'> {
  color?: string;
}

export const U21DynamicColorChip = forwardRef<
  HTMLDivElement,
  U21DynamicColorChipProps
>((props, ref) => {
  const { color, children, variant, ...rest } = props;
  const { colors } = useSelector(selectLumosConfig);

  return (
    <StyledChip
      $color={color}
      $colors={colors}
      ref={ref}
      variant={variant}
      {...rest}
    >
      {children}
    </StyledChip>
  );
});

interface StyleProps {
  $color?: string;
  $colors: LumosConfig['colors'];
}

const StyledChip = styled(U21Chip)<StyleProps>`
  ${(props) => {
    const { $color, $colors, variant, children } = props;
    const backgroundColor =
      $color || generateColor(children as string, $colors);
    if (variant === 'outlined') {
      return css`
        border: 1px solid ${backgroundColor};
        color: ${backgroundColor};

        .MuiChip-deleteIcon {
          color: ${backgroundColor};
        }
      `;
    }
    const color = isSuitableBackgroundColor(backgroundColor)
      ? 'white'
      : 'black';
    return css`
      background-color: ${backgroundColor};
      color: ${color};

      .MuiChip-deleteIcon {
        color: ${color};
      }

      &:hover {
        background-color: ${backgroundColor};
        color: ${color};
      }
    `;
  }}
`;
