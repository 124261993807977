import { u21CreateSlice } from 'app/shared/thunk/u21CreateSlice';
import { PayloadAction } from '@reduxjs/toolkit';

const SUB_SECOND_RULE_CREATE_UI = 'sub-second-rule-create-ui';

interface SubSecondRuleCreateUIState {
  generatesAlerts: boolean;
  isValidationPickerUiValid: boolean;
  step: number;
  validateOrActivate: 'validate' | 'activate';
}

const initialState: SubSecondRuleCreateUIState = {
  generatesAlerts: false,
  isValidationPickerUiValid: true,
  step: 0,
  validateOrActivate: 'validate',
};

const subSecondRuleCreateUiSlice = u21CreateSlice({
  name: SUB_SECOND_RULE_CREATE_UI,
  initialState,
  reducers: {
    resetSubSecondRuleCreateUiSlice: (draft) => {
      Object.assign(draft, initialState);
    },
    setSubSecondRuleCreateUiGeneratesAlerts: (
      draft,
      { payload }: PayloadAction<boolean>,
    ) => {
      draft.generatesAlerts = payload;
    },
    setSubSecondRuleCreateUiValidationPickerValidity: (
      draft,
      { payload }: PayloadAction<boolean>,
    ) => {
      draft.isValidationPickerUiValid = payload;
    },
    setSubSecondRuleCreateUiStep: (
      draft,
      { payload }: PayloadAction<number>,
    ) => {
      draft.step = payload;
    },
    setSubSecondRuleCreateUiValidateOrActivate: (
      draft,
      { payload }: PayloadAction<'validate' | 'activate'>,
    ) => {
      draft.validateOrActivate = payload;
    },
  },
});

export const SUB_SECOND_RULE_CREATE_UI_SLICE_NAME =
  subSecondRuleCreateUiSlice.name;
export const {
  resetSubSecondRuleCreateUiSlice,
  setSubSecondRuleCreateUiGeneratesAlerts,
  setSubSecondRuleCreateUiValidationPickerValidity,
  setSubSecondRuleCreateUiStep,
  setSubSecondRuleCreateUiValidateOrActivate,
} = subSecondRuleCreateUiSlice.actions;
export default subSecondRuleCreateUiSlice.reducer;
