// Utils
import { destroy, get, post, put, upload } from 'app/shared/utils/fetchr';

// Requests
import {
  EnableSlackNotificationsPayload,
  NewAgentPayload,
  PaginatedAgentsPayload,
  UpdateAgentNotificationSettingsPayload,
  UpdateAgentPayload,
  UpdateAgentPicturePayload,
} from 'app/modules/agents/requests';
import {
  AgentNotificationSettingResponse,
  CreateAgentResponse,
  PaginatedAgentsResponse,
  ShortAgentResponse,
  SlackNotificationSettingResponse,
} from 'app/modules/agents/responses';

const AGENTS_API_BASE = '/agents';
const NOTIFICATIONS_API_BASE = '/notifications/settings';
const SLACK_API_BASE = '/slack';

export const retrievePaginatedAgents = (payload: PaginatedAgentsPayload) => {
  return post<PaginatedAgentsResponse>(`${AGENTS_API_BASE}/paginated`, payload);
};

export const retrieveAgentDetails = (id: number) => {
  return get(`${AGENTS_API_BASE}/${id}/details`);
};

export const addAgent = (payload: NewAgentPayload) => {
  return post<CreateAgentResponse>(`${AGENTS_API_BASE}/add`, payload);
};

export const deactivateAgent = (id: number) => {
  return post(`${AGENTS_API_BASE}/${id}/deactivate`);
};

export const activateAgent = (id: number) => {
  return post(`${AGENTS_API_BASE}/${id}/activate`);
};

export const sendNewAgentMail = (id: number) => {
  return post(`${AGENTS_API_BASE}/${id}/new-agent-email`);
};

export const updateAgent = (id: number, payload: UpdateAgentPayload) => {
  return put(`${AGENTS_API_BASE}/${id}`, payload);
};

export const updateAgentPicture = (
  id: number,
  payload: UpdateAgentPicturePayload,
): Promise<ShortAgentResponse> => {
  const formData = Object.entries(payload).reduce((acc, [key, value]) => {
    acc.append(key, value);
    return acc;
  }, new FormData());
  return upload(`${AGENTS_API_BASE}/${id}/picture/upload`, formData);
};

export const getAgentNotificationSettings = (
  id: number,
): Promise<AgentNotificationSettingResponse[]> => {
  return get(`${NOTIFICATIONS_API_BASE}/${id}`);
};

export const updateAgentNotificationSettings = (
  id: number,
  payload: UpdateAgentNotificationSettingsPayload,
): Promise<[AgentNotificationSettingResponse]> => {
  return put(`${NOTIFICATIONS_API_BASE}/${id}`, payload);
};

export const getSlackNotificationSettings = (
  id: number,
): Promise<SlackNotificationSettingResponse> => {
  return get(`${SLACK_API_BASE}/list/${id}`);
};

export const enableSlackNotifications = (
  payload: EnableSlackNotificationsPayload,
): Promise<SlackNotificationSettingResponse> => {
  return post(`${SLACK_API_BASE}/authorize_user`, payload);
};

export const disableSlackNotifications = (
  id: number,
): Promise<SlackNotificationSettingResponse> => {
  return destroy(`${SLACK_API_BASE}/delete/${id}/Individual`);
};
