// Models
import { EntityDetails } from 'app/modules/entities/models';

// Helpers

// Caution: Do not change, as it would cause errors in dropdown selector for EntityList table
export const ENTITIES_PAGE_LIMIT = 25;

export const LABEL_PREDEFINED_COLORS = {
  USER: '#f4a263',
  BUSINESS: '#2c7ca9',
  EXTERNAL: '#965286',
  INTERNAL: '#ddab93',
  OPEN: '#21ba45',
  CLOSED: '#c03c3c',
  ACTIVE: '#52a766',
  INACTIVE: '#c03c3c',
  ALLOWED: '#52a766',
  WATCHLISTED: '#f34949',
  BLOCKED: '#dc4444',
  PLACEHOLDER: '#7f9f87',
  SPOOLED: '#7f9f87',
  TRUE_POSITIVE: '#21ba45',
  FALSE_POSITIVE: '#f34949',
  COMPLETE: '#21ba45',
  FAILED: '#e84b1c',
  GENERATING_VALIDATION_ALERTS: '#23ad9d',
  PENDING: '#43425d',
  QUERY_TIMEOUT: '#e84b1c',
};

export const EMPTY_ENTITY: EntityDetails = {
  id: -1,
  risk_score: 0,
  name_readable: '',
  status: '',
  internal_entity_type: '',
  created_at: '',
  external_id: '',
  entity_type: 'USER',
  type: '',
  paginated_alerts: {
    count: 0,
    alerts: [],
  },
  tags: [],
  comments: [],
  whitelists: {
    id: -1,
    external_id: '',
    active_whitelists: 0,
  },
  internal_status: '',
  formatted_data: {},
};
