import { DEVTOOLS_SLICE_NAME } from 'app/modules/devtools/sliceDevtools';

const DEFAULT_CURRENT_ROUTE_PERMISSIONS = [];

export const selectAllUsedPermissions = (state: RootState) =>
  state[DEVTOOLS_SLICE_NAME].allPermissions;

export const selectCurrentRouteUsedPermissions = (state: RootState) =>
  state[DEVTOOLS_SLICE_NAME].perRoutePermissions[window.location.pathname] ||
  DEFAULT_CURRENT_ROUTE_PERMISSIONS;
