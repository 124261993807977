// Thunk imports
import { PayloadAction } from '@reduxjs/toolkit';
import { u21CreateAsyncThunk } from 'app/shared/thunk/u21CreateAsyncThunk';
import { u21CreateSlice } from 'app/shared/thunk/u21CreateSlice';

// Models
import { GoogleDriveDoc } from 'app/modules/uploads/models';
import { InvestigationType } from 'app/modules/investigations/models';
import { UploadedFile } from 'app/shared/models';

// Types
import { InvestigationActionPayload } from 'app/modules/investigations/types/requests';
import { InvestigationsAlertResponse } from 'app/modules/investigations/types/responses';

// Actions
import {
  addDocumentsSuccess as addDocumentsToCaseSuccess,
  deleteDocumentSuccess as deleteDocumentFromCaseSuccess,
} from 'app/modules/casesOld/actions';
import {
  addDocumentsSuccess as addDocumentsToAlertSuccess,
  deleteDocumentSuccess as deleteDocumentFromAlertSuccess,
} from 'app/modules/alerts/actions';
import {
  sendErrorToast,
  sendSuccessToast,
  sendInfoToast,
} from 'app/shared/toasts/actions';

// Selectors
import { selectIDP } from 'app/modules/session/selectors';
import { selectGDriveFolderID } from 'app/modules/orgSettings/selectors';

// API
import {
  addDocumentsToCase as addGDriveDocumentsToCase,
  addFiles as addS3DocumentsToCase,
  deleteDocumentFromCase,
} from 'app/shared/api/cases';
import {
  addDocuments as addGDriveDocumentsToAlert,
  deleteDocument as deleteDocumentFromAlert,
  uploadFile as addS3DocumentsToAlert,
} from 'app/shared/api/alerts';
import { performInvestigationAction } from 'app/modules/investigations/api';
import { uploadToGoogleDrive } from 'app/shared/utils/uploadFiles';

// Utils
import { createGDriveDocPayload } from 'app/modules/googleDrive/utils';
import pluralize from 'pluralize';
import { generateWarningFromDeleteAttachmentResponse } from 'app/modules/investigations/helpers';
import { queryClient } from 'app/cache/queryClient';
import { ALERT_QUERY_KEYS } from 'app/modules/alerts/queries/keys';
import { CASE_QUERY_KEYS } from 'app/modules/cases/queries/keys';

const INVESTIGATIONS_NAME = 'investigations';

interface InvestigationsState {
  loadingReassignInvestigations: boolean;
  loadingRequeueInvestigations: boolean;
  loadingResolveInvestigations: boolean;
  loadingAddGoogleDocs: boolean;
  loadingAddS3Docs: boolean;
  loadingUploadGoogleDocs: boolean;
}

const initialState: InvestigationsState = {
  loadingReassignInvestigations: false,
  loadingRequeueInvestigations: false,
  loadingResolveInvestigations: false,
  loadingAddGoogleDocs: false,
  loadingAddS3Docs: false,
  loadingUploadGoogleDocs: false,
};

export const addS3DocsThunk = u21CreateAsyncThunk<
  {
    files: File[];
    id: number;
    type: InvestigationType;
  },
  void
>(
  `${INVESTIGATIONS_NAME}/ADD_S3_DOCS`,
  async ({ files, id, type }, { dispatch }) => {
    const label = pluralize('document', files.length);
    const payload = { id: String(id), files };
    try {
      if (type === InvestigationType.ALERT) {
        const response = await addS3DocumentsToAlert(payload);
        dispatch(addDocumentsToAlertSuccess(response));
      } else {
        const response = await addS3DocumentsToCase(payload);
        dispatch(addDocumentsToCaseSuccess(response));
      }
      dispatch(sendSuccessToast(`Successfully added ${label}.`));
    } catch (e) {
      dispatch(sendErrorToast(`Failed to add ${label}. Please try again.`));
      throw e;
    }
  },
);

export const addGoogleDocsThunk = u21CreateAsyncThunk(
  `${INVESTIGATIONS_NAME}/ADD_GOOGLE_DOCS`,
  async (
    {
      gdriveDocs,
      id,
      type,
    }: {
      gdriveDocs: GoogleDriveDoc[];
      id: number;
      type: InvestigationType;
    },
    { dispatch },
  ) => {
    const label = pluralize('documents', gdriveDocs.length);
    try {
      if (type === InvestigationType.ALERT) {
        const response = await addGDriveDocumentsToAlert({
          alertId: id,
          documents: gdriveDocs,
        });
        dispatch(addDocumentsToAlertSuccess(response));
        queryClient.invalidateQueries({
          queryKey: ALERT_QUERY_KEYS.getAlert(id),
        });
      } else {
        const response = await addGDriveDocumentsToCase({
          caseId: id,
          documents: gdriveDocs,
        });
        dispatch(addDocumentsToCaseSuccess(response));
        queryClient.invalidateQueries({
          queryKey: CASE_QUERY_KEYS.getCase(id),
        });
      }
    } catch (e) {
      dispatch(sendErrorToast(`Failed to add ${label}. Please try again.`));
      throw e;
    }
  },
);

export const uploadAndAddGoogleDocsThunk = u21CreateAsyncThunk(
  `${INVESTIGATIONS_NAME}/UPLOAD_AND ADD_GOOGLE_DOCS`,
  async (
    { files, id, type }: { files: File[]; id: number; type: InvestigationType },
    { dispatch, getState },
  ) => {
    const { access_token: accessToken } = selectIDP(getState());
    const gdriveFolder = selectGDriveFolderID(getState());

    // manually set loading to true
    dispatch(setLoadingUploadGoogleDocs(true));
    files.forEach((i, idx) => {
      uploadToGoogleDrive(
        i,
        gdriveFolder,
        accessToken,
        (_, uploadedFile: UploadedFile) => {
          const gdriveDocs: GoogleDriveDoc[] = [
            createGDriveDocPayload(i, uploadedFile),
          ];
          dispatch(addGoogleDocsThunk({ id, gdriveDocs, type }));

          // manually set loading to false if it's the last file
          if (idx === files.length - 1) {
            dispatch(setLoadingUploadGoogleDocs(false));
          }
        },
        () => {
          if (idx === files.length - 1) {
            dispatch(setLoadingUploadGoogleDocs(false));
          }
          dispatch(sendErrorToast(`Failed to upload ${i.name}`));
        },
        i.name,
      );
    });
  },
);

export const deleteDocumentThunk = u21CreateAsyncThunk<
  { id: number; docID: number; type: InvestigationType },
  void
>(
  `${INVESTIGATIONS_NAME}/DELETE_DOCUMENT`,
  async ({ docID, id, type }, { dispatch }) => {
    try {
      let warning: string = '';
      if (type === InvestigationType.ALERT) {
        const response = await deleteDocumentFromAlert({
          alertId: id,
          docId: docID,
        });
        warning = generateWarningFromDeleteAttachmentResponse(docID, response);
        dispatch(deleteDocumentFromAlertSuccess(response));
      } else {
        const response = await deleteDocumentFromCase({
          caseId: id,
          docId: docID,
        });
        warning = generateWarningFromDeleteAttachmentResponse(docID, response);
        dispatch(deleteDocumentFromCaseSuccess(response));
      }
      dispatch(sendSuccessToast('Successfully deleted document.'));
      if (warning) {
        dispatch(sendInfoToast(warning));
      }
    } catch (e) {
      dispatch(sendErrorToast('Failed to delete document. Please try again'));
      throw e;
    }
  },
);

export const reassignInvestigationsThunk = u21CreateAsyncThunk(
  `${INVESTIGATIONS_NAME}/REASSIGN_INVESTIGATIONS`,
  async (
    {
      payload,
      onSuccess,
    }: {
      payload: InvestigationActionPayload;
      onSuccess?: (response: InvestigationsAlertResponse) => void;
    },
    { dispatch },
  ) => {
    const { object_type: type } = payload;
    const text = type === InvestigationType.ALERT ? 'alerts' : 'cases';
    try {
      const response = await performInvestigationAction(payload);
      onSuccess?.(response);
      dispatch(sendSuccessToast(`Successfully re-assigned ${text}.`));
    } catch (e) {
      dispatch(sendErrorToast(`Failed to re-assign ${text}.`));
      throw e;
    }
  },
);

export const requeueInvestigationsThunk = u21CreateAsyncThunk(
  `${INVESTIGATIONS_NAME}/REQUEUE_INVESTIGATIONS`,
  async (
    {
      payload,
      onSuccess,
    }: {
      payload: InvestigationActionPayload;
      onSuccess: (response: InvestigationsAlertResponse) => void;
    },
    { dispatch },
  ) => {
    const { object_type: type } = payload;
    const text = type === InvestigationType.ALERT ? 'alerts' : 'cases';
    try {
      const response = await performInvestigationAction(payload);
      onSuccess(response);
      dispatch(sendSuccessToast(`Successfully changed ${text} queues.`));
    } catch (e) {
      dispatch(sendErrorToast(`Failed to change ${text} queues.`));
      throw e;
    }
  },
);

export const resolveInvestigationsThunk = u21CreateAsyncThunk(
  `${INVESTIGATIONS_NAME}/RESOLVE_INVESTIGATIONS`,
  async (
    {
      payload,
      onSuccess,
    }: {
      payload: InvestigationActionPayload;
      onSuccess: (response: InvestigationsAlertResponse) => void;
    },
    { dispatch },
  ) => {
    const { object_type: type } = payload;
    const text = type === InvestigationType.ALERT ? 'alerts' : 'cases';
    try {
      const response = await performInvestigationAction(payload);
      onSuccess(response);
      dispatch(sendSuccessToast(`Successfully actioned ${text}.`));
    } catch (e) {
      dispatch(sendErrorToast(`Failed to action ${text}.`));
      throw e;
    }
  },
);

const investigationsSlice = u21CreateSlice({
  initialState,
  name: INVESTIGATIONS_NAME,
  reducers: {
    setLoadingUploadGoogleDocs: (draft, action: PayloadAction<boolean>) => {
      draft.loadingUploadGoogleDocs = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addLoadingCase(addS3DocsThunk, 'loadingAddS3Docs')
      .addCase(reassignInvestigationsThunk.pending, (draft) => {
        draft.loadingReassignInvestigations = true;
      })
      .addCase(reassignInvestigationsThunk.fulfilled, (draft) => {
        draft.loadingReassignInvestigations = false;
      })
      .addCase(reassignInvestigationsThunk.rejected, (draft) => {
        draft.loadingReassignInvestigations = false;
      })
      .addCase(requeueInvestigationsThunk.pending, (draft) => {
        draft.loadingRequeueInvestigations = true;
      })
      .addCase(requeueInvestigationsThunk.fulfilled, (draft) => {
        draft.loadingRequeueInvestigations = false;
      })
      .addCase(requeueInvestigationsThunk.rejected, (draft) => {
        draft.loadingRequeueInvestigations = false;
      })
      .addCase(resolveInvestigationsThunk.pending, (draft) => {
        draft.loadingResolveInvestigations = true;
      })
      .addCase(resolveInvestigationsThunk.fulfilled, (draft) => {
        draft.loadingResolveInvestigations = false;
      })
      .addCase(resolveInvestigationsThunk.rejected, (draft) => {
        draft.loadingResolveInvestigations = false;
      })
      .addCase(addGoogleDocsThunk.pending, (draft) => {
        draft.loadingAddGoogleDocs = true;
      })
      .addCase(addGoogleDocsThunk.fulfilled, (draft) => {
        draft.loadingAddGoogleDocs = false;
      })
      .addCase(addGoogleDocsThunk.rejected, (draft) => {
        draft.loadingAddGoogleDocs = false;
      });
  },
});

export const INVESTIGATIONS_SLICE_NAME = investigationsSlice.name;
export const { setLoadingUploadGoogleDocs } = investigationsSlice.actions;
export default investigationsSlice.reducer;
