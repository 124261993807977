export default {
  awaitingTrigger: 'AWAITING_TRIGGER',
  validationPending: 'VALIDATION_PENDING',
  validationFailed: 'VALIDATION_FAILED',
  processPending: 'PROCESS_PENDING',
  processSuccess: 'PROCESS_SUCCESS',
  processFailed: 'PROCESS_FAILED',
  processAborted: 'PROCESS_ABORTED',
  otherFailed: 'OTHER_FAILED',
  uploading: 'UPLOADING',
};

export const uploadStatusLabel = {
  AWAITING_TRIGGER: 'Awaiting Trigger',
  VALIDATION_PENDING: 'Validation Pending',
  VALIDATION_FAILED: 'Validation Failed',
  PROCESS_PENDING: 'Process Pending',
  PROCESS_SUCCESS: 'Process Success',
  PROCESS_FAILED: 'Upload Failed',
  PROCESS_ABORTED: 'Upload Aborted',
  OTHER_FAILED: 'Other Failed',
  UPLOADING: 'Uploading',
};
