// Types
import {
  GetRelationshipConfigsPayload,
  EditRelationshipConfigPayload,
  CreateRelationshipConfigPayload,
} from 'app/modules/relationships/types';

// Utils
import { get, post } from 'app/shared/utils/fetchr';

export const retrieveRelationshipConfig = (relationshipConfigID: string) => {
  return get(`/relationship-configs/${relationshipConfigID}`);
};

export const retrieveRelationshipConfigs = (
  payload: GetRelationshipConfigsPayload,
) => {
  return post('/relationship-configs/list', payload);
};

export const editRelationshipConfig = (
  payload: EditRelationshipConfigPayload,
) => {
  return post('/relationship-configs/edit', payload);
};

export const createRelationshipConfig = (
  payload: CreateRelationshipConfigPayload,
) => {
  return post('/relationship-configs/create', payload);
};
