import { FullTxnInstrumentResponse } from 'app/modules/txnInstruments/types';
import { INSTRUMENT_QUERY_KEYS } from 'app/modules/instruments/queries/keys';
import { get } from 'app/shared/utils/fetchr';
import { useQuery } from '@tanstack/react-query';

export const useInstrumentDetails = <TSelect = FullTxnInstrumentResponse>(
  instrumentId: string,
  select?: (data: FullTxnInstrumentResponse) => TSelect,
  enabled = true,
) => {
  return useQuery({
    queryFn: () =>
      get<FullTxnInstrumentResponse>(`/instruments/${instrumentId}`),
    queryKey: INSTRUMENT_QUERY_KEYS.getInstrumentDetails(instrumentId),
    select,
    enabled: Boolean(instrumentId) && enabled,
  });
};
