import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { CustomChecklistActionTypes } from 'app/shared/customChecklist/models';

// Actions
import {
  retrieveCustomChecklist as retrieveCustomChecklistAction,
  retrieveCustomChecklistSuccess,
} from 'app/shared/customChecklist/actions';

// API
import { getCustomChecklist as getCustomChecklistApi } from 'app/shared/customChecklist/api';

const rootAction = CustomChecklistActionTypes.RETRIEVE_CUSTOM_CHECKLIST;
export function* retrieveCustomChecklist({
  payload,
}: ReturnType<typeof retrieveCustomChecklistAction>) {
  const config = {
    rootAction,
    request: call(getCustomChecklistApi, payload),
    success: function* onSuccess(result) {
      yield put(retrieveCustomChecklistSuccess(result));
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watchRetrieveCustomChecklist() {
  yield takeLatest(rootAction, retrieveCustomChecklist);
}
