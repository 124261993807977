import { createAction, ActionsUnion } from 'app/shared/utils/action-helpers';

// Models
import {
  BlacklistActionTypes,
  Blacklist,
  BlacklistItem,
} from 'app/modules/lists/models';
import {
  CreateBlacklistPayload,
  RetrieveBlacklistItemsPayload,
  EditBlacklistPayload,
  DeactivateBlacklistItemPayload,
  AddBlacklistItemPayload,
  RetrieveBlacklistsPayload,
  AddUserBlacklistItemPayload,
  AddBusinessBlacklistItemPayload,
  AppendFileToBlacklistPayload,
} from 'app/modules/lists/requests';
import {
  RetrieveBlacklistsResponse,
  RetrieveBlacklistItemsResponse,
} from 'app/modules/lists/responses';

export const retrieveBlacklists = (payload: RetrieveBlacklistsPayload) =>
  createAction(BlacklistActionTypes.RETRIEVE_BLACKLISTS, payload);

export const retrieveBlacklistsSuccess = (result: RetrieveBlacklistsResponse) =>
  createAction(BlacklistActionTypes.RETRIEVE_BLACKLISTS_SUCCESS, result);

export const createBlacklist = (payload: CreateBlacklistPayload) =>
  createAction(BlacklistActionTypes.CREATE_BLACKLIST, payload);

export const createBlacklistSuccess = (result: Blacklist) =>
  createAction(BlacklistActionTypes.CREATE_BLACKLIST_SUCCESS, result);

export const retrieveBlacklist = (id: string) =>
  createAction(BlacklistActionTypes.RETRIEVE_BLACKLIST, id);

export const retrieveBlacklistSuccess = (result: Blacklist) =>
  createAction(BlacklistActionTypes.RETRIEVE_BLACKLIST_SUCCESS, result);

export const retrieveBlacklistItems = (
  payload: RetrieveBlacklistItemsPayload,
) => createAction(BlacklistActionTypes.RETRIEVE_BLACKLIST_ITEMS, payload);

export const retrieveBlacklistItemsSuccess = (
  result: RetrieveBlacklistItemsResponse,
) =>
  createAction(BlacklistActionTypes.RETRIEVE_BLACKLIST_ITEMS_SUCCESS, result);

export const editBlacklist = (payload: EditBlacklistPayload) =>
  createAction(BlacklistActionTypes.EDIT_BLACKLIST, payload);

export const editBlacklistSuccess = (result: Blacklist) =>
  createAction(BlacklistActionTypes.EDIT_BLACKLIST_SUCCESS, result);

export const deactivateBlacklistItem = (
  payload: DeactivateBlacklistItemPayload,
) => createAction(BlacklistActionTypes.DEACTIVATE_BLACKLIST_ITEM, payload);

export const deactivateBlacklistItemSuccess = (result: BlacklistItem) =>
  createAction(BlacklistActionTypes.DEACTIVATE_BLACKLIST_ITEM_SUCCESS, result);

export const addBlacklistItem = (payload: AddBlacklistItemPayload) =>
  createAction(BlacklistActionTypes.ADD_BLACKLIST_ITEM, payload);

export const addBlacklistItemSuccess = (result: BlacklistItem) =>
  createAction(BlacklistActionTypes.ADD_BLACKLIST_ITEM_SUCCESS, result);

export const addUserBlacklistItem = (payload: AddUserBlacklistItemPayload) =>
  createAction(BlacklistActionTypes.ADD_USER_BLACKLIST_ITEM, payload);

export const addUserBlacklistItemSuccess = (result) =>
  createAction(BlacklistActionTypes.ADD_USER_BLACKLIST_ITEM_SUCCESS, result);

export const addBusinessBlacklistItem = (
  payload: AddBusinessBlacklistItemPayload,
) => createAction(BlacklistActionTypes.ADD_BUSINESS_BLACKLIST_ITEM, payload);

export const addBusinessBlacklistItemSuccess = (result) =>
  createAction(
    BlacklistActionTypes.ADD_BUSINESS_BLACKLIST_ITEM_SUCCESS,
    result,
  );

export const appendFileToBlacklist = (payload: AppendFileToBlacklistPayload) =>
  createAction(BlacklistActionTypes.APPEND_FILE_TO_BLACKLIST, payload);

export const appendFileToBlacklistSuccess = (result) =>
  createAction(BlacklistActionTypes.APPEND_FILE_TO_BLACKLIST_SUCCESS, result);

const BlacklistActionsList = {
  retrieveBlacklists,
  retrieveBlacklistsSuccess,
  createBlacklist,
  createBlacklistSuccess,
  retrieveBlacklist,
  retrieveBlacklistSuccess,
  retrieveBlacklistItems,
  retrieveBlacklistItemsSuccess,
  editBlacklist,
  editBlacklistSuccess,
  deactivateBlacklistItem,
  deactivateBlacklistItemSuccess,
  addBlacklistItem,
  addBlacklistItemSuccess,
  addUserBlacklistItem,
  addUserBlacklistItemSuccess,
  addBusinessBlacklistItem,
  addBusinessBlacklistItemSuccess,
  appendFileToBlacklist,
  appendFileToBlacklistSuccess,
};

type BlacklistActions = ActionsUnion<typeof BlacklistActionsList>;

export default BlacklistActions;
