import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { AlertsActionTypes } from 'app/modules/alerts/models';

// Actions
import {
  retrieveAlertHistogram as retrieveAlertHistogramAction,
  retrieveAlertHistogramSuccess,
} from 'app/modules/alerts/actions';

// API
import { getAlertHistogram } from 'app/shared/api/alerts';
import { transformHistogramResponse } from 'app/modules/histogram/utils';

const rootAction = AlertsActionTypes.RETRIEVE_ALERT_HISTOGRAM;
function* retrieveAlertHistogram({
  payload,
}: ReturnType<typeof retrieveAlertHistogramAction>) {
  const config = {
    rootAction,
    request: call(getAlertHistogram, payload),
    success: function* onSuccess(result) {
      yield put(
        retrieveAlertHistogramSuccess(transformHistogramResponse(result)),
      );
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watchRetrieveAlertHistogram() {
  yield takeLatest(rootAction, retrieveAlertHistogram);
}
