import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { GoAMLActionTypes } from 'app/modules/goAML/models';

// Actions
import { sendSuccessToast, sendErrorToast } from 'app/shared/toasts/actions';
import {
  validateGoAMLFiling as validateGoAMLFilingAction,
  validateGoAMLFilingSuccess,
} from 'app/modules/goAML/actions';

// API
import { validateGoAMLFiling as validateGoAMLFilingAPI } from 'app/shared/api/goAML';

const rootAction = GoAMLActionTypes.VALIDATE_GOAML_FILING;
export function* validateGoAMLFiling({
  payload,
}: ReturnType<typeof validateGoAMLFilingAction>) {
  const config = {
    rootAction,
    errorToastMessage: 'Something went wrong, unable to validate GoAML report.',
    request: call(validateGoAMLFilingAPI, payload),
    success: function* onSuccess(
      result: ReturnType<typeof validateGoAMLFilingSuccess>['payload'],
    ) {
      yield put(validateGoAMLFilingSuccess(result));
      if (result.error_msgs?.length) {
        yield put(
          sendErrorToast(
            'There are errors in the form. Please fix them before continuing.',
          ),
        );
      } else {
        yield put(sendSuccessToast(`Successfully validated GoAML report.`));
      }
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watch() {
  yield takeLatest(rootAction, validateGoAMLFiling);
}
