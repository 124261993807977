import { INSTRUMENTS_SLICE_NAME } from 'app/modules/instruments/sliceInstruments';
import { TabConfig, TabConfigType } from 'app/shared/CustomConfig/models';

export const selectFilters = (state: RootState) =>
  state[INSTRUMENTS_SLICE_NAME].filters;

export const selectInstrumentCustomTabs = (
  state: RootState,
): TabConfig[] | undefined => {
  return state.tableConfig.configs[TabConfigType.INSTRUMENT_DETAILS_TAB]
    ?.definition;
};
