import retrieveGoAMLFiling from 'app/modules/goAML/sagas/retrieveGoAMLFiling';
import retrieveGoAMLFilings from 'app/modules/goAML/sagas/retrieveGoAMLFilings';
import createGoAMLFiling from 'app/modules/goAML/sagas/createGoAMLFiling';
import editGoAMLFiling from 'app/modules/goAML/sagas/editGoAMLFiling';
import exportGoAMLFiling from 'app/modules/goAML/sagas/exportGoAMLFiling';
import validateGoAMLFiling from 'app/modules/goAML/sagas/validateGoAMLFiling';
import deleteGoAMLAttachment from 'app/modules/goAML/sagas/deleteGoAMLAttachment';
import addGoAMLAttachments from 'app/modules/goAML/sagas/addGoAMLAttachments';

const sagaRegistry = [
  createGoAMLFiling,
  editGoAMLFiling,
  retrieveGoAMLFiling,
  retrieveGoAMLFilings,
  exportGoAMLFiling,
  validateGoAMLFiling,
  deleteGoAMLAttachment,
  addGoAMLAttachments,
];

export default sagaRegistry;
