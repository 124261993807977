import { get, post } from 'app/shared/utils/fetchr';
import routes from 'app/shared/utils/routes';
import { PaginatedTeamsPayload } from 'app/modules/teamsOld/models';

export const getAllTags = () => get(routes.patronus.tagsList);

export const addTagAPI = (payload: { tag: string }) => {
  return post(routes.patronus.tagsAdd, payload);
};

export const getAPIConfig = () => {
  return get(routes.patronus.orgsAPIConfig);
};

export const retrieveDropdownTeams = (payload: PaginatedTeamsPayload) =>
  post(routes.patronus.teamsAutocomplete, payload);
