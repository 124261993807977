import { CreateTagPayload, DeleteTagPayload } from 'app/modules/tags/requests';
import { FullTagResponse } from 'app/modules/tags/responses';

import { get, post } from 'app/shared/utils/fetchr';

const API_BASE = '/tags';

export const retrieveTags = (): Promise<FullTagResponse[]> => {
  return get(`${API_BASE}/list`);
};

export const createTag = (
  payload: CreateTagPayload,
): Promise<FullTagResponse> => {
  return post(`${API_BASE}/add`, payload);
};

export const deleteTag = (payload: DeleteTagPayload) => {
  return post(`${API_BASE}/delete`, payload);
};
