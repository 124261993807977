import { get, post, destroy, put } from 'app/shared/utils/fetchr';

// Utils
import routes, { pathJoin } from 'app/shared/utils/routes';

// Models
import {
  CreateWebhookRequest,
  EditWebhookRequest,
} from 'app/modules/webhooks/models';

export const getWebhooks = () => {
  return get(routes.patronus.webhooksList);
};

export const addWebhook = (payload: CreateWebhookRequest) => {
  const path = routes.patronus.webhooksCreate;
  return post(path, payload);
};

export const retrieveWebhook = (id) => {
  return get(pathJoin(routes.patronus.webhooksId.replace(':id', id)));
};

export const retrieveActionTypes = () => {
  return get(routes.patronus.webhookActionTypesList);
};

export const removeWebhook = (id) => {
  return destroy(pathJoin(routes.patronus.webhooksDeleteId.replace(':id', id)));
};

export const testWebhook = (id: number) => {
  return post(routes.patronus.webhooksTestId.replace(':id', String(id)));
};

export const changeWebhook = (payload: EditWebhookRequest) => {
  const { id, ...reqBody } = payload;
  return put(
    pathJoin(routes.patronus.webhooksEditId.replace(':id', String(id))),
    reqBody,
  );
};

export const rotateWebhook = (id) => {
  return post(
    pathJoin(routes.patronus.webhooksRotateId.replace(':id', String(id))),
  );
};

export const listWebhookCallbacks = (id: number, reqBody) => {
  return post(
    routes.patronus.webhooksIdCallbacks.replace(':id', String(id)),
    reqBody,
  );
};
