import { TextFieldProps } from '@mui/material';
import {
  FocusEventHandler,
  HTMLProps,
  ReactNode,
  RefObject,
  SyntheticEvent,
} from 'react';

export enum TYPES {
  NUMBER = 'number',
  PASSWORD = 'password',
  TEXT = 'text',
  TEXTAREA = 'textarea',
  INTEGER = 'integer',
}

export interface U21EncryptedFieldProps {
  iframeHost: string;
  authTokenFetcher: () => string;
  orgId: number;
  fieldType: string;
  id: string;
}

export const INVALID_KEYS = ['e', 'E', '+'];
export interface U21TextFieldProps
  extends Omit<
    HTMLProps<HTMLDivElement>,
    'onBlur' | 'onChange' | 'size' | 'label'
  > {
  autoFocus?: boolean;
  clearable?: boolean;
  delay?: number;
  disabled?: boolean;
  endIcon?: ReactNode;
  error?: boolean;
  focused?: boolean;
  id?: string;
  InputLabelProps?: { [key: string]: any };
  inputProps?: TextFieldProps['inputProps'];
  InputProps?: TextFieldProps['InputProps'];
  inputRef?: RefObject<HTMLInputElement>;
  label?: ReactNode;
  loading?: boolean;
  minRows?: number;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onChange: (value: number | string | undefined, e: SyntheticEvent) => void;
  placeholder?: string;
  ref?: RefObject<HTMLDivElement>;
  required?: boolean;
  responsiveLength?: boolean;
  size?: 'small' | 'medium';
  startIcon?: ReactNode;
  textTransform?: 'uppercase' | 'lowercase';
  type?: `${TYPES}`;
  value?: number | string;
  valueTransform?: (
    value: number | string | undefined,
  ) => number | string | undefined;
}

export const INPUT_TYPES = {
  [TYPES.NUMBER]: 'number',
  [TYPES.PASSWORD]: 'password',
  [TYPES.TEXT]: 'text',
  [TYPES.TEXTAREA]: 'text',
  [TYPES.INTEGER]: 'number',
};
