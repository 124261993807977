import { call, put, takeEvery } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { DropdownActionTypes } from 'app/shared/dropdown/models';

// Actions
import {
  retrieveDropdownTeams as retrieveDropdownTeamsAction,
  retrieveDropdownTeamsSuccess,
} from 'app/shared/dropdown/actions';

// API
import { retrieveDropdownTeams as retrieveDropdownTeamsAPI } from 'app/shared/api/team';

const rootAction = DropdownActionTypes.RETRIEVE_DROPDOWN_TEAMS;
function* retrieveDropdownTeams({
  payload,
}: ReturnType<typeof retrieveDropdownTeamsAction>) {
  const config = {
    rootAction,
    request: call(retrieveDropdownTeamsAPI, payload),
    success: function* onSuccess(
      result: ReturnType<typeof retrieveDropdownTeamsSuccess>['payload'],
    ) {
      yield put(retrieveDropdownTeamsSuccess(result));
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watchRetrieveDropdownTeams() {
  yield takeEvery(rootAction, retrieveDropdownTeams);
}
