import { SearchRequest } from 'app/modules/search/models';

import { INFINITE_SCROLL_LIMIT, OFFSET_DEFAULT } from 'app/shared/constants';

import { alertsSearch } from 'app/modules/search/actions';
import { identityFunction } from 'app/shared/utils/function';
import { selectAlertOptions } from 'app/modules/search/selectors';
import { selectHasReadAlertsPermission } from 'app/modules/session/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useRef } from 'react';

import { IconFlag } from '@u21/tabler-icons';
import {
  U21SelectOptionProps,
  U21SelectSearch,
  U21SelectSearchProps,
} from 'app/shared/u21-ui/components';

interface Props {
  getPayload?: (payload: SearchRequest) => SearchRequest;
  selector?: (state: RootState) => U21SelectOptionProps<number>[];
}

export type U21AlertSelectProps<TClearable extends boolean> = DistributiveOmit<
  U21SelectSearchProps<number, TClearable>,
  'options' | 'onSearch'
> &
  Props;

export const U21AlertSelect = <TClearable extends boolean>(
  props: U21AlertSelectProps<TClearable>,
) => {
  const {
    getPayload = identityFunction,
    selector = selectAlertOptions,
    clearable,
    ...rest
  } = props;
  const dispatch = useDispatch();
  const options = useSelector(selector);
  const hasReadAlertsPermission = useSelector(selectHasReadAlertsPermission);

  const { value = [] } = props;
  const values = useMemo(
    () => (Array.isArray(value) ? value : [value]),
    [value],
  ) as number[];

  // calledRef is used to prevent an infinite loop in case the API never returns with the missing value
  const calledRef = useRef(false);
  useEffect(() => {
    calledRef.current = false;
  }, [values]);

  const getPayloadRef = useRef(getPayload);
  getPayloadRef.current = getPayload;
  useEffect(() => {
    if (hasReadAlertsPermission) {
      const missingValues = values.filter(
        (i) => !options.find((j) => j.value === i),
      );
      if (missingValues.length && !calledRef.current) {
        calledRef.current = true;
        dispatch(
          alertsSearch(
            getPayloadRef.current({
              ids: missingValues,
              limit: missingValues.length,
              offset: OFFSET_DEFAULT,
              phrase: '',
            }),
          ),
        );
      }
    }
  }, [dispatch, hasReadAlertsPermission, options, values]);

  if (!hasReadAlertsPermission) {
    return null;
  }

  return (
    <U21SelectSearch
      options={options}
      clearable={clearable}
      onSearch={(phrase = '') =>
        dispatch(
          alertsSearch(
            getPayload({
              limit: INFINITE_SCROLL_LIMIT,
              offset: OFFSET_DEFAULT,
              phrase,
            }),
          ),
        )
      }
      startIcon={<IconFlag />}
      {...rest}
    />
  );
};
