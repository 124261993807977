import { TableInstance } from 'react-table';

// Styling
import styled, { css } from 'styled-components';
import { useTheme } from '@mui/material/styles';
import { PINNED_SHADOW_STYLE } from 'app/shared/u21-ui/components/display/table/styles';

// Components
import { IconChevronDown, IconChevronUp } from '@u21/tabler-icons';
import { U21Divider } from 'app/shared/u21-ui/components/display/U21Divider';
import { U21Spacer } from 'app/shared/u21-ui/components/layout/U21Spacer';
import { U21Typography } from 'app/shared/u21-ui/components/display/typography/U21Typography';

// Models
import { PinType } from 'app/shared/u21-ui/components/display/table/hooks/models';
import { U21TableProps } from 'app/shared/u21-ui/components/display/table/models';

// Utils
import { AnalyticsEvents, trackEvent } from 'app/shared/u21-ui/analytics';
import { getA11yClickProps } from 'app/shared/utils/a11y';

interface Props<T extends object, F> {
  tableProps: TableInstance<T>;
  userProps: U21TableProps<T, F>;
}

export const U21TableHeader = <T extends object, F>(props: Props<T, F>) => {
  const { tableProps, userProps } = props;
  const {
    firstRightPinnedColumn,
    headerGroups,
    isScrollable,
    lastLeftPinnedColumn,
  } = tableProps;

  const { palette } = useTheme();

  return (
    <>
      {headerGroups.map((i) => {
        const { getHeaderGroupProps, headers } = i;
        const { key: headerGroupKey, ...headerGroupProps } =
          getHeaderGroupProps();
        return (
          <HeaderRow key={headerGroupKey} {...headerGroupProps}>
            {headers.map((j, idx) => {
              const {
                canResize,
                getHeaderProps,
                getResizerProps,
                Header,
                headerIcon,
                id,
                isResizing,
                isSorted,
                isSortedDesc,
                noDivider,
                pinType,
                canSort,
                toggleSortBy,
              } = j;

              const { key: headerKey, ...headerProps } = getHeaderProps();
              return (
                <HeaderCell
                  $pinType={pinType}
                  $showShadow={
                    (id === lastLeftPinnedColumn ||
                      id === firstRightPinnedColumn) &&
                    isScrollable
                  }
                  key={headerKey}
                  {...headerProps}
                >
                  <StyledU21Spacer horizontal spacing={0.5}>
                    {(() => {
                      if (!Header) {
                        return null;
                      }
                      if (typeof Header === 'string') {
                        return (
                          <U21Typography
                            ellipsis
                            icon={headerIcon}
                            color={palette.text.secondary}
                            variant="subtitle2"
                          >
                            {Header}
                          </U21Typography>
                        );
                      }
                      return (
                        <Header tableProps={tableProps} userProps={userProps} />
                      );
                    })()}
                  </StyledU21Spacer>
                  {canSort && (
                    <SortArrowContainer $isSorted={isSorted}>
                      <SortArrow
                        $selected={isSortedDesc === false}
                        aria-label="Sort ascending"
                        {...getA11yClickProps(() => {
                          trackEvent(
                            AnalyticsEvents.U21SORT_CHANGED,
                            {},
                            {},
                            {
                              toAscending: true,
                              columnKey: id,
                              column: typeof Header === 'string' ? Header : '',
                            },
                          );
                          toggleSortBy(false);
                        })}
                      >
                        <IconChevronUp
                          stroke={isSortedDesc === false ? 3 : undefined}
                        />
                      </SortArrow>
                      <SortArrow
                        $selected={isSortedDesc === true}
                        aria-label="Sort descending"
                        {...getA11yClickProps(() => {
                          trackEvent(
                            AnalyticsEvents.U21SORT_CHANGED,
                            {},
                            {},
                            {
                              toAscending: false,
                              columnKey: id,
                              column: typeof Header === 'string' ? Header : '',
                            },
                          );
                          toggleSortBy(true);
                        })}
                      >
                        <IconChevronDown
                          stroke={isSortedDesc === true ? 3 : undefined}
                        />
                      </SortArrow>
                    </SortArrowContainer>
                  )}
                  {(() => {
                    if (canResize) {
                      return (
                        <StyledU21Divider
                          {...getResizerProps()}
                          $resizing={isResizing}
                          onClick={(e) => e.stopPropagation()}
                        />
                      );
                    }
                    // Don't render divider on last column if not resizable
                    // or if explicitly set to not do so
                    if (noDivider || idx === headers.length - 1) {
                      return null;
                    }
                    return <StyledU21Divider />;
                  })()}
                </HeaderCell>
              );
            })}
          </HeaderRow>
        );
      })}
    </>
  );
};

const SortArrowContainer = styled.div<{ $isSorted: boolean }>`
  display: ${(props) => (props.$isSorted ? 'flex' : 'none')};
  flex-direction: column;
  padding-right: 6px;
`;

const HeaderCell = styled.div<{
  $pinType: PinType;
  $showShadow: boolean;
}>`
  height: 54px;
  display: flex;
  align-items: center;
  padding: 6px 0 6px 6px;
  overflow: hidden;
  background-color: ${(props) => props.theme.palette.background.tableHeader};

  ${PINNED_SHADOW_STYLE}

  :hover ${SortArrowContainer} {
    display: flex;
  }
`;

const SortArrow = styled.div<{ $selected: boolean }>`
  display: flex;
  cursor: pointer;
  color: ${(props) =>
    props.$selected
      ? props.theme.palette.primary.main
      : props.theme.palette.text.secondary};

  :hover {
    ${(props) =>
      !props.$selected
        ? css`
            color: ${props.theme.palette.text.primary};
          `
        : css``};
  }
`;

const StyledU21Spacer = styled(U21Spacer)`
  flex: 1;
  overflow: hidden;
`;

const HeaderRow = styled.div`
  background-color: ${(props) => props.theme.palette.background.tableHeader};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  & > ${HeaderCell}:first-child {
    border-top-left-radius: 8px;
  }

  & > ${HeaderCell}:last-child {
    border-top-right-radius: 8px;
  }
`;

interface StyleProps {
  $resizing?: boolean;
  role?: 'separator';
}

const StyledU21Divider = styled(U21Divider)<StyleProps>`
  ${(props) =>
    props.role === 'separator' &&
    css`
      :hover {
        border-right-width: 3px;
        border-color: ${props.theme.palette.grey[500_48]};
      }
    `}

  ${(props) =>
    props.$resizing &&
    css`
      border-right-width: 3px;
      border-color: ${props.theme.palette.grey[500_48]};
    `}
  }
`;
