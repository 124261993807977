// @ts-ignore
import { JSONPath, PathComponent } from 'jsonpath';
import { MetadataType } from 'app/modules/dataMapping/responses';
import { fromUpperToSentenceCase } from 'app/shared/utils/string';

export const jsonKeyPathToString = (
  keyPath: PathComponent[] | string,
): string => {
  // string input received when keypath is not valid - return as is
  if (!Array.isArray(keyPath)) {
    return keyPath;
  }
  const formattedKeyPath = new JSONPath().stringify(keyPath);
  // jsonpath prepends key path arrays with $. to indicate root object
  return formattedKeyPath.startsWith('$.')
    ? formattedKeyPath.substring(2)
    : formattedKeyPath;
};

export const validJsonPath = (keyPath: PathComponent[] | string): boolean => {
  if (Array.isArray(keyPath)) {
    return true;
  }
  // avoid strings that are valid key paths but will cause unexpected jumps
  if (
    keyPath.startsWith('$.') ||
    keyPath.split('.').some((part) => !isNaN(Number(part)))
  ) {
    return false;
  }
  try {
    new JSONPath().parse(keyPath);
    return true;
  } catch {
    return false;
  }
};

export const FILE_METADATA_SELECT_VALUE_PREFIX = 'file_metadata/';

export const isFileMetadataValue = (
  selectValue: string | undefined,
): boolean => {
  if (selectValue === undefined) {
    return false;
  }
  return selectValue.startsWith(FILE_METADATA_SELECT_VALUE_PREFIX);
};

export const getFileMetadataSelectValue = (
  metadataType: MetadataType,
): string => {
  return `${FILE_METADATA_SELECT_VALUE_PREFIX}${metadataType}`;
};

export const getFileMetadataType = (
  fileMetadataSelectValue: string,
): MetadataType => {
  return fileMetadataSelectValue.substring(
    FILE_METADATA_SELECT_VALUE_PREFIX.length,
  ) as MetadataType;
};

export const getFileMetadataChipString = (
  metadataType: MetadataType,
): string => {
  return `File metadata / ${fromUpperToSentenceCase(metadataType)}`;
};
