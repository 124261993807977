import { u21CreateSlice } from 'app/shared/thunk/u21CreateSlice';
import { u21CreateAsyncThunk } from 'app/shared/thunk/u21CreateAsyncThunk';

import { getOrgAgents } from 'app/modules/impersonation/api';
import { AgentImpersonationState } from 'app/modules/impersonation/models';
import { SessionAgent } from 'app/modules/session/models';
import { PayloadAction } from '@reduxjs/toolkit';

const AGENT_IMPERSONATION_NAME = 'agentImpersonation';

export const initialState: Readonly<AgentImpersonationState> = {
  orgAgents: [],
  loadingOrgAgents: false,
};

export const getOrgAgentsThunk = u21CreateAsyncThunk<number, SessionAgent[]>(
  `${AGENT_IMPERSONATION_NAME}/GET_ORG_AGENTS`,
  getOrgAgents,
);

export const impersonationSlice = u21CreateSlice({
  name: AGENT_IMPERSONATION_NAME,
  initialState,
  reducers: {
    resetOrgAgents: (draft) => {
      draft.orgAgents = [];
      draft.loadingOrgAgents = false;
    },
  },
  extraReducers: (builder) => {
    builder.addLoadingCase(
      getOrgAgentsThunk,
      'loadingOrgAgents',
      (draft, action: PayloadAction<SessionAgent[]>) => {
        draft.orgAgents = action.payload;
      },
    );
  },
});

export const IMPERSONATION_SLICE_NAME = impersonationSlice.name;
export const { resetOrgAgents } = impersonationSlice.actions;
export default impersonationSlice.reducer;
