import { createSelector } from 'reselect';

import {
  selectHasReadAlertsPermission,
  selectHasReadAssignmentsPermissions,
  selectHasReadCasesPermissions,
  selectHasReadSARsPermissions,
} from 'app/modules/session/selectors';

export const selectRoutingHistory = (state: RootState) =>
  state.navigator.history || [];

export const selectAiChatPermissions = createSelector(
  selectHasReadAlertsPermission,
  selectHasReadCasesPermissions,
  selectHasReadSARsPermissions,
  selectHasReadAssignmentsPermissions,
  (
    hasReadAlertsPermissions,
    hasReadCasesPermissions,
    hasReadSarsPermissions,
    hasReadAssignmentsPermissions,
  ) => {
    if (
      hasReadAlertsPermissions ||
      hasReadCasesPermissions ||
      hasReadSarsPermissions ||
      hasReadAssignmentsPermissions
    ) {
      return true;
    }
    return false;
  },
);
