/* eslint-disable no-console */
import * as Sentry from '@sentry/react';
import { sessionReplay } from 'app/shared/utils/sentry';

export function consoleLog(arg, ...args) {
  if (process.env.NODE_ENV === 'development') {
    console.log(arg, ...args);
  }
}

export function consoleInfo(arg, ...args) {
  if (process.env.NODE_ENV === 'development') {
    console.info(arg, ...args);
  }
}

export function consoleDebug(arg, ...args) {
  if (process.env.NODE_ENV === 'development') {
    console.debug(arg, ...args);
  }
}

export function consoleWarn(arg, ...args) {
  if (process.env.NODE_ENV === 'development') {
    console.warn(arg, ...args);
  }
}

export function consoleError(arg, ...args) {
  if (process.env.NODE_ENV === 'development') {
    console.error(arg, ...args);
  } else {
    const error = [arg, ...args].find((i) => i instanceof Error);
    if (error) {
      Sentry.captureException(error);
    } else {
      Sentry.captureEvent({
        message: typeof arg === 'string' ? arg : JSON.stringify(arg),
        level: 'warning',
        extra: {
          ...(args.length ? { info: args } : {}),
          // sentry doesn't seem to connect replay with event so attach replay ID for manual reference
          replayID: sessionReplay.getReplayId(),
        },
      });
    }
    // send sentry replay
    sessionReplay.flush();
  }
}
