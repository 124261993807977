import { OrgSettingsResponse } from 'app/modules/orgSettings/responses';
import { UpdateGDriveFolderPayload } from 'app/modules/orgSettings/requests';

import { post } from 'app/shared/utils/fetchr';

export const updateGDriveFolder = (
  payload: UpdateGDriveFolderPayload,
): Promise<OrgSettingsResponse> => {
  return post('/orgs/integration/settings', payload);
};
