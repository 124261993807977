import { HTMLProps } from 'react';

import { getDOMProps } from 'app/shared/utils/react';
import styled, { css } from 'styled-components';

import {
  IconCircleCheckFilled,
  IconDownload,
  IconTrash,
} from '@u21/tabler-icons';
import { U21Button } from 'app/shared/u21-ui/components/input/U21Button';
import { U21Loading } from 'app/shared/u21-ui/components/display/U21Loading';
import { U21Progress } from 'app/shared/u21-ui/components/display/U21Progress';
import { U21Typography } from 'app/shared/u21-ui/components/display/typography/U21Typography';
import { alpha } from '@mui/system/colorManipulator';

interface Uploading {
  state: 'uploading';
  onCancel?: () => void;
  progress: number;
}

interface Finished {
  onDelete?: () => void;
  onDownload?: () => void;
  state: 'finished';
}

interface Downloading {
  state: 'downloading';
}

interface Deleting {
  state: 'deleting';
}

interface UploadError {
  state: 'error';
  message?: string;
  onHide?: () => void;
}

export type U21FileCardUploadStatus =
  | Uploading
  | UploadError
  | Finished
  | Downloading
  | Deleting;

export interface U21FileCardProps extends HTMLProps<HTMLDivElement> {
  fileName: string;
  fileSize?: number;
  status: U21FileCardUploadStatus;
}

const UPLOAD_STATUS_COLOR: Record<U21FileCardUploadStatus['state'], string> = {
  uploading: 'text.primary',
  downloading: 'text.primary',
  deleting: 'text.primary',
  finished: 'text.primary',
  error: 'error',
};

const calculateReadableFileSize = (size: number) => {
  const power = Math.floor(Math.log(size) / Math.log(1024));
  const formattedFileSize = (size / 1024 ** power).toFixed(1);
  return `${formattedFileSize} ${['B', 'kB', 'MB', 'GB', 'TB'][power]}`;
};

export const U21FileCard = (props: U21FileCardProps) => {
  const { fileName, fileSize, status, ...rest } = props;
  return (
    <Container {...getDOMProps(rest)} $status={status}>
      <Header>
        <U21Typography
          variant="body2"
          color={UPLOAD_STATUS_COLOR[status.state]}
        >
          {fileName}
        </U21Typography>
        <ActionContainer>
          {status.state === 'downloading' && (
            <U21Loading loading variant="spinner" />
          )}
          {status.state === 'deleting' && (
            <U21Loading loading variant="spinner" />
          )}
          {status.state === 'uploading' && status.onCancel && (
            <StyledButton
              aria-label="delete"
              icon={<IconTrash />}
              onClick={status.onCancel}
              size="small"
            />
          )}
          {status.state === 'finished' && status.onDownload && (
            <StyledButton
              aria-label="download"
              icon={<IconDownload />}
              onClick={status.onDownload}
              size="small"
            />
          )}
          {status.state === 'finished' && status.onDelete && (
            <StyledButton
              aria-label="cancel"
              icon={<IconTrash />}
              onClick={status.onDelete}
              size="small"
            />
          )}
          {status.state === 'error' && status.onHide && (
            <StyledButton
              aria-label="hide"
              color="error"
              icon={<IconTrash />}
              onClick={status.onHide}
              size="small"
            />
          )}
        </ActionContainer>
      </Header>
      {fileSize && fileSize > 0 && status.state !== 'error' && (
        <U21Typography variant="body2">
          {calculateReadableFileSize(fileSize)}
        </U21Typography>
      )}
      {status.state === 'error' && status.message && (
        <U21Typography variant="body2" color="error.dark">
          {status.message}
        </U21Typography>
      )}
      {status.state === 'uploading' && (
        <StyledU21Progress value={status.progress}>
          {status.progress < 100 ? (
            `${status.progress}%`
          ) : (
            <StyledIconCircleCheckFilled />
          )}
        </StyledU21Progress>
      )}
      {(status.state === 'finished' || status.state === 'deleting') && (
        <StyledU21Progress value={100}>
          <StyledIconCircleCheckFilled />
        </StyledU21Progress>
      )}
    </Container>
  );
};

const Container = styled.div<{ $status: U21FileCardUploadStatus }>`
  border: 1px solid
    ${(props) =>
      ({
        uploading: props.theme.palette.grey[500_32],
        deleting: props.theme.palette.grey[500_32],
        finished: props.theme.palette.primary.main,
        error: props.theme.palette.error.main,
      })[props.$status.state]};

  border-radius: ${(props) => props.theme.shape.borderRadius}px;
  padding: 16px;

  ${(props) =>
    props.$status.state === 'error'
      ? css`
          color: ${props.theme.palette.error.main};
        `
      : css``};

  background-color: ${(props) =>
    props.$status.state === 'error'
      ? alpha(props.theme.palette.error.main, 0.08)
      : 'transparent'};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ActionContainer = styled.div`
  height: 30px;
  align-items: center;
  display: flex;
  gap: 8px;
`;

const StyledButton = styled(U21Button)`
  // Move it so it aligns with the progress bar icon
  margin-right: -6px;
`;

const StyledU21Progress = styled(U21Progress)`
  margin-top: 4px;
`;

const StyledIconCircleCheckFilled = styled(IconCircleCheckFilled).attrs({
  // size 18 matches text content
  size: 18,
})`
  color: ${(props) => props.theme.palette.primary.main};
`;
