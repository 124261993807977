import {
  destroy,
  get,
  post,
  put,
  upload,
  getFileDownloadResponse,
} from 'app/shared/utils/fetchr';

// Models
import { ReportType } from 'app/modules/fincenSar/models';
import { Activity } from 'app/modules/fincenCtr/models';
import {
  CreateFincenCtrFilingRequestPayload,
  EditReportFilingPayload,
  CreateFincenCtrBatchPayload,
  EditCtrFiling,
} from 'app/modules/fincenCtr/requests';
import {
  FincenCtrFilingDetails,
  CreateFincenCtrBatchResponse,
  ExportFincenCtrBatchResponse,
  GetFincenCtrTemplatesResponse,
  UploadAttachmentsResponse,
  DeleteAttachmentsResponse,
} from 'app/modules/fincenCtr/responses';
import { InvestigationType } from 'app/modules/investigations/models';

export const createFincenCtrFiling = (
  articleId?: number,
  articleType?: InvestigationType,
  actionTriggerId?: number,
): Promise<FincenCtrFilingDetails> => {
  const payload: CreateFincenCtrFilingRequestPayload = {
    report_type: ReportType.FINCEN_CTR,
    article_id: articleId,
    article_type: articleType,
    action_trigger_id: actionTriggerId,
  };
  return post('/filings', payload);
};

export const getFincenCtrFiling = (
  id: string,
): Promise<FincenCtrFilingDetails> => get(`/filings/${id}`);

export const updateFincenCtrFiling = (
  id: string,
  formData: DeepPartial<Activity>,
): Promise<FincenCtrFilingDetails> => {
  const payload: EditReportFilingPayload = { content: formData };
  return put(`/filings/${id}`, payload);
};

export const createFincenCtrBatch = (
  ctrIds: number[],
): Promise<CreateFincenCtrBatchResponse> => {
  const payload: CreateFincenCtrBatchPayload = { ctr_filing_ids: ctrIds };
  return post('/filings/ctr-batch', payload);
};

export const exportFincenCtrBatch = (
  ctrBatchId: number,
): Promise<ExportFincenCtrBatchResponse> => {
  const payload = { ctr_batch_id: ctrBatchId };
  return post('/filings/export-ctr-batch', payload);
};

export const downloadCtrBatch = (ctrBatchId: number): Promise<Response> => {
  return getFileDownloadResponse(`/filings/batch/${ctrBatchId}/download`);
};

export const getFincenCtrTemplates =
  (): Promise<GetFincenCtrTemplatesResponse> => {
    return get('/filings/ctr-templates');
  };

export const lockFincenCtrFiling = (
  id: number,
  formData: DeepPartial<Activity>,
): Promise<FincenCtrFilingDetails> => {
  const payload: EditReportFilingPayload = { content: formData };
  return put(`/filings/${id}/lock`, payload);
};

export const unlockFincenCtrFiling = (
  id: number,
): Promise<FincenCtrFilingDetails> => {
  return put(`/filings/${id}/unlock`);
};

export const uploadAttachments = (
  id: number,
  files: File[],
): Promise<UploadAttachmentsResponse> => {
  const formData = new FormData();
  files.forEach((file, index) => {
    formData.append(`file${index}`, file);
  });
  return upload(`/filings/${id}/add-files`, formData);
};

export const editCtrFiling = (
  id: number,
  payload: EditCtrFiling,
): Promise<FincenCtrFilingDetails> => {
  return put(`/filings/${id}`, payload);
};

export const deleteAttachment = (
  filingId: string,
  attachmentId: string,
): Promise<DeleteAttachmentsResponse> => {
  return destroy(`/filings/${filingId}/attachments/${attachmentId}`);
};
