import { FullCaseResponse } from 'app/modules/casesOld/types/responses';
import { TableConfig } from 'app/shared/pagination/models';

export const INITIAL_CASE: FullCaseResponse = {
  id: -1,
  created_at: '',
  dispositioned_at: '',
  dispositioned_by: '',
  'created_by.full_name': '',
  title: '',
  description: '',
  status: 'OPEN',
  tags: [],
  assigned_to: '',
  assigned_to_name: '',
  'assigned_to.full_name': '',
  'queue.title': '',
  disposition: '',
  deadline: null,
  deadline_config: null,
  work_started_on: '',
  amount_volume: 0,
  checklist_submission: '',
  start_date: '',
  end_date: '',
  attachments: [],
  watchers: [],
  events: [],
  action_events: [],
  comments: [],
  audit_trail: [],
  alerts: [],
  entities: [],
  disposition_notes: '',
  subdispositions: [],
  sars: [],
  queue_id: 12,
  created_by: {
    accessible_queues: [],
    id: -1,
    can_impersonate: false,
    created_at: '',
    email: '',
    full_name: '',
    picture: '',
    status: '',
    org_id: -1,
    unit_id: -1,
    unit_name: '',
    is_reviewer: false,
    recently_merged: false,
    tags: [],
    teams: [],
    teams_permissions: {},
    org_name: '',
    org_display_name: '',
    has_valid_auth: false,
    auth0_stats: [],
    auth_provider: null,
  },
  assigned_to_id: -1,
  object_source: 'INTERNAL',
  queue_access_type: 'OPEN',
  rules: [],
  is_encrypted: false,
};

export const DEFAULT_CASE_QUEUES_TABLE_CONFIG: TableConfig[] = [
  {
    key: 'title',
    type: 'label',
    label: 'Queue name',
  },
  {
    key: 'description',
    type: 'text',
    label: 'Description',
  },
  { key: 'open_cases', type: 'label', label: 'Open cases', sortable: false },
  { key: 'teams', type: 'teams', label: 'Teams', sortable: false },
];
