import { CreateTagPayload, DeleteTagPayload } from 'app/modules/tags/requests';
import { PayloadAction } from '@reduxjs/toolkit';
import { FullTagResponse } from 'app/modules/tags/responses';

import { u21CreateSlice } from 'app/shared/thunk/u21CreateSlice';
import { u21CreateAsyncThunk } from 'app/shared/thunk/u21CreateAsyncThunk';
import { createTag, deleteTag, retrieveTags } from 'app/modules/tags/api';
import { sendErrorToast, sendSuccessToast } from 'app/shared/toasts/actions';

const TAGS_NAME = 'tags';

interface TagsState {
  loadingTags: boolean;
  loadingCreateTag: boolean;
  loadingDeleteTag: boolean;
  tags: FullTagResponse[];
}

const initialState: Readonly<TagsState> = {
  loadingTags: false,
  loadingCreateTag: false,
  loadingDeleteTag: false,
  tags: [],
};

export const retrieveTagsThunk = u21CreateAsyncThunk(
  `${TAGS_NAME}/GET_TAGS`,
  retrieveTags,
);

export const createTagThunk = u21CreateAsyncThunk(
  `${TAGS_NAME}/CREATE_TAG`,
  async (payload: CreateTagPayload, { dispatch }) => {
    try {
      const response = await createTag(payload);
      dispatch(sendSuccessToast('Successfully created tag.'));
      return response;
    } catch (e) {
      dispatch(sendErrorToast('Failed to create tag.'));
      throw e;
    }
  },
);

export const deleteTagThunk = u21CreateAsyncThunk(
  `${TAGS_NAME}/DELETE_TAG`,
  async (payload: DeleteTagPayload, { dispatch }) => {
    try {
      await deleteTag(payload);
      dispatch(sendSuccessToast('Successfully deleted tag.'));
      return payload;
    } catch (e) {
      let errorToastMessage = 'Failed to delete tag.';
      try {
        const { message } = await e.json();
        if (message) {
          errorToastMessage = message;
        }
      } catch {}
      dispatch(sendErrorToast(errorToastMessage));
      throw e;
    }
  },
);

const tagsSlice = u21CreateSlice({
  name: TAGS_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addLoadingCase(
        retrieveTagsThunk,
        'loadingTags',
        (draft, action: PayloadAction<FullTagResponse[]>) => {
          draft.tags = action.payload;
        },
      )
      .addLoadingCase(
        createTagThunk,
        'loadingCreateTag',
        (draft, action: PayloadAction<FullTagResponse>) => {
          draft.tags = [...draft.tags, action.payload];
        },
      )
      .addLoadingCase(
        deleteTagThunk,
        'loadingDeleteTag',
        (draft, action: PayloadAction<DeleteTagPayload>) => {
          draft.tags = draft.tags.filter(
            (tag) =>
              tag.type !== action.payload.type ||
              tag.name !== action.payload.name,
          );
        },
      );
  },
});

export const TAGS_SLICE_NAME = tagsSlice.name;
export default tagsSlice.reducer;
