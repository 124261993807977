import { RetrievePermissionsPayload } from 'app/modules/permissions/models';

import { retrieveSessionAgentPermissions } from 'app/shared/api/permissions';
import { setSessionAgentPermissions } from 'app/modules/session/actions';
import { u21CreateAsyncThunk } from 'app/shared/thunk/u21CreateAsyncThunk';
import { u21CreateSlice } from 'app/shared/thunk/u21CreateSlice';
import { sendAuthEmail } from 'app/modules/session/api';
import { sendErrorToast } from 'app/shared/toasts/actions';

const SESSION_NAME = 'sessionSlice';

interface SessionState {
  loadingPermissions: boolean;
  authEmailSent: boolean;
}

const INITIAL_STATE: Readonly<SessionState> = {
  loadingPermissions: false,
  authEmailSent: false,
};

export const retrieveSessionPermissionsThunk =
  u21CreateAsyncThunk<RetrievePermissionsPayload>(
    `${SESSION_NAME}/GET_SESSION_PERMISSIONS`,
    async (payload, { dispatch }) => {
      const response = await retrieveSessionAgentPermissions(payload);
      dispatch(setSessionAgentPermissions(response));
    },
  );

export const sendAuthEmailThunk = u21CreateAsyncThunk<{ email: string }>(
  `${SESSION_NAME}/UPDATE_AUTH_EMAIL_SENT`,
  async (payload, { dispatch }) => {
    try {
      await sendAuthEmail(payload);
    } catch (e) {
      if (e.status === 429) {
        dispatch(
          sendErrorToast(
            'Rate limit exceeded. Login via magic link is not available at this time',
          ),
        );
      } else {
        dispatch(
          sendErrorToast('Login via magic link is not available at this time'),
        );
      }
      throw e;
    }
  },
);

const sessionSlice = u21CreateSlice({
  name: SESSION_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    initAuthEmailSent: (draft) => {
      draft.authEmailSent = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addLoadingCase(retrieveSessionPermissionsThunk, 'loadingPermissions')
      .addCase(sendAuthEmailThunk.fulfilled, (draft) => {
        // `payload` is `undefined` when the there is an error
        // code
        draft.authEmailSent = true;
      })
      .addCase(sendAuthEmailThunk.rejected, (draft) => {
        draft.authEmailSent = false;
      });
  },
});

export const SESSION_SLICE_NAME = sessionSlice.name;
export const { initAuthEmailSent } = sessionSlice.actions;
export default sessionSlice.reducer;
