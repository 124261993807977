import produce, { Draft } from 'immer';

// Models
import {
  AuditTrailState,
  AuditTrailActionTypes,
  Unit21AuditTrailActionTypes,
} from 'app/shared/auditTrail/models';
import AuditTrailReduxActions from 'app/shared/auditTrail/actions';

const initialState: AuditTrailState = {
  auditTrailDetails: {
    id: -1,
    created_at: '',
    content: '',
    author: '',
    action_type: Unit21AuditTrailActionTypes.CASE_CREATE,
    api_event: false,
    use_modal: false,
    entities: {},
    events: {},
    alerts: {},
    cases: {},
    sars: {},
    rules: {},
    txn_instruments: {},
  },
  auditTrailEntries: {
    count: 0,
    entries: [],
  },
};

const reducer = (state = initialState, action: AuditTrailReduxActions) => {
  return produce(state, (draft: Draft<AuditTrailState>) => {
    switch (action.type) {
      case AuditTrailActionTypes.RETRIEVE_AUDIT_TRAIL_SUCCESS:
        draft.auditTrailDetails = action.payload;
        return;
      case AuditTrailActionTypes.RETRIEVE_AUDIT_TRAIL_ENTRIES_SUCCESS:
        draft.auditTrailEntries = {
          count: action.payload.count,
          entries: [
            ...draft.auditTrailEntries.entries,
            ...action.payload.entries,
          ],
        };
        return;
      case AuditTrailActionTypes.CLEAR_AUDIT_TRAIL_ENTRIES:
        draft.auditTrailEntries = { ...initialState.auditTrailEntries };
        return;
    }
  });
};

export { reducer as auditTrailReducer };
