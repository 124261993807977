import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { AlertsActionTypes } from 'app/modules/alerts/models';

// Actions
import {
  retrieveAlertTransactions as retrieveAlertTransactionsAction,
  retrieveAlertTransactionsSuccess,
} from 'app/modules/alerts/actions';

// API
import {
  getAlertTransactions,
  getAlertTransactionsFromCache,
} from 'app/shared/api/alerts';
import { PaginationPayload } from 'app/shared/pagination/models';

const rootAction = AlertsActionTypes.RETRIEVE_ALERT_TRANSACTIONS;
export function* retrieveAlertTransactions({
  payload,
}: ReturnType<typeof retrieveAlertTransactionsAction>) {
  // Note: hash_key here refers to alert_id, overloading the variable name
  // because EventTable calls the txn pagination API with hash_key
  const {
    offset,
    limit,
    sort_column: sortColumn = '',
    sort_direction: sortDirection = '',
    useCacheEndpoint,
  } = payload;
  const newPayload: PaginationPayload = {
    offset,
    limit,
    sort_column: sortColumn,
  };

  if (sortDirection) {
    newPayload.sort_direction = sortDirection;
  }

  const config = {
    rootAction,
    request: call(
      useCacheEndpoint ? getAlertTransactionsFromCache : getAlertTransactions,
      payload.hash_key,
      newPayload,
    ),
    success: function* onSuccess(result) {
      yield put(retrieveAlertTransactionsSuccess(result));
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watchRetrieveAlertTransactions() {
  yield takeLatest(rootAction, retrieveAlertTransactions);
}
