import React, { useState, useEffect } from 'react';

// Components
import { StrictInputProps } from 'semantic-ui-react';
import { U21Select, U21TextField } from 'app/shared/u21-ui/components';
import styled from 'styled-components';

interface OwnProps extends Omit<StrictInputProps, 'onChange' | 'value'> {
  seconds: number;
  onChange: (seconds: number) => void;
  initialTimeOption?: TimeOptionsType;
  label?: string;
}

type TimeOptionsType = 'h' | 'd' | 'w' | 'm';

const TIME_OPTIONS = [
  { text: 'Hour(s)', value: 'h' },
  { text: 'Day(s)', value: 'd' },
  { text: 'Week(s)', value: 'w' },
  { text: 'Month(s)', value: 'm' },
];

function timeToSeconds(value: number, timeOption: TimeOptionsType) {
  switch (timeOption) {
    case 'h':
      return value * 60 * 60;
    case 'd':
      return value * 60 * 60 * 24;
    case 'w':
      return value * 60 * 60 * 24 * 7;
    case 'm':
      return value * 60 * 60 * 24 * 31;
    default:
      return value;
  }
}

export function secondsToTime(seconds: number, timeOption: TimeOptionsType) {
  switch (timeOption) {
    case 'h':
      return Math.floor(seconds / 60 / 60);
    case 'd':
      return Math.floor(seconds / 60 / 60 / 24);
    case 'w':
      return Math.floor(seconds / 60 / 60 / 24 / 7);
    case 'm':
      return Math.floor(seconds / 60 / 60 / 24 / 31);
    default:
      return seconds;
  }
}

export function getInitialTimeOption(seconds: number): TimeOptionsType {
  // Calculate
  if (seconds % (60 * 60 * 24 * 31) === 0) {
    return 'm';
  }
  if (seconds % (60 * 60 * 24 * 7) === 0) {
    return 'w';
  }
  if (seconds % (60 * 60 * 24) === 0) {
    return 'd';
  }
  return 'h';
}

export function getTimeLabel(seconds: number): string {
  const timeBucket = getInitialTimeOption(seconds);
  const timeAmount = secondsToTime(seconds, timeBucket);
  return `${timeAmount}${timeBucket}`;
}

const TimeDeltaInput = ({
  seconds,
  onChange,
  disabled,
  initialTimeOption,
  label = '',
}: OwnProps) => {
  const [timeOption, setTimeOption] = useState<TimeOptionsType>(
    initialTimeOption || getInitialTimeOption(seconds),
  );

  useEffect(() => {
    // Update timeOption if we received a new value that can't be rendered using the selected time unit
    if (secondsToTime(seconds, timeOption) === 0 && seconds !== 0) {
      setTimeOption(getInitialTimeOption(seconds));
    }
  }, [seconds, timeOption]);

  const onInputChange = (value) => {
    onChange(timeToSeconds(value, timeOption));
  };

  const onDropdownChange = (value) => {
    setTimeOption(value);
  };

  return (
    <Container>
      <U21TextField
        disabled={disabled}
        value={secondsToTime(seconds, timeOption)}
        onChange={onInputChange}
        label={label}
        type="number"
        min={0}
      />
      <U21Select
        disabled={disabled}
        value={timeOption}
        onChange={onDropdownChange}
        options={TIME_OPTIONS}
        clearable={false}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

export default TimeDeltaInput;
