import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { BlacklistActionTypes } from 'app/modules/lists/models';

// Actions
import {
  editBlacklist as editBlacklistAction,
  editBlacklistSuccess,
} from 'app/modules/lists/actions';

// API
import { editBlacklist as editBlacklistApi } from 'app/shared/api/blacklists';

const rootAction = BlacklistActionTypes.EDIT_BLACKLIST;
function* editBlacklist({ payload }: ReturnType<typeof editBlacklistAction>) {
  const config = {
    rootAction,
    request: call(editBlacklistApi, payload),
    success: function* onSuccess(result) {
      yield put(editBlacklistSuccess(result));
    },
    successToastMessage: 'Blacklist successfully updated',
    errorToastMessage: 'Failed to update blacklist',
  };

  yield call(makeSagaRequest, config);
}

export default function* watchEditBlacklist() {
  yield takeLatest(rootAction, editBlacklist);
}
