import { ActionsUnion, createAction } from 'app/shared/utils/action-helpers';
import {
  GoAMLActionTypes,
  GoAMLFilingDetails,
  PaginatedGoAMLFilingsPayload,
  PaginatedGoAMLFilings,
  CreateGoAMLPayload,
  GoAMLAttachments,
  GoAMLAttachmentsResult,
  EditGoAMLPayload,
} from 'app/modules/goAML/models';
import { SarSummary } from 'app/modules/fincenSar/models';

export const retrieveGoAMLFiling = (id: number) =>
  createAction(GoAMLActionTypes.RETRIEVE_GOAML_FILING, id);

export const retrieveGoAMLFilingSuccess = (response: GoAMLFilingDetails) =>
  createAction(GoAMLActionTypes.RETRIEVE_GOAML_FILING_SUCCESS, response);

export const retrieveGoAMLFilings = (payload: PaginatedGoAMLFilingsPayload) =>
  createAction(GoAMLActionTypes.RETRIEVE_GOAML_FILINGS, payload);

export const retrieveGoAMLFilingsSuccess = (response: PaginatedGoAMLFilings) =>
  createAction(GoAMLActionTypes.RETRIEVE_GOAML_FILINGS_SUCCESS, response);

export const createGoAMLFiling = (payload: CreateGoAMLPayload) =>
  createAction(GoAMLActionTypes.CREATE_GOAML_FILING, payload);

export const createGoAMLFilingSuccess = (response: GoAMLFilingDetails) =>
  createAction(GoAMLActionTypes.CREATE_GOAML_FILING_SUCCESS, response);

export const editGoAMLFiling = (payload: EditGoAMLPayload) =>
  createAction(GoAMLActionTypes.EDIT_GOAML_FILING, payload);

export const editGoAMLFilingSuccess = (response: GoAMLFilingDetails) =>
  createAction(GoAMLActionTypes.EDIT_GOAML_FILING_SUCCESS, response);

export const exportGoAMLFiling = (id: number) =>
  createAction(GoAMLActionTypes.EXPORT_GOAML_FILING, id);

export const exportGoAMLFilingSuccess = () =>
  createAction(GoAMLActionTypes.EXPORT_GOAML_FILING_SUCCESS);

export const validateGoAMLFiling = (id: number) =>
  createAction(GoAMLActionTypes.VALIDATE_GOAML_FILING, id);

export const validateGoAMLFilingSuccess = (response: GoAMLFilingDetails) =>
  createAction(GoAMLActionTypes.VALIDATE_GOAML_FILING_SUCCESS, response);

export const addGoAMLAttachments = (payload: GoAMLAttachments) => {
  return createAction(GoAMLActionTypes.ADD_GOAML_ATTACHMENTS, payload);
};

export const addGoAMLAttachmentsSuccess = (payload: GoAMLAttachmentsResult) => {
  return createAction(GoAMLActionTypes.ADD_GOAML_ATTACHMENTS_SUCCESS, payload);
};

export const deleteGoAMLAttachment = (
  filingId: number,
  attachmentId: number,
) => {
  return createAction(
    GoAMLActionTypes.DELETE_GOAML_ATTACHMENT,
    filingId,
    attachmentId,
  );
};

export const deleteGoAMLAttachmentSuccess = (attachmentId: number) => {
  return createAction(
    GoAMLActionTypes.DELETE_GOAML_ATTACHMENT_SUCCESS,
    attachmentId,
  );
};

export const archiveSarSuccess = (payload: SarSummary) => {
  return createAction(GoAMLActionTypes.ARCHIVE_SAR_SUCCESS, payload);
};

const GoAMLActions = {
  retrieveGoAMLFilingSuccess,
  retrieveGoAMLFilingsSuccess,
  createGoAMLFilingSuccess,
  addGoAMLAttachmentsSuccess,
  deleteGoAMLAttachmentSuccess,
  validateGoAMLFilingSuccess,
  editGoAMLFilingSuccess,
  archiveSarSuccess,
};

type GoAMLReduxActions = ActionsUnion<typeof GoAMLActions>;

export default GoAMLReduxActions;
