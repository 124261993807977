import { Core } from 'cytoscape';

export const getMaxDegree = (
  nodesLength: number,
  concentricLevels: number = 2,
) => {
  return Math.max(
    Math.ceil(Math.sqrt(Math.max(nodesLength, 1)) / concentricLevels),
    concentricLevels,
  );
};

const WIDTH_REGEX = /(?<=\s|^)width="\d*""/;
const HEIGHT_REGEX = /(?<=\s|^)height="\d*"/;

export const encodeSvg = (
  icon: string,
  options?: {
    color?: string;
    width?: number;
    height?: number;
  },
) => {
  const { color = 'white', width = 24, height = 24 } = options ?? {};
  const svgString = icon
    .replace('currentColor', color)
    .replace(WIDTH_REGEX, `width="${width}"`)
    .replace(HEIGHT_REGEX, `height="${height}"`);
  return `data:image/svg+xml;utf8,${encodeURIComponent(svgString)}`;
};

export const zoom = (cy: Core, multiplier: number) => {
  const currZoom = cy.zoom();
  // @ts-ignore
  const { clientWidth, clientHeight } = cy.container();
  cy.zoom({
    level: currZoom * multiplier,
    renderedPosition: { x: clientWidth / 2, y: clientHeight / 2 },
  });
};
