import { ColorSchema } from 'vendor/material-minimal/palette';
import { forwardRef, HTMLProps, ReactNode, SyntheticEvent } from 'react';

import { getDOMProps, isRenderable } from 'app/shared/utils/react';

import { FormControlLabel, FormGroup, Switch } from '@mui/material';

type SwitchColor = 'default' | ColorSchema;

interface U21SwitchProps
  extends Omit<HTMLProps<HTMLSpanElement>, 'label' | 'onChange'> {
  onChange: (checked: boolean, e: SyntheticEvent) => void;
  checked?: boolean;
  name?: string;
  label?: ReactNode;
  labelPlacement?: 'start' | 'end' | 'top' | 'bottom';
  color?: SwitchColor;
  disabled?: boolean;
}

export const U21Switch = forwardRef(
  (
    {
      checked = false,
      onChange,
      name,
      label,
      labelPlacement,
      color = 'primary',
      disabled = false,
      ...rest
    }: U21SwitchProps,
    ref,
  ) => {
    const customSwitch = (
      <Switch
        checked={checked}
        onChange={(e, c) => onChange(c, e)}
        name={name}
        color={color}
        disabled={disabled}
        {...getDOMProps(rest)}
      />
    );

    if (isRenderable(label)) {
      return (
        <FormGroup row ref={ref}>
          <FormControlLabel
            control={customSwitch}
            label={label}
            labelPlacement={labelPlacement}
          />
        </FormGroup>
      );
    }

    return customSwitch;
  },
);
