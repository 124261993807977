// Components
import Editor from 'react-simple-code-editor';

// Helpers
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-sql';
import 'prismjs/components/prism-python';
import 'prismjs/themes/prism.css';

// Styles
import styles from 'app/modules/rules/styles/ConditionIDE.module.scss';

interface OwnProps {
  value: string;
  onChange?: (value: string) => void;
  style?: StyleMap;
  viewOnly?: boolean;
}

export const ConditionIDE = ({
  viewOnly,
  onChange = () => {},
  style,
  value,
}: OwnProps) => {
  return (
    <div className={styles.editor} style={style}>
      <Editor
        onValueChange={onChange}
        readOnly={viewOnly}
        value={value}
        highlight={(code) => highlight(code, languages.python)}
        padding={10}
        className={styles.editor}
        style={codeStyle}
      />
    </div>
  );
};

const codeStyle: StyleMap = {
  border: '1px solid lightgray',
  borderRadius: '3px',
  fontFamily: 'monospace',
  fontSize: 14,
  fontWeight: 400,
  overflow: 'auto',
};

export default ConditionIDE;
