import {
  useCallback,
  useEffect,
  useRef,
  useMemo,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';

// Models
import { LocalStorageKeys } from 'app/shared/constants/localStorage';

// Utils
import { isEqual } from 'lodash';

const getLocalStorageItem = <T extends NonNullable<JSONValue>>(
  key: LocalStorageKeys,
  initialValue?: T,
): T => {
  const readFromLocalStorage = localStorage.getItem(key);
  if (readFromLocalStorage === null) {
    return initialValue as T;
  }

  // String type
  if (typeof initialValue === 'string') {
    return readFromLocalStorage as T;
  }

  // Number type
  if (typeof initialValue === 'number') {
    const storageNumber = parseFloat(readFromLocalStorage);
    return (isNaN(storageNumber) ? initialValue : storageNumber) as T;
  }

  // Boolean type parsing
  if (typeof initialValue === 'boolean') {
    if (readFromLocalStorage === 'true') {
      return true as T;
    } else if (readFromLocalStorage === 'false') {
      return false as T;
    }
    return initialValue;
  }

  // JSON type parsing
  try {
    return JSON.parse(readFromLocalStorage);
  } catch {}

  // Default return
  return initialValue || (readFromLocalStorage as T);
};

const setLocalStorageItem = <T extends NonNullable<JSONValue>>(
  key: LocalStorageKeys,
  item?: T,
  initialValue?: T,
) => {
  if (isEqual(item, initialValue)) {
    localStorage.removeItem(key);
  } else {
    const toSet = typeof item === 'string' ? item : JSON.stringify(item);
    localStorage.setItem(key, toSet);
  }
  window.dispatchEvent(new Event('storageUpdate'));
};

/**
 * Changes made to the object, to be stored in localStorage, will not cause your component to rerender.
 */
export const useLocalStorage = <T extends NonNullable<JSONValue>>(
  key: LocalStorageKeys,
  initialState?: T,
): [T, (value: T) => void] => {
  // Ref initialState so it does not cause rerender: initialState never changes
  const initialStateRef = useRef(initialState);

  // Parsed item from storage
  const parsedValue = useMemo(
    () => getLocalStorageItem(key, initialStateRef.current),
    [key],
  );

  // Storage Item in ref
  const storageItem = useRef(parsedValue);

  // Function to change ref
  const onItemChange = useCallback(
    (value: T) => {
      storageItem.current = value;
      setLocalStorageItem(key, value, initialStateRef.current);
    },
    [key],
  );

  return [parsedValue, onItemChange];
};

/**
 * When object, to be stored in localStorage, needs to be the state of component. Changes to the object will cause rerender.
 */
export const useLocalStorageState = <
  T extends string | number | boolean | JSONValue[] | Record<string, JSONValue>,
>(
  key: LocalStorageKeys,
  initialState?: T,
): [T, Dispatch<SetStateAction<T>>] => {
  // Item from storage
  const [storageItem, setStorageItem] = useLocalStorage(key, initialState);

  // Items current state
  const [itemState, setItemState] = useState(storageItem);

  const handleSetItem: Dispatch<SetStateAction<T>> = useCallback(
    (valOrSetter) => {
      if (typeof valOrSetter === 'function') {
        setItemState((oldValue) => {
          const newValue = valOrSetter(oldValue);
          setStorageItem(newValue);
          return newValue;
        });
      } else {
        setItemState(valOrSetter);
        setStorageItem(valOrSetter);
      }
    },
    [setStorageItem],
  );

  const handleStorageChange = useCallback(
    (e: Event) => {
      if (e.type === 'storageUpdate') {
        const newState = getLocalStorageItem(key, initialState);

        setItemState((prevState) =>
          isEqual(prevState, newState) ? prevState : newState,
        );
      }
    },
    [initialState, key],
  );

  useEffect(() => {
    window.addEventListener('storageUpdate', handleStorageChange);

    return () => {
      window.removeEventListener('storageUpdate', handleStorageChange);
    };
  }, [handleStorageChange]);

  return [itemState, handleSetItem];
};
