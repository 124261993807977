import { u21CreateSlice } from 'app/shared/thunk/u21CreateSlice';
import { PayloadAction } from '@reduxjs/toolkit';
import { RawDraftContentState } from 'draft-js';

interface NotesState {
  cachedNotes: Partial<Record<number | 'new', RawDraftContentState>>;
}

const INITIAL_STATE: NotesState = {
  cachedNotes: {},
};

const slice = u21CreateSlice({
  initialState: INITIAL_STATE,
  name: 'notesSlice',
  reducers: {
    cacheNote: (
      draft,
      action: PayloadAction<{
        id: keyof NotesState['cachedNotes'];
        content: RawDraftContentState;
      }>,
    ) => {
      const { id, content } = action.payload;
      draft.cachedNotes[id] = content;
    },
    resetCachedNote: (
      draft,
      action: PayloadAction<keyof NotesState['cachedNotes']>,
    ) => {
      delete draft.cachedNotes[action.payload];
    },
    resetAllCachedNotes: (draft) => {
      draft.cachedNotes = {};
    },
  },
});
export const { name } = slice;
export const { reducer } = slice;
export const { cacheNote, resetCachedNote, resetAllCachedNotes } =
  slice.actions;
