import { call, put, takeEvery } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// API
import { processOneDatafile } from 'app/shared/api/uploads';

// Models
import { UploadsActionTypes } from 'app/modules/uploads/models';

// Actions
import {
  processDatafile as processDatafileAction,
  processDatafileSuccess,
} from 'app/modules/uploads/actions';
import { sendToast } from 'app/shared/toasts/actions';

const rootAction = UploadsActionTypes.PROCESS_DATAFILE;
function* processDatafileFlow({
  payload,
}: ReturnType<typeof processDatafileAction>) {
  const config = {
    rootAction,
    request: call(processOneDatafile, payload.payload1, payload.payload2),
    success: function* onSuccess() {
      yield put(
        sendToast({
          message: `Datafile ${payload.payload1} is being processed.`,
          type: 'Success',
        }),
      );
      yield put(processDatafileSuccess(payload.payload1));
    },
    error: function* onError() {
      const message: string =
        'There was a problem processing this file. It is likely already being processed.';
      yield put(sendToast({ message, type: 'Error' }));
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* processDatafile() {
  yield takeEvery(rootAction, processDatafileFlow);
}
