// Components
import { U21Chip } from 'app/shared/u21-ui/components';

// Models
import { AlertScore } from 'app/modules/alerts/models';

interface OwnProps {
  alertScore?: AlertScore;
}

export const formatAlertScore = (score: number) => {
  return Math.floor(score * 1000) / 10;
};

export const AlertScoreLabel = ({ alertScore }: OwnProps) => {
  if (!alertScore || alertScore?.score < 0) {
    return null;
  }

  const { score, risk } = alertScore;

  return (
    <U21Chip color={risk ? 'error' : 'primary'} variant="ghost">
      {formatAlertScore(score)}
    </U21Chip>
  );
};
