import {
  SortTypes,
  U21TableColumnTypes,
  U21TableProps,
  U21TableState,
} from 'app/shared/u21-ui/components/display/table/models';
import { UsePinColumnsState } from 'app/shared/u21-ui/components/display/table/hooks/models';

import {
  COLUMN_TYPE_TO_SORT_TYPE,
  DEFAULT_COLUMN,
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  DEFAULT_STATE_CHANGE_DELAY,
  EXPANDABLE_COLUMN_ARROW,
  SELECT_COLUMN_CHECKBOX,
} from 'app/shared/u21-ui/components/display/table/constants';

import {
  actions as tableActions,
  Column,
  SortByFn,
  useAsyncDebounce,
  useColumnOrder,
  useExpanded,
  useFlexLayout,
  usePagination,
  useResizeColumns,
  useSortBy,
  useTable,
} from 'react-table';
import {
  alphanumericSorter,
  boolSorter,
  datetimeSorter,
  getColumnTypeDefaults,
  getDefaultSortBy,
  lengthSorter,
  numericSorter,
} from 'app/shared/u21-ui/components/display/table/utils';
import { getDOMProps } from 'app/shared/utils/react';
import { noop } from 'lodash';
import {
  ColumnPins,
  usePinColumns,
} from 'app/shared/u21-ui/components/display/table/hooks/usePinColumns';
import { useEffect, useImperativeHandle, useMemo, useRef } from 'react';
import { useTableRef } from 'app/shared/u21-ui/components/display/table/hooks/useTableRef';
import emptyFn from 'app/shared/utils/empty-fn';
import styled, { CSSObject } from 'styled-components';

import { U21Loading } from 'app/shared/u21-ui/components/display/U21Loading';
import { U21NoData } from 'app/shared/u21-ui/components/display/U21NoData';
import { U21Pagination } from 'app/shared/u21-ui/components/display/pagination/U21Pagination';
import { U21TableActions } from 'app/shared/u21-ui/components/display/table/U21TableActions';
import { U21TableBody } from 'app/shared/u21-ui/components/display/table/U21TableBody';
import { U21TableHeader } from 'app/shared/u21-ui/components/display/table/U21TableHeader';
import { U21TableSelectAllBar } from 'app/shared/u21-ui/components/display/table/U21TableSelectAllBar';

const defaultGetRowID = (row, idx) =>
  typeof row.id !== 'undefined' ? row.id : idx;

export const U21Table = <TRow extends object, TFilters = void>(
  props: U21TableProps<TRow, TFilters>,
) => {
  const {
    actions,
    columns: columnsProp,
    data,
    count = data.length,
    defaultColumnPin,
    defaultPage = DEFAULT_PAGE,
    defaultPageSize = DEFAULT_PAGE_SIZE,
    defaultSortBy,
    defaultColumnWidths,
    disabled,
    isRowClickable,
    filters,
    getRowID = defaultGetRowID,
    label,
    loading,
    manualPagination,
    noDataComponent = <U21NoData />,
    onRefresh,
    onRowClick,
    onRowSelect,
    onStateChange = emptyFn,
    refreshLoading,
    selectable,
    selected,
    stateChangeDelay = DEFAULT_STATE_CHANGE_DELAY,
    onSelectAllButtonPressed,
    selectAllButtonPressed,
    SubComponent,
    tableInstanceRef,
    onPreferenceChange,
    ...rest
  } = props;

  const hasSubComponent = Boolean(SubComponent);
  const columns = useMemo(() => {
    return [
      ...(hasSubComponent ? [EXPANDABLE_COLUMN_ARROW] : []),
      ...(selectable ? [SELECT_COLUMN_CHECKBOX] : []), // U21TableColumn (select all checkbox)
      ...columnsProp.map((i) => {
        const { accessor, id, type, ...restConfig } = i;
        const column = {
          ...DEFAULT_COLUMN,
          ...getColumnTypeDefaults(type), // define first to allow overrides
          accessor,
          id: id || accessor,
          type,
          ...restConfig,
        };
        // Need to do it this way because `sortType: undefined` cannot be passed to useTable
        if (!column.sortType && type && COLUMN_TYPE_TO_SORT_TYPE[type]) {
          column.sortType = COLUMN_TYPE_TO_SORT_TYPE[type];
        }
        // Need to do it this way because `width: undefined` cannot be passed to useTable
        return defaultColumnWidths?.[id]
          ? {
              ...column,
              width: defaultColumnWidths?.[id],
            }
          : column;
      }),
    ];
    // cast to Column type since react-table doesn't like our accessor / id typing
  }, [
    columnsProp,
    selectable,
    defaultColumnWidths,
    hasSubComponent,
  ]) as (Column<TRow> & {
    id: string;
  })[];

  const initialColumnPin: UsePinColumnsState['columnPin'] = useMemo(() => {
    const [first] = columns;
    const last = columns[columns.length - 1];
    const autoColumnPin: UsePinColumnsState['columnPin'] = {};
    if (first?.id === SELECT_COLUMN_CHECKBOX.id) {
      autoColumnPin[first.id] = ColumnPins.LEFT;
    }
    if (last?.type === U21TableColumnTypes.ACTIONS) {
      autoColumnPin[last.id] = ColumnPins.RIGHT;
    }
    return {
      ...autoColumnPin,
      ...defaultColumnPin,
    };
  }, [columns, defaultColumnPin]);

  const initialSortBy = useMemo(() => {
    if (defaultSortBy) {
      return defaultSortBy;
    }
    const firstSortableColumn = columns.find((i) => !i.disableSortBy);
    if (firstSortableColumn) {
      return [getDefaultSortBy(firstSortableColumn.id)];
    }
    return [];
  }, [columns, defaultSortBy]);

  const isTableRendered = !loading && Boolean(data.length);

  const tableProps = useTable<TRow>(
    {
      columns,
      data,
      disableSortRemove: true,
      disableMultiSort: true,
      getRowId: getRowID,
      initialState: {
        columnPin: initialColumnPin,
        pageIndex: defaultPage - 1,
        pageSize: defaultPageSize,
        sortBy: initialSortBy,
      },
      isTableRendered,
      manualSortBy: manualPagination,
      manualPagination,
      pageCount: -1,
      sortTypes: {
        number: (a, b, id) => {
          return numericSorter(a.values[id], b.values[id]);
        },
        alphanumeric: (a, b, id) => {
          return alphanumericSorter(a.values[id], b.values[id]);
        },
        boolean: (a, b, id) => {
          return boolSorter(a.values[id], b.values[id]);
        },
        datetime: (a, b, id) => {
          return datetimeSorter(a.values[id], b.values[id]);
        },
        list: (a, b, id) => {
          return lengthSorter(a.values[id]?.length, b.values[id]?.length);
        },
      } satisfies Record<SortTypes, SortByFn<TRow>>,
    },
    useTableRef,
    useColumnOrder,
    useFlexLayout,
    useResizeColumns,
    useSortBy,
    useExpanded,
    usePagination,
    usePinColumns,
  );
  const {
    dispatch,
    getTableProps,
    gotoPage,
    page,
    setPageSize,
    tableContainerRef,
    totalColumnsWidth,
    state: { pageIndex, sortBy, pageSize, columnResizing },
  } = tableProps;

  useImperativeHandle(tableInstanceRef || noop, () => tableProps, [tableProps]);

  const tableState: U21TableState = useMemo(() => {
    return {
      page: pageIndex + 1,
      pageSize,
      sortBy,
    };
  }, [pageIndex, pageSize, sortBy]);

  const tablePreferences = useMemo(() => {
    const columnWidths = {
      ...defaultColumnWidths,
      ...columnResizing.columnWidths,
    };
    return {
      pageSize,
      sortBy,
      columnWidths,
    };
  }, [pageSize, sortBy, columnResizing, defaultColumnWidths]);

  const onStateChangeRef = useRef(onStateChange);
  const onStateChangeDebounced = useAsyncDebounce(
    onStateChange,
    stateChangeDelay,
  );

  const mountedRef = useRef(false);
  // this useEffect is triggered twice if filters change
  // when filters change, page is reset so tableState changes
  // but only one API call is triggered due to the debouncing
  useEffect(() => {
    if (mountedRef.current) {
      onStateChangeDebounced(tableState, filters);
    } else {
      onStateChangeRef.current?.(tableState, filters);
      mountedRef.current = true;
    }
  }, [filters, onStateChangeDebounced, tableState]);

  useEffect(() => {
    dispatch({ type: tableActions.resetPage });
  }, [dispatch, filters]);

  const { style: tableStyle, ...restTableProps } = getTableProps();

  // Save users table state
  useEffect(() => {
    onPreferenceChange?.(tablePreferences);
  }, [onPreferenceChange, tablePreferences]);

  return (
    <div {...getDOMProps(rest)}>
      {(() => {
        if (loading) {
          return (
            <LoadingContainer>
              <U21Loading loading />
            </LoadingContainer>
          );
        }

        if (!data.length) {
          if (typeof noDataComponent === 'string') {
            return <U21NoData>{noDataComponent}</U21NoData>;
          }
          return noDataComponent;
        }

        return (
          <>
            <U21TableActions userProps={props} />
            <U21TableSelectAllBar tableProps={tableProps} userProps={props} />
            <TableContainer ref={tableContainerRef}>
              <Table
                $style={{
                  ...tableStyle,
                  minWidth: totalColumnsWidth,
                }}
                {...restTableProps}
              >
                <U21TableHeader tableProps={tableProps} userProps={props} />
                <U21TableBody tableProps={tableProps} userProps={props} />
              </Table>
            </TableContainer>
          </>
        );
      })()}
      <U21Pagination
        count={count}
        currentPageCount={page.length}
        // convert page number to page index
        onPageChange={(pageNumber) => gotoPage(pageNumber - 1)}
        onPageSizeChange={setPageSize}
        onRefresh={
          onRefresh ? () => onRefresh?.(tableState, filters) : undefined
        }
        refreshLoading={refreshLoading}
        page={pageIndex + 1}
        pageSize={pageSize}
      />
    </div>
  );
};

const LoadingContainer = styled.div`
  height: 200px;
  display: flex;
  justify-content: center;
`;

const TableContainer = styled.div`
  overflow: auto;
  border: 1px solid ${(props) => props.theme.palette.divider};
  border-radius: 8px;
  overscroll-behavior-x: contain;
`;

const Table = styled.div<{ $style: CSSObject }>`
  ${(props) => props.$style}
`;
