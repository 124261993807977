import {
  FormApi,
  MutableState,
  FormSubscription,
  Config,
  Decorator,
} from 'final-form';

import { HTMLProps, ReactNode, useMemo } from 'react';
import { getDOMProps } from 'app/shared/utils/react';
import arrayMutators from 'final-form-arrays';
import createFocusDecorator from 'final-form-focus';
import createSubmitListenerDecorator from 'final-form-submit-listener';

import { Form as ReactForm, FormRenderProps } from 'react-final-form';
import { U21FormAutoSave } from 'app/shared/u21-ui/components/form/U21FormAutoSave';
import { U21FormContext } from 'app/shared/u21-ui/components/form/U21FormContext';
import { U21LeaveWarning } from 'app/shared/u21-ui/components/display/U21LeaveWarning';
import { FormFieldError } from 'app/shared/models/form';

export interface U21FormProps<FormValues>
  extends Omit<
      HTMLProps<HTMLFormElement>,
      'autoSave' | 'children' | 'onSubmit'
    >,
    Config<FormValues> {
  autoSave?: boolean;
  autoSaveDelay?: number;
  children: ReactNode | ((props: FormRenderProps<FormValues>) => ReactNode);
  decorators?: Decorator<FormValues>[];
  disabled?: boolean;
  ignoredRoutes?: (route: string) => boolean;
  keepDirtyOnReinitialize?: boolean;
  loading?: boolean;
  onAfterSubmitFailed?: (form: FormApi<FormValues>) => void;
  onAfterSubmitSucceeded?: (form: FormApi<FormValues>) => void;
  onBeforeSubmit?: (form: FormApi<FormValues>) => false | undefined;
  preventEnterSubmit?: boolean;
  prompt?: boolean;
  skipAutoSaveValidation?: boolean; // Used when we want to save a draft of a form.
  subscription?: FormSubscription;
}

const mutators = {
  ...arrayMutators,
  setFormTouched: (args: [boolean], state: MutableState<any>) => {
    const [touched = false] = args;
    Object.values(state.fields).forEach((draft) => {
      draft.touched = touched;
    });
  },
  // note: this only works if the form has no validation
  setFieldError: (
    [field, error]: [string, FormFieldError],
    draft: MutableState<any>,
  ) => {
    if (!draft.fields[field]) {
      return;
    }
    if (error && error.length) {
      // if error, add to formState.errors
      if (draft.formState.errors) {
        draft.formState.errors[field] = error;
      } else {
        draft.formState.errors = { [field]: error };
      }
    } else if (draft.formState.errors) {
      // if no error, remove from formState.errors
      delete draft.formState.errors[field];
    }
  },
  setFieldValue: (
    [field, value]: [string, any],
    state: MutableState<any>,
    { changeValue },
  ) => {
    changeValue(state, field, () => value);
  },
  setFieldTouched: (
    [field, touched]: [string, boolean],
    draft: MutableState<any>,
  ) => {
    if (draft.fields[field]) {
      draft.fields[field].touched = touched;
    }
  },
};

const focusOnError = createFocusDecorator();

export const U21Form = <FormValues,>({
  autoSave,
  autoSaveDelay,
  children,
  decorators = [],
  disabled = false,
  ignoredRoutes,
  initialValues,
  keepDirtyOnReinitialize = true,
  loading = false,
  onAfterSubmitFailed,
  onAfterSubmitSucceeded,
  onBeforeSubmit,
  onSubmit,
  preventEnterSubmit = false,
  prompt = true,
  skipAutoSaveValidation,
  subscription,
  validate,
  ...rest
}: U21FormProps<FormValues>) => {
  const context = useMemo(
    () => ({ disabled, loading, preventEnterSubmit }),
    [disabled, loading, preventEnterSubmit],
  );

  const submitListener = useMemo(
    () =>
      createSubmitListenerDecorator({
        afterSubmitFailed: onAfterSubmitFailed,
        afterSubmitSucceeded: onAfterSubmitSucceeded,
        beforeSubmit: onBeforeSubmit,
      }),
    [onAfterSubmitFailed, onAfterSubmitSucceeded, onBeforeSubmit],
  );

  return (
    <ReactForm<FormValues>
      initialValues={initialValues}
      keepDirtyOnReinitialize={keepDirtyOnReinitialize}
      mutators={mutators}
      decorators={[focusOnError, submitListener, ...decorators]}
      onSubmit={onSubmit}
      render={(formProps) => {
        const { dirty, handleSubmit, submitSucceeded, dirtySinceLastSubmit } =
          formProps;
        return (
          <U21FormContext.Provider value={context}>
            <form
              aria-label="form"
              onSubmit={handleSubmit}
              {...getDOMProps(rest)}
            >
              {autoSave && (
                <U21FormAutoSave<FormValues>
                  delay={autoSaveDelay}
                  onSubmit={onSubmit}
                  skipAutoSaveValidation={skipAutoSaveValidation}
                />
              )}
              {typeof children === 'function' ? children(formProps) : children}
            </form>
            {prompt && (
              <U21LeaveWarning
                ignoredRoutes={ignoredRoutes}
                when={
                  submitSucceeded && keepDirtyOnReinitialize
                    ? dirtySinceLastSubmit
                    : dirty
                }
              />
            )}
          </U21FormContext.Provider>
        );
      }}
      subscription={
        typeof subscription !== 'undefined' && prompt
          ? {
              dirty: true,
              dirtySinceLastSubmit: true,
              submitSucceeded: true,
              ...subscription,
            }
          : undefined
      }
      validate={validate}
    />
  );
};
