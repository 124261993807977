export const anyPermissionChecker = (
  userPermissions: string[],
  anyPermissions: string[] = [],
): boolean => {
  if (!anyPermissions.length) {
    return true;
  }
  const userPermissionsSet = new Set(userPermissions);
  return anyPermissions.some((i) => userPermissionsSet.has(i));
};

export const hasPermissionChecker = (
  userPermissions: string[],
  hasPermissions: string[] = [],
): boolean => {
  if (!hasPermissions.length) {
    return true;
  }
  const userPermissionsSet = new Set(userPermissions);
  return hasPermissions.every((i) => userPermissionsSet.has(i));
};
