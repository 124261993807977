import { useEffect, useMemo, useState } from 'react';
import { identityFunction } from 'app/shared/utils/function';
import { selectHasReadMatchlistsPermission } from 'app/modules/session/selectors';
import { useSelector } from 'react-redux';

import { IconList } from '@u21/tabler-icons';
import {
  U21SelectOptionProps,
  U21SelectSearch,
  U21SelectSearchProps,
} from 'app/shared/u21-ui/components';
import { BlacklistType } from 'app/modules/lists/models';
import { ListMatchlistsPayload } from 'app/modules/lists/requests';
import {
  DEFAULT_OFFSET,
  DEFAULT_SEARCH_PAGINATION_LIMIT,
} from 'app/shared/pagination/constants';
import { useFetchMatchlists } from 'app/modules/lists/queries/useFetchMatchlists';
import { MatchlistStatusPill } from 'app/modules/lists/components/MatchlistStatusPill';

interface SharedProps {
  getPayload?: (payload: ListMatchlistsPayload) => ListMatchlistsPayload;
  multi?: boolean;
  types?: BlacklistType[];
}

export type U21MatchlistSelectProps = DistributiveOmit<
  U21SelectSearchProps<number>,
  'options' | 'onSearch'
> &
  SharedProps;

export const U21MatchlistSelect = (props: U21MatchlistSelectProps) => {
  const { getPayload = identityFunction, value, multi, ...rest } = props;

  const hasReadMatchlistsPermission = useSelector(
    selectHasReadMatchlistsPermission,
  );
  const [phrase, setPhrase] = useState<string>('');
  const [options, setOptions] = useState<U21SelectOptionProps[]>([]);

  const payload = useMemo(() => {
    const basePayload: ListMatchlistsPayload = {
      offset: DEFAULT_OFFSET,
      limit: DEFAULT_SEARCH_PAGINATION_LIMIT,
      status: 'ACTIVE',
      phrase,
    };
    return getPayload(basePayload);
  }, [phrase, getPayload]);

  const idsPayload: ListMatchlistsPayload = useMemo(() => {
    let idsArray: number[] = [];
    if (value) {
      if (Array.isArray(value)) {
        idsArray = value;
      } else {
        idsArray = [value];
      }
    }

    // Filter out the ids that are already cached
    const cachedIds = new Set(options.map((option) => option.value));
    const missingIds: number[] = idsArray.filter(
      (id) => id && !cachedIds.has(id),
    );
    return {
      offset: DEFAULT_OFFSET,
      limit: missingIds.length,
      ids: missingIds,
    };
  }, [value, options]);

  const { data: missingIdsRes, isLoading: missingIdsLoading } =
    useFetchMatchlists(idsPayload);
  const { data: searchRes, isLoading: searchLoading } =
    useFetchMatchlists(payload);

  const loading = searchLoading || missingIdsLoading;

  useEffect(() => {
    // Merge the latest results with the existing options so the options are not overwritten
    setOptions((prevOptions) => {
      const existingIds = new Set(prevOptions.map((option) => option.value));
      const allResults = [
        ...(searchRes?.blacklists || []),
        ...(missingIdsRes?.blacklists || []),
      ];
      const newOptions: U21SelectOptionProps[] = allResults
        .filter(({ id }) => !existingIds.has(id))
        .map((res) => ({
          icon: <MatchlistStatusPill status={res.status} />,
          text: `#${res.id} - ${res.name}`,
          value: res.id,
          description: res.description,
        }));
      return [...prevOptions, ...newOptions];
    });
  }, [searchRes, missingIdsRes, setOptions]);

  if (!hasReadMatchlistsPermission) {
    return null;
  }

  return (
    <U21SelectSearch
      label="Matchlist"
      onSearch={(newPhrase = '') => {
        setPhrase(newPhrase);
      }}
      options={options}
      startIcon={<IconList />}
      loading={loading}
      value={value}
      {...rest}
    />
  );
};
