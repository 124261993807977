import {
  FullSARFilingResponse,
  IpsFromEntity,
  IpsFromEvent,
  SARAttachmentResponse,
  RetrieveSarAuditTrailResponse,
  UpdateSarResponse,
  SarNarrativeTemplate,
  ArchiveSarResponse,
  SubmitSarResponse,
} from 'app/modules/fincenSarNew/response';
import {
  APISarValues,
  SarFormValues,
  NarrativeTemplateOption,
} from 'app/modules/fincenSarNew/models';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  DEFAULT_FILING_NAME,
  INITIAL_SAR,
  NARRATIVE_TAB_KEYS,
  NEW_FINANCIAL_INSTITUTION,
  NEW_SUBJECT,
} from 'app/modules/fincenSarNew/constants';
import pluralize from 'pluralize';

// U21 Thunk
import { u21CreateSlice } from 'app/shared/thunk/u21CreateSlice';
import { u21CreateAsyncThunk } from 'app/shared/thunk/u21CreateAsyncThunk';

// Models
import { AuditTrailSummary } from 'app/shared/auditTrail/models';
import { FullTagResponse } from 'app/modules/tags/responses';
import {
  SarXlsxCsvExportPayload,
  DuplicateSarPayload,
  ChangeSarQueuePayload,
  EditSarReviewerPayload,
} from 'app/modules/fincenSarNew/requests';

// Api
import {
  ipsFromEntity,
  ipsFromEvent,
  retrieveSar,
  retrieveSarAuditTrail,
  deleteAttachment,
  uploadAttachment,
  updateSar,
  validateSar,
  getNarrativeTemplates,
  submitSar,
  exportXlsxOrCsv,
  duplicateSar,
  readyForReview,
  returnToEdit,
  archiveSar,
  changeSarQueue,
  editSarReviewerApi,
  downloadAttachment,
} from 'app/modules/fincenSarNew/api';

// Utils
import { flatFormData } from 'app/modules/fincenSarNew/nestingUtils';
import { getTagDisplayName } from 'app/modules/tags/utils';
import { sendErrorToast, sendSuccessToast } from 'app/shared/toasts/actions';
import { selectAgent } from 'app/modules/session/selectors';
import { ShortSARFilingResponse } from 'app/modules/fincenSar/types';
import { ArticleDeadline } from 'app/modules/deadlines/models';
import { selectAllTags } from 'app/modules/teamsOld/selectors';
import { selectSar } from 'app/modules/fincenSarNew/selectors';
import { editSarReviewerSucess } from 'app/modules/fincenSar/actions';

const FINCEN_SAR_NAME = 'fincenSarNew';

export const finCenSarInitialState = {
  previewing: false,
  loadingRetrieveSar: false,
  loadingUpdateSar: false,
  loadingReturnToEdit: false,
  loadingEntityIps: false,
  loadingEventIps: false,
  loadingUploadAttachment: false,
  loadingDownloadAttachment: false,
  loadingDeleteAttachment: false,
  loadingNarrativeTemplates: false,
  loadingSarXlOrCsvExport: false,
  loadingArchive: false,
  loadingDuplicateSar: false,
  loadingAuditTrail: false,
  loadingChangeSarQueue: false,
  loadingSubmittingSar: false,
  carEnabled: false,
  narrativeTemplates: undefined as NarrativeTemplateOption[] | undefined,
  sar: INITIAL_SAR,
  sarFormData: {
    typeOfFiling_1: [],
    typeOfAmountInvolved_29: 'Amount Known',
    typeOfSuspiciousActivity_32_41: [],
    typeOfStructuring_32: [],
    typeOfTerroristFinancing_33: [],
    typeOfFraud_34: [],
    typeOfGamingActivities_35: [],
    typeOfMoneyLaundering_36: [],
    typeOfIdentificationDocumentation_37: [],
    typeOfOtherSuspiciousActivities_38: [],
    typeOfInsurance_39: [],
    typeOfSecuritiesFuturesOptions_40: [],
    typeOfMortgageFraud_41: [],
    typeOfCyberEvent_42: [],
    ipAddress_43: [{}],
    cyberEventIndicators_44: [{}],
    typeOfProductsInvolved_45: [],
    typeOfPaymentInstrumentInvolved_46: [],
    commodityType_47: [''],
    productInstrumentDescription_48: [''],
    marketWhereTraded_49: [''],
    cusipNumber_50: [''],
    financialInstitutionWhereActivityOccurred: [NEW_FINANCIAL_INSTITUTION],
    subjects: [NEW_SUBJECT],
    typeOfSecuritiesAndFutures_80: [],
  } as SarFormValues,
  sarAuditTrail: [] as AuditTrailSummary[],
  touchedFields: {} as Record<string, boolean | undefined>,
  all3241enabled: false,
  narrativeTab: NARRATIVE_TAB_KEYS.CONTENT,
};

export const retrieveSarThunk = u21CreateAsyncThunk<
  string,
  FullSARFilingResponse
>(`${FINCEN_SAR_NAME}/RETRIEVE_SAR`, retrieveSar);

export const updateSarThunk = u21CreateAsyncThunk<
  { id: string; payload: APISarValues },
  UpdateSarResponse
>(`${FINCEN_SAR_NAME}/UPDATE_SAR`, async ({ id, payload }, { dispatch }) => {
  try {
    // copy filing name to the top level object
    // default to DEFAULT_FILING_NAME because BE does not update it if it's empty string
    const name = payload[0].content.filingName || DEFAULT_FILING_NAME;
    return await updateSar(id, { name, content: payload });
  } catch (e) {
    dispatch(sendErrorToast('Failed to update filing. Please try again.'));
    throw e;
  }
});

export const validateSarThunk = u21CreateAsyncThunk<string>(
  `${FINCEN_SAR_NAME}/VALIDATE_SAR`,
  async (id, { dispatch }) => {
    try {
      const { error_msg: error } = await validateSar(id);
      if (error !== 'ok') {
        dispatch(sendErrorToast(`Contact Unit21: ${error}`));
      }
    } catch (e) {
      dispatch(sendErrorToast('Failed to validate filing. Please try again.'));
      throw e;
    }
  },
);

const filterIPs = (
  ipAddresses: APISarValues[4]['content']['43_ipAddress'],
  dispatch: (arg: any) => void,
) => {
  let filteredIPs = ipAddresses.filter((ipAdd) => ipAdd?.['43_ipAddress']);
  dispatch(
    sendSuccessToast(
      `${filteredIPs.length} IP ${pluralize(
        'address',
        filteredIPs.length,
      )} imported`,
    ),
  );
  if (filteredIPs.length === 0) {
    filteredIPs = [
      {
        '43_ipAddress': '',
        '43_ipAddressDate': '',
        '43_ipAddressTimestamp': '',
      },
    ];
  }
  return filteredIPs;
};

export const retrieveEntityIpsThunk = u21CreateAsyncThunk<
  { id: string; formContent: APISarValues },
  IpsFromEntity
>(
  `${FINCEN_SAR_NAME}/ENTITY_IPS`,
  async ({ id, formContent }, { dispatch }) => {
    try {
      // save form first so BE has the latest list of IPs
      await dispatch(updateSarThunk({ id, payload: formContent })).unwrap();
      const response = await ipsFromEntity(id);
      return filterIPs(response, dispatch);
    } catch (e) {
      dispatch(
        sendErrorToast('Failed to import entity IPs. Please try again.'),
      );
      throw e;
    }
  },
);

export const retrieveEventIpsThunk = u21CreateAsyncThunk<
  { id: string; formContent: APISarValues },
  IpsFromEvent
>(`${FINCEN_SAR_NAME}/EVENT_IPS`, async ({ id, formContent }, { dispatch }) => {
  try {
    // save form first so BE has the latest list of IPs
    await dispatch(updateSarThunk({ id, payload: formContent })).unwrap();
    const response = await ipsFromEvent(id);
    return filterIPs(response, dispatch);
  } catch (e) {
    dispatch(sendErrorToast('Failed to import event IPs. Please try again.'));
    throw e;
  }
});

export const uploadAttachmentThunk = u21CreateAsyncThunk<
  { id: string; file: File },
  SARAttachmentResponse
>(
  `${FINCEN_SAR_NAME}/UPLOAD_ATTACHMENT`,
  async ({ id, file }, { dispatch }) => {
    try {
      return await uploadAttachment(id, file);
    } catch (e) {
      dispatch(
        sendErrorToast('Failed to upload attachment. Please try again.'),
      );
      throw e;
    }
  },
);

export const downloadAttachmentThunk = u21CreateAsyncThunk(
  `${FINCEN_SAR_NAME}/DOWNLOAD_ATTACHMENT`,
  async (_, { dispatch, getState }) => {
    try {
      const state = getState();
      const { attached_file: attachedFile } = selectSar(state);
      if (attachedFile) {
        await downloadAttachment(attachedFile.id, attachedFile.name);
        dispatch(sendSuccessToast('Attachment downloaded'));
      } else {
        dispatch(sendErrorToast('Contact Unit21: Illegal action'));
      }
    } catch (e) {
      dispatch(
        sendErrorToast('Failed to download attachment. Please try again.'),
      );
      throw e;
    }
  },
);

export const deleteAttachmentThunk = u21CreateAsyncThunk<
  string,
  Record<string, never>
>(`${FINCEN_SAR_NAME}/DELETE_ATTACHMENT`, async (id, { dispatch }) => {
  try {
    return await deleteAttachment(id);
  } catch (e) {
    dispatch(sendErrorToast('Failed to delete attachment. Please try again.'));
    throw e;
  }
});

const retrieveSarAuditTrailThunkName = `${FINCEN_SAR_NAME}/RETRIEVE_SAR_AUDIT_TRAIL`;
export const retrieveSarAuditTrailThunk = u21CreateAsyncThunk<
  string,
  RetrieveSarAuditTrailResponse
>(retrieveSarAuditTrailThunkName, retrieveSarAuditTrail);

export const retrieveNarrativeTemplatesThunk = u21CreateAsyncThunk<
  void,
  [FullTagResponse[], SarNarrativeTemplate[]]
>(
  `${FINCEN_SAR_NAME}/NARRATIVE_TEMPLATES`,
  async (_, { dispatch, getState }) => {
    try {
      const tags = Object.values(selectAllTags(getState()));
      const template = await getNarrativeTemplates();
      return [tags, template];
    } catch (e) {
      dispatch(sendErrorToast('Failed to get narrative templates.'));
      throw e;
    }
  },
);

export const returnToEditThunk = u21CreateAsyncThunk<string>(
  `${FINCEN_SAR_NAME}/RETURN_TO_EDIT`,
  async (id, { dispatch }) => {
    const intId = Number.parseInt(id, 10);
    try {
      await returnToEdit(intId);
      dispatch(sendSuccessToast('SAR filing returned for editing'));
    } catch (e) {
      dispatch(sendErrorToast('Failed: please try again'));
      throw e;
    }
  },
);

export const readyForReviewThunk = u21CreateAsyncThunk<{
  id: string;
  payload: APISarValues;
}>(
  `${FINCEN_SAR_NAME}/READY_FOR_REVIEW`,
  async ({ id, payload }, { dispatch }) => {
    const intId = Number.parseInt(id, 10);
    try {
      await dispatch(updateSarThunk({ id, payload }));
      await dispatch(validateSarThunk(id));
      await readyForReview(intId);
      dispatch(sendSuccessToast('SAR filing ready for review'));
    } catch (e) {
      dispatch(sendErrorToast('Action failed: Ensure form is complete'));
      throw e;
    }
  },
);

export const submitSarThunk = u21CreateAsyncThunk<string, SubmitSarResponse>(
  `${FINCEN_SAR_NAME}/SUBMIT_SAR`,
  async (id, { dispatch, getState }) => {
    const intId = Number.parseInt(id, 10);
    const {
      [FINCEN_SAR_SLICE_NAME]: { carEnabled },
    } = getState();
    try {
      const result = await submitSar({ id: intId, car_enabled: carEnabled });
      dispatch(
        sendSuccessToast(`SAR filing submitted successfully for #${id}`),
      );
      return result;
    } catch (e) {
      dispatch(sendErrorToast(`Failed to submit SAR filing for #${id}`));
      throw e;
    }
  },
);

export const exportXlsxOrCsvThunk = u21CreateAsyncThunk<
  Omit<SarXlsxCsvExportPayload, 'agent_id'>
>(
  `${FINCEN_SAR_NAME}/EXPORT_SAR_XL_CSV`,
  async (payload, { dispatch, getState }) => {
    try {
      const state = getState();
      const { id: agentId } = selectAgent(state);
      await exportXlsxOrCsv({ ...payload, agent_id: agentId });
      dispatch(sendSuccessToast('Export request confirmed'));
    } catch (e) {
      dispatch(sendErrorToast('Failed to export SAR filing'));
      throw e;
    }
  },
);

export const archiveSarThunk = u21CreateAsyncThunk<string, ArchiveSarResponse>(
  `${FINCEN_SAR_NAME}/ARCHIVE_SAR`,
  async (sarId, { dispatch }) => {
    try {
      return await archiveSar({ sar_filing_id: Number.parseInt(sarId, 10) });
    } catch (e) {
      dispatch(sendErrorToast('Failed to archive SAR filing'));
      throw e;
    }
  },
);

export const duplicateSarThunk = u21CreateAsyncThunk<
  DuplicateSarPayload,
  FullSARFilingResponse
>(`${FINCEN_SAR_NAME}/DUPLICATE_SAR`, async (payload, { dispatch }) => {
  try {
    return await duplicateSar(payload);
  } catch (e) {
    dispatch(sendErrorToast('Failed to duplicate SAR filing'));
    throw e;
  }
});

export const changeSarQueueThunk = u21CreateAsyncThunk<ChangeSarQueuePayload>(
  `${FINCEN_SAR_NAME}/CHANGE_QUEUE`,
  async (payload, { dispatch }) => {
    try {
      await changeSarQueue(payload);
      dispatch(sendSuccessToast('Successfully changed SAR filing queue.'));
    } catch (e) {
      dispatch(sendErrorToast('Failed to change SAR filing queue.'));
      throw e;
    }
  },
);

export const editSarReviewerThunk = u21CreateAsyncThunk<
  EditSarReviewerPayload & { sarId: number },
  ShortSARFilingResponse
>(
  `${FINCEN_SAR_NAME}/EDIT_REVIEWER`,
  async ({ sarId, ...payload }, { dispatch }) => {
    try {
      const response = await editSarReviewerApi(sarId, payload);
      dispatch(editSarReviewerSucess(response));
      dispatch(sendSuccessToast('SAR filing successfully reassigned'));
      return response;
    } catch (e) {
      dispatch(
        sendErrorToast(
          'Unable to update reviewer for SAR filing. Please try again',
        ),
      );
      throw e;
    }
  },
);

const fincenSarSlice = u21CreateSlice({
  initialState: finCenSarInitialState,
  name: FINCEN_SAR_NAME,
  reducers: {
    fieldTouched: (draft, action: PayloadAction<string>) => {
      draft.touchedFields[action.payload] = true;
    },
    fieldsWithPrefixDeleted: (draft, action: PayloadAction<string>) => {
      Object.keys(draft.touchedFields).forEach((fieldKey) => {
        if (fieldKey.startsWith(action.payload)) {
          delete draft.touchedFields[fieldKey];
        }
      });
    },
    sarFormSubmitted: (
      draft,
      action: PayloadAction<Record<string, boolean>>,
    ) => {
      draft.touchedFields = action.payload;
    },
    sarUnmounted: () => {
      return finCenSarInitialState;
    },
    toggleCarEnabled: (draft) => {
      draft.carEnabled = !draft.carEnabled;
    },
    errorsRevealed: (draft, action: PayloadAction<Record<string, boolean>>) => {
      draft.touchedFields = { ...draft.touchedFields, ...action.payload };
    },
    setPreview: (draft, action: PayloadAction<boolean>) => {
      draft.previewing = action.payload;
    },
    toggleAll3241: (draft, action: PayloadAction<boolean>) => {
      draft.all3241enabled = action.payload;
    },
    setNarrativeTab: (
      draft,
      action: PayloadAction<ValueOf<typeof NARRATIVE_TAB_KEYS>>,
    ) => {
      draft.narrativeTab = action.payload;
    },
    setDeadline: (draft, action: PayloadAction<ArticleDeadline>) => {
      draft.sar.deadline = action.payload;
    },
    setCarEnabled: (draft) => {
      draft.carEnabled = true;
    },
    disableCarEnabled: (draft) => {
      draft.carEnabled = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addLoadingCase(
        retrieveSarThunk,
        'loadingRetrieveSar',
        (draft, { payload }) => {
          const { content } = payload;
          draft.sarFormData = flatFormData(content, draft.all3241enabled);
          draft.sar = payload;
        },
      )
      .addLoadingCase(
        updateSarThunk,
        'loadingUpdateSar',
        (draft, { payload }) => {
          draft.sarFormData = flatFormData(
            payload.content,
            draft.all3241enabled,
          );
          draft.sar = {
            ...draft.sar,
            ...payload,
          };
        },
      )
      .addLoadingCase(returnToEditThunk, 'loadingReturnToEdit', (draft) => {
        draft.sar.lock = false;
      })
      .addLoadingCase(
        uploadAttachmentThunk,
        'loadingUploadAttachment',
        (draft, { payload }) => {
          draft.sar.attached_file = payload.attached_file;
          draft.sar.attachments = [payload.attached_file];
        },
      )
      .addLoadingCase(downloadAttachmentThunk, 'loadingDownloadAttachment')
      .addLoadingCase(
        deleteAttachmentThunk,
        'loadingDeleteAttachment',
        (draft) => {
          delete draft.sar.attached_file;
          draft.sar.attachments = [];
        },
      )
      .addLoadingCase(retrieveEntityIpsThunk, 'loadingEntityIps')
      .addLoadingCase(retrieveEventIpsThunk, 'loadingEventIps')
      .addLoadingCase(exportXlsxOrCsvThunk, 'loadingSarXlOrCsvExport')
      .addLoadingCase(
        archiveSarThunk,
        'loadingArchive',
        (draft, { payload }) => {
          draft.sar.status = payload.status;
          draft.sar.updated_at = payload.updated_at;
        },
      )
      .addLoadingCase(duplicateSarThunk, 'loadingDuplicateSar')
      .addLoadingCase(
        retrieveNarrativeTemplatesThunk,
        'loadingNarrativeTemplates',
        (draft, { payload }) => {
          const [tags, narratives] = payload;
          const tagObjects = tags.reduce(
            (acc, tag) => {
              acc[tag.id] = tag;
              return acc;
            },
            {} as Record<number, FullTagResponse>,
          );
          draft.narrativeTemplates = narratives
            .filter((narrative) => {
              if (narrative.tag_id) {
                return Boolean(tagObjects[narrative.tag_id]);
              }
              return Boolean(narrative.tag_id);
            })
            .map((narrative) => {
              // From the previous filter narrative will have tag_id
              const { name: tagName, type: tagType } =
                tagObjects[narrative.tag_id!];
              const label = getTagDisplayName({ name: tagName, type: tagType });
              return {
                id: narrative.id,
                content: narrative.content || '',
                label,
              };
            });
        },
      )
      .addLoadingCase(
        retrieveSarAuditTrailThunk,
        'loadingAuditTrail',
        (draft, { payload }) => {
          draft.sarAuditTrail = payload;
        },
      )
      .addLoadingCase(
        submitSarThunk,
        'loadingSubmittingSar',
        (draft, { payload }) => {
          draft.sarFormData = flatFormData(
            payload.content,
            draft.all3241enabled,
          );
          draft.sar = {
            ...draft.sar,
            ...payload,
          };
        },
      )
      .addLoadingCase(changeSarQueueThunk, 'loadingChangeSarQueue')
      .addCase(readyForReviewThunk.fulfilled, (draft) => {
        draft.sar.lock = true;
      })
      // TODO: add loading case and make reviewire chang pill disabled whlie loading
      .addCase(editSarReviewerThunk.fulfilled, (draft, { payload }) => {
        draft.sar.reviewer = payload.reviewer;
      });
  },
});

export const FINCEN_SAR_SLICE_NAME = fincenSarSlice.name;
export const {
  setPreview,
  fieldTouched,
  fieldsWithPrefixDeleted,
  sarUnmounted,
  sarFormSubmitted,
  toggleCarEnabled,
  setCarEnabled,
  disableCarEnabled,
  errorsRevealed,
  toggleAll3241,
  setNarrativeTab,
  setDeadline,
} = fincenSarSlice.actions;
export default fincenSarSlice.reducer;
