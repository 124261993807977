import { U21TextFieldProps } from 'app/shared/u21-ui/components';
import styled, { css } from 'styled-components';
import { InputAdornment, TextField } from '@mui/material';
import {
  ClearButton,
  Loading,
  StartIconContainer,
} from 'app/shared/u21-ui/components/input/text-field/styles';
import {
  EncryptedInput,
  EncryptedInputProps,
} from 'app/shared/u21-ui/components/input/text-field/EncryptedInput';
import {
  TYPES,
  U21EncryptedFieldProps,
} from 'app/shared/u21-ui/components/input/text-field/U21TextFieldInputProps';
import { getDOMProps } from 'app/shared/utils/react';
import {
  forwardRef,
  Fragment,
  HTMLProps,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import useId from '@mui/utils/useId';
import { consoleWarn } from 'app/shared/utils/console';
import { IconPCI } from 'app/shared/u21-ui/components/input/text-field/IconPCI';
import { FieldError } from 'app/shared/u21-ui/components/input/text-field/models';

export interface U21EncryptedTextFieldProps
  extends Omit<
      HTMLProps<HTMLDivElement>,
      'label' | 'onBlur' | 'onChange' | 'onError' | 'onFocus' | 'size' | 'type'
    >,
    Pick<
      U21TextFieldProps,
      | 'clearable'
      | 'delay'
      | 'disabled'
      | 'endIcon'
      | 'error'
      | 'id'
      | 'InputLabelProps'
      | 'InputProps'
      | 'loading'
      | 'placeholder'
      | 'required'
    > {
  inputProps?: Omit<U21TextFieldProps['inputProps'], 'onBlur' | 'onFocus'>;
  onBlur?: () => void;
  onChange: (value: string | undefined) => void;
  onFocus?: () => void;
  encryptedFieldProps: U21EncryptedFieldProps;
  onError?: (error: string) => void;
  type?: `${TYPES.TEXT}` | `${TYPES.TEXTAREA}`;
}

export const U21EncryptedTextField = forwardRef<
  HTMLDivElement,
  U21EncryptedTextFieldProps
>((props: U21EncryptedTextFieldProps, ref) => {
  const {
    clearable = true,
    delay,
    disabled,
    encryptedFieldProps,
    endIcon,
    error,
    id: idProp,
    InputLabelProps = {},
    inputProps = {},
    InputProps = {},
    loading = false,
    name,
    onChange,
    onError,
    placeholder,
    required,
    type = TYPES.TEXT,
    value,
    ...rest
  } = props;

  const id = useId(idProp);
  const isTextArea = type === TYPES.TEXTAREA;

  const [fieldError, setFieldError] = useState<FieldError | null>(null);
  const [fieldFocus, setFieldFocus] = useState(false);
  const [fieldReady, setFieldReady] = useState(false);
  const [frameError, setFrameError] = useState(false);
  const [frameReady, setFrameReady] = useState(false);

  const frameLoading = !frameReady || !fieldReady;

  const hasClearIcon =
    clearable &&
    !disabled &&
    (value === 0 || Boolean(value)) &&
    !frameLoading &&
    !frameError &&
    !isTextArea;

  const defaultEndAdornment = useMemo(() => {
    const adornments = [
      hasClearIcon && (
        <ClearButton
          key="clear"
          onClick={() => {
            onChange(undefined);
          }}
        />
      ),
      (loading || frameLoading) && <Loading key="loading" loading />,
      <Fragment key="end-icon">{endIcon}</Fragment>,
    ].filter(Boolean);
    if (adornments.length > 0) {
      return <InputAdornment position="end">{adornments}</InputAdornment>;
    }
    return undefined;
  }, [endIcon, frameLoading, hasClearIcon, loading, onChange]);

  // Let people know if there's an issue.
  useEffect(() => {
    if (!name) {
      consoleWarn('Encrypted field must have a name');
    }
  }, [name]);

  const onErrorRef = useRef(onError);
  onErrorRef.current = onError;
  const setFieldErrorWrapper = useCallback(
    (newFieldError: FieldError | null) => {
      if (newFieldError?.errorType === 'VALIDATION_ERROR') {
        onErrorRef.current?.(newFieldError.error);
      }
      setFieldError(newFieldError);
    },
    [],
  );

  return (
    <StyledTextField
      $loading={loading}
      $textarea={isTextArea}
      disabled={disabled}
      focused={fieldFocus}
      error={
        error || frameError || fieldError?.errorType === 'DECRYPTION_ERROR'
      }
      fullWidth
      id={id}
      inputProps={
        {
          delay,
          encryptedFieldProps,
          frameError,
          frameLoading,
          frameReady,
          setFieldError: setFieldErrorWrapper,
          setFieldFocus,
          setFieldReady,
          setFrameError,
          setFrameReady,
          type,
          ...inputProps,
          // onChange | value is injected by TextField
        } satisfies Omit<EncryptedInputProps, 'onChange' | 'value'>
      }
      InputLabelProps={{
        htmlFor: id,
        id: `${id}-label`,
        ...InputLabelProps,
      }}
      InputProps={{
        ...InputProps,
        inputComponent: EncryptedInput,
        endAdornment: InputProps.endAdornment || defaultEndAdornment,
        startAdornment: (
          <InputAdornment position="start">
            <StartIconContainer $disabled={disabled}>
              <IconPCI />
            </StartIconContainer>
          </InputAdornment>
        ),
      }}
      name={name}
      onChange={(e) => {
        const { value: newValue } = e.target;
        onChange(newValue);
      }}
      ref={ref}
      required={required}
      placeholder={placeholder}
      type={type}
      value={value ?? ''}
      {...getDOMProps(rest)}
    />
  );
});

interface TextFieldStyleProps {
  $loading?: boolean;
  $textarea?: boolean;
}

const StyledTextField = styled(TextField)<TextFieldStyleProps>`
  .MuiInputBase-root {
    min-height: 40px;
    ${(props) =>
      props.$textarea
        ? css`
            padding: 8.5px 6px;
            align-items: start;
          `
        : css`
            padding: 0 6px;
          `}

    ${(props) => {
      const { $loading, disabled } = props;
      let cursor;
      if ($loading) {
        cursor = 'progress';
      } else if (disabled) {
        cursor = 'not-allowed';
      }

      return (
        cursor &&
        css`
          cursor: ${cursor};

          .MuiInputBase-input {
            cursor: ${cursor};
          }
        `
      );
    }}
  }

  .MuiInputBase-input {
    ${(props) =>
      !props.InputProps?.startAdornment &&
      css`
        padding-left: 6px;
      `}

    ${(props) =>
      props.$textarea
        ? css`
            resize: vertical;
          `
        : css`
            line-height: 40px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          `}
  }

  .MuiInputAdornment-root {
    height: unset;
    max-height: unset;
  }

  /* make space between adornment + text 8px total */

  .MuiInputAdornment-positionEnd {
    margin-left: 4px;
  }

  .MuiInputAdornment-positionStart {
    margin-right: 4px;
  }

  .Mui-focused ${ClearButton} {
    visibility: visible;
  }

  :hover {
    ${ClearButton} {
      visibility: visible;
    }
  }

  .MuiFormLabel-asterisk {
    color: ${(props) => props.theme.palette.error.main};
  }

  /* fix bug introduced by node_modules/antd/dist/antd.css where legend is set to 100% */
  ${(props) =>
    !props.label &&
    css`
      .MuiOutlinedInput-notchedOutline > legend {
        width: 0;
      }
    `}
`;
