import { AnalyticsEvents, trackEvent } from 'app/shared/u21-ui/analytics';
import {
  FocusEvent,
  FocusEventHandler,
  HTMLProps,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { createPortal } from 'react-dom';
import { format, startOfDay } from 'date-fns';
import { getDOMProps } from 'app/shared/utils/react';
import styled, { css } from 'styled-components';

import { BackdropProps, InputAdornment } from '@mui/material';
import { ClearButton } from 'app/shared/u21-ui/components/input/text-field/styles';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import {
  IconCalendar,
  IconChevronLeft,
  IconChevronDown,
  IconChevronRight,
} from '@u21/tabler-icons';
import { StyledBackdrop } from 'app/shared/u21-ui/components/input/select/styles';
import { U21TextField } from 'app/shared/u21-ui/components/input/text-field/U21TextField';
import { U21TextFieldProps } from 'app/shared/u21-ui/components/input/text-field/U21TextFieldInputProps';
// for some reason lab is not exporting this....
import AdapterDateFns from '@mui/lab/AdapterDateFns';

export interface U21DatePickerProps
  extends Omit<
    HTMLProps<HTMLDivElement>,
    'onBlur' | 'onChange' | 'onFocus' | 'value'
  > {
  autoFocus?: boolean;
  backdrop?: boolean;
  backdropProps?: Omit<BackdropProps, 'open'>;
  clearable?: boolean;
  disabled?: boolean;
  error?: boolean;
  id?: string;
  label?: string;
  maxDate?: Date;
  minDate?: Date;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onChange: (date?: Date) => void;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  placeholder?: string;
  required?: boolean;
  value?: Date;
  valueTransform?: (value: Date) => Date;
  endIcon?: ReactNode;
}

function defaultValueTransform(value: Date): Date {
  return startOfDay(value);
}

const DATE_FORMAT = 'PP';

// defined based on MUI's default min / max
// github.com/mui/mui-x/blob/f074f2735552161fd86d07cca5154e2bcabb263f/packages/x-date-pickers/src/LocalizationProvider/LocalizationProvider.tsx#L126-L127
const DEFAULT_MIN_DATE = new Date('1900-01-01T00:00:00.000Z');
const DEFAULT_MAX_DATE = new Date('2099-12-31T00:00:00.000Z');

export const U21DatePicker = (props: U21DatePickerProps) => {
  const {
    autoFocus,
    backdrop,
    backdropProps,
    clearable = true,
    disabled,
    error,
    id,
    label,
    maxDate = DEFAULT_MAX_DATE,
    minDate = DEFAULT_MIN_DATE,
    name,
    onChange,
    placeholder = disabled ? undefined : 'Select a date',
    required,
    value,
    valueTransform = defaultValueTransform,
    endIcon,
    onBlur,
    onFocus,
    ...rest
  } = props;
  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  const hasClearIcon = clearable && !disabled && value;

  const inputRef = useRef<HTMLInputElement>(null);

  const onChangeWrapper = (rawValue: Date | undefined) => {
    const newValue = rawValue ? valueTransform(rawValue) : rawValue;
    trackEvent(
      AnalyticsEvents.U21DATEPICKER_ON_CHANGE,
      props,
      { open },
      newValue,
    );
    onChange(newValue);
  };

  useEffect(() => {
    if (open) {
      // for some reason needed to get text input focused
      // suspect it's to allow the popper render complete first before shifting focus
      setTimeout(() => inputRef.current?.focus(), 0);
    }
  }, [open]);

  const isDateValid = useCallback(
    (maybe: Date | string): boolean => {
      const date = new Date(maybe);
      if (isNaN(date.getTime())) {
        return false;
      }
      return date < maxDate && date >= minDate;
    },
    [maxDate, minDate],
  );

  const [inputValue, setInputValue] = useState<string>('');
  useEffect(() => {
    if (value) {
      if (isDateValid(value)) {
        // temporary fix since there are instances where value is string, SC-58419 for cleanup
        setInputValue(format(new Date(value), DATE_FORMAT));
      }
    } else {
      // if value is gone, and previous value was valid, clear
      // don't clear otherwise to show red error to user
      setInputValue((prevInputValue) => {
        if (isDateValid(prevInputValue)) {
          return '';
        }
        return prevInputValue;
      });
    }
  }, [isDateValid, value]);

  return (
    <>
      {backdrop &&
        createPortal(
          <StyledBackdrop invisible {...backdropProps} open={open} />,
          document.body,
        )}
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          ref={ref}
          components={{
            LeftArrowIcon: IconChevronLeft,
            RightArrowIcon: IconChevronRight,
            SwitchViewIcon: IconChevronDown,
          }}
          disabled={disabled}
          disableMaskedInput
          inputFormat={DATE_FORMAT}
          maxDate={maxDate}
          minDate={minDate}
          onChange={onChangeWrapper}
          onClose={() => setOpen(false)}
          open={open}
          PopperProps={{
            anchorEl: ref.current,
            // must be greater than backdrop
            // number chosen based on https://mui.com/material-ui/customization/z-index
            ...(backdrop ? { style: { zIndex: 1400 } } : {}),
          }}
          renderInput={(params) => {
            const {
              error: inputError,
              inputProps,
              InputProps,
              ...restParams
            } = params;
            const valid = inputValue ? isDateValid(inputValue) : true;

            return (
              <StyledU21TextField
                {...(restParams as Omit<U21TextFieldProps, 'onChange' | 'as'>)}
                autoFocus={autoFocus}
                clearable={clearable}
                error={error || inputError || !valid}
                id={id}
                inputProps={{
                  ...inputProps,
                  placeholder,
                  onBlur: (e: FocusEvent<HTMLInputElement>) => {
                    inputProps?.onBlur?.(e);
                    onBlur?.(e);
                    // use e.target.value instead of inputValue because inputValue is delayed by 300ms
                    const targetValue = e.target.value;
                    if (targetValue) {
                      // update with input value if valid, otherwise clear existing value
                      onChangeWrapper(
                        isDateValid(targetValue)
                          ? new Date(targetValue)
                          : undefined,
                      );
                    }
                  },
                  onFocus: (e: FocusEvent<HTMLInputElement>) => {
                    inputProps?.onFocus?.(e);
                    onFocus?.(e);
                  },
                  // overwrite default onChange behavior for our custom one
                  onChange: () => {},
                  value: inputValue,
                }}
                InputProps={{
                  ...InputProps,
                  endAdornment: (hasClearIcon || endIcon) && (
                    <InputAdornment position="end">
                      {hasClearIcon && (
                        <ClearButton
                          onClick={(e) => {
                            e.stopPropagation();
                            onChangeWrapper(undefined);
                            inputRef.current?.focus();
                          }}
                        />
                      )}
                      {endIcon}
                    </InputAdornment>
                  ),
                }}
                inputRef={inputRef}
                label={label}
                name={name}
                onClick={disabled ? undefined : () => setOpen(!open)}
                onChange={(newValue: string = '') => {
                  setInputValue(newValue);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setOpen(false);
                    inputRef.current?.blur();
                  }
                }}
                required={required}
                startIcon={<IconCalendar />}
                value={inputValue}
              />
            );
          }}
          value={value ?? null}
          views={['year', 'month', 'day']}
          {...getDOMProps(rest)}
        />
      </LocalizationProvider>
    </>
  );
};

const StyledU21TextField = styled(U21TextField)`
  ${(props) =>
    !props.disabled &&
    css`
      .MuiInputBase-root {
        cursor: pointer;

        .MuiInputBase-input {
          cursor: pointer;
        }
      }
    `}
`;
