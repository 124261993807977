import { PermissionSummary } from 'app/modules/permissions/models';

// Utils
import { put, get } from 'app/shared/utils/fetchr';
import routes from 'app/shared/utils/routes';

// Actions
import {
  retrievePermissions as retrievePermissionsAction,
  retrieveUnassignedPermissions as retrieveUnassignedPermissionsAction,
  editPermissions as editPermissionsAction,
} from 'app/modules/permissions/actions';

export const retrievePermissions = (
  payload: ReturnType<typeof retrievePermissionsAction>['payload'],
) => {
  const { associationType, associationId } = payload;
  // add associationType to url
  const formattedUrl = routes.patronus.permissionsAssociationTypeList
    .replace(':associationType', associationType)
    .replace(':associationId', String(associationId));
  return get<PermissionSummary[]>(formattedUrl);
};

export const retrieveSessionAgentPermissions = (
  payload: ReturnType<typeof retrievePermissionsAction>['payload'],
) => {
  const { associationType, associationId } = payload;
  // add associationType to url
  const formattedUrl = routes.patronus.permissionsAssociationTypeList
    .replace(':associationType', associationType)
    .replace(':associationId', String(associationId));

  return get<PermissionSummary[]>(formattedUrl);
};

export const retrieveUnassignedPermissions = (
  payload: ReturnType<typeof retrieveUnassignedPermissionsAction>['payload'],
) => {
  const { associationType, associationId } = payload;
  // add associationType to url
  const formattedUrl = routes.patronus.permissionsAssociationTypeUnassigned
    .replace(':associationType', associationType)
    .replace(':associationId', String(associationId));
  return get<PermissionSummary[]>(formattedUrl);
};

export const editPermissions = (
  payload: ReturnType<typeof editPermissionsAction>['payload'],
) => {
  const { associationType, associationId, ...body } = payload;
  // add associationType to url
  const formattedUrl = routes.patronus.permissionsAssociationTypeAssociationId
    .replace(':associationType', associationType)
    .replace(':associationId', String(associationId));
  return put(formattedUrl, body);
};
