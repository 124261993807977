import React, { Component } from 'react';
import { find, set, get, pick, fromPairs, cloneDeep } from 'lodash';
import produce from 'immer';
import autoBindReact from 'auto-bind/react';

// Redux
import { connect } from 'react-redux';

// Models
import { ScenarioConstants } from 'app/modules/rules/models';
import { WidgetProps } from 'app/modules/detectionModels/components/scenarioWidgets/models';

// Components
import { Input, Checkbox, Table } from 'semantic-ui-react';
import TimeDeltaSelect from 'app/shared/components/TimeDeltaSelect';

// Selectors
import { selectBlacklists } from 'app/modules/lists/selectors';

// Styles
import styles from 'app/modules/rules/styles/ScenarioPerFieldBlacklistEditor.module.scss';

// Constants
const DECIMAL_RADIX = 10;

interface Field {
  mustMatch: boolean;
  atLeastNMustMatch: boolean;
  fuzzyMatch: number;
}

interface FieldTable {
  [field: string]: Field;
}

interface AllState {
  fields: FieldTable;
}

const mapStateToProps = (state: RootState) => ({
  blacklists: selectBlacklists(state),
});

type AllProps = WidgetProps & ReturnType<typeof mapStateToProps>;

/*
This widget fills editingRule with an object of type

  { fields: FieldTable, numberMustMatch: number }

The widget component saves data for all fields in its state. When the user
changes a field, it updates the state, then updates editingRule with a table
of fields with disabled fields (determined by the blacklist type) filtered
out.

The reason we save the field data in the state and not directly in
editingRule is to preserve data for fields the user has disabled. So if the
user fills in some fields, changes blacklists (disabling the fields), then
changes back, their data isn't lost.

`mustMatch` and `atLeastNMustMatch` checkboxes for a field are mutually exclusive. 
They cannot be both selected at the same time. 
*/

const GENERAL_FIELDS = ['street', 'city', 'state', 'zip_code', 'country'];

const USER_FIELDS = [
  'first_name',
  'middle_name',
  'last_name',
  'alias_first_name',
  'alias_middle_name',
  'alias_last_name',
  'ssn',
  'date_of_birth',
];

const DATE_FIELDS = ['date_of_birth'];

const BUSINESS_FIELDS = ['business_name', 'dba_name', 'corporate_tax_id'];

const ALL_FIELDS = [...GENERAL_FIELDS, ...USER_FIELDS, ...BUSINESS_FIELDS];

const getInitialState = (): AllState => {
  const initialFieldState = {
    mustMatch: false,
    atLeastNMustMatch: false,
    fuzzyMatch: 100,
  };

  const initialDateFieldState = {
    ...initialFieldState,
    timeDiff: 0,
  };
  return {
    fields: fromPairs(
      ALL_FIELDS.map((field) => [
        field,
        DATE_FIELDS.includes(field) ? initialDateFieldState : initialFieldState,
      ]),
    ),
  };
};

class ScenarioPerFieldBlacklistEditor extends Component<AllProps, AllState> {
  onChangeHandlers: {
    [key: string]: {
      fuzzyMatch: any;
      mustMatch: any;
      atLeastNMustMatch: any;
      timeDiff: any;
    };
  };

  constructor(props) {
    super(props);
    const { viewOnly, editingScenario } = this.props;
    if (viewOnly) {
      // when viewOnly (validated/deployed rule) or rule has been duplicated, use existing rule content to populate state of widget
      const matchConditions =
        editingScenario.parameters[ScenarioConstants.MATCH_CONDITIONS] || {};
      const ruleContentFields = matchConditions.fields || [];
      const initialState = getInitialState();
      this.state = {
        fields: { ...initialState.fields, ...ruleContentFields },
      };
    } else {
      this.state = getInitialState();
    }
    autoBindReact(this);

    this.onChangeHandlers = {};
    ALL_FIELDS.forEach((field: string) => {
      this.onChangeHandlers[field] = {
        timeDiff: this.makeOnChange(field, 'timeDiff', 'value'),
        fuzzyMatch: this.makeOnChange(field, 'fuzzyMatch', 'value'),
        mustMatch: this.makeOnChange(field, 'mustMatch', 'checked'),
        atLeastNMustMatch: this.makeOnChange(
          field,
          'atLeastNMustMatch',
          'checked',
        ),
      };
    });
  }

  getBlacklistType() {
    const { blacklists, editingScenario } = this.props;
    if (!blacklists) {
      return null;
    }
    const blacklistId =
      editingScenario.parameters[ScenarioConstants.BLACKLIST_ID];
    if (!blacklistId) {
      return null;
    }
    const match = find(blacklists, { id: blacklistId });
    if (!match) {
      return null;
    }
    return match.type;
  }

  getEnabledFields() {
    const blacklistType = this.getBlacklistType();

    return [
      ...(blacklistType === null ? [] : GENERAL_FIELDS),
      ...(blacklistType === 'USER' ? USER_FIELDS : []),
      ...(blacklistType === 'BUSINESS' ? BUSINESS_FIELDS : []),
    ];
  }

  makeOnChange(field: string, key: string, dataField: string) {
    return (_, data) => {
      // update the fields in state, then update editingRule using the new state
      const { fields } = this.state;
      const newState = {
        fields: produce(fields, (draft) => {
          set(draft, [field, key], data[dataField]);
        }),
      };

      this.setState(newState, () => {
        this.updateMatchConditions(
          'fields',
          pick(fields, this.getEnabledFields()),
        );
      });
    };
  }

  updateMatchConditions(field: string, value: any) {
    const { fields } = this.state;
    const { editingScenario, onChange } = this.props;
    const matchConditions =
      editingScenario.parameters[ScenarioConstants.MATCH_CONDITIONS] || {};

    // Widget Validation
    // - retrieve `numberMustMatch` and `fields`
    // - if any of the rows/fields has a atLeastNMustMatch checkbox that is checked, numberMustMatch >= 1
    let numberMustMatchFields: any[] = []; // array of objects, each obj has structure {mustMatch: ..., atLeastNMustMatch: ..., fuzzyMatch: ...}
    let numberMustMatch = '';
    if (field === 'numberMustMatch') {
      numberMustMatch = value;
      numberMustMatchFields = matchConditions.fields
        ? Object.values(matchConditions.fields)
        : [];
    } else {
      numberMustMatch = matchConditions.numberMustMatch;
      numberMustMatchFields = value ? Object.values(value) : [];
    }
    const atLeastNMustMatchValues: boolean[] = numberMustMatchFields.map(
      (f) => f.atLeastNMustMatch,
    );

    let isWidgetValidated = true;
    if (atLeastNMustMatchValues.includes(true)) {
      isWidgetValidated = (parseInt(numberMustMatch, DECIMAL_RADIX) || 0) >= 1;
    }
    const newEditingScenario = cloneDeep(editingScenario);
    const updatedScenario = {
      ...newEditingScenario,
      parameters: {
        ...newEditingScenario.parameters,
        [ScenarioConstants.MATCH_CONDITIONS]: {
          [field]: value,
          fields,
        },
        [ScenarioConstants.MATCH_CONDITIONS_VALIDATION_RESULT]:
          isWidgetValidated,
      },
    };
    onChange(updatedScenario);
  }

  render() {
    const { fields } = this.state;
    const { editingScenario, viewOnly } = this.props;
    const matchConditions =
      editingScenario.parameters[ScenarioConstants.MATCH_CONDITIONS] || {};

    const enabledFields = this.getEnabledFields();
    return (
      <Table celled striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell>All must match</Table.HeaderCell>
            <Table.HeaderCell>
              <span>At least</span>
              <Input
                disabled={viewOnly}
                value={matchConditions.numberMustMatch || ''}
                size="small"
                className={styles.numberMustMatchInput}
                onChange={(...params) => {
                  this.updateMatchConditions(
                    'numberMustMatch',
                    params[1].value,
                  );
                }}
              />
              <span>must match</span>
            </Table.HeaderCell>
            <Table.HeaderCell>Fuzzy Match</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {enabledFields.map((field) => (
            <Table.Row key={field} disabled={viewOnly}>
              <Table.Cell>{field}</Table.Cell>
              <Table.Cell>
                <Checkbox
                  disabled={get(fields, [field, 'atLeastNMustMatch']) === true}
                  checked={get(fields, [field, 'mustMatch'])}
                  onChange={this.onChangeHandlers[field].mustMatch}
                />
              </Table.Cell>
              <Table.Cell>
                <Checkbox
                  disabled={get(fields, [field, 'mustMatch']) === true}
                  checked={get(fields, [field, 'atLeastNMustMatch'])}
                  onChange={this.onChangeHandlers[field].atLeastNMustMatch}
                />
              </Table.Cell>
              <Table.Cell>
                {DATE_FIELDS.includes(field) ? (
                  <TimeDeltaSelect
                    value={get(fields, [field, 'timeDiff']) || 0}
                    onChange={(value) =>
                      this.onChangeHandlers[field].timeDiff(null, { value })
                    }
                    label="Date is within"
                  />
                ) : (
                  <Input
                    value={get(fields, [field, 'fuzzyMatch'])}
                    onChange={this.onChangeHandlers[field].fuzzyMatch}
                  />
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  }
}

export default connect(mapStateToProps)(ScenarioPerFieldBlacklistEditor);
