import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { WebhookActionTypes } from 'app/modules/webhooks/models';

// Actions
import {
  deleteWebhook,
  deleteWebhookSuccess,
  listWebhooks,
} from 'app/modules/webhooks/actions';

// API
import { removeWebhook } from 'app/shared/api/webhooks';
import { sendSuccessToast } from 'app/shared/toasts/actions';

// Routes
import { ROUTES_MAP } from 'app/shared/utils/routes';
import { history } from 'app/store/browserHistory';

const rootAction = WebhookActionTypes.DELETE_WEBHOOK;
function* deleteWebhookFlow({ payload }: ReturnType<typeof deleteWebhook>) {
  const config = {
    rootAction,
    request: call(removeWebhook, payload),
    success: function* onSuccess() {
      yield put(deleteWebhookSuccess());
      yield put(listWebhooks());
      history.push(ROUTES_MAP.webhooks.path);
      yield put(sendSuccessToast(`Webhook deleted successfully.`));
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watchDeleteWebhook() {
  yield takeLatest(rootAction, deleteWebhookFlow);
}
