import {
  Children,
  ComponentType,
  Fragment,
  isValidElement,
  lazy,
  LazyExoticComponent,
  ReactElement,
  ReactNode,
} from 'react';

import isPropValid from '@emotion/is-prop-valid';

export const getDOMProps = (props: { [key: string]: any }) => {
  return Object.keys(props)
    .filter((i) => isPropValid(i))
    .reduce(
      (acc, i) => ({
        ...acc,
        [i]: props[i],
      }),
      {},
    );
};

export const flattenChildren = (children: ReactNode): ReactNode[] => {
  return Children.toArray(children).reduce((acc: ReactNode[], i) => {
    if (isValidElement(i) && i?.type === Fragment) {
      // @ts-ignore fragments have props
      return [...acc, ...flattenChildren(i.props.children)];
    }
    return [...acc, i];
  }, []) as ReactNode[];
};

export const isRenderable = (children: ReactElement | ReactNode): boolean => {
  if (typeof children === 'string') {
    return children !== '';
  } else if (typeof children === 'number') {
    return true;
  } else if (typeof children === 'boolean') {
    return false;
  } else if (Array.isArray(children)) {
    return children.some(isRenderable);
  }
  return isValidElement(children);
};

const wait = <T>(ms: number): Promise<T> => {
  return new Promise<T>((resolve) => {
    setTimeout(resolve, ms);
  });
};

interface LazyOptions {
  interval?: number;
  retries?: number;
  fallback?: ComponentType;
}

const lazyRetryHelper = async (
  lazyImport: () => Promise<{ default: ComponentType }>,
  options: LazyOptions = {},
): Promise<{ default: ComponentType }> => {
  const {
    fallback = () => null,
    retries = 3,
    interval = 1000,
  }: LazyOptions = options;
  try {
    return await lazyImport();
  } catch (error) {
    if (retries) {
      await wait(interval);
      return lazyRetryHelper(lazyImport, {
        ...options,
        retries: retries - 1,
      });
    }
    // use default to mimic default import
    return Promise.resolve({ default: fallback });
  }
};

export const lazyRetry = (
  lazyImport: () => Promise<{ default: ComponentType }>,
  options?: LazyOptions,
): LazyExoticComponent<ComponentType> => {
  return lazy(() => lazyRetryHelper(lazyImport, options));
};
