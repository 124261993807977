import { get, post } from 'app/shared/utils/fetchr';

// Utils
import routes, { pathJoin } from 'app/shared/utils/routes';

// Actions
import { retrieveAuditTrail as retrieveAuditTrailAction } from 'app/shared/auditTrail/actions';

export const retrieveAuditTrail = (
  id: ReturnType<typeof retrieveAuditTrailAction>['payload'],
) => {
  return get(pathJoin(routes.patronus.auditTrailId.replace(':id', id)));
};

export const retrieveAuditTrailEntries = (payload) => {
  return post(routes.patronus.auditTrailList, payload);
};
