import { WorkflowActionTypes } from 'app/modules/workflows/models';

export const selectWorkflows = (state: RootState) => {
  return state.workflows.workflows || [];
};

export const selectSubdispositions = (state: RootState) => {
  return state.workflows.subdispositions || [];
};

export const selectWorkflowsCount = (state: RootState) => {
  return state.workflows.count;
};

export const selectWorkflow = (state: RootState) => {
  return state.workflows.workflow;
};

export const selectRetrieveWorkflowsLoading = (state: RootState) =>
  Boolean(state.loading[WorkflowActionTypes.RETRIEVE_WORKFLOWS]);

export const selectRetrieveWorkflowLoading = (state: RootState) =>
  Boolean(state.loading[WorkflowActionTypes.RETRIEVE_WORKFLOW]);

export const selectCreateWorkflowLoading = (state: RootState) =>
  Boolean(state.loading[WorkflowActionTypes.CREATE_WORKFLOW]);

export const selectEditWorkflowLoading = (state: RootState) =>
  Boolean(state.loading[WorkflowActionTypes.EDIT_WORKFLOW]);

export const selectDeleteWorkflowLoading = (state: RootState) =>
  Boolean(state.loading[WorkflowActionTypes.DELETE_WORKFLOW]);
