import { FullEntityResponse } from 'app/modules/entities/types';

import { ENTITY_QUERY_KEYS } from 'app/modules/entitiesRefresh/queries/keys';

import { get } from 'app/shared/utils/fetchr';
import { useQuery } from '@tanstack/react-query';

export const useEntityDetails = <TSelect = FullEntityResponse>(
  entityId: string,
  select?: (data: FullEntityResponse) => TSelect,
  enabled: boolean = true,
) => {
  return useQuery({
    queryFn: () =>
      get<FullEntityResponse>(`/entities/${encodeURIComponent(entityId)}`),
    queryKey: ENTITY_QUERY_KEYS.getEntityDetails(entityId),
    select,
    staleTime: 10000,
    enabled: !!entityId && enabled,
  });
};
