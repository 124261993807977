import {
  Investigation,
  InvestigationSource,
  InvestigationStatus,
  InvestigationTableColumn,
} from 'app/modules/investigations/models';

import { LABEL_PREDEFINED_COLORS } from 'app/modules/entities/constants';

import { formatDateToNow } from 'app/shared/utils/date';
import { toSentenceCase } from 'app/shared/utils/string';
import getFullTagName from 'app/shared/utils/getFullTagName';

import {
  IconCalendar,
  IconCalendarTime,
  IconCircleCheck,
  IconTag,
  IconUser,
} from '@u21/tabler-icons';
import { IconArrowArrowRightInCircle } from 'app/shared/components/Icons/IconArrowRightInCircle';
import { U21DynamicColorChip } from 'app/shared/u21-ui/components/dashboard';
import {
  U21TableBodyCellProps,
  U21_NO_VALUE,
} from 'app/shared/u21-ui/components';

import { SubdispositionOption } from 'app/shared/types/actionTriggers';
import { DispositionChip } from 'app/modules/investigations/components/DispositionChip';
import { StatusChip } from 'app/modules/investigations/components/StatusChip';

const ASSIGNED_TO_COLUMN: InvestigationTableColumn = {
  id: 'assigned_to_name',
  accessor: (row) => row.assigned_to_name || U21_NO_VALUE,
  headerIcon: <IconUser />,
};

const CREATED_AT_COLUMN: InvestigationTableColumn = {
  id: 'created_at',
  headerIcon: <IconCalendar />,
};

const DEADLINE_FROMNOW_COLUMN: InvestigationTableColumn = {
  id: 'deadline',
  accessor: (row) =>
    row.status === 'OPEN'
      ? formatDateToNow(row.deadline?.deadline, { addSuffix: true })
      : undefined,
  headerIcon: <IconCalendarTime />,
};

const DEADLINE_DATE_COLUMN: InvestigationTableColumn = {
  id: 'deadline.deadline',
  accessor: (row) =>
    row.status === 'OPEN' && row.deadline?.deadline
      ? row.deadline.deadline
      : '',
  headerIcon: <IconCalendar />,
};

export const DISPOSITION_COLUMN: InvestigationTableColumn = {
  id: 'disposition',
  Cell: (props: U21TableBodyCellProps<Investigation, string>) => {
    const { value } = props;
    return <DispositionChip disposition={value} />;
  },
};

const DISPOSITIONED_DATE_COLUMN: InvestigationTableColumn = {
  id: 'dispositioned_at',
  headerIcon: <IconCalendar />,
};

const DISPOSITIONED_BY_COLUMN: InvestigationTableColumn = {
  id: 'dispositioned_by',
  headerIcon: <IconUser />,
};

const END_DATE_COLUMN: InvestigationTableColumn = {
  id: 'end_date',
  headerIcon: <IconCalendar />,
};

const SOURCE_COLUMN: InvestigationTableColumn = {
  id: 'object_source',
  Cell: (props: U21TableBodyCellProps<Investigation, InvestigationSource>) => {
    const { value } = props;
    const color = LABEL_PREDEFINED_COLORS[value];
    return (
      <U21DynamicColorChip color={color}>
        {toSentenceCase(value)}
      </U21DynamicColorChip>
    );
  },
  headerIcon: <IconArrowArrowRightInCircle />,
};

const START_DATE_COLUMN: InvestigationTableColumn = {
  id: 'start_date',
  headerIcon: <IconCalendar />,
};

export const STATUS_COLUMN: InvestigationTableColumn = {
  id: 'status',
  Cell: (props: U21TableBodyCellProps<Investigation, InvestigationStatus>) => {
    const { value } = props;
    return <StatusChip status={value} />;
  },
  headerIcon: <IconCircleCheck />,
};

const TAGS_COLUMN: InvestigationTableColumn = {
  id: 'tags',
  accessor: (row) => row.tags.map((i) => getFullTagName(i.type, i.name)),
  cellProps: {
    getShowLessText: () => 'Show fewer tags',
    getShowMoreText: (length) => `Show all ${length} tags`,
  },
  headerIcon: <IconTag />,
  type: 'list',
  disableSortBy: true,
};

const SUBDISPOSITION_COLUMN: InvestigationTableColumn = {
  id: 'subdispositions',
  accessor: (row) => {
    if (row.subdispositions === null) {
      return '';
    }
    return row.subdispositions.map(
      (i: SubdispositionOption) => `${i.title}:${i.name}`,
    );
  },
  cellProps: {
    getShowLessText: () => 'Show fewer subdispositions',
    getShowMoreText: (length) => `Show all ${length} subdispositions`,
  },
  type: 'list',
  disableSortBy: true,
};

export const INVESTIGATION_COLUMN_CONFIG = {
  [ASSIGNED_TO_COLUMN.id]: ASSIGNED_TO_COLUMN,
  [CREATED_AT_COLUMN.id]: CREATED_AT_COLUMN,
  [DEADLINE_DATE_COLUMN.id]: DEADLINE_DATE_COLUMN,
  [DEADLINE_FROMNOW_COLUMN.id]: DEADLINE_FROMNOW_COLUMN,
  [DISPOSITION_COLUMN.id]: DISPOSITION_COLUMN,
  [DISPOSITIONED_BY_COLUMN.id]: DISPOSITIONED_BY_COLUMN,
  [DISPOSITIONED_DATE_COLUMN.id]: DISPOSITIONED_DATE_COLUMN,
  [END_DATE_COLUMN.id]: END_DATE_COLUMN,
  [SOURCE_COLUMN.id]: SOURCE_COLUMN,
  [START_DATE_COLUMN.id]: START_DATE_COLUMN,
  [STATUS_COLUMN.id]: STATUS_COLUMN,
  [TAGS_COLUMN.id]: TAGS_COLUMN,
  [SUBDISPOSITION_COLUMN.id]: SUBDISPOSITION_COLUMN,
};
