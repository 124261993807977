import { SymbolTable } from 'app/modules/rulesAdvanced/lib/SymbolTable';

export const ACTION_EVENT_ADVANCED_SYMBOLS: SymbolTable = Object.freeze({
  txn_instrument_id: {
    type: 'FIELD',
    field: 'txn_instrument_id',
    label: 'txn_instrument_id',
    model: 'action_event',
    datatype: 'string',
    cardinality: 'many',
  },
  'client_fingerprint.client_fingerprint': {
    type: 'FIELD',
    field: 'client_fingerprint.client_fingerprint',
    label: 'client_fingerprint',
    model: 'action_event',
    datatype: 'string',
    cardinality: 'many',
  },
  'ip_address.ip_address': {
    type: 'FIELD',
    field: 'ip_address.ip_address',
    label: 'ip_address',
    model: 'action_event',
    datatype: 'string',
    cardinality: 'many',
  },
  'entity.first_esigned_event': {
    type: 'FIELD',
    field: 'entity.first_esigned_event',
    label: 'entity.first_esigned_event',
    model: 'action_event',
    datatype: 'datetime',
    cardinality: 'many',
  },
  'geolocation.country': {
    type: 'FIELD',
    field: 'geolocation.country',
    label: 'geolocation.country',
    model: 'action_event',
    datatype: 'string',
    cardinality: 'many',
  },
  'geolocation.city': {
    type: 'FIELD',
    field: 'geolocation.city',
    label: 'geolocation.city',
    model: 'action_event',
    datatype: 'string',
    cardinality: 'many',
  },
  'geolocation.postal_code': {
    type: 'FIELD',
    field: 'geolocation.postal_code',
    label: 'geolocation.postal_code',
    model: 'action_event',
    datatype: 'string',
    cardinality: 'many',
  },
  'geolocation.longitude': {
    type: 'FIELD',
    field: 'geolocation.longitude',
    label: 'geolocation.longitude',
    model: 'action_event',
    datatype: 'string',
    cardinality: 'many',
  },
  'geolocation.latitude': {
    type: 'FIELD',
    field: 'geolocation.latitude',
    label: 'geolocation.latitude',
    model: 'action_event',
    datatype: 'string',
    cardinality: 'many',
  },
});
