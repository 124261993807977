import { ReactElement } from 'react';

// Components
import { U21Tooltip } from 'app/shared/u21-ui/components';

// Models
import { DeadlinesConfigCalcStartDateType } from 'app/modules/deadlines/models';

// Utils
import { getFullTimeLabel } from 'app/modules/deadlines/utils';

interface OwnProps {
  duration?: number;
  manualDueDate: boolean;
  calcStartDate?: DeadlinesConfigCalcStartDateType;
  title?: string;
  children: ReactElement;
}

/* Not putting this in the models.ts file since it is only used here
 */
const START_DATE_TO_TEXT_MAP: {
  [K in DeadlinesConfigCalcStartDateType]: string;
} = {
  CREATED_AT: 'creation',
  LAST_EVENT: 'last event',
  EARLIEST_EVENT: 'earliest event',
};

export const DeadlineTooltip = ({
  children,
  title,
  duration,
  calcStartDate,
  manualDueDate = false,
}: OwnProps) => {
  if (duration === undefined || title === undefined) {
    return children;
  }

  const formattedDuration = getFullTimeLabel(duration);
  const settings = (
    <i>
      ({formattedDuration}{' '}
      {calcStartDate ? `since ${START_DATE_TO_TEXT_MAP[calcStartDate]}` : ''})
    </i>
  );
  const text = manualDueDate ? (
    'Manual Deadline'
  ) : (
    <>
      Global Deadline: <b>{title}</b> {settings}
    </>
  );
  return <U21Tooltip tooltip={text}>{children}</U21Tooltip>;
};
