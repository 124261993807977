import { HTMLProps, SyntheticEvent, useEffect } from 'react';

import { consoleWarn } from 'app/shared/utils/console';
import { getDOMProps } from 'app/shared/utils/react';

import { U21InputGroup } from 'app/shared/u21-ui/components/display/U21InputGroup';
import { U21TextField } from 'app/shared/u21-ui/components/input/text-field/U21TextField';
import { U21TextFieldProps } from 'app/shared/u21-ui/components/input/text-field/U21TextFieldInputProps';
import {
  U21Select,
  U21SelectProps,
} from 'app/shared/u21-ui/components/input/select/U21Select';

interface U21NumberUnitFieldValue<TUnit extends string> {
  value?: number;
  unit?: TUnit;
}

export interface U21NumberUnitFieldProps<TUnit extends string>
  extends Omit<HTMLProps<HTMLDivElement>, 'onChange' | 'value'> {
  autoFocus?: boolean;
  disabled?: boolean;
  error?: boolean;
  id?: string;
  onChange: (value: U21NumberUnitFieldValue<TUnit>, e: SyntheticEvent) => void;
  options: U21SelectProps<TUnit, false>['options'];
  numberProps?: Omit<
    U21TextFieldProps,
    'disabled' | 'error' | 'onChange' | 'value'
  >;
  unitProps?: Omit<
    U21SelectProps<TUnit, false>,
    'disabled' | 'error' | 'onChange' | 'options' | 'value'
  >;
  value?: U21NumberUnitFieldValue<TUnit>;
}

export const U21NumberUnitField = <TUnit extends string>({
  autoFocus,
  disabled,
  error,
  id,
  numberProps,
  onChange,
  options,
  unitProps,
  value: fieldValue = {},
  ...rest
}: U21NumberUnitFieldProps<TUnit>) => {
  const { value, unit } = fieldValue;
  useEffect(() => {
    if (!fieldValue) {
      consoleWarn('Unit should never be empty.');
    }
  }, [fieldValue]);

  return (
    <U21InputGroup disabled={disabled} error={error} {...getDOMProps(rest)}>
      <U21TextField
        autoFocus={autoFocus}
        disabled={disabled}
        error={error}
        id={id}
        onChange={(newValue: number, e) =>
          onChange({ unit, value: newValue }, e)
        }
        placeholder="Number of"
        type="number"
        value={value}
        {...numberProps}
      />
      <U21Select
        clearable={false}
        searchable={false}
        disabled={disabled}
        error={error}
        options={options}
        onChange={(newUnit: TUnit, e) => onChange({ unit: newUnit, value }, e)}
        placeholder="Unit"
        value={unit}
        {...unitProps}
      />
    </U21InputGroup>
  );
};
