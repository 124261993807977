import {
  FILTER_OPERATOR,
  FILTER_TYPE_OPERATOR_LABELS,
} from 'app/modules/filters/constants';
import { REPORT_TYPE_DISPLAY_TEXT_MAP } from 'app/modules/fincenSar/constants/shared';

interface Props {
  operator: typeof FILTER_OPERATOR.IS;
  value: string;
}

export const FilingTypeFilterDisplay = (props: Props) => {
  const { operator, value } = props;
  const text = REPORT_TYPE_DISPLAY_TEXT_MAP[value] ?? 'Unknown';
  return (
    <>
      <b>Filing type</b> {FILTER_TYPE_OPERATOR_LABELS[operator]} <b>{text}</b>
    </>
  );
};
