import { FC, HTMLProps, ReactNode } from 'react';

import { getDOMProps } from 'app/shared/utils/react';
import styled, { css } from 'styled-components';

interface U21InputGroupProps extends HTMLProps<HTMLDivElement> {
  borders?: boolean;
  children: ReactNode;
  disabled?: boolean;
  error?: boolean;
}

export const U21InputGroup: FC<U21InputGroupProps> = (props) => {
  const { children, borders = true, disabled, error, ...rest } = props;

  return (
    <Container
      $borders={borders}
      $disabled={disabled}
      $error={error}
      {...getDOMProps(rest)}
    >
      {children}
    </Container>
  );
};

interface StyleProps {
  $borders?: boolean;
  $disabled?: boolean;
  $error?: boolean;
}

const innerBorderLeftStyle = css<StyleProps>`
  border-left: ${(props) =>
    props.$borders ? `1px solid ${props.theme.palette.grey[500_32]}` : 'none'};
`;

const innerBorderRightStyle = css<StyleProps>`
  border-right: ${(props) =>
    props.$borders ? `1px solid ${props.theme.palette.grey[500_32]}` : 'none'};
`;

const Container = styled.div<StyleProps>`
  display: flex;

  /* remove inner border radii */
  & > *:not(:first-child) {
    .MuiOutlinedInput-notchedOutline {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }

    ${(props) =>
      props.$borders
        ? css`
            margin-left: -1px;
          `
        : css`
            margin-left: 0;

            .MuiOutlinedInput-notchedOutline {
              border-left: none;
            }
          `}
  }

  & > *:not(:last-child) .MuiOutlinedInput-notchedOutline {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  /* add hover color to outer border */
  :hover {
    ${(props) =>
      !props.$disabled &&
      !props.$error &&
      css`
        .MuiOutlinedInput-notchedOutline {
          border-color: ${props.theme.palette.grey[800]};
        }
      `}
  }

  /* keep inner borders grey */
  & > *:first-child .MuiOutlinedInput-notchedOutline {
    ${innerBorderRightStyle}
  }

  & > *:not(:first-child):not(:last-child) .MuiOutlinedInput-notchedOutline {
    ${innerBorderLeftStyle}
    ${innerBorderRightStyle}
  }

  & > *:last-child .MuiOutlinedInput-notchedOutline {
    ${innerBorderLeftStyle}
  }

  /* add 2px w/ color to outer border but keep inner borders grey */
  :focus-within {
    & > * .MuiOutlinedInput-notchedOutline {
      border: 2px solid
        ${(props) =>
          props.theme.palette[props.$error ? 'error' : 'primary'].main};
    }

    & > *:first-child .MuiOutlinedInput-notchedOutline {
      ${innerBorderRightStyle}
    }

    & > *:not(:first-child):not(:last-child) .MuiOutlinedInput-notchedOutline {
      ${innerBorderLeftStyle}
      ${innerBorderRightStyle}
    }

    & > *:last-child .MuiOutlinedInput-notchedOutline {
      ${innerBorderLeftStyle}
    }
  }

  /* make all inputs stretch to max height available */
  .MuiFormControl-root,
  .MuiFormControl-root .MuiOutlinedInput-root {
    height: 100%;
  }
`;
