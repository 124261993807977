// Models
import { AnalyticsEvents } from 'app/shared/u21-ui/analytics/models';

// Utils
import {
  formatPayload,
  getAnalyticsConfig,
} from 'app/shared/u21-ui/analytics/utils';

export const trackEvent = (
  event: AnalyticsEvents,
  props: Record<string, any> = {},
  state: Record<string, any> = {},
  additional: Record<string, any> = {},
) => {
  const config = getAnalyticsConfig();
  if (!config) {
    return;
  }

  const { name = event, createPayload = () => null } =
    config.events[event] || {};

  const payload = createPayload(props, state, additional);
  if (payload) {
    config.track(name, formatPayload(payload));
  }
};
