import {
  EditingSimpleDetectionModel,
  ModelValidationPayload,
  ScenarioConfig,
  ScenarioConfigs,
  ScenarioPayload,
} from 'app/modules/detectionModels/models';
import {
  NUMERIC_COMPARISON_MIN_FIELDS,
  ScenarioConstants,
} from 'app/modules/rules/models';
import { OrgScenarioConfig } from 'app/modules/orgSettings/models';

import { TIME_WINDOW_OPTIONS } from 'app/modules/rules/config/timeWindow';
import { VALIDATION_WINDOW_LIMIT } from 'app/modules/rules/constants';
import { WIDGET_VALIDATIONS } from 'app/modules/detectionModels/components/scenarioWidgets/helpers';

import { consoleError, consoleWarn } from 'app/shared/utils/console';
import {
  extractMustacheFields,
  isValidTree,
  windowCount,
} from 'app/modules/rules/helpers';
import { isEmpty, isUndefined } from 'lodash';
import {
  isCheckFraudRuleDSN,
  isCheckFraudRuleMSN,
  isConsortiumRule,
  isScenarioBuilderCheckFraudRule,
  isLegacySequenceRule,
  isWatchlistRule,
} from 'app/modules/detectionModels/helpers';
import {
  DORMANT_ACTIVITY_NAME,
  GRAPH_BASED_RULE_NAME,
  WATCHLIST_RULE_NAME,
} from 'app/modules/detectionModels/constants';
import { validateConsortiumContentStep } from 'app/modules/detectionModels/components/scenarioWidgets/consortium/validation';
import { validateWatchlistContentStep } from 'app/modules/detectionModels/components/scenarioWidgets/watchlist/validation';
import { validateCheckFraudRuleContentStep } from 'app/modules/detectionModels/components/scenarioWidgets/checkfraud/validation';
import { isAchFraudScenarioValid } from 'app/modules/detectionModels/components/scenarioWidgets/achFraud/validation';

const isValidScenario = (
  scenario: ScenarioPayload,
  scenarioConfigs: ScenarioConfigs,
): boolean => {
  // Check we have a valid scenario
  if (!scenario.name) {
    return false;
  }

  // Get scenario config
  const scenarioConfig = scenarioConfigs[scenario.name];
  if (!scenarioConfig) {
    return false;
  }

  // ============= Validate scenario parameters =============
  const scenarioParameters = scenario.parameters;
  if (isEmpty(scenarioParameters)) {
    return false;
  }

  const requiredFields = extractMustacheFields(scenarioConfig.content);

  const scenarioHasValidRequiredFields = requiredFields.every(
    (requiredField) => {
      // Skip these fields, they require a custom validation
      if (
        requiredField.includes(ScenarioConstants.NUMERIC_COMPARISON) ||
        requiredField.includes(ScenarioConstants.NUMERIC_COMPARISON_REFACTOR) ||
        requiredField.includes(ScenarioConstants.TIME_WINDOW)
      ) {
        return true;
      }

      // Validate fields
      const scenarioParameterValue = scenarioParameters[requiredField];

      if (
        !isLegacySequenceRule(scenario.name) &&
        (scenarioParameterValue === null ||
          scenarioParameterValue === undefined ||
          scenarioParameterValue === '' ||
          (Array.isArray(scenarioParameterValue) &&
            scenarioParameterValue.length === 0))
      ) {
        consoleWarn('Missing ', requiredField);
        return false;
      }
      return true;
    },
  );

  if (!scenarioHasValidRequiredFields) {
    return false;
  }

  // Validate custom parameters
  // =========== Numeric comparison =============
  const requiredNumCompFields = requiredFields.filter(
    (field) =>
      field.includes(ScenarioConstants.NUMERIC_COMPARISON) ||
      field.includes(ScenarioConstants.NUMERIC_COMPARISON_REFACTOR),
  );

  // IF the scenario has at least one numeric comparison field
  if (requiredNumCompFields.length > 0) {
    // Validate all
    const allNumCompValid = requiredNumCompFields.every((currNCField) => {
      let nCField = currNCField;

      if (!nCField.startsWith('$')) {
        nCField = `$${nCField}`;
      }
      const comparisonType = `${scenarioParameters[`${nCField}-type`]}|${
        scenarioParameters[`${nCField}-comparator`]
      }`;
      const comparisonMinRequiredFields: number =
        NUMERIC_COMPARISON_MIN_FIELDS[comparisonType] ||
        Number.POSITIVE_INFINITY;
      const comparisonPresentFields: number = Object.keys(
        scenarioParameters,
      ).filter((key) => key.includes(nCField)).length;
      return comparisonPresentFields >= comparisonMinRequiredFields;
    });

    if (!allNumCompValid) {
      return false;
    }
  }

  // =========== Time Window =============
  // TODO, not validating for now because we are changing the structure here
  if (isLegacySequenceRule(scenario.name)) {
    const timeWindow = TIME_WINDOW_OPTIONS.find(
      (currWindow) => currWindow.value === scenario.parameters.$time_window,
    );

    const sequenceDurationMinutes =
      (scenarioParameters.sequence_duration_days || 0) * 1440;

    return (
      !isUndefined(timeWindow) &&
      timeWindow.minutes <= 87840 &&
      sequenceDurationMinutes < timeWindow.minutes
    );
  }

  // =========== Embedded Filters =============
  const embeddedFiltersOptions = scenarioConfig.parameter_options;
  const presentEmbeddedFilters = scenario.embeddedFilters;
  if (embeddedFiltersOptions && !isEmpty(embeddedFiltersOptions)) {
    const validFilters = Object.keys(embeddedFiltersOptions).every(
      (parameter) => {
        const isRequired = Boolean(embeddedFiltersOptions[parameter].required);
        if (
          Object.prototype.hasOwnProperty.call(
            presentEmbeddedFilters,
            parameter,
          )
        ) {
          const embeddedFilter = presentEmbeddedFilters[parameter] || {};
          // Empty trees are valid trees
          const hasAValidTree = isValidTree(embeddedFilter.query_tree);
          if (isRequired) {
            const hasTags =
              (
                embeddedFilter.inclusion_tags ||
                embeddedFilter.inclusion_tag_names ||
                []
              ).length > 0 ||
              (
                embeddedFilter.exclusion_tags ||
                embeddedFilter.exclusion_tag_names ||
                []
              ).length > 0;
            const hasTree = Boolean(embeddedFilter.raw_sql);

            // IF we have a tree make sure is valid ELSE check if we have tags and a valid tree (can be empty but not half filled)
            return hasTree ? hasAValidTree : hasTags && hasAValidTree;
          }
          return hasAValidTree;
        }
        // We don't have any filter for this field meaning it is empty, return true if it is not required
        return isRequired === false;
      },
    );
    if (!validFilters) {
      return false;
    }
  }
  if (isConsortiumRule(scenario.name)) {
    const consortiumValidation =
      validateConsortiumContentStep(scenarioParameters);
    if (!consortiumValidation) {
      return false;
    }
  }
  if (
    isCheckFraudRuleDSN(scenario.name) ||
    isCheckFraudRuleMSN(scenario.name)
  ) {
    const checkFraudRuleValid =
      validateCheckFraudRuleContentStep(scenarioParameters);
    if (!checkFraudRuleValid) {
      return false;
    }
  }
  if (isWatchlistRule(scenario.name)) {
    const isWatchlistStepValid = validateWatchlistContentStep(
      scenarioParameters.watchlist,
    );

    if (!isWatchlistStepValid) {
      return false;
    }
  }
  if (
    isScenarioBuilderCheckFraudRule(scenario.name) &&
    scenarioParameters.check_types?.length === 0
  ) {
    return false;
  }
  if (!isAchFraudScenarioValid(scenario.name, scenarioParameters)) {
    return false;
  }

  // =========== Validate Widgets =============
  const { widgets } = scenarioConfig;
  if (widgets) {
    return widgets.every((widgetName) => {
      const validator = WIDGET_VALIDATIONS[widgetName];
      if (validator) {
        return validator(scenarioParameters);
      }
      consoleError('Unknown widget: ', widgetName);
      return false;
    });
  }

  // We made it here, all validations passed
  return true;
};

export const validateSimpleModelScenario = (
  editingModel: EditingSimpleDetectionModel,
) => {
  return Boolean(editingModel.scenario?.name);
};

export const validateSimpleModelScenarioContent = (
  editingModel: EditingSimpleDetectionModel,
  scenarioConfigs: ScenarioConfigs,
) => {
  if (editingModel.scenario) {
    return isValidScenario(editingModel.scenario, scenarioConfigs);
  }
  return false;
};

export const validateSimpleModelMetadata = (
  editingModel: EditingSimpleDetectionModel,
) =>
  Boolean(
    editingModel.metadata &&
      editingModel.metadata.title.length > 0 &&
      editingModel.metadata.description.length > 0,
  );

const hasValidModelValidationPayload = (
  validationPayload: ModelValidationPayload,
  orgScenarioConfig: OrgScenarioConfig,
  validateFrequency = true,
  enforceNumOfWindowsLimit = true,
) => {
  const validStart = validationPayload.start_time.length > 0;

  // Not all models require an execution_frequency
  let validFrequency = true;
  if (validateFrequency) {
    validFrequency = validationPayload.execution_frequency.length > 0;
    // Validate windows
    let validWindows = true;
    if (enforceNumOfWindowsLimit) {
      const validationLimit =
        orgScenarioConfig?.values?.validation_windows_generation_limit ||
        VALIDATION_WINDOW_LIMIT;

      const numberOfWindows = windowCount(
        validationPayload?.start_time || '',
        validationPayload?.end_time || '',
        validationPayload?.execution_frequency || '',
      );
      validWindows = numberOfWindows <= validationLimit;
    }
    validFrequency = validFrequency && validWindows;
  }

  return validStart && validFrequency;
};

const shouldValidateExecFrequency = (
  config: ScenarioConfig | Record<string, never>,
): boolean => {
  if ([GRAPH_BASED_RULE_NAME, WATCHLIST_RULE_NAME].includes(config.name)) {
    return true;
  }
  if ([DORMANT_ACTIVITY_NAME].includes(config.name)) {
    return false;
  }

  if (['WINDOWED', 'NON_WINDOWED'].includes(config.scenario_type || '')) {
    return true;
  }

  return false;
};

export const validateValidationPicker = (
  editingModel: EditingSimpleDetectionModel,
  scenarioConfigs: ScenarioConfigs,
  orgScenarioConfig: OrgScenarioConfig,
) => {
  const config = scenarioConfigs[editingModel.scenario?.name || ''] || {};
  const validateExecFrequency = shouldValidateExecFrequency(config);
  const enforceNumOfWindowsLimit =
    config.scenario_type === 'WINDOWED' ||
    [WATCHLIST_RULE_NAME].includes(config.name);
  return Boolean(
    editingModel.validationPayload &&
      hasValidModelValidationPayload(
        editingModel.validationPayload,
        orgScenarioConfig,
        validateExecFrequency,
        enforceNumOfWindowsLimit,
      ),
  );
};
