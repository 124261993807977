import { call, put, takeLatest, select } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { AlertsActionTypes } from 'app/modules/alerts/models';

// Actions
import {
  associateAlertsWithCases as action,
  associateAlertsWithCasesSuccess as success,
} from 'app/modules/alerts/actions';

// Selectors
import { selectAlert } from 'app/modules/alerts/selectors';

// API
import { associateAlertsWithCases as api } from 'app/shared/api/alerts';

const rootAction = AlertsActionTypes.ASSOCIATE_ALERTS_WITH_CASES;
export function* associateAlertsWithCasesSaga({
  payload,
}: ReturnType<typeof action>) {
  const {
    source_alert_id: sourceAlertId,
    alertsPageChanged,
    ...apiPayload
  } = payload;
  const config = {
    rootAction,
    errorToastMessage:
      'Something went wrong, unable to associate alert(s) with existing case(s).',
    successToastMessage: 'Alert(s) successfully associated with case(s)',
    request: call(api, apiPayload),
    success: function* onSuccess() {
      // only update alert in reducer if page has not changed
      const currentAlert = yield select(selectAlert);
      const alertsPageChangedFlag: boolean = sourceAlertId !== currentAlert.id;

      yield put(
        success({ ...payload, alertsPageChanged: alertsPageChangedFlag }),
      );
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watch() {
  yield takeLatest(rootAction, associateAlertsWithCasesSaga);
}
