import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { EntitiesActionTypes } from 'app/modules/entities/models';

// Actions
import {
  retrieveEntityPreview as retrieveEntityAction,
  retrieveEntitySuccess,
} from 'app/modules/entities/actions';
import { retrieveCommentsSuccess } from 'app/modules/comments/actions';

// Constants
import { getEntity } from 'app/shared/api/entities';

const rootAction = EntitiesActionTypes.RETRIEVE_ENTITY_PREVIEW;
export function* retrieveEntityPreview({
  payload,
}: ReturnType<typeof retrieveEntityAction>) {
  const config = {
    rootAction,
    request: call(getEntity, payload),
    success: function* onSuccess(result) {
      yield put(retrieveEntitySuccess(result));
      // update comments
      if (result.comments) {
        yield put(retrieveCommentsSuccess(result.comments));
      }
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watchRetrieveEntityPreview() {
  yield takeLatest(rootAction, retrieveEntityPreview);
}
