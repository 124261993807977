import { Filter } from 'app/modules/filters/models';

import { ALL_INSTRUMENT_FILTER_OPTIONS } from 'app/modules/instruments/filters';
import { LocalStorageKeys } from 'app/shared/constants/localStorage';

import { getLocalStorageJSON } from 'app/shared/utils/localStorage';
import { getValidFilters } from 'app/modules/filters/utils';
import { selectFilters } from 'app/modules/instruments/selectors';
import { u21CreateAsyncThunk } from 'app/shared/thunk/u21CreateAsyncThunk';
import { u21CreateSlice } from 'app/shared/thunk/u21CreateSlice';

const INSTRUMENTS_NAME = 'instruments';

interface InstrumentsState {
  filters: Filter[];
}

const initialState: InstrumentsState = {
  filters: getValidFilters(
    getLocalStorageJSON(LocalStorageKeys.INSTRUMENT_FILTERS),
    ALL_INSTRUMENT_FILTER_OPTIONS,
  ),
};

export const setInstrumentFilters = u21CreateAsyncThunk<Filter[], Filter[]>(
  `${INSTRUMENTS_NAME}/SET_INSTRUMENT_FILTERS`,
  (payload) => {
    localStorage.setItem(
      LocalStorageKeys.INSTRUMENT_FILTERS,
      JSON.stringify(payload),
    );
    return payload;
  },
);

export const addInstrumentFilter = u21CreateAsyncThunk<Filter>(
  `${INSTRUMENTS_NAME}/ADD_INSTRUMENT_FILTERS`,
  async (payload, { dispatch, getState }) => {
    const currentFilters = selectFilters(getState());
    let exists = false;
    const newFilters = currentFilters.map((i) => {
      if (i.key === payload.key) {
        exists = true;
        return payload;
      }
      return i;
    });
    if (!exists) {
      newFilters.push(payload);
    }
    await dispatch(setInstrumentFilters(newFilters));
  },
);

export const instrumentsSlice = u21CreateSlice({
  initialState,
  name: INSTRUMENTS_NAME,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setInstrumentFilters.fulfilled, (draft, action) => {
      draft.filters = action.payload;
    });
  },
});

export const INSTRUMENTS_SLICE_NAME = instrumentsSlice.name;
export default instrumentsSlice.reducer;
