import { useMemo } from 'react';
import { useSelector } from 'react-redux';

// Types
import {
  EventHistogram,
  HistogramEvent,
  EventHistogramResponse,
} from 'app/modules/histogram/models';
import {
  U21HistogramSeriesDatapoint,
  U21HistogramDateRange,
  U21HistogramSeries,
} from 'app/shared/u21-ui/components';

// Selectors
import { selectLumosConfig } from 'app/modules/orgSettings/selectors';

// Utils
import { generateColor } from 'app/shared/utils/stringToColor';

export const transformHistogramResponse = (
  histogram: EventHistogramResponse,
): EventHistogram => {
  return {
    ...histogram,
    transaction_codes: histogram.transaction_codes.map((i) => i || 'Unknown'),
  };
};

export const hasData = (histogram: EventHistogram): boolean => {
  const { histogram_data: histogramData, transaction_codes: transactionCodes } =
    histogram;

  // If there aren't event types specified
  if (!transactionCodes.length) {
    return false;
  }

  // If there aren't actually any events
  if (!histogramData.in.length && !histogramData.out.length) {
    return false;
  }

  return true;
};

/*
  Takes a list of transaction codes and returns an object containing:
  `transactionCodeMap` - an object mapping index to implied transaction code
  `codesToEvents` - an initial object that will map transaction code to its data points
*/
export const parseTransactionCodes = (
  transactionCodes: string[],
): {
  transactionCodeMap: Record<number, string>;
  codesToEvents: Record<string, U21HistogramSeriesDatapoint[]>;
} => {
  // Mapping the array index of value in an event to the transaction_code it implies
  const transactionCodeMap: Record<number, string> = {};
  const codesToEvents: Record<string, U21HistogramSeriesDatapoint[]> = {};

  transactionCodes.forEach((transactionCode, idx) => {
    transactionCodeMap[idx] = transactionCode;
    codesToEvents[transactionCode] = [];
  });
  if (transactionCodes.length) {
    // Doing -1 in the case of an event being populated with all zeroes
    // eslint-disable-next-line prefer-destructuring
    transactionCodeMap[-1] = transactionCodes[0];
  }

  return {
    transactionCodeMap,
    codesToEvents,
  };
};

/*
  Takes an event and map with transaction code information and
  returns an tuple containing the event type and coordinates
*/
export const getValuesFromEvent = ({
  event,
  transactionCodeMap,
}: {
  event: HistogramEvent;
  transactionCodeMap: Record<number, string>;
}): [string, U21HistogramSeriesDatapoint][] | undefined => {
  /*
    Explanation:
    1. Checking that event isn't empty
    2. Checking the timestamp (the first value) isn't an empty string
    3. Checking that the event actually lines up with the provided transaction codes. In addition to
    timestamp and actual values, the BE appends a zero to the end of the array hence adding 1 (not two
    because the object has one extra key `-1`)
   */
  if (
    !event.length ||
    !event[0] ||
    event.length !== Object.keys(transactionCodeMap).length + 1
  ) {
    return undefined;
  }

  const output: [string, U21HistogramSeriesDatapoint][] = [];

  const timestamp = `${event[0]}Z`; // timestamp is missing Z to indicate UTC
  const withoutTimestamp = event.slice(1) as number[];
  withoutTimestamp.forEach((value, idx) => {
    // Ignore if the column value is zero
    if (value === 0) {
      return;
    }

    const eventType = transactionCodeMap[idx];

    output.push([
      eventType,
      {
        x: timestamp,
        y: value,
      },
    ]);
  });

  return output;
};

const sortAlphabeticallyByName = (seriesA, seriesB) => {
  if (seriesA.name.toLowerCase() < seriesB.name.toLowerCase()) {
    return -1;
  }
  return 1;
};

/*
  What this function is doing is taking the histogram which looks like
  {
    histogram_data: {
      in: [['<timestamp>', txnCodeAValue, txnCodeBValue, ...]],
      out: [['<timestamp>', txnCodeAValue, txnCodeBValue, ...]]
    },
    transaction_codes: ['<txnCodeA>', '<txnCodeB>', ...]
  }

  Each "event" looks like this
  ['<timestamp>', 0, 0, actualValue, 0, 0]
  Explanation: It's populated with zeros except at the index of the implied txn_code so
  if transaction_codes was ['a', 'b', 'c', 'd', 'e'], this would imply type 'c' event

  And converting to a series for a stacked column chart 
  See: https://apexcharts.com/javascript-chart-demos/column-charts/stacked/
  [{ name: '<txnCodeA>', data: [txnCodeAPoint_0, txnCodeAPoint_1...]},
  { name: '<txnCodeB>', data: [txnCodeBPoint_0, txnCodeBPoint_1...]}...]
*/
export const histogramToSeries = (
  histogram: EventHistogram,
  range: U21HistogramDateRange = [undefined, undefined],
  typesSet: Set<string> = new Set(histogram.transaction_codes),
): U21HistogramSeries => {
  const { histogram_data: histogramData, transaction_codes: transactionCodes } =
    histogram;

  // If there isn't enough data, don't bother
  if (!hasData(histogram)) {
    return [];
  }

  const [startDate, endDate] = range;

  const { transactionCodeMap, codesToEvents } =
    parseTransactionCodes(transactionCodes);
  const parseEvent = (event: HistogramEvent) => {
    const result = getValuesFromEvent({ event, transactionCodeMap });

    if (result) {
      result.forEach(([eventType, coord]) => {
        const timestamp = new Date(coord.x);
        if (
          (!startDate || timestamp >= startDate) &&
          (!endDate || timestamp < endDate)
        ) {
          codesToEvents[eventType].push(coord);
        }
      });
    }
  };

  histogramData.in.forEach(parseEvent);
  histogramData.out.forEach(parseEvent);

  // Looping over transaction_codes to maintain order
  return transactionCodes
    .filter((i) => typesSet.has(i))
    .map((i) => ({
      name: i,
      data: codesToEvents[i],
    }))
    .filter((i) => i.data.length)
    .sort(sortAlphabeticallyByName);
};

export const useHistogramColors = (
  series: U21HistogramSeries = [],
): string[] => {
  const { colors } = useSelector(selectLumosConfig);
  return useMemo(
    () => series.map(({ name = '' }) => generateColor(name, colors)),
    [colors, series],
  );
};
