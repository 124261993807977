import styled from 'styled-components';

import { U21Tooltip, U21Typography } from 'app/shared/u21-ui/components';

interface Props {
  values: (number | string)[];
}

export const FilterMoreDisplay = (props: Props) => {
  const { values } = props;
  if (!values.length) {
    return null;
  }
  if (values.length === 1) {
    return <b>{values[0]}</b>;
  }
  const [first, ...rest] = values;
  return (
    <>
      <b>{first}</b> or{' '}
      <StyledU21Tooltip
        tooltip={rest.map((i) => (
          <U21Typography key={i} color="inherit" ellipsis variant="inherit">
            {i}
          </U21Typography>
        ))}
      >
        <U21Typography inline variant="subtitle2">
          {rest.length} more
        </U21Typography>
      </StyledU21Tooltip>
    </>
  );
};

const StyledU21Tooltip = styled(U21Tooltip)`
  .MuiTooltip-tooltip {
    max-width: 400px;
  }
`;
