import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';
import { call, put, takeLatest } from 'redux-saga/effects';

// Models
import { UploadsActionTypes } from 'app/modules/uploads/models';

// Api
import { getAllDatafiles } from 'app/shared/api/uploads';

// Actions
import {
  retrieveDatafiles as retrieveDatafilesAction,
  retrieveDatafilesSuccess,
} from 'app/modules/uploads/actions';

const rootAction = UploadsActionTypes.RETRIEVE_DATAFILES;
export function* retrieveDatafiles({
  payload,
}: ReturnType<typeof retrieveDatafilesAction>) {
  const config = {
    rootAction,
    errorToastMessage:
      'There was a problem loading datafiles. Please try again or contact support.',
    request: call(getAllDatafiles, payload),
    success: function* onSuccess(result) {
      yield put(retrieveDatafilesSuccess(result));
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watch() {
  yield takeLatest(UploadsActionTypes.RETRIEVE_DATAFILES, retrieveDatafiles);
}
