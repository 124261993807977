import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { RulesActionTypes } from 'app/modules/rules/models';
import { GlobalWhitelistPaginatedPayload } from 'app/modules/entities/models';

// Actions
import {
  removeEntitiesFromGlobalWhitelist as removeEntitiesFromGlobalWhitelistAction,
  retrieveEntitiesFromGlobalWhitelist,
} from 'app/modules/rules/actions';
import { sendSuccessToast } from 'app/shared/toasts/actions';

// API
import { removeEntitiesFromGlobalWhitelist as removeEntitiesFromGlobalWhitelistApi } from 'app/shared/api/entities';

// Constants
import { INITIAL_ENTITY_WHITELIST_PAGINATION_SETTINGS } from 'app/modules/rules/constants';

const rootAction = RulesActionTypes.REMOVE_GLOBALWHITELIST_ENTITIES;
function* removeWhitelistEntitiesForRule({
  payload,
}: ReturnType<typeof removeEntitiesFromGlobalWhitelistAction>) {
  const entityMessage =
    payload.entity_external_ids.length > 1 ? 'entities' : 'entity';
  const config = {
    rootAction,
    errorToastMessage: 'Error removing entities from whitelist',
    request: call(removeEntitiesFromGlobalWhitelistApi, payload),
    success: function* onSuccess() {
      yield put(
        sendSuccessToast(`Removed ${entityMessage} from rule whitelist`),
      );
      // Get new whitelisted entities with default pagination
      const request: GlobalWhitelistPaginatedPayload = {
        offset: INITIAL_ENTITY_WHITELIST_PAGINATION_SETTINGS.pageOffset,
        limit: INITIAL_ENTITY_WHITELIST_PAGINATION_SETTINGS.pageLimit,
      };
      yield put(retrieveEntitiesFromGlobalWhitelist(request));
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watchRemoveWhitelistEntitiesForRuleStatus() {
  yield takeLatest(rootAction, removeWhitelistEntitiesForRule);
}
