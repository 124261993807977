import { createSelector } from 'reselect';

import { initialLinkAnalysisFilters } from 'app/modules/filtersOld/reducer';

export const selectLinkAnalysisFilters = (state: RootState) => {
  return state.filters.linkAnalysis || { ...initialLinkAnalysisFilters };
};

// Used when we want to listen to specific filter changes
export function selectLinkAnalysisFilterValue<
  T extends keyof typeof initialLinkAnalysisFilters,
>(key: T) {
  return createSelector(selectLinkAnalysisFilters, (filters) => filters[key]);
}
