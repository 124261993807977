import { Options as HighChartsOptions, SeriesOptionsType } from 'highcharts';
import { Dictionary } from 'lodash';

// Models
import { Layout } from 'react-grid-layout';
import { TimeRange } from 'app/shared/models';

// Constants
import mockResponses from 'app/modules/insights/tests/mock-responses';

export enum InsightsActionTypes {
  RETRIEVE_CHART_DEFINITIONS = '@@insights/RETRIEVE_CHART_DEFINITIONS',
  RETRIEVE_CHART_DEFINITIONS_SUCCESS = '@@insights/RETRIEVE_CHART_DEFINITIONS_SUCCESS',

  RETRIEVE_DASHBOARDS = '@@insights/RETRIEVE_DASHBOARDS',
  RETRIEVE_DASHBOARDS_SUCCESS = '@@insights/RETRIEVE_DASHBOARDS_SUCCESS',

  RETRIEVE_DASHBOARD = '@@insights/RETRIEVE_DASHBOARD',
  RETRIEVE_DASHBOARD_SUCCESS = '@@insights/RETRIEVE_DASHBOARD_SUCCESS',

  RETRIEVE_CHARTS = '@@insights/RETRIEVE_CHARTS',

  RETRIEVE_CHART = '@@insights/RETRIEVE_CHART',
  RETRIEVE_CHART_SUCCESS = '@@insights/RETRIEVE_CHART_SUCCESS',

  CREATE_DASHBOARD = '@@insights/CREATE_DASHBOARD',
  CREATE_DASHBOARD_SUCCESS = '@@insights/CREATE_DASHBOARD_SUCCESS',

  DUPLICATE_DASHBOARD = '@@insights/DUPLICATE_DASHBOARD',
  DUPLICATE_DASHBOARD_SUCCESS = '@@insights/DUPLICATE_DASHBOARD_SUCCESS',

  EDIT_DASHBOARD = '@@insights/EDIT_DASHBOARD',
  EDIT_DASHBOARD_SUCCESS = '@@insights/EDIT_DASHBOARD_SUCCESS',

  EDIT_DASHBOARD_CHARTS_LAYOUT = '@@insights/EDIT_DASHBOARD_CHARTS_LAYOUT',
  EDIT_DASHBOARD_CHARTS_LAYOUT_SUCCESS = '@@insights/EDIT_DASHBOARD_CHARTS_LAYOUT_SUCCESS',

  DELETE_DASHBOARD = '@@insights/DELETE_DASHBOARD',
  DELETE_DASHBOARD_SUCCESS = '@@insights/DELETE_DASHBOARD_SUCCESS',

  CLEAR_DASHBOARD = '@@insights/CLEAR_DASHBOARD',

  EDIT_CHART = '@@insights/EDIT_CHART',
  EDIT_CHART_SUCCESS = 'EDIT_CHART_SUCCESS',

  DELETE_CHART_SUCCESS = '@@insights/DELETE_CHART_SUCCESS',
}

export type CreateDashboardResponse = DashboardDetails;
export type EditChartResponse = typeof mockResponses.editChartResponse;
export type EditDashboardResponse = DashboardDetails;
export type RetrieveChartResponse = typeof mockResponses.retrieveChart;
export type RetrieveChartDefinitionsResponse =
  typeof mockResponses.retrieveChartDefinitions;
export type RetrieveDashboardResponse = DashboardDetails;
export type DuplicateDashboardResponse = DashboardDetails;
export interface RetrieveDashboardsResponse {
  dashboards: DashboardSummary[];
}

export type ArticleName =
  | 'alert'
  | 'case'
  | 'entity'
  | 'event'
  | 'instrument'
  | 'rule'; // corresponds to PERMITTED_TABLE_TYPES keys on patronus insights.py

export type SortDirection = 'ascending' | 'descending';

export type WidgetType = 'card' | 'table' | 'chart';

export type ChartType =
  | 'line'
  | 'pie'
  | 'table'
  | 'column'
  | 'stacked_column'
  | SeriesOptionsType['type']
  | '';

interface ChartFormatOption {
  title: ChartType;
  id: number;
}

export interface ChartDefinitionSummary {
  description: string;
  title: string;
  id: number;
}

export type TimeGranularityFilter = 'DAY' | 'WEEK' | 'MONTH' | 'YEAR' | '';

export interface DashboardSummary {
  description: string;
  title: string;
  id: number;
  time_bucket: TimeGranularityFilter | '';
  filtered_org_id: number | null;
  filtered_unit_id: number | null;
  filters?: DashboardFilter[];
}
// used for filter cases and alerts on charts by status
export type InsightsFiltersStatus = 'OPEN' | 'CLOSED' | '';

export interface DashboardDetails extends DashboardSummary {
  id: number;
  title: string;
  description: string;
  charts: number[];
  charts_layout: Layout[] | null;
  // applied filters to a dashboard, not including time_bucket
  start_date: string | null;
  end_date: string | null;
}

type ChartFilterOption =
  | 'agent_id'
  | 'status'
  | 'tag.name'
  | 'tag.type'
  | 'team_id';

export interface ChartDetails extends HighChartsOptions {
  id: number;
  // a string, only thing that could not extend high charts options
  title: any;
  description: string;
  definition: number;
  assigned_chart_format_id: number;
  assigned_chart_format_name: string;
  object: string;
  series: HighChartsOptions['series'];
  xAxis: HighChartsOptions['xAxis'];
  x_label: string;
  y_label: string;
  available_formats: ChartFormatOption[];
  colors?: string[];
  credits?: { enabled: boolean };
  chart: HighChartsOptions['chart'];
  filter_options: ChartFilterOption[];
  accumulated?: Dictionary<number>;
  widget_type: WidgetType;

  // Array with all the filters applied to the chart, even if a selected filter is part of 'filter_options' we don't always apply it.
  // This array might not contain the right filters for table charts.
  filters_applied?: string[];
}

export interface CardDetails {
  chart_data: {
    value: string | number | boolean;
  };
}

export interface InsightsState {
  chart_definitions: ChartDefinitionSummary[];
  chart_definitions_count: number;
  dashboards: DashboardSummary[];
  dashboard: DashboardDetails;
  charts: { [id: string]: ChartDetails };
}

export interface CreateDashboardPayload {
  title: string;
  description: string;
  charts_layout?: Layout[];
}

// these filters are global filters to the current dashboard used in the edit_one_dashboard api of insights.py in patronus
// these filters are an AND, not an OR
export type DashboardFilter =
  | {
      // if we have multiple agent ids, we will send many of these filters
      name: 'agent_id';
      // Agent id, sent as a number and used as a number on the FE
      // BE converts it to a string
      value: number | string;
    }
  | {
      // if we have multiple org ids, we will send many of these filters
      name: 'org_id';
      value: number | string;
    }
  | {
      // if we have multiple unit ids, we will send many of these filters
      name: 'unit_id';
    }
  | {
      // if we have multiple team ids, we will send many of these filters
      name: 'team_id';
      value: number | string;
    }
  | {
      // currently used as open / closed for case and alert tables
      name: 'status';
      value: InsightsFiltersStatus;
    }
  | {
      // if we want to filter by the full tag, send both the tag.name and tag.type in separate objects
      name: 'tag.name';
      value: string;
    }
  | {
      name: 'tag.type';
      value: string;
    };

export interface EditDashboardPayload {
  id: number;
  time_range?: TimeRange;
  title?: string;
  description?: string;
  charts_layout?: Layout[];
  chart_definitions?: number[];
  filtered_org_id?: number;
  filtered_unit_id?: number;
  chart_to_delete?: number;
  time_bucket?: TimeGranularityFilter;
  filters?: DashboardFilter[];
  // to indicate to retrieve new chart data after the edit
  // this is used so we don't get new charts on a title / description change in the dashboard
  retrieveNewCharts?: boolean;
}

export interface EditChartPayload {
  id: number;
  title: string;
  description: string;
  assigned_chart_format_id?: number;
}

// offset, sortDirection and sortColumn is only req. when
// chart retrieval is a table type
interface RetrieveChartTablePayload {
  // table filters
  limit?: number;
  offset?: number;
  sort_direction?: SortDirection;
  sort_column?: string;
}

export interface RetrieveChartPayload extends RetrieveChartTablePayload {
  id: number;
}

export interface EditDashboardChartsLayoutPayload {
  id: number;
  charts_layout: Layout[];
}

export interface CreateEditDashboardFormValues {
  title: string;
  description?: string;
}
