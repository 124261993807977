import retrieveChartDefinitions from 'app/modules/insights/sagas/retrieveChartDefinitions';
import retrieveDashboards from 'app/modules/insights/sagas/retrieveDashboards';
import retrieveDashboard from 'app/modules/insights/sagas/retrieveDashboard';
import retrieveChart from 'app/modules/insights/sagas/retrieveChart';
import retrieveCharts from 'app/modules/insights/sagas/retrieveCharts';
import createDashboard from 'app/modules/insights/sagas/createDashboard';
import editDashboard from 'app/modules/insights/sagas/editDashboard';
import editDashboardChartsLayout from 'app/modules/insights/sagas/editDashboardChartsLayout';
import duplicateDashboard from 'app/modules/insights/sagas/duplicateDashboard';
import deleteDashboard from 'app/modules/insights/sagas/deleteDashboard';
import editChart from 'app/modules/insights/sagas/editChart';

const sagaRegistry = [
  retrieveChartDefinitions,
  retrieveDashboards,
  retrieveDashboard,
  createDashboard,
  editDashboard,
  editDashboardChartsLayout,
  duplicateDashboard,
  deleteDashboard,
  retrieveChart,
  editChart,
  retrieveCharts,
];

export default sagaRegistry;
