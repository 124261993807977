import produce, { Draft } from 'immer';

// Models
import {
  WebhookActionTypes,
  WebhookState,
  Webhook,
} from 'app/modules/webhooks/models';
import WebhookReduxActions from 'app/modules/webhooks/actions';

export const initialState: WebhookState = {
  webhooks: [],
  webhook: {
    id: -1,
    org_name: '',
    action_types: [],
    url: '',
    is_active: false,
    secret_key: '',
    created_at: '',
  } as Webhook,
  actionTypes: [],
  paginatedWebhookAttempts: {
    count: 0,
    webhook_callback_attempts: [],
  },
};

const reducer = (state = initialState, action: WebhookReduxActions) => {
  return produce(state, (draft: Draft<WebhookState>) => {
    switch (action.type) {
      case WebhookActionTypes.LIST_WEBHOOKS_SUCCESS:
        draft.webhooks = action.payload;
        return;

      case WebhookActionTypes.GET_WEBHOOK_SUCCESS:
        draft.webhook = action.payload;
        return;

      case WebhookActionTypes.EDIT_WEBHOOK_SUCCESS:
        draft.webhook = action.payload;
        return;

      case WebhookActionTypes.ROTATE_WEBHOOK_KEY_SUCCESS:
        draft.webhook = action.payload;
        return;

      case WebhookActionTypes.GET_WEBHOOK_CALLBACKS_SUCCESS:
      case WebhookActionTypes.TEST_WEBHOOK_SUCCESS: {
        draft.paginatedWebhookAttempts = action.payload;
        // Update webhooks list
        const updatedWebhook = draft.webhook;
        draft.webhooks = draft.webhooks.map((webhook) =>
          webhook.id === updatedWebhook.id
            ? { ...webhook, callback_attempts: { ...action.payload } }
            : webhook,
        );
        return;
      }
      case WebhookActionTypes.RETRIEVE_ACTION_TYPE_SUCCESS:
        draft.actionTypes = action.payload;
        return;
    }
  });
};

export { reducer as webhooksReducer };
