// Based on https://github.com/Gustu/string-to-color
import { words, padEnd, round } from 'lodash';
import { rgb, hex, keyword } from 'color-convert';

export const MIXED_WEIGHT = 0.75;
export const TEXT_WEIGHT = 0.25;
export const SEED = 16777218;
export const FACTOR = 49979693;

// Receives some text and returns the colors in it e.g. (cold blue -> [[0, 0, 255]])
export const getColors = (text: string): number[][] => {
  const colors: number[][] = [];
  words(text).forEach((word) => {
    const color: undefined | number[] = keyword.rgb(
      (word as any).toLowerCase(),
    );
    if (color) {
      colors.push(color);
    }
  });
  return colors;
};

// Receives an array of RGB colors and mixes them into one color
export const mixColors = (colors: number[][]): number[] => {
  const mixed = [0, 0, 0];
  if (colors.length === 0) {
    return mixed;
  }
  colors.forEach((value) => {
    for (let i = 0; i < 3; i++) mixed[i] += value[i];
  });
  return [
    mixed[0] / colors.length,
    mixed[1] / colors.length,
    mixed[2] / colors.length,
  ];
};

// Receives a string and returns a hex color generated based on the words/characters
// Colors map comes from lumos config, instead of generating a new one we retrieve the one stored for the current org.
export const generateColor = (text: string, colorsMap = {}): string => {
  if (colorsMap && Object.prototype.hasOwnProperty.call(colorsMap, text)) {
    return colorsMap[text];
  }
  // Generate a hex color based on the characters
  let b = 1;
  let d = 0;
  let f = 1;
  if (text.length > 0) {
    for (let i = 0; i < text.length; i++) {
      if (text[i].charCodeAt(0) > d) {
        d = text[i].charCodeAt(0);
      }
      f = round(SEED / d);
      b = (b + text[i].charCodeAt(0) * f * FACTOR) % SEED;
    }
  }
  let generatedHex: string = ((b * text.length) % SEED).toString(16);
  generatedHex = `#${padEnd(generatedHex, 6, generatedHex)}`;

  // IF the text has an implicit color, mix it (e.g tropical green, suspicious purple)
  let mixed: number[] | undefined;
  const colors = getColors(text);
  if (colors.length > 0) {
    mixed = mixColors(colors);
  }
  if (mixed) {
    const generatedRGB = hex.rgb(generatedHex);
    return rgb.hex(
      TEXT_WEIGHT * generatedRGB[0] + MIXED_WEIGHT * mixed[0],
      TEXT_WEIGHT * generatedRGB[1] + MIXED_WEIGHT * mixed[1],
      TEXT_WEIGHT * generatedRGB[2] + MIXED_WEIGHT * mixed[2],
    );
  }
  return generatedHex;
};
