import { Filter } from 'app/modules/filters/models';
import {
  ShortAgentResponse,
  FullAgentResponse,
  AgentNotificationSettingResponse,
  SlackNotificationSettingResponse,
} from 'app/modules/agents/responses';

export interface NewAgentsState {
  agent: FullAgentResponse;
  agents: ShortAgentResponse[];
  agentsCount: number;
  filters: Filter[];
  loadingActivateAgent: boolean;
  loadingAddAgent: boolean;
  loadingAgentDetails: boolean;
  loadingAgents: boolean;
  loadingDeactivateAgent: boolean;
  loadingDisableSlackNotifications: boolean;
  loadingEnableSlackNotifications: boolean;
  loadingNotificationSettings: boolean;
  loadingUpdateAgent: boolean;
  loadingUpdateAgentPicture: boolean;
  notificationSettings: AgentNotificationSettingResponse[];
  slackNotificationSettings: SlackNotificationSettingResponse;
}

export enum AgentStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  AWAITING_APPROVAL = 'AWAITING_APPROVAL',
  AWAITING_LOGIN = 'AWAITING_LOGIN',
}

export interface Auth0AgentStatsModel {
  last_ip: string;
  last_login: string;
  logins_count: number;
  provider: string;
  external_agent_id: string;
  created_at: string;
  email_verified: boolean;
}

export interface AgentDisplaySettingsHomeRoutes {
  title: string;
  subtitle: string;
  id: string;
}

export interface AgentAddFormValues {
  email: string;
  full_name: string;
  teams?: number[];
}

export interface AgentEditFormValues {
  full_name: string;
  teams: number[];
}
