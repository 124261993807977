import { PayloadAction } from '@reduxjs/toolkit';
import { u21CreateSlice } from 'app/shared/thunk/u21CreateSlice';
import { u21CreateAsyncThunk } from 'app/shared/thunk/u21CreateAsyncThunk';

// Utils
import { getDefaultAgentFilters } from 'app/modules/agents/filters';

// Models
import { AgentStatus, NewAgentsState } from 'app/modules/agents/models';
import { Filter } from 'app/modules/filters/models';

// APIs
import { addAgentToTeam, removeAgentFromTeam } from 'app/modules/teams/api';
import {
  retrievePaginatedAgents,
  deactivateAgent,
  activateAgent,
  addAgent,
  retrieveAgentDetails,
  sendNewAgentMail,
  updateAgent,
  updateAgentNotificationSettings,
  getAgentNotificationSettings,
  getSlackNotificationSettings,
  disableSlackNotifications,
  enableSlackNotifications,
  updateAgentPicture,
} from 'app/modules/agents/api';

// Responses
import {
  AgentNotificationSettingResponse,
  CreateAgentResponse,
  FullAgentResponse,
  PaginatedAgentsResponse,
  ShortAgentResponse,
  SlackNotificationSettingResponse,
} from 'app/modules/agents/responses';

// Actions
import { sendErrorToast, sendSuccessToast } from 'app/shared/toasts/actions';
import {
  updateSessionAgentNameSuccess,
  updateSessionAgentPictureSuccess,
} from 'app/modules/session/actions';

// Selectors
import { selectAgent } from 'app/modules/session/selectors';
import {
  selectAgentDetails,
  selectAllValidNotifications,
  selectIsSlackInstalled,
} from 'app/modules/agents/selectors';

// Requests
import {
  EnableSlackNotificationsPayload,
  NewAgentPayload,
  UpdateAgentNotificationSettingsPayload,
  UpdateAgentPayload,
  UpdateAgentPicturePayload,
} from 'app/modules/agents/requests';

// Constants
import { INITIAL_AGENT_DETAILS } from 'app/modules/agents/constants';
import { LocalStorageKeys } from 'app/shared/constants/localStorage';

const AGENTS_NAME = 'agentsRefresh';

export const initialState: Readonly<NewAgentsState> = {
  agent: INITIAL_AGENT_DETAILS,
  agents: [],
  agentsCount: 0,
  filters: getDefaultAgentFilters(),
  loadingActivateAgent: false,
  loadingAddAgent: false,
  loadingAgentDetails: false,
  loadingAgents: false,
  loadingDeactivateAgent: false,
  loadingDisableSlackNotifications: false,
  loadingEnableSlackNotifications: false,
  loadingNotificationSettings: false,
  loadingUpdateAgent: false,
  loadingUpdateAgentPicture: false,
  notificationSettings: [],
  slackNotificationSettings: {
    group_slack_meta: {},
    individual_slack_meta: {},
  },
};

export const retrieveAgentsThunk = u21CreateAsyncThunk(
  `${AGENTS_NAME}/GET_AGENTS`,
  retrievePaginatedAgents,
);

export const retrieveAgentDetailsThunk = u21CreateAsyncThunk(
  `${AGENTS_NAME}/GET_AGENT_DETAILS`,
  retrieveAgentDetails,
);

export const deactivateAgentThunk = u21CreateAsyncThunk(
  `${AGENTS_NAME}/DEACTIVATE_AGENT`,
  deactivateAgent,
);

export const activateAgentThunk = u21CreateAsyncThunk(
  `${AGENTS_NAME}/ACTIVATE_AGENT`,
  activateAgent,
);

export const sendNewAgentEmailThunk = u21CreateAsyncThunk(
  `${AGENTS_NAME}/SEND_NEW_AGENT_EMAIL`,
  async (payload: number, { dispatch }) => {
    try {
      await sendNewAgentMail(payload);
      dispatch(sendSuccessToast('Successfully resent registration email.'));
    } catch (e) {
      dispatch(sendErrorToast('Please contact Unit21 for more help.'));
      throw e;
    }
  },
);

export const addNewAgentThunk = u21CreateAsyncThunk<
  NewAgentPayload,
  CreateAgentResponse
>(`${AGENTS_NAME}/ADD_AGENT`, async (payload, { dispatch }) => {
  try {
    return await addAgent(payload);
  } catch (e) {
    let errorToastMessage = 'Unable to create agent.';
    try {
      const { message } = await e.json();
      if (message) {
        errorToastMessage = message;
      }
    } catch {}
    dispatch(sendErrorToast(errorToastMessage));
    throw e;
  }
});

// eventually this thunk should just be a single API call. Designed the
// thunk this way so that we only need to modify this thunk in the future
export const updateAgentThunk = u21CreateAsyncThunk(
  `${AGENTS_NAME}/UPDATE_AGENT`,
  async (
    {
      id,
      fullName,
      teams,
    }: {
      id: number;
      fullName: UpdateAgentPayload['full_name'];
      teams: number[];
    },
    { dispatch, getState },
  ) => {
    const sessionAgent = selectAgent(getState());

    if (id === sessionAgent.id) {
      try {
        await updateAgent(id, { full_name: fullName });
        dispatch(updateSessionAgentNameSuccess(fullName));
      } catch (e) {
        dispatch(sendErrorToast('Unable to update agent.'));
        throw e;
      }
    }

    // getState returns unknown type
    const { teams: currentTeams } = selectAgentDetails(getState());
    const currentTeamIDs = currentTeams.map((i) => i.id);
    const teamsSet = new Set(teams);
    const currentTeamsSet = new Set(currentTeamIDs);
    const addedTeams = teams.filter((i) => !currentTeamsSet.has(i));
    const deletedTeams = currentTeamIDs.filter((i) => !teamsSet.has(i));
    try {
      await Promise.all(
        addedTeams.map(async (i) => addAgentToTeam(i, { agent_ids: [id] })),
      );
    } catch (e) {
      dispatch(sendErrorToast('Failed to add agent to team.'));
      throw e;
    }

    try {
      await Promise.all(
        deletedTeams.map(async (i) =>
          removeAgentFromTeam(i, { agent_ids: [id] }),
        ),
      );
    } catch (e) {
      dispatch(sendErrorToast('Failed to remove agent from team.'));
      throw e;
    }

    dispatch(retrieveAgentDetailsThunk(id));
  },
);

export const updateAgentPictureThunk = u21CreateAsyncThunk(
  `${AGENTS_NAME}/UPDATE_AGENT_PICTURE`,
  async (
    {
      id,
      payload,
    }: {
      id: number;
      payload: UpdateAgentPicturePayload;
    },
    { dispatch },
  ) => {
    try {
      const { picture } = await updateAgentPicture(id, payload);
      dispatch(updateSessionAgentPictureSuccess(picture));
      return picture;
    } catch (e) {
      dispatch(sendErrorToast('Failed to update profile picture.'));
      throw e;
    }
  },
);

export const getAgentNotificationSettingsThunk = u21CreateAsyncThunk(
  `${AGENTS_NAME}/GET_AGENT_NOTIFICATION_SETTINGS`,
  getAgentNotificationSettings,
);

export const updateAgentNotificationSettingsThunk = u21CreateAsyncThunk(
  `${AGENTS_NAME}/UPDATE_AGENT_NOTIFICATION_SETTINGS`,
  async (
    {
      id,
      payload,
    }: {
      id: number;
      payload: UpdateAgentNotificationSettingsPayload;
    },
    { dispatch },
  ) => {
    try {
      return await updateAgentNotificationSettings(id, payload);
    } catch (e) {
      dispatch(sendErrorToast('Failed to update notification settings.'));
      throw e;
    }
  },
);

export const toggleAllAgentNotificationSettingsThunk = u21CreateAsyncThunk(
  `${AGENTS_NAME}/TOGGLE_ALL_AGENT_NOTIFICATION_SETTINGS`,
  async (
    { id, enabled }: { id: number; enabled: boolean },
    { dispatch, getState },
  ) => {
    // getState returns unknown type
    const settings = selectAllValidNotifications(getState());
    const isSlackInstalled = selectIsSlackInstalled(getState());
    const settingsThatNeedToBeChanged = settings.filter(
      (i) =>
        i.notification_methods.email !== enabled ||
        i.notification_methods.system !== enabled ||
        (isSlackInstalled ? i.notification_methods.slack !== enabled : false),
    );
    try {
      await Promise.all(
        settingsThatNeedToBeChanged.map(async (i) =>
          updateAgentNotificationSettings(id, {
            [i.event_type]: {
              email: enabled,
              slack: isSlackInstalled ? enabled : undefined,
              system: enabled,
            },
          }),
        ),
      );
      return { enabled, settings: settingsThatNeedToBeChanged };
    } catch (e) {
      dispatch(sendErrorToast(`Failed to ${enabled} all notifications.`));
      throw e;
    }
  },
);

export const getAgentSlackNotificationSettingsThunk = u21CreateAsyncThunk(
  `${AGENTS_NAME}/GET_SLACK_NOTIFICATION_SETTINGS`,
  getSlackNotificationSettings,
);

export const enableSlackNotificationsThunk = u21CreateAsyncThunk(
  `${AGENTS_NAME}/ENABLE_SLACK_NOTIFICATIONS`,
  async (payload: EnableSlackNotificationsPayload, { dispatch }) => {
    try {
      return await enableSlackNotifications(payload);
    } catch (e) {
      dispatch(sendErrorToast('Failed to enable slack notifications.'));
      throw e;
    }
  },
);
export const disableSlackNotificationsThunk = u21CreateAsyncThunk(
  `${AGENTS_NAME}/DISABLE_SLACK_NOTIFICATIONS`,
  async (id: number, { dispatch }) => {
    try {
      await disableSlackNotifications(id);
    } catch (e) {
      dispatch(sendErrorToast('Failed to disable slack notifications.'));
      throw e;
    }
  },
);

export const setAgentFilters = u21CreateAsyncThunk<Filter[], Filter[]>(
  `${AGENTS_NAME}/SET_AGENT_FILTERS`,
  (payload) => {
    localStorage.setItem(
      LocalStorageKeys.AGENT_FILTERS,
      JSON.stringify(payload),
    );
    return payload;
  },
);

const agentsSlice = u21CreateSlice({
  name: AGENTS_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addLoadingCase(
        retrieveAgentsThunk,
        'loadingAgents',
        (draft, action: PayloadAction<PaginatedAgentsResponse>) => {
          draft.agents = action.payload.agents;
          draft.agentsCount = action.payload.count;
        },
      )
      .addLoadingCase(
        deactivateAgentThunk,
        'loadingDeactivateAgent',
        (draft, action: PayloadAction<ShortAgentResponse>) => {
          draft.agents = draft.agents.map((agent) => {
            if (agent.id === action.payload.id) {
              return {
                ...agent,
                status: AgentStatus.INACTIVE,
              };
            }
            return agent;
          });

          if (draft.agent.id !== -1) {
            draft.agent.status = AgentStatus.INACTIVE;
          }
        },
      )
      .addLoadingCase(
        activateAgentThunk,
        'loadingActivateAgent',
        (draft, action: PayloadAction<ShortAgentResponse>) => {
          draft.agents = draft.agents.map((agent) => {
            if (agent.id === action.payload.id) {
              return {
                ...agent,
                status: AgentStatus.ACTIVE,
              };
            }
            return agent;
          });

          if (draft.agent.id !== -1) {
            draft.agent.status = AgentStatus.ACTIVE;
          }
        },
      )
      .addLoadingCase(
        retrieveAgentDetailsThunk,
        'loadingAgentDetails',
        (draft, action: PayloadAction<FullAgentResponse>) => {
          draft.agent = action.payload;
        },
      )
      .addLoadingCase(addNewAgentThunk, 'loadingAddAgent')
      .addLoadingCase(updateAgentThunk, 'loadingUpdateAgent')
      .addLoadingCase(
        updateAgentPictureThunk,
        'loadingUpdateAgentPicture',
        (draft, action: PayloadAction<string>) => {
          draft.agent.picture = action.payload;
        },
      )
      .addLoadingCase(
        getAgentNotificationSettingsThunk,
        'loadingNotificationSettings',
        (draft, action: PayloadAction<AgentNotificationSettingResponse[]>) => {
          draft.notificationSettings = action.payload;
        },
      )
      .addLoadingCase(
        enableSlackNotificationsThunk,
        'loadingEnableSlackNotifications',
        (draft, action: PayloadAction<SlackNotificationSettingResponse>) => {
          draft.slackNotificationSettings = action.payload;
        },
      )
      .addLoadingCase(
        disableSlackNotificationsThunk,
        'loadingDisableSlackNotifications',
        (draft) => {
          draft.slackNotificationSettings.individual_slack_meta = {};
        },
      )
      .addCase(
        updateAgentNotificationSettingsThunk.fulfilled,
        (draft, action: PayloadAction<[AgentNotificationSettingResponse]>) => {
          const [notificationSetting] = action.payload;
          draft.notificationSettings = draft.notificationSettings.map((i) => {
            if (i.event_type === notificationSetting.event_type) {
              return notificationSetting;
            }
            return i;
          });
        },
      )
      .addCase(
        toggleAllAgentNotificationSettingsThunk.fulfilled,
        (
          draft,
          action: PayloadAction<{
            enabled: boolean;
            settings: AgentNotificationSettingResponse[];
          }>,
        ) => {
          const { enabled, settings } = action.payload;
          const settingTypesSet = new Set(settings.map((i) => i.event_type));
          draft.notificationSettings = draft.notificationSettings.map((i) => {
            if (settingTypesSet.has(i.event_type)) {
              return {
                ...i,
                notification_methods: {
                  email: enabled,
                  slack: enabled,
                  system: enabled,
                },
              };
            }
            return i;
          });
        },
      )
      .addCase(
        getAgentSlackNotificationSettingsThunk.fulfilled,
        (draft, action: PayloadAction<SlackNotificationSettingResponse>) => {
          draft.slackNotificationSettings = action.payload;
        },
      )
      .addCase(setAgentFilters.fulfilled, (draft, action) => {
        draft.filters = action.payload;
      });
  },
});

export const AGENTS_SLICE_NAME = agentsSlice.name;
export default agentsSlice.reducer;
