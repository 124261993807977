// Constants
import { WatchlistProviders } from 'app/modules/detectionModels/components/scenarioWidgets/watchlist/enums';
import { DEFAULT_DATA_MIGRATION_LAG } from 'app/modules/rules/constants';
import { UNLOADED_FLAGS_KEY } from 'app/shared/featureFlags/constants';
import { FeatureFlag } from 'app/shared/featureFlags/models';
import { createSelector } from 'reselect';

export const selectFeatureFlags = (state: RootState) => {
  return state.featureFlags.flags;
};

// Loading
export const selectFeatureFlagsLoading = (state: RootState) => {
  return state.featureFlags.flags[UNLOADED_FLAGS_KEY] === true;
};

export const selectDarkModeFlagEnabled = createSelector(
  selectFeatureFlags,
  (featureFlags) => featureFlags[FeatureFlag.DARK_MODE],
);

export const selectRemoveDisplayTagsLogic = createSelector(
  selectFeatureFlags,
  (featureFlags) => featureFlags[FeatureFlag.REMOVE_DISPLAY_TAGS],
);

export const selectSixtranSnowflakeExpectedLag = createSelector(
  selectFeatureFlags,
  (featureFlags) =>
    featureFlags[FeatureFlag.SIXTRAN_SNOWFLAKE_EXPECTED_LAG] ||
    DEFAULT_DATA_MIGRATION_LAG,
);

export const selectBulkActionLimit = createSelector(
  selectFeatureFlags,
  // default to 500 in case launchdarkly goes down
  (featureFlags): number => featureFlags[FeatureFlag.BULK_ACTION_LIMIT] || 500,
);

export const selectAlertFromAlertEnabled = createSelector(
  selectFeatureFlags,
  (featureFlags) => featureFlags[FeatureFlag.ALERT_FROM_ALERT],
);

export const selectComprehensiveAuditTrailsEnabled = createSelector(
  selectFeatureFlags,
  (featureFlags) => featureFlags[FeatureFlag.COMPREHENSIVE_AUDIT_TRAILS],
);

export const selectImmutableNotesEnabled = createSelector(
  selectFeatureFlags,
  (featureFlags) => featureFlags[FeatureFlag.IMMUTABLE_NOTES],
);

export const selectOutdatedIndicatorEnabled = createSelector(
  selectFeatureFlags,
  (featureFlags) => featureFlags[FeatureFlag.OUTDATED_INDICATOR],
);

export const selectFincenCtrFilingEnabled = createSelector(
  selectFeatureFlags,
  (featureFlags) => featureFlags[FeatureFlag.FINCEN_CTR_FILINGS],
);

export const selectSessionReplayEnabled = createSelector(
  selectFeatureFlags,
  (featureFlags) => featureFlags[FeatureFlag.SESSION_REPLAY],
);

export const selectClassifiedQueuesEnabled = createSelector(
  selectFeatureFlags,
  (featureFlags) => featureFlags[FeatureFlag.CLASSIFIED_QUEUES],
);

export const selectCodeInterpreterEnabled = createSelector(
  selectFeatureFlags,
  (featureFlags) => featureFlags[FeatureFlag.CODE_INTERPRETER],
);

export const selectAchRiskScoreScenarioEnabled = createSelector(
  selectFeatureFlags,
  (featureFlags) => featureFlags[FeatureFlag.ACH_RISK_SCORE_SCENARIO_ENABLED],
);

/** NON-STANDARD FeatureFlag, this is a temporary fix
 * to appease seeing the $ when currency is formatted
 */
export const selectWorkaroundHideCurrencyCode = createSelector(
  selectFeatureFlags,
  (featureFlags) => featureFlags[FeatureFlag.WORKAROUND_HIDE_CURRENCY_CONFIG],
);

export const selectFraudTransactionRulesEnabled = createSelector(
  selectFeatureFlags,
  (featureFlags) => featureFlags[FeatureFlag.FRAUD_TRANSACTION_RULES],
);

export const selectAchRiskScoreFieldEnabled = createSelector(
  selectFeatureFlags,
  (featureFlags) => featureFlags[FeatureFlag.ACH_RISK_SCORE_FIELD],
);

export const selectRtmDropdownBuilderFormRefactorEnabled = createSelector(
  selectFeatureFlags,
  (featureFlags) =>
    featureFlags[FeatureFlag.RTM_DROPDOWN_BUILDER_FORM_REFACTOR],
);

export const selectDmScenarioFormRefactorEnabled = createSelector(
  selectFeatureFlags,
  (featureFlags) => featureFlags[FeatureFlag.DM_SCENARIO_FORM_REFACTOR],
);

export const selectRuleDelayFlag = createSelector(
  selectFeatureFlags,
  (featureFlags) => featureFlags[FeatureFlag.RULE_DELAY],
);

export const selectDispositionActions = createSelector(
  selectFeatureFlags,
  (featureFlags) => featureFlags[FeatureFlag.DISPOSITION_ACTIONS],
);

export const selectRuleEventSchemaV2Enabled = createSelector(
  selectFeatureFlags,
  (featureFlags) => featureFlags[FeatureFlag.RULE_EVENT_SCHEMA_V2],
);

export const selectWatchlistAlertsEnabled = createSelector(
  selectFeatureFlags,
  (featureFlags) => featureFlags[FeatureFlag.WATCHLIST_ALERTS],
);

export const selectAlertScoreAiCopilotEnabled = createSelector(
  selectFeatureFlags,
  (featureFlags) => featureFlags[FeatureFlag.ALERT_SCORE_AI_COPILOT],
);

export const selectDeprecateStaticWorkflow = createSelector(
  selectFeatureFlags,
  (featureFlags) => featureFlags[FeatureFlag.DEPRECATE_STATIC_WORKFLOW],
);

export const selectAlertTableAmountColumnSkeletonLoading = createSelector(
  selectFeatureFlags,
  (featureFlags) =>
    featureFlags[FeatureFlag.ALERT_TABLE_AMOUNT_COLUMN_SKELETON_LOADING],
);

export const selectAlertScore = createSelector(
  selectFeatureFlags,
  (featureFlags) => featureFlags[FeatureFlag.ALERT_SCORE],
);

export const selectQualityCycleLimit = createSelector(
  selectFeatureFlags,
  (featureFlags) => featureFlags[FeatureFlag.QUALITY_CYCLE_LIMIT],
);

export const selectFraudDashboardEnabled = createSelector(
  selectFeatureFlags,
  (featureFlags) => featureFlags[FeatureFlag.FRAUD_DASHBOARD],
);

export const selectDarkWebAlertsEnabled = createSelector(
  selectFeatureFlags,
  (featureFlags) => featureFlags[FeatureFlag.DARK_WEB_ALERTS],
);

export const selectRuleLifecycleBannerEnabled = createSelector(
  selectFeatureFlags,
  (featureFlags) => featureFlags[FeatureFlag.RULE_LIFECYCLE_BANNER],
);

export const selectWatchlistProvider = createSelector(
  selectFeatureFlags,
  (featureFlags) =>
    featureFlags[FeatureFlag.WATCHLIST_PROVIDER] || WatchlistProviders.UNIT22,
);

export const selectFeedbackSurveyID = createSelector(
  selectFeatureFlags,
  (featureFlags) => featureFlags[FeatureFlag.FEEDBACK_SURVEY_ID] ?? 0,
);

export const selectDLEnabledForAlertTxnAnalysis = createSelector(
  selectFeatureFlags,
  (featureFlags) =>
    Boolean(featureFlags[FeatureFlag.CM_DELTA_LAKE_ALERT_TXN_ANALYSIS]),
);

export const selectDLEnabledForAlertTxnList = createSelector(
  selectFeatureFlags,
  (featureFlags) =>
    Boolean(featureFlags[FeatureFlag.CM_DELTA_LAKE_ALERT_TXN_LIST]),
);

export const selectDLEnabledForAlertActionEvents = createSelector(
  selectFeatureFlags,
  (featureFlags) =>
    Boolean(featureFlags[FeatureFlag.CM_DELTA_LAKE_ALERT_ACTION_LIST]),
);

export const selectDLEnabledForAlertHistogram = createSelector(
  selectFeatureFlags,
  (featureFlags) =>
    Boolean(featureFlags[FeatureFlag.CM_DELTA_LAKE_ALERT_HISTOGRAM]),
);

export const selectNetworkAnalysisOnDeltaLake = createSelector(
  selectFeatureFlags,
  (featureFlags) =>
    featureFlags[FeatureFlag.CM_DELTA_LAKE_ENTITY_NETWORK_ANALYSIS],
);

export const selectAiRecommendationsEnabled = createSelector(
  selectFeatureFlags,
  (featureFlags) => featureFlags[FeatureFlag.AI_RECOMMENDATIONS],
);

export const selectBulkResolveFromQueuedViewsEnabled = createSelector(
  selectFeatureFlags,
  (featureFlags) => featureFlags[FeatureFlag.BULK_RESOLVE_FROM_QUEUED_VIEWS],
);

export const selectWorkflowConfirmationModalRefresh = createSelector(
  selectFeatureFlags,
  (featureFlags) =>
    featureFlags[FeatureFlag.WORKFLOW_CONFIRMATION_MODAL_REFRESH],
);

export const selectSARFasterEndpointsEnabled = createSelector(
  selectFeatureFlags,
  (featureFlags) => featureFlags[FeatureFlag.SAR_FASTER_ENDPOINTS],
);

export const selectFinCEN314aEnabled = createSelector(
  selectFeatureFlags,
  (featureFlags) => featureFlags[FeatureFlag.FINCEN_314A],
);

export const selectHideAlertHistogram = createSelector(
  selectFeatureFlags,
  (featureFlags) => featureFlags[FeatureFlag.HIDE_ALERT_HISTOGRAM],
);
