import { Auth0Config } from 'app/modules/session/models';
import getFromProcessEnv from 'app/shared/utils/getFromProcessEnv';

export const AUTH_CONFIG: Auth0Config = {
  apiAudience: getFromProcessEnv('REACT_APP_AUTH0_API_AUDIENCE'),
  apiBaseUrl: getFromProcessEnv('REACT_APP_API_BASE_URL'),
  loginRedirectUrl: getFromProcessEnv('REACT_APP_AUTH0_LOGIN_REDIRECT_URL'),
  clientId: getFromProcessEnv('REACT_APP_AUTH0_CLIENT_ID'),
  domain: getFromProcessEnv('REACT_APP_AUTH0_DOMAIN'),
  logoutRedirectUrl: getFromProcessEnv('REACT_APP_AUTH0_LOGOUT_REDIRECT_URL'),
};
