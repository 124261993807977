import { ActionsUnion, createAction } from 'app/shared/utils/action-helpers';
import {
  CreateWorkflowPayload,
  EditWorkflowPayload,
  GetWorkflowsPayload,
  RetrieveWorkflowsSuccessPayload,
  Subdisposition,
  WorkflowActionTypes,
  WorkflowContexts,
  WorkflowDetailResponse,
} from 'app/modules/workflows/models';

export const retrieveWorkflows = (payload: GetWorkflowsPayload) =>
  createAction(WorkflowActionTypes.RETRIEVE_WORKFLOWS, payload);

export const retrieveWorkflowsSuccess = (
  result: RetrieveWorkflowsSuccessPayload,
) => createAction(WorkflowActionTypes.RETRIEVE_WORKFLOWS_SUCCESS, result);

export const retrieveWorkflow = (id: string) =>
  createAction(WorkflowActionTypes.RETRIEVE_WORKFLOW, id);

export const retrieveWorkflowSuccess = (result: WorkflowDetailResponse) =>
  createAction(WorkflowActionTypes.RETRIEVE_WORKFLOW_SUCCESS, result);

export const createWorkflow = (payload: CreateWorkflowPayload) =>
  createAction(WorkflowActionTypes.CREATE_WORKFLOW, payload);

export const createWorkflowSuccess = () =>
  createAction(WorkflowActionTypes.CREATE_WORKFLOW_SUCCESS);

export const editWorkflow = (payload: EditWorkflowPayload) =>
  createAction(WorkflowActionTypes.EDIT_WORKFLOW, payload);

export const editWorkflowSuccess = (result: WorkflowDetailResponse) =>
  createAction(WorkflowActionTypes.EDIT_WORKFLOW_SUCCESS, result);

export const deleteWorkflow = (id: string, context: WorkflowContexts) =>
  createAction(WorkflowActionTypes.DELETE_WORKFLOW, id, context);

export const deleteWorkflowSuccess = (id: string) =>
  createAction(WorkflowActionTypes.DELETE_WORKFLOW_SUCCESS, id);

export const retrieveSubdispositions = () =>
  createAction(WorkflowActionTypes.RETRIEVE_SUBDISPOSITIONS);

export const retrieveSubdispositionsSuccess = (results: Subdisposition[]) =>
  createAction(WorkflowActionTypes.RETRIEVE_SUBDISPOSITIONS_SUCCESS, results);

const WorkflowActions = {
  retrieveWorkflowsSuccess,
  retrieveWorkflowSuccess,
  createWorkflowSuccess,
  editWorkflowSuccess,
  deleteWorkflowSuccess,
  listSubdispositionsSuccess: retrieveSubdispositionsSuccess,
};

type WorkflowReduxActions = ActionsUnion<typeof WorkflowActions>;

export default WorkflowReduxActions;
