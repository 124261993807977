import React, { PureComponent } from 'react';
import { cloneDeep } from 'lodash';

// Models
import { ScenarioConstants } from 'app/modules/rules/models';
import { WidgetProps } from 'app/modules/detectionModels/components/scenarioWidgets/models';

// Components
import { Label, Segment } from 'semantic-ui-react';
import ConditionIDE from 'app/modules/rules/components/scenarioWidgets/ConditionIDE';
import GenericButton from 'app/shared/components/GenericButton';

// Styles
import styles from 'app/modules/rules/styles/ScenarioSequenceCodeEditor.module.scss';
import assets from 'app/shared/utils/assets';

type AllProps = WidgetProps;

class ScenarioSequenceCodeEditor extends PureComponent<AllProps> {
  handleChange = (value: string, idx: number) => {
    const { editingScenario, onChange } = this.props;
    const newEditingScenario = cloneDeep(editingScenario);
    const prevSequenceContent =
      newEditingScenario.parameters[ScenarioConstants.SEQUENCE_CONTENT] || [];

    if (prevSequenceContent.length < idx + 1) {
      newEditingScenario.parameters[ScenarioConstants.SEQUENCE_CONTENT] = [
        ...prevSequenceContent,
        value,
      ];
    } else {
      newEditingScenario.parameters[ScenarioConstants.SEQUENCE_CONTENT][idx] =
        value;
    }

    onChange({ ...newEditingScenario });
  };

  deleteConditionBox = (idx: number) => {
    const { editingScenario, onChange } = this.props;
    const newEditingScenario = cloneDeep(editingScenario);
    newEditingScenario.parameters[ScenarioConstants.SEQUENCE_CONTENT].splice(
      idx,
      1,
    );
    onChange({ ...newEditingScenario });
  };

  render() {
    const { editingScenario, viewOnly } = this.props;
    const currentSequence =
      editingScenario.parameters[ScenarioConstants.SEQUENCE_CONTENT] || [];

    return (
      <Segment>
        <div>
          <span className={styles.dropdownLabel}>
            Define a sequence of events to flag:
          </span>
          <GenericButton
            className={styles.addConditionButton}
            basic
            content="Add a condition box"
            disabled={viewOnly}
            icon={assets.icons.addOutline}
            onClick={() =>
              this.handleChange('', Object.keys(currentSequence).length)
            }
          />
        </div>
        {currentSequence.map((condition, idx) => {
          return (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={`scenario-sequence-condition-${idx}`}
              className={styles.scenarioConditionContainer}
            >
              <Label
                className={styles.scenarioConditionLabel}
                basic
                circular
                content={idx + 1}
              />
              <ConditionIDE
                style={{ background: 'white', flexGrow: 1 }}
                value={condition}
                viewOnly={viewOnly}
                onChange={(value) => this.handleChange(value, idx)}
              />
              <GenericButton
                basic
                className={styles.scenarioConditionDelete}
                color="red"
                disabled={viewOnly || currentSequence.length === 1}
                icon={assets.icons.close}
                onClick={() => this.deleteConditionBox(idx)}
              />
            </div>
          );
        })}
      </Segment>
    );
  }
}

export default ScenarioSequenceCodeEditor;
