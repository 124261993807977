import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { NotesActionTypes, NoteModel } from 'app/modules/notes/models';

// Actions
import {
  addNote as addNoteAction,
  addNoteSuccess,
} from 'app/modules/notes/actions';

// API
import { addNote } from 'app/shared/api/notes';

const rootAction = NotesActionTypes.ADD_NOTE;
function* addNoteFlow({ payload }: ReturnType<typeof addNoteAction>) {
  const config = {
    rootAction,
    request: call(addNote, payload),
    success: function* onSuccess(response: NoteModel) {
      yield put(addNoteSuccess(response));
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watchAddNote() {
  yield takeLatest(rootAction, addNoteFlow);
}
