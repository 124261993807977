import { HTMLProps } from 'react';

import { IconInfoCircle } from '@u21/tabler-icons';
import { U21TooltipProps } from 'app/shared/u21-ui/components/display/U21Tooltip';
import { U21Button } from 'app/shared/u21-ui/components/input/U21Button';
import { getDOMProps } from 'app/shared/utils/react';

export interface U21HelpTooltipProps extends HTMLProps<HTMLButtonElement> {
  help?: U21TooltipProps['tooltip'];
  tooltipProps?: Omit<U21TooltipProps, 'children' | 'tooltip'>;
}

export const U21HelpTooltip = ({
  help,
  tooltipProps,
  ...rest
}: U21HelpTooltipProps) => {
  if (!help) {
    return null;
  }
  return (
    <U21Button
      aria-label="Help tooltip"
      color="info"
      icon={<IconInfoCircle size={18} />}
      size="small"
      tooltip={help}
      tooltipProps={tooltipProps}
      {...getDOMProps(rest)}
    />
  );
};
