import deleteValidationModel from 'app/modules/detectionModels/sagas/deleteValidationModel';
import retrieveDetectionModelsPerformance from 'app/modules/detectionModels/sagas/retrieveDetectionModelsPerformance';
import retrieveScenarioConfigs from 'app/modules/detectionModels/sagas/retrieveScenarioConfigs';
import validateDetectionModel from 'app/modules/detectionModels/sagas/validateDetectionModel';
import retrieveDetectionModelValidation from 'app/modules/detectionModels/sagas/retrieveDetectionModelValidation';
import retrieveDetectionModelValidationAlerts from 'app/modules/detectionModels/sagas/retrieveDetectionModelValidationAlerts';
import revalidateDetectionModel from 'app/modules/detectionModels/sagas/revalidateDetectionModel';
import retrieveDetectionModelValidationMetrics from 'app/modules/detectionModels/sagas/retrieveDetectionModelValidationMetrics';
import retrieveDetectionModelTemplates from 'app/modules/detectionModels/sagas/retrieveDetectionModelTemplates';
import retrieveRuleValidationFiles from 'app/modules/rules/sagas/retrieveRuleValidationFileExports';

const sagaRegistry = [
  deleteValidationModel,
  retrieveRuleValidationFiles,
  retrieveDetectionModelsPerformance,
  retrieveScenarioConfigs,
  validateDetectionModel,
  revalidateDetectionModel,
  retrieveDetectionModelValidation,
  retrieveDetectionModelValidationAlerts,
  retrieveDetectionModelValidationMetrics,
  retrieveDetectionModelTemplates,
];

export default sagaRegistry;
