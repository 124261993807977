import retrieveDeadlinesConfigs from 'app/modules/deadlines/sagas/retrieveDeadlinesConfigs';
import retrieveDeadlinesConfig from 'app/modules/deadlines/sagas/retrieveDeadlinesConfig';
import createDeadlinesConfig from 'app/modules/deadlines/sagas/createDeadlinesConfig';
import editDeadlinesConfig from 'app/modules/deadlines/sagas/editDeadlinesConfig';
import editArticleDeadline from 'app/modules/deadlines/sagas/editArticleDeadline';

const sagaRegistry = [
  retrieveDeadlinesConfigs,
  retrieveDeadlinesConfig,
  editDeadlinesConfig,
  createDeadlinesConfig,
  editArticleDeadline,
];

export default sagaRegistry;
