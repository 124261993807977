import {
  ChangeEventHandler,
  ComponentType,
  forwardRef,
  HTMLProps,
  ElementType,
  useEffect,
  useState,
  ChangeEvent,
  Key,
} from 'react';
import { getDOMProps } from 'app/shared/utils/react';

import { DebounceInput, DebounceInputProps } from 'react-debounce-input';
import { ResponsiveContainer } from 'app/shared/u21-ui/components/input/text-field/ResponsiveContainer';

interface U21DebouncedInputProps
  extends DebounceInputProps<HTMLInputElement, HTMLProps<HTMLInputElement>> {
  delay?: number;
  responsiveLength?: boolean;
  // prop to be used as key to avoid key spread issue in react 19
  inputKey?: Key;
}

export const DebouncedInput = forwardRef<
  HTMLInputElement,
  U21DebouncedInputProps
>((props, ref) => {
  const {
    className,
    delay = 300,
    element,
    inputKey,
    placeholder,
    responsiveLength,
    style,
    value,
    ...rest
  } = props;

  const [immediateValue, setImmediateValue] = useState(value);
  const hasDelay = Boolean(delay);
  useEffect(() => {
    if (hasDelay) {
      setImmediateValue(value);
    }
  }, [hasDelay, value]);

  if (delay === 0) {
    const Component = (element || 'input') as ElementType;
    if (responsiveLength) {
      return (
        <ResponsiveContainer
          className={className}
          style={style}
          value={value || placeholder}
        >
          <Component
            key={inputKey}
            ref={ref}
            className={className}
            placeholder={placeholder}
            style={{ ...style, width: '100%' }}
            value={value}
            {...getDOMProps(rest)}
          />
        </ResponsiveContainer>
      );
    }
    return (
      <Component
        key={inputKey}
        ref={ref}
        className={className}
        placeholder={placeholder}
        style={style}
        value={value}
        {...getDOMProps(rest)}
      />
    );
  }
  if (responsiveLength) {
    return (
      <ResponsiveContainer
        className={className}
        style={style}
        value={immediateValue || placeholder}
      >
        <DebounceInput<HTMLInputElement, WrappedInputProps>
          key={inputKey}
          className={className}
          Component={element}
          debounceTimeout={delay}
          element={WrappedImmediateInput}
          onImmediateChange={(e) => setImmediateValue(e.target.value)}
          inputRef={ref}
          placeholder={placeholder}
          style={{ ...style, width: '100%' }}
          value={value}
          {...(rest as Omit<U21DebouncedInputProps, 'ref'>)}
        />
      </ResponsiveContainer>
    );
  }
  return (
    <DebounceInput<HTMLInputElement, WrappedInputProps>
      key={inputKey}
      className={className}
      debounceTimeout={delay}
      Component={element}
      element={WrappedImmediateInput}
      inputRef={ref}
      placeholder={placeholder}
      style={style}
      value={value}
      {...(rest as Omit<U21DebouncedInputProps, 'ref'>)}
    />
  );
});

interface WrappedInputProps
  extends Omit<HTMLProps<HTMLInputElement>, 'onChange' | 'value'> {
  Component?:
    | string
    | ComponentType<
        DebounceInputProps<HTMLInputElement, HTMLProps<HTMLInputElement>>
      >;
  // typed this way since react-debounce-input is typed this way
  onChange: ChangeEventHandler<HTMLInputElement>;
  onImmediateChange?: ChangeEventHandler<HTMLInputElement>;
  value?: string | number;
}

const WrappedImmediateInput = forwardRef<HTMLInputElement, WrappedInputProps>(
  (props, ref) => {
    const { Component = 'input', onChange, onImmediateChange, ...rest } = props;
    return (
      <Component
        ref={ref}
        {...rest}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          onImmediateChange?.(e);
          onChange?.(e);
        }}
      />
    );
  },
);
