import React from 'react';
import { cloneDeep, merge } from 'lodash';
// TODO uncomment, only testing
// import en_US from 'antd/es/locale/en_US';

// Components
import { IconTrash } from '@u21/tabler-icons';
import AntdConfig from 'react-awesome-query-builder/lib/config/antd';
import AntdWidgets from 'react-awesome-query-builder/lib/components/widgets/antd';
import { Icon } from 'semantic-ui-react';
import { RelativeTimeWidget } from 'app/modules/rules/components/RelativeTimeWidget';
import { U21Button, U21HelpTooltip } from 'app/shared/u21-ui/components';

// Styles
import 'antd/dist/antd.css';

import {
  Operators,
  Widgets,
  Fields,
  Funcs,
  Config,
  Types,
  Conjunctions,
  Settings,
  LocaleSettings,
  BehaviourSettings,
  BinaryOperator,
} from 'react-awesome-query-builder';

// Constants
import { READ_ONLY_QUERY_BUILDER_SETTINGS } from 'app/modules/rules/constants';
import styled from 'styled-components';

const { FieldSelect, FieldDropdown, FieldCascader } = AntdWidgets;

export const createConfig = (
  queryFields,
  includeFieldComparison: boolean,
  readOnly: boolean = false,
  includeRelativeDate: boolean = false,
): Config => {
  const InitialConfig = AntdConfig;

  const conjunctions: Conjunctions = {
    ...InitialConfig.conjunctions,
  };

  const relativeTimeAgo: BinaryOperator = {
    label: 'Within The Past',
    cardinality: 1,
    sqlFormatOp: (field, _, value) => {
      return `${field} > CURRENT_TIMESTAMP - INTERVAL '${value}'`;
    },
  };

  const operators: Operators = {
    ...InitialConfig.operators,
    not_like: {
      ...InitialConfig.operators.not_like,
      label: 'Is not like',
      labelForFormat: 'Is Not Like',
    },
    like: {
      ...InitialConfig.operators.like,
      label: 'Is like',
      labelForFormat: 'Is Like',
    },
    ...(includeRelativeDate ? { relative_time_ago: relativeTimeAgo } : {}),
  };

  const widgets: Widgets = includeRelativeDate
    ? {
        ...InitialConfig.widgets,
        // add any overrides here
        relative_time_ago: {
          type: 'relative_time_ago',
          jsType: 'string',
          // @ts-ignore
          factory: (props) => <RelativeTimeWidget {...props} />,
          sqlFormatValue: (val) => {
            return val;
          },
        },
      }
    : {
        ...InitialConfig.widgets,
      };

  const relativeDateTypes = {
    datetime: {
      widgets: {
        datetime: {
          operators: [
            'equal',
            'not_equal',
            'less',
            'less_or_equal',
            'greater',
            'greater_or_equal',
            'between',
            'not_between',
            'is_null',
            'is_not_null',
          ],
        },
        // Adding new relative filter for datetime fields
        relative_time_ago: {
          operators: ['relative_time_ago'],
        },
      },
    },
  };

  const types: Types = includeRelativeDate
    ? {
        ...InitialConfig.types,
        ...relativeDateTypes,
      }
    : {
        ...InitialConfig.types,
      };

  const behaviourSettings: BehaviourSettings = {
    setOpOnChangeField: ['default'],
    removeIncompleteRulesOnLoad: false,
    removeEmptyGroupsOnLoad: false,
  };

  const localeSettings: LocaleSettings = {
    locale: {
      short: 'en',
      full: 'en-US',
      // antd: en_US,
    } as any, // applying `any` type here since it's causing problems with react-awesome-query-builder
    valueLabel: 'Value',
    valuePlaceholder: 'Value',
    fieldLabel: 'Field',
    operatorLabel: 'Operator',
    funcLabel: 'Function',
    fieldPlaceholder: 'Select field',
    funcPlaceholder: 'Select function',
    operatorPlaceholder: 'Select operator',
    // @ts-ignore
    deleteLabel: (
      <Icon
        name="trash alternate outline"
        color="red"
        style={{
          position: 'absolute',
          fontSize: '16px',
          top: '2px',
          right: '2px',
        }}
      />
    ),
    addGroupLabel: 'Add group',
    addRuleLabel: 'Add rule',
    notLabel: 'Not',
    // for when we want to add value source select
    // valueSourcesPopupTitle: 'Select value source',
    removeRuleConfirmOptions: {
      title: 'Are you sure delete this rule?',
      okText: 'Yes',
      okType: 'danger',
    },
    removeGroupConfirmOptions: {
      title: 'Are you sure delete this group?',
      okText: 'Yes',
      okType: 'danger',
    },
  };

  const valueSourcesInfo = includeFieldComparison
    ? {
        value: {
          label: 'Value',
        },
        // for when we want to add field comparison
        field: {
          label: 'Field',
          widget: 'field',
        },
        func: {
          label: 'Function',
          widget: 'func',
        },
      }
    : {
        value: {
          label: 'Value',
        },
      };

  const settings: Settings = {
    ...InitialConfig.settings,
    ...behaviourSettings,
    ...localeSettings,
    valueSourcesInfo,
    maxNesting: 5,
    canLeaveEmptyGroup: true,
    showErrorMessage: true,
    // @ts-ignore
    renderButton: (props) => {
      const defaultRenderButton = InitialConfig.settings.renderButton;
      if (!defaultRenderButton || !props) return null;
      if (['delRule', 'delRuleGroup', 'delGroup'].includes(props.type)) {
        return (
          <StyledU21Button
            aria-label="delete"
            size="small"
            onClick={props.onClick}
            icon={<IconTrash />}
          />
        );
      }
      return defaultRenderButton(props);
    },
    // @ts-ignore
    renderField: (props) => {
      if (!props) return null;
      const label = props.selectedFullLabel?.replace('.', ' / ') || '';
      return (
        <div>
          <FieldCascader {...props} />
          {label.length >= 20 ? (
            <U21HelpTooltip
              tooltipProps={{ placement: 'top' }}
              help={
                label.includes('Unit21 ACH Risk Score')
                  ? `${label} Note: this field can only be used on ACH transactions and have a number value between 0-100`
                  : label
              }
            />
          ) : null}
        </div>
      );
    },
    // @ts-ignore
    renderOperator: (props) => <FieldDropdown {...props} />,
    // @ts-ignore
    renderFunc: (props) => <FieldSelect {...props} />,
    removeRuleConfirmOptions: undefined,
    removeGroupConfirmOptions: undefined,
    maxNumberOfRules: 30, // number of rules can be added to the query builder
  };

  if (readOnly) {
    merge(settings, READ_ONLY_QUERY_BUILDER_SETTINGS);
  }

  const funcs: Funcs = {
    LOWERCASE: {
      label: 'Lowercase',
      mongoFunc: '$toLower',
      jsonLogic: 'toLowerCase',
      jsonLogicIsMethod: true,
      returnType: 'text',
      args: {
        str: {
          label: 'String',
          type: 'text',
          valueSources: ['value', 'field'],
        },
      },
    },
  };

  const fields: Fields = cloneDeep(queryFields);

  const config: Config = {
    conjunctions,
    operators,
    widgets,
    types,
    settings,
    fields,
    funcs,
  };

  return config;
};

const StyledU21Button = styled(U21Button)`
  padding: 0;
  margin-left: 5px;
`;
