import { createSlice } from '@reduxjs/toolkit';
import { u21CreateAsyncThunk } from 'app/shared/thunk/u21CreateAsyncThunk';

// Models
import {
  InvestigationChecklistCreateThunkArg,
  InvestigationChecklistListPayload,
  InvestigationChecklistRetrievePayload,
  InvestigationChecklistState,
  InvestigationChecklist,
  InvestigationChecklistUpdatePayload,
} from 'app/modules/investigationChecklist/models';

// APIs
import {
  listInvestigationChecklists as listInvestigationChecklistsApi,
  retrieveInvestigationChecklist as retrieveInvestigationChecklistApi,
  updateInvestigationChecklist as updateInvestigationChecklistApi,
  deleteInvestigationChecklist as archiveInvestigationChecklistApi,
  createInvestigationChecklist as createInvestigationChecklistApi,
} from 'app/modules/investigationChecklist/api';
import { submitCustomChecklist as submitCustomChecklistApi } from 'app/shared/customChecklist/api';
import { sendErrorToast, sendSuccessToast } from 'app/shared/toasts/actions';
import { EMPTY_INVESTIGATION_CHECKLIST } from 'app/modules/investigationChecklist/constants';
import { ROUTES_MAP } from 'app/shared/utils/routes';
import { CustomChecklistSubmissionPayload } from 'app/shared/customChecklist/models';
import { submitCustomChecklistSuccess } from 'app/shared/customChecklist/actions';

// Constants
const INVESTIGATION_CHECKLIST_NAME = 'investigationChecklist';

export const initialState: Readonly<InvestigationChecklistState> = {
  checklist: {
    ...EMPTY_INVESTIGATION_CHECKLIST,
  },
  checklistLoading: false,
  checklistUpdateLoading: false,
  checklists: {
    count: 0,
    custom_checklists: [],
  },
  checklistsLoading: false,
  checklistUpdateUnsaved: false,
};

export const listInvestigationChecklistThunkName = `${INVESTIGATION_CHECKLIST_NAME}/LIST`;
export const listInvestigationChecklistThunk = u21CreateAsyncThunk(
  listInvestigationChecklistThunkName,
  async (payload: InvestigationChecklistListPayload, { dispatch }) => {
    try {
      return await listInvestigationChecklistsApi(payload);
    } catch (e) {
      dispatch(
        sendErrorToast('Unable to retrieve checklists. Please try again'),
      );
      throw e;
    }
  },
);

export const retrieveInvestigationChecklistThunkName = `${INVESTIGATION_CHECKLIST_NAME}/RETRIEVE_INVESTIGATION_CHECKLIST`;
export const retrieveInvestigationChecklistThunk = u21CreateAsyncThunk(
  retrieveInvestigationChecklistThunkName,
  retrieveInvestigationChecklistApi,
);

export const createInvestigationChecklistThunkName = `${INVESTIGATION_CHECKLIST_NAME}/CREATE_INVESTIGATION_CHECKLIST`;
export const createInvestigationChecklistThunk = u21CreateAsyncThunk(
  createInvestigationChecklistThunkName,
  async (thunkArg: InvestigationChecklistCreateThunkArg, { dispatch }) => {
    try {
      const response = await createInvestigationChecklistApi(thunkArg.payload);
      thunkArg.successNavigate(
        ROUTES_MAP.investigationChecklistsId.path.replace(
          ':id',
          String(response.id),
        ),
      );
      return response;
    } catch (e) {
      dispatch(sendErrorToast('Unable to create checklists. Please try again'));
      throw e;
    }
  },
);

export const updateInvestigationChecklistThunkName = `${INVESTIGATION_CHECKLIST_NAME}/UPDATE_INVESTIGATION_CHECKLIST`;
export const updateInvestigationChecklistThunk = u21CreateAsyncThunk(
  updateInvestigationChecklistThunkName,
  async (payload: InvestigationChecklistUpdatePayload, { dispatch }) => {
    try {
      return await updateInvestigationChecklistApi(payload);
    } catch (e) {
      dispatch(sendErrorToast('Unable to update checklist. Please try again'));
      throw e;
    }
  },
);

export const archiveInvestigationChecklistThunkName = `${INVESTIGATION_CHECKLIST_NAME}/ARCHIVE_INVESTIGATION_CHECKLIST`;
export const archiveInvestigationChecklistThunk = u21CreateAsyncThunk(
  archiveInvestigationChecklistThunkName,
  async (payload: InvestigationChecklistRetrievePayload, { dispatch }) => {
    try {
      return await archiveInvestigationChecklistApi(payload);
    } catch (e) {
      dispatch(sendErrorToast('Unable to archive checklist. Please try again'));
      throw e;
    }
  },
);

export const upsertInvestigationChecklistSubmissionThunkName = `${INVESTIGATION_CHECKLIST_NAME}/UPSERT_INVESTIGATION_CHECKLIST_SUBMISSION`;
export const upsertInvestigationChecklistSubmissionThunk = u21CreateAsyncThunk(
  upsertInvestigationChecklistSubmissionThunkName,
  async (payload: CustomChecklistSubmissionPayload, { dispatch }) => {
    try {
      const response = await submitCustomChecklistApi(payload);
      dispatch(
        sendSuccessToast(`The custom checklist was updated successfully`),
      );
      dispatch(submitCustomChecklistSuccess(response));
      return response;
    } catch (e) {
      dispatch(
        sendErrorToast(
          'Unable to update checklist submission. Please try again',
        ),
      );
      throw e;
    }
  },
);

const investigationChecklistSlice = createSlice({
  name: INVESTIGATION_CHECKLIST_NAME,
  initialState,
  reducers: {
    updateInvestigationChecklist: (
      draft,
      { payload }: { payload: InvestigationChecklist },
    ) => {
      draft.checklist = payload;
      draft.checklistUpdateUnsaved = true;
    },
  },
  extraReducers: (builder) => {
    builder

      // listInvestigationChecklistThunk
      .addCase(listInvestigationChecklistThunk.pending, (draft) => {
        draft.checklistsLoading = true;
      })
      .addCase(listInvestigationChecklistThunk.fulfilled, (draft, action) => {
        draft.checklists = action.payload;
        draft.checklistsLoading = false;
      })
      .addCase(listInvestigationChecklistThunk.rejected, (draft) => {
        draft.checklistsLoading = false;
      })

      // retrieveInvestigationChecklistThunk
      .addCase(retrieveInvestigationChecklistThunk.pending, (draft) => {
        draft.checklistLoading = true;
      })
      .addCase(
        retrieveInvestigationChecklistThunk.fulfilled,
        (draft, action) => {
          draft.checklist = action.payload;
          draft.checklistLoading = false;
          draft.checklistUpdateUnsaved = false;
        },
      )
      .addCase(retrieveInvestigationChecklistThunk.rejected, (draft) => {
        draft.checklistLoading = false;
      })

      // createInvestigationChecklistThunk
      .addCase(createInvestigationChecklistThunk.pending, (draft) => {
        draft.checklistLoading = true;
      })
      .addCase(createInvestigationChecklistThunk.fulfilled, (draft, action) => {
        draft.checklist = action.payload;
        draft.checklistLoading = false;
      })
      .addCase(createInvestigationChecklistThunk.rejected, (draft) => {
        draft.checklistLoading = false;
      })

      // updateInvestigationChecklistThunk
      .addCase(updateInvestigationChecklistThunk.pending, (draft) => {
        draft.checklistUpdateLoading = true;
      })
      .addCase(updateInvestigationChecklistThunk.fulfilled, (draft, action) => {
        const { id, alert_default_checklist: defaultChecklist } =
          action.payload;

        draft.checklists.custom_checklists =
          draft.checklists.custom_checklists.map((checklist) => {
            if (id === checklist.id) return action.payload;
            // If a new default checklist is published, archive the existing default checklist
            if (defaultChecklist && checklist.alert_default_checklist) {
              return {
                ...checklist,
                archived: true,
                alert_default_checklist: false,
              };
            }
            return checklist;
          });

        draft.checklist = action.payload;
        draft.checklistUpdateLoading = false;
        draft.checklistUpdateUnsaved = false;
      })
      .addCase(updateInvestigationChecklistThunk.rejected, (draft) => {
        draft.checklistUpdateLoading = false;
      })

      // archiveInvestigationChecklistThunk
      .addCase(archiveInvestigationChecklistThunk.pending, (draft) => {
        draft.checklistUpdateLoading = true;
        draft.checklistUpdateUnsaved = false;
      })
      .addCase(
        archiveInvestigationChecklistThunk.fulfilled,
        (draft, action) => {
          const { id } = action.payload;

          const newCustomChecklists = draft.checklists.custom_checklists.map(
            (checklist) => {
              if (id === checklist.id) return action.payload;
              return checklist;
            },
          );
          draft.checklists = {
            custom_checklists: newCustomChecklists,
            count: newCustomChecklists.length,
          };

          draft.checklist = { ...EMPTY_INVESTIGATION_CHECKLIST };
          draft.checklistUpdateLoading = false;
        },
      )
      .addCase(archiveInvestigationChecklistThunk.rejected, (draft) => {
        draft.checklistUpdateLoading = false;
      });
  },
});

export const investigationChecklistSliceName = investigationChecklistSlice.name;
export const { updateInvestigationChecklist } =
  investigationChecklistSlice.actions;
export default investigationChecklistSlice.reducer;
