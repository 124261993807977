import { call, put, takeLatest } from 'redux-saga/effects';

// Actions
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';
import { retrieveAPIConfigsSuccess } from 'app/modules/teamsOld/actions';

// API
import { getAPIConfig } from 'app/shared/api/team';

// Models
import { TeamActionTypes } from 'app/modules/teamsOld/models';

const rootAction = TeamActionTypes.RETRIEVE_API_CONFIGS;
function* retrieveAPIConfigs() {
  const config = {
    rootAction,
    request: call(getAPIConfig),
    success: function* onSuccess(result) {
      yield put(retrieveAPIConfigsSuccess(result));
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watchRetrieveAPIConfigs() {
  yield takeLatest(rootAction, retrieveAPIConfigs);
}
