import { useEffect, useMemo, useRef } from 'react';
import { SearchRequest } from 'app/modules/search/models';

import { INFINITE_SCROLL_LIMIT, OFFSET_DEFAULT } from 'app/shared/constants';
import permissions from 'app/shared/utils/permissions';

import { identityFunction } from 'app/shared/utils/function';
import { rulesSearch } from 'app/modules/search/actions';
import { selectHasPermissionsFactory } from 'app/modules/session/selectors';
import { selectRuleOptions } from 'app/modules/search/selectors';
import { useDispatch, useSelector } from 'react-redux';

import { IconScale } from '@u21/tabler-icons';
import {
  U21SelectOptionProps,
  U21SelectSearch,
  U21SelectSearchProps,
} from 'app/shared/u21-ui/components';

interface Props {
  getPayload?: (payload: SearchRequest) => SearchRequest;
  selector?: (state: RootState) => U21SelectOptionProps<number>[];
}

export type U21RuleSelectProps<TClearable extends boolean> = DistributiveOmit<
  U21SelectSearchProps<number, TClearable>,
  'options' | 'onSearch'
> &
  Props;

const selectHasReadRulesPermission = selectHasPermissionsFactory(
  permissions.readRules,
);

export const U21RuleSelect = <TClearable extends boolean>(
  props: U21RuleSelectProps<TClearable>,
) => {
  const dispatch = useDispatch();
  const {
    getPayload = identityFunction,
    selector = selectRuleOptions,
    ...rest
  } = props;
  const options = useSelector(selector);
  const hasReadRulesPermission = useSelector(selectHasReadRulesPermission);

  // assume array if undefined for missing values calculation
  const { value = [] } = props;
  const values = useMemo(
    () => (Array.isArray(value) ? value : [value]),
    [value],
  ) as number[];

  // calledRef is used to prevent an infinite loop in case the API never returns with the missing value
  const calledRef = useRef(false);
  useEffect(() => {
    calledRef.current = false;
  }, [values]);

  const getPayloadRef = useRef(getPayload);
  getPayloadRef.current = getPayload;
  useEffect(() => {
    if (hasReadRulesPermission) {
      const missingValues = values.filter(
        (i) => !options.find((j) => j.value === i),
      );
      if (missingValues.length && !calledRef.current) {
        calledRef.current = true;
        dispatch(
          rulesSearch(
            getPayloadRef.current({
              ids: missingValues,
              limit: missingValues.length,
              offset: OFFSET_DEFAULT,
              phrase: '',
            }),
          ),
        );
      }
    }
  }, [dispatch, hasReadRulesPermission, options, values]);

  if (!hasReadRulesPermission) {
    return null;
  }

  return (
    <U21SelectSearch
      label="Rules"
      onSearch={(phrase = '') =>
        dispatch(
          rulesSearch(
            getPayload({
              limit: INFINITE_SCROLL_LIMIT,
              offset: OFFSET_DEFAULT,
              phrase,
            }),
          ),
        )
      }
      options={options}
      startIcon={<IconScale />}
      {...rest}
    />
  );
};
