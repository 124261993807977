import {
  TeamActionTypes,
  AddTagPayload,
  ApiConfig,
  Tag,
} from 'app/modules/teamsOld/models';
import { ActionsUnion, createAction } from 'app/shared/utils/action-helpers';

export const retrieveAllTags = () =>
  createAction(TeamActionTypes.RETRIEVE_ALL_TAGS);

export const retrieveAllTagsSuccess = (result: Tag[]) =>
  createAction(TeamActionTypes.RETRIEVE_ALL_TAGS_SUCCESS, result);

export const clearAddedTag = (tagName: string) =>
  createAction(TeamActionTypes.CLEAR_ADDED_TAG, tagName);

export const addTag = (
  payload: AddTagPayload,
  handleSuccess?: (result: Tag) => void,
) => createAction(TeamActionTypes.ADD_TAG, payload, handleSuccess);

export const addTagSuccess = (payload: Tag) =>
  createAction(TeamActionTypes.ADD_TAG_SUCCESS, payload);

export const retrieveAPIConfigs = () =>
  createAction(TeamActionTypes.RETRIEVE_API_CONFIGS);

export const retrieveAPIConfigsSuccess = (result: ApiConfig) =>
  createAction(TeamActionTypes.RETRIEVE_API_CONFIGS_SUCCESS, result);

// NEW ACTIONS START HERE
export const tagLimitReached = () =>
  createAction(TeamActionTypes.TAG_LIMIT_REACHED);

export const clearTagLimitReached = () =>
  createAction(TeamActionTypes.CLEAR_TAG_LIMIT_REACHED);

const TeamActionsList = {
  retrieveAPIConfigs,
  retrieveAPIConfigsSuccess,
  retrieveAllTags,
  retrieveAllTagsSuccess,
  addTag,
  addTagSuccess,
  clearAddedTag,
  tagLimitReached,
  clearTagLimitReached,
};

export type TeamActions = ActionsUnion<typeof TeamActionsList>;

export default TeamActions;
