import { Filter } from 'app/modules/filters/models';

import { LocalStorageKeys } from 'app/shared/constants/localStorage';

import { u21CreateAsyncThunk } from 'app/shared/thunk/u21CreateAsyncThunk';
import { u21CreateSlice } from 'app/shared/thunk/u21CreateSlice';

const ALERTS_NAME = 'alertSlice';

const initialState = {
  adminAlertFilters: [] as Filter[],
  myAlertFilters: [] as Filter[],
  queuedAlertFilters: [] as Filter[],
};

export const setAdminAlertFilters = u21CreateAsyncThunk<Filter[], Filter[]>(
  `${ALERTS_NAME}/SET_ADMIN_ALERT_FILTERS`,
  (payload) => {
    localStorage.setItem(
      LocalStorageKeys.ADMIN_ALERT_FILTERS,
      JSON.stringify(payload),
    );
    return payload;
  },
);

export const setMyAlertFilters = u21CreateAsyncThunk<Filter[], Filter[]>(
  `${ALERTS_NAME}/SET_MY_ALERT_FILTERS`,
  (payload) => {
    localStorage.setItem(
      LocalStorageKeys.MY_ALERT_FILTERS,
      JSON.stringify(payload),
    );
    return payload;
  },
);

export const setQueuedAlertFilters = u21CreateAsyncThunk<Filter[], Filter[]>(
  `${ALERTS_NAME}/SET_QUEUED_ALERT_FILTERS`,
  (payload) => {
    localStorage.setItem(
      LocalStorageKeys.QUEUED_ALERT_FILTERS,
      JSON.stringify(payload),
    );
    return payload;
  },
);

const alertsSlice = u21CreateSlice({
  initialState,
  name: ALERTS_NAME,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setAdminAlertFilters.fulfilled, (draft, action) => {
        draft.adminAlertFilters = action.payload;
      })
      .addCase(setMyAlertFilters.fulfilled, (draft, action) => {
        draft.myAlertFilters = action.payload;
      })
      .addCase(setQueuedAlertFilters.fulfilled, (draft, action) => {
        draft.queuedAlertFilters = action.payload;
      });
  },
});

export const ALERTS_SLICE_NAME = alertsSlice.name;
export default alertsSlice.reducer;
