import { HTMLProps } from 'react';
import NoDataSvg from 'app/shared/svg/no_data.svg';
import { getDOMProps } from 'app/shared/utils/react';
import styled from 'styled-components';
import { Paper } from '@mui/material';
import { U21Typography } from 'app/shared/u21-ui/components/display/typography/U21Typography';
import { U21Button } from 'app/shared/u21-ui/components/input/U21Button';

export interface U21LoadErrorProps extends HTMLProps<HTMLDivElement> {
  label: string;
  onTryAgain: () => void;
}

export const U21LoadError = (props: U21LoadErrorProps) => {
  const { label, onTryAgain, ...rest } = props;
  return (
    <Container {...getDOMProps(rest)}>
      <NoDataSvg />
      <StyledU21Typography>
        Failed to load {label}. Please{' '}
        <U21Button color="primary" onClick={onTryAgain} variant="text">
          try again
        </U21Button>
        .
      </StyledU21Typography>
    </Container>
  );
};

const Container = styled(Paper)`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  padding: 8px;
`;

const StyledU21Typography = styled(U21Typography)`
  margin-top: 8px;
`;
