import { FC, HTMLProps, ReactNode } from 'react';

import { getDOMProps } from 'app/shared/utils/react';
import styled from 'styled-components';

import { FormLabel } from '@mui/material';
import {
  U21HelpTooltip,
  U21HelpTooltipProps,
} from 'app/shared/u21-ui/components/display/U21HelpTooltip';

export interface U21FormLabelProps extends HTMLProps<HTMLLabelElement> {
  children: ReactNode;
  required?: boolean;
  disabled?: boolean;
  help?: U21HelpTooltipProps['help'];
  error?: boolean;
}

export const U21FormLabel: FC<U21FormLabelProps> = ({
  children,
  disabled,
  error,
  help,
  required,
  ...rest
}) => {
  return (
    <StyledFormLabel
      disabled={disabled}
      error={error}
      required={required}
      {...getDOMProps(rest)}
    >
      {children}
      <StyledU21HelpTooltip help={help} />
    </StyledFormLabel>
  );
};

const StyledFormLabel = styled(FormLabel)`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.palette.text.primary};
  ${(props) => props.theme.typography.subtitle2}

  &.Mui-focused {
    color: ${(props) => props.theme.palette.text.primary};
  }

  .MuiFormLabel-asterisk {
    color: ${(props) => props.theme.palette.error.main};
  }

  /* fix bug introduced by node_modules/antd/dist/antd.css where legend margin-bottom is set */
  margin-bottom: 0;
`;

const StyledU21HelpTooltip = styled(U21HelpTooltip)`
  margin-left: 2px;
`;
