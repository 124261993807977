import {
  Filter,
  FilterOperator,
  FilterOperatorAndValue,
  FilterOption,
  FilterType,
} from 'app/modules/filters/models';

import {
  FILTER_TYPE_OPERATORS,
  FILTER_OPERATOR,
} from 'app/modules/filters/constants';

export const isOperatorAndValueValid = (
  operatorAndValue: any,
): operatorAndValue is FilterOperatorAndValue => {
  try {
    const { operator, value } = operatorAndValue;
    switch (operator) {
      case FILTER_OPERATOR.CONTAINS_TEXT:
      case FILTER_OPERATOR.IS_EXACT_TEXT:
      case FILTER_OPERATOR.IS_NOT_EXACT_TEXT:
        return typeof value === 'string' && value !== '';

      case FILTER_OPERATOR.IS:
        return (
          (typeof value === 'string' && value !== '') ||
          typeof value === 'number'
        );

      case FILTER_OPERATOR.IS_AFTER_DATE:
      case FILTER_OPERATOR.IS_BEFORE_DATE:
        return !isNaN(new Date(value).getTime());

      case FILTER_OPERATOR.IS_BETWEEN_DATE: {
        if (!Array.isArray(value)) {
          return false;
        }
        const [start, end] = value;
        const startDate = new Date(start).getTime();
        const endDate = new Date(end).getTime();
        return !isNaN(startDate) && !isNaN(endDate) && endDate > startDate;
      }

      case FILTER_OPERATOR.IS_BETWEEN_NUMBER:
      case FILTER_OPERATOR.IS_BETWEEN_SLIDER: {
        if (!Array.isArray(value)) {
          return false;
        }
        const [min, max] = value;
        return typeof min === 'number' && typeof max === 'number' && max > min;
      }

      case FILTER_OPERATOR.IS_GREATER_THAN_NUMBER:
      case FILTER_OPERATOR.IS_LESS_THAN_NUMBER:
        return typeof value === 'number';

      case FILTER_OPERATOR.IS_ONE_OF:
      case FILTER_OPERATOR.IS_NOT_ONE_OF: {
        if (!Array.isArray(value) || !value.length) {
          return false;
        }
        return value.every(
          (i) => typeof i === 'string' || typeof i === 'number',
        );
      }

      case FILTER_OPERATOR.IS_EMPTY:
      case FILTER_OPERATOR.IS_NOT_EMPTY:
      case FILTER_OPERATOR.IS_TRUE:
      case FILTER_OPERATOR.IS_FALSE: {
        return true;
      }

      default:
        return false;
    }
  } catch (e) {
    return false;
  }
};

export const getValidFilters = (
  something: any,
  filterOptions: FilterOption[],
): Filter[] => {
  if (!Array.isArray(something)) {
    return [];
  }

  const filterOptionsMap = filterOptions.reduce(
    (acc, i) => ({ ...acc, [i.key]: i }),
    {},
  );
  try {
    return something.reduce<Filter[]>((acc, i) => {
      if (!i || typeof i !== 'object') {
        return acc;
      }
      const { key, ...value } = i;
      // check whether value matches operator
      if (!isOperatorAndValueValid(value)) {
        return acc;
      }
      const { operator } = value;

      const { type } = filterOptionsMap[key];
      if (FILTER_TYPE_OPERATORS[type]?.find((j) => j.value === operator)) {
        acc.push(i);
      }
      return acc;
    }, []);
  } catch (e) {
    return [];
  }
};

export const getFilterOperators = (
  type: FilterType,
  customize?: Partial<Record<FilterOperator, { disabled?: true }>>,
): ValueOf<typeof FILTER_TYPE_OPERATORS> => {
  const operatorOptions = FILTER_TYPE_OPERATORS[type];
  return operatorOptions.filter((i) => !customize?.[i.value]?.disabled);
};
