// Models
import { U21TableHeaderCellProps } from 'app/shared/u21-ui/components';
import { NotificationResponse } from 'app/modules/notifications/types/responses';

export enum NotificationEntityType {
  PROFILE = 'PROFILE',
  CASE = 'CASE',
  ALERT = 'ALERT',
  ALERT_QUEUE = 'ALERT_QUEUE',
  RULE = 'RULE',
  SAR = 'SAR',
  FILE_EXPORT = 'FILE_EXPORT',
  FFIP_NOTIFICATION = 'FFIP_NOTIFICATION',
}

export enum NotificationStatus {
  UNREAD = 'UNREAD',
  READ = 'READ',
}

export interface NewNotificationsState {
  notifications: NotificationResponse[];
  notificationsCount: number;
  unreadNotificationsCount: number;
  loadingNotifications: boolean;
  topbarNotifications: NotificationResponse[];
  loadingTopbarNotifications: boolean;
  loadingMarkAllNotificationsRead: boolean;
}

export interface NotificationTableColumnProps
  extends U21TableHeaderCellProps<NotificationResponse> {
  row: NotificationResponse;
}
