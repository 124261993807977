// Redux
import { Reducer } from 'redux';
import produce, { Draft } from 'immer';

// Models
import {
  TransactionsState,
  TransactionsActionTypes,
} from 'app/modules/transactions/models';
import { EventFiltersState } from 'app/shared/events/models';

// Actions
import TransactionsActions from 'app/modules/transactions/actions';
import { EventsActions } from 'app/shared/events/actions';

// Utils
import deepFreeze from 'app/shared/utils/deepFreeze';

// Constants
import { initialActionEvent } from 'app/modules/transactions/constants';

export const initialTransactionFilters: EventFiltersState = {
  internalTxnTypes: [],
  internalTxnStatuses: [],
  txnCodes: [],
  startDate: '',
  endDate: '',
  dateRange: '',
  minimumAmount: '',
  maximumAmount: '',
  tags: [],
  customFilters: {},
};

const initialState: Readonly<TransactionsState> = {
  transactionFilters: initialTransactionFilters,
  actionEvent: initialActionEvent,
};

// to ensure initialState is readonly
deepFreeze(initialState);

const reducer: Reducer<TransactionsState> = (
  state = initialState,
  action: TransactionsActions | EventsActions,
) => {
  return produce(state, (draft: Draft<TransactionsState>) => {
    switch (action.type) {
      case TransactionsActionTypes.SET_ORG_TRANSACTION_FILTERS:
        draft.transactionFilters = action.payload;
        return;

      case TransactionsActionTypes.RETRIEVE_ACTION_EVENT_SUCCESS:
        draft.actionEvent = action.payload;
        return;
    }
  });
};

export { reducer as transactionsReducer, initialState };
