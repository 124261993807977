import { TFrom, TFromMyClient } from 'app/modules/goAML/models';

export const EMPTY_FROM: TFrom = {
  from_funds_code: '',
  from_funds_comment: '',
  // from_foreign_currency: EMPTY_FROM_FOREIGN_CURRENCY,
  // t_conductor: t_person;
  from_country: '',
};

export const EMPTY_FROM_MY_CLIENT: TFromMyClient = {
  from_funds_code: '',
  from_funds_comment: '',
  // from_foreign_currency: EMPTY_FROM_FOREIGN_CURRENCY,
  // t_conductor: t_person;
  from_country: '',
};
