interface Loading {
  status: 'LOADING';
}

interface Error {
  status: 'ERROR';
}

interface Complete {
  status: 'COMPLETE';
}

export type AsyncStatus<T extends object = Record<string, never>> =
  | Loading
  | Error
  | (T extends Record<string, never> ? Complete : Complete & T);

export const LOADING_STATE = {
  status: 'LOADING',
} as const;

export const ERROR_STATE = {
  status: 'ERROR',
} as const;
