import { ROUTES_MAP } from 'app/shared/utils/routes';

import {
  AlertWorkflowFormModel,
  CaseWorkflowFormModel,
  CreateAlertWorkflowPayload,
  CreateCaseWorkflowPayload,
  DisplayButtons,
  QueueSelectionButtons,
  TeamSelectionButtons,
  WhiteListButtons,
  WorkflowContexts,
} from 'app/modules/workflows/models';
import {
  ActionTriggerModel,
  AlertActionTriggerConfig,
} from 'app/modules/webhooks/models';
import { Disposition } from 'app/modules/dispositions/response';
import { DispositionType } from 'app/modules/dispositions/models';

// Utils
import { heapTrack } from 'app/shared/utils/heap';
import heapEvents from 'app/shared/utils/heapEvents';
import palette, { ColorColorSchema } from 'vendor/material-minimal/palette';

// /workflows/buttons/(alerts|cases)'
export const getWorkflowButtonsListPath = (tab: WorkflowContexts): string => {
  // there are only alert and case workflow tabs at the moment
  if (tab === WorkflowContexts.ALERT) {
    return ROUTES_MAP.alertWorkflowButtons.path;
  }
  return ROUTES_MAP.caseWorkflowButtons.path;
};

// /workflows/buttons/(alerts|cases)/:id'
export const getWorkflowButtonDetailsPath = (
  tab: WorkflowContexts,
  id: number | string,
): string => {
  const templatePath =
    tab === WorkflowContexts.ALERT
      ? ROUTES_MAP.alertWorkflowButton.path
      : ROUTES_MAP.caseWorkflowButton.path;
  return templatePath.replace(':id', String(id));
};

export const getRouteWorkflowContext = (route: string): WorkflowContexts => {
  if (route.startsWith('/workflows/buttons/alerts')) {
    return WorkflowContexts.ALERT;
  }
  return WorkflowContexts.CASE;
};

export const formatAlertWorkflowPayload = (
  model: AlertWorkflowFormModel,
): CreateAlertWorkflowPayload => {
  const { whitelist_time: { value, unit } = {}, ...rest } = model;
  const payload: CreateAlertWorkflowPayload = rest;

  if (payload.whitelist === WhiteListButtons.NONE) {
    payload.whitelist_duration = undefined;
  }

  if (payload.whitelist_duration === WhiteListButtons.SPECIFIC) {
    payload.whitelist_time = String(value);
    payload.whitelist_time_units = unit;
  }

  /* Alert Specific */
  if (payload.queue_availability === QueueSelectionButtons.SHOW_IN_ALL_QUEUES) {
    payload.queue = [];
  }
  if (payload.team_availability === TeamSelectionButtons.SHOW_FOR_ALL_TEAMS) {
    payload.teams = [];
  }

  payload.disposition_id ||= null;

  return payload;
};

export const formatCaseWorkflowPayload = (
  model: CaseWorkflowFormModel,
): CreateCaseWorkflowPayload => {
  const { whitelist_time: { value, unit } = {}, ...rest } = model;
  const payload: CreateCaseWorkflowPayload = rest;

  if (payload.whitelist === WhiteListButtons.NONE) {
    payload.whitelist_duration = undefined;
  }

  if (payload.whitelist_duration === WhiteListButtons.SPECIFIC) {
    payload.whitelist_time = String(value);
    payload.whitelist_time_units = unit;
  }
  /* Case Specific */
  if (payload.display_specific === DisplayButtons.DO_NOTHING) {
    payload.teams = [];
    payload.masking_tags = [];
    payload.display_tags = [];
  }
  if (payload.queue_availability === QueueSelectionButtons.SHOW_IN_ALL_QUEUES) {
    payload.queue = [];
  }
  if (payload.team_availability === TeamSelectionButtons.SHOW_FOR_ALL_TEAMS) {
    payload.teams = [];
  }

  payload.disposition_id ||= null;

  return payload;
};

/*
  Given an Action Trigger, determine whether or not to
  open the confirmation modal when it is selected.

  The modal is opened any time that...
  - Extra input is required from the agent (notes, whitelist settings, subdispositions)
  - Potentially unexpected queue/agent assignment side effects may occur, requiring agent confirmation

  This uses the old ActionTiggerModel
*/
export const shouldOpenActionTriggerModal = (
  actionTrigger: ActionTriggerModel,
  defaultActionsEnabled: boolean = false,
): boolean => {
  const actionTriggerConfig =
    actionTrigger.config as AlertActionTriggerConfig | null;

  const {
    reassignment_type: reassignmentType,
    apply_tags_to_children: applyTagsToChildren,
    reassignment_queue_id: reassignmentQueueId,
  } = actionTriggerConfig || {};

  const isExcludedReassignmentType =
    reassignmentType &&
    ['NONE', 'STATIC_QUEUE', 'STATIC'].includes(reassignmentType);
  const notesEnabled = actionTrigger.notes !== 'DISABLED';
  const doesApplyTagsToChildren = Boolean(applyTagsToChildren);
  const hasSubdispositions = Boolean(actionTrigger.subdispositions.length);

  const hasDefaultActions =
    Boolean(actionTrigger.disposition_actions?.length) && defaultActionsEnabled;

  // Queue reassignment will potentially prompt the agent to choose an agent reassignment
  // The one exception to this is STATIC_QUEUE reassignment, where the agent assignment is dropped
  const nonStaticReassignment =
    Boolean(reassignmentQueueId) && reassignmentType !== 'STATIC_QUEUE';

  return (
    !isExcludedReassignmentType ||
    notesEnabled ||
    !doesApplyTagsToChildren ||
    hasSubdispositions ||
    nonStaticReassignment ||
    hasDefaultActions
  );
};

export const submitHeapTrack = (
  id: string | undefined,
  templateId: string | undefined,
  context: string,
  data: AlertWorkflowFormModel | CaseWorkflowFormModel,
) =>
  heapTrack(id ? heapEvents.workflows.edit : heapEvents.workflows.create, {
    context,
    description: data.description,
    label: data.label,
    id,
    templateId,
  });

export const getButtonColor = (color: ColorColorSchema) => ({
  color: palette.light.colors[color].contrastText,
  background: palette.light.colors[color].main,
  hoverBackground: palette.light.colors[color].dark,
});

export const isDispositionValid = (
  selectedDispositionId: number | undefined,
  dispositions: Disposition[],
  allowedClassifications: DispositionType[],
): boolean => {
  if (!selectedDispositionId) {
    return false;
  }
  const selectedDisposition = dispositions.find(
    (disposition) => disposition.id === selectedDispositionId,
  );
  if (
    !selectedDisposition ||
    !allowedClassifications.includes(selectedDisposition.type)
  ) {
    return false;
  }
  return true;
};
