import { U21TableBodyCellProps } from 'app/shared/u21-ui/components/display/table/models';

import { getA11yClickProps } from 'app/shared/utils/a11y';
import styled, { css } from 'styled-components';

import { IconChevronRight } from '@u21/tabler-icons';

export const U21ExpandableArrow = <T extends { id: number | string }>(
  props: U21TableBodyCellProps<T>,
) => {
  const {
    rowProps: { isExpanded, toggleRowExpanded },
  } = props;
  return (
    <Container {...getA11yClickProps(() => toggleRowExpanded(!isExpanded))}>
      <IconContainer $isExpanded={isExpanded}>
        <IconChevronRight size={24} />
      </IconContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  // remove default cell padding so the edges are clickable
  margin: -6px;
  width: calc(100% + 12px);
`;

const IconContainer = styled.div<{
  $isExpanded?: boolean;
}>`
  display: flex;
  transition: all ${(props) => props.theme.transitions.duration.standard}ms;
  ${(props) =>
    props.$isExpanded
      ? css`
          transform: rotate(90deg);
        `
      : css``}
`;
