import { getIn } from 'final-form';
import { cloneDeep } from 'lodash';

// Models
import {
  TransactionFromTypes,
  TransactionToTypes,
  TransactionContent,
  TransactionFromClient,
  TransactionToClient,
  TransactionFrom,
  TransactionTo,
  GoAMLFilingDetails,
  GoAMLErrorsDictionary,
} from 'app/modules/goAML/models';
import {
  FormFieldSchema,
  FormFieldCustomSchema,
  FormFieldArraySchema,
} from 'app/shared/models/form';

// Constants
import {
  EMPTY_ACCOUNT,
  EMPTY_ACCOUNT_MY_CLIENT,
} from 'app/modules/goAML/schemas/templates/emptyAccount';
import {
  EMPTY_PERSON,
  EMPTY_PERSON_MY_CLIENT,
} from 'app/modules/goAML/schemas/templates/emptyPerson';
import {
  EMPTY_FROM,
  EMPTY_FROM_MY_CLIENT,
} from 'app/modules/goAML/schemas/templates/emptyFrom';
import {
  EMPTY_TO,
  EMPTY_TO_MY_CLIENT,
} from 'app/modules/goAML/schemas/templates/emptyTo';
import {
  EMPTY_ENTITY,
  EMPTY_ENTITY_MY_CLIENT,
} from 'app/modules/goAML/schemas/templates/emptyEntity';

export const getTxnFromType = (
  content: TransactionContent,
): TransactionFromTypes => {
  if (getIn(content, 't_from_my_client.from_account')) {
    return TransactionFromTypes.FROM_ACCOUNT_MY_CLIENT;
  }
  if (getIn(content, 't_from_my_client.from_person')) {
    return TransactionFromTypes.FROM_PERSON_MY_CLIENT;
  }
  if (getIn(content, 't_from_my_client.from_entity')) {
    return TransactionFromTypes.FROM_ENTITY_MY_CLIENT;
  }
  if (getIn(content, 't_from.from_account')) {
    return TransactionFromTypes.FROM_ACCOUNT;
  }
  if (getIn(content, 't_from.from_person')) {
    return TransactionFromTypes.FROM_PERSON;
  }
  if (getIn(content, 't_from.from_entity')) {
    return TransactionFromTypes.FROM_ENTITY;
  }
  return TransactionFromTypes.EMPTY_FROM;
};

export const getTxnToType = (
  content: TransactionContent,
): TransactionToTypes => {
  if (getIn(content, 't_to_my_client.to_account')) {
    return TransactionToTypes.TO_ACCOUNT_MY_CLIENT;
  }
  if (getIn(content, 't_to_my_client.to_person')) {
    return TransactionToTypes.TO_PERSON_MY_CLIENT;
  }
  if (getIn(content, 't_to_my_client.to_entity')) {
    return TransactionToTypes.TO_ENTITY_MY_CLIENT;
  }
  if (getIn(content, 't_to.to_account')) {
    return TransactionToTypes.TO_ACCOUNT;
  }
  if (getIn(content, 't_to.to_person')) {
    return TransactionToTypes.TO_PERSON;
  }
  if (getIn(content, 't_to.to_entity')) {
    return TransactionToTypes.TO_ENTITY;
  }
  return TransactionToTypes.EMPTY_TO;
};

export const toggleTxnFromType = (
  transactionContent: TransactionContent,
  newFromType: TransactionFromTypes,
): TransactionContent => {
  const oldFromType = getTxnFromType(transactionContent);
  if (oldFromType === newFromType) {
    return transactionContent;
  }

  const newContent: DistributiveOmit<
    TransactionContent,
    't_from_my_client' | 't_from'
  > & {
    t_from_my_client?: TransactionFromClient['t_from_my_client'];
    t_from?: TransactionFrom['t_from'];
  } = cloneDeep(transactionContent);

  // Remove old content
  switch (oldFromType) {
    case TransactionFromTypes.FROM_ACCOUNT_MY_CLIENT:
    case TransactionFromTypes.FROM_PERSON_MY_CLIENT:
    case TransactionFromTypes.FROM_ENTITY_MY_CLIENT:
      delete newContent.t_from_my_client;
      break;
    case TransactionFromTypes.FROM_ACCOUNT:
    case TransactionFromTypes.FROM_PERSON:
    case TransactionFromTypes.FROM_ENTITY:
      delete newContent.t_from;
      break;
    default:
      break;
  }

  // Add new content
  switch (newFromType) {
    case TransactionFromTypes.FROM_ACCOUNT_MY_CLIENT:
      return {
        ...newContent,
        t_from_my_client: {
          ...cloneDeep(EMPTY_FROM_MY_CLIENT),
          from_account: cloneDeep(EMPTY_ACCOUNT_MY_CLIENT),
        },
      };
    case TransactionFromTypes.FROM_ACCOUNT:
      return {
        ...newContent,
        t_from: {
          ...cloneDeep(EMPTY_FROM),
          from_account: cloneDeep(EMPTY_ACCOUNT),
        },
      };
    case TransactionFromTypes.FROM_PERSON_MY_CLIENT:
      return {
        ...newContent,
        t_from_my_client: {
          ...cloneDeep(EMPTY_FROM_MY_CLIENT),
          from_person: cloneDeep(EMPTY_PERSON_MY_CLIENT),
        },
      };
    case TransactionFromTypes.FROM_PERSON:
      return {
        ...newContent,
        t_from: {
          ...cloneDeep(EMPTY_FROM),
          from_person: cloneDeep(EMPTY_PERSON),
        },
      };
    case TransactionFromTypes.FROM_ENTITY_MY_CLIENT:
      return {
        ...newContent,
        t_from_my_client: {
          ...cloneDeep(EMPTY_FROM_MY_CLIENT),
          from_entity: cloneDeep(EMPTY_ENTITY_MY_CLIENT),
        },
      };
    case TransactionFromTypes.FROM_ENTITY:
      return {
        ...newContent,
        t_from: {
          ...cloneDeep(EMPTY_FROM),
          from_entity: cloneDeep(EMPTY_ENTITY),
        },
      };
    default:
      break;
  }

  return transactionContent;
};

export const toggleTxnToType = (
  transactionContent: TransactionContent,
  newToType: TransactionToTypes,
): TransactionContent => {
  const oldToType = getTxnToType(transactionContent);
  if (oldToType === newToType) {
    return transactionContent;
  }

  const newContent: DistributiveOmit<
    TransactionContent,
    't_to_my_client' | 't_to'
  > & {
    t_to_my_client?: TransactionToClient['t_to_my_client'];
    t_to?: TransactionTo['t_to'];
  } = cloneDeep(transactionContent);

  // Remove old content
  switch (oldToType) {
    case TransactionToTypes.TO_ACCOUNT_MY_CLIENT:
    case TransactionToTypes.TO_PERSON_MY_CLIENT:
    case TransactionToTypes.TO_ENTITY_MY_CLIENT:
      delete newContent.t_to_my_client;
      break;
    case TransactionToTypes.TO_ACCOUNT:
    case TransactionToTypes.TO_PERSON:
    case TransactionToTypes.TO_ENTITY:
      delete newContent.t_to;
      break;
    default:
      break;
  }

  // Add new content
  switch (newToType) {
    case TransactionToTypes.TO_ACCOUNT_MY_CLIENT:
      return {
        ...newContent,
        t_to_my_client: {
          ...cloneDeep(EMPTY_TO_MY_CLIENT),
          to_account: cloneDeep(EMPTY_ACCOUNT_MY_CLIENT),
        },
      };

    case TransactionToTypes.TO_ACCOUNT:
      return {
        ...newContent,
        t_to: {
          ...cloneDeep(EMPTY_TO),
          to_account: cloneDeep(EMPTY_ACCOUNT),
        },
      };
    case TransactionToTypes.TO_PERSON_MY_CLIENT:
      return {
        ...newContent,
        t_to_my_client: {
          ...cloneDeep(EMPTY_TO_MY_CLIENT),
          to_person: cloneDeep(EMPTY_PERSON_MY_CLIENT),
        },
      };
    case TransactionToTypes.TO_PERSON:
      return {
        ...newContent,
        t_to: {
          ...cloneDeep(EMPTY_TO),
          to_person: cloneDeep(EMPTY_PERSON),
        },
      };
    case TransactionToTypes.TO_ENTITY_MY_CLIENT:
      return {
        ...newContent,
        t_to_my_client: {
          ...EMPTY_TO_MY_CLIENT,
          to_entity: cloneDeep(EMPTY_ENTITY_MY_CLIENT),
        },
      };
    case TransactionToTypes.TO_ENTITY:
      return {
        ...newContent,
        t_to: {
          ...cloneDeep(EMPTY_TO),
          to_entity: cloneDeep(EMPTY_ENTITY),
        },
      };
    default:
      break;
  }

  return transactionContent;
};

export const getFromToTransactionPath = (
  fieldPath: string,
  type: 'from' | 'to',
  isMyClient: boolean,
  subPath: 'account' | 'person' | 'entity' | null,
) => {
  let suffix = '';
  let subKey = '';
  if (type === 'from') {
    suffix = isMyClient ? 't_from_my_client' : 't_from';

    if (subPath) {
      subKey = `from_${subPath}`;
    }
  } else {
    suffix = isMyClient ? 't_to_my_client' : 't_to';
    subKey = 'to_account';

    if (subPath) {
      subKey = `to_${subPath}`;
    }
  }
  return `${fieldPath}.${suffix}${subPath ? `.${subKey}` : ''}`;
};

export const showError =
  (message: string = 'Field is required.') =>
  (value, state, formProps) => {
    const { dirty } = formProps;

    if (!dirty) return message;

    return undefined;
  };

export const getValidationFunction = (
  field:
    | FormFieldSchema<any>
    | FormFieldCustomSchema<any>
    | FormFieldArraySchema<any>,
  validationErrors: GoAMLErrorsDictionary,
) => {
  return {
    validate: validationErrors[field.name] || field.validate,
  };
};

// Will try to set all values in populatedTransaction into newTransaction, useful if a transaction is toggled from Account to Account(My client) or any of these combinations
export const populateFromToTransactionContent = (
  content: TransactionContent,
  populatedTransaction: TransactionContent,
): TransactionContent => {
  const newTransaction = cloneDeep(content);

  // Get from/to keys
  let fromKey = '';
  if ('t_from_my_client' in newTransaction && newTransaction.t_from_my_client) {
    fromKey = 't_from_my_client';
  }
  if ('t_from' in newTransaction && newTransaction.t_from) {
    fromKey = 't_from';
  }
  let toKey = '';
  if ('t_to_my_client' in newTransaction && newTransaction.t_to_my_client) {
    toKey = 't_to_my_client';
  }
  if ('t_to' in newTransaction && newTransaction.t_to) {
    toKey = 't_to';
  }
  if (!fromKey || !toKey) {
    return newTransaction;
  }

  // Get Populated From
  let popFromSection: TransactionFrom['t_from'] | Record<string, never> = {};
  if ('t_from' in populatedTransaction && populatedTransaction.t_from) {
    popFromSection = populatedTransaction.t_from;
  }
  if (
    't_from_my_client' in populatedTransaction &&
    populatedTransaction.t_from_my_client
  ) {
    popFromSection = populatedTransaction.t_from_my_client;
  }

  Object.keys(popFromSection).forEach((key) => {
    // Only copy these keys if they exist on the new transaction
    if (['from_account', 'from_entity', 'from_person'].includes(key)) {
      if (Object.prototype.hasOwnProperty.call(newTransaction[fromKey], key)) {
        newTransaction[fromKey][key] = cloneDeep(popFromSection[key]);
      }
      return;
    }
    newTransaction[fromKey][key] = cloneDeep(popFromSection[key]);
  });

  // Get Populated To
  let popToSection: TransactionTo['t_to'] | Record<string, never> = {};
  if ('t_to' in populatedTransaction && populatedTransaction.t_to) {
    popToSection = populatedTransaction.t_to;
  }
  if (
    't_to_my_client' in populatedTransaction &&
    populatedTransaction.t_to_my_client
  ) {
    popToSection = populatedTransaction.t_to_my_client;
  }
  Object.keys(popToSection).forEach((key) => {
    // Only copy these keys if they exist on the new transaction
    if (['to_account', 'to_entity', 'to_person'].includes(key)) {
      if (Object.prototype.hasOwnProperty.call(newTransaction[toKey], key)) {
        newTransaction[toKey][key] = cloneDeep(popToSection[key]);
      }
      return;
    }
    newTransaction[toKey][key] = cloneDeep(popToSection[key]);
  });
  return newTransaction;
};

const nthIndex = (str: string, char: string, number: number): number => {
  let i = -1;
  let n = number;
  while (n && i < str.length) {
    i += 1;
    n -= 1;
    i = str.indexOf(char, i);
    if (i < 0) break;
  }
  return i;
};

const invertPathIndexes = (originalPath: string, values: object): string => {
  let iter = 1;
  let dynamicPath = originalPath;

  return originalPath.replaceAll(/[\d]/g, (toReplace) => {
    const openBracketPosition = nthIndex(dynamicPath, '[', iter);
    iter += 1;
    const pathToArray = dynamicPath.substring(0, openBracketPosition);
    const arrLength = getIn(values, pathToArray)?.length || 0;
    const invertedIndex = arrLength - Number(toReplace);

    const pathAfterArray = dynamicPath.substring(
      openBracketPosition + toReplace.length + 2,
    ); // + []
    dynamicPath = `${pathToArray}[${invertedIndex}]${pathAfterArray}`;
    return `${invertedIndex}`;
  });
};

export const invertIndexes = (
  filing: Pick<GoAMLFilingDetails, 'content'>,
  validationErrors: GoAMLErrorsDictionary,
) => {
  const formattedTransactionErrors = {};

  Object.keys(validationErrors).forEach((err) => {
    if (err.includes('[')) {
      const pathWithInvertedIndexes = invertPathIndexes(err, filing);
      formattedTransactionErrors[pathWithInvertedIndexes] =
        validationErrors[err];
    } else {
      formattedTransactionErrors[err] = validationErrors[err];
    }
  });

  return formattedTransactionErrors;
};

/* eslint-disable @typescript-eslint/no-unused-vars */
// Allow to delete nested fields by default
export const canDeleteNestedObjectDefault = (
  ix: number,
  length: number,
): boolean => true;
/* eslint-enable @typescript-eslint/no-unused-vars */

export const canDeleteIfMyClientCheck =
  (isMyClient: boolean) =>
  (_: number, length: number = 0): boolean => {
    if (!isMyClient) {
      // Not my client can always delete
      return true;
    }
    // My Client must have at least one object
    return length > 1;
  };
