import produce, { Draft } from 'immer';

// Models
import { NotesActionTypes, ImmerNotesState } from 'app/modules/notes/models';

// Actions
import { NotesActions } from 'app/modules/notes/actions';

// Utils
import deepFreeze from 'app/shared/utils/deepFreeze';

const initialState: Readonly<ImmerNotesState> = {
  notes: [],
};
// to ensure initialState is readonly
deepFreeze(initialState);

const reducer = (state = initialState, action: NotesActions) =>
  produce(state, (draft: Draft<ImmerNotesState>) => {
    switch (action.type) {
      case NotesActionTypes.RETRIEVE_NOTES_SUCCESS:
      case NotesActionTypes.SET_NOTES:
        draft.notes = action.payload;
        return;

      case NotesActionTypes.TOGGLE_EDIT_MODE:
        draft.notes = draft.notes.map((note) =>
          note.id === action.payload.id
            ? { ...note, editMode: !note.editMode }
            : note,
        );
        return;

      case NotesActionTypes.TOGGLE_DELETE_MODE:
        draft.notes = draft.notes.map((note) =>
          note.id === action.payload.id
            ? { ...note, deleteMode: !note.deleteMode }
            : note,
        );
        return;

      case NotesActionTypes.ADD_NOTE_SUCCESS:
        draft.notes.push(action.payload);
        return;

      case NotesActionTypes.DELETE_NOTE_SUCCESS:
        draft.notes = draft.notes.filter((note) => note.id !== action.payload);
        return;

      case NotesActionTypes.UPDATE_NOTE_SUCCESS:
        draft.notes = draft.notes.map((note) =>
          note.id === action.payload.id ? action.payload : note,
        );
        return;
    }
  });
export { reducer as notesReducer, initialState };
