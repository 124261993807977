// Saga
import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Api
import { deleteGoAMLAttachment } from 'app/shared/api/sars';

// Models
import { GoAMLActionTypes } from 'app/modules/goAML/models';

// Actions
import {
  deleteGoAMLAttachment as deleteGoAMLAttachmentAction,
  deleteGoAMLAttachmentSuccess,
} from 'app/modules/goAML/actions';
import { sendSuccessToast } from 'app/shared/toasts/actions';

const rootAction = GoAMLActionTypes.DELETE_GOAML_ATTACHMENT;
function* deleteGoAMLAttachmentFlow({
  payload,
  meta,
}: ReturnType<typeof deleteGoAMLAttachmentAction>) {
  const config = {
    rootAction,
    request: call(deleteGoAMLAttachment, payload, meta),
    success: function* onSuccess() {
      // If agentId present, add newly created tag to agent
      yield put(deleteGoAMLAttachmentSuccess(meta));
      yield put(
        sendSuccessToast(`Successfully removed attachment from GoAML form.`),
      );
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watchDeleteGoAMLAttachment() {
  yield takeLatest(rootAction, deleteGoAMLAttachmentFlow);
}
