// Saga
import { call, put, takeEvery } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';
import makeActions from 'app/shared/sagas/make-actions';

// Models
import { InsightsActionTypes } from 'app/modules/insights/models';

// Api
import { retrieveChart as retrieveChartApi } from 'app/shared/api/insights';

// Actions
import {
  retrieveChart as retrieveChartAction,
  retrieveChartSuccess,
} from 'app/modules/insights/actions';

// Helpers
import { combineRootActionWithId } from 'app/shared/helpers';

const rootAction = InsightsActionTypes.RETRIEVE_CHART;
function* retrieveChart({ payload }: ReturnType<typeof retrieveChartAction>) {
  // to keep track of individual charts that are loading
  const formattedRootAction = combineRootActionWithId(rootAction, payload.id);
  const formattedActions = makeActions(formattedRootAction);
  const config = {
    rootAction: formattedRootAction,
    request: call(retrieveChartApi, payload),
    errorToastMessage: `Something went wrong, unable to retrieve chart ${payload.id}.`,
    success: function* onSuccess(
      result: ReturnType<typeof retrieveChartSuccess>['payload'],
    ) {
      yield put(retrieveChartSuccess(result));
      // to cancel individual loading state
      yield put(formattedActions.success({}));
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watchRetrieveChart() {
  yield takeEvery(rootAction, retrieveChart);
}
