import { isEqual } from 'lodash';

// Models
import {
  AmountInvolvedType,
  APISarValues,
  APISubjectAccountNumber,
  APISubjectAddress,
  APISubjectFormsOfID,
  APISubjectPhoneNumber,
  APISubjectRelationship,
  FinancialInstitutionRole,
  FormIPAddress,
  SarFormValues,
  SubjectType,
  TypeGamingInstitutions,
  UnionToBoolObj,
} from 'app/modules/fincenSarNew/models';

// Constants
import {
  SAR_REPORT_TYPES,
  SECURITIES_AND_FUTURES_FILING_INSTITUTION,
  SECURITIES_AND_FUTURES_ACTIVITY_INSTITUTION,
  SECURITIES_FRAUD_TYPES,
  STRUCTURING_FRAUD_TYPES,
  TYPE_GAMING_INSTITUTIONS,
  FINANCIAL_INSTITUTION_ROLE,
  PAYMENT_INSTRUMENTS_TYPE,
  PRODUCTS_TYPE,
  CYBER_EVENT_TYPES,
  MORTGAGE_FRAUD_TYPES,
  INSURANCE_FRAUD_TYPES,
  OTHER_FRAUD_TYPES,
  ID_DOCUMENTATION_FRAUD_TYPES,
  MONEY_LAUNDERING_TYPES,
  GAMING_FRAUD_TYPES,
  FRAUD_TYPES,
  TERRORIST_FINANCING_TYPES,
  AMOUNT_INVOLVED_TYPE,
  RELATIONSHIP_TYPES,
} from 'app/modules/fincenSarNew/nestingConstants';
import {
  COUNTRY_USMCA,
  NEW_FINANCIAL_INSTITUTION,
  NEW_SUBJECT,
  RELATIONS_WITH_STATUS,
  STATE_ISSUED_IDS,
  US_TERRITORIES,
} from 'app/modules/fincenSarNew/constants';

// Utils
import { formatDate, formatTime } from 'app/shared/utils/date';
import {
  error44EventValue,
  error43IpAddress,
} from 'app/modules/fincenSarNew/utils';
import { objectKeysTyped } from 'app/shared/utils/objectKeysTyped';

const BE_DATE_FORMAT = 'dd MMM yyyy';
const BE_TIME_FORMAT = 'HH:mm:ss';

// 1. Nests form values into API schema
export const nestFormData = (plainData: SarFormValues): APISarValues => {
  const part0: APISarValues['0'] = {
    content: {
      '1_typeOfFiling': arrayToBooleanObject(
        SAR_REPORT_TYPES,
        plainData.typeOfFiling_1,
      ),
      '2_filingInstitutionNote': plainData.filingInstitutionNote_2 || '',
      filingName: plainData.filingName || '',
      priorBsaId:
        plainData.typeOfFiling_1.includes('Correct/Amend prior report') ||
        plainData.typeOfFiling_1.includes('Continuing activity report')
          ? plainData.priorBsaId || ''
          : '',
    },
    valid: true, // TODO update with true validation -> Find out how valid is used in BE
  };

  const part1: APISarValues['1'] = {
    content: {
      '75_primaryFederalRegulator': plainData.typeOfFinancialInstitution_79
        ? plainData.primaryFederalRegulator_75 || ''
        : '',
      '76_filerName': plainData.filerName_76 || '',
      '77_taxpayerIdentificationNumber':
        plainData.taxpayerIdentificationNumber_77 || '',
      '78_taxpayerIdentificationType':
        plainData.taxpayerIdentificationType_78 || '',
      '79_typeOfFinancialInstitution':
        plainData.typeOfFinancialInstitution_79 || '',
      '79_typeOfFinancialInstitutionOther':
        plainData.typeOfFinancialInstitution_79 === 'Other'
          ? plainData.typeOfFinancialInstitutionOther_79 || ''
          : '',
      '80_typeOfSecuritiesAndFutures': arrayToBooleanObject(
        SECURITIES_AND_FUTURES_FILING_INSTITUTION,
        plainData.typeOfFinancialInstitution_79 === 'Securities/Futures'
          ? plainData.typeOfSecuritiesAndFutures_80
          : [],
      ),
      '80_typeOfSecuritiesAndFuturesOther':
        plainData.typeOfFinancialInstitution_79 === 'Securities/Futures' &&
        plainData.typeOfSecuritiesAndFutures_80.includes('Other')
          ? plainData.typeOfSecuritiesAndFuturesOther_80 || ''
          : '',
      '81_financialInstitutionIdentificationNumber':
        plainData.financialInstitutionIdentificationType_81
          ? plainData.financialInstitutionIdentificationNumber_81 || ''
          : '',
      '81_financialInstitutionIdentificationType':
        plainData.financialInstitutionIdentificationType_81 || '',
      '82_address': plainData.address_82 || '',
      '83_city': plainData.city_83 || '',
      '84_state': getCountryCanHaveState(plainData.country_86)
        ? plainData.state_84 || ''
        : '',
      '85_zip': plainData.zip_85 || '',
      '86_country': plainData.country_86 || '',
      '87_alternateName': plainData.alternateName_87 || '',
      '88_internalControlFileNumber':
        plainData.internalControlFileNumber_88 || '',
      '89_leContactAgency': plainData.leContactAgency_89 || '',
      '90_leContactName': plainData.leContactName_90 || '',
      '91_leContactPhoneNumber': plainData.leContactPhoneNumber_91 || '',
      '92_leContactDate': formatDate(
        plainData.leContactDate_92,
        BE_DATE_FORMAT,
      ),
      '93_filingInstitutionContactOffice':
        plainData.filingInstitutionContactOffice_93 || '',
      '94_filingInstitutionContactPhone':
        plainData.filingInstitutionContactPhone_94 || '',
      '95_dateFiled': plainData.dateFiled_95 || '',
    },
    valid: true, // TODO update with true validation -> Find out how valid is used in BE
  };

  const part2: APISarValues['2'] = {
    content: {
      forms: plainData.financialInstitutionWhereActivityOccurred.map(
        (institution) => {
          return {
            '51_typeOfFinancialInstitution':
              institution.typeOfFinancialInstitution_51 || '',
            '51_typeOfFinancialInstitutionOther':
              institution.typeOfFinancialInstitution_51 === 'Other'
                ? institution.typeOfFinancialInstitutionOther_51 || ''
                : '',
            '52_primaryFederalRegulator':
              institution.typeOfFinancialInstitution_51
                ? institution.primaryFederalRegulator_52 || ''
                : '',
            '53_typeOfGamingInstitution': arrayToBooleanObject(
              TYPE_GAMING_INSTITUTIONS,
              institution.typeOfFinancialInstitution_51 ===
                'Casino / Card Club' && institution.typeOfGamingInstitution_53
                ? [institution.typeOfGamingInstitution_53]
                : [],
            ),
            '53_typeOfGamingInstitutionOther':
              institution.typeOfFinancialInstitution_51 ===
                'Casino / Card Club' &&
              institution.typeOfGamingInstitution_53 === 'Other'
                ? institution.typeOfGamingInstitutionOther_53 || ''
                : '',
            '54_typeOfSecuritiesAndFutures': arrayToBooleanObject(
              SECURITIES_AND_FUTURES_ACTIVITY_INSTITUTION,
              institution.typeOfFinancialInstitution_51 === 'Securities/Futures'
                ? institution.typeOfSecuritiesAndFutures_54
                : [],
            ),
            '54_typeOfSecuritiesAndFuturesOther':
              institution.typeOfFinancialInstitution_51 ===
                'Securities/Futures' &&
              institution.typeOfSecuritiesAndFutures_54.includes('Other')
                ? institution.typeOfSecuritiesAndFuturesOther_54 || ''
                : '',
            '55_financialInstitutionIdentificationNumber':
              institution.financialInstitutionIdentificationType_55
                ? institution.financialInstitutionIdentificationNumber_55 || ''
                : '',
            '55_financialInstitutionIdentificationType':
              institution.financialInstitutionIdentificationType_55 || '',
            '56_financialInstitutionRoleInTransaction': nestRoleInTransaction(
              institution.financialInstitutionRoleInTransaction_56,
            ),
            '57_financialInstitutionLegalName':
              institution.financialInstitutionLegalNameUnknown_57
                ? ''
                : institution.financialInstitutionLegalName_57 || '',
            '57_financialInstitutionLegalNameUnknown':
              institution.financialInstitutionLegalNameUnknown_57 || false,
            '58_financialInstitutionAlternateName':
              institution.financialInstitutionAlternateName_58 || '',
            '59_taxPayerIdentificationNumber':
              institution.taxPayerIdentificationNumberUnknown_59
                ? ''
                : institution.taxPayerIdentificationNumber_59 || '',
            '59_taxPayerIdentificationNumberUnknown':
              institution.taxPayerIdentificationNumberUnknown_59 || false,
            '60_taxPayerIdentificationType':
              institution.taxPayerIdentificationType_60 || '',
            '61_address': institution.addressUnknown_61
              ? ''
              : institution.address_61 || '',
            '61_addressUnknown': institution.addressUnknown_61 || false,
            '62_city': institution.cityUnknown_62
              ? ''
              : institution.city_62 || '',
            '62_cityUnknown': institution.cityUnknown_62 || false,
            '63_state':
              getCountryCanHaveState(
                institution.country_65,
                institution.countryUnknown_65,
              ) && !institution.stateUnknown_63
                ? institution.state_63 || ''
                : '',
            '63_stateUnknown': getCountryCanHaveState(
              institution.country_65,
              institution.countryUnknown_65,
            )
              ? institution.stateUnknown_63 || false
              : false,
            '64_zip': institution.zipUnknown_64 ? '' : institution.zip_64 || '',
            '64_zipUnknown': institution.zipUnknown_64 || false,
            '65_country': institution.countryUnknown_65
              ? ''
              : institution.country_65 || '',
            '65_countryUnknown': institution.countryUnknown_65 || false,
            '66_internalControlFileNumber':
              institution.internalControlFileNumber_66 || '',
            '67_lossToFinancialInstitution':
              plainData.typeOfAmountInvolved_29 === 'Amount Known'
                ? `${institution.lossToFinancialInstitution_67 || ''}`
                : '',
            '68-74_branches': institution.noBranchActivityInvolvedIndicator_69a
              ? [
                  {
                    '68_roleInTransaction': nestRoleInTransaction(undefined),
                    '69_address': '',
                    '69a_noBranchActivityInvolvedIndicator': true,
                    '70_rssdNumber': '',
                    '71_city': '',
                    '72_state': '',
                    '73_zip': '',
                    '74_country': '',
                  },
                ]
              : filterBranches(institution.branches_68_74),
          };
        },
      ),
    },
    valid: true, // TODO update with true validation -> Find out how valid is used in BE
  };

  const part3: APISarValues['3'] = {
    content: {
      forms: plainData.subjects.map((subject) => {
        const isUnknown = subject.type_3 === 'unknown';
        const isIndividual = subject.type_3 === 'individual';
        const isEntity = subject.type_3 === 'entity';
        return {
          '3_entityInformationUnknown': {
            'All critical information unavailable (does not include item 24)':
              isUnknown,
            'If entity': isEntity,
          },
          '4_legalName':
            isUnknown || subject.legalNameUnknown_4
              ? ''
              : subject.legalName_4 || '',
          '4_legalNameUnknown': isUnknown
            ? false
            : subject.legalNameUnknown_4 || false,
          '5_firstName':
            !isIndividual || subject.firstNameUnknown_5
              ? ''
              : subject.firstName_5 || '',
          '5_firstNameUnknown': isIndividual
            ? subject.firstNameUnknown_5 || false
            : false,
          '6_middleName': isIndividual ? subject.middleName_6 || '' : '',
          '7_suffix': isIndividual ? subject.suffix_7 || '' : '',
          '8_gender': isIndividual ? subject.gender_8 || '' : '',
          '9_alternativeName': filterMultiTextArray(subject.alternativeName_9),
          '11-15_addressInformation': isUnknown
            ? [
                {
                  '11_address': '',
                  '11_addressUnknown': false,
                  '12_city': '',
                  '12_cityUnknown': false,
                  '13_state': '',
                  '13_stateUnknown': false,
                  '14_zipcode': '',
                  '14_zipcodeUnknown': false,
                  '15_country': '',
                  '15_countryUnknown': false,
                },
              ]
            : filterAddressInfo(subject.addressInformation_11_15),
          '10_occupation': subject.occupation_10 || '',
          '10a_naicsCode': subject.naicsCode_10a || '',
          '16_taxPayerIdentificationNumber':
            isUnknown || subject.taxPayerIdentificationNumberUnknown_16
              ? ''
              : subject.taxPayerIdentificationNumber_16 || '',
          '16_taxPayerIdentificationNumberUnknown': isUnknown
            ? false
            : subject.taxPayerIdentificationNumberUnknown_16 || false,
          '17_taxPayerIdentificationType': isUnknown
            ? ''
            : subject.taxPayerIdentificationType_17 || '',
          '18_formsOfId':
            isUnknown || subject.formsOfIdUnknown_18
              ? [
                  {
                    '18_country': '',
                    '18_idNumber': '',
                    '18_idType': '',
                    '18_state': '',
                    '18z_OtherPartyIdentificationTypeText': '',
                    disabled: subject.formsOfIdUnknown_18 || false,
                  },
                ]
              : filterFormsOfId(subject.formsOfId_18).map((formOfId) => ({
                  ...formOfId,
                  disabled: subject.formsOfIdUnknown_18 || false,
                })),
          '18_dateOfBirthUnknown': isIndividual
            ? subject.dateOfBirthUnknown_19 || false
            : false,
          '19_dateOfBirth':
            subject.dateOfBirth_19 &&
            !subject.dateOfBirthUnknown_19 &&
            isIndividual
              ? formatDate(subject.dateOfBirth_19, BE_DATE_FORMAT)
              : '',
          '20-21_phoneNumberValues': filterMultiObjectArray(
            subject.phoneNumberValues_20_21,
            {
              phoneNumberType_20: '20_phoneNumberType',
              phoneNumber_21: '21_phoneNumber',
            },
          ) as APISubjectPhoneNumber[],
          '22_emailAddress': filterMultiTextArray(subject.emailAddress_22),
          '22a._websiteURL': filterMultiTextArray(subject.websiteURL_22a),
          // 24. Implementation as is for v1 SAR form
          '24_relationships': subject.noRelationships_24
            ? [
                {
                  ...BASE_24_RELATIONSHIP,
                  '24_institutionTIN':
                    plainData.taxpayerIdentificationNumber_77 || '',
                  disabled: true,
                },
              ]
            : filterRelationships(subject.relationships_24),
          '23_statementToFiler': isIndividual
            ? subject.statementToFiler_23 || ''
            : '',
          '27_accountNumbers': subject.accountInfoUnknown_27
            ? [{ ...BASE_27_ACCOUNT, disabled: true }]
            : filterAccountNumbers(subject.accountNumbers_27),
          '28_roleInActivity': subject.roleInActivity_28 || '',
        };
      }),
    },
    valid: true, // TODO update with true validation
  };

  // 29 Amount related fields
  const typeOfAmountInvolvedValue29 =
    plainData.typeOfAmountInvolved_29 === 'Amount Known'
      ? undefined
      : plainData.typeOfAmountInvolved_29;
  let amountInvolved = '';
  if (
    plainData.amountInvolved_29 &&
    plainData.typeOfAmountInvolved_29 === 'Amount Known'
  ) {
    amountInvolved = `${plainData.amountInvolved_29}`;
  }

  // 31 Cumulative amount
  let cumulativeAmount = '';
  if (
    plainData.cumulativeAmount_31 &&
    plainData.typeOfFiling_1.includes('Continuing activity report')
  ) {
    cumulativeAmount = `${plainData.cumulativeAmount_31}`;
  }
  const part4: APISarValues['4'] = {
    content: {
      '29_amountInvolved': amountInvolved,
      '29_typeOfAmountInvolved': valueToBooleanObject(
        AMOUNT_INVOLVED_TYPE,
        typeOfAmountInvolvedValue29,
      ),
      '30_suspiciousActivityStartDate': plainData.suspiciousActivityStartDate_30
        ? formatDate(plainData.suspiciousActivityStartDate_30, BE_DATE_FORMAT)
        : '',
      '30_suspiciousActivityEndDate': plainData.suspiciousActivityEndDate_30
        ? formatDate(plainData.suspiciousActivityEndDate_30, BE_DATE_FORMAT)
        : '',
      '31_cumulativeAmount': cumulativeAmount,
      '32_typeOfStructuring': plainData.typeOfSuspiciousActivity_32_41.includes(
        'typeOfStructuring_32',
      )
        ? arrayToBooleanObject(
            STRUCTURING_FRAUD_TYPES,
            plainData.typeOfStructuring_32,
          )
        : STRUCTURING_FRAUD_TYPES,
      '33_typeOfTerroristFinancing':
        plainData.typeOfSuspiciousActivity_32_41.includes(
          'typeOfTerroristFinancing_33',
        )
          ? arrayToBooleanObject(
              TERRORIST_FINANCING_TYPES,
              plainData.typeOfTerroristFinancing_33,
            )
          : TERRORIST_FINANCING_TYPES,
      '34_typeOfFraud': plainData.typeOfSuspiciousActivity_32_41.includes(
        'typeOfFraud_34',
      )
        ? arrayToBooleanObject(FRAUD_TYPES, plainData.typeOfFraud_34)
        : FRAUD_TYPES,
      '35_typeOfGamingActivities':
        plainData.typeOfSuspiciousActivity_32_41.includes(
          'typeOfGamingActivities_35',
        )
          ? arrayToBooleanObject(
              GAMING_FRAUD_TYPES,
              plainData.typeOfGamingActivities_35,
            )
          : GAMING_FRAUD_TYPES,
      '36_typeOfMoneyLaundering':
        plainData.typeOfSuspiciousActivity_32_41.includes(
          'typeOfMoneyLaundering_36',
        )
          ? arrayToBooleanObject(
              MONEY_LAUNDERING_TYPES,
              plainData.typeOfMoneyLaundering_36,
            )
          : MONEY_LAUNDERING_TYPES,
      '37_typeOfIdentificationDocumentation':
        plainData.typeOfSuspiciousActivity_32_41.includes(
          'typeOfIdentificationDocumentation_37',
        )
          ? arrayToBooleanObject(
              ID_DOCUMENTATION_FRAUD_TYPES,
              plainData.typeOfIdentificationDocumentation_37,
            )
          : ID_DOCUMENTATION_FRAUD_TYPES,
      '38_typeOfOtherSuspiciousActivities':
        plainData.typeOfSuspiciousActivity_32_41.includes(
          'typeOfOtherSuspiciousActivities_38',
        )
          ? arrayToBooleanObject(
              OTHER_FRAUD_TYPES,
              plainData.typeOfOtherSuspiciousActivities_38,
            )
          : OTHER_FRAUD_TYPES,
      '39_typeOfInsurance': plainData.typeOfSuspiciousActivity_32_41.includes(
        'typeOfInsurance_39',
      )
        ? arrayToBooleanObject(
            INSURANCE_FRAUD_TYPES,
            plainData.typeOfInsurance_39,
          )
        : INSURANCE_FRAUD_TYPES,
      '40_typeOfSecuritiesFuturesOptions':
        plainData.typeOfSuspiciousActivity_32_41.includes(
          'typeOfSecuritiesFuturesOptions_40',
        )
          ? arrayToBooleanObject(
              SECURITIES_FRAUD_TYPES,
              plainData.typeOfSecuritiesFuturesOptions_40,
            )
          : SECURITIES_FRAUD_TYPES,
      '41_typeOfMortgageFraud':
        plainData.typeOfSuspiciousActivity_32_41.includes(
          'typeOfMortgageFraud_41',
        )
          ? arrayToBooleanObject(
              MORTGAGE_FRAUD_TYPES,
              plainData.typeOfMortgageFraud_41,
            )
          : MORTGAGE_FRAUD_TYPES,
      '32_typeOfStructuringOther':
        (plainData.typeOfSuspiciousActivity_32_41.includes(
          'typeOfStructuring_32',
        ) &&
          plainData.typeOfStructuring_32.includes('Other') &&
          plainData.typeOfStructuringOther_32) ||
        '',
      '33_typeOfTerroristFinancingOther':
        (plainData.typeOfSuspiciousActivity_32_41.includes(
          'typeOfTerroristFinancing_33',
        ) &&
          plainData.typeOfTerroristFinancing_33.includes('Other') &&
          plainData.typeOfTerroristFinancingOther_33) ||
        '',
      '34_typeOfFraudOther':
        (plainData.typeOfSuspiciousActivity_32_41.includes('typeOfFraud_34') &&
          plainData.typeOfFraud_34.includes('Other') &&
          plainData.typeOfFraudOther_34) ||
        '',
      '35_typeOfGamingActivitiesOther':
        (plainData.typeOfSuspiciousActivity_32_41.includes(
          'typeOfGamingActivities_35',
        ) &&
          plainData.typeOfGamingActivities_35.includes('Other') &&
          plainData.typeOfGamingActivitiesOther_35) ||
        '',
      '36_typeOfMoneyLaunderingOther':
        (plainData.typeOfSuspiciousActivity_32_41.includes(
          'typeOfMoneyLaundering_36',
        ) &&
          plainData.typeOfMoneyLaundering_36.includes('Other') &&
          plainData.typeOfMoneyLaunderingOther_36) ||
        '',
      '37_typeOfIdentificationDocumentationOther':
        (plainData.typeOfSuspiciousActivity_32_41.includes(
          'typeOfIdentificationDocumentation_37',
        ) &&
          plainData.typeOfIdentificationDocumentation_37.includes('Other') &&
          plainData.typeOfIdentificationDocumentationOther_37) ||
        '',
      '38_typeOfOtherSuspiciousActivitiesOther':
        (plainData.typeOfSuspiciousActivity_32_41.includes(
          'typeOfOtherSuspiciousActivities_38',
        ) &&
          plainData.typeOfOtherSuspiciousActivities_38.includes('Other') &&
          plainData.typeOfOtherSuspiciousActivitiesOther_38) ||
        '',
      '39_typeOfInsuranceOther':
        (plainData.typeOfSuspiciousActivity_32_41.includes(
          'typeOfInsurance_39',
        ) &&
          plainData.typeOfInsurance_39.includes('Other') &&
          plainData.typeOfInsuranceOther_39) ||
        '',
      '40_typeOfSecuritiesFuturesOptionsOther':
        (plainData.typeOfSuspiciousActivity_32_41.includes(
          'typeOfSecuritiesFuturesOptions_40',
        ) &&
          plainData.typeOfSecuritiesFuturesOptions_40.includes('Other') &&
          plainData.typeOfSecuritiesFuturesOptionsOther_40) ||
        '',
      '41_typeOfMortgageFraudOther':
        (plainData.typeOfSuspiciousActivity_32_41.includes(
          'typeOfMortgageFraud_41',
        ) &&
          plainData.typeOfMortgageFraud_41.includes('Other') &&
          plainData.typeOfMortgageFraudOther_41) ||
        '',
      '39_disabled': false, // TODO findout what to do about this
      '40_disabled': false, // TODO findout what to do about this
      '42_typeOfCyberEventOther':
        (plainData.typeOfCyberEvent_42.includes('Other') &&
          plainData.typeOfCyberEventOther_42) ||
        '',
      '42_typeOfCyberEvent': arrayToBooleanObject(
        CYBER_EVENT_TYPES,
        plainData.typeOfCyberEvent_42,
      ),
      '43_ipAddress': filterIPAddress(plainData.ipAddress_43),
      // @ts-ignore
      '43_ipAddressDate': '22', // TODO - findout where it is coming from
      '43_ipAddressTimestamp': '', // TODO - findout where it is coming from
      '44_cyberEventIndicatorDescription': '', // TODO - findout where it is coming from
      '44_cyberEventIndicators': plainData.cyberEventIndicators_44.map(
        (cybEvent) => {
          return {
            '44_cyberEventIndicatorDate':
              cybEvent.cyberEventIndicators_44 &&
              [
                'Command and Control IP Address',
                'Suspicious IP Address',
              ].includes(cybEvent.cyberEventIndicators_44) &&
              cybEvent.cyberEventIndicatorDateTime_44
                ? formatDate(
                    cybEvent.cyberEventIndicatorDateTime_44,
                    BE_DATE_FORMAT,
                  )
                : '',
            '44_cyberEventIndicatorTimestamp':
              cybEvent.cyberEventIndicators_44 &&
              [
                'Command and Control IP Address',
                'Suspicious IP Address',
              ].includes(cybEvent.cyberEventIndicators_44) &&
              cybEvent.cyberEventIndicatorDateTime_44
                ? formatTime(
                    cybEvent.cyberEventIndicatorDateTime_44,
                    BE_TIME_FORMAT,
                  )
                : '',
            '44_cyberEventIndicatorValue':
              cybEvent.cyberEventIndicatorValue_44 || '',
            '44_cyberEventIndicators': cybEvent.cyberEventIndicators_44 || '',
            '44_cyberEventIndicatorsOther':
              cybEvent.cyberEventIndicators_44 === 'Other'
                ? cybEvent.cyberEventIndicatorsOther_44 || ''
                : '',
          };
        },
      ),
      '45_typeOfProductsInvolvedOther':
        (plainData.typeOfProductsInvolved_45.includes('Other') &&
          plainData.typeOfProductsInvolvedOther_45) ||
        '',
      '45_typesOfProductsInvolved': arrayToBooleanObject(
        PRODUCTS_TYPE,
        plainData.typeOfProductsInvolved_45.map((product) => {
          if (product === 'Other') {
            return 'Other (List Below)';
          }
          return product;
        }),
      ),
      '46_typeOfPaymentInstrumentInvolved': arrayToBooleanObject(
        PAYMENT_INSTRUMENTS_TYPE,
        plainData.typeOfPaymentInstrumentInvolved_46.map((product) => {
          if (product === 'Other') {
            return 'Other (List Below)';
          }
          return product;
        }),
      ),
      '46_typeOfPaymentInstrumentInvolvedOther':
        (plainData.typeOfPaymentInstrumentInvolved_46.includes('Other') &&
          plainData.typeOfPaymentInstrumentInvolvedOther_46) ||
        '',
      '47_commodityType': filterMultiTextArray(plainData.commodityType_47),
      '48_productInstrumentDescription': filterMultiTextArray(
        plainData.productInstrumentDescription_48,
      ),
      '49_marketWhereTraded': filterMultiTextArray(
        plainData.marketWhereTraded_49,
      ),
      '50_cusipNumber': filterMultiTextArray(plainData.cusipNumber_50),
    },
    valid: true, // TODO update with true validation
  };

  const part5: APISarValues['5'] = {
    content: {
      narrative: plainData.narrative || '',
    },
    valid: true, // TODO update with true validation -> Find out how valid is used in BE
  };
  return {
    '0': part0,
    '1': part1,
    '2': part2,
    '3': part3,
    '4': part4,
    '5': part5,
  };
};

// 2. Flattens API schema into form values
export const flatFormData = (
  nestedData: APISarValues,
  all3241Shown: boolean,
): SarFormValues => {
  const part0 = {
    typeOfFiling_1: booleanObjectToArray(
      nestedData[0].content['1_typeOfFiling'],
    ),
    filingInstitutionNote_2: nestedData[0].content['2_filingInstitutionNote'],
    filingName: nestedData[0].content.filingName,
    priorBsaId: nestedData[0].content.priorBsaId,
  };

  const part1 = {
    primaryFederalRegulator_75:
      nestedData[1].content['75_primaryFederalRegulator'] || undefined,
    filerName_76: nestedData[1].content['76_filerName'],
    taxpayerIdentificationNumber_77:
      nestedData[1].content['77_taxpayerIdentificationNumber'],
    taxpayerIdentificationType_78:
      nestedData[1].content['78_taxpayerIdentificationType'] || undefined,
    typeOfFinancialInstitution_79:
      nestedData[1].content['79_typeOfFinancialInstitution'] || undefined,
    typeOfFinancialInstitutionOther_79:
      nestedData[1].content['79_typeOfFinancialInstitutionOther'],
    typeOfSecuritiesAndFutures_80: booleanObjectToArray(
      nestedData[1].content['80_typeOfSecuritiesAndFutures'],
    ),
    typeOfSecuritiesAndFuturesOther_80:
      nestedData[1].content['80_typeOfSecuritiesAndFuturesOther'],
    financialInstitutionIdentificationNumber_81:
      nestedData[1].content['81_financialInstitutionIdentificationNumber'],
    financialInstitutionIdentificationType_81:
      nestedData[1].content['81_financialInstitutionIdentificationType'] ||
      undefined,
    address_82: nestedData[1].content['82_address'],
    city_83: nestedData[1].content['83_city'],
    state_84: nestedData[1].content['84_state'] || undefined,
    zip_85: nestedData[1].content['85_zip'],
    country_86: nestedData[1].content['86_country'] || undefined,
    alternateName_87: nestedData[1].content['87_alternateName'],
    internalControlFileNumber_88:
      nestedData[1].content['88_internalControlFileNumber'],
    leContactAgency_89: nestedData[1].content['89_leContactAgency'],
    leContactName_90: nestedData[1].content['90_leContactName'],
    leContactPhoneNumber_91: nestedData[1].content['91_leContactPhoneNumber'],
    leContactDate_92: getDateForFlatter(
      nestedData[1].content['92_leContactDate'],
    ),
    filingInstitutionContactOffice_93:
      nestedData[1].content['93_filingInstitutionContactOffice'],
    filingInstitutionContactPhone_94:
      nestedData[1].content['94_filingInstitutionContactPhone'],
    dateFiled_95: nestedData[1].content['95_dateFiled'],
  };

  const part2 = {
    financialInstitutionWhereActivityOccurred: nestedData[2].content.forms.map(
      (institution) => {
        return {
          typeOfFinancialInstitution_51:
            institution['51_typeOfFinancialInstitution'] || undefined,
          typeOfFinancialInstitutionOther_51:
            institution['51_typeOfFinancialInstitutionOther'],
          primaryFederalRegulator_52:
            institution['52_primaryFederalRegulator'] || undefined,
          // grab the key of the first true value in the object
          // old form only allows selecting one option
          typeOfGamingInstitution_53: Object.entries(
            institution['53_typeOfGamingInstitution'],
          ).find(([, v]) => v)?.[0] as TypeGamingInstitutions | undefined,
          typeOfGamingInstitutionOther_53:
            institution['53_typeOfGamingInstitutionOther'],
          typeOfSecuritiesAndFutures_54: booleanObjectToArray(
            institution['54_typeOfSecuritiesAndFutures'],
          ),
          typeOfSecuritiesAndFuturesOther_54:
            institution['54_typeOfSecuritiesAndFuturesOther'],
          financialInstitutionIdentificationNumber_55:
            institution['55_financialInstitutionIdentificationNumber'],
          financialInstitutionIdentificationType_55:
            institution['55_financialInstitutionIdentificationType'] ||
            undefined,
          financialInstitutionRoleInTransaction_56: flatRoleInTransaction(
            institution['56_financialInstitutionRoleInTransaction'],
          ),
          financialInstitutionLegalName_57:
            institution['57_financialInstitutionLegalName'],
          financialInstitutionLegalNameUnknown_57:
            institution['57_financialInstitutionLegalNameUnknown'],
          financialInstitutionAlternateName_58:
            institution['58_financialInstitutionAlternateName'],
          taxPayerIdentificationNumber_59:
            institution['59_taxPayerIdentificationNumber'],
          taxPayerIdentificationNumberUnknown_59:
            institution['59_taxPayerIdentificationNumberUnknown'],
          taxPayerIdentificationType_60:
            institution['60_taxPayerIdentificationType'] || undefined,
          address_61: institution['61_address'],
          addressUnknown_61: institution['61_addressUnknown'],
          city_62: institution['62_city'],
          cityUnknown_62: institution['62_cityUnknown'],
          state_63: institution['63_state'] || undefined,
          stateUnknown_63: institution['63_stateUnknown'],
          zip_64: institution['64_zip'],
          zipUnknown_64: institution['64_zipUnknown'],
          country_65: institution['65_country'] || undefined,
          countryUnknown_65: institution['65_countryUnknown'],
          internalControlFileNumber_66:
            institution['66_internalControlFileNumber'],
          lossToFinancialInstitution_67: isNaN(
            Number.parseInt(institution['67_lossToFinancialInstitution'], 10),
          )
            ? undefined
            : Number.parseInt(institution['67_lossToFinancialInstitution'], 10),
          branches_68_74: institution['68-74_branches'][0]?.[
            '69a_noBranchActivityInvolvedIndicator'
          ]
            ? NEW_FINANCIAL_INSTITUTION.branches_68_74
            : institution['68-74_branches'].map((branch) => {
                return {
                  roleInTransaction_68: flatRoleInTransaction(
                    branch['68_roleInTransaction'],
                  ),
                  address_69: branch['69_address'],
                  rssdNumber_70: branch['70_rssdNumber'],
                  city_71: branch['71_city'],
                  state_72: branch['72_state'] || undefined,
                  zip_73: branch['73_zip'],
                  country_74: branch['74_country'] || undefined,
                };
              }),
          noBranchActivityInvolvedIndicator_69a:
            institution['68-74_branches'][0]?.[
              '69a_noBranchActivityInvolvedIndicator'
            ],
        };
      },
    ),
  };

  const part3 = {
    subjects: nestedData[3].content.forms.map<
      SarFormValues['subjects'][number]
    >((subject) => {
      let type: SubjectType = 'individual';
      if (subject['3_entityInformationUnknown']['If entity']) {
        type = 'entity';
      }

      // unknown overrides other options
      if (
        subject['3_entityInformationUnknown'][
          'All critical information unavailable (does not include item 24)'
        ]
      ) {
        type = 'unknown';
      }
      return {
        type_3: type,
        legalName_4: subject['4_legalName'],
        legalNameUnknown_4: subject['4_legalNameUnknown'],
        firstName_5: subject['5_firstName'],
        firstNameUnknown_5: subject['5_firstNameUnknown'],
        middleName_6: subject['6_middleName'],
        suffix_7: subject['7_suffix'],
        gender_8: subject['8_gender'] || undefined,
        alternativeName_9: subject['9_alternativeName'],
        occupation_10: subject['10_occupation'],
        naicsCode_10a: subject['10a_naicsCode'] || undefined,
        addressInformation_11_15: subject['11-15_addressInformation'].map(
          (addInfo) => {
            return {
              addressUnknown_11: addInfo['11_addressUnknown'],
              address_11: addInfo['11_address'],
              cityUnknown_12: addInfo['12_cityUnknown'],
              city_12: addInfo['12_city'],
              countryUnknown_15: addInfo['15_countryUnknown'],
              country_15: addInfo['15_country'] || undefined,
              stateUnknown_13: addInfo['13_stateUnknown'],
              state_13: addInfo['13_state'] || undefined,
              zipcodeUnknown_14: addInfo['14_zipcodeUnknown'],
              zipcode_14: addInfo['14_zipcode'],
            };
          },
        ),
        taxPayerIdentificationNumberUnknown_16:
          subject['16_taxPayerIdentificationNumberUnknown'],
        taxPayerIdentificationNumber_16:
          subject['16_taxPayerIdentificationNumber'],
        taxPayerIdentificationType_17:
          subject['17_taxPayerIdentificationType'] || undefined,
        formsOfIdUnknown_18: subject['18_formsOfId'][0]?.disabled,
        formsOfId_18: subject['18_formsOfId'].map((formOfId) => {
          return {
            OtherPartyIdentificationTypeText_18z:
              formOfId['18z_OtherPartyIdentificationTypeText'],
            country_18: formOfId['18_country'] || undefined,
            idNumber_18: formOfId['18_idNumber'],
            idType_18: formOfId['18_idType'] || undefined,
            state_18: formOfId['18_state'] || undefined,
          };
        }),
        dateOfBirthUnknown_19: subject['18_dateOfBirthUnknown'], // Old mistake should have been 19_dateOfBirthUnknown
        dateOfBirth_19: formatBEDate(subject['19_dateOfBirth']),
        phoneNumberValues_20_21: subject['20-21_phoneNumberValues'].map(
          (phNum) => {
            return {
              phoneNumberType_20: phNum['20_phoneNumberType'] || undefined,
              phoneNumber_21: phNum['21_phoneNumber'],
            };
          },
        ),
        emailAddress_22: subject['22_emailAddress'],
        websiteURL_22a: subject['22a._websiteURL'],
        statementToFiler_23: subject['23_statementToFiler'] || undefined,
        noRelationships_24: subject['24_relationships'][0]?.disabled,
        relationships_24: subject['24_relationships'].map((relationship) => {
          return {
            institutionTIN_24: relationship['24_institutionTIN'] || undefined,
            relationshipOther_24: relationship['24_relationshipOther'],
            status_25: relationship['25_status'] || undefined,
            actionDate_26: getDateForFlatter(relationship['26_actionDate']),
            relationship_24: booleanObjectToArray(
              relationship['24_relationship'],
            ),
          };
        }),
        accountInfoUnknown_27: subject['27_accountNumbers'][0]?.disabled,
        accountNumbers_27: subject['27_accountNumbers'].map((accNumber) => {
          const { disabled, ...newAccNumber } = accNumber;
          return {
            accountNumbers_27: newAccNumber['27_accountNumbers'].map((accN) => {
              return {
                accountNumberClosed_27: accN['27_accountNumberClosed'],
                accountNumber_27: accN['27_accountNumber'],
              };
            }),
            tin_27: newAccNumber['27_tin'],
            tinNonUS_27: newAccNumber['27_tinUnknown'],
          };
        }),
        roleInActivity_28: subject['28_roleInActivity'] || undefined,
      };
    }),
  };

  // 29 Amount related fields
  let typeOfAmountInvolved: AmountInvolvedType = 'Amount Known';

  if (nestedData[4].content['29_typeOfAmountInvolved']['Amount Unknown']) {
    typeOfAmountInvolved = 'Amount Unknown';
  }

  if (nestedData[4].content['29_typeOfAmountInvolved']['No Amount Involved']) {
    typeOfAmountInvolved = 'No Amount Involved';
  }

  // 32-41 Type of suspicious activity related fields
  const typeOfStructuring = booleanObjectToArray(
    nestedData[4].content['32_typeOfStructuring'],
  );
  const typeOfTerroristFinancing = booleanObjectToArray(
    nestedData[4].content['33_typeOfTerroristFinancing'],
  );
  const typeOfFraud = booleanObjectToArray(
    nestedData[4].content['34_typeOfFraud'],
  );
  const typeOfGamingActivities = booleanObjectToArray(
    nestedData[4].content['35_typeOfGamingActivities'],
  );
  const typeOfMoneyLaundering = booleanObjectToArray(
    nestedData[4].content['36_typeOfMoneyLaundering'],
  );
  const typeOfIdentificationDocumentation = booleanObjectToArray(
    nestedData[4].content['37_typeOfIdentificationDocumentation'],
  );
  const typeOfOtherSuspiciousActivities = booleanObjectToArray(
    nestedData[4].content['38_typeOfOtherSuspiciousActivities'],
  );
  const typeOfInsurance = booleanObjectToArray(
    nestedData[4].content['39_typeOfInsurance'],
  );
  const typeOfSecuritiesFuturesOptions = booleanObjectToArray(
    nestedData[4].content['40_typeOfSecuritiesFuturesOptions'],
  );
  const typeOfMortgageFraud = booleanObjectToArray(
    nestedData[4].content['41_typeOfMortgageFraud'],
  );

  const typeOfSuspiciousActivity: SarFormValues['typeOfSuspiciousActivity_32_41'] =
    [];
  if (typeOfStructuring.length > 0 || all3241Shown) {
    typeOfSuspiciousActivity.push('typeOfStructuring_32');
  }
  if (typeOfTerroristFinancing.length > 0 || all3241Shown) {
    typeOfSuspiciousActivity.push('typeOfTerroristFinancing_33');
  }
  if (typeOfFraud.length > 0 || all3241Shown) {
    typeOfSuspiciousActivity.push('typeOfFraud_34');
  }
  if (typeOfGamingActivities.length > 0 || all3241Shown) {
    typeOfSuspiciousActivity.push('typeOfGamingActivities_35');
  }
  if (typeOfMoneyLaundering.length > 0 || all3241Shown) {
    typeOfSuspiciousActivity.push('typeOfMoneyLaundering_36');
  }
  if (typeOfIdentificationDocumentation.length > 0 || all3241Shown) {
    typeOfSuspiciousActivity.push('typeOfIdentificationDocumentation_37');
  }
  if (typeOfOtherSuspiciousActivities.length > 0 || all3241Shown) {
    typeOfSuspiciousActivity.push('typeOfOtherSuspiciousActivities_38');
  }
  if (typeOfInsurance.length > 0 || all3241Shown) {
    typeOfSuspiciousActivity.push('typeOfInsurance_39');
  }
  if (typeOfSecuritiesFuturesOptions.length > 0 || all3241Shown) {
    typeOfSuspiciousActivity.push('typeOfSecuritiesFuturesOptions_40');
  }
  if (typeOfMortgageFraud.length > 0 || all3241Shown) {
    typeOfSuspiciousActivity.push('typeOfMortgageFraud_41');
  }

  const part4 = {
    typeOfAmountInvolved_29: typeOfAmountInvolved,
    amountInvolved_29: isNaN(
      Number.parseInt(nestedData[4].content['29_amountInvolved'], 10),
    )
      ? undefined
      : Number.parseInt(nestedData[4].content['29_amountInvolved'], 10),
    suspiciousActivityEndDate_30: formatBEDate(
      nestedData[4].content['30_suspiciousActivityEndDate'],
    ),
    suspiciousActivityStartDate_30: formatBEDate(
      nestedData[4].content['30_suspiciousActivityStartDate'],
    ),
    cumulativeAmount_31: isNaN(
      Number.parseInt(nestedData[4].content['31_cumulativeAmount'], 10),
    )
      ? undefined
      : Number.parseInt(nestedData[4].content['31_cumulativeAmount'], 10),
    typeOfSuspiciousActivity_32_41: typeOfSuspiciousActivity,
    typeOfStructuring_32: typeOfStructuring,
    typeOfTerroristFinancing_33: typeOfTerroristFinancing,
    typeOfFraud_34: typeOfFraud,
    typeOfGamingActivities_35: typeOfGamingActivities,
    typeOfMoneyLaundering_36: typeOfMoneyLaundering,
    typeOfIdentificationDocumentation_37: typeOfIdentificationDocumentation,
    typeOfOtherSuspiciousActivities_38: typeOfOtherSuspiciousActivities,
    typeOfInsurance_39: typeOfInsurance,
    typeOfSecuritiesFuturesOptions_40: typeOfSecuritiesFuturesOptions,
    typeOfMortgageFraud_41: typeOfMortgageFraud,
    typeOfStructuringOther_32:
      nestedData[4].content['32_typeOfStructuringOther'],
    typeOfTerroristFinancingOther_33:
      nestedData[4].content['33_typeOfTerroristFinancingOther'],
    typeOfFraudOther_34: nestedData[4].content['34_typeOfFraudOther'],
    typeOfGamingActivitiesOther_35:
      nestedData[4].content['35_typeOfGamingActivitiesOther'],
    typeOfMoneyLaunderingOther_36:
      nestedData[4].content['36_typeOfMoneyLaunderingOther'],
    typeOfIdentificationDocumentationOther_37:
      nestedData[4].content['37_typeOfIdentificationDocumentationOther'],
    typeOfOtherSuspiciousActivitiesOther_38:
      nestedData[4].content['38_typeOfOtherSuspiciousActivitiesOther'],
    typeOfInsuranceOther_39: nestedData[4].content['39_typeOfInsuranceOther'],
    typeOfSecuritiesFuturesOptionsOther_40:
      nestedData[4].content['40_typeOfSecuritiesFuturesOptionsOther'],
    typeOfMortgageFraudOther_41:
      nestedData[4].content['41_typeOfMortgageFraudOther'],
    typeOfCyberEvent_42: booleanObjectToArray(
      nestedData[4].content['42_typeOfCyberEvent'],
    ),
    typeOfCyberEventOther_42: nestedData[4].content['42_typeOfCyberEventOther'],
    ipAddress_43: flat43IpAddresses(nestedData[4].content['43_ipAddress']),
    cyberEventIndicators_44: nestedData[4].content[
      '44_cyberEventIndicators'
    ].map((cybEvent) => {
      return {
        cyberEventIndicatorDateTime_44: getDateForFlatter(
          `${cybEvent['44_cyberEventIndicatorDate']} ${cybEvent['44_cyberEventIndicatorTimestamp']}`,
        ),
        cyberEventIndicatorValue_44: error44EventValue(
          cybEvent['44_cyberEventIndicatorValue'],
          cybEvent['44_cyberEventIndicators'],
        )
          ? undefined
          : cybEvent['44_cyberEventIndicatorValue'],
        cyberEventIndicatorsOther_44:
          cybEvent['44_cyberEventIndicatorsOther'] || undefined,
        cyberEventIndicators_44:
          cybEvent['44_cyberEventIndicators'] || undefined,
      };
    }),
    typeOfProductsInvolved_45: booleanObjectToArray(
      nestedData[4].content['45_typesOfProductsInvolved'],
    ).map((product) => {
      if (product === 'Other (List Below)') {
        return 'Other';
      }
      return product;
    }),
    typeOfProductsInvolvedOther_45:
      nestedData[4].content['45_typeOfProductsInvolvedOther'] || undefined,
    typeOfPaymentInstrumentInvolved_46: booleanObjectToArray(
      nestedData[4].content['46_typeOfPaymentInstrumentInvolved'],
    ).map((product) => {
      if (product === 'Other (List Below)') {
        return 'Other';
      }
      return product;
    }),
    typeOfPaymentInstrumentInvolvedOther_46:
      nestedData[4].content['46_typeOfPaymentInstrumentInvolvedOther'] ||
      undefined,
    commodityType_47: nestedData[4].content['47_commodityType'],
    productInstrumentDescription_48:
      nestedData[4].content['48_productInstrumentDescription'],
    marketWhereTraded_49: nestedData[4].content['49_marketWhereTraded'],

    cusipNumber_50: nestedData[4].content['50_cusipNumber'],
  };

  const part5 = { narrative: nestedData[5].content.narrative };
  return {
    ...part0,
    ...part1,
    ...part2,
    ...part3,
    ...part4,
    ...part5,
  };
};

export const flat43IpAddresses = (
  nestedIps: APISarValues['4']['content']['43_ipAddress'],
): FormIPAddress[] => {
  return nestedIps.map((ipAdd) => {
    return {
      ipAddressDate_43: getDateForFlatter(ipAdd['43_ipAddressDate']),
      ipAddressTime_43: ipAdd['43_ipAddressTimestamp'],
      ipAddress_43: error43IpAddress(ipAdd['43_ipAddress'])
        ? undefined
        : ipAdd['43_ipAddress'],
    };
  });
};

// Utils
const arrayToBooleanObject = <Obj extends Record<string, boolean>>(
  objectFramework: Obj,
  arrayOfIncludedKeys: (keyof Obj)[],
) => {
  const objectOfIncludedOnce = arrayOfIncludedKeys.reduce(
    (acc, cur) => {
      acc[cur] = true;
      return acc;
    },
    {} as Record<keyof Obj, boolean>,
  );

  return { ...objectFramework, ...objectOfIncludedOnce };
};

const valueToBooleanObject = <Key extends string>(
  objectFramework: Record<Key, boolean>,
  value?: Key,
) => {
  return objectKeysTyped(objectFramework).reduce(
    (acc, cur) => {
      acc[cur] = value === cur;
      return acc;
    },
    { ...objectFramework },
  );
};

const booleanObjectToArray = <Obj extends Record<string, boolean>>(
  obj: Obj,
) => {
  return objectKeysTyped(obj).filter((key) => {
    return obj[key];
  });
};

const getDateForFlatter = (apiDate: string) => {
  return isNaN(Number(new Date(apiDate))) ? undefined : new Date(apiDate);
};

const filterMultiTextArray = (
  textArray: Array<string | undefined>,
): string[] => {
  const filteredArray = textArray.filter((text) => Boolean(text));
  if (filteredArray.length === 0) {
    return [''];
  }
  return filteredArray as string[];
};

const STR_ADDRESS_MAPPING = {
  address_11: '11_address',
  city_12: '12_city',
  state_13: '13_state',
  zipcode_14: '14_zipcode',
  country_15: '15_country',
};

const UNKNOWN_MAPPING = {
  address_11: 'addressUnknown_11',
  city_12: 'cityUnknown_12',
  state_13: 'stateUnknown_13',
  zipcode_14: 'zipcodeUnknown_14',
  country_15: 'countryUnknown_15',
};

const BOOL_ADDRESS_MAPPING = {
  addressUnknown_11: '11_addressUnknown',
  cityUnknown_12: '12_cityUnknown',
  stateUnknown_13: '13_stateUnknown',
  zipcodeUnknown_14: '14_zipcodeUnknown',
  countryUnknown_15: '15_countryUnknown',
};

const getCountryCanHaveState = (
  countryValue?: string,
  countryUnknown?: boolean,
): boolean => {
  return (
    (COUNTRY_USMCA.has(countryValue || '') ||
      US_TERRITORIES.has(countryValue || '')) &&
    !countryUnknown
  );
};

const filterAddressInfo = (
  addressInfo: SarFormValues['subjects'][0]['addressInformation_11_15'],
): APISubjectAddress[] => {
  const filteredArray = addressInfo.filter((address) => {
    // address can become null | undefined in form-state. TS cannot guard it as it is runtime!
    if (!address) {
      return false;
    }
    const validCountryForState = getCountryCanHaveState(
      address?.country_15,
      address?.countryUnknown_15,
    );

    const newStateUnknown = validCountryForState
      ? address?.stateUnknown_13
      : false;

    const newSate =
      validCountryForState && !address?.stateUnknown_13
        ? address?.state_13
        : undefined;

    const newAddress = {
      ...address,
      state_13: newSate,
      stateUnknown_13: newStateUnknown,
    };
    return Object.values(newAddress).some(Boolean);
  });
  if (filteredArray.length === 0) {
    return [
      {
        '11_address': '',
        '11_addressUnknown': false,
        '12_city': '',
        '12_cityUnknown': false,
        '13_state': '',
        '13_stateUnknown': false,
        '14_zipcode': '',
        '14_zipcodeUnknown': false,
        '15_country': '',
        '15_countryUnknown': false,
      },
    ];
  }

  return filteredArray.map((address) => {
    return objectKeysTyped(address).reduce(
      (acc, key) => {
        if (/unknown/i.test(key)) {
          acc[BOOL_ADDRESS_MAPPING[key]] = address[key] || false;
        } else {
          acc[STR_ADDRESS_MAPPING[key]] = address[UNKNOWN_MAPPING[key]]
            ? ''
            : address[key] || '';
        }
        return acc;
      },
      {
        '11_address': '',
        '11_addressUnknown': false,
        '12_city': '',
        '12_cityUnknown': false,
        '13_state': '',
        '13_stateUnknown': false,
        '14_zipcode': '',
        '14_zipcodeUnknown': false,
        '15_country': '',
        '15_countryUnknown': false,
      },
    );
  });
};

const FORMS_OF_ID_MAP = {
  country_18: '18_country',
  idNumber_18: '18_idNumber',
  idType_18: '18_idType',
  state_18: '18_state',
  OtherPartyIdentificationTypeText_18z: '18z_OtherPartyIdentificationTypeText',
};

const BASE_18_FORMS_OF_ID: APISubjectFormsOfID = {
  '18_country': '',
  '18_idNumber': '',
  '18_idType': '',
  '18_state': '',
  '18z_OtherPartyIdentificationTypeText': '',
  disabled: false,
};

const filterFormsOfId = (
  formsOfIdArray: SarFormValues['subjects'][0]['formsOfId_18'],
): APISubjectFormsOfID[] => {
  const filteredArray = formsOfIdArray
    .map((formOfId) => {
      // formOfId can become null | undefined in form-state. TS cannot guard it as it is runtime!
      if (!formOfId) {
        return {} as SarFormValues['subjects'][0]['formsOfId_18'][0];
      }
      const newSate =
        getCountryCanHaveState(formOfId?.country_18) &&
        STATE_ISSUED_IDS.includes(formOfId?.idType_18 || '')
          ? formOfId?.state_18
          : undefined;
      return { ...formOfId, state_18: newSate };
    })
    .filter((formOfId) => {
      return Object.values(formOfId).some((val) => Boolean(val));
    });

  if (filteredArray.length === 0) {
    return [{ ...BASE_18_FORMS_OF_ID }];
  }

  return filteredArray.map((formOfId) => {
    return objectKeysTyped(formOfId).reduce(
      (acc, cur) => {
        if (!formOfId[cur]) {
          acc[FORMS_OF_ID_MAP[cur]] = '';
        } else {
          acc[FORMS_OF_ID_MAP[cur]] = formOfId[cur];
        }
        return acc;
      },
      { ...BASE_18_FORMS_OF_ID },
    );
  });
};

const filterMultiObjectArray = (
  objArray: Record<string, string | undefined>[],
  baseNestMap: Record<string, string>,
): Record<string, string>[] => {
  const filteredArray = objArray.filter((obj) => {
    // obj can become null | undefined in form-state. TS cannot guard it as it is runtime!
    if (!obj) {
      return false;
    }
    return Object.values(obj).some((val) => Boolean(val));
  });

  if (filteredArray.length === 0) {
    const defaultObject = objectKeysTyped(baseNestMap).reduce((acc, cur) => {
      acc[baseNestMap[cur]] = '';
      return acc;
    }, {});
    return [defaultObject];
  }

  return filteredArray.map((obj) => {
    return objectKeysTyped(baseNestMap).reduce((acc, cur) => {
      if (!obj[cur]) {
        acc[baseNestMap[cur]] = '';
      } else {
        acc[baseNestMap[cur]] = obj[cur];
      }
      return acc;
    }, {});
  });
};

const BASE_24_RELATIONSHIP: APISubjectRelationship = {
  disabled: false,
  '24_institutionTIN': '',
  '24_relationshipOther': '',
  '24_relationship': RELATIONSHIP_TYPES,
  '25_status': '',
  '26_actionDate': '',
};

const filterRelationships = (
  relations: SarFormValues['subjects'][0]['relationships_24'],
): APISubjectRelationship[] => {
  const filteredRelations = relations.filter((rel) => {
    // rel can become null | undefined in form-state. TS cannot guard it as it is runtime!
    if (!rel) {
      return false;
    }
    return !isEqual(rel, NEW_SUBJECT.relationships_24[0]);
  });
  if (filteredRelations.length === 0) {
    return [{ ...BASE_24_RELATIONSHIP }];
  }
  return filteredRelations.map((relationship) => {
    const otherIncluded = relationship?.relationship_24.includes('Other');
    let status: APISubjectRelationship['25_status'] = '';
    let actionDate = '';
    if (
      relationship?.status_25 &&
      relationship?.relationship_24.some((rel) =>
        RELATIONS_WITH_STATUS.has(rel),
      )
    ) {
      status = relationship?.status_25;

      if (relationship?.status_25 !== 'Relationship continues') {
        actionDate = formatDate(relationship?.actionDate_26, BE_DATE_FORMAT);
      }
    }
    return {
      disabled: false,
      '24_institutionTIN': relationship?.institutionTIN_24 || '',
      '24_relationshipOther': otherIncluded
        ? relationship?.relationshipOther_24 || ''
        : '',
      '25_status': status,
      '26_actionDate': actionDate,
      '24_relationship': arrayToBooleanObject(
        RELATIONSHIP_TYPES,
        relationship?.relationship_24,
      ),
    };
  });
};

const BASE_27_ACCOUNT: APISubjectAccountNumber = {
  '27_accountNumbers': [
    { '27_accountNumber': '', '27_accountNumberClosed': false },
  ],
  '27_tin': '',
  '27_tinUnknown': false,
  disabled: false,
};

const filterAccountNumbers = (
  accNums: SarFormValues['subjects'][0]['accountNumbers_27'],
): APISubjectAccountNumber[] => {
  const filteredAccounts = accNums.filter((account) => {
    // account can become null | undefined in form-state. TS cannot guard it as it is runtime!
    if (!account) {
      return false;
    }
    return !isEqual(account, NEW_SUBJECT.accountNumbers_27[0]);
  });
  if (filteredAccounts.length === 0) {
    return [{ ...BASE_27_ACCOUNT }];
  }
  return filteredAccounts.map((accNum) => ({
    '27_accountNumbers': accNum?.accountNumbers_27.map((accN) => {
      return {
        '27_accountNumber': accN.accountNumber_27 || '',
        '27_accountNumberClosed': accN.accountNumberClosed_27 || false,
      };
    }),
    '27_tin': accNum?.tin_27 || '',
    '27_tinUnknown': accNum?.tinNonUS_27 || false,
    disabled: false,
  }));
};

const nestRoleInTransaction = (
  formValue?: FinancialInstitutionRole,
): UnionToBoolObj<FinancialInstitutionRole> => {
  const apiValue = { ...FINANCIAL_INSTITUTION_ROLE };
  switch (formValue) {
    case 'Both':
      apiValue.Both = true;
      apiValue['Selling location'] = true;
      apiValue['Paying location'] = true;
      break;
    case 'Selling location':
      apiValue['Selling location'] = true;
      break;
    case 'Paying location':
      apiValue['Paying location'] = true;
      break;
    default:
      break;
  }
  return apiValue;
};

const flatRoleInTransaction = (
  apiData: UnionToBoolObj<FinancialInstitutionRole>,
): FinancialInstitutionRole | undefined => {
  if (apiData.Both) {
    return 'Both';
  } else if (apiData['Selling location']) {
    return 'Selling location';
  } else if (apiData['Paying location']) {
    return 'Paying location';
  }
  return undefined;
};

const filterBranches = (
  branches: SarFormValues['financialInstitutionWhereActivityOccurred'][0]['branches_68_74'],
) => {
  const filteredBranches = branches.filter((branch) => {
    // branch can become null | undefined in form-state. TS cannot guard it as it is runtime!
    if (!branch) {
      return false;
    }
    return Object.values(branch).some((v) => Boolean(v));
  });
  if (filteredBranches.length === 0) {
    return [
      {
        '68_roleInTransaction': nestRoleInTransaction(),
        '69_address': '',
        '69a_noBranchActivityInvolvedIndicator': false,
        '70_rssdNumber': '',
        '71_city': '',
        '72_state': '',
        '73_zip': '',
        '74_country': '',
      },
    ];
  }
  return filteredBranches.map((branch) => {
    return {
      '68_roleInTransaction': nestRoleInTransaction(
        branch.roleInTransaction_68,
      ),
      '69_address': branch.address_69 || '',
      // Institution level field is repeated per branch
      '69a_noBranchActivityInvolvedIndicator': false,
      '70_rssdNumber': branch.rssdNumber_70 || '',
      '71_city': branch.city_71 || '',
      '72_state': getCountryCanHaveState(branch.country_74)
        ? branch.state_72 || ''
        : '',
      '73_zip': branch.zip_73 || '',
      '74_country': branch.country_74 || '',
    };
  });
};

const filterIPAddress = (ipAddresses: SarFormValues['ipAddress_43']) => {
  const filteredIPs = ipAddresses.filter((ipAdd) => ipAdd?.ipAddress_43);
  if (filteredIPs.length === 0) {
    return [
      {
        '43_ipAddress': '',
        '43_ipAddressDate': '',
        '43_ipAddressTimestamp': '',
      },
    ];
  }
  return filteredIPs.map((ipAdd) => {
    const isDateSent = ipAdd?.ipAddress_43 && ipAdd?.ipAddressDate_43;
    let timeSent: typeof ipAdd.ipAddressTime_43;
    if (isDateSent) {
      if (ipAdd?.ipAddressTime_43) {
        timeSent = ipAdd?.ipAddressTime_43;
      } else {
        timeSent = '00:00:00';
      }
    }

    return {
      '43_ipAddress': ipAdd?.ipAddress_43 || '',
      '43_ipAddressDate':
        ipAdd?.ipAddress_43 && ipAdd?.ipAddressDate_43
          ? formatDate(ipAdd.ipAddressDate_43, BE_DATE_FORMAT)
          : '',
      '43_ipAddressTimestamp': timeSent || '',
    };
  });
};

const formatBEDate = (date?: string) => {
  if (!date) {
    return undefined;
  }
  if (date.includes(':')) {
    const timelessDate = date.replace(/T.+/, '');
    const [yearStr, monStr, dayStr] = timelessDate.split('-');
    const year = parseInt(yearStr, 10);
    const monthIndex = parseInt(monStr, 10) - 1; // For Date function (Jan - Dec) : (0 - 11)
    const day = parseInt(dayStr, 10);
    return new Date(year, monthIndex, day);
  }
  return new Date(date);
};
