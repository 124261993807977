// /detection-models/templates
import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { DetectionModelsActionTypes } from 'app/modules/detectionModels/models';

// Actions
import {
  retrieveDetectionModelTemplates as retrieveDetectionModelTemplatesAction,
  retrieveDetectionModelTemplatesSuccess,
} from 'app/modules/detectionModels/actions';

// API
import { retrieveDetectionModelTemplates as retrieveDetectionModelTemplatesAPI } from 'app/shared/api/detectionModels';

const rootAction =
  DetectionModelsActionTypes.RETRIEVE_DETECTION_MODEL_TEMPLATES;
export function* retrieveDetectionModelTemplates({
  payload,
}: ReturnType<typeof retrieveDetectionModelTemplatesAction>) {
  const config = {
    rootAction,
    errorToastMessage: 'Something went wrong, unable to retrieve templates.',
    request: call(retrieveDetectionModelTemplatesAPI, payload),
    success: function* onSuccess(result) {
      yield put(retrieveDetectionModelTemplatesSuccess(result));
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watch() {
  yield takeLatest(rootAction, retrieveDetectionModelTemplates);
}
