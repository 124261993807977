import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import {
  AlertsActionTypes,
  RetrieveAlertTxnsPayload,
} from 'app/modules/alerts/models';
import { InvestigationType } from 'app/modules/investigations/models';

// Types
import { InvestigationsAlertResponse } from 'app/modules/investigations/types';

// Actions
import {
  retrieveAlertDetails as retrieveAlertDetailsAction,
  retrieveAlertDetailsSuccess,
  retrieveAlertTransactions,
  retrieveAlertActionEvents,
} from 'app/modules/alerts/actions';
import { retrieveCustomChecklistSubmission } from 'app/shared/customChecklist/actions';

// API
import { retrieveInvestigationsAlert } from 'app/modules/investigations/api';

// Constants
import { DEFAULT_PAGINATION_PAYLOAD } from 'app/shared/pagination/constants';
import { RetrieveAlertActionEventsPayload } from 'app/modules/alerts/requests';

const rootAction = AlertsActionTypes.RETRIEVE_ALERT_DETAILS;
function* retrieveAlertDetails({
  payload: { id, useAlertTxnCacheEndpoint },
}: ReturnType<typeof retrieveAlertDetailsAction>) {
  const config = {
    rootAction,
    request: call(retrieveInvestigationsAlert, {
      object_type: InvestigationType.ALERT,
      object_ids: [id],
      short: false,
    }),
    success: function* onSuccess(result: InvestigationsAlertResponse) {
      const alertDetailsResponse = result.alerts[0];
      yield put(retrieveAlertDetailsSuccess(alertDetailsResponse));

      /*
        To retrieve alert transactions
       */
      const transactionsPayload: RetrieveAlertTxnsPayload = {
        hash_key: String(alertDetailsResponse.id),
        useCacheEndpoint: useAlertTxnCacheEndpoint,
        ...DEFAULT_PAGINATION_PAYLOAD,
      };

      const actionEventsPayload: RetrieveAlertActionEventsPayload = {
        alert_id: alertDetailsResponse.id,
        ...DEFAULT_PAGINATION_PAYLOAD,
      };

      yield put(retrieveAlertTransactions(transactionsPayload));
      yield put(retrieveAlertActionEvents(actionEventsPayload));
      yield put(retrieveCustomChecklistSubmission(id));
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watchRetrieveAlertDetails() {
  yield takeLatest(rootAction, retrieveAlertDetails);
}
