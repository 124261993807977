// Redux
import { Reducer } from 'redux';
import { produce, Draft } from 'immer';
import { cloneDeep } from 'lodash';

// Models
import {
  DetectionModelsActionTypes,
  ModelsState,
} from 'app/modules/detectionModels/models';

// Actions
import { ModelsActions } from 'app/modules/detectionModels/actions';

// Constants
import { initialState } from 'app/modules/detectionModels/constants';

const reducer: Reducer<ModelsState> = (
  state = initialState,
  action: ModelsActions,
) => {
  return produce(state, (draft: Draft<ModelsState>) => {
    switch (action.type) {
      case DetectionModelsActionTypes.RETRIEVE_DETECTION_MODELS_PERFORMANCE_SUCCESS:
        draft.detectionModelsPerformance = action.payload;
        return;
      case DetectionModelsActionTypes.SET_EDITING_SIMPLE_DETECTION_MODEL:
        draft.editingSimpleDetectionModel = action.payload;
        return;
      case DetectionModelsActionTypes.SET_EDITING_ADVANCED_DETECTION_MODEL:
        draft.editingAdvancedDetectionModel = action.payload;
        return;
      case DetectionModelsActionTypes.RETRIEVE_SCENARIO_CONFIGS_SUCCESS:
        draft.scenarioConfigs = action.payload;
        return;
      case DetectionModelsActionTypes.RESET_EDITING_DETECTION_MODELS:
        draft.editingSimpleDetectionModel = cloneDeep(
          initialState.editingSimpleDetectionModel,
        );
        draft.editingAdvancedDetectionModel = cloneDeep(
          initialState.editingAdvancedDetectionModel,
        );
        return;
      case DetectionModelsActionTypes.RESET_MODEL_VALIDATION:
        draft.detectionModelValidation = cloneDeep(
          initialState.detectionModelValidation,
        );
        draft.validationAlerts = cloneDeep(initialState.validationAlerts);
        return;
      case DetectionModelsActionTypes.RETRIEVE_DETECTION_MODEL_VALIDATION_SUCCESS:
        draft.detectionModelValidation = action.payload;
        return;
      case DetectionModelsActionTypes.RETRIEVE_DETECTION_MODEL_VALIDATION_ALERTS_SUCCESS:
        draft.validationAlerts = action.payload;
        return;
      case DetectionModelsActionTypes.RETRIEVE_DETECTION_MODEL_VALIDATION_METRICS_SUCCESS:
        draft.detectionModelValidationMetrics = action.payload;
        return;
      case DetectionModelsActionTypes.RETRIEVE_DETECTION_MODEL_TEMPLATES_SUCCESS:
        draft.detectionModelTemplates = action.payload;
        return;
    }
  });
};

export { reducer as detectionModelsReducer, initialState };
