import { useDispatch, useSelector } from 'react-redux';
import { useParams, useRouteMatch } from 'react-router';

import { ROUTES_MAP } from 'app/shared/utils/routes';
import {
  U21Chip,
  U21ChipProps,
  U21NoValue,
} from 'app/shared/u21-ui/components';

import { toggleSidebar } from 'app/modules/sidebar/slice';
import { SidebarComponentTypes } from 'app/modules/sidebar/models';
import {
  DataSettingFieldType,
  FormattedDataItem,
  OrgDataSettingsConfig,
} from 'app/modules/dataSettings/responses';
import { useEntityDetails } from 'app/modules/entitiesRefresh/queries/useEntityDetails';
import { useInstrumentDetails } from 'app/modules/instruments/queries/useInstrumentDetails';
import {
  selectHasReadEntitiesPermissions,
  selectHasReadInstrumentsPermission,
} from 'app/modules/session/selectors';

interface PerspectiveChipProps
  extends Omit<U21ChipProps, 'ref' | 'as' | 'children'> {
  formattedDataItem: FormattedDataItem;
  dataSetting?: OrgDataSettingsConfig;
}

export const PerspectiveChip = (props: PerspectiveChipProps) => {
  const dispatch = useDispatch();
  const { formattedDataItem, dataSetting, ...rest } = props;

  // Page location id
  const { id: pageId } = useParams<{ id?: string }>();

  // Entity page
  const isOnEntityPage = useRouteMatch(ROUTES_MAP.entitiesId.path);
  const { data: entityExternalId } = useEntityDetails(
    pageId || '',
    (data) => data.external_id,
    false,
  );

  // Instrument page
  const isOnInstrumentPage = useRouteMatch(ROUTES_MAP.instrumentsId.path);
  const { data: instrumentExternalId } = useInstrumentDetails(
    pageId || '',
    (data) => data.external_id,
    false,
  );

  // permissions
  const canReadEntityPermissions = useSelector(
    selectHasReadEntitiesPermissions,
  );
  const canReadInstrumentPermissions = useSelector(
    selectHasReadInstrumentsPermission,
  );

  if (!dataSetting || !formattedDataItem?.formatted_value) {
    return <U21NoValue />;
  }

  switch (dataSetting.data_type) {
    case DataSettingFieldType.ENTITY_REFERENCE:
      return (
        <U21Chip
          onClick={
            // can be an external entity, in which case no id
            formattedDataItem.formatted_value.unit21_id &&
            canReadEntityPermissions
              ? (e) => {
                  e.stopPropagation();
                  dispatch(
                    toggleSidebar({
                      type: SidebarComponentTypes.ENTITY,
                      data: {
                        id: String(formattedDataItem.formatted_value.unit21_id),
                      },
                    }),
                  );
                }
              : undefined
          }
          color={
            isOnEntityPage &&
            entityExternalId === formattedDataItem.formatted_value.external_id
              ? 'primary'
              : undefined
          }
          {...rest}
        >
          {(() => {
            if (
              !dataSetting.rendering_options?.reference_display_fields?.length
            ) {
              return formattedDataItem.formatted_value.name_readable;
            }
            const fields =
              dataSetting.rendering_options.reference_display_fields;
            return fields
              .map((field) => formattedDataItem.formatted_value[field])
              .join(' — ');
          })()}
        </U21Chip>
      );
    case DataSettingFieldType.INSTRUMENT_REFERENCE:
      return (
        <U21Chip
          onClick={
            canReadInstrumentPermissions
              ? (e) => {
                  e.stopPropagation();
                  dispatch(
                    toggleSidebar({
                      type: SidebarComponentTypes.INSTRUMENT,
                      data: {
                        id: String(formattedDataItem.formatted_value.unit21_id),
                      },
                    }),
                  );
                }
              : undefined
          }
          color={
            isOnInstrumentPage &&
            instrumentExternalId ===
              formattedDataItem.formatted_value.external_id
              ? 'primary'
              : undefined
          }
          {...rest}
        >
          {formattedDataItem.formatted_value.external_id}
        </U21Chip>
      );
    default:
      return null;
  }
};
