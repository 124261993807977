import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { AlertsActionTypes } from 'app/modules/alerts/models';
import { PaginationPayload } from 'app/shared/models';

// Actions
import {
  retrieveAlertInstruments as retrieveAlertInstrumentsAction,
  retrieveAlertInstrumentsSuccess,
} from 'app/modules/alerts/actions';

// API
import { retrieveAlertInstruments as retrieveAPI } from 'app/shared/api/alerts';

const rootAction = AlertsActionTypes.RETRIEVE_ALERT_INSTRUMENTS;
function* retrieveAlertInstruments({
  payload,
}: ReturnType<typeof retrieveAlertInstrumentsAction>) {
  const {
    offset,
    limit,
    sort_column: sortColumn = '',
    sort_direction: sortDirection = '',
  } = payload;
  const newPayload: PaginationPayload = {
    offset,
    limit,
    sort_column: sortColumn,
    sort_direction: sortDirection,
  };
  const config = {
    rootAction,
    request: call(retrieveAPI, payload.alertId, newPayload),
    success: function* onSuccess(result) {
      yield put(retrieveAlertInstrumentsSuccess(result));
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watchRetrieveAlertInstruments() {
  yield takeLatest(rootAction, retrieveAlertInstruments);
}
