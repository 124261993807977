// Types
import { CommentResponse } from 'app/modules/comments/types';

export enum CommentsActionTypes {
  RETRIEVE_COMMENTS = '@@comments/RETRIEVE_COMMENTS',
  RETRIEVE_COMMENTS_SUCCESS = '@@comments/RETRIEVE_COMMENTS_SUCCESS',

  RESET_COMMENTS = '@@comments/RESET_COMMENTS',
  SET_COMMENTS = '@@comments/SET_COMMENTS',

  ADD_COMMENT = '@@comments/ADD_COMMENT',
  ADD_COMMENT_SUCCESS = '@@comments/ADD_COMMENT_SUCCESS',
}

export type TimelineTimeFormat = 'From Now' | 'LLLL';

export interface CommentModel extends CommentResponse {}
export interface ChainalysisCommentModel extends CommentModel {
  raw_content: string;
}

export interface ImmerCommentsState {
  readonly comments: CommentModel[];
}
