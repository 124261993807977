// Thunk imports
import { u21CreateSlice } from 'app/shared/thunk/u21CreateSlice';
import { u21CreateAsyncThunk } from 'app/shared/thunk/u21CreateAsyncThunk';

import {
  getOrgSwitcherStatus,
  startOrgSwitcher as startApi,
  endOrgSwitcher as endApi,
} from 'app/modules/orgSwitcher/api';
import { OrgSwitcherState } from 'app/modules/orgSwitcher/models';
import { StartOrgSwitcherResponse } from 'app/modules/orgSwitcher/types/responses';
import { ROUTES_MAP } from 'app/shared/utils/routes';
import {
  getOrgSwitcherLogId,
  setOrgSwitcherAgentId,
  setOrgSwitcherLogId,
} from 'app/shared/utils/sessionStorage';
import { AuthClient } from 'app/modules/session/models';
import { startSession } from 'app/modules/session/actions';
import { sendErrorToast } from 'app/shared/toasts/actions';
import { History } from 'history';
import { queryClient } from 'app/cache/queryClient';

const initialState: Readonly<OrgSwitcherState> = {
  orgSwitcher: {
    is_default_login: true,
    is_enabled: false,
    orgs: [],
  },
  loadingOrgSwitcher: false,
  loadingStartOrgSwitcher: false,
};

const ORG_SWITCHER_NAME = 'ORG_SWITCHER_NAME';

export const retrieveOrgSwitcher = u21CreateAsyncThunk(
  `${ORG_SWITCHER_NAME}/RETRIEVE_ORG_SWITCHER`,
  getOrgSwitcherStatus,
);

export const startOrgSwitcher = u21CreateAsyncThunk<
  { agentId: number; auth: AuthClient; history: History },
  StartOrgSwitcherResponse | null
>(
  `${ORG_SWITCHER_NAME}/START_ORG_SWITCHER`,
  async ({ agentId, auth, history }, { dispatch }) => {
    try {
      const data = await startApi({ switched_to_agent_id: agentId });
      setOrgSwitcherAgentId(agentId);
      setOrgSwitcherLogId(data.org_switcher_log_id);

      queryClient.clear();
      await dispatch({ type: '@@rootReducer/RESET_APP' });
      await dispatch(startSession(auth));
      history.push(ROUTES_MAP.home.path);
      return data;
    } catch (err) {
      dispatch(
        sendErrorToast('Failed to switch orgs. Please try again later.'),
      );
    }
    return null;
  },
);

export const endOrgSwitcher = u21CreateAsyncThunk<{
  agentId: number;
  auth: AuthClient;
  history: History;
}>(
  `${ORG_SWITCHER_NAME}/END_ORG_SWITCHER`,
  async ({ agentId, auth, history }, { dispatch }) => {
    const logId = getOrgSwitcherLogId() || -1;
    try {
      await endApi({ org_switcher_log_id: logId });
    } catch {}

    setOrgSwitcherAgentId(-1);
    setOrgSwitcherLogId(-1);

    queryClient.clear();
    await dispatch({ type: '@@rootReducer/RESET_APP' });
    await dispatch(startSession(auth));
    history.push(ROUTES_MAP.agentsId.path.replace(':id', agentId.toString()));
  },
);

const orgSwitcherSlice = u21CreateSlice({
  name: ORG_SWITCHER_NAME,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addLoadingCase(
      retrieveOrgSwitcher,
      'loadingOrgSwitcher',
      (draft, action) => {
        draft.orgSwitcher = action.payload;
      },
    );
  },
});

export const ORG_SWITCHER_SLICE_NAME = orgSwitcherSlice.name;
export default orgSwitcherSlice.reducer;
