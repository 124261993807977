import { useMemo } from 'react';

// Components
import { IconTrash } from '@u21/tabler-icons';
import { U21Typography, U21Modal } from 'app/shared/u21-ui/components';
import { CustomChecklistDefinition } from 'app/shared/customChecklist/models';
import { getChecklistItemDefinition } from 'app/modules/investigationChecklist/helpers';

interface OwnProps {
  open: boolean;
  handleDeleteChecklist: (
    groupKey: string,
    checklistItem: CustomChecklistDefinition,
    index: number,
  ) => void;
  checklistItemIndex: number | undefined;
  checklistItem: CustomChecklistDefinition | undefined;
  handleClose: () => void;
  groupKey: string;
}
export const DeleteChecklistItemModal = ({
  open,
  handleDeleteChecklist,
  checklistItemIndex,
  checklistItem,
  handleClose,
  groupKey,
}: OwnProps) => {
  const getHelpText = useMemo(() => {
    if (checklistItem?.type) {
      const itemDefinition = getChecklistItemDefinition(checklistItem.type);

      return `Are you sure you want to delete this ${itemDefinition?.text}`;
    }
    return '';
  }, [checklistItem]);

  const handleDelete = () => {
    if (typeof checklistItemIndex === 'number' && checklistItem) {
      handleDeleteChecklist(groupKey, checklistItem, checklistItemIndex);
      handleClose();
    }
  };

  return (
    <U21Modal
      actionButtonProps={{
        children: 'Delete',
        color: 'error',
        startIcon: <IconTrash />,
      }}
      onAction={handleDelete}
      onClose={handleClose}
      open={open}
      title="Delete item"
    >
      <U21Typography>{getHelpText}</U21Typography>
    </U21Modal>
  );
};
