// Types
import {
  ShortEntityResponse,
  FullEntityResponse,
} from 'app/modules/entities/types';
import { ShortAlertResponse } from 'app/modules/alerts/types';

// Models
import { PaginationPayload } from 'app/shared/models';
import { AlertSummary } from 'app/modules/alerts/models';
import { Attachment } from 'app/modules/uploads/models';
import { TableConfig } from 'app/shared/pagination/models';

export enum EntitiesActionTypes {
  EDIT_ENTITY = '@@entities/EDIT_ENTITY',
  EDIT_ENTITY_SUCCESS = '@@entities/EDIT_ENTITY_SUCCESS',

  RETRIEVE_ENTITY = '@@entities/RETRIEVE_ENTITY',
  RETRIEVE_ENTITY_PREVIEW = '@@entities/RETRIEVE_ENTITY_PREVIEW',
  RETRIEVE_ENTITY_SUCCESS = '@@entities/RETRIEVE_ENTITY_SUCCESS',

  RETRIEVE_ENTITY_ALERTS = '@@entities/RETRIEVE_ENTITY_ALERTS',
  RETRIEVE_ENTITY_ALERTS_SUCCESS = '@@entities/RETRIEVE_ENTITY_ALERTS_SUCCESS',

  RETRIEVE_ENTITY_LINK_ANALYSIS = '@@entities/RETRIEVE_ENTITY_LINK_ANALYSIS',
  RETRIEVE_ENTITY_LINK_ANALYSIS_SUCCESS = '@@entities/RETRIEVE_ENTITY_LINK_ANALYSIS_SUCCESS',

  QUEUE_ENTITY_LINK_ANALYSIS = '@@entities/QUEUE_ENTITY_LINK_ANALYSIS',
  QUEUE_ENTITY_LINK_ANALYSIS_SUCCESS = '@@entities/QUEUE_ENTITY_LINK_ANALYSIS_SUCCESS',

  RESET_ENTITIES_LINK_ANALYSIS_METADATA = '@@entities/RESET_ENTITIES_LINK_ANALYSIS_METADATA',
  RETRIEVE_ENTITIES_LINK_ANALYSIS_METADATA = '@@entities/RETRIEVE_ENTITIES_LINK_ANALYSIS_METADATA',
  RETRIEVE_ENTITIES_LINK_ANALYSIS_METADATA_SUCCESS = '@@entities/RETRIEVE_ENTITIES_LINK_ANALYSIS_METADATA_SUCCESS',

  CLEAR_ALL_ENTITY_STATE = '@@entities/CLEAR_ALL_ENTITY_STATE',
}

export interface EditEntityPayload {
  id: number | string;
  tags?: (number | string)[];
  comments?: string;
}

export interface EntityAlertsPayload extends PaginationPayload {
  entityExternalId: string;
  excluded_alert_ids?: number[];
}

export interface EditEntityResult {
  id: number;
}

export interface RetrieveLinkAnalysisPayload extends PaginationPayload {
  entityId: number | string;
}

export type EntityType = 'USER' | 'BUSINESS';

export type EntitySummary = ShortEntityResponse;

export interface Geolocation {
  city?: string;
  country?: string;
  geolocation_string?: string;
  ip_address?: string;
  latitude?: number;
  longitude?: number;
  postal_code?: string;
  subdivision?: string;
}

export interface EntityDetails extends FullEntityResponse {
  paginated_alerts: {
    alerts: ShortAlertResponse[];
    count: number;
  };
}

export interface GlobalWhitelistPaginatedPayload extends PaginationPayload {
  entity_external_ids?: string[]; // Filter
}

export interface EntityAlerts {
  count: number;
  alerts: AlertSummary[];
}

export interface GlobalWhitelistEntitiesPayload {
  entity_external_ids: string[];
  duration?: number;
}

export interface EntityLinkAnalysisHitInfo extends EntityDetails {
  // + extra table columns
  link_analysis_hits: any; // TODO add type
  data?: any;
}

export interface EntityLinkAnalysisReport {
  status: 'COMPLETED' | 'PENDING' | 'ERROR' | 'STARTED';
  created_at: string;
  queries_done: number;
  total_queries: number;
  table?: {
    entities: EntityLinkAnalysisHitInfo[];
    columns: TableConfig[];
    count: number;
  };
}

export interface EntityLinkAnalysisMetadata {
  entity_id: number;
  link_analysis_id: number;
  pii_overlap: boolean;
}
export interface EntitiesLinkAnalysisMetadataResponse {
  metadata: EntityLinkAnalysisMetadata[];
}

export interface EntitiesState {
  details: EntityDetails;
  linkAnalysisReport?: EntityLinkAnalysisReport | null;
  attachments: Attachment[];
  entitiesLinkAnalysisMetadata: EntitiesLinkAnalysisMetadataResponse;
}
