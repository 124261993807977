import { LABEL_PREDEFINED_COLORS } from 'app/modules/entities/constants';
import { formatDisposition } from 'app/shared/utils/disposition';
import { U21DynamicColorChip } from 'app/shared/u21-ui/components/dashboard';
import { U21Chip } from 'app/shared/u21-ui/components';

interface Props {
  disposition: string;
}

export const DispositionChip = (props: Props) => {
  const { disposition } = props;
  const color = LABEL_PREDEFINED_COLORS[disposition];
  const displayValue = formatDisposition(disposition);
  if (color) {
    return (
      <U21DynamicColorChip color={color}>{displayValue}</U21DynamicColorChip>
    );
  }
  return <U21Chip>{displayValue}</U21Chip>;
};
