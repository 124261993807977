import { call, put, takeEvery } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { SearchActionTypes } from 'app/modules/search/models';

// Api
import { teamsSearch as teamsSearchApi } from 'app/shared/api/search';

// Actions
import {
  teamsSearch as teamsSearchAction,
  teamsSearchSuccess,
} from 'app/modules/search/actions';

const rootAction = SearchActionTypes.TEAMS_SEARCH;
export function* teamsSearch({
  payload,
}: ReturnType<typeof teamsSearchAction>) {
  const config = {
    rootAction,
    request: call(teamsSearchApi, payload),
    errorToastMessage: `There was an error searching teams. Please try again`,
    success: function* onSuccess(
      result: ReturnType<typeof teamsSearchSuccess>['payload'],
    ) {
      yield put(teamsSearchSuccess(result));
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watch() {
  yield takeEvery(rootAction, teamsSearch);
}
