import { forwardRef, SyntheticEvent, useState } from 'react';

import { U21Button } from 'app/shared/u21-ui/components/input/U21Button';
import {
  U21Typography,
  U21TypographyProps,
} from 'app/shared/u21-ui/components/display/typography/U21Typography';

export interface U21ShowMoreTextProps extends U21TypographyProps {
  getShowLessText?: (length: number) => string;
  getShowMoreText?: (length: number) => string;
  limit?: number;
  onLessClick?: (e: SyntheticEvent) => void;
  onMoreClick?: (e: SyntheticEvent) => void;
  value: string;
}

const defaultShowLessText = () => 'Show less';
const defaultShowMoreText = () => 'Show more';

export const U21ShowMoreText = forwardRef<HTMLDivElement, U21ShowMoreTextProps>(
  (props, ref) => {
    const {
      getShowLessText = defaultShowLessText,
      getShowMoreText = defaultShowMoreText,
      limit = 100,
      onLessClick,
      onMoreClick,
      value = '',
      ...rest
    } = props;
    const [show, setShow] = useState(false);

    const { length } = value;
    if (!value.length) {
      return null;
    }

    const onClick = show ? onLessClick : onMoreClick;

    return (
      <U21Typography ref={ref} {...rest}>
        {show ? value : value.slice(0, limit).trim()}
        {length > limit && !show ? '...   ' : '   '}
        {length > limit && (
          <U21Button
            color="primary"
            onClick={(e) => {
              onClick?.(e);
              setShow(!show);
            }}
            size="small"
            variant="text"
          >
            {show ? getShowLessText(length) : getShowMoreText(length)}
          </U21Button>
        )}
      </U21Typography>
    );
  },
);
