import produce, { Draft } from 'immer';

// Models
import {
  CommentsActionTypes,
  ImmerCommentsState,
} from 'app/modules/comments/models';
import { CasesActionTypes } from 'app/modules/casesOld/models';

// Actions
import { CommentsActions } from 'app/modules/comments/actions';
import CasesActions from 'app/modules/casesOld/actions';

// Utils
import deepFreeze from 'app/shared/utils/deepFreeze';

const initialState: Readonly<ImmerCommentsState> = {
  comments: [],
};
// to ensure initialState is readonly
deepFreeze(initialState);

const reducer = (
  state = initialState,
  action: CommentsActions | CasesActions,
) =>
  produce(state, (draft: Draft<ImmerCommentsState>) => {
    switch (action.type) {
      case CommentsActionTypes.RETRIEVE_COMMENTS_SUCCESS:
      case CommentsActionTypes.SET_COMMENTS:
        draft.comments = action.payload;
        return;

      case CasesActionTypes.RETRIEVE_CASE_SUCCESS:
        draft.comments = action.payload.comments;
        return;

      case CommentsActionTypes.RESET_COMMENTS:
        draft.comments = initialState.comments;
        return;
    }
  });

export { reducer as commentsReducer, initialState };
