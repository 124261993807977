import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { DetectionModelsActionTypes } from 'app/modules/detectionModels/models';

// Actions
import {
  retrieveDetectionModelValidation as retrieveDetectionModelValidationAction,
  retrieveDetectionModelValidationSuccess,
} from 'app/modules/detectionModels/actions';

// API
import { retrieveDetectionModelValidation as retrieveDetectionModelValidationAPI } from 'app/shared/api/detectionModels';

const rootAction =
  DetectionModelsActionTypes.RETRIEVE_DETECTION_MODEL_VALIDATION;
export function* retrieveDetectionModelValidation({
  payload,
}: ReturnType<typeof retrieveDetectionModelValidationAction>) {
  const config = {
    rootAction,
    errorToastMessage: 'Something went wrong, unable to retrieve validation.',
    request: call(retrieveDetectionModelValidationAPI, payload),
    success: function* onSuccess(result) {
      yield put(retrieveDetectionModelValidationSuccess(result));
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watch() {
  yield takeLatest(rootAction, retrieveDetectionModelValidation);
}
