import React, { useMemo, FC } from 'react';

// Models
import { PaginationPayload } from 'app/shared/pagination/models';

// Components
import { Container } from '@mui/material';
import {
  IconChevronLeft,
  IconChevronRight,
  IconRefresh,
} from '@u21/tabler-icons';
import { U21Button } from 'app/shared/u21-ui/components/input/U21Button';
import { U21Select } from 'app/shared/u21-ui/components/input/select/U21Select';
import { U21Spacer } from 'app/shared/u21-ui/components/layout/U21Spacer';
import { U21Typography } from 'app/shared/u21-ui/components/display/typography/U21Typography';

// Constants
import {
  DEFAULT_OFFSET,
  DEFAULT_MIN_PAGINATION_LIMIT,
} from 'app/shared/pagination/constants';

// Styles
import styled from 'styled-components';

export type PageLimitValue = 10 | 25 | 50 | 100;

// to indicate getting the count timed out on the BE
const EMPTY_COUNT = null;

interface PageSizeOption {
  value: PageLimitValue;
  text: string;
}

export const PAGE_SIZE_OPTIONS: PageSizeOption[] = [
  { value: 10, text: '10 Rows Per Page' },
  { value: 25, text: '25 Rows Per Page' },
  { value: 50, text: '50 Rows Per Page' },
  { value: 100, text: '100 Rows Per Page' },
];

interface U21PaginationTableFooterProps {
  limit: PageLimitValue;
  offset: number;
  onUpdate: (payload: Partial<PaginationPayload>) => void;
  // can be null if the query timeouts on the BE
  count: number | null;
  currentRows: number;
}

export const U21PaginationTableFooter: FC<U21PaginationTableFooterProps> = ({
  onUpdate,
  count = 0,
  limit,
  offset,
  currentRows,
}) => {
  const pages = count === EMPTY_COUNT ? offset : Math.ceil(count / limit);
  // if we have total pages, standard check that we at the end (totalPages = offset)
  // if we don't have totalPages (caused by no totalCount), check to see if the rows are less than the limit, indicating we are on the final page
  const pageIncreaseDisabled =
    (count !== EMPTY_COUNT && pages && pages === offset) ||
    (count !== EMPTY_COUNT && count < limit) ||
    (count === EMPTY_COUNT && currentRows < limit);
  const pagination = { limit, offset };
  const pageOptions = useMemo(
    () =>
      [...Array(pages).keys()].map((i) => ({
        text: i + 1,
        value: i + 1,
      })),
    [pages],
  );

  const refreshButton = (
    <U21Button
      startIcon={<IconRefresh size={18} />}
      variant="outlined"
      onClick={() => onUpdate(pagination)}
    >
      Refresh
    </U21Button>
  );

  const footerPaginationOptions = (
    <U21Spacer horizontal>
      <U21Spacer horizontal>
        <U21Typography>Page</U21Typography>
        <U21Button
          aria-label="previous page"
          icon={<IconChevronLeft size={18} />}
          onClick={() => onUpdate({ offset: offset - 1 })}
          disabled={offset < 2}
        />
        <StyledNoWrapSelect
          clearable={false}
          searchable={false}
          onChange={(newOffset: number) => onUpdate({ offset: newOffset })}
          options={pageOptions}
          value={offset}
        />
        {Boolean(count) && <StyledNoWrapText>{`of ${pages}`}</StyledNoWrapText>}
        {/* TODO: When we figure out how to actually count add this back
        <StyledNoWrapText>{`of ${count ? pages : 'Many'}`}</StyledNoWrapText> */}
        <U21Button
          aria-label="next page"
          icon={<IconChevronRight size={18} />}
          onClick={() => onUpdate({ offset: offset + 1 })}
          disabled={pageIncreaseDisabled}
        />
      </U21Spacer>
      <StyledSelect
        $width={190}
        clearable={false}
        searchable={false}
        onChange={(newLimit: number) => {
          onUpdate({ limit: newLimit, offset: DEFAULT_OFFSET });
        }}
        options={PAGE_SIZE_OPTIONS}
        value={limit}
      />
      {refreshButton}
    </U21Spacer>
  );

  return (
    <StyledContainer>
      {count === EMPTY_COUNT || count > DEFAULT_MIN_PAGINATION_LIMIT
        ? footerPaginationOptions
        : refreshButton}
    </StyledContainer>
  );
};

const StyledNoWrapText = styled(U21Typography)`
  white-space: nowrap;
`;

const StyledNoWrapSelect = styled(U21Select)`
  .MuiOutlinedInput-root {
    flex-wrap: nowrap;
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  max-width: 100%;
  margin-top: 10px;
`;

const StyledSelect = styled(U21Select)<{ $width: number }>`
  width: ${(props) => props.$width}px;
`;
