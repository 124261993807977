import { get, post, put, destroy } from 'app/shared/utils/fetchr';

// Utils
import routes, { pathJoin } from 'app/shared/utils/routes';

// Models
import {
  CreateWorkflowPayload,
  EditWorkflowPayload,
  GetWorkflowsPayload,
  WorkflowDetailResponse,
} from 'app/modules/workflows/models';

export const createWorkflow = (payload: CreateWorkflowPayload) => {
  const path = routes.patronus.workflows;
  return post(path, payload);
};

export const retrieveWorkflow = (id: string) => {
  return get<WorkflowDetailResponse>(
    pathJoin(routes.patronus.workflowsId.replace(':id', id)),
  );
};

export const retrieveWorkflows = (payload: GetWorkflowsPayload) =>
  post(routes.patronus.workflowsList, payload);

export const retrieveSubdispositions = () =>
  get(routes.patronus.workflowsSubdispositionsList);

export const editWorkflow = (payload: EditWorkflowPayload) => {
  const { id, ...reqBody } = payload;
  return put(pathJoin(routes.patronus.workflowsId.replace(':id', id)), reqBody);
};

export const deleteWorkflow = (id: string) => {
  return destroy(pathJoin(routes.patronus.workflowsId.replace(':id', id)));
};
