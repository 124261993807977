import { ColorSchema } from 'vendor/material-minimal/palette';

import {
  forwardRef,
  HTMLProps,
  MouseEventHandler,
  ReactElement,
  ReactNode,
  RefObject,
  useEffect,
} from 'react';

import { varMediumClick, varSmallClick } from 'vendor/material-minimal/animate';

import { AnalyticsEvents, trackEvent } from 'app/shared/u21-ui/analytics';
import { consoleWarn } from 'app/shared/utils/console';
import { getDOMProps } from 'app/shared/utils/react';
import styled, { css } from 'styled-components';
import { Link, LinkProps } from 'react-router-dom';

import { Button, ButtonProps, IconButton } from '@mui/material';
import { motion } from 'framer-motion';
import { U21Loading } from 'app/shared/u21-ui/components/display/U21Loading';
import {
  U21Tooltip,
  U21TooltipProps,
} from 'app/shared/u21-ui/components/display/U21Tooltip';

export type U21ButtonColor = 'inherit' | ColorSchema;
export type U21ButtonVariant = 'contained' | 'outlined' | 'ghost' | 'text';
export type U21ButtonSize = 'small' | 'medium' | 'large';

export interface U21ButtonProps
  extends Omit<HTMLProps<HTMLButtonElement>, 'size'> {
  children?: ReactNode;
  color?: U21ButtonColor;
  disabled?: boolean;
  endIcon?: ReactElement;
  href?: string;
  icon?: ReactElement;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  ref?: RefObject<HTMLButtonElement>;
  size?: U21ButtonSize;
  startIcon?: ReactElement;
  tooltip?: U21TooltipProps['tooltip'];
  tooltipProps?: Omit<U21TooltipProps, 'children' | 'tooltip'>;
  variant?: U21ButtonVariant;
  loading?: boolean;
  to?: LinkProps['to'];
  type?: 'button' | 'submit';
}

const U21_TO_MUI_VARIANT: Record<U21ButtonVariant, ButtonProps['variant']> = {
  contained: 'contained',
  outlined: 'outlined',
  ghost: 'text',
  text: 'text',
};

// @ts-ignore Link forwards ref properly but is not typed so
const MotionLink = motion.create(Link);

export const U21Button = forwardRef<HTMLButtonElement, U21ButtonProps>(
  (props, ref) => {
    const {
      children,
      color = 'inherit',
      disabled: disabledProp,
      endIcon,
      href,
      icon,
      loading,
      onClick,
      size = 'medium',
      startIcon,
      tooltip,
      to,
      tooltipProps,
      type = 'button',
      variant = 'outlined',
      ...rest
    } = props;
    const onU21ButtonClick = (event) => {
      onClick?.(event);
      trackEvent(AnalyticsEvents.U21BUTTON_ON_CLICK, props, {}, event);
    };

    const { 'aria-label': ariaLabel } = rest;
    const isIcon = Boolean(icon);
    useEffect(() => {
      if (isIcon && !ariaLabel) {
        consoleWarn('Button icon requires aria-label for accessibility.');
      }
    }, [isIcon, ariaLabel]);

    const disabled = disabledProp || loading;
    const isLink = Boolean(href || to) && !disabled;
    const buttonProps = { type };
    const LinkComponent = to ? MotionLink : motion.a;
    const button = isIcon ? (
      <StyledIconButton
        $tooltip={Boolean(tooltip)}
        color={color}
        disabled={disabled}
        onClick={onU21ButtonClick}
        ref={ref}
        size={size}
        {...(!disabled && {
          component: isLink ? LinkComponent : motion.button,
          whileTap: size === 'small' ? varSmallClick.tap : varMediumClick.tap,
          whileHover:
            size === 'small' ? varSmallClick.hover : varMediumClick.hover,
        })}
        {...(isLink
          ? {
              href,
              rel: href ? 'noreferrer' : undefined,
              target: href ? '_blank' : undefined,
              to,
            }
          : buttonProps)}
        {...getDOMProps(rest)}
      >
        {icon}
      </StyledIconButton>
    ) : (
      <StyledMuiButton
        $loading={loading}
        $tooltip={Boolean(tooltip)}
        $u21Variant={variant}
        color={disabled ? 'inherit' : color}
        disabled={disabled}
        disableRipple={variant === 'text'}
        endIcon={endIcon}
        onClick={onU21ButtonClick}
        ref={ref}
        size={size}
        startIcon={
          loading ? (
            <U21Loading delay={0} loading variant="spinner" />
          ) : (
            startIcon
          )
        }
        variant={U21_TO_MUI_VARIANT[variant]}
        {...(isLink
          ? {
              href,
              LinkComponent: to ? Link : undefined,
              rel: href ? 'noreferrer' : undefined,
              target: href ? '_blank' : undefined,
              to,
            }
          : buttonProps)}
        {...getDOMProps(rest)}
      >
        {children}
      </StyledMuiButton>
    );

    if (!tooltip) {
      return button;
    }

    return (
      <U21Tooltip tooltip={tooltip} {...tooltipProps}>
        {/* need to wrap button in a span for tooltip so it works on disabled buttons */}
        <Span>{button}</Span>
      </U21Tooltip>
    );
  },
);

interface IconButtonStyleProps {
  $tooltip: boolean;
  color: U21ButtonColor;
}

const StyledIconButton = styled(IconButton)<
  IconButtonStyleProps & Partial<LinkProps>
>`
  &.Mui-disabled {
    ${(props) =>
      !props.$tooltip &&
      css`
        pointer-events: auto;
      `}
    pointer-events: auto;
    cursor: not-allowed;
    :hover {
      background: transparent;
    }
  }

  ${(props) => {
    const { color, href, to, theme } = props;
    if (href || to) {
      return css`
        :hover {
          color: ${color === 'inherit' ? 'inherit' : theme.palette[color].main};
        }
      `;
    }
    return css``;
  }}
`;

interface ButtonStyleProps {
  $loading?: boolean;
  $tooltip: boolean;
  $u21Variant: U21ButtonVariant;
  color: U21ButtonColor;
}

const StyledMuiButton = styled(Button)<ButtonStyleProps & Partial<LinkProps>>`
  min-width: unset;
  font-weight: 600;
  white-space: nowrap;

  ${(props) => {
    const { $u21Variant, size } = props;
    if ($u21Variant !== 'text') {
      switch (size) {
        case 'small':
          return css`
            height: 30px;
            padding: 0 10px;
            font-size: 13px;
            line-height: 22px;
          `;
        case 'medium':
          return css`
            height: 40px;
            padding: 0 16px;
            font-size: 14px;
            line-height: 24px;
          `;
        case 'large':
          return css`
            height: 48px;
            padding: 0 22px;
            font-size: 15px;
            line-height: 26px;
          `;
        default:
          return css``;
      }
    }
    return css``;
  }}

  ${(props) => {
    const { $u21Variant, theme } = props;
    if ($u21Variant === 'contained') {
      return css`
        opacity: ${theme.palette.button.opacity};
      `;
    }
    return css``;
  }}

  ${(props) => {
    const { $u21Variant, color, theme } = props;
    if ($u21Variant === 'contained' && theme.palette.mode === 'light') {
      return css`
        box-shadow: ${theme.customShadows[color]};
      `;
    }
    return css``;
  }}

  ${(props) => {
    const { $u21Variant, color, theme } = props;
    if ($u21Variant === 'contained') {
      return css`
        border: 1px solid
          ${color === 'inherit'
            ? theme.palette.grey[300]
            : theme.palette[color].main};
        :hover {
          border: 1px solid
            ${color === 'inherit'
              ? theme.palette.grey[400]
              : theme.palette[color].dark};
        }
      `;
    }
    return css``;
  }}

  &.Mui-disabled {
    ${(props) =>
      !props.$tooltip &&
      css`
        pointer-events: auto;
      `}
    cursor: ${(props) => (props.$loading ? 'progress' : 'not-allowed')};

    :hover {
      background-color: ${(props) =>
        props.$u21Variant === 'contained'
          ? props.theme.palette.grey[500_24]
          : 'transparent'};
      border-color: ${(props) =>
        props.$u21Variant === 'contained'
          ? props.theme.palette.grey[300]
          : props.theme.palette.grey[500_24]};
    }
  }

  ${(props) => {
    const { $u21Variant, disabled } = props;
    if ($u21Variant === 'text') {
      return css`
        padding: 0;
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
        vertical-align: baseline;

        ${!disabled &&
        css`
          :hover {
            background-color: inherit;
            opacity: 0.8;
            text-decoration: underline;
          }

          :focus {
            text-decoration: underline;
          }
        `}
      `;
    }
    return css``;
  }}

  ${(props) => {
    const { $u21Variant, color, href, to, theme } = props;
    if (href || to) {
      let colorValue;
      if (color === 'inherit') {
        colorValue = 'inherit';
      } else if ($u21Variant === 'contained') {
        colorValue = theme.palette[color].contrastText;
      } else {
        colorValue = theme.palette[color].main;
      }
      return css`
        :hover {
          color: ${colorValue};
        }
      `;
    }
    return css``;
  }}

  ${(props) => {
    if (props.$u21Variant === 'text') {
      return css`
        text-transform: none;
      `;
    }
    return css``;
  }}

  ${(props) => {
    if (props.$u21Variant === 'contained' && props.color === 'inherit') {
      return css`
        color: ${props.theme.palette.text.primary};
      `;
    }
    return css``;
  }}
`;

const Span = styled.span`
  display: inline-block;
`;
