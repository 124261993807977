import { u21CreateAsyncThunk } from 'app/shared/thunk/u21CreateAsyncThunk';
import { u21CreateSlice } from 'app/shared/thunk/u21CreateSlice';

// Models
import {
  CreateDispositionPayload,
  UpdateDispositionPayload,
} from 'app/modules/dispositions/request';
import { PaginationPayload } from 'app/shared/pagination/models';
import {
  Disposition,
  PaginatedDispositionsResponse,
} from 'app/modules/dispositions/response';

// API
import {
  createDisposition,
  getDispositions,
  updateDisposition,
} from 'app/modules/dispositions/api';

import { sendErrorToast, sendSuccessToast } from 'app/shared/toasts/actions';

const DISPOSITIONS_NAME = 'dispositions';

interface DispositionState {
  loadingCreateDisposition: boolean;
  loadingRetrieveDispositions: boolean;
  loadingUpdateDisposition: boolean;
  dispositions: Disposition[];
  dispositionsCount: number;
}

const initialState: Readonly<DispositionState> = {
  loadingCreateDisposition: false,
  loadingRetrieveDispositions: false,
  loadingUpdateDisposition: false,
  dispositions: [],
  dispositionsCount: 0,
};

export const createDispositionThunk = u21CreateAsyncThunk<
  CreateDispositionPayload,
  Disposition
>(
  `${DISPOSITIONS_NAME}/CREATE_DISPOSITION`,
  async (payload: CreateDispositionPayload, { dispatch }) => {
    try {
      const response = await createDisposition(payload);
      dispatch(sendSuccessToast('Successfully created disposition.'));
      return response;
    } catch (e) {
      dispatch(sendErrorToast('Error creating disposition.'));
      throw e;
    }
  },
);

export const retrieveDispositionsThunk = u21CreateAsyncThunk<
  PaginationPayload,
  PaginatedDispositionsResponse
>(
  `${DISPOSITIONS_NAME}/RETRIEVE_DISPOSITIONS`,
  async (payload: PaginationPayload, { dispatch }) => {
    try {
      return await getDispositions(payload);
    } catch (e) {
      dispatch(
        sendErrorToast('Unable to retrieve dispositions. Please try again'),
      );
      throw e;
    }
  },
);

export const updateDispositionThunk = u21CreateAsyncThunk<
  UpdateDispositionPayload,
  Disposition
>(
  `${DISPOSITIONS_NAME}/UPDATE_DISPOSITION`,
  async (payload: UpdateDispositionPayload, { dispatch }) => {
    try {
      const response = await updateDisposition(payload);
      dispatch(sendSuccessToast('Successfully updated disposition.'));
      return response;
    } catch (e) {
      dispatch(sendErrorToast('Failed to update disposition.'));
      throw e;
    }
  },
);

const dispositionsSlice = u21CreateSlice({
  name: DISPOSITIONS_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addLoadingCase(
        createDispositionThunk,
        'loadingCreateDisposition',
        (draft, action) => {
          draft.dispositions = [...draft.dispositions, action.payload];
          draft.dispositionsCount = draft.dispositions.length;
        },
      )
      .addLoadingCase(
        retrieveDispositionsThunk,
        'loadingRetrieveDispositions',
        (draft, action) => {
          draft.dispositions = action.payload.dispositions;
          draft.dispositionsCount = action.payload.count;
        },
      )
      .addLoadingCase(
        updateDispositionThunk,
        'loadingUpdateDisposition',
        (draft, action) => {
          draft.dispositions = draft.dispositions.map((i) => {
            if (i.id === action.payload.id) {
              return action.payload;
            }
            return i;
          });
        },
      );
  },
});

export const DISPOSITIONS_SLICE_NAME = dispositionsSlice.name;
export default dispositionsSlice.reducer;
