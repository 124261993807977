import { PayloadAction } from '@reduxjs/toolkit';

import { u21CreateSlice } from 'app/shared/thunk/u21CreateSlice';
import { u21CreateAsyncThunk } from 'app/shared/thunk/u21CreateAsyncThunk';
import { sendErrorToast } from 'app/shared/toasts/actions';
import {
  DataSettingsState,
  OverlayState,
} from 'app/modules/dataSettings/types';
import {
  DataSettingsKeyType,
  OrgDataSettingsConfig,
  OrgDataSettingsConfigAddEditResponse,
  OrgDataSettingsConfigResponse,
} from 'app/modules/dataSettings/responses';
import {
  addOrgCustomDataSettingsConfig,
  deleteOrgCustomDataSettingsConfig,
  editDataSetting,
  getDataSettings,
} from 'app/modules/dataSettings/api';
import {
  AddOrgCustomDataSettingsRequestPayload,
  EditDataSettingsConfigRequest,
} from 'app/modules/dataSettings/requests';
import { ERROR_STATE, LOADING_STATE } from 'app/shared/types/utils/asyncStatus';
import { CLASSIFIER_TO_LABEL } from 'app/modules/tableSettings/constants';
import { cloneDeep } from 'lodash';
import { BASE_DATA_SETTINGS_BY_NATIVE_KEY } from 'app/modules/dataSettings/constants';

const DATA_SETTINGS_NAME = 'dataSettings';

const NO_OVERLAY_STATE = {
  overlay: 'NONE',
} as const;

const initialState: DataSettingsState = {
  dataSettings: LOADING_STATE,
  filters: [],
  overlay: NO_OVERLAY_STATE,
  dataSettingsById: {},
  dataSettingsByNativeKey: BASE_DATA_SETTINGS_BY_NATIVE_KEY,
};

export const getOrgCustomDataSettingsConfigsThunk = u21CreateAsyncThunk<
  void,
  OrgDataSettingsConfigResponse
>(
  `${DATA_SETTINGS_NAME}/GET_ORG_CUSTOM_DATA_SETTINGS_CONFIGS`,
  async (_, { dispatch }) => {
    try {
      return await getDataSettings();
    } catch (e) {
      dispatch(sendErrorToast('Something went wrong'));
      throw e;
    }
  },
);

export const deleteOrgCustomDataSettingsConfigThunk = u21CreateAsyncThunk<
  { id: number },
  Record<string, never>
>(
  `${DATA_SETTINGS_NAME}/DELETE_ORG_CUSTOM_DATA_SETTINGS_CONFIGS`,
  async ({ id }, { dispatch }) => {
    try {
      const retVal = await deleteOrgCustomDataSettingsConfig(id);
      dispatch(getOrgCustomDataSettingsConfigsThunk());
      return retVal;
    } catch (e) {
      dispatch(sendErrorToast('An error ocurred'));
      throw e;
    }
  },
);

export const addOrgCustomDataSettingsConfigThunk = u21CreateAsyncThunk<
  { payload: AddOrgCustomDataSettingsRequestPayload },
  OrgDataSettingsConfigAddEditResponse
>(
  `${DATA_SETTINGS_NAME}/ADD_ORG_CUSTOM_DATA_SETTING_CONFIG`,
  async ({ payload }, { dispatch }) => {
    try {
      const retVal = await addOrgCustomDataSettingsConfig(payload);
      dispatch(getOrgCustomDataSettingsConfigsThunk());
      return retVal;
    } catch (e) {
      let errorMessage = 'An error ocurred';
      if ('status' in e && e.status === 409) {
        errorMessage = `${payload.key_path} already exists as a setting for ${
          CLASSIFIER_TO_LABEL[payload.unit21_data_classifier]
        }`;
      }
      dispatch(sendErrorToast(errorMessage));
      throw e;
    }
  },
);

export const editOrgCustomDataSettingsConfigThunk = u21CreateAsyncThunk<
  { id: number; payload: EditDataSettingsConfigRequest },
  OrgDataSettingsConfigAddEditResponse
>(
  `${DATA_SETTINGS_NAME}/EDIT_ORG_CUSTOM_DATA_SETTING_CONFIG`,
  async ({ id, payload }, { dispatch }) => {
    try {
      const retVal = await editDataSetting(id, payload);
      dispatch(getOrgCustomDataSettingsConfigsThunk());
      return retVal;
    } catch (e) {
      dispatch(sendErrorToast('An error ocurred'));
      throw e;
    }
  },
);

const customDataSlice = u21CreateSlice({
  name: DATA_SETTINGS_NAME,
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getOrgCustomDataSettingsConfigsThunk.pending, (draft) => {
        draft.dataSettings = LOADING_STATE;
      })
      .addCase(getOrgCustomDataSettingsConfigsThunk.rejected, (draft) => {
        draft.dataSettings = ERROR_STATE;
      })
      .addCase(
        getOrgCustomDataSettingsConfigsThunk.fulfilled,
        (draft, { payload }) => {
          draft.dataSettings = {
            status: 'COMPLETE',
            ...payload,
          };

          // Normalize data settings
          const dataSettingsById: Record<string, OrgDataSettingsConfig> = {};
          const dataSettingsByNativeKey = cloneDeep(
            BASE_DATA_SETTINGS_BY_NATIVE_KEY,
          );
          payload.data.forEach((dataSetting) => {
            dataSettingsById[dataSetting.id] = dataSetting;
            if (dataSetting.key_type === DataSettingsKeyType.NATIVE) {
              dataSettingsByNativeKey[dataSetting.unit21_data_classifier][
                dataSetting.native_key
              ] = dataSetting;
            }
          });

          draft.dataSettingsById = dataSettingsById;
          draft.dataSettingsByNativeKey = dataSettingsByNativeKey;
        },
      );
  },
  reducers: {
    setOverlay: (draft, { payload }: PayloadAction<OverlayState>) => {
      draft.overlay = payload;
    },
    clearOverlay: (draft) => {
      draft.overlay = NO_OVERLAY_STATE;
    },
    setDataSettingsFilters: (
      draft,
      { payload }: PayloadAction<DataSettingsState['filters']>,
    ) => {
      draft.filters = payload;
    },
  },
});

export const { name, reducer, actions } = customDataSlice;
export const { setOverlay, clearOverlay, setDataSettingsFilters } = actions;
