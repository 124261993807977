import { PayloadAction } from '@reduxjs/toolkit';
import { SettingsImportExportState } from 'app/modules/settingsImportExport/responses';
import { u21CreateSlice } from 'app/shared/thunk/u21CreateSlice';
import { Stream } from 'app/modules/pullBasedDataFiles/responses';
import { OrgDataSettingsConfig } from 'app/modules/dataSettings/responses';

const IMPORT_EXPORT_NAME = 'settingsImportExport';

const initialState: SettingsImportExportState = {
  selectedDataSettings: [],
  selectedStreams: [],
};

const settingsImportExportSlice = u21CreateSlice({
  name: IMPORT_EXPORT_NAME,
  initialState,
  reducers: {
    setSelectedDataSettings: (
      draft,
      { payload }: PayloadAction<OrgDataSettingsConfig[]>,
    ) => {
      draft.selectedDataSettings = payload;
    },
    setSelectedStreams: (draft, { payload }: PayloadAction<Stream[]>) => {
      draft.selectedStreams = payload;
    },
  },
});

export const { name, reducer, actions } = settingsImportExportSlice;
export const { setSelectedDataSettings, setSelectedStreams } = actions;
