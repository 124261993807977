import { call, put, takeEvery } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { SearchActionTypes } from 'app/modules/search/models';

// Api
import { entitiesSearch as entitiesSearchApi } from 'app/shared/api/search';

// Actions
import {
  entitiesSearch as entitiesSearchAction,
  entitiesSearchSuccess,
} from 'app/modules/search/actions';

const rootAction = SearchActionTypes.ENTITIES_SEARCH;
function* entitiesSearch(
  payload: ReturnType<typeof entitiesSearchAction>['payload'],
) {
  const config = {
    rootAction,
    request: call(entitiesSearchApi, payload),
    errorToastMessage: `There was an error searching entities. Please try again`,
    success: function* onSuccess(
      result: ReturnType<typeof entitiesSearchSuccess>['payload'],
    ) {
      yield put(entitiesSearchSuccess(result));
    },
  };
  yield call(makeSagaRequest, config);
}

function* entitiesSearchFlow({
  payload,
}: ReturnType<typeof entitiesSearchAction>) {
  yield call(entitiesSearch, payload);
}

export default function* watchEntitiesSearch() {
  yield takeEvery(rootAction, entitiesSearchFlow);
}
