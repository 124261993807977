import { call, takeEvery, put } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { UploadsActionTypes } from 'app/modules/uploads/models';

// Actions
import {
  deleteDatafile as deleteDatafileAction,
  deleteDatafileSuccess,
} from 'app/modules/uploads/actions';

// Api
import { deleteDatafile as api } from 'app/shared/api/uploads';

const rootAction = UploadsActionTypes.DELETE_DATAFILE;
export function* deleteDatafile({
  payload,
}: ReturnType<typeof deleteDatafileAction>) {
  const config = {
    successToastMessage: `Successfully deleted datafile`,
    errorToastMessage: `There was a problem deleting a datafile. Please try again.`,
    rootAction,
    request: call(api, payload),
    success: function* onSuccess() {
      yield put(deleteDatafileSuccess(payload));
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watch() {
  yield takeEvery(rootAction, deleteDatafile);
}
