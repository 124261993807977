// Redux
import { Reducer } from 'redux';
import { produce, Draft } from 'immer';
import { orderBy } from 'lodash';

// Models
import {
  DeadlinesActionTypes,
  DeadlinesState,
} from 'app/modules/deadlines/models';

// Helpers
import { DeadlinesActions } from 'app/modules/deadlines/actions';

// Utils
import deepFreeze from 'app/shared/utils/deepFreeze';

// Constants
import { DEFAULT_DEADLINES_CONFIG } from 'app/modules/deadlines/constants';

const initialState: Readonly<DeadlinesState> = {
  deadlinesConfigs: {
    count: 0,
    configs: [],
  },
  deadlinesConfig: {
    ...DEFAULT_DEADLINES_CONFIG,
  },
};
// to ensure initialState is readonly
deepFreeze(initialState);

const reducer: Reducer<DeadlinesState> = (
  state = initialState,
  action: DeadlinesActions,
) => {
  return produce(state, (draft: Draft<DeadlinesState>) => {
    switch (action.type) {
      case DeadlinesActionTypes.RETRIEVE_DEADLINES_CONFIGS_SUCCESS:
        draft.deadlinesConfigs = action.payload;
        return;
      case DeadlinesActionTypes.RETRIEVE_DEADLINES_CONFIG_SUCCESS:
        draft.deadlinesConfig = action.payload;
        return;
      case DeadlinesActionTypes.CREATE_DEADLINES_CONFIG_SUCCESS:
        draft.deadlinesConfig = action.payload;
        draft.deadlinesConfigs.configs.push(action.payload);
        draft.deadlinesConfigs.count += 1;
        return;
      case DeadlinesActionTypes.SET_DEADLINES_CONFIG:
      case DeadlinesActionTypes.EDIT_DEADLINES_CONFIG_SUCCESS:
        draft.deadlinesConfig = action.payload;
        draft.deadlinesConfigs.configs = draft.deadlinesConfigs.configs.map(
          (config) => {
            if (config.id === action.payload.id) {
              return action.payload;
            }
            return config;
          },
        );
        draft.deadlinesConfigs.configs = orderBy(
          draft.deadlinesConfigs.configs,
          'priority',
          'desc',
        );
        return;
    }
  });
};

export { reducer as deadlinesReducer, initialState };
