import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { AlertsActionTypes } from 'app/modules/alerts/models';

// Actions
import { retrieveAllAlertQueuesSuccess } from 'app/modules/alerts/actions';

// API
import { retrieveAllAlertQueues as retrieveAllAlertQueuesApi } from 'app/shared/api/alerts';

const rootAction = AlertsActionTypes.RETRIEVE_ALL_ALERT_QUEUES;
export function* retrieveAllAlertQueues() {
  const config = {
    rootAction,
    request: call(retrieveAllAlertQueuesApi),
    success: function* onSuccess(result) {
      yield put(retrieveAllAlertQueuesSuccess(result));
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watchRetrieveAllAlertQueues() {
  yield takeLatest(rootAction, retrieveAllAlertQueues);
}
