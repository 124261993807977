import {
  cloneElement,
  HTMLProps,
  ReactElement,
  ReactNode,
  useRef,
} from 'react';
import { getDOMProps } from 'app/shared/utils/react';
import Popper, { PopperPlacementType } from '@mui/material/Popper';
import styled from 'styled-components';

export interface U21PopperProps extends HTMLProps<HTMLDivElement> {
  placement?: PopperPlacementType;
  children: ReactNode;
  open?: boolean;
  trigger: ReactElement;
}

export const U21Popper = (props: U21PopperProps) => {
  const {
    placement = 'right',
    children,
    open = false,
    trigger,
    ...rest
  } = props;
  const anchorEl = useRef<HTMLElement>(null);

  return (
    <>
      {cloneElement(trigger, { ref: anchorEl })}
      <StyledPopper
        anchorEl={anchorEl.current}
        placement={placement}
        open={open}
        {...getDOMProps(rest)}
      >
        {children}
      </StyledPopper>
    </>
  );
};

const StyledPopper = styled(Popper)`
  z-index: 3;
`;
