import { ReactNode } from 'react';
import {
  U21Button,
  U21ButtonProps,
} from 'app/shared/u21-ui/components/input/U21Button';
import { U21CopyButton } from 'app/shared/u21-ui/components/display/U21CopyButton';
import { U21Spacer } from 'app/shared/u21-ui/components/layout/U21Spacer';
import { U21Typography } from 'app/shared/u21-ui/components/display/typography/U21Typography';
import getFromProcessEnv from 'app/shared/utils/getFromProcessEnv';
import styled from 'styled-components';

export interface U21DocsLinkProps extends U21ButtonProps {
  docsLink: string;
}

export const U21DocsLink = ({ docsLink, ...rest }: U21DocsLinkProps) => {
  return (
    <U21Button
      color="info"
      variant="text"
      href={docsLink}
      tooltip={U21_DOCS_PASSWORD_TOOLTIP}
      {...rest}
    >
      <U21Typography color="info" variant="body2">
        Need help?
      </U21Typography>
    </U21Button>
  );
};

const StyledU21CopyButton = styled(U21CopyButton)`
  :hover {
    background-color: ${(props) => props.theme.palette.grey[500_24]};
  }
`;

export const U21_DOCS_PASSWORD_TOOLTIP: ReactNode = getFromProcessEnv(
  'DOCS_PASSWORD',
) ? (
  <U21Spacer horizontal useFlexGap>
    {`Password: ${getFromProcessEnv('DOCS_PASSWORD')}`}
    <StyledU21CopyButton
      text={getFromProcessEnv('DOCS_PASSWORD')}
      variant="ghost"
    />
  </U21Spacer>
) : null;
