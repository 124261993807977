// Saga
import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { InsightsActionTypes } from 'app/modules/insights/models';

// Api
import { duplicateDashboard as duplicateDashboardApi } from 'app/shared/api/insights';

// Actions
import {
  duplicateDashboard as duplicateDashboardAction,
  duplicateDashboardSuccess,
} from 'app/modules/insights/actions';
import { sendSuccessToast } from 'app/shared/toasts/actions';

// Utils
import routes from 'app/shared/utils/routes';
import { history } from 'app/store/browserHistory';

const rootAction = InsightsActionTypes.DUPLICATE_DASHBOARD;
function* duplicateDashboard({
  payload,
}: ReturnType<typeof duplicateDashboardAction>) {
  const config = {
    rootAction,
    request: call(duplicateDashboardApi, payload),
    errorToastMessage: `Something went wrong, unable to duplicate dashboard.`,
    success: function* onSuccess(
      result: ReturnType<typeof duplicateDashboardSuccess>['payload'],
    ) {
      yield put(
        sendSuccessToast(`Dashboard ${result.title} successfully duplicated`),
      );
      yield put(duplicateDashboardSuccess(result));

      history.push(routes.lumos.dashboardsId.replace(':id', String(result.id)));
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watchDuplicateDashboard() {
  yield takeLatest(rootAction, duplicateDashboard);
}
