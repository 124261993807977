import { SymbolTable } from 'app/modules/rulesAdvanced/lib/SymbolTable';

export const TXN_EVENT_COMMON_ADVANCED_SYMBOLS: SymbolTable = Object.freeze({
  external_fee: {
    type: 'FIELD',
    field: 'external_fee',
    label: 'external_fee',
    model: 'txn_event',
    datatype: 'number',
    cardinality: 'many',
  },
  'client_fingerprint.client_fingerprint': {
    type: 'FIELD',
    field: 'client_fingerprint.client_fingerprint',
    label: 'client_fingerprint',
    model: 'txn_event',
    datatype: 'string',
    cardinality: 'many',
  },
  'ip_address.ip_address': {
    type: 'FIELD',
    field: 'ip_address.ip_address',
    label: 'ip_address',
    model: 'txn_event',
    datatype: 'string',
    cardinality: 'many',
  },
  'geolocation.country': {
    type: 'FIELD',
    field: 'geolocation.country',
    label: 'geolocation.country',
    model: 'txn_event',
    datatype: 'string',
    cardinality: 'many',
  },
  'geolocation.city': {
    type: 'FIELD',
    field: 'geolocation.city',
    label: 'geolocation.city',
    model: 'txn_event',
    datatype: 'string',
    cardinality: 'many',
  },
  'geolocation.postal_code': {
    type: 'FIELD',
    field: 'geolocation.postal_code',
    label: 'geolocation.postal_code',
    model: 'txn_event',
    datatype: 'string',
    cardinality: 'many',
  },
  'geolocation.longitude': {
    type: 'FIELD',
    field: 'geolocation.longitude',
    label: 'geolocation.longitude',
    model: 'txn_event',
    datatype: 'string',
    cardinality: 'many',
  },
  'geolocation.latitude': {
    type: 'FIELD',
    field: 'geolocation.latitude',
    label: 'geolocation.latitude',
    model: 'txn_event',
    datatype: 'string',
    cardinality: 'many',
  },
});
