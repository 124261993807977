import getFromProcessEnv from 'app/shared/utils/getFromProcessEnv';
import { Filter } from 'app/modules/filters/models';
import { PaginatedAgentsFiltersPayload } from 'app/modules/agents/requests';
import { AGENT_FILTER_KEYS } from 'app/modules/agents/filters';
import { FILTER_OPERATOR } from 'app/modules/filters/constants';
import { AgentStatus } from 'app/modules/agents/models';

export const getAgentDisplayName = (agent: {
  full_name?: string;
  email: string;
}) => {
  const { email, full_name: fullName } = agent;
  return fullName || email;
};

const SLACK_OAUTH_URL = 'https://slack.com/oauth/authorize';
const SLACK_INDIVIDUAL_SCOPES = ['bot', 'im:write', 'chat:write:bot'].join(',');

export const getSlackRedirectURI = (): string =>
  `${window.location.origin}${window.location.pathname}`;

export const getSlackAuthURL = (): string => {
  const SLACK_CLIENT_ID = getFromProcessEnv('REACT_APP_SLACK_CLIENT_ID');
  return `${SLACK_OAUTH_URL}?client_id=${SLACK_CLIENT_ID}&scope=${SLACK_INDIVIDUAL_SCOPES}&redirect_uri=${getSlackRedirectURI()}`;
};

const filterNumbers = (array: (number | string)[]) => {
  return array.filter((i) => typeof i === 'number') as number[];
};

export const createAgentFilterPayload = (
  filters: Filter[],
): PaginatedAgentsFiltersPayload => {
  return filters.reduce<PaginatedAgentsFiltersPayload>((acc, i) => {
    const { key, operator, value } = i;
    switch (key) {
      case AGENT_FILTER_KEYS.NAME_EMAIL: {
        if (operator === FILTER_OPERATOR.CONTAINS_TEXT) {
          acc.query = value;
        }
        break;
      }
      case AGENT_FILTER_KEYS.STATUS: {
        if (operator === FILTER_OPERATOR.IS_ONE_OF) {
          acc.statuses = value as AgentStatus[];
        }
        break;
      }
      case AGENT_FILTER_KEYS.TEAM: {
        if (operator === FILTER_OPERATOR.IS_ONE_OF) {
          acc.teams = filterNumbers(value);
        }
        break;
      }
      default:
        break;
    }
    return acc;
  }, {});
};
