import { U21TableBodyCellProps } from 'app/shared/u21-ui/components/display/table/models';

import { useMemo } from 'react';
import styled from 'styled-components';

import { U21Checkbox } from 'app/shared/u21-ui/components/input/U21Checkbox';

export const U21TableBodyCheckbox = <T extends { id: number | string }, V, F>(
  props: U21TableBodyCellProps<T, V, F>,
) => {
  const {
    id,
    tableProps: { rowsById },
    userProps: {
      disabled: disabledProp,
      onRowSelect,
      selected = [],
      onSelectAllButtonPressed,
    },
  } = props;

  const checked = useMemo(() => selected.includes(id), [id, selected]);
  const disabled = useMemo(() => {
    if (Array.isArray(disabledProp)) {
      return disabledProp.includes(id);
    }
    return Boolean(disabledProp);
  }, [disabledProp, id]);

  return (
    <Container>
      <U21Checkbox
        checked={checked}
        disabled={disabled}
        onChange={(val, e) => {
          const ids = val
            ? [...selected, id]
            : selected.filter((i) => i !== id);
          const rows = ids.map((i) => rowsById[i]?.original);
          onRowSelect?.(ids, rows, e);
          if (checked) {
            onSelectAllButtonPressed?.(false);
          }
        }}
        onClick={(e) => e.stopPropagation()}
      />
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;
