import { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { listInvestigationChecklistThunk } from 'app/modules/investigationChecklist/sliceInvestigationChecklist';

// Selectors
import { selectInvestigationChecklists } from 'app/modules/investigationChecklist/selectors';

import { IconListCheck } from '@u21/tabler-icons';
import {
  U21Select,
  U21SelectOptionProps,
  U21SelectProps,
} from 'app/shared/u21-ui/components';
import {
  InvestigationChecklistStatus,
  InvestigationChecklistTypes,
} from 'app/modules/investigationChecklist/models';
import { QueueType } from 'app/modules/queues/models';

export interface U21ChecklistSelectProps<TClearable extends boolean>
  extends Omit<U21SelectProps<number, TClearable>, 'options'> {
  noRefresh?: false;
  queueType: QueueType;
}

export const U21ChecklistSelect = <TClearable extends boolean>(
  props: U21ChecklistSelectProps<TClearable>,
) => {
  const { noRefresh, queueType } = props;

  const dispatch = useDispatch();
  const checklists = useSelector(selectInvestigationChecklists);

  useEffect(() => {
    if (!noRefresh)
      dispatch(
        listInvestigationChecklistThunk({
          limit: 1000,
          offset: 1,
          type:
            queueType === QueueType.ALERT_QUEUE
              ? InvestigationChecklistTypes.ALERT_CHECKLIST
              : InvestigationChecklistTypes.CASE_CHECKLIST,
        }),
      );
  }, [dispatch, noRefresh, queueType]);

  const options: U21SelectOptionProps[] = useMemo(
    () =>
      checklists
        .filter(
          ({ alert_default_checklist: isDefault, status, archived }) =>
            !isDefault &&
            status !== InvestigationChecklistStatus.INACTIVE &&
            !archived,
        )
        .map((checklist) => ({
          text: checklist.name,
          value: checklist.id,
        })),
    [checklists],
  );

  const selectProps = {
    label: 'Checklist',
    options,
    startIcon: <IconListCheck />,
  };

  return <U21Select {...selectProps} {...props} />;
};
