// Actions
import {
  editEvent as editEventAction,
  retrieveActionEvents as retrieveActionEventsAction,
} from 'app/shared/events/actions';
import { retrieveActionEvent as retrieveActionEventAction } from 'app/modules/transactions/actions';

// Utils
import { get, post, put } from 'app/shared/utils/fetchr';
import routes from 'app/shared/utils/routes';

export const editEvent = (
  request: ReturnType<typeof editEventAction>['payload'],
) => {
  const { eventId, ...details } = request;
  const path = routes.patronus.eventsEventId.replace(
    ':eventId',
    String(eventId),
  );
  return put(path, details);
};

export const retrieveActionEvents = (
  payload: ReturnType<typeof retrieveActionEventsAction>['payload'],
) => {
  return post(routes.patronus.actionEventsList, payload);
};

export const retrieveActionEvent = (
  id: ReturnType<typeof retrieveActionEventAction>['payload'],
) => {
  const path = routes.patronus.actionEventsEventId.replace(
    ':eventId',
    String(id),
  );
  return get(path);
};
