// Types
import { ShortSARFilingResponse } from 'app/modules/fincenSar/types';

// Models
import { PaginationPayload } from 'app/shared/pagination/models';

// Calling SAR instead of filing. Assuming we will have just SAR s for a while, no need to add nested state until we need to (having a key of sar in this state)
export enum SarActionTypes {
  CREATE_SAR = '@@sars/CREATE_SAR',
  CREATE_SAR_SUCCESS = '@@sars/CREATE_SAR_SUCCESS',

  CREATE_NEW_SAR = '@@sars/CREATE_NEW_SAR',

  RETRIEVE_SAR_LIST = '@@sars/RETRIEVE_SAR_LIST',
  RETRIEVE_SAR_LIST_SUCCESS = '@@sars/RETRIEVE_SAR_LIST_SUCCESS',

  REASSIGN_SAR_SUCCESS = '@@sars/REASSIGN_SAR_SUCCESS',

  ARCHIVE_SAR = '@@sars/ARCHIVE_SAR',
  ARCHIVE_SAR_SUCCESS = '@@sars/ARCHIVE_SAR_SUCCESS',

  EDIT_SAR_REVIEWER_SUCCESS = '@@sars/EDIT_REVIEWER_SUCCESS',
}

export enum SarType {
  INITIAL_REPORT = 'initial_report',
  CORRECT_OR_AMEND_PRIOR_REPORT = 'correct_or_amend_prior_report',
  CONTINUING_ACTIVITY_REPORT = 'continuing_activity_report',
}

export enum SarStatus {
  ARCHIVED = 'ARCHIVED',
  PREFILING_VALIDATION_PENDING = 'PREFILING_VALIDATION_PENDING',
  PREFILING_VALIDATION_IN_PROCESS = 'PREFILING_VALIDATION_IN_PROCESS',
  PREFILING_VALIDATION_FAILED = 'PREFILING_VALIDATION_FAILED',
  PREFILING_VALIDATION_SUCCESS = 'PREFILING_VALIDATION_SUCCESS',
  QUEUED_FOR_FILING = 'QUEUED_FOR_FILING',
  READY_FOR_REVIEW = 'READY_FOR_REVIEW',
  SENT_TO_FINCEN = 'SENT_TO_FINCEN',
  SENDING_TO_FINCEN = 'SENDING_TO_FINCEN',
  FILING_FAILED = 'FILING_FAILED',
  FILING_MAX_RETRIES_EXCEEDED = 'FILING_MAX_RETRIES_EXCEEDED',
  FILING_SUCCESS = 'FILING_SUCCESS',
  FINCEN_VALIDATION_FAILED = 'FINCEN_VALIDATION_FAILED',
  FINCEN_REJECTED = 'FINCEN_REJECTED',
  FINCEN_STATUS_OTHER = 'FINCEN_STATUS_OTHER',
  FINCEN_ACCEPTED = 'FINCEN_ACCEPTED',
}

export interface SarFiltersRequest extends PaginationPayload {
  sar_ids?: number[];
  agent_ids?: number[];
  reviewer_ids?: number[];
  created_at_start?: string | null;
  created_at_end?: string | null;
  updated_at_start?: string | null;
  updated_at_end?: string | null;
  filed_at_start?: string | null;
  filed_at_end?: string | null;
  statuses?: string[];
  submitted?: boolean;
  search_id?: string;
  entity_external_ids?: string[];
  report_type?: string;
  queue_ids?: number[];
  my_queues_only?: boolean;
  country?: string;
  state?: string;
  city?: string;
}

export interface ValidationMessages {
  [key: string]: string;
}

export interface SarSummary extends ShortSARFilingResponse {}

export interface ArchiveSarPayload {
  sar_filing_id: number;
}

export interface SarState {
  num_filings: number;
  sars: SarSummary[];
  validationErrors: ValidationMessages;
}

export interface RetrieveSarListPayload {
  filings: SarSummary[];
  num_filings: number;
}

export enum ReportType {
  FINCEN_SAR = 'FINCEN_SAR',
  GOAML_STR = 'GOAML_STR',
  FINCEN_CTR = 'FINCEN_CTR',
  /**
   * @deprecated Kept for legacy filings
   */
  ESTONIAN_FIU = 'ESTONIAN_FIU',
  /**
   * @deprecated Kept for legacy filings
   */
  NCA_SAR = 'NCA_SAR',
}

export enum FincenSarTablePages {
  REPORT_FILING = 'REPORT_FILING',
  QUEUE_DETAILS = 'QUEUE_DETAILS',
  MY_FILINGS = 'MY_FILINGS',
  QUEUED_FILINGS = 'QUEUED_FILINGS',
  QUALITY_CYCLE_SARS = 'QUALITY_CYCLE_SARS',
}

export interface SarFilingTemplate<
  Content extends Record<string, any>,
  TType extends string,
> {
  id: number;
  org_id: number;
  template_type: TType;
  content: Content;
  created_at: string;
  updated_at?: string;
  agent_id?: number;
  tag_id?: number;
}
