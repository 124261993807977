import { call, put, takeEvery } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { SearchActionTypes } from 'app/modules/search/models';

// Api
import { alertsSearch as alertsSearchApi } from 'app/shared/api/search';

// Actions
import {
  alertsSearch as alertsSearchAction,
  alertsSearchSuccess,
} from 'app/modules/search/actions';

const rootAction = SearchActionTypes.ALERTS_SEARCH;
function* alertsSearch(
  payload: ReturnType<typeof alertsSearchAction>['payload'],
) {
  yield call(makeSagaRequest, {
    rootAction,
    request: call(alertsSearchApi, payload),
    errorToastMessage: `There was an error searching alerts. Please try again`,
    success: function* onSuccess(
      result: ReturnType<typeof alertsSearchSuccess>['payload'],
    ) {
      yield put(alertsSearchSuccess(result));
    },
  });
}

function* alertsSearchFlow({ payload }: ReturnType<typeof alertsSearchAction>) {
  yield call(alertsSearch, payload);
}

export default function* watchAlertsSearch() {
  yield takeEvery(rootAction, alertsSearchFlow);
}
