import { HTMLProps, ReactNode } from 'react';
import { U21HelpTooltipProps } from 'app/shared/u21-ui/components/display/U21HelpTooltip';

import { getDOMProps } from 'app/shared/utils/react';
import { isEqual } from 'lodash';
import styled from 'styled-components';

import { FieldArray, FieldArrayRenderProps } from 'react-final-form-arrays';
import { FieldSubscription } from 'final-form';
import { FormControl } from '@mui/material';
import { U21FormLabel } from 'app/shared/u21-ui/components/form/U21FormLabel';

export type U21FieldArrayRenderProps<FieldValue> = FieldArrayRenderProps<
  FieldValue,
  HTMLElement
>;
export interface U21FormFieldArrayProps<FieldValue>
  extends Omit<HTMLProps<HTMLDivElement>, 'children' | 'type'> {
  children:
    | ReactNode
    | ((props: U21FieldArrayRenderProps<FieldValue>) => ReactNode);
  help?: U21HelpTooltipProps['help'];
  hidden?: boolean;
  label?: string;
  name: string;
  required?: boolean;
  subscription?: FieldSubscription;
}

export const U21FormFieldArray = <FieldValue extends any = any>({
  children,
  help,
  hidden,
  label,
  name,
  required,
  subscription,
  ...rest
}: U21FormFieldArrayProps<FieldValue>) => {
  if (hidden) {
    return null;
  }

  return (
    <FieldArray<FieldValue>
      name={name}
      isEqual={isEqual}
      subscription={subscription}
    >
      {(fieldArrayProps: FieldArrayRenderProps<FieldValue, HTMLElement>) => {
        return (
          <Container {...getDOMProps(rest)}>
            <FormControl fullWidth>
              {Boolean(label) && (
                <U21FormLabel help={help} required={required}>
                  {label}
                </U21FormLabel>
              )}
              {typeof children === 'function'
                ? children(fieldArrayProps)
                : children}
            </FormControl>
          </Container>
        );
      }}
    </FieldArray>
  );
};

const Container = styled.div`
  min-width: 180px;
`;
