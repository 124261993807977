import {
  FC,
  HTMLProps,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from 'react';

import { consoleWarn } from 'app/shared/utils/console';
import { getA11yClickProps } from 'app/shared/utils/a11y';
import { getDOMProps } from 'app/shared/utils/react';
import styled, { css } from 'styled-components';

import { IconArrowLeft } from '@u21/tabler-icons';
import { Step, StepLabel, Stepper } from '@mui/material';
import { U21Modal } from 'app/shared/u21-ui/components/layout/modal/U21Modal';
import { U21Typography } from 'app/shared/u21-ui/components/display/typography/U21Typography';

export interface U21StepProps extends HTMLProps<HTMLDivElement> {
  icon?: ReactElement;
  label: string;
  component: ReactNode;
}

export interface U21StepperProps {
  onChangeStep?: (step: number) => void;
  step: number;
  steps: U21StepProps[];
}

export const U21Stepper: FC<U21StepperProps> = ({
  onChangeStep,
  step,
  steps,
  ...rest
}) => {
  // -1 indicates no step is selected and modal is closed
  const [targetStep, setTargetStep] = useState(-1);
  useEffect(() => {
    if (step < 0 || step >= steps.length) {
      consoleWarn(`Step must between 0 and ${steps.length - 1}.`);
    }
  }, [step, steps.length]);

  const resetTargetStep = () => setTargetStep(-1);

  const clickable = Boolean(onChangeStep);

  return (
    <>
      <StyledStepper activeStep={step} alternativeLabel {...getDOMProps(rest)}>
        {steps.map((i, idx) => {
          const { icon, label } = i;
          return (
            <StyledStep key={label}>
              <StyledStepLabel
                $clickable={clickable}
                $future={idx > step}
                $past={idx < step}
                {...getA11yClickProps(
                  () => setTargetStep(idx),
                  idx >= step || !clickable,
                )}
              >
                <StyledU21Typography icon={icon} variant="body2">
                  {label}
                </StyledU21Typography>
              </StyledStepLabel>
            </StyledStep>
          );
        })}
      </StyledStepper>
      {steps[step]?.component}
      {clickable && (
        <U21Modal
          actionButtonProps={{ children: 'Go back', color: 'warning' }}
          closeButtonProps={{
            children: 'Stay on this page',
          }}
          onAction={() => {
            onChangeStep!(targetStep);
            resetTargetStep();
          }}
          onClose={resetTargetStep}
          open={targetStep > -1}
          titleIcon={<IconArrowLeft />}
          title="Are you sure you want to go back?"
        >
          <U21Typography variant="body2">
            Going back might result in losing progress.
          </U21Typography>
        </U21Modal>
      )}
    </>
  );
};

const StyledStepper = styled(Stepper)`
  justify-content: center;
  margin-bottom: 20px;
`;

const StyledStep = styled(Step)`
  max-width: 200px;
`;

interface StyleProps {
  $clickable?: boolean;
  $future?: boolean;
  $past?: boolean;
}

const StyledStepLabel = styled(StepLabel)<StyleProps>`
  &&& {
    ${(props) =>
      props.$future &&
      props.$clickable &&
      css`
        cursor: not-allowed;
      `};

    ${(props) =>
      props.$past &&
      props.$clickable &&
      css`
        cursor: pointer;
      `};
  }
`;

const StyledU21Typography = styled(U21Typography)`
  justify-content: center;
`;
