import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { WorkflowActionTypes } from 'app/modules/workflows/models';

// Actions
import {
  editWorkflow as editWorkflowAction,
  editWorkflowSuccess,
} from 'app/modules/workflows/actions';

// API
import { editWorkflow as editWorkflowAPI } from 'app/shared/api/workflows';
import { retrieveActionTriggers } from 'app/modules/session/actions';

const rootAction = WorkflowActionTypes.EDIT_WORKFLOW;
export function* editWorkflow({
  payload,
}: ReturnType<typeof editWorkflowAction>) {
  const config = {
    errorToastMessage: 'Something went wrong, unable to edit this workflow.',
    successToastMessage: `Workflow updated successfully.`,
    rootAction,
    request: call(editWorkflowAPI, payload),
    success: function* onSuccess(
      result: ReturnType<typeof editWorkflowSuccess>['payload'],
    ) {
      yield put(editWorkflowSuccess(result));
      yield put(retrieveActionTriggers());
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watchEditWebhook() {
  yield takeLatest(rootAction, editWorkflow);
}
