import { PayloadAction } from '@reduxjs/toolkit';
import { U21Themes } from 'app/shared/u21-ui/components';

import { THEME_COLOR_MODE_KEY } from 'app/modules/theme/constants';

import { u21CreateSlice } from 'app/shared/thunk/u21CreateSlice';

interface ThemeState {
  mode: U21Themes;
}

const getInitialState = (): U21Themes => {
  // Using local storage is prone to error since someone
  // could change it to whatever they want. This should always
  // return 'light' unless local storage is 'dark'.
  if (localStorage.getItem(THEME_COLOR_MODE_KEY) === U21Themes.DARK) {
    return U21Themes.DARK;
  }
  return U21Themes.LIGHT;
};
export const initialState: Readonly<ThemeState> = {
  mode: getInitialState(),
};

export const themeSlice = u21CreateSlice({
  name: THEME_COLOR_MODE_KEY,
  initialState,
  reducers: {
    toggleTheme: (draft) => {
      draft.mode =
        draft.mode === U21Themes.DARK ? U21Themes.LIGHT : U21Themes.DARK;
      localStorage.setItem(THEME_COLOR_MODE_KEY, draft.mode);
    },
    updateTheme: (draft, action: PayloadAction<U21Themes>) => {
      draft.mode = action.payload;
      localStorage.setItem(THEME_COLOR_MODE_KEY, action.payload);
    },
  },
});

export const THEME_SLICE_NAME = themeSlice.name;
export const { toggleTheme, updateTheme } = themeSlice.actions;
export default themeSlice.reducer;
