import { call, takeLatest, put } from 'redux-saga/effects';
import { tokenPing as tokenPingApi } from 'app/shared/api/session';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';
import { SessionActionTypes } from 'app/modules/session/models';
import { tokenPingSuccess } from 'app/modules/session/actions';

const rootAction = SessionActionTypes.TOKEN_PING;
function* tokenPing() {
  const config = {
    rootAction,
    request: call(tokenPingApi),
    success: function* onSuccess() {
      yield put(tokenPingSuccess(false));
    },
    error: function* onError(result) {
      // Only set this as an invalid ping if the server actually tells us we
      // have a bad token. Otherwise mark it as ok.
      yield put(
        tokenPingSuccess(
          ['token_expired', 'token_invalid'].includes(result.code),
        ),
      );
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watchTokenPing() {
  yield takeLatest(SessionActionTypes.TOKEN_PING, tokenPing);
}
