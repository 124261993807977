import { CustomChecklistValidationFieldsTypes } from 'app/shared/customChecklist/models';
import {
  InvestigationChecklist,
  InvestigationChecklistStatus,
  InvestigationChecklistTypes,
} from 'app/modules/investigationChecklist/models';

export const INVALID_CHECKLIST_ID = -1;

export const EMPTY_INVESTIGATION_CHECKLIST: InvestigationChecklist = {
  id: INVALID_CHECKLIST_ID,
  archived: true,
  name: '',
  description: '',
  definition: [],
  status: InvestigationChecklistStatus.INACTIVE,
  modified_at: '',
  alert_queues: [],
  alert_default_checklist: false,
  type: InvestigationChecklistTypes.ALERT_CHECKLIST,
  ai_investigations_enabled: false,
};

export const VALIDATION_DESCRIPTION: Record<
  CustomChecklistValidationFieldsTypes,
  string
> = {
  [CustomChecklistValidationFieldsTypes.ALL]:
    'Message shown if there are any fields in this group are empty',
  [CustomChecklistValidationFieldsTypes.ANY]:
    'Message shown if no fields in this group are filled in',
  [CustomChecklistValidationFieldsTypes.REQUIRED]:
    'Message shown if any fields with a required star in this group are empty',
  [CustomChecklistValidationFieldsTypes.NONE]:
    'Message shown if any fields in this group are filled in',
};

export const STANDARD_VALIDATION_MESSAGES: Record<
  CustomChecklistValidationFieldsTypes,
  string
> = {
  [CustomChecklistValidationFieldsTypes.ALL]: 'All fields required',
  [CustomChecklistValidationFieldsTypes.ANY]: 'At least one field required',
  [CustomChecklistValidationFieldsTypes.REQUIRED]:
    'Fill out all required fields',
  [CustomChecklistValidationFieldsTypes.NONE]: 'All fields must be empty',
};
