// Saga
import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { InsightsActionTypes } from 'app/modules/insights/models';

// Api
import { retrieveDashboards as retrieveDashboardsApi } from 'app/shared/api/insights';

// Actions
import { retrieveDashboardsSuccess } from 'app/modules/insights/actions';

const rootAction = InsightsActionTypes.RETRIEVE_DASHBOARDS;
function* retrieveDashboards() {
  const config = {
    rootAction,
    request: call(retrieveDashboardsApi),
    errorToastMessage: `Something went wrong, unable to retrieve dashboards.`,
    success: function* onSuccess(
      result: ReturnType<typeof retrieveDashboardsSuccess>['payload'],
    ) {
      yield put(retrieveDashboardsSuccess(result));
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watchRetrieveDashboards() {
  yield takeLatest(rootAction, retrieveDashboards);
}
