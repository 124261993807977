import { HTMLProps, useMemo } from 'react';

import {
  EMPTY_COUNT,
  PAGE_SIZE_OPTIONS,
} from 'app/shared/u21-ui/components/display/table/constants';

import { getDOMProps } from 'app/shared/utils/react';
import styled from 'styled-components';

import {
  IconChevronLeft,
  IconChevronRight,
  IconRefresh,
} from '@u21/tabler-icons';
import { U21Button } from 'app/shared/u21-ui/components/input/U21Button';
import { U21Select } from 'app/shared/u21-ui/components/input/select/U21Select';
import { U21Spacer } from 'app/shared/u21-ui/components/layout/U21Spacer';
import { U21Typography } from 'app/shared/u21-ui/components/display/typography/U21Typography';
import { generatePageOptions } from 'app/shared/u21-ui/components/display/pagination/utils';
import { createU21FilterOptions } from 'app/shared/u21-ui/components/input/select/utils';

export interface U21PaginationProps extends HTMLProps<HTMLDivElement> {
  count: number | null;
  currentPageCount?: number;
  onPageChange: (page: number) => void;
  onPageSizeChange: (pageSize: number) => void;
  onRefresh?: () => void;
  refreshLoading?: boolean;
  page: number;
  pageSizeOptions?: { text: string; value: number }[];
  pageSize: number;
}

export const U21Pagination = (props: U21PaginationProps) => {
  const {
    count,
    onPageChange,
    onPageSizeChange,
    onRefresh,
    refreshLoading,
    page,
    pageSize,
    // used to determine whether on last page if count = null
    currentPageCount = pageSize,
    pageSizeOptions = PAGE_SIZE_OPTIONS,
    ...rest
  } = props;

  const pageCount =
    count === EMPTY_COUNT
      ? Number.MAX_SAFE_INTEGER
      : Math.ceil(count / pageSize);

  const pageOptions = useMemo(
    () => generatePageOptions(count, page, pageSize),
    [count, page, pageSize],
  );

  const minPageSize = useMemo(
    () => Math.min(...pageSizeOptions.map((i) => i.value)),
    [pageSizeOptions],
  );

  const showPaginationButtons = count === EMPTY_COUNT || count > minPageSize;
  const showRefreshButton = Boolean(onRefresh);

  if (!showPaginationButtons && !showRefreshButton) {
    return null;
  }

  return (
    <Container {...getDOMProps(rest)}>
      <U21Spacer horizontal>
        {showPaginationButtons && (
          <>
            <U21Typography>Page</U21Typography>
            <U21Button
              aria-label="previous page"
              disabled={page <= 1}
              icon={<IconChevronLeft />}
              onClick={() => onPageChange(page - 1)}
            />
            <PageSelect
              clearable={false}
              filterOptions={(selectOptions, state) => {
                const { inputValue } = state;
                const filteredOptions = createU21FilterOptions(
                  selectOptions,
                  state,
                );
                if (/^\d+$/.test(inputValue)) {
                  const inputNumber = Number(inputValue);
                  if (
                    inputNumber <= pageCount &&
                    !filteredOptions.find((i) => i.value === inputNumber)
                  ) {
                    return [
                      ...filteredOptions,
                      { text: inputValue, value: inputNumber },
                    ].sort((a, b) => (a.value > b.value ? 1 : -1));
                  }
                }
                return filteredOptions;
              }}
              onChange={onPageChange}
              responsiveLength
              options={pageOptions}
              value={page}
            />
            {Boolean(count) && (
              <StyledU21Typography>{`of ${pageCount}`}</StyledU21Typography>
            )}
            <U21Button
              aria-label="next page"
              // disabled if # current page items < page size or last page
              disabled={currentPageCount < pageSize || page >= pageCount}
              icon={<IconChevronRight />}
              onClick={() => onPageChange(page + 1)}
            />
            <PageOptionSelect
              clearable={false}
              onChange={onPageSizeChange}
              options={pageSizeOptions}
              searchable={false}
              value={pageSize}
            />
          </>
        )}
        {showRefreshButton && (
          <U21Button
            startIcon={<IconRefresh />}
            onClick={onRefresh}
            loading={refreshLoading}
          >
            Refresh
          </U21Button>
        )}
      </U21Spacer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 8px;
`;

const PageSelect = styled(U21Select)`
  width: 100px;
`;

const StyledU21Typography = styled(U21Typography)`
  white-space: nowrap;
`;

const PageOptionSelect = styled(U21Select)`
  min-width: 190px;
  width: 190px;
`;
