import produce, { Draft } from 'immer';
import { unionBy } from 'lodash';

// Models
import { SearchActionTypes, SearchState } from 'app/modules/search/models';
import { SearchActions } from 'app/modules/search/actions';

export const initialSearchState = {
  data: [],
  count: 0,
};

const initialState: SearchState = {
  entities: {
    ...initialSearchState,
  },
  rules: {
    ...initialSearchState,
  },
  alerts: {
    ...initialSearchState,
  },
  events: {
    ...initialSearchState,
  },
  teams: {
    ...initialSearchState,
  },
};

const reducer = (state = initialState, action: SearchActions) => {
  return produce(state, (draft: Draft<SearchState>) => {
    switch (action.type) {
      case SearchActionTypes.ENTITIES_SEARCH_SUCCESS:
        draft.entities = mergeSearchResults(draft.entities, action);
        return;

      case SearchActionTypes.RULES_SEARCH_SUCCESS:
        draft.rules = mergeSearchResults(draft.rules, action);
        return;

      case SearchActionTypes.ALERTS_SEARCH_SUCCESS:
        draft.alerts = mergeSearchResults(draft.alerts, action);
        return;

      case SearchActionTypes.EVENTS_SEARCH_SUCCESS:
        draft.events = mergeSearchResults(draft.events, action);
        return;

      case SearchActionTypes.TEAMS_SEARCH_SUCCESS:
        draft.teams = mergeSearchResults(draft.teams, action);
        return;

      default:
        return;
    }
  });
};

interface MergedSearchResults {
  data: any[];
  count: number;
}

const mergeSearchResults = (
  searchState,
  action: { payload: { data: any[]; count?: number } },
): MergedSearchResults => {
  const { data, count = 0 } = action.payload;
  return {
    data: unionBy(data, searchState.data, 'id'),
    count,
  };
};

export { reducer as searchReducer };
