export enum ToastActionTypes {
  SEND_TOAST = '@@Toast/SEND_TOAST',
  CLEAR_TOAST = '@@Toast/CLEAR_TOAST',
}

export type ToastType = 'Error' | 'Success' | 'Info';

export interface ToastStateObject {
  message: string;
  cta?: {
    to: string;
    text: string;
  };
  position?: 'Top' | 'Bottom';
  type?: ToastType;
}

export interface ToastState {
  messages: ToastStateObject[];
}
