import { retrieveComponentRules } from 'app/modules/rules/actions';
import { constructRulesSelectorStatuses } from 'app/modules/rules/helpers';
// Models
import {
  RetrieveRulesPayload,
  ScenarioConstants,
} from 'app/modules/rules/models';
import { WidgetProps } from 'app/modules/detectionModels/components/scenarioWidgets/models';

// Selectors
import {
  selectRulesDropdownOptions,
  selectRulesLoading,
} from 'app/modules/rules/selectors';
// Styles
import styles from 'app/modules/rules/styles/ComponentRulesSelector.module.scss';
import { cloneDeep } from 'lodash';
import React, { useEffect } from 'react';
// Redux
import { useDispatch, useSelector } from 'react-redux';
// Components
import { Dropdown, Icon, Segment } from 'semantic-ui-react';

export const ComponentRulesSelector = (props: WidgetProps) => {
  const { editingScenario, onChange, viewOnly } = props;
  const dispatch = useDispatch();
  const rulesLoading = useSelector(selectRulesLoading);
  const rulesOptions = useSelector(selectRulesDropdownOptions);

  useEffect(() => {
    const payload: RetrieveRulesPayload = {
      offset: 1,
      limit: 100,
      statuses: constructRulesSelectorStatuses(editingScenario.name),
    };
    dispatch(retrieveComponentRules(payload));
  }, [dispatch, editingScenario.name]);

  const handleRulesDropdownChange = (e, data) => {
    const newEditingScenario = cloneDeep(editingScenario);
    onChange({
      ...newEditingScenario,
      parameters: {
        ...newEditingScenario.parameters,
        [ScenarioConstants.COMPONENT_RULE_IDS]: data.value,
      },
    });
  };

  const selectedComponentRules =
    editingScenario.parameters[ScenarioConstants.COMPONENT_RULE_IDS];
  const canUseMultipleRules = editingScenario.name !== 'multiple_occurrences';
  return (
    <Segment>
      <span className={styles.dropdownLabel}>Select rule:</span>
      <Icon name="gavel" />
      <Dropdown
        placeholder="No rules currently selected"
        className={styles.rulesDropdown}
        disabled={viewOnly}
        fluid
        closeOnChange
        scrolling
        multiple={canUseMultipleRules}
        loading={rulesLoading}
        value={selectedComponentRules}
        options={rulesOptions}
        onChange={handleRulesDropdownChange}
      />
    </Segment>
  );
};
