import { Theme } from '@mui/material/styles';
import { alpha } from '@mui/system/colorManipulator';

// ----------------------------------------------------------------------
// CUSTOM CHANGES
// - changed background color
// - added borderRadius and transform styling
// - changed animation background color

export default function Skeleton(theme: Theme) {
  return {
    MuiSkeleton: {
      defaultProps: {
        animation: 'wave',
      },

      styleOverrides: {
        root: {
          backgroundColor: alpha(theme.palette.text.primary, 0.1),
          borderRadius: 8,
          transform: 'none',
          '&::after': {
            background: `linear-gradient(
              90deg,
              transparent,
              ${alpha(theme.palette.text.primary, 0.15)},
              transparent
            )`,
          },
        },
      },
    },
  };
}
