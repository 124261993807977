import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { SessionActionTypes } from 'app/modules/session/models';

// API
import { retrieveActionTriggers } from 'app/shared/api/session';

// Action
import { retrieveActionTriggersSuccess } from 'app/modules/session/actions';

// Utils
import routes from 'app/shared/utils/routes';
import { history } from 'app/store/browserHistory';

const rootAction = SessionActionTypes.RETRIEVE_ACTION_TRIGGERS;
function* retrieveActionTriggersFlow() {
  const config = {
    rootAction,
    request: call(retrieveActionTriggers),
    success: function* onSuccess(result) {
      yield put(retrieveActionTriggersSuccess(result));
    },
    // eslint-disable-next-line require-yield
    error: function* onError() {
      history.push(routes.lumos.error);
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watchRetrieveActionTriggers() {
  yield takeLatest(rootAction, retrieveActionTriggersFlow);
}
