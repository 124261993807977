import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { RulesActionTypes } from 'app/modules/rules/models';

// Actions
import {
  retrievePaginatedAlerts as retrievePaginatedAlertsAction,
  retrievePaginatedAlertsSuccess,
} from 'app/modules/rules/actions';

// API
import { retrievePaginatedAlerts as retrievePaginatedAlertsApi } from 'app/shared/api/rules';

const rootAction = RulesActionTypes.RETRIEVE_PAGINATED_ALERTS;
function* retrievePaginatedAlerts({
  payload,
}: ReturnType<typeof retrievePaginatedAlertsAction>) {
  const config = {
    rootAction,
    request: call(retrievePaginatedAlertsApi, payload),
    success: function* onSuccess(
      result: ReturnType<typeof retrievePaginatedAlertsSuccess>['payload'],
    ) {
      yield put(retrievePaginatedAlertsSuccess(result));
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watchRetrieveValidationRuleAlerts() {
  yield takeLatest(rootAction, retrievePaginatedAlerts);
}
