// Redux
import { Reducer } from 'redux';
import { produce, Draft } from 'immer';

// Models
import {
  UploadState,
  UploadsActionTypes,
  UploadStatus,
} from 'app/modules/uploads/models';

// Actions
import UploadsActions from 'app/modules/uploads/actions';

// Utils
import deepFreeze from 'app/shared/utils/deepFreeze';
import uploadStatus, {
  uploadStatusLabel,
} from 'app/modules/uploads/enums/uploadStatus';

const initialState: Readonly<UploadState> = {
  count: 0,
  unprocessedCount: 0,
  filesToUpload: [],
  filesToUploadAsync: [],
  uploads: [],
};
// to ensure initialState is readonly
deepFreeze(initialState);

const reducer: Reducer<UploadState> = (
  state = initialState,
  action: UploadsActions,
) => {
  return produce(state, (draft: Draft<UploadState>) => {
    switch (action.type) {
      case UploadsActionTypes.RETRIEVE_DATAFILES_SUCCESS:
        draft.uploads = action.payload.files;
        draft.count = action.payload.count;
        draft.unprocessedCount = action.payload.unprocessed_count;
        return;

      case UploadsActionTypes.DELETE_DATAFILE_SUCCESS:
        draft.uploads = draft.uploads.filter(
          (upload) => upload.id !== action.payload,
        );
        draft.count -= 1;
        return;

      case UploadsActionTypes.SET_FILES_TO_UPLOAD:
        draft.filesToUpload = action.payload;
        return;

      case UploadsActionTypes.SET_FILE_UPLOAD_PROGRESS:
        draft.filesToUploadAsync = draft.filesToUploadAsync.map((upload) => {
          if (upload.original_file_name === action.payload.file.name) {
            return {
              ...upload,
              percent: action.payload.percent,
              file_size: action.payload.total,
              xhr: action.payload.xhr,
            };
          }
          return upload;
        });
        return;

      case UploadsActionTypes.SET_FILES_TO_UPLOAD_ASYNC:
        draft.filesToUploadAsync = action.payload.map((file) => ({
          ...file,
          id: 0,
          created_at: new Date().toUTCString(),
          url: '',
          status: UploadStatus.UPLOADING,
          original_file_name: file.name,
        }));
        return;

      case UploadsActionTypes.UPLOAD_DATAFILE_ASYNC_SUCCESS:
        draft.filesToUploadAsync = draft.filesToUploadAsync.filter(
          (upload) =>
            upload.original_file_name !== action.payload.original_file_name &&
            upload.original_file_name !== action.payload.custom_data?.name,
        );

        draft.uploads = [action.payload, ...draft.uploads];
        return;

      case UploadsActionTypes.UPLOAD_DATAFILE_ASYNC_ERROR:
        draft.filesToUploadAsync = draft.filesToUploadAsync.map((upload) => {
          if (upload.original_file_name === action.payload.name) {
            return {
              ...upload,
              status:
                action.payload.errorMessage ===
                uploadStatusLabel[uploadStatus.processAborted]
                  ? UploadStatus.PROCESS_ABORTED
                  : UploadStatus.PROCESS_FAILED,
              status_message: action.payload.errorMessage,
            };
          }
          return upload;
        });
        return;

      case UploadsActionTypes.PROCESS_DATAFILE_SUCCESS:
        draft.uploads = draft.uploads.map((upload) => {
          if (upload.id === action.payload) {
            return {
              ...upload,
              status: UploadStatus.VALIDATION_PENDING,
            };
          }
          return upload;
        });
        return;

      default:
        return;
    }
  });
};

export { reducer as uploadsReducer, initialState };
