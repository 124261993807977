import {
  ForwardedRef,
  forwardRef,
  HTMLProps,
  ReactElement,
  SyntheticEvent,
  useState,
} from 'react';

import { getDOMProps } from 'app/shared/utils/react';
import styled, { css } from 'styled-components';

import { U21Button } from 'app/shared/u21-ui/components/input/U21Button';
import { U21Chip } from 'app/shared/u21-ui/components/display/U21Chip';
import { U21Spacer } from 'app/shared/u21-ui/components/layout/U21Spacer';
import { U21Typography } from 'app/shared/u21-ui/components/display/typography/U21Typography';

export interface U21ShowMoreListProps<T>
  extends Omit<HTMLProps<HTMLDivElement>, 'value'> {
  displayFunc?: (value: T, index: number) => ReactElement | null;
  getShowLessText?: (length: number) => string;
  getShowMoreText?: (length: number) => string;
  limit?: number;
  onLessClick?: (e: SyntheticEvent) => void;
  onMoreClick?: (e: SyntheticEvent) => void;
  value?: T[];
  horizontal?: boolean;
}

const defaultDisplayFunc = (value) => <U21Chip key={value}>{value}</U21Chip>;

const defaultShowLessText = () => 'Show fewer';
const defaultShowMoreText = (length) => `Show all ${length}`;

export const U21ShowMoreListInner = <T extends any>(
  props: U21ShowMoreListProps<T>,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const {
    displayFunc = defaultDisplayFunc,
    getShowLessText = defaultShowLessText,
    getShowMoreText = defaultShowMoreText,
    limit = 3,
    onLessClick,
    onMoreClick,
    value = [],
    horizontal = false,
    ...rest
  } = props;
  const [show, setShow] = useState(false);

  const { length } = value;
  if (!value.length) {
    return null;
  }

  const onClick = show ? onLessClick : onMoreClick;

  return (
    <StyledU21Spacer ref={ref} wrap={horizontal} {...getDOMProps(rest)}>
      {show ? value.map(displayFunc) : value.slice(0, limit).map(displayFunc)}
      {length > limit && (
        <U21Typography variant="button">
          <U21Button
            color="primary"
            onClick={(e) => {
              onClick?.(e);
              setShow((prevShow) => !prevShow);
            }}
            size="small"
            variant="text"
          >
            {show ? getShowLessText(length) : getShowMoreText(length)}
          </U21Button>
        </U21Typography>
      )}
    </StyledU21Spacer>
  );
};

export const U21ShowMoreList = forwardRef(U21ShowMoreListInner);

const StyledU21Spacer = styled(U21Spacer)`
  ${(props) =>
    !props.wrap &&
    css`
      align-items: flex-start;
    `}
`;
