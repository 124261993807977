import { FilterOption } from 'app/modules/filters/models';

import {
  DATA_LABEL_FILTER_OPTION,
  getEntityFilterOption,
} from 'app/modules/filters/filters';
import { FILTER_TYPE } from 'app/modules/filters/constants';
import { Unit21DataClassifier } from 'app/modules/dataSettings/responses';

export const ENTITY_FILTER_KEYS = {
  REGISTERED_AT: 'registered_at',
  ENTITY: 'entity',
  STATUS: 'status',
  SUBTYPE: 'entity_subtype',
  TAG: 'tag',
  TYPE: 'entity_type',
} as const;

export const ALL_ENTITY_FILTER_OPTIONS: FilterOption[] = [
  {
    key: ENTITY_FILTER_KEYS.ENTITY,
    ...getEntityFilterOption({ useExternalID: true }),
  },
  {
    key: ENTITY_FILTER_KEYS.REGISTERED_AT,
    label: 'Registered at',
    type: FILTER_TYPE.DATE_RANGE,
    unit21DataClassifier: Unit21DataClassifier.ENTITY,
  },
  {
    key: ENTITY_FILTER_KEYS.STATUS,
    label: 'Status',
    type: FILTER_TYPE.EXACT_TEXT,
    unit21DataClassifier: Unit21DataClassifier.ENTITY,
  },
  {
    key: ENTITY_FILTER_KEYS.SUBTYPE,
    label: 'Subtype',
    type: FILTER_TYPE.EXACT_TEXT,
    unit21DataClassifier: Unit21DataClassifier.ENTITY,
  },
  {
    key: ENTITY_FILTER_KEYS.TAG,
    ...DATA_LABEL_FILTER_OPTION,
  },
  {
    key: ENTITY_FILTER_KEYS.TYPE,
    label: 'Type',
    type: FILTER_TYPE.EXACT_TEXT,
    unit21DataClassifier: Unit21DataClassifier.ENTITY,
  },
];
