import { Reducer } from 'redux';
import { produce, Draft } from 'immer';

// Models
import {
  PermissionsState,
  PermissionsActionTypes,
} from 'app/modules/permissions/models';

// Actions
import { PermissionsActions } from 'app/modules/permissions/actions';

// Utils
import deepFreeze from 'app/shared/utils/deepFreeze';

const initialState: Readonly<PermissionsState> = {
  // permissions for the current agent or team page, i.e. agent/id, team/id
  permissions: [],
  // unassigned permissions for the current agent or team page, i.e /agent/id, team/id
  unassignedPermissions: [],
};

// to ensure initialState is readonly
deepFreeze(initialState);

const reducer: Reducer<PermissionsState> = (
  state = initialState,
  action: PermissionsActions,
) => {
  return produce(state, (draft: Draft<PermissionsState>) => {
    switch (action.type) {
      case PermissionsActionTypes.RETRIEVE_PERMISSIONS_SUCCESS:
        draft.permissions = action.payload;
        return;

      case PermissionsActionTypes.EDIT_PERMISSIONS_SUCCESS:
        draft.permissions = action.payload.permissions;
        draft.unassignedPermissions = action.payload.unassigned_permissions;
        return;

      case PermissionsActionTypes.RETRIEVE_UNASSIGNED_PERMISSIONS_SUCCESS:
        draft.unassignedPermissions = action.payload;
        return;
    }
  });
};

export { reducer as permissionsReducer, initialState };
