import { forwardRef, HTMLProps } from 'react';
import styled from 'styled-components';

import { getDOMProps } from 'app/shared/utils/react';

export interface U21IFrameProps extends HTMLProps<HTMLIFrameElement> {
  src: string;
  title: string;
}

export const U21IFrame = forwardRef<HTMLIFrameElement, U21IFrameProps>(
  ({ src, title, ...rest }, ref) => {
    return (
      <Frame
        src={src}
        frameBorder="0"
        allowFullScreen
        title={title}
        ref={ref}
        {...getDOMProps(rest)}
      />
    );
  },
);

const Frame = styled.iframe`
  width: 680px;
  height: 385px;
  border-radius: 8px;
`;
