import { FilterOperator, FilterType } from 'app/modules/filters/models';

export const FILTER_TYPE = {
  BOOLEAN: 'boolean',
  DATE_RANGE: 'date_range',
  MULTI_SELECT: 'multi_select',
  NUMBER_RANGE: 'number_range',
  SELECT: 'select',
  SLIDER_RANGE: 'slider_range',
  TEXT: 'text',

  // added for legacy filters, should not be used going forward
  EXACT_TEXT: 'exact_text',

  // temporary due to existing differences between native + custom data
  // should be merged in the future
  CUSTOM_DATA_TEXT: 'custom_data_text',
  CUSTOM_DATA_NUMBER: 'custom_data_number',
} as const;

export const FILTER_OPERATOR = {
  CONTAINS_TEXT: 'contains_text',
  IS_AFTER_DATE: 'is_after_date',
  IS_BEFORE_DATE: 'is_before_date',
  IS_BETWEEN_DATE: 'is_between_date',
  IS_BETWEEN_NUMBER: 'is_between_number',
  IS_BETWEEN_SLIDER: 'is_between_slider',
  IS_GREATER_THAN_NUMBER: 'is_greater_than_number',
  IS_LESS_THAN_NUMBER: 'is_less_than_number',
  IS_ONE_OF: 'is_one_of',
  IS_NOT_ONE_OF: 'is_not_one_of',
  IS: 'is', // for select dropdowns
  IS_EXACT_TEXT: 'is_exact_text', // UX-wise this should not exist
  IS_NOT_EXACT_TEXT: 'is_not_exact_text',
  IS_EMPTY: 'is_empty',
  IS_NOT_EMPTY: 'is_not_empty',
  IS_TRUE: 'is_true',
  IS_FALSE: 'is_false',
} as const;

export const FILTER_TYPE_OPERATOR_LABELS: Record<FilterOperator, string> = {
  [FILTER_OPERATOR.CONTAINS_TEXT]: 'contains',
  [FILTER_OPERATOR.IS_AFTER_DATE]: 'is after',
  [FILTER_OPERATOR.IS_BEFORE_DATE]: 'is before',
  [FILTER_OPERATOR.IS_BETWEEN_DATE]: 'is between',
  [FILTER_OPERATOR.IS_BETWEEN_NUMBER]: 'is between',
  [FILTER_OPERATOR.IS_BETWEEN_SLIDER]: 'is between',
  [FILTER_OPERATOR.IS_GREATER_THAN_NUMBER]: 'is greater than',
  [FILTER_OPERATOR.IS_LESS_THAN_NUMBER]: 'is less than',
  [FILTER_OPERATOR.IS_ONE_OF]: 'is one of',
  [FILTER_OPERATOR.IS_NOT_ONE_OF]: 'is not one of',
  [FILTER_OPERATOR.IS]: 'is',
  [FILTER_OPERATOR.IS_EXACT_TEXT]: 'is',
  [FILTER_OPERATOR.IS_NOT_EXACT_TEXT]: 'is not',
  [FILTER_OPERATOR.IS_EMPTY]: 'is empty',
  [FILTER_OPERATOR.IS_NOT_EMPTY]: 'is not empty',
  [FILTER_OPERATOR.IS_TRUE]: 'is true',
  [FILTER_OPERATOR.IS_FALSE]: 'is false',
};

const generateFilterOptions = (keys: FilterOperator[]) =>
  keys.map((i) => ({
    text: FILTER_TYPE_OPERATOR_LABELS[i],
    value: i,
  }));

export const FILTER_TYPE_OPERATORS: Record<
  FilterType,
  { text: string; value: FilterOperator }[]
> = {
  [FILTER_TYPE.BOOLEAN]: generateFilterOptions([
    FILTER_OPERATOR.IS_TRUE,
    FILTER_OPERATOR.IS_FALSE,
  ]),
  [FILTER_TYPE.DATE_RANGE]: generateFilterOptions([
    FILTER_OPERATOR.IS_AFTER_DATE,
    FILTER_OPERATOR.IS_BEFORE_DATE,
    FILTER_OPERATOR.IS_BETWEEN_DATE,
  ]),
  [FILTER_TYPE.MULTI_SELECT]: generateFilterOptions([
    FILTER_OPERATOR.IS_ONE_OF,
    FILTER_OPERATOR.IS_NOT_ONE_OF,
  ]),
  [FILTER_TYPE.NUMBER_RANGE]: generateFilterOptions([
    FILTER_OPERATOR.IS_GREATER_THAN_NUMBER,
    FILTER_OPERATOR.IS_LESS_THAN_NUMBER,
    FILTER_OPERATOR.IS_BETWEEN_NUMBER,
  ]),
  [FILTER_TYPE.SELECT]: generateFilterOptions([FILTER_OPERATOR.IS]),
  [FILTER_TYPE.SLIDER_RANGE]: generateFilterOptions([
    FILTER_OPERATOR.IS_BETWEEN_SLIDER,
  ]),
  [FILTER_TYPE.TEXT]: generateFilterOptions([FILTER_OPERATOR.CONTAINS_TEXT]),
  [FILTER_TYPE.EXACT_TEXT]: generateFilterOptions([
    FILTER_OPERATOR.IS_EXACT_TEXT,
  ]),
  [FILTER_TYPE.CUSTOM_DATA_NUMBER]: generateFilterOptions([
    FILTER_OPERATOR.IS_GREATER_THAN_NUMBER,
    FILTER_OPERATOR.IS_LESS_THAN_NUMBER,
    FILTER_OPERATOR.IS_EMPTY,
    FILTER_OPERATOR.IS_NOT_EMPTY,
  ]),
  [FILTER_TYPE.CUSTOM_DATA_TEXT]: generateFilterOptions([
    FILTER_OPERATOR.IS_EXACT_TEXT,
    FILTER_OPERATOR.IS_ONE_OF,
    FILTER_OPERATOR.IS_NOT_EXACT_TEXT,
    FILTER_OPERATOR.IS_NOT_ONE_OF,
    FILTER_OPERATOR.IS_EMPTY,
    FILTER_OPERATOR.IS_NOT_EMPTY,
  ]),
};
