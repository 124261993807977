import {
  ElementType,
  HTMLProps,
  JSXElementConstructor,
  MouseEventHandler,
  ReactElement,
  ReactNode,
  Ref,
  SyntheticEvent,
} from 'react';
import { DefaultSortTypes, Row, SortByFn, TableInstance } from 'react-table';
import { UsePinColumnsState } from 'app/shared/u21-ui/components/display/table/hooks/models';
import {
  U21ButtonColor,
  U21ButtonVariant,
} from 'app/shared/u21-ui/components/input/U21Button';

export interface U21TableAction {
  icon?: ReactElement;
  label: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  color?: U21ButtonColor;
  loading?: boolean;
  disabled?: boolean;
  tooltip?: string;
  variant?: U21ButtonVariant;
}

export interface U21TableState {
  page: number;
  pageSize: number;
  sortBy: { desc?: boolean; id: string }[];
}

export interface U21TablePreference {
  pageSize: number;
  sortBy: { desc?: boolean; id: string }[];
  columnWidths: Record<string, number>;
}

type RowID = number | string;
type ColumnID = string;

export interface U21TableProps<TRow extends object, TFilters = undefined>
  extends Omit<
    HTMLProps<HTMLDivElement>,
    'data' | 'disabled' | 'selected' | 'ref'
  > {
  actions?: U21TableAction[];
  columns: U21TableColumn<TRow, any>[];
  count?: number | null;
  data: TRow[];
  defaultColumnPin?: UsePinColumnsState['columnPin'];
  defaultColumnWidths?: Record<ColumnID, number>;
  defaultPage?: number;
  defaultPageSize?: number;
  defaultSortBy?: { desc?: boolean; id: ColumnID }[];
  disabled?: boolean | RowID[];
  isRowClickable?: (row: TRow) => boolean;
  /**
   * @deprecated should not need this anymore with react-query
   */
  filters?: TFilters;
  getRowID?: (row: TRow, relativeIndex: number) => RowID;
  label?: string;
  loading?: boolean;
  manualPagination?: boolean;
  noDataComponent?: ReactNode;
  onPreferenceChange?: (tablePreference: U21TablePreference) => void;
  onRefresh?: (state: U21TableState, filters?: TFilters) => void;
  onRowClick?: (
    id: RowID,
    row: TRow,
    e: SyntheticEvent | KeyboardEvent,
  ) => void;
  onRowSelect?: (ids: RowID[], rows: TRow[], e: SyntheticEvent) => void;
  onSelectAllButtonPressed?: (selectAllButtonPressed: boolean) => void;
  onStateChange?: (state: U21TableState, filters?: TFilters) => void;
  refreshLoading?: boolean;
  selectable?: boolean;
  selectAllButtonPressed?: boolean;
  selected?: RowID[];
  stateChangeDelay?: number;
  SubComponent?: JSXElementConstructor<
    U21TableSubComponentProps<TRow, TFilters>
  >;
  tableInstanceRef?: Ref<TableInstance<TRow>>;
}

export enum U21TableColumnTypes {
  ACTIONS = 'actions',
  BUTTON = 'button',
  DATE = 'date',
  DATETIME = 'datetime',
  LABEL = 'label',
  LIST = 'list',
  TEXT = 'text',
  BOOLEAN = 'boolean',
}

export type U21TableColumnTypesType =
  | 'actions'
  | 'button'
  | 'date'
  | 'datetime'
  | 'label'
  | 'list'
  | 'text'
  | 'boolean';

export interface U21TableHeaderCellProps<
  TRow extends object,
  TFilters = undefined,
> {
  tableProps: TableInstance<TRow>;
  userProps: U21TableProps<TRow, TFilters>;
}

export interface U21TableBodyCellProps<
  TRow extends object,
  TValue = undefined,
  TFilters = undefined,
> extends U21TableHeaderCellProps<TRow, TFilters> {
  id: RowID;
  row: TRow;
  value: TValue;
  rowProps: Row<TRow>;
}

export interface U21TableSubComponentProps<
  TRow extends object,
  TFilters = undefined,
> extends U21TableHeaderCellProps<TRow, TFilters> {
  id: RowID;
  row: TRow;
  rowProps: Row<TRow>;
}

export interface U21TableColumn<
  TRow extends object,
  TValue = undefined,
  TFilters = undefined,
> {
  accessor?: string | ((row: TRow, index: number) => TValue);
  Cell?: ElementType<U21TableBodyCellProps<TRow, TValue>>;
  cellProps?: Record<string, any> | ((row: TRow) => Record<string, any>);
  disablePinning?: boolean;
  disableResizing?: boolean;
  disableSortBy?: boolean;
  Header?: ElementType<U21TableHeaderCellProps<TRow, TFilters>> | string;
  headerIcon?: ReactElement;
  id: ColumnID;
  maxWidth?: number;
  minWidth?: number;
  noDivider?: boolean;
  sortType?: SortByFn<TRow> | DefaultSortTypes | SortTypes;
  type?: U21TableColumnTypesType;
  width?: number;
}

export type SortTypes =
  | 'alphanumeric'
  | 'boolean'
  | 'datetime'
  | 'list'
  | 'number';
