import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { DetectionModelsActionTypes } from 'app/modules/detectionModels/models';

// Actions
import {
  retrieveDetectionModelValidationAlerts as retrieveDetectionModelValidationAlertsAction,
  retrieveDetectionModelValidationAlertsSuccess,
} from 'app/modules/detectionModels/actions';

// API
import { retrieveDetectionModelValidationAlerts as retrieveDetectionModelValidationAlertsAPI } from 'app/shared/api/detectionModels';

const rootAction =
  DetectionModelsActionTypes.RETRIEVE_DETECTION_MODEL_VALIDATION_ALERTS;
function* retrieveDetectionModelValidationAlerts({
  payload,
}: ReturnType<typeof retrieveDetectionModelValidationAlertsAction>) {
  const config = {
    rootAction,
    request: call(retrieveDetectionModelValidationAlertsAPI, payload),
    success: function* onSuccess(
      result: ReturnType<
        typeof retrieveDetectionModelValidationAlertsSuccess
      >['payload'],
    ) {
      yield put(retrieveDetectionModelValidationAlertsSuccess(result));
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watch() {
  yield takeLatest(rootAction, retrieveDetectionModelValidationAlerts);
}
