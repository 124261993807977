import { FC, HTMLProps, ReactElement, ReactNode } from 'react';

import { getDOMProps } from 'app/shared/utils/react';
import styled from 'styled-components';

import {
  U21Spacer,
  U21SpacerProps,
} from 'app/shared/u21-ui/components/layout/U21Spacer';
import { U21Typography } from 'app/shared/u21-ui/components/display/typography/U21Typography';

export interface U21ContentDisplayProps
  extends Omit<HTMLProps<HTMLDivElement>, 'action' | 'title'> {
  action?: ReactNode;
  children?: ReactNode;
  icon?: ReactElement;
  title?: ReactNode;
  align?: U21SpacerProps['align'];
}

export const U21ContentDisplay: FC<U21ContentDisplayProps> = ({
  action,
  children,
  icon,
  title,
  align,
  ...rest
}) => {
  return (
    <U21Spacer horizontal spacing={2} align={align} {...getDOMProps(rest)}>
      <U21Typography icon={icon} variant="subtitle2">
        {title ? <>{title}:</> : ''}
      </U21Typography>
      <ContentContainer>{children}</ContentContainer>
      {action}
    </U21Spacer>
  );
};

const ContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
  gap: ${(props) => props.theme.spacing(1)};
`;
