import { FeatureFlag } from 'app/shared/featureFlags/models';

import { createAction } from 'app/shared/utils/action-helpers';
import { isEqual } from 'lodash';
import { selectAgent, selectIsUnit21User } from 'app/modules/session/selectors';
import { selectFeatureFlags } from 'app/shared/featureFlags/selectors';
import { setFeatureFlags } from 'app/shared/featureFlags/actions';
import { toSentenceCase } from 'app/shared/utils/string';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useFlags,
  useLDClient,
  withLDProvider,
} from 'launchdarkly-react-client-sdk';
import getFromProcessEnv from 'app/shared/utils/getFromProcessEnv';
import styled from 'styled-components';

import { IconAlertTriangle, IconSearch } from '@u21/tabler-icons';
import {
  U21SideMenu,
  U21SideMenuProps,
} from 'app/shared/u21-ui/components/dashboard';
import {
  U21Spacer,
  U21Switch,
  U21TextField,
  U21Typography,
} from 'app/shared/u21-ui/components';
import { LD_CONFIG } from 'app/shared/featureFlags/constants';

export const SET_FEATURE_FLAG_ACTION_TYPE = '@@featureFlags/SET_FEATURE_FLAG';

// defined here to prevent other places using this devtool-only action
export const setFeatureFlag = (payload: {
  key: FeatureFlag;
  value: boolean | number | string;
}) => {
  return createAction(SET_FEATURE_FLAG_ACTION_TYPE, payload);
};

interface FeatureFlagOption {
  key: FeatureFlag;
  label: string;
  value: boolean | number | string;
}

const LUMOS_URL = getFromProcessEnv('REACT_APP_AUTH0_LOGIN_REDIRECT_URL');

const FE_FEATURE_FLAGS = new Set<string>(Object.values(FeatureFlag));

export const FeatureFlagControl = withLDProvider(LD_CONFIG)(() => {
  const dispatch = useDispatch();
  const featureFlags = useSelector(selectFeatureFlags);
  const sessionAgent = useSelector(selectAgent);
  const isUnit21User = useSelector(selectIsUnit21User);

  const actualFlags = useFlags();
  const ldClient = useLDClient();

  useEffect(() => {
    ldClient?.identify({
      key: sessionAgent.email,
      email: sessionAgent.email,
      custom: {
        org_id: sessionAgent.org_id,
        org_name: sessionAgent.org_name,
        lumos_url: LUMOS_URL,
      },
    });
  }, [
    ldClient,
    sessionAgent.email,
    sessionAgent.org_id,
    sessionAgent.org_name,
  ]);

  const [filter, setFilter] = useState('');

  const filteredFlags = useMemo(() => {
    const lowercaseFilter = filter.toLowerCase();
    return Object.entries(featureFlags)
      .map(([key, value]) => ({
        key,
        label: toSentenceCase(key.replaceAll('-', ' ')),
        value,
      }))
      .filter((i) => FE_FEATURE_FLAGS.has(i.key))
      .filter(
        (i) =>
          i.key.toLowerCase().includes(lowercaseFilter) ||
          i.label.toLowerCase().includes(lowercaseFilter),
      );
  }, [featureFlags, filter]);

  const ldConnected = ldClient?.getContext()?.email === sessionAgent.email;

  const flagsChanged = useMemo(
    () => ldConnected && !isEqual(actualFlags, featureFlags),
    [actualFlags, featureFlags, ldConnected],
  );

  if (!isUnit21User) {
    return null;
  }

  const actionProps: Partial<U21SideMenuProps> | undefined = flagsChanged
    ? {
        actionButtonProps: { color: 'warning', children: 'Reset' },
        onAction: () => dispatch(setFeatureFlags(actualFlags)),
        footerLeftContent: (
          <U21Typography icon={<StyledIconAlertTriangle />} variant="body2">
            Flags Changed
          </U21Typography>
        ),
      }
    : undefined;

  return (
    <U21SideMenu title="Feature Flag Control" {...actionProps}>
      <U21Spacer marginStart>
        <U21TextField
          startIcon={<IconSearch />}
          onChange={(val?: string) => setFilter(val || '')}
          placeholder="Search feature flags"
          value={filter}
        />
        {filteredFlags.map((i: FeatureFlagOption) => {
          const { key, label, value } = i;
          return (
            <Container key={key}>
              <U21Typography variant="body2">{label}</U21Typography>
              {(() => {
                if (typeof value === 'boolean') {
                  return (
                    <U21Switch
                      checked={value}
                      onChange={(val) =>
                        dispatch(setFeatureFlag({ key, value: val }))
                      }
                    />
                  );
                }
                if (typeof value === 'number') {
                  return (
                    <StyledU21TextField
                      onChange={(val = 0) =>
                        dispatch(setFeatureFlag({ key, value: val }))
                      }
                      type="number"
                      value={value}
                    />
                  );
                }
                if (typeof value === 'string') {
                  return (
                    <StyledU21TextField
                      onChange={(val = '') =>
                        dispatch(setFeatureFlag({ key, value: val }))
                      }
                      value={value}
                    />
                  );
                }
                return null;
              })()}
            </Container>
          );
        })}
      </U21Spacer>
    </U21SideMenu>
  );
});

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledU21TextField = styled(U21TextField)`
  width: 100px;
`;

const StyledIconAlertTriangle = styled(IconAlertTriangle)`
  color: ${(props) => props.theme.palette.warning.main};
`;
