import { createAction, ActionsUnion } from 'app/shared/utils/action-helpers';

// Models
import {
  InsightsActionTypes,
  CreateDashboardPayload,
  EditDashboardPayload,
  EditDashboardChartsLayoutPayload,
  RetrieveChartPayload,
  EditChartPayload,
  RetrieveDashboardsResponse,
  RetrieveDashboardResponse,
  CreateDashboardResponse,
  EditDashboardResponse,
  EditChartResponse,
  DuplicateDashboardResponse,
} from 'app/modules/insights/models';
import { PaginatedSearchRequest } from 'app/modules/search/models';

export const retrieveChartDefinitions = (payload: PaginatedSearchRequest) => {
  return createAction(InsightsActionTypes.RETRIEVE_CHART_DEFINITIONS, payload);
};

export const retrieveChartDefinitionsSuccess = (result) => {
  return createAction(
    InsightsActionTypes.RETRIEVE_CHART_DEFINITIONS_SUCCESS,
    result,
  );
};

export const retrieveDashboards = () => {
  return createAction(InsightsActionTypes.RETRIEVE_DASHBOARDS);
};

export const retrieveDashboardsSuccess = (
  result: RetrieveDashboardsResponse,
) => {
  return createAction(InsightsActionTypes.RETRIEVE_DASHBOARDS_SUCCESS, result);
};

export const retrieveDashboard = (payload: number) => {
  return createAction(InsightsActionTypes.RETRIEVE_DASHBOARD, payload);
};

export const retrieveDashboardSuccess = (result: RetrieveDashboardResponse) => {
  return createAction(InsightsActionTypes.RETRIEVE_DASHBOARD_SUCCESS, result);
};

export const retrieveChart = (payload: RetrieveChartPayload) => {
  return createAction(InsightsActionTypes.RETRIEVE_CHART, payload);
};

export const retrieveCharts = () => {
  return createAction(InsightsActionTypes.RETRIEVE_CHARTS);
};

export const retrieveChartSuccess = (result) => {
  return createAction(InsightsActionTypes.RETRIEVE_CHART_SUCCESS, result);
};

export const createDashboard = (payload: CreateDashboardPayload) => {
  return createAction(InsightsActionTypes.CREATE_DASHBOARD, payload);
};

export const createDashboardSuccess = (result: CreateDashboardResponse) => {
  return createAction(InsightsActionTypes.CREATE_DASHBOARD_SUCCESS, result);
};

export const editDashboard = (payload: EditDashboardPayload) => {
  return createAction(InsightsActionTypes.EDIT_DASHBOARD, payload);
};

export const editDashboardSuccess = (result: EditDashboardResponse) => {
  return createAction(InsightsActionTypes.EDIT_DASHBOARD_SUCCESS, result);
};

export const deleteChartSuccess = (chartId: number) => {
  return createAction(InsightsActionTypes.DELETE_CHART_SUCCESS, chartId);
};

export const editChart = (payload: EditChartPayload) => {
  return createAction(InsightsActionTypes.EDIT_CHART, payload);
};

export const editChartSuccess = (result: EditChartResponse) => {
  return createAction(InsightsActionTypes.EDIT_CHART_SUCCESS, result);
};

export const editDashboardChartsLayout = (
  payload: EditDashboardChartsLayoutPayload,
) => {
  return createAction(
    InsightsActionTypes.EDIT_DASHBOARD_CHARTS_LAYOUT,
    payload,
  );
};

export const editDashboardChartsLayoutSuccess = (
  result: EditDashboardResponse,
) => {
  return createAction(
    InsightsActionTypes.EDIT_DASHBOARD_CHARTS_LAYOUT_SUCCESS,
    result,
  );
};

export const deleteDashboard = (payload: number) => {
  return createAction(InsightsActionTypes.DELETE_DASHBOARD, payload);
};

export const deleteDashboardSuccess = (payload: number) => {
  return createAction(InsightsActionTypes.DELETE_DASHBOARD_SUCCESS, payload);
};

export const duplicateDashboard = (payload: number) => {
  return createAction(InsightsActionTypes.DUPLICATE_DASHBOARD, payload);
};

export const duplicateDashboardSuccess = (
  result: DuplicateDashboardResponse,
) => {
  return createAction(InsightsActionTypes.DUPLICATE_DASHBOARD_SUCCESS, result);
};

export const clearDashboard = () => {
  return createAction(InsightsActionTypes.CLEAR_DASHBOARD);
};

const ReduxActionsList = {
  retrieveChartDefinitionsSuccess,
  createDashboardSuccess,
  retrieveDashboardsSuccess,
  retrieveDashboardSuccess,
  editDashboardSuccess,
  editDashboardChartsLayoutSuccess,
  deleteDashboardSuccess,
  duplicateDashboardSuccess,
  retrieveChartSuccess,
  clearDashboard,
  editChartSuccess,
  editChart,
  deleteChartSuccess,
};

type ReduxActions = ActionsUnion<typeof ReduxActionsList>;
export default ReduxActions;
