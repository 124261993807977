import { FormSchema } from 'app/shared/models/form';

import { getIn, setIn } from 'final-form';

export function createFormInitialValues<InitalValuesType>(
  schema: FormSchema,
  data: Record<string, any> = {},
) {
  // TODO: Make this work with arrays of nested objects with default functions
  return schema.reduce((acc, i) => {
    let value;
    if (typeof i.default === 'function') {
      value = i.default(data, i.name);
    } else {
      value = getIn(data, i.name);
      if (typeof value !== 'boolean' && !value) {
        value = i.default;
      }
    }
    if (typeof value !== 'undefined') {
      return setIn(acc, i.name, value);
    }
    return acc;
  }, {}) as InitalValuesType;
}

export function generateFormFieldProps(formField) {
  const { default: drop, permissions, ...rest } = formField;
  return rest;
}

export const pickFormInitialValues = <
  DataObj,
  DataObjKeys extends keyof DataObj,
>(
  fieldNames: DataObjKeys[],
  data: DataObj,
) => {
  return fieldNames.reduce(
    (acc, cur) => {
      if (data[cur] !== undefined) {
        acc[cur] = data[cur];
      }
      return acc;
    },
    {} as { [Key in (typeof fieldNames)[number]]: DataObj[Key] },
  );
};

type Join<K, P> = K extends string | number
  ? P extends string | number
    ? `${K}.${P}`
    : never
  : never;

// Recursive type to extract possible paths. It works well for non-recursive
// form. For recursive one, it can only reach a certain depth.
export type FormPath<T> = T extends object
  ? {
      [K in keyof T]: T[K] extends object ? Join<K, FormPath<T[K]>> | K : K;
    }[keyof T]
  : never;
