// Saga
import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { InsightsActionTypes } from 'app/modules/insights/models';

// Api
import { retrieveChartDefinitions as retrieveChartDefinitionsApi } from 'app/shared/api/insights';

// Actions
import {
  retrieveChartDefinitions as retrieveChartDefinitionsAction,
  retrieveChartDefinitionsSuccess,
} from 'app/modules/insights/actions';

const rootAction = InsightsActionTypes.RETRIEVE_CHART_DEFINITIONS;
function* retrieveChartDefinitions({
  payload,
}: ReturnType<typeof retrieveChartDefinitionsAction>) {
  const config = {
    rootAction,
    request: call(retrieveChartDefinitionsApi, payload),
    errorToastMessage: `Something went wrong, unable to retrieve charts.`,
    success: function* onSuccess(
      result: ReturnType<typeof retrieveChartDefinitionsSuccess>['payload'],
    ) {
      yield put(retrieveChartDefinitionsSuccess(result));
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watchRetrieveCharts() {
  yield takeLatest(rootAction, retrieveChartDefinitions);
}
