import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { CustomChecklistActionTypes } from 'app/shared/customChecklist/models';

// Actions
import {
  retrieveCustomChecklistSubmission as retrieveCustomChecklistSubmissionAction,
  retrieveCustomChecklistSubmissionSuccess,
} from 'app/shared/customChecklist/actions';

// API
import { getCustomChecklistSubmissionByArticleId as getCustomChecklistSubmissionByArticleIdApi } from 'app/shared/customChecklist/api';

const rootAction =
  CustomChecklistActionTypes.RETRIEVE_CUSTOM_CHECKLIST_SUBMISSION;
export function* retrieveCustomChecklistSubmission({
  payload,
}: ReturnType<typeof retrieveCustomChecklistSubmissionAction>) {
  const config = {
    rootAction,
    request: call(getCustomChecklistSubmissionByArticleIdApi, payload),
    success: function* onSuccess(result) {
      yield put(retrieveCustomChecklistSubmissionSuccess(result));
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watchRetrieveCustomChecklistSubmission() {
  yield takeLatest(rootAction, retrieveCustomChecklistSubmission);
}
