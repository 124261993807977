import { InvestigationTabs } from 'app/modules/investigations/models';
import { CaseDetails } from 'app/modules/casesOld/models';
import { CaseActionTriggerModel } from 'app/modules/webhooks/models';
import {
  RemoveDocumentFromCaseResponse,
  RemoveDocumentFromAlertResponse,
} from 'app/modules/uploads/models';
import { isEmpty } from 'lodash';

export const getDefaultTabObject = (
  tabsByType: InvestigationTabs[],
): Record<string, InvestigationTabs> =>
  tabsByType.reduce(
    (acc, tab: InvestigationTabs) => Object.assign(acc, { [tab.path]: tab }),
    {},
  );

export const shouldRenderCase = (
  trigger: CaseActionTriggerModel,
  caseDetails: CaseDetails,
) => {
  const correctCaseStatus =
    trigger.config.show_on_case_status === null ||
    trigger.config.show_on_case_status === caseDetails.status;
  if (!correctCaseStatus) {
    return false;
  }
  const noQueues = !trigger.queues?.length;
  const containsCorrectQueue =
    caseDetails.queue_id && trigger.queues.includes(caseDetails.queue_id);
  return noQueues || containsCorrectQueue;
};

/* From a list of action triggers filter
 * out the triggers that shouldn't be visible
 * based on the queueIds passed.
 */
export const filterActionTriggersByQueueIds = <T extends { queues: number[] }>(
  actionTriggers: T[],
  queueIds: number[] | undefined,
): T[] => {
  const queueIdSet = new Set(queueIds);
  return queueIds === undefined
    ? actionTriggers
    : actionTriggers.filter(
        ({ queues }) =>
          queues.length === 0 || queues.some((q) => queueIdSet.has(q)), // Intersection of queue id filters and action trigger queue ids
      );
};

export const generateWarningFromDeleteAttachmentResponse = (
  attachmentId: number,
  {
    linked_objects_count: linkedObjectsCount,
  }: RemoveDocumentFromCaseResponse | RemoveDocumentFromAlertResponse,
): string => {
  if (isEmpty(linkedObjectsCount)) {
    return '';
  }
  let warning = `Attachment ${attachmentId} remains associated with `;
  let useSeparator = false;

  if (linkedObjectsCount.CASE) {
    warning += `${linkedObjectsCount.CASE} Case(s)`;
    useSeparator = true;
  }
  if (linkedObjectsCount.ALERT) {
    warning += `${useSeparator ? ', ' : ''}${linkedObjectsCount.ALERT} Alert(s)`;
    useSeparator = true;
  }
  if (linkedObjectsCount.SAR_FILING) {
    warning += `${useSeparator ? ', ' : ''}${linkedObjectsCount.SAR_FILING} SAR(s)`;
    useSeparator = true;
  }
  if (linkedObjectsCount.CTR_FILING) {
    warning += `${useSeparator ? ', ' : ''}${linkedObjectsCount.CTR_FILING} CTR(s)`;
    useSeparator = true;
  }
  if (linkedObjectsCount.RULE) {
    warning += `${useSeparator ? ', ' : ''}${linkedObjectsCount.RULE} Rule(s)`;
    useSeparator = true;
  }

  return warning;
};
