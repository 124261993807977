// Tools for a strongly typed redux store
// https://medium.com/@martin_hotell/improved-redux-type-safety-with-typescript-2-8-2c11a8062575

export interface Action<Type extends string> {
  type: Type;
}
export interface ActionWithPayload<Type extends string, P>
  extends Action<Type> {
  payload: P;
}
export interface ActionWithTwoPayloads<Type extends string, P, Meta>
  extends Action<Type> {
  payload: P;
  meta: Meta;
}

export function createAction<Type extends string>(type: Type): Action<Type>;
export function createAction<Type extends string, P>(
  type: Type,
  payload: P,
): ActionWithPayload<Type, P>;
export function createAction<Type extends string, P, Meta>(
  type: Type,
  payload: P,
  meta: Meta,
): ActionWithTwoPayloads<Type, P, Meta>;

/**
 * @deprecated Use slice + thunks instead
 */
export function createAction<Type extends string, P, Meta>(
  type: Type,
  payload?: P,
  meta?: Meta,
) {
  if (payload === undefined) {
    return { type };
  }
  return meta === undefined ? { type, payload } : { type, payload, meta };
}

type FunctionType = (...args: any[]) => any;
interface ActionCreatorsMapObject {
  [actionCreator: string]: FunctionType;
}

// used for the full object of all actions export
export type ActionsUnion<A extends ActionCreatorsMapObject> = ReturnType<
  A[keyof A]
>;
