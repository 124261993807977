import { startCase } from 'lodash';
/*
    Heap recommends an event naming structure of `Location/Category - Past Tense Action - Object`:
    - “Dashboard - Clicked - Add New Button”
    - “PDP - Viewed - Warranty.”
    The key here is to be as descriptive as possible when defining any event in the account. 
    This makes it easy for anyone who sees the event to know what data it is collecting, ultimately ensuring insights found in Heap are accurate and trustworthy.

    We are tracking all sagas events + custom events, we can combine these events in our Reports to measure average time between events.

    DO NOT CREATE events for:
      - Page/Component loaded (Can be obtained from heap by accessing the Page view event)
    AVOID creating for:
      - Buttons that trigger API calls unless one button can trigger different sagas, e.g Search bar can call search events/entities/alerts/etc sagas so it's fine to create an event for it but most buttons only call 1 sagas.
      - Every button/input on a form, only add it if we plan to modify the UI and we want to measure the time it takes to interact with the components. (If we only care of the general time to fill in a form we can obtain it by using "page hit" and the "hit" sagas)

    IF you don't see the API event(sagas calls) that you are looking for on Heap most likely it is not using makeSagasRequest, try updating the sagas definition so it appears on Heap.
    You can send whatever you want as the event payload, make sure to never send sensitive data and always send primitive values (number/string/boolean) and not arrays/objects/functions/etc
*/
const customEvents = {
  navigator: {
    topBarClickedResult: 'TopBar - Clicked - Result',
    sidebarMaximize: 'Sidebar - Maximize',
    sidebarMinimize: 'Sidebar - Minimize',
  },
  rules: {
    // Metadata Form
    metadataFormChangedTitle: 'Rules Metadata Form - Changed - Title',
    metadataFormChangedDescription:
      'Rules Metadata Form - Changed - Description',
    metadataFormChangedTags: 'Rules Metadata Form - Changed - Tags',
    metadataFormChangedStartTime: 'Rules Metadata Form - Changed - Start Time',
    metadataFormChangedEndTime: 'Rules Metadata Form - Changed - End Time',
    // Scenario Builder
    scenarioBuilderSelectedScenario:
      'Rules Scenario Builder - Selected - Scenario',
    scenarioBuilderClickedResetScenario:
      'Rules Scenario Builder - Clicked - Change Scenario',
    // Validate Buttons
    validateButtonsClickedReset: 'Rules Validate Buttons - Clicked - Reset',
    // Rule Deploy Buttons
    ruleDeployButtonsClickedDuplicate:
      'Rules Deploy Buttons - Clicked - Duplicate',
  },
  detectionModeling: {
    // Simple Create
    simpleCreateClickedStepIndicator:
      'Simple Detection Model - Clicked - Step Indicator',
    simpleCreateClickedNext: 'Simple Detection Model - Clicked - Next Button',
    simpleCreateClickedPrev:
      'Simple Detection Model - Clicked - Previous Button',
    // Scenario Picker
    scenarioPickerSelectedScenario:
      'Simple Detection Model - Selected - Scenario',

    // Advanced Create
    advancedCreateClickedStepIndicator:
      'Advanced Detection Model - Clicked - Step Indicator',
    advancedCreateClickedNext:
      'Advanced Detection Model - Clicked - Next Button',
    advancedCreateClickedPrev:
      'Advanced Detection Model - Clicked - Previous Button',
    dynamicModelSelectedTemplate: 'Dynamic Model - Selected - Template',
  },
  tableConfigs: {
    // Table Settings Button
    paginationTableClickedTableSettings:
      'Pagination Table - Clicked - Table Settings Button',
    tableConfigSidebarToggledColumnVisibility:
      'Table Config Sidebar - Toggled - Column Visibility',
  },
  authentication: {
    logout: 'Authentication - Logout - Session',
  },
  uiRefresh: {
    optIn: 'UI Refresh - Opt-in',
    optOut: 'UI Refresh - Opt-out',
  },
  workflows: {
    create: 'Workflow - Create',
    edit: 'Workflow - Edit',
  },
  print: {
    alert: 'Alert PDF - Printed',
    case: 'Case PDF - Printed',
    rule: 'Rule PDF - Printed',
    sar: 'SAR PDF - Printed',
  },
  visibility: {
    blur: 'Exited Application',
    focus: 'Entered Application',
  },
  sidebar: {
    toggle: 'Sidebar - Toggled - Visibility',
  },
  timeTracking: 'Time Tracking',
};

// Helper to format sagas to heap event
export const sagasToHeapEvent = (
  rootAction: string,
  action: 'Hit' | 'Succeeded' | 'Failed',
): string => {
  // Sagas types look like @@module/SOME_ACTION
  // Format it to: Module API - Hit - Some Action
  const splitted = rootAction.split('/');
  if (splitted.length > 1) {
    const moduleAction =
      action === 'Succeeded'
        ? splitted[1].replace('_SUCCESS', '')
        : splitted[1];
    return `${startCase(
      splitted[0].replace('@@', ''),
    )} API - ${action} - ${startCase(moduleAction.toLowerCase())}`;
  }
  // Sagas has a wrong format, return the original type
  return rootAction;
};

export default customEvents;
