// Models
import { TableConfig } from 'app/shared/pagination/models';
import { RadioOption } from 'app/shared/components/RadioGroup';
import {
  TimeGranularityFilter,
  InsightsFiltersStatus,
} from 'app/modules/insights/models';
import { DropdownItemProps } from 'semantic-ui-react';

// Utils
import assets from 'app/shared/utils/assets';

// Charts Grid
export const WIDTH_OF_DASHBOARD = 1000;
export const WIDTH_OF_SIDEBAR = 300;
export const HEIGHT_OF_ROW = 200;
export const NUM_GRID_COLS = 12;
export const GRID_STEPS = 4;
export const WIDTH_PER_COL = WIDTH_OF_DASHBOARD / NUM_GRID_COLS;
export const STEP_SIZE = NUM_GRID_COLS / GRID_STEPS;
export const GRID_LAYOUT_MARGIN = 10;
export const LAYOUT_WRAPPER_CONTROL_BAR_HEIGHT = 65;

// in columns
export const MIN_LAYOUT_HEIGHT = 1;
export const MAX_LAYOUT_HEIGHT = 2;
export const DEFAULT_LAYOUT_HEIGHT = MAX_LAYOUT_HEIGHT;

export const DATETIME_FORMAT = 'MM/DD/YYYY';

export const X_AXIS_TYPE_CATEGORY = 'category';
export const X_AXIS_TYPE_DATETIME = 'datetime';

const INSIGHTS_FILTERS_STATUSES: InsightsFiltersStatus[] = ['OPEN', 'CLOSED'];
export const INSIGHTS_FILTERS_STATUSES_OPTIONS: DropdownItemProps[] =
  INSIGHTS_FILTERS_STATUSES.map((status) => {
    return {
      key: status,
      value: status,
      text: status,
    };
  });

export const CHART_TYPE_CONFIG = {
  line: {
    text: 'Line',
    icon: assets.icons.chartLine,
  },
  pie: {
    text: 'Pie',
    icon: assets.icons.chartPie,
  },
  column: {
    text: 'Column',
    icon: assets.icons.barChart,
  },
  table: {
    text: 'Table',
    icon: assets.icons.table,
  },
  stacked_column: {
    text: 'Stacked',
    icon: assets.icons.barChart,
  },
};

export const CHART_DEFINITION_TABLE_CONFIG: TableConfig[] = [
  {
    key: 'title',
    type: 'text',
    label: 'Chart',
  },
  {
    key: 'description',
    type: 'text',
    label: 'Description',
  },
];

export const PIE_CHART_PROPS = {
  legend: {
    enabled: true,
    floating: false,
    itemStyle: {
      fontFamily: 'Source Sans Pro',
    },
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      dataLabels: {
        enabled: false,
        format:
          '<span style="font-size:1rem; font-family:\'Source Sans Pro\'; font-weight: 300;">{point.name}</span>',
      },
      showInLegend: true,
    },
  },
};

export const DATE_GRANULARITY_OPTIONS: RadioOption<TimeGranularityFilter>[] = [
  {
    value: 'DAY',
    label: 'Day',
  },
  {
    value: 'WEEK',
    label: 'Week',
  },
  {
    value: 'MONTH',
    label: 'Month',
  },
];
