import { ColorSchema } from 'vendor/material-minimal/palette';
import { HTMLProps, ReactNode, SyntheticEvent } from 'react';

import { getDOMProps } from 'app/shared/utils/react';
import styled, { css } from 'styled-components';

import { ToggleButtonGroup } from '@mui/material';
import {
  U21Button,
  U21ButtonProps,
} from 'app/shared/u21-ui/components/input/U21Button';

type U21ButtonGroupColors = 'inherit' | ColorSchema;

type U21ButtonGroupSizes = 'large' | 'medium' | 'small';

type U21ButtonGroupValue = number | string | boolean;

export interface U21ButtonGroupButtonProps<T extends U21ButtonGroupValue>
  extends Omit<
    U21ButtonProps,
    'as' | 'icon' | 'size' | 'type' | 'value' | 'label'
  > {
  label: ReactNode;
  value: T;
}

export interface U21ButtonGroupProps<T extends U21ButtonGroupValue>
  extends Omit<HTMLProps<HTMLDivElement>, 'size' | 'onClick' | 'value'> {
  buttons: U21ButtonGroupButtonProps<T>[];
  color?: U21ButtonGroupColors;
  disabled?: boolean;
  horizontal?: boolean;
  onClick: (value: T, event: SyntheticEvent) => void;
  size?: U21ButtonGroupSizes;
  value: T;
}

export const U21ButtonGroup = <T extends U21ButtonGroupValue>(
  props: U21ButtonGroupProps<T>,
) => {
  const {
    buttons,
    color = 'primary',
    disabled,
    horizontal = true,
    onClick,
    size = 'medium',
    value,
    ...rest
  } = props;

  return (
    <ToggleButtonGroup
      orientation={horizontal ? 'horizontal' : 'vertical'}
      {...getDOMProps(rest)}
    >
      {buttons.map((i, idx) => {
        const { className = '', value: buttonValue, label, ...buttonProps } = i;
        const isSelected = buttonValue === value;
        const first = idx === 0;
        const last = idx === buttons.length - 1;

        let toggleButtonClassName = 'MuiToggleButtonGroup-middleButton';
        if (first) {
          toggleButtonClassName = 'MuiToggleButtonGroup-firstButton';
        } else if (last) {
          toggleButtonClassName = 'MuiToggleButtonGroup-lastButton';
        }

        return (
          <StyledU21Button
            $vertical={!horizontal}
            key={String(buttonValue)}
            className={`${className} ${toggleButtonClassName}`}
            color={isSelected ? color : 'inherit'}
            disabled={disabled}
            onClick={(e) => onClick(buttonValue, e)}
            size={size}
            variant={isSelected ? 'contained' : 'outlined'}
            {...buttonProps}
          >
            {label}
          </StyledU21Button>
        );
      })}
    </ToggleButtonGroup>
  );
};

const StyledU21Button = styled(U21Button)<{ $vertical: boolean }>`
  ${(props) =>
    props.$vertical && props.tooltip
      ? css`
          width: 100%;
        `
      : css``}
`;
