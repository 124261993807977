import { SarStatusTypes } from 'app/modules/fincenSarNew/models';
import { U21SelectOptionProps } from 'app/shared/u21-ui/components';

export const BE_STATUS_KEYS: { [K in SarStatusTypes]: K } = {
  // Type: Archived, Color: Grey
  ARCHIVED: 'ARCHIVED',

  // Type: In progress, Color: Blue
  PREFILING_VALIDATION_PENDING: 'PREFILING_VALIDATION_PENDING',
  PREFILING_VALIDATION_IN_PROCESS: 'PREFILING_VALIDATION_IN_PROCESS',
  PREFILING_VALIDATION_FAILED: 'PREFILING_VALIDATION_FAILED',
  PREFILING_VALIDATION_SUCCESS: 'PREFILING_VALIDATION_SUCCESS',

  // Type: In review, Color: Blue
  READY_FOR_REVIEW: 'READY_FOR_REVIEW',

  // Type: Submitting, Color: Yellow
  QUEUED_FOR_FILING: 'QUEUED_FOR_FILING',
  SENDING_TO_FINCEN: 'SENDING_TO_FINCEN',
  SENT_TO_FINCEN: 'SENT_TO_FINCEN',

  // Type: Submission accepted, Color: Green
  FINCEN_ACCEPTED: 'FINCEN_ACCEPTED',

  // Type: Submission successful, Color: Green - contained
  FILING_SUCCESS: 'FILING_SUCCESS',

  // Type: Submission failed, Color: Red
  FILING_FAILED: 'FILING_FAILED',
  FINCEN_VALIDATION_FAILED: 'FINCEN_VALIDATION_FAILED',
  FINCEN_REJECTED: 'FINCEN_REJECTED',
  FILING_MAX_RETRIES_EXCEEDED: 'FILING_MAX_RETRIES_EXCEEDED',

  // Type: Other, Color: Grey
  FINCEN_STATUS_OTHER: 'FINCEN_STATUS_OTHER',
};

export const FILING_STATUS_KEYS = {
  IN_PROGRESS: 'IN_PROGRESS',
  IN_REVIEW: 'IN_REVIEW',
  SUBMITTING: 'SUBMITTING',
  SUBMISSION_ACCEPTED: 'SUBMISSION_ACCEPTED',
  SUBMISSION_SUCCESSFUL: 'SUBMISSION_SUCCESSFUL',
  SUBMISSION_FAILED: 'SUBMISSION_FAILED',
  ARCHIVED: 'ARCHIVED',
  OTHER: 'OTHER',
} as const;

export type FilingStatus = ValueOf<typeof FILING_STATUS_KEYS>;

export const FILING_STATUS_DISPLAY_LABEL: Record<FilingStatus, string> = {
  [FILING_STATUS_KEYS.IN_PROGRESS]: 'In progress',
  [FILING_STATUS_KEYS.IN_REVIEW]: 'In review',
  [FILING_STATUS_KEYS.SUBMITTING]: 'Submitting',
  [FILING_STATUS_KEYS.SUBMISSION_ACCEPTED]: 'Submission accepted',
  [FILING_STATUS_KEYS.SUBMISSION_SUCCESSFUL]: 'Submission successful',
  [FILING_STATUS_KEYS.SUBMISSION_FAILED]: 'Submission failed',
  [FILING_STATUS_KEYS.ARCHIVED]: 'Archived',
  [FILING_STATUS_KEYS.OTHER]: 'Other',
};

export const FILING_STATUS_OPTIONS: U21SelectOptionProps<FilingStatus>[] = [
  FILING_STATUS_KEYS.IN_PROGRESS,
  FILING_STATUS_KEYS.IN_REVIEW,
  FILING_STATUS_KEYS.SUBMITTING,
  FILING_STATUS_KEYS.SUBMISSION_ACCEPTED,
  FILING_STATUS_KEYS.SUBMISSION_SUCCESSFUL,
  FILING_STATUS_KEYS.SUBMISSION_FAILED,
  FILING_STATUS_KEYS.ARCHIVED,
].map((i) => ({
  text: FILING_STATUS_DISPLAY_LABEL[i],
  value: i,
}));

export const BE_FE_STATUS_MAPPING: Record<SarStatusTypes, FilingStatus> = {
  [BE_STATUS_KEYS.ARCHIVED]: FILING_STATUS_KEYS.ARCHIVED,
  [BE_STATUS_KEYS.PREFILING_VALIDATION_PENDING]: FILING_STATUS_KEYS.IN_PROGRESS,
  [BE_STATUS_KEYS.PREFILING_VALIDATION_IN_PROCESS]:
    FILING_STATUS_KEYS.IN_PROGRESS,
  [BE_STATUS_KEYS.PREFILING_VALIDATION_FAILED]: FILING_STATUS_KEYS.IN_PROGRESS,
  [BE_STATUS_KEYS.PREFILING_VALIDATION_SUCCESS]: FILING_STATUS_KEYS.IN_PROGRESS,
  [BE_STATUS_KEYS.READY_FOR_REVIEW]: FILING_STATUS_KEYS.IN_REVIEW,
  [BE_STATUS_KEYS.QUEUED_FOR_FILING]: FILING_STATUS_KEYS.SUBMITTING,
  [BE_STATUS_KEYS.SENDING_TO_FINCEN]: FILING_STATUS_KEYS.SUBMITTING,
  [BE_STATUS_KEYS.SENT_TO_FINCEN]: FILING_STATUS_KEYS.SUBMITTING,
  [BE_STATUS_KEYS.FINCEN_ACCEPTED]: FILING_STATUS_KEYS.SUBMISSION_ACCEPTED,
  [BE_STATUS_KEYS.FILING_SUCCESS]: FILING_STATUS_KEYS.SUBMISSION_SUCCESSFUL,
  [BE_STATUS_KEYS.FILING_FAILED]: FILING_STATUS_KEYS.SUBMISSION_FAILED,
  [BE_STATUS_KEYS.FINCEN_VALIDATION_FAILED]:
    FILING_STATUS_KEYS.SUBMISSION_FAILED,
  [BE_STATUS_KEYS.FINCEN_REJECTED]: FILING_STATUS_KEYS.SUBMISSION_FAILED,
  [BE_STATUS_KEYS.FILING_MAX_RETRIES_EXCEEDED]:
    FILING_STATUS_KEYS.SUBMISSION_FAILED,
  [BE_STATUS_KEYS.FINCEN_STATUS_OTHER]: FILING_STATUS_KEYS.OTHER,
};

export const FE_BE_STATUS_MAPPING = Object.entries(BE_FE_STATUS_MAPPING).reduce<
  Record<FilingStatus, SarStatusTypes[]>
>(
  (acc, [k, v]: [SarStatusTypes, FilingStatus]) => {
    if (acc[v]) {
      acc[v].push(k);
    } else {
      acc[v] = [k];
    }
    return acc;
  },
  {
    IN_PROGRESS: [],
    IN_REVIEW: [],
    SUBMITTING: [],
    SUBMISSION_ACCEPTED: [],
    SUBMISSION_SUCCESSFUL: [],
    SUBMISSION_FAILED: [],
    ARCHIVED: [],
    OTHER: [],
  },
);
