import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { DetectionModelsActionTypes } from 'app/modules/detectionModels/models';

// Actions
import {
  retrieveDetectionModelsPerformance as retrieveDetectionModelsPerformanceAction,
  retrieveDetectionModelsPerformanceSuccess,
} from 'app/modules/detectionModels/actions';

// API
import { retrieveDetectionModelsPerformance as retrieveDetectionModelsPerformanceAPI } from 'app/shared/api/detectionModels';

const rootAction =
  DetectionModelsActionTypes.RETRIEVE_DETECTION_MODELS_PERFORMANCE;
export function* retrieveDetectionModelsPerformance({
  payload,
}: ReturnType<typeof retrieveDetectionModelsPerformanceAction>) {
  const config = {
    rootAction,
    errorToastMessage: 'Something went wrong, unable to retrieve chart.',
    request: call(retrieveDetectionModelsPerformanceAPI, payload),
    success: function* onSuccess(result) {
      yield put(retrieveDetectionModelsPerformanceSuccess(result));
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watch() {
  yield takeLatest(rootAction, retrieveDetectionModelsPerformance);
}
