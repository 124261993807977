import { CSSProperties } from 'react';
import { useSelector } from 'react-redux';

// Models
import { TableConfig, TableValueType } from 'app/shared/pagination/models';

// Components
import { Label as SemanticLabel } from 'semantic-ui-react';

// Selectors
import { selectLumosConfig } from 'app/modules/orgSettings/selectors';

// Utils
import { generateColor } from 'app/shared/utils/stringToColor';

// Helpers
import { isSuitableBackgroundColor } from 'app/shared/helpers';
import { getLabelStyle } from 'app/shared/u21-ui/components/tables/helpers';

// Styles
import scssStyles from 'app/shared/events/styles/EventTable.module.scss';

// used to indicate to generate a random color
const USE_RANDOM_COLOR_KEYS = [
  'internal_txn_type',
  'action_type',
  'org.name',
  'sender_id',
  'receiver_id',
  'runs_on_org',
];

interface BaseProps {
  config: TableConfig;
  rowKey: string;
  type: Exclude<TableValueType, 'address'>;
  value?: string | null | { value_: string };
}

interface AddressProps extends Omit<BaseProps, 'type' | 'value'> {
  type: 'address';
  value?: { value_: string; type: string };
}

type Props = BaseProps | AddressProps;

export const Label = ({ rowKey, value, config, type }: Props) => {
  const { colors } = useSelector(selectLumosConfig);
  const { useRandomColor } = config;
  const useGenerateColor = USE_RANDOM_COLOR_KEYS.includes(config.key);

  if (value === undefined || value === '' || value === null) {
    return <div />;
  }

  const isObject = value instanceof Object;
  const valueToRender = isObject ? value.value_ : value;

  let labelStyle: CSSProperties;
  if (isObject) {
    labelStyle = getLabelStyle(1);
  } else if (useGenerateColor) {
    const bgColor = generateColor(valueToRender, colors);
    labelStyle = {
      background: bgColor,
      border: `1px solid ${bgColor}`,
      color: isSuitableBackgroundColor(bgColor) ? 'white' : 'black',
    };
  } else {
    labelStyle = getLabelStyle(value, useRandomColor);
  }

  const label = (
    <SemanticLabel
      key={`${rowKey}-${value}`}
      className={scssStyles.valueLabel}
      style={labelStyle}
    >
      {String(valueToRender)}
    </SemanticLabel>
  );

  if (type === 'address' && value.type) {
    return (
      <div className={scssStyles.horizontal}>
        {label}
        <SemanticLabel
          className={scssStyles.valueLabel}
          style={getLabelStyle(1)}
        >
          {String(value.type)}
        </SemanticLabel>
      </div>
    );
  }
  return label;
};
