const FONT_FAMILY = 'Source Sans Pro';
const CONTENT_BODY_FONT_SIZE = '1rem';
const CONTENT_BODY_FONT_WEIGHT = 400;

// Matching Semantic UI's negative / positive color scheme
const NEGATIVE_COLOR = '#db2828';
const NOT_SO_NEGATIVE_COLOR = '#5e5e5e';
// const NEGATIVE_COLOR_HOVER = '#d01919';
const POSITIVE_COLOR = '#21ba45';
// const POSITIVE_COLOR_HOVER = '#16ab39';
const NOT_SO_POSITIVE_COLOR = '#5a4970';
const TAG_COLOR = '#43425D';
const TAG_HOVER_COLOR = '#8685b5';
const AGENT_COLOR = 'green';

export const UNIT21_COLORS = Object.freeze({
  UNIT21_CORAL: '#015B75',
  UNIT21_INDIGO: '#43425D',
  UNIT21_ARCTIC_BLUE: '#3FB6C3',
  UNIT21_BITTERSWEET: '#FF6258',
  UNIT21_CARD_BACKGROUND: '#f9fafb',
  UNIT21_BLUE: '#052F5F',
  UNIT21_BEAN_RED: '#F46059',
});

const ALERT_GRAPH_COLORS = Object.freeze([
  '#1B998B', // UNIT21_AQUA:
  '#FF9F1C', // UNIT21_YELLOW
  '#052F5F', // UNIT21_BLUE
  '#5F0F40', // UNIT21_PURPLE
]);

export const StyleConstants = Object.freeze({
  POSITIVE_COLOR,
  NOT_SO_POSITIVE_COLOR,
  NEGATIVE_COLOR,
  NOT_SO_NEGATIVE_COLOR,
  TAG_COLOR,
  TAG_HOVER_COLOR,
  AGENT_COLOR,
  INFO_COLOR: UNIT21_COLORS.UNIT21_ARCTIC_BLUE,

  TOPBAR_HEIGHT: 72,
  TOPBAR_COLOR: '#43425D',

  SIDEBAR_THICK_WIDTH: 200,
  SIDEBAR_NEW_STYLE_THICK_WIDTH: 180,
  SIDEBAR_THIN_WIDTH: 60,

  SIDEBAR_COLOR_SELECTED: '#F46059', // BEAN RED
  SIDEBAR_BG_COLOR_SELECTED: 'rgba(244, 96, 89, 0.08)',
  SIDEBAR_BG_COLOR: '#FFF', // WHITE
  SIDEBAR_COLOR: '#637381', // PALE SKY

  FONT_FAMILY,

  FONT_SIZE_CONTAINER_PLACEHOLDER: 72,
  FONT_SIZE_LABEL: '0.8rem',

  CONTENT_HEADER_FONT_SIZE: '2rem',
  CONTENT_LIGHT_FONT_WEIGHT: 300,
  CONTENT_REGULAR_FONT_WEIGHT: 400,
  CONTENT_BOLD_FONT_WEIGHT: 700,

  CONTENT_BODY_FONT_SIZE,
  CONTENT_BODY_FONT_WEIGHT,

  MENTION_DROPDOWN: '#1d9bd1',
  SYNCHRONOUS_LABEL: '#35BAF6',
  EXTERNAL_OBJECT: '#E5989B',

  ...UNIT21_COLORS,

  ALERT_GRAPH_COLORS: [...ALERT_GRAPH_COLORS],

  STATUS: Object.freeze({
    OPEN: POSITIVE_COLOR,
    CLOSED: NEGATIVE_COLOR,
    IN_REVIEW: NOT_SO_POSITIVE_COLOR,
  }),

  SOURCE: Object.freeze({
    INTERNAL: UNIT21_COLORS.UNIT21_BLUE,
    EXTERNAL: 'grey',
  }),

  ALERT_RESOLUTION: Object.freeze({
    TRUE_POSITIVE: POSITIVE_COLOR,
    FALSE_POSITIVE: NEGATIVE_COLOR,
    UNRESOLVED: UNIT21_COLORS.UNIT21_BLUE,
    CLOSED: 'grey',
  }),

  RULE_STATUS: Object.freeze({
    ACTIVE: POSITIVE_COLOR,
    INACTIVE: NEGATIVE_COLOR,
    SANDBOX: TAG_COLOR,
  }),

  HALF_COLUMN_SEGMENT: Object.freeze({
    width: '50%',
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'stretch',
  }),

  FORM_FIELDS_GRID: Object.freeze({
    paddingBottom: '14px',
  }),

  SUB_TITLE: Object.freeze({
    fontFamily: FONT_FAMILY,
    fontSize: CONTENT_BODY_FONT_SIZE,
    fontWeight: CONTENT_BODY_FONT_WEIGHT,
    marginBottom: '5px',
  }),

  FULL_WIDTH: Object.freeze({
    width: 100,
  }),
});
