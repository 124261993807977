import { createAction, ActionsUnion } from 'app/shared/utils/action-helpers';
import { ToastActionTypes, ToastStateObject } from 'app/shared/toasts/models';

export const sendToast = ({
  message,
  type = 'Success',
  position = 'Bottom',
  cta,
}: ToastStateObject) => {
  return createAction(ToastActionTypes.SEND_TOAST, {
    message,
    type,
    position,
    cta,
  });
};

export const sendErrorToast = (message: string) => {
  const payload: ToastStateObject = {
    message,
    type: 'Error',
    position: 'Top',
  };
  return createAction(ToastActionTypes.SEND_TOAST, payload);
};

export const sendSuccessToast = (
  message: string,
  cta?: ToastStateObject['cta'],
) => {
  const payload: ToastStateObject = {
    message,
    cta,
    type: 'Success',
    position: 'Bottom',
  };
  return createAction(ToastActionTypes.SEND_TOAST, payload);
};

export const sendInfoToast = (message: string) => {
  const payload: ToastStateObject = {
    message,
    type: 'Info',
    position: 'Top',
  };
  return createAction(ToastActionTypes.SEND_TOAST, payload);
};

export const clearToast = (toastMessage: string) =>
  createAction(ToastActionTypes.CLEAR_TOAST, toastMessage);

const ToastsActionsList = {
  sendToast,
  sendErrorToast,
  sendSuccessToast,
  clearToast,
};

export type ToastsActions = ActionsUnion<typeof ToastsActionsList>;
