import { Dispatch } from 'redux';

import {
  createAsyncThunk,
  AsyncThunkPayloadCreator,
  AsyncThunkOptions,
  AsyncThunk,
} from '@reduxjs/toolkit';
import { consoleWarn } from 'app/shared/utils/console';

// Helpers
import { isFourOhFourRoute } from 'app/shared/sagas/helpers';
import { history } from 'app/store/browserHistory';
import isErrorStatus from 'app/shared/utils/is-error-status/is-error-status';
import routes from 'app/shared/utils/routes';

// copied over from redux toolkit since it's not exported
type AsyncThunkConfig = {
  state?: unknown;
  dispatch?: Dispatch;
  extra?: unknown;
  rejectValue?: unknown;
  serializedErrorType?: unknown;
  pendingMeta?: unknown;
  fulfilledMeta?: unknown;
  rejectedMeta?: unknown;
};

export function u21CreateAsyncThunk<
  ThunkArg = void,
  Returned = void,
  ThunkApiConfig extends AsyncThunkConfig = { state: RootState },
>(
  typePrefix: string,
  payloadCreator: AsyncThunkPayloadCreator<Returned, ThunkArg, ThunkApiConfig>,
  options?: AsyncThunkOptions<ThunkArg, ThunkApiConfig>,
): AsyncThunk<Returned, ThunkArg, ThunkApiConfig> {
  const u21PayloadCreator = async (arg, thunkAPI) => {
    try {
      // Pending: do some stuff before async call
      // Fulfilled: do some stuff after successful async call
      return await payloadCreator(arg, thunkAPI);
    } catch (err) {
      // Rejected: do some stuff after failed async call
      consoleWarn(err);
      if (isErrorStatus(err.status) && isFourOhFourRoute(typePrefix)) {
        history.push(routes.lumos.fourOhfour);
      }
      return thunkAPI.rejectWithValue(err);
    }
  };
  return createAsyncThunk<Returned, ThunkArg, ThunkApiConfig>(
    typePrefix,
    u21PayloadCreator,
    options,
  );
}
