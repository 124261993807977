import {
  GA_BANNER_FEATURE_DISABLED_LS_PREFIX,
  FEATURE_CURRENT_VERSION,
  GaFeatureTypes,
} from 'app/shared/components/GaBanner/constants';

export const getFeatureDisabledLSKey = (
  featureName: GaFeatureTypes,
  version?: number,
) => {
  if (version === undefined) {
    return `${GA_BANNER_FEATURE_DISABLED_LS_PREFIX}${featureName}_${FEATURE_CURRENT_VERSION[featureName]}`;
  }
  return `${GA_BANNER_FEATURE_DISABLED_LS_PREFIX}${featureName}_${version}`;
};

// Cleanup - clean local storage keys for old versions
(() => {
  const objectEntries = Object.entries(FEATURE_CURRENT_VERSION) as [
    GaFeatureTypes,
    number,
  ][];
  objectEntries.forEach(([feature, curVersion]) => {
    for (let verToRemove = 1; verToRemove < curVersion; verToRemove++) {
      localStorage.removeItem(getFeatureDisabledLSKey(feature, verToRemove));
    }
  });
})();
