import { IconProps } from 'app/shared/components/Icons/models';

export const IconFlagArrow = ({
  size = 20,
  color = 'currentColor',
  stroke = 2,
  ...props
}: IconProps) => (
  <svg
    fill="none"
    height={size}
    width={size}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={stroke}
      {...props}
    >
      <path d="m5 5v16" />
      <path d="m19 5v7" />
      <path d="m5 5.00009c.93464-.91614 2.19124-1.42929 3.5-1.42929s2.5654.51315 3.5 1.42929c.9346.91613 2.1912 1.42928 3.5 1.42928s2.5654-.51315 3.5-1.42928" />
      <path d="m5 14.0001c.93464-.9161 2.19124-1.4293 3.5-1.4293s2.5654.5132 3.5 1.4293" />
      <path d="m14 19h7m0 0-3-3m3 3-3 3" />
    </g>
  </svg>
);
