import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Actions
import {
  editArticleDeadline as editArticleDeadlineAction,
  editArticleDeadlineSuccess,
} from 'app/modules/deadlines/actions';

// API
import { editArticleDeadline as editArticleDeadlineAPI } from 'app/shared/api/deadlines';

// Models
import { DeadlinesActionTypes } from 'app/modules/deadlines/models';

const rootAction = DeadlinesActionTypes.EDIT_ARTICLE_DEADLINE;
function* editArticleDeadline({
  payload,
}: ReturnType<typeof editArticleDeadlineAction>) {
  const config = {
    rootAction,
    errorToastMessage: 'Unable to edit deadline.',
    successToastMessage: 'Deadline edited successfully',
    request: call(editArticleDeadlineAPI, payload),
    success: function* onSuccess(
      result: ReturnType<typeof editArticleDeadlineSuccess>['payload'],
    ) {
      yield put(editArticleDeadlineSuccess(result));
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watchEditArticleDeadline() {
  yield takeLatest(rootAction, editArticleDeadline);
}
