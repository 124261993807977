// Models
import { CommentsActionTypes, CommentModel } from 'app/modules/comments/models';
import { createAction, ActionsUnion } from 'app/shared/utils/action-helpers';

export const retrieveComments = (articleId: number) =>
  createAction(CommentsActionTypes.RETRIEVE_COMMENTS, articleId);

export const retrieveCommentsSuccess = (result: CommentModel[]) =>
  createAction(CommentsActionTypes.RETRIEVE_COMMENTS_SUCCESS, result);

export const setComments = (comments: CommentModel[]) =>
  createAction(CommentsActionTypes.SET_COMMENTS, comments);

export const resetComments = () =>
  createAction(CommentsActionTypes.RESET_COMMENTS);

export const addComment = (articleId, details) => {
  return createAction(CommentsActionTypes.ADD_COMMENT, { articleId, details });
};

export const addCommentSuccess = () =>
  createAction(CommentsActionTypes.ADD_COMMENT_SUCCESS);

const CommentsActionsList = {
  retrieveComments,
  retrieveCommentsSuccess,
  setComments,
  resetComments,
  addComment,
  addCommentSuccess,
};

export type CommentsActions = ActionsUnion<typeof CommentsActionsList>;
