import { Fragment, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { retrieveSubdispositions } from 'app/modules/workflows/actions';
import { selectSubdispositions } from 'app/modules/workflows/selectors';
import {
  FILTER_OPERATOR,
  FILTER_TYPE_OPERATOR_LABELS,
} from 'app/modules/filters/constants';

interface Props {
  ids: number[];
  operator:
    | typeof FILTER_OPERATOR.IS_ONE_OF
    | typeof FILTER_OPERATOR.IS_NOT_ONE_OF;
}

export const SubdispositionFilterDisplay = (props: Props) => {
  const { ids, operator } = props;

  const dispatch = useDispatch();
  const subdispositions = useSelector(selectSubdispositions);

  useEffect(() => {
    dispatch(retrieveSubdispositions());
  }, [dispatch]);

  const value = useMemo(() => {
    const subdispositionIDTextMap = subdispositions.reduce((acc, i) => {
      i.options.forEach((j) => {
        acc[j.id] = `${i.title}:${j.name}`;
      });
      return acc;
    }, {});
    return ids.map((i, idx) => {
      return (
        <Fragment key={i}>
          {idx !== 0 ? ', ' : ''}
          <b>{subdispositionIDTextMap[i] || i}</b>
        </Fragment>
      );
    });
  }, [ids, subdispositions]);

  return (
    <>
      <b>Subdisposition</b> {FILTER_TYPE_OPERATOR_LABELS[operator]}&nbsp;
      {value}
    </>
  );
};
