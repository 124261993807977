import { FullTagResponse } from 'app/modules/tags/responses';
import {
  ShortTeamResponse,
  FullTeamResponse,
} from 'app/modules/teams/responses';

import { AgentStatus, Auth0AgentStatsModel } from 'app/modules/agents/models';

type TeamsPermissionsMap = {
  [key: string]: boolean;
} & {
  alert_assignment_limit?: number;
};

export interface ShortAgentResponse {
  can_impersonate: boolean;
  created_at: string | null; // Returns null if the column value is null
  email: string;
  full_name: string;
  id: number;
  is_reviewer: boolean | null;
  org_id: number;
  picture: string;
  recently_merged: false | any; // Has `false` as default value but is untyped
  status: `${AgentStatus}`;
  tags: FullTagResponse[];
  teams: ShortTeamResponse[];
  is_default_login: boolean;
  default_org_display_name: string;
  default_org_id: number;
  teams_permissions: TeamsPermissionsMap;
  accessible_queues: number[];
}

export interface FullAgentResponse extends ShortAgentResponse {
  auth0_stats?: Auth0AgentStatsModel[];
  has_valid_auth?: [true];
  org_display_name?: string;
  org_name?: string;
  teams: FullTeamResponse[];
}

export type CreateAgentResponse = Omit<
  ShortAgentResponse,
  'default_org_display_name' | 'default_org_id' | 'is_default_login'
>;

export interface PaginatedAgentsResponse {
  count: number;
  agents: ShortAgentResponse[];
}

export enum NotificationEventGroups {
  ALERTS = 'alerts',
  CASES = 'cases',
  DEADLINES = 'deadlines',
  GENERAL = 'general',
  MISC = 'miscellaneous',
  RULES = 'rules',
  SARS = 'sars',
  GLOBAL_TABLE_SETTINGS = 'global_data_settings',
  FFIP_NOTIFICATION = 'ffip_notifications',
}

export enum NotificationEventTypes {
  DAILY_DIGEST = 'DAILY_DIGEST',
}

export type AgentNotificationSettingResponse = {
  id: number | null;
  event_type: `${NotificationEventTypes}` | string;
  name: string;
  description: string;
  event_group: `${NotificationEventGroups}`;
  notification_methods: {
    email: boolean;
    slack?: boolean;
    system: boolean;
  };
};

interface SlackNotificationSetting {
  access_token: string;
  agent_id: number;
  bot_access_token: string;
  bot_user_id: string;
  channel: string;
  channel_id: string;
  configuration_url: string;
  created_at: string;
  id: number;
  org_name: string;
  scope: string;
  team_id: string;
  team_name: string;
  type: string;
  unit_name: string;
  url: string;
  user_id: string;
}

export interface SlackNotificationSettingResponse {
  group_slack_meta: SlackNotificationSetting | Record<string, never>;
  individual_slack_meta: SlackNotificationSetting | Record<string, never>;
}
