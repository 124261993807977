// Types
import { AlertType } from 'app/modules/alerts/types';

// Models
import { SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';
import { AlertStatus } from 'app/modules/alerts/models';
import { PaginationPayload } from 'app/shared/pagination/models';
import { CaseStatus } from 'app/modules/casesOld/models';
import { ShortAgentResponse } from 'app/modules/agentsOld/types';
import {
  CreateCaseAlertButtons,
  CreateSarButtons,
  QueueAgentAssignmentButtons,
} from 'app/modules/workflows/models';
import { ColorColorSchema } from 'vendor/material-minimal/palette';
import { IconOptions } from 'app/modules/workflows/constants';

export enum WebhookActionTypes {
  LIST_WEBHOOKS = '@@webhooks/LIST_WEBHOOKS',
  LIST_WEBHOOKS_SUCCESS = '@@webhooks/LIST_WEBHOOKS_SUCCESS',

  CREATE_WEBHOOK = '@@webhooks/CREATE_WEBHOOK',
  CREATE_WEBHOOK_SUCCESS = '@@webhooks/CREATE_WEBHOOK_SUCCESS',

  GET_WEBHOOK = '@@webhooks/GET_WEBHOOK',
  GET_WEBHOOK_SUCCESS = '@@webhooks/GET_WEBHOOK_SUCCESS',

  EDIT_WEBHOOK = '@@webhooks/EDIT_WEBHOOK',
  EDIT_WEBHOOK_SUCCESS = '@@webhooks/EDIT_WEBHOOK_SUCCESS',

  DELETE_WEBHOOK = '@@webhooks/DELETE_WEBHOOK',
  DELETE_WEBHOOK_SUCCESS = '@@webhooks/DELETE_WEBHOOK_SUCCESS',

  RETRIEVE_ACTION_TYPE = '@@webhooks/RETRIEVE_ACTION_TYPE',
  RETRIEVE_ACTION_TYPE_SUCCESS = '@@webhooks/RETRIEVE_ACTION_TYPE_SUCCESS',

  ROTATE_WEBHOOK_KEY = '@@webhooks/ROTATE_WEBHOOK_KEY',
  ROTATE_WEBHOOK_KEY_SUCCESS = '@@webhooks/ROTATE_WEBHOOK_KEY_SUCCESS',

  GET_WEBHOOK_CALLBACKS = '@@webhooks/GET_WEBHOOK_CALLBACKS',
  GET_WEBHOOK_CALLBACKS_SUCCESS = '@@webhooks/GET_WEBHOOK_CALLBACKS_SUCCESS',

  TEST_WEBHOOK = '@@webhooks/TEST_WEBHOOK',
  TEST_WEBHOOK_SUCCESS = '@@webhooks/TEST_WEBHOOK_SUCCESS',
}

export type SelectedDispositionActions = {
  group_id: number;
  selected_options: {
    entity_external_id: string;
    option_id: number;
  }[];
  apply_to_all_option_id: number | null;
};

export interface ActionTriggerOnTrigger {
  disposition: string | null;
  subdispositions: number[];
  dispositionActions?: SelectedDispositionActions[];
  notes: string;
  tagsToAdd: number[];
  tagsToRemove: number[];
  whitelistDuration?: number | null;
  reassignmentAgentId: number | null;
  reassignmentTeamId: number | null;
  id: number;
}

export interface Webhook {
  id: number;
  url: string;
  secret_key: string;
  action_types: string[];
  is_active: boolean;
  org_name: string;
  created_at: string;
  created_by?: ShortAgentResponse;
}

export interface WebhookCallbackRequestBody {
  change?: string;
  change_time?: number;
  changed_by?: string;
  created_by?: string;
  description?: string;
  end_date?: number | null;
  entities?: number[];
  events?: number[];
  id?: number;
  instruments?: number[];
  object_type?: string;
  start_date?: number | null;
  tags?: string[];
  title?: string;
  watchers?: string[];
}
export interface WebhookCallback {
  id: number;
  webhook_id: number;
  attempted_at: string;
  org_name: string;
  request_body: WebhookCallbackRequestBody;
  status_code: number | string;
  action_type: string;
  url: string;
  retries: string;
}

export interface WebhookCallbackRequest extends PaginationPayload {
  id: number;
}

export interface WebhookCallbackResponse {
  count: number;
  webhook_callback_attempts: WebhookCallback[];
}

export interface WebhookState {
  webhooks: Webhook[];
  webhook: Webhook;
  paginatedWebhookAttempts: WebhookCallbackResponse;
  actionTypes: string[];
}

export enum ActionTriggerContexts {
  ENTITY = 'ENTITY',
  ALERT = 'ALERT',
  ALERT_COMPONENT = 'ALERT_COMPONENT',
  RULE = 'RULE',
  CASE = 'CASE',
  EVENT = 'EVENT',
}

export type ReassignmentType =
  | 'NONE'
  | 'DYNAMIC'
  | 'STATIC'
  | 'RANDOM'
  | 'DYNAMIC_TEAM'
  | 'STATIC_TEAM'
  | 'STATIC_QUEUE';

export interface AlertActionTriggerConfig {
  trigger_webhook: boolean;
  close_alert: boolean;
  open_alert: boolean;
  show_on_alert_status: AlertStatus | null;
  alert_type: AlertType | null;
  reassignment_type: ReassignmentType;
  requeue_agent_assignment: QueueAgentAssignmentButtons | null;
  reassignment_agent_id: number | null; // used only if reassignment_type === 'STATIC'
  reassignment_team_id: number | null; // used only if reassignment_type === 'STATIC_TEAM'
  reassignment_queue_id: number | null;
  enable_if_invalid: boolean;
  remove_tags_from_children: boolean;
  apply_tags_to_children: boolean;
  enabled_for_bulk_resolutions: boolean;
  create_case: boolean;
  create_case_alert: CreateCaseAlertButtons;
  create_sar: boolean;
  create_fincen_ctr: boolean;
}

export interface AlertComponentActionTriggerConfig {
  resolution: string | null;
}

export interface CaseActionTriggerConfig {
  close_case: boolean;
  open_case: boolean;
  show_on_case_status: CaseStatus | null;
  case_type: string | null;
  create_filing: CreateSarButtons | null;
  reassignment_type: ReassignmentType;
  requeue_agent_assignment: QueueAgentAssignmentButtons | null;
  reassignment_agent_id: number | null; // used only if reassignment_type === 'STATIC'
  reassignment_team_id: number | null; // used only if reassignment_type === 'STATIC_TEAM'
  reassignment_queue_id: number | null;
  enable_if_invalid: boolean;
  remove_tags_from_children: boolean;
  apply_tags_to_children: boolean;
  enabled_for_bulk_resolutions: boolean;
}

export interface EntityActionTriggerConfig {}

export interface EventActionTriggerConfig {}

export type DispositionNotes = 'DISABLED' | 'ALLOWED' | 'REQUIRED';

export type WhitelistType =
  | 'DISABLED'
  | 'GLOBAL'
  | 'RULES'
  | 'GLOBAL_RULES'
  | 'GLOBAL_REQUIRED'
  | 'RULES_REQUIRED'
  | 'GLOBAL_RULES_REQUIRED';

export interface SubdispositionOption {
  id?: number;
  name: string;
  title?: string;
}

export interface Subdisposition {
  id: number;
  title: string;
  newOption: string;
  included?: boolean;
  options: SubdispositionOption[];
}

export interface DispositionActionOption {
  id: number;
  name: string;
}

export interface DispositionAction {
  group_id: number;
  group_name: string;
  options: DispositionActionOption[];
}

export interface SubdispositionValues {
  [key: number]: number;
}

export interface ActionTriggerModel {
  id: number;
  created_at: string;
  label: string;
  context: ActionTriggerContexts;
  tags: number[];
  applyToChildren?: boolean;
  tags_to_remove: number[];
  removeFromChildren?: boolean;
  teams: number[];
  display_tags: number[];
  masking_tags: number[];
  disposition_actions?: DispositionAction[];
  subdispositions: Subdisposition[];
  disposition: string | null;
  notes: DispositionNotes;
  color: ColorColorSchema;
  description: string;
  external_id: string;
  is_active: boolean;
  icon: IconOptions;
  whitelist_type: WhitelistType;
  whitelist_durations?: WhitelistTimeDurationModel[];

  config:
    | AlertActionTriggerConfig
    | AlertComponentActionTriggerConfig
    | CaseActionTriggerConfig
    | EntityActionTriggerConfig
    | EventActionTriggerConfig
    | null;
}

export interface AlertActionTriggerModel extends ActionTriggerModel {
  config: AlertActionTriggerConfig;
  queues: number[];
}

export interface AlertComponentActionTriggerModel
  extends Omit<ActionTriggerModel, 'icon'> {
  config: AlertComponentActionTriggerConfig;
  icon: SemanticICONS;
}

export interface CaseActionTriggerModel extends ActionTriggerModel {
  config: CaseActionTriggerConfig;
  queues: number[];
}

export interface TriggerActionPayload {
  actionId: number;
  resourceId: number;
  resourceExternalId?: string;
  disposition?: string;
  subdispositions?: number[];
  disposition_actions?: SelectedDispositionActions[];
  notes?: string;
  tags?: number[];
  tagsToRemove?: number[];
  reassignment_agent_id?: number;
  reassignment_team_id?: number;
  whitelist_duration?: number | null;
}

export interface CreateWebhookRequest {
  url: string;
  action_types: string[];
}

export interface EditWebhookRequest {
  id: number;
  is_active?: boolean;
  action_types?: string[];
  url?: string;
}

export type WhitelistTimeUnit =
  | 'days'
  | 'weeks'
  | 'months'
  | 'years'
  | 'indefinitely';

export interface WhitelistTimeDurationModel {
  duration: string;
  unit: WhitelistTimeUnit | null;
}
