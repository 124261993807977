import {
  isValidElement,
  HTMLProps,
  ReactElement,
  ReactNode,
  RefObject,
  SyntheticEvent,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';

// Components
import { IconChevronDown, IconChevronUp } from '@u21/tabler-icons';

import { U21Divider } from 'app/shared/u21-ui/components/display/U21Divider';
import { U21MenuLayout } from 'app/shared/u21-ui/components/layout/U21MenuLayout';

import {
  U21MenuItem,
  U21MenuItemProps,
} from 'app/shared/u21-ui/components/display/U21MenuItem';
import {
  U21Button,
  U21ButtonProps,
} from 'app/shared/u21-ui/components/input/U21Button';

// Utils
import { getDOMProps } from 'app/shared/utils/react';

export type U21MenuButtonItems = (U21MenuItemProps | ReactElement | false)[];

export interface U21MenuButtonProps extends HTMLProps<HTMLButtonElement> {
  alignRight?: boolean;
  buttonProps?: Omit<U21ButtonProps, 'children'>;
  children?: ReactNode;
  items: U21MenuButtonItems;
  onClose?: (e: SyntheticEvent) => void;
  showArrows?: boolean;
  backdropRef?: RefObject<HTMLDivElement>;
  menuRef?: RefObject<HTMLDivElement>;
}

export const U21MenuButton = ({
  alignRight = false,
  buttonProps,
  children,
  items,
  onClose,
  showArrows = false,
  backdropRef,
  menuRef,
  ...rest
}: U21MenuButtonProps) => {
  const ref = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);
  const itemsToRender = items.filter(Boolean) as (
    | U21MenuItemProps
    | ReactElement
  )[];

  if (!itemsToRender.length) {
    return null;
  }

  return (
    <U21MenuLayout
      alignRight={alignRight}
      backdropRef={backdropRef}
      menuRef={menuRef}
      onClose={(e) => {
        onClose?.(e);
        setOpen(false);
      }}
      open={open}
      trigger={
        <U21Button
          ref={ref}
          {...(showArrows && {
            endIcon: open ? <IconChevronUp /> : <IconChevronDown />,
          })}
          {...buttonProps}
          onClick={(e) => {
            buttonProps?.onClick?.(e);
            setOpen(!open);
          }}
          {...getDOMProps(rest)}
        >
          {children}
        </U21Button>
      }
    >
      {itemsToRender.map((i) => {
        if (isValidElement<any>(i)) {
          return i;
        }
        const { key, ...restItem } = i;
        return (
          <U21MenuItem
            key={i.key || (i.text as string)}
            alignRight={alignRight}
            item={restItem}
            onClose={() => setOpen(false)}
          />
        );
      })}
    </U21MenuLayout>
  );
};

const StyledDivider = styled(U21Divider).attrs({ horizontal: true })`
  margin: 8px 0;
`;

U21MenuButton.Divider = StyledDivider;
