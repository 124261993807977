import { U21TableProps } from 'app/shared/u21-ui/components/display/table/models';

import { EMPTY_COUNT } from 'app/shared/u21-ui/components/display/table/constants';

import numeral from 'numeral';
import pluralize from 'pluralize';
import styled from 'styled-components';

import { Collapse } from '@mui/material';
import { IconChecks } from '@u21/tabler-icons';
import { U21Button } from 'app/shared/u21-ui/components/input/U21Button';
import { U21Spacer } from 'app/shared/u21-ui/components/layout/U21Spacer';
import { U21Typography } from 'app/shared/u21-ui/components/display/typography/U21Typography';

interface Props<T extends object, F> {
  userProps: U21TableProps<T, F>;
}

export const U21TableActions = <T extends object, F>(props: Props<T, F>) => {
  const { userProps } = props;
  const {
    actions = [],
    data,
    count = data.length,
    label = 'item',
    selectable,
    selected = [],
    selectAllButtonPressed,
  } = userProps;

  const selectedCount = selectAllButtonPressed ? count : selected.length;

  return (
    <Collapse
      in={selectable && (selected.length > 0 || selectAllButtonPressed)}
      mountOnEnter
      unmountOnExit
    >
      <ActionsContainer horizontal spacing={2}>
        <U21Typography variant="subtitle2" icon={<IconChecks />}>{`${
          selectedCount === EMPTY_COUNT
            ? 'All'
            : numeral(selectedCount).format()
        } ${pluralize(label, selectedCount)} selected${
          actions.length ? ':' : ''
        }`}</U21Typography>
        {actions.map((i) => {
          const {
            icon,
            label: actionLabel,
            onClick,
            color,
            loading,
            disabled,
            tooltip,
            variant,
          } = i;
          return (
            <U21Button
              key={actionLabel}
              onClick={onClick}
              startIcon={icon}
              color={color}
              loading={loading}
              disabled={disabled}
              tooltip={tooltip}
              variant={variant}
            >
              {actionLabel}
            </U21Button>
          );
        })}
      </ActionsContainer>
    </Collapse>
  );
};

const ActionsContainer = styled(U21Spacer)`
  margin-bottom: 8px;
`;
