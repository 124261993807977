import { call, takeLatest, put } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { SessionActionTypes } from 'app/modules/session/models';

// API
import { emailTokenExchange as emailTokenExchangeEndpoint } from 'app/shared/api/session';

// Action
import {
  emailTokenExchange as emailTokenExchangeAction,
  startSession,
} from 'app/modules/session/actions';

// Utils
import routes from 'app/shared/utils/routes';
import { history } from 'app/store/browserHistory';

const rootAction = SessionActionTypes.EMAIL_TOKEN_EXCHANGE;
function* emailTokenExchange({
  payload,
}: ReturnType<typeof emailTokenExchangeAction>) {
  const { token, auth } = payload;

  yield call(makeSagaRequest, {
    rootAction,
    request: call(emailTokenExchangeEndpoint, { token }),
    success: function* onSuccess(result) {
      if (auth) {
        auth.loginSuccess(result);
        yield put(startSession(auth));
        history.push(routes.lumos.home);
      }
    },
  });
}

export default function* emailTokenExchangeSaga() {
  yield takeLatest(rootAction, emailTokenExchange);
}
