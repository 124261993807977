// Based on the following implementation
// https://github.com/gargroh/react-table/blob/0d3074a548bb9aba9eb1dfb5b7752e1918d582c9/src/plugin-hooks/useColumnPin.js
import { PinType } from 'app/shared/u21-ui/components/display/table/hooks/models';

import {
  actions,
  ColumnInstance,
  defaultColumn,
  functionalUpdate,
  TableInstance,
} from 'react-table';
import { useCallback } from 'react';

// Actions
actions.resetColumnPin = 'resetColumnPin';
actions.setColumnPin = 'setColumnPin';

export enum ColumnPins {
  LEFT = 'LEFT',
  CENTER = 'CENTER',
  RIGHT = 'RIGHT',
}

defaultColumn.pinType = ColumnPins.CENTER;

export const reducer = (state, action, previousState, instance) => {
  switch (action.type) {
    case actions.init:
      return {
        columnPin: {},
        ...state,
      };
    case actions.resetColumnPin:
      return {
        ...state,
        columnPin: instance.initialState.columnPin || {},
      };
    case actions.setColumnPin:
      return {
        ...state,
        columnPin: functionalUpdate(action.columnPin, state.columnPin),
      };
    default:
      return state;
  }
};

export const getColumnPinInstanceData = (columns: ColumnInstance[]) => {
  const pinMap: Record<string, ColumnInstance[]> = {
    [ColumnPins.LEFT]: [],
    [ColumnPins.CENTER]: [],
    [ColumnPins.RIGHT]: [],
  };

  columns.forEach((col) => {
    pinMap[col.pinType].push(col);
  });

  const leftCount = pinMap[ColumnPins.LEFT].length;

  return {
    lastLeftPinnedColumn: pinMap[ColumnPins.LEFT][leftCount - 1]?.id,
    firstRightPinnedColumn: pinMap[ColumnPins.RIGHT][0]?.id,
  };
};

const useInstance = (instance: TableInstance) => {
  const {
    dispatch,
    allColumns,
    disablePinning,
    state: { columnPin },
  } = instance;

  const setColumnPin = useCallback(
    (newColumnPin) => {
      return dispatch({ type: actions.setColumnPin, columnPin: newColumnPin });
    },
    [dispatch],
  );

  allColumns.forEach((column) => {
    const { disablePinning: columnDisablePinning } = column;
    // eslint-disable-next-line no-param-reassign
    column.canPin = !(columnDisablePinning || disablePinning);
    // eslint-disable-next-line no-param-reassign
    column.pinType = columnPin[column.id] || ColumnPins.CENTER;
    // eslint-disable-next-line no-param-reassign
    column.setColumnPin = (pinType: PinType) => {
      // eslint-disable-next-line no-param-reassign
      column.pinType = pinType;
      setColumnPin({
        ...columnPin,
        [column.id]: pinType,
      });
    };

    // eslint-disable-next-line no-param-reassign
    column.toggleColumnPin = (pinType: PinType) => {
      const setPinValue =
        column.pinType === pinType ? ColumnPins.CENTER : pinType;
      column.setColumnPin(setPinValue);
    };
  });

  Object.assign(instance, {
    setColumnPin,
    ...getColumnPinInstanceData(allColumns),
  });
};

export const getCellStyles = (
  column: ColumnInstance,
  instance: TableInstance,
) => {
  const { pinType, totalLeft, totalWidth } = column;
  const { totalColumnsWidth } = instance;
  if (column.pinType === ColumnPins.CENTER) {
    return { position: 'static' };
  }
  return {
    position: 'sticky',
    left: pinType === ColumnPins.LEFT ? totalLeft : undefined,
    right:
      pinType === ColumnPins.RIGHT
        ? totalColumnsWidth - (totalLeft + totalWidth)
        : undefined,
    zIndex: 10,
  };
};

export const usePinColumns = (hooks) => {
  hooks.stateReducers.push(reducer);
  hooks.useInstance.push(useInstance);

  hooks.getHeaderProps.push((props, { column, instance }) => [
    props,
    { style: getCellStyles(column, instance) },
  ]);

  hooks.getCellProps.push((props, { cell, instance }) => [
    props,
    { style: getCellStyles(cell.column, instance) },
  ]);
};
usePinColumns.pluginName = 'usePinColumns';
