import { ReactNode, HTMLProps, isValidElement, useMemo, useState } from 'react';

// Components
import { Alert, Collapse } from '@mui/material';
import { IconX, IconChevronDown, IconChevronUp } from '@u21/tabler-icons';
import {
  U21Button,
  U21ButtonProps,
} from 'app/shared/u21-ui/components/input/U21Button';
import { U21Typography } from 'app/shared/u21-ui/components/display/typography/U21Typography';

// Utils
import { getDOMProps } from 'app/shared/utils/react';
import styled, { css } from 'styled-components';
import { useTheme } from '@mui/styles';
import { getA11yClickProps } from 'app/shared/utils/a11y';

export interface U21AlertProps
  extends Omit<HTMLProps<HTMLDivElement>, 'size' | 'title'> {
  children: ReactNode;
  closeButtonProps?: Omit<
    U21ButtonProps,
    'children' | 'color' | 'onClick' | 'small'
  >;
  collapsible?: boolean;
  defaultCollapsed?: boolean;
  hidden?: boolean;
  Icon?: (props: { size: number }) => ReactNode;
  onClose?: () => void;
  severity?: 'info' | 'success' | 'normal' | 'warning' | 'error';
  title?: ReactNode;
}

export const U21Alert = ({
  children,
  closeButtonProps,
  collapsible = false,
  defaultCollapsed = false,
  hidden = false,
  Icon,
  onClose,
  severity = 'error',
  title,
  ...rest
}: U21AlertProps) => {
  const theme = useTheme();
  const [collapsed, setCollapsed] = useState(defaultCollapsed);

  const displayTitle = useMemo(() => {
    if (!title) {
      return null;
    }
    const innerTitle = isValidElement(title) ? (
      title
    ) : (
      <U21Typography
        color={
          theme.palette.mode === 'light'
            ? `${severity}.darker`
            : `${severity}.lighter`
        }
        variant="subtitle2"
      >
        {title}
      </U21Typography>
    );
    if (collapsible) {
      return (
        <CollapsibleContainer
          {...getA11yClickProps(() => {
            setCollapsed((old) => !old);
          })}
        >
          <TitleContainer>{innerTitle}</TitleContainer>
          {collapsed ? <StyledIconChevronDown /> : <StyledIconChevronUp />}
        </CollapsibleContainer>
      );
    }
    return innerTitle;
  }, [collapsed, collapsible, severity, theme.palette.mode, title]);

  return (
    <Collapse in={!hidden} mountOnEnter unmountOnExit {...getDOMProps(rest)}>
      <StyledAlert
        $severity={severity}
        severity={severity === 'normal' ? 'info' : severity}
        icon={Icon ? <Icon size={24} /> : undefined}
        action={
          onClose && (
            <U21Button
              onClick={onClose}
              color={severity === 'normal' ? 'inherit' : severity}
              size="small"
              aria-label="close"
              {...closeButtonProps}
            >
              <IconX />
            </U21Button>
          )
        }
      >
        {displayTitle}
        <Collapse in={!collapsed} mountOnEnter unmountOnExit>
          {children}
        </Collapse>
      </StyledAlert>
    </Collapse>
  );
};

const StyledAlert = styled(Alert)<{ $severity: U21AlertProps['severity'] }>`
  ${(props) => {
    if (props.$severity === 'normal') {
      return css`
        background-color: ${props.theme.palette.background.neutral};
      `;
    }

    return css``;
  }}

  .MuiAlert-icon {
    color: ${(props) =>
      props.$severity === 'normal'
        ? props.theme.palette.text.primary
        : props.theme.palette[props.$severity!].main};
  }
  .MuiAlert-message {
    flex: 1;
    ${(props) => {
      if (props.$severity === 'normal') {
        return css`
          color: ${props.theme.palette.text.primary};
        `;
      }

      return css``;
    }}
  }
`;

const CollapsibleContainer = styled.div`
  cursor: pointer;
  display: flex;
`;

const TitleContainer = styled.div`
  flex: 1;
  overflow: hidden;
`;

const StyledIconChevronUp = styled(IconChevronUp)`
  margin-left: auto;
`;

const StyledIconChevronDown = styled(IconChevronDown)`
  margin-left: auto;
`;
