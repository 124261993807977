import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Actions
import {
  createWorkflow as createAction,
  createWorkflowSuccess,
} from 'app/modules/workflows/actions';
import { sendSuccessToast } from 'app/shared/toasts/actions';

// API
import { createWorkflow as createWorkflowAPI } from 'app/shared/api/workflows';

// Models
import { WorkflowActionTypes } from 'app/modules/workflows/models';

// Utils
import { getWorkflowButtonsListPath } from 'app/modules/workflows/helpers';
import { retrieveActionTriggers } from 'app/modules/session/actions';
import { history } from 'app/store/browserHistory';

const rootAction = WorkflowActionTypes.CREATE_WORKFLOW;
function* createWorkflow({ payload }: ReturnType<typeof createAction>) {
  const config = {
    rootAction,
    errorToastMessage: 'Something went wrong, unable to create a workflow.',
    request: call(createWorkflowAPI, payload),
    success: function* onSuccess({ id }) {
      yield put(createWorkflowSuccess());
      yield put(sendSuccessToast(`Workflow ${id} created successfully.`));
      yield put(retrieveActionTriggers());
      history.push(getWorkflowButtonsListPath(payload.context));
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watchCreateWorkflow() {
  yield takeLatest(rootAction, createWorkflow);
}
