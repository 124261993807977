import { FC, useState, HTMLProps, ReactNode } from 'react';

import { U21Modal } from 'app/shared/u21-ui/components/layout/modal/U21Modal';
import {
  U21Button,
  U21ButtonProps,
} from 'app/shared/u21-ui/components/input/U21Button';
import { IconInfoCircle, IconExternalLink } from '@u21/tabler-icons';

import { getDOMProps } from 'app/shared/utils/react';

export interface U21HelpModalProps extends HTMLProps<HTMLButtonElement> {
  children: ReactNode;
  docsLink?: string;
  title?: string;
  docsLinkProps?: Omit<U21ButtonProps, 'href' | 'to'>;
}

export const U21HelpModal: FC<U21HelpModalProps> = ({
  children,
  docsLink,
  docsLinkProps,
  title = 'How to Use This Feature',
  ...rest
}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <U21Button
        aria-label="Help modal"
        color="info"
        icon={<IconInfoCircle size={18} />}
        onClick={() => {
          setOpen(true);
        }}
        size="small"
        {...getDOMProps(rest)}
      />
      <U21Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        title={title}
        titleIcon={<IconInfoCircle />}
        closeButtonProps={{
          children: 'Close',
          color: 'primary',
        }}
        actionButtonProps={
          docsLink
            ? {
                endIcon: <IconExternalLink />,
                color: 'primary',
                href: docsLink,
                variant: 'contained',
                children: 'Go To Docs',
                ...docsLinkProps,
              }
            : undefined
        }
      >
        {children}
      </U21Modal>
    </>
  );
};
