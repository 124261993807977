import { useMemo } from 'react';

// Components
import { U21Chip, U21NoValue } from 'app/shared/u21-ui/components';
import { DeadlineTooltip } from 'app/modules/deadlines/components/DeadlineTooltip';

// Models
import { ArticleDeadline, DeadlinesConfig } from 'app/modules/deadlines/models';

// Utils
import { isPast, formatDistanceToNow } from 'date-fns';
import { getDeadlineChipStatus } from 'app/modules/deadlines/utils';

// Constants
import { CHIP_STATUS_TO_STYLE } from 'app/modules/deadlines/constants';

interface OwnProps {
  articleDeadline?: ArticleDeadline;
  deadlinesConfig?: DeadlinesConfig;
  closed?: boolean;
  onClick?: () => void;
}

export const DeadlineChip = ({
  articleDeadline,
  deadlinesConfig,
  closed,
  onClick,
}: OwnProps) => {
  const {
    deadline,
    show_warning: showWarning,
    duration,
  } = articleDeadline ?? {};
  const {
    title,
    calc_start_date: calcStartDate,
    duration: configDuration,
  } = deadlinesConfig ?? {};

  const ms: number | undefined = useMemo(
    () => (!closed && deadline && Date.parse(deadline)) || undefined,
    [deadline, closed],
  );

  const pastDue = useMemo(() => (ms && isPast(ms)) || undefined, [ms]);

  const text = useMemo(() => {
    if (ms) {
      const days = formatDistanceToNow(ms);
      return pastDue ? `${days} past due` : `${days} remaining`;
    }
    return '';
  }, [ms, pastDue]);

  const chipProps = useMemo(() => {
    return CHIP_STATUS_TO_STYLE[
      getDeadlineChipStatus(ms, pastDue, showWarning)
    ];
  }, [ms, pastDue, showWarning]);

  if (!ms) return <U21NoValue />;
  return (
    <DeadlineTooltip
      title={title}
      duration={duration}
      calcStartDate={calcStartDate}
      manualDueDate={configDuration !== duration}
    >
      <U21Chip {...chipProps} onClick={onClick}>
        {text}
      </U21Chip>
    </DeadlineTooltip>
  );
};
