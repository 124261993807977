import {
  call,
  put,
  select,
  takeLatest,
  all,
  SimpleEffect,
  PutEffectDescriptor,
} from 'redux-saga/effects';

// Actions
import {
  startSessionSuccess,
  retrieveIDP,
  startSessionError,
  retrieveActionTriggers,
  retrieveRelevantOrgs,
  startSession as startSessionAction,
  initIntercom,
} from 'app/modules/session/actions';
import { retrieveAllTags } from 'app/modules/teamsOld/actions';
import { retrieveOrgTableConfig } from 'app/shared/CustomConfig/actions';
import {
  getAgentTableSettingsThunk,
  getTableSettingsThunk,
} from 'app/modules/tableSettings/sliceTableSettings';

// Models
import { SessionActionTypes, SessionAgent } from 'app/modules/session/models';

// Api
import { login } from 'app/shared/api/session';

// Sagas
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Utils
import { consoleWarn } from 'app/shared/utils/console';
import { getIsImpersonating } from 'app/modules/session/helpers';
import { createSentryError, setSentryUser } from 'app/shared/utils/sentry';
import {
  HeapEventProperties,
  heapLogin,
  setHeapEventProperties,
} from 'app/shared/utils/heap';
import {
  getImpersonateId,
  getOriginalAgentId,
} from 'app/shared/utils/sessionStorage';

// Selectors
import { getOrgCustomDataSettingsConfigsThunk } from 'app/modules/dataSettings/sliceDataSettings';
import { retrieveSessionPermissionsThunk } from 'app/modules/session/sliceSession';
import { selectSessionAgentPermissions } from 'app/modules/session/selectors';
import { getSummaryViewConfigsThunk } from 'app/modules/summarySettings/sliceSummarySettings';
import { LocalStorageKeys } from 'app/shared/constants/localStorage';
import { NOT_ALLOWED_BACK_ROUTES } from 'app/shared/utils/routes';
import { retrieveOrgSettingsThunk } from 'app/modules/orgSettings/sliceOrgSettings';

const rootAction = SessionActionTypes.START_SESSION;
function* startSessionFlow({
  payload: auth,
}: ReturnType<typeof startSessionAction>) {
  if (!auth.isAuthenticated() || !auth.hasRefreshedToken) {
    const url = window.location.pathname;
    if (url && !/access_token|error/.test(url)) {
      if (!NOT_ALLOWED_BACK_ROUTES[url]) {
        localStorage.setItem(LocalStorageKeys.LAST_ROUTE, url);
      }
    }

    yield auth.login();
  }

  yield call(makeSagaRequest, {
    rootAction,
    request: call(login),
    success: function* onSuccess(result: SessionAgent) {
      if (result) {
        // Setting Sentry persona to track events back to users
        if (result.email) {
          setSentryUser(result.email);
        }

        // Heap tracking
        heapLogin({
          email: result.email,
          org: result.org_id,
          unit: result.unit_id,
          orgName: result.org_name,
        });
        setHeapEventProperties(
          HeapEventProperties.IS_IMPERSONATING,
          getIsImpersonating(),
        );

        // dispatch all of these redux actions in parallel
        const parallelActions: SimpleEffect<'PUT', PutEffectDescriptor<any>>[] =
          [];

        if (result.recently_merged) {
          // only Auth0 login
          yield auth.login();
        }

        const permissions: string[] = yield select(
          selectSessionAgentPermissions,
        );
        parallelActions.push(
          put(
            retrieveSessionPermissionsThunk({
              associationType: 'agent',
              associationId: result.id,
            }),
          ),
        );

        // retrieve new token from auth0 with new permissions if permissions are empty (happens when a user first signs up)
        if (!(permissions.length > 0)) {
          // if it's an impersonation, the person is already logged in
          const isImpersonation =
            getImpersonateId() !== -1 &&
            getImpersonateId() !== getOriginalAgentId();
          if (isImpersonation) {
            parallelActions.push(put(startSessionAction(auth)));
          } else if (auth.provider === 'auth0') {
            yield auth.login();
          }
        }

        parallelActions.push(put(startSessionSuccess(result)));

        // start IDP refresh looping
        const isGoogleAuth = result.auth_provider === 'google-oauth2';
        if (isGoogleAuth) {
          parallelActions.push(put(retrieveIDP()));
        }

        // schedule our check to test last activity which allows for logging out inactive users
        auth.scheduleCheckLastActivity();

        parallelActions.push(put(retrieveOrgSettingsThunk()));
        parallelActions.push(put(retrieveRelevantOrgs()));
        parallelActions.push(put(retrieveActionTriggers()));
        parallelActions.push(put(retrieveAllTags()));
        parallelActions.push(put(retrieveOrgTableConfig()));
        parallelActions.push(put(initIntercom()));
        parallelActions.push(put(getAgentTableSettingsThunk()));
        parallelActions.push(put(getTableSettingsThunk()));
        parallelActions.push(put(getOrgCustomDataSettingsConfigsThunk()));
        parallelActions.push(put(getSummaryViewConfigsThunk()));

        yield all(parallelActions);
      } else {
        const error =
          'Unexpected response from server for startSessionFlow saga';
        createSentryError({ error });
      }
    },
    error: function* onError(error) {
      consoleWarn('Init Session Error', error);
      // Assuming that session errors mean auth token is expired
      consoleWarn('Redirecting to login');
      yield put(startSessionError());
      yield auth.logout();
    },
  });
}

export default function* startSession() {
  yield takeLatest(rootAction, startSessionFlow);
}
