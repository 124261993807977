import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { PermissionsActionTypes } from 'app/modules/permissions/models';

// Api
import { retrieveUnassignedPermissions as api } from 'app/shared/api/permissions';

// Actions
import {
  retrieveUnassignedPermissions as action,
  retrieveUnassignedPermissionsSuccess,
} from 'app/modules/permissions/actions';

const rootAction = PermissionsActionTypes.RETRIEVE_UNASSIGNED_PERMISSIONS;
export function* retrieveUnassignedPermissions({
  payload,
}: ReturnType<typeof action>) {
  const config = {
    rootAction,
    request: call(api, payload),
    success: function* onSuccess(
      result: ReturnType<
        typeof retrieveUnassignedPermissionsSuccess
      >['payload'],
    ) {
      yield put(retrieveUnassignedPermissionsSuccess(result));
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watch() {
  yield takeLatest(rootAction, retrieveUnassignedPermissions);
}
