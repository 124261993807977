import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { BlacklistActionTypes } from 'app/modules/lists/models';

// Actions
import {
  retrieveBlacklist as retrieveBlacklistAction,
  retrieveBlacklistSuccess,
} from 'app/modules/lists/actions';

// API
import { retrieveBlacklist as retrieveBlacklistApi } from 'app/shared/api/blacklists';

const rootAction = BlacklistActionTypes.RETRIEVE_BLACKLIST;
function* retrieveBlacklist({
  payload,
}: ReturnType<typeof retrieveBlacklistAction>) {
  const config = {
    rootAction,
    request: call(retrieveBlacklistApi, payload),
    success: function* onSuccess(result) {
      yield put(retrieveBlacklistSuccess(result));
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watchRetrieveBlacklist() {
  yield takeLatest(rootAction, retrieveBlacklist);
}
