import { PayloadAction } from '@reduxjs/toolkit';
import { u21CreateAsyncThunk } from 'app/shared/thunk/u21CreateAsyncThunk';

import {
  ModalProps,
  DataMappingPreviewType,
  DataMappingSchema,
  DataMappingRow,
  SelectedAnnotation,
  DataMappingState,
  PrimaryObject,
} from 'app/modules/dataMapping/types';
import { getConfig, updateConfig } from 'app/modules/dataMapping/api';
import { sendErrorToast, sendSuccessToast } from 'app/shared/toasts/actions';
import { u21CreateSlice } from 'app/shared/thunk/u21CreateSlice';
import {
  addBlankHardcodedValue as addBlankHardcodedValueHelper,
  updateAnnotationAggregateFunction as updateAnnotationAggregateFunctionHelper,
  updateAnnotationOrigin as updateAnnotationOriginHelper,
  addBlankAnnotation as addBlankAnnotationHelper,
  autoAddBlankAnnotation as autoAddBlankAnnotationHelper,
  removeObject as removeObjectHelper,
  removeAnnotation as removeAnnotationHelper,
  updateDataMappingDestination as updateDataMappingDestinationHelper,
  convertStreamConfigResponseToDataMappingSchema,
  convertDataMappingSchemaToConfigPayload,
  getObject,
  getDataMappingRow,
  getDefaultConfigFromType,
} from 'app/modules/dataMapping/helpers';
import { selectLocalConfig } from 'app/modules/dataMapping/selectors';
import { ERROR_STATE, LOADING_STATE } from 'app/shared/types/utils/asyncStatus';
import { NewObjectFormValues } from 'app/modules/dataMapping/models';
import {
  BLANK_STREAM_ANNOTATION,
  COMPLETE_STATUS,
  DEFAULT_MODAL_PROPS,
} from 'app/modules/dataMapping/constants';
import {
  StreamConfigResponse,
  TypeHandling,
  Aggregation,
  DirectionaltyConfiguration,
  Relationship,
  AnnotationCondition,
} from 'app/modules/dataMapping/responses';
import { previewDatafile } from 'app/modules/pullBasedDataFiles/api';
import {
  PullBasedDataFileStatus,
  PullBasedDataFilesPreview,
} from 'app/modules/pullBasedDataFiles/responses';
import { resetDataFilePage } from 'app/modules/pullBasedDataFiles/slicePullBasedDataFiles';
import { ROUTES_MAP } from 'app/shared/utils/routes';
import { PBDF_FAILURE_CODE_TO_TOOLTIP_MAP } from 'app/modules/pullBasedDataFiles/constants';

export const DATA_MAPPING_NAME = 'dataMapping';

export const initialState: DataMappingState = {
  initialServerAnnotationConfig: LOADING_STATE,
  updateServerAnnotationStatus: null,
  localAnnotationConfig: null,
  filePreview: LOADING_STATE,
  selectedAnnotation: null,
  selectedColumn: null,
  modalProps: DEFAULT_MODAL_PROPS,
  dataMappingPreview: 'DATA',
};

export const getConfigThunk = u21CreateAsyncThunk<
  { streamId: number },
  StreamConfigResponse
>(`${DATA_MAPPING_NAME}/GET_CONFIG`, async (payload, { dispatch }) => {
  try {
    return await getConfig(payload.streamId);
  } catch (e) {
    if ('status' in e && e.status === 404) {
      return {
        config: null,
      };
    }
    dispatch(sendErrorToast('Something went wrong'));
    throw e;
  }
});

export const persistLocalConfigThunk = u21CreateAsyncThunk<
  { streamId: string; isDraft: boolean },
  DataMappingSchema | null
>(
  `${DATA_MAPPING_NAME}/PERSIST_CONFIG`,
  async ({ streamId, isDraft }, { dispatch, getState }) => {
    const localConfig = selectLocalConfig(getState());
    if (localConfig === null) {
      return null;
    }
    try {
      await updateConfig({
        stream_id: streamId,
        unit21_object: localConfig.unit21_object,
        config: convertDataMappingSchemaToConfigPayload(localConfig),
        is_draft: isDraft,
      });
      dispatch(
        sendSuccessToast(`Saved schema${isDraft ? ` as draft` : ''}!`, {
          to: ROUTES_MAP.dataManagementFlatFileIngestion.path,
          text: 'Back to streams',
        }),
      );
      return localConfig;
    } catch (e) {
      if ('status' in e && e.status === 404) {
        dispatch(sendErrorToast('Error saving'));
        return localConfig;
      }
      dispatch(sendErrorToast('Something went wrong'));
      throw e;
    }
  },
);

export const getFilePreviewThunk = u21CreateAsyncThunk<
  { fileId: number },
  PullBasedDataFilesPreview
>(`${DATA_MAPPING_NAME}/GET_FILE_PREVIEW`, async (payload, { dispatch }) => {
  try {
    return await previewDatafile(payload.fileId);
  } catch (e) {
    let errorToastMessage = 'Something went wrong';
    try {
      const { message } = await e.json();
      if (message) {
        if (message.includes('InvalidTextEncoding')) {
          errorToastMessage =
            PBDF_FAILURE_CODE_TO_TOOLTIP_MAP.NON_UTF8_ENCODING;
        } else if (message.includes('CSV file could not be parsed')) {
          errorToastMessage =
            PBDF_FAILURE_CODE_TO_TOOLTIP_MAP.CSV_PARSING_ERROR;
        }
      }
    } catch {}
    dispatch(sendErrorToast(errorToastMessage));
    throw e;
  }
});

export const setupAnnotationConfigStateThunk = u21CreateAsyncThunk<{
  streamId: number;
}>(
  `${DATA_MAPPING_NAME}/SETUP_ANNOTATION_CONFIG_STATE`,
  async (payload, { dispatch }) => {
    const { streamId } = payload;
    dispatch(getConfigThunk(payload));
    try {
      const streamPage = await resetDataFilePage(dispatch, streamId).unwrap();
      // Get first available data file.
      const skipStatuses = new Set([
        PullBasedDataFileStatus.PENDING_UPLOAD,
        PullBasedDataFileStatus.DELETED,
      ]);
      const [firstAvailableDataFile] = streamPage.pull_based_datafiles.filter(
        (pbdf) => !skipStatuses.has(pbdf.status),
      );
      if (firstAvailableDataFile) {
        await dispatch(
          getFilePreviewThunk({ fileId: firstAvailableDataFile.id }),
        );
      }
    } catch (e) {}
  },
);

const dataMappingSlice = u21CreateSlice({
  name: DATA_MAPPING_NAME,
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getConfigThunk.pending, (draft) => {
        draft.initialServerAnnotationConfig = LOADING_STATE;
      })
      .addCase(getConfigThunk.fulfilled, (draft, { payload }) => {
        const dataMappingSchema =
          convertStreamConfigResponseToDataMappingSchema(payload);
        draft.initialServerAnnotationConfig = {
          status: 'COMPLETE',
          config: dataMappingSchema,
        };
        draft.localAnnotationConfig = dataMappingSchema;
      })
      .addCase(getConfigThunk.rejected, (draft) => {
        draft.initialServerAnnotationConfig = ERROR_STATE;
      })
      .addCase(persistLocalConfigThunk.pending, (draft) => {
        draft.updateServerAnnotationStatus = LOADING_STATE;
      })
      .addCase(persistLocalConfigThunk.fulfilled, (draft, { payload }) => {
        if (payload === null) {
          return; // Shouldn't happen. Just for type purposes
        }
        // Update the initial server config so that the UI is no longer "dirty"
        draft.initialServerAnnotationConfig = {
          config: payload,
          status: 'COMPLETE',
        };
        draft.localAnnotationConfig = payload;
        draft.updateServerAnnotationStatus = COMPLETE_STATUS;
      })
      .addCase(persistLocalConfigThunk.rejected, (draft, { payload }) => {
        if (payload === null) {
          return; // Shouldn't happen. Just for type purposes
        }
        draft.updateServerAnnotationStatus = ERROR_STATE;
        // No need to update local config. Update failed
      })
      .addCase(getFilePreviewThunk.pending, (draft) => {
        draft.filePreview = LOADING_STATE;
      })
      .addCase(getFilePreviewThunk.fulfilled, (draft, { payload }) => {
        draft.filePreview = {
          status: 'COMPLETE',
          preview: payload,
        };
      })
      .addCase(getFilePreviewThunk.rejected, (draft) => {
        draft.filePreview = ERROR_STATE;
      });
  },
  reducers: {
    setPrimaryObjectType: (
      draft,
      { payload }: PayloadAction<PrimaryObject>,
    ) => {
      draft.localAnnotationConfig = getDefaultConfigFromType(
        payload,
      ) as DataMappingSchema;
    },
    updateRowFiltering: (draft, { payload }: PayloadAction<TypeHandling>) => {
      if (draft.localAnnotationConfig) {
        draft.localAnnotationConfig.type_handling = payload;
      }
    },
    updateAnnotationOrigin: (
      draft,
      {
        payload,
      }: PayloadAction<{
        objectName: string;
        row: DataMappingRow;
        val: string | string[];
      }>,
    ) => {
      if (draft.localAnnotationConfig) {
        const { objectName, row, val } = payload;
        const object = getObject(draft.localAnnotationConfig, objectName);
        updateAnnotationOriginHelper(object, row, val);
      }
    },
    updateDataMappingDestination: (
      draft,
      {
        payload: { objectName, row, val, toCustomAnnotation = false },
      }: PayloadAction<{
        objectName: string;
        row: DataMappingRow;
        val: string;
        toCustomAnnotation?: boolean;
      }>,
    ) => {
      if (draft.localAnnotationConfig) {
        const object = getObject(draft.localAnnotationConfig, objectName);
        updateDataMappingDestinationHelper(
          object,
          row,
          val,
          toCustomAnnotation,
        );
        const {
          custom_data_annotations: customDataAnnotations,
          typed_annotations: typedAnnotations,
        } = object;
        let { id } = row;
        if (row.isCustom !== toCustomAnnotation) {
          id =
            row.isCustom && !toCustomAnnotation
              ? typedAnnotations.length - 1
              : [...customDataAnnotations, ...typedAnnotations].length - 1;
          if (id < 0) {
            id = 0;
          }
        }
        const newSelectedAnnotation = {
          objectName,
          isCustom: toCustomAnnotation,
          id,
        };
        draft.selectedAnnotation = newSelectedAnnotation;
      }
    },
    updateAnnotationAggregateFunction: (
      draft,
      {
        payload,
      }: PayloadAction<{
        objectName: string;
        row: DataMappingRow;
        method: Aggregation['aggregate_func'];
      }>,
    ) => {
      if (draft.localAnnotationConfig) {
        const { objectName, row, method } = payload;
        updateAnnotationAggregateFunctionHelper(
          getObject(draft.localAnnotationConfig, objectName),
          row,
          method,
        );
      }
    },
    addBlankHardcodedValue: (draft, { payload }: PayloadAction<string>) => {
      if (draft.localAnnotationConfig) {
        addBlankHardcodedValueHelper(
          getObject(draft.localAnnotationConfig, payload),
        );
      }
    },
    setModalProps: (draft, { payload }: PayloadAction<ModalProps>) => {
      draft.modalProps = payload;
    },
    closeModal: (draft) => {
      draft.modalProps = DEFAULT_MODAL_PROPS;
    },
    addObjectReference: (
      draft,
      { payload }: PayloadAction<NewObjectFormValues>,
    ) => {
      if (draft.localAnnotationConfig) {
        const { name, type } = payload;
        draft.localAnnotationConfig.objects[name] = {
          name,
          type,
          typed_annotations: [],
          custom_data_annotations: [BLANK_STREAM_ANNOTATION],
        };
      }
    },
    addBlankAnnotation: (draft, { payload }: PayloadAction<string>) => {
      if (draft.localAnnotationConfig) {
        addBlankAnnotationHelper(
          getObject(draft.localAnnotationConfig, payload),
        );
      }
    },
    removeAnnotation: (
      draft,
      { payload }: PayloadAction<{ objectName: string; row: DataMappingRow }>,
    ) => {
      if (draft.localAnnotationConfig) {
        removeAnnotationHelper(
          draft.localAnnotationConfig,
          payload.objectName,
          payload.row,
        );
        draft.selectedAnnotation = null;
      }
    },
    setObjectDirectionality: (
      draft,
      {
        payload,
      }: PayloadAction<{
        directionality: DirectionaltyConfiguration;
        objectName: string;
      }>,
    ) => {
      if (draft.localAnnotationConfig) {
        const object = draft.localAnnotationConfig.objects[payload.objectName];
        if (!(object.type === 'entity' || object.type === 'instrument')) {
          throw new Error('invalid object');
        }
        object.directionality = payload.directionality;
      }
    },
    updateAnnotationTransformations: (
      draft,
      {
        payload,
      }: PayloadAction<{
        objectName: string;
        row: DataMappingRow;
      }>,
    ) => {
      if (draft.localAnnotationConfig) {
        const { objectName, row } = payload;
        const object = getObject(draft.localAnnotationConfig, objectName);
        const dataMappingRow = getDataMappingRow(object, row.isCustom, row.id);
        dataMappingRow.value = row.value;
      }
    },
    setSelectedAnnotation: (
      draft,
      { payload }: PayloadAction<SelectedAnnotation | null>,
    ) => {
      draft.selectedAnnotation = payload;
    },
    setSelectedColumn: (draft, { payload }: PayloadAction<string | null>) => {
      draft.selectedColumn = payload;
    },
    setDataMappingPreview: (
      draft,
      { payload }: PayloadAction<DataMappingPreviewType>,
    ) => {
      draft.dataMappingPreview = payload;
    },
    removeRowFiltering: (draft) => {
      if (draft.localAnnotationConfig?.type_handling) {
        draft.localAnnotationConfig.type_handling = null;
      }
    },
    autoAddBlankAnnotation: (draft, { payload }: PayloadAction<string>) => {
      if (draft.localAnnotationConfig) {
        autoAddBlankAnnotationHelper(
          getObject(draft.localAnnotationConfig, payload),
        );
      }
    },
    removeObject: (draft, { payload }: PayloadAction<string>) => {
      if (draft.localAnnotationConfig) {
        removeObjectHelper(draft.localAnnotationConfig, payload);
      }
    },
    updateRelationships: (
      draft,
      { payload }: PayloadAction<Relationship[]>,
    ) => {
      if (draft.localAnnotationConfig) {
        draft.localAnnotationConfig.relationships = payload;
      }
    },
    mappingRowSelectedToCondition: (
      draft,
      {
        payload,
      }: { payload: { objectName: string; row: DataMappingRow } | undefined },
    ) => {
      draft.mappingRowToCondition = payload;
    },
    mappingRowConditionChanged: (
      draft,
      {
        payload,
      }: {
        payload: {
          objectName: string;
          row: DataMappingRow;
          conditions: AnnotationCondition[];
        };
      },
    ) => {
      if (draft.localAnnotationConfig) {
        const { objectName, row, conditions } = payload;
        const object = getObject(draft.localAnnotationConfig, objectName);
        const dataMappingRow = getDataMappingRow(object, row.isCustom, row.id);
        dataMappingRow.conditions = conditions;
      }
    },
  },
});

export const {
  updateRowFiltering,
  setSelectedAnnotation,
  setSelectedColumn,
  setDataMappingPreview,
  updateAnnotationOrigin,
  updateDataMappingDestination,
  updateAnnotationAggregateFunction,
  addBlankHardcodedValue,
  addObjectReference,
  setModalProps,
  closeModal,
  addBlankAnnotation,
  removeAnnotation,
  setObjectDirectionality,
  updateAnnotationTransformations,
  removeRowFiltering,
  autoAddBlankAnnotation,
  setPrimaryObjectType,
  removeObject,
  updateRelationships,
  mappingRowSelectedToCondition,
  mappingRowConditionChanged,
} = dataMappingSlice.actions;
export default dataMappingSlice.reducer;
