import { retrieveSubdispositions } from 'app/modules/workflows/actions';
import { selectSubdispositions } from 'app/modules/workflows/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';

import { IconLicense } from '@u21/tabler-icons';
import {
  U21MultiSelect,
  U21MultiSelectProps,
  U21Select,
  U21SelectProps,
} from 'app/shared/u21-ui/components';

interface U21SubdispositionAndOptionsSelectSingleProps<
  TClearable extends boolean,
> extends Omit<U21SelectProps<number, TClearable>, 'options'> {
  multi?: false;
}

interface U21SubdispositionAndOptionsSelectMultiProps<
  TClearable extends boolean,
> extends Omit<U21MultiSelectProps<number, TClearable>, 'options'> {
  multi: true;
}

export type U21SubdispositionAndOptionsSelectProps<
  TClearable extends boolean = true,
> =
  | U21SubdispositionAndOptionsSelectSingleProps<TClearable>
  | U21SubdispositionAndOptionsSelectMultiProps<TClearable>;

export const U21SubdispositionAndOptionsSelect = <
  TClearable extends boolean = true,
>(
  props: U21SubdispositionAndOptionsSelectProps<TClearable>,
) => {
  const { multi, onChange, value, ...rest } = props;
  const dispatch = useDispatch();
  const subdispositions = useSelector(selectSubdispositions);

  const options = useMemo(
    () =>
      subdispositions
        .map((i) =>
          i.options.map((j) => ({
            text: `${i.title}:${j.name}`,
            value: j.id,
          })),
        )
        .flat(),
    [subdispositions],
  );

  useEffect(() => {
    dispatch(retrieveSubdispositions());
  }, [dispatch]);

  if (multi) {
    return (
      <U21MultiSelect
        onChange={onChange}
        options={options}
        startIcon={<IconLicense />}
        value={value}
        {...rest}
      />
    );
  }
  return (
    <U21Select
      onChange={onChange}
      options={options}
      startIcon={<IconLicense />}
      value={value}
      {...rest}
    />
  );
};
