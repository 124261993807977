import { get, post, put, destroy } from 'app/shared/utils/fetchr';
import routes from 'app/shared/utils/routes';
import { RawDraftContentState } from 'draft-js';

export const getNotes = (articleId: number) =>
  get(
    routes.patronus.notesArticleId.replace(':articleId', articleId.toString()),
  );

export const addNote = ({
  articleId,
  content,
}: {
  articleId: number;
  content: RawDraftContentState;
}) =>
  post(
    routes.patronus.notesArticleIdAdd.replace(
      ':articleId',
      articleId.toString(),
    ),
    {
      content,
    },
  );

export const updateNote = ({
  noteId,
  content,
}: {
  noteId: number;
  content: RawDraftContentState;
}) =>
  put(routes.patronus.notesNoteId.replace(':noteId', noteId.toString()), {
    content,
  });

export const deleteNote = (noteId: number) =>
  destroy(routes.patronus.notesNoteId.replace(':noteId', noteId.toString()));
