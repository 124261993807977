// Models
import { SarStatus, ReportType } from 'app/modules/fincenSar/models';

// Constants
import { ROUTES_MAP } from 'app/shared/utils/routes';

export const DO_NOT_SHOW_DEADLINE_STATUSES: SarStatus[] = [
  SarStatus.ARCHIVED,
  SarStatus.FILING_FAILED,
  SarStatus.FILING_SUCCESS,
  SarStatus.FINCEN_VALIDATION_FAILED,
  SarStatus.FINCEN_REJECTED,
  SarStatus.FINCEN_ACCEPTED,
  SarStatus.FINCEN_STATUS_OTHER,
  SarStatus.SENT_TO_FINCEN,
];

export const REPORT_TYPE_PATH_MAP: Record<ReportType, string> = {
  [ReportType.FINCEN_SAR]: ROUTES_MAP.filingsSarId.path,
  [ReportType.GOAML_STR]: ROUTES_MAP.filingsGoAMLIdEdit.path,
  [ReportType.FINCEN_CTR]: ROUTES_MAP.filingsFincenCtrEdit.path.replace(
    '/:tab?',
    '',
  ),
  [ReportType.ESTONIAN_FIU]: ROUTES_MAP.fourOhFour.path, // deprecated
  [ReportType.NCA_SAR]: ROUTES_MAP.fourOhFour.path, // deprecated
};

export const REPORT_TYPE_DISPLAY_TEXT_MAP: Record<ReportType, string> = {
  [ReportType.FINCEN_SAR]: 'FinCEN SAR',
  [ReportType.GOAML_STR]: 'goAML STR',
  [ReportType.FINCEN_CTR]: 'FinCEN CTR',
  [ReportType.ESTONIAN_FIU]: 'Unsupported', // deprecated
  [ReportType.NCA_SAR]: 'Unsupported', // deprecated
};
