// Models
import {
  CompositeQueryTreeNode,
  ConstantValue,
  DmAggregateFunction,
  DmEventSubtype,
  DmObjectToFlag,
  DmOperator,
  DurationValue,
  FieldValue,
  IRSchema,
  QueryTreeNode,
} from 'app/modules/rulesAdvanced/types';
import {
  AndOrCondition,
  Condition,
  ConditionOperandValue,
  DmEventByObjectFactOperand,
  DropdownBuilderFormValues,
  FECompositeQueryTreeNode,
} from 'app/modules/detectionModels/components/DropdownBuilderForm/models';
import {
  FieldValueOperand,
  SymbolFieldValue,
} from 'app/modules/rulesAdvanced/lib/SymbolTable';

// Constants
import {
  AndOrOperator,
  ConditionalOperators,
  EMPTY_FIELD_VALUE,
  OBJECT_TO_FLAG_TO_MODEL,
} from 'app/modules/detectionModels/constants';

// Utils
import {
  determineOperator,
  isCompositeOperator,
  isConditionalOperator,
} from 'app/modules/detectionModels/helpers';
import {
  DmFact,
  MatchlistFieldValue,
  NullValue,
} from 'app/modules/rulesAdvanced/types/RulesRepresentation';
import {
  DropdownBuilderFormFields,
  EMPTY_DROPDOWN_BUILDER_CONDITION,
} from 'app/modules/detectionModels/components/DropdownBuilderForm/constants';
import { convertFactConditionToRuleCondition } from 'app/modules/detectionModels/components/scenarioWidgets/helpers';

// NOTE: Dropdown Builder helper functions
// Extracted from helpers.tsx to avoid circular dependencies
export const generateEmptyIrRule = (object: DmObjectToFlag): IRSchema => {
  return {
    facts: [],
    rule_condition: {
      type: 'rule_condition_composite',
      operands: [],
      operator: 'AND',
    },
    object,
    field_to_flag: {
      type: 'FLAG_FIELD',
      field: `${object === 'TXN_EVENT' ? 'internal_txn_type' : 'action_type'}`,
      model: OBJECT_TO_FLAG_TO_MODEL[object],
      values: [],
    },
  };
};

export const generateEventByObjectFactOperand = (
  id: string,
  aggregateFunction: DmAggregateFunction,
  eventSubtype: DmEventSubtype,
): DmEventByObjectFactOperand => {
  return {
    type: 'EVENT_BY_OBJECT_FACT',
    id,
    object: 'ENTITY',
    sender_receiver: 'SENDER',
    event_filters: {
      type: 'COMPOSITE',
      operands: [],
      operator: 'AND',
    },
    aggregate_function: aggregateFunction,
    aggregate_field: EMPTY_FIELD_VALUE,
    event_subtype: eventSubtype,
    window_end_offset: {
      type: 'DURATION',
      unit: 'HOUR',
      value: 0,
    },
    window_start_offset: {
      type: 'DURATION',
      unit: 'DAY',
      value: 0,
    },
  };
};

export const isAndOrOperator = (
  operator: DmOperator,
): operator is AndOrOperator => {
  return operator === AndOrOperator.AND || operator === AndOrOperator.OR;
};

export const isAndOrCondition = (
  condition: AndOrCondition | Condition | ConditionOperandValue,
): condition is AndOrCondition => {
  return (
    condition !== null &&
    Object.hasOwn(condition, DropdownBuilderFormFields.CONDITIONS)
  );
};

export const isCondition = (
  condition: AndOrCondition | Condition,
): condition is Condition => {
  return Object.hasOwn(condition, DropdownBuilderFormFields.LEFT_OPERAND);
};

export const isRuleConditionComposite = (
  operandValue: ConditionOperandValue | CompositeQueryTreeNode | QueryTreeNode,
): operandValue is FECompositeQueryTreeNode => {
  return operandValue?.type === 'rule_condition_composite';
};

export const isComposite = (
  operandValue: ConditionOperandValue | CompositeQueryTreeNode | QueryTreeNode,
): operandValue is FECompositeQueryTreeNode => {
  return operandValue?.type === 'COMPOSITE';
};

export const isFieldValue = (
  operandValue: ConditionOperandValue | QueryTreeNode,
): operandValue is FieldValue | SymbolFieldValue => {
  // NOTE: the only difference is SymbolFieldValue has a 'label' prop on it
  return operandValue?.type === 'FIELD';
};

export const isConstantValue = (
  operandValue: ConditionOperandValue | QueryTreeNode,
): operandValue is ConstantValue => {
  return operandValue?.type === 'CONSTANT';
};

export const isAggregateVariable = (
  operandValue: ConditionOperandValue | QueryTreeNode,
): operandValue is DmEventByObjectFactOperand => {
  return operandValue?.type === 'EVENT_BY_OBJECT_FACT';
};

export const isDurationValue = (
  operandValue: ConditionOperandValue | QueryTreeNode,
): operandValue is DurationValue => {
  return operandValue?.type === 'DURATION';
};

export const isMatchlistFieldValue = (
  operandValue: ConditionOperandValue | QueryTreeNode,
): operandValue is MatchlistFieldValue => {
  return operandValue?.type === 'MATCHLIST_FIELD';
};

export const isNullValue = (
  operandValue: ConditionOperandValue | QueryTreeNode,
): operandValue is NullValue => {
  return operandValue?.type === 'NULL';
};

export const isConditionOperandValue = (
  operandValue: QueryTreeNode | ConditionOperandValue,
): operandValue is ConditionOperandValue => {
  return (
    isAggregateVariable(operandValue) ||
    isConstantValue(operandValue) ||
    isDurationValue(operandValue) ||
    isFieldValue(operandValue) ||
    isMatchlistFieldValue(operandValue) ||
    isNullValue(operandValue)
  );
};

export const isFEComposite = (
  operand: ConditionOperandValue,
): operand is FECompositeQueryTreeNode => {
  return operand?.type === 'COMPOSITE';
};

export const isMathOperator = (
  operator: DmOperator | ConditionalOperators,
): boolean => {
  return ['ADD', 'SUB', 'MUL', 'DIV'].includes(operator);
};

const convertOperandToNode = (
  operand: ConditionOperandValue,
): QueryTreeNode => {
  if (isFieldValue(operand)) return operand;

  if (isAggregateVariable(operand)) {
    return {
      type: 'VARIABLE',
      name: operand.id,
    };
  }
  if (isRuleConditionComposite(operand)) {
    const operandCopy = structuredClone(operand);

    if (isMathOperator(operandCopy.operator)) {
      // TODO: chore/sc-78358/add-support-for-math-operations-other-than
      const { 1: mathRightOperand } = operandCopy.operands;
      if (isFieldValue(mathRightOperand)) {
        operandCopy.operands[1] = mathRightOperand;
      } else if (isAggregateVariable(mathRightOperand)) {
        const name = mathRightOperand.id;
        operandCopy.operands[1] = {
          type: 'VARIABLE',
          name,
        };
      }
      return isFEComposite(operandCopy)
        ? (operandCopy as CompositeQueryTreeNode)
        : operandCopy;
    }
  }
  return isFEComposite(operand) ? (operand as CompositeQueryTreeNode) : operand;
};

export const dropdownBuilderSpecToForm = (
  condition: CompositeQueryTreeNode,
): DropdownBuilderFormValues => {
  const generatedCondition =
    generateDropdownBuilderFormValuesFromCompositeQueryTreeNode(
      convertFactConditionToRuleCondition(condition),
      [],
    );
  if (!isAndOrCondition(generatedCondition)) {
    throw new Error('Expected AndOr condition');
  }
  return {
    condition: generatedCondition,
  };
};

export const convertConditionsToQueryTreeNodes = (
  condition: AndOrCondition | Condition,
): CompositeQueryTreeNode => {
  if (isAndOrCondition(condition)) {
    return {
      type: condition.type,
      operator: condition.operator,
      meta_ui: { ui_operator: condition.operator },
      operands: condition[DropdownBuilderFormFields.CONDITIONS].map(
        (nestedCondition) => convertConditionsToQueryTreeNodes(nestedCondition),
      ),
    };
  }
  const { type, operator, leftOperand, rightOperand, betweenOperand } =
    condition;

  const operands: QueryTreeNode[] = [leftOperand];
  if (rightOperand) operands.push(convertOperandToNode(rightOperand));

  if (betweenOperand) operands.push(convertOperandToNode(betweenOperand));

  const convertedCondition = {
    type,
    operands,
    operator,
  };
  const operatorForBE = determineOperator(convertedCondition);

  return {
    ...convertedCondition,
    operator: operatorForBE,
    meta_ui: {
      input_choice: 'dropdown',
      ui_operator: operator,
    },
  };
};

export const convertVariable = (
  factList: DmFact[],
  variableName: string,
): DmEventByObjectFactOperand | null => {
  const foundFact = factList.filter((fact) => fact.id === variableName)[0];
  if (
    foundFact.type === 'EVENT_BY_OBJECT_FACT' &&
    typeof foundFact.aggregate_field !== 'string' &&
    isFieldValue(foundFact.aggregate_field)
  ) {
    return {
      ...foundFact,
      aggregate_field: foundFact.aggregate_field || EMPTY_FIELD_VALUE,
    };
  }
  return null;
};

const convertNode = (
  node: QueryTreeNode,
  facts: DmFact[],
): ConditionOperandValue => {
  let operand: ConditionOperandValue = null;
  if (isFieldValue(node)) {
    operand = node;
  } else if (isRuleConditionComposite(node)) {
    const operandCopy: FECompositeQueryTreeNode = structuredClone(node);
    if (isMathOperator(operandCopy.operator)) {
      if (operandCopy.operands[1]?.type === 'VARIABLE') {
        operandCopy.operands[1] = convertVariable(
          facts,
          operandCopy.operands[1].name,
        );
      }
      operand = operandCopy;
    }
  } else if (node?.type === 'VARIABLE') {
    operand = convertVariable(facts, node.name);
  } else if (isConditionOperandValue(node)) {
    operand = node;
  }
  return operand;
};

export const generateDropdownBuilderFormValuesFromCompositeQueryTreeNode = (
  queryTreeNode: QueryTreeNode | CompositeQueryTreeNode,
  facts?: DmFact[],
): AndOrCondition | Condition => {
  if (isRuleConditionComposite(queryTreeNode)) {
    if (isAndOrOperator(queryTreeNode.operator)) {
      return {
        type: queryTreeNode.type,
        operator: queryTreeNode.operator,
        conditions: queryTreeNode.operands.map((operand) =>
          generateDropdownBuilderFormValuesFromCompositeQueryTreeNode(
            operand,
            facts,
          ),
        ),
      };
    }

    const { 1: rightOperandNode } = queryTreeNode.operands;
    const rightOperand: ConditionOperandValue = convertNode(
      rightOperandNode,
      facts ?? [],
    );
    const { 2: betweenOperandNode } = queryTreeNode.operands;
    const betweenOperand: ConditionOperandValue = convertNode(
      betweenOperandNode,
      facts ?? [],
    );

    const leftOperandFieldValue = isFieldValue(queryTreeNode.operands[0])
      ? queryTreeNode.operands[0]
      : EMPTY_FIELD_VALUE;

    const uiOperator = queryTreeNode.meta_ui?.ui_operator;
    return {
      type: queryTreeNode.type,
      operator: isConditionalOperator(uiOperator)
        ? uiOperator
        : ConditionalOperators.EQ,
      leftOperand: leftOperandFieldValue || EMPTY_FIELD_VALUE,
      rightOperand,
      betweenOperand,
    };
  }
  return EMPTY_DROPDOWN_BUILDER_CONDITION;
};

export const generateNestedOperandKey = (groupIndex: number[]) => {
  let operatorKey: string = ``;
  groupIndex.forEach((gIndex) => {
    operatorKey = operatorKey.concat(
      `${operatorKey === '' ? '' : '.'}operands[${gIndex}]`,
    );
  });
  return operatorKey;
};

export const extractFactNames = (
  ruleCondition: CompositeQueryTreeNode,
): Set<string> => {
  const foundFacts: QueryTreeNode[] = ruleCondition.operands.filter(
    (operand) => operand?.type === 'VARIABLE',
  );
  const factNames: Set<string> = new Set();
  if (foundFacts.length) {
    foundFacts.forEach((variable) => {
      if (variable?.type === 'VARIABLE') factNames.add(variable.name);
    });
  }
  return factNames;
};

export const extractFactNamesFromGroup = (
  groupCondition: CompositeQueryTreeNode,
): Set<string> => {
  const factNames: Set<string> = new Set();
  groupCondition.operands.forEach((operand) => {
    if (operand?.type === 'rule_condition_composite') {
      if (isCompositeOperator(operand.operator))
        extractFactNamesFromGroup(operand).forEach((factName) =>
          factNames.add(factName),
        );
      // NOTE: still a "group" condition
      else
        extractFactNames(operand).forEach((factName) =>
          factNames.add(factName),
        );
    }
  });
  return factNames;
};

export const comparisonOperatorSideEffect = (
  operand: FieldValueOperand | null,
): { uiOperator: DmOperator; operator: DmOperator } | undefined => {
  if (!operand) return undefined;
  let res;
  if (
    operand.dropdownValues?.length ||
    ['enum', 'multiselect', 'select'].includes(operand.operandType)
  ) {
    res = {
      uiOperator: ConditionalOperators.IN,
      operator: ConditionalOperators.ANY_IN,
    };
  } else if (['date', 'datetime'].includes(operand.operandType)) {
    res = {
      uiOperator: ConditionalOperators.GT,
      operator: ConditionalOperators.GT,
    };
  } else {
    res = {
      uiOperator: ConditionalOperators.EQ,
      operator: ConditionalOperators.EQ,
    };
  }

  return res;
};
