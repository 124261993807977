import { investigationChecklistSliceName } from 'app/modules/investigationChecklist/sliceInvestigationChecklist';

export const selectInvestigationChecklists = (state: RootState) =>
  state[investigationChecklistSliceName].checklists.custom_checklists || [];

export const selectInvestigationChecklistCount = (state: RootState) =>
  state[investigationChecklistSliceName].checklists.count || 0;

export const selectInvestigationChecklistsPending = (state: RootState) =>
  Boolean(state[investigationChecklistSliceName].checklistsLoading);

export const selectInvestigationChecklist = (state: RootState) =>
  state[investigationChecklistSliceName].checklist;

export const selectInvestigationChecklistPending = (state: RootState) =>
  state[investigationChecklistSliceName].checklistLoading;

export const selectInvestigationChecklistUpdatePending = (state: RootState) =>
  state[investigationChecklistSliceName].checklistUpdateLoading;

export const selectInvestigationChecklistUpdateUnsaved = (state: RootState) =>
  state[investigationChecklistSliceName].checklistUpdateUnsaved;
