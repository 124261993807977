import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { BlacklistActionTypes } from 'app/modules/lists/models';

// Actions
import {
  retrieveBlacklists as retrieveBlacklistsAction,
  retrieveBlacklistsSuccess,
} from 'app/modules/lists/actions';

// API
import { retrieveBlacklists as retrieveBlacklistsApi } from 'app/shared/api/blacklists';

const rootAction = BlacklistActionTypes.RETRIEVE_BLACKLISTS;
function* retrieveBlacklists({
  payload,
}: ReturnType<typeof retrieveBlacklistsAction>) {
  const config = {
    rootAction,
    request: call(retrieveBlacklistsApi, payload),
    success: function* onSuccess(result) {
      yield put(retrieveBlacklistsSuccess(result));
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watchRetrieveBlacklists() {
  yield takeLatest(rootAction, retrieveBlacklists);
}
