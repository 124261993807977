import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { DeadlinesActionTypes } from 'app/modules/deadlines/models';

// Actions
import {
  retrieveDeadlinesConfig as retrieveDeadlinesConfigAction,
  retrieveDeadlinesConfigSuccess,
} from 'app/modules/deadlines/actions';

// API
import { retrieveDeadlinesConfig as retrieveDeadlinesConfigAPI } from 'app/shared/api/deadlines';

const rootAction = DeadlinesActionTypes.RETRIEVE_DEADLINES_CONFIG;
function* retrieveDeadlinesConfig({
  payload,
}: ReturnType<typeof retrieveDeadlinesConfigAction>) {
  const config = {
    rootAction,
    request: call(retrieveDeadlinesConfigAPI, payload),
    success: function* onSuccess(
      result: ReturnType<typeof retrieveDeadlinesConfigSuccess>['payload'],
    ) {
      yield put(retrieveDeadlinesConfigSuccess(result));
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watchRetrieveDeadlinesConfig() {
  yield takeLatest(rootAction, retrieveDeadlinesConfig);
}
