import React, { ReactElement, useState } from 'react';
import { startCase } from 'lodash';

// Models
import { EditingRuleFiltersModel } from 'app/modules/rules/models';

// Components
import {
  Query,
  Builder,
  Utils as QbUtils,
  ImmutableTree,
  JsonGroup,
  Config as QbConfig,
} from 'react-awesome-query-builder';
import ReactTooltip from 'react-tooltip';
import {
  U21Modal,
  U21MultiSelect,
  U21SelectOptionProps,
} from 'app/shared/u21-ui/components';

// Styles
import styles from 'app/modules/rules/styles/ScenarioFiltersEditorModal.module.scss';

// Utils
import { createConfig } from 'app/modules/rules/queryConfig';
import {
  getEmptyFilters,
  isValidTree,
  ONLY_USERS_FIELD_KEY,
} from 'app/modules/rules/helpers';
import {
  EmbeddedFilters,
  QueryBuilderConfigStruct,
} from 'app/modules/detectionModels/models';
import { useSelector } from 'react-redux';
import { selectQueryBuilderConfig } from 'app/modules/orgSettings/selectors';
import { getEmbeddedFilterFields } from 'app/modules/rules/selectors';
import { selectCustomDataSettingsByClassifier } from 'app/modules/dataSettings/selectors';
import { CustomDataSettingsConfigResponse } from 'app/modules/dataSettings/responses';
import { EntityGraphLink } from 'app/modules/detectionModels/components/scenarioWidgets/models';
import { entityLinkToCustomDataKey } from 'app/modules/detectionModels/components/scenarioWidgets/helpers';
import { U21DataLabelSelect } from 'app/shared/u21-ui/components/dashboard';

const CLIENT_FINGERPRINT_QUERY_CONFIG: QueryBuilderConfigStruct = {
  type: '!struct',
  label: 'client_fingerprint',
  subfields: {
    first_seen: {
      type: 'datetime',
      label: 'first_seen',
    },
    last_seen: {
      type: 'datetime',
      label: 'last_seen',
    },
    client_fingerprint: {
      type: 'text',
      label: 'client_fingerprint',
    },
  },
};

const NODE_NAME_TO_TABLE_MAP = {
  email: 'email_address',
  client_fingerprint: 'client_fingerprint',
  phone: 'phone_number',
  instrument: 'txn_instrument',
};

// Object mapping from connection_type -> list of fields we can match on
// Only doing addresses for now as we need to update the backend to work
// when not matching instrument IDs directly
export const FIELD_TO_MATCHING_ATTRIBUTES: Partial<
  Record<EntityGraphLink, string[]>
> = {
  address: ['country', 'state', 'zip_code', 'city', 'street', 'status', 'type'],
};

export const QUERY_CONFIG_MAP: Partial<
  Record<EntityGraphLink, QueryBuilderConfigStruct>
> = {
  address: {
    type: '!struct',
    label: 'address',
    subfields: {
      created_at: {
        type: 'datetime',
        label: 'created_at',
      },
      updated_at: {
        type: 'datetime',
        label: 'updated_at',
      },
      street: {
        type: 'text',
        label: 'street',
      },
      city: {
        type: 'text',
        label: 'city',
      },
      state: {
        type: 'text',
        label: 'state',
      },
      country: {
        type: 'text',
        label: 'country',
      },
      status: {
        type: 'text',
        label: 'status',
      },
      zip_code: {
        type: 'text',
        label: 'zip_code',
      },
      type: {
        type: 'text',
        label: 'type',
      },
    },
  },
  ip_address: {
    type: '!struct',
    label: 'ip_address',
    subfields: {
      created_at: {
        type: 'datetime',
        label: 'created_at',
      },
      updated_at: {
        type: 'datetime',
        label: 'updated_at',
      },
      first_seen: {
        type: 'datetime',
        label: 'first_seen',
      },
      last_seen: {
        type: 'datetime',
        label: 'last_seen',
      },
    },
  },
  instrument: {
    type: '!struct',
    label: 'txn_instrument',
    subfields: {
      created_at: {
        type: 'datetime',
        label: 'created_at',
      },
      updated_at: {
        type: 'datetime',
        label: 'updated_at',
      },
      registered_at: {
        type: 'datetime',
        label: 'registered_at',
      },
      instrument_type: {
        type: 'text',
        label: 'instrument_type',
      },
      instrument_subtype: {
        type: 'text',
        label: 'instrument_subtype',
      },
      status: {
        type: 'text',
        label: 'status',
      },
    },
  },
  phone: {
    type: '!struct',
    label: 'phone_number',
    subfields: {
      created_at: {
        type: 'datetime',
        label: 'created_at',
      },
      is_verified: {
        type: 'select',
        label: 'is_verified',
        fieldSettings: {
          listValues: { TRUE: 'TRUE', FALSE: 'FALSE' },
        },
      },
    },
  },
  client_fingerprint: CLIENT_FINGERPRINT_QUERY_CONFIG,
  email: {
    type: '!struct',
    label: 'email_address',
    subfields: {
      created_at: {
        type: 'datetime',
        label: 'created_at',
      },
    },
  },
  physical_id: {
    type: '!struct',
    label: 'physical_id',
    subfields: {
      created_at: {
        type: 'datetime',
        label: 'created_at',
      },
      registered_on: {
        type: 'datetime',
        label: 'registered_on',
      },
      type: {
        type: 'text',
        label: 'type',
      },
      state: {
        type: 'text',
        label: 'state',
      },
      country: {
        type: 'text',
        label: 'country',
      },
      status: {
        type: 'text',
        label: 'status',
      },
      name: {
        type: 'text',
        label: 'name',
      },
    },
  },
  geolocation: {
    type: '!struct',
    label: 'geolocation',
    subfields: {
      created_at: {
        type: 'datetime',
        label: 'created_at',
      },
      country: {
        type: 'text',
        label: 'country',
      },
      country_iso: {
        type: 'text',
        label: 'country_iso',
      },
      city: {
        type: 'text',
        label: 'city',
      },
      postal_code: {
        type: 'text',
        label: 'postal_code',
      },
    },
  },
};

interface OwnProps {
  connectionType: EntityGraphLink;
  fullConnectionName: string;
  onClose: () => void;
  viewOnly?: boolean;
  title?: string;
  baseTable?: string | undefined;
  embeddedFilters?: EmbeddedFilters;
  onEmbeddedFilterChanged: (
    fullConnectionName: string,
    filters: EditingRuleFiltersModel,
  ) => void;
}

const getInitialRuleFilters = (
  fullConnectionName: string,
  embeddedFilters: EmbeddedFilters,
  viewOnly: boolean,
  qbConfig: QbConfig,
): EditingRuleFiltersModel => {
  const ruleFilters = embeddedFilters?.[fullConnectionName];
  if (viewOnly && ruleFilters) {
    const queryTree = QbUtils.checkTree(
      QbUtils.loadTree(ruleFilters.query_tree as unknown as JsonGroup),
      qbConfig,
    );
    return {
      ...ruleFilters,
      query_tree: queryTree,
    };
  }
  return ruleFilters ?? getEmptyFilters();
};

export const GraphNodeFilterEditorModal = (props: OwnProps) => {
  const {
    connectionType,
    fullConnectionName,
    title = 'Filter',
    viewOnly = true,
    embeddedFilters,
    onEmbeddedFilterChanged,
    onClose,
  } = props;

  const rootQueryBuilder = useSelector(selectQueryBuilderConfig);

  const customDataSettings = useSelector(selectCustomDataSettingsByClassifier);

  const createQueryBuilderConfig = () => {
    if (connectionType === 'entity' || connectionType === 'entity_custom') {
      const qbConfig = {
        entity: getEmbeddedFilterFields(
          rootQueryBuilder.fields,
          'entity',
          'entity',
          undefined,
          ONLY_USERS_FIELD_KEY,
        ),
      };
      for (const subfield of Object.keys(qbConfig.entity.subfields)) {
        if (qbConfig.entity.subfields?.[subfield]?.type === 'datetime') {
          if (Array.isArray(qbConfig.entity.subfields[subfield].operators)) {
            // Delete the operators to use the default ones provided in the
            // create config so we can have the 'relative_time_ago' added
            qbConfig.entity.subfields[subfield].operators.push(
              'relative_time_ago',
            );
          }
        }
      }
      return createConfig(qbConfig, false, viewOnly, true);
    }
    // Fix to ensure that we search from the correct table on the backend for the "client_finterprints"
    const paramKey = NODE_NAME_TO_TABLE_MAP[connectionType] ?? connectionType;
    const qbFieldsConfig = { [paramKey]: QUERY_CONFIG_MAP[connectionType] };
    return createConfig(qbFieldsConfig, false, viewOnly, true);
  };

  const qbConfig = createQueryBuilderConfig();
  const [filters, setFilters] = useState<EditingRuleFiltersModel>(
    getInitialRuleFilters(
      fullConnectionName,
      embeddedFilters ?? {},
      viewOnly,
      qbConfig,
    ),
  );

  const isEntityNode =
    connectionType === 'entity' || connectionType === 'entity_custom';

  // Don't render component if we are filtering fields but we weren't able to obtain a valid config
  if (!QUERY_CONFIG_MAP[connectionType] && !isEntityNode) {
    return null;
  }

  const canSave = isValidTree(filters.query_tree);

  const onSave = () => {
    // IF we got a callback function just call it
    onEmbeddedFilterChanged(fullConnectionName, filters);
    onClose();
  };

  const handleTreeChange = (tree: ImmutableTree) => {
    if (viewOnly) return;
    setFilters({
      ...filters,
      query_tree: tree,
      raw_sql: QbUtils.sqlFormat(tree, qbConfig) || '',
    });
  };

  const handleTagChange = (
    tagNameType: 'inclusion_tag_names' | 'exclusion_tag_names',
    newTagNames: string[],
  ) => {
    if (viewOnly) return;
    setFilters({
      ...filters,
      [tagNameType]: newTagNames,
    });
  };

  const renderFilters = () => {
    return (
      <>
        <div className={`${styles.fontStyle} ${styles.propFilterLabel}`}>
          Filter by field:
        </div>
        <div>
          <Query
            value={filters.query_tree}
            {...qbConfig}
            onChange={handleTreeChange}
            renderBuilder={(builderProps) => (
              <div className="query-builder-container">
                <div className="query-builder">
                  <Builder {...builderProps} />
                </div>
              </div>
            )}
          />
        </div>
        {isEntityNode && (
          <div className={styles.filterTagContainer}>
            <div className={styles.filterTagSection}>
              <U21DataLabelSelect
                label=""
                valueType="name"
                disabled={viewOnly}
                value={filters.inclusion_tag_names ?? []}
                onChange={(value: string[]) =>
                  handleTagChange('inclusion_tag_names', value)
                }
              />
            </div>
            <div className={styles.filterTagSection}>
              <U21DataLabelSelect
                label=""
                valueType="name"
                disabled={viewOnly}
                value={filters.exclusion_tag_names ?? []}
                onChange={(value: string[]) =>
                  handleTagChange('exclusion_tag_names', value)
                }
              />
            </div>
          </div>
        )}
      </>
    );
  };

  const renderGrouping: () => ReactElement | null = () => {
    const selectionOptions: string[] =
      FIELD_TO_MATCHING_ATTRIBUTES[connectionType] ?? [];
    const configKey = entityLinkToCustomDataKey(connectionType);
    const customOptions: U21SelectOptionProps[] =
      (configKey &&
        customDataSettings?.[configKey]
          ?.filter(
            (val: CustomDataSettingsConfigResponse) =>
              val.user_facing_label ?? val.key_path.length > 0,
          )
          ?.map((val: CustomDataSettingsConfigResponse) => ({
            text: `${val.user_facing_label ?? val.key_path.join(',')}`,
            value: `custom:${val.id}`,
          }))) ??
      [];
    if (!selectionOptions && customOptions.length === 0) {
      return null;
    }
    const selectOptionProps: U21SelectOptionProps[] = selectionOptions.map(
      (value) => ({ text: value, value }),
    );
    const allGroupings = selectOptionProps.concat(customOptions);

    const handleGroupingChange = (groupings: string[]) => {
      if (viewOnly) return;
      setFilters({
        ...filters,
        grouping: groupings,
      });
    };
    return (
      <>
        <div className={`${styles.fontStyle} ${styles.propFilterLabel}`}>
          Match on field(s):
        </div>
        <U21MultiSelect
          options={allGroupings}
          disabled={viewOnly}
          onChange={handleGroupingChange}
          value={filters.grouping}
        />
      </>
    );
  };

  return (
    <>
      <U21Modal
        size="large"
        onClose={onClose}
        onAction={!viewOnly ? onSave : undefined}
        open
        title={title || `Editing ${startCase(connectionType)}`}
        actionButtonProps={
          !viewOnly
            ? {
                children: 'Save',
                disabled: !canSave,
              }
            : undefined
        }
      >
        {renderGrouping()}
        {renderFilters()}
      </U21Modal>
      {!viewOnly && (
        <ReactTooltip id={connectionType} effect="solid" place="top" />
      )}
    </>
  );
};
