import { SummaryViewConfigGroup } from 'app/modules/summarySettings/types';

export const RESERVED_GROUP_NAMES = new Set(['other', 'unit21 metadata']);

export const EMPTY_SUMMARY_VIEW_CONFIG_GROUP: Omit<
  SummaryViewConfigGroup,
  'id'
> = {
  group: '',
  fields: [],
};

export const SUMMARY_CONFIG_GROUP_ERRORS = {
  noName: 'Groups must have a name.',
  duplicateName: 'Groups must have unique names.',
  reservedName: '"Other" and "Unit21 Metadata" are both reserved names.',
  noFields: 'All groups must have at least one field.',
} as const;
