// Models
import { DropdownActionTypes } from 'app/shared/dropdown/models';

// Utils
import { createSelector } from 'reselect';
import { getAgentDisplayName } from 'app/modules/agents/utils';

// Components
import { U21Avatar, U21SelectOptionProps } from 'app/shared/u21-ui/components';

export const selectDropdownAgents = (state: RootState) => {
  return state.dropdown.agents || {};
};

export const selectAgentOptions = createSelector(
  [selectDropdownAgents],
  (agents): U21SelectOptionProps[] =>
    Object.values(agents).map((i) => {
      const name = getAgentDisplayName(i);
      return {
        icon: <U21Avatar name={name} src={i.picture} />,
        text: name,
        value: i.id,
      };
    }),
);

export const selectActiveAgentOptions = createSelector(
  [selectDropdownAgents],
  (agents) =>
    Object.values(agents).reduce((acc: U21SelectOptionProps[], i) => {
      if (i.status === 'ACTIVE') {
        const name = getAgentDisplayName(i);
        acc.push({
          icon: <U21Avatar name={name} src={i.picture} />,
          text: name,
          value: i.id,
        });
      }
      return acc;
    }, []),
);

export const selectActiveQueueAgentOptions = (
  selectQueue: number | undefined,
) =>
  createSelector([selectDropdownAgents], (agents) =>
    Object.values(agents).reduce((acc: U21SelectOptionProps[], i) => {
      if (
        i.status === 'ACTIVE' &&
        selectQueue &&
        i.accessible_queues.includes(selectQueue)
      ) {
        const name = getAgentDisplayName(i);
        acc.push({
          icon: <U21Avatar name={name} src={i.picture} />,
          text: name,
          value: i.id,
        });
      }
      return acc;
    }, []),
  );

export const selectActiveQueuesAgentOptions = (selectQueues: number[]) =>
  createSelector([selectDropdownAgents], (agents) =>
    Object.values(agents).reduce((acc: U21SelectOptionProps[], i) => {
      const agentAccessibleQueues = new Set(i.accessible_queues);
      if (
        i.status === 'ACTIVE' &&
        selectQueues.every((queue) => agentAccessibleQueues.has(queue))
      ) {
        const name = getAgentDisplayName(i);
        acc.push({
          icon: <U21Avatar name={name} src={i.picture} />,
          text: name,
          value: i.id,
        });
      }
      return acc;
    }, []),
  );

export const selectDropdownTeams = (state: RootState) => {
  return state.dropdown.teams || {};
};

export const selectDropdownOrgs = (state: RootState) => {
  return state.dropdown.orgs || {};
};

export const selectDropdownUnits = (state: RootState) => {
  return state.dropdown.units || {};
};

// Loading
export const selectDropdownAgentsLoading = (state: RootState): boolean =>
  Boolean(state.loading[DropdownActionTypes.RETRIEVE_DROPDOWN_AGENTS]);

export const selectDropdownTeamsLoading = (state: RootState): boolean =>
  Boolean(state.loading[DropdownActionTypes.RETRIEVE_DROPDOWN_TEAMS]);

export const selectDropdownOrgsLoading = (state: RootState): boolean =>
  Boolean(state.loading[DropdownActionTypes.RETRIEVE_DROPDOWN_ORGS]);
