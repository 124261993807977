import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { CommentsActionTypes } from 'app/modules/comments/models';

// Actions
import {
  addComment as addCommentAction,
  addCommentSuccess,
  retrieveComments,
} from 'app/modules/comments/actions';

// API
import { addComment } from 'app/shared/api/comments';

const rootAction = CommentsActionTypes.ADD_COMMENT;
function* addCommentFlow({ payload }: ReturnType<typeof addCommentAction>) {
  const config = {
    rootAction,
    request: call(addComment, payload),
    success: function* onSuccess() {
      yield put(addCommentSuccess());
      // fetch all new comments from the backend
      yield put(retrieveComments(payload.articleId));
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watchAddComment() {
  yield takeLatest(rootAction, addCommentFlow);
}
