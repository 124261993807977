import { CallEffect, put, select } from 'redux-saga/effects';
import makeActions from 'app/shared/sagas/make-actions';

// Actions
import { setLastRequestDate } from 'app/modules/session/actions';

// Helpers
import isErrorStatus from 'app/shared/utils/is-error-status/is-error-status';
import emptyFn from 'app/shared/utils/empty-fn';
import { sendErrorToast, sendSuccessToast } from 'app/shared/toasts/actions';

// Constants
import { LAST_REQUEST_SAGAS_TO_IGNORE } from 'app/modules/session/constants';

// Selectors
import { selectTrackLastActivity } from 'app/modules/orgSettings/selectors';

// Utils
import { consoleWarn } from 'app/shared/utils/console';
import { trackSagas } from 'app/shared/utils/heap';
import routes from 'app/shared/utils/routes';
import { isFourOhFourRoute } from 'app/shared/sagas/helpers';
import { history } from 'app/store/browserHistory';

type PatronusResponse = Response | { error_code: string; message: string };
const EMPTY_ERROR_RESPONSE: PatronusResponse = { error_code: '', message: '' };

// this component dispatches loading and error states
// in case of error, it also handles sentry logging
export interface MakeRequestProps {
  rootAction: string;
  request: CallEffect<any>;
  pending?: () => void;
  success?: (a) => void;
  error?: (response: PatronusResponse, errorCode: number) => void;
  errorToastMessage?: string;
  successToastMessage?: string;
  handleFourOhFour?: boolean;
}

/**
 * @deprecated Use thunks with u21CreateAsyncThunk instead
 */
export default function* makeSagaRequest(props: MakeRequestProps) {
  const {
    rootAction,
    request,
    pending = emptyFn,
    success = emptyFn,
    error = emptyFn,
    errorToastMessage,
    successToastMessage,
  } = props;
  const actions = makeActions(rootAction);

  // Heap analytics
  trackSagas(rootAction, 'Hit');

  // keeping track of the last request if we need to
  // reads (selects) are ~10x faster than writes (actions that update redux store)
  // this check ensures we don't unnecessarily make this write unless it's required. currently, it is used to logout an agent after inactivity
  const trackLastRequest = Boolean(
    yield select(selectTrackLastActivity) &&
      !LAST_REQUEST_SAGAS_TO_IGNORE[rootAction],
  );
  if (trackLastRequest) {
    yield put(setLastRequestDate(new Date()));
  }

  try {
    // do not dispatch pending action twice
    if (props.pending) {
      yield pending();
    } else {
      yield put(actions.pending()); // dispatches redux action
    }

    const result = yield request;
    if (typeof successToastMessage === 'string') {
      yield put(sendSuccessToast(successToastMessage));
    }

    // Heap Analytics
    trackSagas(rootAction, 'Succeeded');

    yield success(result);
    return result;
  } catch (response) {
    consoleWarn(response);

    // Heap Analytics
    trackSagas(rootAction, 'Failed', { code: response.status });

    if (isErrorStatus(response.status) && isFourOhFourRoute(props.rootAction)) {
      history.push(routes.lumos.fourOhfour);
    }

    // Needed for when the response is HTML, not JSON. Otherwise, the yield error never gets triggered as response.json() or JSON.parse(resText) will fail https://daveceddia.com/unexpected-token-in-json-at-position-0/
    try {
      const resText = yield response.text();
      const resJSON = yield JSON.parse(resText);
      // indicates there is an error message, send the error message
      if (typeof resJSON.message === 'string' && resJSON.message.length > 0) {
        yield put(sendErrorToast(resJSON.message));
        // otherwise, send passed in error toast if it exists
      } else if (errorToastMessage) {
        yield put(sendErrorToast(errorToastMessage));
      }
      // if an error function was passed in (used for custom error handling)
      if (props.error) {
        yield error(resJSON, response.status);
      }
      yield put(actions.error(resJSON)); // dispatches redux action, cancels loading state
    } catch (e) {
      // send error toast if it exists
      if (errorToastMessage) {
        yield put(sendErrorToast(errorToastMessage));
      }
      if (props.error) {
        yield error(EMPTY_ERROR_RESPONSE, response.status);
      }
      yield put(actions.error(EMPTY_ERROR_RESPONSE)); // dispatches redux action
    }
  }
  return undefined;
}
