// Models
import {
  BaseCustomConfigPayload,
  CustomTableConfig,
  CustomTableConfigPayload,
  TableConfigActionTypes,
} from 'app/shared/CustomConfig/models';

import { createAction, ActionsUnion } from 'app/shared/utils/action-helpers';

export const saveCustomConfig = (payload: BaseCustomConfigPayload) =>
  createAction(TableConfigActionTypes.SAVE_TABLE_CONFIG, payload);

export const saveCustomConfigSuccess = (payload: CustomTableConfig) => {
  return createAction(
    TableConfigActionTypes.SAVE_TABLE_CONFIG_SUCCESS,
    payload,
  );
};

export const retrieveOrgTableConfig = () =>
  createAction(TableConfigActionTypes.RETRIEVE_ORG_TABLE_CONFIG);

export const retrieveOrgTableConfigSuccess = (payload) =>
  createAction(
    TableConfigActionTypes.RETRIEVE_ORG_TABLE_CONFIG_SUCCESS,
    payload,
  );

export const retrieveTableConfigSuccess = (
  payload: CustomTableConfig[] | undefined,
) => {
  return createAction(
    TableConfigActionTypes.RETRIEVE_TABLE_CONFIG_SUCCESS,
    payload,
  );
};

export const saveLocalTableConfig = (payload: CustomTableConfigPayload) =>
  createAction(TableConfigActionTypes.SAVE_LOCAL_TABLE_CONFIG, payload);

const TableConfigReduxActions = {
  saveCustomConfig,
  saveCustomConfigSuccess,
  retrieveOrgTableConfig,
  retrieveOrgTableConfigSuccess,
  retrieveTableConfigSuccess,
  saveLocalTableConfig,
};

export type TableConfigActions = ActionsUnion<typeof TableConfigReduxActions>;
