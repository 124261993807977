// Models
import {
  UploadsActionTypes,
  UploadDatafile,
  RetrieveFilesPayload,
  FileToUpload,
  DatafilesListResponse,
  Upload,
  UploadFileProgress,
} from 'app/modules/uploads/models';
import { ActionsUnion, createAction } from 'app/shared/utils/action-helpers';

export const uploadDatafileAsync = (model: UploadDatafile) =>
  createAction(UploadsActionTypes.UPLOAD_DATAFILE_ASYNC, model);

export const setFileUploadProgress = (payload: UploadFileProgress) =>
  createAction(UploadsActionTypes.SET_FILE_UPLOAD_PROGRESS, payload);

export const uploadDatafileAsyncSuccess = (payload: Upload) =>
  createAction(UploadsActionTypes.UPLOAD_DATAFILE_ASYNC_SUCCESS, payload);

export const uploadDatafileAsyncError = (payload: {
  name: string;
  errorMessage: string;
}) => createAction(UploadsActionTypes.UPLOAD_DATAFILE_ASYNC_ERROR, payload);

export const retrieveDatafiles = (model: RetrieveFilesPayload) =>
  createAction(UploadsActionTypes.RETRIEVE_DATAFILES, model);

export const retrieveDatafilesSuccess = (response: DatafilesListResponse) =>
  createAction(UploadsActionTypes.RETRIEVE_DATAFILES_SUCCESS, response);

export const deleteDatafile = (id: number) =>
  createAction(UploadsActionTypes.DELETE_DATAFILE, id);

export const deleteDatafileSuccess = (id: number) =>
  createAction(UploadsActionTypes.DELETE_DATAFILE_SUCCESS, id);

export const setFilesToUpload = (payload: FileToUpload[]) =>
  createAction(UploadsActionTypes.SET_FILES_TO_UPLOAD, payload);

export const setFilesToUploadAsync = (payload: FileToUpload[]) =>
  createAction(UploadsActionTypes.SET_FILES_TO_UPLOAD_ASYNC, payload);

export const processDatafile = (id: number, executeRules: boolean) =>
  createAction(UploadsActionTypes.PROCESS_DATAFILE, {
    payload1: id,
    payload2: executeRules,
  });

export const processDatafileSuccess = (payload: number) =>
  createAction(UploadsActionTypes.PROCESS_DATAFILE_SUCCESS, payload);

const UploadsActionsList = {
  retrieveDatafiles,
  retrieveDatafilesSuccess,
  setFilesToUpload,
  setFilesToUploadAsync,
  setFileUploadProgress,
  uploadDatafileAsyncSuccess,
  uploadDatafileAsyncError,
  processDatafile,
  processDatafileSuccess,
  deleteDatafileSuccess,
};

type UploadsActions = ActionsUnion<typeof UploadsActionsList>;
export default UploadsActions;
