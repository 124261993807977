// Models
import { EmailAddressResponse } from 'app/shared/types/emailAddress';

// Components
import { Label } from 'semantic-ui-react';

// Styles
import scssStyles from 'app/shared/events/styles/EventTable.module.scss';

interface Props {
  value: EmailAddressResponse[];
}

export const EmailAddresses = ({ value }: Props) => {
  if (!value?.length) {
    return null;
  }
  return (
    <Label.Group>
      {value.map((item) => (
        <Label key={item.value_} className={scssStyles.valueLabel}>
          {item.value_}
        </Label>
      ))}
    </Label.Group>
  );
};
