import { FilterOption } from 'app/modules/filters/models';
import { FILTER_TYPE } from 'app/modules/filters/constants';

export const WATCHLIST_FILTER_KEYS: Readonly<Record<string, string>> = {
  CREATED_AT: 'created_at',
  MATCH_SCORE: 'match_score',
  PROVIDER: 'provider',
  WATCHLIST_TYPE: 'type',
  WATCHLIST_NAMES: 'names',
};

export const WATCHLIST_FILTER_KEYS_SET = new Set<string>(
  Object.values(WATCHLIST_FILTER_KEYS),
);

export const WATCHLIST_FILTER_OPTIONS: FilterOption[] = [
  {
    key: WATCHLIST_FILTER_KEYS.CREATED_AT,
    label: 'Created at',
    type: FILTER_TYPE.DATE_RANGE,
  },
  {
    key: WATCHLIST_FILTER_KEYS.MATCH_SCORE,
    label: 'Match score',
    type: FILTER_TYPE.NUMBER_RANGE,
  },
  {
    key: WATCHLIST_FILTER_KEYS.PROVIDER,
    label: 'Provider',
    type: FILTER_TYPE.TEXT,
  },
  {
    key: WATCHLIST_FILTER_KEYS.WATCHLIST_TYPE,
    label: 'Watchlist type',
    type: FILTER_TYPE.TEXT,
  },
];
