import { call, put, takeEvery, take } from 'redux-saga/effects';

import { UploadsActionTypes } from 'app/modules/uploads/models';
import {
  uploadDatafileAsync as uploadDatafileAction,
  uploadDatafileAsyncSuccess,
  uploadDatafileAsyncError,
  setFileUploadProgress,
} from 'app/modules/uploads/actions';
import createUploadFileChannel from 'app/modules/uploads/sagas/createFileUploadChannel';
import routes from 'app/shared/utils/routes';

const rootAction = UploadsActionTypes.UPLOAD_DATAFILE_ASYNC;
export function* uploadDatafileAsync({
  payload,
}: ReturnType<typeof uploadDatafileAction>) {
  const channel = yield call(
    createUploadFileChannel,
    routes.patronus.uploadsDatafiles,
    payload.file,
  );
  while (true) {
    const {
      percent = 0,
      total = 0,
      loaded = 0,
      xhr,
      err,
      success,
    } = yield take(channel);
    if (err) {
      yield put(
        uploadDatafileAsyncError({
          name: payload.file.name,
          errorMessage: err.message,
        }),
      );
      return;
    }
    if (success) {
      yield put(uploadDatafileAsyncSuccess(JSON.parse(success)));
      return;
    }
    yield put(
      setFileUploadProgress({
        file: payload.file,
        percent,
        total,
        loaded,
        xhr,
      }),
    );
  }
}

export default function* watch() {
  yield takeEvery(rootAction, uploadDatafileAsync);
}
