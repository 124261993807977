export enum AnalyticsEvents {
  U21BUTTON_ON_CLICK = 'U21BUTTON_ON_CLICK',
  U21CHECKBOX_ON_CLICK = 'U21CHECKBOX_ON_CLICK',
  U21DATEPICKER_ON_CHANGE = 'U21DATEPICKER_ON_CHANGE',
  U21DATERANGEPICKER_ON_CHANGE = 'U21DATERANGEPICKER_ON_CHANGE',
  U21DATETIMEPICKER_ON_CHANGE = 'U21DATETIMEPICKER_ON_CHANGE',
  U21FILTERS_CHANGED = 'U21FILTERS_CHANGED',
  U21MENUITEM_ON_CLICK = 'U21MENUITEM_ON_CLICK',
  U21MULTISELECT_ON_CHANGE = 'U21MULTISELECT_ON_CHANGE',
  U21SELECT_ON_CHANGE = 'U21SELECT_ON_CHANGE',
  U21SORT_CHANGED = 'U21SORT_CHANGED',
}
export interface AnalyticsConfig {
  track: (eventName: string, properties: AnalyticsPayload) => void;
  events: Partial<Record<AnalyticsEvents, EventConfig<any>>>;
}

export interface EventConfig<T = void> {
  name: string;
  createPayload:
    | ((props: Record<string, any>) => AnalyticsPayload | null)
    | ((
        props: Record<string, any>,
        state: Record<string, any>,
      ) => AnalyticsPayload | null)
    | ((
        props: Record<string, any>,
        state: Record<string, any>,
        additional: T,
      ) => AnalyticsPayload | null);
}

export type AnalyticsPayload = Record<string, any>;
