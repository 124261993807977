import { get, post, destroy } from 'app/shared/utils/fetchr';
import {
  DeleteAgentTableConfigRequest,
  UpdateOrgTableConfigRequest,
} from 'app/modules/tableSettings/requests';
import {
  DeleteAgentTableConfigResponse,
  OrgTableConfigResponse,
  UpdateOrgTableConfigResponse,
} from 'app/modules/tableSettings/responses';

export const getTableSettings = (): Promise<OrgTableConfigResponse> =>
  get(`/table/settings/list`);

export const getAgentTableSettings = (): Promise<OrgTableConfigResponse> =>
  get(`/table/settings/agent/list`);

export const updateTableSettings = (
  payload: UpdateOrgTableConfigRequest,
): Promise<UpdateOrgTableConfigResponse> =>
  post(`/table/settings/create`, payload);

export const updateAgentTableSettings = (
  payload: UpdateOrgTableConfigRequest,
): Promise<UpdateOrgTableConfigResponse> =>
  post(`/table/settings/agent/create`, payload);

export const deleteAgentTableSettings = (
  payload: DeleteAgentTableConfigRequest,
): Promise<DeleteAgentTableConfigResponse> =>
  destroy(`/table/settings/agent/${payload.id}/delete`);
