import { u21CreateAsyncThunk } from 'app/shared/thunk/u21CreateAsyncThunk';
import { u21CreateSlice } from 'app/shared/thunk/u21CreateSlice';
import { sendErrorToast } from 'app/shared/toasts/actions';
import { ERROR_STATE, LOADING_STATE } from 'app/shared/types/utils/asyncStatus';
import {
  deleteAgentTableSettings,
  getAgentTableSettings,
  getTableSettings,
  updateAgentTableSettings,
  updateTableSettings,
} from 'app/modules/tableSettings/api';
import {
  DeleteAgentTableConfigRequest,
  UpdateOrgTableConfigRequest,
} from 'app/modules/tableSettings/requests';
import {
  OrgTableConfigResponse,
  UpdateOrgTableConfigResponse,
} from 'app/modules/tableSettings/responses';
import { TableSettingsState } from 'app/modules/tableSettings/types';

const TS_NAME = 'tableSettings';
const initialState: TableSettingsState = {
  tableSettings: LOADING_STATE,
  agentTableSettings: LOADING_STATE,
  updateTableSettings: { status: 'COMPLETE' },
  updateAgentTableSettings: { status: 'COMPLETE' },
};

export const getTableSettingsThunk = u21CreateAsyncThunk<
  void,
  OrgTableConfigResponse
>(`${TS_NAME}/GET_TABLE_SETTINGS`, async (_, { dispatch }) => {
  try {
    return await getTableSettings();
  } catch (e) {
    dispatch(sendErrorToast('Something went wrong'));
    throw e;
  }
});

export const getAgentTableSettingsThunk = u21CreateAsyncThunk<
  void,
  OrgTableConfigResponse
>(`${TS_NAME}/GET_AGENT_TABLE_SETTINGS`, async (_, { dispatch }) => {
  try {
    return await getAgentTableSettings();
  } catch (e) {
    dispatch(sendErrorToast('Something went wrong'));
    throw e;
  }
});

export const updateTableSettingsThunk = u21CreateAsyncThunk<
  UpdateOrgTableConfigRequest,
  UpdateOrgTableConfigResponse
>(`${TS_NAME}/UPDATE_TABLE_SETTINGS`, async (payload, { dispatch }) => {
  try {
    const result = await updateTableSettings(payload);
    // Agent settings might have updated since agent
    // table settings use org table settings and defaults as fallback values
    dispatch(getAgentTableSettingsThunk());
    return result;
  } catch (e) {
    dispatch(sendErrorToast('Something went wrong'));
    throw e;
  }
});

export const updateAgentTableSettingsThunk = u21CreateAsyncThunk<
  UpdateOrgTableConfigRequest,
  UpdateOrgTableConfigResponse
>(`${TS_NAME}/UPDATE_AGENT_TABLE_SETTINGS`, async (payload, { dispatch }) => {
  try {
    return await updateAgentTableSettings(payload);
  } catch (e) {
    dispatch(sendErrorToast('Something went wrong'));
    throw e;
  }
});

export const resetAgentTableSettingsThunk = u21CreateAsyncThunk<
  DeleteAgentTableConfigRequest,
  void
>(`${TS_NAME}/RESET_AGENT_TABLE_SETTINGS`, async (payload, { dispatch }) => {
  try {
    await deleteAgentTableSettings(payload);
    // Fetch agent settings and let the backend recalculate the agent settings
    // from defaults or from org table settings
    await dispatch(getAgentTableSettingsThunk());
  } catch (e) {
    dispatch(sendErrorToast('Something went wrong'));
    throw e;
  }
});

const tableSettingsSlice = u21CreateSlice({
  name: TS_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTableSettingsThunk.pending, (draft) => {
        draft.tableSettings = LOADING_STATE;
      })
      .addCase(getTableSettingsThunk.rejected, (draft) => {
        draft.tableSettings = ERROR_STATE;
      })
      .addCase(getTableSettingsThunk.fulfilled, (draft, { payload }) => {
        draft.tableSettings = {
          status: 'COMPLETE',
          ...payload,
        };
      })
      .addCase(getAgentTableSettingsThunk.pending, (draft) => {
        draft.agentTableSettings = LOADING_STATE;
      })
      .addCase(getAgentTableSettingsThunk.rejected, (draft) => {
        draft.agentTableSettings = ERROR_STATE;
      })
      .addCase(getAgentTableSettingsThunk.fulfilled, (draft, { payload }) => {
        draft.agentTableSettings = {
          status: 'COMPLETE',
          ...payload,
        };
      })
      .addCase(updateTableSettingsThunk.pending, (draft) => {
        draft.updateTableSettings = LOADING_STATE;
      })
      .addCase(updateTableSettingsThunk.fulfilled, (draft, { payload }) => {
        draft.updateTableSettings = { status: 'COMPLETE' };
        // This shouldn't be possible at this point, it's only here for the type system
        if (draft.tableSettings.status !== 'COMPLETE') {
          return;
        }

        draft.tableSettings.data = draft.tableSettings.data.map((config) => {
          if (config.type_classifier === payload.data.type_classifier) {
            return payload.data;
          }
          return config;
        });
      })
      .addCase(
        updateAgentTableSettingsThunk.fulfilled,
        (draft, { payload }) => {
          draft.updateAgentTableSettings = {
            status: 'COMPLETE',
          };
          // This shouldn't be possible at this point, it's only here for the type system
          if (draft.agentTableSettings.status !== 'COMPLETE') {
            return;
          }

          draft.agentTableSettings.data = draft.agentTableSettings.data.map(
            (config) => {
              if (config.type_classifier === payload.data.type_classifier) {
                return payload.data;
              }
              return config;
            },
          );
        },
      );
  },
});

export const { name, reducer, actions } = tableSettingsSlice;
