import React, { Component } from 'react';
import { set, get, pick, fromPairs, cloneDeep } from 'lodash';
import produce from 'immer';
import autoBindReact from 'auto-bind/react';

// Redux
import { connect } from 'react-redux';

// Models
import { ScenarioConstants } from 'app/modules/rules/models';
import { WidgetProps } from 'app/modules/detectionModels/components/scenarioWidgets/models';
import { AnyObjectType } from 'app/shared/models';

// Components
import { Checkbox, Table } from 'semantic-ui-react';

// Selectors
import { selectOrgEnums } from 'app/modules/orgSettings/selectors';
import {
  U21Button,
  U21Select,
  U21SelectOptionProps,
  U21Typography,
} from 'app/shared/u21-ui/components';
import { IconTrash } from '@u21/tabler-icons';

// Constants
const DECIMAL_RADIX = 10;

interface Field {
  mustMatch: boolean;
  atLeastNMustMatch: boolean;
  fuzzyMatch: number;
}

interface FieldTable {
  [field: string]: Field;
}

interface AllState {
  fields: FieldTable;
  selectedCustomFields: Set<string>;
  suspendedFilter?: string;
}

const mapStateToProps = (state: RootState) => ({
  orgEnums: selectOrgEnums(state),
});

type AllProps = WidgetProps & ReturnType<typeof mapStateToProps>;

/*
This widget fills editingRule with an object of type

  { fields: FieldTable, numberMustMatch: number }

The widget component saves data for all fields in its state. When the user
changes a field, it updates the state, then updates editingRule with a table
of fields with disabled fields (determined by the blacklist type) filtered
out.

The reason we save the field data in the state and not directly in
editingRule is to preserve data for fields the user has disabled. So if the
user fills in some fields, changes blacklists (disabling the fields), then
changes back, their data isn't lost.

`mustMatch` and `atLeastNMustMatch` checkboxes for a field are mutually exclusive. 
They cannot be both selected at the same time. 
*/

const DEFAULT_FIELDS = ['First Name', 'Last Name', 'Phone', 'Email'];

const getInitialState = (): AllState => {
  const initialFieldState = {
    mustMatch: false,
    atLeastNMustMatch: false,
    fuzzyMatch: 100,
  };

  return {
    fields: fromPairs(
      DEFAULT_FIELDS.map((field) => [field, initialFieldState]),
    ),
    selectedCustomFields: new Set(),
    suspendedFilter: 'suspended',
  };
};

class WonoloScenarioPerBlacklistEditor extends Component<AllProps, AllState> {
  onChangeHandlers: {
    [key: string]: {
      fuzzyMatch: any;
      mustMatch: any;
      atLeastNMustMatch: any;
      timeDiff: any;
    };
  };

  constructor(props) {
    super(props);
    const { viewOnly, editingScenario, orgEnums } = this.props;
    if (viewOnly) {
      // when viewOnly (validated/deployed rule) or rule has been duplicated, use existing rule content to populate state of widget
      const matchConditions =
        editingScenario.parameters[ScenarioConstants.MATCH_CONDITIONS] || {};
      const ruleContentFields = matchConditions.fields || [];
      const initialState = getInitialState();
      this.state = {
        fields: { ...initialState.fields, ...ruleContentFields },
        selectedCustomFields: initialState.selectedCustomFields,
      };
    } else {
      this.state = getInitialState();
    }
    autoBindReact(this);

    this.onChangeHandlers = {};
    DEFAULT_FIELDS.forEach((field: string) => {
      this.onChangeHandlers[field] = {
        timeDiff: this.makeOnChange(field, 'timeDiff', 'value'),
        fuzzyMatch: this.makeOnChange(field, 'fuzzyMatch', 'value'),
        mustMatch: this.makeOnChange(field, 'mustMatch', 'checked'),
        atLeastNMustMatch: this.makeOnChange(
          field,
          'atLeastNMustMatch',
          'checked',
        ),
      };
    });
    const entityCustomDataEnums: AnyObjectType =
      orgEnums.custom_data?.entity_custom_data?.keys ?? {};
    const customDataList: string[] = Object.keys(entityCustomDataEnums);
    customDataList.forEach((field) => {
      this.onChangeHandlers[field] = {
        timeDiff: this.makeOnChange(field, 'timeDiff', 'value'),
        fuzzyMatch: this.makeOnChange(field, 'fuzzyMatch', 'value'),
        mustMatch: this.makeOnChange(field, 'mustMatch', 'checked'),
        atLeastNMustMatch: this.makeOnChange(
          field,
          'atLeastNMustMatch',
          'checked',
        ),
      };
    });
  }

  makeOnChange(field: string, key: string, dataField: string) {
    return (_, data) => {
      // update the fields in state, then update editingRule using the new state
      const { fields } = this.state;
      const newState = {
        fields: produce(fields, (draft) => {
          set(draft, [field, key], data[dataField]);
        }),
      };

      this.setState(newState, () => {
        this.updateMatchConditions('fields', pick(fields, DEFAULT_FIELDS));
      });
    };
  }

  updateMatchConditions(field: string, value: any) {
    const { fields, suspendedFilter } = this.state;
    const { editingScenario, onChange } = this.props;
    const matchConditions =
      editingScenario.parameters[ScenarioConstants.MATCH_CONDITIONS] || {};

    // Widget Validation
    // - retrieve `numberMustMatch` and `fields`
    // - if any of the rows/fields has a atLeastNMustMatch checkbox that is checked, numberMustMatch >= 1
    let numberMustMatchFields: any[] = []; // array of objects, each obj has structure {mustMatch: ..., atLeastNMustMatch: ..., fuzzyMatch: ...}
    let numberMustMatch = '';
    if (field === 'numberMustMatch') {
      numberMustMatch = value;
      numberMustMatchFields = matchConditions.fields
        ? Object.values(matchConditions.fields)
        : [];
    } else {
      numberMustMatch = matchConditions.numberMustMatch;
      numberMustMatchFields = value ? Object.values(value) : [];
    }
    const atLeastNMustMatchValues: boolean[] = numberMustMatchFields.map(
      (f) => f.atLeastNMustMatch,
    );

    let isWidgetValidated = true;
    if (atLeastNMustMatchValues.includes(true)) {
      isWidgetValidated = (parseInt(numberMustMatch, DECIMAL_RADIX) || 0) >= 1;
    }
    const newEditingScenario = cloneDeep(editingScenario);
    const updatedScenario = {
      ...newEditingScenario,
      parameters: {
        ...newEditingScenario.parameters,
        suspended_filter: suspendedFilter,
        [ScenarioConstants.MATCH_CONDITIONS]: {
          [field]: value,
          fields,
        },
        [ScenarioConstants.MATCH_CONDITIONS_VALIDATION_RESULT]:
          isWidgetValidated,
      },
    };
    onChange(updatedScenario);
  }

  selectCustomField(newField: string) {
    // Set checkbox to true in the UI
    this.onChangeHandlers[newField].mustMatch(null, { checked: true });
    const { selectedCustomFields } = this.state;
    const newSelectedFields: Set<string> = new Set(selectedCustomFields);
    newSelectedFields.add(newField);
    this.setState({ selectedCustomFields: newSelectedFields });
  }

  removeCustomField(field: string) {
    // Remove field from being checked in the UI
    this.onChangeHandlers[field].mustMatch(null, { checked: false });
    const { selectedCustomFields } = this.state;
    const newSelectedFields: Set<string> = new Set(selectedCustomFields);
    newSelectedFields.delete(field);
    this.setState({ selectedCustomFields: newSelectedFields });
  }

  updateSuspendedFilter(newFilter: string) {
    const { editingScenario, onChange } = this.props;
    const newEditingScenario = cloneDeep(editingScenario);
    const updatedScenario = {
      ...newEditingScenario,
      parameters: {
        ...newEditingScenario.parameters,
        suspended_filter: newFilter,
      },
    };
    onChange(updatedScenario);
    this.setState({ suspendedFilter: newFilter });
  }

  render() {
    const { fields, selectedCustomFields, suspendedFilter } = this.state;
    const { viewOnly, orgEnums } = this.props;
    const entityCustomDataEnums: AnyObjectType =
      orgEnums.custom_data?.entity_custom_data?.keys ?? {};
    const customDataList: U21SelectOptionProps[] = Object.keys(
      entityCustomDataEnums,
    )
      // Filter out the values that have already been selected or are default fields
      .filter(
        (value) =>
          !selectedCustomFields.has(value) && !DEFAULT_FIELDS.includes(value),
      )
      .map((value) => ({ text: value, value }));

    const suspendedFlagsList: U21SelectOptionProps[] = [
      { text: 'Both', value: 'both' },
      { text: 'Suspended', value: 'suspended' },
      { text: 'Duplicate', value: 'duplicate' },
    ];

    return (
      <>
        <U21Typography>Suspended filter:</U21Typography>
        <U21Select
          disabled={viewOnly}
          clearable={false}
          value={suspendedFilter}
          onChange={this.updateSuspendedFilter}
          options={suspendedFlagsList}
        />
        <Table celled striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell />
              <Table.HeaderCell>All must match</Table.HeaderCell>
              <Table.HeaderCell width={1}>Remove</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {DEFAULT_FIELDS.map((field) => (
              <Table.Row key={field} disabled={viewOnly}>
                <Table.Cell>{field}</Table.Cell>
                <Table.Cell>
                  <Checkbox
                    disabled={
                      get(fields, [field, 'atLeastNMustMatch']) === true
                    }
                    checked={get(fields, [field, 'mustMatch'])}
                    onChange={this.onChangeHandlers[field].mustMatch}
                  />
                </Table.Cell>
                <Table.Cell />
              </Table.Row>
            ))}
            {/* Add the selected custom data fields in the org enums */}
            {[...selectedCustomFields].map((field) => (
              <Table.Row key={field} disabled={viewOnly}>
                <Table.Cell>{field}</Table.Cell>
                <Table.Cell>
                  <Checkbox
                    disabled={
                      get(fields, [field, 'atLeastNMustMatch']) === true
                    }
                    checked={get(fields, [field, 'mustMatch'])}
                    onChange={this.onChangeHandlers[field].mustMatch}
                  />
                </Table.Cell>
                <Table.Cell>
                  <U21Button
                    aria-label="Remove custom field"
                    icon={<IconTrash color="red" />}
                    onClick={() => this.removeCustomField(field)}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
            <Table.Row key="custom_data_selection" disabled={viewOnly}>
              <Table.Cell>
                <U21Select
                  disabled={viewOnly}
                  onChange={this.selectCustomField}
                  options={customDataList}
                />
              </Table.Cell>
              <Table.Cell />
              <Table.Cell />
            </Table.Row>
          </Table.Body>
        </Table>
      </>
    );
  }
}

export default connect(mapStateToProps)(WonoloScenarioPerBlacklistEditor);
