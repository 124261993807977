import { useQuery } from '@tanstack/react-query';
import { TYPE_CLASSIFICATION_QUERY_KEYS } from 'app/modules/typeClassification/queries/keys';
import { ListTypeClassificationResponse } from 'app/modules/typeClassification/responses';
import { get } from 'app/shared/utils/fetchr';

const getTypeClassifications =
  async (): Promise<ListTypeClassificationResponse> =>
    get(`/data-settings-type-classification/list`);

export const useGetTypeClassifications = () =>
  useQuery({
    queryKey: TYPE_CLASSIFICATION_QUERY_KEYS.getTypeClassifications(),
    queryFn: () => getTypeClassifications(),
    meta: { errorMessage: 'Failed to get type classifications.' },
    refetchOnWindowFocus: false,
  });
