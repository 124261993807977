// Constants
import { StyleConstants, UNIT21_COLORS } from 'app/styles/StyleConstants';
import { LABEL_PREDEFINED_COLORS } from 'app/modules/entities/constants';

// Helpers
import { generateLabelBackgroundColor } from 'app/shared/helpers';

export const getLabelStyle = (
  labelValue: string | number,
  useRandomColor?: boolean,
) => {
  const labelStyle: StyleMap = {
    color: 'white',
    fontFamily: StyleConstants.FONT_FAMILY,
    fontSize: StyleConstants.FONT_SIZE_LABEL,
  };

  if (!labelValue) {
    return labelStyle;
  }

  if (typeof labelValue === 'number') {
    // no custom label colors for numbers (i.e. ids)
    labelStyle.backgroundColor = UNIT21_COLORS.UNIT21_INDIGO;
    return labelStyle;
  }

  if (labelValue.toUpperCase() in LABEL_PREDEFINED_COLORS) {
    labelStyle.backgroundColor =
      LABEL_PREDEFINED_COLORS[labelValue.toUpperCase()];
  } else if (useRandomColor) {
    labelStyle.backgroundColor = generateLabelBackgroundColor(
      labelValue.toLowerCase(),
    );
  } else {
    labelStyle.backgroundColor = UNIT21_COLORS.UNIT21_INDIGO;
  }
  return labelStyle;
};
