import {
  EditAttachmentPayload,
  RetrieveAttachmentsPayload,
} from 'app/modules/attachmentsRefresh/requests';

import { downloadFile, post } from 'app/shared/utils/fetchr';
import { RetrieveAttachmentsResponse } from 'app/modules/attachmentsRefresh/responses';

export const downloadAttachment = (id: number, fileName: string) => {
  return downloadFile(`/attachments/download/${id}`, fileName);
};

export const updateAttachment = (
  id: number,
  payload: EditAttachmentPayload,
) => {
  return post(`/attachments/edit/${id}`, payload);
};

export const retrieveAttachments = (
  payload: RetrieveAttachmentsPayload,
): Promise<RetrieveAttachmentsResponse> => {
  return post('/attachments/list', payload);
};
