// Models
import {
  SettingsActionTypes,
  RetrieveGoAMLTemplatesResult,
  RetrieveGoAMLTemplatesContent,
} from 'app/modules/settings/models';

// Utils
import { createAction, ActionsUnion } from 'app/shared/utils/action-helpers';

export const retrieveGoAMLTemplates = () =>
  createAction(SettingsActionTypes.RETRIEVE_GO_AML_TEMPLATES);

export const retrieveGoAMLTemplatesSuccess = (
  result: RetrieveGoAMLTemplatesResult[],
) =>
  createAction(SettingsActionTypes.RETRIEVE_GO_AML_TEMPLATES_SUCCESS, result);

export const editGoAMLTemplates = (payload: {
  type: string;
  content: RetrieveGoAMLTemplatesContent;
}) => createAction(SettingsActionTypes.EDIT_GO_AML_TEMPLATES, payload);

export const editGoAMLTemplatesSuccess = (
  result: RetrieveGoAMLTemplatesResult,
) => createAction(SettingsActionTypes.EDIT_GO_AML_TEMPLATES_SUCCESS, result);

const SettingsActionsList = {
  editGoAMLTemplatesSuccess,
  retrieveGoAMLTemplates,
  retrieveGoAMLTemplatesSuccess,
};

export type SettingsActions = ActionsUnion<typeof SettingsActionsList>;
