// Saga
import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { InsightsActionTypes } from 'app/modules/insights/models';

// Api
import { createDashboard as createDashboardApi } from 'app/shared/api/insights';

// Actions
import {
  createDashboard as createDashboardAction,
  createDashboardSuccess,
} from 'app/modules/insights/actions';
import { sendSuccessToast } from 'app/shared/toasts/actions';

// Utils
import routes from 'app/shared/utils/routes';
import { history } from 'app/store/browserHistory';

const rootAction = InsightsActionTypes.CREATE_DASHBOARD;
function* createDashboard({
  payload,
}: ReturnType<typeof createDashboardAction>) {
  const config = {
    rootAction,
    request: call(createDashboardApi, payload),
    errorToastMessage: `Something went wrong, unable to create dashboard.`,
    success: function* onSuccess(
      result: ReturnType<typeof createDashboardSuccess>['payload'],
    ) {
      yield put(
        sendSuccessToast(`Dashboard ${result.title} successfully created`),
      );
      history.push(routes.lumos.dashboardsId.replace(':id', String(result.id)));
      yield put(createDashboardSuccess(result));
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watchCreateDashboard() {
  yield takeLatest(rootAction, createDashboard);
}
