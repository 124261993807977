import { FC, HTMLProps, ReactNode, useMemo } from 'react';

import {
  generateColorFromString,
  generateInitials,
  generateTextColor,
} from 'app/shared/u21-ui/components/display/avatar/utils';
import { getDOMProps } from 'app/shared/utils/react';
import styled, { css } from 'styled-components';

import { Avatar } from '@mui/material';

type AvatarSize = 'small' | 'medium' | 'large';

interface U21AvatarProps extends Omit<HTMLProps<HTMLDivElement>, 'size'> {
  name: string;
  size?: AvatarSize;
  src?: string;
  svg?: ReactNode;
}

export const U21Avatar: FC<U21AvatarProps> = (props) => {
  const { name, svg, size = 'medium', src, ...rest } = props;

  const initials = useMemo(() => generateInitials(name), [name]);

  if (svg) {
    return (
      <StyledAvatar $size={size!} alt={name} {...getDOMProps(rest)}>
        {svg}
      </StyledAvatar>
    );
  }

  return (
    <StyledAvatar $size={size!} alt={name} src={src} {...getDOMProps(rest)}>
      {initials}
    </StyledAvatar>
  );
};

interface StyleProps {
  $size: AvatarSize;
}

const StyledAvatar = styled(Avatar)<StyleProps>`
  ${(props) => {
    switch (props.$size) {
      case 'small':
        return css`
          height: 20px;
          width: 20px;
          font-size: 12px;
        `;
      case 'medium':
        return css`
          height: 40px;
          width: 40px;
          font-size: 20px;
        `;
      case 'large':
        return css`
          height: 96px;
          width: 96px;
          font-size: 48px;
        `;
      default:
        return '';
    }
  }}
  ${(props) => {
    const { alt, src } = props;
    if (src) {
      return '';
    }
    const backgroundColor = generateColorFromString(alt!);
    const color = generateTextColor(backgroundColor);
    return css`
      background-color: ${backgroundColor};
      color: ${color};
    `;
  }}
`;
