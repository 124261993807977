// Types
import { ShortEntityResponse } from 'app/modules/entities/types';
import {
  RelationshipConfigResponse,
  EntityToEntityRelationshipConfigResponse,
  EntityToInstrumentRelationshipConfig as EntityToInstrumentRelationshipConfigResponse,
} from 'app/modules/relationships/types';
import { PaginatedRelationshipsPayload } from 'app/modules/relationships/types/requests';
import { InstrumentSummary } from 'app/modules/txnInstruments/models';

export interface RelationshipsState {
  relationshipConfig: RelationshipConfigResponse;

  loadingRetrieveRelationshipConfig: boolean;
  loadingCreateRelationshipConfig: boolean;
  loadingEditRelationshipConfig: boolean;

  entityToEntityRelationshipConfigs: EntityToEntityRelationshipConfigResponse[];
  entityToEntityRelationshipCount: number;
  entityToInstrumentRelationshipConfigs: EntityToInstrumentRelationshipConfigResponse[];
  entityToInstrumentRelationshipCount: number;
  loadingRetrieveRelationshipConfigs: boolean;

  entityRelatedEntitiesPaginationPayload: Partial<
    Omit<PaginatedRelationshipsPayload, 'filters'>
  >;
  entityRelatedInstrumentsPaginationPayload: Partial<
    Omit<PaginatedRelationshipsPayload, 'filters'>
  >;
  instrumentRelatedEntitiesPaginationPayload: Partial<
    Omit<PaginatedRelationshipsPayload, 'filters'>
  >;

  entityRelatedEntitiesFilters: {
    selectedConfigs: string[];
    direction: DirectionOptions;
  };
  entityRelatedInstrumentsFilters: { selectedConfigs: string[] };
  instrumentRelatedEntitiesFilters: { selectedConfigs: string[] };
}

export enum RelationshipConfigType {
  ENTITY_TO_ENTITY = 'ENTITY_TO_ENTITY',
  ENTITY_TO_INSTRUMENT = 'ENTITY_TO_INSTRUMENT',
}

export enum RelatedEntityDirectedness {
  TO = 'TO',
  FROM = 'FROM',
  UNDIRECTED = 'UNDIRECTED',
}

export enum DegreeType {
  ONE = 'ONE',
  MANY = 'MANY',
}

export enum RiskType {
  PROACTIVE = 'PROACTIVE',
  REACTIVE = 'REACTIVE',
}

export interface EditRelationshipFormValues {
  name: string;
  description: string;
  degree: DegreeType;
  relationship_id: string;
  risk_type: RiskType;
}

export interface MergeRelationshipResponse<T> {
  __relationship: T;
}

export type MergedRelatedEntityResponse = ShortEntityResponse & {
  directedness?: RelatedEntityDirectedness;
} & MergeRelationshipResponse<EntityToEntityRelationshipConfigResponse>;

export type MergedRelatedInstrumentResponse = InstrumentSummary &
  MergeRelationshipResponse<EntityToInstrumentRelationshipConfigResponse>;

export interface RelatedEntitiesResponse {
  entities: MergedRelatedEntityResponse[];
  count: number;
}

export interface RelatedInstrumentsResponse {
  instruments: MergedRelatedInstrumentResponse[];
  count: number;
}

export type DirectionOptions = 'either' | 'directed' | 'undirected';

export type TableTypeOptions = 'entity' | 'instrument';
