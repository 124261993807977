import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { AuditTrailActionTypes } from 'app/shared/auditTrail/models';

// Actions
import {
  retrieveAuditTrailEntries as retrieveAuditTrailEntriesAction,
  retrieveAuditTrailEntriesSuccess,
} from 'app/shared/auditTrail/actions';

// API
import { retrieveAuditTrailEntries as retrieveAuditTrailEntriesAPI } from 'app/shared/api/auditTrail';

const rootAction = AuditTrailActionTypes.RETRIEVE_AUDIT_TRAIL_ENTRIES;
function* retrieveAuditTrailEntries({
  payload,
}: ReturnType<typeof retrieveAuditTrailEntriesAction>) {
  const config = {
    rootAction,
    request: call(retrieveAuditTrailEntriesAPI, payload),
    success: function* onSuccess(
      result: ReturnType<typeof retrieveAuditTrailEntriesSuccess>['payload'],
    ) {
      yield put(retrieveAuditTrailEntriesSuccess(result));
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watchRetrieveAuditTrailEntries() {
  yield takeLatest(rootAction, retrieveAuditTrailEntries);
}
