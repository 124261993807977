// Models
import { SarStatus } from 'app/modules/fincenSar/models';
import { DropdownItemProps } from 'semantic-ui-react';

export const statusDropdownOptions: DropdownItemProps[] = Object.keys(
  SarStatus,
).map((status, idx) => {
  return {
    text: status,
    value: status,
    key: status + String(idx),
  };
});

export const FINCEN_SAR_REPORT_TYPE: string = 'FINCEN_SAR';
