// Models
import {
  DeadlinesActionTypes,
  DeadlinesConfigsResponse,
  DeadlinesConfig,
  CreateDeadlinesConfigPayload,
  EditDeadlinesConfigPayload,
  EditDeadlinePayload,
  ArticleDeadline,
} from 'app/modules/deadlines/models';

// Utils
import { createAction, ActionsUnion } from 'app/shared/utils/action-helpers';
import { PaginationPayload } from 'app/shared/models';

export const retrieveDeadlinesConfigs = (
  payload: Omit<PaginationPayload, 'sort_column' | 'sort_direction'>,
) => createAction(DeadlinesActionTypes.RETRIEVE_DEADLINES_CONFIGS, payload);

export const retrieveDeadlinesConfigsSuccess = (
  result: DeadlinesConfigsResponse,
) =>
  createAction(DeadlinesActionTypes.RETRIEVE_DEADLINES_CONFIGS_SUCCESS, result);

export const retrieveDeadlinesConfig = (id: number) =>
  createAction(DeadlinesActionTypes.RETRIEVE_DEADLINES_CONFIG, id);

export const retrieveDeadlinesConfigSuccess = (result: DeadlinesConfig) =>
  createAction(DeadlinesActionTypes.RETRIEVE_DEADLINES_CONFIG_SUCCESS, result);

export const createDeadlinesConfig = (payload: CreateDeadlinesConfigPayload) =>
  createAction(DeadlinesActionTypes.CREATE_DEADLINES_CONFIG, payload);

export const createDeadlinesConfigSuccess = (payload: DeadlinesConfig) =>
  createAction(DeadlinesActionTypes.CREATE_DEADLINES_CONFIG_SUCCESS, payload);

export const editDeadlinesConfig = (payload: EditDeadlinesConfigPayload) =>
  createAction(DeadlinesActionTypes.EDIT_DEADLINES_CONFIG, payload);

export const editDeadlinesConfigSuccess = (payload: DeadlinesConfig) =>
  createAction(DeadlinesActionTypes.EDIT_DEADLINES_CONFIG_SUCCESS, payload);

export const setDeadlinesConfig = (payload: DeadlinesConfig) =>
  createAction(DeadlinesActionTypes.SET_DEADLINES_CONFIG, payload);

export const editArticleDeadline = (payload: EditDeadlinePayload) =>
  createAction(DeadlinesActionTypes.EDIT_ARTICLE_DEADLINE, payload);

export const editArticleDeadlineSuccess = (result: ArticleDeadline) =>
  createAction(DeadlinesActionTypes.EDIT_ARTICLE_DEADLINE_SUCCESS, result);

const DeadlinesActionsList = {
  retrieveDeadlinesConfigs,
  retrieveDeadlinesConfigsSuccess,
  retrieveDeadlinesConfig,
  retrieveDeadlinesConfigSuccess,
  createDeadlinesConfig,
  createDeadlinesConfigSuccess,
  editDeadlinesConfig,
  editDeadlinesConfigSuccess,
  setDeadlinesConfig,
  editArticleDeadline,
  editArticleDeadlineSuccess,
};

export type DeadlinesActions = ActionsUnion<typeof DeadlinesActionsList>;
