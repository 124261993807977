import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

// Components
import { IconTrash, IconPlus } from '@u21/tabler-icons';

import {
  U21Button,
  U21Typography,
  U21Modal,
  U21TextField,
  U21FormError,
  U21Section,
  U21Spacer,
} from 'app/shared/u21-ui/components';

// Models
import {
  U21CustomDataProps,
  CustomData,
} from 'app/shared/u21-ui/components/customData/models';
import { DarkWebAlertCustomData } from 'app/modules/alerts/types/responses';

interface CustomDataObject {
  column: string;
  value: string;
}
const EMPTY_CUSTOM_DATA: CustomDataObject = {
  column: '',
  value: '',
};

export interface EditCustomDataProps extends Omit<U21CustomDataProps, 'value'> {
  // U21CustomData should provide an empty object if value is falsey
  value: CustomData | DarkWebAlertCustomData;

  toggleEditing?: () => void;
  editing: boolean;
}

export const EditCustomData = (props: EditCustomDataProps) => {
  const {
    disabled,
    editing,
    loading,
    onChange,
    toggleEditing,
    value,
    editInModal: editInModalProp,
    submittable: submittableProp,
    customDataKeyValuesMap,
    showFriendlyNameTextField,
  } = props;

  const editInModal = Object.prototype.hasOwnProperty.call(props, 'editInModal')
    ? editInModalProp
    : true;
  const submittable = Object.prototype.hasOwnProperty.call(props, 'submittable')
    ? submittableProp
    : true;

  const customDataToObj = () => {
    const customDataObj = {};
    customData.forEach((customDataField) => {
      const { column, value: fieldValue } = customDataField;
      if (column) {
        let formattedValue: JSONValue = fieldValue || '';
        try {
          formattedValue = JSON.parse(fieldValue);
        } catch (e) {
          // Do nothing, keep as is
        }

        customDataObj[column] = formattedValue;
      }
    });
    return customDataObj;
  };

  const valueToCustomData = () => {
    const customDataRows = Object.entries(value).map(
      ([column, providedValue]) => {
        const formattedValue =
          typeof providedValue === 'string'
            ? providedValue
            : JSON.stringify(providedValue);

        return {
          column,
          value: formattedValue,
        };
      },
    );

    return customDataRows.length ? customDataRows : [EMPTY_CUSTOM_DATA];
  };

  const [customData, setCustomData] =
    useState<CustomDataObject[]>(valueToCustomData());
  const [duplicateKeys, setDuplicateKeys] = useState<{
    [key: string]: boolean;
  }>({});

  const customDataToObjRef = useRef(customDataToObj);
  customDataToObjRef.current = customDataToObj;
  const valueToCustomDataRef = useRef(valueToCustomData);
  valueToCustomDataRef.current = valueToCustomData;
  useEffect(() => {
    const customDataObj = customDataToObjRef.current();
    const hasChange = Object.keys(value).some(
      (customDataKey) => value[customDataKey] !== customDataObj[customDataKey],
    );

    if (!hasChange) {
      return;
    }

    setCustomData(valueToCustomDataRef.current());
  }, [value]);

  useEffect(() => {
    const visitedKeys: { [key: string]: boolean } = {};
    const discoveredDuplicates: { [key: string]: boolean } = {};

    customData.forEach((customDataRow) => {
      if (customDataRow.column && visitedKeys[customDataRow.column]) {
        discoveredDuplicates[customDataRow.column] = true;
      }
      visitedKeys[customDataRow.column] = true;
    });
    setDuplicateKeys(discoveredDuplicates);
  }, [customData]);

  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;
  useEffect(() => {
    if (!submittable) {
      onChangeRef.current(customDataToObjRef.current());
    }
  }, [customData, submittable]);

  const handleKeyChange = (customDataIndex: number) => (newValue: string) => {
    setCustomData(
      customData.map((customDataRow, idx) => {
        if (idx === customDataIndex) {
          return {
            column: newValue,
            value: customDataRow.value,
          };
        }

        return customDataRow;
      }),
    );
  };

  const handleValueChange = (customDataIndex: number) => (newValue: string) => {
    setCustomData(
      customData.map((customDataRow, idx) => {
        if (idx === customDataIndex) {
          return {
            ...customDataRow,
            value: newValue,
          };
        }
        return customDataRow;
      }),
    );
  };

  const addRow = () => {
    setCustomData([...customData, EMPTY_CUSTOM_DATA]);
  };

  const removeRow = (index: number) => () => {
    if (customData.length > 1) {
      setCustomData(customData.filter((_, idx) => idx !== index));
      return;
    }
    setCustomData([EMPTY_CUSTOM_DATA]);
  };

  const handleClose = () => {
    toggleEditing?.();
    setCustomData(valueToCustomData());
  };

  const handleSubmit = () => {
    onChange(customDataToObj());
    toggleEditing?.();
  };

  const valid = Object.keys(duplicateKeys).length === 0;

  const content = (
    <U21Spacer>
      {customData.map((customDataRow, idx) => {
        const { column, value: fieldValue } = customDataRow;
        const isDuplicateKey = duplicateKeys[column];
        const friendlyName = customDataKeyValuesMap?.[column]?.label || '';

        return (
          // eslint-disable-next-line react/no-array-index-key
          <div key={idx}>
            <U21Spacer horizontal spacing={2}>
              {showFriendlyNameTextField !== false && (
                <U21TextField
                  disabled
                  onChange={handleKeyChange(idx)}
                  value={friendlyName}
                  clearable={false}
                />
              )}
              <U21TextField
                disabled={disabled}
                value={column}
                onChange={handleKeyChange(idx)}
                error={isDuplicateKey}
                placeholder="Add new key"
              />
              <U21TextField
                disabled={disabled}
                value={fieldValue}
                onChange={handleValueChange(idx)}
                placeholder="Add new value"
              />
              <U21Button
                color="error"
                variant="outlined"
                onClick={removeRow(idx)}
              >
                <IconTrash />
              </U21Button>
            </U21Spacer>
            <U21FormError
              error={isDuplicateKey ? 'You cannot have duplicate keys.' : ''}
            />
          </div>
        );
      })}
      <AddRowButton
        startIcon={<IconPlus />}
        onClick={addRow}
        color="primary"
        variant="outlined"
      >
        Add
      </AddRowButton>
    </U21Spacer>
  );

  if (editInModal) {
    return (
      <U21Modal
        title="Edit Custom Data"
        open={editing}
        onClose={handleClose}
        onAction={valid ? handleSubmit : () => {}}
        actionButtonProps={{
          children: 'Save',
          loading,
        }}
      >
        <StyledSpacer horizontal spacing={2}>
          <FlexGrowText variant="subtitle2">Friendly Name</FlexGrowText>
          <FlexGrowText variant="subtitle2">Key</FlexGrowText>
          <FlexGrowText variant="subtitle2">Value</FlexGrowText>
        </StyledSpacer>
        {content}
      </U21Modal>
    );
  }
  if (editing) {
    return (
      <U21Section title="Custom Data">
        <StyledSpacer horizontal spacing={2}>
          <FlexGrowText
            variant="subtitle2"
            showFriendlyNameTextField={showFriendlyNameTextField}
          >
            Key
          </FlexGrowText>
          <FlexGrowText
            variant="subtitle2"
            showFriendlyNameTextField={showFriendlyNameTextField}
          >
            Value
          </FlexGrowText>
        </StyledSpacer>
        {content}
      </U21Section>
    );
  }
  return null;
};

const AddRowButton = styled(U21Button)`
  align-self: flex-end;
`;

const StyledSpacer = styled(U21Spacer)`
  /* The width of the delete button so the "key"/"value" labels line up horizontally with the inputs */
  padding-right: 56px;
`;

const FlexGrowText = styled(U21Typography)<{
  showFriendlyNameTextField?: boolean;
}>`
  flex-grow: 1;
  ${(props) =>
    !(props.showFriendlyNameTextField === false) && 'max-width: 31%;'}
`;
