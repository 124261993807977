import { css } from 'styled-components';
import { getIn } from 'final-form';

export const COLOR_STYLE = css<{ $color: string }>`
  ${(props) =>
    props.$color &&
    css`
      color: ${props.theme.palette[props.$color]?.main ||
      props.theme.palette.colors[props.$color]?.main ||
      getIn(props.theme.palette, props.$color) ||
      props.$color};
    `}
`;
