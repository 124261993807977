// Saga
import { call, put, takeEvery, select } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { DropdownActionTypes } from 'app/shared/dropdown/models';

// Api
import { retrieveDropdownAgents as retrieveDropdownAgentsAPI } from 'app/shared/api/agents';

// Actions
import {
  retrieveDropdownAgents as retrieveDropdownAgentsAction,
  retrieveDropdownAgentsSuccess,
} from 'app/shared/dropdown/actions';

// Selectors
import { selectHasReadAgentsPermissions } from 'app/modules/session/selectors';

const rootAction = DropdownActionTypes.RETRIEVE_DROPDOWN_AGENTS;
function* retrieveDropdownAgents({
  payload,
}: ReturnType<typeof retrieveDropdownAgentsAction>) {
  const hasReadAgentsPermissions = yield select(selectHasReadAgentsPermissions);
  if (!hasReadAgentsPermissions) {
    return;
  }

  const config = {
    rootAction,
    request: call(retrieveDropdownAgentsAPI, payload),
    success: function* onSuccess(
      result: ReturnType<typeof retrieveDropdownAgentsSuccess>['payload'],
    ) {
      yield put(retrieveDropdownAgentsSuccess(result));
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watchRetrieveDropdownAgents() {
  yield takeEvery(rootAction, retrieveDropdownAgents);
}
