// Constants
import { HISTORY_LIMIT } from 'app/modules/navigator/constants';

export const popRoute = (history: string[], route: string): string[] => {
  const index = history.findIndex((i) => i === route);
  // Math.max protects against 0 index or not found
  const newHistory = history.slice(Math.max(index, 1));

  // there should always be a history
  if (newHistory.length) {
    return newHistory;
  }
  return [`${window.location.pathname}${window.location.search}`];
};

export const pushRoute = (history: string[], route: string): string[] => {
  if (route === history[0]) {
    return history;
  }
  return history.length < HISTORY_LIMIT
    ? [route, ...history]
    : [route, ...history.slice(0, -1)];
};

export const replaceRoute = (history: string[], route: string): string[] => {
  const newHistory = history.slice(1);
  // filter out duplicate routes in recent history
  const index = newHistory.findIndex((i) => i !== route);
  return [route, ...newHistory.slice(index)];
};
