import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { AlertsActionTypes } from 'app/modules/alerts/models';

// Actions
import { retrieveMyAlertsStatsSuccess } from 'app/modules/alerts/actions';

// API
import { getMyAlertsStats as getMyAlertsStatsApi } from 'app/shared/api/alerts';

const rootAction = AlertsActionTypes.RETRIEVE_MY_ALERTS_STATS;
function* retrieveMyAlertsStats() {
  const config = {
    rootAction,
    request: call(getMyAlertsStatsApi),
    success: function* onSuccess(result) {
      yield put(retrieveMyAlertsStatsSuccess(result));
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watchRetrieveMyAlertsStats() {
  yield takeLatest(rootAction, retrieveMyAlertsStats);
}
