import {
  NotificationResponse,
  RetrieveNotificationsResponse,
} from 'app/modules/notifications/types/responses';

// Utils
import { post } from 'app/shared/utils/fetchr';
import routes from 'app/shared/utils/routes';

export const getNotifications = (payload) =>
  post<RetrieveNotificationsResponse>(
    routes.patronus.notificationsPaginated,
    payload,
  );

export const readNotification = (payload: {
  agent_id: number;
  notification_id: number;
}) =>
  post<NotificationResponse>(
    `${routes.patronus.notificationsRead}/${payload.agent_id}/${payload.notification_id}`,
  );

export const readAllNotification = () =>
  post<NotificationResponse>(routes.patronus.notificationsReadAll);
