// For list of possible fields: https://developers.google.com/drive/api/v3/reference/files
const GOOGLE_DRIVE_RETRIEVE_FIELDS = [
  'id',
  'iconLink',
  'createdTime',
  'size',
  'parents',
  'mimeType',
];
const GOOGLE_DRIVE_UPLOAD_URL = `https://www.googleapis.com/upload/drive/v3/files?supportsAllDrives=true&supportsTeamDrives=true&uploadType=multipart&fields=${GOOGLE_DRIVE_RETRIEVE_FIELDS.join(
  ',',
)}`;

// Inspired by https://tanaikech.github.io/2018/08/13/upload-files-to-google-drive-using-javascript/
export function uploadToGoogleDrive(
  file: File,
  parentId: string,
  oauthToken: string,
  successCallback: (fileId: string, data: JSONObject) => void,
  failureCallback: (fileId: string, shortMessage: string) => void,
  fileId: string,
) {
  const metadata = {
    name: file.name, // Filename at Google Drive
    mimeType: file.type, // mimeType at Google Drive
    parents: [parentId], // Folder ID at Google Drive
  };

  const form = new FormData();
  form.append(
    'metadata',
    new Blob([JSON.stringify(metadata)], { type: 'application/json' }),
  );
  form.append('file', file);

  fetch(GOOGLE_DRIVE_UPLOAD_URL, {
    method: 'POST',
    headers: new Headers({ Authorization: `Bearer ${oauthToken}` }),
    body: form,
  }).then((response) => {
    if (response.status !== 200) {
      response.json().then(({ error }) => {
        const shortMessage = `Google Error Message: ${
          error.message.split('.')[0] || 'User Rate Limit Exceeded.'
        }.`;
        failureCallback(fileId, shortMessage);
      });
    } else {
      response.json().then((data: JSONObject) => successCallback(fileId, data));
    }
  });
}
