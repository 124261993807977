const BASE = ['data_labels'];

export const ALL_DATA_LABELS_QUERY_KEYS = {
  getAllDataLabels: () => [...BASE] as const,
  getCgdoDataLabelRecentEdit: (
    objectType: string,
    objectId: string,
    isDataConfigDeltalake: boolean,
  ) => [...BASE, objectType, objectId, isDataConfigDeltalake] as const,
};
