import { createSelector } from '@reduxjs/toolkit';
import { name } from 'app/modules/dataSettings/sliceDataSettings';
import {
  DataSettingsKeyType,
  OrgDataSettingsConfig,
  Unit21DataClassifier,
  CustomDataSettingsConfigResponse,
  NativeDataSettingsConfigResponse,
  OrgDataSettingsConfigResponse,
  DataSettingFieldType,
  SemanticTypes,
} from 'app/modules/dataSettings/responses';
import { AsyncStatus } from 'app/shared/types/utils/asyncStatus';
import { filterDataSettings } from 'app/modules/dataSettings/utils';
import { useGetTypeClassifications } from 'app/modules/typeClassification/queries/useGetTypeClassifications';
import { CLASSIFIER_TO_TYPE_NATIVE_KEY } from 'app/modules/typeClassification/constants';
import { useMemo } from 'react';
import {
  TxnEventTypeClassification,
  TypeClassificationMapping,
} from 'app/modules/typeClassification/types';
import { useSelector } from 'react-redux';

export const selectDataSettingsState = (state: RootState) => state[name];

export const selectDataSettingsFilters = createSelector(
  selectDataSettingsState,
  (state) => state.filters,
);

export const selectDataSettingsOverlay = createSelector(
  selectDataSettingsState,
  (state) => state.overlay,
);

export const selectDataSettingsStatus = (state: RootState) =>
  state[name].dataSettings.status;

export const selectCustomData = createSelector(
  selectDataSettingsState,
  (state) =>
    state.dataSettings.status === 'COMPLETE'
      ? state.dataSettings.data.filter(
          (data): data is CustomDataSettingsConfigResponse =>
            data.key_type === DataSettingsKeyType.CUSTOM,
        )
      : [],
);

export const selectNativeData = createSelector(
  selectDataSettingsState,
  (state) =>
    state.dataSettings.status === 'COMPLETE'
      ? state.dataSettings.data.filter(
          (data): data is NativeDataSettingsConfigResponse =>
            data.key_type === DataSettingsKeyType.NATIVE,
        )
      : [],
);

export const selectDataSettings = createSelector(
  selectDataSettingsState,
  (state) => state.dataSettings,
);

export const selectEntityPerspectives = createSelector(
  selectDataSettings,
  (state) =>
    state.status === 'COMPLETE'
      ? state.data.filter(
          (data) =>
            data.data_type === DataSettingFieldType.ENTITY_REFERENCE &&
            data.unit21_data_classifier === Unit21DataClassifier.EVENT,
        )
      : [],
);

export const selectFilteredDataSettings = createSelector(
  selectDataSettings,
  selectDataSettingsFilters,
  (data, filters) => {
    return filterDataSettings(data, filters);
  },
);

const dataSettingsByClassifierHelper = (
  data: AsyncStatus<OrgDataSettingsConfigResponse>,
  customOnly: boolean = false,
) => {
  let configs = data.status === 'COMPLETE' ? data.data : [];
  if (customOnly) {
    configs = configs.filter((c) => c.key_type === DataSettingsKeyType.CUSTOM);
  }
  const base: Record<Unit21DataClassifier, OrgDataSettingsConfig[]> = {
    [Unit21DataClassifier.ENTITY]: [],
    [Unit21DataClassifier.INSTRUMENT]: [],
    [Unit21DataClassifier.EVENT]: [],
    [Unit21DataClassifier.ACTION_EVENT]: [],
    [Unit21DataClassifier.ALERT]: [],
    [Unit21DataClassifier.RULE]: [],
    [Unit21DataClassifier.CASE]: [],
    [Unit21DataClassifier.ADDRESS]: [],
    [Unit21DataClassifier.WATCHLIST]: [],
  };
  return {
    ...base,
    ...configs.reduce((acc, config) => {
      acc[config.unit21_data_classifier] ||= [];
      acc[config.unit21_data_classifier].push(config);
      return acc;
    }, {}),
  };
};

export const selectDataSettingsByClassifier = createSelector(
  selectDataSettings,
  (data) => dataSettingsByClassifierHelper(data),
);

export const selectCustomDataSettingsByClassifier = createSelector(
  selectDataSettings,
  (data) =>
    dataSettingsByClassifierHelper(data, true) as Record<
      Unit21DataClassifier,
      CustomDataSettingsConfigResponse[]
    >,
);

export const selectCustomDataSettingsByClassifierAndSemanticType =
  createSelector(
    selectCustomDataSettingsByClassifier,
    (
      _,
      options: {
        classifier: Unit21DataClassifier;
        semanticType: SemanticTypes;
      },
    ) => options,
    (
      customDataSettings: Record<Unit21DataClassifier, OrgDataSettingsConfig[]>,
      { classifier, semanticType },
    ) => {
      return customDataSettings[classifier].filter(
        (data) => data.semantic_type === semanticType,
      );
    },
  );

export const selectCustomDataTableLabelsByClassifier = createSelector(
  selectDataSettingsByClassifier,
  (customDataSettings) => {
    return Object.entries(customDataSettings).reduce(
      (acc, [classifier, configs]) => {
        acc[classifier] = configs.reduce((accumulator, config) => {
          // top level keys only
          if (
            config.key_type === DataSettingsKeyType.CUSTOM &&
            config.key_path.length === 1
          ) {
            accumulator[config.key_path[0]] = {
              label: config.user_facing_label || config.key_path[0],
              description: config.description,
            };
          }
          return accumulator;
        }, {});
        return acc;
      },
      {} as Record<
        Unit21DataClassifier,
        Record<string, { label: string; description: string }>
      >,
    );
  },
);

const useTxnEventTypeClassification = (
  txnTypeClassification: TxnEventTypeClassification,
) => {
  const { data: typeClassifications } = useGetTypeClassifications();
  const transactionDataSettingsByNativeKey = useSelector(
    selectTransactionDataSettingsByNativeKey,
  );
  const txnTypeDataSettingId =
    transactionDataSettingsByNativeKey?.[
      CLASSIFIER_TO_TYPE_NATIVE_KEY[Unit21DataClassifier.EVENT]
    ]?.id;
  return useMemo(() => {
    if (!txnTypeDataSettingId) return [];
    if (!typeClassifications) return [];
    return typeClassifications.data.filter(
      ({ classification, data_setting_id: dataSettingId }) =>
        classification === txnTypeClassification &&
        dataSettingId === txnTypeDataSettingId,
    );
  }, [txnTypeClassification, txnTypeDataSettingId, typeClassifications]);
};
export const useCheckClassifications = (): TypeClassificationMapping[] => {
  return useTxnEventTypeClassification(TxnEventTypeClassification.CHECK);
};
export const useAchClassifications = (): TypeClassificationMapping[] => {
  return useTxnEventTypeClassification(TxnEventTypeClassification.ACH);
};

// Normalized state selectors
export const selectDataSettingsById = createSelector(
  selectDataSettingsState,
  (state) => state.dataSettingsById,
);

export const selectDataSettingsByNativeKey = createSelector(
  selectDataSettingsState,
  (state) => state.dataSettingsByNativeKey,
);

export const selectEntityDataSettingsByNativeKey = createSelector(
  selectDataSettingsByNativeKey,
  (state) => state?.[Unit21DataClassifier.ENTITY] ?? {},
);

export const selectInstrumentDataSettingsByNativeKey = createSelector(
  selectDataSettingsByNativeKey,
  (state) => state?.[Unit21DataClassifier.INSTRUMENT] ?? {},
);

export const selectTransactionDataSettingsByNativeKey = createSelector(
  selectDataSettingsByNativeKey,
  (state) => state?.[Unit21DataClassifier.EVENT] ?? {},
);

export const selectActionEventDataSettingsByNativeKey = createSelector(
  selectDataSettingsByNativeKey,
  (state) => state?.[Unit21DataClassifier.ACTION_EVENT] ?? {},
);
