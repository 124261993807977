const NEW_DESIGN_KEY = 'U21_NEW_DESIGN';

export const isNewDesignEnabled = () =>
  localStorage.getItem(NEW_DESIGN_KEY) !== null;

export const enableNewDesign = () => {
  localStorage.setItem(NEW_DESIGN_KEY, 'true');
  window.location.reload();
};

export const disableNewDesign = () => {
  localStorage.removeItem(NEW_DESIGN_KEY);
  window.location.reload();
};
