import { AUTH_CONFIG } from 'app/modules/session/auth0Config';
import { getImpersonateId } from 'app/shared/utils/sessionStorage';
import { buffers, eventChannel, END } from 'redux-saga';
import uploadStatus, {
  uploadStatusLabel,
} from 'app/modules/uploads/enums/uploadStatus';
import { v4 } from 'uuid';
import { LocalStorageKeys } from 'app/shared/constants/localStorage';

interface UploadBuffer {
  percent?: number;
  total?: number;
  loaded?: number;
  success?: XMLHttpRequest['response'];
  err?: Error;
  xhr?: XMLHttpRequest;
}

export default async function createUploadFileChannel(
  endpoint: string,
  file: File,
) {
  const accessToken = localStorage.getItem(LocalStorageKeys.ACCESS_TOKEN);
  const headers = {
    Authorization: `Bearer ${accessToken}`,
    RequestID: v4(),
  };

  // Allow root users (i.e. u21-internal) to specify a user id to impersonate as
  const impersonateId: number | null = getImpersonateId();
  if (impersonateId && impersonateId >= 0) {
    headers['impersonate-as'] = impersonateId;
  }
  return eventChannel((emitter) => {
    const xhr = new XMLHttpRequest();

    const onProgress = (e: ProgressEvent) => {
      if (e.lengthComputable) {
        const percent = (100 * e.loaded) / e.total;
        emitter({ percent, total: e.total, loaded: e.loaded, xhr });
      }
    };
    const onFailure = (e: ProgressEvent) => {
      const msg =
        e.type === 'abort'
          ? uploadStatusLabel[uploadStatus.processAborted]
          : uploadStatusLabel[uploadStatus.processFailed];

      emitter({ err: new Error(msg) });
      emitter(END);
    };

    const onLoad = () => {
      if (xhr.status !== 200) {
        emitter({ err: new Error(xhr.response) });
        emitter(END);
        return;
      }
      emitter({ success: xhr.response });
      emitter(END);
    };
    xhr.upload.addEventListener('progress', onProgress);
    xhr.upload.addEventListener('error', onFailure);
    xhr.upload.addEventListener('abort', onFailure);
    xhr.addEventListener('load', onLoad);
    xhr.open('POST', `${AUTH_CONFIG.apiBaseUrl}${endpoint}`, true);
    Object.keys(headers).forEach((i) => {
      xhr.setRequestHeader(i, headers[i]);
    });
    const formData = new FormData();

    formData.append('file', file);
    xhr.send(formData);
    return () => {
      xhr.upload.removeEventListener('progress', onProgress);
      xhr.upload.removeEventListener('error', onFailure);
      xhr.upload.removeEventListener('abort', onFailure);
      xhr.removeEventListener('load', onLoad);
      xhr.onreadystatechange = null;
      xhr.abort();
    };
  }, buffers.sliding<UploadBuffer>(2));
}
