import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { RulesActionTypes } from 'app/modules/rules/models';

// Api
import { retrieveValidationRulesFileExports } from 'app/shared/api/rules';

// Actions
import {
  retrieveRuleValidationFiles as retrieveFilesAction,
  retrieveRuleValidationFilesSuccess,
} from 'app/modules/rules/actions';

const rootAction = RulesActionTypes.RETRIEVE_RULE_VALIDATION_FILE_EXPORTS;
export function* retrieveRuleValidationFiles({
  payload: ruleId,
}: ReturnType<typeof retrieveFilesAction>) {
  const config = {
    rootAction,
    request: call(retrieveValidationRulesFileExports, ruleId),
    success: function* onSuccess(
      result: ReturnType<typeof retrieveRuleValidationFilesSuccess>['payload'],
    ) {
      yield put(retrieveRuleValidationFilesSuccess(result));
    },
    errorToastMessage: `Something went wrong retrieving validation files for rule #${ruleId}. Please try again`,
  };
  yield call(makeSagaRequest, config);
}

export default function* watch() {
  yield takeLatest(rootAction, retrieveRuleValidationFiles);
}
