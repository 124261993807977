import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { AgentsActionTypes } from 'app/modules/agentsOld/models';

// Actions
import {
  cancelImpersonation as cancelImpersonationAction,
  cancelImpersonationSuccess,
} from 'app/modules/agentsOld/actions';
import { startSession } from 'app/modules/session/actions';

// API
import { cancelImpersonation as cancelImpersonationAPI } from 'app/shared/api/agents';

// Utils
import {
  setImpersonateId,
  setImpersonateLogId,
} from 'app/shared/utils/sessionStorage';
import routes from 'app/shared/utils/routes';
import { history } from 'app/store/browserHistory';
import { queryClient } from 'app/cache/queryClient';

const rootAction = AgentsActionTypes.CANCEL_IMPERSONATION;
function* cancelImpersonation({
  payload: { authClient, ...payload },
}: ReturnType<typeof cancelImpersonationAction>) {
  const config = {
    rootAction,
    request: call(cancelImpersonationAPI, payload),
    errorToastMessage: 'Something went wrong - unable to cancel impersonation.',
    success: function* onSuccess(result) {
      setImpersonateId(-1);
      setImpersonateLogId(-1);
      queryClient.clear();
      yield put({ type: '@@rootReducer/RESET_APP' });
      yield put(startSession(authClient));
      history.push(routes.lumos.home);

      yield put(cancelImpersonationSuccess(result));
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watch() {
  yield takeLatest(rootAction, cancelImpersonation);
}
