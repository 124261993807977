import { flattenChildren, getDOMProps } from 'app/shared/utils/react';
import { forwardRef, HTMLProps, ReactNode, RefObject, useMemo } from 'react';
import styled, { css } from 'styled-components';

import { Stack } from '@mui/material';
import { U21Divider } from 'app/shared/u21-ui/components/display/U21Divider';

export type U21SpacerAlign = 'normal' | 'start' | 'center' | 'end';

export interface U21SpacerProps
  extends Omit<HTMLProps<HTMLDivElement>, 'wrap'> {
  align?: U21SpacerAlign;
  children: ReactNode;
  dividers?: boolean;
  horizontal?: boolean;
  marginEnd?: boolean;
  marginStart?: boolean;
  ref?: RefObject<HTMLDivElement>;
  spacing?: string | number;
  useFlexGap?: boolean;
  wrap?: boolean;
}

export const U21Spacer = forwardRef<HTMLDivElement, U21SpacerProps>(
  (
    {
      children,
      dividers = false,
      horizontal = false,
      marginEnd = false,
      marginStart = false,
      spacing = 1,
      wrap = false,
      align = horizontal || wrap ? 'center' : 'normal',
      useFlexGap,
      ...rest
    },
    ref,
  ) => {
    const actualChildren = useMemo(
      () => (dividers ? flattenChildren(children) : children),
      [children, dividers],
    );

    if (wrap) {
      return (
        <StyledWrappedDiv
          $marginStart={marginStart}
          $marginEnd={marginEnd}
          $align={align}
          ref={ref}
          $spacing={spacing}
          {...getDOMProps(rest)}
        >
          {actualChildren}
        </StyledWrappedDiv>
      );
    }

    return (
      <StyledStack
        $marginStart={marginStart}
        $marginEnd={marginEnd}
        $align={align}
        direction={horizontal ? 'row' : 'column'}
        divider={
          dividers ? (
            <U21Divider horizontal={!horizontal} flexItem />
          ) : undefined
        }
        ref={ref}
        spacing={spacing}
        useFlexGap={useFlexGap}
        {...getDOMProps(rest)}
      >
        {actualChildren}
      </StyledStack>
    );
  },
);

interface StyleProps {
  $marginEnd?: boolean;
  $marginStart?: boolean;
  $align: U21SpacerAlign;
}

interface DivStyledProps {
  $marginEnd?: boolean;
  $marginStart?: boolean;
  $align: U21SpacerAlign;
  $spacing?: string | number;
}

const ALIGN_VALUE = {
  center: 'center',
  normal: 'normal',
  start: 'flex-start',
  end: 'flex-end',
};

const StyledStack = styled(Stack)<StyleProps>`
  align-items: ${(props) => ALIGN_VALUE[props.$align]};
  ${(props) => {
    const { $marginStart, direction, spacing, theme } = props;
    if ($marginStart) {
      if (direction === 'row') {
        return css`
          margin-left: ${theme.spacing(spacing)};
        `;
      }
      return css`
        margin-top: ${theme.spacing(spacing)};
      `;
    }
    return css``;
  }}

  ${(props) => {
    const { $marginEnd, direction, spacing, theme } = props;
    if ($marginEnd) {
      if (direction === 'row') {
        return css`
          margin-right: ${theme.spacing(spacing)};
        `;
      }
      return css`
        margin-bottom: ${theme.spacing(spacing)};
      `;
    }
    return css``;
  }}
`;

const StyledWrappedDiv = styled.div<DivStyledProps>`
  display: flex;
  flex-wrap: wrap;
  ${(props) => css`
    gap: ${props.theme.spacing(props.$spacing)};
  `}
  align-items: ${(props) => ALIGN_VALUE[props.$align]};

  ${(props) => {
    const { $marginStart, $spacing, theme } = props;
    if ($marginStart) {
      return css`
        margin-left: ${theme.spacing($spacing)};
      `;
    }
    return css``;
  }}

  ${(props) => {
    const { $marginEnd, $spacing, theme } = props;
    if ($marginEnd) {
      return css`
        margin-right: ${theme.spacing($spacing)};
      `;
    }
    return css``;
  }}
`;
