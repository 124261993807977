import { call, put, select, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import {
  EntitiesActionTypes,
  EntityAlertsPayload,
} from 'app/modules/entities/models';

// Actions
import {
  retrieveEntity as retrieveEntityAction,
  retrieveEntitySuccess,
  retrieveEntityAlerts,
} from 'app/modules/entities/actions';
import { retrieveCommentsSuccess } from 'app/modules/comments/actions';

// Constants
import { getEntity } from 'app/shared/api/entities';
import { selectHasReadAlertsPermission } from 'app/modules/session/selectors';

const rootAction = EntitiesActionTypes.RETRIEVE_ENTITY;
export function* retrieveEntity({
  payload: { entityId, ignoreComments },
}: ReturnType<typeof retrieveEntityAction>) {
  const config = {
    rootAction,
    request: call(getEntity, entityId),
    success: function* onSuccess(result) {
      yield put(retrieveEntitySuccess(result));

      // update comments
      if (result.comments && !ignoreComments) {
        yield put(retrieveCommentsSuccess(result.comments));
      }
      const hasReadAlertsPermission = yield select(
        selectHasReadAlertsPermission,
      );
      if (hasReadAlertsPermission) {
        yield put(
          retrieveEntityAlerts({
            entityExternalId: result.external_id,
            limit: 100,
            offset: 1,
          } as EntityAlertsPayload),
        );
      }
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watchRetrieveEntity() {
  yield takeLatest(rootAction, retrieveEntity);
}
