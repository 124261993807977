import { AddGDriveDocsPayload } from 'app/modules/entitiesRefresh/requests';
import { Filter } from 'app/modules/filters/models';
import { GoogleDriveDoc } from 'app/modules/uploads/models';
import { UploadedFile } from 'app/shared/models';
import { PayloadAction } from '@reduxjs/toolkit';

import { ALL_ENTITY_FILTER_OPTIONS } from 'app/modules/entitiesRefresh/filters';
import { LocalStorageKeys } from 'app/shared/constants/localStorage';

import {
  addAttachments,
  deleteAttachment,
} from 'app/modules/attachmentsRefresh/sliceAttachments';
import {
  addGDriveDocs,
  addS3Docs,
  deleteDocument,
} from 'app/modules/entitiesRefresh/api';
import { createGDriveDocPayload } from 'app/modules/googleDrive/utils';
import { getLocalStorageJSON } from 'app/shared/utils/localStorage';
import { getValidFilters } from 'app/modules/filters/utils';
import { selectIDP } from 'app/modules/session/selectors';
import { selectGDriveFolderID } from 'app/modules/orgSettings/selectors';
import { sendErrorToast, sendSuccessToast } from 'app/shared/toasts/actions';
import { u21CreateAsyncThunk } from 'app/shared/thunk/u21CreateAsyncThunk';
import { u21CreateSlice } from 'app/shared/thunk/u21CreateSlice';
import { uploadToGoogleDrive } from 'app/shared/utils/uploadFiles';
import pluralize from 'pluralize';

const ENTITIES_NAME = 'entitiesRefresh';

interface EntitiesState {
  filters: Filter[];
  loadingAddDocs: boolean;
}

const initialState: EntitiesState = {
  filters: getValidFilters(
    getLocalStorageJSON(LocalStorageKeys.ENTITY_FILTERS),
    ALL_ENTITY_FILTER_OPTIONS,
  ),
  loadingAddDocs: false,
};

export const addS3DocsThunk = u21CreateAsyncThunk<{
  files: File[];
  externalId: string;
}>(
  `${ENTITIES_NAME}/ADD_S3_DOCS`,
  async ({ files, externalId }, { dispatch }) => {
    const label = pluralize('document', files.length);
    try {
      const response = await addS3Docs(externalId, files);
      dispatch(addAttachments(response.attachments));
      dispatch(sendSuccessToast(`Successfully added ${label}.`));
    } catch (e) {
      dispatch(sendErrorToast(`Failed to add ${label}. Please try again.`));
      throw e;
    }
  },
);

export const addGDriveDocsThunk = u21CreateAsyncThunk<
  AddGDriveDocsPayload & { externalId: string }
>(
  `${ENTITIES_NAME}/ADD_GDRIVE_DOCS`,
  async ({ externalId, ...payload }, { dispatch }) => {
    const label = pluralize('documents', payload.docs.length);
    try {
      const response = await addGDriveDocs(externalId, payload);
      dispatch(addAttachments(response.attachments));
    } catch (e) {
      dispatch(sendErrorToast(`Failed to add ${label}. Please try again.`));
      throw e;
    }
  },
);

export const uploadAndAddGoogleDocsThunk = u21CreateAsyncThunk<{
  files: File[];
  externalId: string;
}>(
  `${ENTITIES_NAME}/UPLOAD_ADD_GDRIVE_DOCS`,
  async ({ files, externalId }, { dispatch, getState }) => {
    const { access_token: accessToken } = selectIDP(getState());
    const gdriveFolder = selectGDriveFolderID(getState());

    dispatch(setLoadingAddDocs(true));

    files.forEach((i, idx) => {
      uploadToGoogleDrive(
        i,
        gdriveFolder,
        accessToken,
        (_, uploadedFile: UploadedFile) => {
          const docs: GoogleDriveDoc[] = [
            createGDriveDocPayload(i, uploadedFile),
          ];
          dispatch(addGDriveDocsThunk({ externalId, docs }));
        },
        () => {
          if (idx === files.length - 1) {
            dispatch(setLoadingAddDocs(false));
          }
          dispatch(sendErrorToast(`Failed to upload ${i.name}`));
        },
        i.name,
      );
    });
  },
);

export const deleteDocThunk = u21CreateAsyncThunk<{
  externalId: string;
  docID: number;
}>(
  `${ENTITIES_NAME}/DELETE_DOC`,
  async ({ docID, externalId }, { dispatch }) => {
    try {
      await deleteDocument(externalId, docID);
      dispatch(deleteAttachment(docID));
      dispatch(sendSuccessToast('Successfully deleted document.'));
    } catch (e) {
      dispatch(sendErrorToast('Failed to delete document. Please try again'));
      throw e;
    }
  },
);

export const setEntityFilters = u21CreateAsyncThunk<Filter[], Filter[]>(
  `${ENTITIES_NAME}/SET_ENTITY_FILTERS`,
  (payload) => {
    localStorage.setItem(
      LocalStorageKeys.ENTITY_FILTERS,
      JSON.stringify(payload),
    );
    return payload;
  },
);

export const entitiesSlice = u21CreateSlice({
  initialState,
  name: ENTITIES_NAME,
  reducers: {
    setLoadingAddDocs: (draft, action: PayloadAction<boolean>) => {
      draft.loadingAddDocs = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addLoadingCase(addS3DocsThunk, 'loadingAddDocs')
      .addLoadingCase(addGDriveDocsThunk, 'loadingAddDocs')
      .addCase(setEntityFilters.fulfilled, (draft, action) => {
        draft.filters = action.payload;
      });
  },
});

export const ENTITIES_SLICE_NAME = entitiesSlice.name;
export const { setLoadingAddDocs } = entitiesSlice.actions;
export default entitiesSlice.reducer;
