// Components
import { Button } from 'semantic-ui-react';

// Models
import { ExportStatus } from 'app/modules/fileExportsOld/models';

// Styles
import scssStyles from 'app/shared/events/styles/EventTable.module.scss';

// API
import { downloadFileExport } from 'app/shared/api/fileExports';

interface Props {
  row: { id: number; file_name: string; status: ExportStatus };
}

export const FileExportsDownload = ({ row }: Props) => {
  const { id, file_name: fileName, status } = row;
  const enabledStatus: ExportStatus = 'READY_FOR_DOWNLOAD';

  return (
    <div className={scssStyles.downloadIconContainer}>
      <Button
        className={scssStyles.downloadIconButton}
        circular
        icon="download"
        disabled={Boolean(status !== enabledStatus)}
        onClick={() => downloadFileExport({ id, file_name: fileName })}
      />
    </div>
  );
};
