import { HTMLProps, ReactElement, useMemo } from 'react';
import { Theme } from '@mui/material/styles';

import { debounce } from 'lodash';
import { getDOMProps } from 'app/shared/utils/react';
import styled, { createGlobalStyle } from 'styled-components';

import {
  MentionsInput,
  Mention,
  MentionItem,
  SuggestionDataItem,
} from 'react-mentions';
import { U21Typography } from 'app/shared/u21-ui/components/display/typography/U21Typography';

export interface U21MentionOption {
  icon?: ReactElement;
  label: string;
  value: string;
}

export interface U21MentionsTextFieldProps
  extends Omit<HTMLProps<HTMLDivElement>, 'onChange'> {
  options: U21MentionOption[];
  delay?: number;
  onChange: (
    value: string,
    newPlainTextValue: string,
    mentions: MentionItem[],
  ) => void;
  onMention: (value: string) => void;
  placeholder?: string;
  value?: string;
}

export const U21MentionsTextField = (props: U21MentionsTextFieldProps) => {
  const {
    options,
    delay = 300,
    onChange,
    onMention,
    placeholder,
    value,
    ...rest
  } = props;

  const data = useMemo(
    () =>
      options.map((i) => ({
        display: i.label,
        icon: i.icon,
        id: i.value,
      })),
    [options],
  );

  const onMentionDebounced = useMemo(
    () => debounce(onMention, delay),
    [delay, onMention],
  );

  return (
    <Container {...getDOMProps(rest)}>
      <GlobalStyle />
      <MentionsInput
        className="mentions-input"
        onChange={(_, newValue, plainText, mentions) => {
          onMentionDebounced(newValue);
          onChange(newValue, plainText, mentions);
        }}
        placeholder={placeholder}
        suggestionsPortalHost={document.body}
        value={value || ''}
      >
        <Mention
          appendSpaceOnAdd
          className="mention-item"
          data={data}
          displayTransform={(id, display) => `@${display}`}
          renderSuggestion={(
            suggestion: SuggestionDataItem & { icon?: ReactElement },
            query: string,
            highlightedDisplay: ReactElement,
          ) => {
            return (
              <U21Typography icon={suggestion.icon} variant="body2">
                {highlightedDisplay}
              </U21Typography>
            );
          }}
          trigger="@"
        />
      </MentionsInput>
    </Container>
  );
};

const Container = styled.div`
  .mentions-input {
    min-height: 63px;

    .mentions-input__highlighter {
      padding: 8.5px 6px;
    }

    .mention-item {
      padding: 2px 0;
      border-radius: 8px;
      background-color: ${(props) => props.theme.palette.primary.lighter};
    }

    .mentions-input__input {
      padding: 8.5px 6px;
      border: 1px solid ${(props) => props.theme.palette.grey[500_32]};
      border-radius: 8px;

      &:hover {
        border-color: ${(props) => props.theme.palette.grey[800]};
      }

      &:focus {
        /* use 1px border + outline to get 2px border but avoid changing content size */
        border-color: ${(props) => props.theme.palette.primary.main};
        outline: 1px solid ${(props) => props.theme.palette.primary.main};
      }

      ::placeholder {
        color: ${(props) => props.theme.palette.grey[500]};
        ${(props) => props.theme.typography.body2};
      }
    }
  }
`;

/* important needed to override react-mentions inline-style */
const GlobalStyle = createGlobalStyle<{ theme: Theme }>`
  .mentions-input__suggestions {
    border-radius: 8px;
    box-shadow: ${(props) => props.theme.customShadows.z12};
    /* make it above modals */
    z-index: 2000 !important;

    .mentions-input__suggestions__list {
      padding: 8px 0 !important;
    }

    .mentions-input__suggestions__item {
      margin: 0 8px;
      padding: 8px;
      border-radius: 8px;
    }

    .mentions-input__suggestions__item--focused {
      background-color: ${(props) => props.theme.palette.grey[500_12]};
    }
  }
`;
