import { PayloadAction } from '@reduxjs/toolkit';
import { EMPTY_FILTERS } from 'app/modules/networkAnalysis/constants';
import { Filters } from 'app/modules/networkAnalysis/types';
import { u21CreateSlice } from 'app/shared/thunk/u21CreateSlice';

const NETWORK_ANALYSIS_NAME = 'networkAnalysis';

const initialState = {
  filters: EMPTY_FILTERS,
  queuedRequests: [] as string[],
};

const networkAnalysisRefreshReducer = u21CreateSlice({
  name: NETWORK_ANALYSIS_NAME,
  initialState,
  reducers: {
    setFilters: <T extends keyof typeof EMPTY_FILTERS>(
      draft,
      {
        payload,
      }: PayloadAction<{
        type: T;
        val: Filters[T];
      }>,
    ) => {
      draft.filters[payload.type] = payload.val;
    },
    clearFilters: (draft) => {
      draft.filters = EMPTY_FILTERS;
    },
    addQueuedRequest: (draft, { payload }: PayloadAction<string>) => {
      const queuedRequests = new Set(draft.queuedRequests);
      queuedRequests.add(payload);
      draft.queuedRequests = Array.from(queuedRequests);
    },
    removeQueuedRequest: (draft, { payload }: PayloadAction<string>) => {
      const queuedRequests = new Set(draft.queuedRequests);
      queuedRequests.delete(payload);
      draft.queuedRequests = Array.from(queuedRequests);
    },
  },
});

export const { name, reducer, actions } = networkAnalysisRefreshReducer;
export const {
  setFilters,
  clearFilters,
  addQueuedRequest,
  removeQueuedRequest,
} = actions;
