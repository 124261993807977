import { call, put, takeEvery } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { SearchActionTypes } from 'app/modules/search/models';

// Api
import { eventsSearch as eventsSearchApi } from 'app/shared/api/search';

// Actions
import {
  eventsSearch as eventsSearchAction,
  eventsSearchSuccess,
} from 'app/modules/search/actions';

const rootAction = SearchActionTypes.EVENTS_SEARCH;
function* eventsSearch(
  payload: ReturnType<typeof eventsSearchAction>['payload'],
) {
  const config = {
    rootAction,
    request: call(eventsSearchApi, payload),
    errorToastMessage: `There was an error searching events. Please try again`,
    success: function* onSuccess(
      result: ReturnType<typeof eventsSearchSuccess>['payload'],
    ) {
      yield put(eventsSearchSuccess(result));
    },
  };
  yield call(makeSagaRequest, config);
}

function* eventsSearchFlow({ payload }: ReturnType<typeof eventsSearchAction>) {
  yield call(eventsSearch, payload);
}

export default function* watchEventsSearch() {
  yield takeEvery(rootAction, eventsSearchFlow);
}
