import { FeatureFlagsState } from 'app/shared/featureFlags/models';

import getFromProcessEnv from 'app/shared/utils/getFromProcessEnv';

export const UNLOADED_FLAGS_KEY = 'LAUNCH_DARKLY_FLAGS_UNLOADED';

export const initialState: Readonly<FeatureFlagsState> = {
  flags: {
    [UNLOADED_FLAGS_KEY]: true,
  },
};

export const LD_CONFIG = {
  clientSideID: getFromProcessEnv('LAUNCH_DARKLY_CLIENT_ID'),
  context: {
    anonymous: true,
    custom: {
      lumos_url: getFromProcessEnv('REACT_APP_AUTH0_LOGIN_REDIRECT_URL'),
    },
  },
  options: { useReport: true, allAttributesPrivate: true },
  reactOptions: { useCamelCaseFlagKeys: false, sendEventsOnFlagRead: false },
};
