import { FC, HTMLProps, ReactNode } from 'react';

import { getDOMProps } from 'app/shared/utils/react';
import styled from 'styled-components';

import { IconFilter, IconX } from '@u21/tabler-icons';
import {
  U21Button,
  U21ButtonProps,
  U21ContentDisplay,
} from 'app/shared/u21-ui/components';

export interface U21FiltersBarProps extends HTMLProps<HTMLDivElement> {
  title?: string;
  children?: ReactNode;
  onClearAllFilter?: U21ButtonProps['onClick'];
}

/**
 * @deprecated Use Filters instead
 */
export const U21FiltersBar: FC<U21FiltersBarProps> = ({
  title = 'Filters',
  children,
  onClearAllFilter,
  ...rest
}) => {
  return (
    <StyledU21ContentDisplay
      action={
        onClearAllFilter ? (
          <U21Button
            size="small"
            startIcon={<IconX />}
            onClick={onClearAllFilter}
            variant="contained"
          >
            Clear filters
          </U21Button>
        ) : undefined
      }
      icon={<IconFilter />}
      title={title}
      {...getDOMProps(rest)}
    >
      {children}
    </StyledU21ContentDisplay>
  );
};

const StyledU21ContentDisplay = styled(U21ContentDisplay)`
  margin-bottom: 16px;
`;
