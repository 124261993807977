import { PayloadAction } from '@reduxjs/toolkit';
import { u21CreateAsyncThunk } from 'app/shared/thunk/u21CreateAsyncThunk';
import { u21CreateSlice } from 'app/shared/thunk/u21CreateSlice';

// APIs
import { addFiles, createCase, updateCase } from 'app/shared/api/cases';
import {
  retrieveInvestigationAgentStats,
  retrieveInvestigationsCases,
} from 'app/modules/investigations/api';
import { submitCustomChecklist } from 'app/shared/customChecklist/api';

// Models
import { CustomChecklistSubmissionPayload } from 'app/shared/customChecklist/models';
import { GoogleDriveDoc } from 'app/modules/uploads/models';
import { CreateCasePayload, NewCasesState } from 'app/modules/cases/models';
import { CreateCasePayload as CreateCasePayloadOld } from 'app/modules/casesOld/models';
import { CaseEditPayload } from 'app/modules/cases/types/requests';
import { InvestigationType } from 'app/modules/investigations/models';
import { Filter } from 'app/modules/filters/models';
import { FullCaseResponse } from 'app/modules/casesOld/types';

// Actions
import { sendErrorToast } from 'app/shared/toasts/actions';
import {
  editCaseSuccess,
  uploadS3FilesSuccess,
} from 'app/modules/casesOld/actions';
import {
  InvestigationsAgentStatsResponse,
  InvestigationsCaseResponse,
} from 'app/modules/investigations/types';

// Selectors
import { selectCustomChecklistId } from 'app/shared/customChecklist/selectors';

// Thunks
import {
  addGoogleDocsThunk,
  uploadAndAddGoogleDocsThunk,
} from 'app/modules/investigations/sliceInvestigations';

// Constants
import { LocalStorageKeys } from 'app/shared/constants/localStorage';
import { selectHasEditCasesPermission } from 'app/modules/session/selectors';
import { queryClient } from 'app/cache/queryClient';
import { CASE_QUERY_KEYS } from 'app/modules/cases/queries/keys';

const CASES_NAME = 'casesRefresh';

const initialState: Readonly<NewCasesState> = {
  adminCaseFilters: [],
  myCaseFilters: [],
  queuedCaseFilters: [],
  agentStats: {
    open_count: 0,
    completed_last_24_hrs_count: 0,
    fetched_at: '',
  },
  cases: [],
  count: 0,
  loadingCreateCase: false,
  loadingRetrieveAgentStats: false,
  loadingRetrieveCases: false,
  loadingEditCase: false,
};

export const setAdminCaseFilters = u21CreateAsyncThunk<Filter[], Filter[]>(
  `${CASES_NAME}/SET_ADMIN_CASE_FILTERS`,
  (payload) => {
    localStorage.setItem(
      LocalStorageKeys.ADMIN_CASE_FILTERS,
      JSON.stringify(payload),
    );
    return payload;
  },
);

export const setMyCaseFilters = u21CreateAsyncThunk<Filter[], Filter[]>(
  `${CASES_NAME}/SET_MY_CASE_FILTERS`,
  (payload) => {
    localStorage.setItem(
      LocalStorageKeys.MY_CASE_FILTERS,
      JSON.stringify(payload),
    );
    return payload;
  },
);

export const setQueuedCaseFilters = u21CreateAsyncThunk<Filter[], Filter[]>(
  `${CASES_NAME}/SET_QUEUED_CASE_FILTERS`,
  (payload) => {
    localStorage.setItem(
      LocalStorageKeys.QUEUED_CASE_FILTERS,
      JSON.stringify(payload),
    );
    return payload;
  },
);

export const retrieveCasesThunk = u21CreateAsyncThunk(
  `${CASES_NAME}/GET_CASES`,
  retrieveInvestigationsCases<InvestigationsCaseResponse>,
);

export const retrieveAgentStatsThunk = u21CreateAsyncThunk(
  `${CASES_NAME}/GET_AGENT_STATS`,
  retrieveInvestigationAgentStats,
);

export const createCaseThunk = u21CreateAsyncThunk(
  `${CASES_NAME}/CREATE_CASE`,
  async (
    {
      checklist,
      gdriveDocs = [],
      gdrivePickedDocs = [],
      payload,
      s3Docs = [],
    }: {
      checklist: CustomChecklistSubmissionPayload['content'];
      gdriveDocs?: File[];
      gdrivePickedDocs?: GoogleDriveDoc[];
      payload: CreateCasePayload;
      s3Docs?: File[];
    },
    { dispatch, getState },
  ) => {
    try {
      // new payload type doesn't have docs or s3_docs
      const response = await createCase(payload as CreateCasePayloadOld);
      const { id } = response;
      if (s3Docs.length) {
        const addS3FilesPayload = { id: String(id), files: s3Docs };
        try {
          const result = await addFiles(addS3FilesPayload);
          dispatch(uploadS3FilesSuccess(result));
        } catch (error) {
          sendErrorToast('Error adding documents to case.');
        }
      }

      if (gdriveDocs.length) {
        dispatch(
          uploadAndAddGoogleDocsThunk({
            files: gdriveDocs,
            id,
            type: InvestigationType.CASE,
          }),
        );
      }

      if (gdrivePickedDocs.length) {
        dispatch(
          addGoogleDocsThunk({
            gdriveDocs: gdrivePickedDocs,
            id,
            type: InvestigationType.CASE,
          }),
        );
      }

      // getState returns unknown type
      const checklistID = selectCustomChecklistId(getState());
      const hasEditAlertsPermission = selectHasEditCasesPermission(getState());
      if (checklistID && hasEditAlertsPermission) {
        await submitCustomChecklist({
          checklist_id: checklistID,
          article_id: id,
          content: checklist,
        });
      }

      return response;
    } catch (e) {
      dispatch(sendErrorToast('Unable to create case.'));
      throw e;
    }
  },
);

export const editCaseThunk = u21CreateAsyncThunk<
  CaseEditPayload,
  FullCaseResponse
>(`${CASES_NAME}/EDIT_CASE`, async (payload, { dispatch }) => {
  try {
    const response = await updateCase(payload);
    dispatch(editCaseSuccess(response));
    queryClient.invalidateQueries({
      queryKey: CASE_QUERY_KEYS.getCase(payload.id),
    });
    return response;
  } catch (e) {
    dispatch(sendErrorToast('Unable to edit case.'));
    throw e;
  }
});

const casesSlice = u21CreateSlice({
  name: CASES_NAME,
  initialState,
  reducers: {
    updateCasesCache: (draft, action: PayloadAction<FullCaseResponse>) => {
      draft.cases = draft.cases.map((i) => {
        if (i.id === action.payload.id) {
          return {
            ...i,
            ...action.payload,
          };
        }
        return i;
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addLoadingCase(
        retrieveCasesThunk,
        'loadingRetrieveCases',
        (draft, action) => {
          draft.cases = action.payload.cases;
          draft.count = action.payload.count;
        },
      )
      .addLoadingCase(createCaseThunk, 'loadingCreateCase')
      .addLoadingCase(editCaseThunk, 'loadingEditCase')
      .addLoadingCase(
        retrieveAgentStatsThunk,
        'loadingRetrieveAgentStats',
        (draft, action: PayloadAction<InvestigationsAgentStatsResponse>) => {
          draft.agentStats = action.payload;
          draft.loadingRetrieveAgentStats = false;
        },
      )
      .addCase(setAdminCaseFilters.fulfilled, (draft, action) => {
        draft.adminCaseFilters = action.payload;
      })
      .addCase(setMyCaseFilters.fulfilled, (draft, action) => {
        draft.myCaseFilters = action.payload;
      })
      .addCase(setQueuedCaseFilters.fulfilled, (draft, action) => {
        draft.queuedCaseFilters = action.payload;
      });
  },
});

export const CASES_SLICE_NAME = casesSlice.name;
export const { updateCasesCache } = casesSlice.actions;
export default casesSlice.reducer;
