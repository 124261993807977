import uploadDatafileAsync from 'app/modules/uploads/sagas/uploadDatafileAsync';
import retrieveDatafiles from 'app/modules/uploads/sagas/retrieveDatafiles';
import deleteDatafile from 'app/modules/uploads/sagas/deleteDatafile';
import processDatafile from 'app/modules/uploads/sagas/processDatafile';

const sagaRegistry = [
  retrieveDatafiles,
  uploadDatafileAsync,
  deleteDatafile,
  processDatafile,
];

export default sagaRegistry;
