export const DISPOSITION_TO_READABLE = {
  FALSE_POSITIVE: 'False positive',
  TRUE_POSITIVE: 'True positive',
  UNRESOLVED: 'Unresolved',
  CLOSED: 'Closed',
};

export const formatDisposition = (
  disposition: string | undefined = '',
): string => {
  return DISPOSITION_TO_READABLE[disposition] || disposition;
};
