import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { TransactionsActionTypes } from 'app/modules/transactions/models';

// Actions
import {
  retrieveActionEvent as retrieveActionEventAction,
  retrieveActionEventSuccess,
} from 'app/modules/transactions/actions';
import { setComments } from 'app/modules/comments/actions';

// API
import { retrieveActionEvent as retrieveActionEventApi } from 'app/shared/api/events';

const rootAction = TransactionsActionTypes.RETRIEVE_ACTION_EVENT;
function* retrieveActionEvent({
  payload,
}: ReturnType<typeof retrieveActionEventAction>) {
  const config = {
    rootAction,
    request: call(retrieveActionEventApi, payload),
    errorToastMessage: `Error retrieving action event with id: ${payload}`,
    success: function* onSuccess(
      result: ReturnType<typeof retrieveActionEventSuccess>['payload'],
    ) {
      // set comments to txn comments
      yield put(setComments(result.comments));
      yield put(retrieveActionEventSuccess(result));
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watchRetrieveActionEvent() {
  yield takeLatest(rootAction, retrieveActionEvent);
}
