import { post } from 'app/shared/utils/fetchr';

// Actions
import {
  entitiesSearch as entitiesSearchAction,
  rulesSearch as rulesSearchAction,
  alertsSearch as alertsSearchAction,
  eventsSearch as eventsSearchAction,
  teamsSearch as teamsSearchAction,
} from 'app/modules/search/actions';

// Models
import { EventsSearchResponse, SearchRequest } from 'app/modules/search/models';

// Utils
import routes from 'app/shared/utils/routes';

export const entitiesSearch = (
  payload: ReturnType<typeof entitiesSearchAction>['payload'],
) => {
  return post(routes.patronus.entitiesSearch, payload);
};

export const rulesSearch = (
  payload: ReturnType<typeof rulesSearchAction>['payload'],
) => {
  return post(routes.patronus.rulesSearch, payload);
};

export const alertsSearch = (
  payload: ReturnType<typeof alertsSearchAction>['payload'],
) => {
  return post(routes.patronus.alertsSearch, payload);
};

export const teamsSearch = (
  payload: ReturnType<typeof teamsSearchAction>['payload'],
) => {
  return post(routes.patronus.teamsSearch, payload);
};

export const eventsSearch = (
  payload: ReturnType<typeof eventsSearchAction>['payload'],
) => {
  return post(routes.patronus.eventsSearch, payload);
};

export const actionEventsSearch = (payload: SearchRequest) => {
  return post<EventsSearchResponse>(
    routes.patronus.actionEventsSearch,
    payload,
  );
};
