export const getTagDisplayName = (tag: {
  name: string;
  type: string;
}): string => {
  return `${tag.type}:${tag.name}`;
};

export const createTagValidation =
  (tagsSet: Set<string>) =>
  (value): string | undefined => {
    if (!value) {
      return 'Tag is required.';
    }
    const split = value.split(':');
    const [type, name] = split;
    if (!type || !name) {
      return 'Tags must use the format type:text';
    }
    if (split.length > 2) {
      return 'Tags must not have more than one `:`';
    }
    if (tagsSet.has(value)) {
      return `${value} already exists`;
    }
    return undefined;
  };
