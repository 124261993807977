import listWebhooks from 'app/modules/webhooks/sagas/listWebhooks';
import createWebhook from 'app/modules/webhooks/sagas/createWebhook';
import getWebhook from 'app/modules/webhooks/sagas/getWebhook';
import deleteWebhook from 'app/modules/webhooks/sagas/deleteWebhook';
import editWebhook from 'app/modules/webhooks/sagas/editWebhook';
import rotateWebhookKey from 'app/modules/webhooks/sagas/rotateWebhookKey';
import getWebhookCallbacks from 'app/modules/webhooks/sagas/getWebhookCallbacks';
import testWebhook from 'app/modules/webhooks/sagas/testWebhook';
import retrieveActionTypes from 'app/modules/webhooks/sagas/retrieveActionTypes';

const sagaRegistry = [
  listWebhooks,
  createWebhook,
  getWebhook,
  deleteWebhook,
  editWebhook,
  retrieveActionTypes,
  rotateWebhookKey,
  getWebhookCallbacks,
  testWebhook,
];

export default sagaRegistry;
