import { useState, useMemo, useEffect } from 'react';

// Components
import {
  U21Checkbox,
  U21Modal,
  U21Select,
  U21SelectOptionProps,
  U21Spacer,
  U21TextField,
  U21Typography,
} from 'app/shared/u21-ui/components';
import {
  IconCheck,
  IconCheckbox,
  IconChecks,
  IconFileDescription,
  IconFileText,
  IconCalendar,
  IconAlertTriangle,
} from '@u21/tabler-icons';
import { CreateSelectOptions } from 'app/shared/u21-ui/components/dashboard/CreateSelectOptions';

// Models
import {
  ChecklistSelectOption,
  CustomChecklistDefinition,
} from 'app/shared/customChecklist/models';

// Helpers
import {
  createUniqueItemKey,
  getChecklistItemDefinition,
} from 'app/modules/investigationChecklist/helpers';

const DEFAULT_ITEM_TYPE = 'checkbox';
const CONDITIONAL_FIELD_KEY = 'conditional';
export const CHECKLIST_ITEMS_OPTIONS: U21SelectOptionProps[] = [
  {
    text: 'Checkbox',
    value: DEFAULT_ITEM_TYPE,
    icon: <IconCheckbox />,
  },
  {
    text: 'Checkbox w/ conditional Textarea',
    value: CONDITIONAL_FIELD_KEY,
    icon: <IconCheckbox />,
  },
  {
    text: 'Short Text Input',
    value: 'input',
    icon: <IconFileDescription />,
  },
  {
    text: 'Long Textarea',
    value: 'textarea',
    icon: <IconFileText />,
  },
  {
    text: 'Single Select Dropdown',
    value: 'dropdown',
    icon: <IconCheck />,
  },
  {
    text: 'Multi-Select Dropdown',
    value: 'multi_select',
    icon: <IconChecks />,
  },
  {
    text: 'Date Range',
    value: 'date_range',
    icon: <IconCalendar />,
  },
  {
    text: 'Warning Label',
    value: 'label',
    icon: <IconAlertTriangle />,
  },
];

const EMPTY_CHECKLIST_ITEM = {
  key: '',
  required: false,
  type: DEFAULT_ITEM_TYPE,
};
const EMPTY_CONDITIONAL_CHECKLIST_ITEM = { key: '', required: true };

interface OwnProps {
  open: boolean;
  handleClose: () => void;
  handleSubmit: (
    checklistItem: CustomChecklistDefinition,
    conditionalChecklistItem?: CustomChecklistDefinition,
  ) => void;
  checklistGroupDefinition: CustomChecklistDefinition;
  itemToEdit?: CustomChecklistDefinition;
  conditionalItemToEdit?: CustomChecklistDefinition;
}

export const U21CreateChecklistItemModal = ({
  open,
  handleClose,
  handleSubmit,
  checklistGroupDefinition,
  itemToEdit,
  conditionalItemToEdit,
}: OwnProps) => {
  const [checklistItem, setChecklistItem] =
    useState<CustomChecklistDefinition>(EMPTY_CHECKLIST_ITEM);
  const [conditionalChecklistItem, setConditionalChecklistItem] =
    useState<CustomChecklistDefinition>(EMPTY_CONDITIONAL_CHECKLIST_ITEM);
  const [checklistItemType, setChecklistItemType] = useState(DEFAULT_ITEM_TYPE);

  useEffect(() => {
    if (itemToEdit && itemToEdit.type) {
      setChecklistItem(itemToEdit);

      if (conditionalItemToEdit) {
        setChecklistItemType(CONDITIONAL_FIELD_KEY);
        setConditionalChecklistItem(conditionalItemToEdit);
      } else {
        setChecklistItemType(itemToEdit.type);
      }
    }
  }, [itemToEdit, conditionalItemToEdit]);

  const isChecklistItemValid = useMemo(() => {
    const itemHasLabel = Boolean(checklistItem.label);

    switch (checklistItemType) {
      case 'dropdown':
      case 'multi_select':
        return checklistItem?.options?.length && itemHasLabel;
      case CONDITIONAL_FIELD_KEY:
        return (
          Boolean(conditionalChecklistItem.placeholder) &&
          Boolean(conditionalChecklistItem.enabled_by) &&
          itemHasLabel
        );
      default:
        return itemHasLabel;
    }
  }, [checklistItem, checklistItemType, conditionalChecklistItem]);

  const handleChecklistItemLabelChange = (labelValue?: string) => {
    const editChecklistItem: CustomChecklistDefinition = { ...checklistItem };
    const items = checklistGroupDefinition.items || [];
    const uniqueKey = labelValue ? createUniqueItemKey(labelValue, items) : '';
    const itemKey = itemToEdit ? itemToEdit.key : uniqueKey;
    editChecklistItem.label = labelValue;
    editChecklistItem.key = itemKey;

    if (checklistItemType === CONDITIONAL_FIELD_KEY) {
      const uniqueConditionalKey = `conditional-${itemKey}`;
      const editConditionalChecklistItem: CustomChecklistDefinition = {
        ...conditionalChecklistItem,
      };

      editConditionalChecklistItem.enabled_by = itemKey;
      editConditionalChecklistItem.key = conditionalItemToEdit
        ? conditionalItemToEdit.key
        : uniqueConditionalKey;

      setConditionalChecklistItem(editConditionalChecklistItem);
    }

    setChecklistItem(editChecklistItem);
  };

  const handleConditionalItemLabelChange = (labelValue?: string) => {
    const uniqueKey = `conditional-${checklistItem.key}`;

    const editConditionalChecklistItem: CustomChecklistDefinition = {
      ...conditionalChecklistItem,
    };

    editConditionalChecklistItem.placeholder = labelValue;
    editConditionalChecklistItem.key = conditionalItemToEdit
      ? conditionalItemToEdit.key
      : uniqueKey;

    setConditionalChecklistItem(editConditionalChecklistItem);
  };

  const handlePlaceholderTextareas = (placeholder?: string) => {
    const editChecklistItem: CustomChecklistDefinition = { ...checklistItem };

    editChecklistItem.placeholder = placeholder;

    setChecklistItem(editChecklistItem);
  };

  const handleRequired = (isRequired: boolean) => {
    const editChecklistItem: CustomChecklistDefinition = { ...checklistItem };

    editChecklistItem.required = isRequired;

    setChecklistItem(editChecklistItem);
  };

  const handleDropdownOptions = (options: ChecklistSelectOption[]) => {
    const editChecklistItem: CustomChecklistDefinition = { ...checklistItem };

    editChecklistItem.options = options;

    setChecklistItem(editChecklistItem);
  };

  const handleSelectItemType = (selectedType: string) => {
    setChecklistItemType(selectedType);

    const editChecklistItem: CustomChecklistDefinition = { ...checklistItem };

    if (selectedType === CONDITIONAL_FIELD_KEY) {
      const editConditionalChecklistItem: CustomChecklistDefinition = {
        ...conditionalChecklistItem,
      };
      editConditionalChecklistItem.type = 'textarea';

      if (checklistItem.key) {
        editConditionalChecklistItem.enabled_by = checklistItem.key;
      }

      editChecklistItem.required = false;
      setChecklistItem(editChecklistItem);

      setConditionalChecklistItem(editConditionalChecklistItem);
      editChecklistItem.type = 'checkbox';
    } else {
      setConditionalChecklistItem(EMPTY_CONDITIONAL_CHECKLIST_ITEM);
    }

    const emptyChecklistItem = {
      ...EMPTY_CHECKLIST_ITEM,
      type: selectedType === CONDITIONAL_FIELD_KEY ? 'checkbox' : selectedType,
      key: editChecklistItem.key,
      label: editChecklistItem.label,
      required: editChecklistItem.required,
    };
    return setChecklistItem(emptyChecklistItem);
  };

  const getAddText = useMemo(() => {
    const chosenItem = getChecklistItemDefinition(checklistItemType);

    if (itemToEdit || !chosenItem) {
      return 'Save';
    }

    return `Add ${chosenItem.text}`;
  }, [checklistItemType, itemToEdit]);

  const handleExit = () => {
    setChecklistItem(EMPTY_CHECKLIST_ITEM);
    setChecklistItemType(DEFAULT_ITEM_TYPE);
    setConditionalChecklistItem(EMPTY_CONDITIONAL_CHECKLIST_ITEM);
    handleClose();
  };

  const renderChecklistItemCRUD = () => {
    switch (checklistItemType) {
      case 'dropdown':
      case 'multi_select':
        return (
          <CreateSelectOptions
            data={checklistItem.options || []}
            handleEditSelectOptions={handleDropdownOptions}
          />
        );
      case CONDITIONAL_FIELD_KEY:
        return (
          <U21TextField
            value={conditionalChecklistItem.placeholder}
            onChange={handleConditionalItemLabelChange}
            label="Add placeholder for textarea"
            required
            startIcon={<IconFileText />}
          />
        );
      case 'textarea':
      case 'input':
        return (
          <U21TextField
            value={checklistItem.placeholder}
            onChange={handlePlaceholderTextareas}
            label={`Add optional placeholder for ${checklistItemType}`}
            type={checklistItemType === 'textarea' ? checklistItemType : 'text'}
          />
        );
      default:
        return null;
    }
  };

  const showRequiredCheckbox = useMemo(() => {
    return (
      checklistItemType !== CONDITIONAL_FIELD_KEY &&
      checklistItemType !== 'label'
    );
  }, [checklistItemType]);

  const submitChecklistItem = () => {
    if (conditionalChecklistItem.key) {
      handleSubmit(checklistItem, conditionalChecklistItem);
    } else {
      handleSubmit(checklistItem);
    }
    handleClose();
  };

  return (
    <U21Modal
      title={`${itemToEdit ? 'Edit' : 'Add'} List Item`}
      onExited={handleExit}
      onClose={handleClose}
      open={open}
      onAction={submitChecklistItem}
      actionButtonProps={{
        children: getAddText,
        color: 'primary',
        disabled: !isChecklistItemValid,
      }}
    >
      <U21Spacer spacing={4}>
        <U21Typography variant="overline">{`${checklistGroupDefinition.label} Group`}</U21Typography>
        <U21Spacer>
          <U21TextField
            value={checklistItem.label}
            onChange={handleChecklistItemLabelChange}
            label="Add a descriptive label"
            required
          />
          {showRequiredCheckbox && (
            <U21Checkbox
              onChange={handleRequired}
              checked={checklistItem.required}
              label="Make this a required List Item"
            />
          )}
        </U21Spacer>
        <U21Select
          clearable={false}
          value={checklistItemType}
          onChange={handleSelectItemType}
          options={CHECKLIST_ITEMS_OPTIONS}
          label="Select the item type to add to this group"
        />
        <U21Spacer>{renderChecklistItemCRUD()}</U21Spacer>
      </U21Spacer>
    </U21Modal>
  );
};
