import styled from 'styled-components';

// Models
import { BlacklistStatus } from 'app/modules/lists/models';

// Components
import { U21Chip } from 'app/shared/u21-ui/components';

export const MatchlistStatusPill = ({
  status,
}: {
  status: BlacklistStatus;
}) => {
  switch (status) {
    case 'ACTIVE':
      return (
        <StyledChip color="success" variant="ghost">
          Active
        </StyledChip>
      );
    case 'INACTIVE':
      return (
        <StyledChip color="error" variant="ghost">
          Inactive
        </StyledChip>
      );
    default:
      return (
        <StyledChip
          tooltip="Contact Unit21 for more information"
          variant="ghost"
        >
          Other
        </StyledChip>
      );
  }
};

const StyledChip = styled(U21Chip)`
  min-width: min-content;
`;
