import {
  SortTypes,
  U21TableColumn,
  U21TableColumnTypes,
} from 'app/shared/u21-ui/components/display/table/models';

import { U21ExpandableArrow } from 'app/shared/u21-ui/components/display/table/U21ExpandableArrow';
import { U21TableBodyCheckbox } from 'app/shared/u21-ui/components/display/table/U21TableBodyCheckbox';
import { U21TableHeaderCheckbox } from 'app/shared/u21-ui/components/display/table/U21TableHeaderCheckbox';

export const DEFAULT_COLUMN = {
  Cell: undefined,
  Header: undefined,
  maxWidth: 1000,
  minWidth: 50,
  width: 150,
};

export const EXPANDABLE_COLUMN_ARROW: Partial<
  U21TableColumn<{
    id: number | string;
  }>
> = {
  Cell: U21ExpandableArrow,
  disableResizing: true,
  disableSortBy: true,
  id: 'expand',
  maxWidth: 36,
  minWidth: 36,
  width: 36,
};

export const SELECT_COLUMN_CHECKBOX: Partial<
  U21TableColumn<{
    id: number | string;
  }>
> = {
  Cell: U21TableBodyCheckbox,
  disableResizing: true,
  disableSortBy: true,
  Header: U21TableHeaderCheckbox,
  id: 'selector',
  maxWidth: 52,
  minWidth: 52,
  width: 52, // width of checkbox + cell padding
};

// indicates get count operation timed out on the BE
export const EMPTY_COUNT = null;

export const PAGE_SIZE_OPTIONS = [
  { value: 5, text: '5 rows per page' },
  { value: 10, text: '10 rows per page' },
  { value: 25, text: '25 rows per page' },
  { value: 50, text: '50 rows per page' },
  { value: 100, text: '100 rows per page' },
];

export const DEFAULT_PAGE = 1;
export const DEFAULT_PAGE_SIZE = 25;

export const DEFAULT_STATE_CHANGE_DELAY = 300;

export const DEFAULT_TABLE_PREFRENCES = {
  pageSize: DEFAULT_PAGE_SIZE,
  sortBy: [
    {
      desc: true,
      id: 'id',
    },
  ],
  columnWidths: {},
};

export const COLUMN_TYPE_TO_SORT_TYPE: Partial<
  Record<U21TableColumnTypes, SortTypes>
> = {
  [U21TableColumnTypes.BOOLEAN]: 'alphanumeric',
  [U21TableColumnTypes.DATE]: 'datetime',
  [U21TableColumnTypes.DATETIME]: 'datetime',
  [U21TableColumnTypes.LIST]: 'list',
};
