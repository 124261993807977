// Models
import { RuleMetadata, PaginatedAlerts } from 'app/modules/rules/models';
import { TableConfig } from 'app/shared/pagination/models';
import { PaginationPayload } from 'app/shared/models';

// Types
import { IRSchema } from 'app/modules/rulesAdvanced/types/RulesRepresentation';

// Constants
import { PRESET_DATE_WITH_TIME_IN_UTC } from 'app/shared/constants';
import {
  DEFAULT_OFFSET,
  DEFAULT_PAGINATION_LIMIT,
} from 'app/shared/pagination/constants';
import { DropdownItemProps } from 'semantic-ui-react';
import { FullRuleResponse } from 'app/modules/rules/types';

export const INITIAL_RULE_ID = -1;

export const RULE_VALIDATION_ALERT_TRANSACTIONS_PAYLOAD: PaginationPayload = {
  offset: DEFAULT_OFFSET,
  limit: DEFAULT_PAGINATION_LIMIT,
};

export const RULE_VALIDATION_ALERT_ENTITIES_PAYLOAD: PaginationPayload = {
  offset: DEFAULT_OFFSET,
  limit: DEFAULT_PAGINATION_LIMIT,
};

export const INITIAL_PAGINATED_ALERTS: PaginatedAlerts = {
  alerts: [],
  count: 0,
};

export const TIME_STRING_FORMAT = 'dd MMM yyyy HH:mm';
export const DEFAULT_DATA_MIGRATION_LAG = 720;
export const STRIDE_WITH_NO_WINDOW: string = 'stride_with_no_window';
export const FILE_EXPORT_WINDOW_LIMIT: number = 50;
export const EXECUTION_FREQUENCY_OVERLAP_PERCENT_DEFAULT: number = 2;

export const RULES_TABLE_CONFIG: TableConfig[] = [
  {
    key: 'id',
    type: 'text',
    label: 'ID',
    collapsing: true,
  },
  {
    key: 'created_at',
    type: 'datetime',
    label: 'Created At',
    collapsing: true,
  },
  {
    key: 'title',
    type: 'text',
    label: 'Title',
  },
  {
    key: 'progress',
    type: 'rule_validation_progress',
    label: 'Progress',
    collapsing: true,
    sortable: false,
  },
  {
    key: 'status',
    type: 'label',
    label: 'Status',
    collapsing: true,
    sortable: false,
  },
  {
    key: 'completed_at',
    type: 'datetime',
    label: 'Completed At',
    collapsing: true,
    sortable: true,
  },
  {
    key: 'created_by_readable',
    type: 'text',
    label: 'Created By',
    collapsing: true,
    sortable: false,
  },
];

export const WHITELISTED_RULES_TABLE_CONFIG: TableConfig[] = [
  ...RULES_TABLE_CONFIG,
  {
    key: 'expiration',
    type: 'datetime',
    label: 'Expires',
    collapsing: true,
  },
];

export const INSIGHTS_RULES_TABLE_CONFIG: TableConfig[] = [
  {
    key: 'id',
    type: 'text',
    label: 'ID',
  },
  {
    key: 'deploy_start_date',
    type: 'datetime',
    label: 'Deployed On',
  },
  {
    key: 'title',
    type: 'text',
    label: 'Title',
  },
  {
    key: 'status',
    type: 'label',
    label: 'Status',
  },
  {
    key: 'created_by_readable',
    type: 'text',
    label: 'Created By',
  },
];

export const INITIAL_ENTITY_WHITELIST_PAGINATION_SETTINGS = {
  pageLimit: 25,
  totalPages: 0,
  pageOffset: 1,
};

export const INITIAL_TRIGGERED_ALERT_PAGINATION_SETTINGS = {
  pageLimit: 25,
  totalPages: 0,
  pageOffset: 1,
};

export const MINUTE_IN_MILLISECONDS: number = 60000;
export const VALIDATION_RULE_ALERTS_PAGE_LIMIT = 10;
export const VALIDATION_WINDOW_LIMIT: number = 1000;
export const ACTIVATION_WINDOW_LIMIT: number = 500;
export const FIRST_WINDOW_END_RECENCY_IN_DAYS: number = 180;

export const INITIAL_VALIDATION_RULE_ALERTS_PAGINATION_SETTINGS: PaginationPayload =
  {
    offset: 1,
    limit: VALIDATION_RULE_ALERTS_PAGE_LIMIT,
  };

export const INITIAL_METADATA: RuleMetadata = {
  title: '',
  description: '',
  exec_start_date_time: PRESET_DATE_WITH_TIME_IN_UTC,
  exec_end_date_time: '',
  customer_support_involved: false,
  visible_to_child_org: false,
};

export const INITIAL_FILTERS = {
  raw_sql: '',
  query_tree: {} as any,
  inclusion_tags: [],
  exclusion_tags: [],
  inclusion_tag_names: [],
  exclusion_tag_names: [],
  aggregate_query_tree: {},
};

const SECONDS_IN_ONE_DAY = 60 * 60 * 24;

export const DEFAULT_RULE_ENTITY_WHITELIST_DURATIONS: DropdownItemProps[] = [
  {
    key: '3 days',
    text: '3 days',
    value: SECONDS_IN_ONE_DAY * 3,
  },
  {
    key: '1 week',
    text: '1 week',
    value: SECONDS_IN_ONE_DAY * 7,
  },
  {
    key: '2 weeks',
    text: '2 weeks',
    value: SECONDS_IN_ONE_DAY * 14,
  },
  {
    key: '1 month',
    text: '1 month',
    value: SECONDS_IN_ONE_DAY * 30,
  },
  {
    key: '3 months',
    text: '3 months',
    value: SECONDS_IN_ONE_DAY * 90,
  },
  {
    key: '6 months',
    text: '6 months',
    value: SECONDS_IN_ONE_DAY * 180,
  },
  {
    key: 'indefinitely',
    text: 'Indefinitely',
    value: 'indefinitely',
  },
];

export const READ_ONLY_QUERY_BUILDER_SETTINGS = {
  immutableGroupsMode: true,
  immutableFieldsMode: true,
  immutableOpsMode: true,
  immutableValuesMode: true,
  canReorder: false,
  canRegroup: false,
};

export const initialCurrentRule: FullRuleResponse = {
  currency: '',
  scenario: '',
  source: '',
  amount_volume: 0,
  audit_trail: [],
  comments: [],
  override_qbc: null,
  content: {
    filters: {
      aggregate_query_tree: {},
      query_tree: {},
      hashed_aggregate_query_tree: '',
      hashed_query_tree: '',
      hashed_tags: '',
      inclusion_tags: [],
      exclusion_tags: [],
      inclusion_tag_names: [],
      exclusion_tag_names: [],
    },
    scenario: {
      hashed_parameters: '',
      name: '',
      parameters: {},
      filters: {},
    },
    specification: {} as IRSchema,
  },
  execution_frequency: '',
  execution_delay_in_minutes: 0,
  is_makeup_execution_enabled: false,
  generates_alerts: true,
  is_synchronous: false,
  created_at: '',
  created_by_readable: '',
  created_by_id: null,
  deploy_start_date: '',
  deploy_end_date: '',
  derived_from_id: INITIAL_RULE_ID,
  derived_from_name: null,
  derived_rules: [],
  description: '',
  editors: [],
  id: INITIAL_RULE_ID,
  notes: '',
  num_alerts: 0,
  num_entities: 0,
  num_txns: 0,
  num_instruments: 0,
  queue: INITIAL_RULE_ID,
  alert_priority: null,
  status: '',
  tags: [],
  title: '',
  watchers: [],
  customer_support_involved: false,
  execution_mode: '',
  template: null,
  runs_on_org_id: null,
  visible_to_child_org: false,
  org_id: 0,
  runs_on_org: null,
  runs_independently: null,
  relevance_score: null,
  'created_by.full_name': '',
  open_alerts: null,
  closed_alerts: null,
  closed_alert_to_case: null,
  closed_alert_to_sar: null,
  average_alerts_dispositioned_daily: null,
};
