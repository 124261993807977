import { post, put, destroy } from 'app/shared/utils/fetchr';

// Utils
import { pathJoin } from 'app/shared/utils/routes';

// Models
import {
  CreateNarrativeTemplatePayload,
  EditNarrativeTemplatePayload,
  ListNarrativeTemplatesPayload,
  SearchNarrativeTemplatesPayload,
} from 'app/modules/narrativeTemplates/requests';
import {
  ListNarrativeTemplatesResponse,
  SearchNarrativeTemplatesResponse,
} from 'app/modules/narrativeTemplates/responses';
import { NarrativeTemplate } from 'app/modules/narrativeTemplates/models';

export const editNarrativeTemplateApi = (
  payload: EditNarrativeTemplatePayload,
): Promise<NarrativeTemplate> => {
  return put('/narrative-templates', payload);
};

export const createNarrativeTemplateApi = (
  payload: CreateNarrativeTemplatePayload,
): Promise<NarrativeTemplate> => {
  return post('/narrative-templates', payload);
};

export const retrieveNarrativeTemplatesListApi = (
  payload: ListNarrativeTemplatesPayload,
): Promise<ListNarrativeTemplatesResponse> => {
  return post('/narrative-templates/list', payload);
};

export const deleteNarrativeTemplateApi = (
  id: string,
): Promise<NarrativeTemplate> => {
  return destroy(pathJoin('/narrative-templates/:id'.replace(':id', id)));
};

export const retrieveNarrativeTemplatesSearchApi = (
  payload: SearchNarrativeTemplatesPayload,
): Promise<SearchNarrativeTemplatesResponse> => {
  return post('/narrative-templates/search', payload);
};
