import moment, { MomentBuiltinFormat } from 'moment'; // @deprecated - Use date-fns instead.
import { addMinutes, format, isValid, subMinutes } from 'date-fns';

// Models
import { TimeRange } from 'app/shared/models';
import {
  OptionalDate,
  DateRangeValue,
} from 'app/shared/u21-ui/components/input/U21DateRangePicker';

// Constants
import {
  BE_DATE_FORMAT,
  DATE_FORMAT_STR,
  BE_DATE_FORMAT_FNS,
} from 'app/shared/constants';
import { formatDatetime } from 'app/shared/utils/date';

/* Info about Date objects
Please note that Date objects internally work in UTC.
Only getters of the Date object work in local time.
 */

// input a date string in UTC, return formatted string without localizing it
/**
 * @deprecated Use formatters in src/app/shared/utils/date.ts instead
 */
export const displayUTCFormat = (
  dateInfo: string, // input date in UTC
  dateFormat: string,
): string => {
  const momentObj = moment.utc(dateInfo);
  return momentObj.isValid() ? momentObj.utc().format(dateFormat) : '';
};

// Primarily used for sending UTC format back to BE
/**
 * @deprecated Should refactor BE to use ISO date format instead
 */
export const getUTCFormat = (
  momentObj: moment.Moment, // moment(local timestamp)
  dateFormat: string = BE_DATE_FORMAT,
): string => {
  return momentObj.isValid() ? momentObj.utc().format(dateFormat) : '';
};

/**
 * Validate date and and return a formatted UTC string in GMT time zone
 *
 * @remarks
 * Refer to https://date-fns.org/v2.23.0/docs/format for formatting options
 *
 * @param date - Date object to validate and format
 * @params dateFormat - Format string (e.g. 'yyyy-MM-dd HH:mm:ss')
 * @returns The UTC string in the provided dateFormat
 */

export const getUTCDateFormat = (
  date: OptionalDate,
  dateFormat: string = BE_DATE_FORMAT_FNS,
): string => {
  if (!date || !isValid(date)) return '';
  const offset = date.getTimezoneOffset();
  return format(addMinutes(date, offset), dateFormat);
};

// assume FE is local timezone and convert to UTC with expected BE_DATE_FORMAT
/**
 * @deprecated Should refactor BE to use ISO date format instead
 */
export const getBEDateFormat = (
  dateStr: string | Date = '',
  dateFormat: string | MomentBuiltinFormat = DATE_FORMAT_STR,
) => {
  const momentObj = moment(dateStr, dateFormat);
  return momentObj.isValid() ? momentObj.utc().format(BE_DATE_FORMAT) : '';
};

// need to add an optional parameter which is used to interpret the format of the input (second arg to moment)
// Primarily used for display dateInfo (which *must* be in UTC) as local time
/**
 * @deprecated Use formatters in src/app/shared/utils/date.ts instead
 */
export const getLocalFormat = (
  dateValue: string | number | undefined | null,
  dateFormat: string = DATE_FORMAT_STR,
  inputFormat: string = '',
): string => {
  // return empty string if dateValue is undefined
  if (!dateValue) {
    return '';
  }
  let momentObj =
    inputFormat === ''
      ? moment.utc(dateValue)
      : moment.utc(dateValue, inputFormat);
  // if dateValue is of type number, indicates a unix timestamp which is already in UTC
  if (typeof dateValue === 'number') {
    momentObj = moment.unix(dateValue);
  }
  return momentObj.isValid() ? momentObj.local().format(dateFormat) : '';
};

// dateInfo must be in UTC, if dateInfo is null, it works as expected
// if dateInfo is undefined, same as calling moment.utc()
/**
 * @deprecated Use date-fns formatDistanceToNow instead
 */
export const getTimeFromNow = (
  dateInfo: string | number | null,
  discardSuffix: boolean = false,
): string => {
  if (!dateInfo) {
    return '';
  }

  const momentObj = moment.utc(dateInfo);
  return momentObj.isValid() ? momentObj.local().fromNow(discardSuffix) : '';
};

/**
 * @deprecated Use date-fns formatDistanceToNow instead
 */
export const getElapsedMinutes = (
  sinceDate: string | number,
  toDate?: string | number,
  ignoreNegative?: boolean,
): string => {
  const sinceMomentObj = moment.utc(sinceDate);
  const toMomentObject =
    toDate === undefined ? moment().utc() : moment.utc(toDate);
  const timeDiff = toMomentObject.local().diff(sinceMomentObj);
  if (timeDiff < 0 && ignoreNegative) {
    return '';
  }
  return `${Math.floor(moment.duration(timeDiff).asMinutes())} mins`;
};

// we expect the date format to be in local time, otherwise pass in a custom date format arg
/**
 * @deprecated Should refactor BE to use ISO date format instead
 */
export const convertTimeRangeToUTC = (
  timeRange: TimeRange,
  dateFormat: string = DATE_FORMAT_STR,
): TimeRange => {
  const { start_date: startDate, end_date: endDate } = timeRange;
  const formattedStartDate = moment(startDate, dateFormat)
    .startOf('date')
    .format(BE_DATE_FORMAT);
  const formattedEndDate = moment(endDate, dateFormat)
    .endOf('date')
    .format(BE_DATE_FORMAT);
  return { start_date: formattedStartDate, end_date: formattedEndDate };
};

/**
 * Converts a DateRange into a pair of UTC-formatted strings for the backend to ingest.
 *
 * @param dateRange - A tuple of [start_date, end_date] dates
 * @returns - A pair of UTC-formatted date strings
 */
export const convertDateRangeToUTC = (
  dateRange: DateRangeValue,
): [string, string] => {
  const [startDate, endDate] = dateRange;
  const formattedStartDate = getUTCDateFormat(startDate);
  const formattedEndDate = getUTCDateFormat(endDate);
  return [formattedStartDate, formattedEndDate];
};

export const subDateString = (dateString: string, minutes: number): string => {
  const date = new Date(dateString);
  const dateAfterSub = subMinutes(date, minutes);
  return formatDatetime(dateAfterSub);
};

export const subDate = (dateString: string, minutes: number): Date => {
  const date = new Date(dateString);
  return subMinutes(date, minutes);
};
