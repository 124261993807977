import { TAccountMyClient, TAccount } from 'app/modules/goAML/models';

export const EMPTY_ACCOUNT: TAccount = {
  institution_name: '',
  // institution_code: '',
  swift: '',
  non_bank_institution: '0',
  branch: '',
  account: '',
  currency_code: '',
  account_name: '',
  iban: '',
  client_number: '',
  personal_account_type: '',
  signatory: [],
  opened: '',
  balance: '',
  status_code: '',
  beneficiary: '',
};

export const EMPTY_ACCOUNT_MY_CLIENT: TAccountMyClient = {
  institution_name: '',
  // institution_code: '',
  swift: '',
  non_bank_institution: '0',
  branch: '',
  account: '',
  currency_code: '',
  account_name: '',
  iban: '',
  client_number: '',
  personal_account_type: '',
  signatory: [
    {
      is_primary: '1',
      t_person: {
        gender: '',
        title: '',
        first_name: '',
        last_name: '',
        birthdate: '',
        id_number: '',
        nationality1: '',
        residence: '',
        email: '',
        identification: [
          {
            type: '',
            number: '',
            issue_country: '',
          },
        ],
      },
      role: '',
    },
  ],
  opened: '',
  balance: '',
  status_code: '',
  beneficiary: '',
  date_balance: '',
};
