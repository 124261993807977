import { OFFSET_DEFAULT } from 'app/shared/constants';

import { entitiesSearch } from 'app/modules/search/actions';
import { formatEntityOptions } from 'app/modules/search/helpers';
import { selectHasReadEntitiesPermissions } from 'app/modules/session/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useRef } from 'react';

import { FilterMoreDisplay } from 'app/modules/filters/components/FilterMoreDisplay';

interface Props {
  ids: number[];
  label: string;
}

export const EntityFilterDisplay = (props: Props) => {
  const { ids, label } = props;
  const dispatch = useDispatch();
  // using search/selectors caused an import cycle
  const entities = useSelector(
    (state: RootState) => state.search.entities.data,
  );
  const hasReadEntitiesPermissions = useSelector(
    selectHasReadEntitiesPermissions,
  );

  const entityOptionsMap = useMemo(
    () =>
      formatEntityOptions(entities, 'external_id').reduce(
        (acc, i) => ({
          ...acc,
          [i.value]: i,
        }),
        {},
      ),
    [entities],
  );

  // calledRef is used to prevent an infinite loop in case the API never returns with the missing value
  const calledRef = useRef(false);
  useEffect(() => {
    calledRef.current = false;
  }, [ids]);
  useEffect(() => {
    if (hasReadEntitiesPermissions) {
      const missingValues = ids.filter((i) => !entityOptionsMap[i]);
      if (missingValues.length && !calledRef.current) {
        calledRef.current = true;
        dispatch(
          entitiesSearch({
            ids: missingValues,
            limit: missingValues.length,
            offset: OFFSET_DEFAULT,
            phrase: '',
            type: 'unit21_id',
          }),
        );
      }
    }
  }, [dispatch, hasReadEntitiesPermissions, ids, entityOptionsMap]);

  const values = useMemo(
    () => ids.map((i) => entityOptionsMap[i]?.text || i),
    [entityOptionsMap, ids],
  );

  return (
    <>
      <b>{label}</b> is one of <FilterMoreDisplay values={values} />
    </>
  );
};
