import { PayloadAction } from '@reduxjs/toolkit';
import {
  getSummaryViewConfigs,
  updateSummaryViewConfig,
} from 'app/modules/summarySettings/api';
import { EMPTY_SUMMARY_VIEW_CONFIG_GROUP } from 'app/modules/summarySettings/constants';
import { configDataHelper } from 'app/modules/summarySettings/helpers';
import { UpdateSummaryViewConfigRequest } from 'app/modules/summarySettings/requests';
import { ListSummaryViewConfigsResponse } from 'app/modules/summarySettings/responses';
import {
  SummarySettingsState,
  SummaryViewConfig,
  SummaryViewConfigGroup,
  SummaryViewConfigTypeClassifier,
  SummaryViewConfigs,
} from 'app/modules/summarySettings/types';
import { CLASSIFIER_TO_LABEL } from 'app/modules/tableSettings/constants';
import { u21CreateAsyncThunk } from 'app/shared/thunk/u21CreateAsyncThunk';
import { u21CreateSlice } from 'app/shared/thunk/u21CreateSlice';
import { sendErrorToast, sendSuccessToast } from 'app/shared/toasts/actions';
import { ERROR_STATE, LOADING_STATE } from 'app/shared/types/utils/asyncStatus';
import { v4 as uuidv4 } from 'uuid';

const NAME = 'summarySettings';

const initialState: SummarySettingsState = {
  configs: LOADING_STATE,
  initialConfigs: undefined,
  selectedClassifier: SummaryViewConfigTypeClassifier.ENTITY,
  selectedGroup: undefined,
  updateConfigPending: false,
};

export const getSummaryViewConfigsThunk = u21CreateAsyncThunk<
  void,
  ListSummaryViewConfigsResponse
>(`${NAME}/GET_SUMMARY_VIEW_CONFIGS`, async (_, { dispatch }) => {
  try {
    return await getSummaryViewConfigs();
  } catch (e) {
    dispatch(sendErrorToast('Something went wrong'));
    throw e;
  }
});

export const updateSummaryViewConfigThunk = u21CreateAsyncThunk<
  UpdateSummaryViewConfigRequest,
  void
>(`${NAME}/UPDATE_SUMMARY_VIEW_CONFIG`, async (payload, { dispatch }) => {
  try {
    await updateSummaryViewConfig(payload);
    dispatch(
      sendSuccessToast(
        `${CLASSIFIER_TO_LABEL[payload.classifier]} summary config saved!`,
      ),
    );
    dispatch(getSummaryViewConfigsThunk());
  } catch (e) {
    dispatch(sendErrorToast('Something went wrong'));
    throw e;
  }
});

const summarySettingsSlice = u21CreateSlice({
  name: NAME,
  initialState,
  reducers: {
    setSelectedClassifier: (
      draft,
      { payload }: PayloadAction<SummaryViewConfigTypeClassifier>,
    ) => {
      draft.selectedClassifier = payload;
    },
    reorderGroups: (draft, { payload }: PayloadAction<SummaryViewConfig>) => {
      const classifier = draft.selectedClassifier;
      draft.configs[classifier] = payload;
    },
    setSelectedGroup: (
      draft,
      { payload }: PayloadAction<SummaryViewConfigGroup['id']>,
    ) => {
      draft.selectedGroup = payload;
    },
    addGroup: (draft) => {
      const { classifier, currentConfig } = configDataHelper(draft);
      const id = uuidv4();
      draft.configs[classifier] = [
        ...currentConfig,
        { id, ...EMPTY_SUMMARY_VIEW_CONFIG_GROUP },
      ];
      draft.selectedGroup = id;
    },
    deleteGroup: (draft) => {
      const { classifier, currentConfig, id } = configDataHelper(draft);
      draft.configs[classifier] = currentConfig.filter((g) => g.id !== id);
    },
    updateGroupName: (
      draft,
      { payload }: PayloadAction<SummaryViewConfigGroup['group']>,
    ) => {
      if (draft.configs.status === 'COMPLETE') {
        const { classifier, id, currentConfig } = configDataHelper(draft);
        draft.configs[classifier] = currentConfig.map((g) =>
          g.id === id ? { ...g, group: payload } : g,
        );
      }
    },
    handleFieldsChange: (
      draft,
      { payload }: PayloadAction<{ id: string; fields: number[] }>,
    ) => {
      const { id, fields } = payload;
      const { classifier, currentConfig } = configDataHelper(draft);
      draft.configs[classifier] = currentConfig.map((g) =>
        g.id === id ? { ...g, fields } : g,
      );
      draft.selectedGroup = id;
    },
    removeFieldFromGroup: (draft, { payload }: PayloadAction<number>) => {
      const { classifier, id, currentConfig } = configDataHelper(draft);
      draft.configs[classifier] = currentConfig.map((g) =>
        g.id === id
          ? { ...g, fields: g.fields.filter((f) => f !== payload) }
          : g,
      );
    },
    reorderFields: (draft, { payload }: PayloadAction<number[]>) => {
      const { classifier, id, currentConfig } = configDataHelper(draft);
      draft.configs[classifier] = currentConfig.map((g) =>
        g.id === id ? { ...g, fields: payload } : g,
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSummaryViewConfigsThunk.pending, (draft) => {
        draft.configs = LOADING_STATE;
      })
      .addCase(getSummaryViewConfigsThunk.rejected, (draft) => {
        draft.configs = ERROR_STATE;
      })
      .addCase(getSummaryViewConfigsThunk.fulfilled, (draft, { payload }) => {
        const configsMap = payload.reduce<SummaryViewConfigs>((acc, c) => {
          const { type_classifier: classifier, config } = c;
          acc[classifier] = config.map((group) => ({ ...group, id: uuidv4() }));
          return acc;
        }, {} as SummaryViewConfigs);
        draft.configs = {
          status: 'COMPLETE',
          ...configsMap,
        };
        draft.initialConfigs = configsMap;
      })
      .addCase(updateSummaryViewConfigThunk.pending, (draft) => {
        draft.updateConfigPending = true;
      })
      .addCase(updateSummaryViewConfigThunk.rejected, (draft) => {
        draft.updateConfigPending = false;
      })
      .addCase(updateSummaryViewConfigThunk.fulfilled, (draft) => {
        draft.updateConfigPending = false;
      });
  },
});

export const { name, reducer, actions } = summarySettingsSlice;

export const {
  setSelectedClassifier,
  reorderGroups,
  setSelectedGroup,
  addGroup,
  deleteGroup,
  updateGroupName,
  handleFieldsChange,
  removeFieldFromGroup,
  reorderFields,
} = actions;
