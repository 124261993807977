import { QueueType } from 'app/modules/queues/models';

import { useMemo } from 'react';

import { FilterMoreDisplay } from 'app/modules/filters/components/FilterMoreDisplay';
import { convertQueues } from 'app/modules/queues/helpers';
import { useGetAllQueues } from 'app/modules/queues/queries/useGetAllQueues';

interface Props {
  ids: number[];
  queueType: QueueType;
}

export const QueueFilterDisplay = (props: Props) => {
  const { ids, queueType } = props;
  const { data } = useGetAllQueues(queueType);

  const values = useMemo(() => {
    const queueIDTitleMap = convertQueues(data?.queues, queueType).reduce(
      (acc, i) => ({
        ...acc,
        [i.id]: i.title,
      }),
      {},
    );
    return ids.map((i) => queueIDTitleMap[i] || i);
  }, [data?.queues, ids, queueType]);

  return (
    <>
      <b>Queue</b> is one of <FilterMoreDisplay values={values} />
    </>
  );
};
