import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { AuditTrailActionTypes } from 'app/shared/auditTrail/models';

// Actions
import {
  retrieveAuditTrail as retrieveAuditTrailAction,
  retrieveAuditTrailSuccess,
} from 'app/shared/auditTrail/actions';

// API
import { retrieveAuditTrail as retrieveAuditTrailAPI } from 'app/shared/api/auditTrail';

const rootAction = AuditTrailActionTypes.RETRIEVE_AUDIT_TRAIL;
function* retrieveAuditTrail({
  payload,
}: ReturnType<typeof retrieveAuditTrailAction>) {
  const config = {
    rootAction,
    request: call(retrieveAuditTrailAPI, payload),
    success: function* onSuccess(
      result: ReturnType<typeof retrieveAuditTrailSuccess>['payload'],
    ) {
      yield put(retrieveAuditTrailSuccess(result));
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watchRetrieveAuditTrail() {
  yield takeLatest(rootAction, retrieveAuditTrail);
}
