import { FC, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

// Components
import { IconDots, IconClipboardList, IconArrowRight } from '@u21/tabler-icons';
import {
  U21Button,
  U21Chip,
  U21ShowMoreList,
  U21Spacer,
  U21Typography,
} from 'app/shared/u21-ui/components';
import { U21MenuButton } from 'app/shared/u21-ui/components/display/U21MenuButton';
import FlaggedLabel from 'app/shared/components/FlaggedLabel';
import LinkedLabel from 'app/shared/components/LinkedLabel';
import UploadStatus from 'app/shared/u21-ui/components/tables/helpers/UploadStatus';

import {
  EmailAddresses,
  FileExportsDownload,
  Label,
  LabelArray,
  Tags,
} from 'app/shared/u21-ui/components/tables/cells';

// Models
import { TableConfig } from 'app/shared/pagination/models';
import { AlertQueue, AlertQueueSummary } from 'app/modules/alerts/models';

// Utils
import assets from 'app/shared/utils/assets';
import formatPhoneNumber from 'app/shared/utils/formatPhoneNumber';
import { getLocalFormat, displayUTCFormat } from 'app/shared/utils/timeHelpers';
import { formatDatetime } from 'app/shared/utils/date';
import { isNil } from 'lodash';

// Helpers
import { humanReadableFileSize, isArrayOfType } from 'app/shared/helpers';
import { getValueFromConfig } from 'app/shared/u21-ui/components/tables/helpers';

// Selectors
import { selectFormatAmount } from 'app/modules/orgSettings/selectors';
import { selectDataSettingsById } from 'app/modules/dataSettings/selectors';

// Styles
import scssStyles from 'app/shared/events/styles/EventTable.module.scss';
import styled from 'styled-components';

import {
  VALIDATION_PROGRESS_BLOCKS_TOTAL_WIDTH,
  VALIDATION_PROGRESS_MAX_BLOCKS,
} from 'app/modules/detectionModels/constants';
import ProgressBar from 'app/modules/detectionModels/components/ProgressBar';
import { WorkflowButton } from 'app/modules/workflows/components/WorkflowButton';
import { WorkflowTemplateButton } from 'app/modules/workflows/components/WorkflowTemplateButton';
import { AlertScoreLabel } from 'app/modules/investigations/components/SummaryCard/AlertScoreLabel';

interface OwnProps {
  config: TableConfig;
  row: any;
  actionButtons?: any[];
  actionItems?: any[];
}

export const U21RenderTableCell: FC<OwnProps> = ({
  row,
  config,
  actionButtons,
  actionItems = [],
}) => {
  const formatAmount = useSelector(selectFormatAmount);
  const dataSettingsById = useSelector(selectDataSettingsById);
  const history = useHistory();
  const { key, type, fmtstr, route, accessor, dataSettingId } = config;
  const keyObj = accessor || key;
  const value = getValueFromConfig(row, keyObj, type);

  switch (type) {
    case 'phone_numbers':
      if (typeof value === 'string') {
        return formatPhoneNumber(value);
      }
      // handle unexpected data
      if (!isArrayOfType(value, 'string')) {
        return null;
      }

      return (
        <div className={scssStyles.textArray}>
          {value.map((text, idx) => {
            if (idx === value.length - 1) {
              return formatPhoneNumber(text);
            }
            return `${formatPhoneNumber(text)}, `;
          })}
        </div>
      );

    case 'text_array':
      // handle unexpected data
      if (!isArrayOfType(value, 'string')) {
        return null;
      }
      return (
        <div className={scssStyles.textArray}>
          {value.map((text, idx) => {
            if (idx === value.length - 1) {
              return text;
            }
            return `${text}, `;
          })}
        </div>
      );
    case 'label_array':
      return <LabelArray value={value} />;

    case 'dollar':
      if (dataSettingId !== undefined) {
        return formatAmount({
          amount: value,
          precision: dataSettingsById[key]?.rendering_options?.precision,
        });
      }
      return formatAmount({ amount: value });

    case 'datetime':
      if (key === 'date_of_birth') {
        return displayUTCFormat(value, fmtstr || 'lll');
      }
      if (dataSettingId !== undefined) {
        return formatDatetime(value, undefined, {
          timezone:
            dataSettingsById[dataSettingId]?.rendering_options?.timezone,
        });
      }
      return getLocalFormat(value, fmtstr || 'lll');

    case 'email_addresses':
      return <EmailAddresses value={value} />;

    case 'email_address':
    case 'phone_number':
    case 'physical_id':
    case 'address':
    case 'label':
      return <Label value={value} rowKey={key} config={config} type={type} />;

    case 'tags':
      return <Tags value={value} />;

    case 'teams':
      return (
        <U21ShowMoreList
          getShowLessText={() => 'Show fewer teams'}
          getShowMoreText={(length) => `Show all ${length} teams`}
          onLessClick={(e) => e.stopPropagation()}
          onMoreClick={(e) => e.stopPropagation()}
          value={(value || []).map((i) => i.name)}
        />
      );

    case 'number':
      if (typeof value === 'number') {
        return <div className={scssStyles.numberText}>{value}</div>;
      }
      return <div>{String(value)}</div>;

    case 'alert':
      if (!value || value === '') {
        return <div />;
      }
      return (
        <LinkedLabel
          key={`${key}-${value.id}`}
          id={value.id}
          title={value.title}
          type="Alert"
          shouldLink={Number.isInteger(value.id)}
        />
      );

    case 'queues':
      if (typeof value === 'undefined' || value === null) {
        return null;
      }
      if (value.length === 0) {
        // Empty array means available in all queues
        return <U21Chip>All Queues</U21Chip>;
      }

      return (
        <U21ShowMoreList
          displayFunc={(val: AlertQueue | AlertQueueSummary) => (
            <U21Chip key={val.id}>{val.title}</U21Chip>
          )}
          getShowLessText={() => 'Show fewer queues'}
          getShowMoreText={(length) => `Show all ${length} queues`}
          onLessClick={(e) => e.stopPropagation()}
          onMoreClick={(e) => e.stopPropagation()}
          value={value}
        />
      );

    case 'queue_title':
      if (!value || value === '') {
        return <div />;
      }
      return (
        <U21Spacer horizontal>
          <U21Typography icon={<IconClipboardList />} variant="subtitle2">
            {row.org_default_queue ? 'Default Queue' : row.title}
          </U21Typography>
          {row.org_default_queue && <U21Chip>Default</U21Chip>}
        </U21Spacer>
      );

    case 'rule':
      if (!value || value === '') {
        return <div />;
      }
      return (
        <LinkedLabel
          key={`${value.id}-rule`}
          id={value.id}
          title={value.title}
          type="Rule"
          shouldLink={Number.isInteger(value.id)}
        />
      );

    case 'rule_id':
      if (!value || value === '') {
        return <div />;
      }
      return (
        <LinkedLabel
          key={`${value}-rule`}
          id={value.id}
          title={value.title}
          type="Rule ID"
          shouldLink
        />
      );

    case 'entity': {
      if (!value) {
        return <div />;
      }
      if (key?.includes('instrument_type')) {
        return (
          <Label
            value={value}
            type="instrument"
            key={`${value.id}-instrument-type`}
            config={config}
            rowKey={key}
          />
        );
      }
      // we have instrument values being returned as type 'entity'
      if (key?.includes('instrument')) {
        return (
          <LinkedLabel
            key={`${value.id}-instrument`}
            id={value.id}
            title={value.title}
            type="Instrument"
            shouldLink
          />
        );
      }

      // show transaction sender_readable or receiver_readable if exists
      // falllback to entity.id
      const readableTitle = /readable/.test(key) && row[key];
      return (
        <LinkedLabel
          key={`${value.id}-entity`}
          id={value.id}
          title={readableTitle || value.title}
          type="Entity"
          shouldLink
        />
      );
    }

    case 'alert_score':
      if (!value) {
        return <div />;
      }
      return <AlertScoreLabel alertScore={value} />;

    case 'file_exports_download':
      return <FileExportsDownload row={row} />;

    case 'uploaded_by':
      return row.uploaded_by?.full_name || 'N/A';

    case 'upload_status':
      return <UploadStatus row={row} />;

    case 'upload_id':
      return row.id || 'N/A';

    case 'file_size':
      return humanReadableFileSize(value);

    case 'statistics': {
      if (!value) {
        return <div />;
      }
      const flaggedLabels: ReactElement[] = [];
      if (Object.prototype.hasOwnProperty.call(value, 'num_txns')) {
        flaggedLabels.push(
          <FlaggedLabel
            id="flaggedEventTooltip"
            content={value.num_txns}
            icon={assets.icons.checkeredFlag}
            key="flaggedEventTooltip"
            classNameProps={
              value.num_txns > 0
                ? scssStyles.flaggedLabelRed
                : scssStyles.flaggedLabel
            }
          />,
        );
      }
      if (Object.prototype.hasOwnProperty.call(value, 'num_entities')) {
        flaggedLabels.push(
          <FlaggedLabel
            id="flaggedEntitiesTooltip"
            content={value.num_entities}
            icon={assets.icons.user}
            key="flaggedEntitiesTooltip"
            classNameProps={
              value.num_entities > 0
                ? scssStyles.flaggedLabelRed
                : scssStyles.flaggedLabel
            }
          />,
        );
      }
      if (Object.prototype.hasOwnProperty.call(value, 'num_instruments')) {
        flaggedLabels.push(
          <FlaggedLabel
            id="flaggedInstrumentsTooltip"
            content={value.num_instruments}
            icon={assets.icons.idCard}
            key="flaggedInstrumentsTooltip"
            classNameProps={
              value.num_instruments > 0
                ? scssStyles.flaggedLabelRed
                : scssStyles.flaggedLabel
            }
          />,
        );
      }
      return flaggedLabels.map((label) => label);
    }

    case '_goto':
      return (
        <U21Button
          variant="outlined"
          onClick={(e) => {
            e.stopPropagation();
            if (route && row.id) {
              history.push(route.replace(':id', row.id));
            }
          }}
        >
          <IconArrowRight />
        </U21Button>
      );

    case '_action':
      return (
        <StyledU21Spacer horizontal spacing={2}>
          {actionButtons?.map((i) => (
            <U21Button
              key={i.id}
              onClick={(e) => {
                e.stopPropagation();
                i.action(row);
              }}
            >
              {i.icon}
            </U21Button>
          ))}

          <U21MenuButton
            alignRight
            buttonProps={{ onClick: (e) => e.stopPropagation() }}
            onClose={(e) => e.stopPropagation()}
            items={actionItems
              .filter(Boolean)
              .filter((button) => {
                return !(button.hidden && button.hidden(row));
              })
              .map((i) => {
                const { action, isDisabled, ...rest } = i;
                const disabled = isDisabled ? isDisabled(row) : false;
                return {
                  ...rest,
                  disabled,
                  onClick: (e) => {
                    e.stopPropagation();
                    action(row);
                  },
                };
              })}
          >
            <IconDots />
          </U21MenuButton>
        </StyledU21Spacer>
      );

    case 'rule_validation_progress': {
      if (
        !value ||
        value.cur_processing_window === undefined ||
        value.num_windows === undefined
      ) {
        return null;
      }

      const numProgressBlocks = Math.min(
        VALIDATION_PROGRESS_MAX_BLOCKS,
        value.num_windows,
      );

      const processingCount = value.num_windows - value.cur_processing_window;

      const processingIndex = Math.floor(
        processingCount * (numProgressBlocks / value.num_windows),
      );

      const windowProgressText =
        row.status === 'COMPLETE'
          ? `Finished processing ${value.num_windows} window${
              value.num_windows === 1 ? '' : 's'
            }`
          : `Processing ${processingCount} out of ${value.num_windows} window${
              value.num_windows === 1 ? '' : 's'
            }`;

      return (
        <ProgressBar
          width={VALIDATION_PROGRESS_BLOCKS_TOTAL_WIDTH}
          tooltip={windowProgressText}
          totalWindows={numProgressBlocks}
          currentWindow={processingIndex}
        />
      );
    }

    case 'workflow_button':
      return <WorkflowButton item={row} />;

    case 'workflow_template':
      return <WorkflowTemplateButton item={row} />;

    case 'text_bold':
      return <U21Typography variant="subtitle2">{value || ''}</U21Typography>;

    case 'text':
    default:
      if (key === 'u21_global') {
        return value ? 'Yes' : 'No';
      }

      if (value instanceof Object) {
        return Object.prototype.hasOwnProperty.call(value, 'value_')
          ? value.value_
          : '';
      }

      return Array.isArray(value) || isNil(value) ? '' : `${value}`;
  }
};

const StyledU21Spacer = styled(U21Spacer)`
  justify-content: flex-end;
`;
