import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { AlertsActionTypes } from 'app/modules/alerts/models';

// Actions
import {
  retrieveUnassignedAlerts as retrieveUnassignedAlertsAction,
  appendToTopOfAlerts,
  retrieveAllAlertQueues,
  retrieveUnassignedAlertsSuccess,
} from 'app/modules/alerts/actions';
import { sendInfoToast, sendSuccessToast } from 'app/shared/toasts/actions';

// API
import { retrieveUnassignedAlerts as retrieveUnassignedAlertsApi } from 'app/shared/api/alerts';

const rootAction = AlertsActionTypes.RETRIEVE_UNASSIGNED_ALERTS;

function* retrieveUnassignedAlerts({
  payload,
}: ReturnType<typeof retrieveUnassignedAlertsAction>) {
  const config = {
    rootAction,
    errorToastMessage:
      'Something went wrong, unable to retrieve unassigned alerts.',
    request: call(retrieveUnassignedAlertsApi, payload),
    success: function* onSuccess(result) {
      yield put(appendToTopOfAlerts(result));
      yield put(retrieveUnassignedAlertsSuccess(result));
      if (result.count === 0) {
        yield put(
          sendInfoToast('No matching alerts found for the current filters.'),
        );
      } else {
        yield put(
          sendSuccessToast(
            `${result.count} alert${
              result.count > 1 ? 's have' : ' has'
            } been retrieved`,
          ),
        );
      }
      // We need to refetch queues to update metadata+available unassigned alerts
      yield put(retrieveAllAlertQueues());
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watchRetrieveUnassignedAlerts() {
  yield takeLatest(rootAction, retrieveUnassignedAlerts);
}
