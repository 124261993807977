import { Unit21DataClassifier } from 'app/modules/dataSettings/responses';
import { SymbolTable } from 'app/modules/rulesAdvanced/lib/SymbolTable';

export const TXN_EVENT_COMMON_DROPDOWN_SYMBOLS: SymbolTable = Object.freeze({
  // NOTE: builderType === 'dropdown'
  u21_ach_risk_score: {
    type: 'number',
    text: 'Unit21 ACH risk score',
    value: {
      operandType: 'number',
      unit21DataClassifier: Unit21DataClassifier.EVENT,
      value: {
        type: 'FIELD',
        field: 'u21_ach_risk_score',
        label: 'u21_ach_risk_score',
        model: 'txn_event',
        datatype: 'number',
      },
    },
  },
  external_fee: {
    type: 'number',
    text: 'External fee',
    value: {
      operandType: 'number',
      unit21DataClassifier: Unit21DataClassifier.EVENT,
      value: {
        type: 'FIELD',
        field: 'external_fee',
        label: 'external_fee',
        model: 'txn_event',
        datatype: 'number',
        cardinality: 'many',
      },
    },
  },
  'geolocation.country': {
    type: 'text',
    text: 'Geolocation / Country',
    value: {
      operandType: 'text',
      unit21DataClassifier: Unit21DataClassifier.EVENT,
      value: {
        type: 'FIELD',
        field: 'geolocation.country',
        label: 'geolocation.country',
        model: 'txn_event',
        datatype: 'string',
        cardinality: 'many',
      },
    },
  },
  'geolocation.city': {
    type: 'text',
    text: 'Geolocation / City',
    value: {
      operandType: 'text',
      unit21DataClassifier: Unit21DataClassifier.EVENT,
      value: {
        type: 'FIELD',
        field: 'geolocation.city',
        label: 'geolocation.city',
        model: 'txn_event',
        datatype: 'string',
        cardinality: 'many',
      },
    },
  },
  'geolocation.postal_code': {
    type: 'text',
    text: 'Geolocation / Postal code',
    value: {
      operandType: 'text',
      unit21DataClassifier: Unit21DataClassifier.EVENT,
      value: {
        type: 'FIELD',
        field: 'geolocation.postal_code',
        label: 'geolocation.postal_code',
        model: 'txn_event',
        datatype: 'string',
        cardinality: 'many',
      },
    },
  },
  'geolocation.longitude': {
    type: 'text',
    text: 'Geolocation / Longitude',
    value: {
      operandType: 'text',
      unit21DataClassifier: Unit21DataClassifier.EVENT,
      value: {
        type: 'FIELD',
        field: 'geolocation.longitude',
        label: 'geolocation.longitude',
        model: 'txn_event',
        datatype: 'string',
        cardinality: 'many',
      },
    },
  },
  'geolocation.latitude': {
    type: 'text',
    text: 'Geolocation / Latitude',
    value: {
      operandType: 'text',
      unit21DataClassifier: Unit21DataClassifier.EVENT,
      value: {
        type: 'FIELD',
        field: 'geolocation.latitude',
        label: 'geolocation.latitude',
        model: 'txn_event',
        datatype: 'string',
        cardinality: 'many',
      },
    },
  },
});
