import { FC, HTMLProps, ReactElement, ReactNode, SyntheticEvent } from 'react';

import { getDOMProps } from 'app/shared/utils/react';
import styled from 'styled-components';

import { IconX } from '@u21/tabler-icons';
import { U21Button } from 'app/shared/u21-ui/components/input/U21Button';
import { U21Divider } from 'app/shared/u21-ui/components/display/U21Divider';
import { U21HelpTooltip } from 'app/shared/u21-ui/components/display/U21HelpTooltip';
import { U21Typography } from 'app/shared/u21-ui/components/display/typography/U21Typography';

export interface U21ModalHeaderProps
  extends Omit<HTMLProps<HTMLDivElement>, 'action'> {
  children?: ReactNode;
  icon?: ReactElement;
  onClose?: (event: SyntheticEvent) => void;
  tooltip?: string;
  action?: ReactNode;
  subtitle?: ReactNode;
}

export const U21ModalHeader: FC<U21ModalHeaderProps> = ({
  icon,
  children,
  onClose,
  tooltip,
  action,
  subtitle,
  ...rest
}) => {
  return (
    <Container {...getDOMProps(rest)}>
      <Content>
        <ContentTop>
          <ContentDiv>
            <Title icon={icon} variant="h4" id="u21-modal-title">
              {children}
            </Title>
            {tooltip && <StyledU21HelpTooltip help={tooltip} />}
          </ContentDiv>
          <ContentDiv>
            {action}
            <StyledU21Button aria-label="close" onClick={onClose}>
              <IconX />
            </StyledU21Button>
          </ContentDiv>
        </ContentTop>
        {Boolean(subtitle) && <Subtitle variant="body2">{subtitle}</Subtitle>}
      </Content>
      <U21Divider horizontal />
    </Container>
  );
};

const Container = styled.div`
  position: sticky;
  top: 0;
  background-color: ${(props) => props.theme.palette.background.paper};
  align-items: center;

  /* Adding z-index to handle scroll overflow in short screens */
  &&& {
    z-index: 2;
  }
`;

const Content = styled.div`
  padding: 24px;
`;

const ContentTop = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ContentDiv = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled(U21Typography)`
  text-transform: capitalize;
`;

const Subtitle = styled(U21Typography)`
  margin-top: 4px;
`;

const StyledU21HelpTooltip = styled(U21HelpTooltip)`
  margin-left: 6px;
`;

const StyledU21Button = styled(U21Button)`
  margin-left: 8px;
`;
