import { post, get, put, downloadFile } from 'app/shared/utils/fetchr';

// Actions
import {
  retrieveGoAMLFiling as retrieveGoAMLFilingAction,
  retrieveGoAMLFilings as retrieveGoAMLFilingsAction,
  createGoAMLFiling as createGoAMLFilingAction,
  validateGoAMLFiling as validateGoAMLFilingAction,
  editGoAMLFiling as editGoAMLFilingAction,
} from 'app/modules/goAML/actions';

// Routes
import routes from 'app/shared/utils/routes';

export const retrieveGoAMLFilings = (
  payload: ReturnType<typeof retrieveGoAMLFilingsAction>['payload'],
) => {
  return post(routes.patronus.sarsList, payload);
};

export const retrieveGoAMLFiling = (
  id: ReturnType<typeof retrieveGoAMLFilingAction>['payload'],
) => {
  return get(routes.patronus.sarsId.replace(':id', `${id}`));
};

export const createGoAMLFiling = (
  payload: ReturnType<typeof createGoAMLFilingAction>['payload'],
) => post(routes.patronus.goAMLCreate, payload);

export const editGoAMLFiling = (
  payload: ReturnType<typeof editGoAMLFilingAction>['payload'],
) => put(routes.patronus.goAMLIdEdit.replace(':id', payload.id), payload);

export const exportGoAMLFiling = (id: number) => {
  return downloadFile(
    routes.patronus.goAMLIdExport.replace(':id', `${id}`),
    `filing_${id}.zip`,
    'POST',
  );
};

export const validateGoAMLFiling = (
  id: ReturnType<typeof validateGoAMLFilingAction>['payload'],
) => post(routes.patronus.goAMLValidate.replace(':id', `${id}`));
