import { GoAMLContent } from 'app/modules/goAML/models';

export const EMPTY_CONTENT: GoAMLContent = {
  report: {
    rentity_id: '',
    rentity_branch: '',
    submission_code: 'E',
    report_code: 'STR',
    entity_reference: '',
    fiu_ref_number: '',
    submission_date: '',
    currency_code_local: '',
    reporting_person: {
      gender: '',
      title: '',
      first_name: '',
      last_name: '',
      birthdate: '',
      ssn: '',
      id_number: '',
      nationality1: '',
      email: '',
      occupation: '',
    },
    location: {
      address_type: '',
      address: '',
      city: '',
      country_code: '',
    },
    transaction: [],
  },
};
