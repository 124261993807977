import { Reducer } from 'redux';
import { produce, Draft } from 'immer';

// Models
import {
  FeatureFlagsActionTypes,
  FeatureFlagsState,
} from 'app/shared/featureFlags/models';

// Actions
import { FeatureFlagActions } from 'app/shared/featureFlags/actions';

// Constants
import { initialState } from 'app/shared/featureFlags/constants';

// Utils
import deepFreeze from 'app/shared/utils/deepFreeze';
import {
  SET_FEATURE_FLAG_ACTION_TYPE,
  setFeatureFlag,
} from 'app/modules/devtools/components/FeatureFlagControl';

// to ensure initialState is readonly
deepFreeze(initialState);

const reducer: Reducer<FeatureFlagsState> = (
  state = initialState,
  action: FeatureFlagActions | ReturnType<typeof setFeatureFlag>,
) => {
  return produce(state, (draft: Draft<FeatureFlagsState>) => {
    switch (action.type) {
      case FeatureFlagsActionTypes.SET_FEATURE_FLAGS:
        draft.flags = action.payload;
        return;

      //  only used for devtool
      case SET_FEATURE_FLAG_ACTION_TYPE:
        draft.flags[action.payload.key] = action.payload.value;
        return;
    }
  });
};

export { reducer as featureFlagsReducer, initialState };
