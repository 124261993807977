import retrievePermissions from 'app/modules/permissions/sagas/retrievePermissions';
import retrieveUnassignedPermissions from 'app/modules/permissions/sagas/retrieveUnassignedPermissions';
import editPermissions from 'app/modules/permissions/sagas/editPermissions';

const sagaRegistry = [
  retrievePermissions,
  retrieveUnassignedPermissions,
  editPermissions,
];

export default sagaRegistry;
