// Saga
import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Api
import { addGoAMLAttachments } from 'app/shared/api/sars';

// Models
import { GoAMLActionTypes } from 'app/modules/goAML/models';

// Actions
import { sendSuccessToast } from 'app/shared/toasts/actions';
import {
  addGoAMLAttachments as addGoAMLAttachmentsAction,
  addGoAMLAttachmentsSuccess,
} from 'app/modules/goAML/actions';

const rootAction = GoAMLActionTypes.ADD_GOAML_ATTACHMENTS;
function* addGoAMLAttachmentsFlow({
  payload,
}: ReturnType<typeof addGoAMLAttachmentsAction>) {
  const config = {
    rootAction,
    request: call(addGoAMLAttachments, payload),
    success: function* onSuccess(result) {
      yield put(addGoAMLAttachmentsSuccess(result));
      yield put(
        sendSuccessToast(`Successfully attached file to GoAML STR form.`),
      );
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watchAddGoAMLAttachments() {
  yield takeLatest(rootAction, addGoAMLAttachmentsFlow);
}
