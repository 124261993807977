import { ColorSchema } from 'vendor/material-minimal/palette';

import styled, { css } from 'styled-components';

import { Backdrop } from '@mui/material';
import { U21TextField } from 'app/shared/u21-ui/components/input/text-field/U21TextField';

export const AutocompleteTextField = styled(U21TextField)<{
  $color?: ColorSchema;
}>`
  .MuiInputBase-root.MuiInputBase-root.MuiInputBase-root {
    padding: 6px;
  }

  /* set padding left to 0 if there's a start adornment */
  &&& .MuiInputAdornment-positionStart + .MuiAutocomplete-input {
    padding-left: 0;
  }

  .MuiInputAdornment-positionEnd {
    margin-left: auto;
  }

  ${(props) => {
    const { disabled, inputProps: { readOnly = false } = {} } = props;
    return (
      !disabled &&
      readOnly &&
      css`
        .MuiInputBase-root {
          cursor: pointer;

          .MuiInputBase-input {
            cursor: pointer;
          }
        }
      `
    );
  }}

  ${(props) =>
    props.$color &&
    css`
      .MuiInputBase-input {
        color: ${props.theme.palette[props.$color].main};
      }
    `}
`;

export const Menu = styled.ul`
  padding: 8px;
`;

export const StyledBackdrop = styled(Backdrop)`
  // number chosen based on https://mui.com/material-ui/customization/z-index
  z-index: 1350;
`;
