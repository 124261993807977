import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { DetectionModelsActionTypes } from 'app/modules/detectionModels/models';

// Actions
import {
  deleteValidationModel as deleteValidationModelAction,
  deleteValidationModelSuccess,
} from 'app/modules/detectionModels/actions';
// TODO: Write custom actions if needed
import { deleteValidationRuleSuccess } from 'app/modules/rules/actions';
import { sendSuccessToast } from 'app/shared/toasts/actions';

// API
// TODO: Replace this with the new API
import { deleteValidationRule as deleteValidationRuleApi } from 'app/shared/api/rules';

// Utils
import routes from 'app/shared/utils/routes';
import { history } from 'app/store/browserHistory';

const rootAction = DetectionModelsActionTypes.DELETE_VALIDATION_MODEL;
function* deleteValidationModel({
  payload,
}: ReturnType<typeof deleteValidationModelAction>) {
  const config = {
    rootAction,
    errorToastMessage:
      'Something went wrong. Error attempting to delete model.',
    request: call(deleteValidationRuleApi, payload.id),
    success: function* onSuccess(result) {
      yield put(deleteValidationModelSuccess());
      yield put(deleteValidationRuleSuccess(result.rule_id));
      // go back to the detection models home page after delete
      history.push(routes.lumos.detectionModels);

      // Show toast by default, we hide it when we re-validate a model
      if (payload.showToast !== false) {
        yield put(sendSuccessToast(`Model successfully deleted`));
      }
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watchDeleteValidationModel() {
  yield takeLatest(rootAction, deleteValidationModel);
}
