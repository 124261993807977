import { get, destroy, post, put } from 'app/shared/utils/fetchr';

import {
  OrgDataSettingsConfigAddEditResponse,
  OrgDataSettingsConfigResponse,
} from 'app/modules/dataSettings/responses';
import {
  AddOrgCustomDataSettingsRequestPayload,
  EditDataSettingsConfigRequest,
} from 'app/modules/dataSettings/requests';

export const getDataSettings = (): Promise<OrgDataSettingsConfigResponse> =>
  get('/data/settings/list');

export const deleteOrgCustomDataSettingsConfig = (
  id: number,
): Promise<Record<string, never>> =>
  destroy(`/custom_data/settings/${id}/delete`);

export const addOrgCustomDataSettingsConfig = (
  payload: AddOrgCustomDataSettingsRequestPayload,
): Promise<OrgDataSettingsConfigAddEditResponse> =>
  post('/custom_data/settings/create', payload);

export const editDataSetting = (
  id: number,
  payload: EditDataSettingsConfigRequest,
): Promise<OrgDataSettingsConfigAddEditResponse> =>
  put(`/data/settings/${id}/edit`, payload);
