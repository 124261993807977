import { call, takeLatest, put } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Actions
import {
  retrieveEntityLinkAnalysis as retrieveEntityLinkAnalysisAction,
  retrieveEntityLinkAnalysisSuccess,
} from 'app/modules/entities/actions';

// Models
import { EntitiesActionTypes } from 'app/modules/entities/models';

// API
import { getEntityLinkAnalysis } from 'app/shared/api/entities';

const rootAction = EntitiesActionTypes.RETRIEVE_ENTITY_LINK_ANALYSIS;
function* retrieveEntityLinkAnalysis({
  payload,
}: ReturnType<typeof retrieveEntityLinkAnalysisAction>) {
  const config = {
    rootAction,
    request: call(getEntityLinkAnalysis, payload),
    success: function* onSuccess(
      result: ReturnType<typeof retrieveEntityLinkAnalysisSuccess>['payload'],
    ) {
      yield put(retrieveEntityLinkAnalysisSuccess(result));
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watchRetrieveLinkAnalysis() {
  yield takeLatest(rootAction, retrieveEntityLinkAnalysis);
}
