import {
  AddDocsResponse,
  DeleteDocResponse,
} from 'app/modules/entitiesRefresh/responses';

import { destroy, post, upload } from 'app/shared/utils/fetchr';
import { AddGDriveDocsPayload } from 'app/modules/entitiesRefresh/requests';

export const addS3Docs = (
  externalId: string,
  files: File[],
): Promise<AddDocsResponse> => {
  const payload = new FormData();
  files.forEach((i, idx) => {
    payload.append(`file${idx}`, i);
  });
  return upload(
    `/entities/${encodeURIComponent(externalId)}/add-files-by-external-id`,
    payload,
  );
};

export const addGDriveDocs = (
  externalId: string,
  payload: AddGDriveDocsPayload,
): Promise<AddDocsResponse> => {
  return post(
    `/entities/${encodeURIComponent(externalId)}/attachment/add-by-external-id`,
    payload,
  );
};

export const deleteDocument = (
  externalId: string,
  docID: number,
): Promise<DeleteDocResponse> => {
  return destroy(
    `/entities/${encodeURIComponent(externalId)}/attachment/${encodeURIComponent(docID)}/delete-by-external-id`,
  );
};
