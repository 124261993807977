// Models
import {
  AlertsHistogramPayload,
  DeleteValidationModelPayload,
  DetectionModelMetricsResponse,
  DetectionModelsActionTypes,
  DetectionModelsPerformance,
  DetectionModelTemplatesPayload,
  DetectionModelTemplatesResponse,
  DetectionModelValidation,
  EditingAdvancedDetectionModel,
  EditingSimpleDetectionModel,
  RetrieveValidationMetricsPayload,
  ScenarioConfigs,
  ValidateModelPayload,
  ValidateModelResponse,
  ValidationAlertsPayload,
} from 'app/modules/detectionModels/models';
import { PaginatedAlerts } from 'app/modules/rules/models';

// Utils
import { ActionsUnion, createAction } from 'app/shared/utils/action-helpers';

export const retrieveScenarioConfigs = () => {
  return createAction(DetectionModelsActionTypes.RETRIEVE_SCENARIO_CONFIGS);
};

export const retrieveScenarioConfigsSuccess = (payload: ScenarioConfigs) => {
  return createAction(
    DetectionModelsActionTypes.RETRIEVE_SCENARIO_CONFIGS_SUCCESS,
    payload,
  );
};

export const retrieveDetectionModelsPerformance = (
  payload: AlertsHistogramPayload,
) => {
  return createAction(
    DetectionModelsActionTypes.RETRIEVE_DETECTION_MODELS_PERFORMANCE,
    payload,
  );
};

export const retrieveDetectionModelsPerformanceSuccess = (
  payload: DetectionModelsPerformance,
) => {
  return createAction(
    DetectionModelsActionTypes.RETRIEVE_DETECTION_MODELS_PERFORMANCE_SUCCESS,
    payload,
  );
};

export const revalidateDetectionModel = (
  payload: ValidateModelPayload & { id: number },
) =>
  createAction(DetectionModelsActionTypes.REVALIDATE_DETECTION_MODEL, payload);

export const revalidateDetectionModelSuccess = (
  payload: ValidateModelResponse,
) =>
  createAction(
    DetectionModelsActionTypes.REVALIDATE_DETECTION_MODEL_SUCCESS,
    payload,
  );

export const validateDetectionModel = (payload: ValidateModelPayload) => {
  return createAction(
    DetectionModelsActionTypes.VALIDATE_DETECTION_MODEL,
    payload,
  );
};

export const validateDetectionModelSuccess = (
  payload: ValidateModelResponse,
) => {
  return createAction(
    DetectionModelsActionTypes.VALIDATE_DETECTION_MODEL_SUCCESS,
    payload,
  );
};

export const resetEditingModels = () =>
  createAction(DetectionModelsActionTypes.RESET_EDITING_DETECTION_MODELS);

export const resetModelValidation = () =>
  createAction(DetectionModelsActionTypes.RESET_MODEL_VALIDATION);

export const setEditingSimpleDetectionModel = (
  payload: EditingSimpleDetectionModel,
) =>
  createAction(
    DetectionModelsActionTypes.SET_EDITING_SIMPLE_DETECTION_MODEL,
    payload,
  );

export const setEditingAdvancedDetectionModel = (
  payload: EditingAdvancedDetectionModel,
) =>
  createAction(
    DetectionModelsActionTypes.SET_EDITING_ADVANCED_DETECTION_MODEL,
    payload,
  );

// Validation Models
export const deleteValidationModel = (payload: DeleteValidationModelPayload) =>
  createAction(DetectionModelsActionTypes.DELETE_VALIDATION_MODEL, payload);

export const deleteValidationModelSuccess = () => {
  return createAction(
    DetectionModelsActionTypes.DELETE_VALIDATION_MODEL_SUCCESS,
  );
};

export const retrieveDetectionModelValidation = (id: number) =>
  createAction(
    DetectionModelsActionTypes.RETRIEVE_DETECTION_MODEL_VALIDATION,
    id,
  );

export const retrieveDetectionModelValidationSuccess = (
  response: DetectionModelValidation,
) =>
  createAction(
    DetectionModelsActionTypes.RETRIEVE_DETECTION_MODEL_VALIDATION_SUCCESS,
    response,
  );

export const retrieveDetectionModelValidationAlerts = (
  payload: ValidationAlertsPayload,
) =>
  createAction(
    DetectionModelsActionTypes.RETRIEVE_DETECTION_MODEL_VALIDATION_ALERTS,
    payload,
  );

export const retrieveDetectionModelValidationAlertsSuccess = (
  payload: PaginatedAlerts,
) =>
  createAction(
    DetectionModelsActionTypes.RETRIEVE_DETECTION_MODEL_VALIDATION_ALERTS_SUCCESS,
    payload,
  );

export const retrieveDetectionModelValidationMetrics = (
  payload: RetrieveValidationMetricsPayload,
) =>
  createAction(
    DetectionModelsActionTypes.RETRIEVE_DETECTION_MODEL_VALIDATION_METRICS,
    payload,
  );

export const retrieveDetectionModelValidationMetricsSuccess = (
  response: DetectionModelMetricsResponse,
) =>
  createAction(
    DetectionModelsActionTypes.RETRIEVE_DETECTION_MODEL_VALIDATION_METRICS_SUCCESS,
    response,
  );

export const retrieveDetectionModelTemplates = (
  payload: DetectionModelTemplatesPayload,
) =>
  createAction(
    DetectionModelsActionTypes.RETRIEVE_DETECTION_MODEL_TEMPLATES,
    payload,
  );

export const retrieveDetectionModelTemplatesSuccess = (
  response: DetectionModelTemplatesResponse,
) =>
  createAction(
    DetectionModelsActionTypes.RETRIEVE_DETECTION_MODEL_TEMPLATES_SUCCESS,
    response,
  );

const ModelsActionsList = {
  retrieveScenarioConfigsSuccess,
  retrieveDetectionModelValidationSuccess,
  retrieveDetectionModelsPerformanceSuccess,
  validateDetectionModel,
  validateDetectionModelSuccess,
  retrieveDetectionModelValidationAlertsSuccess,
  setEditingSimpleDetectionModel,
  setEditingAdvancedDetectionModel,
  resetEditingModels,
  resetModelValidation,
  retrieveDetectionModelValidationMetricsSuccess,
  retrieveDetectionModelTemplatesSuccess,
};

export type ModelsActions = ActionsUnion<typeof ModelsActionsList>;

export default ModelsActions;
