import { startCase, reduce, Dictionary } from 'lodash';

// Models
import {
  DashboardDetails,
  DashboardSummary,
  ChartDetails,
} from 'app/modules/insights/models';
import { Layout } from 'react-grid-layout';
import { XAxisLabelsOptions } from 'highcharts';
import { UnitSummary } from 'app/shared/dropdown/models';

// Constants
import {
  NUM_GRID_COLS,
  PIE_CHART_PROPS,
  X_AXIS_TYPE_CATEGORY,
  X_AXIS_TYPE_DATETIME,
  DEFAULT_LAYOUT_HEIGHT,
} from 'app/modules/insights/constants';

const COLUMN_MIN_LENGTH = 10;

export const extractDashboardSummary = (
  dashboard: DashboardDetails,
): DashboardSummary => {
  const { id, title, description, time_bucket: timeBucket } = dashboard;
  return {
    id,
    title,
    description,
    time_bucket: timeBucket,
    filtered_org_id: null,
    filtered_unit_id: null,
  };
};

export const isLayoutEqual = (oldLayout: Layout[], newLayout: Layout[]) => {
  // Different sizes
  if (oldLayout.length !== newLayout.length) {
    return false;
  }

  // Different x,y,w,h,i
  let equalLayouts = true;
  for (let ix = 0; ix < newLayout.length; ix++) {
    const { x, y, w, h, i } = newLayout[ix];
    const chartLayout = oldLayout![ix];
    equalLayouts =
      x === chartLayout.x &&
      y === chartLayout.y &&
      w === chartLayout.w &&
      h === chartLayout.h &&
      i === chartLayout.i &&
      equalLayouts;
  }
  return equalLayouts;
};

export const createNewChartsLayout = (
  oldLayout: Layout[] | null,
  newCharts: number[],
): Layout[] => {
  const newLayout: Layout[] = [];
  const existingCharts: number[] = [];
  let lastAvailableY: number = 0;
  // append new charts to the end if they don't already exist
  // remove charts from they layout if they no longer exist
  (oldLayout || []).forEach((layoutItem) => {
    const { i } = layoutItem;
    // if the chart still exists, add it to the new layout
    // handles the case where we delete charts
    if (i && newCharts.includes(parseInt(i, 10))) {
      const formattedLayout: Layout = {
        ...layoutItem,
      };
      newLayout.push(formattedLayout);
      // add the chart id to the existing charts array to later filter what new charts to add
      existingCharts.push(parseInt(i, 10));
      const layoutYEnd = formattedLayout.y + formattedLayout.h;
      if (layoutYEnd > lastAvailableY) {
        lastAvailableY = layoutYEnd;
      }
    }
  });
  // filter out charts already added to the layout
  const chartsToAdd = newCharts.filter((id) => !existingCharts.includes(id));
  chartsToAdd.forEach((id) => {
    const layout: Layout = {
      i: String(id),
      x: 0,
      y: lastAvailableY,
      w: NUM_GRID_COLS,
      h: DEFAULT_LAYOUT_HEIGHT, // TODO don't use the default height if the 'chart' is a card
    };
    newLayout.push(layout);
    lastAvailableY += layout.h;
  });
  return newLayout;
};

// Chart creation helpers
const formatLineGraph = (chartData): ChartDetails => {
  const { y_label: yLabel } = chartData;
  const newChartData = { ...chartData };

  const yAxis = {
    title: {
      text: yLabel || '',
    },
  };

  const isOverTimeGraph = chartData.xAxis?.type === X_AXIS_TYPE_DATETIME;

  // add format for datetime if datetime
  // this is needed as a single day will not format properly
  // to further customize datetime formatting: https://api.highcharts.com/highcharts/xAxis.dateTimeLabelFormats
  const labels: XAxisLabelsOptions = isOverTimeGraph
    ? { format: '{value:%e. %b}' }
    : { enabled: true };

  newChartData.xAxis = {
    ...(chartData.xAxis || {}),
    labels,
  };

  newChartData.yAxis = yAxis;
  newChartData.legend = {
    enabled: true,
  };

  return newChartData;
};

const formatPieChart = (chartData): ChartDetails => {
  const { series } = chartData;
  const newChartData = { ...chartData };

  if (!series) {
    return chartData;
  }

  const newSeries = [...series];

  newChartData.series = newSeries;
  return { ...newChartData, ...PIE_CHART_PROPS };
};

const formatMultiPieChart = (chartData): ChartDetails => {
  const { series } = chartData;
  const newChartData = { ...chartData };

  const reducedData = reduce(
    series,
    (result: any[], currentSeries) => {
      const reducedValue = reduce(
        currentSeries.data,
        (acc: number, groupValue: any) => acc + groupValue.y,
        0,
      );
      return [
        ...result,
        {
          name: currentSeries.name,
          y: Math.floor(reducedValue),
        },
      ];
    },
    [],
  );

  newChartData.series = [
    {
      data: reducedData,
      name: '',
    },
  ];

  return { ...newChartData, ...PIE_CHART_PROPS };
};

const formatMultiColumnChart = (chartData): ChartDetails => {
  const { y_label: yLabel, series } = chartData;
  const newChartData: ChartDetails = { ...chartData };

  newChartData.yAxis = {
    title: {
      text: yLabel || '',
    },
  };
  // if not over time, set xAxis type to category
  const xAxisType =
    chartData.xAxis?.type === X_AXIS_TYPE_DATETIME
      ? X_AXIS_TYPE_DATETIME
      : X_AXIS_TYPE_CATEGORY;

  // add format for datetime if datetime
  // this is needed as a single day will not format properly
  // to further customize datetime formatting: https://api.highcharts.com/highcharts/xAxis.dateTimeLabelFormats
  const labels: XAxisLabelsOptions =
    xAxisType === X_AXIS_TYPE_DATETIME
      ? { format: '{value:%e. %b}' }
      : { enabled: true };

  newChartData.xAxis = {
    ...(chartData.xAxis || {}),
    labels,
    type: xAxisType,
  };

  newChartData.plotOptions = {
    column: {
      pointPlacement: -series.length * 0.05, // Otherwise point starts at the bottom left corner
      minPointLength: COLUMN_MIN_LENGTH, // so smaller columns are not hidden
    },
  };

  return newChartData;
};

const formatColumnChart = (chartData): ChartDetails => {
  const { series, y_label: yLabel, object } = chartData;
  const newChartData: ChartDetails = { ...chartData };

  const xAxisType =
    chartData.xAxis?.type === X_AXIS_TYPE_DATETIME
      ? X_AXIS_TYPE_DATETIME
      : X_AXIS_TYPE_CATEGORY;

  // add format for datetime if datetime
  // this is needed as a single day will not format properly
  // to further customize datetime formatting: https://api.highcharts.com/highcharts/xAxis.dateTimeLabelFormats
  const labels: XAxisLabelsOptions =
    xAxisType === X_AXIS_TYPE_DATETIME
      ? { format: '{value:%e. %b}' }
      : { enabled: true };

  newChartData.xAxis = {
    ...(chartData.xAxis || {}),
    labels,
    type: xAxisType,
  };

  const yAxis = {
    title: {
      text: yLabel || '',
    },
  };

  const name = `${startCase(object)}s`;
  const newSeries = [{ ...series[0], name }];

  newChartData.yAxis = yAxis;
  newChartData.series = newSeries;

  newChartData.legend = {
    enabled: true,
  };
  newChartData.plotOptions = {
    column: {
      pointPlacement: -0.35, // Otherwise point starts at the bottom left corner
      minPointLength: COLUMN_MIN_LENGTH, // so smaller columns are not hidden
    },
  };

  return newChartData;
};

const formatStackedColumnChart = (chartData: ChartDetails): ChartDetails => {
  const newChartData: ChartDetails = { ...chartData };

  // Stacked Column charts are Column charts with different plot options
  newChartData.chart = {
    ...(newChartData.chart || {}),
    type: 'column',
  };

  newChartData.plotOptions = {
    ...(newChartData.plotOptions || {}),
    series: {
      stacking: 'normal',
    },
  };

  return newChartData;
};

export const formatChartData = (chartData: ChartDetails) => {
  const { series, chart = {}, title } = chartData;
  const newChartData = { ...chartData };
  const credits = { enabled: false };
  const colors = [
    '#2f7ed8',
    '#910000',
    '#8bbc21',
    '#492970',
    '#0d233a',
    '#1aadce',
    '#f28f43',
    '#77a1e5',
    '#c42525',
    '#a6c96a',
  ];

  // Type should always be the assigned_chart_format_name but this ternary allows for backwards compatibility when the format type was defined on the chart.type
  const chartType = newChartData.assigned_chart_format_name
    ? newChartData.assigned_chart_format_name
    : chart?.type;

  const formattedChartInfo = {
    ...chart,
    type: chartType,
  };

  if (newChartData.accumulated) {
    newChartData.subtitle = {
      align: 'right',
      useHTML: true,
      text: `
        <table>
        ${Object.keys(newChartData.accumulated).map(
          (key) => `
          <tr>
            <td>${key}: </td>
            <td>${newChartData.accumulated![key]}</td>
          </tr>`,
        )}
        </table>
      `,
    };
  }

  newChartData.colors = colors;
  newChartData.credits = credits;
  newChartData.chart = formattedChartInfo;
  newChartData.title = { text: title };
  if (!series || series.length <= 0) {
    return newChartData;
  }

  // TODO merge MultiCol/MultiPie with Col/Pie and move the if to the function (if required)
  if (series.length > 1) {
    switch (chartType) {
      case 'stacked_column':
        return formatStackedColumnChart(newChartData);
      case 'column':
        return formatMultiColumnChart(newChartData);
      case 'pie':
        return formatMultiPieChart(newChartData);
      default:
        break;
    }
  }

  // 1 series entry, type defined in the series
  const type = series[0] && series[0].type ? series[0].type : chartType;
  switch (type) {
    case 'column':
      return formatColumnChart(newChartData);
    case 'pie':
      return formatPieChart(newChartData);
    case 'line':
      return formatLineGraph(newChartData);
    case 'stacked_column':
      return formatStackedColumnChart(newChartData);
    default:
      return newChartData;
  }
};

export const getFirstUnitForOrg = (
  orgIds: number[],
  AllUnits: Dictionary<UnitSummary>,
) => {
  const orgId = orgIds[0];
  const ids: string[] = Object.keys(AllUnits);
  const unitId = ids.find((key) => AllUnits[key].org_id === orgId);

  return unitId ? [Number(unitId)] : [];
};

export const getAppliedFiltersTooltip = (filters: string[]): string => {
  if (!filters || filters.length === 0) {
    return 'No filters applied';
  }

  const formattedFilters = filters.map((rawFilter) => {
    return startCase(rawFilter);
  });

  if (formattedFilters.length === 1) {
    return `1 filter applied: ${formattedFilters[0]}`;
  }

  return `${filters.length} filters applied: ${formattedFilters.join(', ')}`;
};
