import { FC, HTMLProps, ReactNode, SyntheticEvent } from 'react';

import { getDOMProps } from 'app/shared/utils/react';
import styled, { css } from 'styled-components';

import { RadioGroup } from '@mui/material';
import {
  U21Radio,
  U21RadioValue,
} from 'app/shared/u21-ui/components/input/radio/U21Radio';
import {
  GRID_STYLE,
  GridStyleProps,
} from 'app/shared/u21-ui/components/layout/U21Grid';

interface U21RadioGroupOptionProps<
  RadioOptionValue extends U21RadioValue = U21RadioValue,
> {
  checked?: boolean;
  description?: string;
  disabled?: boolean;
  label: ReactNode;
  value: RadioOptionValue;
}

export interface U21RadioGroupProps<
  RadioValue extends U21RadioValue = U21RadioValue,
> extends Omit<HTMLProps<HTMLDivElement>, 'onChange'> {
  columnMinWidth?: number;
  columns?: number;
  disabled?: boolean;
  horizontal?: boolean;
  onChange: (value, e: SyntheticEvent) => void;
  options: U21RadioGroupOptionProps<RadioValue>[];
  value?: RadioValue;
}

export const U21RadioGroup: FC<U21RadioGroupProps> = ({
  columnMinWidth = 200,
  columns = 1,
  disabled,
  horizontal,
  onChange,
  options,
  value,
  ...rest
}) => {
  return (
    <StyledRadioGroup
      $columns={columns}
      $minWidth={columnMinWidth}
      // U21Radio already includes spacing so not needed
      $spacing={0}
      row={horizontal}
      value={value ?? ''}
      {...getDOMProps(rest)}
    >
      {options.map((option) => {
        const {
          checked,
          description,
          disabled: optionDisabled,
          label,
          value: optionValue,
          ...restOption
        } = option;
        return (
          <U21Radio
            key={optionValue}
            checked={checked}
            description={description}
            disabled={disabled || optionDisabled}
            label={label}
            onChange={(_, e) => onChange(optionValue, e)}
            value={optionValue}
            {...getDOMProps(restOption)}
          />
        );
      })}
    </StyledRadioGroup>
  );
};

const StyledRadioGroup = styled(RadioGroup)<GridStyleProps>`
  ${(props) => (props.$columns > 1 ? GRID_STYLE : css``)}
`;
