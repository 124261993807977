import { useMemo } from 'react';

import { useSelector } from 'react-redux';
import { selectActionEventTypes } from 'app/modules/actionEvents/selectors';

import {
  U21MultiSelect,
  U21MultiSelectProps,
} from 'app/shared/u21-ui/components';

export const ActionTypeSelect = <TClearable extends boolean>(
  props: Omit<U21MultiSelectProps<string, TClearable>, 'options'>,
) => {
  const types = useSelector(selectActionEventTypes);
  const options = useMemo(
    () => types.map((i) => ({ text: i, value: i })),
    [types],
  );

  return <U21MultiSelect {...props} options={options} />;
};
