// Types
import { ArticleDeadlineResponse } from 'app/modules/deadlines/types';
import { AlertType } from 'app/modules/alerts/types';
import {
  U21ChipColor,
  U21ChipVariant,
} from 'app/shared/u21-ui/components/display/U21Chip';
import { ReactElement } from 'react';

export enum DeadlinesActionTypes {
  RETRIEVE_DEADLINES_CONFIGS = '@@settings/RETRIEVE_DEADLINES_CONFIGS',
  RETRIEVE_DEADLINES_CONFIGS_SUCCESS = '@@settings/RETRIEVE_DEADLINES_CONFIGS_SUCCESS',

  RETRIEVE_DEADLINES_CONFIG = '@@settings/RETRIEVE_DEADLINES_CONFIG',
  RETRIEVE_DEADLINES_CONFIG_SUCCESS = '@@settings/RETRIEVE_DEADLINES_CONFIG_SUCCESS',

  CREATE_DEADLINES_CONFIG = '@@settings/CREATE_DEADLINES_CONFIG',
  CREATE_DEADLINES_CONFIG_SUCCESS = '@@settings/CREATE_DEADLINES_CONFIG_SUCCESS',

  EDIT_DEADLINES_CONFIG = '@@settings/EDIT_DEADLINES_CONFIG',
  EDIT_DEADLINES_CONFIG_SUCCESS = '@@settings/EDIT_DEADLINES_CONFIG_SUCCESS',
  SET_DEADLINES_CONFIG = '@@settings/SET_DEADLINES_CONFIG',

  EDIT_ARTICLE_DEADLINE = '@@settings/EDIT_ARTICLE_DEADLINE',
  EDIT_ARTICLE_DEADLINE_SUCCESS = '@@settings/EDIT_ARTICLE_DEADLINE_SUCCESS',
}

export interface DeadlinesState {
  deadlinesConfigs: DeadlinesConfigsResponse;
  deadlinesConfig: DeadlinesConfig;
}

export type DeadlinesConfigCalcStartDateType =
  | 'CREATED_AT'
  | 'LAST_EVENT'
  | 'EARLIEST_EVENT';

export interface DeadlinesConfigConditions {
  tags?: number[];
  ALERT?: {
    alert_type?: AlertType[];
  };
  CASE?: Record<string, never>;
  SAR_FILING?: Record<string, never>;
}

export interface DeadlinesConfig {
  id: number;
  title: string;
  calc_start_date: DeadlinesConfigCalcStartDateType;
  conditions: DeadlinesConfigConditions;
  priority: number;
  // In seconds
  duration: number;
  notify_before?: number;
  warn_before?: number;
}

export interface DeadlinesConfigsResponse {
  configs: DeadlinesConfig[];
  count: number;
}

export interface ArticleDeadline extends ArticleDeadlineResponse {}

export interface CreateDeadlinesConfigPayload
  extends Omit<DeadlinesConfig, 'id'> {}
export interface EditDeadlinesConfigPayload extends Partial<DeadlinesConfig> {
  id: number;
}
export interface EditDeadlinePayload {
  article_id: number;
  duration: number;
}

export enum DeadlineChipStatus {
  NO_DEADLINE = 'NO_DEADLINE',
  PAST_DEADLINE = 'PAST_DEADLINE',
  WARN_BEFORE_DEADLINE = 'WARN_BEFORE_DEADLINE',
  NORMAL_DEADLINE = 'NORMAL_DEADLINE',
}

export enum DeadlineType {
  ARTICLE_DEADLINE = 'ARTICLE_DEADLINE',
  QUEUE_DEADLINE = 'QUEUE_DEADLINE',
  MANUAL_DEADLINE = 'MANUAL_DEADLINE',
  NO_DEADLINE = 'NO_DEADLINE',
}

export type StatusToStyle = {
  [key in DeadlineChipStatus]: {
    color: U21ChipColor;
    icon?: ReactElement;
    variant: U21ChipVariant;
  };
};
