import {
  cloneElement,
  HTMLProps,
  ReactElement,
  ReactNode,
  RefObject,
  SyntheticEvent,
  useRef,
} from 'react';
import { getDOMProps } from 'app/shared/utils/react';
import styled from 'styled-components';

import { Menu } from '@mui/material';

export interface U21MenuLayoutProps extends HTMLProps<HTMLDivElement> {
  alignRight?: boolean;
  backdropRef?: RefObject<HTMLDivElement>;
  children: ReactNode;
  menuRef?: RefObject<HTMLDivElement>;
  onClose: (e: SyntheticEvent) => void;
  onEnter?: () => void;
  onExited?: () => void;
  open?: boolean;
  trigger: ReactElement;
}

export const U21MenuLayout = (props: U21MenuLayoutProps) => {
  const {
    alignRight,
    backdropRef,
    children,
    menuRef,
    onClose,
    onEnter,
    onExited,
    open = false,
    trigger,
    ...rest
  } = props;
  const anchorEl = useRef<HTMLElement>(null);
  return (
    <>
      {cloneElement(trigger, { ref: anchorEl })}
      <StyledMenu
        anchorEl={anchorEl.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: alignRight ? 'right' : 'left',
        }}
        BackdropProps={{
          'aria-label': 'backdrop',
          invisible: true,
          ref: backdropRef,
        }}
        onClose={onClose}
        open={open}
        PaperProps={{ ref: menuRef }}
        transformOrigin={{
          vertical: 'top',
          horizontal: alignRight ? 'right' : 'left',
        }}
        TransitionProps={{ onEnter, onExited }}
        {...getDOMProps(rest)}
      >
        {children}
      </StyledMenu>
    </>
  );
};

const StyledMenu = styled(Menu)`
  .MuiList-root {
    padding: 8px;
  }
`;
