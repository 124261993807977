import { MouseEventHandler } from 'react';

export function keyPressEnhancer(
  func: MouseEventHandler<HTMLElement | SVGElement>,
) {
  return (e) => {
    if (e.key === 'Enter') {
      func(e);
    }
  };
}

export function getA11yClickProps<T extends HTMLElement | SVGElement>(
  onClick?: MouseEventHandler<T>,
  disabled: boolean = false,
) {
  if (disabled || !onClick) {
    return {};
  }
  return {
    onClick,
    onKeyPress: keyPressEnhancer(onClick),
    role: 'button',
    tabIndex: 0,
  };
}
