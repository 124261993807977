import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { AlertsActionTypes } from 'app/modules/alerts/models';

// Actions
import {
  retrieveAlertActionEvents as retrieveAlertActionEventsAction,
  retrieveAlertActionEventsSuccess,
} from 'app/modules/alerts/actions';

// API
import { retrieveAlertActionEvents as retrieveAlertActionEventsAPI } from 'app/shared/api/alerts';

const rootAction = AlertsActionTypes.RETRIEVE_ALERT_ACTION_EVENTS;
function* retrieveAlertActionEvents({
  payload,
}: ReturnType<typeof retrieveAlertActionEventsAction>) {
  const config = {
    rootAction,
    request: call(retrieveAlertActionEventsAPI, payload),
    success: function* onSuccess(result) {
      yield put(retrieveAlertActionEventsSuccess(result));
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watchRetrieveAlertActionEvents() {
  yield takeLatest(rootAction, retrieveAlertActionEvents);
}
