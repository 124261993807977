import { u21CreateSlice } from 'app/shared/thunk/u21CreateSlice';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  ModelValidationPayload,
  ValidateModelPayload,
} from 'app/modules/detectionModels/models';
import {
  EMPTY_VALIDATE_MODEL_PAYLOAD,
  EMPTY_VALIDATION_PAYLOAD,
} from 'app/modules/detectionModels/constants';

const REAL_TIME_MONITORING_RULES = 'real-time-monitoring-rules';

interface RealTimeMonitoringRulesState {
  validationPickerPayload: ModelValidationPayload;
  validateModelPayload: ValidateModelPayload;
}

const initialState: RealTimeMonitoringRulesState = {
  validationPickerPayload: {
    ...EMPTY_VALIDATION_PAYLOAD,
    execution_frequency: '1w',
  },
  validateModelPayload: EMPTY_VALIDATE_MODEL_PAYLOAD,
};

const realTimeMonitoringRulesSlice = u21CreateSlice({
  name: REAL_TIME_MONITORING_RULES,
  initialState,
  reducers: {
    setValidationPickerPayload: (
      draft,
      { payload }: PayloadAction<ModelValidationPayload>,
    ) => {
      draft.validationPickerPayload = payload;
    },
    setValidateModelPayload: (
      draft,
      { payload }: PayloadAction<ValidateModelPayload>,
    ) => {
      draft.validateModelPayload = payload;
    },
  },
});

export const REAL_TIME_MONITORING_RULES_SLICE_NAME =
  realTimeMonitoringRulesSlice.name;
export const { setValidationPickerPayload, setValidateModelPayload } =
  realTimeMonitoringRulesSlice.actions;
export default realTimeMonitoringRulesSlice.reducer;
