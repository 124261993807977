import { Label } from 'semantic-ui-react';

// Helpers
import { isArrayOfType } from 'app/shared/helpers';

// Styles
import scssStyles from 'app/shared/events/styles/EventTable.module.scss';

interface Props {
  value: string[];
}

export const LabelArray = ({ value }: Props) => {
  // handle unexpected data
  if (!isArrayOfType(value, 'string')) {
    return null;
  }
  return (
    <Label.Group>
      {value.map((item) => (
        <Label key={item} className={scssStyles.valueLabel}>
          {item}
        </Label>
      ))}
    </Label.Group>
  );
};
