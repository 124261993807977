import { HTMLProps, useEffect, useState } from 'react';

import { getDOMProps } from 'app/shared/utils/react';
import styled from 'styled-components';

import { CircularProgress, LinearProgress, Paper } from '@mui/material';
import loadingDarkGif from 'app/shared/gif/loading-dark.gif';
import loadingLightGif from 'app/shared/gif/loading-light.gif';
import { useTheme } from '@mui/styles';
import { U21Themes } from 'vendor/material-minimal/models';
import { ColorSchema } from 'vendor/material-minimal/palette';

interface BaseLoadingProps extends HTMLProps<HTMLDivElement> {
  delay?: number;
  loading?: boolean;
}

export interface U21GraphicLoadingProps extends BaseLoadingProps {
  label?: string;
  variant?: 'graphic';
}

export interface U21LinearLoadingProps extends BaseLoadingProps {
  color?: ColorSchema;
  variant: 'linear';
}

export interface U21SpinnerLoadingProps extends BaseLoadingProps {
  size?: number;
  variant: 'spinner';
}

export interface U21DotsLoadingProps extends BaseLoadingProps {
  label?: string;
  variant: 'dots';
}

export type U21LoadingProps =
  | U21SpinnerLoadingProps
  | U21DotsLoadingProps
  | U21GraphicLoadingProps
  | U21LinearLoadingProps;

const DEFAULT_LOADING_DELAY = 500;
const DEFAULT_LOADING_LABEL = 'Loading content. Please wait...';

export const U21Loading = (props: U21LoadingProps) => {
  const {
    delay: delayProp = DEFAULT_LOADING_DELAY,
    loading = false,
    ...restProps
  } = props;
  const { palette } = useTheme();

  const delay = process.env.NODE_ENV === 'test' ? 0 : delayProp;
  const [show, setShow] = useState(delay === 0);
  useEffect(() => {
    let timer;
    if (show !== loading) {
      if (loading) {
        // delay showing loading
        timer = setTimeout(() => {
          setShow(loading);
        }, delay);
      } else {
        setShow(false);
      }
    }
    return () => clearTimeout(timer);
  }, [delay, show, loading]);

  if (!show) {
    return null;
  }

  const { variant } = restProps;

  if (variant === 'linear') {
    const { color, variant: ignore, ...rest } = restProps;
    return <StyledLinearProgress color={color} {...getDOMProps(rest)} />;
  }

  if (variant === 'spinner') {
    const { size = 20, variant: ignore, ...rest } = restProps;
    return (
      <SpinnerContainer {...getDOMProps(rest)}>
        <StyledCircularProgress aria-label="Loading" size={size} />
      </SpinnerContainer>
    );
  }

  if (variant === 'dots') {
    const {
      label = DEFAULT_LOADING_LABEL,
      variant: ignore,
      ...rest
    } = restProps;
    return (
      <DotsContainer {...getDOMProps(rest)}>
        <FlashingDots />
        <Text>{label}</Text>
      </DotsContainer>
    );
  }

  const { label = DEFAULT_LOADING_LABEL, variant: ignore, ...rest } = restProps;
  return (
    <GraphicContainer {...getDOMProps(rest)}>
      <Img
        src={
          palette.mode === U21Themes.LIGHT ? loadingLightGif : loadingDarkGif
        }
        alt="Loading"
      />
      <Text>{label}</Text>
    </GraphicContainer>
  );
};

const StyledLinearProgress = styled(LinearProgress)`
  flex: 1 1 auto;
  background-color: ${(props) => props.theme.palette.grey[200]};
  height: 9px;
`;

const SpinnerContainer = styled.div`
  display: flex;
`;

const GraphicContainer = styled(Paper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledCircularProgress = styled(CircularProgress)`
  color: ${(props) => props.theme.palette.grey[400]};
`;

const Img = styled.img`
  height: 54px;
  width: 54px;
`;

const Text = styled.span`
  padding: 10px;
`;

const DotsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  flex-direction: column;
`;

const FlashingDots = styled.div`
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: ${(props) => props.theme.palette.grey[400]};
  color: ${(props) => props.theme.palette.grey[400]};
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;

  &:before,
  &:after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }
  &:before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${(props) => props.theme.palette.grey[400]};
    color: ${(props) => props.theme.palette.grey[400]};
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 0s;
  }
  &:after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${(props) => props.theme.palette.grey[400]};
    color: ${(props) => props.theme.palette.grey[400]};
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 1s;
  }

  @keyframes dot-flashing {
    0% {
      background-color: ${(props) => props.theme.palette.grey[400]};
    }
    50%,
    100% {
      background-color: rgba(152, 128, 255, 0.2);
    }
  }
`;
