import { AgentsActionTypes } from 'app/modules/agentsOld/models';
import { ImpersonationSuccessResponse } from 'app/modules/agentsOld/types/responses';
import {
  ImpersonationPayload,
  CancelImpersonationPayload,
} from 'app/modules/agentsOld/types/requests';

import { createAction, ActionsUnion } from 'app/shared/utils/action-helpers';

export const startImpersonation = (payload: ImpersonationPayload) =>
  createAction(AgentsActionTypes.START_IMPERSONATION, payload);

export const startImpersonationSuccess = (
  result: ImpersonationSuccessResponse,
) => createAction(AgentsActionTypes.START_IMPERSONATION_SUCCESS, result);

export const cancelImpersonation = (payload: CancelImpersonationPayload) =>
  createAction(AgentsActionTypes.CANCEL_IMPERSONATION, payload);

export const cancelImpersonationSuccess = (result) =>
  createAction(AgentsActionTypes.CANCEL_IMPERSONATION_SUCCESS, result);

const AgentActionsList = {
  startImpersonation,
  startImpersonationSuccess,
  cancelImpersonation,
  cancelImpersonationSuccess,
};

export type AgentActions = ActionsUnion<typeof AgentActionsList>;
export default AgentActions;
