// Redux
import { Reducer } from 'redux';
import produce, { Draft } from 'immer';

// Models
import { EventState, EventActionTypes } from 'app/shared/events/models';

// Actions
import { EventsActions } from 'app/shared/events/actions';

// Utils
import deepFreeze from 'app/shared/utils/deepFreeze';
import {
  getTransactionsFilters,
  setTransactionsFilters,
} from 'app/shared/utils/localStorage';

const initialState: Readonly<EventState> = {
  internalTxnTypes: [],
  dateRange: '',
  sender: true,
  receiver: true,
  internalTxnStatuses: [],
  startDate: '',
  endDate: '',
  tags: [],
  hideSpooled: true,
  customFilters: {},
  actionEvents: {
    count: 0,
    action_events: [],
  },
};

// to ensure initialState is readonly
const localStorageTransactionsFilters = {
  ...initialState,
  ...getTransactionsFilters(),
};

deepFreeze(localStorageTransactionsFilters);

const reducer: Reducer<EventState> = (
  state = localStorageTransactionsFilters,
  action: EventsActions,
) => {
  // eslint-disable-next-line consistent-return
  return produce(state, (draft: Draft<EventState>) => {
    switch (action.type) {
      case EventActionTypes.SET_EVENT_FILTERS: {
        const newEventsFilters = {
          ...action.payload,
          actionEvents: draft.actionEvents,
        };
        setTransactionsFilters(newEventsFilters);
        return newEventsFilters;
      }

      case EventActionTypes.RETRIEVE_ACTION_EVENTS_SUCCESS:
        draft.actionEvents = action.payload;
        // eslint-disable-next-line consistent-return
        return;
    }
  });
};

export { reducer as eventFiltersReducer, initialState };
