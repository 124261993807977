import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { GoAMLActionTypes } from 'app/modules/goAML/models';

// Actions
import {
  editGoAMLFiling as editGoAMLFilingAction,
  editGoAMLFilingSuccess,
} from 'app/modules/goAML/actions';

// API
import { editGoAMLFiling as editGoAMLFilingAPI } from 'app/shared/api/goAML';

const rootAction = GoAMLActionTypes.EDIT_GOAML_FILING;
export function* editGoAMLFilingFlow({
  payload,
}: ReturnType<typeof editGoAMLFilingAction>) {
  const config = {
    rootAction,
    errorToastMessage: 'Something went wrong, unable to edit goAML report.',
    successToastMessage: 'Successfully saved the report',
    request: call(editGoAMLFilingAPI, payload),
    success: function* onSuccess(
      result: ReturnType<typeof editGoAMLFilingSuccess>['payload'],
    ) {
      yield put(editGoAMLFilingSuccess(result));
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watch() {
  yield takeLatest(rootAction, editGoAMLFilingFlow);
}
