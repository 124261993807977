import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';
import makeActions from 'app/shared/sagas/make-actions';

// Models
import { RulesActionTypes } from 'app/modules/rules/models';

// Actions
import {
  retrieveRuleValidationAlertTransactions as retrieveRuleValidationAlertTransactionsAction,
  retrieveRuleValidationAlertTransactionsSuccess,
} from 'app/modules/rules/actions';

// API
import { retrieveRuleValidationAlertTransactions as retrieveRuleValidationAlertTransactionsApi } from 'app/shared/api/rules';

// Helpers
import { combineRootActionWithId } from 'app/shared/helpers';

const rootAction = RulesActionTypes.RETRIEVE_RULE_VALIDATION_ALERT_TRANSACTIONS;
function* retrieveRuleValidationAlertTransactions({
  payload,
}: ReturnType<typeof retrieveRuleValidationAlertTransactionsAction>) {
  // to keep track of individual alerts loading
  const formattedRootAction = combineRootActionWithId(
    rootAction,
    String(payload.id),
  );
  const formattedActions = makeActions(formattedRootAction);

  const config = {
    rootAction: formattedRootAction,
    request: call(retrieveRuleValidationAlertTransactionsApi, payload),
    success: function* onSuccess(result) {
      // to update alert state by id
      const formattedResult: ReturnType<
        typeof retrieveRuleValidationAlertTransactionsSuccess
      >['payload'] = { id: payload.id, events: result };
      yield put(
        retrieveRuleValidationAlertTransactionsSuccess(formattedResult),
      );
      // to cancel individual loading state
      yield put(formattedActions.success({}));
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watchRetrieveValidationRuleAlert() {
  yield takeLatest(rootAction, retrieveRuleValidationAlertTransactions);
}
