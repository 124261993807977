export const SAR_REPORT_TYPES = {
  'Continuing activity report': false,
  'Correct/Amend prior report': false,
  'Initial report': false,
  'Joint report': false, // TODO Final decesion yet to be made
};

export const SECURITIES_AND_FUTURES_FILING_INSTITUTION = {
  'CPO/CTA': false,
  'Clearing broker-securities': false,
  'Execution-only broker securities': false,
  'Futures Commission Merchant': false,
  'Holding company': false,
  'Introducing broker-commodities': false,
  'Introducing broker-securities': false,
  'Investment Adviser': false,
  'Investment company': false,
  Other: false,
  'Retail foreign exchange dealer': false,
  'SRO Futures': false,
  'SRO Securities': false,
  'Self-clearing broker securities': false,
  'Subsidiary of financial/bank': false,
};

export const SECURITIES_AND_FUTURES_ACTIVITY_INSTITUTION = {
  'Clearing broker-securities': false,
  'Execution-only broker securities': false,
  'Futures Commission Merchant': false,
  'Holding Company': false,
  'Introducing broker-commodities': false,
  'Introducing broker-securities': false,
  'Investment adviser': false,
  'Investment company': false,
  Other: false,
  'Retail foreign exchange dealer': false,
  'Self-clearing broker-securities': false,
  'Subsidiary of financial/bank': false,
};

export const TYPE_GAMING_INSTITUTIONS = {
  'Card club': false,
  'State licensed casino': false,
  'Tribal authorized casino': false,
  Other: false,
};

export const FINANCIAL_INSTITUTION_ROLE = {
  'Selling location': false,
  'Paying location': false,
  Both: false,
};

export const PAYMENT_INSTRUMENTS_TYPE = {
  'Bank / Cashiers Check': false,
  'Foreign Currency': false,
  'Funds Transfer': false,
  'Gaming Instruments': false,
  'Government  Payment': false,
  'Money Orders': false,
  'Other (List Below)': false,
  'Personal / Business Check': false,
  'Travelers Check': false,
  'U.S. Currency': false,
};
export type PaymentInstrumentsTypeAPI = typeof PAYMENT_INSTRUMENTS_TYPE;

export const PRODUCTS_TYPE = {
  'Bonds / Notes': false,
  'Commercial Mortgage': false,
  'Commercial Paper': false,
  'Credit Card': false,
  'Debit Card': false,
  'Deposit Account': false,
  'Forex Transactions': false,
  'Futures / Options on Futures': false,
  'Hedge Fund': false,
  'Home Equity Line of Credit': false,
  'Home Equity Loan': false,
  'Insurance / Annuity Products': false,
  'Microcap  Securities': false,
  'Mutual Fund': false,
  'Options of Securities': false,
  'Other (List Below)': false,
  'Prepaid Access': false,
  'Residential Mortgage': false,
  'Security Futures Products': false,
  Stocks: false,
  'Swap, Hybird, or other Derivatives': false,
};
export type ProductsTypeAPI = typeof PRODUCTS_TYPE;

export const CYBER_EVENT_TYPES = {
  'Against Financial Institution Customer(s)': false,
  'Against Financial Institution(s)': false,
  Other: false,
};

export const MORTGAGE_FRAUD_TYPES = {
  'Application Fraud': false,
  'Appraisal Fraud': false,
  'Foreclosure / Short Sale Fraud': false,
  'Loan Modification Fraud': false,
  'Origination Fraud': false,
  Other: false,
};

export const SECURITIES_FRAUD_TYPES = {
  'Insider Trading': false,
  'Market Manipulation': false,
  Misappropriation: false,
  Other: false,
  'Unauthorized Pooling': false,
  'Wash Trading': false,
};

export const INSURANCE_FRAUD_TYPES = {
  'Excessive Insurance': false,
  'Excessive or Unusual Cash Borrowing Against Policy / Annuity': false,
  Other: false,
  'Proceeds Sent To or Received from Unrelated  Third Party': false,
  'Suspicious Life Settlement Sales Insurance (e.g. STOLIs, Viaticals)': false,
  'Suspicious Termination of Policy or Contract': false,
  'Unclear or No Insurable Interest': false,
};

export const OTHER_FRAUD_TYPES = {
  'Account Takeover': false,
  'Bribery or Gratuity': false,
  'Counterfeit Instruments': false,
  'Elder Financial Exploitation': false,
  'Embezzlement/theft/\ndisappearance of funds': false, // NOTE: \n is real
  Forgeries: false,
  'Human Smuggling': false,
  'Human Trafficking': false,
  'Identity Theft': false,
  'Little or No Concern for Product Performance Penalities, Fees, Or Tax Consequences':
    false,
  'Misuse of Position or Self-Dealing': false,
  Other: false,
  'Suspected Public /Private Corruption (Domestic)': false,
  'Suspected public/private corruption (foreign)': false,
  'Suspicious Use of Informal Value Transfer': false,
  'Suspicious Use of Multiple Transaction Locations': false,
  'Transaction with No Apparent Economic, Business, or Lawful Purpose': false,
  'Transaction(s) Involving Foreign High Risk Jurisdiction': false,
  'Two or More Individual Working Together': false,
  'Unlicensed or Unregistered MSB': false,
};

export const ID_DOCUMENTATION_FRAUD_TYPES = {
  'Changes Spelling or Arrangement of Name': false,
  'Multiple Individuals with Same or Similar Identities': false,
  Other: false,
  'Provided Questionable or False Documentation': false,
  'Provided Questionable or False Identification': false,
  'Refused or Avoided Request for Documentation': false,
  'Single Individual with Multiple Identities': false,
};

export const MONEY_LAUNDERING_TYPES = {
  'Exchange Small Bills for Large Bills or Vice  Versa': false,
  'Funnel Account': false,
  Other: false,
  'Suspicion Concerning the Physical Condition of Funds': false,
  'Suspicion Concerning the Source of Funds': false,
  'Suspicion Designation of Beneficiaries, Assignees or Joint Owners': false,
  'Suspicion EFT / Wire Transfers': false,
  'Suspicion Exchange of Currencies': false,
  'Suspicious Receipt of Government Payments / Benefits': false,
  'Suspicious Use of Multiple Accounts': false,
  'Suspicious Use of Noncash Monetary Instruments': false,
  'Suspicious Use of Third-Party Transactors (Straw-Man)': false,
  'Trade Based Money Laundering / Black Market Peso Exchange': false,
  'Transaction Out of Pattern for Customer(s)': false,
};

export const GAMING_FRAUD_TYPES = {
  'Chip Walking': false,
  'Minimal Gaming with Large Transactions': false,
  Other: false,
  'Suspicious User of Counter Checks or Markers': false,
  'Unknown Source of Chips': false,
};

export const FRAUD_TYPES = {
  ACH: false,
  'Advance fee': false,
  'Business loan': false,
  Check: false,
  'Consumer loan': false,
  'Credit / Debit card': false,
  'Healthcare / Public or private health insurance': false,
  Mail: false,
  'Mass-marketing': false,
  Other: false,
  'Ponzi Scheme': false,
  'Pyramid Scheme': false,
  'Securities Fraud': false,
  Wire: false,
};

export const TERRORIST_FINANCING_TYPES = {
  'Known or suspected terrorist/terrorist organization': false,
  Other: false,
};

export const STRUCTURING_FRAUD_TYPES = {
  'Alters or cancels transaction to avoid BSA recordkeeping requirement': false,
  'Alters or cancels transaction to avoid CTR requirement': false,
  Other: false,
  'Suspicious inquiry by customer regarding  BSA reporting or recordkeeping requirements':
    false,
  'Transactions(s) below BSA recording threshold': false,
  'Transactions(s) below CTR threshold': false,
};

export const AMOUNT_INVOLVED_TYPE = {
  'Amount Unknown': false,
  'No Amount Involved': false,
};

export const RELATIONSHIP_TYPES = {
  Accountant: false,
  Agent: false,
  Appraiser: false,
  Attorney: false,
  Borrower: false,
  Customer: false,
  Director: false,
  Employee: false,
  Officer: false,
  Other: false,
  'Owner or Controlling Shareholder': false,
};
