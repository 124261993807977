import {
  AnnotationTransformationExceptionType,
  ProcessingExceptionType,
  PullBasedDataFileExecutionFailureCode,
} from 'app/modules/pullBasedDataFiles/responses';
import {
  DataFilesTableState,
  StreamSortValues,
} from 'app/modules/pullBasedDataFiles/types';
import { PrimaryObject } from 'app/modules/dataMapping/types';
import { ReactNode } from 'react';
import {
  ListReplicationBucketsPayload,
  StreamSort,
} from 'app/modules/pullBasedDataFiles/requests';
import { TableConfig } from 'app/shared/pagination/models';

export const DEFAULT_MODAL_STATE = {
  modalStatus: 'NONE',
} as const;

export const PBDF_FAILURE_CODE_TO_TOOLTIP_MAP = {
  [PullBasedDataFileExecutionFailureCode.INVALID_SCHEMA]:
    'Data mapping schema is invalid. Open the data mapping UI to see which fields or configurations are missing or incorrect.',
  [PullBasedDataFileExecutionFailureCode.MAX_RETRIES_EXCEEDED]:
    'Maximum retries exceeded. Try reprocessing the file or reach out to support for further instruction.',
  [PullBasedDataFileExecutionFailureCode.STREAM_NOT_CONFIGURED]:
    'No schema mapping. Please map your schema before processing your files.',
  [PullBasedDataFileExecutionFailureCode.UNKNOWN]:
    'File failed to process. Please view file errors for more information or reach out to support for further instruction.',
  [PullBasedDataFileExecutionFailureCode.UNPARSEABLE_FILE]:
    'The file contents are unparseable. Please ensure your file is valid or reach out to support for further instruction.',
  [PullBasedDataFileExecutionFailureCode.NON_UTF8_ENCODING]:
    'The file includes content that is not encoded in UTF-8. Please ensure that all file contents are encoded in UTF-8.',
  [PullBasedDataFileExecutionFailureCode.CSV_PARSING_ERROR]:
    'The CSV file could not be parsed. Please check that there are no invalid sequences, such as unescaped double quotation marks.',
};

export const U21_OBJECT_TYPE_TO_COLOR: Record<PrimaryObject, string> = {
  txn_event: 'purple',
  action_event: 'orange',
  entity: 'olive',
  alert: 'teal',
  instrument: 'brown',
};

export const STREAM_PARAM_SORT_BY_PAYLOAD_MAP: Record<
  StreamSortValues,
  StreamSort
> = {
  [StreamSortValues.CREATED_AT_LATEST]: {
    sort_column: 'created_at',
    sort_direction: 'descending',
  },
  [StreamSortValues.CREATED_AT_OLDEST]: {
    sort_column: 'created_at',
    sort_direction: 'ascending',
  },
  [StreamSortValues.UPDATED_AT_LATEST]: {
    sort_column: 'updated_at',
    sort_direction: 'descending',
  },
  [StreamSortValues.UPDATED_AT_OLDEST]: {
    sort_column: 'updated_at',
    sort_direction: 'ascending',
  },
  [StreamSortValues.A_TO_Z]: {
    sort_column: 'source_object_name',
    sort_direction: 'ascending',
  },
  [StreamSortValues.Z_TO_A]: {
    sort_column: 'source_object_name',
    sort_direction: 'descending',
  },
};

export const DEFAULT_STREAM_DATA_FILES_TABLE_STATE: DataFilesTableState = {
  offset: 1,
  limit: 10,
  sort_column: 'date_created',
  sort_direction: 'descending',
};

export const ANNOTATION_TRANSFORMATION_EXCEPTION_TYPE_TO_READABLE_NAME: Record<
  AnnotationTransformationExceptionType,
  string
> = {
  [AnnotationTransformationExceptionType.TRANSFORMATION_ERROR]:
    'Transformation',
  [AnnotationTransformationExceptionType.CONDITION_ERROR]:
    'Conditional mapping',
  [AnnotationTransformationExceptionType.INVALID_ID]: 'Invalid ID',
  [AnnotationTransformationExceptionType.INVALID_DATETIME]: 'Invalid datetime',
  [AnnotationTransformationExceptionType.INVALID_NUMBER]: 'Invalid number',
  [AnnotationTransformationExceptionType.INVALID_ENUM]: 'Invalid text value',
  [AnnotationTransformationExceptionType.CONDITIONAL_REQUIRED_FIELD_ERROR]:
    'Missing required field',
};

export const PROCESSING_EXCEPTION_TYPE_TO_ERROR_DESCRIPTION: Record<
  ProcessingExceptionType,
  ReactNode
> = {
  [ProcessingExceptionType.DIRECTIONALITY_ERROR]:
    'when calculating directionality',
  [ProcessingExceptionType.EXCESSIVE_OBJECT_ERROR]:
    'attempting to map multiple sending or receiving',
};

export const ANNOTATION_TRANSFORMATION_EXCEPTION_TYPE_SET: Set<string> =
  new Set(Object.values(AnnotationTransformationExceptionType));

export const DEFAULT_DATAFILE_TABLE_CONFIG: TableConfig[] = [
  { key: 'id', type: 'upload_id', label: 'Id' },
  { key: 'original_file_name', type: 'text', label: 'File Name' },
  { key: 'file_size', type: 'file_size', label: 'Size' },
  {
    key: 'created_at',
    type: 'datetime',
    label: 'Upload Date',
    collapsing: true,
  },
  { key: 'status', type: 'upload_status', label: 'Status' },
  { key: 'uploaded_by', type: 'uploaded_by', label: 'Uploaded By' },
];

export const DEFAULT_DIRECT_UPLOADS_PAYLOAD: ListReplicationBucketsPayload = {
  limit: 100,
  offset: 1,
};

export const MAX_S3_BUCKET_LIST_LENGTH = 500;
