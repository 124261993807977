import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Actions
import {
  createDeadlinesConfig as createDeadlinesConfigAction,
  createDeadlinesConfigSuccess,
} from 'app/modules/deadlines/actions';

// API
import { createDeadlinesConfig as createDeadlinesConfigAPI } from 'app/shared/api/deadlines';

// Models
import { DeadlinesActionTypes } from 'app/modules/deadlines/models';

const rootAction = DeadlinesActionTypes.CREATE_DEADLINES_CONFIG;
function* createDeadlinesConfig({
  payload,
}: ReturnType<typeof createDeadlinesConfigAction>) {
  const config = {
    rootAction,
    errorToastMessage: 'Unable to create config',
    successToastMessage: 'Config created successfully',
    request: call(createDeadlinesConfigAPI, payload),
    success: function* onSuccess(
      result: ReturnType<typeof createDeadlinesConfigSuccess>['payload'],
    ) {
      yield put(createDeadlinesConfigSuccess(result));
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watchCreateDeadlinesConfig() {
  yield takeLatest(rootAction, createDeadlinesConfig);
}
