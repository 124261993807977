export enum ActivityPartyTypeCode {
  CONTACT_FOR_ASSISTANCE = 8,
  REPORTING_FINANCIAL_INSTITUTION = 30,
  TRANSMITTER = 35,
  TRANSMITTER_CONTACT = 37,
  TRANSACTION_LOCATION = 34,
  PERSON_CONDUCTING_TXN_ON_OWN_BEHALF = 50,
  PERSON_CONDUCTING_TXN_FOR_ANOTHER = 17,
  PERSON_ON_WHOSE_BEHALF_TXN_WAS_CONDUCTED = 23,
  COMMON_CARRIER = 58,
}

export type PersonPartyTypeCode = Extract<
  ActivityPartyTypeCode,
  | ActivityPartyTypeCode.PERSON_CONDUCTING_TXN_ON_OWN_BEHALF
  | ActivityPartyTypeCode.PERSON_CONDUCTING_TXN_FOR_ANOTHER
  | ActivityPartyTypeCode.PERSON_ON_WHOSE_BEHALF_TXN_WAS_CONDUCTED
  | ActivityPartyTypeCode.COMMON_CARRIER
>;

export enum PrimaryRegulatorTypeCode {
  CFTC = 9,
  FDIC = 2,
  FRB = 1,
  IRS = 7,
  NCUA = 3,
  OCC = 4,
  SEC = 6,
  UNKNOWN = 14,
}

export enum InstitutionTypeCode {
  CASINO_CARD_CLUB = 1,
  DEPOSITORY_INSTITUTION = 2,
  MONEY_SERVICES_BUSINESS = 4,
  SECURITIES_FUTURES = 5,
  OTHER = 999,
}

export enum PartyNameTypeCode {
  LEGAL = 'L',
  DOING_BUSINESS_AS = 'DBA',
  ALSO_KNOWN_AS = 'AKA',
}

export enum PartyIdentificationTypeCode {
  ALIEN_REGISTRATION = 7,
  CRD = 10,
  DRIVER_STATE_ID = 5,
  EIN = 2,
  FOREIGN_TIN = 9,
  IARD = 11,
  NFA = 12,
  PASSPORT = 6,
  RSSD = 14,
  SEC = 13,
  SSN_ITIN = 1,
  TCC = 28,
  TIN = 4,
  OTHER_IDENTIFICATION = 999,
}

export enum GamingInstitutionTypeCode {
  CARD_CLUB = 103,
  STATE_LICENSED_CASINO = 101,
  TRIBAL_AUTHORIZED_CASINO = 102,
  OTHER = 1999,
}

export enum AccountAssociationTypeCode {
  CASH_IN = 8,
  CASH_OUT = 9,
}

export enum CtrFilingStatus {
  PENDING = 'PENDING', // initial state, filings with Pending status can be either valid or invalid
  READY_TO_FILE = 'READY_TO_FILE', // Means the user can create a batch
  ACCEPTED_WITH_WARNINGS = 'ACCEPTED_WITH_WARNINGS',
  ACCEPTED_BY_FINCEN = 'ACCEPTED_BY_FINCEN',
  REJECTED_BY_FINCEN = 'REJECTED_BY_FINCEN',
  PREFILING_VALIDATION_FAILED = 'PREFILING_VALIDATION_FAILED',
  PREFILING_VALIDATION_SUCCESS = 'PREFILING_VALIDATION_SUCCESS',
}
