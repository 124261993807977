import { INFINITE_SCROLL_LIMIT, OFFSET_DEFAULT } from 'app/shared/constants';

import { retrieveDropdownAgents } from 'app/shared/dropdown/actions';
import { selectDropdownAgents } from 'app/shared/dropdown/selectors';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  U21Avatar,
  U21MentionOption,
  U21MentionsTextField,
  U21MentionsTextFieldProps,
} from 'app/shared/u21-ui/components';
import { getAgentDisplayName } from 'app/modules/agents/utils';

export interface U21AgentMentionsProps
  extends Omit<U21MentionsTextFieldProps, 'onMention' | 'options'> {
  articleID?: number;
}

export const U21AgentMentions = (props: U21AgentMentionsProps) => {
  const { articleID, ...rest } = props;
  const dispatch = useDispatch();
  const agents = useSelector(selectDropdownAgents);
  const agentOptions: U21MentionOption[] = useMemo(
    () =>
      Object.values(agents).map((i) => {
        const name = getAgentDisplayName(i);
        return {
          icon: <U21Avatar name={name} src={i.picture} />,
          label: name,
          value: i.email,
        };
      }),
    [agents],
  );

  useEffect(() => {
    dispatch(
      retrieveDropdownAgents({
        limit: INFINITE_SCROLL_LIMIT,
        offset: OFFSET_DEFAULT,
        article_id: articleID === -1 ? undefined : articleID,
      }),
    );
  }, [articleID, dispatch]);

  const onMention = useCallback(
    (value) => {
      // filter out completed mentions because email includes @domain
      const filteredValue = value.replace(/@\[.*]\(.*\)/g, '');

      // find incomplete mentions
      const queries = [...filteredValue.matchAll(/@(\w+)/g)];
      queries.forEach((i) => {
        const query = i[1];
        dispatch(
          retrieveDropdownAgents({
            limit: INFINITE_SCROLL_LIMIT,
            offset: OFFSET_DEFAULT,
            query,
          }),
        );
      });
    },
    [dispatch],
  );

  return (
    <U21MentionsTextField
      onMention={onMention}
      options={agentOptions}
      placeholder="Mention teammates with @name"
      {...rest}
    />
  );
};
