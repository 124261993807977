// Utils
import { put, post } from 'app/shared/utils/fetchr';
import { RetrieveSarListPayload } from 'app/modules/fincenSar/models';
import {
  RequeueSarPayload,
  ReassignSarPayload,
} from 'app/modules/fincenSar/types/requests';

export const requeueSarApi = (
  payload: RequeueSarPayload,
): Promise<RetrieveSarListPayload> => {
  return post('/sars/requeue', payload);
};

export const reassignSarApi = (
  payload: ReassignSarPayload,
): Promise<RetrieveSarListPayload> => {
  return put('/sars/bulk-reassign', payload);
};
