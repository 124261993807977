import { PayloadAction } from '@reduxjs/toolkit';

import { SIDE_PANEL_DEFAULT_WIDTH } from 'app/shared/components/SidePanel/constants';

import { u21CreateSlice } from 'app/shared/thunk/u21CreateSlice';

const SIDE_PANEL = 'sidePanelSlice';

export const sidePanelState = {
  sidePanelWidth: SIDE_PANEL_DEFAULT_WIDTH,
  mainPanelWidth: 0,
};

const sidePanelSlice = u21CreateSlice({
  initialState: sidePanelState,
  name: SIDE_PANEL,
  reducers: {
    setPanelWidths: (
      draft,
      action: PayloadAction<{ mainPanelWidth: number; sidePanelWidth: number }>,
    ) => {
      draft.mainPanelWidth = action.payload.mainPanelWidth;
      draft.sidePanelWidth = action.payload.sidePanelWidth;
    },
  },
});

export const SIDE_PANEL_NAME = sidePanelSlice.name;
export const { setPanelWidths } = sidePanelSlice.actions;
export default sidePanelSlice.reducer;

export const selectMainPanelWidth = (state: RootState) =>
  state[SIDE_PANEL_NAME].mainPanelWidth;

export const selectSidePanelWidth = (state: RootState) =>
  state[SIDE_PANEL_NAME].sidePanelWidth;
