import { QueryCache, QueryClient } from '@tanstack/react-query';
import { sendErrorToast } from 'app/shared/toasts/actions';
import { store } from 'app/store/configureStore';

const refetchOnWindowFocus = process.env.NODE_ENV === 'production';

declare module '@tanstack/react-query' {
  interface Register {
    defaultError: Response;
    queryMeta: { errorMessage?: string };
  }
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus,
    },
  },
  queryCache: new QueryCache({
    onError: (_, query) => {
      if (query.meta?.errorMessage) {
        store.dispatch(sendErrorToast(query.meta.errorMessage));
      }
    },
  }),
});
