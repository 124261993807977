import {
  GA_FEATURES,
  GaFeatureTypes,
} from 'app/shared/components/GaBanner/constants';
import { getFeatureDisabledLSKey } from 'app/shared/components/GaBanner/utils';
import { u21CreateAsyncThunk } from 'app/shared/thunk/u21CreateAsyncThunk';
import { u21CreateSlice } from 'app/shared/thunk/u21CreateSlice';

const GA_BANNER_HIDDEN_LS_PREFIX = '__GA_BANNER_HIDDEN_';
const PRE_RELEASE_BANNER_HIDDEN_LS_PREFIX = '__PRE_RELEASE_BANNER_HIDDEN_';

type InitalState = Record<
  GaFeatureTypes,
  {
    featureDisabled: boolean;
    gaBannerHidden: boolean;
    preReleaseBannerHidden: boolean;
  }
>;
const initialState = GA_FEATURES.reduce<InitalState>((acc, feature) => {
  acc[feature] = {
    featureDisabled:
      localStorage.getItem(getFeatureDisabledLSKey(feature)) === 'true',
    gaBannerHidden:
      localStorage.getItem(`${GA_BANNER_HIDDEN_LS_PREFIX}${feature}`) ===
      'true',
    preReleaseBannerHidden:
      localStorage.getItem(
        `${PRE_RELEASE_BANNER_HIDDEN_LS_PREFIX}${feature}`,
      ) === 'true',
  };
  return acc;
}, {} as InitalState);

const GA_BANNER_NAME = 'gaBannerSlice';

export const toggleHideGABanner = u21CreateAsyncThunk<
  { featureName: GaFeatureTypes; hideBanner: boolean },
  { featureName: GaFeatureTypes; hideBanner: boolean }
>(`${GA_BANNER_NAME}/TOGGLE_GA_BANNER`, (payload) => {
  if (payload.hideBanner) {
    localStorage.setItem(
      `${GA_BANNER_HIDDEN_LS_PREFIX}${payload.featureName}`,
      JSON.stringify(payload.hideBanner),
    );
  } else {
    localStorage.removeItem(
      `${GA_BANNER_HIDDEN_LS_PREFIX}${payload.featureName}`,
    );
  }
  return payload;
});

export const toggleHidePreReleaseBanner = u21CreateAsyncThunk<
  { featureName: GaFeatureTypes; hideBanner: boolean },
  { featureName: GaFeatureTypes; hideBanner: boolean }
>(`${GA_BANNER_NAME}/TOGGLE_PRE_RELEASE_BANNER`, (payload) => {
  if (payload.hideBanner) {
    localStorage.setItem(
      `${PRE_RELEASE_BANNER_HIDDEN_LS_PREFIX}${payload.featureName}`,
      JSON.stringify(payload.hideBanner),
    );
  } else {
    localStorage.removeItem(
      `${PRE_RELEASE_BANNER_HIDDEN_LS_PREFIX}${payload.featureName}`,
    );
  }
  return payload;
});

export const toggleDisableNewFeature = u21CreateAsyncThunk<
  { featureName: GaFeatureTypes; featureDisabled: boolean },
  { featureName: GaFeatureTypes; featureDisabled: boolean }
>(`${GA_BANNER_NAME}/TOGGLE_NEW_FEATURE`, (payload) => {
  if (payload.featureDisabled) {
    localStorage.setItem(
      getFeatureDisabledLSKey(payload.featureName),
      JSON.stringify(payload.featureDisabled),
    );
  } else {
    localStorage.removeItem(getFeatureDisabledLSKey(payload.featureName));
  }
  return payload;
});

const gaBannerSlice = u21CreateSlice({
  name: GA_BANNER_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(toggleHideGABanner.fulfilled, (draft, { payload }) => {
        draft[payload.featureName].gaBannerHidden = payload.hideBanner;
      })
      .addCase(toggleHidePreReleaseBanner.fulfilled, (draft, { payload }) => {
        draft[payload.featureName].preReleaseBannerHidden = payload.hideBanner;
      })
      .addCase(toggleDisableNewFeature.fulfilled, (draft, { payload }) => {
        draft[payload.featureName].featureDisabled = payload.featureDisabled;
      });
  },
});

export const { name, reducer } = gaBannerSlice;
