import { FC, HTMLProps, ReactNode } from 'react';

import { getDOMProps } from 'app/shared/utils/react';
import styled, { css } from 'styled-components';

// Components
import {
  U21CountLabel,
  U21CountLabelProps,
} from 'app/shared/u21-ui/components/display/U21CountLabel';
import { U21Skeleton } from 'app/shared/u21-ui/components/display/U21Skeleton';

export interface U21TitleCountLabelProps extends HTMLProps<HTMLDivElement> {
  color?: U21CountLabelProps['color'];
  count: null | number;
  label?: string;
  children?: ReactNode;
  loading?: boolean;
}

export const U21TitleCountLabel: FC<U21TitleCountLabelProps> = ({
  color,
  count,
  label,
  children,
  loading = false,
  ...rest
}) => {
  return (
    <Container {...getDOMProps(rest)}>
      {children}
      {!loading ? (
        <StyledU21CountLabel
          $hasChildren={Boolean(children)}
          color={color}
          count={count}
          label={label}
        />
      ) : (
        <StyledU21Skeleton
          $hasChildren={Boolean(children)}
          width={label ? 80 : 40}
        />
      )}
    </Container>
  );
};

const StyledU21Skeleton = styled(U21Skeleton)<{ $hasChildren: boolean }>`
  ${(props) =>
    props.$hasChildren
      ? css`
          margin-left: 8px;
        `
      : css``}
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const StyledU21CountLabel = styled(U21CountLabel)<{ $hasChildren: boolean }>`
  // overwrite capitalize text transform since this is used in titles
  text-transform: none;

  ${(props) =>
    props.$hasChildren
      ? css`
          margin-left: 8px;
        `
      : css``}
`;
