import { useSelector, useDispatch } from 'react-redux';
import {
  FC,
  HTMLProps,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';

// Components
import {
  U21InputGroup,
  U21Select,
  U21SelectSearch,
  U21SelectProps,
  U21SelectSearchProps,
  U21SelectOptionProps,
} from 'app/shared/u21-ui/components';
import { IconLock, IconSearch } from '@u21/tabler-icons';

// Actions
import { actionEventsSearch } from 'app/modules/search/sliceSearch';

// Selectors
import {
  selectActionEventsSearchResults,
  selectActionEventsSearchLoading,
} from 'app/modules/search/selectors';
import { selectHasPermissionsFactory } from 'app/modules/session/selectors';

// Constants
import { ACTION_EVENTS_TYPE_OPTIONS } from 'app/modules/search/constants';
import { INFINITE_SCROLL_LIMIT, OFFSET_DEFAULT } from 'app/shared/constants';

// Utils
import permissions from 'app/shared/utils/permissions';

// Helpers
import {
  formatActionEventOptions,
  ValueField,
} from 'app/modules/search/helpers';
import { uniqBy } from 'lodash';

export interface U21ActionEventSearchProps
  extends Omit<HTMLProps<HTMLDivElement>, 'as' | 'ref' | 'onChange' | 'value'> {
  disabled?: boolean;
  error?: boolean;
  clearable?: boolean;
  onChange: (value: number[], e: SyntheticEvent) => void;
  value?: number[];
  valueField?: ValueField;
  selectProps?: Omit<
    U21SelectProps<string, false>,
    'disabled' | 'error' | 'onChange' | 'options' | 'ref' | 'as'
  >;
  searchProps?: Omit<
    U21SelectSearchProps<number>,
    | 'disabled'
    | 'error'
    | 'clearable'
    | 'onChange'
    | 'onSearch'
    | 'options'
    | 'ref'
    | 'value'
    | 'multi'
    | 'as'
  >;
  additionalOptions?: U21SelectOptionProps[];
}

const selectHasReadEventsPermissions = selectHasPermissionsFactory(
  permissions.readEvents,
);

const TYPE_OPTIONS = ACTION_EVENTS_TYPE_OPTIONS;

export const U21ActionEventSearch: FC<U21ActionEventSearchProps> = ({
  disabled,
  error,
  clearable = true,
  onChange,
  value = [],
  valueField = ValueField.ID,
  selectProps = {},
  searchProps = {},
  additionalOptions = [],
  ...rest
}) => {
  const dispatch = useDispatch();
  const { data } = useSelector(selectActionEventsSearchResults);
  const loading = useSelector(selectActionEventsSearchLoading);
  const hasReadEventsPermissions = useSelector(selectHasReadEventsPermissions);

  const [type, setType] = useState(TYPE_OPTIONS[0].value);

  const options = useMemo(() => {
    return uniqBy(
      [
        ...formatActionEventOptions(data, type, valueField),
        ...additionalOptions,
      ],
      'value',
    );
  }, [additionalOptions, data, type, valueField]);

  // calledRef is used to prevent an infinite loop in case the API never returns with the missing value
  const calledRef = useRef(false);
  useEffect(() => {
    calledRef.current = false;
  }, [value]);

  useEffect(() => {
    if (hasReadEventsPermissions) {
      const missingValues = value.filter(
        (i) => !options.find((j) => j.value === i),
      );
      if (missingValues.length && !calledRef.current) {
        calledRef.current = true;
        missingValues.map((i) =>
          dispatch(
            actionEventsSearch({
              limit: INFINITE_SCROLL_LIMIT,
              offset: OFFSET_DEFAULT,
              phrase: String(i),
              type: valueField === ValueField.ID ? 'unit21_id' : 'external_id',
            }),
          ),
        );
      }
    }
  }, [dispatch, hasReadEventsPermissions, options, value, valueField]);

  const onSearch = useCallback(
    (query) =>
      dispatch(
        actionEventsSearch({
          limit: INFINITE_SCROLL_LIMIT,
          offset: OFFSET_DEFAULT,
          phrase: query || '',
          type,
        }),
      ),
    [dispatch, type],
  );

  if (!hasReadEventsPermissions) {
    return null;
  }

  return (
    <U21InputGroup disabled={disabled} error={error}>
      <StyledU21Select
        clearable={false}
        disabled={disabled}
        error={error}
        label="Search Action Event"
        onChange={(val: string) => setType(val)}
        options={TYPE_OPTIONS}
        searchable={false}
        startIcon={<IconLock />}
        value={type}
        {...selectProps}
      />
      <StyledU21SelectSearch
        disabled={disabled}
        error={error}
        clearable={clearable}
        limitTags={1}
        loading={loading}
        multi
        onChange={onChange}
        options={options}
        onSearch={onSearch}
        placeholder="Any"
        startIcon={<IconSearch />}
        value={value}
        {...rest}
        {...searchProps}
      />
    </U21InputGroup>
  );
};

const StyledU21Select = styled(U21Select)`
  min-width: 200px;
  width: 200px;
`;

const StyledU21SelectSearch = styled(U21SelectSearch)`
  min-width: 300px;
`;
