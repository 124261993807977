import retrieveRules from 'app/modules/rules/sagas/retrieveRules';
import retrieveComponentRules from 'app/modules/rules/sagas/retrieveComponentRules';
import watchRetrieveWhitelistedEntities from 'app/modules/rules/sagas/retrieveWhitelistedEntities';
import whitelistEntitiesForRule from 'app/modules/rules/sagas/whitelistEntitiesForRule';
import removeWhitelistEntitiesForRule from 'app/modules/rules/sagas/removeWhitelistEntitiesForRule';
import globalWhitelistEntities from 'app/modules/rules/sagas/globalWhitelistEntities';
import retrieveEntitiesFromGlobalWhitelist from 'app/modules/rules/sagas/retrieveEntitiesFromGlobalWhitelist';
import removeEntitiesFromGlobalWhitelist from 'app/modules/rules/sagas/removeEntitiesFromGlobalWhitelist';
import retrieveRuleValidationAlert from 'app/modules/rules/sagas/retrieveRuleValidationAlert';
import retrieveRuleValidationAlertTransactions from 'app/modules/rules/sagas/retrieveRuleValidationAlertTransactions';
import retrieveRuleValidationAlertEntities from 'app/modules/rules/sagas/retrieveRuleValidationAlertEntities';
import retrievePaginatedAlerts from 'app/modules/rules/sagas/retrievePaginatedAlerts';
import retrieveValidationRules from 'app/modules/rules/sagas/retrieveValidationRules';
import deployShadowRule from 'app/modules/rules/sagas/deployShadowRule';

const sagaRegistry = [
  retrieveRules,
  retrieveComponentRules,
  whitelistEntitiesForRule,
  removeWhitelistEntitiesForRule,
  watchRetrieveWhitelistedEntities,
  globalWhitelistEntities,
  retrieveEntitiesFromGlobalWhitelist,
  removeEntitiesFromGlobalWhitelist,
  retrieveValidationRules,
  retrieveRuleValidationAlert,
  retrievePaginatedAlerts,
  retrieveRuleValidationAlertTransactions,
  retrieveRuleValidationAlertEntities,
  deployShadowRule,
];

export default sagaRegistry;
