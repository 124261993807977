import { u21CreateAsyncThunk } from 'app/shared/thunk/u21CreateAsyncThunk';
import { u21CreateSlice } from 'app/shared/thunk/u21CreateSlice';

// Models
import { TxnAnalysisState } from 'app/modules/txnAnalysis/models';
import { Filter } from 'app/modules/filters/models';

// Utils
import { getLocalStorageJSON } from 'app/shared/utils/localStorage';
import { getValidFilters } from 'app/modules/filters/utils';

// Constants
import { LocalStorageKeys } from 'app/shared/constants/localStorage';
import { ALL_TXN_ANALYSIS_FILTER_OPTIONS } from 'app/modules/txnAnalysis/filters';

const TXN_ANALYSIS_NAME = 'txnAnalysis';

const initialState: Readonly<TxnAnalysisState> = {
  txnAnalysisFilters: getValidFilters(
    getLocalStorageJSON(LocalStorageKeys.TXN_ANALYSIS_FILTERS),
    ALL_TXN_ANALYSIS_FILTER_OPTIONS,
  ),
};

export const setTxnAnalysisFilters = u21CreateAsyncThunk<Filter[], Filter[]>(
  `${TXN_ANALYSIS_NAME}/SET_TXN_ANALYSIS_FILTERS`,
  (payload) => {
    localStorage.setItem(
      LocalStorageKeys.TXN_ANALYSIS_FILTERS,
      JSON.stringify(payload),
    );
    return payload;
  },
);

export const txnAnalysisSlice = u21CreateSlice({
  name: TXN_ANALYSIS_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setTxnAnalysisFilters.fulfilled, (draft, action) => {
      draft.txnAnalysisFilters = action.payload;
    });
  },
});

export const TXN_ANALYSIS_SLICE_NAME = txnAnalysisSlice.name;
export default txnAnalysisSlice.reducer;
