import { ForwardedRef, forwardRef, useMemo } from 'react';
// Components
import {
  U21SelectOptionProps,
  U21MultiSelect,
  U21MultiSelectProps,
} from 'app/shared/u21-ui/components';
import { IconTag } from '@u21/tabler-icons';

// Selectors
import { getTagDisplayName } from 'app/modules/tags/utils';
import {
  DataLabelResponse,
  useFetchAllDataLabels,
} from 'app/modules/dataLabels/queries/useFetchAllDataLabels';
import { TAG_CAP } from 'app/shared/helpers';

export interface U21DataLabelSelectProps<TClearable extends boolean>
  extends Omit<
    U21MultiSelectProps<string | number, TClearable>,
    'options' | 'disabled'
  > {
  valueType?: 'id' | 'name';
  excludeInternalTags?: boolean;
  disabled?: boolean;
  showDeleted?: boolean;
  deletedOptionDescription?: string;
}

export const U21DataLabelSelectInner = <TClearable extends boolean>(
  {
    value,
    valueType = 'id',
    excludeInternalTags = false,
    disabled = false,
    showDeleted = false,
    deletedOptionDescription = 'Deleted tag, will not be used.',
    ...rest
  }: U21DataLabelSelectProps<TClearable>,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const { data: allDataLabelData, isLoading } = useFetchAllDataLabels();
  const formattedOptions: U21SelectOptionProps[] = useMemo(() => {
    const selectedValues = value ?? [];
    const allDataLabels: DataLabelResponse[] =
      allDataLabelData?.data_labels ?? [];
    let allOptions: U21SelectOptionProps[] = allDataLabels.map((dataLabel) => {
      const dataLabelName = getTagDisplayName(dataLabel);
      return {
        key: dataLabelName,
        value: valueType === 'id' ? dataLabel.id : dataLabelName,
        text: dataLabelName,
        icon: <IconTag />,
      };
    });
    const knownOptionsValues = new Set(allOptions.map((each) => each.value));

    if (showDeleted) {
      allOptions = allOptions.concat(
        selectedValues
          .filter((selectedValue) => !knownOptionsValues.has(selectedValue))
          .map((unknownValue) => ({
            value: unknownValue,
            text:
              valueType === 'name'
                ? unknownValue
                : `unknown id ${unknownValue}`,
            icon: <IconTag />,
            color: 'warning',
            description: deletedOptionDescription,
          })),
      );
    }

    return excludeInternalTags
      ? allOptions.filter(
          (tag) => typeof tag.text === 'string' && !tag.text.startsWith('$'),
        )
      : allOptions;
  }, [
    allDataLabelData,
    excludeInternalTags,
    valueType,
    value,
    showDeleted,
    deletedOptionDescription,
  ]);

  return (
    <U21MultiSelect
      ref={ref}
      label="Tags"
      options={formattedOptions}
      optionLimit={TAG_CAP}
      startIcon={<IconTag />}
      value={value ?? []}
      loading={isLoading}
      disabled={disabled}
      {...rest}
    />
  );
};

export const U21DataLabelSelect = forwardRef(U21DataLabelSelectInner);
