import { useEffect, useRef, useState } from 'react';

export const useControlled = <T extends any>(
  value: T,
  onChange?: (value: T, ...args: any[]) => void,
  defaultValue: T = value,
): [T, (value: T, ...args: any[]) => void, boolean] => {
  const [state, setState] = useState<T>(defaultValue);
  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;

  // change state if uncontrolled
  useEffect(() => {
    if (!onChangeRef.current) {
      setState(value);
    }
  }, [value]);
  return [onChange ? value : state, onChange || setState, Boolean(onChange)];
};
