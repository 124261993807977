import {
  StreamConfig,
  TypedAnnotation,
  CustomDataAnnotation,
  Aggregation,
  ObjectReference,
  StreamConfigResponse,
  GeneralTypedAnnotation,
  Selection,
  AggregationSelection,
  MathTransformationOperators,
  ValueSelection,
  Transformation,
  ValueMapTransformation,
  MetadataType,
} from 'app/modules/dataMapping/responses';
import {
  DataMappingSchema,
  BaseDataMappingPair,
  DraftTransformation,
  U21DataMappingObjectTypes,
  DataMappingObject,
  DataMappingRow,
  BaseDataMappingSchema,
  AnnotationConstant,
  PrimaryObject,
  DraftValueMapTransformation,
} from 'app/modules/dataMapping/types';
import {
  BLANK_STREAM_ANNOTATION,
  BLANK_HARDCODED_ANNOTATION,
  CUSTOM_ANNOTATION_OPTION_PREFIX,
  DATA_MAPPING_AGGREGATION_METHODS,
  DATA_MAPPING_SELECTION_OPTIONS,
  TYPED_ANNOTATION_OPTION_PREFIX,
  U21_DATA_MAPPING_SCHEMA_KEYS,
  U21_TYPE_TO_ANNOTATIONS,
  SINGLETON_TRANSFORMATIONS,
} from 'app/modules/dataMapping/constants';
import {
  isFileMetadataValue,
  getFileMetadataType,
} from 'app/modules/dataMapping/utils';
import { Draft } from 'immer';
import { U21SelectOptionProps } from 'app/shared/u21-ui/components';
import { v4 as uuidv4 } from 'uuid';
import { consoleError } from 'app/shared/utils/console';

export const getObject = (
  draft: Draft<DataMappingSchema>,
  objectName: string,
): Draft<DataMappingObject> => {
  return draft.objects[objectName];
};

// TODO: decide how transformations are preserved when converting to an aggregation
const convertToAggregation = (val: string[]): AggregationSelection => {
  return {
    type: DATA_MAPPING_SELECTION_OPTIONS.AGGREGATION,
    aggregation: {
      values: val.map((s: string): Aggregation['values'][number] =>
        isFileMetadataValue(s)
          ? {
              selection: {
                type: DATA_MAPPING_SELECTION_OPTIONS.METADATA_VALUE,
                metadata_type: getFileMetadataType(s),
              },
              transformations: [],
            }
          : {
              selection: {
                type: DATA_MAPPING_SELECTION_OPTIONS.STREAM_VALUE,
                key: s,
              },
              transformations: [],
            },
      ),
      aggregate_func: DATA_MAPPING_AGGREGATION_METHODS.STRING_CONCATENATION,
    },
  };
};

const getNewAnnotationForOrigin = (
  draft: Draft<BaseDataMappingPair>,
  val: string | string[] | MetadataType,
) => {
  const originType = draft.value.selection.type;
  const isArray = Array.isArray(val);

  switch (originType) {
    case DATA_MAPPING_SELECTION_OPTIONS.HARD_CODED_VALUE: {
      if (isArray) {
        consoleError('incompatible input for hard coded value');
        return;
      }
      if (val) {
        draft.value.selection.value = val;
      }
      return;
    }
    case DATA_MAPPING_SELECTION_OPTIONS.METADATA_VALUE: {
      if (!isArray) {
        consoleError('unexpected non-array input for metadata value');
        return;
      }
      if (val.length === 0) {
        draft.value.selection = BLANK_STREAM_ANNOTATION.value.selection;
        return;
      }
      if (val.length === 1) {
        draft.value.selection.metadata_type = getFileMetadataType(val[0]);
        return;
      }
      draft.value.selection = convertToAggregation(val);
      draft.value.selection.type = DATA_MAPPING_SELECTION_OPTIONS.AGGREGATION;
      return;
      // no return here bc if the length is greater than 1, we need to convert to aggregation (below)
    }
    case DATA_MAPPING_SELECTION_OPTIONS.AGGREGATION: {
      if (!isArray) {
        consoleError('incompatible input for aggregation or stream value');
        return;
      }
      // this erases transformations on individual values of the aggregation.
      // currently FE does not support adding transformations on individual aggregation selections
      const aggregationValues = val.map((v) =>
        isFileMetadataValue(v)
          ? {
              selection: {
                type: DATA_MAPPING_SELECTION_OPTIONS.METADATA_VALUE,
                metadata_type: getFileMetadataType(v),
              },
              transformations: [],
            }
          : {
              selection: {
                type: DATA_MAPPING_SELECTION_OPTIONS.STREAM_VALUE,
                key: v,
              },
              transformations: [],
            },
      );
      if (val.length <= 1) {
        draft.value.selection =
          aggregationValues[0].selection.type ===
          DATA_MAPPING_SELECTION_OPTIONS.METADATA_VALUE
            ? {
                type: DATA_MAPPING_SELECTION_OPTIONS.METADATA_VALUE,
                metadata_type: aggregationValues[0].selection.metadata_type,
              }
            : {
                type: DATA_MAPPING_SELECTION_OPTIONS.STREAM_VALUE,
                key: aggregationValues[0].selection.key,
              };
        return;
      }
      draft.value.selection.aggregation.values = aggregationValues;
      return;
    }
    case DATA_MAPPING_SELECTION_OPTIONS.STREAM_VALUE:
    default:
      if (!isArray) {
        consoleError('incompatible input for aggregation or stream value');
        return;
      }
      // convert to aggregation if we're working with more than one value
      if (val.length > 1) {
        draft.value.selection = convertToAggregation(val);
        draft.value.selection.type = DATA_MAPPING_SELECTION_OPTIONS.AGGREGATION;
        return;
      }

      if (val.length === 1 && isFileMetadataValue(val[0])) {
        draft.value.selection = {
          type: DATA_MAPPING_SELECTION_OPTIONS.METADATA_VALUE,
          metadata_type: getFileMetadataType(val[0]),
        };
        return;
      }

      // eslint-disable-next-line prefer-destructuring
      draft.value.selection.key = val[0] ?? '';
  }
};

export const getDataMappingRow = (
  object: Draft<DataMappingObject>,
  isCustom: boolean,
  id: number,
) => {
  return isCustom
    ? object.custom_data_annotations?.[id - object.typed_annotations.length]
    : object.typed_annotations[id];
};

export const updateAnnotationOrigin = (
  draft: Draft<DataMappingObject>,
  { id, isCustom }: Omit<DataMappingRow, 'destination' | 'value'>,
  val: string | string[],
) => {
  const dataMappingPair = getDataMappingRow(draft, isCustom, id);
  getNewAnnotationForOrigin(dataMappingPair, val);
};

export const objectIsPrimaryObject = (name: string): name is PrimaryObject =>
  Object.values<string>(PrimaryObject).includes(name);

export const updateDataMappingDestination = (
  draft: Draft<DataMappingObject>,
  { id, isCustom }: Omit<DataMappingRow, 'destination' | 'value'>,
  val: string,
  toCustomAnnotation: boolean,
) => {
  if (isCustom && !toCustomAnnotation) {
    // remove relevant custom annotation and create typed annotation
    const annotation = draft.custom_data_annotations.splice(
      id - draft.typed_annotations.length,
      1,
    );
    draft.typed_annotations.push({
      ...(annotation[0] as BaseDataMappingPair),
      destination: val as keyof typeof U21_DATA_MAPPING_SCHEMA_KEYS,
    });
  } else if (!isCustom && toCustomAnnotation) {
    const annotation = draft.typed_annotations.splice(id, 1);
    draft.custom_data_annotations.push({
      ...(annotation[0] as BaseDataMappingPair),
      destination: val,
    });
  } else {
    const dataMappingPair = getDataMappingRow(draft, isCustom, id);
    dataMappingPair.destination = val;
  }
};

export const updateAnnotationAggregateFunction = (
  object: Draft<DataMappingObject>,
  row: DataMappingRow,
  method: Aggregation['aggregate_func'],
) => {
  const dataMappingRow = getDataMappingRow(object, row.isCustom, row.id);
  if (dataMappingRow.value.selection.type !== 'aggregation') {
    throw new Error(
      'cannot update aggregation method for non-aggregated source',
    );
  }
  dataMappingRow.value.selection.aggregation.aggregate_func = method;
};

export const addBlankHardcodedValue = (draft: Draft<DataMappingObject>) => {
  draft.custom_data_annotations.push(BLANK_HARDCODED_ANNOTATION);
};

export const addBlankAnnotation = (draft: Draft<DataMappingObject>) => {
  draft.custom_data_annotations.push(BLANK_STREAM_ANNOTATION);
};

export const removeObject = (draft: DataMappingSchema, objectName: string) => {
  const { [objectName]: deleteMe, ...objects } = draft.objects;
  draft.objects = objects;
};

export const removeAnnotation = (
  draft: Draft<DataMappingSchema>,
  objectName: string,
  row: DataMappingRow,
) => {
  const { isCustom, id } = row;
  const object = getObject(draft, objectName);
  if (isCustom) {
    object.custom_data_annotations.splice(
      id - object.typed_annotations.length,
      1,
    );
  } else {
    object.typed_annotations.splice(id, 1);
  }
  if (
    !U21_TYPE_TO_ANNOTATIONS[objectName] &&
    (object.custom_data_annotations?.length ?? 0) +
      (object.typed_annotations?.length ?? 0) <
      1
  ) {
    // delete object from map if it has no annotations
    removeObject(draft, objectName);
  }
};

export const setDefaultDraftTransformationForNewType = (
  draftTransformation: DraftTransformation,
  newType: DraftTransformation['type'],
): DraftTransformation => {
  switch (newType) {
    case 'case_format': {
      return {
        id: draftTransformation.id,
        type: 'case_format',
        transformation: {
          function: 'uppercase',
        },
      };
    }
    case 'postfix': {
      return {
        id: draftTransformation.id,
        type: 'postfix',
        transformation: {
          function: 'postfix',
          configuration: { postfix: '' },
        },
      };
    }
    case 'prefix': {
      return {
        id: draftTransformation.id,
        type: 'prefix',
        transformation: {
          function: 'prefix',
          configuration: { prefix: '' },
        },
      };
    }
    case 'replace_all': {
      return {
        id: draftTransformation.id,
        type: 'replace_all',
        transformation: {
          function: 'find_replace',
          configuration: {
            old_value: '',
            new_value: '',
            global_replace: true,
          },
        },
      };
    }
    case 'replace_first': {
      return {
        id: draftTransformation.id,
        type: 'replace_first',
        transformation: {
          function: 'find_replace',
          configuration: {
            old_value: '',
            new_value: '',
            global_replace: false,
          },
        },
      };
    }
    case 'value_map': {
      return {
        id: draftTransformation.id,
        type: 'value_map',
        transformation: {
          function: 'value_map',
          configuration: {
            [uuidv4()]: {
              key: '',
              value: '',
            },
          },
        },
      };
    }
    case 'remove_escape_sequence': {
      return {
        id: draftTransformation.id,
        type: 'remove_escape_sequence',
        transformation: {
          function: 'remove_escape_sequence',
          configuration: {
            options: [],
          },
        },
      };
    }
    case 'md5_hash': {
      return {
        id: draftTransformation.id,
        type: 'md5_hash',
        transformation: {
          function: 'md5_hash',
        },
      };
    }
    case 'numeric_cast': {
      return {
        id: draftTransformation.id,
        type: 'numeric_cast',
        transformation: {
          function: 'numeric_cast',
        },
      };
    }
    case 'id_cleanup': {
      return {
        id: draftTransformation.id,
        type: 'id_cleanup',
        transformation: {
          function: 'id_cleanup',
        },
      };
    }
    case 'json_loads': {
      return {
        id: draftTransformation.id,
        type: 'json_loads',
        transformation: {
          function: 'json_loads',
        },
      };
    }
    case 'math': {
      return {
        id: draftTransformation.id,
        type: 'math',
        transformation: {
          function: 'math',
          configuration: {
            operator: MathTransformationOperators.ADD,
            operand: '',
          },
        },
      };
    }
    case 'datetime_timezone': {
      return {
        id: draftTransformation.id,
        type: 'datetime_timezone',
        transformation: {
          function: 'datetime_timezone',
          configuration: {
            datetime_config: {
              format: 'auto',
            },
            timezone_transformation: false,
            timezone: null,
          },
        },
      };
    }
    case 'boolean_cast': {
      return {
        id: draftTransformation.id,
        type: 'boolean_cast',
        transformation: {
          function: 'boolean_cast',
        },
      };
    }
    case '': {
      return {
        id: draftTransformation.id,
        type: '',
        transformation: null,
      };
    }
    default: {
      const exhaustivenessCheck: never = newType;
      return exhaustivenessCheck;
    }
  }
};

export const destinationOptionsHelper = (
  typedAnnotations: BaseDataMappingPair<
    keyof typeof U21_DATA_MAPPING_SCHEMA_KEYS
  >[],
  objectType: U21DataMappingObjectTypes,
  currentValue: string,
  primaryObjectType: PrimaryObject,
  updateExisting: boolean = false,
) => {
  const { currentAnnotations, annotationsWithCondition } =
    typedAnnotations.reduce<{
      currentAnnotations: Set<string>;
      annotationsWithCondition: Set<string>;
    }>(
      (acc, { destination, conditions }) => {
        if (conditions?.length) {
          acc.annotationsWithCondition.add(destination);
        }
        acc.currentAnnotations.add(destination);
        return acc;
      },
      {
        currentAnnotations: new Set(),
        annotationsWithCondition: new Set(),
      },
    );

  return Object.entries(U21_TYPE_TO_ANNOTATIONS[objectType]).reduce(
    (
      acc: U21SelectOptionProps[],
      [value, info]: [string, AnnotationConstant],
    ) => {
      if (
        // exclude the annotation if it has already been mapped,
        !currentAnnotations.has(value) ||
        // unless it's mapped with a condition,
        annotationsWithCondition.has(value) ||
        // or unless it is a list-type field (e.g. entity.email_addresses),
        U21_TYPE_TO_ANNOTATIONS[objectType][value].many ||
        // or unless it is the currently mapped annotation
        // (in which case it needs to be in the select options to show up)
        value === currentValue
      ) {
        const text = (() => {
          const { requirementType } = info;
          const { notRequiredFor } = info;
          let required =
            requirementType === 'update' ||
            (requirementType === 'create' && !updateExisting);
          if (required && notRequiredFor?.length) {
            required = !notRequiredFor.includes(primaryObjectType);
          }
          return required
            ? `Unit21: ${info.label} (required)`
            : `Unit21: ${info.label}`;
        })();
        const option: U21SelectOptionProps = {
          value: addAnnotationOptionPrefix(value),
          text,
          description: info.description,
        };
        acc.push(option);
      }
      return acc;
    },
    [],
  );
};

const convertAnnotations = (
  annotations: GeneralTypedAnnotation[] | CustomDataAnnotation[],
  isCustom: boolean = false,
): BaseDataMappingPair[] =>
  annotations.map(
    (
      ta: GeneralTypedAnnotation | CustomDataAnnotation,
    ): BaseDataMappingPair => ({
      value: ta.value,
      conditions: ta.conditions,
      // we specify if it's custom with `isCustom`
      // @ts-ignore
      destination: isCustom ? ta.label : ta.annotation,
    }),
  );

export function convertStreamConfigResponseObject<
  T extends keyof typeof U21_TYPE_TO_ANNOTATIONS,
>(
  type: T,
  name: string | keyof typeof U21_TYPE_TO_ANNOTATIONS,
  typedAnnotations: GeneralTypedAnnotation[],
  customDataAnnotations: CustomDataAnnotation[] | undefined = undefined,
): DataMappingObject<T> {
  const object = {
    type,
    name,
    typed_annotations: convertAnnotations(
      typedAnnotations,
    ) as BaseDataMappingPair<keyof typeof U21_DATA_MAPPING_SCHEMA_KEYS>[],
    custom_data_annotations: customDataAnnotations
      ? convertAnnotations(customDataAnnotations, true)
      : [],
  };
  // just so an object with no annotations has at least something on first load
  if (
    object.typed_annotations.length + object.custom_data_annotations.length <
    1
  ) {
    object.custom_data_annotations.push(BLANK_STREAM_ANNOTATION);
  }
  return object;
}

export const convertStreamConfigResponseToDataMappingSchema = (
  config: StreamConfigResponse,
): DataMappingSchema | null => {
  // it turns out a config can come back as {} instead of null
  // so not only should we check there is a config, but that it has keys
  // and unit21_object should be on every non-empty config
  if (config?.config?.unit21_object) {
    const {
      unit21_object: unit21Object,
      type_handling: typeHandling,
      typed_annotations: typedAnnotations,
      custom_data_annotations: customDataAnnotations,
      updating_existing: updateExisting,
      objects,
      relationships,
    } = config.config;
    const dataMappingSchema = { objects: {} } as DataMappingSchema;
    dataMappingSchema.unit21_object = unit21Object;
    dataMappingSchema.type_handling = typeHandling;
    dataMappingSchema.relationships = relationships;
    dataMappingSchema.updating_existing = Boolean(updateExisting);
    // since we map typed annotations and custom data annotations in the same method above
    // ts is worried that the typed annotations will have a type of string
    // when they should be
    // @ts-ignore
    dataMappingSchema.objects[unit21Object] = {
      ...convertStreamConfigResponseObject(
        // currently primary objects don't have unique names,
        // so we give them the name of the unit21_object type
        // since we don't allow naming other objects after primary objects
        unit21Object,
        unit21Object,
        typedAnnotations,
        customDataAnnotations?.annotations,
      ),
    };

    objects?.forEach((obj: ObjectReference) => {
      const {
        type,
        name: objectName,
        typed_annotations: secondaryObjectTypedAnnotations,
        custom_data_annotations: secondaryObjectCustomDataAnnotations,
      } = obj;
      dataMappingSchema.objects[objectName] = {
        ...convertStreamConfigResponseObject(
          type,
          objectName,
          secondaryObjectTypedAnnotations,
          secondaryObjectCustomDataAnnotations?.annotations,
        ),
        directionality:
          type === 'instrument' || type === 'entity'
            ? obj.directionality
            : undefined,
      } as DataMappingObject<typeof unit21Object>;
    });
    return dataMappingSchema;
  }
  return null;
};

export const annotationContainsBlankSource = (selection: Selection): boolean =>
  (selection?.type === DATA_MAPPING_SELECTION_OPTIONS.STREAM_VALUE &&
    !selection.key) ||
  (selection?.type === DATA_MAPPING_SELECTION_OPTIONS.HARD_CODED_VALUE &&
    !selection.value) ||
  (selection?.type === DATA_MAPPING_SELECTION_OPTIONS.METADATA_VALUE &&
    !selection.metadata_type) ||
  (selection?.type === DATA_MAPPING_SELECTION_OPTIONS.AGGREGATION &&
    selection.aggregation.values.length < 1);

export const annotationContainsBlankSourceOrDestination = (
  selection: Selection,
  destination: string,
) => !destination || annotationContainsBlankSource(selection);

export const revertAnnotations = (
  annotations: BaseDataMappingPair[],
  isCustom: boolean = false,
) => {
  return (
    annotations?.reduce(
      (
        acc: TypedAnnotation[] | CustomDataAnnotation[],
        ta: BaseDataMappingPair,
      ) => {
        // filter blank annotations
        // TODO: skip this logic when saving as draft
        if (
          annotationContainsBlankSourceOrDestination(
            ta?.value?.selection,
            ta.destination,
          )
        ) {
          return acc;
        }
        // @ts-ignore we check if its custom or not with `isCustom`
        acc.push({
          value: ta.value,
          conditions: ta.conditions,
          ...(isCustom
            ? { label: ta.destination }
            : { annotation: ta.destination }),
        });
        return acc;
      },
      [],
    ) ?? []
  );
};

export const convertDataMappingSchemaToConfigPayload = (
  dataMappingSchema: DataMappingSchema,
): StreamConfig => {
  const {
    unit21_object: unit21Object,
    type_handling: typeHandling,
    objects,
    relationships,
    updating_existing: updateExisting,
  } = dataMappingSchema;
  const config = {
    unit21_object: unit21Object,
    type_handling: typeHandling,
    relationships,
    updating_existing: updateExisting,
  } as StreamConfig;

  if (objects[unit21Object]) {
    config.typed_annotations = revertAnnotations(
      objects[unit21Object].typed_annotations,
    ) as any;

    config.custom_data_annotations = {
      strategy: 'subset',
      annotations: revertAnnotations(
        objects[unit21Object].custom_data_annotations,
        true,
      ) as any,
    };
  }
  config.objects = Object.values(dataMappingSchema.objects).reduce(
    (acc: ObjectReference[], obj: DataMappingObject) => {
      const {
        name,
        type,
        custom_data_annotations: customDataAnnotations,
        typed_annotations: typedAnnotations,
        directionality,
      } = obj;
      // make sure we skip the primary object, which name will be a U21 object type
      if (U21_TYPE_TO_ANNOTATIONS[name]) return acc;
      const convertedCustomDataAnnotations = revertAnnotations(
        customDataAnnotations,
        true,
      );
      acc.push({
        ...(convertedCustomDataAnnotations.length
          ? {
              custom_data_annotations: {
                strategy: 'subset',
                annotations: convertedCustomDataAnnotations,
              },
            }
          : {}),
        typed_annotations: revertAnnotations(typedAnnotations),
        type,
        name,
        ...(directionality ? { directionality } : {}),
      } as ObjectReference);
      return acc;
    },
    [],
  );
  return config;
};

export const autoAddBlankAnnotation = (draft: Draft<DataMappingObject>) => {
  const annotations = [
    ...draft.typed_annotations,
    ...draft.custom_data_annotations,
  ];
  const dataHasBlanks = annotations?.some((a: BaseDataMappingPair) =>
    annotationContainsBlankSourceOrDestination(
      a.value.selection,
      a.destination,
    ),
  );
  if (!dataHasBlanks) {
    addBlankAnnotation(draft);
  }
};

export function getDefaultConfigFromType<T extends PrimaryObject>(
  objectName: T,
): BaseDataMappingSchema<T> {
  return {
    objects: {
      [objectName]: {
        type: objectName,
        name: objectName,
        typed_annotations: [],
        custom_data_annotations: [BLANK_STREAM_ANNOTATION],
      },
    },
    unit21_object: objectName,
    updating_existing: false,
  };
}

export const getTransformationsLength = (value: ValueSelection) =>
  (value?.transformations?.length ?? 0) +
  (value?.selection.type === 'aggregation'
    ? value.selection.aggregation.values.reduce<number>(
        (acc, sel) => acc + (sel.transformations?.length ?? 0),
        0,
      )
    : 0);

export const isAggregationSelection = (
  selection: Selection,
): selection is AggregationSelection => {
  return selection.type === 'aggregation';
};

export const convertTransformationToDraft = (
  t: Transformation | null,
): DraftTransformation => {
  const id = uuidv4();
  const emptyTransformation = { id, type: '', transformation: null } as const;
  if (t === null || t.function === 'substring') {
    return emptyTransformation;
  }
  if (t.function === 'camel_case' || t.function === 'uppercase') {
    return { id, type: 'case_format', transformation: t };
  }
  if (t.function === 'datetime') {
    return {
      id,
      type: 'datetime_timezone',
      transformation: {
        function: 'datetime_timezone',
        configuration: {
          datetime_config: t.configuration,
          timezone_transformation: false,
          timezone: null,
        },
      },
    };
  }
  if (t.function === 'timezone') {
    return {
      id,
      type: 'datetime_timezone',
      transformation: {
        function: 'datetime_timezone',
        configuration: {
          datetime_config: { format: 'auto' },
          timezone_transformation: true,
          timezone: t.configuration.timezone,
        },
      },
    };
  }
  if (t.function === 'datetime_timezone') {
    return { id, type: 'datetime_timezone', transformation: t };
  }
  if (t.function === 'find_replace') {
    return {
      id,
      type: t.configuration.global_replace ? 'replace_all' : 'replace_first',
      transformation: t,
    };
  }
  if (t.function === 'postfix') {
    return { id, type: 'postfix', transformation: t };
  }
  if (t.function === 'prefix') {
    return { id, type: 'prefix', transformation: t };
  }
  if (t.function === 'value_map') {
    return {
      id,
      type: 'value_map',
      transformation: {
        function: t.function,
        configuration: Object.entries(t.configuration.value_map).reduce<
          DraftValueMapTransformation['configuration']
        >((acc, [key, value]) => {
          const pair = { key, value };
          acc[uuidv4()] = pair;
          return acc;
        }, {}),
      },
    };
  }
  if (t.function === 'remove_escape_sequence') {
    return {
      id,
      type: 'remove_escape_sequence',
      transformation: t,
    };
  }
  if (t.function === 'math') {
    return {
      id,
      type: 'math',
      transformation: t,
    };
  }
  if (t.function === 'json_loads') {
    return {
      id,
      type: 'json_loads',
      transformation: t,
    };
  }
  if (SINGLETON_TRANSFORMATIONS.has(t.function)) {
    return { id, type: t.function, transformation: t };
  }
  return emptyTransformation;
};

export const mapConvertedAnnotations = (tfmns) => {
  // Always render at least one empty transformation
  if (tfmns.length === 0) {
    return [convertTransformationToDraft(null)];
  }
  return tfmns.map((t) => convertTransformationToDraft(t));
};
export const convertDraftsToTransformations = (
  transformations: DraftTransformation[],
) =>
  transformations.reduce<Transformation[]>((tfmns, dt) => {
    const { transformation } = dt;
    if (transformation) {
      if (transformation.function === 'value_map') {
        const valueMap = Object.values(transformation.configuration).reduce<
          ValueMapTransformation['configuration']['value_map']
        >((acc, { key, value }) => {
          acc[key] = value;
          return acc;
        }, {});
        tfmns.push({
          function: 'value_map',
          configuration: { value_map: valueMap },
        });
      } else {
        tfmns.push(transformation);
      }
    }
    return tfmns;
  }, []);

export const addAnnotationOptionPrefix = (
  v: string,
  isCustom: boolean = false,
) =>
  `${
    isCustom ? CUSTOM_ANNOTATION_OPTION_PREFIX : TYPED_ANNOTATION_OPTION_PREFIX
  }${v}`;

export const removeAnnotationOptionPrefix = (v: string) =>
  v
    .replace(TYPED_ANNOTATION_OPTION_PREFIX, '')
    .replace(CUSTOM_ANNOTATION_OPTION_PREFIX, '');

export const annotationIsRequired = (
  annotationInfo: AnnotationConstant,
  updateExisting: boolean,
  primaryObjectType: PrimaryObject,
): boolean => {
  const { requirementType: requiredFor } = annotationInfo;
  const { notRequiredFor } = annotationInfo;
  let required =
    requiredFor === 'update' || (requiredFor === 'create' && !updateExisting);
  if (required && notRequiredFor?.length) {
    required = !notRequiredFor.includes(primaryObjectType);
  }
  return required;
};
