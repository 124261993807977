import {
  forwardRef,
  FormEvent,
  HTMLProps,
  useCallback,
  useEffect,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react';
import {
  TYPES,
  U21EncryptedFieldProps,
  U21TextFieldProps,
} from 'app/shared/u21-ui/components/input/text-field/U21TextFieldInputProps';
import styled, { css } from 'styled-components';
import { useTheme } from '@mui/styles';
import useForkRef from '@mui/utils/useForkRef';
import { U21LoadError } from 'app/shared/u21-ui/components/display/U21LoadError';
import { U21Typography } from 'app/shared/u21-ui/components/display/typography/U21Typography';
import { IconAlertTriangle } from '@u21/tabler-icons';
import { U21Button } from 'app/shared/u21-ui/components/input/U21Button';
import { FieldError } from 'app/shared/u21-ui/components/input/text-field/models';

export interface EncryptedInputProps
  extends Omit<HTMLProps<HTMLInputElement>, 'onBlur' | 'onFocus' | 'type'>,
    Pick<
      U21TextFieldProps,
      'delay' | 'error' | 'disabled' | 'placeholder' | 'required' | 'type'
    > {
  delay?: number;
  onBlur?: () => void;
  onFocus?: () => void;
  onChange: Exclude<HTMLProps<HTMLInputElement>['onChange'], undefined>;
  encryptedFieldProps: U21EncryptedFieldProps;
  frameError: boolean;
  frameLoading: boolean;
  frameReady: boolean;
  setFieldError: Dispatch<SetStateAction<FieldError | null>>;
  setFieldFocus: Dispatch<SetStateAction<boolean>>;
  setFieldReady: Dispatch<SetStateAction<boolean>>;
  setFrameError: Dispatch<SetStateAction<boolean>>;
  setFrameReady: Dispatch<SetStateAction<boolean>>;
  value: string;
}

export const EncryptedInput = forwardRef<
  HTMLIFrameElement,
  EncryptedInputProps
>((props: EncryptedInputProps, ref) => {
  const {
    delay = 1200,
    disabled,
    encryptedFieldProps,
    error,
    frameError,
    frameLoading,
    frameReady,
    id,
    maxLength,
    minLength,
    name,
    onBlur,
    onChange,
    onFocus,
    pattern,
    placeholder,
    required,
    setFieldError,
    setFieldFocus,
    setFieldReady,
    setFrameError,
    setFrameReady,
    type,
    value,
  } = props;

  const iframeRef = useRef<HTMLIFrameElement>(null);
  const combinedRef = useForkRef(ref, iframeRef);

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const theme = useTheme();

  useEffect(() => {
    // Set timeout for iframe loading
    timeoutRef.current = setTimeout(() => {
      if (!frameReady) {
        setFrameError(true);
      }
    }, 10000); // 10 second timeout
  }, [frameReady, setFrameError]);

  useEffect(() => {
    if (!frameReady) return;

    // Add function to extract styles
    const getInputStyles = () => {
      const styles = {
        'min-height': '40px',
        height: '100%',
        width: '100%',
        padding: '0',
        'text-overflow': 'ellipsis',
        '-webkit-tap-highlight-color': 'transparent',
        'font-size': '16px',
        'touch-action': 'manipulation',
        '-webkit-font-smoothing': 'antialiased',
        'font-variant-numeric': 'tabular-nums',
        'font-feature-settings': '\'tnum\', "tnum"',
        'font-family': theme.typography.fontFamily,
        'font-weight': theme.typography.fontWeightRegular,
        'background-color': theme.palette.background.paper,
        resize: 'none',
        border: 'none',
        outline: 'none',
        ...(type === TYPES.TEXTAREA
          ? {
              'line-height': '23px',
              'white-space': 'pre-wrap',
            }
          : {
              'line-height': '40px',
              'white-space': 'nowrap',
            }),
        ...(disabled
          ? {
              cursor: 'not-allowed',
              color: theme.palette.text.disabled,
            }
          : {
              color: theme.palette.text.primary,
            }),
      };
      return Object.entries(styles)
        .map(([cssKey, cssValue]) => `${cssKey}: ${cssValue}`)
        .join('; ');
    };

    const setupMessage = {
      type: 'SETUP_INPUT_FIELD',
      fieldId: id,
      fieldType: type,
      placeholder,
      required,
      disabled,
      minLength,
      maxLength,
      pattern,
      token: value,
      authToken: encryptedFieldProps.authTokenFetcher(),
      aad: `type=${encryptedFieldProps.fieldType}&id=${encryptedFieldProps.id}`,
      style: getInputStyles(),
      formStyle:
        'overflow: hidden; margin: 0; padding: 0; height: 100%; width: 100%;',
      selectionStyle: 'background-color: rgba(100, 100, 100, .4);',
    };

    iframeRef.current?.contentWindow?.postMessage(
      setupMessage,
      encryptedFieldProps.iframeHost,
    );
  }, [
    disabled,
    encryptedFieldProps,
    error,
    frameReady,
    id,
    maxLength,
    minLength,
    pattern,
    placeholder,
    required,
    theme,
    type,
    value,
  ]);

  const iFrameLoaded = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setFrameReady(true);
  }, [setFrameReady]);

  const onBlurRef = useRef(onBlur);
  onBlurRef.current = onBlur;
  const onFocusRef = useRef(onFocus);
  onFocusRef.current = onFocus;
  useEffect(() => {
    let changeTimeout: NodeJS.Timeout;
    const handleMessage = (event: MessageEvent) => {
      if (!event.data || event.data.fieldId !== id) {
        return;
      }
      if (event.data.type === 'INPUT_READY') {
        setFieldReady(true);
      }

      if (event.data.type === 'INPUT_BLUR') {
        setFieldFocus(false);
        onBlurRef.current?.();
      }

      if (event.data.type === 'INPUT_FOCUS') {
        setFieldFocus(true);
        onFocusRef.current?.();
      }

      if (event.data.type === 'INPUT_ERROR') {
        setFieldError({
          error: event.data.error,
          errorType: event.data.errorType,
          info: event.data.info,
        });
      }

      if (event.data.type === 'TOKENIZED_VALUE') {
        setFieldError(null);
        onChange({
          target: {
            name,
            value: event.data.token,
          },
        } as unknown as FormEvent<HTMLInputElement>);
      }

      if (event.data.type === 'INPUT_CHANGED') {
        // Clear the timeout if it exists to avoid multiple calls
        clearTimeout(changeTimeout);

        // Wait and force an "auto save"
        changeTimeout = setTimeout(() => {
          iframeRef.current?.contentWindow?.postMessage(
            {
              type: 'GET_TOKENIZED_VALUE',
              fieldId: id,
              authToken: encryptedFieldProps.authTokenFetcher(),
              orgId: encryptedFieldProps.orgId,
              aad: `type=${encryptedFieldProps.fieldType}&id=${encryptedFieldProps.id}`,
            },
            encryptedFieldProps.iframeHost,
          );
        }, delay);
      }
    };

    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
      clearTimeout(changeTimeout);
    };
  }, [
    delay,
    encryptedFieldProps,
    id,
    name,
    onChange,
    setFieldError,
    setFieldFocus,
    setFieldReady,
  ]);

  if (frameError) {
    if (type === TYPES.TEXTAREA) {
      return (
        <U21LoadError
          label="encrypted field"
          onTryAgain={() => window.location.reload()}
        />
      );
    }
    return (
      <U21Typography
        icon={<IconAlertTriangle color={theme.palette.error.main} />}
        variant="body2"
      >
        Failed to load encrypted field. Please{' '}
        <U21Button
          color="primary"
          onClick={() => window.location.reload()}
          variant="text"
        >
          try again
        </U21Button>
      </U21Typography>
    );
  }
  return (
    <EncryptedIframe
      $loading={frameLoading}
      $type={type}
      // apply the CSS of the input element to the iframe
      className="MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputMultiline MuiInputBase-inputSizeSmall MuiInputBase-inputAdornedEnd"
      ref={combinedRef}
      title={`Encrypted Input ${id}`}
      src={`${encryptedFieldProps.iframeHost}/encrypt-field/${id}?field_type=${type}`}
      onLoad={iFrameLoaded}
    />
  );
});

const EncryptedIframe = styled.iframe<{
  $loading?: boolean;
  $type?: U21TextFieldProps['type'];
}>`
  border: none;
  width: 100%;
  min-height: 40px;
  height: 100%;
  border: 0;
  padding: 0;
  margin: 0;
  flex-grow: 1;
  background: transparent;
  overflow: hidden;

  ${(props) =>
    props.$loading
      ? css`
          visibility: hidden;
        `
      : css``}

  ${(props) =>
    props.$type === TYPES.TEXTAREA
      ? css``
      : css`
          height: 40px;
        `}
`;
