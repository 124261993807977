import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Actions
import {
  editDeadlinesConfig as editDeadlinesConfigAction,
  editDeadlinesConfigSuccess,
} from 'app/modules/deadlines/actions';

// API
import { editDeadlinesConfig as editDeadlinesConfigAPI } from 'app/shared/api/deadlines';

// Models
import { DeadlinesActionTypes } from 'app/modules/deadlines/models';

const rootAction = DeadlinesActionTypes.EDIT_DEADLINES_CONFIG;
function* editDeadlinesConfig({
  payload,
}: ReturnType<typeof editDeadlinesConfigAction>) {
  const config = {
    rootAction,
    errorToastMessage: 'Unable to edit config.',
    successToastMessage: 'Config edited successfully',
    request: call(editDeadlinesConfigAPI, payload),
    success: function* onSuccess(
      result: ReturnType<typeof editDeadlinesConfigSuccess>['payload'],
    ) {
      yield put(editDeadlinesConfigSuccess(result));
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watchEditDeadlinesConfig() {
  yield takeLatest(rootAction, editDeadlinesConfig);
}
