import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { CasesActionTypes } from 'app/modules/casesOld/models';
import { InvestigationType } from 'app/modules/investigations/models';

// Types
import { InvestigationsCaseResponse } from 'app/modules/investigations/types';

// Actions
import {
  retrieveCase,
  retrieveCaseSuccess,
} from 'app/modules/casesOld/actions';

// API
import { retrieveInvestigationsCase } from 'app/modules/investigations/api';
import { retrieveCustomChecklistSubmission } from 'app/shared/customChecklist/actions';

const rootAction = CasesActionTypes.RETRIEVE_CASE;
function* retrieveCaseFlow({ payload }: ReturnType<typeof retrieveCase>) {
  const config = {
    rootAction,
    request: call(retrieveInvestigationsCase, {
      object_type: InvestigationType.CASE,
      object_ids: [payload],
      short: false,
    }),
    success: function* onSuccess(result: InvestigationsCaseResponse) {
      yield put(retrieveCaseSuccess(result.cases[0]));
      yield put(retrieveCustomChecklistSubmission(payload));
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watchRetrieveCase() {
  yield takeLatest(rootAction, retrieveCaseFlow);
}
