import { FC, HTMLProps, ReactNode, SyntheticEvent } from 'react';

import { getDOMProps } from 'app/shared/utils/react';
import styled, { css } from 'styled-components';

import { Dialog } from '@mui/material';

const SIZES = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  FULLSCREEN: 'fullscreen',
} as const;

export type U21ModalSize = ValueOf<typeof SIZES>;

export interface U21ModalBaseProps
  extends Omit<HTMLProps<HTMLDivElement>, 'size'> {
  children: ReactNode;
  onClose?: (event: SyntheticEvent, reason: string) => void;
  onEnter?: () => void;
  onExited?: () => void;
  open: boolean;
  size?: U21ModalSize;
}

export const U21ModalBase: FC<U21ModalBaseProps> = ({
  children,
  onClose,
  onEnter,
  onExited,
  open,
  size = SIZES.MEDIUM,
  ...rest
}) => {
  return (
    <Modal
      $size={size}
      aria-labelledby="u21-modal-title"
      disableEscapeKeyDown
      onClose={onClose}
      open={open}
      TransitionProps={{ onEnter, onExited }}
      {...getDOMProps(rest)}
    >
      {children}
    </Modal>
  );
};

const Modal = styled(Dialog)<{ $size: U21ModalSize }>`
  .MuiDialog-container {
    align-items: stretch;

    .MuiDialog-paper {
      width: 100%;

      ${(props) => {
        switch (props.$size) {
          case SIZES.SMALL:
            return css`
              margin: 120px 32px;
              max-height: calc(100% - 240px);
              max-width: 540px;
              height: fit-content;
            `;
          case SIZES.MEDIUM:
            return css`
              margin: 120px 32px;
              max-height: calc(100% - 240px);
              max-width: 700px;
              height: fit-content;
            `;
          case SIZES.LARGE:
            return css`
              margin: 120px 32px;
              max-height: calc(100% - 240px);
              max-width: 1080px;
              height: fit-content;
            `;
          case SIZES.FULLSCREEN:
            return css`
              margin: 32px;
              max-height: calc(100% - 64px);
              max-width: 100%;
            `;
          default:
            return css``;
        }
      }}
    }
  }
`;
