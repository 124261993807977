import { call, put, takeLatest, select } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { PermissionsActionTypes } from 'app/modules/permissions/models';

// Api
import { editPermissions as api } from 'app/shared/api/permissions';

// Actions
import {
  editPermissions as action,
  editPermissionsSuccess,
} from 'app/modules/permissions/actions';

// Selectors
import { selectAgent } from 'app/modules/session/selectors';
import { setSessionAgentPermissions } from 'app/modules/session/actions';

const rootAction = PermissionsActionTypes.EDIT_PERMISSIONS;
export function* editPermissions({ payload }: ReturnType<typeof action>) {
  const successToastMessage = 'Permissions saved successfully';
  const errorToastMessage = `Something went wrong, unable to update permissions for this ${payload.associationType}.`;
  const config = {
    rootAction,
    request: call(api, payload),
    successToastMessage,
    errorToastMessage,
    success: function* onSuccess(
      result: ReturnType<typeof editPermissionsSuccess>['payload'],
    ) {
      yield put(editPermissionsSuccess(result));

      // update logged in agents permissions if agent is editing their own permissions
      const loggedInAgent = yield select(selectAgent);
      if (
        payload.associationType === 'agent' &&
        payload.associationId === loggedInAgent.id
      ) {
        yield put(setSessionAgentPermissions(result.permissions));
      }
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watch() {
  yield takeLatest(rootAction, editPermissions);
}
