import React, { ReactNode } from 'react';

// Components
import ReactTooltip from 'react-tooltip';
import { Label } from 'semantic-ui-react';

// Styles
import styles from 'app/shared/styles/FlaggedLabel.module.scss';

interface OwnProps {
  classNameProps?: string;
  content: string;
  icon: ReactNode;
  tooltipContent?: string;
  id: string;
  onClick?: () => void;
  // To disable the onClick as well as hover styles
  disabled?: boolean;
}

type AllProps = OwnProps;

/**
 * @deprecated Use U21Label instead
 */
const FlaggedLabel = ({
  disabled,
  onClick,
  tooltipContent,
  id,
  classNameProps,
  content,
  icon,
}: AllProps) => {
  const optionalProps = disabled ? {} : { onClick };
  return (
    <>
      {tooltipContent && tooltipContent.length > 0 && !disabled && (
        <ReactTooltip
          id={id}
          place="top"
          effect="solid"
          className={styles.tooltip}
        />
      )}
      <Label
        as="a"
        className={`${classNameProps || ''} ${styles.flaggedLabel} ${
          !disabled && styles.activeFlaggedLabel
        }`}
        content={content}
        icon={icon}
        data-for={id}
        data-tip={tooltipContent}
        {...optionalProps}
      />
    </>
  );
};

export default FlaggedLabel;
