import { useSelector } from 'react-redux';
import { selectOrgEventStatuses } from 'app/modules/orgSettings/selectors';
import {
  U21MultiSelect,
  U21MultiSelectProps,
} from 'app/shared/u21-ui/components';
import { useMemo } from 'react';

export const StatusSelect = <TClearable extends boolean>(
  props: Omit<U21MultiSelectProps<string, TClearable>, 'options'>,
) => {
  const statuses = useSelector(selectOrgEventStatuses);

  const options = useMemo(() => {
    return statuses.map((i) => ({ text: i, value: i }));
  }, [statuses]);

  return <U21MultiSelect {...props} options={options} />;
};
