import { createSelector } from 'reselect';
import { keyBy } from 'lodash';

// Models
import { SidenavConfig } from 'app/modules/navigator/models';
import {
  SessionActionTypes,
  SessionAgent,
  SessionRelevantOrgs,
} from 'app/modules/session/models';
import { FullTagResponse } from 'app/modules/tags/responses';

// Utils
import permissionsList from 'app/shared/utils/permissions';
import getFromProcessEnv from 'app/shared/utils/getFromProcessEnv';
import { HOME_ROUTES } from 'app/shared/utils/routes';

// Helpers
import {
  anyPermissionChecker,
  hasPermissionChecker,
} from 'app/modules/permissions/utils';

// Constants
import { SIDENAV_CONFIG } from 'app/modules/navigator/config';
import { AUTH_CONFIG } from 'app/modules/session/auth0Config';
import {
  INITIAL_SESSION_AGENT,
  INITIAL_SESSION_RELEVANT_ORGS,
} from 'app/modules/session/constants';

// Selectors
import { selectFeatureFlags } from 'app/shared/featureFlags/selectors';

const EMPTY_ARRAY = [] as const;
const EMPTY_OBJECT = {} as const;

export const selectAgent = (state: RootState): SessionAgent => {
  return state.session.agent || INITIAL_SESSION_AGENT;
};

export const selectRelevantOrgs = (state: RootState): SessionRelevantOrgs => {
  return state.session.relevantOrgs || INITIAL_SESSION_RELEVANT_ORGS;
};

export const selectAgentOrgDisplayName = createSelector(
  selectAgent,
  (agent) => agent.org_display_name || '',
);

export const selectAgentOrgID = createSelector(
  selectAgent,
  (agent) => agent.org_id,
);

export const selectIsParentOrg = createSelector(
  selectRelevantOrgs,
  (relevantOrgs): boolean => relevantOrgs.org_type === 'parent_org',
);

export const selectIDP = (state: RootState) => {
  return state.session.idp || EMPTY_OBJECT;
};

export const selectLastRequestDate = (state: RootState) => {
  return state.session.lastRequestDate;
};

export const selectIsImpersonating = (state: RootState) =>
  state.session.impersonating;

export const selectIsUnit21User = createSelector(
  selectIsImpersonating,
  selectAgent,
  (isImpersonating, agent) => {
    return (
      isImpersonating ||
      agent.org_name === 'unit21' ||
      agent.email.match(/@unit21.com$|@unit21.ai$/)
    );
  },
);

export const selectSessionAgentPermissions = (state: RootState) => {
  return state.session.permissions;
};

export const selectAvailableHomeRoutes = createSelector(
  selectSessionAgentPermissions,
  (permissions) => {
    return HOME_ROUTES.filter((route) => {
      const { permissions: hasPermissions = [], anyPermissions = [] } = route;
      if (!hasPermissionChecker(permissions, hasPermissions)) {
        return false;
      }
      if (!anyPermissionChecker(permissions, anyPermissions)) {
        return false;
      }
      return true;
    });
  },
);

export const selectCanImpersonate = createSelector(
  selectAgent,
  selectSessionAgentPermissions,
  (sessionAgent, allPermissions) => {
    return (
      sessionAgent.can_impersonate &&
      allPermissions.includes(permissionsList.adminAPI)
    );
  },
);

/**
 * @deprecated Use a predefined permission selectors in session/selectors.
 *  If one doesn't exist, create a new permission selector in session/selectors
 */
export const selectHasPermissionsFactory = (
  permissionName: string | string[],
) =>
  createSelector(selectSessionAgentPermissions, (permissions) => {
    if (Array.isArray(permissionName)) {
      return hasPermissionChecker(permissions, permissionName);
    }
    return hasPermissionChecker(permissions, [permissionName]);
  });

// renamed so that we can still use this function in this file without seeing the deprecation strikethrough
// plan is to no longer export `selectHasPermissionsFactory` so permission selectors can only be defined here
const selectHasPermissionsFactoryInternal = selectHasPermissionsFactory;

export const selectHasAdminAPIPermission = selectHasPermissionsFactoryInternal(
  permissionsList.adminAPI,
);

export const selectHasEditAdminDashboardPermission =
  selectHasPermissionsFactoryInternal(permissionsList.editAdminDashboard);

export const selectHasEditRuleAdminDashboardPermission =
  selectHasPermissionsFactoryInternal(permissionsList.editRulesAdminDashboard);

export const selectHasReadAPIKeysPermissions =
  selectHasPermissionsFactoryInternal(permissionsList.readApiKeys);
export const selectHasCreateAPIKeysPermissions =
  selectHasPermissionsFactoryInternal(permissionsList.createApiKeys);
export const selectHasDeleteAPIKeysPermissions =
  selectHasPermissionsFactoryInternal(permissionsList.deleteApiKeys);
export const selectHasReadMatchlistsPermission =
  selectHasPermissionsFactoryInternal(permissionsList.readBlacklists);
export const selectHasCreateMatchlistsPermission =
  selectHasPermissionsFactoryInternal(permissionsList.createBlacklists);

export const selectHasReadAgentsPermissions =
  selectHasPermissionsFactoryInternal(permissionsList.readAgents);

export const selectHasReadSARsPermissions = selectHasPermissionsFactoryInternal(
  permissionsList.readSars,
);

export const selectHasReadCasesPermissions =
  selectHasPermissionsFactoryInternal(permissionsList.readCases);

export const selectHasReadEntitiesPermissions =
  selectHasPermissionsFactoryInternal(permissionsList.readEntities);

export const selectHasReadAssignmentsPermissions =
  selectHasPermissionsFactoryInternal(permissionsList.readAssignments);

export const selectHasReadInstrumentsPermission =
  selectHasPermissionsFactoryInternal(permissionsList.readTxnInstruments);

export const selectHasReadEventsPermission =
  selectHasPermissionsFactoryInternal(permissionsList.readEvents);

export const selectHasReadCustomDataUserPermissions =
  selectHasPermissionsFactoryInternal(permissionsList.readCustomDataSettings);

export const selectHasEditCustomDataUserPermissions =
  selectHasPermissionsFactoryInternal(permissionsList.editCustomDataSettings);

export const selectHasReadDataFileUploadsPermission =
  selectHasPermissionsFactoryInternal(permissionsList.readDatafileUploads);

export const selectHasEditStorageUserPermission =
  selectHasPermissionsFactoryInternal(permissionsList.editStorage);

export const selectHasReadDeadlinesConfigUserPermission =
  selectHasPermissionsFactoryInternal(permissionsList.readDeadlinesConfig);

export const selectHasEditSarsConfigUserPermission =
  selectHasPermissionsFactoryInternal(permissionsList.editSarsConfig);

export const selectHasEditRulePermission = selectHasPermissionsFactoryInternal(
  permissionsList.editRules,
);

export const selectHasEditEventPermissions =
  selectHasPermissionsFactoryInternal(permissionsList.editEvents);

export const selectHasCreateRulesPermission =
  selectHasPermissionsFactoryInternal(permissionsList.createRules);

export const selectNavConfig = createSelector(
  selectSessionAgentPermissions,
  selectFeatureFlags,
  selectIsParentOrg,
  (permissions, featureFlags, isParentOrg) =>
    SIDENAV_CONFIG.filter((config: SidenavConfig) => {
      const {
        anyPermissions,
        featureFlag,
        hasPermissions,
        hiddenByFeatureFlag,
        checkIsParent,
      } = config;
      if (featureFlag && !featureFlags[featureFlag]) {
        return false;
      }
      if (hiddenByFeatureFlag && featureFlags[hiddenByFeatureFlag]) {
        return false;
      }
      if (
        hasPermissions &&
        !hasPermissionChecker(permissions, hasPermissions)
      ) {
        return false;
      }
      if (
        anyPermissions &&
        !anyPermissionChecker(permissions, anyPermissions)
      ) {
        return false;
      }
      if (checkIsParent && !isParentOrg) {
        return false;
      }
      return true;
    }),
);

/**
 * @deprecated Use hasPermissionChecker in src/app/modules/permissions/utils.ts instead
 */
const hasPermission = (
  permissions: string[], // allPermissions
  permissionName: string | string[],
): boolean => {
  if (typeof permissionName === 'string') {
    return permissions.includes(permissionName);
  }
  return permissionName.reduce(
    (acc, permission) => acc && permissions.includes(permission),
    true,
  );
};

/**
 * @deprecated Use hasPermissionChecker in src/app/modules/permissions/utils.ts instead
 * */
export const hasPermissionsHelper = (
  permissions: string[], // allPermissions
  permissionName: string | string[],
): boolean => {
  return hasPermission(permissions, permissionName);
};

const googleClientId = getFromProcessEnv('REACT_APP_GOOGLE_CLIENT_ID');
const googleProjectNumber = getFromProcessEnv(
  'REACT_APP_GOOGLE_PROJECT_NUMBER',
);
const appOrigin = AUTH_CONFIG.loginRedirectUrl;

export const selectHasGoogleDriveToken = createSelector(selectIDP, (idp) => {
  const { isSocial, provider } = idp;
  const googleOAuthToken = idp.access_token;

  return (
    isSocial &&
    provider === 'google-oauth2' &&
    (
      [
        googleOAuthToken,
        googleClientId,
        googleProjectNumber,
        appOrigin,
      ] as Array<string>
    ).every((e) => e !== null)
  );
});

export const selectSessionAgentAllowedTags = createSelector(
  selectAgent,
  (sessionAgent): FullTagResponse[] => {
    const allowedTags = sessionAgent.teams.reduce(
      (tags, team) => ({ ...tags, ...keyBy(team.allowed_tags, 'id') }),
      {},
    );
    return Object.values(allowedTags);
  },
);

// action triggers can be enabled for only specific teams
// check to see if action trigger has any teams, if so, the logged in agent is on one of those teams
export const selectActionTriggerEnabledForSessionAgent = createSelector(
  selectAgent,
  (agent) => {
    return (actionTriggerTeams: number[]) => {
      // return true if no teams exist for this action trigger
      if (actionTriggerTeams.length <= 0) {
        return true;
      }
      const { teams } = agent;
      return teams.some((team) => actionTriggerTeams.includes(team.id));
    };
  },
);

// Loading
export const selectIDPLoading = (state: RootState) =>
  Boolean(state.loading[SessionActionTypes.RETRIEVE_IDP]);

export const selectStartSessionLoading = (state: RootState) =>
  Boolean(
    state.loading[SessionActionTypes.START_SESSION] ||
      INITIAL_SESSION_AGENT.id === state.session.agent.id,
  );

export const selectActionTriggers = (state: RootState) => {
  return state.session.actionTriggers || EMPTY_OBJECT;
};

export const selectAlertActionTriggers = createSelector(
  selectActionTriggers,
  ({ alertTriggers }) => alertTriggers || EMPTY_ARRAY,
);

export const selectBulkResolutionAlertActionTriggers = createSelector(
  selectAlertActionTriggers,
  selectAgent,
  (alertActionTriggers, agent) => {
    return alertActionTriggers.filter((trigger) => {
      if (trigger.teams.length <= 0) {
        return Boolean(trigger.config.enabled_for_bulk_resolutions);
      }
      const { teams } = agent;
      return (
        teams.some((team) => trigger.teams.includes(team.id)) &&
        Boolean(trigger.config.enabled_for_bulk_resolutions)
      );
    });
  },
);

export const selectAlertComponentActionTriggers = createSelector(
  selectActionTriggers,
  ({ alertComponentTriggers }) => alertComponentTriggers || EMPTY_ARRAY,
);

export const selectCaseActionTriggers = createSelector(
  selectActionTriggers,
  ({ caseTriggers }) => caseTriggers || EMPTY_ARRAY,
);

export const selectBulkResolutionCaseActionTriggers = createSelector(
  selectCaseActionTriggers,
  selectAgent,
  (actionTriggers, agent) => {
    return actionTriggers.filter((trigger) => {
      if (trigger.teams.length <= 0) {
        return Boolean(trigger.config.enabled_for_bulk_resolutions);
      }
      const { teams } = agent;
      return (
        teams.some((team) => trigger.teams.includes(team.id)) &&
        Boolean(trigger.config.enabled_for_bulk_resolutions)
      );
    });
  },
);

export const selectAgentTeamPermissions = createSelector(
  selectAgent,
  (agent) => agent.teams_permissions,
);

export const selectAgentAlertAssignmentLimit = createSelector(
  selectAgentTeamPermissions,
  (teamPermissions) => teamPermissions.alert_assignment_limit || 0,
);

export const selectAuthEmailSent = (state: RootState) => {
  return state.sessionSlice.authEmailSent;
};

export const selectHasCreateSARsPermission =
  selectHasPermissionsFactoryInternal(permissionsList.createSars);

export const selectHasEditSARsPermission = selectHasPermissionsFactoryInternal(
  permissionsList.editSars,
);

export const selectHasEditSARsConfigPermission =
  selectHasPermissionsFactoryInternal(permissionsList.editSarsConfig);

export const selectHasSarSubmitPermissions =
  selectHasPermissionsFactoryInternal([permissionsList.submitSars]);

export const selectHasEditAlertsPermission =
  selectHasPermissionsFactoryInternal(permissionsList.editAlerts);

export const selectHasEditCasesPermission = selectHasPermissionsFactoryInternal(
  permissionsList.editCases,
);

export const selectHasEditAgentsPermissions =
  selectHasPermissionsFactoryInternal(permissionsList.editAgents);

export const selectHasReadAlertComponentButtonsPermissions =
  selectHasPermissionsFactoryInternal(
    permissionsList.readAlertComponentButtons,
  );

export const selectHasReadRulesPermission = selectHasPermissionsFactoryInternal(
  permissionsList.readRules,
);

export const selectHasReadTeamsPermission = selectHasPermissionsFactoryInternal(
  permissionsList.readTeams,
);

export const selectHasCreateTeamsPermission =
  selectHasPermissionsFactoryInternal(permissionsList.createTeams);

export const selectHasEditTeamsPermission = selectHasPermissionsFactoryInternal(
  permissionsList.editTeams,
);

export const selectHasDeleteTeamsPermission =
  selectHasPermissionsFactoryInternal(permissionsList.deleteTeams);

export const selectHasReadWorkflowsPermission =
  selectHasPermissionsFactoryInternal(permissionsList.readWebhooks);

export const selectHasReadWebhooksPermissions =
  selectHasPermissionsFactoryInternal(permissionsList.readWebhooks);

export const selectHasCreateWebhooksPermissions =
  selectHasPermissionsFactoryInternal(permissionsList.createWebhooks);

export const selectHasEditWebhooksPermissions =
  selectHasPermissionsFactoryInternal(permissionsList.editWebhooks);

export const selectHasDeleteWebhooksPermissions =
  selectHasPermissionsFactoryInternal(permissionsList.deleteWebhooks);

export const selectHasEditQueuesPermission =
  selectHasPermissionsFactoryInternal(permissionsList.editQueues);

export const selectHasEditPermissionsPermission =
  selectHasPermissionsFactoryInternal(permissionsList.editPermissions);

export const selectHasReadAlertsPermission =
  selectHasPermissionsFactoryInternal(permissionsList.readAlerts);

export const selectHasCreateAlertsPermission =
  selectHasPermissionsFactoryInternal(permissionsList.createAlerts);

export const selectHasCreateCasePermission =
  selectHasPermissionsFactoryInternal(permissionsList.createCases);

export const selectHasReadSubdispositionsPermission =
  selectHasPermissionsFactoryInternal(permissionsList.readWebhooks); // yes ... this is intentional

export const selectHasEditDeadlinesPermission =
  selectHasPermissionsFactoryInternal(permissionsList.editDeadlines);

export const selectHasReadQualityPermission =
  selectHasPermissionsFactoryInternal(permissionsList.readQuality);

export const selectHasEditQualityPermission =
  selectHasPermissionsFactoryInternal(permissionsList.editQuality);

export const selectHasReadRiskRatingsPermission =
  selectHasPermissionsFactoryInternal(permissionsList.readRiskRatings);

export const selectHasEditRiskRatingsPermission =
  selectHasPermissionsFactoryInternal(permissionsList.editRiskRatings);

export const selectHasCreateRiskRatingsPermission =
  selectHasPermissionsFactoryInternal(permissionsList.createRiskRatings);

export const selectHasReadEntityMediaPermission =
  selectHasPermissionsFactoryInternal(permissionsList.readEntityMedia);

export const selectHasEditEntitiesPermission =
  selectHasPermissionsFactoryInternal(permissionsList.editEntities);

export const selectHasEditInstrumentsPermission =
  selectHasPermissionsFactoryInternal(permissionsList.editTxnInstruments);

export const selectHasEditInstrumentActionPermission =
  selectHasPermissionsFactoryInternal(permissionsList.editTxnInstrumentActions);

export const selectHasCreateDDBConnectionsPermission =
  selectHasPermissionsFactoryInternal(permissionsList.createDDBConnections);

export const selectHasEditDDBConnectionsPermission =
  selectHasPermissionsFactoryInternal(permissionsList.editDDBConnections);

export const selectHasDeleteDDBConnectionsPermission =
  selectHasPermissionsFactoryInternal(permissionsList.deleteDDBConnections);

export const selectHasReadDDBConnectionsPermission =
  selectHasPermissionsFactoryInternal(permissionsList.readDDBConnections);

export const selectHasEditTagsPermission = selectHasPermissionsFactoryInternal(
  permissionsList.editTags,
);

export const selectHasCreateDatafileUploads =
  selectHasPermissionsFactoryInternal(permissionsList.createDatafileUploads);

export const selectHasDeleteDatafileUploads =
  selectHasPermissionsFactoryInternal(permissionsList.deleteDatafileUploads);

export const selectHasReadLinkAnalysis = selectHasPermissionsFactoryInternal(
  permissionsList.readLinkAnalysis,
);

export const selectHasCreateDashboardPermission =
  selectHasPermissionsFactoryInternal(permissionsList.createDashboards);

export const selectHasEditDashboardPermission =
  selectHasPermissionsFactoryInternal(permissionsList.editDashboards);

export const selectHasDeleteDashboardPermission =
  selectHasPermissionsFactoryInternal(permissionsList.deleteDashboards);

export const selectHasDecryptPansPermission =
  selectHasPermissionsFactoryInternal(permissionsList.readPans);

export const selectHasCreate314aScansPermission =
  selectHasPermissionsFactoryInternal(permissionsList.create314aScans);
