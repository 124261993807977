import { SymbolTable } from 'app/modules/rulesAdvanced/lib/SymbolTable';

export const TXN_EVENT_SENDER_RECEIVER_ADVANCED_SYMBOLS: SymbolTable =
  Object.freeze({
    'sender_entity.cumulative_transaction_sum': {
      type: 'FIELD',
      field: 'sender_entity.cumulative_transaction_sum',
      label: 'sender_entity.cumulative_transaction_sum',
      model: 'txn_event',
      datatype: 'number',
      cardinality: 'many',
    },
    'receiver_entity.cumulative_transaction_sum': {
      type: 'FIELD',
      field: 'receiver_entity.cumulative_transaction_sum',
      label: 'receiver_entity.cumulative_transaction_sum',
      model: 'txn_event',
      datatype: 'number',
      cardinality: 'many',
    },
    'sender_entity.first_esigned_event': {
      type: 'FIELD',
      field: 'sender_entity.first_esigned_event',
      label: 'sender_entity.first_esigned_event',
      model: 'txn_event',
      datatype: 'datetime',
      cardinality: 'many',
    },
    'receiver_entity.first_esigned_event': {
      type: 'FIELD',
      field: 'receiver_entity.first_esigned_event',
      label: 'receiver_entity.first_esigned_event',
      model: 'txn_event',
      datatype: 'datetime',
      cardinality: 'many',
    },
    'sender_entity.first_transaction': {
      type: 'FIELD',
      field: 'sender_entity.first_transaction',
      label: 'sender_entity.first_transaction',
      model: 'txn_event',
      datatype: 'datetime',
      cardinality: 'many',
    },
    'receiver_entity.first_transaction': {
      type: 'FIELD',
      field: 'receiver_entity.first_transaction',
      label: 'receiver_entity.first_transaction',
      model: 'txn_event',
      datatype: 'datetime',
      cardinality: 'many',
    },

    sender_id: {
      type: 'FIELD',
      field: 'sender_id',
      label: 'sender_id',
      model: 'txn_event',
      datatype: 'string',
      cardinality: 'many',
    },
    receiver_id: {
      type: 'FIELD',
      field: 'receiver_id',
      label: 'receiver_id',
      model: 'txn_event',
      datatype: 'string',
      cardinality: 'many',
    },
    sender_source: {
      type: 'FIELD',
      field: 'sender_source',
      label: 'sender_source',
      model: 'txn_event',
      datatype: 'string',
      cardinality: 'many',
    },
    receiver_source: {
      type: 'FIELD',
      field: 'receiver_source',
      label: 'receiver_source',
      model: 'txn_event',
      datatype: 'string',
      cardinality: 'many',
    },
    'sender_entity.phone_number.number': {
      type: 'FIELD',
      field: 'sender_entity.phone_number.number',
      label: 'sender_entity.phone_number.number',
      model: 'txn_event',
      datatype: 'string',
      cardinality: 'many',
    },
    'receiver_entity.phone_number.number': {
      type: 'FIELD',
      field: 'receiver_entity.phone_number.number',
      label: 'receiver_entity.phone_number.number',
      model: 'txn_event',
      datatype: 'string',
      cardinality: 'many',
    },
    'sender_entity.email_address.email_address': {
      type: 'FIELD',
      field: 'sender_entity.email_address.email_address',
      label: 'sender_entity.email_address',
      model: 'txn_event',
      datatype: 'string',
      cardinality: 'many',
    },
    'receiver_entity.email_address.email_address': {
      type: 'FIELD',
      field: 'receiver_entity.email_address.email_address',
      label: 'receiver_entity.email_address',
      model: 'txn_event',
      datatype: 'string',
      cardinality: 'many',
    },
  });
