import { PaginationPayload, RowId } from 'app/shared/pagination/models';
import { ColorColorSchema } from 'vendor/material-minimal/palette';
import { IconOptions } from 'app/modules/workflows/constants';

export interface WorkflowTemplate {
  id: RowId;
  title: string;
  description: string;
  sortable: boolean;
}

export enum QueueSelectionButtons {
  SHOW_IN_ALL_QUEUES = 'SHOW_IN_ALL_QUEUES',
  SHOW_IN_SPECIFIC_QUEUES = 'SHOW_IN_SPECIFIC_QUEUES',
}

export enum TeamSelectionButtons {
  SHOW_FOR_ALL_TEAMS = 'SHOW_FOR_ALL_TEAMS',
  SHOW_FOR_SPECIFIC_TEAMS = 'SHOW_FOR_SPECIFIC_TEAMS',
}

export enum WorkflowCategoryButtons {
  INVESTIGATION = 'INVESTIGATION',
  QUALITY = 'QUALITY',
}

export enum ChangeQueueButtons {
  DO_NOTHING = 'DO_NOTHING',
  CHANGE_QUEUE = 'CHANGE_QUEUE',
  REASSIGN_AGENT = 'REASSIGN_AGENT',
  ASSIGN_TEAM = 'ASSIGN_TEAM',
}

export enum ReassignAgentButtons {
  SPECIFIC_AGENT = 'SPECIFIC_AGENT',
  ASK_AGENT_TO_CHOOSE = 'ASK_AGENT_TO_CHOOSE',
}

export enum ReassignTeamButtons {
  SPECIFIC_TEAM = 'SPECIFIC_TEAM',
  ASK_AGENT_TO_CHOOSE = 'ASK_AGENT_TO_CHOOSE_TEAM',
}

export enum QueueAgentAssignmentButtons {
  EXISTING_ASSIGNED_AGENT = 'EXISTING_ASSIGNED_AGENT',
  UNASSIGN = 'UNASSIGN_AGENT',
  REASSIGN_AGENT = 'REASSIGN_AGENT',
}

export enum OpenCloseAlertButtons {
  DO_NOTHING = 'DO_NOTHING',
  CLOSE_ALERT = 'CLOSE_ALERT',
  OPEN_ALERT = 'OPEN_ALERT',
  IN_REVIEW = 'IN_REVIEW',
}

export enum BulkResolutionButtons {
  DISABLED = 'DISABLED',
  ENABLED = 'ENABLED',
}

export enum DispositionNotesButtons {
  NONE = 'NONE',
  OPTIONAL = 'OPTIONAL',
  REQUIRED = 'REQUIRED',
}

export enum ApplySubdispositionsButtons {
  DISABLED = 'DISABLED',
  ENABLED = 'ENABLED',
}

export enum CreateCaseAlertButtons {
  DO_NOTHING = 'DO_NOTHING',
  CREATE_CASE = 'CREATE_CASE',
  CREATE_ALERT = 'CREATE_ALERT',
}

export enum CreateSarButtons {
  DO_NOTHING = 'DO_NOTHING',
  CREATE_SAR = 'CREATE_SAR',
  CREATE_GOAML = 'CREATE_GOAML',
  CREATE_FINCEN_CTR = 'CREATE_FINCEN_CTR',
}

export enum AddRemoveTagsButtons {
  DO_NOTHING = 'DO_NOTHING',
  AUTO = 'AUTO',
  YES = 'YES',
  NO = 'NO',
}

export enum WhiteListButtons {
  NONE = 'NONE',
  GLOBAL = 'GLOBAL',
  RULE = 'RULE',
  INDEFINITELY = 'INDEFINITELY',
  SPECIFIC = 'SPECIFIC',
  DAYS = 'DAYS',
  MONTHS = 'MONTHS',
  YEARS = 'YEARS',
}

export enum DisplayButtons {
  DO_NOTHING = 'DO_NOTHING',
  SPECIFIC = 'SPECIFIC',
}

export enum OpenCloseCaseButtons {
  DO_NOTHING = 'DO_NOTHING',
  CLOSE = 'CLOSE',
  OPEN = 'OPEN',
  IN_REVIEW = 'IN_REVIEW',
}

export enum ReassignmentTypeButtons {
  DO_NOTHING = 'DO_NOTHING',
  CHANGE_QUEUE = 'CHANGE_QUEUE',
  ASSIGN_TO_AGENT = 'ASSIGN_TO_AGENT',
  ASSIGN_TO_TEAM = 'ASSIGN_TO_TEAM',
  DYNAMIC = 'DYNAMIC',
  STATIC = 'STATIC',
}

export enum WorkflowContexts {
  ALERT = 'ALERT',
  CASE = 'CASE',
}

export enum WorkflowActionTypes {
  RETRIEVE_WORKFLOWS = '@@workflows/RETRIEVE_WORKFLOWS',
  RETRIEVE_WORKFLOWS_SUCCESS = '@@workflows/RETRIEVE_WORKFLOWS_SUCCESS',

  CREATE_WORKFLOW = '@@workflows/CREATE_WORKFLOW',
  CREATE_WORKFLOW_SUCCESS = '@@workflows/CREATE_WORKFLOW_SUCCESS',

  EDIT_WORKFLOW = '@@workflows/EDIT_WORKFLOW',
  EDIT_WORKFLOW_SUCCESS = '@@workflows/EDIT_WORKFLOW_SUCCESS',

  RETRIEVE_WORKFLOW = '@@workflows/RETRIEVE_WORKFLOW',
  RETRIEVE_WORKFLOW_SUCCESS = '@@workflows/RETRIEVE_WORKFLOW_SUCCESS',

  DELETE_WORKFLOW = '@@workflows/DELETE_WORKFLOW',
  DELETE_WORKFLOW_SUCCESS = '@@workflows/DELETE_WORKFLOW_SUCCESS',

  RETRIEVE_SUBDISPOSITIONS = '@@workflows/RETRIEVE_SUBDISPOSITIONS',
  RETRIEVE_SUBDISPOSITIONS_SUCCESS = '@@workflows/RETRIEVE_SUBDISPOSITIONS_SUCCESS',
}

export interface AlertWorkflowDetailResponse {
  context: WorkflowContexts.ALERT;
  add_tags: AddRemoveTagsButtons;
  apply_subdisposition: ApplySubdispositionsButtons;
  apply_tags_to_children: AddRemoveTagsButtons;
  bulk_resolutions: BulkResolutionButtons;
  category: WorkflowCategoryButtons;
  change_queue: ChangeQueueButtons;
  color: ColorColorSchema;
  create_case: CreateCaseAlertButtons;
  create_sar?: CreateSarButtons;
  created_at: string;
  created_by: string;
  description: string;
  display_specific: DisplayButtons;
  display_tags: number[];
  disposition_id?: number | null;
  disposition_notes: DispositionNotesButtons;
  enable_if_invalid: BulkResolutionButtons;
  external_id: string | null;
  id: number;
  icon: IconOptions;
  is_active: boolean;
  label: string;
  masking_tags?: number[];
  open_close_alert: OpenCloseAlertButtons;
  persist_agent_reassignment: QueueAgentAssignmentButtons;
  queue: number[];
  queue_availability: QueueSelectionButtons;
  reassign_agent: ReassignAgentButtons;
  reassignment_agent_id: number | null;
  reassignment_dynamic: ReassignmentTypeButtons;
  reassignment_team_id: number | null;
  reassignment_type: ReassignmentTypeButtons;
  remove_tags: AddRemoveTagsButtons;
  remove_tags_from_children: AddRemoveTagsButtons;
  select_agent: number | null;
  select_queue: number | null;
  select_queue_assignment_create_article: number | null;
  subdispositions?: Subdisposition[];
  tags_to_remove?: number[];
  tags: number[];
  teams: number[];
  whitelist: WhiteListButtons;
  whitelist_duration: WhiteListButtons;
  whitelist_time: string;
  whitelist_time_units: WhiteListButtons;
}

export interface CaseWorkflowDetailResponse {
  context: WorkflowContexts.CASE;
  add_tags: AddRemoveTagsButtons;
  apply_subdisposition: ApplySubdispositionsButtons;
  apply_tags_to_children: AddRemoveTagsButtons;
  category: WorkflowCategoryButtons;
  change_queue: ChangeQueueButtons;
  color: ColorColorSchema;
  created_at: string;
  created_by: string;
  create_sar?: CreateSarButtons;
  description: string;
  display_specific: DisplayButtons;
  display_tags: number[];
  disposition_id?: number | null;
  disposition_notes: DispositionNotesButtons;
  enable_if_invalid: BulkResolutionButtons;
  external_id: string | null;
  id: number;
  icon: IconOptions;
  is_active: boolean;
  label: string;
  masking_tags: number[];
  open_close_case: OpenCloseCaseButtons;
  queue_availability?: QueueSelectionButtons;
  persist_agent_reassignment: QueueAgentAssignmentButtons;
  reassign_agent?: ReassignAgentButtons;
  reassignment_agent_id: number | null;
  reassignment_dynamic: ReassignmentTypeButtons;
  reassignment_team_id: number | null;
  reassignment_type: ReassignmentTypeButtons;
  remove_tags: AddRemoveTagsButtons;
  remove_tags_from_children: AddRemoveTagsButtons;
  select_agent: number | null;
  select_queue: number | null;
  select_queue_assignment_create_article: number | null;
  subdispositions: Subdisposition[];
  tags_to_remove: number[];
  tags: number[];
  teams: number[];
  whitelist: WhiteListButtons;
  whitelist_duration: WhiteListButtons;
  whitelist_time: string;
  whitelist_time_units: WhiteListButtons;
}

export type WorkflowDetailResponse =
  | AlertWorkflowDetailResponse
  | CaseWorkflowDetailResponse;

export interface CreateAlertWorkflowPayload {
  context: WorkflowContexts.ALERT;
  add_tags: AddRemoveTagsButtons;
  apply_subdisposition: ApplySubdispositionsButtons;
  apply_tags_to_children: AddRemoveTagsButtons;
  bulk_resolutions: BulkResolutionButtons;
  category: WorkflowCategoryButtons;
  change_queue: ChangeQueueButtons;
  color: ColorColorSchema;
  create_case_alert: CreateCaseAlertButtons;
  create_sar: CreateSarButtons;
  description?: string;
  display_specific: DisplayButtons;
  display_tags?: number[];
  disposition_id?: number | null;
  disposition_notes: DispositionNotesButtons;
  disposition_is_fraud: boolean | null;
  enable_if_invalid: BulkResolutionButtons;
  external_id?: string;
  icon: IconOptions;
  label: string;
  masking_tags?: number[];
  open_close_alert: OpenCloseAlertButtons;
  persist_agent_reassignment: QueueAgentAssignmentButtons;
  queue?: number[];
  queue_agent?: number;
  queue_availability: QueueSelectionButtons;
  reassign_agent: ReassignAgentButtons;
  reassignment_agent_id?: number;
  reassignment_dynamic: ReassignmentTypeButtons;
  reassignment_team_id?: number;
  reassignment_type: ReassignmentTypeButtons;
  team_reassignment_type: ReassignTeamButtons;
  remove_tags: AddRemoveTagsButtons;
  remove_tags_from_children: AddRemoveTagsButtons;
  select_agent?: number;
  select_queue?: number;
  select_queue_assignment_create_article?: number;
  subdispositions?: Subdisposition[];
  tags_to_remove?: number[];
  tags?: number[];
  teams?: number[];
  team_availability: TeamSelectionButtons;
  whitelist: WhiteListButtons;
  whitelist_duration?: WhiteListButtons;
  whitelist_time?: string;
  whitelist_time_units?: WhiteListButtons;
}

export interface CreateCaseWorkflowPayload {
  context: WorkflowContexts.CASE;
  add_tags: AddRemoveTagsButtons;
  apply_subdisposition: ApplySubdispositionsButtons;
  apply_tags_to_children: AddRemoveTagsButtons;
  apply_tags_to_related_alerts:
    | AddRemoveTagsButtons.YES
    | AddRemoveTagsButtons.NO;
  bulk_resolutions: BulkResolutionButtons;
  category: WorkflowCategoryButtons;
  color: ColorColorSchema;
  create_sar: CreateSarButtons;
  change_queue: ChangeQueueButtons;
  description?: string;
  display_specific: DisplayButtons;
  display_tags?: number[];
  disposition_id: number | null;
  disposition_notes: DispositionNotesButtons;
  disposition_is_fraud: boolean | null;
  external_id?: string;
  icon: IconOptions;
  label: string;
  masking_tags?: number[];
  open_close_case: OpenCloseCaseButtons;
  persist_agent_reassignment: QueueAgentAssignmentButtons;
  queue?: number[];
  queue_agent?: number;
  reassign_agent: ReassignAgentButtons;
  reassignment_agent_id?: number;
  reassignment_dynamic: ReassignmentTypeButtons;
  reassignment_team_id?: number;
  reassignment_type: ReassignmentTypeButtons;
  team_reassignment_type: ReassignTeamButtons;
  remove_tags: AddRemoveTagsButtons;
  remove_tags_from_children: AddRemoveTagsButtons;
  remove_tags_from_related_alerts:
    | AddRemoveTagsButtons.YES
    | AddRemoveTagsButtons.NO;
  queue_availability: QueueSelectionButtons;
  select_agent?: number;
  select_queue?: number;
  select_queue_assignment_create_article?: number;
  subdispositions?: Subdisposition[];
  tags_to_remove?: number[];
  tags?: number[];
  teams?: number[];
  team_availability: TeamSelectionButtons;
  whitelist: WhiteListButtons;
  whitelist_duration?: WhiteListButtons;
  whitelist_time?: string;
  whitelist_time_units?: WhiteListButtons;
}

export type CreateWorkflowPayload =
  | CreateAlertWorkflowPayload
  | CreateCaseWorkflowPayload;

export type EditWorkflowPayload = CreateWorkflowPayload & {
  id: string;
};

export interface AlertWorkflowFormModel
  extends Omit<CreateAlertWorkflowPayload, 'whitelist_time'> {
  whitelist_time: {
    value: number;
    unit: WhiteListButtons;
  };
}

export interface CaseWorkflowFormModel
  extends Omit<CreateCaseWorkflowPayload, 'whitelist_time'> {
  whitelist_time: {
    value: number;
    unit: WhiteListButtons;
  };
}

export type WorkflowFormModel = AlertWorkflowFormModel | CaseWorkflowFormModel;

export interface GetWorkflowsPayload extends PaginationPayload {
  context: WorkflowContexts;
}

export interface RetrieveWorkflowsSuccessPayload {
  workflows: WorkflowDetailResponse[];
  count: number;
}

export interface SubdispositionOption {
  name: string;
  title?: string;
  id: number;
}

export interface Subdisposition {
  included: boolean;
  id: number;
  options: SubdispositionOption[];
  title: string;
}

export interface WorkflowState {
  workflows: WorkflowDetailResponse[];
  workflow?: WorkflowDetailResponse;
  subdispositions: Subdisposition[];
  count: number;
}
