import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Actions
import {
  createWebhook as createWebhookAction,
  createWebhookSuccess,
  listWebhooks,
} from 'app/modules/webhooks/actions';

import { sendSuccessToast } from 'app/shared/toasts/actions';

// API
import { addWebhook } from 'app/shared/api/webhooks';

// Models
import { WebhookActionTypes } from 'app/modules/webhooks/models';

// Routes
import routes, { pathJoin } from 'app/shared/utils/routes';
import { history } from 'app/store/browserHistory';

const rootAction = WebhookActionTypes.CREATE_WEBHOOK;
function* createWebhook({ payload }: ReturnType<typeof createWebhookAction>) {
  const config = {
    rootAction,
    errorToastMessage: 'Something went wrong, unable to create the webhook.',
    request: call(addWebhook, payload),
    success: function* onSuccess({ id }) {
      yield put(createWebhookSuccess());
      yield put(listWebhooks());
      history.push(pathJoin(routes.lumos.webhooksId.replace(':id', id)));
      yield put(sendSuccessToast(`Webhook ${id} created successfully.`));
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watchCreateWebhook() {
  yield takeLatest(rootAction, createWebhook);
}
