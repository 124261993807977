import { OFFSET_DEFAULT } from 'app/shared/constants';

import { selectHasReadTeamsPermission } from 'app/modules/session/selectors';
import { selectTeamSearchResults } from 'app/modules/search/selectors';
import { teamsSearch } from 'app/modules/search/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useRef } from 'react';

import { FilterMoreDisplay } from 'app/modules/filters/components/FilterMoreDisplay';

interface Props {
  ids: number[];
  label: string;
}

export const TeamFilterDisplay = (props: Props) => {
  const { ids, label } = props;
  const dispatch = useDispatch();
  const hasReadTeamsPermission = useSelector(selectHasReadTeamsPermission);
  const teams = useSelector(selectTeamSearchResults).data;

  const teamsMap = useMemo(
    () => teams.reduce((acc, i) => ({ ...acc, [i.id]: i }), {}),
    [teams],
  );

  // calledRef is used to prevent an infinite loop in case the API never returns with the missing value
  const calledRef = useRef(false);
  useEffect(() => {
    calledRef.current = false;
  }, [ids]);
  useEffect(() => {
    if (hasReadTeamsPermission) {
      const missingValues = ids.filter((i) => !teamsMap[i]);
      if (missingValues.length && !calledRef.current) {
        calledRef.current = true;
        dispatch(
          teamsSearch({
            ids: missingValues,
            limit: missingValues.length,
            offset: OFFSET_DEFAULT,
            phrase: '',
          }),
        );
      }
    }
  }, [dispatch, hasReadTeamsPermission, ids, teamsMap]);

  const values = useMemo(
    () => ids.map((i) => teamsMap[i]?.name || 'Unknown'),
    [ids, teamsMap],
  );

  return (
    <>
      <b>{label}</b> is one of <FilterMoreDisplay values={values} />
    </>
  );
};
