import React from 'react';

// Components
import ReactTooltip from 'react-tooltip';

// Styles
import styles from 'app/modules/detectionModels/styles/ProgressBar.module.scss';

interface OwnProps {
  totalWindows: number;
  currentWindow: number;
  maxWindows?: number;
  tooltip?: string;
  width?: number;
  animated?: boolean;
}

const APPEARANCE_DURATION = 500; // ms
const DEFAULT_MAX_WINDOWS = 30;

const ProgressBar = ({
  totalWindows,
  currentWindow,
  maxWindows = DEFAULT_MAX_WINDOWS,
  tooltip,
  width,
  animated,
}: OwnProps) => {
  const numWindows = Math.min(totalWindows, maxWindows);
  // Get current window, make sure to scale the current window if we are using MAX_WINDOWS
  const cWindow =
    numWindows === totalWindows
      ? currentWindow
      : Math.floor((maxWindows * currentWindow) / totalWindows);

  const getBlockClass = (blockIx): string => {
    // Progress completed, no need to add custom styles to the blocks
    if (numWindows === cWindow) {
      return '';
    }

    if (cWindow === blockIx) {
      return styles.activeWindow;
    }
    return cWindow > blockIx ? styles.completedWindow : styles.pendingWindow;
  };

  return (
    <div
      data-for="progressTooltip"
      data-tip={tooltip}
      className={`${styles.progressBarContainer} ${
        animated ? styles.animated : ''
      }`}
      style={{ width }}
    >
      {tooltip && <ReactTooltip id="progressTooltip" multiline />}
      {[...Array(numWindows)].map((_, ix) => {
        return (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={ix}
            style={{
              animationDelay: `${(APPEARANCE_DURATION / numWindows) * ix}ms`,
            }}
            className={`${styles.progressBarWindow} ${getBlockClass(ix)}`}
          />
        );
      })}
    </div>
  );
};

export default ProgressBar;
