// Types
import { AuditTrailActionTriggerDigest } from 'app/shared/auditTrail/types';

export enum AuditTrailActionTypes {
  RETRIEVE_AUDIT_TRAIL = '@@auditTrail/RETRIEVE_AUDIT_TRAIL',
  RETRIEVE_AUDIT_TRAIL_SUCCESS = '@@auditTrail/RETRIEVE_AUDIT_TRAIL_SUCCESS',
  RETRIEVE_AUDIT_TRAIL_ENTRIES = '@@auditTrail/RETRIEVE_AUDIT_TRAIL_ENTRIES',
  RETRIEVE_AUDIT_TRAIL_ENTRIES_SUCCESS = '@@auditTrail/RETRIEVE_AUDIT_TRAIL_ENTRIES_SUCCESS',
  CLEAR_AUDIT_TRAIL_ENTRIES = '@@auditTrail/CLEAR_AUDIT_TRAIL_ENTRIES',
}

export interface AuditTrailState {
  auditTrailDetails: AuditTrailDetails;
  auditTrailEntries: PaginatedAuditTrailResponse;
}

export enum Unit21AuditTrailActionTypes {
  CASE_CREATE = 'CASE_CREATE',
  CASE_EDIT = 'CASE_EDIT',
  CASE_OPEN = 'CASE_OPEN',
  CASE_CLOSED = 'CASE_CLOSED',
  CASE_ACTION_TRIGGERED = 'CASE_ACTION_TRIGGERED',
  CASE_REASSIGN = 'CASE_REASSIGN',
  CASE_CREATE_SAR = 'CASE_CREATE_SAR',

  GLOBAL_WHITELIST = 'GLOBAL_WHITELIST',
  GLOBAL_UNWHITELIST = 'GLOBAL_UNWHITELIST',
  RULE_WHITELIST = 'RULE_WHITELIST',
  RULE_UNWHITELIST = 'RULE_UNWHITELIST',
  // pending much more
}

export interface AuditTrailSummary {
  id: number;
  author: string | null;
  created_at: string | null;
  content: string;
  action_type: Unit21AuditTrailActionTypes;
  api_event: boolean;
  use_modal: boolean;
}

export interface AuditTrailDetails extends AuditTrailSummary {
  req_data?: any; // stored api request on api related audit trails, Deprecated
  before_after?: any; // dictionary of key: [before, after] audited action
  action_trigger_digest?: AuditTrailActionTriggerDigest; // action_trigger digest given on usage of an action trigger

  entities: { [key: string]: string[] };
  events: { [key: string]: string[] };
  cases: { [key: string]: string[] };
  alerts: { [key: string]: string[] };
  sars?: { [key: string]: string[] };
  rules: { [key: string]: string[] };
  txn_instruments: { [key: string]: string[] };

  whitelist_duration?: number; // day count of whitelist duration selected
}

export interface AuditTrailEntriesPayload {
  entity_ids?: number[];
  limit: number | null;
  offset: number;
}

export interface PaginatedAuditTrailResponse {
  count: number;
  entries: AuditTrailSummary[];
}
