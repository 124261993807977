import { unionBy } from 'lodash';
import { actionEventsSearch as actionEventsSearchAPI } from 'app/shared/api/search';

import { PayloadAction } from '@reduxjs/toolkit';
import { u21CreateSlice } from 'app/shared/thunk/u21CreateSlice';
import { u21CreateAsyncThunk } from 'app/shared/thunk/u21CreateAsyncThunk';

// Models
import {
  NewSearchState,
  EventsSearchResponse,
  SearchRequest,
} from 'app/modules/search/models';

const SEARCH_NAME = 'searchSlice';

export const initialState: Readonly<NewSearchState> = {
  actionEvents: {
    data: [],
  },

  // Loading
  actionEventsLoading: false,
};

export const actionEventsSearch = u21CreateAsyncThunk<
  SearchRequest,
  EventsSearchResponse
>(`${SEARCH_NAME}/GET_ACTION_EVENTS`, actionEventsSearchAPI);

export const searchSlice = u21CreateSlice({
  name: SEARCH_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addLoadingCase(
      actionEventsSearch,
      'actionEventsLoading',
      (draft, action: PayloadAction<EventsSearchResponse>) => {
        draft.actionEvents.data = unionBy(
          draft.actionEvents.data,
          action.payload.data,
          'id',
        );
      },
    );
  },
});

export const SEARCH_SLICE_NAME = searchSlice.name;
export default searchSlice.reducer;
