// Models
import { FormFieldCustomSchema, FormFieldSchema } from 'app/shared/models/form';
import { NewCaseDetails } from 'app/modules/cases/models';

// Components
import { U21FormFieldTypes } from 'app/shared/u21-ui/components';
import {
  U21AgentSelect,
  U21QueueSelect,
} from 'app/shared/u21-ui/components/dashboard';

// Utils
import emptyFn from 'app/shared/utils/empty-fn';
import { QueueType } from 'app/modules/queues/models';
import { required } from 'app/shared/utils/validation';

export const CASE_TITLE: FormFieldSchema<string> = {
  label: 'Case title',
  name: 'title',
  type: U21FormFieldTypes.TEXT,
  required: true,
  fieldProps: {
    placeholder: 'Enter title',
  },
  validate: required('Case title is required.'),
};

export const CASE_DESCRIPTION: FormFieldSchema<string> = {
  label: 'Case description',
  name: 'description',
  type: U21FormFieldTypes.TEXT,
  required: true,
  fieldProps: {
    placeholder: 'Enter description',
  },
  validate: required('Case description is required.'),
};

export const CASE_ASSIGNEE: FormFieldCustomSchema<number> = {
  children: <U21AgentSelect label={undefined} onChange={emptyFn} />,
  label: 'Assignee',
  name: 'assignee',
  type: 'CUSTOM',
  fieldProps: {
    placeholder: 'Enter assignee',
  },
};

export const CASE_QUEUE: FormFieldCustomSchema<number> = {
  children: (
    <U21QueueSelect
      queueType={QueueType.CASE_QUEUE}
      label={undefined}
      onChange={emptyFn}
      placeholder="Select Queue"
      clearable={false}
    />
  ),
  label: 'Queue',
  required: true,
  name: 'queue',
  type: 'CUSTOM',
  validate: required('Queue is required.'),
};

export const CASE_START_DATE: FormFieldSchema<Date> = {
  label: 'Start date',
  default: (caseDetail: NewCaseDetails) =>
    caseDetail.start_date ? new Date(caseDetail.start_date) : new Date(),
  name: 'start_date',
  type: U21FormFieldTypes.DATE_PICKER,
  required: true,
};

export const CASE_END_DATE: FormFieldSchema<string> = {
  label: 'End date',
  name: 'end_date',
  type: U21FormFieldTypes.DATE_PICKER,
};

export const EDIT_CASE_SCHEMA = [
  CASE_TITLE,
  CASE_DESCRIPTION,
  CASE_START_DATE,
  CASE_END_DATE,
];
