import { U21TableHeaderCellProps } from 'app/shared/u21-ui/components/display/table/models';

import {
  getDisabledSelectedRows,
  getSelectableRows,
} from 'app/shared/u21-ui/components/display/table/utils';
import { useMemo } from 'react';

import { U21Checkbox } from 'app/shared/u21-ui/components/input/U21Checkbox';

export const U21TableHeaderCheckbox = <T extends { id: number | string }, F>(
  props: U21TableHeaderCellProps<T, F>,
) => {
  const {
    tableProps: { page, rows },
    userProps: {
      disabled,
      onRowSelect,
      selected = [],
      onSelectAllButtonPressed,
    },
  } = props;

  const pageIDs = useMemo(() => page.map((i) => i.id), [page]);

  const checked = useMemo(() => {
    if (!pageIDs.length) {
      return false;
    }
    const selectedSet = new Set(selected);
    if (disabled === true) {
      return pageIDs.every((i) => selectedSet.has(i));
    }
    const disabledSet = new Set(disabled || []);
    return pageIDs.every((i) => selectedSet.has(i) || disabledSet.has(i));
  }, [disabled, pageIDs, selected]);

  const checkboxDisabled = useMemo(() => {
    if (!disabled) {
      return false;
    }
    if (disabled === true) {
      return true;
    }
    const disabledSet = new Set(disabled);
    return pageIDs.every((i) => disabledSet.has(i));
  }, [disabled, pageIDs]);

  return (
    <U21Checkbox
      checked={checked}
      disabled={checkboxDisabled}
      indeterminate={selected.length > 0}
      onChange={(val, e) => {
        if (val) {
          const currentPageSelectable = getSelectableRows(
            page,
            selected,
            disabled,
          );
          const pageIDsSet = new Set(pageIDs);

          // get disabled selected rows on other pages
          // to keep disabled selected rows on other pages still selected
          const disabledSelectedRows = getDisabledSelectedRows(
            rows.filter((i) => !pageIDsSet.has(i.id)),
            selected,
            disabled,
          );
          const selectable = [
            ...currentPageSelectable,
            ...disabledSelectedRows,
          ];
          onRowSelect?.(
            selectable.map((i) => i.id),
            selectable.map((i) => i.original),
            e,
          );
        } else {
          // make sure to not deselect disabled selected rows on other pages
          const disabledSelectedRows = getDisabledSelectedRows(
            rows,
            selected,
            disabled,
          );
          onRowSelect?.(
            disabledSelectedRows.map((i) => i.id),
            disabledSelectedRows.map((i) => i.original),
            e,
          );
        }
        if (checked) {
          onSelectAllButtonPressed?.(false);
        }
      }}
    />
  );
};
