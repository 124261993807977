import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { AlertsActionTypes } from 'app/modules/alerts/models';

// Actions
import {
  retrieveAlertQueues as retrieveAlertQueuesAction,
  retrieveAlertQueuesSuccess,
} from 'app/modules/alerts/actions';

// API
import { retrieveAlertQueues as retrieveAlertQueuesApi } from 'app/shared/api/alerts';

const rootAction = AlertsActionTypes.RETRIEVE_ALERT_QUEUES;
function* retrieveAlertQueues({
  payload,
}: ReturnType<typeof retrieveAlertQueuesAction>) {
  const config = {
    rootAction,
    request: call(retrieveAlertQueuesApi, payload),
    success: function* onSuccess(result) {
      yield put(retrieveAlertQueuesSuccess(result));
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watchRetrieveAlertQueues() {
  yield takeLatest(rootAction, retrieveAlertQueues);
}
