// Models
import { TeamPermissions } from 'app/modules/teamsOld/models';

export const INITIAL_TEAMS_PERMISSIONS: TeamPermissions = {
  allow_listing_unassigned_alerts: true,
  allow_viewing_unassigned_alerts: true,
  allow_viewing_entity_page_of_unassigned_alerts: true,
  allow_self_assigning_alerts: true,
  allow_assign_others_alerts: true,
  allow_listing_unassigned_entities: true,
  alert_assignment_limit: 0,
  allow_tag_create: true,
  allow_tag_edit: true,
  allow_rule_deployment: true,
};
