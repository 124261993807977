import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { EventActionTypes } from 'app/shared/events/models';

// Api
import { retrieveActionEvents as api } from 'app/shared/api/events';

// Actions
import {
  retrieveActionEvents as action,
  retrieveActionEventsSuccess,
} from 'app/shared/events/actions';

const rootAction = EventActionTypes.RETRIEVE_ACTION_EVENTS;
export function* retrieveActionEvents({ payload }: ReturnType<typeof action>) {
  const formattedPayload = {} as any;
  Object.keys(payload).forEach((key) => {
    if (payload[key]) {
      formattedPayload[key] = payload[key];
    }
  });
  const config = {
    rootAction,
    request: call(api, formattedPayload),
    success: function* onSuccess(
      result: ReturnType<typeof retrieveActionEventsSuccess>['payload'],
    ) {
      yield put(retrieveActionEventsSuccess(result));
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watch() {
  yield takeLatest(rootAction, retrieveActionEvents);
}
