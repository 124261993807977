import { Skeleton, SkeletonProps } from '@mui/material';
import { forwardRef, HTMLProps } from 'react';
import { getDOMProps } from 'app/shared/utils/react';

export interface U21SkeletonProps extends HTMLProps<HTMLSpanElement> {
  children?: SkeletonProps['children'];
  height?: SkeletonProps['height'];
  width?: SkeletonProps['width'];
}

export const U21Skeleton = forwardRef<HTMLSpanElement, U21SkeletonProps>(
  (props: U21SkeletonProps, ref) => {
    const { children, height = 24, width, ...rest } = props;
    return (
      <Skeleton
        ref={ref}
        height={height}
        variant="text"
        width={width}
        {...getDOMProps(rest)}
      >
        {children}
      </Skeleton>
    );
  },
);
