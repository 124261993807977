import { HTMLProps, ReactElement, ReactNode } from 'react';

import {
  U21HelpModal,
  U21HelpModalProps,
} from 'app/shared/u21-ui/components/display/U21HelpModal';
import { U21Typography } from 'app/shared/u21-ui/components/display/typography/U21Typography';
import { U21Loading } from 'app/shared/u21-ui/components/display/U21Loading';
import { U21Spacer } from 'app/shared/u21-ui/components/layout/U21Spacer';

import { getDOMProps } from 'app/shared/utils/react';
import styled from 'styled-components';
import { isNewDesignEnabled } from 'vendor/material-minimal/featureFlag';
import { U21PageNew } from 'app/shared/u21-ui/components/layout/U21PageNew';

const SIZES = {
  medium: '1280px',
  max: '100%',
};

type PageSizeType = 'medium' | 'max';

export interface U21PageProps
  extends Omit<HTMLProps<HTMLDivElement>, 'action' | 'size' | 'title'> {
  action?: ReactNode;
  afterTitleContent?: ReactNode;
  beforeTitleContent?: ReactNode;
  children: ReactNode;
  helpModalProps?: U21HelpModalProps;
  loading?: boolean;
  size?: PageSizeType;
  title: ReactNode;
  titleIcon?: ReactElement;
}

const U21PageOld = (props: U21PageProps) => {
  const {
    action,
    afterTitleContent,
    beforeTitleContent,
    children,
    helpModalProps,
    loading,
    size = 'max',
    title,
    titleIcon,
    ...rest
  } = props;
  return (
    <Container {...getDOMProps(rest)}>
      {!loading && (
        <Content $size={size}>
          <Header>
            <HeaderSpacer horizontal spacing={2}>
              {beforeTitleContent}
              <U21Typography align="start" icon={titleIcon} variant="h4">
                {afterTitleContent ? (
                  <U21Spacer horizontal useFlexGap>
                    {title}
                    {afterTitleContent}
                  </U21Spacer>
                ) : (
                  title
                )}
              </U21Typography>
              {helpModalProps && (
                <U21HelpModal
                  title="How to Use This Page"
                  {...helpModalProps}
                />
              )}
            </HeaderSpacer>
            {action}
          </Header>
          {children}
        </Content>
      )}
      <MaxLoading loading={loading} />
    </Container>
  );
};

const Container = styled.div`
  min-height: 100%;
  display: flex;
  justify-content: center;
  margin-left: 0;
  padding: 24px;
  background: ${(props) => props.theme.palette.background.neutral};

  @media print {
    min-height: unset;
  }
`;

const Content = styled.div<{ $size: PageSizeType }>`
  width: 100%;
  max-width: ${(props) => SIZES[props.$size]};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 16px;
`;

const HeaderSpacer = styled(U21Spacer)`
  align-items: flex-start;
`;

const MaxLoading = styled(U21Loading)`
  width: 100%;
`;

export const U21Page = isNewDesignEnabled() ? U21PageNew : U21PageOld;
