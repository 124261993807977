// Redux
import { Reducer } from 'redux';
import { produce, Draft } from 'immer';

// Models
import {
  SettingsActionTypes,
  SettingsState,
} from 'app/modules/settings/models';

// Helpers
import { SettingsActions } from 'app/modules/settings/actions';

// Utils
import deepFreeze from 'app/shared/utils/deepFreeze';

const initialState: Readonly<SettingsState> = {
  settingsGoAMLTemplates: [] as SettingsState['settingsGoAMLTemplates'],
};
// to ensure initialState is readonly
deepFreeze(initialState);

const reducer: Reducer<SettingsState> = (
  state = initialState,
  action: SettingsActions,
) => {
  return produce(state, (draft: Draft<SettingsState>) => {
    switch (action.type) {
      case SettingsActionTypes.EDIT_GO_AML_TEMPLATES_SUCCESS: {
        const templateTypeIndex = draft.settingsGoAMLTemplates.findIndex(
          (t) => t.template_type === action.payload.template_type,
        );
        if (templateTypeIndex > -1) {
          draft.settingsGoAMLTemplates[templateTypeIndex] = action.payload;
        }
        return;
      }
      case SettingsActionTypes.RETRIEVE_GO_AML_TEMPLATES_SUCCESS:
        draft.settingsGoAMLTemplates = action.payload;
        return;
    }
  });
};

export { reducer as settingsReducer, initialState };
