import {
  ElementType,
  HTMLProps,
  isValidElement,
  MouseEventHandler,
  ReactElement,
  ReactNode,
  useRef,
  useState,
} from 'react';

import { getDOMProps } from 'app/shared/utils/react';
import styled, { css } from 'styled-components';

import { IconChevronRight } from '@u21/tabler-icons';
import { Link, LinkProps } from 'react-router-dom';
import { Menu, MenuItem, PopoverOrigin } from '@mui/material';
import {
  U21Typography,
  U21TypographyProps,
} from 'app/shared/u21-ui/components/display/typography/U21Typography';
import {
  U21Tooltip,
  U21TooltipProps,
} from 'app/shared/u21-ui/components/display/U21Tooltip';
import { AnalyticsEvents, trackEvent } from 'app/shared/u21-ui/analytics';
import { U21TypographyIcon } from 'app/shared/u21-ui/components/display/typography/U21TypographyIcon';
import { U21Spacer } from 'app/shared/u21-ui/components/layout/U21Spacer';

export interface U21MenuItemProps
  extends Omit<HTMLProps<HTMLLIElement>, 'children'> {
  children?: (U21MenuItemProps | ReactElement)[];
  description?: string;
  color?: U21TypographyProps['color'];
  disabled?: boolean;
  href?: string;
  icon?: ReactElement;
  key?: string;
  onClick?: MouseEventHandler<HTMLElement>;
  rightIcon?: ReactElement;
  text: ReactNode;
  to?: LinkProps['to'];
  tooltip?: U21TooltipProps['tooltip'];
  tooltipProps?: Omit<U21TooltipProps, 'children' | 'tooltip'>;
  typographyProps?: Omit<U21TypographyProps, 'as' | 'children'>;
}

export interface U21NestedMenuProps {
  alignRight?: boolean;
  item: U21MenuItemProps;
  menuAutoFocus?: boolean;
  onClose?: () => void;
}

const getPopoverXCoordinate = (
  anchorElement: HTMLLIElement | null,
  alignRight: boolean,
): PopoverOrigin['horizontal'] => {
  if (alignRight) {
    return -8;
  }
  if (!anchorElement) {
    return 'right';
  }
  return anchorElement.getBoundingClientRect().width + 8;
};

export const U21MenuItem = (props: U21NestedMenuProps) => {
  const { alignRight = false, item, menuAutoFocus, onClose, ...rest } = props;
  const ref = useRef<HTMLLIElement>(null);
  const [open, setOpen] = useState(false);

  const {
    children = [],
    color,
    description,
    disabled,
    href,
    icon,
    onClick,
    rightIcon,
    text,
    to,
    tooltip,
    tooltipProps,
    typographyProps,

    // link-specific attributes
    target,
    ...restItem
  } = item;
  const hasSubMenu = children.length > 0;

  const onOpenMenu = () => setOpen(true);
  const onCloseMenu = () => setOpen(false);

  const onCloseWrapper = () => {
    onCloseMenu();
    onClose?.();
  };

  const disabledWithTooltip = disabled && Boolean(tooltip);

  const menuItem = (
    <StyledMenuItem
      // disabled default 'menuitem' role since we wrap this in li when disabled + tooltip
      component={disabledWithTooltip ? 'div' : undefined}
      disabled={disabled}
      onClick={(e) => {
        onClick?.(e);
        trackEvent(AnalyticsEvents.U21MENUITEM_ON_CLICK, props);
        onCloseWrapper();
      }}
      // override default 'menuitem' role since we wrap this in li when disabled + tooltip
      role={disabledWithTooltip ? '' : undefined}
      {...getDOMProps(rest)}
      {...getDOMProps(restItem)}
      onMouseEnter={hasSubMenu ? onOpenMenu : undefined}
      onMouseLeave={hasSubMenu ? onCloseMenu : undefined}
      ref={ref}
    >
      {(() => {
        const option = (
          <StyledU21Spacer horizontal>
            <U21TypographyIcon color={color} variant="body2">
              {icon}
            </U21TypographyIcon>
            <OptionContainer>
              <U21Typography
                ellipsis
                color={color}
                variant="body2"
                {...typographyProps}
              >
                {text}
              </U21Typography>
              <DescriptionText color="text.secondary" variant="body2">
                {description}
              </DescriptionText>
            </OptionContainer>
            {rightIcon && <OptionRightIcon>{rightIcon}</OptionRightIcon>}
            {hasSubMenu && <StyledIconChevronRight $color={color} />}
          </StyledU21Spacer>
        );
        if (to && !disabled) {
          return (
            <MenuItemContent as={Link} to={to}>
              {option}
            </MenuItemContent>
          );
        }
        if (href && !disabled) {
          return (
            <MenuItemContent
              as="a"
              href={href}
              rel="noreferrer"
              target="_blank"
            >
              {option}
            </MenuItemContent>
          );
        }
        return <MenuItemContent>{option}</MenuItemContent>;
      })()}
    </StyledMenuItem>
  );

  return (
    <>
      {tooltip ? (
        <U21Tooltip tooltip={tooltip} {...tooltipProps}>
          {disabled ? (
            <li aria-disabled="true" role="menuitem">
              {menuItem}
            </li>
          ) : (
            menuItem
          )}
        </U21Tooltip>
      ) : (
        menuItem
      )}
      {hasSubMenu && (
        <StyledMenu
          anchorEl={ref.current}
          anchorOrigin={{
            vertical: -8,
            horizontal: getPopoverXCoordinate(ref.current, alignRight),
          }}
          autoFocus={menuAutoFocus}
          hideBackdrop
          disableAutoFocus
          disableEnforceFocus
          MenuListProps={{
            onMouseEnter: onOpenMenu,
            onMouseLeave: onCloseMenu,
          }}
          open={open}
          transformOrigin={{
            vertical: 'top',
            horizontal: alignRight ? 'right' : 'left',
          }}
          variant="menu"
        >
          {children.map((i) => {
            if (isValidElement<any>(i)) {
              return i;
            }
            return (
              <U21MenuItem
                key={i.key || (i.text as string)}
                alignRight={alignRight}
                item={i}
                onClose={onCloseWrapper}
              />
            );
          })}
        </StyledMenu>
      )}
    </>
  );
};

const StyledMenu = styled(Menu)`
  pointer-events: none;

  .MuiList-root {
    padding: 8px;
    pointer-events: auto;
  }
`;

// component should be configured by mui but for some reason tsc is not recognizing it
const StyledMenuItem = styled(MenuItem)<{ component?: ElementType }>`
  padding: 0;
  border-radius: 8px;
`;

const MenuItemContent = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  width: 100%;
  min-height: 46px;
`;

const StyledU21Spacer = styled(U21Spacer)`
  width: 100%;
`;

const OptionContainer = styled.div`
  flex: 1;
  overflow: hidden;
`;

const DescriptionText = styled(U21Typography)`
  // overwrite menuitem white-space: nowrap
  white-space: normal;
`;

const OptionRightIcon = styled.div`
  display: flex;
  margin-left: 16px;
`;

const StyledIconChevronRight = styled(IconChevronRight)<{ $color?: string }>`
  ${(props) => {
    const { $color, theme } = props;
    return (
      $color &&
      css`
        color: ${theme.palette[$color].main};
      `
    );
  }}
`;
