// Saga
import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Api
import { retrieveSarList } from 'app/shared/api/sars';

// Models
import { SarActionTypes } from 'app/modules/fincenSar/models';

// Actions
import {
  retrieveSarList as retrieveSarListAction,
  retrieveSarListSuccess,
} from 'app/modules/fincenSar/actions';

const rootAction = SarActionTypes.RETRIEVE_SAR_LIST;
function* retrieveSarListFlow({
  payload,
}: ReturnType<typeof retrieveSarListAction>) {
  const config = {
    rootAction,
    request: call(retrieveSarList, payload),
    success: function* onSuccess(
      result: ReturnType<typeof retrieveSarListSuccess>['payload'],
    ) {
      yield put(retrieveSarListSuccess(result));
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watchRetrieveSarList() {
  yield takeLatest(rootAction, retrieveSarListFlow);
}
