import { OFFSET_DEFAULT } from 'app/shared/constants';

import { rulesSearch } from 'app/modules/search/actions';
import { selectHasReadRulesPermission } from 'app/modules/session/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useRef } from 'react';

import { FilterMoreDisplay } from 'app/modules/filters/components/FilterMoreDisplay';

interface Props {
  ids: number[];
}

export const RuleFilterDisplay = (props: Props) => {
  const { ids } = props;
  const dispatch = useDispatch();
  // using search/selectors caused an import cycle
  const rules = useSelector((state: RootState) => state.search.rules.data);
  const hasReadRulesPermission = useSelector(selectHasReadRulesPermission);

  const rulesMap = useMemo(
    () => rules.reduce((acc, i) => ({ ...acc, [i.id]: i }), {}),
    [rules],
  );

  // calledRef is used to prevent an infinite loop in case the API never returns with the missing value
  const calledRef = useRef(false);
  useEffect(() => {
    calledRef.current = false;
  }, [ids]);
  useEffect(() => {
    if (hasReadRulesPermission) {
      const missingValues = ids.filter((i) => !rulesMap[i]);
      if (missingValues.length && !calledRef.current) {
        calledRef.current = true;
        dispatch(
          rulesSearch({
            ids: missingValues,
            limit: missingValues.length,
            offset: OFFSET_DEFAULT,
            phrase: '',
          }),
        );
      }
    }
  }, [dispatch, hasReadRulesPermission, ids, rulesMap]);

  const values = useMemo(
    () => ids.map((i) => rulesMap[i]?.title || i),
    [ids, rulesMap],
  );

  return (
    <>
      <b>Rule</b> is one of <FilterMoreDisplay values={values} />
    </>
  );
};
