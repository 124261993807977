import { useQuery } from '@tanstack/react-query';
import { post } from 'app/shared/utils/fetchr';
import { LIST_QUERY_KEYS } from 'app/modules/lists/queries/keys';
import { useSelector } from 'react-redux';
import { selectHasReadMatchlistsPermission } from 'app/modules/session/selectors';
import { ListMatchlistsPayload } from 'app/modules/lists/requests';
import { RetrieveBlacklistsResponse } from 'app/modules/lists/responses';

export const useFetchMatchlists = (body: ListMatchlistsPayload) => {
  const hasReadMatchlistsPermission = useSelector(
    selectHasReadMatchlistsPermission,
  );
  return useQuery<RetrieveBlacklistsResponse>({
    enabled: hasReadMatchlistsPermission && body.limit > 0,
    queryKey: LIST_QUERY_KEYS.getFetchMatchlistsQueryKey(body),
    queryFn: () => post('/blacklists/list', body),
  });
};
