import { call, takeLatest, put } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { WorkflowActionTypes } from 'app/modules/workflows/models';

// Actions
import {
  retrieveWorkflows as retrieveWorkflowsAction,
  retrieveWorkflowsSuccess,
} from 'app/modules/workflows/actions';

// API
import { retrieveWorkflows as api } from 'app/shared/api/workflows';

const rootAction = WorkflowActionTypes.RETRIEVE_WORKFLOWS;
function* retrieveWorkflows({
  payload,
}: ReturnType<typeof retrieveWorkflowsAction>) {
  const config = {
    rootAction,
    request: call(api, payload),
    success: function* onSuccess(result) {
      yield put(retrieveWorkflowsSuccess(result));
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watchRetrieveWorkflows() {
  yield takeLatest(rootAction, retrieveWorkflows);
}
