import {
  DataSettingFieldType,
  DataSettingsKeyType,
  FormattedDataCensorshipStatus,
  FormattedDataItem,
  OrgDataSettingsConfig,
  OrgDataSettingsConfigResponse,
  Unit21DataClassifier,
} from 'app/modules/dataSettings/responses';
import { AsyncStatus } from 'app/shared/types/utils/asyncStatus';
import { Filter } from 'app/modules/filters/models';
import { DATA_SETTING_FILTER_KEYS } from 'app/modules/dataSettings/filters';
import { FILTER_OPERATOR } from 'app/modules/filters/constants';
import { isEmpty } from 'lodash';
import objectIsEmpty from 'app/shared/utils/objectIsEmpty';
import {
  HUMAN_READABLE_CUSTOM_DATA_FIELD_TYPE,
  MAX_ALLOWED_ENUMS,
  REFERENCE_TYPES,
} from 'app/modules/dataSettings/constants';
import {
  U21MultiSelectProps,
  U21SelectOptionProps,
  createU21FilterOptions,
} from 'app/shared/u21-ui/components';

export const keyPathToLabel = (
  ds: OrgDataSettingsConfig,
  ignoreFriendlyName: boolean = false,
  joinWith: string = ' ► ',
) => {
  const { user_facing_label: friendlyName, key_type: keyType } = ds;
  let field = '';
  if (friendlyName && !ignoreFriendlyName) {
    field = friendlyName;
  } else if (keyType === DataSettingsKeyType.CUSTOM && ds.key_path.length) {
    field = ds.key_path.join(joinWith);
  } else if (keyType === DataSettingsKeyType.NATIVE) {
    field = ds.native_field;
  }
  return field;
};

// you should make sure data is not undefined before calling this func
export const getFormattedValue = (data: FormattedDataItem) => {
  const {
    is_parseable: isParsable,
    formatted_value: formattedValue,
    raw_value: rawValue,
  } = data;
  return isParsable ? formattedValue : rawValue;
};

export const isFieldEmpty = (data?: FormattedDataItem): boolean => {
  if (isEmpty(data)) {
    return true;
  }
  if (data.censorship !== FormattedDataCensorshipStatus.UNCENSORED) {
    return false;
  }
  return objectIsEmpty(getFormattedValue(data));
};

export const filterDataSettings = (
  data: AsyncStatus<OrgDataSettingsConfigResponse>,
  filters: Filter[],
): OrgDataSettingsConfig[] => {
  if (data.status !== 'COMPLETE') {
    return [];
  }
  return filters.reduce((acc, i) => {
    const { key, operator, value } = i;
    switch (key) {
      case DATA_SETTING_FILTER_KEYS.DATA_TYPE: {
        if (operator === FILTER_OPERATOR.IS_ONE_OF) {
          const valueSet = new Set(value);
          return acc.filter((j) => valueSet.has(j.data_type));
        }
        if (operator === FILTER_OPERATOR.IS_NOT_ONE_OF) {
          const valueSet = new Set(value);
          return acc.filter((j) => !valueSet.has(j.data_type));
        }
        break;
      }

      case DATA_SETTING_FILTER_KEYS.FIELD_TYPE: {
        if (operator === FILTER_OPERATOR.IS) {
          return acc.filter((j) => j.key_type === value);
        }
        break;
      }

      case DATA_SETTING_FILTER_KEYS.NAME: {
        if (operator === FILTER_OPERATOR.CONTAINS_TEXT) {
          const lowercaseValue = value.toLowerCase();
          return acc.filter(
            (j) =>
              j.user_facing_label?.toLowerCase().includes(lowercaseValue) ||
              (j.key_type === DataSettingsKeyType.NATIVE &&
                j.native_field.toLowerCase().includes(lowercaseValue)) ||
              (j.key_type === DataSettingsKeyType.CUSTOM &&
                j.key_path.some((p) =>
                  p.toLowerCase().includes(lowercaseValue),
                )),
          );
        }
        break;
      }

      case DATA_SETTING_FILTER_KEYS.OBJECT_TYPE: {
        if (operator === FILTER_OPERATOR.IS_ONE_OF) {
          const valueSet = new Set(value);
          return acc.filter((j) => valueSet.has(j.unit21_data_classifier));
        }
        if (operator === FILTER_OPERATOR.IS_NOT_ONE_OF) {
          const valueSet = new Set(value);
          return acc.filter((j) => !valueSet.has(j.unit21_data_classifier));
        }
        break;
      }
      default:
        break;
    }
    return acc;
  }, data.data);
};

export const enumDataSettingCustomFilterOptions: U21MultiSelectProps<string>['filterOptions'] =
  (allOptions, state) => {
    // if user hasn't typed anything, then just render the current enum options
    const filteredOptions = createU21FilterOptions<string>(allOptions, state);
    if (!state.inputValue) {
      return filteredOptions;
    }

    // but if user typed something, and total options is less than 250, then add to the filteredOptions
    const value = state.inputValue.trim();
    const optionsSet = new Set(filteredOptions.map(({ value: v }) => v));

    if (
      value &&
      !optionsSet.has(value) &&
      allOptions.length < MAX_ALLOWED_ENUMS
    ) {
      filteredOptions.push({
        value,
        text: `Add value: ${value}`,
      });
    }

    return filteredOptions;
  };

export const getDataSettingDataTypeOptions = (classifier) =>
  Object.entries(HUMAN_READABLE_CUSTOM_DATA_FIELD_TYPE).reduce<
    U21SelectOptionProps[]
  >((acc, [value, text]: [DataSettingFieldType, string]) => {
    // skip 'ANY' data type
    if (value === DataSettingFieldType.ANY) {
      return acc;
    }
    // reference type data settings cannot be used on all primary objects
    if (REFERENCE_TYPES.has(value)) {
      // events can have both reference data settings
      if (classifier === Unit21DataClassifier.EVENT) {
        acc.push({
          value,
          text,
          description: `For multi-${
            value === DataSettingFieldType.ENTITY_REFERENCE
              ? 'entity'
              : 'instrument'
          } transactions`,
        });
      }
      // instruments can have only instrument reference data settings
      if (
        classifier === Unit21DataClassifier.INSTRUMENT &&
        value === DataSettingFieldType.INSTRUMENT_REFERENCE
      ) {
        acc.push({
          value,
          text,
          description: 'For instrument relationships',
        });
      }
      return acc;
    }
    // all other data types can be used on all primary objects
    acc.push({
      value,
      text,
    });
    return acc;
  }, []);

export const validateNonEmpty = (value?: string) =>
  value && value.length === 0 ? 'Key path is required' : undefined;
