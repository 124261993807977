import { FC, HTMLProps, ReactNode } from 'react';

import { getDOMProps } from 'app/shared/utils/react';
import styled from 'styled-components';

import { U21Divider } from 'app/shared/u21-ui/components/display/U21Divider';
import { U21Spacer } from 'app/shared/u21-ui/components/layout/U21Spacer';

export interface U21ModalFooterProps extends HTMLProps<HTMLDivElement> {
  children: ReactNode;
  leftAction?: ReactNode;
}

export const U21ModalFooter: FC<U21ModalFooterProps> = ({
  children,
  leftAction,
  ...rest
}) => {
  return (
    <Container>
      <U21Divider horizontal />
      <Content $leftAction={Boolean(leftAction)} horizontal>
        {leftAction && <U21Spacer>{leftAction}</U21Spacer>}
        <U21Spacer horizontal {...getDOMProps(rest)}>
          {children}
        </U21Spacer>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  position: sticky;
  bottom: 0;
  background-color: ${(props) => props.theme.palette.background.paper};
`;

const Content = styled(U21Spacer)<{ $leftAction?: boolean }>`
  padding: 24px;

  justify-content: ${(props) =>
    props.$leftAction ? 'space-between' : 'flex-end'};
`;
