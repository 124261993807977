export const fromUpperToSentenceCase = (str: string) => {
  if (!str) {
    return str;
  }

  return str
    .toLowerCase()
    .split('_')
    .map((s, i) => (i === 0 ? s[0].toUpperCase() + s.slice(1) : s))
    .join(' ');
};

export const toSentenceCase = (s: string | undefined | null): string => {
  if (!s || s.length === 0) return '';
  const lowercaseS = s.toLowerCase();
  return lowercaseS[0].toUpperCase() + lowercaseS.substring(1, s.length);
};
