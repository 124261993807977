// U21 Thunk
import { u21CreateSlice } from 'app/shared/thunk/u21CreateSlice';
import { u21CreateAsyncThunk } from 'app/shared/thunk/u21CreateAsyncThunk';

// Models
import { RetrieveSarListPayload } from 'app/modules/fincenSar/models';
import {
  RequeueSarPayload,
  ReassignSarPayload,
} from 'app/modules/fincenSar/types/requests';

// Api
import { reassignSarApi, requeueSarApi } from 'app/modules/fincenSar/api';

// Actions
import { sendSuccessToast, sendErrorToast } from 'app/shared/toasts/actions';
import { reassignSarSuccess } from 'app/modules/fincenSar/actions';

const SARS_NAME = 'fincenSars';

interface SarsState {
  loadingRequeueSar: boolean;
  loadingReassignSar: boolean;
}

export const sarsInitialState: Readonly<SarsState> = {
  loadingRequeueSar: false,
  loadingReassignSar: false,
};

const requeueSarThunkName = `${SARS_NAME}/REQUEUE_SARS`;
export const requeueSarThunk = u21CreateAsyncThunk<
  RequeueSarPayload,
  RetrieveSarListPayload
>(requeueSarThunkName, async (payload, { dispatch }) => {
  try {
    const response = await requeueSarApi(payload);
    dispatch(sendSuccessToast('Successfully requeued selected SAR filings.'));
    return response;
  } catch (e) {
    dispatch(sendErrorToast('Unable to requeue SAR filings.'));
    throw e;
  }
});

const reassignSarThunkName = `${SARS_NAME}/REASSIGN_SARS`;
export const reassignSarThunk = u21CreateAsyncThunk<
  ReassignSarPayload,
  RetrieveSarListPayload
>(reassignSarThunkName, async (payload, { dispatch }) => {
  try {
    const response = await reassignSarApi(payload);
    dispatch(sendSuccessToast('Successfully reassigned selected filings.'));
    dispatch(reassignSarSuccess(response));
    return response;
  } catch (e) {
    dispatch(sendErrorToast('Unable to re-assign SAR filings'));
    throw e;
  }
});

const sarsSlice = u21CreateSlice({
  initialState: sarsInitialState,
  name: SARS_NAME,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addLoadingCase(requeueSarThunk, 'loadingRequeueSar')
      .addLoadingCase(reassignSarThunk, 'loadingReassignSar');
  },
});

export const SARS_SLICE_NAME = sarsSlice.name;
export default sarsSlice.reducer;
