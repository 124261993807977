import {
  StartOrgSwitcherRequest,
  EndOrgSwitcherRequest,
} from 'app/modules/orgSwitcher/types';

// Utils
import { get, post } from 'app/shared/utils/fetchr';
import {
  OrgSwitcherResponse,
  StartOrgSwitcherResponse,
} from 'app/modules/orgSwitcher/types/responses';

const API_BASE = '/org_switcher';

export const startOrgSwitcher = (
  payload: StartOrgSwitcherRequest,
): Promise<StartOrgSwitcherResponse> => post(`${API_BASE}/start`, payload);

export const endOrgSwitcher = (
  payload: EndOrgSwitcherRequest,
): Promise<Record<string, never>> => post(`${API_BASE}/end`, payload);

export const getOrgSwitcherStatus = (): Promise<OrgSwitcherResponse> =>
  get(`${API_BASE}/status`);
