// Models
import { AgentDetails } from 'app/modules/agentsOld/models';
import { StorageResource } from 'app/modules/orgSettings/responses';

export enum UploadsActionTypes {
  UPLOAD_DATAFILE_ASYNC = '@@uploads/UPLOAD_DATAFILE_ASYNC',
  UPLOAD_DATAFILE_ASYNC_SUCCESS = '@@uploads/UPLOAD_DATAFILE_ASYNC_SUCCESS',
  UPLOAD_DATAFILE_ASYNC_ERROR = '@@uploads/UPLOAD_DATAFILE_ASYNC_ERROR',

  RETRIEVE_DATAFILES = '@@uploads/RETRIEVE_DATAFILES',
  RETRIEVE_DATAFILES_SUCCESS = '@@uploads/RETRIEVE_DATAFILES_SUCCESS',

  DELETE_DATAFILE = '@@uploads/DELETE_DATAFILE',
  DELETE_DATAFILE_SUCCESS = '@@uploads/DELETE_DATAFILE_SUCCESS',

  SET_FILES_TO_UPLOAD = '@@uploads/SET_FILES_TO_UPLOAD',
  SET_FILES_TO_UPLOAD_ASYNC = '@@uploads/SET_FILES_TO_UPLOAD_ASYNC',

  SET_FILE_UPLOAD_PROGRESS = '@@uploads/SET_FILE_UPLOAD_PROGRESS',

  PROCESS_DATAFILE = '@@uploads/PROCESS_DATAFILE',
  PROCESS_DATAFILE_SUCCESS = '@@uploads/PROCESS_DATAFILE_SUCCESS',
}

export interface GoogleDriveDoc {
  pendingUpload?: boolean | null;
  uploadFailed?: boolean | null;
  description?: string;
  driveSuccess?: boolean;
  path?: string;
  embedUrl: string;
  iconUrl: string;
  id: string;
  lastEditedUtc: number;
  mimeType: string;
  name: string;
  parentId?: string;
  serviceId?: string;
  sizeBytes?: number;
  type?: string;
  url: string;
  parentUrl: string | null;
  isExternalDoc: boolean;
  size?: number;
  storageResource?: string;
  lastModified?: number;
}

export interface Upload {
  id: number;
  created_at: string | null;
  url: string;
  org_id?: number;
  org_name?: string;
  unit_id?: number;
  uploaded_by?: AgentDetails;
  status: UploadStatus;
  original_file_name: string;
  custom_data?: JSONObject;
  status_message?: string;
  file_size?: number;
  percent?: number;
  xhr?: any;
}

export enum UploadStatus {
  AWAITING_TRIGGER = 'AWAITING_TRIGGER',
  VALIDATION_PENDING = 'VALIDATION_PENDING',
  VALIDATION_FAILED = 'VALIDATION_FAILED',
  PROCESS_PENDING = 'PROCESS_PENDING',
  PROCESS_SUCCESS = 'PROCESS_SUCCESS',
  PROCESS_FAILED = 'PROCESS_FAILED',
  PROCESS_ABORTED = 'PROCESS_ABORTED',
  OTHER_FAILED = 'OTHER_FAILED',
  UPLOADING = 'UPLOADING',
  ERROR_SAME_CONTENT = 'ERROR_SAME_CONTENT',
}

export interface FileToUpload {
  status: string;
  name: string;
  path?: string;
  size: number;
  errorMessage?: string;
  binary?: File;
}

export interface DatafilesListResponse {
  count: number;
  unprocessed_count: number;
  files: Upload[];
}

export interface UploadState {
  count: number;
  unprocessedCount: number;
  uploads: Upload[];
  filesToUploadAsync: Upload[];
  filesToUpload: FileToUpload[];
}

export interface RetrieveFilesPayload {
  offset: number;
  limit: number;
  agent_ids?: number[];
  statuses?: string[];
  start_date?: string;
  end_date?: string;
  sort_column?: string;
  sort_direction?: string;
}

export interface UploadDatafile {
  file: File;
}
export interface UploadFileProgress {
  file: File;
  percent: number;
  total: number;
  loaded: number;
  xhr?: any;
}

export interface Attachment {
  id: number;
  created_at: string | null;
  created_by_id: number;
  mime_type: string;
  name: string;
  description: string;
  url: string;
  embed_url: string;
  media_type: string;
  storage_resource: StorageResource;
  custom_data?: Record<string, never>; // TODO
  is_external_doc: boolean;
  icon_url: string;
}

export interface UploadFilesPayload {
  id: string;
  files: File[];
}

export interface RemoveDocumentFromCaseResponse {
  attachments: Attachment[];
  id: number;
  // It means that the attachment might have been removed from the article but it is still linked to other objects, we surface this warning
  linked_objects_count?: Record<RawArticleType, number>;
}
export type RemoveDocumentFromAlertResponse = RemoveDocumentFromCaseResponse;
