import { FC, HTMLProps, ReactNode } from 'react';

import { SCALING_FACTOR } from 'vendor/material-minimal/spacing';

import { getDOMProps } from 'app/shared/utils/react';
import styled, { css } from 'styled-components';

export interface U21GridProps extends HTMLProps<HTMLDivElement> {
  children: ReactNode;
  columns?: number;
  minWidth?: number;
  spacing?: number;
}

export const U21Grid: FC<U21GridProps> = ({
  children,
  columns = 2,
  minWidth = 200,
  spacing = 1,
  ...rest
}) => {
  return (
    <Container
      $columns={columns}
      $minWidth={minWidth}
      $spacing={spacing}
      {...getDOMProps(rest)}
    >
      {children}
    </Container>
  );
};

export interface GridStyleProps {
  $columns: number;
  $minWidth: number;
  $spacing: number;
}

export const GRID_STYLE = css<GridStyleProps>`
  display: grid;
  gap: ${(props) => props.theme.spacing(props.$spacing)};
  grid-template-columns: ${(props) => {
    const { $columns, $minWidth, $spacing } = props;
    const gap = $spacing * SCALING_FACTOR;
    const minSpaceNeededOrMaxAvailable = `min(100%, ${$minWidth}px)`;
    const totalWidthNeeded = `(100% - (${$columns} - 1) * ${gap}px)`;
    const widthNeededPerColumn = `${totalWidthNeeded} / ${$columns}`;
    return `repeat(auto-fill, minmax(max(${minSpaceNeededOrMaxAvailable}, ${widthNeededPerColumn}), 1fr))`;
  }};
  width: 100%;
`;

const Container = styled.div<GridStyleProps>`
  ${GRID_STYLE}
`;
