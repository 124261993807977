// Saga
import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { InsightsActionTypes } from 'app/modules/insights/models';

// Api
import { editDashboard as editDashboardApi } from 'app/shared/api/insights';

// Actions
import {
  editDashboard as editDashboardAction,
  editDashboardSuccess,
  editDashboardChartsLayout,
  deleteChartSuccess,
  retrieveCharts,
} from 'app/modules/insights/actions';
import { sendSuccessToast } from 'app/shared/toasts/actions';

// Helpers
import { createNewChartsLayout } from 'app/modules/insights/helpers';

const rootAction = InsightsActionTypes.EDIT_DASHBOARD;
function* editDashboard({ payload }: ReturnType<typeof editDashboardAction>) {
  // remove retrieveNewCharts boolean so it is not sent to the BE
  const { retrieveNewCharts, ...details } = payload;
  const config = {
    rootAction,
    request: call(editDashboardApi, details),
    errorToastMessage: 'Something went wrong, unable to save dashboard.',
    success: function* onSuccess(
      response: ReturnType<typeof editDashboardSuccess>['payload'],
    ) {
      const result = { ...response };
      // create the new charts_layout if new charts have been created
      if (payload.chart_definitions) {
        const newLayout = createNewChartsLayout(
          result.charts_layout,
          result.charts,
        );
        result.charts_layout = newLayout;
        const editChartsLayoutPayload = {
          id: result.id,
          charts_layout: newLayout,
        };
        yield put(editDashboardChartsLayout(editChartsLayoutPayload));
      }

      // more than just title / description updated, retrieve all charts with new data
      if (retrieveNewCharts) {
        yield put(retrieveCharts());
      }

      yield put(
        sendSuccessToast(`Dashboard ${result.title} successfully edited`),
      );
      yield put(editDashboardSuccess(result));
    },
  };
  if (payload.chart_to_delete !== undefined) {
    yield put(deleteChartSuccess(payload.chart_to_delete));
  }
  yield call(makeSagaRequest, config);
}

export default function* watchEditDashboard() {
  yield takeLatest(rootAction, editDashboard);
}
