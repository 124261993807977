// APIs
import {
  retrieveDetectionModelExecutions,
  retrieveDetectionModelGeneratedAlerts,
  retrieveDetectionModelNewMetric,
} from 'app/modules/detectionModels/api';

// Models
import { PaginationPayload } from 'app/shared/pagination/models';
import { PaginatedAlerts } from 'app/modules/rules/models';
import {
  DetectionModelExecutionResponse,
  DetectionModelNewMetricResponse,
} from 'app/modules/detectionModels/types/responses';
import { DetectionModelsNewMetricPayload } from 'app/modules/detectionModels/types/requests';
import { MetricNameEnum } from 'app/modules/detectionModels/constants';

// Actions
import { sendErrorToast } from 'app/shared/toasts/actions';

// Utils
import { u21CreateAsyncThunk } from 'app/shared/thunk/u21CreateAsyncThunk';
import { u21CreateSlice } from 'app/shared/thunk/u21CreateSlice';

interface DetectionModelDetailsState {
  loadingDetectionModelExecutions: boolean;
  loadingDetectionModelGeneratedAlerts: boolean;
  detectionModelExecutions: DetectionModelExecutionResponse | null;
  detectionModelGeneratedAlerts: PaginatedAlerts;
}

const DETECTION_MODEL_DETAILS = 'detection-model-details';
export const initialState: DetectionModelDetailsState = {
  loadingDetectionModelExecutions: false,
  loadingDetectionModelGeneratedAlerts: false,
  detectionModelExecutions: null,
  detectionModelGeneratedAlerts: {
    alerts: [],
    count: 0,
  },
};

export const retrieveDetectionModelExecutionsThunk = u21CreateAsyncThunk<
  { ruleId: number } & PaginationPayload,
  DetectionModelExecutionResponse
>(
  `${DETECTION_MODEL_DETAILS}/RETRIEVE_DETECTION_MODEL_EXECUTIONS`,
  async (
    { ruleId, ...payload }: { ruleId: number } & PaginationPayload,
    { dispatch },
  ) => {
    try {
      return await retrieveDetectionModelExecutions(ruleId, payload);
    } catch (e) {
      dispatch(
        sendErrorToast(`Unable to retrieve executions for rule #${ruleId}`),
      );
      throw e;
    }
  },
);

export const retrieveDetectionModelGeneratedAlertsThunk = u21CreateAsyncThunk<
  { id: number } & PaginationPayload,
  PaginatedAlerts
>(
  `${DETECTION_MODEL_DETAILS}/DETECTION_MODEL_GENERATED_ALERT`,
  async (payload: { id: number } & PaginationPayload, { dispatch }) => {
    try {
      return await retrieveDetectionModelGeneratedAlerts(payload);
    } catch (e) {
      dispatch(sendErrorToast('Unable to retrieve generated alerts.'));
      throw e;
    }
  },
);

export const retrieveDetectionModelMetricThunk = u21CreateAsyncThunk<
  {
    ruleId: number;
    metricName: MetricNameEnum;
  } & DetectionModelsNewMetricPayload,
  DetectionModelNewMetricResponse
>(
  `${DETECTION_MODEL_DETAILS}/RETRIEVE_DETECTION_MODEL_NEW_METRIC`,
  async ({ ruleId, metricName, ...payload }, { dispatch }) => {
    try {
      return await retrieveDetectionModelNewMetric(ruleId, metricName, payload);
    } catch (e) {
      dispatch(sendErrorToast(`Unable to retrieve ${metricName} metric`));
      throw e;
    }
  },
);

const detectionModelDetailsSlice = u21CreateSlice({
  name: DETECTION_MODEL_DETAILS,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // retrieve dm executions
      .addLoadingCase(
        retrieveDetectionModelExecutionsThunk,
        'loadingDetectionModelExecutions',
        (draft, { payload }) => {
          draft.detectionModelExecutions = payload;
        },
      )
      // retrieve detection model generated alerts
      .addLoadingCase(
        retrieveDetectionModelGeneratedAlertsThunk,
        'loadingDetectionModelGeneratedAlerts',
        (draft, { payload }) => {
          draft.detectionModelGeneratedAlerts = payload;
        },
      );
  },
});

export const DETECTION_MODEL_DETAILS_SLICE_NAME =
  detectionModelDetailsSlice.name;
export default detectionModelDetailsSlice.reducer;
