import { call, takeLatest, put } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { WebhookActionTypes } from 'app/modules/webhooks/models';

// Actions
import { retrieveActionTypesSuccess } from 'app/modules/webhooks/actions';

// API
import { retrieveActionTypes as retrieveActionTypesApi } from 'app/shared/api/webhooks';

const rootAction = WebhookActionTypes.RETRIEVE_ACTION_TYPE;
function* retrieveActionType() {
  const config = {
    rootAction,
    request: call(retrieveActionTypesApi),
    success: function* onSuccess(
      result: ReturnType<typeof retrieveActionTypesSuccess>['payload'],
    ) {
      yield put(retrieveActionTypesSuccess(result));
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* retrieveActionTypes() {
  yield takeLatest(rootAction, retrieveActionType);
}
