// Saga
import { call, put, takeLatest, select } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { InsightsActionTypes } from 'app/modules/insights/models';

// Api
import { deleteDashboard as deleteDashboardApi } from 'app/shared/api/insights';

// Actions
import {
  deleteDashboard as deleteDashboardAction,
  deleteDashboardSuccess,
  retrieveDashboard,
  clearDashboard,
} from 'app/modules/insights/actions';
import { sendSuccessToast } from 'app/shared/toasts/actions';

// Selector
import { selectFirstDashboardsId } from 'app/modules/insights/selectors';

const rootAction = InsightsActionTypes.DELETE_DASHBOARD;
function* deleteDashboard({
  payload,
}: ReturnType<typeof deleteDashboardAction>) {
  const config = {
    rootAction,
    request: call(deleteDashboardApi, payload),
    errorToastMessage: `Something went wrong, unable to delete dashboard.`,
    success: function* onSuccess() {
      yield put(sendSuccessToast(`Dashboard successfully deleted`));
      yield put(deleteDashboardSuccess(payload));
      const firstDashboardId = yield select(selectFirstDashboardsId);
      // if there is still a dashboard that exists, retrieve it and replace the current dashboard
      if (firstDashboardId) {
        yield put(retrieveDashboard(firstDashboardId));
      } else {
        yield put(clearDashboard());
      }
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watchDeleteDashboard() {
  yield takeLatest(rootAction, deleteDashboard);
}
