import { LocalStorageKeys } from 'app/shared/constants/localStorage';
import { addDays } from 'date-fns';

import { store } from 'app/store/configureStore';
import { selectFeedbackSurveyID } from 'app/shared/featureFlags/selectors';

export const setupIntercom = (settings: IntercomSettings) => {
  window.initIntercom(settings);
};

const shouldTriggerInterceptSurvey = (): boolean => {
  const timestamp = localStorage.getItem(
    LocalStorageKeys.LAST_SURVEY_TIMESTAMP,
  );
  if (!timestamp) {
    return true;
  }

  const date = new Date(timestamp);

  // ignore invalid timestamps
  if (isNaN(date.getTime())) {
    return true;
  }

  // trigger survey if > 7 days from last survey
  return new Date() >= addDays(date, 7);
};

export const triggerInterceptSurvey = (
  surveyID: SURVEY,
  isSurveyAlwaysShown: boolean = false,
) => {
  if (isSurveyAlwaysShown || shouldTriggerInterceptSurvey()) {
    window.Intercom?.('startSurvey', surveyID);

    if (!isSurveyAlwaysShown) {
      localStorage.setItem(
        LocalStorageKeys.LAST_SURVEY_TIMESTAMP,
        new Date().toISOString(),
      );
    }
  }
};

export const triggerFeedbackSurvey = () => {
  const surveyID = selectFeedbackSurveyID(store.getState());
  window.Intercom?.('startSurvey', surveyID);
};

export enum SURVEY {
  ASK_YOUR_DATA = 40582242,
  AI_INVESTIGATION_RESPONSE = 41898609,
}
