import { createSelector } from 'reselect';

// Models
import { QueueType } from 'app/modules/queues/models';
import { QueueSubtypes } from 'app/modules/alerts/models';
import {
  selectHasCreateCasePermission,
  selectHasReadAlertsPermission,
  selectHasReadCasesPermissions,
  selectHasReadSARsPermissions,
} from 'app/modules/session/selectors';

// redefined here to prevent an import cycle
// this should match app/modules/queues/sliceQueues
const QUEUES_SLICE_NAME = 'queues';

export const selectQueue = (state: RootState) => state[QUEUES_SLICE_NAME].queue;

export const selectQueues = (queueType: QueueType, subtype?: QueueSubtypes) =>
  createSelector(
    selectAlertQueues,
    selectCaseQueues,
    selectSarQueues,
    (alertQueues, caseQueues, sarQueues) => {
      switch (queueType) {
        case QueueType.ALERT_QUEUE:
          // If `subtypes` is not undefined or empty, filter out
          // alert queues that are not of the given `subtypes`
          if (subtype) {
            return alertQueues.filter(
              (queue) => queue.subtype === subtype || queue.subtype === null,
            );
          }
          return alertQueues;
        case QueueType.CASE_QUEUE:
          return caseQueues;
        case QueueType.SAR_QUEUE:
        default:
          return sarQueues;
      }
    },
  );

const selectAlertQueues = (state: RootState) =>
  state[QUEUES_SLICE_NAME].alertQueues;

const selectCaseQueues = (state: RootState) =>
  state[QUEUES_SLICE_NAME].caseQueues;

const selectSarQueues = (state: RootState) =>
  state[QUEUES_SLICE_NAME].sarQueues;

export const selectQueuesCount = (queueType: QueueType) =>
  createSelector(
    selectAlertQueuesCount,
    selectCaseQueuesCount,
    selectSarQueuesCount,
    (alertQueuesCount, caseQueuesCount, sarQueuesCount) => {
      switch (queueType) {
        case QueueType.ALERT_QUEUE:
          return alertQueuesCount;
        case QueueType.CASE_QUEUE:
          return caseQueuesCount;
        case QueueType.SAR_QUEUE:
        default:
          return sarQueuesCount;
      }
    },
  );

const selectAlertQueuesCount = (state: RootState) =>
  state[QUEUES_SLICE_NAME].alertQueuesCount;

const selectCaseQueuesCount = (state: RootState) =>
  state[QUEUES_SLICE_NAME].caseQueuesCount;

const selectSarQueuesCount = (state: RootState) =>
  state[QUEUES_SLICE_NAME].sarQueuesCount;

export const selectQueuedArticlesCount = (state: RootState) =>
  state[QUEUES_SLICE_NAME].queuedArticlesCount;

export const selectLoadingQueue = (state: RootState) =>
  state[QUEUES_SLICE_NAME].loadingQueue;

export const selectLoadingQueues = (state: RootState) =>
  state[QUEUES_SLICE_NAME].loadingQueues;

export const selectLoadingEditDeleteQueue = (state: RootState) =>
  state[QUEUES_SLICE_NAME].loadingEditDeleteQueue;

export const selectDeadlineSummary = (state: RootState) =>
  state[QUEUES_SLICE_NAME].queueDeadlineSummary.queues;

export const selectDeadlineSummaryLoading = (state: RootState) =>
  state[QUEUES_SLICE_NAME].loadingQueueDeadlineSummary;

export const selectAssociatedAgents = (state: RootState) =>
  state[QUEUES_SLICE_NAME].associatedAgents;

export const selectAlertQueueAlertFilters = (state: RootState) =>
  state[QUEUES_SLICE_NAME].alertQueueAlertFilters;

export const selectCaseQueueCaseFilters = (state: RootState) =>
  state[QUEUES_SLICE_NAME].caseQueueCaseFilters;

export const selectFilingQueueFilingFilters = (state: RootState) =>
  state[QUEUES_SLICE_NAME].filingQueueFilingFilters;

export const selectHasReadQueuePermission = createSelector(
  [
    selectHasReadAlertsPermission,
    selectHasCreateCasePermission,
    selectHasReadCasesPermissions,
    selectHasReadSARsPermissions,
    (_, queueType: QueueType) => queueType,
  ],
  (
    hasReadAlertsPermission,
    hasCreateCasePermission,
    hasReadCasePermission,
    hasReadFilingsPermission,
    queueType,
  ) => {
    switch (queueType) {
      case QueueType.ALERT_QUEUE:
        return hasReadAlertsPermission;
      case QueueType.CASE_QUEUE:
        return hasCreateCasePermission || hasReadCasePermission;
      case QueueType.SAR_QUEUE:
        return hasReadFilingsPermission;
      default:
        return false;
    }
  },
);
