import { createSelector } from 'reselect';

// Selectors
import { selectSessionAgentAllowedTags } from 'app/modules/session/selectors';

// Utils
import getFullTagName from 'app/shared/utils/getFullTagName';

// Models
import { DropdownItemProps } from 'semantic-ui-react';
import { TeamActionTypes, Tag, TagsById } from 'app/modules/teamsOld/models';

export function selectAllTags(
  state: RootState,
  filterAllowedTags = false,
): TagsById {
  const allTags = state.team.tags;
  if (filterAllowedTags) {
    const allowedTags: Tag[] = selectSessionAgentAllowedTags(state);
    return allowedTags.reduce((tags, allowedTag) => {
      if (Object.prototype.hasOwnProperty.call(allTags, allowedTag.id)) {
        return { ...tags, [allowedTag.id]: allowedTag };
      }
      return tags;
    }, {});
  }
  return allTags || {};
}

export const selectApiConfigAllowedEndpoints = (state: RootState) => {
  return state.team.api_config?.allowed_endpoints || [];
};

export const selectFormattedTags = createSelector(selectAllTags, (tags) => {
  // TODO use allowedTags ?
  const formattedTags: DropdownItemProps[] = Object.values(tags).map((tag) => {
    const fullTag = getFullTagName(tag.type, tag.name);
    return {
      key: tag.id,
      value: tag.id,
      text: fullTag,
      icon: 'tags',
    };
  });
  return formattedTags;
});

export const selectFormattedTagTypes = createSelector(selectAllTags, (tags) => {
  // get unique types
  const uniqueTypes = new Set(Object.values(tags).map((tag) => tag.type));
  const formattedTags: DropdownItemProps[] = [];
  uniqueTypes.forEach((type) => {
    const formattedTag = {
      key: `${type}-type`,
      value: type,
      text: type,
      icon: 'tags',
    };
    formattedTags.push(formattedTag);
  });
  return formattedTags;
});

export const selectFormattedTagNames = createSelector(selectAllTags, (tags) => {
  // get unique names
  const uniqueNames = new Set(Object.values(tags).map((tag) => tag.name));
  const formattedTags: DropdownItemProps[] = [];
  uniqueNames.forEach((name) => {
    const formattedTag = {
      key: `${name}-name`,
      value: name,
      text: name,
      icon: 'tags',
    };
    formattedTags.push(formattedTag);
  });
  return formattedTags;
});

export const selectAddedTag = (
  state: RootState,
  tagName: string,
): Tag | undefined => {
  return state.team.addedTags?.[tagName];
};

export const selectTagLimitReached = (state: RootState) =>
  state.team.tagLimitReached;

// Loading
export const selectAllTagsLoading = (state: RootState) =>
  Boolean(state.loading[TeamActionTypes.RETRIEVE_ALL_TAGS]);

export const selectAddTagPending = (state: RootState) =>
  Boolean(state.loading[TeamActionTypes.ADD_TAG]);
