import {
  DATA_MAPPING_PREVIEW_OPTIONS,
  DATA_MAPPING_SELECTION_OPTIONS,
  DIRECTIONALITY_TYPES,
  TRANSFORMATION_TYPE_OPTIONS,
  U21_DATA_MAPPING_SCHEMA_KEYS,
  U21_REQUIRED_TYPE_TO_ANNOTATIONS,
  U21_TYPE_TO_ANNOTATIONS,
  VALUE_BASED_DIRECTIONALITY_VALUES,
} from 'app/modules/dataMapping/constants';
import {
  Directionality,
  DirectionaltyConfiguration,
  Relationship,
  SingletonTransformation,
  Transformation,
  TypeHandling,
  ValueBasedDirectionality,
  ValueSelection,
  PrefixTransformation,
  PostfixTransformation,
  FindReplaceTransformation,
  RemoveEscapeSequenceTransformation,
  MathTransformation,
  DatetimeTimezoneTransformation,
  AnnotationCondition,
  LegacyDatetimeTransformation,
  LegacyTimezoneTransformation,
  JsonLoadsTransformation,
} from 'app/modules/dataMapping/responses';
import { PullBasedDataFilesPreview } from 'app/modules/pullBasedDataFiles/responses';
import { RelationshipConfigResponse } from 'app/modules/relationships/types';
import { AsyncStatus } from 'app/shared/types/utils/asyncStatus';

export interface TopLevelModalProps {
  type: 'none' | 'newObject' | 'rowFiltering' | 'relationships';
}
export interface AnnotationModalProps {
  type: 'customAnnotation' | 'transformations' | 'aggregation';
  objectName: string;
  row: DataMappingRow;
}

export interface ModalPropsWithObjectName {
  type: 'directionality' | 'deleteObject';
  objectName: string;
}

export type ModalProps =
  | TopLevelModalProps
  | AnnotationModalProps
  | ModalPropsWithObjectName;

export interface DataMappingState {
  initialServerAnnotationConfig: AsyncStatus<{
    config: DataMappingSchema | null;
  }>;
  updateServerAnnotationStatus: AsyncStatus | null;
  localAnnotationConfig: DataMappingSchema | null;
  filePreview: AsyncStatus<{ preview: PullBasedDataFilesPreview }>;
  selectedAnnotation: SelectedAnnotation | null;
  selectedColumn: string | null;
  modalProps: ModalProps;
  dataMappingPreview: DataMappingPreviewType;
  mappingRowToCondition?:
    | { objectName: string; row: DataMappingRow }
    | undefined;
}

export enum PrimaryObject {
  TRANSACTION = 'txn_event',
  ACTION = 'action_event',
  ENTITY = 'entity',
  INSTRUMENT = 'instrument',
  ALERT = 'alert',
}

export enum SecondaryObject {
  TRANSACTION = 'txn_event',
  ENTITY = 'entity',
  INSTRUMENT = 'instrument',
  TAG = 'tag',
  ADDRESS = 'address',
  DOCUMENT = 'document',
}

export type NonPrimaryObjects =
  | SecondaryObject.ADDRESS
  | SecondaryObject.TAG
  | SecondaryObject.DOCUMENT;

export type U21Object = PrimaryObject | NonPrimaryObjects;

export interface SelectedAnnotation
  extends Omit<DataMappingRow, 'value' | 'destination'> {
  objectName: string;
}

export type SupportedTransformationTypes =
  (typeof TRANSFORMATION_TYPE_OPTIONS)[number]['value'];

type BaseDraftTransformation<
  T extends SupportedTransformationTypes | '',
  U extends
    | Exclude<
        Transformation,
        LegacyDatetimeTransformation | LegacyTimezoneTransformation
      >
    | DraftValueMapTransformation
    | null,
> = {
  id: string;
  type: T;
  transformation: U;
};

export type DraftValueMapTransformation = {
  function: 'value_map';
  configuration: Record<
    string,
    {
      key: string;
      value: string;
    }
  >;
};

export type DraftTransformation =
  | BaseDraftTransformation<'case_format', SingletonTransformation>
  | BaseDraftTransformation<'prefix', PrefixTransformation>
  | BaseDraftTransformation<'postfix', PostfixTransformation>
  | BaseDraftTransformation<'datetime_timezone', DatetimeTimezoneTransformation>
  | BaseDraftTransformation<'replace_all', FindReplaceTransformation>
  | BaseDraftTransformation<'replace_first', FindReplaceTransformation>
  | BaseDraftTransformation<'value_map', DraftValueMapTransformation>
  | BaseDraftTransformation<'md5_hash', SingletonTransformation>
  | BaseDraftTransformation<'numeric_cast', SingletonTransformation>
  | BaseDraftTransformation<'id_cleanup', SingletonTransformation>
  | BaseDraftTransformation<'json_loads', JsonLoadsTransformation>
  | BaseDraftTransformation<
      'remove_escape_sequence',
      RemoveEscapeSequenceTransformation
    >
  | BaseDraftTransformation<'math', MathTransformation>
  | BaseDraftTransformation<'boolean_cast', SingletonTransformation>
  | BaseDraftTransformation<'', null>;

export type U21DataMappingObjectTypes =
  keyof typeof U21_REQUIRED_TYPE_TO_ANNOTATIONS;

export type DataMappingPreviewType = keyof typeof DATA_MAPPING_PREVIEW_OPTIONS;

export type DataMappingPairTypes = string | AnnotationKeyType;

export interface BaseDataMappingPair<T = string> {
  value: ValueSelection;
  destination: T;
  conditions?: AnnotationCondition[];
}

export type AnnotationKeyType = keyof typeof U21_DATA_MAPPING_SCHEMA_KEYS;

export interface DataMappingObject<T = keyof typeof U21_TYPE_TO_ANNOTATIONS> {
  type: T;
  name: string;
  typed_annotations: BaseDataMappingPair<AnnotationKeyType>[];
  custom_data_annotations: BaseDataMappingPair<string>[];
  directionality?: DirectionaltyConfiguration | null;
}

export interface BaseDataMappingSchema<
  T extends keyof typeof U21_TYPE_TO_ANNOTATIONS,
> {
  objects: Record<string, DataMappingObject<T>>;
  type_handling?: TypeHandling | null;
  relationships?: Relationship[] | null;
  unit21_object: PrimaryObject;
  updating_existing: boolean;
}

export interface EntityDataMappingSchema
  extends BaseDataMappingSchema<'entity'> {}
export interface TransactionDataMappingSchema
  extends BaseDataMappingSchema<'txn_event'> {}
export interface ActionDataMappingSchema
  extends BaseDataMappingSchema<'action_event'> {}
export interface InstrumentDataMappingSchema
  extends BaseDataMappingSchema<'instrument'> {}
export interface AddressDataMappingSchema
  extends BaseDataMappingSchema<'address'> {}
export interface TagDataMappingSchema extends BaseDataMappingSchema<'tag'> {}
export interface AlertDataMappingSchema
  extends BaseDataMappingSchema<'alert'> {}

export type DataMappingSchema =
  | EntityDataMappingSchema
  | TransactionDataMappingSchema
  | ActionDataMappingSchema
  | InstrumentDataMappingSchema
  | AddressDataMappingSchema
  | TagDataMappingSchema
  | AlertDataMappingSchema;

export interface DataMappingRow extends BaseDataMappingPair {
  isCustom: boolean;
  id: number;
}

export interface U21Destination {
  label: string;
  required?: boolean;
  description?: string;
  many?: boolean;
}

export type U21TypeToAnnotation = Record<string, U21Destination>;

export interface ObjectValidation<T = keyof typeof U21_TYPE_TO_ANNOTATIONS>
  extends Omit<
    DataMappingObject<T>,
    'typed_annotations' | 'custom_data_annotations' | 'directionality'
  > {
  unmappedRequiredFields: Array<AnnotationKeyType>;
  missingDirectionality?: boolean;
}

export interface SchemaValidation {
  relationships: Relationship[] | null;
  objects: Record<string, ObjectValidation>;
}

export interface RelationshipModalValues {
  config?: RelationshipConfigResponse;
  relationship?: Partial<Relationship>;
  id: string;
}

export type DirectionalityType =
  (typeof DIRECTIONALITY_TYPES)[keyof typeof DIRECTIONALITY_TYPES];

export type ValueBasedDirectionalityValues =
  (typeof VALUE_BASED_DIRECTIONALITY_VALUES)[keyof typeof VALUE_BASED_DIRECTIONALITY_VALUES];

export interface ConditionLeftRightModel {
  type:
    | typeof DATA_MAPPING_SELECTION_OPTIONS.STREAM_VALUE
    | typeof DATA_MAPPING_SELECTION_OPTIONS.METADATA_VALUE
    | typeof DATA_MAPPING_SELECTION_OPTIONS.HARD_CODED_VALUE;
  value: string;
}
export interface ValueBasedModel
  extends Partial<Omit<ValueBasedDirectionality, 'value'>> {
  key: string;
}

export type DirectionalityModalFormModel =
  | { option?: undefined }
  | {
      option: typeof DIRECTIONALITY_TYPES.HARD_CODED;
      value: Directionality;
    }
  | {
      option?: typeof DIRECTIONALITY_TYPES.CONDITIONAL;
      true_outcome?: Directionality;
      false_outcome?: Directionality;
      operator?: 'lt' | 'le' | 'eq' | 'ne' | 'ge' | 'gt';
      condition: {
        left: ConditionLeftRightModel;
        right: ConditionLeftRightModel;
      };
    }
  | {
      option: typeof DIRECTIONALITY_TYPES.VALUE_BASED;
      value_based: ValueBasedModel;
    };

export interface AnnotationConstant {
  label: string;
  description?: string;
  /**
   * @description
   * Fields required for creating a new object are not necessarily required for updating an existing object. See {@link BaseDataMappingSchema}.updating_existing
   * @remarks
   * ID fields are required for creating and updating objects. Hence all should have `required: 'update'`, wherea, for example, a field like `amount` or `event_time` on a `txn_event` object are only required for creating a new object.
   * @example
   * {
      event_id: {
        requiredFor: 'update',
        label: 'event ID',
      },
      event_time: {
        requiredFor: 'create',
        label: 'timestamp',
        notRequiredFor: [PrimaryObject.ALERT],
      },
      amount: {
        requiredFor: 'create',
        label: 'amount',
        notRequiredFor: [PrimaryObject.ALERT],
      },
    }
   */
  requirementType?: 'create' | 'update';
  /**
   * @description
   * The field can be mapped multiple times.
   */
  many?: boolean;
  /**
   * @description
   * field will not be marked as required if the primary object type is in the `notRequiredFor` array. By default it will be required for all streams if the `required` bool (above) is true
   * @remarks For example, `event_time` and `amount` aren't required for secondary `txn_event` objects on alert streams.
   * @example
   * event_time: {
      requiredFor: 'create',
      label: 'timestamp',
      notRequiredFor: [PrimaryObject.ALERT],
    },
   */
  notRequiredFor?: PrimaryObject[];
}
