import editEntity from 'app/modules/entities/sagas/editEntity';
import retrieveEntity from 'app/modules/entities/sagas/retrieveEntity';
import retrieveEntityPreview from 'app/modules/entities/sagas/retrieveEntityPreview';
import retrieveEntityAlerts from 'app/modules/entities/sagas/retrieveEntityAlerts';
import retrieveEntityLinkAnalysis from 'app/modules/entities/sagas/retrieveEntityLinkAnalysis';
import queueEntityLinkAnalysis from 'app/modules/entities/sagas/queueEntityLinkAnalysis';
import retrieveEntitiesLinkAnalysisMetadata from 'app/modules/entities/sagas/retrieveEntitiesLinkAnalysisMetadata';

const sagaRegistry = [
  editEntity,
  retrieveEntity,
  retrieveEntityPreview,
  retrieveEntityAlerts,
  retrieveEntityLinkAnalysis,
  queueEntityLinkAnalysis,
  retrieveEntitiesLinkAnalysisMetadata,
];

export default sagaRegistry;
