import { U21SelectOptionProps } from 'app/shared/u21-ui/components/input/select/U21Select';
import { EMPTY_COUNT } from 'app/shared/u21-ui/components/display/table/constants';

const PAGE_RANGE = 5;

/**
 * Algorithm is to return the 1st 5 pages, the surrounding 5 pages, and last 5 pages
 * without overlap
 */
export const generatePageOptions = (
  count: number | null,
  page: number,
  pageSize: number,
): U21SelectOptionProps<number>[] => {
  if (count === EMPTY_COUNT) {
    return [...Array(page).keys()].map((i) => ({
      text: i + 1,
      value: i + 1,
    }));
  }
  const pages = Math.ceil(count / pageSize);
  const options: number[] = [];

  const pageLowerBound = Math.max(0, page - PAGE_RANGE - 1);
  const pageUpperBound = Math.min(pages, page + PAGE_RANGE);

  // add surrounding 5 page options
  for (let i = 0; i < Math.min(pageLowerBound, PAGE_RANGE); i++) {
    options.push(i);
  }

  // add beginning 5 page options
  for (let i = pageLowerBound; i < pageUpperBound; i++) {
    options.push(i);
  }

  // add last 5 page options
  for (let i = Math.max(pageUpperBound, pages - PAGE_RANGE); i < pages; i++) {
    options.push(i);
  }

  return options.map((i) => ({ text: i + 1, value: i + 1 }));
};
