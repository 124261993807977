import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { EntitiesActionTypes } from 'app/modules/entities/models';

// Actions
import {
  queueEntityLinkAnalysis as queueAction,
  queueEntityLinkAnalysisSuccess,
} from 'app/modules/entities/actions';

// Constants
import { queueEntityLinkAnalysis as API } from 'app/shared/api/entities';

const rootAction = EntitiesActionTypes.QUEUE_ENTITY_LINK_ANALYSIS;
export function* queueEntityLinkAnalysis({
  payload,
}: ReturnType<typeof queueAction>) {
  const config = {
    rootAction,
    request: call(API, payload),
    success: function* onSuccess(result) {
      yield put(queueEntityLinkAnalysisSuccess(result));
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watchQueueEntityLinkAnalysis() {
  yield takeLatest(rootAction, queueEntityLinkAnalysis);
}
