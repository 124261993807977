import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';
import makeActions from 'app/shared/sagas/make-actions';

// Models
import { RulesActionTypes } from 'app/modules/rules/models';

// Actions
import {
  retrieveRuleValidationAlert as retrieveRuleValidationAlertAction,
  retrieveRuleValidationAlertSuccess,
} from 'app/modules/rules/actions';

// API
import { retrieveRuleValidationAlert as retrieveRuleValidationAlertApi } from 'app/shared/api/rules';

// Helpers
import { combineRootActionWithId } from 'app/shared/helpers';

const rootAction = RulesActionTypes.RETRIEVE_RULE_VALIDATION_ALERT;
function* retrieveRuleValidationAlert({
  payload,
}: ReturnType<typeof retrieveRuleValidationAlertAction>) {
  // to keep track of individual alerts loading
  const formattedRootAction = combineRootActionWithId(rootAction, payload);
  const formattedActions = makeActions(formattedRootAction);

  const config = {
    rootAction: formattedRootAction,
    request: call(retrieveRuleValidationAlertApi, payload),
    success: function* onSuccess(
      result: ReturnType<typeof retrieveRuleValidationAlertSuccess>['payload'],
    ) {
      yield put(retrieveRuleValidationAlertSuccess(result));
      // to cancel individual loading state
      yield put(formattedActions.success({}));
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watchRetrieveValidationRuleAlert() {
  yield takeLatest(rootAction, retrieveRuleValidationAlert);
}
