import {
  createFilterOptions,
  FilterOptionsState,
} from '@mui/base/useAutocomplete';
import { isEqual } from 'lodash';

import { ColorSchema } from 'vendor/material-minimal/palette';
import { CSSProperties, SyntheticEvent } from 'react';
import { SelectOptionItemProps } from 'app/shared/u21-ui/components/input/select/SelectOptionItem';
import {
  U21FlattenedSelectOptionProps,
  U21SelectNestedMenuProps,
  U21SelectOptionProps,
  U21SelectValue,
} from 'app/shared/u21-ui/components/input/select/U21Select';

interface Options {
  backdrop?: boolean;
  expand?: boolean;
  width?: CSSProperties['width'];
}

export const generatePopperStyleProp = (options: Options): CSSProperties => {
  const { backdrop, expand, width } = options;
  const style: CSSProperties = {
    width,
  };
  if (backdrop) {
    // must be greater than backdrop
    // number chosen based on https://mui.com/material-ui/customization/z-index
    style.zIndex = 1400;
  }
  if (expand) {
    style.width = 'fit-content';
  }
  return style;
};

export const getDescriptionColor = (color?: ColorSchema) => {
  if (color === 'warning') {
    return 'warning.dark';
  }
  return color || 'text.secondary';
};

export const createU21FilterOptions = <TValue extends U21SelectValue>(
  options: (U21FlattenedSelectOptionProps<TValue> | U21SelectNestedMenuProps)[],
  state: FilterOptionsState<
    U21FlattenedSelectOptionProps<TValue> | U21SelectNestedMenuProps
  >,
): (
  | U21FlattenedSelectOptionProps<TValue>
  | U21SelectNestedMenuProps
  // allow U21SelectOptionProps to make it easier for devs to use
  // since extra U21FlattenedSelectOptionProps data is internal-only
  | U21SelectOptionProps<TValue>
)[] => {
  return createFilterOptions<
    U21FlattenedSelectOptionProps<TValue> | U21SelectNestedMenuProps
  >({
    stringify: (option) => {
      if (!option.optionType) {
        return option.searchTerms.join('|');
      }
      if (typeof option.item.text === 'string') {
        return option.item.text;
      }
      return '';
    },
  })(options, state);
};

export const flattenOption = <TValue extends U21SelectValue>(
  option: U21SelectOptionProps<TValue>,
): U21FlattenedSelectOptionProps<TValue>[] => {
  const { children, value } = option;
  const searchTerms = [String(option.text)];
  if (option.description) {
    searchTerms.push(option.description);
  }
  if (children) {
    const childrenOptions = flattenOptionsRecursor<TValue>(children, option);
    return [
      {
        ...option,
        children: injectSearchAndValues<TValue>(children),
        isNestedOption: false,
        searchTerms: getUniqueChildrenSearchTerms<TValue>(
          childrenOptions,
          searchTerms,
        ),
        values: getUniqueChildrenValues<TValue>(childrenOptions),
      },
      ...childrenOptions,
    ];
  }
  return [
    {
      ...option,
      isNestedOption: false,
      searchTerms,
      values: [value],
    },
  ];
};

const flattenOptionsRecursor = <TValue extends U21SelectValue>(
  options: U21SelectOptionProps<TValue>[],
  parentOption: U21SelectOptionProps<TValue>,
): U21FlattenedSelectOptionProps<TValue>[] => {
  return options.reduce<U21FlattenedSelectOptionProps<TValue>[]>((acc, i) => {
    const text = `${parentOption.text} / ${i.text}`;
    const searchTerms = [String(text)];
    if (i.description) {
      searchTerms.push(i.description);
    }
    if (i.children) {
      const childrenOptions = flattenOptionsRecursor<TValue>(i.children, {
        ...i,
        text,
      });
      return [
        ...acc,
        {
          ...i,
          children: injectSearchAndValues<TValue>(i.children),
          isNestedOption: true,
          text,
          searchTerms: getUniqueChildrenSearchTerms<TValue>(
            childrenOptions,
            searchTerms,
          ),
          values: getUniqueChildrenValues<TValue>(childrenOptions),
        },
        ...childrenOptions,
      ];
    }
    return [
      ...acc,
      {
        ...i,
        isNestedOption: true,
        text,
        searchTerms,
        values: [i.value],
      },
    ];
  }, []);
};

const injectSearchAndValues = <TValue extends U21SelectValue>(
  options: U21SelectOptionProps<TValue>[],
): U21FlattenedSelectOptionProps<TValue>[] => {
  return options.map<U21FlattenedSelectOptionProps<TValue>>((i) => {
    const searchTerms = [String(i.text)];
    if (i.description) {
      searchTerms.push(i.description);
    }
    if (i.children) {
      const updatedChildren = injectSearchAndValues<TValue>(i.children);
      return {
        ...i,
        children: updatedChildren,
        searchTerms: getUniqueChildrenSearchTerms<TValue>(
          updatedChildren,
          searchTerms,
        ),
        values: getUniqueChildrenValues<TValue>(updatedChildren),
      };
    }
    return {
      ...i,
      values: [i.value],
      searchTerms,
    };
  });
};

const getUniqueChildrenValues = <TValue extends U21SelectValue>(
  options: U21FlattenedSelectOptionProps<TValue>[],
): TValue[] => {
  const values = new Set(options.flatMap((j) => j.values));
  return [...values];
};

const getUniqueChildrenSearchTerms = <TValue extends U21SelectValue>(
  options: U21FlattenedSelectOptionProps<TValue>[],
  additional: string[] = [],
): string[] => {
  const values = new Set([
    ...additional,
    ...options.flatMap((j) => {
      const searchTerms = [String(j.text)];
      if (j.description) {
        searchTerms.push(j.description);
      }
      return searchTerms;
    }),
  ]);
  return [...values];
};

export const createSelectOptionItemProps = <TValue extends U21SelectValue>(
  option: U21FlattenedSelectOptionProps<TValue>,
  onChange: (
    option: U21FlattenedSelectOptionProps<TValue>,
    e: SyntheticEvent,
  ) => void,
  selectedValue: U21FlattenedSelectOptionProps<TValue> | null,
): SelectOptionItemProps => {
  const {
    children,
    color,
    description,
    disabled,
    tooltip,
    icon,
    text,
    value: optionValue,
    values,
  } = option;
  return {
    autoOpen: true,
    checkmark: true,
    color,
    description,
    disabled,
    onClick: optionValue !== undefined ? (e) => onChange(option, e) : undefined,
    children: children
      ? children.map((i) =>
          createSelectOptionItemProps<TValue>(i, onChange, selectedValue),
        )
      : undefined,
    icon,
    selected: selectedValue
      ? values.some((i) => isEqual(selectedValue.value, i))
      : false,
    tooltip,
    text,
  };
};

export const createMultiSelectOptionItemProps = <TValue extends U21SelectValue>(
  option: U21FlattenedSelectOptionProps<TValue>,
  onChange: (
    option: U21FlattenedSelectOptionProps<TValue>[],
    e: SyntheticEvent,
  ) => void,
  selectedValues: U21FlattenedSelectOptionProps<TValue>[],
): SelectOptionItemProps => {
  const {
    children,
    color,
    description,
    disabled,
    tooltip,
    icon,
    text,
    value: optionValue,
    values,
    required,
  } = option;
  const selected = selectedValues.length
    ? values.some((i) => selectedValues.some((j) => isEqual(i, j.value)))
    : false;
  return {
    autoOpen: false,
    checkbox: true,
    color,
    description,
    disabled,
    onClick:
      optionValue !== undefined
        ? (e) => {
            const newSelectedValues = selected
              ? selectedValues.filter((i) => i.value !== optionValue)
              : [...selectedValues, option];
            onChange(newSelectedValues, e);
          }
        : undefined,
    children: children
      ? children.map((i) =>
          createMultiSelectOptionItemProps<TValue>(i, onChange, selectedValues),
        )
      : undefined,
    icon,
    indeterminate:
      selected &&
      !values.every((i) => selectedValues.some((j) => isEqual(i, j.value))),
    required,
    selected,
    tooltip,
    text,
  };
};
