// Saga
import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';
import makeActions from 'app/shared/sagas/make-actions';

// Models
import { InsightsActionTypes } from 'app/modules/insights/models';

// Api
import { editChart as editChartApi } from 'app/shared/api/insights';

// Actions
import {
  editChart as editChartAction,
  editChartSuccess,
} from 'app/modules/insights/actions';
import { sendSuccessToast } from 'app/shared/toasts/actions';

// Helpers
import { combineRootActionWithId } from 'app/shared/helpers';

const rootAction = InsightsActionTypes.EDIT_CHART;
function* editChart({ payload }: ReturnType<typeof editChartAction>) {
  const formattedRootAction = combineRootActionWithId(
    rootAction,
    `${payload.id}`,
  );
  const formattedActions = makeActions(formattedRootAction);
  const config = {
    rootAction: formattedRootAction,
    request: call(editChartApi, payload),
    errorToastMessage: `Something went wrong, unable to edit chart.`,
    success: function* onSuccess(
      result: ReturnType<typeof editChartSuccess>['payload'],
    ) {
      yield put(sendSuccessToast(`Chart ${result.title} successfully edited`));
      yield put(editChartSuccess(result));
      // to cancel individual loading state
      yield put(formattedActions.success({}));
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watchEditDashboard() {
  yield takeLatest(rootAction, editChart);
}
