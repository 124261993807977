import { PayloadAction } from '@reduxjs/toolkit';
import { u21CreateSlice } from 'app/shared/thunk/u21CreateSlice';
import { u21CreateAsyncThunk } from 'app/shared/thunk/u21CreateAsyncThunk';

// Models
import { APIKey } from 'app/modules/apiKeys/responses';

// Api
import {
  getAPIKeys,
  updateAPIKeyPermissions,
  createAPIKey,
  deactivateAPIKey,
} from 'app/shared/api/settings';
import { sendErrorToast, sendSuccessToast } from 'app/shared/toasts/actions';
import { NewApiKeyPayload } from 'app/modules/apiKeys/request';

const API_KEYS_NAME = 'apiKeys';

interface ApiKeysState {
  loadingApiKeys: boolean;
  loadingCreateApiKey: boolean;
  loadingDeactivateApiKey: boolean;
  apiKeys: APIKey[];
  latestApiKey: string;
}

const initialState: Readonly<ApiKeysState> = {
  loadingApiKeys: false,
  loadingCreateApiKey: false,
  loadingDeactivateApiKey: false,
  apiKeys: [],
  latestApiKey: '',
};

export const retrieveApiKeysThunk = u21CreateAsyncThunk(
  `${API_KEYS_NAME}/GET_API_KEYS`,
  getAPIKeys,
);

export const createApiKeysThunk = u21CreateAsyncThunk(
  `${API_KEYS_NAME}/CREATE_API_KEYS`,
  async (payload: NewApiKeyPayload, { dispatch }) => {
    try {
      const initialKey: APIKey = await createAPIKey();
      const response = await updateAPIKeyPermissions({
        ...payload,
        key_prefix: initialKey.key_prefix,
      });
      dispatch(apiKeysSlice.actions.updateLatestApiKey(initialKey.api_key));
      dispatch(sendSuccessToast('Successfully created API Key.'));
      return response;
    } catch (e) {
      dispatch(sendErrorToast('Error creating API Key.'));
      throw e;
    }
  },
);

export const deactivateApiKeyThunk = u21CreateAsyncThunk(
  `${API_KEYS_NAME}/DEACTIVATE_API_KEY`,
  async (id: number, { dispatch }) => {
    try {
      await deactivateAPIKey(String(id));
      dispatch(sendSuccessToast('Successfully deactivated API key.'));
      return id;
    } catch (e) {
      let errorToastMessage = 'Failed to deactivate API key.';
      try {
        const { message } = await e.json();
        if (message) {
          errorToastMessage = message;
        }
      } catch {}
      dispatch(sendErrorToast(errorToastMessage));
      throw e;
    }
  },
);

const apiKeysSlice = u21CreateSlice({
  name: API_KEYS_NAME,
  initialState,
  reducers: {
    resetLatestApiKey: (draft) => {
      draft.latestApiKey = '';
    },
    updateLatestApiKey: (draft, action) => {
      draft.latestApiKey = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addLoadingCase(
        retrieveApiKeysThunk,
        'loadingApiKeys',
        (draft, action: PayloadAction<APIKey[]>) => {
          draft.apiKeys = action.payload;
        },
      )
      .addLoadingCase(
        createApiKeysThunk,
        'loadingCreateApiKey',
        (draft, action: PayloadAction<APIKey>) => {
          draft.apiKeys = [...draft.apiKeys, action.payload];
        },
      )
      .addLoadingCase(
        deactivateApiKeyThunk,
        'loadingDeactivateApiKey',
        (draft, action: PayloadAction<number>) => {
          draft.apiKeys = draft.apiKeys.filter((i) => i.id !== action.payload);
        },
      );
  },
});

export const API_KEYS_SLICE_NAME = apiKeysSlice.name;
export const { resetLatestApiKey } = apiKeysSlice.actions;
export default apiKeysSlice.reducer;
