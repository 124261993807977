import { u21CreateAsyncThunk } from 'app/shared/thunk/u21CreateAsyncThunk';
import { u21CreateSlice } from 'app/shared/thunk/u21CreateSlice';
import wait from 'app/shared/utils/wait';
import { cloneDeep } from 'lodash';
import pluralize from 'pluralize';

import { ReportType } from 'app/modules/fincenSar/models';
import { EditCtrFiling } from 'app/modules/fincenCtr/requests';

// Actions
import { sendErrorToast, sendSuccessToast } from 'app/shared/toasts/actions';

// API
import {
  createFincenCtrBatch,
  createFincenCtrFiling,
  getFincenCtrFiling,
  updateFincenCtrFiling,
  exportFincenCtrBatch,
  downloadCtrBatch,
  getFincenCtrTemplates,
  lockFincenCtrFiling,
  unlockFincenCtrFiling,
  uploadAttachments,
  editCtrFiling,
  deleteAttachment,
} from 'app/modules/fincenCtr/api';

// Models
import { FincenCtrContent, Activity } from 'app/modules/fincenCtr/models';
import {
  FincenCtrFilingDetails,
  CreateFincenCtrBatchResponse,
  ExportFincenCtrBatchResponse,
  PaginatedTransactionLocationsResponse,
  UploadAttachmentsResponse,
  DeleteAttachmentsResponse,
} from 'app/modules/fincenCtr/responses';
import { InvestigationType } from 'app/modules/investigations/models';
import { selectFiling } from 'app/modules/fincenCtr/selectors';
import { PayloadAction } from '@reduxjs/toolkit';
import { EditAttachmentPayloadWithID } from 'app/modules/attachmentsRefresh/models';
import { CtrFilingStatus } from 'app/modules/fincenCtr/enums';
import { downloadBlobFromResponse } from 'app/shared/utils/apiUtils';
import { ArticleDeadline } from 'app/modules/deadlines/models';

const SLICE_NAME = 'fincenCtr';

// #region STATE DECLARATIONS

interface FincenCtrLoadingState {
  loadingFiling: boolean;
  loadingCreateFiling: boolean;
  loadingUpdateFiling: boolean;
  loadingEditFiling: boolean;
  loadingLockFiling: boolean;
  loadingUnlockFiling: boolean;
  loadingUploadAttachments: boolean;
  loadingDeleteAttachments: boolean;
}

interface FincenCtrState extends FincenCtrLoadingState {
  filing: FincenCtrFilingDetails;
  transactionLocations: PaginatedTransactionLocationsResponse;
}

const EMPTY_FINCEN_CTR_CONTENT: FincenCtrContent = {
  form: {},
  version: 1.0,
  errors: [],
};

export const EMPTY_FILING: FincenCtrFilingDetails = {
  org_id: -1,
  first_alert_created_at: '',
  deadline: null,
  deadline_config: null,
  name: '',
  description: '',
  id: -1,
  created_at: '',
  filing_type: 'Initial report',
  status: CtrFilingStatus.PENDING,
  updated_at: '',
  attachments: [],
  cases: [],
  alerts: [],
  entities: [],
  events: [],
  lock: false,
  comments_count: 0,
  comments: [],
  report_type: ReportType.FINCEN_CTR,
  created_by: {
    full_name: '',
  },
  error_msg: '',
  queue: null,
  content: cloneDeep(EMPTY_FINCEN_CTR_CONTENT),
  activity_type: [],
  entity_region: [],
  is_encrypted: false,
};

export const INITIAL_STATE: FincenCtrState = {
  filing: EMPTY_FILING,
  transactionLocations: {
    results: [],
    count: 0,
  },
  // Loading
  loadingFiling: false,
  loadingCreateFiling: false,
  loadingUpdateFiling: false,
  loadingEditFiling: false,
  loadingLockFiling: false,
  loadingUnlockFiling: false,
  loadingUploadAttachments: false,
  loadingDeleteAttachments: false,
};

// #endregion

// #region THUNKS

export const createFincenCtrFilingThunk = u21CreateAsyncThunk<
  | {
      articleId?: number;
      articleType?: InvestigationType;
      actionTriggerId?: number;
    }
  | undefined,
  FincenCtrFilingDetails
>(`${SLICE_NAME}/CREATE_CTR`, async (payload, { dispatch }) => {
  const { articleId, articleType, actionTriggerId } = payload || {};
  try {
    const response = await createFincenCtrFiling(
      articleId,
      articleType,
      actionTriggerId,
    );
    dispatch(sendSuccessToast('New filing created successfully.'));
    return response;
  } catch (e) {
    dispatch(sendErrorToast('Failed to create new filing.'));
    throw e;
  }
});

export const getFincenCtrFilingThunk = u21CreateAsyncThunk<
  string,
  FincenCtrFilingDetails
>(`${SLICE_NAME}/GET_CTR`, async (id, { dispatch }) => {
  try {
    return await getFincenCtrFiling(id);
  } catch (e) {
    dispatch(sendErrorToast('Failed to get filing.'));
    throw e;
  }
});

export const updateFincenCtrFilingThunk = u21CreateAsyncThunk<
  {
    id: string;
    formData: DeepPartial<Activity>;
  },
  FincenCtrFilingDetails
>(`${SLICE_NAME}/UPDATE_CTR`, async ({ id, formData }, { dispatch }) => {
  try {
    const response = await updateFincenCtrFiling(id, formData);
    dispatch(sendSuccessToast('Filing updated successfully.'));
    return response;
  } catch (e) {
    dispatch(sendErrorToast('Failed to update filing.'));
    throw e;
  }
});

export const editCtrFilingThunk = u21CreateAsyncThunk<
  {
    id: number;
    payload: EditCtrFiling;
  },
  FincenCtrFilingDetails
>(`${SLICE_NAME}/EDIT_CTR`, async ({ id, payload }, { dispatch }) => {
  try {
    const response = await editCtrFiling(id, payload);
    dispatch(sendSuccessToast('Filing edited successfully.'));
    return response;
  } catch (e) {
    dispatch(sendErrorToast('Failed to edit filing.'));
    throw e;
  }
});

export const createFincenCtrBatchThunk = u21CreateAsyncThunk<
  { ctrIds: number[] },
  CreateFincenCtrBatchResponse & { ctrIds: number[] }
>(`${SLICE_NAME}/CREATE_CTR_BATCH`, async ({ ctrIds }, { dispatch }) => {
  try {
    const response = await createFincenCtrBatch(ctrIds);
    dispatch(exportFincenCtrBatchThunk(response.id));

    dispatch(
      sendSuccessToast(
        'CTR batch created successfully. Downloading in the background.',
      ),
    );
    return { ...response, ctrIds };
  } catch (e) {
    dispatch(sendErrorToast('Failed to create CTR batch.'));
    throw e;
  }
});

export const exportFincenCtrBatchThunk = u21CreateAsyncThunk<
  number,
  ExportFincenCtrBatchResponse
>(`${SLICE_NAME}/EXPORT_CTR_BATCH`, async (ctrBatchId, { dispatch }) => {
  const response = await exportFincenCtrBatch(ctrBatchId);
  await dispatch(downloadFincenCtrBatchXmlThunk(ctrBatchId));
  return response;
});

export const downloadFincenCtrBatchXmlThunk = u21CreateAsyncThunk<number, null>(
  `${SLICE_NAME}/DOWNLOAD_CTR_BATCH_XML`,
  async (ctrBatchId, { dispatch }) => {
    const waitForCtrToExport = async (retry: number = 0): Promise<boolean> => {
      // 63 seconds
      if (retry > 5) {
        const message = `Failed to download CTR Batch ${ctrBatchId}.`;
        dispatch(sendErrorToast(message));
        throw new Error(message);
      }
      const response = await downloadCtrBatch(ctrBatchId);
      if (response.status === 202) {
        await wait(2 ** retry * 1000);
        return waitForCtrToExport(retry + 1);
      } else if (response.status === 201) {
        await downloadBlobFromResponse(response, `CtrBatch${ctrBatchId}.xml`);
        return true;
      }
      throw new Error('Unexpeceted status from download. Stopping');
    };
    await waitForCtrToExport();
    dispatch(sendSuccessToast('CTR batch exported successfully.'));
    return null;
  },
);

export const getTransactionLocationsThunk = u21CreateAsyncThunk<
  void,
  PaginatedTransactionLocationsResponse
>(`${SLICE_NAME}/GET_TRANSACTION_LOCATIONS`, async (_, { dispatch }) => {
  try {
    // TODO instead of calling templates endpoint create one just for the locations with pagination and filtering
    const response = await getFincenCtrTemplates();
    const locations = response.transaction_locations?.content?.locations || [];

    return {
      results: locations || [],
      count: locations?.length || 0,
    };
  } catch (e) {
    dispatch(sendErrorToast('Failed to retrieve transaction locations.'));
    throw e;
  }
});

export const lockFincenCtrFilingThunk = u21CreateAsyncThunk<
  {
    id: number;
    formData: DeepPartial<Activity>;
  },
  FincenCtrFilingDetails
>(`${SLICE_NAME}/LOCK_CTR`, async ({ id, formData }, { dispatch }) => {
  try {
    const response = await lockFincenCtrFiling(id, formData);
    // The endpoint can return a success but still leave the filling locked because it has errors
    if (response.lock) {
      dispatch(sendSuccessToast('Filing locked successfully.'));
    } else {
      dispatch(sendErrorToast('Failed to lock, please fix the form errors.'));
    }
    return response;
  } catch (e) {
    dispatch(
      sendErrorToast('Failed to lock filing, please fix the form errors.'),
    );
    throw e;
  }
});

export const unlockFincenCtrFilingThunk = u21CreateAsyncThunk<
  number,
  FincenCtrFilingDetails
>(`${SLICE_NAME}/UNLOCK_CTR`, async (id, { dispatch }) => {
  try {
    const response = await unlockFincenCtrFiling(id);
    dispatch(sendSuccessToast('Filing unlocked successfully.'));
    return response;
  } catch (e) {
    dispatch(sendErrorToast('Failed to unlock filing.'));
    throw e;
  }
});

export const uploadAttachmentsThunk = u21CreateAsyncThunk<
  File[],
  UploadAttachmentsResponse
>(`${SLICE_NAME}/UPLOAD_ATTACHMENTS`, async (files, { dispatch, getState }) => {
  const filing = selectFiling(getState());
  try {
    const response = await uploadAttachments(filing.id, files);
    dispatch(
      sendSuccessToast(
        `Successfully uploaded ${files.length} ${pluralize(
          'file',
          files.length,
        )}.`,
      ),
    );
    return response;
  } catch (e) {
    dispatch(sendErrorToast('Failed to upload file(s), try again.'));
    throw e;
  }
});

export const deleteAttachmentThunk = u21CreateAsyncThunk<
  { filingId: string; attachmentId: string },
  DeleteAttachmentsResponse
>(
  `${SLICE_NAME}/DELETE_ATTACHMENT`,
  async ({ filingId, attachmentId }, { dispatch }) => {
    try {
      return await deleteAttachment(filingId, attachmentId);
    } catch (e) {
      dispatch(
        sendErrorToast('Failed to delete attachment. Please try again.'),
      );
      throw e;
    }
  },
);

// #endregion

const fincenCtrSlice = u21CreateSlice({
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    updateAttachment: (
      draft,
      action: PayloadAction<EditAttachmentPayloadWithID>,
    ) => {
      const { object_id: drop, ...rest } = action.payload;
      draft.filing.attachments = draft.filing.attachments.map((attachment) => {
        if (rest.id === attachment.id) {
          return { ...attachment, ...rest };
        }
        return attachment;
      });
    },
    updateFiling: (draft, action: PayloadAction<FincenCtrFilingDetails>) => {
      draft.filing = action.payload;
    },
    resetFiling: (draft) => {
      draft.filing = cloneDeep(EMPTY_FILING);
    },
    updateFilingDeadline: (draft, action: PayloadAction<ArticleDeadline>) => {
      draft.filing.deadline = action.payload;
    },
    clearError: (draft, action: PayloadAction<string>) => {
      const existingErrors = draft.filing.content.errors;
      draft.filing.content.errors = existingErrors?.filter(
        (val) => val.path !== action.payload,
      );
    },
  },
  extraReducers: (builder) => {
    builder.addLoadingCase(
      createFincenCtrFilingThunk,
      'loadingCreateFiling',
      (draft, { payload }) => {
        draft.filing = payload;
      },
    );
    builder.addLoadingCase(
      getFincenCtrFilingThunk,
      'loadingFiling',
      (draft, { payload }) => {
        draft.filing = payload;
      },
    );
    builder.addLoadingCase(
      updateFincenCtrFilingThunk,
      'loadingUpdateFiling',
      (draft, { payload }) => {
        draft.filing = payload;
      },
    );
    builder.addLoadingCase(
      editCtrFilingThunk,
      'loadingEditFiling',
      (draft, { payload }) => {
        draft.filing = payload;
      },
    );
    builder.addLoadingCase(
      lockFincenCtrFilingThunk,
      'loadingLockFiling',
      (draft, { payload }) => {
        draft.filing = payload;
      },
    );
    builder.addLoadingCase(
      unlockFincenCtrFilingThunk,
      'loadingUnlockFiling',
      (draft, { payload }) => {
        draft.filing = payload;
      },
    );
    builder.addLoadingCase(
      uploadAttachmentsThunk,
      'loadingUploadAttachments',
      (draft, { payload }) => {
        draft.filing.attachments = payload.attachments;
      },
    );
    builder.addLoadingCase(
      deleteAttachmentThunk,
      'loadingDeleteAttachments',
      (draft, { payload }) => {
        draft.filing.attachments = payload.attachments;
      },
    );
    builder.addCase(
      getTransactionLocationsThunk.fulfilled,
      (draft, { payload }) => {
        draft.transactionLocations = payload;
      },
    );
  },
});

export const FINCEN_CTR_SLICE_NAME = fincenCtrSlice.name;
export const {
  updateAttachment,
  updateFiling,
  updateFilingDeadline,
  resetFiling,
  clearError,
} = fincenCtrSlice.actions;
export default fincenCtrSlice.reducer;
