import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { CommentsActionTypes } from 'app/modules/comments/models';

// Actions
import {
  retrieveComments as retrieveCommentsAction,
  retrieveCommentsSuccess,
} from 'app/modules/comments/actions';

// API
import { getComments } from 'app/shared/api/comments';

const rootAction = CommentsActionTypes.RETRIEVE_COMMENTS;
function* retrieveComments({
  payload,
}: ReturnType<typeof retrieveCommentsAction>) {
  const config = {
    rootAction,
    request: call(getComments, payload),
    success: function* onSuccess(result) {
      yield put(retrieveCommentsSuccess(result));
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watchRetrieveComments() {
  yield takeLatest(CommentsActionTypes.RETRIEVE_COMMENTS, retrieveComments);
}
