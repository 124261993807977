import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { EventActionTypes } from 'app/shared/events/models';

// Actions
import {
  editEvent as editEventAction,
  editEventSuccess,
} from 'app/shared/events/actions';

// API
import { editEvent as editEventApi } from 'app/shared/api/events';

import { queryClient } from 'app/cache/queryClient';
import { TRANSACTION_QUERY_KEYS } from 'app/modules/transactions/queries/keys';

const rootAction = EventActionTypes.EDIT_EVENT;
function* editEvent({ payload }: ReturnType<typeof editEventAction>) {
  const config = {
    rootAction,
    request: call(editEventApi, payload),
    errorToastMessage: 'Something went wrong, unable to edit the event.',
    successToastMessage: 'Event edited successfully',
    success: function* onSuccess(
      result: ReturnType<typeof editEventSuccess>['payload'],
    ) {
      yield put(editEventSuccess(result));
      queryClient.invalidateQueries({
        queryKey: TRANSACTION_QUERY_KEYS.getTransactionById(result.id),
      });
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watchEditEvent() {
  yield takeLatest(rootAction, editEvent);
}
