import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { OrgsActionTypes } from 'app/modules/orgs/models';

// Api
import { retrieveRelevantOrgs as api } from 'app/shared/api/orgs';

// Actions
import { retrieveRelevantOrgsSuccess } from 'app/modules/session/actions';

const rootAction = OrgsActionTypes.RETRIEVE_RELEVANT_ORGS;
function* retrieveRelevantOrgs() {
  const config = {
    rootAction,
    request: call(api),
    success: function* onSuccess(
      result: ReturnType<typeof retrieveRelevantOrgsSuccess>['payload'],
    ) {
      yield put(retrieveRelevantOrgsSuccess(result));
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watch() {
  yield takeLatest(rootAction, retrieveRelevantOrgs);
}
