import { FormFieldSchema } from 'app/shared/models/form';
import { GoogleDriveDoc } from 'app/modules/uploads/models';
import { U21FormFieldTypes } from 'app/shared/u21-ui/components';

// for create view
export const CASE_S3_DOCUMENTS: FormFieldSchema<File[]> = {
  name: 's3Docs',
  default: [],
  type: U21FormFieldTypes.FILE_UPLOADER,
};

export const CASE_GDRIVE_DOCUMENTS: FormFieldSchema<File[]> = {
  name: 'gdriveDocs',
  default: [],
  type: U21FormFieldTypes.FILE_UPLOADER, // not used but needed for typing
};

export const CASE_GDRIVE_PICKED_DOCUMENTS: FormFieldSchema<GoogleDriveDoc[]> = {
  name: 'gdrivePickedDocs',
  default: [],
  type: U21FormFieldTypes.FILE_UPLOADER, // not used but needed for typing
};
