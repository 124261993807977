import { createBrowserHistory } from 'history';

import { U21SavingPrompt } from 'app/shared/u21-ui/components/form/U21SavingPrompt';

/**
 * @deprecated Do not use outside components. In components use `useHistoy` hook instead.
 */
export const history = createBrowserHistory({
  getUserConfirmation: U21SavingPrompt,
});
