// Models
import { DropdownItemProps } from 'semantic-ui-react';
import { SearchActionTypes } from 'app/modules/search/models';
import { U21SelectOptionProps } from 'app/shared/u21-ui/components';

import { ALERT_QUEUE_RULE_STATUSES } from 'app/shared/constants';
import assets from 'app/shared/utils/assets';

import { createSelector } from 'reselect';

import { IconFlag, IconScale, IconUsers } from '@u21/tabler-icons';

export const selectRulesSearchResults = (state: RootState) => {
  return (
    state.search.rules || {
      data: [],
    }
  );
};

export const selectRuleOptions = createSelector(
  selectRulesSearchResults,
  (rules): U21SelectOptionProps[] =>
    rules.data.map((rule) => ({
      icon: <IconScale />,
      text: `#${rule.id} - ${rule.title}`,
      value: rule.id,
    })),
);

export const selectActiveRuleOptions = createSelector(
  selectRulesSearchResults,
  (rules): U21SelectOptionProps[] =>
    rules.data
      .filter((i) => i.status === 'ACTIVE')
      .map((rule) => ({
        icon: <IconScale />,
        text: `#${rule.id} - ${rule.title}`,
        value: rule.id,
      })),
);

export const selectActiveAsyncRuleOptions = createSelector(
  selectRulesSearchResults,
  (rules): U21SelectOptionProps[] =>
    rules.data
      .filter((i) => i.status === 'ACTIVE' && i.is_synchronous !== true)
      .map((rule) => ({
        icon: <IconScale />,
        text: `#${rule.id} - ${rule.title}`,
        value: rule.id,
      })),
);

export const selectActiveRuleOptionsWithQueueName = createSelector(
  selectRulesSearchResults,
  (rules): U21SelectOptionProps[] =>
    rules.data
      .filter((i) => ALERT_QUEUE_RULE_STATUSES.includes(i.status))
      .map((rule) => {
        return {
          icon: <IconScale />,
          text: `#${rule.id} - ${rule.title}`,
          value: rule.id,
          description: rule.alert_queue_title,
          ...(rule.alert_queue_title && { color: 'error' }),
        };
      }),
);

export const selectAlertsSearchResults = (state: RootState) => {
  return (
    state.search.alerts || {
      data: [],
    }
  );
};

export const selectEventsSearchResults = (state: RootState) => {
  return (
    state.search.events || {
      data: [],
    }
  );
};

export const selectEntitiesSearchResults = (state: RootState) => {
  return (
    state.search.entities || {
      data: [],
    }
  );
};

export const selectTeamSearchResults = (state: RootState) => {
  return (
    state.search.teams || {
      data: [],
    }
  );
};

export const selectFormattedTeamSearchResults = createSelector(
  selectTeamSearchResults,
  (teamSearchResults): DropdownItemProps[] => {
    if (teamSearchResults?.data.length > 0) {
      return teamSearchResults.data.map((teamSearchResult) => {
        const { id, name, description } = teamSearchResult;
        const dropdownItem: DropdownItemProps = {
          key: id,
          text: `#${id} - ${name}`,
          value: id,
          icon: assets.icons.team,
          // not used in display, used for searching the dropdown
          description,
        };
        return dropdownItem;
      });
    }
    return [];
  },
);

export const selectTeamOptions = createSelector(
  selectTeamSearchResults,
  (teams): U21SelectOptionProps[] =>
    teams.data.map((i) => ({
      icon: <IconUsers />,
      text: i.name,
      value: i.id,
    })),
);

export const selectAlertOptions = createSelector(
  selectAlertsSearchResults,
  (alerts): U21SelectOptionProps[] =>
    alerts.data.map((i) => ({
      description: i.external_id || undefined,
      icon: <IconFlag />,
      text: `#${i.id} - ${i.title}`,
      value: i.id,
    })),
);

export const selectActionEventsSearchResults = (state: RootState) =>
  state.searchSlice.actionEvents || {
    data: [],
  };

// Loading
export const selectEntitiesSearchLoading = (state: RootState) =>
  Boolean(state.loading[SearchActionTypes.ENTITIES_SEARCH]);

export const selectEventsSearchLoading = (state: RootState) =>
  Boolean(state.loading[SearchActionTypes.EVENTS_SEARCH]);

export const selectTeamsSearchLoading = (state: RootState) =>
  Boolean(state.loading[SearchActionTypes.TEAMS_SEARCH]);

export const selectActionEventsSearchLoading = (state: RootState) =>
  Boolean(state.searchSlice.actionEventsLoading);
