import { EditAttachmentPayloadWithID } from 'app/modules/attachmentsRefresh/models';
import { FullAttachmentResponse } from 'app/modules/attachments/types';
import { PayloadAction } from '@reduxjs/toolkit';
import { RetrieveAttachmentsPayload } from 'app/modules/attachmentsRefresh/requests';
import { RetrieveAttachmentsResponse } from 'app/modules/attachmentsRefresh/responses';

import { DOCUMENT_UPLOAD_LIMIT } from 'app/modules/attachmentsRefresh/constants';

import {
  downloadAttachment,
  retrieveAttachments,
  updateAttachment,
} from 'app/modules/attachmentsRefresh/api';
import { sendErrorToast, sendSuccessToast } from 'app/shared/toasts/actions';
import { u21CreateAsyncThunk } from 'app/shared/thunk/u21CreateAsyncThunk';
import { u21CreateSlice } from 'app/shared/thunk/u21CreateSlice';

// make different from the attachment reducer name
const ATTACHMENTS_NAME = 'attachmentSlice';

interface AttachmentsState {
  attachments: FullAttachmentResponse[];
  loadingAttachments: boolean;
  loadingEditAttachment: boolean;
}

const initialState: AttachmentsState = {
  attachments: [],
  loadingAttachments: false,
  loadingEditAttachment: false,
};

export const retrieveAttachmentsThunk = u21CreateAsyncThunk<
  DistributiveOmit<RetrieveAttachmentsPayload, 'limit' | 'offset'>,
  RetrieveAttachmentsResponse
>(`${ATTACHMENTS_NAME}/RETRIEVE_ATTACHMENTS`, async (payload, { dispatch }) => {
  try {
    return await retrieveAttachments({
      ...payload,
      // hardcode to the document limit
      limit: DOCUMENT_UPLOAD_LIMIT,
      offset: 1,
    });
  } catch (e) {
    dispatch(
      sendErrorToast('Failed to retrieve attachments. Please try again.'),
    );
    throw e;
  }
});

export const downloadAttachmentThunk = u21CreateAsyncThunk<{
  id: number;
  fileName: string;
}>(
  `${ATTACHMENTS_NAME}/DOWNLOAD_ATTACHMENT`,
  async ({ id, fileName }, { dispatch }) => {
    try {
      await downloadAttachment(id, fileName);
    } catch (e) {
      dispatch(
        sendErrorToast('Failed to download attachment. Please try again.'),
      );
      throw e;
    }
  },
);

export const editAttachmentThunk = u21CreateAsyncThunk<
  EditAttachmentPayloadWithID,
  EditAttachmentPayloadWithID
>(`${ATTACHMENTS_NAME}/EDIT_ATTACHMENT`, async (payload, { dispatch }) => {
  try {
    const { id, ...rest } = payload;
    await updateAttachment(id, rest);
    dispatch(sendSuccessToast('Successfully edited attachment.'));
    return payload;
  } catch (e) {
    dispatch(sendErrorToast('Failed to edit attachment. Please try again.'));
    throw e;
  }
});

const attachmentsSlice = u21CreateSlice({
  initialState,
  name: ATTACHMENTS_NAME,
  reducers: {
    addAttachments: (
      draft,
      action: PayloadAction<FullAttachmentResponse[]>,
    ) => {
      draft.attachments = [...draft.attachments, ...action.payload];
    },
    deleteAttachment: (draft, action: PayloadAction<number>) => {
      draft.attachments = draft.attachments.filter(
        (i) => i.id !== action.payload,
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addLoadingCase(
        editAttachmentThunk,
        'loadingEditAttachment',
        (draft, action) => {
          draft.attachments = draft.attachments.map((i) =>
            i.id === action.payload.id
              ? {
                  ...i,
                  description: action.payload.description,
                  name: action.payload.name,
                }
              : i,
          );
        },
      )
      .addLoadingCase(
        retrieveAttachmentsThunk,
        'loadingAttachments',
        (draft, action) => {
          draft.attachments = action.payload.attachments;
        },
      );
  },
});

export const ATTACHMENTS_SLICE_NAME = attachmentsSlice.name;
export const { addAttachments, deleteAttachment } = attachmentsSlice.actions;
export default attachmentsSlice.reducer;
