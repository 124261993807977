// eslint-disable-next-line import/no-extraneous-dependencies
import { ClientOptions, StackFrame } from '@sentry/types';
import * as Sentry from '@sentry/react';

// Utils
import getFromProcessEnv from 'app/shared/utils/getFromProcessEnv';

interface SentryProps {
  error: string;
  info?: any;
}

// context is not used here because user is set once
// https://docs.sentry.io/platforms/javascript/
export const setSentryUser = (emailAddress: string) => {
  Sentry.setUser({ email: emailAddress });
};

/**
 * @deprecated use consoleError instead
 */
export const createSentryError = ({ error, info = '' }: SentryProps) => {
  if (process.env.NODE_ENV === 'development') {
    return;
  }
  Sentry.captureEvent({
    message: error,
    level: 'warning',
    extra: { info },
  });
};

// the following functions are to capture dev console errors and filter them out
// https://github.com/getsentry/sentry-javascript/issues/5179
export const filterConsoleErrors: ClientOptions['beforeSend'] = (event) => {
  const originalException = event.exception?.values?.[0];

  // Console errors appear to always bubble up to `window.onerror` and to be unhandled.
  // So if, we don't have the original exception or the mechanism looks different,
  // we can early return the event.
  // (Note, this might change depending on the used framework, so feel free to remove this check.)
  if (
    !originalException ||
    !originalException.mechanism ||
    originalException.mechanism.type !== 'onerror' ||
    originalException.mechanism.handled
  ) {
    return event;
  }

  const stackFrames = originalException.stacktrace?.frames;
  const errorType = originalException.type?.toLowerCase();

  // If we don't have any information on error type or stacktrace, we have no information about the error
  // this is unlikely to happen but it doesn't appear to happen in console errors.
  // Hence, we can early return here as well.
  if (!stackFrames || !errorType) {
    return event;
  }

  // For simple console errors (e.g. users just typing a statement they want evaluated)
  // the stacktrace will only have one frame.
  // This condition will not catch errors that would be thrown if users type in multi-line
  // statements. For example, if they define a multi-line function.
  // You can try experimenting with this number but there's little guarantee that the other
  // conditions will work. Nevertheless, the checks below also work with multi-frame stacktraces.
  if (
    stackFrames.length <= 2 &&
    isInnocuousError(errorType) &&
    hasSuspiciousFrames(stackFrames)
  ) {
    return null;
  }
  return event;
};

const isInnocuousError = (errorType: string): boolean => {
  return ['syntaxerror', 'referenceerror', 'typeerror'].includes(errorType);
};

const hasSuspiciousFrames = (stackFrames: StackFrame[]): boolean => {
  const allSuspicious = stackFrames.every(isSuspiciousFrame);

  // Certain type errors will include the thrown error message as the second stack frame,
  // but the first will still follow the suspicious pattern.
  const firstSuspicious =
    stackFrames.length === 2 && isSuspiciousFrame(stackFrames[0]);
  return allSuspicious || firstSuspicious;
};

const isSuspiciousFrame = (frame: StackFrame): boolean => {
  const url = window.location.href;
  return (
    frame.function === '?' &&
    (frame.filename === '<anonymous>' || frame.filename === url)
  );
};

export const sessionReplay = Sentry.replayIntegration();

export const initSentry = () => {
  if (process.env.NODE_ENV === 'production') {
    Sentry.init({
      beforeSend: filterConsoleErrors,
      denyUrls: [/launchdarkly/],
      dsn: getFromProcessEnv('REACT_APP_SENTRY_DSN'),
      environment: window.location.hostname,
      ignoreErrors: [
        /ResizeObserver loop limit exceeded/,
        /ResizeObserver loop completed with undelivered notifications/,

        // matches "Loading CSS chunk 100 failed." and "Loading chunk 100 failed."
        /Loading (CSS )?chunk \d+ failed\./,

        // network errors
        /Failed to fetch/,
        /A network error occurred/,

        // https://github.com/facebook/react/issues/17256
        /Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node./,
      ],
      maxBreadcrumbs: 25, // default is 100
      normalizeDepth: 5,
      release: getFromProcessEnv('REACT_APP_HEAD_HASH'),
      replaysOnErrorSampleRate: 1,
      replaysSessionSampleRate: 0,
    });
  }
};
