import { Filter } from 'app/modules/filters/models';

import { ALL_ACTION_EVENT_FILTER_OPTIONS } from 'app/modules/actionEvents/filters';
import { LocalStorageKeys } from 'app/shared/constants/localStorage';

import { getLocalStorageJSON } from 'app/shared/utils/localStorage';
import { getValidFilters } from 'app/modules/filters/utils';
import { u21CreateAsyncThunk } from 'app/shared/thunk/u21CreateAsyncThunk';
import { u21CreateSlice } from 'app/shared/thunk/u21CreateSlice';

export const ACTION_EVENTS_SLICE_NAME = 'actionEvents';

interface ActionEventsState {
  filters: Filter[];
}

const initialState: ActionEventsState = {
  filters: getValidFilters(
    getLocalStorageJSON(LocalStorageKeys.ACTION_EVENT_FILTERS),
    ALL_ACTION_EVENT_FILTER_OPTIONS,
  ),
};

export const setFilters = u21CreateAsyncThunk<Filter[], Filter[]>(
  `${ACTION_EVENTS_SLICE_NAME}/SET_FILTERS`,
  (payload) => {
    localStorage.setItem(
      LocalStorageKeys.ACTION_EVENT_FILTERS,
      JSON.stringify(payload),
    );
    return payload;
  },
);

const actionEventsSlice = u21CreateSlice({
  initialState,
  name: ACTION_EVENTS_SLICE_NAME,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setFilters.fulfilled, (draft, action) => {
      draft.filters = action.payload;
    });
  },
});

export default actionEventsSlice.reducer;
