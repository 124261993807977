import { useState } from 'react';
import { snakeCase } from 'lodash';

// Components
import {
  U21Button,
  U21Spacer,
  U21TextField,
  U21Typography,
} from 'app/shared/u21-ui/components';
import { IconCheck, IconTrash } from '@u21/tabler-icons';

// Models
import { ChecklistSelectOption } from 'app/shared/customChecklist/models';
import styled from 'styled-components';

interface ChecklistSelectOptionsDuplicate extends ChecklistSelectOption {
  duplicate?: boolean;
}

const EMPTY_SELECT_ITEM: ChecklistSelectOptionsDuplicate = {
  key: '',
  text: '',
  value: '',
};

interface OwnProps {
  data: ChecklistSelectOption[];
  handleEditSelectOptions: (selectOptions: ChecklistSelectOption[]) => void;
}

export const CreateSelectOptions = ({
  data,
  handleEditSelectOptions,
}: OwnProps) => {
  const [selectOptions, setSelectOptions] = useState<
    ChecklistSelectOptionsDuplicate[]
  >(data.length ? [...data, EMPTY_SELECT_ITEM] : [EMPTY_SELECT_ITEM]);

  const duplicateError = (
    currentOptions: ChecklistSelectOptionsDuplicate[],
    option: ChecklistSelectOptionsDuplicate,
    index: number,
  ) => {
    const duplicateIndex = currentOptions.findIndex((currentOption) => {
      return currentOption.key === option.key;
    });

    return duplicateIndex >= 0 && duplicateIndex !== index;
  };

  const handleAutomaticAddition = (
    alertedOptions: ChecklistSelectOptionsDuplicate[],
  ) => {
    const prunedOptions = alertedOptions
      .filter((option) => option.key)
      .map((option, idx) => {
        return {
          ...option,
          duplicate: duplicateError(alertedOptions, option, idx),
        };
      });

    // Don't prune duplicates for this UI but do for the source of truth object
    const noDuplicateOptions: ChecklistSelectOption[] = prunedOptions
      .filter((option) => !option.duplicate)
      .map((option) => {
        const { duplicate, ...validOption } = option;
        return validOption;
      });

    handleEditSelectOptions(noDuplicateOptions);
    setSelectOptions([...prunedOptions, EMPTY_SELECT_ITEM]);
  };

  const handleValueChange = (optionIndex: number) => (optionValue: string) => {
    const alertedOptions = selectOptions.map((option, index) => {
      if (index === optionIndex) {
        const keyAndValue = snakeCase(optionValue);
        return {
          key: keyAndValue,
          text: optionValue,
          value: keyAndValue,
        };
      }
      return option;
    });
    handleAutomaticAddition(alertedOptions);
  };

  const removeRow = (optionIndex: number) => () => {
    if (selectOptions.length > 1) {
      const alertedOptions = selectOptions.filter(
        (_, idx) => idx !== optionIndex,
      );
      handleAutomaticAddition(alertedOptions);
      return;
    }
    setSelectOptions([EMPTY_SELECT_ITEM]);
  };

  return (
    <U21Spacer>
      <U21Typography>Add unique options for selectable dropdown</U21Typography>
      {selectOptions.map((option, idx) => {
        const optionText = option.text;
        const validOption = Boolean(optionText);

        return (
          // eslint-disable-next-line react/no-array-index-key
          <div key={`${idx}`}>
            <U21Spacer horizontal spacing={2}>
              <U21TextField
                error={option.duplicate}
                startIcon={validOption && !option.duplicate && <IconCheck />}
                onChange={handleValueChange(idx)}
                value={optionText}
              />
              {validOption ? (
                <U21Button
                  color="error"
                  variant="outlined"
                  onClick={removeRow(idx)}
                >
                  <IconTrash />
                </U21Button>
              ) : (
                <NoButtonSpacer />
              )}
            </U21Spacer>
          </div>
        );
      })}
    </U21Spacer>
  );
};

const NoButtonSpacer = styled.div`
  width: 60px;
`;
