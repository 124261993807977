import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { BlacklistActionTypes } from 'app/modules/lists/models';

// Actions
import {
  deactivateBlacklistItem as deactivateBlacklistItemAction,
  deactivateBlacklistItemSuccess,
} from 'app/modules/lists/actions';

// API
import { deactivateBlacklistItem as deactivateBlacklistItemApi } from 'app/shared/api/blacklists';

const rootAction = BlacklistActionTypes.DEACTIVATE_BLACKLIST_ITEM;
function* deactivateBlacklistItem({
  payload,
}: ReturnType<typeof deactivateBlacklistItemAction>) {
  const config = {
    rootAction,
    request: call(deactivateBlacklistItemApi, payload),
    success: function* onSuccess(result) {
      yield put(deactivateBlacklistItemSuccess(result));
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watchdeactivateBlacklistItem() {
  yield takeLatest(rootAction, deactivateBlacklistItem);
}
