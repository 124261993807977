import {
  ActivityPartyTypeCode,
  InstitutionTypeCode,
  GamingInstitutionTypeCode,
  PartyIdentificationTypeCode,
  PartyNameTypeCode,
  PrimaryRegulatorTypeCode,
  AccountAssociationTypeCode,
} from 'app/modules/fincenCtr/enums';
import { CurrencyTransactionActivityDetailTypeCodeType } from 'app/modules/fincenCtr/catalogues/currencyTransactionActivityDetailTypeCode';
import { ValidateFn } from 'app/shared/models/form';
import { TABS } from 'app/modules/fincenCtr/constants';
import { SortDirection } from 'app/shared/pagination/models';

export type FincenCtrContent = {
  // A single CTR is represented by the Activity node (see FinCEN CTR specification)
  // Since it's a form we don't expect to have every field filled out or even present
  form: Partial<Activity>;
  version?: number;
  errors?: {
    path: string;
    message: string;
  }[];
  alert_info?: {
    id: number;
    name: string;
  };
};

type NullOrNumber = number | null;
type NullOrString = string | null;
type DateText = string; // YYYYMMDD
type CountryCode2Char = string; // TODO use countries catalogue
type StateCode2Char = string; // TODO use states catalogue, 2-letter code according to USPS AND Canadian Post Corporation AND Mexico ISO 3166-2:MX

// TODO remove this, we are migrating to a new piece of state and any reference to this complex fincen logic should be removed, the new section is TransactionActivityDetails
export interface CurrencyTransactionActivityDetail {
  currencyTransactionActivityDetailTypeCode: CurrencyTransactionActivityDetailTypeCodeType;
  detailTransactionAmountText: number;
  otherCurrencyTransactionActivityDetailText: NullOrString;
  otherForeignCurrencyCountryText: CountryCode2Char | null;
}

interface CurrencyTransactionActivity {
  aggregateTransactionIndicator: boolean;
  armoredCarServiceIndicator: boolean;
  atmIndicator: boolean;
  mailDepositShipmentIndicator: boolean;
  nightDepositIndicator: boolean;
  sharedBranchingIndicator: boolean;
  // amounts in usd with 1-15 digits
  totalCashInReceiveAmountText: NullOrNumber;
  totalCashOutAmountText: NullOrNumber;
  transactionDateText: DateText;
  // Max 219 elements
  currencyTransactionActivityDetail: CurrencyTransactionActivityDetail[];
}

export interface GenericPartyName {
  entityLastNameUnknownIndicator?: boolean;
  firstNameUnknownIndicator?: boolean;
  partyNameTypeCode?: PartyNameTypeCode;
  rawEntityIndividualLastName?: string;
  rawIndividualFirstName?: string;
  rawIndividualMiddleName?: string;
  rawIndividualNameSuffixText?: string;
  rawPartyFullName?: string;
}

// Half parties require simple, other half the one with details
type SimplePartyName = Required<
  Pick<GenericPartyName, 'partyNameTypeCode' | 'rawPartyFullName'>
>;

type DetailedPartyName = Pick<
  GenericPartyName,
  | 'entityLastNameUnknownIndicator'
  | 'firstNameUnknownIndicator'
  | 'partyNameTypeCode'
  | 'rawEntityIndividualLastName'
  | 'rawIndividualFirstName'
  | 'rawIndividualMiddleName'
  | 'rawIndividualNameSuffixText'
>;

export interface GenericAddress {
  cityUnknownIndicator?: boolean;
  countryCodeUnknownIndicator?: boolean;
  rawCityText?: string;
  rawCountryCodeText?: CountryCode2Char;
  rawStateCodeText?: StateCode2Char;
  rawStreetAddress1Text?: string;
  rawZIPCode?: string; // Custom validations
  stateCodeUnknownIndicator?: boolean;
  streetAddressUnknownIndicator?: boolean;
  zipCodeUnknownIndicator?: boolean;
}

// A few parties require simple, about half with all details
type SimpleAddress = Required<
  Pick<
    GenericAddress,
    | 'rawCityText'
    | 'rawCountryCodeText'
    | 'rawStateCodeText'
    | 'rawStreetAddress1Text'
    | 'rawZIPCode'
  >
>;

interface GenericPhoneNumber {
  phoneNumberExtensionText?: NullOrString;
  phoneNumberText?: string;
}

export interface GenericPartyIdentification {
  identificationPresentUnknownIndicator?: boolean;
  otherIssuerCountryText?: CountryCode2Char;
  otherIssuerStateText?: StateCode2Char;
  otherPartyIdentificationTypeText?: string;
  partyIdentificationNumberText?: string; // Custom validations
  partyIdentificationTypeCode?: PartyIdentificationTypeCode;
  tinUnknownIndicator?: boolean;
}

// When the Party is a Person we have the same type of identification
type PersonPartyIdentification =
  | (GenericPartyIdentification & {
      partyIdentificationTypeCode?:
        | PartyIdentificationTypeCode.EIN
        | PartyIdentificationTypeCode.SSN_ITIN
        | PartyIdentificationTypeCode.TIN
        | PartyIdentificationTypeCode.DRIVER_STATE_ID
        | PartyIdentificationTypeCode.PASSPORT
        | PartyIdentificationTypeCode.ALIEN_REGISTRATION
        | PartyIdentificationTypeCode.OTHER_IDENTIFICATION;
    })
  | {
      identificationPresentUnknownIndicator: true;
    }
  | {
      partyIdentificationTypeCode?:
        | PartyIdentificationTypeCode.EIN
        | PartyIdentificationTypeCode.SSN_ITIN
        | PartyIdentificationTypeCode.TIN;
      tinUnknownIndicator: true;
    };

export interface KnownInstitution {
  organizationTypeID:
    | InstitutionTypeCode.DEPOSITORY_INSTITUTION
    | InstitutionTypeCode.MONEY_SERVICES_BUSINESS
    | InstitutionTypeCode.SECURITIES_FUTURES;
}

export interface KnownCasinoInstitution {
  organizationTypeID: InstitutionTypeCode.CASINO_CARD_CLUB;
  organizationSubtypeID:
    | GamingInstitutionTypeCode.CARD_CLUB
    | GamingInstitutionTypeCode.STATE_LICENSED_CASINO
    | GamingInstitutionTypeCode.TRIBAL_AUTHORIZED_CASINO;
}

export interface OtherCasinoInstitution {
  organizationTypeID: InstitutionTypeCode.CASINO_CARD_CLUB;
  organizationSubtypeID: GamingInstitutionTypeCode.OTHER;
  otherOrganizationSubTypeText: string;
}

export interface OtherOrganizationType {
  organizationTypeID: InstitutionTypeCode.OTHER;
  otherOrganizationTypeText: string;
}

export type OrganizationClassificationTypeSubtype =
  | KnownInstitution
  | KnownCasinoInstitution
  | OtherCasinoInstitution
  | OtherOrganizationType;

interface PartyOccupationBusiness {
  naicsCode: string; // Custom validations
  occupationBusinessText: string;
}

interface ElectronicAddress {
  electronicAddressText: string;
}

export interface Account {
  accountNumberText: string;
  partyAccountAssociation: {
    partyAccountAssociationTypeCode: AccountAssociationTypeCode; // 8 - Cash IN, 9 - Cash OUT
  };
}

export enum GenderIndicator {
  MALE_GENDER = 'MaleGenderIndicator',
  FEMALE_GENDER = 'FemaleGenderIndicator',
  UNKNOWN_GENDER = 'UnknownGenderIndicator',
}

interface GenericParty {
  // Here we display all the possible fields as optional since this is an _abstract_ interface
  // Interfaces extending this one define what's required and how the nested objects look like
  activityPartyTypeCode: ActivityPartyTypeCode;
  birthDateUnknownIndicator?: boolean;
  eFilingCoverageBeginningDateText?: DateText;
  eFilingCoverageEndDateText?: DateText;
  genderIndicator?: GenderIndicator;
  individualBirthDateText?: DateText;
  individualEntityCashInAmountText?: NullOrNumber;
  individualEntityCashOutAmountText?: NullOrNumber;
  multipleTransactionsPersonsIndividualsIndicator?: boolean;
  partyAsEntityOrganizationIndicator?: boolean;
  primaryRegulatorTypeCode?: PrimaryRegulatorTypeCode;
  partyName?: GenericPartyName[];
  address?: GenericAddress;
  phoneNumber?: GenericPhoneNumber;
  partyIdentification?: GenericPartyIdentification[];
  // Definitions below are not Generic bc they must be either present or null but there's no variations within their fields like above
  organizationClassificationTypeSubtype?: OrganizationClassificationTypeSubtype;
  partyOccupationBusiness?: PartyOccupationBusiness;
  electronicAddress?: ElectronicAddress;
  account?: Account[];
}

export interface ReportingFinancialInstitution
  extends Required<
    Pick<
      GenericParty,
      | 'activityPartyTypeCode'
      | 'primaryRegulatorTypeCode'
      | 'partyName'
      | 'address'
      | 'partyIdentification'
      | 'organizationClassificationTypeSubtype'
    >
  > {
  activityPartyTypeCode: ActivityPartyTypeCode.REPORTING_FINANCIAL_INSTITUTION;
  partyName: SimplePartyName[];
  address: SimpleAddress;
  partyIdentification: (Required<
    Pick<
      GenericPartyIdentification,
      'partyIdentificationNumberText' | 'partyIdentificationTypeCode'
    >
  > & {
    partyIdentificationTypeCode:
      | PartyIdentificationTypeCode.EIN
      | PartyIdentificationTypeCode.CRD
      | PartyIdentificationTypeCode.IARD
      | PartyIdentificationTypeCode.NFA
      | PartyIdentificationTypeCode.RSSD
      | PartyIdentificationTypeCode.SEC;
  })[];
}

export interface ContactForAssistance
  extends Required<
    Pick<GenericParty, 'activityPartyTypeCode' | 'partyName' | 'phoneNumber'>
  > {
  // Appears as Contact Office in some places in the FinCEN docs
  activityPartyTypeCode: ActivityPartyTypeCode.CONTACT_FOR_ASSISTANCE;
  partyName: SimplePartyName[];
  phoneNumber: Required<GenericPhoneNumber>;
}

export interface TransactionLocation
  extends Required<
    Pick<
      GenericParty,
      | 'activityPartyTypeCode'
      | 'individualEntityCashInAmountText'
      | 'individualEntityCashOutAmountText'
      | 'primaryRegulatorTypeCode'
      | 'partyName'
      | 'address'
      | 'partyIdentification'
      | 'organizationClassificationTypeSubtype'
    >
  > {
  // Appears as Transaction Location Business in some places in the FinCEN docs
  activityPartyTypeCode: ActivityPartyTypeCode.TRANSACTION_LOCATION;
  partyName: SimplePartyName[];
  address: SimpleAddress;
  partyIdentification: (Pick<
    GenericPartyIdentification,
    | 'partyIdentificationNumberText'
    | 'partyIdentificationTypeCode'
    | 'tinUnknownIndicator'
  > & {
    partyIdentificationTypeCode?:
      | PartyIdentificationTypeCode.EIN
      | PartyIdentificationTypeCode.CRD
      | PartyIdentificationTypeCode.IARD
      | PartyIdentificationTypeCode.NFA
      | PartyIdentificationTypeCode.RSSD
      | PartyIdentificationTypeCode.SEC;
  })[];

  // Custom fields
  external_id: string; // TODO use camelcase, BE uses this field for autofill and will require changes
  externalDisplayName: string;
}

// Codes 50, 17 share the same generic person interface. 23, 58 is almost the same, just 1 extra field
export interface GenericPersonParty
  extends Required<
    Pick<
      GenericParty,
      | 'activityPartyTypeCode'
      | 'individualEntityCashInAmountText'
      | 'individualEntityCashOutAmountText'
      | 'multipleTransactionsPersonsIndividualsIndicator'
      | 'partyName'
      | 'address'
      | 'phoneNumber'
      | 'partyIdentification'
      | 'partyOccupationBusiness'
      | 'electronicAddress'
      | 'account'
    >
  > {
  partyName: DetailedPartyName[];
  address: GenericAddress;
  phoneNumber: Required<GenericPhoneNumber>;
  partyIdentification: PersonPartyIdentification[];
  birthDateUnknownIndicator?: boolean;
  individualBirthDateText?: DateText;
  genderIndicator?: GenderIndicator;
  externalId?: string;
  customEntity?: JSONObject;
}
interface PersonConductingTxnOnOwnBehalf extends GenericPersonParty {
  activityPartyTypeCode: ActivityPartyTypeCode.PERSON_CONDUCTING_TXN_ON_OWN_BEHALF;
}

interface PersonConductingTxnForAnother extends GenericPersonParty {
  activityPartyTypeCode: ActivityPartyTypeCode.PERSON_CONDUCTING_TXN_FOR_ANOTHER;
}

interface PersonOnWhoseBehalfTxnWasConducted extends GenericPersonParty {
  activityPartyTypeCode: ActivityPartyTypeCode.PERSON_ON_WHOSE_BEHALF_TXN_WAS_CONDUCTED;
  partyAsEntityOrganizationIndicator: boolean;
}

interface CommonCarrier extends GenericPersonParty {
  activityPartyTypeCode: ActivityPartyTypeCode.COMMON_CARRIER;
  partyAsEntityOrganizationIndicator: boolean;
}

export type PersonParty =
  | PersonConductingTxnOnOwnBehalf
  | PersonConductingTxnForAnother
  | PersonOnWhoseBehalfTxnWasConducted
  | CommonCarrier;

// Note: Only one of these fields can be set to true
export enum ActivityAssociationValue {
  CORRECTS_AMENDS_PRIOR_REPORT = 'CorrectsAmendsPriorReportIndicator',
  FIN_CEN_DIRECT_BACK_FILE = 'FinCENDirectBackFileIndicator',
  INITIAL_REPORT = 'InitialReportIndicator',
}

export interface ForeignCurrencyTransactionActivity {
  countryCode: string;
  amount: number;
}

export interface TransactionActivityDetails {
  totalCashOut: number;
  totalCashIn: number;

  // Individual amounts
  cashIn: {
    deposits: number;
    payments: number;
    currencyReceivedFromFundsTransfer: number;
    purchasesOfNegotiableInstruments: number;
    currencyExchangesIn: number;
    currencyToPrepaidAccess: number;
    purchaseOfCasinoChips: number;
    currencyWager: number;
    billsInsertedIntoGamingDevices: number;

    // Other
    otherCashIn: number;
    otherCashInDescription: string;

    // Max of 99 elements
    foreignCurrencyIn: ForeignCurrencyTransactionActivity[];
  };
  cashOut: {
    withdrawals: number;
    advancesOnCredit: number;
    currencyPaidFromFundsTransfer: number;
    negotiableInstrumentsCashed: number;
    currencyExchangesOut: number;
    currencyFromPrepaidAccess: number;
    redemptionOfCasinoChips: number;
    paymentOfWager: number;
    travelComplimentaryExpensesAndIncentives: number;
    paymentForTournaments: number;

    // Other
    otherCashOut: number;
    otherCashOutDescription: string;

    // Max of 99 elements
    foreignCurrencyOut: ForeignCurrencyTransactionActivity[];
  };
}

export interface Activity {
  /*
      Represents a FinCEN CTR document in the batch. Batches must have one <Activity> element per filing.
      It identifies all information related to a single FinCEN CTR, including (but not limited to) the transmitter, the reporting financial institution (i.e. filing institution), the transaction location(s), the person(s) involved in the transaction(s), and transaction details such as the cash-in/out amount(s), the filing date, etc.
      There is no limit to the number of <Activity> elements that may be recorded in the batch.
    */
  eFilingPriorDocumentNumber?: string;
  filingDateText: DateText;
  activityAssociation: ActivityAssociationValue;
  party: {
    // This should be transformed into an array of Party tags in the BE, we are storing them as a hashmap for easier handling
    // An Activity must have at least 6 parties, one separate for:
    // - Transmitter (Added on the BE, no need to add it here)
    // - Transmitter Official Contact (ditto above)
    // - Reporting Financial Institution
    // - Contact office
    // - Transaction Location Business (max 999)
    // - Person Involved (max 999)
    reportingFinancialInstitution: ReportingFinancialInstitution;
    contactOffice: ContactForAssistance;
    transactionLocations: [TransactionLocation, ...TransactionLocation[]]; // At least 1 TransactionLocation
    personsInvolved: [PersonParty, ...PersonParty[]]; // At least 1 PersonParty
  };
  currencyTransactionActivity: CurrencyTransactionActivity;

  // Custom state, decoupling complex FinCEN logic from the UI
  transactionActivityDetails: TransactionActivityDetails;
}

export interface CTRFiling {
  eFilingBatchXML: {
    /*
      This is the root element and serves as the container for all FinCEN CTR documents recorded in the batch.
      It must be the first element recorded in the file.
    */
    formTypeCode: string;
    activity: Activity[];
    totalAmount: number;
    partyCount: number;
    activityCount: number;
  };
}

export interface TransactionTableRow {
  // Mapping of each individual CurrencyTransactionActivityDetail to a payload used by the U21Table component
  id: string;
  cashIn: number;
  cashOut: number;
  type: string;
  // Generated from TransactionLocation metadata, see helpers > getStringIdFromTransactionLocation (TEMPORARY)
  locationID: string;
}

export interface TransactionLocationTableRow {
  id: string;
  name: string;
  address: string;
  totalCashIn: number;
  totalCashOut: number;
}

export interface PersonTableRow {
  id: number; // TODO improve, for now is the index in the array and we don't support rearranging items
  name: string;
  partyType: string;
}

export interface ValidationErrors {
  fields: {
    [fieldPath: string]: {
      message: string;
      validateFn: ValidateFn<any, Record<string, any>>;
    };
  };
  sections: {
    [key in Exclude<ValueOf<typeof TABS>, 'overview'>]: boolean;
  };
}

export enum FincenCtrTablePages {
  ADMIN = 'ADMIN',
  QUEUE_DETAILS = 'QUEUE_DETAILS',
  MY_FILINGS = 'MY_FILINGS',
  QUEUED_FILINGS = 'QUEUED_FILINGS',
}

export type FincenCtrGroupBy = 'NONE' | 'BATCH_ID';
export type FincenCtrSortBy =
  | 'BATCH_ID'
  | 'TOTAL'
  | 'EARLIEST'
  | 'LATEST'
  | 'ACCEPTED_COUNT'
  | 'REJECTED_COUNT';

export interface FincenCtrTablePreferences {
  groupBy: FincenCtrGroupBy;
  sortBy: FincenCtrSortBy;
  sortDir: SortDirection;
}
