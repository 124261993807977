import { FilterOption, IsOperatorAndValue } from 'app/modules/filters/models';

import {
  DATA_CLASSIFIER_SELECT_OPTIONS,
  DATA_SETTINGS_FIELD_TYPE_OPTIONS,
} from 'app/modules/dataSettings/constants';
import {
  DataSettingsKeyType,
  Unit21DataClassifier,
} from 'app/modules/dataSettings/responses';
import {
  FILTER_OPERATOR,
  FILTER_TYPE,
  FILTER_TYPE_OPERATOR_LABELS,
} from 'app/modules/filters/constants';

import { selectAgentOrgDisplayName } from 'app/modules/session/selectors';
import { useSelector } from 'react-redux';

import { U21Select } from 'app/shared/u21-ui/components';

export const DATA_SETTING_FILTER_KEYS = {
  DATA_TYPE: 'data_type',
  FIELD_TYPE: 'field_type',
  NAME: 'name',
  OBJECT_TYPE: 'object_type',
} as const;

export const ALL_DATA_SETTING_FILTER_OPTIONS: FilterOption[] = [
  {
    key: DATA_SETTING_FILTER_KEYS.DATA_TYPE,
    label: 'Data type',
    type: FILTER_TYPE.MULTI_SELECT,
    customize: {
      [FILTER_OPERATOR.IS_ONE_OF]: {
        inputProps: { options: DATA_SETTINGS_FIELD_TYPE_OPTIONS },
      },
      [FILTER_OPERATOR.IS_NOT_ONE_OF]: {
        inputProps: { options: DATA_SETTINGS_FIELD_TYPE_OPTIONS },
      },
    },
  },
  {
    key: DATA_SETTING_FILTER_KEYS.FIELD_TYPE,
    label: 'Field type',
    type: FILTER_TYPE.SELECT,
    customize: {
      [FILTER_OPERATOR.IS]: {
        Display: ({
          operator,
          value,
        }: Omit<IsOperatorAndValue<DataSettingsKeyType>, 'onChange'>) => {
          const agentOrgName = useSelector(selectAgentOrgDisplayName);
          const operatorLabel = FILTER_TYPE_OPERATOR_LABELS[operator];
          const options: Record<DataSettingsKeyType, string> = {
            [DataSettingsKeyType.CUSTOM]:
              agentOrgName === 'Unit21'
                ? 'Custom field'
                : `${agentOrgName} field`,
            [DataSettingsKeyType.NATIVE]: 'Unit21 field',
          };
          return (
            <>
              <b>Field type</b> {operatorLabel} <b>{options[value]}</b>
            </>
          );
        },
        Input: ({
          onChange,
          value,
        }: IsOperatorAndValue<DataSettingsKeyType>) => {
          const agentOrgName = useSelector(selectAgentOrgDisplayName);
          return (
            <U21Select
              autoFocus
              backdrop
              clearable={false}
              onChange={onChange}
              options={[
                { text: 'Unit21 field', value: DataSettingsKeyType.NATIVE },
                {
                  text:
                    agentOrgName === 'Unit21'
                      ? 'Custom field'
                      : `${agentOrgName} field`,
                  value: DataSettingsKeyType.CUSTOM,
                },
              ]}
              value={value}
            />
          );
        },
      },
    },
  },
  {
    key: DATA_SETTING_FILTER_KEYS.NAME,
    label: 'Name',
    type: FILTER_TYPE.TEXT,
  },
  {
    key: DATA_SETTING_FILTER_KEYS.OBJECT_TYPE,
    label: 'Object type',
    type: FILTER_TYPE.MULTI_SELECT,
    customize: {
      [FILTER_OPERATOR.IS_ONE_OF]: {
        inputProps: { options: DATA_CLASSIFIER_SELECT_OPTIONS },
      },
      [FILTER_OPERATOR.IS_NOT_ONE_OF]: {
        inputProps: { options: DATA_CLASSIFIER_SELECT_OPTIONS },
      },
    },
  },
];

export const DATA_SETTING_OBJECT_TYPE_FILTER_OPTION_NO_WATCHLIST = {
  key: DATA_SETTING_FILTER_KEYS.OBJECT_TYPE,
  label: 'Object type',
  type: FILTER_TYPE.MULTI_SELECT,
  customize: {
    [FILTER_OPERATOR.IS_ONE_OF]: {
      inputProps: {
        options: DATA_CLASSIFIER_SELECT_OPTIONS.filter(
          (o) => o.value !== Unit21DataClassifier.WATCHLIST,
        ),
      },
    },
    [FILTER_OPERATOR.IS_NOT_ONE_OF]: {
      inputProps: {
        options: DATA_CLASSIFIER_SELECT_OPTIONS.filter(
          (o) => o.value !== Unit21DataClassifier.WATCHLIST,
        ),
      },
    },
  },
};
