import { destroy, downloadFile, get, post, put } from 'app/shared/utils/fetchr';

// Utils
import routes, { pathJoin } from 'app/shared/utils/routes';

// Models
import {
  BulkDeactivatePayload,
  EditRulePayload,
  RuleEntitiesWhitelistPayload,
} from 'app/modules/rules/models';
import {
  BulkFileExportPayload,
  SingleFileExportPayload,
} from 'app/modules/fileExportsOld/models';

// Actions
import {
  deployShadowRule as deployShadowRuleAction,
  removeWhitelistEntitiesForRule as removeWhitelistEntitiesForRuleAction,
  retrievePaginatedAlerts as retrievePaginatedAlertsAction,
  retrieveRules as retrieveRulesAction,
  retrieveRuleValidationAlert as retrieveRuleValidationAlertAction,
  retrieveRuleValidationAlertEntities as retrieveRuleValidationAlertEntitiesAction,
  retrieveRuleValidationAlertTransactions as retrieveRuleValidationAlertTransactionsAction,
  retrieveValidationRules as retrieveValidationRulesAction,
  retrieveWhitelistedEntities as retrieveWhitelistedEntitiesAction,
} from 'app/modules/rules/actions';
import {
  BulkActivateDetectionModelPayload,
  DetectionModel,
} from 'app/modules/detectionModels/models';
import {
  BulkDeactivateRulesResponse,
  RuleImportResponse,
  SingleRuleImportResult,
} from 'app/modules/detectionModels/types/responses';

export const retrieveValidationRules = (
  payload: ReturnType<typeof retrieveValidationRulesAction>['payload'],
) => {
  return post(routes.patronus.validationRulesList, payload);
};

export const deleteValidationRule = (id: number) => {
  const path = routes.patronus.validationRulesIdDelete.replace(
    ':id',
    String(id),
  );
  return destroy(path);
};

export const retrieveValidationRulesFileExports = (id: number) => {
  const path = routes.patronus.fileExportsRuleValidationFileExportsId.replace(
    ':id',
    String(id),
  );
  return get(path);
};

export const retrieveRuleValidationAlert = (
  id: ReturnType<typeof retrieveRuleValidationAlertAction>['payload'],
) => {
  const path = routes.patronus.ruleValidationAlertsId.replace(
    ':id',
    String(id),
  );
  return get(path);
};

export const retrieveRuleValidationAlertTransactions = (
  payload: ReturnType<
    typeof retrieveRuleValidationAlertTransactionsAction
  >['payload'],
) => {
  const { id, ...data } = payload;
  const path = routes.patronus.ruleValidationAlertsIdTransactions.replace(
    ':id',
    String(id),
  );
  return post(path, data);
};

export const retrieveRuleValidationAlertEntities = (
  payload: ReturnType<
    typeof retrieveRuleValidationAlertEntitiesAction
  >['payload'],
) => {
  const { id, ...data } = payload;
  const path = routes.patronus.ruleValidationAlertsIdEntities.replace(
    ':id',
    String(id),
  );
  return post(path, data);
};

export const deployShadowRule = (
  payload: ReturnType<typeof deployShadowRuleAction>['payload'],
) => {
  const { id, ...details } = payload;
  const path = routes.patronus.shadowRulesIdDeploy.replace(':id', String(id));
  return post(path, details);
};

export const retrievePaginatedAlerts = (
  payload: ReturnType<typeof retrievePaginatedAlertsAction>['payload'],
) => {
  const { id, ...details } = payload;
  const path = routes.patronus.validationRulesPaginatedAlertsId.replace(
    ':id',
    String(id),
  );
  return post(path, details);
};

export const retrieveRules = (
  payload: ReturnType<typeof retrieveRulesAction>['payload'],
) => {
  return post(routes.patronus.rulesList, payload);
};

export const bulkDeactivate = (payload: BulkDeactivatePayload) =>
  post<BulkDeactivateRulesResponse>(
    routes.patronus.rulesBulkDeactivate,
    payload,
  );

export const bulkActivate = (payload: BulkActivateDetectionModelPayload) =>
  post<DetectionModel[]>(routes.patronus.validationRulesBulkDeploy, payload);

export const exportRules = (
  payload: number[],
  fileName: string,
): Promise<Record<string, never>> =>
  downloadFile('/rules/export-rules', fileName, 'POST', payload);

export const importRules = (payload: string): Promise<RuleImportResponse> =>
  post('/rules/import-rules', payload);

export const downloadFileImportErrorFile = (
  payload: SingleRuleImportResult[],
  fileName: string,
) => {
  downloadFile(
    '/file-exports/download/rule-import-errors',
    fileName,
    'POST',
    payload,
  );
};

export const getRuleWhitelistedEntities = (
  payload: ReturnType<typeof retrieveWhitelistedEntitiesAction>['payload'],
) => {
  const { id, ...details } = payload;
  const path = pathJoin(routes.patronus.rulesPaginatedWhitelistedEntities, id);
  return post(path, details);
};

export const addWhitelistedEntitiesForRule = (
  payload: RuleEntitiesWhitelistPayload,
) => {
  return post(routes.patronus.rulesWhitelistEntities, payload);
};

export const removeWhitelistedEntitiesForRule = (
  payload: ReturnType<typeof removeWhitelistEntitiesForRuleAction>['payload'],
) => {
  return post(routes.patronus.rulesUnwhitelistEntities, payload);
};

export const editRule = (payload: EditRulePayload) => {
  const { id, ...data } = payload;
  return put<DetectionModel>(
    routes.patronus.rulesEditId.replace(':id', `${id}`),
    data,
  );
};

export const sendSingleRulesXlsxRequest = (
  payload: SingleFileExportPayload,
) => {
  return post(routes.patronus.rulesSingleEmailXlsx, payload);
};

export const sendBulkRulesXlsxRequest = (payload: BulkFileExportPayload) => {
  return post(routes.patronus.rulesBulkXlsx, payload);
};
