import { createAction, ActionsUnion } from 'app/shared/utils/action-helpers';
// Models
import { TransactionsActionTypes } from 'app/modules/transactions/models';
import { EventFiltersState } from 'app/shared/events/models';

export const retrieveActionEvent = (payload: number | string) => {
  return createAction(TransactionsActionTypes.RETRIEVE_ACTION_EVENT, payload);
};

export const retrieveActionEventSuccess = (result) => {
  return createAction(
    TransactionsActionTypes.RETRIEVE_ACTION_EVENT_SUCCESS,
    result,
  );
};

export const setOrgTransactionFilters = (newFilters: EventFiltersState) => {
  return createAction(
    TransactionsActionTypes.SET_ORG_TRANSACTION_FILTERS,
    newFilters,
  );
};

const TransactionsActionsList = {
  setOrgTransactionFilters,
  retrieveActionEventSuccess,
};
type TransactionsActions = ActionsUnion<typeof TransactionsActionsList>;

export default TransactionsActions;
