import { getIn, setIn } from 'final-form';
import { cloneDeep } from 'lodash';
import { v4 } from 'uuid';

// Models
import {
  TransactionActivityDetails,
  PersonTableRow,
  PersonParty,
  Activity,
  KnownInstitution,
  KnownCasinoInstitution,
  OtherCasinoInstitution,
  OtherOrganizationType,
  GenericPartyName,
  GenericPartyIdentification,
  TransactionLocationTableRow,
  TransactionLocation,
  GenericAddress,
  ValidationErrors,
  ActivityAssociationValue,
} from 'app/modules/fincenCtr/models';
import {
  FormFieldSchema,
  FormFieldCustomSchema,
  FormFieldArraySchema,
  ValidateFn,
} from 'app/shared/models/form';

// Constants
import {
  InstitutionTypeCode,
  GamingInstitutionTypeCode,
  ActivityPartyTypeCode,
  PartyIdentificationTypeCode,
  PrimaryRegulatorTypeCode,
  PartyNameTypeCode,
  CtrFilingStatus,
} from 'app/modules/fincenCtr/enums';
import { TABS } from 'app/modules/fincenCtr/constants';

export const generateNewTransactionLocation = (): TransactionLocation => {
  return {
    activityPartyTypeCode: ActivityPartyTypeCode.TRANSACTION_LOCATION,
    individualEntityCashInAmountText: 0,
    individualEntityCashOutAmountText: 0,
    primaryRegulatorTypeCode: PrimaryRegulatorTypeCode.IRS,
    partyName: [
      {
        partyNameTypeCode: PartyNameTypeCode.LEGAL,
        rawPartyFullName: '',
      },
    ],
    address: {
      rawCityText: '',
      rawCountryCodeText: '',
      rawStateCodeText: '',
      rawStreetAddress1Text: '',
      rawZIPCode: '',
    },
    partyIdentification: [
      {
        partyIdentificationNumberText: '',
        partyIdentificationTypeCode: PartyIdentificationTypeCode.EIN,
        tinUnknownIndicator: false,
      },
    ],
    organizationClassificationTypeSubtype: {
      organizationTypeID: InstitutionTypeCode.MONEY_SERVICES_BUSINESS,
    },

    // Custom fields
    external_id: `txn_location_${v4()}`,
    externalDisplayName: '',
  };
};

const formatAddress = (address?: GenericAddress): string => {
  const {
    rawStreetAddress1Text,
    rawCityText,
    rawStateCodeText,
    rawCountryCodeText,
    rawZIPCode,
  } = address ?? {};
  const st = rawStreetAddress1Text ? `${rawStreetAddress1Text}, ` : '';
  const ct = rawCityText ? `${rawCityText}, ` : '';
  const state = rawStateCodeText ? `${rawStateCodeText}, ` : '';
  const country = rawCountryCodeText ? `${rawCountryCodeText}, ` : '';
  const postalCode = rawZIPCode ? `${rawZIPCode}, ` : '';
  return `${st}${ct}${state}${country}${postalCode}`.replace(/, $/, '.');
};

const getStringIdFromTransactionLocation = (
  txnLocation: TransactionLocation,
  index: number,
): string => {
  // Fallback to index id if there's not an external id
  return txnLocation.external_id || `txn_location_ix_${index}`;
};

export const getIndexFromTransactionLocationTableRowID = (
  { id }: Pick<TransactionLocationTableRow, 'id'>,
  txnLocations: TransactionLocation[] | undefined,
): number => {
  return (txnLocations || []).findIndex(
    (txnLocation: TransactionLocation, index: number) =>
      getStringIdFromTransactionLocation(txnLocation, index) === id,
  );
};

export const formatPartyName = (
  partyNames: GenericPartyName[] | undefined,
): string => {
  let formattedName = '';
  // A Party can have either a legal name or an AKA name, try to get the first one
  (partyNames || []).forEach((partyName: GenericPartyName) => {
    if (!formattedName) {
      const {
        rawPartyFullName,
        rawEntityIndividualLastName,
        rawIndividualFirstName,
        rawIndividualMiddleName,
      } = partyName;
      // Wither we use the full name (institution) or the individual name (person)
      if (rawPartyFullName) {
        formattedName = rawPartyFullName;
      } else {
        if (rawIndividualFirstName) {
          formattedName += `${rawIndividualFirstName} `;
        }
        if (rawIndividualMiddleName) {
          formattedName += `${rawIndividualMiddleName} `;
        }
        if (rawEntityIndividualLastName) {
          formattedName += `${rawEntityIndividualLastName} `;
        }
      }
    }
  });
  return formattedName.replace(/ $/, '');
};

export const getTransactionLocationRowsFromTransactionLocationsFormValues = (
  txnLocations: TransactionLocation[] | undefined,
): TransactionLocationTableRow[] =>
  (txnLocations || []).map(
    (txnLocation: TransactionLocation, index: number) => {
      return {
        id: getStringIdFromTransactionLocation(txnLocation, index),
        name:
          txnLocation.externalDisplayName ||
          formatPartyName(txnLocation.partyName),
        address: formatAddress(txnLocation.address),
        totalCashIn: txnLocation.individualEntityCashInAmountText || 0,
        totalCashOut: txnLocation.individualEntityCashOutAmountText || 0,
      } satisfies TransactionLocationTableRow;
    },
  );

export const getPersonInvolvedTableRows = (
  personsInvolved: PersonParty[],
): PersonTableRow[] => {
  return (personsInvolved || []).map((personInvolved: PersonParty, index) => {
    return {
      id: index,
      name: formatPartyName(personInvolved.partyName),
      // TODO show a label instead of a number + more info
      partyType: `${personInvolved.activityPartyTypeCode}`,
    };
  });
};

function clearPartyIdentificationStaleValues<T extends Record<string, any>>(
  valuesToUpdate: T,
  pathToOrgSubtype: string,
): T {
  // The path should take us to the partyName array
  const partyIDArray: GenericPartyIdentification[] | undefined = getIn(
    valuesToUpdate,
    pathToOrgSubtype,
  );
  if (!partyIDArray) {
    // Path seems to be wrong, do not mess with the values
    return valuesToUpdate;
  }

  const isIdentificationUnknown =
    partyIDArray.length > 0 &&
    partyIDArray[0].identificationPresentUnknownIndicator;
  // We do not know any of the identification values, clear the array and add the unknown indicator
  if (isIdentificationUnknown) {
    return setIn(valuesToUpdate, pathToOrgSubtype, [
      { identificationPresentUnknownIndicator: true },
    ]) as T;
  }

  // Map the array
  const updatedpartyIDArray = partyIDArray
    .map((partyIDNode) => {
      const updatedPartyIDNode = { ...partyIDNode };

      if (partyIDNode === undefined) {
        // Nothing to update return the same value
        return updatedPartyIDNode;
      }

      // IdentificationPresentUnknownIndicator (precedence over TINUnknownIndicator)
      if (partyIDNode.identificationPresentUnknownIndicator) {
        delete updatedPartyIDNode.otherIssuerCountryText;
        delete updatedPartyIDNode.otherIssuerStateText;
        delete updatedPartyIDNode.otherPartyIdentificationTypeText;
        delete updatedPartyIDNode.partyIdentificationNumberText;
        delete updatedPartyIDNode.partyIdentificationTypeCode;
        delete updatedPartyIDNode.tinUnknownIndicator;
      } else if (partyIDNode.tinUnknownIndicator) {
        // TINUnknownIndicator
        delete updatedPartyIDNode.identificationPresentUnknownIndicator;
        delete updatedPartyIDNode.otherIssuerCountryText;
        delete updatedPartyIDNode.otherIssuerStateText;
        delete updatedPartyIDNode.otherPartyIdentificationTypeText;
        delete updatedPartyIDNode.partyIdentificationNumberText;
        delete updatedPartyIDNode.partyIdentificationTypeCode;
      }

      // Record "OtherIssuerStateText" if:
      // = The type of identification is Driver’s license/State Identification or Other
      // = AND The issuing country is US, Canada, Mexico, or a U.S. Territory.
      if (
        partyIDNode.partyIdentificationTypeCode !==
          PartyIdentificationTypeCode.DRIVER_STATE_ID &&
        partyIDNode.partyIdentificationTypeCode !==
          PartyIdentificationTypeCode.OTHER_IDENTIFICATION
      ) {
        // Not the right type, remove
        delete updatedPartyIDNode.otherIssuerStateText;
      } else {
        // TODO add logic for removing the issuerState if the country is not US, Canada, Mexico, or a U.S. Territory.
      }

      // OtherPartyIdentificationTypeText
      if (
        partyIDNode.partyIdentificationTypeCode !==
        PartyIdentificationTypeCode.OTHER_IDENTIFICATION
      ) {
        delete updatedPartyIDNode.otherPartyIdentificationTypeText;
      }

      // clean the otherIssuerCountryText if it's empty
      if (!updatedPartyIDNode.otherIssuerCountryText) {
        delete updatedPartyIDNode.otherIssuerCountryText;
      }

      return updatedPartyIDNode;
      // Only keep nodes that have values
    })
    .filter((partyIDNode) => Object.keys(partyIDNode).length > 0);

  return setIn(valuesToUpdate, pathToOrgSubtype, updatedpartyIDArray) as T;
}

function clearPartyNameStaleValues<T extends Record<string, any>>(
  valuesToUpdate: T,
  pathToOrgSubtype: string,
): T {
  // The path should take us to the partyName array
  const partyNames: GenericPartyName[] | undefined = getIn(
    valuesToUpdate,
    pathToOrgSubtype,
  );
  if (!partyNames) {
    // Path seems to be wrong, do not mess with the values
    return valuesToUpdate;
  }

  // Map the array
  const updatedPartyNames = partyNames.map(
    (partyNameNode: GenericPartyName) => {
      const updatedPartyNameNode = { ...partyNameNode };
      if (partyNameNode.firstNameUnknownIndicator) {
        delete updatedPartyNameNode.rawIndividualFirstName;
      }
      if (partyNameNode.entityLastNameUnknownIndicator) {
        delete updatedPartyNameNode.rawEntityIndividualLastName;
      }
      return updatedPartyNameNode;
    },
  );

  return setIn(valuesToUpdate, pathToOrgSubtype, updatedPartyNames) as T;
}

function clearOrgSubTypeStaleValues<T extends Record<string, any>>(
  valuesToUpdate: T,
  pathToOrgSubtype: string,
): T {
  // Removes any stale value that might be present (e.g. if the user changes the institution type from casino to bank)
  // Only keep the fields that match the right interface defined by FinCEN
  const orgTypeId: InstitutionTypeCode | undefined = getIn(
    valuesToUpdate,
    `${pathToOrgSubtype}.organizationTypeID`,
  );
  // Subtype is only present on casinos
  const casinoType = getIn(
    valuesToUpdate,
    `${pathToOrgSubtype}.organizationSubtypeID`,
  );
  switch (orgTypeId) {
    case InstitutionTypeCode.DEPOSITORY_INSTITUTION:
    case InstitutionTypeCode.MONEY_SERVICES_BUSINESS:
    case InstitutionTypeCode.SECURITIES_FUTURES:
      return setIn(valuesToUpdate, pathToOrgSubtype, {
        organizationTypeID: orgTypeId,
      } satisfies KnownInstitution) as T;
    case InstitutionTypeCode.CASINO_CARD_CLUB:
      if (casinoType === GamingInstitutionTypeCode.OTHER) {
        return setIn(valuesToUpdate, pathToOrgSubtype, {
          organizationTypeID: orgTypeId,
          organizationSubtypeID: casinoType,
          otherOrganizationSubTypeText: getIn(
            valuesToUpdate,
            `${pathToOrgSubtype}.otherOrganizationSubTypeText`,
          ),
        } satisfies OtherCasinoInstitution) as T;
      }
      return setIn(valuesToUpdate, pathToOrgSubtype, {
        organizationTypeID: orgTypeId,
        organizationSubtypeID: casinoType,
      } satisfies KnownCasinoInstitution) as T;
    case InstitutionTypeCode.OTHER:
      return setIn(valuesToUpdate, pathToOrgSubtype, {
        organizationTypeID: orgTypeId,
        otherOrganizationTypeText: getIn(
          valuesToUpdate,
          `${pathToOrgSubtype}.otherOrganizationTypeText`,
        ),
      } satisfies OtherOrganizationType) as T;
    default:
      return valuesToUpdate;
  }
}

const clearStaleFinancialInstitutionValues = (
  valuesToUpdate: Activity,
): Activity => {
  let updatedValues = valuesToUpdate;

  const pathToOrgSubtype =
    'party.reportingFinancialInstitution.organizationClassificationTypeSubtype';

  // Add code to the financial institution IDs
  const pathToInstitutionIdentifications =
    'party.reportingFinancialInstitution.partyIdentification';
  const updatedIdentifications: GenericPartyIdentification[] =
    getIn(valuesToUpdate, pathToInstitutionIdentifications) || [];
  if (updatedIdentifications.length > 0) {
    // The first ID is always the EIN
    updatedIdentifications[0].partyIdentificationTypeCode =
      PartyIdentificationTypeCode.EIN;
    const filteredIdentifications = updatedIdentifications.filter(
      // Only keep the identification if it has a value
      (identification) =>
        identification && identification.partyIdentificationNumberText,
    );
    updatedValues = setIn(
      valuesToUpdate,
      pathToInstitutionIdentifications,
      filteredIdentifications,
    ) as Activity;
  }

  return clearOrgSubTypeStaleValues(updatedValues, pathToOrgSubtype);
};

const clearStaleTransactionLocationValues = (
  valuesToUpdate: Activity,
): Activity => {
  const updatedTxnLocations = (
    getIn(valuesToUpdate, 'party.transactionLocations') || []
  ).map((txnLocation) => {
    // Remove OrgSubType if it is stale
    let updatedTxnLocation = clearOrgSubTypeStaleValues(
      txnLocation,
      'organizationClassificationTypeSubtype',
    );

    // Remove PartyID stale values
    updatedTxnLocation = clearPartyIdentificationStaleValues(
      updatedTxnLocation,
      'partyIdentification',
    );

    // Return
    return updatedTxnLocation;
  });

  return setIn(
    valuesToUpdate,
    'party.transactionLocations',
    updatedTxnLocations,
  ) as Activity;
};

export const clearStaleInvolvedPersonsValues = (
  valuesToUpdate: Activity,
): Activity => {
  const updatedPersons = (
    getIn(valuesToUpdate, 'party.personsInvolved') || []
  ).map((person) => {
    let updatedPerson = { ...person };

    // === Remove Organization/Person type data
    if (
      person.activityPartyTypeCode ===
        ActivityPartyTypeCode.PERSON_CONDUCTING_TXN_ON_OWN_BEHALF ||
      person.activityPartyTypeCode ===
        ActivityPartyTypeCode.PERSON_CONDUCTING_TXN_FOR_ANOTHER
    ) {
      // These entities do not support organization type
      updatedPerson = setIn(
        updatedPerson,
        'partyAsEntityOrganizationIndicator',
        undefined,
      ) as PersonParty;
    }

    const isEntityPerson = getIn(
      updatedPerson,
      'partyAsEntityOrganizationIndicator',
    );
    if (isEntityPerson) {
      delete updatedPerson.birthDateUnknownIndicator;
      delete updatedPerson.genderIndicator;
      delete updatedPerson.individualBirthDateText;
    } else if (updatedPerson.birthDateUnknownIndicator) {
      // We have a non-entity person AND birth date is unknown, clear field
      delete updatedPerson.individualBirthDateText;
    }

    // === Remove Address data if unknown (Only for Persons, all other address forms have to be filled in)
    if (person.address.countryCodeUnknownIndicator) {
      delete updatedPerson.address.rawCountryCodeText;
    }

    if (person.address.stateCodeUnknownIndicator) {
      delete updatedPerson.address.rawStateCodeText;
    }

    if (person.address.cityUnknownIndicator) {
      delete updatedPerson.address.rawCityText;
    }

    if (person.address.streetAddressUnknownIndicator) {
      delete updatedPerson.address.rawStreetAddress1Text;
    }

    if (person.address.zipCodeUnknownIndicator) {
      delete updatedPerson.address.rawZIPCode;
    }

    // === Remove Party Name data if unknown
    updatedPerson = clearPartyNameStaleValues(updatedPerson, 'partyName');

    // === Remove ID data if unknown
    updatedPerson = clearPartyIdentificationStaleValues(
      updatedPerson,
      'partyIdentification',
    );

    // Return
    return updatedPerson;
  });

  return setIn(
    valuesToUpdate,
    'party.personsInvolved',
    updatedPersons,
  ) as Activity;
};

const clearStaleTransactionsValues = (valuesToUpdate: Activity): Activity => {
  let updatedValues = { ...valuesToUpdate };

  // == Update the transactionActivityDetails piece of state
  let updatedTransactionActivityDetails: TransactionActivityDetails =
    getIn(valuesToUpdate, 'transactionActivityDetails') || {};
  const otherCashIn = getIn(
    updatedTransactionActivityDetails,
    'cashIn.otherCashIn',
  );

  if (!otherCashIn) {
    updatedTransactionActivityDetails = setIn(
      updatedTransactionActivityDetails,
      'cashIn.otherCashInDescription',
      '',
    ) as TransactionActivityDetails;
  }
  const otherCashOut = getIn(
    updatedTransactionActivityDetails,
    'cashOut.otherCashOut',
  );
  if (!otherCashOut) {
    updatedTransactionActivityDetails = setIn(
      updatedTransactionActivityDetails,
      'cashOut.otherCashOutDescription',
      '',
    ) as TransactionActivityDetails;
  }

  // Make sure we have the latest total cash in/out values
  const { otherCashInDescription, foreignCurrencyIn, ...updatedCashIn } =
    updatedTransactionActivityDetails.cashIn || {};
  const totalCashIn = getTxnActivityTotalCash({
    type: 'cashIn',
    values: {
      ...updatedCashIn,
    },
  });

  const { otherCashOutDescription, foreignCurrencyOut, ...updatedCashOut } =
    updatedTransactionActivityDetails.cashOut || {};
  const totalCashOut = getTxnActivityTotalCash({
    type: 'cashOut',
    values: {
      ...updatedCashOut,
    },
  });

  updatedTransactionActivityDetails.totalCashIn = totalCashIn;
  updatedTransactionActivityDetails.totalCashOut = totalCashOut;

  // Update the state for transactionActivityDetails
  updatedValues = setIn(
    valuesToUpdate,
    'transactionActivityDetails',
    updatedTransactionActivityDetails,
  ) as Activity;

  // == Update the FinCEN old state so it is in sync with the new state
  updatedValues = setIn(
    updatedValues,
    'currencyTransactionActivity.totalCashInReceiveAmountText',
    updatedTransactionActivityDetails.totalCashIn || 0,
  ) as Activity;

  updatedValues = setIn(
    updatedValues,
    'currencyTransactionActivity.totalCashOutAmountText',
    updatedTransactionActivityDetails.totalCashOut || 0,
  ) as Activity;

  return updatedValues;
};

export const clearActivityTopLevelValues = (valuesToUpdate: Activity) => {
  const updatedValues = { ...valuesToUpdate };

  // We only send the prior document number if the activity corrects a prior filing
  if (
    updatedValues.activityAssociation !==
    ActivityAssociationValue.CORRECTS_AMENDS_PRIOR_REPORT
  ) {
    updatedValues.eFilingPriorDocumentNumber = '';
  }

  return updatedValues;
};

export const getCTRFormPayloadFromFormState = (
  formState: Activity,
): Activity => {
  let updatedValues = cloneDeep(formState ?? {});

  // === Transactions ===
  updatedValues = clearStaleTransactionsValues(updatedValues);

  //  === Involved Persons ===
  updatedValues = clearStaleInvolvedPersonsValues(updatedValues);

  //  === Transaction Location ===
  updatedValues = clearStaleTransactionLocationValues(updatedValues);

  // === Financial Institution ===
  updatedValues = clearStaleFinancialInstitutionValues(updatedValues);

  // === Clear Activity top level values ===
  updatedValues = clearActivityTopLevelValues(updatedValues);

  return updatedValues;
};

// We use it for showing the error message on individual input fields
export const getValidationFunction = (
  field:
    | FormFieldSchema<any>
    | FormFieldCustomSchema<any>
    | FormFieldArraySchema<any>,
  validationErrors?: ValidationErrors,
): {
  validate: ValidateFn<any, Record<string, any>> | undefined;
} => {
  return {
    validate:
      validationErrors?.fields[field.name]?.validateFn || field.validate,
  };
};

export const generateErrorFunction =
  (message: string = 'Required.'): ValidateFn<any, Record<string, any>> =>
  (value, state, formProps) => {
    if (!formProps?.dirty) return message;
    return undefined;
  };

const SECTION_ERROR_PATHS: {
  [key in Exclude<ValueOf<typeof TABS>, 'overview'>]: string[];
} = {
  // We show an error icon on the section if any of the paths include the following keys
  home: ['activityAssociation'],
  'financial-institution': ['reportingFinancialInstitution', 'contactOffice'],
  'transaction-locations': ['transactionLocations'],
  persons: ['personsInvolved'],
  transactions: [
    // FinCEN old state
    'currencyTransactionActivity',
    // Custom state
    'transactionActivityDetails',
  ],
};

// This object stores the regex for each section (generated by joining the array of strings defined above)
const SECTION_REGEX_MATCHER = Object.keys(SECTION_ERROR_PATHS).reduce(
  (acc, sectionKey) => ({
    ...acc,
    [sectionKey]: new RegExp(SECTION_ERROR_PATHS[sectionKey].join('|'), 'g'),
  }),
  {} as { [k: string]: RegExp },
);

export const formatBEErrors = (
  rawFormErrors: {
    path: string;
    message: string;
  }[],
): ValidationErrors => {
  // Prepare sections response
  const sectionErrors = Object.keys(SECTION_REGEX_MATCHER).reduce(
    (acc, sectionKey) => ({
      ...acc,
      [sectionKey]: false,
    }),
    {} as ValidationErrors['sections'],
  );

  // Iterate over all errors and keep track of which sections have errors
  const fieldErrors = (rawFormErrors || []).reduce(
    (allErrors, { path, message }) => {
      // Check if the path matches any of the sections (TODO improve so we don't iterate over already spotted sections)
      Object.keys(SECTION_REGEX_MATCHER).forEach((sectionKey) => {
        if (
          !sectionErrors[sectionKey] &&
          path.match(SECTION_REGEX_MATCHER[sectionKey])
        ) {
          sectionErrors[sectionKey] = true;
        }
      });

      // Return the error object
      return {
        ...allErrors,
        [path]: {
          message,
          validateFn: generateErrorFunction(message),
        },
      };
    },
    {} as ValidationErrors['fields'],
  );

  return {
    fields: fieldErrors,
    sections: sectionErrors,
  };
};

const sumAmounts = (inputValues: number[]): number =>
  inputValues.reduce((accumulatedSum, currentAmount) => {
    if (currentAmount && !isNaN(Number(currentAmount))) {
      return accumulatedSum + Number(currentAmount);
    }
    return accumulatedSum;
  }, 0);

export const getTxnActivityTotalCash = ({
  values,
}:
  | {
      type: 'cashIn';
      values: Omit<
        TransactionActivityDetails['cashIn'],
        'otherCashInDescription' | 'foreignCurrencyIn'
      >;
    }
  | {
      type: 'cashOut';
      values: Omit<
        TransactionActivityDetails['cashOut'],
        'otherCashOutDescription' | 'foreignCurrencyOut'
      >;
    }): number => sumAmounts(Object.values(values));

export const isCtrEndStatus = (ctrStatus: CtrFilingStatus): boolean => {
  return [
    CtrFilingStatus.ACCEPTED_WITH_WARNINGS,
    CtrFilingStatus.ACCEPTED_BY_FINCEN,
    CtrFilingStatus.REJECTED_BY_FINCEN,
  ].includes(ctrStatus);
};
