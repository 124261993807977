import retrieveDropdownAgents from 'app/shared/dropdown/sagas/retrieveDropdownAgents';
import retrieveDropdownTeams from 'app/shared/dropdown/sagas/retrieveDropdownTeams';
import retrieveDropdownOrgs from 'app/shared/dropdown/sagas/retrieveDropdownOrgs';

const sagaRegistry = [
  retrieveDropdownAgents,
  retrieveDropdownTeams,
  retrieveDropdownOrgs,
];

export default sagaRegistry;
