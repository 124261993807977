import { UpdateConfigPayload } from 'app/modules/dataMapping/requests';
import { StreamConfigResponse } from 'app/modules/dataMapping/responses';
import { API_BASE } from 'app/modules/pullBasedDataFiles/api';
import { get, post } from 'app/shared/utils/fetchr';

export const getConfig = (streamId: number): Promise<StreamConfigResponse> =>
  get(`${API_BASE}/streams/${streamId}/annotation-transformation-configs`);

export const updateConfig = (payload: UpdateConfigPayload): Promise<void> =>
  post(`${API_BASE}/annotation-transformation-configs`, payload);
