import {
  FileExportResponse,
  FileExportsResponse,
} from 'app/modules/fileExports/responses';
import {
  CancelFileExportPayload,
  FileExportDownloadPayload,
  PaginatedFileExportsPayload,
} from 'app/modules/fileExports/requests';

import { downloadFile, post } from 'app/shared/utils/fetchr';

const API_BASE = '/file-exports';

export const getFileExports = (
  payload: PaginatedFileExportsPayload,
): Promise<FileExportsResponse> => {
  return post(`${API_BASE}/list`, payload);
};

export const downloadFileExport = (
  payload: FileExportDownloadPayload,
): Promise<Record<string, never>> => {
  const { fileName, id } = payload;
  return downloadFile(`${API_BASE}/download/${id}`, fileName);
};

export const cancelFileExport = ({
  id,
}: CancelFileExportPayload): Promise<FileExportResponse> => {
  return post(`${API_BASE}/cancel/${id}`);
};
