import { PayloadAction } from '@reduxjs/toolkit';

import { u21CreateSlice } from 'app/shared/thunk/u21CreateSlice';
import { EvaluationLog } from 'app/shared/events/models';
import { u21CreateAsyncThunk } from 'app/shared/thunk/u21CreateAsyncThunk';
import routes from 'app/shared/utils/routes';
import { get } from 'app/shared/utils/fetchr';

const EVENTS_NAME = 'sliceEvents';

interface EventSliceState {
  evaluationLog: EvaluationLog;
  loadingEvaluationLog: boolean;
}

const initialState: Readonly<EventSliceState> = {
  evaluationLog: {
    evaluation_id: '',
    event_id: '',
    rule_executions: {},
  },
  loadingEvaluationLog: false,
};

export const retrieveEvaluationLogThunk = u21CreateAsyncThunk(
  `${EVENTS_NAME}/RETRIEVE_TRANSACTION_EVALUATION_LOG`,
  async (id: string) => {
    try {
      const path = routes.patronus.txnEventsEvaluationLogEventId.replace(
        ':eventId',
        id,
      );
      return await get(path);
    } catch (e) {
      return initialState.evaluationLog;
    }
  },
);

const eventsSlice = u21CreateSlice({
  name: EVENTS_NAME,
  initialState,
  reducers: {
    clearEvaluationLog: (draft) => {
      draft.evaluationLog = initialState.evaluationLog;
    },
  },
  extraReducers: (builder) => {
    builder.addLoadingCase(
      retrieveEvaluationLogThunk,
      'loadingEvaluationLog',
      (draft, action: PayloadAction<EvaluationLog>) => {
        draft.evaluationLog = action.payload;
      },
    );
  },
});

export const EVENTS_SLICE_NAME = eventsSlice.name;
export default eventsSlice.reducer;
export const { name, reducer, actions } = eventsSlice;
export const { clearEvaluationLog } = actions;
