import { call, put, takeLatest } from 'redux-saga/effects';

// Actions
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';
import {
  retrieveWhitelistedEntities as retrieveWhitelistedEntitiesAction,
  retrieveWhitelistedEntitiesSuccess,
} from 'app/modules/rules/actions';

// API
import { getRuleWhitelistedEntities } from 'app/shared/api/rules';

// Models
import { RulesActionTypes } from 'app/modules/rules/models';

const rootAction = RulesActionTypes.RETRIEVE_WHITELISTED_ENTITIES;
function* retrieveWhitelistedEntities({
  payload,
}: ReturnType<typeof retrieveWhitelistedEntitiesAction>) {
  const config = {
    rootAction,
    request: call(getRuleWhitelistedEntities, payload),
    success: function* onSuccess(result) {
      yield put(retrieveWhitelistedEntitiesSuccess(result));
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watchRetrieveWhitelistedEntities() {
  yield takeLatest(rootAction, retrieveWhitelistedEntities);
}
