// Saga
import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Api
import { appendFileToBlacklist as appendFileToBlacklistApi } from 'app/shared/api/blacklists';

// Models
import { BlacklistActionTypes } from 'app/modules/lists/models';

// Actions
import {
  appendFileToBlacklistSuccess,
  appendFileToBlacklist as appendFileToBlacklistAction,
  retrieveBlacklistItems,
} from 'app/modules/lists/actions';

// Utils
import { BLACKLIST_ITEMS_LIMIT } from 'app/modules/lists/constants';
import { sendSuccessToast, sendErrorToast } from 'app/shared/toasts/actions';

const rootAction = BlacklistActionTypes.APPEND_FILE_TO_BLACKLIST;
function* appendFileToBlacklist({
  payload,
}: ReturnType<typeof appendFileToBlacklistAction>) {
  const { ...request } = payload;
  const config = {
    rootAction,
    request: call(appendFileToBlacklistApi, request),
    success: function* onSuccess(result) {
      yield put(appendFileToBlacklistSuccess(result));
      if (result.status && result.status === 'OK') {
        yield put(
          retrieveBlacklistItems({
            id: request.id,
            offset: 1,
            limit: BLACKLIST_ITEMS_LIMIT,
          }),
        );
        yield put(sendSuccessToast(result.message));
      } else if (result.status && result.status === 'ERROR') {
        yield put(sendErrorToast(result.message));
      }
    },
    errorToastMessage: 'Failed to upload blacklist file',
  };
  yield call(makeSagaRequest, config);
}

export default function* watchAppendFileToBlacklist() {
  yield takeLatest(rootAction, appendFileToBlacklist);
}
