// Saga
import { takeLatest, select, put, all } from 'redux-saga/effects';

// Models
import {
  InsightsActionTypes,
  DashboardDetails,
} from 'app/modules/insights/models';

// Actions
import { retrieveChart } from 'app/modules/insights/actions';

// Selectors
import { selectDashboard } from 'app/modules/insights/selectors';

const rootAction = InsightsActionTypes.RETRIEVE_CHARTS;
function* retrieveCharts() {
  // the purpose of this saga is retrieve all charts on the currently selected dashboard
  const dashboard: DashboardDetails = yield select(selectDashboard);
  const actions = dashboard.charts.map((id) => {
    return put(retrieveChart({ id }));
  });

  yield all(actions);
}

export default function* watchRetrieveCharts() {
  yield takeLatest(rootAction, retrieveCharts);
}
