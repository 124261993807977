export const removeNullKeys = (obj: object) => {
  const newObj = {};

  Object.keys(obj).forEach((key) => {
    if (
      obj[key] !== null &&
      obj[key] !== undefined &&
      !(typeof obj[key] === 'number' && isNaN(obj[key]))
    ) {
      newObj[key] = obj[key];
    }
  });

  return newObj;
};

export const downloadBlobFromResponse = async (
  response: Response,
  fileName: string,
): Promise<void> => {
  const blob = await response.blob();
  const url = window.URL.createObjectURL(new Blob([blob]));
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.style.display = 'none';
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
};
