import React from 'react';

// Components
import { Button, ButtonProps } from 'semantic-ui-react';
import ReactTooltip from 'react-tooltip';

// Helpers
import { isSuitableBackgroundColor } from 'app/shared/helpers';

// Styles
import styles from 'app/shared/styles/GenericButton.module.scss';

export interface AllProps extends ButtonProps {
  // id must be passed in with dynamicColor, only way it can work
  dynamicColor?: string;
  // to indicate the button color should be filled
  filled?: boolean;
  loading?: boolean;

  // props for tooltips to be displayed when button is disabled
  disabledTooltips?: boolean;
  dataFor?: string;
  dataTip?: string;
}

// Using this in place of import semantic UI button directly so we can uniform styling
/**
 * @deprecated Use U21Button instead
 */
const GenericButton = (props: AllProps) => {
  const {
    dynamicColor,
    filled,
    loading,
    disabledTooltips,
    dataFor,
    dataTip,
    id,
    className,
    children,
    disabled,
    ...restOfProps
  } = props;

  const renderDynamicColor = () => {
    // avoiding the use of semantic uis color prop so we are not limited to their colors. we can now pass in any css color to easily color our buttons
    // !important is needed and that is not supported with inline styling;
    // need to use a dynamic style tag and id described here
    // https://stackoverflow.com/questions/23074748/important-inline-styles-in-react
    // id must be passed in with dynamicColor, only way it can work
    if (dynamicColor && id && !filled) {
      return (
        <style>
          {`
        #${id} {
            color: ${dynamicColor} !important;
            -webkit-box-shadow: 0 0 0 1px ${dynamicColor} inset!important;
            box-shadow: 0 0 0 1px ${dynamicColor} inset!important;
          }
        `}
        </style>
      );
    }

    if (dynamicColor && id && filled) {
      // want to show the button as filled in color with a dynamicColor (color not supported by semantic UI colors)
      const textColor = isSuitableBackgroundColor(dynamicColor)
        ? 'white'
        : 'black';
      return (
        <style>
          {`
        #${id} {
            color: ${textColor} !important;
            background-color: ${dynamicColor} !important;
          }
        `}
        </style>
      );
    }
    return null;
  };

  const renderDisabledButtonWithTooltip = () => {
    // render button that shows tooltip only when disabled
    // if a button is disabled it normally will not show it's corresponding ReactTooltip if it has one
    // workaround is to wrap Button in an element and give the wrapper the data-for and data-tip props
    // https://github.com/wwayne/react-tooltip/issues/304
    const recombineProps = {
      children,
      id,
      className,
      disabled,
      ...restOfProps,
    };
    return (
      <>
        <div data-for={dataFor} data-tip={dataTip}>
          <Button
            // overriding button props here
            basic
            loading={loading}
            {...recombineProps}
            className={`${styles.button} ${className || ''}`}
          >
            {children}
          </Button>
        </div>

        <ReactTooltip
          id={dataFor}
          effect="solid"
          place="right"
          getContent={() => <p>{dataTip}</p>}
        />
      </>
    );
  };

  const renderGenericButton = () => {
    const recombineProps = {
      children,
      id,
      className,
      disabled,
      ...restOfProps,
    };
    if (disabledTooltips && disabled) {
      return renderDisabledButtonWithTooltip();
    }
    return (
      <Button
        // overriding button props here
        basic
        loading={loading}
        {...recombineProps}
        className={`${styles.button} ${className || ''}`}
      >
        {children}
      </Button>
    );
  };

  return (
    <>
      {renderDynamicColor()}
      {renderGenericButton()}
    </>
  );
};

export default GenericButton;
