import { FullTagResponse } from 'app/modules/tags/responses';

import { Label } from 'semantic-ui-react';

// Helpers
import { getLabelStyle } from 'app/shared/u21-ui/components/tables/helpers';

// Styles
import scssStyles from 'app/shared/events/styles/EventTable.module.scss';

interface Props {
  value: FullTagResponse[];
}

export const Tags = ({ value }: Props) => {
  if (value === undefined || value === null || value.length === 0) {
    return <div />;
  }
  return (
    <Label.Group>
      {value.map((tag) => {
        const displayValue = `${tag.type}:${tag.name}`;
        return (
          <Label
            key={displayValue}
            className={scssStyles.valueLabel}
            style={getLabelStyle(displayValue)}
          >
            {displayValue}
          </Label>
        );
      })}
    </Label.Group>
  );
};
