import { createSelector } from 'reselect';
import { selectOrgEnums } from 'app/modules/orgSettings/selectors';
import { ACTION_EVENTS_SLICE_NAME } from 'app/modules/actionEvents/sliceActionEvents';

const EMPTY_ARRAY = [] as const;

export const selectActionEventTypes = createSelector(
  selectOrgEnums,
  (orgEnums): string[] =>
    (orgEnums?.standard?.action_event_types?.values as string[]) ?? EMPTY_ARRAY,
);

export const selectFilters = (state: RootState) =>
  state[ACTION_EVENTS_SLICE_NAME].filters;
