// Models
import { AnalyticsConfig } from 'app/shared/u21-ui/analytics/models';

// Constants
import { ANALYTICS_CONFIG_LOCATION } from 'app/shared/u21-ui/analytics/constants';

export const initAnalytics = (config: AnalyticsConfig) => {
  window[ANALYTICS_CONFIG_LOCATION] = config;
};

export const getAnalyticsConfig = (): AnalyticsConfig | undefined => {
  return window[ANALYTICS_CONFIG_LOCATION];
};

export const clearAnalyticsConfig = () => {
  delete window[ANALYTICS_CONFIG_LOCATION];
};

export const formatPayload = (
  payload: Record<string, any>,
): Record<string, string | number | boolean> => {
  return Object.keys(payload).reduce((acc, i) => {
    const value = payload[i];
    if (
      typeof value === 'string' ||
      typeof value === 'number' ||
      typeof value === 'boolean'
    ) {
      acc[i] = value;
    } else if (value instanceof Date) {
      acc[i] = value.toISOString();
    } else if (typeof value === 'object') {
      try {
        acc[i] = JSON.stringify(value);
      } catch {}

      // null is an object
      if (value) {
        acc[`${i}_length`] = Array.isArray(value)
          ? value.length
          : Object.keys(value).length;
      }
    }

    return acc;
  }, {});
};
