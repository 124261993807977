import { ORG_SETTINGS_SLICE_NAME } from 'app/modules/orgSettings/sliceOrgSettings';
import {
  DefaultTagConfig,
  EntityExternalLinkModel,
  LumosConfig,
  SelectFormatAmountPayload,
} from 'app/modules/orgSettings/models';
import { OrgEnums } from 'app/modules/orgSettings/responses';
import { createSelector } from 'reselect';
import { selectWorkaroundHideCurrencyCode } from 'app/shared/featureFlags/selectors';
import {
  AMOUNT_FORMAT,
  LARGE_AMOUNT_FORMAT,
  SHORT_DECIMAL_FORMAT,
} from 'app/shared/constants';
import numeral from 'numeral';
import { isEqual } from 'lodash';
import { selectAllTags } from 'app/modules/teamsOld/selectors';
import { FullTagResponse } from 'app/modules/tags/responses';
import { OrgFeature } from 'app/modules/orgManagement/models';
import { INITIAL_DEFAULT_TAG } from 'app/modules/orgSettings/constants';

export const selectLoadingUpdateGDriveFolder = (state: RootState) =>
  state[ORG_SETTINGS_SLICE_NAME].loadingUpdateGDriveFolder;

export const selectLoadingOrgSettings = (state: RootState) =>
  state[ORG_SETTINGS_SLICE_NAME].loadingOrgSettings;

export const selectOrgFeatures = (state: RootState) => {
  return state[ORG_SETTINGS_SLICE_NAME].orgFeatures;
};
export const selectAiInvestigationsOrgFeatureEnabled = createSelector(
  selectOrgFeatures,
  (orgFeatures): boolean =>
    orgFeatures.indexOf(OrgFeature.ALL) >= 0 ||
    orgFeatures.indexOf(OrgFeature.AI_INVESTIGATIONS) >= 0,
);

export const selectQueryBuilderConfig = (state: RootState) => {
  return state[ORG_SETTINGS_SLICE_NAME].queryBuilderConfig;
};

export const selectQueryBuildConfigAggregateFieldsExist = createSelector(
  selectQueryBuilderConfig,
  (queryBuilderConfig): boolean => {
    return Boolean(
      queryBuilderConfig.aggregate_fields &&
        Object.keys(queryBuilderConfig.aggregate_fields).length > 0,
    );
  },
);

export const selectOrgScenarioConfig = (state: RootState) => {
  return state[ORG_SETTINGS_SLICE_NAME].scenarioConfig;
};

export const selectOrgRuleExecutionConfig = (state: RootState) => {
  return state[ORG_SETTINGS_SLICE_NAME].ruleExecutionConfig;
};

export const selectHomeCurrencyCode = (state: RootState) => {
  return state[ORG_SETTINGS_SLICE_NAME].homeCurrencyCode;
};

export const selectCurrencyConfig = (state: RootState) => {
  return state[ORG_SETTINGS_SLICE_NAME].currencyConfig;
};

export const selectRealTimeRulesEnabled = (state: RootState) => {
  return state[ORG_SETTINGS_SLICE_NAME].realTimeRulesConfig.enabled;
};

export const selectFormatAmount = createSelector(
  selectHomeCurrencyCode,
  selectCurrencyConfig,
  selectWorkaroundHideCurrencyCode,
  (homeCurrencyCode, config, hideCurrencyCode) => {
    return (payload: SelectFormatAmountPayload = { amount: 0 }) => {
      const {
        amount,
        largeFormat,
        currencyCodeProps,
        shortenDecimalPlaces = true,
        precision,
      } = payload;

      // return empty string if amount does not exist or is not of type number
      if (typeof amount !== 'number') return '';
      // use home currency code if no other currency code is passed in
      const codeToUse = String(
        currencyCodeProps || homeCurrencyCode,
      ).toUpperCase();
      const dataAtConfig = config[codeToUse] || {};

      let symbolToShow: string = codeToUse ? `${codeToUse} ` : '';
      // show USD, BTC, etc. (currency code) if the symbol does not exist
      if (
        typeof dataAtConfig.symbol === 'string' &&
        dataAtConfig.symbol.length > 0
      ) {
        symbolToShow = dataAtConfig.symbol;
      }

      let formatString = largeFormat ? LARGE_AMOUNT_FORMAT : AMOUNT_FORMAT;
      const useDecimalFormat = Boolean(
        dataAtConfig.max_decimal_places && dataAtConfig.max_decimal_places > 2,
      );

      // more than two decimal places and want to show a shortened version (i.e. 1.24e-4)
      const useShortDecimalFormat = Boolean(
        useDecimalFormat && shortenDecimalPlaces,
      );
      if (useShortDecimalFormat) {
        formatString = SHORT_DECIMAL_FORMAT;
      }

      // more than two decimal places and to show long version (i.e. 1.232323...);
      if (
        Boolean(useDecimalFormat && !shortenDecimalPlaces) &&
        typeof dataAtConfig.max_decimal_places === 'number'
      ) {
        formatString = `0.[${'0'.repeat(dataAtConfig.max_decimal_places)}]`;
      }

      /** Workaround to force the hiding of usd
       * as a symbol in the app. Check the utilization
       * of the feature flag before removal.
       */
      if (hideCurrencyCode) {
        if (symbolToShow === '$') {
          symbolToShow = '';
        }
      }

      if (typeof precision === 'number') {
        const decimalPlaces = (amount.toString().split('.')[1] || '').length;
        // when precision <= 2, force precision regardless of decimal places
        // when precision > 2, force numbers with < 2 decimal places to fill up to precision of 2
        if (precision <= 2) {
          formatString = `0,0.${'0'.repeat(precision)}`;
        } else if (decimalPlaces < 2) {
          formatString = `0,0.${'0'.repeat(2)}`;
        } else {
          formatString = `0,0.[${'0'.repeat(precision)}]`;
        }
      }

      return `${symbolToShow}${numeral(amount).format(formatString)}`;
    };
  },
);

export const selectLumosConfig = (state: RootState): LumosConfig => {
  return state[ORG_SETTINGS_SLICE_NAME].lumosConfig;
};

const EMPTY_ARRAY = [];

export const selectEntityExternalLinks = createSelector(
  selectLumosConfig,
  (lumosConfig): EntityExternalLinkModel[] =>
    lumosConfig.entity_page.external_links || EMPTY_ARRAY,
);

export const selectLogoutAfterInactivity = createSelector(
  selectLumosConfig,
  (lumosConfig) => {
    return lumosConfig.logout_after_inactivity;
  },
);

export const selectTrackLastActivity = createSelector(
  selectLogoutAfterInactivity,
  (logoutAfterInactivity) => {
    // add any additional checks here in the future for when we want to track this
    // currently, we just want to track the last request when the logout_after_inactivity key exists in lumos_config
    // this is so we can log a user out after a specified amount of time of inactivity
    return Boolean(logoutAfterInactivity);
  },
);

export const selectDefaultTagConfig = createSelector(
  selectLumosConfig,
  (lumosConfig): DefaultTagConfig => {
    return lumosConfig.default_tag_config || INITIAL_DEFAULT_TAG;
  },
);

export const selectDefaultTagConfigExists = createSelector(
  selectDefaultTagConfig,
  (defaultTagConfig): boolean => {
    return !isEqual(defaultTagConfig, INITIAL_DEFAULT_TAG);
  },
);

// corresponding tag ids to the tag_types in the default tag config
export const selectDefaultTagConfigTagIds = createSelector(
  selectDefaultTagConfigExists,
  selectDefaultTagConfig,
  selectAllTags,
  (defaultTagConfigExists, defaultTagConfig, allTags): number[] => {
    if (!defaultTagConfigExists) {
      return [];
    }
    const tagIds: number[] = [];
    Object.values(allTags).forEach((tag: FullTagResponse) => {
      if (defaultTagConfig.tag_types.includes(tag.type)) {
        tagIds.push(tag.id);
      }
    });
    return tagIds;
  },
);

// helper that takes in a list of tag ids and returns a string list of the tag names
export const selectGetDefaultTagConfigNames = createSelector(
  selectDefaultTagConfigExists,
  selectDefaultTagConfigTagIds,
  selectAllTags,
  (defaultTagsExist, defaultTagConfigIds, allTags) => {
    return (tags: FullTagResponse[]): string[] => {
      const tagNames: string[] = [];
      // handle case where tag ids is not an array
      if (!Array.isArray(tags)) {
        return tagNames;
      }
      // return if default tags do not exist
      if (!defaultTagsExist) {
        return tagNames;
      }

      const tagIds = tags.map((tag) => tag.id);
      Object.values(allTags).forEach((tag: FullTagResponse) => {
        const { id } = tag;
        if (defaultTagConfigIds.includes(id) && tagIds.includes(id)) {
          tagNames.push(tag.name);
        }
      });
      return tagNames;
    };
  },
);

export const selectConsortiumEnabled = (state: RootState): boolean => {
  return Boolean(state[ORG_SETTINGS_SLICE_NAME].consortiumConfig.enabled);
};
export const selectOrgEnums = (state: RootState): OrgEnums => {
  return state[ORG_SETTINGS_SLICE_NAME].orgEnums;
};

export const selectOrgEventStatuses = createSelector(
  selectOrgEnums,
  (orgEnums) => orgEnums.standard.event_statuses?.values || EMPTY_ARRAY,
);

const selectAlertConfig = (state: RootState) => {
  return state[ORG_SETTINGS_SLICE_NAME].alertConfig;
};

export const selectAlertConfigDisableCaseCreateModal = createSelector(
  selectAlertConfig,
  (alertConfig) => {
    return Boolean(alertConfig.disable_case_create_modal);
  },
);

export const selectAlertHitGenerationLimit = createSelector(
  selectAlertConfig,
  (alertConfig) => alertConfig.alert_hit_generation_limit || 1000,
);

export const selectAlertTypesEnabled = createSelector(
  selectAlertConfig,
  (alertConfig) => alertConfig.alert_types_enabled || EMPTY_ARRAY,
);

export const selectHideAlertExport = createSelector(
  selectAlertConfig,
  (alertConfig) => Boolean(alertConfig.hide_alert_export),
);

export const selectAlertEnabledFeatures = createSelector(
  selectAlertConfig,
  (alertConfig) => alertConfig.enabled_features || EMPTY_ARRAY,
);

export const selectSarConfig = (state: RootState) => {
  return state[ORG_SETTINGS_SLICE_NAME].sarConfig;
};

export const selectStorageConfig = (state: RootState) => {
  return state[ORG_SETTINGS_SLICE_NAME].storageConfig;
};

export const selectGDriveFolderID = createSelector(
  selectStorageConfig,
  (storageConfig) => storageConfig.folder_id || '',
);

export const selectStorageResources = (state: RootState) => {
  return state[ORG_SETTINGS_SLICE_NAME].storageResources;
};

export const selectOrgDataConfig = (state: RootState) =>
  state[ORG_SETTINGS_SLICE_NAME].deltaLakeDataConfig;

export const selectIsDataConfigDeltaLake = createSelector(
  selectOrgDataConfig,
  (dataConfig) => dataConfig === 'DELTA_LAKE',
);

export const selectFfipPgpEncryptionEnabled = (state: RootState) => {
  return state[ORG_SETTINGS_SLICE_NAME].ffipPgpEncryptionEnabled;
};

export const selectFinCEN314aScanConfig = (state: RootState) => {
  return state[ORG_SETTINGS_SLICE_NAME].finCEN314aScanConfig;
};

export const selectPCIOrgFeatureEnabled = createSelector(
  selectOrgFeatures,
  (orgFeatures): boolean => orgFeatures.indexOf(OrgFeature.PCI) >= 0,
);
