import {
  FC,
  MouseEventHandler,
  ReactElement,
  ReactNode,
  SyntheticEvent,
} from 'react';

import { getDOMProps } from 'app/shared/utils/react';

import {
  U21ModalBase,
  U21ModalBaseProps,
} from 'app/shared/u21-ui/components/layout/modal/U21ModalBase';
import { U21ModalBody } from 'app/shared/u21-ui/components/layout/modal/U21ModalBody';
import { U21ModalFooter } from 'app/shared/u21-ui/components/layout/modal/U21ModalFooter';
import { U21ModalHeader } from 'app/shared/u21-ui/components/layout/modal/U21ModalHeader';
import { U21Typography } from 'app/shared/u21-ui/components/display/typography/U21Typography';
import {
  U21Button,
  U21ButtonProps,
} from 'app/shared/u21-ui/components/input/U21Button';
import { U21Loading } from 'app/shared/u21-ui/components/display/U21Loading';

// Utils
import styled from 'styled-components';

export interface U21ModalProps
  extends Omit<U21ModalBaseProps, 'onClose' | 'title'> {
  actionButtonProps?: Omit<U21ButtonProps, 'onClick'>;
  closeButtonProps?: Omit<U21ButtonProps, 'onClick'>;
  loading?: boolean;
  onAction?: MouseEventHandler<HTMLButtonElement>;
  onClose: (event: SyntheticEvent) => void;
  title?: ReactNode;
  titleIcon?: ReactElement;
  titleTooltip?: string;
  subtitle?: ReactNode;
  footerLeftAction?: ReactNode;
  headerAction?: ReactNode;
}

export const U21Modal: FC<U21ModalProps> = ({
  actionButtonProps,
  children,
  closeButtonProps,
  loading,
  onAction,
  onClose,
  onEnter,
  onExited,
  open,
  size,
  title,
  titleIcon,
  titleTooltip,
  subtitle,
  footerLeftAction,
  headerAction,
  ...rest
}) => {
  return (
    <U21ModalBase
      onEnter={onEnter}
      onExited={onExited}
      open={open}
      size={size}
      {...getDOMProps(rest)}
    >
      <U21ModalHeader
        icon={titleIcon}
        onClose={onClose}
        tooltip={titleTooltip}
        subtitle={subtitle}
        action={headerAction}
      >
        {title}
      </U21ModalHeader>
      <StyledU21Loading loading={loading} />
      {!loading && (
        <U21ModalBody>
          {typeof children === 'string' ? (
            <U21Typography variant="subtitle2">{children}</U21Typography>
          ) : (
            children
          )}
        </U21ModalBody>
      )}
      <U21ModalFooter leftAction={footerLeftAction}>
        {/* eslint-disable-next-line react/no-children-prop */}
        <U21Button children="Cancel" {...closeButtonProps} onClick={onClose} />
        {(Boolean(onAction) || Boolean(actionButtonProps)) && (
          <U21Button
            // eslint-disable-next-line react/no-children-prop
            children="Confirm"
            color="primary"
            variant="contained"
            {...actionButtonProps}
            onClick={onAction}
          />
        )}
      </U21ModalFooter>
    </U21ModalBase>
  );
};

const StyledU21Loading = styled(U21Loading)`
  padding: 24px;
`;
