import {
  IconChevronDown,
  IconChevronUp,
  IconDropletOff,
  IconPalette,
  IconPaletteOff,
} from '@u21/tabler-icons';
import { U21Tooltip } from 'app/shared/u21-ui/components/display/U21Tooltip';
import { U21Typography } from 'app/shared/u21-ui/components/display/typography/U21Typography';
import { U21Button } from 'app/shared/u21-ui/components/input/U21Button';
import { U21MenuLayout } from 'app/shared/u21-ui/components/layout/U21MenuLayout';
import { U21Spacer } from 'app/shared/u21-ui/components/layout/U21Spacer';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { HexColorPicker } from 'react-colorful';
import { U21TextField } from 'app/shared/u21-ui/components/input/text-field/U21TextField';
import { U21Themes } from 'vendor/material-minimal/models';

const DEFAULT_COLORS = [
  '#0C53B7',
  '#1890FF',
  '#74CAFF',
  '#328185',
  '#43AAB1',
  '#6BC2C7',
  '#7CA00E',
  '#A3D211',
  '#C3EB45',
  '#B78103',
  '#FFC107',
  '#FFE16A',
  '#C54640',
  '#F46059',
  '#F9A287',
  '#7D1F8D',
  '#9C3CAC',
  '#EB71FF',
];

const DEFAULT_COLORS_SET = new Set(DEFAULT_COLORS);

const DEFAULT_COLOR_ROWS = [
  DEFAULT_COLORS.slice(0, DEFAULT_COLORS.length / 2),
  DEFAULT_COLORS.slice(DEFAULT_COLORS.length / 2),
];

interface U21ColorPickerProps {
  value: string;
  onChange: (color: string) => void;
  preview?: ReactNode;
}

export const U21ColorPicker = ({
  value,
  onChange,
  preview,
}: U21ColorPickerProps) => {
  const [showColorPicker, setShowColorPicker] = useState<boolean>(false);
  const [showGradient, setShowGradient] = useState<boolean>(false);
  const toggleColorPicker = useCallback(
    () => setShowColorPicker((prev) => !prev),
    [setShowColorPicker],
  );
  const trigger = useMemo(
    () => (
      <U21Tooltip tooltip={value || 'No color selected'}>
        <SwatchWrapper
          onClick={(e) => {
            e.stopPropagation();
            toggleColorPicker();
          }}
        >
          <NullableSwatch $color={value} />
          <U21Button
            icon={showColorPicker ? <IconChevronUp /> : <IconChevronDown />}
            size="small"
          />
        </SwatchWrapper>
      </U21Tooltip>
    ),
    [value, showColorPicker, toggleColorPicker],
  );
  const [hexValue, setHexValue] = useState<string>(value);
  const handleTextInput = useCallback(
    (val: string) => {
      setHexValue(val ?? '');
      if (!val || /^#[0-9A-F]{6}$/i.test(val)) {
        onChange(val ?? '');
        // prefix with # if not present
      } else if (/^[0-9A-F]{6}$/i.test(val)) {
        onChange(`#${val}`);
      }
    },
    [setHexValue, onChange],
  );
  useEffect(() => {
    setHexValue(value);
  }, [value]);

  return (
    <U21MenuLayout
      trigger={trigger}
      open={showColorPicker}
      onClose={() => {
        setShowColorPicker(false);
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <U21Spacer spacing={1.5}>
        {(showGradient ||
          // show hex picker if color is not in defaults
          (!value && DEFAULT_COLORS_SET.has(value))) && (
          <U21Spacer align="center">
            <HexColorPickerWrapper>
              <HexColorPicker
                color={/^#?[0-9A-F]{6}$/i.test(value) ? value : undefined}
                onChange={handleTextInput}
              />
            </HexColorPickerWrapper>
            <StyledSpacer horizontal align="center">
              <div>
                <NullableSwatch $color={value} />
              </div>
              <U21TextField
                value={hexValue}
                label="Hex"
                onChange={handleTextInput}
                placeholder="#1890FF"
                size="small"
              />
            </StyledSpacer>
          </U21Spacer>
        )}
        <U21Typography variant="subtitle2">Default Colors</U21Typography>
        <U21Spacer>
          {DEFAULT_COLOR_ROWS.map((row) => (
            <U21Spacer key={row[0]} horizontal wrap>
              {row.map((c) => (
                <ClickableSwatch
                  key={c}
                  $selected={c === value}
                  $color={c}
                  onClick={() => onChange(c)}
                />
              ))}
            </U21Spacer>
          ))}
        </U21Spacer>
        <U21Spacer horizontal align="end">
          <U21Button
            size="small"
            tooltip={`${showGradient ? 'Hide' : 'Show'} custom colors`}
            onClick={() => {
              setShowGradient((prev) => !prev);
            }}
          >
            {showGradient ? <IconPaletteOff /> : <IconPalette />}
          </U21Button>
          {!!value && (
            <U21Button
              size="small"
              tooltip="No color"
              onClick={() => {
                onChange('');
              }}
            >
              <IconDropletOff />
            </U21Button>
          )}
          {!!preview && <PreviewWrapper horizontal>{preview}</PreviewWrapper>}
        </U21Spacer>
      </U21Spacer>
    </U21MenuLayout>
  );
};

const BaseSwatch = styled.div<{ $color: string }>`
  ${(props) => css`
    border-radius: 6px;
    background-color: ${props.$color || 'transparent'};
    width: 22px;
    height: 22px;
  `}
`;

const NullableSwatch = styled(BaseSwatch)`
  ${(props) => css`
    overflow: hidden;
    border: 2px solid
      ${(() => {
        if (props.$color) {
          return 'transparent';
        }
        return props.theme.palette.mode === U21Themes.DARK
          ? props.theme.palette.white
          : props.theme.palette.grey[800];
      })()};
    ${!props.$color
      ? css`
          &::after {
            content: '';
            display: block;
            width: ${22 * Math.SQRT2}px;
            height: 2px;
            background-color: ${props.theme.palette.mode === U21Themes.DARK
              ? props.theme.palette.white
              : props.theme.palette.grey[800]};
            position: relative;
            top: calc(50% - 1px);
            transform: translateX(-${(22 * Math.SQRT2 - 22) / 2 + 2}px)
              rotate(-45deg);
            transform-origin: center;
          }
        `
      : ''}
  `}
`;

const ClickableSwatch = styled(BaseSwatch)<{ $selected: boolean }>`
  ${(props) => css`
    border: 2px solid
      ${props.$selected ? props.theme.palette.grey[800] : 'transparent'};
    cursor: pointer;
    ${props.$selected ? `box-shadow: ${props.theme.customShadows.z8};` : ''}
    &:hover {
      border-color: ${props.theme.palette.grey[800]};
    }
  `}
`;

const SwatchWrapper = styled.div`
  ${(props) => css`
    display: flex;
    align-items: center;
    gap: ${props.theme.spacing(1)};
    padding: ${props.theme.spacing(0.5)};
    padding-left: ${props.theme.spacing(1.5)};
    border-radius: ${props.theme.shape.borderRadius}px;
    border: 1px solid ${props.theme.palette.grey[500_32]};
    cursor: pointer;

    &:hover {
      border-color: ${props.theme.palette.grey[800]};
    }
  `}
`;

const PreviewWrapper = styled(U21Spacer)`
  &&& {
    margin-left: auto;
  }
`;

const StyledSpacer = styled(U21Spacer)`
  width: 100%;
`;

const HexColorPickerWrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1)};
`;
