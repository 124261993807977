// Utils
import { get, post, destroy, put } from 'app/shared/utils/fetchr';
import routes from 'app/shared/utils/routes';

// Actions
import {
  retrieveDeadlinesConfigs as retrieveDeadlinesConfigsAction,
  createDeadlinesConfig as createDeadlinesConfigAction,
  editDeadlinesConfig as editDeadlinesConfigAction,
  editArticleDeadline as editArticleDeadlineAction,
} from 'app/modules/deadlines/actions';

export const retrieveDeadlinesConfigs = (
  payload: ReturnType<typeof retrieveDeadlinesConfigsAction>['payload'],
) => post(routes.patronus.deadlinesConfigList, payload);

export const retrieveDeadlinesConfig = (id: number) =>
  get(routes.patronus.deadlinesConfigId.replace(':id', `${id}`));

export const editDeadlinesConfig = ({
  id,
  ...payload
}: ReturnType<typeof editDeadlinesConfigAction>['payload']) =>
  put(routes.patronus.deadlinesConfigId.replace(':id', `${id}`), payload);

export const createDeadlinesConfig = (
  payload: ReturnType<typeof createDeadlinesConfigAction>['payload'],
) => post(routes.patronus.deadlinesConfigCreate, payload);

export const deleteDeadlinesConfig = (id: number) =>
  destroy(routes.patronus.deadlinesConfigId.replace(':id', `${id}`));

export const editArticleDeadline = (
  payload: ReturnType<typeof editArticleDeadlineAction>['payload'],
) => post(routes.patronus.deadlinesEdit, payload);
