import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { WebhookActionTypes } from 'app/modules/webhooks/models';

// Actions
import {
  getWebhook as getWebhookAction,
  getWebhookSuccess,
} from 'app/modules/webhooks/actions';

// API
import { retrieveWebhook } from 'app/shared/api/webhooks';

const rootAction = WebhookActionTypes.GET_WEBHOOK;
export function* getWebhook({ payload }: ReturnType<typeof getWebhookAction>) {
  const config = {
    rootAction,
    request: call(retrieveWebhook, payload),
    success: function* onSuccess(
      result: ReturnType<typeof getWebhookSuccess>['payload'],
    ) {
      yield put(getWebhookSuccess(result));
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watchGetWebhook() {
  yield takeLatest(rootAction, getWebhook);
}
