import {
  EntityLink,
  FilteredNetworkAnalysisData,
  Filters,
  NetworkAnalysisResponse,
  LinkedEntity,
  TransactionLinkTableRow,
  TransactionData,
  EntityTransaction,
} from 'app/modules/networkAnalysis/types';
import { getMaxDegree } from 'app/shared/components/Graphs/utils';
import { isEmpty } from 'lodash';
import { Core, NodeDefinition } from 'cytoscape';
import { LINK_ANALYSIS_LINK_TYPES } from 'app/modules/networkAnalysis/constants';
import {
  FullEntityResponse,
  ShortEntityResponse,
} from 'app/modules/entities/types';
import { U21NetworkGraphElements } from 'app/shared/components/Graphs/U21NetworkGraph';

export const applyTransactionFilters = (
  { min, direction }: TransactionData,
  amount: number,
): boolean => {
  switch (direction) {
    case 'BOTH':
      return Math.abs(amount) >= min;
    case 'RECEIVING':
      return amount > min;
    case 'SENDING':
      return amount < min * -1;
    default:
      return true;
  }
};

export const filterData = (
  { links, entities, transactions }: NetworkAnalysisResponse,
  {
    linkType,
    entityType,
    entityStatus,
    entitySubtype,
    transactionData,
  }: Filters,
  baseEntityID: string,
): FilteredNetworkAnalysisData => {
  const filteredData: FilteredNetworkAnalysisData = {
    links: new Set(),
    entities: new Set([baseEntityID]),
    transactions: new Set(),
  };
  for (const { id, type, entities: linkedEntities } of Object.values(links)) {
    // check if link is included in filters
    if (!linkType.length || linkType.includes(type)) {
      let shouldAddLink = false;
      // iterate through link's related entities
      for (const e of linkedEntities) {
        // if entity id is already in filteredData then we can add this link id as well
        if (filteredData.entities.has(e)) {
          shouldAddLink = true;
          // should never be falsey, but there could be bad data
        } else if (entities[e]) {
          const {
            type: eType,
            internal_entity_type: subtype,
            status,
            id: entityId,
          } = entities[e];
          // otherwise ensure that the linked entity is included in the entity filters
          if (
            (!entityType.length || entityType.includes(eType)) &&
            (!entitySubtype.length || entitySubtype.includes(subtype)) &&
            (!entityStatus.length || entityStatus.includes(status))
          ) {
            filteredData.entities.add(entityId);
            shouldAddLink = true;
          }
        }
      }
      // add the link id if at least one of its related entities is in filteredData
      if (shouldAddLink) {
        filteredData.links.add(id);
      }
    }
  }
  for (const { id, amount, entity } of Object.values(transactions)) {
    // check if txn is included in filters
    if (
      (!linkType.length ||
        linkType.includes(LINK_ANALYSIS_LINK_TYPES.TRANSACTION)) &&
      ((transactionData.direction === 'BOTH' && transactionData.min === 0) ||
        applyTransactionFilters(transactionData, amount))
    ) {
      // if entity is already in filteredData we don't need to do the checks
      if (filteredData.entities.has(entity)) {
        filteredData.transactions.add(id);
        // should never be falsey, but there could be bad data
      } else if (entities[entity]) {
        const {
          type: eType,
          internal_entity_type: subtype,
          status,
          id: entityId,
        } = entities[entity];
        // otherwise ensure that the entity is included in the entity filters
        if (
          (!entityType.length || entityType.includes(eType)) &&
          (!entitySubtype.length || entitySubtype.includes(subtype)) &&
          (!entityStatus.length || entityStatus.includes(status))
        ) {
          filteredData.entities.add(entityId);
          filteredData.transactions.add(id);
        }
      }
    }
  }
  return filteredData;
};

export const createNode = (
  nodeType: 'link' | 'entity',
  data: EntityLink | LinkedEntity,
  degree: number,
  additionalData: Partial<{
    isBaseEntity: boolean;
    selected: boolean;
    opaque: boolean;
  }>,
): NodeDefinition => {
  if (nodeType === 'link') {
    const { id, type, value } = data as EntityLink;
    return {
      data: {
        id,
        type,
        value,
        label: value,
        degree,
        weight: degree,
        nodeType: 'link',
        ...additionalData,
      },
    };
  }
  const {
    display_name: displayName,
    links: entityLinks,
    ...rest
  } = data as LinkedEntity;
  return {
    data: {
      ...rest,
      label: displayName,
      nodeType: 'entity',
      degree,
      ...additionalData,
    },
  };
};

export const createEdge = (linkID: string, entityID: string) => {
  return {
    data: {
      id: `${linkID}___${entityID}`,
      source: linkID,
      target: entityID,
    },
  };
};

export const createTransactionEdge = (
  { id, entity, amount }: EntityTransaction,
  baseEntityId: string,
) => {
  let source = entity;
  let target = baseEntityId;
  if (amount < 0) {
    source = baseEntityId;
    target = entity;
  }
  return {
    data: {
      id,
      source,
      target,
      label: formatTxnAmount(amount),
      sent: amount < 0,
    },
  };
};

export const graphifyNetworkAnalysisData = (
  { links, entities, transactions }: NetworkAnalysisResponse,
  baseEntity: FullEntityResponse | ShortEntityResponse,
  filteredData: FilteredNetworkAnalysisData,
): U21NetworkGraphElements => {
  const maxDegree = getMaxDegree(filteredData.entities.size, 3);
  const {
    id: unconvertedBasedEntityId,
    external_id: baseEntityExternalID,
    name_readable: baseEntityLabel,
    type: baseEntityType,
    internal_entity_type: baseEntitySubtype,
    status: baseEntityStatus,
  } = baseEntity;
  const baseEntityId = unconvertedBasedEntityId.toString();
  const els: U21NetworkGraphElements = {
    nodes: {
      [baseEntityId]:
        // ensure base entity is included
        createNode(
          'entity',
          {
            id: baseEntityId,
            external_id: baseEntityExternalID,
            type: baseEntityType,
            entity_type: baseEntityType,
            internal_entity_type: baseEntitySubtype || '',
            status: baseEntityStatus,
            display_name: baseEntityLabel,
            // not required but for type safety
            links: [],
          },
          maxDegree,
          { isBaseEntity: true },
        ),
    },
    edges: {},
  };
  for (const link of Object.values(links)) {
    const linkId = link.id;
    if (filteredData?.links.has(linkId)) {
      // add link node
      els.nodes[linkId] = createNode('link', link, maxDegree - 1, {
        opaque: true,
      });
      // add base entity <-> link edge
      els.edges[`${linkId}___${baseEntityId}`] = createEdge(
        link.id,
        baseEntityId,
      );
    }
  }
  let degreeStaggerer = 0;
  for (const e of Object.values(entities)) {
    if (e.id !== baseEntityId && filteredData.entities.has(e.id)) {
      const degree = degreeStaggerer % (maxDegree - 2);
      degreeStaggerer += 1;
      // add entity node
      els.nodes[e.id] = createNode('entity', e, degree, { opaque: true });
      // add link <-> entity edge
      for (const linkId of e.links) {
        if (filteredData.links.has(linkId)) {
          els.edges[`${linkId}___${e.id}`] = createEdge(linkId, e.id);
        }
      }
    }
  }
  for (const txn of Object.values(transactions)) {
    if (filteredData.transactions.has(txn.id)) {
      els.edges[`${txn.id}___${baseEntityId}`] = createTransactionEdge(
        txn,
        baseEntityId,
      );
    }
  }
  return els;
};

export const getFilterOptions = (
  data?: NetworkAnalysisResponse,
): Record<keyof Omit<Filters, 'transactionData'>, Array<any>> => {
  const filterOptions: Record<
    keyof Omit<Filters, 'transactionData'>,
    Array<any>
  > = {
    linkType: !isEmpty(data?.transactions)
      ? [LINK_ANALYSIS_LINK_TYPES.TRANSACTION]
      : [],
    entityType: [],
    entitySubtype: [],
    entityStatus: [],
  };
  if (!data) {
    return filterOptions;
  }
  if (!isEmpty(data.links)) {
    filterOptions.linkType = filterOptions.linkType.concat(
      Array.from(new Set(Object.values(data.links).map(({ type }) => type))),
    );
  }
  if (!isEmpty(data.entities)) {
    const { entityType, entitySubtype, entityStatus } = Object.values(
      data.entities,
    ).reduce(
      (acc, e) => {
        if (e.type) {
          acc.entityType.add(e.type);
        }
        if (e.internal_entity_type) {
          acc.entitySubtype.add(e.internal_entity_type);
        }
        if (e.status) {
          acc.entityStatus.add(e.status);
        }
        return acc;
      },
      {
        entityType: new Set(),
        entitySubtype: new Set(),
        entityStatus: new Set(),
      },
    );
    filterOptions.entityType = Array.from(entityType);
    filterOptions.entitySubtype = Array.from(entitySubtype);
    filterOptions.entityStatus = Array.from(entityStatus);
  }
  return filterOptions;
};

export const formatTxnAmount = (val: number) =>
  `${val < 0 ? '-' : ''}$${Math.abs(val).toLocaleString('en-US', {
    minimumFractionDigits: 2,
  })}`;

export const makeTransactionsLinksTableData = (
  transactions: EntityTransaction[],
  data: NetworkAnalysisResponse,
  filteredData: FilteredNetworkAnalysisData,
) =>
  transactions.reduce<TransactionLinkTableRow[]>(
    (acc, { amount, entity, id: rowId }) => {
      if (filteredData.entities.has(entity)) {
        acc.push({ ...data.entities[entity], amount, rowId });
      }
      return acc;
    },
    [],
  );

export const resetSelectedNodesAndEdges = (cy: Core | null) => {
  if (cy) {
    // reset highlighted nodes and edges
    for (const el of cy.elements('node[selected = true],node[opaque = true]')) {
      el.data('selected', false);
      el.data('opaque', false);
      for (const edge of el.connectedEdges()) {
        edge.data('selected', false);
      }
    }
  }
};
