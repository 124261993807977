import { StorageResource } from 'app/modules/attachments/models';

export const DOCUMENT_UPLOAD_LIMIT = 100;
export const DOCUMENT_UPLOAD_FILE_LENGTH_LIMIT = 512;

export const STORAGE_RESOURCE_LABELS: Record<StorageResource, string> = {
  [StorageResource.GDRIVE]: 'Google Drive',
  [StorageResource.S3]: 'Amazon S3',
};

enum MediaType {
  IMAGE_PROFILE_PICTURE = 'IMAGE_PROFILE_PICTURE',
  IMAGE_DRIVERS_LICENSE_FRONT = 'IMAGE_DRIVERS_LICENSE_FRONT',
  IMAGE_DRIVERS_LICENSE_BACK = 'IMAGE_DRIVERS_LICENSE_BACK',
  IMAGE_PASSPORT_FRONT = 'IMAGE_PASSPORT_FRONT',
  IMAGE_ID_CARD_FRONT = 'IMAGE_ID_CARD_FRONT',
  IMAGE_ID_CARD_BACK = 'IMAGE_ID_CARD_BACK',
  IMAGE_FACE_IMAGE = 'IMAGE_FACE_IMAGE',
  INVESTIGATOR_NOTES = 'INVESTIGATOR_NOTES',
  SOCURE_DOWNLOADED_IMAGE = 'SOCURE_DOWNLOADED_IMAGE',
  IMAGE_CHECK_FRONT = 'IMAGE_CHECK_FRONT',
  IMAGE_CHECK_BACK = 'IMAGE_CHECK_BACK',
}

export const MEDIA_TYPE_TO_TITLE_MAP: Record<MediaType, string> = {
  IMAGE_PROFILE_PICTURE: 'Profile picture',
  IMAGE_DRIVERS_LICENSE_FRONT: 'Driver license (Front)',
  IMAGE_DRIVERS_LICENSE_BACK: 'Driver license (Back)',
  IMAGE_PASSPORT_FRONT: 'Passport (Front)',
  IMAGE_ID_CARD_FRONT: 'ID card (Front)',
  IMAGE_ID_CARD_BACK: 'ID card (Back)',
  IMAGE_FACE_IMAGE: 'Face image',
  INVESTIGATOR_NOTES: 'Investigator notes',
  SOCURE_DOWNLOADED_IMAGE: 'Socure downloaded image',
  IMAGE_CHECK_FRONT: 'Check image (Front)',
  IMAGE_CHECK_BACK: 'Check image (Back)',
};
