import {
  CancelFileExportPayload,
  FileExportDownloadPayload,
} from 'app/modules/fileExports/requests';
import {
  FileExportResponse,
  FileExportsResponse,
} from 'app/modules/fileExports/responses';
import { PayloadAction } from '@reduxjs/toolkit';

import {
  cancelFileExport,
  downloadFileExport,
  getFileExports,
} from 'app/modules/fileExports/api';
import { sendErrorToast } from 'app/shared/toasts/actions';
import { u21CreateAsyncThunk } from 'app/shared/thunk/u21CreateAsyncThunk';
import { u21CreateSlice } from 'app/shared/thunk/u21CreateSlice';

const FILE_EXPORTS_NAME = 'fileExportsRefresh';

interface FileExportsState {
  fileExports: FileExportResponse[];
  fileExportCount: number;
  loadingFileExports: boolean;
}

export const initialState: Readonly<FileExportsState> = {
  fileExports: [],
  fileExportCount: 0,
  loadingFileExports: false,
};

export const getFileExportsThunk = u21CreateAsyncThunk(
  `${FILE_EXPORTS_NAME}/GET_FILE_EXPORTS`,
  getFileExports,
);

export const downloadFileExportThunk = u21CreateAsyncThunk(
  `${FILE_EXPORTS_NAME}/DOWNLOAD_FILE_EXPORT`,
  async (payload: FileExportDownloadPayload, { dispatch }) => {
    try {
      await downloadFileExport(payload);
    } catch (e) {
      dispatch(sendErrorToast('Failed to download file.'));
      throw e;
    }
  },
);

export const cancelFileExportThunk = u21CreateAsyncThunk(
  `${FILE_EXPORTS_NAME}/CANCEL_FILE_EXPORT`,
  async (payload: CancelFileExportPayload, { dispatch }) => {
    try {
      return await cancelFileExport(payload);
    } catch (e) {
      dispatch(sendErrorToast('Failed to cancel file export.'));
      throw e;
    }
  },
);

export const fileExportsSlice = u21CreateSlice({
  name: FILE_EXPORTS_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addLoadingCase(
        getFileExportsThunk,
        'loadingFileExports',
        (draft, action: PayloadAction<FileExportsResponse>) => {
          draft.fileExports = action.payload.file_exports;
          draft.fileExportCount = action.payload.count;
        },
      )
      .addLoadingCase(
        cancelFileExportThunk,
        'loadingFileExports',
        (draft, action: PayloadAction<FileExportResponse>) => {
          const newFileExports = [...draft.fileExports];

          const canceledFileExportIndex = newFileExports.findIndex(
            (fe) => fe.id === action.payload.id,
          );

          if (canceledFileExportIndex !== -1) {
            newFileExports[canceledFileExportIndex] = action.payload;

            draft.fileExports = newFileExports;
          }
        },
      );
  },
});

export const FILE_EXPORTS_SLICE_NAME = fileExportsSlice.name;
export default fileExportsSlice.reducer;
