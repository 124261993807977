import { PayloadAction } from '@reduxjs/toolkit';

// Models
import {
  RelationshipsState,
  RelationshipConfigType,
  DegreeType,
} from 'app/modules/relationships/models';

// Types
import {
  RetrieveRelationshipConfigsResponse,
  RelationshipConfigResponse,
} from 'app/modules/relationships/types';

// Thunks
import { u21CreateAsyncThunk } from 'app/shared/thunk/u21CreateAsyncThunk';
import { u21CreateSlice } from 'app/shared/thunk/u21CreateSlice';

// API
import {
  retrieveRelationshipConfigs,
  retrieveRelationshipConfig,
  editRelationshipConfig,
  createRelationshipConfig,
} from 'app/modules/relationships/api';

const RELATIONSHIPS_NAME = 'relationships';

const initialState: Readonly<RelationshipsState> = {
  relationshipConfig: {
    id: '',
    name: '',
    description: '',
    created_at: '',
    updated_at: '',
    external_id: '',
    directed: false,
    type: RelationshipConfigType.ENTITY_TO_ENTITY,
    degree: DegreeType.MANY,
  },

  loadingRetrieveRelationshipConfig: false,
  loadingCreateRelationshipConfig: false,
  loadingEditRelationshipConfig: false,

  entityToEntityRelationshipConfigs: [],
  entityToEntityRelationshipCount: 0,
  entityToInstrumentRelationshipConfigs: [],
  entityToInstrumentRelationshipCount: 0,
  loadingRetrieveRelationshipConfigs: false,

  entityRelatedEntitiesPaginationPayload: { limit: 100 },
  entityRelatedInstrumentsPaginationPayload: { limit: 100 },
  instrumentRelatedEntitiesPaginationPayload: { limit: 100 },

  entityRelatedEntitiesFilters: { selectedConfigs: [], direction: 'either' },
  entityRelatedInstrumentsFilters: { selectedConfigs: [] },
  instrumentRelatedEntitiesFilters: { selectedConfigs: [] },
};

export const retrieveRelationshipConfigsThunk = u21CreateAsyncThunk(
  `${RELATIONSHIPS_NAME}/GET_RELATIONSHIP_CONFIGS`,
  retrieveRelationshipConfigs,
);

export const retrieveRelationshipConfigThunk = u21CreateAsyncThunk(
  `${RELATIONSHIPS_NAME}/GET_RELATIONSHIP_CONFIG`,
  retrieveRelationshipConfig,
);

export const editRelationshipConfigThunk = u21CreateAsyncThunk(
  `${RELATIONSHIPS_NAME}/EDIT_RELATIONSHIP_CONFIG`,
  editRelationshipConfig,
);

export const createRelationshipConfigThunk = u21CreateAsyncThunk(
  `${RELATIONSHIPS_NAME}/CREATE_RELATIONSHIP_CONFIG`,
  createRelationshipConfig,
);

const handleNewRelationshipConfig = (
  draft,
  newRelationshipConfig: RelationshipConfigResponse,
) => {
  /*
    This is a helper function to DRY handling a new relationship config (whether it's coming from a fetch, edit, or creation)
   */
  draft.relationshipConfig = newRelationshipConfig;

  let alreadyExists = false;

  const existingRelationshipConfigsKey: keyof RelationshipsState =
    newRelationshipConfig.type === RelationshipConfigType.ENTITY_TO_ENTITY
      ? 'entityToEntityRelationshipConfigs'
      : 'entityToInstrumentRelationshipConfigs';

  const existingRelationshipConfigs = draft[existingRelationshipConfigsKey];
  const newRelationshipConfigs: RelationshipConfigResponse[] =
    existingRelationshipConfigs.map(
      (relationshipConfig: RelationshipConfigResponse) => {
        if (relationshipConfig.id === newRelationshipConfig.id) {
          alreadyExists = true;
          return newRelationshipConfig;
        }
        return relationshipConfig;
      },
    );

  if (!alreadyExists) {
    newRelationshipConfigs.push(newRelationshipConfig);
  }

  draft[existingRelationshipConfigsKey] = newRelationshipConfigs;
};

export const relationshipsSlice = u21CreateSlice({
  name: RELATIONSHIPS_NAME,
  initialState,
  reducers: {
    setEntityRelatedEntitiesPaginationPayload: (
      draft,
      {
        payload,
      }: PayloadAction<
        RelationshipsState['entityRelatedEntitiesPaginationPayload']
      >,
    ) => {
      draft.entityRelatedEntitiesPaginationPayload = payload;
    },
    setEntityRelatedInstrumentsPaginationPayload: (
      draft,
      {
        payload,
      }: PayloadAction<
        RelationshipsState['entityRelatedInstrumentsPaginationPayload']
      >,
    ) => {
      draft.entityRelatedInstrumentsPaginationPayload = payload;
    },
    setInstrumentRelatedEntitiesPaginationPayload: (
      draft,
      {
        payload,
      }: PayloadAction<
        RelationshipsState['instrumentRelatedEntitiesPaginationPayload']
      >,
    ) => {
      draft.instrumentRelatedEntitiesPaginationPayload = payload;
    },
    setEntityRelatedEntitiesFilters: (
      draft,
      {
        payload,
      }: PayloadAction<RelationshipsState['entityRelatedEntitiesFilters']>,
    ) => {
      draft.entityRelatedEntitiesFilters = payload;
    },
    setEntityRelatedInstrumentsFilters: (
      draft,
      {
        payload,
      }: PayloadAction<RelationshipsState['entityRelatedInstrumentsFilters']>,
    ) => {
      draft.entityRelatedInstrumentsFilters = payload;
    },
    setInstrumentRelatedEntitiesFilters: (
      draft,
      {
        payload,
      }: PayloadAction<RelationshipsState['instrumentRelatedEntitiesFilters']>,
    ) => {
      draft.instrumentRelatedEntitiesFilters = payload;
    },
    clearAllRelationshipFilters: (draft) => {
      draft.entityRelatedEntitiesFilters = {
        selectedConfigs: [],
        direction: 'either',
      };
      draft.entityRelatedInstrumentsFilters = { selectedConfigs: [] };
      draft.instrumentRelatedEntitiesFilters = { selectedConfigs: [] };
    },
  },
  extraReducers: (builder) => {
    builder
      // retrieveRelationshipConfigThunk
      .addLoadingCase(
        retrieveRelationshipConfigThunk,
        'loadingRetrieveRelationshipConfig',
        (draft, action: PayloadAction<RelationshipConfigResponse>) => {
          handleNewRelationshipConfig(draft, action.payload);
        },
      )
      // retrieveRelationshipConfigsThunk
      .addLoadingCase(
        retrieveRelationshipConfigsThunk,
        'loadingRetrieveRelationshipConfigs',
        (draft, action: PayloadAction<RetrieveRelationshipConfigsResponse>) => {
          draft.entityToEntityRelationshipConfigs =
            action.payload.entity_to_entity_relationship_configs;
          draft.entityToEntityRelationshipCount =
            action.payload.entity_to_entity_relationship_count;
          draft.entityToInstrumentRelationshipConfigs =
            action.payload.entity_to_instrument_relationship_configs;
          draft.entityToInstrumentRelationshipCount =
            action.payload.entity_to_instrument_relationship_count;
        },
      )
      // createRelationshipConfigThunk
      .addLoadingCase(
        createRelationshipConfigThunk,
        'loadingCreateRelationshipConfig',
        (draft, action: PayloadAction<RelationshipConfigResponse>) => {
          handleNewRelationshipConfig(draft, action.payload);
        },
      )
      // editRelationshipConfigThunk
      .addLoadingCase(
        editRelationshipConfigThunk,
        'loadingEditRelationshipConfig',
        (draft, action: PayloadAction<RelationshipConfigResponse>) => {
          handleNewRelationshipConfig(draft, action.payload);
        },
      );
  },
});

export const RELATIONSHIPS_SLICE_NAME = relationshipsSlice.name;
export default relationshipsSlice.reducer;
export const {
  setEntityRelatedEntitiesPaginationPayload,
  setEntityRelatedInstrumentsPaginationPayload,
  setInstrumentRelatedEntitiesPaginationPayload,
  setEntityRelatedEntitiesFilters,
  setEntityRelatedInstrumentsFilters,
  setInstrumentRelatedEntitiesFilters,
  clearAllRelationshipFilters,
} = relationshipsSlice.actions;
