import { post, get } from 'app/shared/utils/fetchr';

// Actions
import {
  retrieveDetectionModelsPerformance as retrieveDetectionModelsPerformanceAction,
  validateDetectionModel as validateDetectionModelAction,
  revalidateDetectionModel as revalidateDetectionModelAction,
  retrieveDetectionModelValidation as retrieveDetectionModelValidationAction,
  retrieveDetectionModelValidationAlerts as retrieveDetectionModelValidationAlertsAction,
  retrieveDetectionModelValidationMetrics as retrieveDetectionModelValidationMetricsAction,
  retrieveDetectionModelTemplates as retrieveDetectionModelTemplatesAction,
} from 'app/modules/detectionModels/actions';

// Models
import {
  ActivateDetectionModelPayload,
  ActivateSynchronousDetectionModelPayload,
  DetectionModel,
  DetectionModelValidation,
  ScenarioConfigs,
  ValidateModelResponse,
} from 'app/modules/detectionModels/models';

// Utils
import routes from 'app/shared/utils/routes';
import { DetectionModelTestResult } from 'app/modules/detectionModels/sliceDetectionModels';
import { RuleTestPayload } from 'app/modules/detectionModels/types/requests';

export const retrieveDetectionModelsPerformance = (
  payload: ReturnType<
    typeof retrieveDetectionModelsPerformanceAction
  >['payload'],
) => {
  return post(routes.patronus.detectionModelsPerformanceChart, payload);
};

export const retrieveScenarioConfigs = () => {
  return get<ScenarioConfigs>(routes.patronus.orgsScenarioConfigs);
};

export const validateDetectionModel = (
  payload: ReturnType<typeof validateDetectionModelAction>['payload'],
) => {
  return post<ValidateModelResponse>(
    routes.patronus.detectionModelsValidate,
    payload,
  );
};

export const revalidateDetectionModel = ({
  id,
  ...payload
}: ReturnType<typeof revalidateDetectionModelAction>['payload']) => {
  return post<ValidateModelResponse>(
    routes.patronus.detectionModelsRevalidateId.replace(':id', `${id}`),
    payload,
  );
};

export const retrieveDetectionModelValidation = (
  id: ReturnType<typeof retrieveDetectionModelValidationAction>['payload'],
) => {
  return get<DetectionModelValidation>(
    routes.patronus.detectionModelsValidationId.replace(':id', `${id}`),
  );
};

export const activateDetectionModel = ({
  id,
  ...payload
}: ActivateDetectionModelPayload) => {
  return post<DetectionModel>(
    routes.patronus.detectionModelsIdActivate.replace(':id', `${id}`),
    payload,
  );
};

export const testRule = (
  payload: RuleTestPayload,
): Promise<DetectionModelTestResult> => {
  const path = routes.patronus.detectionModelsTest.replace(
    ':id',
    String(payload.rule_id),
  );
  return post(path, payload);
};

export const retrieveDetectionModel = (id: number) => {
  return get<DetectionModel>(
    routes.patronus.detectionModelId.replace(':id', `${id}`),
  );
};

export const activateSynchronousDetectionModel = (
  payload: ActivateSynchronousDetectionModelPayload,
): Promise<DetectionModel> =>
  post('/detection-models/synchronous/activate', payload);

export const retrieveDetectionModelValidationAlerts = (
  payload: ReturnType<
    typeof retrieveDetectionModelValidationAlertsAction
  >['payload'],
) => {
  const { id, ...details } = payload;
  const path = routes.patronus.validationRulesPaginatedAlertsId.replace(
    ':id',
    String(id),
  );
  return post(path, details);
};

export const retrieveDetectionModelValidationMetrics = ({
  id,
  ...payload
}: ReturnType<
  typeof retrieveDetectionModelValidationMetricsAction
>['payload']) =>
  post(
    routes.patronus.detectionModelsValidationIdMetrics.replace(':id', `${id}`),
    payload,
  );

// Templates
export const retrieveDetectionModelTemplates = (
  payload: ReturnType<typeof retrieveDetectionModelTemplatesAction>['payload'],
) => post(routes.patronus.detectionModelsTemplates, payload);
