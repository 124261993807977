// Reducer
import { Reducer, Action } from 'redux';
import { produce, Draft } from 'immer';

export interface LoadingState {
  [key: string]: boolean;
}

const initialState: LoadingState = {};

const reducer: Reducer<LoadingState> = (
  state: LoadingState = initialState,
  action: Action<string>,
) => {
  return produce(state, (draft: Draft<LoadingState>) => {
    const actionType = action.type;
    if (actionType.includes('_PENDING') || actionType.includes('_LOADING')) {
      const rootAction = actionType
        .replace('_PENDING', '')
        .replace('_LOADING', '');
      draft[rootAction] = true;
    } else if (
      actionType.includes('_ERROR') ||
      actionType.includes('_SUCCESS')
    ) {
      const rootAction = actionType
        .replace('_ERROR', '')
        .replace('_SUCCESS', '');
      draft[rootAction] = false;
    }
    return;
  });
};

export { reducer as loadingReducer };
