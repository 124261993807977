import produce, { Draft } from 'immer';
import { omit, cloneDeep } from 'lodash';

// Models
import {
  InsightsState,
  InsightsActionTypes,
  DashboardDetails,
} from 'app/modules/insights/models';
import ReduxActions from 'app/modules/insights/actions';

// Helpers
import { extractDashboardSummary } from 'app/modules/insights/helpers';

// Utils
import deepFreeze from 'app/shared/utils/deepFreeze';

const initialDashboard: DashboardDetails = {
  title: '',
  description: '',
  charts: [],
  id: -1,
  charts_layout: [],
  time_bucket: '',
  filtered_org_id: null,
  filtered_unit_id: null,
  filters: [],
  start_date: '',
  end_date: '',
};

// for safe exporting; ensures we do not alter initialState in another file
const clonedDashboard = cloneDeep(initialDashboard);
export { clonedDashboard as initialDashboard };

const initialState: Readonly<InsightsState> = {
  chart_definitions: [],
  dashboards: [],
  dashboard: initialDashboard,
  charts: {},
  chart_definitions_count: 0,
};
// to ensure initialState is readonly
deepFreeze(initialState);

const reducer = (state = initialState, action: ReduxActions) => {
  return produce(state, (draft: Draft<InsightsState>) => {
    switch (action.type) {
      case InsightsActionTypes.RETRIEVE_CHART_DEFINITIONS_SUCCESS:
        draft.chart_definitions = action.payload.chart_definitions;
        draft.chart_definitions_count = action.payload.count;
        return;

      case InsightsActionTypes.CREATE_DASHBOARD_SUCCESS:
      case InsightsActionTypes.DUPLICATE_DASHBOARD_SUCCESS:
        // add the new dashboard summary to the dashboards list
        draft.dashboards = [
          ...draft.dashboards,
          extractDashboardSummary(action.payload),
        ];
        // set the newly created dashboard as the current dashboard
        draft.dashboard = action.payload;
        return;

      case InsightsActionTypes.DELETE_DASHBOARD_SUCCESS:
        // remove the dashboard from dashboards
        draft.dashboards = draft.dashboards.filter(
          (dashboard) => dashboard.id !== action.payload,
        );
        // set dashboard back to initial state
        draft.dashboard = { ...initialDashboard };
        return;

      case InsightsActionTypes.CLEAR_DASHBOARD:
        draft.dashboard = initialDashboard;
        draft.charts = {};
        return;

      case InsightsActionTypes.EDIT_DASHBOARD_SUCCESS: {
        // set the newly created dashboard as the current dashboard
        draft.dashboard = action.payload;
        const idxToReplace = draft.dashboards.findIndex(
          (dashboard) => dashboard.id === action.payload.id,
        );
        // update existing dashboard in the dashboards list if it exists
        if (idxToReplace >= 0) {
          draft.dashboards[idxToReplace] = extractDashboardSummary(
            action.payload,
          );
          // append dashboard to dashboards list if does not exist
        } else {
          draft.dashboards.push(extractDashboardSummary(action.payload));
        }
        return;
      }

      case InsightsActionTypes.EDIT_CHART_SUCCESS:
        if (draft.charts[action.payload.id]) {
          draft.charts[action.payload.id] = {
            ...draft.charts[action.payload.id],
            ...action.payload,
          };
        }
        return;

      case InsightsActionTypes.EDIT_DASHBOARD_CHARTS_LAYOUT_SUCCESS:
        draft.dashboard.charts_layout = action.payload.charts_layout;
        return;

      case InsightsActionTypes.RETRIEVE_DASHBOARDS_SUCCESS:
        draft.dashboards = action.payload.dashboards;
        return;

      case InsightsActionTypes.RETRIEVE_DASHBOARD_SUCCESS:
        draft.dashboard = action.payload;
        return;

      case InsightsActionTypes.RETRIEVE_CHART_SUCCESS:
        draft.charts[action.payload.id] = action.payload;
        return;

      case InsightsActionTypes.DELETE_CHART_SUCCESS:
        draft.charts = omit(draft.charts, action.payload);
        return;
    }
  });
};

export { reducer as insightsReducer, initialState };
