import { type Crop } from 'react-image-crop';

export const initialiseCanvases = (
  mainCanvasRef: React.MutableRefObject<HTMLCanvasElement | null>,
  cropCanvasRef: React.MutableRefObject<HTMLCanvasElement | null>,
  image: HTMLImageElement | null,
): OffscreenCanvas | null => {
  const canvas = mainCanvasRef.current;
  const context = canvas?.getContext('2d');

  const cropCanvas = cropCanvasRef.current;
  const cropContext = cropCanvas?.getContext('2d');

  if (canvas && context && cropCanvas && cropContext && image) {
    const rawCanvas = new OffscreenCanvas(image.width, image.height); // Creation of a raw canvas to save all operations WITHOUT the application of image filters
    const rawContext = rawCanvas.getContext('2d');

    if (!rawContext) {
      return null;
    }

    context.imageSmoothingEnabled = false;
    rawContext.imageSmoothingEnabled = false;
    cropContext.imageSmoothingEnabled = false;

    rawContext.drawImage(image, 0, 0); // Drawing image onto the raw canvas

    cropCanvas.width = image.width; // Setting width of crop canvas to image width
    cropCanvas.height = image.height; // Setting height of crop canvas to image height

    cropContext.clearRect(0, 0, cropCanvas.width, cropCanvas.height); // Clearing the crop canvas

    canvas.width = image.width; // Setting the width of the display canvas
    canvas.height = image.height; // Setting the height of the display canvas

    context.clearRect(0, 0, canvas.width, canvas.height); // Clearing the display canvas
    context.drawImage(image, 0, 0); // Drawing image onto the display canvas

    return rawCanvas;
  }

  return null;
};

export const rotateImageClockwise = (
  mainCanvasRef: React.MutableRefObject<HTMLCanvasElement | null>,
  rawCanvasRef: React.MutableRefObject<OffscreenCanvas | null>,
): number => {
  const canvas = mainCanvasRef.current;
  const context = canvas?.getContext('2d');

  const rawCanvas = rawCanvasRef.current;
  const rawContext = rawCanvas?.getContext('2d');

  if (canvas && context && rawCanvas && rawContext) {
    const prevWidth = rawCanvas.width;
    const prevHeight = rawCanvas.height;

    const largestAxis = prevWidth > prevHeight ? prevWidth : prevHeight; // Getting the largest axis so we can initialise an intermediary canvas that fits the image regardless of image orientation

    const opCanvas = new OffscreenCanvas(largestAxis, largestAxis); // Initialising a canvas for intermediary operations with largest axis to fit our image regardless or orientation
    const opContext = opCanvas?.getContext('2d');

    if (!opContext) {
      return 1;
    }

    const offsetX = opCanvas.width / 2; // Getting X coord for the middle point of opCanvas
    const offsetY = opCanvas.height / 2; // Getting Y coord for the middle point of opCanvas

    opContext.translate(offsetX, offsetY); // Translate cursor to the middle point of opCanvas
    opContext.rotate((90 * Math.PI) / 180); // Rotating the canvas by 90 degree clockwise with respect to the cursor, which is the middle point
    opContext.translate(-offsetX, -offsetY); // Resetting cursor back to 0,0, which is the top left corner of opCanvas
    opContext.drawImage(
      canvas,
      0,
      0,
      prevWidth,
      prevHeight,
      opCanvas.width / 2 - prevWidth / 2,
      opCanvas.height / 2 - prevHeight / 2,
      prevWidth,
      prevHeight,
    ); // Copying image from the top left corner of canvas to the opCanvas. Note: The copied image will be centralised in opCanvas

    rawCanvas.width = prevHeight; // Updating the width of the canvas after rotation
    rawCanvas.height = prevWidth; // Updating the height of the canvas after rotation

    rawContext.clearRect(0, 0, opCanvas.width, opCanvas.height); // Clearing the entire raw canvas
    rawContext.drawImage(
      opCanvas,
      opCanvas.width / 2 - rawCanvas.width / 2,
      opCanvas.height / 2 - rawCanvas.height / 2,
      rawCanvas.width,
      rawCanvas.height,
      0,
      0,
      rawCanvas.width,
      rawCanvas.height,
    ); // Copying rotated image from the opCanvas to raw canvas

    canvas.width = rawCanvas.width; // Setting the width of the display canvas
    canvas.height = rawCanvas.height; // Setting the height of the display canvas

    context.clearRect(0, 0, canvas.width, canvas.height); // Clearing the display canvas
    context.drawImage(rawCanvas, 0, 0); // Copying the raw canvas to display canvas

    return canvas.width / canvas.height;
  }

  return 1;
};

export const flipImageOnHorizontalAxis = (
  mainCanvasRef: React.MutableRefObject<HTMLCanvasElement | null>,
  rawCanvasRef: React.MutableRefObject<OffscreenCanvas | null>,
): void => {
  const canvas = mainCanvasRef.current;
  const context = canvas?.getContext('2d');

  const rawCanvas = rawCanvasRef.current;
  const rawContext = rawCanvas?.getContext('2d');

  if (canvas && context && rawCanvas && rawContext) {
    const opCanvas = new OffscreenCanvas(rawCanvas.width, rawCanvas.height); // Initialising a canvas for intermediary operations with canvas's diamension
    const opContext = opCanvas.getContext('2d');

    if (!opContext) {
      return;
    }

    opContext.drawImage(rawCanvas, 0, 0); // Copying image on raw canvas over to the operation canvas

    const offsetX = rawCanvas.width / 2; // Getting X coord for the middle point of raw canvas
    const offsetY = rawCanvas.height / 2; // Getting Y coord for the middle point of raw canvas

    rawContext.clearRect(0, 0, rawCanvas.width, rawCanvas.height); // Clearing the entire raw canvas
    rawContext.translate(offsetX, offsetY); // Setting cursor to the middle point of the raw canvas
    rawContext.scale(1, -1); // Flip the image along the horizontal axis
    rawContext.drawImage(opCanvas, -offsetX, -offsetY); // Drawing the image from the top left corner (0,0) of raw canvas. Note: Cursor is now at the middle point of the canvas, hence we need to use -offsetX and -offsetY to point the cursor to the top left corner of 0,0
    rawContext.scale(1, -1); // Reset the flip along the horizontal axis (Needed to else the next flip wont work)
    rawContext.translate(-offsetX, -offsetY); // Reset the cursor for raw canvas to the top left corner

    canvas.width = rawCanvas.width; // Setting the width of the display canvas
    canvas.height = rawCanvas.height; // Setting the height of the display canvas

    context.clearRect(0, 0, canvas.width, canvas.height); // Clearing the display canvas
    context.drawImage(rawCanvas, 0, 0); // Copying the raw canvas to display canvas
  }
};

export const flipImageOnVerticalAxis = (
  mainCanvasRef: React.MutableRefObject<HTMLCanvasElement | null>,
  rawCanvasRef: React.MutableRefObject<OffscreenCanvas | null>,
): void => {
  const canvas = mainCanvasRef.current;
  const context = canvas?.getContext('2d');

  const rawCanvas = rawCanvasRef.current;
  const rawContext = rawCanvas?.getContext('2d');

  if (canvas && context && rawCanvas && rawContext) {
    const opCanvas = new OffscreenCanvas(rawCanvas.width, rawCanvas.height); // Initialising a canvas for intermediary operations with canvas's diamension
    const opContext = opCanvas.getContext('2d');

    if (!opContext) {
      return;
    }

    opContext.drawImage(rawCanvas, 0, 0); // Copying image on raw canvas over to the operation canvas

    const offsetX = rawCanvas.width / 2; // Getting X coord for the middle point of raw canvas
    const offsetY = rawCanvas.height / 2; // Getting Y coord for the middle point of raw canvas

    rawContext.clearRect(0, 0, rawCanvas.width, rawCanvas.height); // Clearing the entire raw canvas
    rawContext.translate(offsetX, offsetY); // Setting cursor to the middle point of the raw canvas
    rawContext.scale(-1, 1); // Flip the image along the vertical axis
    rawContext.drawImage(opCanvas, -offsetX, -offsetY); // Drawing the image from the top left corner (0,0) of raw canvas. Note: Cursor is now at the middle point of the canvas, hence we need to use -offsetX and -offsetY to point the cursor to the top left corner of 0,0
    rawContext.scale(-1, 1); // Reset the flip along the vertical axis (Needed to else the next flip wont work)
    rawContext.translate(-offsetX, -offsetY); // Reset the cursor for raw canvas to the top left corner

    canvas.width = rawCanvas.width; // Setting the width of the display canvas
    canvas.height = rawCanvas.height; // Setting the height of the display canvas

    context.clearRect(0, 0, canvas.width, canvas.height); // Clearing the display canvas
    context.drawImage(rawCanvas, 0, 0); // Copying the raw canvas to display canvas
  }
};

export const applyImageFilter = (
  mainCanvasRef: React.MutableRefObject<HTMLCanvasElement | null>,
  rawCanvasRef: React.MutableRefObject<OffscreenCanvas | null>,
  brightness: number,
  contrast: number,
  grayscale: number,
  saturation: number,
) => {
  const canvas = mainCanvasRef.current;
  const context = canvas?.getContext('2d');

  const rawCanvas = rawCanvasRef.current;
  const rawContext = rawCanvas?.getContext('2d');

  if (canvas && context && rawCanvas && rawContext) {
    context.filter = `brightness(${brightness}%) contrast(${contrast}%) grayscale(${grayscale}%) saturate(${saturation}%)`; // Applying filters to the display canvas
    context.drawImage(rawCanvas, 0, 0); // Drawing image from raw canvas to display canvas
  }
};

export const copyCanvas = (
  mainCanvasRef: React.MutableRefObject<HTMLCanvasElement | null>,
  secondCanvasRef: React.MutableRefObject<HTMLCanvasElement | null>,
): void => {
  const canvas = mainCanvasRef.current;
  const context = canvas?.getContext('2d');

  const secondCanvas = secondCanvasRef.current;
  const secondContext = secondCanvas?.getContext('2d');

  if (canvas && context && secondCanvas && secondContext) {
    secondCanvas.width = canvas.width; // Setting the width of the second canvas
    secondCanvas.height = canvas.height; // Setting the height of the second canvas

    secondContext.clearRect(0, 0, secondCanvas.width, secondCanvas.height); // Clearing the second canvas
    secondContext.drawImage(canvas, 0, 0); // Copying the display canvas to second canvas
  }
};

export const cropImage = (
  crop: Crop,
  mainCanvasRef: React.MutableRefObject<HTMLCanvasElement | null>,
  cropCanvasRef: React.MutableRefObject<HTMLCanvasElement | null>,
  rawCanvasRef: React.MutableRefObject<OffscreenCanvas | null>,
): number => {
  const canvas = mainCanvasRef.current;
  const context = canvas?.getContext('2d');

  const cropCanvas = cropCanvasRef.current;
  const cropContext = cropCanvas?.getContext('2d');

  const rawCanvas = rawCanvasRef.current;
  const rawContext = rawCanvas?.getContext('2d');

  if (
    canvas &&
    context &&
    cropCanvas &&
    cropContext &&
    rawCanvas &&
    rawContext
  ) {
    const scaleX = cropCanvas.width / cropCanvas.offsetWidth; // Calculate the scaling factor for the X axis
    const scaleY = cropCanvas.height / cropCanvas.offsetHeight; // Calculate teh scaling factor for the Y axis

    const scaledX = crop.x * scaleX; // Scale the horizontal starting point of the crop. Note: Crop calculates start point using offsetWidth instead of width
    const scaledY = crop.y * scaleY; // Scale the vertical starting point of the crop. Note: Crop calculates start point using offsetHeight instead of height
    const scaledWidth = crop.width * scaleX; // Scale the width of the crop. Note: Crop calculates crop width using offsetWidth instead of width
    const scaledHeight = crop.height * scaleY; // Scale the height of the crop. Note: Crop calculates crop height using offsetHeight instead of height

    rawCanvas.width = scaledWidth; // Set the display canvas to the cropped width to ensure crop image fills the screen horizontally
    rawCanvas.height = scaledHeight; // Set the display canvas to the cropped height to ensure crop image fills the screen veritcally

    rawContext.clearRect(0, 0, rawCanvas.width, rawCanvas.height); // Clearing any previous drawing from the display canvas
    rawContext.drawImage(
      cropCanvas,
      scaledX,
      scaledY,
      scaledWidth,
      scaledHeight,
      0,
      0,
      scaledWidth,
      scaledHeight,
    ); // Copying the cropped image to the display canvas

    canvas.width = rawCanvas.width; // Setting the width of the display canvas
    canvas.height = rawCanvas.height; // Setting the height of the display canvas

    context.clearRect(0, 0, canvas.width, canvas.height); // Clearing the display canvas
    context.drawImage(rawCanvas, 0, 0); // Copying the raw canvas to display canvas

    return canvas.width / canvas.height;
  }

  return 1;
};
