import { createSelector } from '@reduxjs/toolkit';
import {
  BASE_SCHEMA_VALIDATION,
  DEFAULT_TYPE_HANDLING_VALUES,
  RESERVED_PRIMARY_OBJECT_INTERNAL_NAME,
  TABLE_TO_READABLE_NAME,
  U21_DATA_MAPPING_SCHEMA_KEYS,
  U21_REQUIRED_TYPE_TO_ANNOTATIONS,
  DATA_MAPPING_SELECTION_OPTIONS,
} from 'app/modules/dataMapping/constants';
import { cloneDeep, isEqual } from 'lodash';
import {
  BaseDataMappingPair,
  DataMappingRow,
  DataMappingObject,
  SchemaValidation,
  ObjectValidation,
  U21DataMappingObjectTypes,
  AnnotationConstant,
  SecondaryObject,
} from 'app/modules/dataMapping/types';
import {
  ValueSelection,
  StreamSelection,
} from 'app/modules/dataMapping/responses';
import {
  annotationContainsBlankSource,
  destinationOptionsHelper,
  getDataMappingRow,
  getObject,
  getTransformationsLength,
} from 'app/modules/dataMapping/helpers';
import { U21SelectOptionProps } from 'app/shared/u21-ui/components';
import { DATA_MAPPING_NAME } from 'app/modules/dataMapping/sliceDataMapping';

export const selectInitialServerConfig = (state: RootState) =>
  state[DATA_MAPPING_NAME].initialServerAnnotationConfig;

export const selectLocalConfig = (state: RootState) =>
  state[DATA_MAPPING_NAME].localAnnotationConfig;

export const selectSecondaryObjects = (state: RootState) =>
  state[DATA_MAPPING_NAME].localAnnotationConfig?.objects;

export const selectUpdateConfigStatus = (state: RootState) =>
  state[DATA_MAPPING_NAME].updateServerAnnotationStatus;

export const selectSelectedAnnotation = (state: RootState) => {
  const { selectedAnnotation, localAnnotationConfig } =
    state[DATA_MAPPING_NAME];
  if (selectedAnnotation && localAnnotationConfig) {
    const {
      custom_data_annotations: customDataAnnotations,
      typed_annotations: typedAnnotations,
    } = localAnnotationConfig.objects[selectedAnnotation.objectName];
    return (
      (selectedAnnotation.isCustom
        ? customDataAnnotations[selectedAnnotation.id - typedAnnotations.length]
        : typedAnnotations[selectedAnnotation.id]) ?? null
    );
  }
  return null;
};

export const selectSelectedColumn = (state: RootState) =>
  state[DATA_MAPPING_NAME].selectedColumn;

export const selectFilePreview = (state: RootState) => {
  return state[DATA_MAPPING_NAME].filePreview;
};

export const selectModalProps = (state: RootState) => {
  return state[DATA_MAPPING_NAME].modalProps;
};

export const selectObjectType = createSelector(
  [selectLocalConfig, (_, objectName: string) => objectName],
  (localConfig, objectName) =>
    localConfig && localConfig.objects[objectName].type,
);

export const selectPrimaryObjectType = (state: RootState) => {
  return state[DATA_MAPPING_NAME].localAnnotationConfig?.unit21_object;
};

export const selectFileHasBlankColumnHeaders = createSelector(
  [selectFilePreview],
  (filePreview) =>
    filePreview.status === 'COMPLETE' &&
    filePreview.preview.top_level_keys.includes(''),
);

export const selectIsDirtyConfig = createSelector(
  [selectInitialServerConfig, selectLocalConfig],
  (serverAnnotationConfig, localAnnotationConfig) => {
    return (
      serverAnnotationConfig.status === 'COMPLETE' &&
      !isEqual(serverAnnotationConfig.config, localAnnotationConfig)
    );
  },
);

export const selectTransformationsLength = createSelector(
  [selectLocalConfig],
  (localConfig) => {
    if (localConfig) {
      return Object.values(localConfig.objects)
        .flatMap((obj: DataMappingObject) => [
          ...obj.typed_annotations,
          ...obj.custom_data_annotations,
        ])
        .reduce(
          (acc: number, annotation: BaseDataMappingPair) =>
            acc + getTransformationsLength(annotation.value),
          0,
        );
    }
    return 0;
  },
);

export const selectSchemaValidation = createSelector(
  [selectLocalConfig],
  (localConfig) => {
    const base = cloneDeep(BASE_SCHEMA_VALIDATION);
    if (localConfig) {
      const primaryObject = localConfig.unit21_object;
      return Object.entries(localConfig.objects).reduce(
        (
          acc: SchemaValidation,
          [objectName, obj]: [string, DataMappingObject],
        ) => {
          const {
            type: objectType,
            typed_annotations: typedAnnotations,
            directionality,
          } = obj;
          const requiredAnnotations: Record<string, AnnotationConstant> =
            U21_REQUIRED_TYPE_TO_ANNOTATIONS[objectType];
          const missing = Object.keys(requiredAnnotations).filter((key) => {
            if (
              // if the config is only for updating existing records
              // and the annotation is required for creating new records, return false
              (requiredAnnotations[key].requirementType === 'create' &&
                localConfig.updating_existing) ||
              // if the annotation is not required for the primary object, return false
              requiredAnnotations[key].notRequiredFor?.includes(
                primaryObject,
              ) ||
              // edge case where, if a user has mapped `us_address_string`
              // then the previously required annotations are no longer required
              (obj.type === SecondaryObject.ADDRESS &&
                typedAnnotations.find(
                  (ta) => ta.destination === 'us_address_string',
                ))
            ) {
              return false;
            }
            const annotation = typedAnnotations.find(
              (ta) => ta.destination === key,
            );
            return (
              !annotation ||
              annotationContainsBlankSource(annotation.value.selection)
            );
          }) as Array<keyof typeof U21_DATA_MAPPING_SCHEMA_KEYS>;
          const { type } = obj;
          acc.objects[objectName] = {
            name: objectName,
            type,
            unmappedRequiredFields: missing,
            missingDirectionality: Boolean(
              localConfig.unit21_object === 'txn_event' &&
                (type === 'entity' || type === 'instrument') &&
                !directionality,
            ),
          };
          return acc;
        },
        base,
      );
    }
    return base;
  },
);

export const selectSchemaIsInvalid = createSelector(
  [selectSchemaValidation],
  (schemaValidation) =>
    schemaValidation &&
    Object.values(schemaValidation.objects).some(
      (obj: ObjectValidation) =>
        obj.unmappedRequiredFields.length || obj.missingDirectionality,
    ),
);

export const selectDestinationOptions = createSelector(
  [
    selectLocalConfig,
    selectPrimaryObjectType,
    (_, objectName: string, value: string) => ({
      objectName,
      value,
    }),
  ],
  (localConfig, primaryObjectType, { objectName, value }) => {
    if (localConfig && primaryObjectType) {
      const object = localConfig.objects[objectName];
      const updateExisting = localConfig.updating_existing;
      return destinationOptionsHelper(
        object.typed_annotations,
        object.type,
        value,
        primaryObjectType,
        updateExisting,
      );
    }
    return [];
  },
);

export const selectSourceDestinationData = createSelector(
  [selectLocalConfig, (_, objectName: string) => objectName],
  (annotationConfig, objectName): DataMappingRow[] => {
    if (!annotationConfig) {
      return [];
    }
    const object = annotationConfig.objects[objectName];
    if (!object) {
      return [];
    }
    return [
      ...(object.typed_annotations ?? []),
      ...(object.custom_data_annotations ?? []),
    ].map(
      (a: BaseDataMappingPair, i: number): DataMappingRow => ({
        ...a,
        isCustom: i >= object.typed_annotations?.length,
        id: i,
      }),
    );
  },
);

export type SourceDestinationData = ArrayElement<
  ReturnType<typeof selectSourceDestinationData>
>;

export const selectSourceOptions = createSelector(
  [selectFilePreview],
  (filePreview) =>
    filePreview.status === 'COMPLETE'
      ? filePreview.preview.top_level_keys
          .filter((value) => value)
          .map((value) => ({
            text: value,
            value,
          }))
      : [],
);

export const selectKeysAreLoading = createSelector(
  [selectInitialServerConfig, selectFilePreview],
  (initialServerConfig, filePreview) =>
    filePreview.status !== 'COMPLETE' ||
    initialServerConfig.status !== 'COMPLETE',
);

export const selectInUseTopLevelKeys = createSelector(
  selectLocalConfig,
  (localConfig) => {
    if (!localConfig) {
      return new Set();
    }

    const getKeysInSelection = (annotation: BaseDataMappingPair) => {
      const { selection } = annotation.value;
      switch (selection.type) {
        case 'hard_coded_value': {
          return [];
        }
        case 'metadata_value': {
          return [];
        }
        case 'stream_value': {
          return selection.key ? [selection.key] : [];
        }
        case 'aggregation': {
          return selection.aggregation.values
            .filter(
              (val): val is ValueSelection<StreamSelection> =>
                val.selection.type ===
                DATA_MAPPING_SELECTION_OPTIONS.STREAM_VALUE,
            )
            .map((val) => val.selection.key);
        }
        default: {
          throw new Error(`Undefined data mapping source`);
        }
      }
    };

    const selectionKeys = Object.values(localConfig.objects).flatMap(
      (obj: DataMappingObject) =>
        [...obj.typed_annotations, ...obj.custom_data_annotations].flatMap(
          (annotation: BaseDataMappingPair) => getKeysInSelection(annotation),
        ),
    );
    return new Set(selectionKeys);
  },
);

export const selectRowFiltering = createSelector(
  [selectLocalConfig],
  (localConfig) => localConfig?.type_handling ?? DEFAULT_TYPE_HANDLING_VALUES,
);

export const selectDataMappingPreview = (state: RootState) =>
  state[DATA_MAPPING_NAME].dataMappingPreview;

export const selectRelationships = (state: RootState) =>
  state[DATA_MAPPING_NAME].localAnnotationConfig?.relationships;

export const selectObjectSelectOptions = createSelector(
  [
    selectLocalConfig,
    (_: RootState, objectType: U21DataMappingObjectTypes) => objectType,
  ],
  (localConfig, objectType): U21SelectOptionProps[] => {
    if (localConfig) {
      return Object.values(localConfig.objects).reduce(
        (
          acc: U21SelectOptionProps[],
          o: DataMappingObject,
        ): U21SelectOptionProps[] => {
          if (o.type === objectType) {
            acc.push({
              // self is the keyword for primary objects
              value:
                o.name === localConfig.unit21_object
                  ? RESERVED_PRIMARY_OBJECT_INTERNAL_NAME
                  : o.name,
              text: o.name,
              description: TABLE_TO_READABLE_NAME[o.type],
            });
          }
          return acc;
        },
        [],
      );
    }
    return [];
  },
);

export const selectDirectionality = createSelector(
  [selectLocalConfig, (_: RootState, objectName: string) => objectName],
  (localConfig, objectName) => {
    return localConfig?.objects[objectName]?.directionality;
  },
);

export const selectMappingRowToCondition = (state: RootState) =>
  state[DATA_MAPPING_NAME].mappingRowToCondition;

export const selectMappingRowConditions = createSelector(
  [selectLocalConfig, selectMappingRowToCondition],
  (localConfig, mappingRowToCondition) => {
    if (localConfig && mappingRowToCondition) {
      const { objectName, row } = mappingRowToCondition;
      const object = getObject(localConfig, objectName);
      const dataMappingRow = getDataMappingRow(object, row.isCustom, row.id);
      return dataMappingRow?.conditions || [];
    }
    return [];
  },
);

export const selectSelectedConditions = (state: RootState) => {
  const { selectedAnnotation, localAnnotationConfig } =
    state[DATA_MAPPING_NAME];

  if (localAnnotationConfig && selectedAnnotation) {
    const { objectName, id, isCustom } = selectedAnnotation;
    const object = getObject(localAnnotationConfig, objectName);
    const dataMappingRow = getDataMappingRow(object, isCustom, id);
    return dataMappingRow?.conditions || [];
  }

  return [];
};

export const selectConditionsLength = createSelector(
  [selectLocalConfig],
  (localConfig) => {
    if (localConfig) {
      return Object.values(localConfig.objects)
        .flatMap((obj: DataMappingObject) => [
          ...obj.typed_annotations,
          ...obj.custom_data_annotations,
        ])
        .flatMap(
          (annotation: BaseDataMappingPair) => annotation.conditions ?? [],
        ).length;
    }
    return 0;
  },
);

export const selectTableRowId = (state: RootState) => {
  const { selectedAnnotation } = state[DATA_MAPPING_NAME];
  return selectedAnnotation?.id;
};

export const selectSettingUpdateExisting = createSelector(
  [selectLocalConfig],
  (localConfig) => localConfig?.updating_existing ?? false,
);
