import { createSelector } from 'reselect';
import { NarrativeTemplateType } from 'app/modules/narrativeTemplates/models';
import { consoleWarn } from 'app/shared/utils/console';
import { NARRATIVE_TEMPLATES_SLICE_NAME } from 'app/modules/narrativeTemplates/sliceNarrativeTemplates';
import { DropdownItemProps } from 'semantic-ui-react';

export const selectNarrativeTemplates = (type: NarrativeTemplateType) =>
  createSelector(
    selectAlertNarrativeTemplates,
    selectCaseNarrativeTemplates,
    (alertNarrativeTemplates, caseNarrativeTemplates) => {
      switch (type) {
        case NarrativeTemplateType.ALERT_NARRATIVE_TEMPLATE:
          return alertNarrativeTemplates;
        case NarrativeTemplateType.CASE_NARRATIVE_TEMPLATE:
          return caseNarrativeTemplates;
        default:
          consoleWarn(`Not implemented for: ${type}`);
          return [];
      }
    },
  );

export const selectAlertNarrativeTemplates = (state: RootState) =>
  state[NARRATIVE_TEMPLATES_SLICE_NAME].alertNarrativeTemplates;

export const selectCaseNarrativeTemplates = (state: RootState) =>
  state[NARRATIVE_TEMPLATES_SLICE_NAME].caseNarrativeTemplates;

export const selectNarrativeTemplatesCount = (type: NarrativeTemplateType) =>
  createSelector(
    selectAlertNarrativeTemplatesCount,
    selectCaseNarrativeTemplatesCount,
    (alertNarrativeTemplatesCount, caseNarrativeTemplatesCount) => {
      switch (type) {
        case NarrativeTemplateType.ALERT_NARRATIVE_TEMPLATE:
          return alertNarrativeTemplatesCount;
        case NarrativeTemplateType.CASE_NARRATIVE_TEMPLATE:
          return caseNarrativeTemplatesCount;
        default:
          consoleWarn(`Not implemented for: ${type}`);
          return 0;
      }
    },
  );

export const selectAlertNarrativeTemplatesCount = (state: RootState) =>
  state[NARRATIVE_TEMPLATES_SLICE_NAME].alertNarrativeTemplatesCount;

export const selectCaseNarrativeTemplatesCount = (state: RootState) =>
  state[NARRATIVE_TEMPLATES_SLICE_NAME].caseNarrativeTemplatesCount;

export const selectLoadingNarrativeTemplates = (state: RootState) =>
  state[NARRATIVE_TEMPLATES_SLICE_NAME].loadingNarrativeTemplates;

export const selectLoadingCreateEditDeleteNarrativeTemplate = (
  state: RootState,
) =>
  state[NARRATIVE_TEMPLATES_SLICE_NAME]
    .loadingCreateEditDeleteNarrativeTemplate;

export const selectSearchAlertNarrativeTemplates = (state: RootState) =>
  state[NARRATIVE_TEMPLATES_SLICE_NAME].searchAlertNarrativeTemplates;

export const selectSearchCaseNarrativeTemplates = (state: RootState) =>
  state[NARRATIVE_TEMPLATES_SLICE_NAME].searchCaseNarrativeTemplates;

export const selectSearchNarrativeTemplates = (type: NarrativeTemplateType) =>
  createSelector(
    selectSearchAlertNarrativeTemplates,
    selectSearchCaseNarrativeTemplates,
    (alertNarrativeTemplates, caseNarrativeTemplates) => {
      switch (type) {
        case NarrativeTemplateType.ALERT_NARRATIVE_TEMPLATE:
          return alertNarrativeTemplates;
        case NarrativeTemplateType.CASE_NARRATIVE_TEMPLATE:
          return caseNarrativeTemplates;
        default:
          consoleWarn(`Not implemented for: ${type}`);
          return [];
      }
    },
  );

export const selectFormattedNarrativeTemplates = (
  type: NarrativeTemplateType,
) =>
  createSelector(
    selectSearchNarrativeTemplates(type),
    (searchTemplates): DropdownItemProps[] => {
      return searchTemplates.map((template) => {
        return {
          value: template.id,
          text: template.title,
        };
      });
    },
  );
