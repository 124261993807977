import { TableInstance } from 'react-table';

import { useEffect, useReducer, useRef } from 'react';
import { useResizeDetector } from 'react-resize-detector';

const useInstance = (instance: TableInstance) => {
  const { isTableRendered } = instance;
  const { ref: tableContainerRef } = useResizeDetector({
    handleHeight: false,
    refreshMode: 'debounce',
    refreshRate: 300,
  });
  const tableRef = useRef<HTMLDivElement>(null);

  // forceUpdate once table is rendered so we can get the latest table widths
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  useEffect(() => {
    if (isTableRendered) {
      forceUpdate();
    }
  }, [isTableRendered]);

  const containerWidth = tableContainerRef.current?.offsetWidth || 0;
  const tableWidth = tableRef.current?.offsetWidth || 0;

  Object.assign(instance, {
    isScrollable: tableWidth > containerWidth,
    tableContainerRef,
    tableRef,
  });
};

export const useTableRef = (hooks) => {
  hooks.getTableProps.push((props, { instance }) => [
    props,
    { ref: instance.tableRef },
  ]);
  hooks.useInstance.push(useInstance);
};
useTableRef.pluginName = 'useTableRef';
