import {
  DeadlineChipStatus,
  DeadlinesConfig,
  StatusToStyle,
} from 'app/modules/deadlines/models';
import { IconClockExclamation } from '@u21/tabler-icons';

export const DEFAULT_DEADLINES_CONFIG: DeadlinesConfig = {
  id: -1,
  title: '',
  calc_start_date: 'CREATED_AT',
  conditions: {},
  priority: 3,
  duration: 0,
};

export const DEADLINES_CALC_OPTIONS = [
  {
    value: 'CREATED_AT',
    text: 'Created at',
  },
  {
    value: 'LAST_EVENT',
    text: 'Last event',
  },
  {
    value: 'EARLIEST_EVENT',
    text: 'Earliest event',
  },
];

export const CHIP_STATUS_TO_STYLE: StatusToStyle = {
  [DeadlineChipStatus.PAST_DEADLINE]: {
    color: 'error',
    icon: <IconClockExclamation />,
    variant: 'filled',
  },
  [DeadlineChipStatus.WARN_BEFORE_DEADLINE]: {
    color: 'warning',
    icon: <IconClockExclamation />,
    variant: 'filled',
  },
  [DeadlineChipStatus.NORMAL_DEADLINE]: {
    color: 'primary',
    variant: 'ghost',
  },
  [DeadlineChipStatus.NO_DEADLINE]: { color: 'default', variant: 'filled' },
};
