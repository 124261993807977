import {
  AuditTrailActionTypes,
  AuditTrailDetails,
  AuditTrailEntriesPayload,
  PaginatedAuditTrailResponse,
} from 'app/shared/auditTrail/models';
import { createAction, ActionsUnion } from 'app/shared/utils/action-helpers';

export const retrieveAuditTrail = (payload: string) =>
  createAction(AuditTrailActionTypes.RETRIEVE_AUDIT_TRAIL, payload);

export const retrieveAuditTrailSuccess = (result: AuditTrailDetails) =>
  createAction(AuditTrailActionTypes.RETRIEVE_AUDIT_TRAIL_SUCCESS, result);

export const retrieveAuditTrailEntries = (payload: AuditTrailEntriesPayload) =>
  createAction(AuditTrailActionTypes.RETRIEVE_AUDIT_TRAIL_ENTRIES, payload);

export const retrieveAuditTrailEntriesSuccess = (
  result: PaginatedAuditTrailResponse,
) =>
  createAction(
    AuditTrailActionTypes.RETRIEVE_AUDIT_TRAIL_ENTRIES_SUCCESS,
    result,
  );

export const clearAuditTrailEntries = () =>
  createAction(AuditTrailActionTypes.CLEAR_AUDIT_TRAIL_ENTRIES);

const AuditTrailReduxActionsList = {
  retrieveAuditTrail,
  retrieveAuditTrailSuccess,
  retrieveAuditTrailEntries,
  retrieveAuditTrailEntriesSuccess,
  clearAuditTrailEntries,
};

type AuditTrailReduxActions = ActionsUnion<typeof AuditTrailReduxActionsList>;

export default AuditTrailReduxActions;
