import { useState } from 'react';

// Components
import { EditCustomData } from 'app/shared/u21-ui/components/customData/EditCustomData';
import { ShowCustomData } from 'app/shared/u21-ui/components/customData/ShowCustomData';

// Models
import { U21CustomDataProps } from 'app/shared/u21-ui/components/customData/models';

export const U21CustomData = (props: U21CustomDataProps) => {
  const { defaultEditing, value, ...rest } = props;
  const { editInModal: editInModalProp } = props;
  const editInModal = Object.prototype.hasOwnProperty.call(props, 'editInModal')
    ? editInModalProp
    : true;

  const customDataValues = value || {};

  const [editing, setEditing] = useState(!!defaultEditing);
  const toggleEditing = () => {
    setEditing(!editing);
  };

  return (
    <>
      {(!editing || editInModal) && (
        <ShowCustomData
          value={customDataValues}
          toggleEditing={toggleEditing}
          {...rest}
        />
      )}
      <EditCustomData
        value={customDataValues}
        toggleEditing={toggleEditing}
        editing={editing}
        {...rest}
      />
    </>
  );
};
