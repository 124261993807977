// Redux
import { Reducer } from 'redux';
import { Draft, produce } from 'immer';

// Models
import {
  FiltersActionTypes,
  FiltersState,
} from 'app/modules/filtersOld/models';

// Actions
import { FiltersActions } from 'app/modules/filtersOld/actions';

// Utils
import deepFreeze from 'app/shared/utils/deepFreeze';

export const initialLinkAnalysisFilters = {
  selectedEntity: -1,
  showCurrentAlert: true,
  showEntities: true,
  showCurrency: false,
  showInstruments: false,
  groupBy: 'default',
  eventTypeShown: 'internal_txn_type',
  eventTypesShown: {},
};

const initialState: Readonly<FiltersState> = {
  linkAnalysis: { ...initialLinkAnalysisFilters },
};

// to ensure initialState is readonly
deepFreeze(initialState);

const reducer: Reducer<FiltersState> = (
  state = initialState,
  action: FiltersActions,
) => {
  return produce(state, (draft: Draft<FiltersState>) => {
    switch (action.type) {
      case FiltersActionTypes.SET_LINK_ANALYSIS_FILTERS:
        draft.linkAnalysis = action.payload;
        return;
      case FiltersActionTypes.MODIFY_LINK_ANALYSIS_FILTERS:
        draft.linkAnalysis = {
          ...draft.linkAnalysis,
          ...action.payload,
        };
        return;
    }
  });
};

export { reducer as filtersReducer, initialState };
