import { OFFSET_DEFAULT } from 'app/shared/constants';

import { getAgentDisplayName } from 'app/modules/agents/utils';
import { retrieveDropdownAgents } from 'app/shared/dropdown/actions';
import { selectDropdownAgents } from 'app/shared/dropdown/selectors';
import { selectHasReadAgentsPermissions } from 'app/modules/session/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useRef } from 'react';

import { FilterMoreDisplay } from 'app/modules/filters/components/FilterMoreDisplay';

interface Props {
  ids: number[];
  label: string;
  unassigned?: boolean;
}

export const AgentFilterDisplay = (props: Props) => {
  const { ids, label, unassigned } = props;
  const dispatch = useDispatch();
  const hasReadAgentsPermission = useSelector(selectHasReadAgentsPermissions);
  const baseAgents = useSelector(selectDropdownAgents);
  const agents = useMemo(() => {
    if (unassigned) {
      return {
        ...baseAgents,
        [-1]: { id: -1, email: 'Unassigned' },
      };
    }
    return baseAgents;
  }, [baseAgents, unassigned]);

  // calledRef is used to prevent an infinite loop in case the API never returns with the missing value
  const calledRef = useRef(false);
  useEffect(() => {
    calledRef.current = false;
  }, [ids]);
  useEffect(() => {
    if (hasReadAgentsPermission) {
      const missingValues = ids.filter((i) => i !== -1 && !agents[i]);
      if (missingValues.length && !calledRef.current) {
        calledRef.current = true;
        dispatch(
          retrieveDropdownAgents({
            limit: missingValues.length,
            offset: OFFSET_DEFAULT,
            ids: missingValues,
          }),
        );
      }
    }
  }, [agents, dispatch, hasReadAgentsPermission, ids]);

  const values = useMemo(
    () =>
      ids.map((i) => getAgentDisplayName(agents[i] || { email: 'Unknown' })),
    [agents, ids],
  );

  return (
    <>
      <b>{label}</b> is one of <FilterMoreDisplay values={values} />
    </>
  );
};
