import {
  CASE_ASSIGNEE,
  CASE_DESCRIPTION,
  CASE_END_DATE,
  CASE_QUEUE,
  CASE_START_DATE,
  CASE_TITLE,
} from 'app/modules/cases/schemas/generalCaseCreate';
import {
  CASE_S3_DOCUMENTS,
  CASE_GDRIVE_DOCUMENTS,
  CASE_GDRIVE_PICKED_DOCUMENTS,
} from 'app/modules/cases/schemas/attachmentCaseCreate';
import {
  CASE_DETAILS_SCHEMA,
  CASE_LINK_ALL_RELATED_ENTITIES,
  CASE_LINK_ALL_RELATED_TRANSACTIONS,
} from 'app/modules/cases/schemas/detailsCaseCreate';

export const CASE_CREATE_SCHEMA = [
  CASE_TITLE,
  CASE_DESCRIPTION,
  CASE_ASSIGNEE,
  CASE_QUEUE,
  CASE_START_DATE,
  CASE_END_DATE,
  ...CASE_DETAILS_SCHEMA,
  CASE_LINK_ALL_RELATED_ENTITIES,
  CASE_LINK_ALL_RELATED_TRANSACTIONS,
  CASE_S3_DOCUMENTS,
  CASE_GDRIVE_DOCUMENTS,
  CASE_GDRIVE_PICKED_DOCUMENTS,
];
