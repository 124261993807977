import { CSSProperties, ReactElement } from 'react';

import styled from 'styled-components';

interface Props {
  children: ReactElement;
  className?: string;
  style?: CSSProperties;
  value?: number | string;
}

export const ResponsiveContainer = (props: Props) => {
  const { children, className, style, value } = props;
  return (
    <Container>
      {/* hidden div that sets the parent component width */}
      <div
        // apply classname + style so it's styled similarly to the input component
        // mainly for left / right margin + padding
        className={className}
        style={{
          ...style,
          // force hidden with no height using highest specificity
          height: 0,
          minHeight: 0,
          marginBottom: 0,
          marginTop: 0,
          paddingBottom: 0,
          paddingTop: 0,
          width: '100%',
          visibility: 'hidden',
        }}
      >
        {value}
      </div>
      {children}
    </Container>
  );
};

const Container = styled.div`
  overflow: hidden;
`;
