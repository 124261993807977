import { FC, HTMLProps } from 'react';

import { getDOMProps } from 'app/shared/utils/react';
import pluralize from 'pluralize';

// Components
import {
  U21Chip,
  U21ChipColor,
} from 'app/shared/u21-ui/components/display/U21Chip';

export interface U21CountLabelProps extends HTMLProps<HTMLSpanElement> {
  color?: U21ChipColor;
  count: null | number;
  label?: string;
}

// to indicate getting the count timed out on the BE
const EMPTY_COUNT = null;

export const U21CountLabel: FC<U21CountLabelProps> = ({
  color,
  count,
  label = 'result',
  ...rest
}) => {
  if (count === EMPTY_COUNT) {
    return null;
  }

  return (
    <U21Chip
      color={color}
      {...getDOMProps(rest)}
    >{`${new Intl.NumberFormat('en-US').format(count)} ${pluralize(label, count)}`}</U21Chip>
  );
};
